import { render, staticRenderFns } from "./EventEmailShareModal.vue?vue&type=template&id=b0ae4c70&scoped=true&"
import script from "./EventEmailShareModal.vue?vue&type=script&lang=ts&"
export * from "./EventEmailShareModal.vue?vue&type=script&lang=ts&"
import style0 from "./EventEmailShareModal.vue?vue&type=style&index=0&id=b0ae4c70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0ae4c70",
  null
  
)

export default component.exports