
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

import { organization_enum } from '@/model/organization';

import { CommonDatepickerModalInfo } from '@/store/modules/ModalInfo';
import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion
    },
})
export default class OrganizationEnteredDateSetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;
    
    @Prop() event_bus !: Vue;

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME ) {

                if( o1_name == o2_name ) {
                    // 같은 이름은 직급순
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_name > o2_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION ) {
                
                // 같은 직급은 이름순
                if( o1_pos_seq == o2_pos_seq ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0
                }
                else if( o1_pos_seq > o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
                else if( o1_pos_seq < o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.DEPARTMENT ) {
                const o1_dept_id   = o1.dept_id;
                const o2_dept_id   = o2.dept_id;

                const o1_dept_name = this.getDepartmentName(o1_dept_id);
                const o2_dept_name = this.getDepartmentName(o2_dept_id);

                if( o1_dept_name == o2_dept_name ) {

                    if( o1_dept_id == o2_dept_id ) {
                        // 같은 이름은 dept_id 순서 + 직급순
                        if( o1_pos_seq > o2_pos_seq ) return 1;
                        else if( o1_pos_seq < o2_pos_seq ) return -1;
                        return 0;
                    }
                    else if( o1_dept_id > o2_dept_id ) return 1;
                    else if( o1_dept_id < o2_dept_id ) return -1;
                    
                    return 0;
                }
                else if( o1_dept_name > o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_dept_name < o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.ENTERED_DATE ) {
                const o1_entered_date = o1.start_date ? o1.start_date : '';
                const o2_entered_date = o2.start_date ? o2.start_date : '';

                if( o1_entered_date == o2_entered_date ) {
                    // 같은 이름은 직급순
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_entered_date > o2_entered_date ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_entered_date < o2_entered_date ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.RESIGN_DATE ) {
                const o1_resign_date = o1.end_date ? o1.end_date : '';
                const o2_resign_date = o2.end_date ? o2.end_date : '';

                if( o1_resign_date == o2_resign_date ) {
                    // 같은 이름은 직급순
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_resign_date > o2_resign_date ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_resign_date < o2_resign_date ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            return 0;
        });

        // 검색
        if( this.search_query != null && this.search_query.length > 0 ) {
            employees = employees.filter(item => // 이름   
                                                 this.hodu_string_includes(item.user_name, this.search_query) ||

                                                 // 부서
                                                 this.hodu_string_includes(this.getDepartmentName(item.dept_id), this.search_query) ||

                                                 // 직급
                                                 this.hodu_string_includes(this.getPositionName(item.pos_id), this.search_query));
        }

        return employees;
    }

    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetCommonDatepickerModalInfo ?: (params : CommonDatepickerModalInfo) => void; 

    departments : any[] = [];
    selected_department : any = { dept_id : -1 }; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];
    search_query : string = "";

    sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE = organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME;
    sort_direction : SORT_TYPE = SORT_TYPE.ASC;

    department_scroll_height : number = 0;

    is_close_organization : boolean = false;
    
    async mounted() : Promise<void> {
        this.event_bus?.$on("OrganizationEnteredDateSettingResize", this.handleResize);

        await this.getTotalOrganizationData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        // 조직도 스크롤
        const title_box_height = $('#organization_setting .title_box').outerHeight();
        const title_box2_height = $('#organization_setting .title_box2').outerHeight();
        const all_height = $('#organization_setting .approval_content .wrapper .chk.all').outerHeight();
        const content_top_height = $('#organization_setting .approval_content .content_top').outerHeight();

        this.department_scroll_height = window.innerHeight - (title_box_height ? title_box_height : 0)
                                                           - (title_box2_height ? title_box2_height : 0)
                                                           - (content_top_height ? content_top_height : 0)
                                                           - (all_height ? all_height : 0);

        // 부서원 리스트 스크롤
        const fixed_div_height = $('#organization_setting .approval_content .section_scroll .fixedDiv').outerHeight();

        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (title_box2_height ? title_box2_height : 0)
                                           - (content_top_height ? content_top_height : 0)
                                           - (fixed_div_height ? fixed_div_height : 0)
        });
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 부서 이름 반환
     */
    getDepartmentName(dept_id : number) : string {
        try {
            const target = this.departments.filter(item => item.dept_id == dept_id);
            if( target.length < 1 ) return "-";
            return target[0].dept_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 정렬
     */
    sort(sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user_on.png');
    }

    /**
     * datepicker 열기
     */
    openDatepickerModal(employee : any, date : string, type : string) : void {

        if( employee == null ) return;

        const data : CommonDatepickerModalInfo = {
            show_modal : true,
            callback : async(selected_date : Date) => {
                
                try {
                    const date_string = moment(selected_date).format('YYYYMMDD');
                    
                    // API 실행
                    const data = JSON.parse(JSON.stringify(employee));

                    if( type == 'START' ) {
                        data.start_date = date_string;
                    }

                    else if( type == 'END' ) {
                        data.end_date = date_string;
                    }

                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.POST, data);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 직원 입·퇴사일 변경 중 오류 발생");
                    }

                    this.getTotalOrganizationData();

                } catch(e) {
                    this.hodu_show_dialog('cancel', "조직도 직원 입·퇴사일 변경 중 오류 발생", ["확인"]);
                    this.hodu_error_process(e, false, false, true);
                }   

            }
        }

        if( date != null ) {
            const date_string = ([date.substring(0, 4), date.substring(4, 6), date.substring(6, 8)] as string[]).join('-');
            data.default_date = new Date(date_string);
        }

        this.doSetCommonDatepickerModalInfo?.(data);
    }

    /**
     * 날짜 가공
     */
    processDate(date : string) : string {
        const date_string = ([date.substring(0, 4), date.substring(4, 6), date.substring(6, 8)] as string[]).join('-');
        return moment(date_string).format('YYYY.MM.DD');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
