
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { t_event_file } from '@/model/event';

import JSZip from 'jszip';

import { GroupBoardReplyModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class GroupBoardDetail extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupBoardReplyModalInfo ?: (params : GroupBoardReplyModalInfo) => void;

    board : any = null;
    board_id : string | null = null;
    body : string = "";

    file_popup_open : boolean = false;
    files : any[] = [];

    interval : number | undefined;

    beforeMount() {
        this.board_id = this.$route.params.board_id;
    }

    mounted() : void {
        this.getGroupBoard();

        this.interval = window.setInterval(() => { $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 367)); }, 1000);
    }

    beforeDestroy() {
        if( this.interval ) window.clearInterval(this.interval);
    }

    async getGroupBoard() : Promise<void> {

        try {
            
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/board/${this.board_id}?do_read=true`, API_METHOD.GET);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || ! response.data.data || !response.data.data.post ) {
                throw new Error("그룹 게시글 조회 중 오류 발생");
            }

            this.board = JSON.parse(JSON.stringify(response.data.data.post));
            
            this.body = this.board.board_info.content.replace(/\n/g, "<br>");

            this.files.splice(0, this.files.length);
            this.files = this.files.concat(this.board.board_info.files);

            this.$nextTick(() => {
                $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 367));
            });

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "그룹 게시글 조회 중 오류 발생", ['확인']);
        }
        
    }

    /**
     * 파일 다운로드 (조회)
     */
    fileDownload(file : t_event_file) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 파일 전체 다운로드
     */
     async allDownload() : Promise<void> {
        
        const selected_files : t_event_file[] = this.board.board_info.files;

        if( selected_files.length < 1 ) {
            this.hodu_show_dialog('alert', '다운로드 할 파일을 선택해주세요', ["확인"], [() => {}]);
            return;
        }

        // 파일 1개라면
        if( selected_files.length == 1 ) {
            this.fileDownload(selected_files[0]);
            return;
        }

        // 파일 여러개는 zip 파일로 받는다

        await this.hodu_show_indicator();

        const zip = new JSZip();

        const file_promise : Promise<void>[] = [];

        for( const file of selected_files ) {

            // 파일 다운로드 Promise
            const promise = this.hodu_download(`/app_images/${file.url}`, "", true)
                .then((response) => {
                    console.log(response);

                    // 다운로드한 파일은 zip 파일 구성에 추가
                    zip.file(file.name, new Blob([response.data]));
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });

            // 병렬처리용 Promise 배열에 넣기
            file_promise.push(promise);
        }

        Promise.all(file_promise)
            .then(async() => {

                // zip 파일 압축
                await zip.generateAsync({type:"blob"}).then((content) => {
                    
                    // 압축 완료시 나오는 blob으로 다운로드
                    try {
                        this.hodu_download_blob(content, `hodu_download_${new Date().getTime()}.zip`);
                    } catch(e) {
                        this.hodu_error_process(e, true, false);
                    }

                });
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            }).finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    filePopupOnOff() {
        if( this.files == null || this.files.length < 1 ) return;
        this.file_popup_open = !this.file_popup_open;
    }

    /**
     * 댓글
     */
     showBoardReply() : void {
        this.doSetGroupBoardReplyModalInfo?.({
            show_modal : true,
            group_id : this.board.group_id,
            board_id : this.board.board_id,
            callback : this.getGroupBoard
        });
    }

    moveModify() : void {
        this.hodu_router_push(`/group/${new Date().getTime()}/board/modify/${this.board_id}`);
    }

    deleteBoard() : void {
        this.hodu_show_dialog('cancel', "정말로 게시글을 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/board/${this.board_id}`, API_METHOD.DELETE);

                    console.log(response);

                    if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("그룹 게시글 삭제 중 오류 발생");
                    }

                    this.movePrevPage();
                     
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "그룹 게시글 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);
    }

}
