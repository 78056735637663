
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { approval_interface } from '@/model/approval';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    name : "OrganizationRecursion",
    components: {
        
    },
})
export default class OrganizationRecursion extends Mixins(VueHoduCommon) {

    @Prop() level               !: number;
    @Prop() content_type        !: string; // checkbox, radio, p
    @Prop() departments         !: any[];
    @Prop() selected_department !: any;
    @Prop() prev_department     !: any;

    @Prop() department_closed_map !: Map<number, boolean>;
    @Prop() department_height_map !: Map<number, number>;

    @Prop() filter !: approval_interface.ApprovalFilter; 

    mounted() : void {
        
    }

    /**
     * 카테고리 토글
     */
    toggleMenu(department : any) : void {
        console.log(department);
        department.is_closed = !department.is_closed;
        this.$forceUpdate();
        this.updateHeight();

        this.department_closed_map.set(department.dept_id, department.is_closed);
    }

    /**
     * 높이 업데이트
     */
    updateHeight() : void {
        this.$nextTick(() => {

            if( this.level == 2 ) {
                // 레벨 2 컴포넌트 전체 높이 업데이트
                for(const department of this.departments) {

                    const target_dept_id = department.dept_id;

                    const parent_li_height = $(`#modal-organization-li-${department.dept_id}`).outerHeight();
                    const last_child_li_height = $(`#modal-organization-li-${department.dept_id} > ul.sub2 > li:last-child`).outerHeight(); 
                    const final_height = (parent_li_height ? parent_li_height : 0) - (last_child_li_height ? last_child_li_height : 0) + 15;
                    department.vertical_height = final_height;

                    // 맵에 세팅
                    this.$nextTick(() => { this.department_height_map.set(target_dept_id, final_height); }); 
                }
            }
            else if( this.level == 3 ) { 
                // 레벨 2 컴포넌트 전체 높이 업데이트
                this.$emit('updateHeight');
            }
            else if( this.level > 3 ) {

                // 현재 컴포넌트 높이 업데이트
                const target_dept_id = this.prev_department.dept_id;

                const parent_li_height = $(`#modal-organization-li-${this.prev_department.dept_id}`).outerHeight();
                const last_child_li_height = $(`#modal-organization-li-${this.prev_department.dept_id} > ul.level4 > li > ul.sub2 > li:last-child`).outerHeight();
                const final_height = (parent_li_height ? parent_li_height : 0) - (last_child_li_height ? last_child_li_height : 0) + 12;

                this.prev_department.vertical_height = final_height;
                if( this.prev_department.is_closed == true ) this.prev_department.vertical_height = 0;

                // 맵에 세팅
                this.$nextTick(() => { this.department_height_map.set(target_dept_id, final_height); });

                // 이전 컴포넌트 높이 업데이트 
                this.$emit('updateHeight');
            }
            this.$forceUpdate();
        })
    }

    /**
     * 부서 선택
     */
    select(department : any) : void {
        this.$emit('select', department);
    }

    /**
     * 변경
     */
    change(event, department) : void {
        if( this.content_type == 'radio' && event.target.checked == true ) {
            this.select(department);
        }

        else if( this.content_type == 'checkbox' ) {
            this.$emit('select', { "event" : event, "dept_id" : department.dept_id } );
        }
    }

    // getVerticalLineHeightForLevelOne(id : string) : string {
    //     this.getVerticalLineHeightForLevelOnePromise(id).then((height) => {
    //         this.height_map.set(id, height);
    //     });
    //     return "0";
    // }

    // getVerticalLineHeightForLevelOnePromise(id : string) : Promise<string> {
    //     return new Promise((resolve, reject) => {
    //         this.$nextTick(() => {
    //             const parent_li_height = $(`#${id}`).outerHeight();
    //             const last_child_li_height = $(`#${id} > ul.sub2 > li:last-child`).outerHeight(); 
    //             resolve(`${ (parent_li_height ? parent_li_height : 0) - (last_child_li_height ? last_child_li_height : 0) + 15}px`)
    //         });
    //     });
    // }

}
