import {GetterTree, MutationTree , ActionTree} from "vuex";

export const namespaced = true;

export interface EtcInfoState {
    hodu_noti_uid : string
    message_uid : string
    message_info : any

    /**
     * 예제용 string
     */
    example_string : string
}

export const state: EtcInfoState = {
    hodu_noti_uid : "",
    message_uid: "",
    message_info: {},

    /**
     * 예제용 string
     */
    example_string: "",
}

// getters
export const getters: GetterTree<EtcInfoState, any> = {
    getHoduNotiUid : (state) => {
        return state.hodu_noti_uid;
    },
    getMessageUid : (state) => {
        return state.message_uid;
    },
    getMessageInfo : (state) => {
        return state.message_info;
    },

    /**
     * 예제용 string
     */
    getExampleString : (state) => {
        return state.example_string;    
    },
}

// action - 동기
export const actions: ActionTree<EtcInfoState, EtcInfoState> = {
    doHoduNotiUid: ({commit, state}, hodu_noti_uid : EtcInfoState) => {
        console.log("actions.doHoduNotiUid");
        commit("replaceHoduNotiUid", hodu_noti_uid);
    },
    doMessageUid: ({commit, state}, message_uid : EtcInfoState) => {
        console.log("actions.doMessageUid");
        commit("replaceMessageUid", message_uid);
    },
    doMessageInfo: ({commit, state}, message_info : EtcInfoState) => {
        console.log("actions.doMessageInfo");
        commit("replaceMessageInfo", message_info);
    },

    /**
     * 예제용 String 
     */
    doSetExampleString : ({commit, state}, example_string : string) => {
        console.log("actions.doSetExampleString");
        commit("setExampleString", example_string);
    },
}

// mutation - 비동기
export const mutations: MutationTree<EtcInfoState> = {
    replaceEtcInfoState:(state, payload : EtcInfoState) => {
        state.hodu_noti_uid = payload.hodu_noti_uid;
        state.message_uid   = payload.message_uid;
        state.message_info  = payload.message_info;

        /**
         * 예제용 string 유지
         */
        state.example_string = payload.example_string;

        if( sessionStorage != null ){
            sessionStorage.setItem("etc_info", JSON.stringify(state));
        }
    },

    replaceHoduNotiUid: (state, payload : string) => {
        state.hodu_noti_uid = payload;

        if( sessionStorage != null ){
            sessionStorage.setItem("etc_info", JSON.stringify(state));
        }
    },    

    replaceMessageUid: (state, payload : string) => {
        state.message_uid = payload;

        if( sessionStorage != null ){
            sessionStorage.setItem("etc_info", JSON.stringify(state));
        }
    },    

    replaceMessageInfo: (state, payload : any) => {
        state.message_info = payload;

        if( sessionStorage != null ){
            sessionStorage.setItem("etc_info", JSON.stringify(state));
        }
    },
    
    setExampleString: (state, payload : string) => {
        state.example_string = payload;
        
        /**
         * 새로고침 해도 유지가 되어야 한다면
         */
        if( sessionStorage != null ){
            sessionStorage.setItem("etc_info", JSON.stringify(state));
        }
    },
}