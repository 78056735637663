
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const MenuInfo = namespace('MenuInfo');

const dateFormat = require('dateformat');
import moment from 'moment';

@Component({

})
export default class Top extends Mixins(VueHoduCommon) {
    
    /** 
     * @CalendarInfo.Action 
     */ 
    @CalendarInfo.Action doSetStartDate ?: any; // start_date 업데이트

    /**
     * @MenuInfo.Action
     */
    @MenuInfo.Action doSetUserProfileOpen          ?: any;
    @MenuInfo.Action doSetNotificationListMenuOpen ?: any;
    @MenuInfo.Action doSetFileboxMenuOpen          ?: any;
    @MenuInfo.Action doSetTodoMenuOpen             ?: any;
    @MenuInfo.Action doTodoCreateOrUpdate          ?: any;
    @MenuInfo.Action doSetEtcMenuOpen              ?: any;

    async logoClick() : Promise<void> {

        // 호두홈 보안실은 달력으로 진입못함
        if( this.isHoduH(this.scope_group_team_option.biz_type) && this.get_group_role(this.scope_group_id) == 'GROUP_SUB_MANAGER' ) {
            return;
        }

        await this.doSetStartDate(new Date()); 

        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date());
        
        this.hodu_router_push(`/?${new Date().getTime()}`);
        this.calendarGotoDateCss(dateFormat(new Date(), 'yyyy-mm-dd'));
    }

    /**
     * 캘린더 변경 CSS 
     */
    calendarGotoDateCss(date : string) : void {
        
        this.$nextTick(() => {
            $('#calendar').find('.fc-day-number').removeClass('on_orange');
            $('#calendar').find('.fc-day-number').removeClass('on_select');
            
            if(dateFormat(moment().toDate(), 'yyyy-mm-dd') == date) {
                $('#calendar').find(`[data-date="${date}"]`).find('.fc-day-number').addClass('on_orange');
            }else {
                $('#calendar').find(`[data-date="${date}"]`).find('.fc-day-number').addClass('on_select');
            }
        });
        
    }

    leftControlBoxClick() : void {
        this.doSetLeftControlBoxFlag(!this.left_control_box_flag);
    }

    rightControlBoxClick() : void {
        this.doSetRightControlBoxFlag(!this.right_control_box_flag);

        // 오른쪽 메뉴 닫히게 설정
        if( this.right_control_box_flag == true ) {
            this.doSetUserProfileOpen(false);
            this.doSetNotificationListMenuOpen(false);
            this.doSetFileboxMenuOpen(false);
            this.doSetTodoMenuOpen(false);
            this.doTodoCreateOrUpdate(false);
            this.doSetEtcMenuOpen(false);
        }
    }

}

