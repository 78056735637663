
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_enum, organization_modal_interface } from '@/model/organization';

import moment from 'moment';

import { directive as onClickaway } from 'vue-clickaway';

@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        
    },
})
export default class OrganizationOffSetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;
    
    @Prop() event_bus !: Vue;

    /**
     * 년도와 년도에 맞는 지정휴무일 배열 데이터
     */
    get computedYearAndOffs() : any[] {

        let year_and_offs : any[] = [];

        for( const off of this.day_offs ) {
            const start : string = off.start_date;
            const end   : string = off.end_date;

            const start_yyyy = start.substring(0,4);
            const end_yyyy   = end.substring(0,4);

            const start_year_search = year_and_offs.filter(item => item.year == start_yyyy);
            
            if( start_year_search.length > 0 ) {
                start_year_search[0].offs.push(off);
            }
            else {
                year_and_offs.push({
                    year : start_yyyy,
                    offs : [off]
                });
            }

            // 시작 년도와 종료 년도가 다르다면
            if( start_yyyy != end_yyyy ) {
                const end_year_search = year_and_offs.filter(item => item.year == end_yyyy);
                if( end_year_search.length > 0 ) {
                    end_year_search[0].offs.push(off);
                }
                else {
                    year_and_offs.push({
                        year : end_yyyy,
                        offs : [off]
                    });
                }
            }
            
        }

        return year_and_offs;
    }

    /**
     * 선택된 년도의 지정 휴무일
     */
    get computedSelectedYearOffs() : any[] {
        if( this.selected_year == null || this.selected_year.length < 1 ) return [];

        const target = this.computedYearAndOffs.filter(item => item.year == this.selected_year);
        
        if( target.length < 1 ) return [];

        return target[0].offs.sort((o1, o2) : number => {

            const o1_start_date = o1.start_date;
            const o2_start_date = o2.start_date;
            
            if( this.sort_type == organization_enum.ORGANIZATION_OFF_SORT_TYPE.DATE ) {
                if( o1_start_date > o2_start_date ) return this.sort_dir == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_start_date < o2_start_date ) return this.sort_dir == SORT_TYPE.ASC ? -1 : 1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_OFF_SORT_TYPE.COUNT ) {
                const o1_end_date = o1.end_date;
                const o2_end_date = o2.end_date;

                const o1_count = this.getDateDiff(this.yyyymmddToDate(o1_start_date), this.yyyymmddToDate(o1_end_date));
                const o2_count = this.getDateDiff(this.yyyymmddToDate(o2_start_date), this.yyyymmddToDate(o2_end_date));

                if( o1_count > o2_count ) return this.sort_dir == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_count < o2_count ) return this.sort_dir == SORT_TYPE.ASC ? -1 : 1;
                else {
                    // 일수가 같은것은 start_date ASC 순서로 뽑아냄
                    if( o1_start_date > o2_start_date ) return 1;
                    else if( o1_start_date < o2_start_date ) return -1;
                }
            }

            return 0;
        });
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationOffAddModalInfo ?: (params : organization_modal_interface.OrganizationOffAddModalInfo) => void;

    day_offs : any[] = [];

    selected_year : string = "";

    is_year_open : boolean = false;

    sort_type : organization_enum.ORGANIZATION_OFF_SORT_TYPE = organization_enum.ORGANIZATION_OFF_SORT_TYPE.DATE;
    sort_dir : SORT_TYPE = SORT_TYPE.ASC;

    mounted() : void {
        this.event_bus?.$on("OrganizationOffSettingResize", this.handleResize);

        this.selected_year = moment().format('YYYY');
        this.setScroll();

        this.getOrganizationDayOffs();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            // 조직도 스크롤
            const title_box_height = $('#organization_setting .title_box').outerHeight();
            const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            const sort_header_height = $('#organization_off_setting .sortHeader').outerHeight();

            // @ts-ignore
            $('#organization_off_setting_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (title_box2_height ? title_box2_height : 0)
                                            - (sort_header_height ? sort_header_height : 0)
            });

            // @ts-ignore
            $('#year_dropdown').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
            });
        });
    }

    /**
     * 지정휴무일 리스트 조회
     */
    async getOrganizationDayOffs() : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.day_offs ) {
                throw new Error("지정 휴무일 조회 중 오류 발생");
            }

            this.day_offs.splice(0, this.day_offs.length);
            this.day_offs = this.day_offs.concat(response.data.data.day_offs);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "지정 휴무일 조회 중 오류 발생", ['확인']);
        }

    }

    /**
     * 정렬
     */
    sort(sort_type : organization_enum.ORGANIZATION_OFF_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_dir = SORT_TYPE.ASC;
            return;
        }

        this.sort_dir = (this.sort_dir == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 드롭다운 바깥쪽 클릭
     */
    dropdownOutsideClick() : void {
        this.is_year_open = false;
    }

    /**
     * 휴가 날짜 텍스트 반환
     */
    getDateText(off : any) : string {

        const vacation_start = off.start_date;
        const vacation_end = off.end_date;

        if( vacation_start == vacation_end ) {
            const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
            return `${this.hodu_date_to_format_string(target_start, 'MM월 DD일')} ${this.getDayOfWeekByDate(target_start)}`;
        }

        const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
        const target_end = [vacation_end.substring(0,4), vacation_end.substring(4,6), vacation_end.substring(6,8)].join('-');

        return `${this.hodu_date_to_format_string(target_start, 'MM월 DD일')} ${this.getDayOfWeekByDate(target_start)} ~ ${this.hodu_date_to_format_string(target_end, 'MM월 DD일')} ${this.getDayOfWeekByDate(target_end)}`;
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    } 

    /**
     * 휴무일 추가
     */
    add() : void {
        this.doSetOrganizationOffAddModalInfo?.({ 
            show_modal : true, 
            is_create : true, 
            off_id : 0, 
            callback : this.getOrganizationDayOffs
        });
    }

    /**
     * 상세
     */
    detail(off) : void {
        this.doSetOrganizationOffAddModalInfo?.({ 
            show_modal : true, 
            is_create : false, 
            off_id : off.off_id, 
            callback : this.getOrganizationDayOffs
        });
    }

    /**
     * 삭제
     */
    deleteOff(off) : void {
        this.hodu_show_dialog('cancel', '정말로 해당 휴무일을 삭제 하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off/${off.off_id}`, API_METHOD.DELETE);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("휴무일 삭제 중 오류 발생");
                    }
                    
                    this.getOrganizationDayOffs();

                } catch(e) {
                    this.hodu_show_dialog('cancel', '휴무일 삭제 중 오류 발생', ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ]);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_off_setting_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#year_dropdown').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
