
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationVacationTypeModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_vacation_type_modal_info   !: organization_modal_interface.OrganizationVacationTypeModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationTypeModalInfo  ?: (params : organization_modal_interface.OrganizationVacationTypeModalInfo) => void;

    name : string = "";
    day : string = "0";
    amPm : string = "AM";
    pay : string = "Y";

    mounted() : void {
        if( this.organization_vacation_type_modal_info.is_create == false ) {
            this.name = this.organization_vacation_type_modal_info.title ? this.organization_vacation_type_modal_info.title : '';
            this.day = this.organization_vacation_type_modal_info.day ? String(this.organization_vacation_type_modal_info.day) : '1';
            this.pay = this.organization_vacation_type_modal_info.pay == false ? 'N' : 'Y';
        }
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationVacationTypeModalInfo?.({
            show_modal : false, 
            is_create : false
        })
    }

    /**
     * 체크
     */
    validationCheck() : boolean {
        
        if( this.name == null || this.name.trim().length < 1 ) {
            alert("휴가 종류 이름을 입력해주세요");
            $('#vacation_type_name').focus();
            return false;
        }
        
        if( this.day == null || String(this.day).trim().length < 1 ) {
            alert("휴가 일수를 입력해주세요");
            $('#vacation_type_daycount').focus();
            return false;
        }

        try {
            const n = Number(this.day);

            if( 0 > n ) {
                alert("휴가 일수에는 양수만 입력해주세요");
                $('#vacation_type_daycount').focus();
                return false;
            }

            if( 99.99 < n ) {
                alert("휴가 일수는 최대 99.99일 까지입니다");
                $('#vacation_type_daycount').focus();
                return false;
            }
            
            if( n > 1 && (n - Math.floor(n)) > 0 ) {
                alert("1일 이상의 휴가에는 소수점을 쓸수 없습니다");
                $('#vacation_type_daycount').focus();
                return false;
            }

        } catch(e) {
            alert("휴가 일수에는 숫자만 입력해주세요");
            $('#vacation_type_daycount').focus();
            return false;
        }
        
        return true;
    }

    /**
     * 휴가 종류 저장
     */
    async save() : Promise<void> {

        if( this.validationCheck() == false ) return;

        try {

            this.name = this.name.trim();
            this.day = String(this.day).trim();

            const contents = (this.day == '0.5') ? { "amPm" : this.amPm } : {};

            if( this.organization_vacation_type_modal_info.is_create == true ) {
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype`, API_METHOD.POST, {
                    "vacation_type_name" : this.name, 
                    "vacation_type_daycount" : Number(this.day), 
                    "vacation_dedution_yn" : this.pay == 'Y', 
                    "contents" : contents, 
                    "audit_create_id" : this.user_id
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴가타입 생성 중 오류 발생");
                }

            }

            else {

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype/${this.organization_vacation_type_modal_info.vacation_type_id}`, API_METHOD.PUT, {
                    "vacation_type_name" : this.name, 
                    "vacation_type_daycount" : Number(this.day), 
                    "vacation_dedution_yn" : this.pay == 'Y', 
                    "contents" : contents, 
                    "audit_create_id" : this.user_id
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴가타입 생성 중 오류 발생");
                }

            }
            
            this.organization_vacation_type_modal_info.callback?.();
            this.close();
        } catch(e) {
            alert('휴가종류 설정 중 오류 발생');
        }

    }

}
