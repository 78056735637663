
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');

import HospitalDoctorList     from '@/components/hodu_d/HospitalDoctorList.vue'
import HospitalDepartmentList from '@/components/hodu_d/HospitalDepartmentList.vue'

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HospitalDoctorList, HospitalDepartmentList
    },
}) 
export default class DoctorAndDepartmentList extends Mixins(VueHoduCommon) {

    /**
     * @HoduDocInfo.State
     */
    @HoduDocInfo.State doctor_and_department_tab !: "DOCTOR" | "DEPARTMENT";

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetDoctorAndDepartmentTab ?: (parms : "DOCTOR" | "DEPARTMENT") => void;

    event_bus : Vue = new Vue();

    mounted() : void {
        
    }

    /**
     * 의사 상세 페이지로 이동
     */
    moveDoctorDetail() : void {
        this.hodu_router_push(`/hospital/${new Date().getTime()}/doctor/detail`);
    }

    /**
     * 탭 변경
     */
    setPatientTab(tab : "DOCTOR" | "DEPARTMENT") : void {
        if( this.doSetDoctorAndDepartmentTab ) { 
            this.doSetDoctorAndDepartmentTab(tab);
            this.hodu_router_push(`/hospital/${new Date().getTime()}/${tab.toLowerCase()}`); 
        }
    }

    /**
     * 최상단 클래스 탭에 따라서 소문자로 반환
     */
    getTopClass() : string {
        return this.doctor_and_department_tab.toLowerCase();
    }

    /**
     * 상단 타이틀 반환
     */
    getTitle() : string {
        switch( this.doctor_and_department_tab ) {
            case "DOCTOR": return "의사";
            case "DEPARTMENT" : return "진료과";
            default : return ''; 
        }
    }

    /**
     * 진료과 멀티 삭제 버튼
     */
    deleteDepartments() : void {
        this.event_bus.$emit('deleteDepartments');
    }

}
