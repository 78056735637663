
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, getBrowserInfo, getOSInfo } from '@/mixin/VueHoduCommon';

declare let Kakao : any;
import '@/assets/js/kakao';
import { AxiosResponse } from 'axios';

@Component({
    components: {
        
    },
})
export default class LoginApp extends Mixins(VueHoduCommon) {

    login_type : string = "";

    async mounted() : Promise<void> {
        if( !Kakao.isInitialized() ){ await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
        await this.appleLoginEventInit();

        window['chrome'].webview.addEventListener('message', event => {

            const login_type = event.data;

            if( login_type == 'APPLE' ) {
                this.$nextTick(() => {
                    this.appleLogin();
                    $('#appleid-signin').trigger('click');
                });
            }
            else if( login_type == 'KAKAO' ) {
                this.$nextTick(() => {
                    this.kakaoLogin();
                });
            }
            else if( login_type == 'GOOGLE' ) {
                this.$nextTick(() => {
                    this.googleLogin();
                });
            }

        });
    }

    async kakaoLogin() : Promise<void> {

        try {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].isKakaoLogin();
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.isKakaoLogin();
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        this.login_type = 'KAKAOTALK';
        

        try {

            const access_token = Kakao.Auth.getAccessToken();

            /**
             * access_token 있다면
             */
            if( access_token ) {
                
                try {

                    const response : AxiosResponse = await this.hodu_api_call(`/api/v1/auth/login/platform/KAKAOTALK`, API_METHOD.POST, {
                        kakaotalk_access_token : access_token
                    });

                    this.processResponse(response);

                } catch(e) {
                    await this.kakaoLoginForm();
                }
                
            }

            /**
            * access_token 없다면
            */
            else {
                await this.kakaoLoginForm();
            }

        } catch(e) {
            alert("카카오톡 계정 로그인 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }
    
    async kakaoLoginForm() {
        await Kakao.Auth.loginForm({
            success: async(authObj) => {

                try {
                    const response : AxiosResponse = await this.hodu_api_call(`/api/v1/auth/login/platform/KAKAOTALK`, API_METHOD.POST, {
                        kakaotalk_access_token : authObj.access_token
                    });

                    this.processResponse(response);
                } catch(e) {
                    alert("카카오톡 계정 로그인 중 오류 발생");
                    this.hodu_error_process(e, false, false, true);
                }

            },
            fail: async(e) => {
                alert("카카오톡 계정 로그인 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        });
    }

    googleLogin() : void {

        try {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].isNotKakaoLogin();
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.isNotKakaoLogin();
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        this.login_type = 'GOOGLE';

        window['gapi'].load('auth2', async() => {

            this.webviewLog(`1:gapi load auth2`);
            
            const auth2 = window['gapi'].auth2.init({
                client_id: '48808503160-k10pbn6thi0ko0easfuid8e4lq4afta9.apps.googleusercontent.com',
            });

            console.log(auth2);

            this.webviewLog(`2: auth2 init`);
            
            auth2.grantOfflineAccess({ scope: 'profile email', prompt: 'select_account' })
                .then(async(result) => {

                    this.webviewLog(`3: auth2.grantOfflineAccess - ${result ? JSON.stringify(result) : ''}`);

                    console.log(result);
                    const access_code = result.code;

                    try {

                        this.webviewLog(`4: access_code - ${access_code}`);

                        const response : AxiosResponse = await this.hodu_api_call(`/api/v1/auth/login/platform/GOOGLE`, API_METHOD.POST, {
                            google_code : access_code
                        });

                        this.processResponse(response);

                    } catch(e) {
                        alert("구글 계정 로그인 중 오류 발생");
                        this.hodu_error_process(e, false, false, true);
                    }

                })
                .catch((e) => {
                    // alert("구글 계정 로그인 중 오류 발생");
                    // this.hodu_error_process(e, false, false, true);

                    if( e.message ) {
                        this.webviewLog(`error: auth2.grantOfflineAccess - ${e.message}`);
                    }
                    else {
                        this.webviewLog(`error: auth2.grantOfflineAccess - ${JSON.stringify(e)}`);
                    }
                });
            });
    }

    appleLogin() {
        try {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].isNotKakaoLogin();
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.isNotKakaoLogin();
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 애플 계정 로그인 관련 이벤트
     */
     async appleLoginEventInit() : Promise<void> {

        this.login_type = 'APPLE';
        
        // meta 태그 redirect-uri 변경
        document.getElementById('appleid-signin-redirect-uri')?.setAttribute('content', `${location.protocol}//${location.hostname}/api/v1/apple`);

        document.addEventListener('AppleIDSignInOnSuccess', async(data) => {
            console.log(data);

            if( !data || !data['detail'] || !data['detail']['authorization'] ) {
                alert("애플 계정 로그인 중 오류 발생");
                return;
            }

            const code = data['detail']['authorization']['code'];
            const id_token = data['detail']['authorization']['id_token'];
            const state = data['detail']['authorization']['state'];

            try {

                this.login_type = 'APPLE';

                const response : AxiosResponse = await this.hodu_api_call(`/api/v1/auth/login/platform/APPLE`, API_METHOD.POST, {
                    sns_id : id_token,
                    apple_access_token : code
                });

                this.processResponse(response);

            } catch(e) {
                alert("애플 계정 로그인 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
            
        });

        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            console.log(error);

            if( error && (error as any).detail && (error as any).detail.error ) {

                if( (error as any).detail.error == "popup_closed_by_user" || (error as any).detail.error == "user_cancelled_authorize" ) {
                    return;
                } 

            }

            alert("애플 계정 로그인 중 오류 발생");
        });
    }

    processResponse(response : AxiosResponse) {
        try {

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.id || response.data.data.id.length < 1  ) {
                throw new Error("로그인 실패");
            }

            const sns_id = response.data.data.id;
            const nickname = response.data.data.nickname;
            const profile_image = response.data.data.profile_image;

            // window?.opener?.postMessage(JSON.stringify({
            //     id : sns_id,
            //     login_type : this.login_type
            // }));

            const message = JSON.stringify({
                id : sns_id,
                nickname : nickname,
                profile_image : profile_image,
                login_type : this.login_type
            });

            // console.log(`[${this.login_type}]` + " window.opener.postMessage : " + sns_id);

            const userAgent = navigator.userAgent.toLowerCase();

            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].showMessage(message);
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.postMessage(message);
            } else { // 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
                // window.opener.postMessage(message);
                window['chrome'].webview.postMessage(message);
            }

        } catch(e) {
            throw e;
        }
    } 

    webviewLog(log : string) {

        try {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('android') !== -1) {
                window['WebViewBridge'].log(log);
            } else if ( userAgent.indexOf('ios') !== -1 ) {
                window['webkit'].messageHandlers.webViewMessageHandler.log(log);
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

}
