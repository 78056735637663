
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object } from '@/model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class PatientSendMessageModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State patient_send_message_modal_info !: hodu_doc_modal_info.PatientSendMessageModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetPatientSendMessageModalInfo ?: (params : hodu_doc_modal_info.PatientSendMessageModalInfo) => void;

    template_list : any[] = [];

    send_text : string = "";

    mounted() : void {
        this.getMessageTemplate();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const modal_height = $('#patient_send_message_modal').outerHeight();
        const modal_title_height = $('#patient_send_message_modal .titleBox').outerHeight();
        const modal_button_height = $('#patient_send_message_modal .btns').outerHeight();

        // @ts-ignore
        $('#send_target_patient_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight :  (modal_height ? modal_height : 0) - (modal_title_height ? modal_title_height : 0) - (modal_button_height ? modal_button_height : 0),
        });

    }

    /**
     * 메시지 템플릿 조회
     */
    getMessageTemplate() : void {
        this.hodu_api_call(`api/v1/message_template/GROUP/${this.scope_group_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.template_list = this.template_list.concat(response.data.data.list);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 메시지 보내기
     */
    async sendMessage() : Promise<void> {
        
        const checked_patient : hodu_doc_object.patient_info[] = this.patient_send_message_modal_info.selected_patients.filter(item => item.checked == true);
        const users : number[] = [];
        for( const patient of checked_patient ) { users.push(patient.user_id); }

        let req_data = {
            users: users,
            group_id: this.scope_group_id,
            team_id: 0,
            group_team_name: this.scope_group_team_option.group_team_name,
            message_content: this.send_text
        }
        
        const scope = OWNER_TYPE.GROUP;
        const scope_id = this.scope_group_id;

        await this.hodu_api_call(`api/v1/message_template/${scope}/${scope_id}/send`, API_METHOD.POST, req_data)
            .then(async(response) => {
                this.hodu_show_dialog("success", "성공적으로 메세지 발송하였습니다", ['확인']);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            }).finally(() => {
                this.closeModal();
            });

    }

    /**
     * 메세지 템플릿 선택
     */
    selectMessageTemplate(value : string) : void {
        this.send_text = value;
    } 

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetPatientSendMessageModalInfo == null ) { return; }

        this.doSetPatientSendMessageModalInfo({ show_modal : false, selected_patients : [] });
    }

}
