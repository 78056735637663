
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

@Component({
    components: {
        
    },
})
export default class SupplyManagementDetailModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State supply_management_detail_modal_info !: hodu_home_modal_info.SupplyManagementDetailModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetSupplyManagementDetailModalInfo ?: (params : hodu_home_modal_info.SupplyManagementDetailModalInfo) => void;

    management : any = null;

    default_image = require("@/assets/images/contents/hoduhome_car_camera.png");

    mounted() : void {
        this.getHomeSupplyManagement();
    }

    /**
     * 비품 관리 이력 조회
     */
    async getHomeSupplyManagement() : Promise<void> {
        try {
            const uid = this.supply_management_detail_modal_info.uid;
            const seq = this.supply_management_detail_modal_info.seq;

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${uid}/management/${seq}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.management ) {
                throw new Error("비품 관리 이력 조회 중 오류 발생");
            }

            this.management = JSON.parse(JSON.stringify(response.data.data.management));

        } catch(e) {
            alert("비품 관리 이력 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    /**
     * 장비 관리 이력 삭제
     */
    async deleteHomeSupplyManagement() : Promise<void> {
        try {

            const uid = this.supply_management_detail_modal_info.uid;
            const seq = this.supply_management_detail_modal_info.seq; 

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${uid}/management/${seq}`, API_METHOD.DELETE);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("비품 관리 이력 삭제 중 오류 발생");
            }

            this.close();
            
        } catch(e) {
            alert("비품 관리 이력 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.getHomeSupplyManagement();
        }
    }

    /**
     * 이미지 클릭
     */
    imageClick() : void {
        if( this.management && this.management.supply && this.management.supply.supply_data && this.management.supply.supply_data.image ) {
            window.open(`app_images/${this.management.supply.supply_data.image.url}`, '_blank');
        }
    }

    /**
     * 재고수 반환
     */
    getPrevStock() : number {

        let filter = this.management.managements;

        let index = -1;
        for( const management of filter ) {
            if( management.uid == this.management.uid && management.seq == this.management.seq ) index = filter.indexOf(management) + 1;
        }

        if( index < 0 || index >= filter.length ) {
            return 0; 
        }

        let stock : number = 0;
        let array_count : number = filter.length;

        for( let i = (array_count - 1); i >= index; i-- ) {
            const management = filter[i]
            if ( management.management_data.type == 'inbound' ) stock += management.management_data.count;
            if ( management.management_data.type == 'outbound' ) stock -= management.management_data.count;
        }

        return stock;
    }

    /**
     * 재고수 반환
     */
    getStock() : number {

        let filter = this.management.managements;

        let index = -1;
        for( const management of filter ) {
            if( management.uid == this.management.uid && management.seq == this.management.seq ) index = filter.indexOf(management);
        }

        if( index < 0 ) {
            return 0; 
        }

        let stock : number = 0;
        let array_count : number = filter.length;

        for( let i = (array_count - 1); i >= index; i-- ) {
            const management = filter[i]
            if ( management.management_data.type == 'inbound' ) stock += management.management_data.count;
            if ( management.management_data.type == 'outbound' ) stock -= management.management_data.count;
        }

        return stock;
    }

    /**
     * 수정
     */
    modify() : void {
        const uid = this.supply_management_detail_modal_info.uid;
        const seq = this.supply_management_detail_modal_info.seq; 
        this.hodu_router_push(`/home/supply/${uid}/management/modify/${seq}`, () => this.close());
    }

    /**
     * 삭제
     */
    remove() : void {
        if( confirm("정말 비품 관리 이력을 삭제 하시겠습니까?") == false ) return;
        this.deleteHomeSupplyManagement();
    }

    /**
     * 닫기
     */
    close() : void {
        this.supply_management_detail_modal_info.callback?.();
        this.doSetSupplyManagementDetailModalInfo?.({
            show_modal : false,
            uid : "",
            seq : 0,
        })
    }

}
