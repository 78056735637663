
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { t_event, t_event_vote } from '@/model/event';
import { t_user, t_vote_item, t_event_attend } from '@/model/osm';

import moment from 'moment';

@Component({

}) export default class EventVoteResult extends Mixins(VueHoduCommon) {
    
    /**
     * EventInfo.State
     */
    @EventInfo.State event             !: t_event; // 일정 정보
    @EventInfo.State vote_modify_index !: number;  // 선택된 투표 index

    /**
     * ModalInfo.State
     */
    @ModalInfo.State show_event_attend_result !: boolean; // 참석여부인지의 여부

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventVoteResult   ?: any; // Modal On / Off
    @ModalInfo.Action doSetShowEventAttendResult ?: any; // Modal On / Off

    is_attendable        : boolean            = false; // 참석 / 투표 참여 가능 여부
    audit_user_id        : number             = 0;     // 투표 최종 수정 한 사람 아이디
    audit_user_name      : string             = "";    // 투표 최종 수정 한 사람 이름
    vote_list            : t_event_vote[]     = [];    // 투표 정보
    voted_count_list     : number[]           = [];    // 투표 참여 카운트
    
    attendable_user_list : t_user[]           = [];    // 참석 가능 유저 리스트
    attend_user_list     : t_user[]           = [];    // 참여자 리스트
    absentee_user_list   : t_user[]           = [];    // 불참자 리스트
    vote_result_list     : t_vote_item[]      = [];    // 투표 참여 결과 리스트
    my_vote_result       : number[][]         = [];    // 사용자의 투표 참여 결과

    end_date_text : string = ""; // 투표 종료일 텍스트
    audit_user_image_url : string = require('@/assets/images/contents/im_photoB.gif');
    vote_count : number = 0; // 투표 참여 한 사람 수

    view_vote_all_status : boolean = false;
    status_list_type     : string  = "ALL"; // ALL - 전체, ATTEND - 참여자, ABSEENTEE - 불참자

    view_index_vote_status : boolean = false;
    seleted_index : number = 0;
    seleted_user_list : t_user[] = [];

    async mounted() : Promise<void> {

        // 투표로 들어 온 경우
        if( this.show_event_attend_result == false ) {
            await this.selectVote();
            await this.getAllVotableUserAndVotedItem();
        }

        // 참석여부로 들어 온 경우
        else {
            await this.getAttend();
        }

        await this.makeData();

        // @ts-ignore - 투표 결과 리스트 스크롤
        $('.voteDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside'
        })

        // @ts-ignore - 투표 현황 리스트 스크롤
        $('.whoVotedDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside'
        })
    }

    /**
     * 투표 조회
     */
    async selectVote() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/vote/${this.event.event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                if( vue.vote_list == null ) {
                    vue.vote_list = [];
                }

                // 투표 데이터 초기화
                vue.vote_list.splice(0, vue.vote_list.length);
                vue.vote_list = vue.vote_list.concat(response.data.data.vote_data.vote);

                vue.is_attendable = response.data.data.attend_permission;
                vue.audit_user_id = response.data.data.vote_data.audit_user_id;
                
                // 투표 아이템 개수만큼 항목 투표 카운트에 0을 집어넣는다
                vue.voted_count_list.splice(0, vue.voted_count_list.length);
                const vote_item_length : number = vue.vote_list[vue.vote_modify_index].items.length;
                for( let i = 0; i < vote_item_length; i++ ) {
                    vue.voted_count_list.push(0);
                }
                
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 투표 참여 데이터 조회
     */
    async getAllVotableUserAndVotedItem() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/vote/stat/${this.event.event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                // 참여가능한 유저 목록 & 참여자 목록 & 불참자 목록 초기화
                vue.attendable_user_list.splice(0, vue.attendable_user_list.length);
                vue.attend_user_list.splice(0, vue.attend_user_list.length);
                vue.absentee_user_list.splice(0, vue.absentee_user_list.length);

                vue.attendable_user_list = vue.attendable_user_list.concat(JSON.parse(JSON.stringify(response.data.data.users)));
                vue.absentee_user_list   = vue.absentee_user_list.concat(JSON.parse(JSON.stringify(response.data.data.users)));

                // 투표 항목 데이터 초기화
                vue.vote_result_list.splice(0, vue.vote_result_list.length);
                vue.vote_count = 0;

                if( vue.my_vote_result != null ) {
                    vue.my_vote_result.splice(0, vue.my_vote_result.length);
                }

                // 어떤 투표 항목에 투표 했는지 계산
                const vote_items_length : number = response.data.data.vote_items.length;
                for( let i = 0; i < vote_items_length; i++ ){
                    const vote_item_obj : t_vote_item = response.data.data.vote_items[i];
                    vue.vote_result_list.push(vote_item_obj);

                    try {
                        let vote_user_id : number = vote_item_obj.user_id;

                        // 사용자 본인의 투표아이템 데이터라면 따로 저장
                        if( vue.user_id == vote_item_obj.user_id ) {
                            vue.my_vote_result = vue.my_vote_result.concat(vote_item_obj.vote);
                        }

                        // 투표된 항목 + 1씩
                        const voted_list_size : number = vote_item_obj.vote[vue.vote_modify_index].length;
                        for( let j = 0; j < voted_list_size; j++ ){
                            let voted_seq   : number = vote_item_obj.vote[vue.vote_modify_index][j];
                            let voted_count : number = vue.voted_count_list[voted_seq];
                            vue.voted_count_list.splice(voted_seq, 1, (voted_count + 1));
                        }

                        // 해당 투표에 투표 했다면 참여자 + 1
                        if( voted_list_size != 0 ){
                            vue.vote_count++;

                            // 불참자 리스트에서 제거
                            let absentee_user_list_size = vue.absentee_user_list.length;
                            for( let j = 0; j < absentee_user_list_size; j++ ){
                                if( vue.absentee_user_list[j].user_id == vote_user_id ){
                                    vue.attend_user_list.push(JSON.parse(JSON.stringify(vue.absentee_user_list[j])));
                                    vue.absentee_user_list.splice(j, 1);
                                    break;
                                }
                            }
                        }
                        
                    } catch(e) {
                        // 뽑는 과정에서 IndexOut이 난 경우는 해당 투표에 투표한게 없는 것이므로 넘어간다.
                    }
                }

            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 데이터 만들기
     */
    async makeData() : Promise<void> {
        // 투표 종료일을 텍스트화 시킨다 ex) 2019.11.30 토요일 09:00 PM
        const moment_date = moment(this.vote_list[this.vote_modify_index].end_date);
        this.end_date_text = `${ moment_date.format('YYYY.MM.DD') } ${ this.getDayOfWeek(new Date(this.vote_list[this.vote_modify_index].end_date)) }요일 ${ new Date(this.vote_list[this.vote_modify_index].end_date).getHours() < 12 ? '오전' : '오후' } ${ moment_date.format('hh:mm') }`;

        // 투표 작성자 정보를 얻는다
        const user_count : number = this.attendable_user_list.length; 
        for( let i = 0; i < user_count; i++ ) {
            if( this.attendable_user_list[i].user_id == this.audit_user_id ) {
                this.audit_user_name = this.attendable_user_list[i].user_name;
            }
        }

        // 유저 이미지 url을 얻는다
        this.audit_user_image_url = `/app_images/profile/user/${Math.floor(this.audit_user_id / 10000)}/${this.audit_user_id}.jpg`;
    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * 날짜 타입의 투표 항목을 포맷변환해서 반환 ex) 2019-11-08 금요일
     */
    getDateItemText(date : Date | string) : string {
        return `${ moment(date).format('YYYY-MM-DD') } ${ this.getDayOfWeek(new Date(date)) }요일`;
    }

    /**
     * 종료된 투표인지 체크 
     * true : 진행 중
     * false : 종료
     */
    checkVoteEndDate() : boolean {
        // null 체크
        if( this.vote_list == null || this.vote_list[this.vote_modify_index] == null ) {
            return false;
        }

        // 종료된 투표인지 체크
        const current_date : Date = new Date();
        if( this.vote_list[this.vote_modify_index].is_end == true || current_date.getTime() >= new Date(this.vote_list[this.vote_modify_index].end_date).getTime() ) {
            return false;
        }

        return true;
    }

    /**
     * 체크가 변경된 경우 -> 데이터 변경
     */
    checkChanged(event, index : number) : void {
        
        if( this.my_vote_result[this.vote_modify_index] == null ) {
            this.my_vote_result[this.vote_modify_index] = [];
        }

        // 투표가 마감 된 경우 || 투표가 시간이 지나 종료 된 경우
        if( this.vote_list[this.vote_modify_index].is_end == true || new Date(this.vote_list[this.vote_modify_index].end_date).getTime() <= new Date().getTime() ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // event.target.checked == false인 경우 (체크 취소하는 경우)
        if( event.target.checked == false ) {
            
            this.my_vote_result[this.vote_modify_index].splice(this.my_vote_result[this.vote_modify_index].indexOf(index), 1);
            const voted_count : number = this.voted_count_list[index];
            this.voted_count_list.splice(index, 1, voted_count - 1);
            
            if( this.my_vote_result[this.vote_modify_index].length < 1 ) {
                this.vote_count--;

                // 참여자 리스트에서 제거 불참자 리스트에 추가
                const attend_user_list_length : number = this.attend_user_list.length;
                for( let i = 0; i < attend_user_list_length; i++ ) {
                    if( this.attend_user_list[i] == null ) {
                        break;
                    } 

                    if( this.attend_user_list[i].user_id == this.user_id ) {
                        this.absentee_user_list.push(JSON.parse(JSON.stringify(this.attend_user_list[i])));
                        this.attend_user_list.splice(i, 1);
                    }
                }
            }
        }

        // event.target.checked == true인 경우 (체크하는 경우)
        else {
            // 투표한 값이 없었다면 추가
            if( this.my_vote_result[this.vote_modify_index].length < 1 ) {
                this.vote_count++;

                // 불참자 리스트에서 제거 참여자 리스트에 추가
                const absentee_user_list_length : number = this.absentee_user_list.length;
                for( let i = 0; i < absentee_user_list_length; i++ ) {
                    if( this.absentee_user_list[i] == null ) {
                        break;
                    } 

                    if( this.absentee_user_list[i].user_id == this.user_id ) {
                        this.attend_user_list.push(JSON.parse(JSON.stringify(this.absentee_user_list[i])));
                        this.absentee_user_list.splice(i, 1);
                    }
                }
            }

            // 중복 가능인경우 -> 추가한다
            if( this.vote_list[this.vote_modify_index].multiple == true ) {
                this.my_vote_result[this.vote_modify_index].push(index);
            }
            
            // 중복 불가능인경우 -> 기존에 있는 추가된 값을 버리고 추가
            else {
                // 추가 되어 있던 값 voted_count에서 --
                const my_vote_count : number = this.my_vote_result[this.vote_modify_index].length;
                for( let i = 0; i < my_vote_count; i++ ) {
                    const my_voted_count : number = this.voted_count_list[this.my_vote_result[this.vote_modify_index][i]];
                    this.voted_count_list.splice(this.my_vote_result[this.vote_modify_index][i], 1, my_voted_count - 1);
                    this.my_vote_result[this.vote_modify_index].splice(i, 1);
                }
                this.my_vote_result[this.vote_modify_index].push(index);
            }

            // 선택한 값 count 증가
            const voted_count : number = this.voted_count_list[index];
            this.voted_count_list.splice(index, 1, voted_count + 1);
        }
    }

    /**
     * 유저 이미지 에러
     */
    userImageError() : void {
        this.audit_user_image_url = require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * 리스트 유저 이미지 에러
     */
    userImageErrorList(event) : void {
        $(event.target).parent().find('.fPic').css("background-image", `url(${ require('@/assets/images/contents/im_photoB.gif') })`);
    }

    /**
     * 투표 모달 끄기
     */
    cancelVoteResultModal() : void {
        this.doSetShowEventVoteResult(false);
        this.doSetShowEventAttendResult(false);
    }

    /**
     * 투표 마감
     */
    async voteTerminate() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/vote/${ this.event.event_id }/${ this.vote_modify_index }/close`, API_METHOD.PUT)
            .then(async(response) => {
                await vue.selectVote();
                await vue.getAllVotableUserAndVotedItem();
                await vue.makeData();
                await window["getEvent"]();
            })
            .catch(async(e) => {
                await vue.selectVote();
                await vue.getAllVotableUserAndVotedItem();
                await vue.makeData();
            });
    }

    /**
     * 참석&투표결과 변경값 저장
     */
    async saveVoteResult() : Promise<void> {
        // 투표 결과 저장 일 때
        if( this.show_event_attend_result == false ) {
            await this.addVoting();
        }

        // 참석 결과 저장 일 때
        else {
            await this.saveAttendResult();
        }
    }

    /**
     * 투표 항목 선택 & 선택 수정
     */
    async addVoting() : Promise<void> {
        const vue = this;

        if( this.my_vote_result[this.vote_modify_index] == null ) {
            this.my_vote_result[this.vote_modify_index] = [];    
        }

        // 비어있는 투표들 채우기
        const vote_length : number = this.vote_list.length;
        for( let i = 0; i < vote_length; i++ ) {
            if( this.my_vote_result[i] == null ) {
                this.my_vote_result[i] = [];  
            }
        }

        this.my_vote_result[this.vote_modify_index].sort();

        await this.hodu_api_call(`api/v1/vote/voting/${ this.event.event_id }`, API_METHOD.POST, { vote : this.my_vote_result })
            .then(async(response) => {
                console.log(response);
                await vue.selectVote();
                await vue.getAllVotableUserAndVotedItem();
                await vue.makeData();
                vue.doSetShowEventVoteResult(false);
                await window["getEvent"]();
            })
            .catch(async(e) => {
                await vue.selectVote();
                await vue.getAllVotableUserAndVotedItem();
                await vue.makeData();
            });
    }

    /**
     * 전체 투표현황 보기
     */
    viewAllVoteStatus() : void {
        
        // 익명 투표일때는 볼 수 없음
        if( this.vote_list != null && this.vote_list[this.vote_modify_index] != null && this.vote_list[this.vote_modify_index].secret ) {
            this.view_vote_all_status = false;
            return;
        }

        this.view_vote_all_status = true;
    }

    /**
     * 투표현황 탭변경 - 전체를 제외하고 0인곳은 탭 변경을 금지
     */
    voteStatusTabClick(tab : string) {
        if( tab == 'ATTEND' && this.attend_user_list.length < 1 ) {
            return;
        }

        if( tab == 'ABSEENTEE' && this.absentee_user_list.length < 1 ) {
            return;
        }

        this.status_list_type = tab;
    }

    /**
     * 투표현황 뒤로가기
     */
    voteStatusCancel() : void {
        this.status_list_type     = 'ALL';
        this.view_vote_all_status = false;
    }

    /**
     * 해당 유저가 투표에 참여 했는지 체크
     */
    userAttendCheck(check_user_id : number) : boolean {

        const attend_user_length : number = this.attend_user_list.length;
        for( let i = 0; i < attend_user_length; i++ ) {
            if( this.attend_user_list[i].user_id == check_user_id ) {
                return true;
            }
        }

        return false;
    }

    /**
     * 해당 항목 투표 상태 보기
     */
    viewVoteStatusByIndex(index : number) : void {

        // 익명 투표일때는 볼 수 없음
        if( this.vote_list != null && this.vote_list[this.vote_modify_index] != null && this.vote_list[this.vote_modify_index].secret ) {
            this.view_index_vote_status = false;
            return;
        }

        this.seleted_index = index;
        this.view_index_vote_status = true;

        // 해당 항목을 투표한 유저데이터 받아오기
        this.seleted_user_list.splice(0, this.seleted_user_list.length);
        const result_length : number = this.vote_result_list.length;
        for( let i = 0; i < result_length; i++ ) {
            // 해당 투표에 투표 했는지 검사
            if( this.vote_result_list[i].vote[this.vote_modify_index] == null ) {
                continue;
            }

            // 해당 투표 항목에 투표 했는지 검사
            if( this.vote_result_list[i].vote[this.vote_modify_index].indexOf(index) == -1 ) {
                continue;
            }

            // user_list에서 해당 유저 정보 찾아서 push
            const attendable_user_list_length : number = this.attendable_user_list.length;
            for( let j = 0; j < attendable_user_list_length; j++ ) {
                if( this.vote_result_list[i].user_id == this.attendable_user_list[j].user_id ) {
                    this.seleted_user_list.push(JSON.parse(JSON.stringify(this.attendable_user_list[j])));
                    break;
                }
            }
        }
    }

    /**
     * 항목 투표 상세 취소
     */
    voteIndexStatusCancel() : void {
        this.seleted_index = 0;
        this.view_index_vote_status = false;
    }

    /**
     * 참석 여부 데이터 조회
     */
    async getAttend() : Promise<void> {
        const vue = this;
        
        await this.hodu_api_call(`api/v1/attend/${this.event.event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                vue.is_attendable = response.data.data.attend_permission;
                vue.audit_user_id = vue.event.event_data.event_owner_id == null ? 0 : vue.event.event_data.event_owner_id;

                // 참석 데이터를 투표데이터로 가공
                vue.vote_list.push({
                    title : vue.event.event_data.title == null ? "" : vue.event.event_data.title,
                    items_type : 'T',
                    items : ['참석', '불참', '미정'],
                    end_date : vue.event.event_data.schedule_date.end == null ? new Date() : new Date(vue.event.event_data.schedule_date.end),
                    is_end : false,
                    ing_report : true,
                    multiple : false,
                    secret : false
                });

                // 참여가능한 유저 목록 & 참여자 목록 & 불참자 목록 초기화
                vue.attendable_user_list.splice(0, vue.attendable_user_list.length);
                vue.attend_user_list.splice(0, vue.attend_user_list.length);
                vue.absentee_user_list.splice(0, vue.absentee_user_list.length);

                vue.attendable_user_list = vue.attendable_user_list.concat(JSON.parse(JSON.stringify(response.data.data.users)));
                vue.absentee_user_list   = vue.absentee_user_list.concat(JSON.parse(JSON.stringify(response.data.data.users)));

                vue.vote_count = 0;
                vue.vote_result_list.splice(0, vue.vote_result_list.length);
                vue.voted_count_list.splice(0, vue.voted_count_list.length);
                for( let i = 0; i < 3; i++ ) vue.voted_count_list.push(0);

                // 참석, 불참, 미정 데이터 생성
                const attend_list : t_event_attend[] = response.data.data.attended_member;
                const attend_list_length : number = attend_list.length;
                for( let i = 0; i < attend_list_length; i++ ) {
                    
                    const user_vote : number[][] = [];

                    switch( attend_list[i].attend ) {
                        case 'Y':
                            user_vote.push([0]);
                            vue.voted_count_list.splice(0, 1, vue.voted_count_list[0] + 1);
                            vue.vote_count++
                            break;

                        case 'N':
                            user_vote.push([1]);
                            vue.voted_count_list.splice(1, 1, vue.voted_count_list[1] + 1);
                            vue.vote_count++
                            break;

                        case '-':
                            user_vote.push([2]);
                            vue.voted_count_list.splice(2, 1, vue.voted_count_list[2] + 1);
                            vue.vote_count++
                            break;
                    }

                    vue.vote_result_list.push({
                        event_id : vue.event.event_id == null ? '' : vue.event.event_id,
                        user_id : attend_list[i].user_id,
                        user_name : attend_list[i].user_name,
                        vote : user_vote,
                        audit_created : attend_list[i].audit_modified,
                        audit_modified : attend_list[i].audit_modified,
                        audit_deleted : null,
                        audit_delete_flag : false,
                        audit_user_id : attend_list[i].user_id
                    });

                    if( attend_list[i].user_id == vue.user_id ) {
                        vue.my_vote_result.splice(0, vue.my_vote_result.length);
                        vue.my_vote_result.push(user_vote[0]);
                    }

                    // 불참자 리스트에서 제거 
                    const absentee_user_list_length : number = vue.absentee_user_list.length;
                    for( let j = 0; j < absentee_user_list_length; j++ ) {
                        if( attend_list[i].user_id == vue.absentee_user_list[j].user_id ) {
                            vue.attend_user_list.push(JSON.parse(JSON.stringify(vue.absentee_user_list[j])))
                            vue.absentee_user_list.splice(j, 1);
                            break;
                        }
                    }
                }

            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });

            
    }

    /**
     * 참석여부 정보 저장
     */
    async saveAttendResult() : Promise<void> {
        const vue = this;
        
        // 참석여부 저장
        if( this.my_vote_result[this.vote_modify_index].length > 0 ) {

            const user_attend : string = this.my_vote_result[this.vote_modify_index][0] == 0 ? 'Y' :
                                         this.my_vote_result[this.vote_modify_index][0] == 1 ? 'N' : '-';

            await this.hodu_api_call(`api/v1/attend/${this.event.event_id}`, API_METHOD.POST, {
                event_id : this.event.event_id,
                user_id : this.user_id,
                user_name : this.user_name,
                attend : user_attend
            }).then(async(response) => {
                await this.getAttend();
                await this.makeData();
                vue.doSetShowEventAttendResult(false);
                await window["getEvent"]();
            }).catch(async(e) => {
                await this.getAttend();
                await this.makeData();
            });
        }

        // 참석 여부 취소
        else {
            await this.hodu_api_call(`api/v1/attend/${this.event.event_id}`, API_METHOD.DELETE)
                .then(async(response) => {
                    await this.getAttend();
                    await this.makeData();
                    vue.doSetShowEventAttendResult(false);
                    await window["getEvent"]();
                })
                .catch(async(e) => {
                    await this.getAttend();
                    await this.makeData();
                });
        }
    }

}
