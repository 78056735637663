import { ActionTree, MutationTree, GetterTree } from 'vuex';

export const namespaced = true;

export interface GalleryInfoState {
    tab : string;
    events : any[];
    files : any[];
    search_query : string;
    date : Date;
}

export const state : GalleryInfoState = {
    tab : "",
    events : [],
    files : [],
    search_query : "",
    date : new Date(),
};

// getters
export const getters: GetterTree<GalleryInfoState, any> = {

    getTab : (state) => {
        return state.tab;
    },

    getEvents : (state) => {
        return state.events;
    },
    
    getFiles : (state) => {
        return state.files;
    },

    getSearch : (state) => {
        return state.search_query;
    },

    getDate : (state) => {
        return state.date;
    },

};

// action
export const actions: ActionTree<GalleryInfoState, GalleryInfoState> = {

    doSetGalleryInfo: ({state, commit}, gallery_info : GalleryInfoState) => {
        console.log(`actions.doSetGalleryInfo ${JSON.stringify(gallery_info)}`);
        
        state.tab = gallery_info.tab ? gallery_info.tab : "imgs";

        // gallery_info.events null 값 금지
        state.events.splice(0, state.events.length);
        if( gallery_info.events != null ) {
            state.events = state.events.concat(gallery_info.events);
        }
        
        // gallery_info.files null 값 금지
        state.files.splice(0, state.files.length);
        if( gallery_info.files != null ) {
            state.files = state.files.concat(gallery_info.files);
        }

        state.search_query = gallery_info.search_query ? gallery_info.search_query : "";
        state.date = gallery_info.date ? new Date(gallery_info.date) : new Date();

        sessionStorage.setItem("gallery_info", JSON.stringify(state));
    },

    doSetTab: ({state, commit}, tab : string) => {
        console.log(`actions.doSetTab ${JSON.stringify(tab)}`);
        state.tab = tab ? tab : "imgs";
        sessionStorage.setItem("gallery_info", JSON.stringify(state));
    },

    doSetEvents: ({state, commit}, events : any[]) => {
        console.log(`actions.doSetFiles ${JSON.stringify(events)}`);

        state.events.splice(0, state.events.length);

        // events null 값 금지
        if( events != null ) {
            state.events = state.events.concat(events);
        }

        sessionStorage.setItem("gallery_info", JSON.stringify(state));
    },

    doSetFiles: ({state, commit}, files : any[]) => {
        console.log(`actions.doSetFiles ${JSON.stringify(files)}`);

        state.files.splice(0, state.files.length);

        // files null 값 금지
        if( files != null ) {
            state.files = state.files.concat(files);
        }

        sessionStorage.setItem("gallery_info", JSON.stringify(state));
    },

    doSetSearchQuery: ({state, commit}, search_query : string) => {
        console.log(`actions.doSetSearchQuery ${JSON.stringify(search_query)}`);
        state.search_query = search_query ? search_query : "";
        sessionStorage.setItem("gallery_info", JSON.stringify(state));
    },

    doSetDate: ({state, commit}, date : Date) => {
        console.log(`actions.doSetDate ${JSON.stringify(date)}`);
        state.date = date ? new Date(date) : new Date();
        sessionStorage.setItem("gallery_info", JSON.stringify(state));
    }

}