
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const WorkInfo = namespace('WorkInfo');

import { WorkStatusInfo } from '@/store/modules/WorkInfo';

import { t_work_status } from '@/model/osm';

@Component({

}) export default class EventWorkProcess extends Mixins(VueHoduCommon) {
    /**
     * @WorkInfo.State
     */
    @WorkInfo.State work_status_info !: WorkStatusInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventWorkProcess ?: any;

    work_data             : any                  = null;
    work_status_list      : t_work_status[]      = [];
    user_work_status      : t_work_status | null = null;
    user_work_status_temp : t_work_status | null = null;

    cancel_bg_open : boolean = false;
    complete_bg_open : boolean = false;

    async mounted() : Promise<void> {
        $('.taskModal').css('margin-top', window.innerHeight);

        // 업무 현황 조회
        await this.getWorkStatus();
    }

    /**
     * 업무 현황 조회
     */
    async getWorkStatus() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/status/${this.work_status_info.event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);
                vue.work_data = null;
                vue.work_data = response.data.data.list[0];
                vue.work_status_list.splice(0, vue.work_status_list.length);
                vue.work_status_list = vue.work_status_list.concat( vue.work_data.status_info ? vue.work_data.status_info : []);
                
                vue.user_work_status_temp = null;
                for( const work_status of vue.work_status_list ) {
                    if( work_status.user_id == vue.user_id ) {
                        vue.user_work_status = work_status;
                        vue.user_work_status_temp = JSON.parse(JSON.stringify(work_status));
                    }
                }

                // modal 가운데에 맞추기
                setTimeout(() => {
                    const modal_height : number | undefined = $('.taskModal').outerHeight();
                    $('.taskModal').css('margin-top', -( modal_height ? modal_height : 0 ) / 2);
                }, 100);

                // Event.vue 초기화 시키기위해 함수 사용
                await window['setEventWorkData'](vue.work_data.event_data.work, vue.work_status_list);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * index로 사용자의 해당 업무 상태를 반환
     */
    getUserStatusByIndex(index : number) : string {
        try {
            if( index < 0 || this.user_work_status == null || this.user_work_status.work_status_info.progress.length <= index ) {
                return "invalid";
            }

            return this.user_work_status.work_status_info.progress[index].status;
        } catch(e) {
            this.hodu_error_process(e, false, false);
            return "invalid";
        }
    }

    /**
     * index로 해당 업무의 progress % 텍스트 반환
     */
    getProgressByIndex(index : number) : string {
        try { 
            if( index < 0 || !this.work_data || this.work_status_list == null || this.work_status_list.length < 1 ) {
                return `0`;
            }

            let index_progress_count : number = 0;
            for( let work_status of this.work_status_list ) {
                
                // 체크식일땐 END 인것만 progress에 포함
                if( this.work_data.template_type == 'CHECK' && work_status.work_status_info.progress[index] != null && work_status.work_status_info.progress[index].status == 'END' ) {
                    index_progress_count++;
                }

                // 단계식일땐 START 혹은 마지막 업무단계인데 END인 것만 progress에 포함
                else if ( this.work_data.template_type == 'FLOW' && work_status.work_status_info.progress[index] != null && 
                          ( work_status.work_status_info.progress[index].status == 'START' || ( (index == this.work_data.template_info.content.length - 1) && work_status.work_status_info.progress[index].status == 'END') )  ) {
                    index_progress_count++;
                }
            }

            return `${ ( index_progress_count / (this.work_data.event_data.work.assignment_type == 'UNDEF' ? 1 : this.work_status_list.length) * 100 ).toFixed(10) }`;

        } catch(e) {
            this.hodu_error_process(e, false, false);
            return `0`;
        }
    }

    /**
     * 단계식 업무 클릭한 경우
     */
    changeFlowWorkStatus(event : any, index : number) : void {

        // index가 이상한 경우 원래대로 돌리고 return
        if( index < 0 || !this.work_data ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 선착순 업무가 아닌데 배정되지 않은 경우 return
        if( this.work_data.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 중단, 완료된 업무인 경우
        const status_code : string = this.work_data.event_data.work.work_status_code;
        if( status_code == 'CANCEL' || status_code == 'END' ) {
            event.target.checked = !event.target.checked;
            alert(`${ status_code == 'CANCEL' ? '중단' : '완료' }된 업무입니다`);
            return;
        }

        // 이미 누군가 진행중인 미정 업무라면
        if( this.work_data.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null && this.work_status_list.length > 0 ) {
            event.target.checked = !event.target.checked;
            alert('이미 할당 된 업무입니다');
            return;
        }

        // 선착순 단계 업무에서 아무도 배정되지 않은경우
        if( this.work_data.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null && this.work_status_list.length < 1 ) {
            this.user_work_status = {
                event_id : this.work_status_info.event_id,
                user_id : this.user_id,
                scope : this.work_status_info.scope,
                scope_id : this.work_status_info.scope_id,
                last_update_tag : '0',
                audit_modified : new Date(),
                audit_delete_flag : false,
                audit_user_id : this.user_id,
                work_status_info : {
                    is_end : false,
                    progress : []
                }
            };

            // 템플릿 정보 만큼 progress 생성
            const content_count : number = this.work_data.template_info.content.length; 
            for( let i = 0; i < content_count; i++ ) {
                this.user_work_status.work_status_info.progress.push({
                    seq : i,
                    status : 'WAIT'
                });
            }
            
            // 업무자 리스트에 추가
            this.work_status_list.push(this.user_work_status);
        }

        // 유저 업무 상태 정보가 없으면 return
        if( this.user_work_status == null || this.user_work_status.work_status_info.progress[index] == null ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 이미 선택된 곳이라면 return
        if( this.user_work_status.work_status_info.progress[index].status == 'START' || ( (index == this.work_data.template_info.content.length - 1) && this.user_work_status.work_status_info.progress[index].status == 'END') ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 이미 완료한 업무인 경우
        if( this.user_work_status.work_status_info.is_end == true ) {
            event.target.checked = !event.target.checked;
            alert("완료한 업무입니다");
            return;
        }
        
        const progress : any = JSON.parse(JSON.stringify(this.user_work_status.work_status_info.progress));

        const progress_count : number = progress.length;
        for( let i = 0; i < progress_count; i++ ) {
            const progress_fragment : any = JSON.parse(JSON.stringify(progress[i]));
            
            // 클릭한 단계의 이전 단계
            if( i < index ) {
                progress_fragment.status = 'END';
            } 
            
            // 클릭한 단계
            else if ( i == index ) {
                progress_fragment.status = 'START';
            } 
            
            // 클릭한 단계의 이후 단계
            else {
                progress_fragment.status = 'WAIT';
            }

            progress.splice(i, 1, progress_fragment);
        }

        this.user_work_status.work_status_info.progress.splice(0, this.user_work_status.work_status_info.progress.length);
        this.user_work_status.work_status_info.progress = this.user_work_status.work_status_info.progress.concat(progress);

        // 체크값 변경
        $('.modal-content ul > li .taskAb .stepCheck .input_check').each((each_index, item) => {
            if( this.user_work_status == null ) {
                return false;
            }

            if( this.user_work_status.work_status_info.progress[each_index].status == 'END' ) {
                (item as HTMLInputElement).checked = true;
            } else {
                (item as HTMLInputElement).checked = false;
            }
        })

        this.workStatusInsertOrUpdateAndDelete(event, index);
    }

    /**
     * 체크식 업무 클릭한 경우
     */
    changeCheckWorkStatus(event : any, index : number) : void {

        // 배정되지 않은 경우, index가 이상한 경우 원래대로 돌리고 return
        if( index < 0 || !this.work_data ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 선착순 업무가 아닌데 배정되지 않은 경우 return
        if( this.work_data.event_data.work.assignment_type != 'UNDEF' && this.user_work_status == null ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 중단, 완료된 업무인 경우
        const status_code : string = this.work_data.event_data.work.work_status_code;
        if( status_code == 'CANCEL' || status_code == 'END' ) {
            event.target.checked = !event.target.checked;
            alert(`${ status_code == 'CANCEL' ? '중단' : '완료' }된 업무입니다`);
            return;
        }

        // 선착순 업무지만 해당 체크가 다른 사람이 이미 체크 했다면 return
        // if( this.work_data.event_data.work.assignment_type == 'UNDEF' ) {
        //     for( let work_status of this.work_status_list ) {
        //         const progress_count : number = work_status.work_status_info.progress.length;

        //         if( work_status.user_id != this.user_id && work_status.work_status_info.progress[index].status == 'END' ) {
        //             event.target.checked = !event.target.checked;
        //             alert('이미 할당 된 업무입니다');
        //             return;
        //         }

        //     }
        // }

        // 선착순 업무에서 해당 체크가 배정되지 않은경우
        // if( this.work_data.event_data.work.assignment_type == 'UNDEF' && this.user_work_status == null ) {
        //     this.user_work_status = {
        //         event_id : this.work_status_info.event_id,
        //         user_id : this.user_id,
        //         scope : this.work_status_info.scope,
        //         scope_id : this.work_status_info.scope_id,
        //         last_update_tag : '0',
        //         audit_modified : new Date(),
        //         audit_delete_flag : false,
        //         audit_user_id : this.user_id,
        //         work_status_info : {
        //             is_end : false,
        //             progress : []
        //         }
        //     };

        //     // 템플릿 정보 만큼 progress 생성
        //     const content_count : number = this.work_data.template_info.content.length; 
        //     for( let i = 0; i < content_count; i++ ) {
        //         this.user_work_status.work_status_info.progress.push({
        //             seq : i,
        //             status : 'WAIT'
        //         });
        //     }
            
        //     // 업무자 리스트에 추가
        //     this.work_status_list.push(this.user_work_status);
        // }

        // 유저 업무 상태 정보가 없으면 return
        if( this.user_work_status == null || this.user_work_status.work_status_info.progress[index] == null ) {
            event.target.checked = !event.target.checked;
            return;
        }

        // 이미 완료한 업무인 경우
        if( this.user_work_status.work_status_info.is_end == true ) {
            event.target.checked = !event.target.checked;
            alert("완료한 업무입니다");
            return;
        }

        const progress_fragment : any = JSON.parse(JSON.stringify(this.user_work_status.work_status_info.progress[index]));
        progress_fragment.status = event.target.checked ? "END" : "WAIT";
        this.user_work_status.work_status_info.progress.splice(index, 1, progress_fragment);

        // 단일 선택 업무일때 체크를 했다면 다른 업무를 전부 WAIT 상태로 만든다
        if( progress_fragment.status == "END" && this.work_data.template_info.is_multi_select == false ) {
            const progress_count : number = this.user_work_status.work_status_info.progress.length;
            for( let i = 0; i < progress_count; i++ ) {
                if( i == index ) { continue; }
                const progress_frag : any = JSON.parse(JSON.stringify(this.user_work_status.work_status_info.progress[index]));
                progress_frag.status = "WAIT";
                this.user_work_status.work_status_info.progress.splice(i, 1, progress_frag);
            }
        }

        // 미정일때 체크를 전부 없앤다면 완전히 제거 한다
        // if( this.work_data.event_data.work.assignment_type == 'UNDEF' && event.target.checked == false ) {
        //     let flag : boolean = true;
            
        //     // 하나라도 WAIT가 아니라면 false로 만든다
        //     for( let progress of this.user_work_status ? this.user_work_status.work_status_info.progress : [] ) {
        //         flag = flag && progress.status == 'WAIT'
        //     }

        //     // 하나도 체크가 안되어있다면 user_status를 없앤다
        //     if( flag == true ) {
        //         this.user_work_status = null;

        //         for( let i = 0; i < this.work_status_list.length; i++ ) {
        //             if( this.work_status_list[i].user_id == this.user_id ) {
        //                 this.work_status_list.splice(i, 1);
        //                 break;
        //             }
        //         }
        //     }
        // }

        this.workStatusInsertOrUpdateAndDelete(event, index);
    }

    /**
     * 업무 수행 저장
     */
    workStatusInsertOrUpdateAndDelete(event, index : number) : void {
        // 업무 상태 삭제 : deleteWorkStatus (temp != null && status == null)
        if( this.user_work_status_temp != null && this.user_work_status == null ) {
            this.deleteWorkUserStatus();
        }

        // insertOrUpdate 
        else {
            this.insertOrUpdateWorkStatus(event, index);
        }
    }

    /**
     * 업무 상태 삭제
     */
    async deleteWorkUserStatus() : Promise<void> {
        const vue = this;

        let url : string = `api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/${this.work_status_info.event_id}/status/user`;
        await this.hodu_api_call(url, API_METHOD.DELETE)
            .then(async(response) => {
                console.log(response);
                await vue.getWorkStatus();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 업무 상태추가 또는 수정
     */
    async insertOrUpdateWorkStatus(event, index : number) : Promise<void> {
        const vue = this;

        if( this.user_work_status == null ) {
            return;
        }

        this.user_work_status.event_id = this.work_status_info.event_id;
        this.user_work_status.scope    = this.work_status_info.scope;
        this.user_work_status.scope_id = this.work_status_info.scope_id;
        this.user_work_status.user_id  = this.user_id;

        let url : string = `api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/${this.work_status_info.event_id}/status/user?select_seq=${index}`;
        await this.hodu_api_call(url, API_METHOD.PUT, this.user_work_status)
            .then(async(response) => {
                console.log(response);
                await vue.getWorkStatus();
            })
            .catch(async(e) => {
                event.target.checked = !event.target.checked;
                if( e.response && e.response.status == 406 ) {
                    vue.getWorkStatus();
                } 
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 체크 미정 업무에서 이미 할당된 업무인 경우
     */
    isUndefCheckSelectedOtherUser(index : number) : boolean {
        if( this.work_data.event_data.work.assignment_type != 'UNDEF' || this.work_data.event_data.work.template_type != 'CHECK'  ) { 
            return false; 
        }
        
        try {
            for( let status of this.work_status_list ) {
                if( status.user_id == this.user_id ) {
                    continue;
                }

                if( status.work_status_info.progress[index].status != 'WAIT' ) {
                    return true;
                }
            }
        } catch(e) { }

        return false;
    }

    /**
     * 업무 취소 버튼 클릭
     */
    cancelBgOpen() : void {
        this.cancel_bg_open = true;
    }

    /**
     * 업무 완료 버튼 클릭
     */
    completeBgOpen() : void {
        this.complete_bg_open = true;
        // // 완료 API 실행후 this.complete_bg_open = true
        // setTimeout(() => { this.doSetShowEventWorkProcess(false); }, 2000);
    }

    /**
     * 업무 수행 모달 닫기
     */
    closeWorkModal() : void {
        this.doSetShowEventWorkProcess(false);
    }

}
