
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { EventShareStatusInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {

    }
}) export default class EventShareStatus extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State event_share_status_info !: EventShareStatusInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventShareStatusInfo ?: any;

    get computedShareGroupAndTeamList() : any[] {
        let target : any[] = [];

        if( this.view_scope == 'GROUP_AND_TEAM' ) {
            target = target.concat(this.share_list.filter(share_obj => share_obj.share_scope != 'PERSONAL'));
        }

        return target;
    }

    get computedShareUserList() : any[] {
        let target : any[] = [];

        if( this.view_scope == 'PERSONAL' ) {
            for( let share_obj of this.share_list.filter(share_obj => share_obj.share_scope == 'PERSONAL') ) {
                target = target.concat(share_obj.users);
            }
        }

        else if ( this.view_scope == 'GROUP_AND_TEAM_USERS' ) {
            const scope_share_obj : any[] = this.share_list.filter(share_obj => share_obj.share_scope    == this.view_share_scope && 
                                                                                share_obj.share_scope_id == this.view_share_scope_id );
            for( let share_obj of scope_share_obj ) {
                target = target.concat(share_obj.users);
            }
        }

        return target;
    }

    share_list       : any[]    = [];
    subscribe_users  : number[] = [];
    subscribe_groups : number[] = [];
    subscribe_teams  : number[] = [];

    view_scope          : string = 'PERSONAL'; // PERSONAL & GROUP_AND_TEAM & GROUP_AND_TEAM_USERS
    view_share_scope    : string = '';
    view_share_scope_id : number = 0;

    mounted() : void { 

        // .count 클릭 시 
        $(".count a").click(function(){
            $(".peepsDiv").addClass("on");

        });

        this.getShareList();
    }

    /**
     * 공유 목록 불러오기
     */
    getShareList() : void {
        this.hodu_api_call(`api/v1/calendars/${this.event_share_status_info.calendar_id}/events/${this.event_share_status_info.scope}/${this.event_share_status_info.scope_id}/${this.event_share_status_info.event_id}/share?is_web=true`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.share_list.splice(0, this.share_list.length);

                // 조회 결과가 없는 경우 return
                if( response.data.data.share_list == null ) {
                    return;
                }
 
                this.share_list       = this.share_list.concat(response.data.data.share_list.share_list);
                this.subscribe_users  = response.data.data.share_list.subscribe_users;
                this.subscribe_groups = response.data.data.share_list.subscribe_groups;
                this.subscribe_teams  = response.data.data.share_list.subscribe_teams;
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            })
    }

    /**
     * 수신자 탭 변경
     */
    shareTabChange(tab : string, share_scope ?: string, share_scope_id ?: number) : void {
        this.view_scope = tab;
        
        if( share_scope    ) { this.view_share_scope    = share_scope;    }
        if( share_scope_id ) { this.view_share_scope_id = share_scope_id; }
    }

    /**
     * 그룹 / 팀 role에 따른 오브젝트 반환
     */
    getRole(share_user_obj : any) : Object {
        if( !share_user_obj ) {
            return { role : '', role_text : '' };
        }

        // group_role 체크
        if( share_user_obj.group_role && share_user_obj.group_role[0] != null ) {
            
            if( share_user_obj.group_role[0] == 'ADMIN' ) {
                return { role : 'king', role_text : '최종관리자' };
            }
            
            else if( share_user_obj.group_role[0] == 'GROUP_MANAGER' ) {
                return { role : 'king', role_text : '관리자' };
            }

            else if( share_user_obj.group_role[0] == 'GROUP_SUB_MANAGER' ) {
                return { role : 'manager', role_text : '부관리자' };
            }

            else if( share_user_obj.group_role[0] == 'GROUP_USER' ) {
                return { role : 'mem', role_text : '멤버' };
            }
        }

        // team_role 체크
        if( share_user_obj.team_role && share_user_obj.team_role[0] != null ) {
            
            if( share_user_obj.team_role[0] == 'ADMIN' ) {
                return { role : 'king', role_text : '최종관리자' };
            }
            
            else if( share_user_obj.team_role[0] == 'TEAM_MANAGER' ) {
                return { role : 'king', role_text : '팀장' };
            }

            else if( share_user_obj.team_role[0] == 'TEAM_USER' ) {
                return { role : 'mem', role_text : '팀원' };
            }
        }

        return '';
    }

    /**
     * 그룹, 팀 이미지 URL 반환
     */
    getGroupAndTeamImageUrl(share_obj : any) : string {
        if( !share_obj ) {
            return '';
        }

        // 그룹
        if( share_obj.share_scope == OWNER_TYPE.GROUP ) {
            return `/app_images/profile/group/${Math.floor(share_obj.share_scope_id / 10000)}/${share_obj.share_scope_id}.jpg`;
        }

        // 팀
        else if ( share_obj.share_scope == OWNER_TYPE.TEAM ) {
            return `/app_images/profile/team/${Math.floor(share_obj.share_scope_id / 10000)}/${share_obj.share_scope_id}.jpg`;
        }

        return '';
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 그룹, 팀 이미지 불러오는 중 에러가 발생한 경우
     */
    groupAndTeamImageError(event, share_obj : any) : void {
        $(event.target).parent().find('.img').addClass(this.hodu_color_dc_lc_for_group(share_obj.scope_color));
    }
    
    /**
     * 현재 상태에 따라서 Modal을 닫거나 그룹/팀의 유저 정보창에서 나간다
     */
    closeButtonClick() : void {

        // 그룹, 팀 유저를 보고있는 상태에선 그룹 / 팀 리스트로 이동만 시키고 종료
        if( this.view_scope == 'GROUP_AND_TEAM_USERS' ) {
            this.view_scope = 'GROUP_AND_TEAM';
            return;
        }

        // 그 외의 경우는 모달 닫기
        this.doSetEventShareStatusInfo({
            show_event_share_status : false,
            event_id : ""
        })
    }
}
