
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SHARE_OPTION, CRUD_TYPE } from '@/mixin/VueHoduCommon';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class TempsBox extends Mixins(VueHoduCommon) {

    get computedTemps() {

        let computed_temps : any[] = JSON.parse(JSON.stringify(this.temps));
        
        if( this.filter_type != "ALL" ) {
            computed_temps = computed_temps.filter(temp => temp.temp_type == this.filter_type);
        }

        // 웹은 카드는 제외
        computed_temps = computed_temps.filter(temp => temp.temp_type != 'CARD');

        // 30일 체크
        computed_temps = computed_temps.filter(temp => this.getDDay(temp.audit_modified) >= 0);

        return computed_temps.sort((o1, o2) => {

            if( new Date(o1.audit_modified).getTime() < new Date(o2.audit_modified).getTime() ) return 1;
            else if( new Date(o1.audit_modified).getTime() > new Date(o2.audit_modified).getTime() ) return -1;

            return 0;
        });
    }

    filter_type : string = "ALL";
    temps : any[] = [];

    mounted() : void {
        this.getTemps();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
     async setScroll() : Promise<void> {
        
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();
        const selectBoxOuterHeight : number | undefined = $('.selectBox').outerHeight();
        const sortHeaderOuterHeight : number | undefined = $('.sortHeader').outerHeight();
        
        const scroll_height = window.innerHeight - (titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight) - 
                              (selectBoxOuterHeight == null ? 0 : selectBoxOuterHeight) -
                              (sortHeaderOuterHeight == null ? 0 : sortHeaderOuterHeight);
        
        // @ts-ignore alarmDivScroll 스크롤
        $('#temps_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height
        });
    }

    async getTemps() {

        try {

            const response = await this.hodu_api_call(`api/v1/temp`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.temps ) {
                throw new Error("임시보관함 조회 실패");
            }

            this.temps.splice(0, this.temps.length);
            this.temps = this.temps.concat(response.data.data.temps);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "임시보관함 조회 실패", ['확인']);
        }

    }

    getTempType(temp) : string {

        switch( temp.temp_type ) {
            case "BASIC": return "일정";
            case "CARD": return "카드";
            case "MEETINGLOG": return "회의록";
            case "REPORT": return "업무일지";
            case "WORK": return "프로젝트";
        }

        return "";
    }

    getTempTitle(temp) : string {

        switch( temp.temp_type ) {
            case "BASIC":
            case "CARD": 
            case "MEETINGLOG":
            case "REPORT":
            case "WORK":
                return temp.temp_data.event_data.title;
        }

        return "";
    }

    /**
     * dday 구하기
     */
     getDDay(audit_modified : string | Date) : number {
        audit_modified = audit_modified instanceof Date ? audit_modified : moment(audit_modified).toDate();
        const target_date = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        const diff : number = this.getDateDiff(target_date, audit_modified);
        return 30 - diff;
    }

    goDetail(temp) {

        switch( temp.temp_type ) {
            case "BASIC":
            case "CARD": 
            case "MEETINGLOG":
            case "REPORT":
            case "WORK":
                temp.temp_data.is_temp = true;
                temp.temp_data.temp_id = temp.temp_id;
                this.doSetEvent(temp.temp_data);
                this.doSetEventCrudType(CRUD_TYPE.CREATE);
                this.doSetEventShareInfo({
                    share_option : temp.temp_data.share_option,
                    user_ids : [],
                    group_ids : [],
                    team_ids : [],
                    group_user_ids : [],
                    team_user_ids : []
                });
                this.hodu_router_push(`/event`);
                break;
        }

        return "";

    }

    async deleteTemp(temp) {
        this.hodu_show_dialog('cancel', `정말로 삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    
                    const response = await this.hodu_api_call(`api/v1/temp/${temp.temp_id}`, API_METHOD.DELETE);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("임시보관함 삭제 중 오류 발생");
                    }

                    this.getTemps();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog('cancel', "임시보관함 삭제 중 오류 발생", ['확인']);
                }
            }
        ]); 
    }

    /**
     * 리사이즈 감지 
     */
     handleResize() : void {
        // @ts-ignore
        $('#temps_list_scroll').mCustomScrollbar('destroy');
        this.$nextTick(() => this.setScroll());
    }

}
