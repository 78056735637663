
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

@Component({
    name : "OrganizationRecursion2",
    components: {
        
    },
})
export default class OrganizationRecursion2 extends Mixins(VueHoduCommon) {

    @Prop() level !: number;
    @Prop() departments !: any[];
    @Prop() selected_department !: any;
    @Prop() department_closed_map !: Map<number, boolean>;
    @Prop() is_select !: boolean
    
    mounted() : void {
        
    }

    /**
     * 열기·닫기
     */
    openToggle(department : any) : void {
        const copy_department = JSON.parse(JSON.stringify(department));
        copy_department.is_closed = !copy_department.is_closed;
        this.department_closed_map?.set(copy_department.dept_id, copy_department.is_closed);
        this.departments.splice(this.departments.indexOf(department), 1, copy_department);
        this.$forceUpdate();
    }
    
    /**
     * 부서 추가
     */
    add(target_department : any) : void {
        this.$emit('add', target_department);
    }

    /**
     * 부서 수정
     */
    update(target_department : any) : void {
        if( this.get_group_role(this.scope_group_id) != 'GROUP_MANAGER' && this.get_group_role(this.scope_group_id) != 'ADMIN' ) {
            this.openToggle(target_department)
            return;
        }
        this.$emit('update', target_department);
    }

    /**
     * 부서 삭제
     */
    remove(target_department : any) : void {
        this.$emit('remove', target_department);
    }

    /**
     * 부서 선택
     */
    select(target_department : any) : void {
        this.$emit('select', target_department);
    }

}
