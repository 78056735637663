import { ActionTree, MutationTree, GetterTree } from 'vuex';

export const namespaced = true;

import { approval_interface, approval_enum } from '@/model/approval';

export interface ApprovalInfoState {
    approval : approval_interface.t_approval;
    filter : approval_interface.ApprovalFilter;

    draft_filter : approval_interface.ApprovalFilter;
    receive_filter : approval_interface.ApprovalFilter;

    is_first_init_complete : boolean;

    selected_tab : approval_enum.APPROVAL_BOX_TAB;
    selected_draft_tab : approval_enum.APPROVAL_DRAFT_BOX_TAB;
    selected_receive_tab : approval_enum.APPROVAL_RECEIVE_BOX_TAB;
}

export const state : ApprovalInfoState = {
    approval : {
        "approval_uid"  : "",
        "approval_rev"  : 1,
        "group_id"      : 0,
        "approval_type" : approval_enum.APPROVAL_TYPE.BASIC,
        "approval_subtype_id" : 0,
        "user_id" : 0,
        "dept_id" : 0,
        "pos_id" : 0,
        "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
        "receive_reference" : [],
        "contents" : {
            "title" : "",
            "comment" : "",
            "color" : "#477FFF",
            "files" : [],
        },
        "approval_state" : "",
        "approval_cur" : 0,
        "approval_total" : 0,
        "approval_document_num" : "",
        "approver" : []
    },

    filter : {
        search_query : "",
        start : new Date(),
        end : new Date(),
        is_emergency : true,
        is_importance : true,
        is_normal : true,
        importance_filter : [10, 20, 30],
        departments : [],
        dept_ids : [],
        is_selecet_all_departments : true,
        is_approval : true,
        is_vacation : true,
        is_holiday_work : true,
        is_businesstrip : true,
        is_cash_disbursement_voucher : true,
        is_free : true,
    },

    draft_filter : {
        search_query : "",
        start : new Date(),
        end : new Date(),
        is_emergency : true,
        is_importance : true,
        is_normal : true,
        importance_filter : [10, 20, 30],
        departments : [],
        dept_ids : [],
        is_selecet_all_departments : true,
        is_approval : true,
        is_vacation : true,
        is_holiday_work : true,
        is_businesstrip : true,
        is_cash_disbursement_voucher : true,
        is_free : true,
    },

    receive_filter : {
        search_query : "",
        start : new Date(),
        end : new Date(),
        is_emergency : true,
        is_importance : true,
        is_normal : true,
        importance_filter : [10, 20, 30],
        departments : [],
        dept_ids : [],
        is_selecet_all_departments : true,
        is_approval : true,
        is_vacation : true,
        is_holiday_work : true,
        is_businesstrip : true,
        is_cash_disbursement_voucher : true,
        is_free : true,
    },

    is_first_init_complete : false,

    selected_tab : approval_enum.APPROVAL_BOX_TAB.DRAFT,
    selected_draft_tab : approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL,
    selected_receive_tab : approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST
};

// getters
export const getters: GetterTree<ApprovalInfoState, any> = {

    getApproval : (state) => {
        return state.approval;
    },

    getFilter : (state) => {
        return state.filter;
    },

    getDraftFilter : (state) => {
        return state.filter;
    },

    getReceiveFilter : (state) => {
        return state.filter;
    },

    isFirstInitComplete : (state) => {
        return state.is_first_init_complete;
    },

    getSelectedTab : (state) => {
        return state.selected_tab;
    },

    getSelectedDraftTab : (state) => {
        return state.selected_draft_tab;
    },

    getSelectedReceiveTab : (state) => {
        return state.selected_receive_tab;
    },

}

// action
export const actions: ActionTree<ApprovalInfoState, ApprovalInfoState> = {

    doSetApproval: ({state, commit}, approval : approval_interface.t_approval) => {
        console.log(`actions.doSetApproval ${JSON.stringify(approval)}`);
        state.approval = JSON.parse(JSON.stringify(approval));

        if( sessionStorage != null ) {
            sessionStorage.setItem("approval_info", JSON.stringify(state.approval));
        }
    },

    doSetFilter: ({state, commit}, filter : approval_interface.ApprovalFilter ) => {
        console.log(`actions.doSetFilter ${JSON.stringify(filter)}`);
        state.filter = JSON.parse(JSON.stringify(filter));

        if( state.filter.departments == null || filter.departments == null ) {
            state.filter.departments = filter.departments;
        }
        else {
            state.filter.departments.splice(0, state.filter.departments.length);
            state.filter.departments = state.filter.departments.concat(filter.departments);
        }

        if( state.filter.dept_ids == null || filter.dept_ids == null ) {
            state.filter.dept_ids = filter.dept_ids;
        }
        else {
            state.filter.dept_ids.splice(0, state.filter.dept_ids.length);
            state.filter.dept_ids = state.filter.dept_ids.concat(filter.dept_ids);
        }

        if( state.filter.importance_filter == null || filter.importance_filter == null ) {
            state.filter.importance_filter = filter.importance_filter;
        }
        else {
            state.filter.importance_filter.splice(0, state.filter.importance_filter.length);
            state.filter.importance_filter = state.filter.importance_filter.concat(filter.importance_filter);
        }

        state.filter.start = filter.start;
        state.filter.end = filter.end;
        state.filter.is_emergency = filter.is_emergency;
        state.filter.is_importance = filter.is_importance;
        state.filter.is_normal = filter.is_normal;
        state.filter.search_query = filter.search_query;
        state.filter.is_approval = filter.is_approval;
        state.filter.is_vacation = filter.is_vacation;
        state.filter.is_businesstrip = filter.is_businesstrip;
        state.filter.is_holiday_work = filter.is_holiday_work;
        state.filter.is_cash_disbursement_voucher = filter.is_cash_disbursement_voucher;
        state.filter.is_free = filter.is_free;
        state.filter.is_selecet_all_departments = filter.is_selecet_all_departments;

        if( sessionStorage != null ) {
            sessionStorage.setItem("approval_filter", JSON.stringify(state.filter));
        }
    },

    doSetDraftFilter: ({state, commit}, draft_filter : approval_interface.ApprovalFilter ) => {
        console.log(`actions.doSetDraftFilter ${JSON.stringify(draft_filter)}`);
        state.draft_filter = JSON.parse(JSON.stringify(draft_filter));

        if( state.draft_filter.departments == null || draft_filter.departments == null ) {
            state.draft_filter.departments = draft_filter.departments;
        }
        else {
            state.draft_filter.departments.splice(0, state.draft_filter.departments.length);
            state.draft_filter.departments = state.draft_filter.departments.concat(draft_filter.departments);
        }

        if( state.draft_filter.dept_ids == null || draft_filter.dept_ids == null ) {
            state.draft_filter.dept_ids = draft_filter.dept_ids;
        }
        else {
            state.draft_filter.dept_ids.splice(0, state.draft_filter.dept_ids.length);
            state.draft_filter.dept_ids = state.draft_filter.dept_ids.concat(draft_filter.dept_ids);
        }

        if( state.draft_filter.importance_filter == null || draft_filter.importance_filter == null ) {
            state.draft_filter.importance_filter = draft_filter.importance_filter;
        }
        else {
            state.draft_filter.importance_filter.splice(0, state.draft_filter.importance_filter.length);
            state.draft_filter.importance_filter = state.draft_filter.importance_filter.concat(draft_filter.importance_filter);
        }

        state.draft_filter.start = draft_filter.start;
        state.draft_filter.end = draft_filter.end;
        state.draft_filter.is_emergency = draft_filter.is_emergency;
        state.draft_filter.is_importance = draft_filter.is_importance;
        state.draft_filter.is_normal = draft_filter.is_normal;
        state.draft_filter.search_query = draft_filter.search_query;
        state.draft_filter.is_approval = draft_filter.is_approval;
        state.draft_filter.is_vacation = draft_filter.is_vacation;
        state.draft_filter.is_businesstrip = draft_filter.is_businesstrip;
        state.draft_filter.is_holiday_work = draft_filter.is_holiday_work;
        state.draft_filter.is_cash_disbursement_voucher = draft_filter.is_cash_disbursement_voucher;
        state.draft_filter.is_free = draft_filter.is_free;
        state.draft_filter.is_selecet_all_departments = draft_filter.is_selecet_all_departments;

        if( sessionStorage != null ) {
            sessionStorage.setItem("approval_draft_filter", JSON.stringify(state.draft_filter));
        }
    },

    doSetReceiveFilter: ({state, commit}, receive_filter : approval_interface.ApprovalFilter ) => {
        console.log(`actions.doSetReceiveFilter ${JSON.stringify(receive_filter)}`);
        state.receive_filter = JSON.parse(JSON.stringify(receive_filter));

        if( state.receive_filter.departments == null || receive_filter.departments == null ) {
            state.receive_filter.departments = receive_filter.departments;
        }
        else {
            state.receive_filter.departments.splice(0, state.receive_filter.departments.length);
            state.receive_filter.departments = state.receive_filter.departments.concat(receive_filter.departments);
        }

        if( state.receive_filter.dept_ids == null || receive_filter.dept_ids == null ) {
            state.receive_filter.dept_ids = receive_filter.dept_ids;
        }
        else {
            state.receive_filter.dept_ids.splice(0, state.receive_filter.dept_ids.length);
            state.receive_filter.dept_ids = state.receive_filter.dept_ids.concat(receive_filter.dept_ids);
        }

        if( state.receive_filter.importance_filter == null || receive_filter.importance_filter == null ) {
            state.receive_filter.importance_filter = receive_filter.importance_filter;
        }
        else {
            state.receive_filter.importance_filter.splice(0, state.receive_filter.importance_filter.length);
            state.receive_filter.importance_filter = state.receive_filter.importance_filter.concat(receive_filter.importance_filter);
        }

        state.receive_filter.start = receive_filter.start;
        state.receive_filter.end = receive_filter.end;
        state.receive_filter.is_emergency = receive_filter.is_emergency;
        state.receive_filter.is_importance = receive_filter.is_importance;
        state.receive_filter.is_normal = receive_filter.is_normal;
        state.receive_filter.search_query = receive_filter.search_query;
        state.receive_filter.is_approval = receive_filter.is_approval;
        state.receive_filter.is_vacation = receive_filter.is_vacation;
        state.receive_filter.is_businesstrip = receive_filter.is_businesstrip;
        state.receive_filter.is_holiday_work = receive_filter.is_holiday_work;
        state.receive_filter.is_cash_disbursement_voucher = receive_filter.is_cash_disbursement_voucher;
        state.receive_filter.is_free = receive_filter.is_free;
        state.receive_filter.is_selecet_all_departments = receive_filter.is_selecet_all_departments;

        if( sessionStorage != null ) {
            sessionStorage.setItem("approval_receive_filter", JSON.stringify(state.receive_filter));
        }
    },

    doSetIsFirstInitComplete: ({state, commit}, is_first_init_complete : boolean ) => {
        console.log(`actions.doSetIsFirstInitComplete ${is_first_init_complete}`);
        state.is_first_init_complete = is_first_init_complete;
        
        if( sessionStorage != null ) {
            sessionStorage.setItem("approval_is_first_init_complete", JSON.stringify(state.filter));
        }
    },

    doSetTabInfo : ({state, commit}, tabs : { selected_tab : approval_enum.APPROVAL_BOX_TAB, selected_draft_tab : approval_enum.APPROVAL_DRAFT_BOX_TAB, selected_receive_tab : approval_enum.APPROVAL_RECEIVE_BOX_TAB }) => {
        console.log(`actions.doSetTab ${JSON.stringify(tabs)}`);
        state.selected_tab = tabs.selected_tab;
        state.selected_draft_tab = tabs.selected_draft_tab;
        state.selected_receive_tab = tabs.selected_receive_tab;

        if( sessionStorage != null ) {
            sessionStorage.setItem("approval_tab_info", JSON.stringify({ 
                selected_tab : state.selected_tab, 
                selected_draft_tab : state.selected_draft_tab, 
                selected_receive_tab : state.selected_receive_tab
            }));
        }
    },

    doSetSelectedTab: ({state, commit}, selected_tab : approval_enum.APPROVAL_BOX_TAB) => {
        console.log(`actions.doSetSelectedTab ${JSON.stringify(selected_tab)}`);
        state.selected_tab = selected_tab;

        if( sessionStorage != null ) {
            sessionStorage.setItem("approval_tab_info", JSON.stringify({ 
                selected_tab : state.selected_tab, 
                selected_draft_tab : state.selected_draft_tab, 
                selected_receive_tab : state.selected_receive_tab
            }));
        }
    },

    doSetSelectedDraftTab: ({state, commit}, selected_draft_tab : approval_enum.APPROVAL_DRAFT_BOX_TAB) => {
        console.log(`actions.doSetSelectedDraftTab ${JSON.stringify(selected_draft_tab)}`);
        state.selected_draft_tab = selected_draft_tab;

        if( sessionStorage != null ) {
            sessionStorage.setItem("approval_tab_info", JSON.stringify({ 
                selected_tab : state.selected_tab, 
                selected_draft_tab : state.selected_draft_tab, 
                selected_receive_tab : state.selected_receive_tab
            }));
        }
    },

    doSetSelectedReceiveTab: ({state, commit}, selected_receive_tab : approval_enum.APPROVAL_RECEIVE_BOX_TAB) => {
        console.log(`actions.doSetSelectedReceiveTab ${JSON.stringify(selected_receive_tab)}`);
        state.selected_receive_tab = selected_receive_tab;

        if( sessionStorage != null ) {
            sessionStorage.setItem("approval_tab_info", JSON.stringify({ 
                selected_tab : state.selected_tab, 
                selected_draft_tab : state.selected_draft_tab, 
                selected_receive_tab : state.selected_receive_tab
            }));
        }
    },

}