
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';
import { approval_enum } from '@/model/approval';

@Component({
    components: {
        
    },
})
export default class OrganizationMyVacationDetailModal extends Mixins(VueHoduCommon) {

    /**
     * 히스토리 + 현재 결재 상태 합치기
     */
    get computedApproverHistory() : any[] {
        let history : any[] = [];

        if( this.approval != null ) {
            
            if( this.approval['histapprover'] != null ) {
                history = history.concat(this.approval['histapprover']);
            }

            history = history.concat(this.approval.approver);
        }

        return history;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_my_vacation_detail_modal_info  !: organization_modal_interface.OrganizationMyVacationDetailModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationMyVacationDetailModalInfo ?: (params : organization_modal_interface.OrganizationMyVacationDetailModalInfo) => void;

    approval : any = null;

    mounted() : void {
        this.getSelectedApprovalInfo();
    }

    /**
     * 기안서 조회
     */
    async getSelectedApprovalInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.organization_my_vacation_detail_modal_info.approval_uid}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("휴가원 조회 중 오류 발생");
            }

            const approval_info = response.data.data.approval_info;

            this.approval = JSON.parse(JSON.stringify(approval_info));

        } catch(e) {
            alert("휴가원 조회 중 오류 발생")
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 상태에 따른 텍스트 반환
     */
    getStatusText(approval : any) : string {
        switch(approval.approval_state) {
            case approval_enum.APPROVAL_STATE.ING:
                return "승인 대기중";
            case approval_enum.APPROVAL_STATE.CANCEL:
                return "취소됨";
            case approval_enum.APPROVAL_STATE.COMPLETE:
                return "승인 됨";
            case approval_enum.APPROVAL_STATE.REJECT:
                return "반려 됨";
        }

        return "";
    }

    /**
     * 휴가 날짜 텍스트 반환
     */
    getDateText(approval : any) : string {

        if( this.hodu_string_validation(approval.contents.vacation_start, '') != "" && this.hodu_string_validation(approval.contents.vacation_end, '') != '' ) {

            const vacation_start = approval.contents.vacation_start;
            const vacation_end = approval.contents.vacation_end;

            if( vacation_start == vacation_end ) {
                const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
                
                let str = `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)}`;

                // if( approval.contents.vacation_type_daycount > 0 && approval.contents.vacation_type_daycount < 1 ) {
                if( approval.contents.vacation_type_daycount == 0.5 ) {
                    let start_hour = Number(approval.contents.vacation_start_time.substring(0,2));
                    let start_min = Number(approval.contents.vacation_start_time.substring(2,4));
                    let start_am_pm = 'AM';

                    if( start_hour >= 12 ) start_am_pm = "PM";
                    if( start_hour >  12 ) start_hour -= 12;
                    if( start_hour == 0  ) start_hour = 12;

                    let end_hour = Number(approval.contents.vacation_end_time.substring(0,2));
                    let end_min = Number(approval.contents.vacation_end_time.substring(2,4));
                    let end_am_pm = 'AM';

                    if( end_hour >= 12 ) end_am_pm = "PM";
                    if( end_hour >  12 ) end_hour -= 12;
                    if( end_hour == 0  ) end_hour = 12;

                    str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                }

                return str;
            }

            const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
            const target_end = [vacation_end.substring(0,4), vacation_end.substring(4,6), vacation_end.substring(6,8)].join('-');

            return `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)} ~ ${this.hodu_date_to_format_string(target_end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_end)}`;
   
        }

        let str = "";

        for( const selected_date of approval.contents.vacation_selected ) {
            if( str.length > 0 ) str += ", ";
            const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
            str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
        }

        if( approval.contents.vacation_selected.length == 1 ) {
            // if( approval.contents.vacation_type_daycount < 1 && approval.contents.vacation_type_daycount > 0 ) {
            if( approval.contents.vacation_type_daycount == 0.5 ) {
                // str += " 오전";

                let start_hour = approval.contents.vacation_start_time ? Number(approval.contents.vacation_start_time.substring(0,2)) : -1;
                let start_min = approval.contents.vacation_start_time ? Number(approval.contents.vacation_start_time.substring(2,4)) : -1;
                let start_am_pm = 'AM';

                if( start_hour < 0 || start_min < 0 ) return str;

                if( start_hour >= 12 ) start_am_pm = "PM";
                if( start_hour >  12 ) start_hour -= 12;
                if( start_hour == 0  ) start_hour = 12;

                let end_hour = approval.contents.vacation_end_time ? Number(approval.contents.vacation_end_time.substring(0,2)) : approval.contents.vacation_end_time;
                let end_min = approval.contents.vacation_end_time ? Number(approval.contents.vacation_end_time.substring(2,4)) : approval.contents.vacation_end_time;
                let end_am_pm = 'AM';

                if( end_hour < 0 || end_min < 0 ) return str;

                if( end_hour >= 12 ) end_am_pm = "PM";
                if( end_hour >  12 ) end_hour -= 12;
                if( end_hour == 0  ) end_hour = 12;

                str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
            }
        }
        
        return str;
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 승인자 이름 리스트
     */
    approverNameList(approval) : string {
        
        let name_list : string = "";
        
        for( const approver of approval.approver ) {
            if( approval.approver.indexOf(approver) == 0 ) continue;
            if( name_list.length > 0 ) name_list += ", ";
            name_list += approver.user_name;
        }

        return name_list;
    }

    /**
     * 결재자 상태값 텍스트
     */
    getApproverStateText(state : approval_enum.APPROVAL_APPROVER_STATE) : string {

        switch(state) {
            case approval_enum.APPROVAL_APPROVER_STATE.REQUEST:
                return "요청";

            case approval_enum.APPROVAL_APPROVER_STATE.CANCEL:
                return "취소";

            case approval_enum.APPROVAL_APPROVER_STATE.WAIT:
                return "대기";

            case approval_enum.APPROVAL_APPROVER_STATE.ING:
                return "진행";

            case approval_enum.APPROVAL_APPROVER_STATE.CONFIRM:
                return "승인";

            case approval_enum.APPROVAL_APPROVER_STATE.REJECT:
                return "반려";
        }

        return "";
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 사용자 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('span.prof').css('background-image', require('@/assets/images/contents/ic_approval_user.png'));
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationMyVacationDetailModalInfo?.({ show_modal : false, approval_uid : "" });
    }

    /**
     * 휴가 취소하기
     */
    async cancel() : Promise<void> {

        if( confirm("휴가원을 취소 처리 하시겠습니까?") == false ) return;

        // try {
            
        //     await this.getSelectedApprovalInfo();

        //     if( (this.approval.approval_state != approval_enum.APPROVAL_STATE.ING && this.approval.approval_state != approval_enum.APPROVAL_STATE.TEMP) || this.approval.approver[1].state != approval_enum.APPROVAL_APPROVER_STATE.ING ) {
        //         alert("휴가원 취소를 할 수 없습니다\n휴가원이 이미 처리 됐습니다");
        //         return;
        //     }

        //     this.approval.approval_state = approval_enum.APPROVAL_STATE.CANCEL;
        //     this.approval.approver[0].state = approval_enum.APPROVAL_APPROVER_STATE.CANCEL;
        //     this.approval.approver[1].state = approval_enum.APPROVAL_APPROVER_STATE.WAIT;

        //     // 수정 API
        //     const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval.approval_uid}`, API_METHOD.PUT, this.approval);

        //     console.log(response);

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("휴가원 취소 중 오류 발생");
        //     }

        //     this.organization_my_vacation_detail_modal_info.callback?.();
        //     this.close();
            
        // } catch(e) {
        //     this.hodu_show_dialog('cancel', '휴가원 취소 처리 중 오류 발생', ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // }

        try {

            //! 1. 기안서 조회
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval.approval_uid}`, API_METHOD.GET);
        
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("기안서 조회 중 오류 발생");
            }

            const approval = response.data.data.approval_info;

            //! 2. 기안서 취소
            approval.approval_state = approval_enum.APPROVAL_STATE.CANCEL;
            
            const response_update = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval.approval_uid}?is_not_send_push=true`, API_METHOD.PUT, approval);

            console.log(response_update);

            if( !response_update || !this.isHttpStatusSuccess(response_update.status) ) {
                throw new Error("기안서 취소 중 오류 발생");
            }

            this.organization_my_vacation_detail_modal_info.callback?.();
            this.close();

        } catch(e) {
            alert("휴가원 취소 처리 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
        
    }

}
