
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const EtcInfo = namespace('EtcInfo');

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
	components : {
		ResizeObserver
	}
})
export default class HospitalMessageStatus extends Mixins(VueHoduCommon) {

	/**
     * @ModalInfo.Action
     */
	@ModalInfo.Action doSetMessageSendStatus ?: any;

	/**
     * EtcInfo.Action
     */
    @EtcInfo.Action doMessageInfo ?: any;

	status_list : any[] = [];
	start_date : Date = new Date();
	end_date : Date = new Date();

    mounted() : void {
		const vue = this;

		this.start_date = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
		this.end_date = moment(this.start_date).set('date', this.start_date.getDate() + 7).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

		// date_picker
        let datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) : void {

				const id : string | undefined = $(this).attr('id');
				const mnt : moment.Moment = moment(dateText);

				inst.input.val(mnt.format('YYYY.MM.DD') + ` ${vue.getDayOfWeekByDate(mnt.toDate(), '요일')}`);
				
				// 시작일 변경
				if( id == 'patient_msg_status_fr_ymd' ) {
					vue.start_date = mnt.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();

					// 시작일이 종료일보다 미래의 날짜일때
					if( vue.start_date.getTime() > vue.end_date.getTime() ) {
						vue.end_date = mnt.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
					}
				}

				// 종료일 변경
				else {
					vue.end_date = mnt.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

					// 종료일이 시작일보다 과거의 날짜일때
					if( vue.end_date.getTime() < vue.start_date.getTime() ) {
						vue.start_date = mnt.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
					}
				}
				
				vue.getMessageStatus();
            },
		};

		// @ts-ignore
		$('#patient_msg_status_fr_ymd').datepicker(datepicker_option);
		
		// @ts-ignore
		$('#patient_msg_status_to_ymd').datepicker(datepicker_option);

		this.getMessageStatus();
	}
	
	/**
     * 스크롤 설정
     */
    setScroll() : void {

		const title_box_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const schedule_box2_height : number = schedule_box_height ? schedule_box_height : 0;
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#patient_message_send_status_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (schedule_box_height ? schedule_box_height : 0) - schedule_box2_height
                                           - (sort_header_height ? sort_header_height : 0),
		});
        
	}
	
	/**
	 * 리스트 조회
	 */
	async getMessageStatus() : Promise<void> {

		let scope : string = OWNER_TYPE.GROUP;

		const start = moment(this.start_date).set('hour', 0)
										     .set('minute', 0)
										     .set('second', 0)
										     .set('millisecond', 0).utc().format();
													
		const end = moment(this.end_date).set('hour', 23)
										 .set('minute', 59)
										 .set('second', 59)
										 .set('millisecond', 999).utc().format();

		await this.hodu_api_call(`api/v1/message_template/getMessageStatus/${scope}/${this.scope_group_id}/0?start_date=${start}&end_date=${end}`, API_METHOD.GET)
            .then(async(response) => {
				this.status_list = response.data.data.list;

				let list_length = this.status_list.length;

				for ( let i = 0; i < list_length; i++ ) {
					this.status_list[i].checked = false;
					
					this.status_list[i].message_start_date = moment(this.status_list[i].audit_created).format('YYYY.MM.DD');
					this.status_list[i].message_start_time = moment(this.status_list[i].audit_created).format('hh:mm A');
				}
				
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
			});
			
	}

	/**
	 * 메세지 전송여부 결과 리턴
	 */
	messageStatusResult(po_data : any) : boolean {
		let data_length = po_data.push_result.length;

		if ( po_data.data_length < 1 ) {
			return false;
		}

		let result_value = false;

		for ( let i = 0; i < data_length; i++ ) {
			let lo_data = po_data.push_result[i];

			if ( lo_data.status == "FEED_OK" ) {
				result_value = true;
				break;
			}
		}

		return result_value;
	}

	/**
	 * 메세지 전송 상태 모달 열기
	 */
	showMessageSendStatus(status : any) : void {
		this.doMessageInfo(status);
		window["messageSendStatusPopClose"] = this.messageSendStatusPopClose;
		this.doSetMessageSendStatus(true);
	}

	/**
	 * 메세지 상세보기 닫기
	 */
	messageSendStatusPopClose() : void { 
		this.doMessageInfo({});
		this.doSetMessageSendStatus(false);
	}

	/**
	 * 메세지 재전송
	 */
	resendMessage(status : any) : void {
		alert("준비 중");
	}

	/**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#patient_message_send_status_scroll').mCustomScrollbar('destroy');
		this.setScroll();
		
    }

}
