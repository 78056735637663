import { render, staticRenderFns } from "./ChattingRight.vue?vue&type=template&id=9f797282&scoped=true&"
import script from "./ChattingRight.vue?vue&type=script&lang=ts&"
export * from "./ChattingRight.vue?vue&type=script&lang=ts&"
import style0 from "./ChattingRight.vue?vue&type=style&index=0&id=9f797282&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f797282",
  null
  
)

export default component.exports