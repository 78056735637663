
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');

import { GroupNoticeSearchInfo } from '@/store/modules/GroupInfo';

@Component({

})
export default class LeftGroupNotice extends Mixins(VueHoduCommon) {
    
    /**
     * GroupInfo.Action
     */
    @GroupInfo.Action doSetGroupNoticeSearchInfo ?: (params : GroupNoticeSearchInfo) => void;
    
    mounted() : void {
        if( this.scope != null && this.scope != OWNER_TYPE.PERSONAL ) { this.doSetCommunityMenuClose(true); }
    }

    /**
     * 커뮤니티 메뉴 ON / OFF
     */
     communityMenuOnOff() : void {
        this.doSetCommunityMenuClose(!this.community_menu_close);
    }

    /**
     * 그룹 공지사항 화면으로 이동
     */
    moveGroupNotice() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        
        this.doGroupId(this.scope_group_id);
        this.doTeamId(this.scope_team_id);
        this.doGroupTeamOption(JSON.parse(JSON.stringify(this.scope_group_team_option)));
        this.doSetGroupNoticeSearchInfo?.({
            notice_sort_direction : "ASC",
            notice_sort_target : null,
            search_query : "",
        });
        this.hodu_router_push(`/group/${new Date().getTime()}/notice`);
    }

    /**
     * 그룹 게시판으로 이동
     */
    moveGroupBoard() : void {
        this.doSetGroupNoticeSearchInfo?.({
            notice_sort_direction : "ASC",
            notice_sort_target : null,
            search_query : "",
        });
        this.hodu_router_push(`/group/${new Date().getTime()}/board`);
    }

    /**
     * 메세지 발송
     */
    moveMessageSend() : void {
        this.hodu_router_push(`/message/${new Date().getTime()}/send`);
    }

    /**
     * 메세지 발송 현황
     */
    moveMessageStatus() : void {
        this.hodu_router_push(`/message/${new Date().getTime()}/status`);
    }

    /**
     * 메세지 템플릿
     */
    moveMessageTemplate() : void {
        this.hodu_router_push(`/message/${new Date().getTime()}/template`);
    }
}
