
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";
import { namespace } from 'vuex-class';
import { GroupAddFileModalInfo } from '@/store/modules/ModalInfo';
const ModalInfo = namespace('ModalInfo');

@Component({

}) export default class GroupAddFileBox extends Mixins(VueHoduCommon) {
    /**
     * ModalInfo.State
     */
    @ModalInfo.State group_add_file_modal_info !: GroupAddFileModalInfo;

    /**
     * 변수 선언부
     */

    async mounted() : Promise<void> {

    }

    groupAddFileBoxCancel() : void {
        window["groupAddFileBoxCancel"]();
    }

    groupAddFileBoxConfirm() : void {
        let value = String($('#input_file_box_name').val());
        
        if ( value.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "폴더명을 입력해주세요.", ['확인']);
            return;
        }

        window["groupAddFileBoxConfirm"](this.group_add_file_modal_info.is_create, this.group_add_file_modal_info.dir_uuid, value);
    }

    groupDeleteFileBox() : void {
        this.hodu_show_dialog("alert", `${this.group_add_file_modal_info.dir_name} 삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            () => {
                window["groupDeleteFileBoxConfirm"](this.group_add_file_modal_info.dir_uuid);
            },
        ]);
    }
}
