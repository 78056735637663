
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object } from '@/model/hodudoc';

import moment from 'moment';

import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        Hooper, Slide
    },
})
export default class AppointmentAvailableListLeft extends Mixins(VueHoduCommon) {

    // this.$refs.hooperSlide 사용
    $refs !: { hooperSlide : HTMLFormElement; }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentCreateModalInfo ?: (params : hodu_doc_modal_info.AppointmentCreateModalInfo) => void;

    /**
     * Prop
     */
    @Prop() event_bus          !: Vue;
    @Prop() max_date           !: Date;
    @Prop() selected_date      !: Date;
    @Prop() selected_doctor    !: hodu_doc_object.doctor_info;
    @Prop() is_open_right_list !: boolean;

    /**
     * variable
     */
    available_list : hodu_doc_object.appointment_available_time_info[] = [];
    day_list : Date[] = [];
    holi_days : any[] = [];
    
    choose_day_div_style = "";
    choose_day_a_style = "";

    current_scroll_week_index : number = 0;

    is_render_hooper : boolean = true;

    async mounted() : Promise<void> {

        if( this.event_bus != null ) { 
            this.event_bus.$on('leftAvailableListResize', this.handleResize);
            this.event_bus.$on('rerenderHooper', this.rerenderHooper); 
        }

        // 날짜 리스트 제작
        // this.day_list.splice(0, this.day_list.length); 
        // for( let i = 0; i < 7; i++ ) {
        //     const date_diff : number = i - this.selected_date.getDay();

        //     const target_date = new Date(this.selected_date);
        //     target_date.setDate(target_date.getDate() + date_diff);
        //     target_date.setHours(23);
        //     target_date.setMinutes(59);
        //     target_date.setSeconds(59);
        //     target_date.setMilliseconds(999);

        //     this.day_list.push(target_date);
        // }

        // 스크롤 하는 경우 (전부 만드는 경우)
        let current_max_diff : number = this.getDateDiff(this.selected_date, this.max_date)
        this.day_list.splice(0, this.day_list.length); 
        for( let i = 0; i <= current_max_diff; i++ ) {
            const date_diff : number = i - new Date().getDay();

            if( date_diff < 0 ) { current_max_diff++; }

            const target_date = new Date(this.selected_date);
            target_date.setDate(target_date.getDate() + date_diff);
            target_date.setHours(23);
            target_date.setMinutes(59);
            target_date.setSeconds(59);
            target_date.setMilliseconds(999);

            this.day_list.push(target_date);
        }

        // 공휴일 조회
        this.holi_days = await this.hodu_get_holidays(new Date(), this.max_date);

        if( this.selected_doctor != null ) { this.getAvailableAppointmentTime(); }

        this.$nextTick(() => { this.setScroll(); });

        // 재조회용
        window['leftSelectAvailableList'] = this.getAvailableAppointmentTime;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const choose_day_height : number = 67;  // 높이가 순간적으로 변해서 고정값 67px로 사용 

        // @ts-ignore
        $('#available_list_left_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - choose_day_height
        });

        setTimeout(() => {
            this.$nextTick(() => {
                const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
                const right_area_controller_width : number | undefined = $('#right_area_controller').outerWidth();
                const doc2_width : number | undefined = $('.doc2').outerWidth();

                const choose_day_scroll_width : number = window.innerWidth - (left_area_width ? left_area_width : 0)
                                                                            - (right_area_controller_width ? right_area_controller_width : 0)
                                                                            - (doc2_width ? doc2_width : 0)
                
                const date_a_width : number = choose_day_scroll_width / 7;
                this.choose_day_a_style   = `width : ${date_a_width}px !important`;
                this.choose_day_div_style = `width : ${choose_day_scroll_width}px !important`;
            });
        }, 100);
        
        // this.$nextTick(() => {
        //     const choose_day_scroll_width : number = window.innerWidth - (left_area_width ? left_area_width : 0)
        //                                                                 - (right_area_controller_width ? right_area_controller_width : 0)
        //                                                                 - (doc2_width ? doc2_width : 0)
               
        //     const date_a_width : number = choose_day_scroll_width / 7;
        //     this.choose_day_a_style   = `width : ${date_a_width}px !important`;
        //     this.choose_day_div_style = `width : ${choose_day_scroll_width}px !important`;

        //     // @ts-ignore
        //     $('#choose_day_scroll').mCustomScrollbar({
        //         axis : 'x',
        //         scrollbarPosition : 'outside',
        //         mouseWheel:{ 
        //             scrollAmount: choose_day_scroll_width,
        //             preventDefault : true, 
        //         },
        //         scrollInertia : 200,
        //         autoDraggerLength : false,
        //         setWidth : choose_day_scroll_width,
        //         whileScrolling : () => {

        //         },
        //         onScroll : () => {

        //         }
        //     });

        //     // @ts-ignore
        //     // $('#choose_day_scroll').mCustomScrollbar("disable", true);
        // })
    }

    /**
     * 해당 날짜의 예약 가능, 불가능 여부 정보 조회
     */
    getAvailableAppointmentTime() : void {

        // @ts-ignore
        $('#available_list_left_scroll').mCustomScrollbar('scrollTo', 'top', { scrollInertia : 0 });

        const start = moment(this.selected_date).format('YYYY-MM-DD'); // 조회일
        const department_code = this.selected_doctor.department_code;  // 진료과 코드
        const doctor_code = this.selected_doctor.doctor_code;          // 의사 코드

        if( department_code == null || department_code.length < 1 || doctor_code == null || doctor_code.length < 1 ) { return; }

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/availability?start=${start}&department_code=${department_code}&doctor_code=${doctor_code}`, API_METHOD.GET)
            .then((response) => {
                this.available_list.splice(0, this.available_list.length);
                this.available_list = this.available_list.concat(response.data.data);

                // 조회 결과가 없는 경우 throw?
                if( this.available_list.length < 1 ) {
                    return;
                }
                
                // 오늘 날짜인 경우 이미 시간이 지난 예약에 대해서 삭제한다
                const current_day : Date = new Date();
                if( this.selected_date.getFullYear() == current_day.getFullYear() &&
                    this.selected_date.getMonth()    == current_day.getMonth()    && 
                    this.selected_date.getDate()     == current_day.getDate() ) {

                    // 60 - (reservation_by_term) 분 미만이라면 현재 시각의 0분 보다 작은 경우 없앤다
                    // 60 - (reservation_by_term) 분 이상이라면 1시간후의 0분 일때 보다 작은 데이터는 없앤다
                    if( current_day.getMinutes() >= (60 - this.available_list[0].reservation_term) ) { current_day.setHours(current_day.getHours() + 1) }
                    current_day.setMinutes(0);
                    current_day.setSeconds(0);
                    current_day.setMilliseconds(0);
                    
                    this.available_list = this.available_list.filter((item) => moment(item.original_timetable).toDate().getTime() >= current_day.getTime());

                    // this.available_list.length < 1인 경우 어떻게 할 것 인지? (다음 일자 조회?)
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false); 
            });
    }

    /**
     * 예약 등록 모달
     */
    showAppointmentCreateModal(available : hodu_doc_object.appointment_available_time_info) : void {
        if( available.available == false ) { return; }

        if( this.doSetAppointmentCreateModalInfo ) {
            
            this.doSetAppointmentCreateModalInfo({ 
                "show_modal"            : true,
                "is_create_one_patient" : false,
                "department_code"       : available.department_code,
                "doctor_code"           : available.doctor_code,
                "original_timetable"    : new Date(available.original_timetable), 
            }); 
        } 
    }

    /**
     * 날짜 선택
     */
    selectDate(date : Date) : void {
        if( (date.getFullYear() == this.selected_date.getFullYear() &&
             date.getMonth()    == this.selected_date.getMonth()    &&
             date.getDate()     == this.selected_date.getDate() )   || 
             date.getTime()      < new Date().getTime() ) { 
            return; 
        }
        date = date instanceof Date ? date : new Date(date);
        this.$emit('selectDate', date);
    }

    /**
     * 상단 날짜 메뉴 몇 주 까지 있는지 반환
     */
    getWeekList() : number {
        return Math.ceil(this.day_list.length / 7);
    }

    /**
     * 해당 주에 어떤 날짜들이 나올지 반환
     */
    getWeekDayList(week_row : number) : Date[] {
        return this.day_list.slice(week_row * 7, week_row * 7 + 7);
    }

    /**
     * 공휴일 여부 반환
     */
    isHoliday(date : Date) : boolean {
        return this.holi_days.filter( item => item.solar_ymd == moment(date).format("YYYY-MM-DD") ).length > 0;
    } 

    /**
     * Hooper Slide 변경 될 때 마다 index 업데이트
     */
    updateCurrentScrollWeekIndex(payload : any) : void {
        this.current_scroll_week_index = payload.currentSlide;
    }

    /**
     * Hooper 리스트 다시 렌더링
     */
    rerenderHooper() : void {
        this.is_render_hooper = false;
        setTimeout(() => { this.is_render_hooper = true; }, 100);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#available_list_left_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        // $('#choose_day_scroll').mCustomScrollbar('destroy');

        // this.choose_day_div_style = "width : 0 !important";
        // this.choose_day_a_style = "width : 0 !important";
    	this.setScroll();
    }
    
    @Watch('is_open_right_list')
    changedIsOpenRightList() : void {
        setTimeout(() => { this.handleResize(); }, 100);
    }

    /**
     * 다른 의사를 선택한 경우
     */
    @Watch('selected_doctor')
    chagnedSelectedDoctor() : void {
        this.getAvailableAppointmentTime();
    }

    /**
     * 날짜 선택이 변경 된 경우
     */
    @Watch('selected_date')
    chagnedSelectedDate() : void {
        this.getAvailableAppointmentTime();

        try {
            const date : Date = this.day_list.filter((item) => item.getFullYear() == this.selected_date.getFullYear() &&
                                                        item.getMonth() == this.selected_date.getMonth() &&
                                                        item.getDate() == this.selected_date.getDate() )[0];

            
            this.current_scroll_week_index = Math.floor(this.day_list.indexOf(date) / 7);
            
            this.$refs.hooperSlide.slideTo(this.current_scroll_week_index);

            // @ts-ignore
            // $('#choose_day_scroll').mCustomScrollbar('scrollTo', `#choose_day_${this.current_scroll_week_index * 7}`);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        
    }

}
