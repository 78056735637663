
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import moment from 'moment';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        
    },
})
export default class AttendanceTotalListMonth extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 월 데이터
     */
    get computedMonth() : any[] {
        let month : any[] = [];

        if( this.date_string == null || this.date_string.length < 1 ) return [];

        const month_start = moment(`${this.date_string}.01`).toDate();
        const month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

        const start = month_start.getDate();
        const end = month_end.getDate()

        for( let i = start; i <= end; i++ ) {
            month.push({
                date : moment(month_start).add('day', i - 1).toDate(),
                day : this.getDayOfWeekByDate(moment(month_start).add('day', i - 1).toDate())
            });
        }

        return month;
    }
    
    /**
     * @AttendanceInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @AttendanceInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트

    date_string : string = "";

    attend_emp_month_info : any[] = [];
    lunar_date : Map<string, string> = new Map(); // 음력 Map
    holidays : Map<string, Object> = new Map(); // 공휴일 Map

    header_width : number = 0;

    resize_function_submit : boolean = false;

    departments : any[] = [];
    selected_dept_id : number = -1;

    show_attendance_time : boolean = false;

    async mounted() : Promise<void> {

        let show_attendance_time = hodu_local_storage.getItem("show_attendance_time");
        if( show_attendance_time != null && show_attendance_time.length > 0 ) {
            this.show_attendance_time = (show_attendance_time === 'true');
        }

        $(".teamLeft").click(function(){
            $(".dropdown").toggleClass("on");
        });

        this.date_string = moment(this.selected_date).format('YYYY.MM');
        this.datepickerInit();

        // 조회
        await this.getTotalData();

        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        this.$nextTick(() => {
            
            const title_height = $('#attendance .title_box').outerHeight();
            const schedule_box_height = $('#attendance_total_list_month .schedule_box').outerHeight();
            const sort_header_height = $('#attendance_total_list_month .sortHeader').outerHeight();

            console.log(`${title_height}px ${schedule_box_height}px ${sort_header_height}px`)

            const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                    - (schedule_box_height ? schedule_box_height : 0)
                                                    - (sort_header_height ? sort_header_height : 0) - 10;

            // @ts-ignore
            $('#attendance_list_year_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_year_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#totalCon_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#attendance_list_year_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            // @ts-ignore
            $('#totalCon_year_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#totalCon_year_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#attendance_list_year_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                        
                    }
                }
            });

            // @ts-ignore
            $('#attendance_list_year_right_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_year_right_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#totalCon_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            const left_div_width : number | undefined = $('#attendance_total_list_month .leftDiv').outerWidth();
            const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
            const right_area_controller_width : number | undefined = !this.right_control_box_flag ? $('#right_area_controller').outerWidth() : 0;

            const scroll_width : number = window.innerWidth - (left_div_width ? left_div_width : 0)
                                                            - (left_area_width ? left_area_width : 0) 
                                                            - (right_area_controller_width ? right_area_controller_width : 0);

            this.header_width = scroll_width;

            if( this.resize_function_submit == false ) { 
                this.event_bus.$on('totalListMonthResize', this.handleResize);
                this.resize_function_submit = true;

                if( this.date_string == this.hodu_date_to_format_string(new Date(), 'YYYY.MM') ) {
                    this.$nextTick(() => {
                        const last_date = new Date();
                        last_date.setMonth(last_date.getMonth() + 1);
                        last_date.setDate(0);
                        const width = document.getElementById('month_right_scroll_ul')?.offsetWidth ?? 0;

                        $('#month_right_scroll').scrollLeft((width * (new Date().getDate() / last_date.getDate())) - 60);
                    });
                }
            }
        });
        
    }

    /**
     * attend data 가져오기
     */
    @Watch('selected_dept_id')
    async getTotalData() : Promise<void> {
        try {
            await this.hodu_show_indicator();

            // @ts-ignore
            $('#attendance_list_month_right_scroll').mCustomScrollbar('destroy');

            let month_start = moment(`${this.date_string}.01`).toDate();
            let month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

            await Promise.all([this.getLunar(month_start, month_end), this.getHolidays(month_start, month_end), this.getOrganization()]);
            await this.getEmpAttendDataMonthInfo();

            this.$nextTick(() => {
                
                this.setScroll();

                if( this.date_string == this.hodu_date_to_format_string(new Date(), 'YYYY.MM') ) {
                    const last_date = new Date();
                    last_date.setMonth(last_date.getMonth() + 1);
                    last_date.setDate(0);
                    const width = document.getElementById('month_right_scroll_ul')?.offsetWidth ?? 0;
                    $('#month_right_scroll').scrollLeft((width * (new Date().getDate() / last_date.getDate())) - 60);
                }
                else {
                    $('#month_right_scroll').scrollLeft(0);
                }
            });            

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 이벤트(월 달력 일별 근태현황) 조회
     */
    async getEmpAttendDataMonthInfo() : Promise<void> {
        try {

            const request_date_string = this.date_string.replaceAll(/\./ig, "");

            // 오늘 날짜를 넣어서 오늘 이후의 데이터를 안나오게 하려다가 오늘 이후의 연차 까지 나와야해서 이 달력의 마지막 날까지 조회 하도록 변경
            const today_date_string = moment().format('YYYYMMDD');
            // const today_date_string = moment(`${this.date_string}.01`).add('month', 1).set('date', 0).format('YYYYMMDD');

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/data/month?month=${request_date_string}&today_date_string=${today_date_string}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_month_info ) {
                throw new Error("월별 통합 리스트 근태현황 조회 실패");
            }

            if( this.selected_dept_id >= 0 ) {
                response.data.data.attend_emp_month_info = response.data.data.attend_emp_month_info.filter(emp => emp.dept_id == this.selected_dept_id);
            }
            
            this.attend_emp_month_info.splice(0, this.attend_emp_month_info.length);
            this.attend_emp_month_info = this.attend_emp_month_info.concat(response.data.data.attend_emp_month_info);

            for( const month_info of this.attend_emp_month_info ) {
                for( const key in month_info.jsonb ) {

                    // 근태현황 시작일이 적용 안됐다면 전부 제외
                    if( this.getAttendanceStart() == '' ) {
                        delete(month_info.jsonb[key]);
                        continue;
                    }

                    // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                    if( Number(this.getAttendanceStart()) > Number(key) ) {
                        delete(month_info.jsonb[key]);
                        continue;
                    }
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 음력 구하기
     */
    async getLunar(start : Date, end : Date) : Promise<void> {

        start = start instanceof Date ? start : new Date(moment(start).format());
        end   = end   instanceof Date ? end   : new Date(moment(end).format());

        const lunar_objs : any[] = await this.hodu_solars_to_lunars(start, end);
        
        for( let lunar_obj of lunar_objs ) {
            this.lunar_date.set(lunar_obj.solar_ymd, lunar_obj.lunar_ymd);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {

        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            this.holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

    }

    /**
     * 날짜 선택
     */
    datepickerInit() : void {

        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const date_moment = moment(dateText);
                
                vue.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
                vue.doSetSelectedDate(date_moment.toDate());
                vue.date_string = moment(vue.selected_date).format('YYYY.MM');

                inst.input.val(vue.date_string);

                // @ts-ignore
                $('#datepicker').datepicker('setDate', vue.selected_date);

                // 조회
                vue.getTotalData();
            },
        };

        // @ts-ignore
        $('#attendance-list-month-current-date').datepicker(option);
    }

    /**
     * 이전 날짜
     */
    prev() : void {
        this.doSetSelectedDate?.(moment(this.selected_date).add('month', -1).toDate());
        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // 조회
        this.getTotalData();
    }

    /**
     * 다음 날짜
     */
    next() : void {
        this.doSetSelectedDate?.(moment(this.selected_date).add('month', 1).toDate());
        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // 조회
        this.getTotalData();
    }

    /**
     * 오늘
     */
    today() : void {
        this.doSetSelectedDate?.(new Date());
        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // 조회
        this.getTotalData();
    }

    getTotalLateCount(employee) {

        let count = 0;

        const keys = Object.keys(employee.jsonb);

        for( const key of keys ) {

            const obj_array = employee.jsonb[key];

            if( obj_array == null ) {
                continue;
            }

            for( const obj of obj_array ) {
                if( obj.attend_type == 10 && obj.attend_state == '30' ) {
                    count += obj.daycount;
                }
            }

        }

        return `${count}`;
    }

    /**
     * count 지각
     */
    getLateCount(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 && obj.attend_state == '30' ) {
                count += obj.daycount;
            }
        }

        return `${count}`;
    }

    getTotalAwolCount(employee) {

        let count = 0;

        const keys = Object.keys(employee.jsonb);

        for( const key of keys ) {

            const obj_array = employee.jsonb[key];

            if( obj_array == null ) {
                continue;
            }

            for( const obj of obj_array ) {
                if( obj.attend_type == 10 && obj.attend_state == '40' ) {
                    count += obj.daycount;
                }
            }

        }

        return `${count}`;
    }

    /**
     * count 결근
     */
    getAwolCount(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 && obj.attend_state == '40' ) {
                count += obj.daycount;
            }
        }

        return `${count}`;
    }

    getStartTime(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];

        if( obj_array == null ) {
            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let start_time = '';

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 ) {
                start_time = obj.start_time;
            }
        }

        if( start_time == null || start_time.length < 1 ) return '-';

        return `${start_time.substring(0,2)}:${start_time.substring(2,4)}`;
    }

    getEndTime(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];

        if( obj_array == null ) {
            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let end_time = '';

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 ) {
                end_time = obj.end_time;
            }
        }

        if( end_time == null || end_time.length < 1 ) return '-';

        return `${end_time.substring(0,2)}:${end_time.substring(2,4)}`;

    }

    isLate(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return false;
            }

            return false;
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 10 && obj.attend_state == '30' ) {
                count += obj.daycount;
            }
        }

        return count > 0;

    }

    getTotalVacationCount(employee) {

        let count = 0;

        const keys = Object.keys(employee.jsonb);

        for( const key of keys ) {

            const obj_array = employee.jsonb[key];

            if( obj_array == null ) {
                continue;
            }

            for( const obj of obj_array ) {
                if( obj.attend_type == 20 && obj.vacation_dedution_yn == true ) {
                    count += obj.daycount;
                }
            }

        }

        return `${count}`;
    }

    /**
     * count 휴가
     */
    getVacationCount(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 20 && obj.vacation_dedution_yn == true ) {
                count += obj.daycount;
            }
        }

        return `${count}`;
    }

    getTotalBusinessTripCount(employee) {

        let count = 0;

        const keys = Object.keys(employee.jsonb);

        for( const key of keys ) {

            const obj_array = employee.jsonb[key];

            if( obj_array == null ) {
                continue;
            }

            for( const obj of obj_array ) {
                if( obj.attend_type == 30 ) {
                    count += obj.daycount;
                }
            }

        }

        return `${count}`;
    }

    /**
     * count 출장
     */
    getBusinessTripCount(employee, day) {

        const yyyymmdd = this.hodu_date_to_format_string(day.date, 'YYYYMMDD');

        const obj_array = employee.jsonb[yyyymmdd];
        
        if( obj_array == null ) {

            if( Number(yyyymmdd) <= Number(this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')) ) {
                return '-';
            }

            return '';
        }

        let count = 0;

        for( const obj of obj_array ) {
            if( obj.attend_type == 30 ) {
                count += obj.daycount;
            }
        }

        return `${count}`;
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getAttendanceStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    getDeptName(dept_id : number) : string {

        if( dept_id == null || dept_id < 0 ) {
            return '전체 부서';
        }

        const filter = this.departments.filter(dept => dept.dept_id == dept_id);
        
        if( filter.length < 1 ) {
            return '';
        }

        return filter[0].dept_name;
    }

    async excelDownload() {
        
        if( this.attend_emp_month_info.length < 1 ) {
            return;
        }

        try {

            const dates : any[] = [];
            const employees : any[] = [];

            for( const day of this.computedMonth ) {
                dates.push({
                    "obj" : day,
                    "date": String(new Date(day.date).getDate()),
                    "day": day.day
                });
            }

            for( const emp of this.attend_emp_month_info ) {

                const employee : any = {
                    "obj" : emp,
                    "user_name" : emp.user_name,
                    "pos_name" : emp.pos_name,
                    "dept_name" : emp.dept_name,
                    "total": {
                        "vacation": this.getTotalVacationCount(emp),
                        "late": this.getTotalLateCount(emp),
                        "business_trip": this.getTotalBusinessTripCount(emp),
                        "awol": this.getTotalAwolCount(emp)          
                    },
                    "attend": {}
                };

                for( const day of this.computedMonth ) {
                    
                    employee.attend[String(day.date.getDate())] = {
                        "vacation": this.getVacationCount(emp, day),
                        "late": this.getLateCount(emp, day),
                        "business_trip": this.getBusinessTripCount(emp, day),
                        "awol": this.getAwolCount(emp, day),
                        "start_time" : this.getStartTime(emp, day),  
                        "end_time" : this.getEndTime(emp, day),  
                    }

                }

                employees.push(employee);
            }

            const body = {
                "name" : encodeURIComponent(`${this.date_string.replaceAll(".", "")}_호두_월별_통합_리스트.xlsx`),
                "sheet_name" : this.date_string,
                "date" : dates, 
                "emp" : employees,
                "show_attendance_time" : this.show_attendance_time
            }

            console.log(body);

            const excel_response = await this.$http({
                url : "api/v1/excel/attend/total_list_month",
                method : 'POST',
                responseType: 'blob',
                headers : {
                    "Cache-Control" : "no_cache"
                },
                data : body
            });

            console.log(excel_response);

            const blob = new Blob([excel_response.data]);

            // @ts-ignore
            if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, decodeURIComponent(body.name)); // for IE
                return;
            }

            await this.hodu_download_blob(blob, decodeURIComponent(body.name));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }

        
    }

    changeShowAttendanceTime(event) {
        hodu_local_storage.setItem("show_attendance_time", String(this.show_attendance_time));
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_taget.parent().find('p.img').css('background-image', `url(${replace_image})`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#attendance_list_year_left_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#totalCon_year_left_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#attendance_list_year_right_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

}
