
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info } from '@/model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class DoctorDetail extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDoctorTimeSettingModalInfo ?: (params : hodu_doc_modal_info.DoctorTimeSettingModalInfo) => void;
    @ModalInfo.Action doSetDoctorOffSettingModalInfo  ?: (params : hodu_doc_modal_info.DoctorOffSettingModalInfo) => void;
    @ModalInfo.Action doSetShowCommonColorPickerModal ?: (params : boolean) => void;

    mounted() : void {
        $(".checkDiv a").click(function(){
            var getIndex = $(this).index();
            $(this).toggleClass("on");
            if (getIndex == 2) {
                    $(this).siblings().removeClass("on");
            } else {
                
                
            }
        });
    }

    /**
     * 근무시간 추가
     */
    addWorkTime(event : any) : void {
        this.showDoctorTimeSettingModalInfo(true);
    }
    
    /**
     * 의사 근무 시간 설정 모달 보이기
     */
    showDoctorTimeSettingModalInfo(is_create_work_time : boolean) : void {
        if( this.doSetDoctorTimeSettingModalInfo ) { 
            // this.doSetDoctorTimeSettingModalInfo({
            //     "show_modal" : true,
            //     "is_create_work_time" : is_create_work_time
            // }); 
        }
    }

    /**
     * 휴가 관리
     */
    showDoctorOffSettingModal(is_create : boolean) : void {
        if( this.doSetDoctorOffSettingModalInfo ) { 
            this.doSetDoctorOffSettingModalInfo({
                "show_modal" : true,
                "is_create" : is_create,
            }); 
        }
    }

    /**
     * 색상 선택
     */
    showCommonColorPickerModal() : void {
        if( this.doSetShowCommonColorPickerModal ) { this.doSetShowCommonColorPickerModal(true); }
    }

}
