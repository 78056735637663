
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_object, hodu_doc_modal_info } from '@/model/hodudoc';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HospitalTimeSettingModal extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.State hospital_time_setting_modal_info !: hodu_doc_modal_info.HospitalTimeSettingModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHospitalTimeSettingModalInfo ?: (params : hodu_doc_modal_info.HospitalTimeSettingModalInfo) => void;

    last_select_value : string = "";

    selected_time_setting : hodu_doc_object.hospital_setting_info[] = [];
    selected_term : number = 15;
    selected_term_by_people : number = 10;

    selected_start_date : Date = new Date();
    selected_end_date : Date = new Date();
    is_deleteable : boolean = false;
    
    async mounted() : Promise<void> {
        const vue = this;

        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        // 진료시간 수정인데 현재 적용되고 있는 세팅이 없다면
        if( this.hospital_time_setting_modal_info.is_create == false && ( this.hospital_setting_info == null || this.hospital_setting_info.length < 7 ) ) {   
            this.closeModal();
            this.hodu_show_dialog('cancel', '진료시간 정보를 찾을 수 없습니다', ['확인'], [() => { this.$router.go(0); }]);
            return;
        }

        // 진료시간 추가일때
        if( this.hospital_time_setting_modal_info.is_create == true ) {
            
            // @ts-ignore 
            $('#hospital_time_setting_start_date').datepicker({
                inline: false,
                minDate : new Date(),
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: function (dateText, inst) : void {

                    const mnt : moment.Moment = moment(dateText);
                    inst.input.val(mnt.format('YYYY.MM.DD') + ` ${vue.getDayOfWeekByDate(mnt.toDate(), '요일')}`);
                    vue.selected_start_date = mnt.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();

                },
            });

            // 기본 세팅 추가
            for( let i = 0; i < 7; i++ ) {
                switch(i) {
                    case 0:
                    case 6:
                        this.selected_time_setting.push({
                            is_current_schedule : false,
                            from_to : "",
                            day_of_week : i,
                            start_am_time : null,
                            end_am_time : null,
                            start_pm_time : null,
                            end_pm_time : null,
                            start_date : moment().toDate(),
                            end_date : moment().add('year', 1).toDate(),
                            reservation_term : this.selected_term,
                            reservation_term_by_people : this.selected_term_by_people,
                            summary : ""
                        });
                        break;

                    default:
                        this.selected_time_setting.push({
                            is_current_schedule : false,
                            from_to : "",
                            day_of_week : i,
                            start_am_time : "0900",
                            end_am_time : "1300",
                            start_pm_time : "1400",
                            end_pm_time : "1800",
                            start_date : moment().toDate(),
                            end_date : moment().add('year', 1).toDate(),
                            reservation_term : this.selected_term,
                            reservation_term_by_people : this.selected_term_by_people,
                            summary : ""
                        })
                        break;
                }
            }
            this.selected_start_date = moment().toDate();
            return;
        }

        // 진료시간 수정일때
        if( this.hospital_time_setting_modal_info.is_create == false && this.hospital_setting_info != null && this.hospital_setting_info.length > 0 ) {

            this.selected_time_setting = this.selected_time_setting.concat(this.hospital_setting_info.filter(
                item => new Date(item.start_date).getTime() == this.hospital_time_setting_modal_info.start_date.getTime()
            ));

            this.selected_time_setting.sort((item1, item2) : number => {
                
                if( Number(item1.day_of_week) < Number(item2.day_of_week) ) {
                    return -1;
                }
                
                else if ( Number(item1.day_of_week) > Number(item2.day_of_week) ) {
                    return 1;
                }

                return 0;
            });
            
        }

        // 예약텀 세팅 데이터 받아오기
        if( this.selected_time_setting.length > 0 ) {
            const setting : hodu_doc_object.hospital_setting_info = this.selected_time_setting[0];
            this.selected_term = setting.reservation_term;
            this.selected_term_by_people = setting.reservation_term_by_people;
            this.selected_start_date = new Date(setting.start_date);
            this.selected_end_date = new Date(setting.end_date);

            // 삭제 가능 여부 
            this.is_deleteable = new Date().getTime() < this.selected_start_date.getTime();
        }

    }

    /**
     * 근무표 생성
     */
    createHospitalSettingInfo() : void {

        const setting : any[] = [];

        for( const selected_setting of this.selected_time_setting ) {
            setting.push({
                "day_of_week" : selected_setting.day_of_week,
                "start_am_time" : selected_setting.start_am_time,
                "end_am_time" : selected_setting.end_am_time,
                "start_pm_time" : selected_setting.start_pm_time,
                "end_pm_time" : selected_setting.end_pm_time,
            });
        }

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/timetable`, API_METHOD.POST, {
            "setting" : setting,
            "start" : moment(this.selected_start_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format(),
            "reservation_term_by_people" : this.selected_term_by_people 
        }).then((response) => {
            console.log(response);

            // 진료시간 페이지 재조회 시키기
            this.hodu_show_dialog('success', '근무표 생성 완료', ['확인'], [() => { this.$router.go(0); }]);
            
        }).catch((e) => {
            this.hodu_error_process(e, true, false, false, () => { this.$router.go(0); });
        }).finally(() => {
            this.closeModal();
        })

    }

    /**
     * 근무표 수정
     */
    updateHospitalSettingInfo() : void {

        const setting : any[] = [];

        for( const selected_setting of this.selected_time_setting ) {
            setting.push({
                "day_of_week" : selected_setting.day_of_week,
                "start_am_time" : selected_setting.start_am_time,
                "end_am_time" : selected_setting.end_am_time,
                "start_pm_time" : selected_setting.start_pm_time,
                "end_pm_time" : selected_setting.end_pm_time,
            });
        }

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/timetable`, API_METHOD.PUT, {
            "setting" : setting,
            "start" : moment(this.selected_start_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format(),
            "reservation_term_by_people" : this.selected_term_by_people 
        }).then((response) => {
            console.log(response);

            // 진료시간 페이지 재조회 시키기
            this.hodu_show_dialog('success', '근무표 수정 완료', ['확인'], [() => { this.$router.go(0); }]);
            
        }).catch((e) => {
            this.hodu_error_process(e, true, false, false, () => { this.$router.go(0); });
        }).finally(() => {
            this.closeModal();
        })

    }

    /**
     * 근무표 삭제
     */
    deleteHospitalSettingInfo() : void {

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/timetable`, API_METHOD.DELETE, {
            "start" : moment(this.selected_start_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format()
        }).then((response) => {
            console.log(response);

            // 진료시간 페이지 재조회 시키기
            this.hodu_show_dialog('success', '근무표 삭제 완료', ['확인'], [() => { this.$router.go(0); }]);
            
        }).catch((e) => {
            this.hodu_error_process(e, true, false, false, () => { this.$router.go(0); });
        }).finally(() => {
            this.closeModal();
        })

    }

    /**
     * 15분당 몇 명 설정
     */
    selectTermByPeople(n : number) : void {
        this.selected_term_by_people = n;
    }

    /**
     * 세팅 선택 여부
     */
    isSelectedSetting(setting : any) : boolean {
        
        const current_date : Date = new Date();

        return new Date(setting.start_date).getTime() <= current_date.getTime() && new Date(setting.end_date).getTime() >= current_date.getTime();
    }

    /**
     * 옵션 태그 오전 시간 문자열 반환
     */
    getTimeOptionAmText(n : number, is_start :boolean) : string {
        const target_date : Date = moment().set('hour', 7).set('minute', (n - 1) * 30 + ( is_start ? 0 : 30 )).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }

    /**
     * 옵션 태그 오후 시간 문자열 반환
     */
    getTimeOptionPmText(n : number, is_start :boolean) : string {
        const target_date : Date = moment().set('hour', 12).set('minute', (n - 1) * 30 + ( is_start ? 0 : 30 )).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }
    
    /**
     * 선택된 시간 여부 반환
     */
    isSelectedTime(HHmm : string | null, n : number, default_hour : number, default_min : number) : boolean {

        const target_string : string = moment().set('hour', default_hour).set('minute', (n - 1) * 30 + default_min).format('HHmm');

        // console.log(`${target_string}_${HHmm}`);

        return HHmm == target_string;
    }

    /**
     * 해당 Option의 시간 텍스트 HHmm을 value로 설정하기 위해 반환
     */
    valueOfSelect(n : number, default_hour : number, default_min : number) : string {
        return moment().set('hour', default_hour).set('minute', (n - 1) * 30 + default_min).format('HHmm');
    }

    /**
     * 오전 시작 시간 변경
     */
    changeAmStartTime(index : number, value : string) : void {

        if( this.selected_time_setting[index] == null ) { return; }
        const target_setting_info : hodu_doc_object.hospital_setting_info = JSON.parse(JSON.stringify(this.selected_time_setting[index]));
        
        // 휴원 선택시
        if( value == null || value.length < 1 ) {
            target_setting_info.start_am_time = null;
            target_setting_info.end_am_time = null;
            this.selected_time_setting.splice(index, 1, target_setting_info);
            return;
        }

        target_setting_info.start_am_time = value;

        // 오전 종료 시간이 없거나 오전 종료시간보다 미래의 시간으로 바꿨을때 오전 종료 시간 변경
        if( target_setting_info.end_am_time == null || Number(target_setting_info.end_am_time) <= Number(target_setting_info.start_am_time) ) {
            const target_number = Number(target_setting_info.start_am_time) + 30;
            target_setting_info.end_am_time = `00${target_number % 100 == 60 ? (target_number + 40) : target_number}`.slice(-4);
        } 

        // 오후 시작 시간 변경
        if( target_setting_info.start_pm_time != null && Number(target_setting_info.end_am_time) > Number(target_setting_info.start_pm_time) ) {
            const target_number = Number(target_setting_info.end_am_time) + 100;
            target_setting_info.start_pm_time = `00${target_number}`.slice(-4);
        }

        // 오후 종료 시간 변경
        if( target_setting_info.end_pm_time != null && Number(target_setting_info.start_pm_time) >= Number(target_setting_info.end_pm_time) ) {
            const target_number = Number(target_setting_info.start_pm_time) + 30;
            target_setting_info.end_pm_time = `00${target_number % 100 == 60 ? (target_number + 40) : target_number}`.slice(-4);
        }

        this.selected_time_setting.splice(index, 1, target_setting_info);
    }

    /**
     * 오전 종료 시간 변경
     */
    changeAmEndTime(index : number, value : string, target : HTMLElement) : void {
        if( this.selected_time_setting[index] == null ) { return; }
        const target_setting_info : hodu_doc_object.hospital_setting_info = JSON.parse(JSON.stringify(this.selected_time_setting[index]));

        // 휴원 선택시
        if( value == null || value.length < 1 ) {
            target_setting_info.start_am_time = null;
            target_setting_info.end_am_time = null;
            this.selected_time_setting.splice(index, 1, target_setting_info);
            return;
        }

        // 오전 종료 시간 변경
        if( Number(target_setting_info.start_pm_time) < Number(value) ) {
            alert("오후 근무 시작시간보다 늦을 수 없습니다")
            $(target).val(this.last_select_value).prop('selected', true);
            return;
        }

        target_setting_info.end_am_time = value;

        // 오전 시작 시간이 없거나 오전 시작 시간보다 과거의 시간으로 바꿨을때 오전 시작 시간 변경
        if( target_setting_info.start_am_time == null || Number(target_setting_info.end_am_time) <= Number(target_setting_info.start_am_time) ) {
            const target_number = Number(target_setting_info.end_am_time) - 30;
            target_setting_info.start_am_time = `00${target_number % 100 == 70 ? (target_number - 40) : target_number}`.slice(-4);
        }

        // 오후 시작 시간 변경
        // if( target_setting_info.start_pm_time != null && Number(target_setting_info.end_am_time) > Number(target_setting_info.start_pm_time) ) {
        //     const target_number = Number(target_setting_info.end_am_time) + 100;
        //     target_setting_info.start_pm_time = `00${target_number}`.slice(-4);
        // }

        // 오후 종료 시간 변경
        // if( target_setting_info.end_pm_time != null && Number(target_setting_info.start_pm_time) >= Number(target_setting_info.end_pm_time) ) {
        //     const target_number = Number(target_setting_info.start_pm_time) + 30;
        //     target_setting_info.end_pm_time = `00${target_number % 100 == 60 ? (target_number + 40) : target_number}`.slice(-4);
        // }

        this.selected_time_setting.splice(index, 1, target_setting_info);
    }

    /**
     * 오후 시작 시간 변경
     */
    changePmStartTime(index : number, value : string, target : HTMLElement) : void {
        if( this.selected_time_setting[index] == null ) { return; }
        const target_setting_info : hodu_doc_object.hospital_setting_info = JSON.parse(JSON.stringify(this.selected_time_setting[index]));

        // 휴원 선택시
        if( value == null || value.length < 1 ) {
            target_setting_info.start_pm_time = null;
            target_setting_info.end_pm_time = null;
            this.selected_time_setting.splice(index, 1, target_setting_info);
            return;
        }

        // 오전 종료 시간 변경
        if( Number(target_setting_info.end_am_time) > Number(value) ) {
            alert("오전 근무 종료시간보다 빠를 수 없습니다")
            $(target).val(this.last_select_value).prop('selected', true);
            return;
        }

        target_setting_info.start_pm_time = value;

        // 오후 종료 시간 변경
        if( target_setting_info.end_pm_time == null || Number(target_setting_info.start_pm_time) >= Number(target_setting_info.end_pm_time) ) {
            const target_number = Number(target_setting_info.start_pm_time) + 30;
            target_setting_info.end_pm_time = `00${target_number % 100 == 60 ? (target_number + 40) : target_number}`.slice(-4);
        }

        // 오전 종료 시간 변경
        // if( target_setting_info.start_pm_time != null && Number(target_setting_info.end_am_time) > Number(target_setting_info.start_pm_time) ) {
        //     const target_number = Number(target_setting_info.start_pm_time) - 100;
        //     target_setting_info.end_am_time = `00${target_number}`.slice(-4);
        // }
        
        // 오전 시작 시간 변경
        // if( target_setting_info.start_am_time != null && Number(target_setting_info.start_am_time) >= Number(target_setting_info.end_am_time) ) {
        //     const target_number = Number(target_setting_info.end_am_time) - 30;
        //     target_setting_info.start_am_time = `00${target_number % 100 == 70 ? (target_number - 40) : target_number}`.slice(-4);
        // }

        this.selected_time_setting.splice(index, 1, target_setting_info);
    }

    /**
     * 오후 종료 시간 변경
     */
    changePmEndTime(index : number, value : string) : void {
        if( this.selected_time_setting[index] == null ) { return; }
        const target_setting_info : hodu_doc_object.hospital_setting_info = JSON.parse(JSON.stringify(this.selected_time_setting[index]));
        target_setting_info.end_pm_time = value;

        // 휴원 선택시
        if( value == null || value.length < 1 ) {
            target_setting_info.start_pm_time = null;
            target_setting_info.end_pm_time = null;
            this.selected_time_setting.splice(index, 1, target_setting_info);
            return;
        }

        // 오후 시작 시간 변경
        if( target_setting_info.start_pm_time == null || Number(target_setting_info.start_pm_time) >= Number(target_setting_info.end_pm_time) ) {
            const target_number = Number(target_setting_info.end_pm_time) - 30;
            target_setting_info.start_pm_time = `00${target_number % 100 == 70 ? (target_number - 40) : target_number}`.slice(-4);
        }

        // 오전 종료 시간 변경
        if( target_setting_info.start_pm_time != null && Number(target_setting_info.end_am_time) > Number(target_setting_info.start_pm_time) ) {
            const target_number = Number(target_setting_info.start_pm_time) - 100;
            target_setting_info.end_am_time = `00${target_number}`.slice(-4);
        }
        
        // 오전 시작 시간 변경
        if( target_setting_info.start_am_time != null && Number(target_setting_info.start_am_time) >= Number(target_setting_info.end_am_time) ) {
            const target_number = Number(target_setting_info.end_am_time) - 30;
            target_setting_info.start_am_time = `00${target_number % 100 == 70 ? (target_number - 40) : target_number}`.slice(-4);
        }

        this.selected_time_setting.splice(index, 1, target_setting_info);
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetHospitalTimeSettingModalInfo == null ) { return; }

        this.doSetHospitalTimeSettingModalInfo({ "show_modal" : false, "start_date" : new Date(), "is_create" : true, }); 
    }

    /**
     * 근무표 삭제
     */
    deleteHospitalTimetable() : void {
        if( confirm("근무표를 삭제 하시겠습니까?") == true ) {
            this.deleteHospitalSettingInfo();
        } 
    }

    /**
     * 근무표 생성 또는 수정
     */
    createOrUpdateHospitalTimetable() : void {
        
        // 근무표 생성
        if( this.hospital_time_setting_modal_info.is_create ) {
            this.createHospitalSettingInfo();
            return;
        }

        // 근무표 수정
        this.updateHospitalSettingInfo();

    }

}
