
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo    = namespace('ModalInfo');

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';

import { hodu_doc_modal_info } from '@/model/hodudoc';
import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HoduInlineDatePicker
    },
}) 
export default class DayOffSettingModal extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State day_off_setting_modal_info !: hodu_doc_modal_info.DayOffSettingModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDayOffSettingModalInfo    ?: (day_off_setting_modal_info : hodu_doc_modal_info.DayOffSettingModalInfo) => void;

    selectedStartAmPm : string = "AM"
    selectedEndAmPm : string = "AM"
    
    is_focused_start: boolean = true
    amPmAll : any;
    hours   : any;
    minutes : any;

    event_bus : Vue = new Vue();

    created() : void {
        this.amPmAll = [{key: "오전", value: "AM"}, {key: "오후", value: "PM"}] //{key: "종일", value: "AllDay"}
        this.hours   = [{key: "1", value: 1}, {key: "2", value: 2}, {key: "3", value: 3}, {key: "4", value: 4}, {key: "5", value: 5}, {key: "6", value: 6}, {key: "7", value: 7}, {key: "8", value: 8}, {key: "9", value: 9}, {key: "10", value: 10}, {key: "11", value: 11}, {key: "12", value: 12}]
        this.minutes = [{key: "00", value: 0}, {key: "10", value: 10}, {key: "20", value: 20}, {key: "30", value: 30}, {key: "40", value: 40}, {key: "50", value: 50}]
    }

    mounted() : void {
        // 오른쪽 시간선택시 
        $(".timeDiv div input").click(function(){
            $(this).parent().siblings().removeClass("on");
            $(this).parent().addClass("on");
            $(this).parent().siblings().find("input").removeClass("on");
            $(this).addClass("on");

        });
        if (this.day_off_setting_modal_info.is_create && this.day_off_setting_modal_info.day_off == null) {
            const start_date = moment(new Date()).set("hours",0).set("minutes",0).set("seconds",0).set("milliseconds",0).toDate();
            const end_Date   = moment(start_date).set("hours",23).set("minutes",59).set("seconds",59).set("milliseconds",0).toDate();

            this.day_off_setting_modal_info.day_off = {
                doctor_off_id   : "",
                biz_id          : "",
                department_code : "",
                doctor_code     : "",
                off_name        : "",
                off_reason      : "",
                start_date      : start_date,
                end_date        : end_Date,
                audit_created   : new Date(),
                audit_created_id: 0,
                audit_deleted   : "",
                audit_deleted_id : 0,
                audit_delete_flag : false
            }
            
        }else{
            
        }

        if (this.day_off_setting_modal_info.day_off != null) {
            this.selectedStartAmPm = moment(this.day_off_setting_modal_info.day_off.start_date).get("hours") >= 12 ? "PM" : "AM"
            this.selectedEndAmPm   = moment(this.day_off_setting_modal_info.day_off.end_date).get("hours") >= 12 ? "PM" : "AM"
            // alert(`${this.selectedStartAmPm} / ${this.selectedEndAmPm}`)
        }
    }

    /**
     * 탭 변경으로 왼쪽 데이트피커 날짜 변경
     */
    changeDate(is_start: boolean) : void {
        this.is_focused_start = is_start
        if ( this.day_off_setting_modal_info.day_off == null ) {
            return 
        }
        if ( is_start ) {
            this.event_bus.$emit('changeDate', this.day_off_setting_modal_info.day_off.start_date);
        }else{
            this.event_bus.$emit('changeDate', this.day_off_setting_modal_info.day_off.end_date);
        }
    }

    changeStartAmPm(event) : void {
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return
        }
        this.selectedStartAmPm = event.target.value as string
        const start_date  = this.day_off_setting_modal_info.day_off.start_date
        const start_hours = moment(start_date).get("hours")
        if (event.target.value == "AM" && start_hours > 12) {
            this.day_off_setting_modal_info.day_off.start_date = moment(start_date).set("hours", start_hours-12).toDate()
        }
        if (event.target.value == "PM" && start_hours < 12) {
            this.day_off_setting_modal_info.day_off.start_date = moment(start_date).set("hours", start_hours+12).toDate()
        }
        if (event.target.value == "AM" && start_hours == 12) {
            this.day_off_setting_modal_info.day_off.start_date = moment(start_date).set("hours", 0).toDate()
        }
    }

    changeStartHour(event) : void {
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return 
        }
        let selected_hour = Number(event.target.value)
        //pm 12시는 점심 12시니까 그대로 12시이고 나머지는 (12 + n)시
        if (this.selectedStartAmPm == "PM" && selected_hour < 12) {
            selected_hour = selected_hour + 12
        }
        //am 12시는 밤 12시 (자정) 이니까 00시
        else if ( this.selectedStartAmPm == "AM" && selected_hour == 12 ) {
            selected_hour = 0
        }
        
        let origin_start_date = this.day_off_setting_modal_info.day_off.start_date
        this.day_off_setting_modal_info.day_off.start_date = moment(origin_start_date).set("hours", selected_hour).toDate()
    }

    changeStartMin(event) : void { 
        const selected_min = event.target.value as number
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return 
        }

        let origin_start_date = this.day_off_setting_modal_info.day_off.start_date
        this.day_off_setting_modal_info.day_off.start_date = moment(origin_start_date).set("minutes", selected_min).toDate()
    }

    changeEndAmPm(event) : void {
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return
        }
        this.selectedEndAmPm = event.target.value as string
        const end_date  = this.day_off_setting_modal_info.day_off.end_date
        const end_hours = moment(end_date).get("hours")
        if (event.target.value == "AM" && end_hours > 12) {
            this.day_off_setting_modal_info.day_off.end_date = moment(end_date).set("hours", end_hours-12).toDate()
        }
        if (event.target.value == "PM" && end_hours < 12) {
            this.day_off_setting_modal_info.day_off.end_date = moment(end_date).set("hours", end_hours+12).toDate()
        }
        if (event.target.value == "AM" && end_hours == 12) {
            this.day_off_setting_modal_info.day_off.end_date = moment(end_date).set("hours", 0).toDate()
        }
    }

    changeEndHour(event) : void {
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return 
        }
        let selected_hour = Number(event.target.value)
        //pm 12시는 점심 12시니까 그대로 12시이고 나머지는 (12 + n)시
        if (this.selectedEndAmPm == "PM" && selected_hour < 12) {
            selected_hour = selected_hour + 12
        }
        //am 12시는 밤 12시 (자정) 이니까 00시
        else if ( this.selectedEndAmPm == "AM" && selected_hour == 12 ) {
            selected_hour = 0
        }
        
        let origin_end_date = this.day_off_setting_modal_info.day_off.end_date
        this.day_off_setting_modal_info.day_off.end_date = moment(origin_end_date).set("hours", selected_hour).toDate()
    }

    changeEndMin(event) : void { 
        const selected_min = event.target.value as number
        if ( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return 
        }

        let origin_end_date = this.day_off_setting_modal_info.day_off.end_date
        this.day_off_setting_modal_info.day_off.end_date = moment(origin_end_date).set("minutes", selected_min).toDate()
    }

    isStartHour(selected_hour : number) : boolean {
        if( this.day_off_setting_modal_info.day_off == null ) {
            return false
        }
        let start_hour = moment(this.day_off_setting_modal_info.day_off.start_date).get("hours")

        //시작일이 오후일 때엔 선택일도 오후로 교체 후 비교
        if (start_hour > 12) {
            selected_hour += 12
            return selected_hour == start_hour
        }
        // 시작 시간이 am 0시 or pm 0시 라면 무조건 12로 표기
        else if (start_hour == 0 || start_hour == 12) {
            start_hour = 12
            return selected_hour == start_hour
        }
        //나머지는 그대로
        else{
            return selected_hour == start_hour
        }
    }

    isStartMin(min : number) : boolean {
        if( this.day_off_setting_modal_info.day_off == null ) {
            return false
        }
        let start_min = moment(this.day_off_setting_modal_info.day_off.start_date).get("minutes")
        return min == start_min
    }

    isEndMin(min : number) : boolean {
        if( this.day_off_setting_modal_info.day_off == null ) {
            return false
        }
        let end_min = moment(this.day_off_setting_modal_info.day_off.end_date).get("minutes")
        return min == end_min
    }

    isEndHour(selected_hour : number) : boolean {
        if( this.day_off_setting_modal_info.day_off == null ) {
            return false
        }
        let end_hour = moment(this.day_off_setting_modal_info.day_off.end_date).get("hours")
        
        //시작일이 오후일 때엔 선택일도 오후로 교체 후 비교
        if (end_hour > 12) {
            selected_hour += 12
            return selected_hour == end_hour
        }
        // 시작 시간이 am 0시 or pm 0시 라면 무조건 12로 표기
        else if (end_hour == 0 || end_hour == 12) {
            end_hour = 12
            return selected_hour == end_hour
        }
        //나머지는 그대로
        else{
            return selected_hour == end_hour
        }
    }

    getDateString(is_start: boolean) : string {
        if( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return "" 
        }

        if ( is_start ) {
            let date = moment(this.day_off_setting_modal_info.day_off.start_date).format("YYYY.MM.DD ")
            let dowName = this.getDayName(moment(this.day_off_setting_modal_info.day_off.start_date).get("day"), "요일")
            return date + dowName + " 부터"

        }else{
            let date = moment(this.day_off_setting_modal_info.day_off.end_date).format("YYYY.MM.DD ")
            let dowName = this.getDayName(moment(this.day_off_setting_modal_info.day_off.end_date).get("day"), "요일")
            return date + dowName + " 까지"
        }
    }

    /**
     * datepicker 선택 emit 함수
     */
    datepickerSelectFunction(date_string : string , data : any) : void {
        if( this.day_off_setting_modal_info == null || this.day_off_setting_modal_info.day_off == null ) {
            return
        }
        let selected_date = moment(date_string).toDate()
        let start_date    = new Date(this.day_off_setting_modal_info.day_off.start_date)
        let end_date      = new Date(this.day_off_setting_modal_info.day_off.end_date)

        //시작일 선택시
        if (this.is_focused_start) {
            //시작일에 시간 말고 날짜만 세팅해주고
            start_date = moment(start_date).set("years", selected_date.getFullYear()).set("months", selected_date.getMonth()).set("date", selected_date.getDate()).toDate()
            this.day_off_setting_modal_info.day_off.start_date = start_date

            //! 종료일이 시작일보다 전에 있거나 같으면 다시 뒤로 옮겨준다
            if( moment(start_date).isBefore(end_date) == false ) {
                this.day_off_setting_modal_info.day_off.end_date = moment(start_date).set("hours", 23).set("minutes", 59).set("seconds", 59).toDate()
            }
        }
        //종료일 선택시
        else{
            selected_date = moment(end_date).set("years", selected_date.getFullYear()).set("months", selected_date.getMonth()).set("date", selected_date.getDate()).toDate()

            if( moment(start_date).isBefore(selected_date) ) {
                this.day_off_setting_modal_info.day_off.end_date = selected_date
                
            }else{
                this.day_off_setting_modal_info.day_off.start_date = moment(selected_date).set("hours", 0).set("minutes", 0).set("seconds", 0).toDate()
                this.day_off_setting_modal_info.day_off.end_date = moment(selected_date).set("hours", 23).set("minutes", 59).set("seconds", 59).toDate()
                // this.event_bus.$emit('changeDate', this.day_off_setting_modal_info.day_off.end_date);
            }
        }
    }   
    
    /**
     * datepicker 년, 월 변경 emit 함수
     */
    datepickerChangeFunction(year: number, month: number, inst: any) : void {
        // alert(`${`0${year}`.slice(-2)}년 ${`0${month}`.slice(-2)}월`);
    }

    cancel() : void {
        if( this.doSetDayOffSettingModalInfo ) {
            this.doSetDayOffSettingModalInfo({
                "show_modal" : false,
                "is_create"  : true,
                "is_doctor"  : true,
                "day_off"    : null
            }); 
        }
    }

    validationCheck() : boolean {
        if(this.day_off_setting_modal_info.day_off == null) { return false }
        if( moment(this.day_off_setting_modal_info.day_off.start_date).isBefore(this.day_off_setting_modal_info.day_off.end_date) == false ) {
            alert("종료 시간이 시작 시간보다 빠르거나 같습니다\n다시 설정해주세요")
            return false
        }
        if( this.day_off_setting_modal_info.day_off.off_name.length < 2 ) {
            alert("휴가 이름을 2자 이상 작성해주세요")
            return false
        }
        return true
    }

    createDayOff() : void {
        if (this.day_off_setting_modal_info == null) { return }
        if (this.validationCheck()){
            window['createDayOff'](this.day_off_setting_modal_info.day_off)
            this.cancel()
        }
    }

    updateDayOff() : void {
        if (this.day_off_setting_modal_info == null) { return }
        if (this.validationCheck() == false) { return }
        window['updateDayOff'](this.day_off_setting_modal_info.day_off)
        this.cancel()
    }

    deleteDayOff() : void {
        if (this.day_off_setting_modal_info.day_off == null) { return }
        
        if (confirm(`[${this.day_off_setting_modal_info.day_off.off_name}] 휴가를 삭제하시겠습니까?`)) {
            window['deleteDayOff'](this.day_off_setting_modal_info.day_off)
            this.cancel()
        }
    }

    inputOffName(event) {
        const value = event.target.value;
        
        if( this.day_off_setting_modal_info.day_off != null ) {
            this.day_off_setting_modal_info.day_off.off_name = value;
        }
    }

    inputOffReason(event) {
        const value = event.target.value;
        
        if( this.day_off_setting_modal_info.day_off != null ) {
            this.day_off_setting_modal_info.day_off.off_reason = value;
        }
    }

}
