
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

import { t_event_vote } from '@/model/event';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import moment from 'moment';

@Component({

}) export default class EventVoteCreate extends Mixins(VueHoduCommon) {
    
    @EventInfo.State vote                !: t_event_vote
    @EventInfo.State vote_end_date_limit !: Date
    @EventInfo.State vote_modify_index   !: number
    @EventInfo.State event_crud_type     !: string

    @ModalInfo.Action doSetShowEventVoteCreate ?: any;

    vote_item_model : string[] = [];

    time_picker_open : boolean = false;

    vote_end_date_text : string = "";
    vote_end_time_text : string = "";
    vote_end_hour      : string = "";
    vote_end_min       : string = "";
    vote_end_am_pm     : string = ""; 

    mounted() {
        const vue = this;

        // 아이템이 4개가 안된다면 강제로 4개를 맞춘다
        if( this.vote.items.length < 4 ) {
            const repeat_count : number = 4 - this.vote.items.length;
            for( let i = 0; i < repeat_count; i++ ) {
                this.vote.items.push("" as string & Date);
            }
        }

        const item_length : number = this.vote.items.length;
        for( let i = 0; i < item_length; i++ ) {
            this.vote_item_model.push(this.vote.items[i] as string);
        }

        let scrollerOption = {
            "axis" : 'y',
            "setHeight" : 464,
            "scrollbarPosition" : 'outside'
        };

        // @ts-ignore
        $('#regisVoteScroll').mCustomScrollbar(scrollerOption);
        
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const select_date : Date = new Date(dateText); 

                // 선택된 날짜에 시간 적용
                select_date.setHours(new Date(vue.vote.end_date).getHours());
                select_date.setMinutes(new Date(vue.vote.end_date).getMinutes());

                inst.input.val(`${dateText} ${vue.getDayOfWeek(select_date)}요일`);
                vue.vote.end_date = select_date;
                vue.makeDateString();
            }
        };

        // @ts-ignore
        $('#voteEndDate_1').datepicker(option);

        this.vote_end_date_text = `${moment(vue.vote.end_date).format('YYYY.MM.DD')} ${vue.getDayOfWeek(new Date(vue.vote.end_date))}요일`;
        this.makeDateString();
    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * 날짜 텍스트 만들기
     */
    makeDateString() : void {
        const vote_end : Date = new Date(new Date(this.vote.end_date).getTime());

        this.vote_end_date_text  = `${vote_end.getFullYear()}.${`0${vote_end.getMonth() + 1}`.slice(-2)}.${`0${vote_end.getDate()}`.slice(-2)} ${this.getDayOfWeek(vote_end)}요일`;
        this.vote_end_hour       = `0${vote_end.getHours() <= 12 ? ( vote_end.getHours() == 0 ? 12 : vote_end.getHours() ) : vote_end.getHours() - 12}`.slice(-2);
        this.vote_end_min        = `0${vote_end.getMinutes()}`.slice(-2);
        this.vote_end_am_pm      = vote_end.getHours() < 12 ? "AM" : "PM";
        const string_vote_end_am_pm = vote_end.getHours() < 12 ? "오전" : "오후";
        this.vote_end_time_text  = `${string_vote_end_am_pm} ${this.vote_end_hour}:${this.vote_end_min}`;
    }

    // timepicker ON / OFF
    timePickerToggle() : void {
        this.time_picker_open = !this.time_picker_open;
    }

    /**
     * 투표 종료일 시간 변경
     */
    selectVoteEndTimeHour(event) : void {
        const endHours : number = this.vote_end_am_pm == "AM" && Number(event.target.value) == 12 
                                  ? 0 
                                  : (this.vote_end_am_pm == "AM" && Number(event.target.value) != 12) || (this.vote_end_am_pm == "PM" && Number(event.target.value) == 12)
                                    ? Number(event.target.value)
                                    : Number(event.target.value) + 12;

        const end : Date = new Date(this.vote.end_date); 
        end.setHours(endHours);
        
        // 날짜 세팅
        this.vote.end_date= end;
        this.makeDateString();
    }

    /**
     * 투표 종료일 분 변경
     */
    selectVoteEndTimeMin(event) : void {
        const end : Date = new Date(new Date(this.vote.end_date).getTime()); 
        end.setMinutes(Number(event.target.value));

        // 날짜 세팅
        this.vote.end_date = end;
        this.makeDateString();
    }

    /**
     * 투표 종료일 오전 오후 변경
     */
    selectVoteEndTimeAmPm(event) : void {

        // 이미 같은 값이라면 return
        if( this.vote_end_am_pm == event.target.value ){
            return;
        }

        const end : Date = new Date(new Date(this.vote.end_date).getTime()); 

        switch( event.target.value ){
            case "AM":
                end.setHours(end.getHours() - 12);
                break;
            
            case "PM":
                end.setHours(end.getHours() + 12);
                break;
        }

        // 날짜 세팅
        this.vote.end_date = end;
        this.makeDateString();
    }   

    /**
     * 투표 세팅 값 전체 초기화
     */
    voteDataReset() : void {
        this.vote.end_date = new Date(this.vote_end_date_limit.getTime());
        this.vote.title = "";
        
        this.vote_item_model.splice(0, this.vote.items.length);
        this.vote.items.splice(0, this.vote.items.length);

        for( let i = 0; i < 4; i++ ) {
            this.vote_item_model.push("");
            this.vote.items.push("" as string & Date);
        }

        this.vote.ing_report = false;
        this.vote.multiple   = false;
        this.vote.secret     = true;

        this.makeDateString();
    }
    
    /**
     * 투표 제목 제거  
     */    
    voteTitleRemove() : void {
        this.vote.title = "";
    }

    /**
     * 투표 항목 추가
     */
    voteItemAdd() : void {
        if( this.vote.items.length >= 20 ) {
            alert("투표/설문 항목은 20개까지 사용 가능합니다");
            return;
        }

        this.vote.items.push("" as string & Date);
        this.vote_item_model.push("");

        // @ts-ignore
        setTimeout(() => { $('#regisVoteScroll').mCustomScrollbar("scrollTo", "bottom"); }, 75);
    }

    /**
     * 투표 항목 내용 삭제
     */
    voteContentDelete(index : number) {
        this.vote_item_model.splice(index, 1, "");
    }

    /**
     * Modal 닫기
     */
    voteModalCancel() : void {
        this.doSetShowEventVoteCreate(false);
    }

    /**
     * 투표 삭제
     */
    voteDelete() : void {
        window['deleteVote'](this.vote_modify_index);
    }

    /**
     * 투표 저장
     */
    voteSave() : void {
        // 투표 종료 제한 시간(현재 시간) 보다 작은 경우
        if( new Date(this.vote.end_date).getTime() <= new Date().getTime() ) {
            alert("투표/설문 종료일이 현재 시간 보다 빠를 수 없습니다");
            return;
        }

        // 투표 종료 제한 시간(일정 종료일) 보다 큰 경우
        if( new Date(this.vote.end_date).getTime() > this.vote_end_date_limit.getTime() ) {
            alert("투표/설문 종료일이 일정 종료일 보다 늦을 수 없습니다");
            return;
        }

        // 투표 제목 체크
        if( this.vote.title.trim().length < 1 ) {
            alert("투표/설문 제목을 입력 해 주세요");
            return;
        }

        // 투표 아이템 체크
        const item_count       : number   = this.vote_item_model.length;
        let   valid_item_count : number   = 0;
        let   vote_items       : string[] = [];
        for( let i = 0; i < item_count; i++ ) {
            if( this.vote_item_model[i].trim().length > 0 ) {
                valid_item_count++;
                vote_items.push(this.vote_item_model[i].trim());
            } 
        }
        
        // 올바르게 작성 된 항목이 2개 미만일때
        if( valid_item_count < 2 ) {
            alert("투표/설문 항목을 2개 이상 입력 해 주세요");
            return;
        } 

        const vote_data : t_event_vote = JSON.parse(JSON.stringify(this.vote));
        vote_data.items = vote_items;
        
        if( this.vote_modify_index != -1 ) {
            window['updateVote'](this.vote_modify_index, vote_data);
            return;
        }

        window['createVote'](vote_data);
    }

}
