
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');
const ModalInfo = namespace('ModalInfo');

import PatientListComp from '@/components/hodu_d/PatientListComp.vue';
import HospitalMessageStatus from '@/components/hodu_d/HospitalMessageStatus.vue';
import HospitalMessageTemplate from '@/components/hodu_d/HospitalMessageTemplate.vue';
import PatientFileBox from '@/components/hodu_d/PatientFileBox.vue';

import { hodu_doc_modal_info, hodu_doc_object } from '@/model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        PatientListComp, HospitalMessageStatus, HospitalMessageTemplate, PatientFileBox
    },
}) 
export default class PatientList extends Mixins(VueHoduCommon) {

    /**
     * @HoduDocInfo.State
     */
    @HoduDocInfo.State patient_tab !: "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX";

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetPatientTab ?: (parms : "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX") => void;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetPatientSendMessageModalInfo ?: (params : hodu_doc_modal_info.PatientSendMessageModalInfo) => void;

    event_bus : Vue = new Vue();

    is_search_open : boolean = false;
    search_word : string = "";

    is_file_list_card_mode : boolean = false;

    mounted() : void {

    }

    /**
     * 탭 변경
     */
    setPatientTab(tab : "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX") : void {
        if( this.doSetPatientTab ) { this.doSetPatientTab(tab); }

        if( tab != "PATIENT_FILEBOX" ) {
            this.is_file_list_card_mode = false;
        }

        this.is_search_open = false;
        this.search_word = "";
    }

    /**
     * 메세지 발송을 위해 선택된 환자 리스트 요청
     */
    requestSelectedPatients() : void {
        this.event_bus.$emit('getSelectedPatient');
    }

    /**
     * 리스트 요청 완료시 메세지 발송 Modal 띄우기
     */
    showPatientSendMessageModal(selected_patients : hodu_doc_object.patient_info[]) : void {
        if( selected_patients == null || selected_patients.length < 1 ) {
            this.hodu_show_dialog('alert', '선택 된 환자가 없습니다', ['확인']);
            return;
        }

        if( this.doSetPatientSendMessageModalInfo == null ) { return; }

        this.doSetPatientSendMessageModalInfo({
            show_modal : true,
            selected_patients : JSON.parse(JSON.stringify(selected_patients)),
        });
    }

    /**
     * 최상단 클래스 탭에 따라서 소문자로 반환
     */
    getTopClass() : string {
        return this.patient_tab.toLowerCase();
    }

    /**
     * 다운로드 버튼 클릭
     */
    downloadButtonClick() : void {

        if ( this.patient_tab != 'PATIENT_FILEBOX' ) { return; }
        this.event_bus.$emit('patientFileDownload');

    }

    /**
     * 삭제 버튼 클릭
     */
    deleteButtonClick() : void {
        
        // 환자 리스트에서 삭제
        if( this.patient_tab == 'LIST' ) {
            this.event_bus.$emit('patientDelete');
        }

        // 환자 파일함에서 삭제
        else if ( this.patient_tab == 'PATIENT_FILEBOX' ) {
            this.event_bus.$emit('patientFileDelete');
        }
    }

    /**
     * 파일리스트 보기 모드 변경
     */
    fileListCardModeOnOff() : void {
        this.is_file_list_card_mode = !this.is_file_list_card_mode;
    }

    /**
     * 검색창 열기 / 닫기
     */
    searchOnOff() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_word = "";
            this.event_bus.$emit('searchPatientList');
        }

        // 검색 창 열릴때 검색 창에 포커스 가도록
        else {
            this.$nextTick(() => { $('#patient_search_input').focus(); });
        }
    }

    /**
     * 검색 
     */
    searchPatientList() : void {
        
        // 환자 리스트에서만 검색 가능
        if( this.patient_tab != 'LIST' ) { return; }

        this.event_bus.$emit('searchPatientList', this.search_word);
    }

    /**
     * 예약 검색 keydown
     */
    eventKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        // 검색
        this.searchPatientList();
    }

}
