
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, EVENT_SUB_TYPE, CRUD_TYPE, GROUP_TYPE, API_METHOD, SHARE_OPTION } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const EventInfo    = namespace('EventInfo');
const HoduDocInfo  = namespace('HoduDocInfo');

import { ScheduleSearchConfig, GroupAndTeamFilter, EventSearchOption } from '@/store/modules/CalendarInfo';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { t_event, t_event_alarm, t_event_location, t_event_contact, t_event_vote } from '@/model/event';
 
const dateFormat = require('dateformat');
import moment from 'moment';
import 'moment-lunar';

import { RRule, RRuleSet, rrulestr } from 'rrule'

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class EventSearch extends Mixins(VueHoduCommon) {
    
    /**
     * CalendarInfo.State
     */
    @CalendarInfo.State is_event_filter_search !: boolean;              // 일정 필터 검색 여부
    @CalendarInfo.State event_search_query     !: string;               // 일정 검색 쿼리  
    @CalendarInfo.State event_search_option    !: EventSearchOption;    // 일정 검색 옵션
 
    /** 
     * CalendarInfo.Action 
     */
    @CalendarInfo.Action doSetIsEventFilterSearch ?: (a : boolean) => void; // is_event_search_filter 업데이트 
    @CalendarInfo.Action doSetEventSearchQuery    ?: any;                   // event_search_query 업데이트
    @CalendarInfo.Action doSelectScheduleList     ?: any;                   // schedule_search_config 업데이트
    @CalendarInfo.Action doSetEventSearchOption   ?: any;                   // event_search_option 업데이트 

    /**
     * EventInfo.Action
     */
    @EventInfo.Action doSetIsFirstRepeatEvent ?: any; // 조회하는 반복일정이 해당 반복일정의 첫 일정이였다면 true 아니라면 false
    @EventInfo.Action doSetEventOriginalDate  ?: any; // 반복일정 조회시 해당 일정의 원본 date들을 설정

    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;

    event_search_query_temp    : string = "";
    render_events              : any[]     = [];
    events                     : t_event[] = [];
    last_event_id              : string    = "";
    is_paging_end              : boolean   = false;
    is_api_call                : boolean   = false;
    is_first_api_call_complete : boolean   = false;
    
    start_date_text : string | null = null;
    end_date_text   : string | null = null;

    get computedGroupAndTeam() : any[] {
        let group_and_team : any[] = [];

        const group_length : number = this.all_group_data.length;
        for( let i = 0; i < group_length; i++ ) {
            const group_data : any = this.all_group_data[i];
            group_and_team.push(group_data);

            if( group_data.teams == null || group_data.teams.length < 1 ) { continue; }

            const team_length : number = group_data.teams.length;
            for( let j = 0; j < team_length; j++ ) { group_and_team.push(group_data.teams[j]); }
        }

        return group_and_team;
    }

    searchOptionVisible : boolean = false; // 일정검색 옵션창 보일지 여부
    
    async mounted() : Promise<void> {
        await this.setScroll();
        if( this.is_event_filter_search == false ) { await this.searchEventForWeb(); }
        else { await this.searchEventForWebByFilter(); }

        $('#event_search').focus();
        this.event_search_query_temp = this.event_search_query;
    }

    /**
     * 스크롤 설정
     */
    async setScroll() : Promise<void> {
        const title_height  : number | undefined = $('.title_box').outerHeight();
        const search_height : number | undefined = $('.searchDiv').outerHeight();

        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ) - ( search_height == null ? 0 : search_height ),
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            callbacks : {
                whileScrolling: this.whileScrolling
            }
        });

    }

    /**
     * 일정 검색 keydown
     */
    eventKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.newSearchEvent();
    }

    /**
     * 새롭게 일정 검색 
     * select_by_config_change == true라면 설정 변경으로 인해 다시 조회 하는것
     */
    async newSearchEvent(select_by_config_change : boolean = false) : Promise<void> {
        this.$nextTick(async() => {
            if( this.is_api_call == true ) { return; }

            if( this.doSetIsEventFilterSearch != null ) { this.doSetIsEventFilterSearch(false); }
        
            if( select_by_config_change == false && this.event_search_query_temp.length < 2 ) {
                this.hodu_show_dialog('alert', '검색어를 2자 이상 입력해주세요', ["확인"]);
                return;
            }

            if( select_by_config_change == false ) {
                this.doSetEventSearchQuery(this.event_search_query_temp);
            }

            this.is_paging_end = false;
            this.is_first_api_call_complete = false;
            this.last_event_id = "";
            this.events.splice(0, this.events.length);
            this.render_events.splice(0, this.render_events.length);

            this.searchOptionReset();

            // @ts-ignore
            $('#eventSearchDiv').mCustomScrollbar("disable", true);
            // @ts-ignore
            $('#eventSearchDiv').mCustomScrollbar("update");
            await this.searchEventForWeb();
        });
    }

    /**
     * 새롭게 필터 검색
     */
    async newSearchEventByFilter() : Promise<void> {
        this.$nextTick(async() => {
            if( this.is_api_call == true ) { return; }
            if( this.doSetIsEventFilterSearch != null ) { this.doSetIsEventFilterSearch(true); }

            this.is_paging_end = false;
            this.is_first_api_call_complete = false;
            this.last_event_id = "";
            this.events.splice(0, this.events.length);
            this.render_events.splice(0, this.render_events.length);
            this.doSetEventSearchQuery("");
            this.event_search_query_temp = "";

            // @ts-ignore
            $('#eventSearchDiv').mCustomScrollbar("disable", true);
            // @ts-ignore
            $('#eventSearchDiv').mCustomScrollbar("update");
            await this.searchEventForWebByFilter();
        });
    }

    /**
     * 이벤트 조회
     */
    async searchEventForWeb() : Promise<void> {

        if( this.is_api_call == true || this.is_paging_end == true ) {
            return;
        }

        await this.get_group_role_service();

        const vue = this;
        this.is_api_call = true; 

        let owner_type  : string = this.scope;
        let owner_id    : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id :
                                   this.scope == OWNER_TYPE.GROUP    ? this.scope_group_id : this.scope_team_id;

        let calendar_id : string = this.calendar_id;
        let query : string = `?last_event_id=${this.last_event_id}&include_past_event=${ this.schedule_search_config.past_schedule }&mycal=${ this.scope == OWNER_TYPE.PERSONAL ? this.schedule_search_config.my_schedule : false }&syscal=${ this.scope == OWNER_TYPE.PERSONAL ? this.schedule_search_config.system_calendar : false }&shared=${ this.scope == OWNER_TYPE.PERSONAL ? this.schedule_search_config.shared_schedule : false }&search=${this.event_search_query}`;
        
        // groups 쿼리 생성
        if( this.user_group_role != null && this.user_group_role.length > 0 ){
            const role_size : number = this.user_group_role.length;
            for( let i = 0; i < role_size; i++ ){
                if ( this.user_group_role[i].group_id == null || this.user_group_role[i].group_id < 1 ) {
                    continue;
                }
                    
                // 개인 달력일때 그룹 필터에 해당 그룹 아이디가 존재한다면 다음순서로
                if( this.scope == OWNER_TYPE.PERSONAL && this.schedule_search_config.group_filter.indexOf(Number(this.user_group_role[i].group_id)) != -1 ){
                    continue;
                }

                // PERSONAL이 아니라면 해당 그룹의 정보만 추가한다
                if( this.scope != OWNER_TYPE.PERSONAL ) {
                    if( this.scope_group_id > 0 && this.scope_group_id != Number(this.user_group_role[i].group_id) ) {
                        continue;
                    }

                    let biz_id : string | null = null;
                    let hodu_c_group_length : number = this.hodu_c_group_data.length;
                    for( let j = 0; j < hodu_c_group_length; j++ ) {
                        if( this.hodu_c_group_data[j].group_id == Number(this.user_group_role[i].group_id) ) {
                            biz_id = this.hodu_c_group_data[j].biz_id;
                        }
                    }

                    // 판독한 그룹이 biz그룹이 아니라면 || 현재 보고 있는 달력의 비즈 그룹이 아니라면 continue;
                    if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                        continue;
                    }

                    // BIZ_ID로 필터를 뽑아낸다
                    let filter : GroupAndTeamFilter | null = null;
                    const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
                    for( let j = 0; j < filter_length; j++ ) {
                        if( this.schedule_search_config.group_and_team_filter[j].biz_id == biz_id &&
                            this.schedule_search_config.group_and_team_filter[j].scope == this.scope &&
                            this.schedule_search_config.group_and_team_filter[j].scope_id == (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) ) {
                            filter = this.schedule_search_config.group_and_team_filter[j];
                        }
                    }

                    // 필터에 들어있다면 건너뛴다
                    if( filter != null && filter.group_id == Number(this.user_group_role[i].group_id) ) {
                        continue;
                    }
                }

                query += `&groups=${Number(this.user_group_role[i].group_id)}`;
            }
        }

        // teams 쿼리 생성
        if( this.user_team_role != null && this.user_team_role.length > 0 ){
            const role_size : number = this.user_team_role.length;
            for( let i = 0; i < role_size; i++ ){
                if( this.user_team_role[i].team_id == null || this.user_team_role[i].team_id < 1 ){
                    continue;
                }
                    
                // 팀 필터에 해당 팀 아이디가 존재한다면 다음순서로
                if( this.scope == OWNER_TYPE.PERSONAL && this.schedule_search_config.team_filter.indexOf(Number(this.user_team_role[i].team_id)) != -1 ){
                    continue;
                }

                // PERSONAL이 아니라면 해당 팀의 정보만 추가한다
                if( this.scope != OWNER_TYPE.PERSONAL ) {
                    if( this.scope_team_id > 0 && this.scope_team_id != Number(this.user_team_role[i].team_id) ) {
                        continue;
                    }

                    let biz_id : string | null = null;
                    let hodu_c_group_length : number = this.hodu_c_group_data.length;
                    for( let j = 0; j < hodu_c_group_length; j++ ) {
                        const team_length : number = this.hodu_c_group_data[j].teams.length;
                        for( let k = 0; k < team_length; k++ ) {
                            if( this.hodu_c_group_data[j].teams[k].team_id == Number(this.user_team_role[i].team_id) ) {
                                biz_id = this.hodu_c_group_data[j].biz_id;
                                break;
                            }
                        }

                        // 비즈 아이디를 찾았다면 break;
                        if( biz_id != null ) {
                            break;
                        }
                    }

                    // 판독한 팀이 biz팀이 아니라면 || 현재 보고 있는 달력의 비즈 팀이 아니라면 continue;
                    if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                        continue;
                    }

                    // BIZ_ID로 필터를 뽑아낸다
                    let filter : GroupAndTeamFilter | null = null;
                    const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
                    for( let j = 0; j < filter_length; j++ ) {
                        if( this.schedule_search_config.group_and_team_filter[j].biz_id == biz_id &&
                            this.schedule_search_config.group_and_team_filter[j].scope == this.scope &&
                            this.schedule_search_config.group_and_team_filter[j].scope_id == (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) ) {
                            filter = this.schedule_search_config.group_and_team_filter[j];
                        }
                    }

                    // 필터에 들어있다면 건너뛴다
                    if( filter != null && filter.team_ids.indexOf(Number(this.user_team_role[i].team_id)) > -1 ) {
                        continue;
                    }
                }

                query += `&teams=${Number(this.user_team_role[i].team_id)}`;
            }
        }

        // 병원 예약 - 일반 달력인 경우 
        if( this.hodu_d_group_data.length > 0 && this.scope == OWNER_TYPE.PERSONAL ) {
            const hopital_count : number = this.hodu_d_group_data.length;
            for( let i = 0; i < hopital_count; i++ ) {
                const doctor_count : number = this.hodu_d_group_data[i].teams.length;
                for( let j = 0; j < doctor_count; j++ ) {
                    const doctor_key : string = `${this.hodu_d_group_data[i].teams[j].biz_id}___${this.hodu_d_group_data[i].teams[j].department_code}___${this.hodu_d_group_data[i].teams[j].doctor_code}`; 

                    // 필터에 들어 있는 경우 건너 뜀
                    if( this.schedule_search_config.hodu_d_filter.indexOf(doctor_key) > -1 ) {
                        continue;
                    }

                    query += `&doctors=${doctor_key}`;
                }
            }
        }

        // 병원 예약 - 호두 D 달력인 경우
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD && this.scope_group_team_option.biz_id != null && this.scope_group_team_option.biz_id.length > 0 ) {
            query += `&biz_type=${this.scope_group_team_option.biz_type}&biz_id=${this.scope_group_team_option.biz_id}`;

            const group_appointment_filter_count : number = this.schedule_search_config.group_appointment_filter.length;
            for( const doctor_key of this.schedule_search_config.group_appointment_filter ) {
                query += `&doctors=${doctor_key}`;
            }
        }
        
        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar("disable", false);
        await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}/web${query}`, API_METHOD.GET, undefined, true)
            .then(async(response) => {
                console.log(response);
                
                await vue.hodu_show_indicator();

                // null 체크
                if( response.data.data.events == null ) {
                    return;
                }

                await vue.makeEventsToRenderEvents(response);

                // 과거일정을 안보는 경우
                if( this.schedule_search_config.past_schedule == false ) {
                    const current_date : Date = new Date();
                    current_date.setHours(0);
                    current_date.setMinutes(0);
                    current_date.setSeconds(0);
                    current_date.setMilliseconds(0);

                    const return_events_length : number = this.render_events.length;
                    for( let i = (return_events_length - 1); i >= 0; i-- ) {
                        const year_and_month_last_date : Date = new Date(this.render_events[i].year_and_month.replace(/\./g, "-"));
                        
                        // 해당 월의 마지막 일자 23시 59분 59초 999로 만들고 비교
                        year_and_month_last_date.setMonth(year_and_month_last_date.getMonth() + 1);
                        year_and_month_last_date.setDate(0);
                        year_and_month_last_date.setHours(23);
                        year_and_month_last_date.setMinutes(59);
                        year_and_month_last_date.setSeconds(59);
                        year_and_month_last_date.setMilliseconds(999);

                        // 해당 월이 과거라면 제외시킨다
                        if( year_and_month_last_date.getTime() < current_date.getTime() ) {
                            this.render_events.splice(i, 1);
                            continue;
                        }

                        const render_event :any = JSON.parse(JSON.stringify(this.render_events[i]));
                        let events : t_event[] = render_event.events == null ? null : JSON.parse(JSON.stringify(render_event.events));
                        if( events == null ) {
                            render_event.events = [];
                            events = [];
                        }

                        const events_length : number = events.length;
                        render_event.events.splice(0, events_length);
                        for( let j = 0; j < events_length; j++ ) {
                            const event : t_event = events[j];

                            const start_date : Date = new Date(event.event_data.schedule_date.start);

                            // 오늘 0시 0분 0초보다 미래라면 push
                            if( start_date.getTime() >= current_date.getTime() ) {
                                render_event.events.push(event);
                            }
                        }

                        // 일정이 전부 잘려나갔다면 월을 없앤다
                        if( render_event.events.length < 1 ) {
                            this.render_events.splice(i, 1);
                            continue;
                        }
                        
                        // 일정이 남아있다면 대체시킨다
                        // render_event.events = render_event.events.concat(events);
                        this.render_events.splice(i, 1, render_event);
                    }
                }

                this.$nextTick(() => { setTimeout(() => { if( this.is_first_api_call_complete == false ) { this.compareToday(); } }, 500); });
            })
            .catch(async(e) => { this.hodu_error_process(e, true, false); })
            .finally(async() => {
                if( this.render_events == null || this.render_events.length < 1 ) { this.events.splice(0, this.events.length); }
                vue.is_api_call = false;
                await this.hodu_hide_indicator();

                // @ts-ignore
                $('#eventSearchDiv').mCustomScrollbar("update");
            });

    }

    /**
     * 이벤트 데이터를 렌더링 가능한 데이터로 변경 
     */
    async makeEventsToRenderEvents(response : any) : Promise<void> {
        const vue = this;
        
        await this.hodu_show_indicator();

        let groups : number[] = [];
        let teams : number[] = [];

        if( this.user_group_role != null && this.user_group_role.length > 0 ) {
            for( const group_role of this.user_group_role ) {
                if( group_role.group_id == null || group_role.group_id < 1 ) {
                    continue
                }
                groups.push(group_role.group_id);
            }
        }

        if( this.user_team_role != null && this.user_team_role.length > 0 ) {
            for( const team_role of this.user_team_role ) {
                if( team_role.team_id == null || team_role.team_id < 1 ) {
                    continue
                }
                teams.push(team_role.team_id);
            }
        }

        const temp_events : t_event[] = [];
        const response_events : t_event[] = response.data.data.events;

        for( const event of response_events ) {

            if ( event == null || event.event_data == null ) {
                temp_events.push(event);
                continue;
            }

            const event_data = event.event_data;

            // 숨김 처리 아니라면 추가
            if( (event_data.is_private ?? false) == false ) {
                temp_events.push(event);
                continue;
            }

            // 본인 작성이면 숨김상태여도 그대로 추가
            if( event_data.event_owner_id == this.user_id ) {
                temp_events.push(event);
                continue;
            }

            // 권한 구하기
            let is_permitted : boolean = false;
            if( event.team_id > 0 ) {
                if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                    is_permitted = this.is_team_permmision(event.team_id, "event", "private_read");
                }
                else if( this.isMeetingLog(event.event_sub_type) ) {
                    is_permitted = this.is_team_permmision(event.team_id, "meetinglog", "private_read");
                }
                else if( this.isReport(event.event_sub_type) ) {
                    is_permitted = this.is_team_permmision(event.team_id, "report", "private_read");
                }
                else if( this.isWork(event.event_sub_type) ) {
                    is_permitted = this.is_team_permmision(event.team_id, "work", "private_read");
                }
            }
            else if( event.group_id > 0 ) {
                if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                    is_permitted = this.is_group_permmision(event.group_id, "event", "private_read");
                }
                else if( this.isMeetingLog(event.event_sub_type) ) {
                    is_permitted = this.is_group_permmision(event.group_id, "meetinglog", "private_read");
                }
                else if( this.isReport(event.event_sub_type) ) {
                    is_permitted = this.is_group_permmision(event.group_id, "report", "private_read");
                }
                else if( this.isWork(event.event_sub_type) ) {
                    is_permitted = this.is_group_permmision(event.group_id, "work", "private_read");
                }
            }

            if( is_permitted == true ) {
                temp_events.push(event);
                continue;
            }

            // 프로젝트의 경우 담당자면 볼 수 있음
            if( event.event_sub_type, "WORK" && event_data.work != null ) {

                if( event_data.work?.assignment_type == "ALL" ) {
                    temp_events.push(event);
                    continue;
                }

                const assign_user_ids = event_data.work!.assign_user_ids;
                if( assign_user_ids != null && assign_user_ids.indexOf(this.user_id) > -1 ) {
                    temp_events.push(event);
                    continue;
                }

            }

            // 숨김 처리인데 개인 공유 받은 경우 추가
            if( event.subscribe_users != null && event.subscribe_users.indexOf(this.user_id) > -1 ) {
                temp_events.push(event);
                continue;
            }

            // 숨김 처리인데 그룹 공유 받은 경우 추가
            for( const group_id of groups ) {
                if ( event.subscribe_groups != null && event.subscribe_groups.indexOf(group_id) > -1 ) {
                    temp_events.push(event);
                    break;
                }
            }

            if( temp_events.indexOf(event) > -1 ) continue;

            // 숨김 처리인데 팀 공유 받은 경우 추가
            for( const team_id of teams ) {
                if ( event.subscribe_teams != null && event.subscribe_teams.indexOf(team_id) > -1) {
                    temp_events.push(event);
                    break;
                }
            }

            if( temp_events.indexOf(event) > -1 ) continue;
        }

        vue.events = vue.events.concat(temp_events);

        // console.log(JSON.stringify(response.data.data.events));

        // 이벤트 조회된 개수가 50개 미만이면 페이지 끝 
        if( response.data.data.events.length < 50 ) {
            vue.is_paging_end = true;
        }

        // 이벤트 조회된게 없으면 나가기
        if( response.data.data.events.length < 1 ) {
            vue.is_api_call = false;
            return;
        }

        // 마지막 이벤트 id 담기
        let last_event : t_event | null = null;
        if( vue.events.length > 0 ) {
            last_event = vue.events[vue.events.length - 1];
            if( last_event.event_id != null ) {
                vue.last_event_id = last_event.event_id;
            }
        }

        // 연속일정 반복일정 첫 날짜 이외의 쪼개진 날짜의 이벤트들 다 제거 (구분법은 original_start가 null이 아니면 됨)
        const render_month_length : number = vue.render_events.length;
        for( let i = 0; i < render_month_length; i++ ) {
            const render_month_event_length : number = vue.render_events[i].events.length;
            for( let j = render_month_event_length - 1; j >= 0; j-- ) {
                const event : t_event = vue.render_events[i].events[j];
                if( event.original_start != null ) {
                    vue.render_events[i].events.splice(j, 1);
                }
            }
        }

        // 과거일정 체크용
        const current_date : Date = new Date();
        current_date.setHours(0);
        current_date.setMinutes(0);
        current_date.setSeconds(0);
        current_date.setMilliseconds(0);
        
        // 조회된 이벤트 가공
        const select_event_length : number = temp_events.length;
        for( let i = 0; i < select_event_length; i++ ) {
            const select_event : t_event = temp_events[i];

            // 권한 체크는 서버에서만 체크하도록 변경, 공유된 일정에 대해서 하나라도 권한이 있는지 체크해야하므로 느려질 가능성이 있기 때문
            // 본인이 작성한게 아니라면 권한체크
            // if( select_event.event_data.event_owner_id != this.user_id ) {
            //     // 일정 읽기 권한 없으면 건너뛴다
            //     if( (select_event.event_sub_type == EVENT_SUB_TYPE.SCHEDULE || select_event.event_sub_type == EVENT_SUB_TYPE.CARD) && !(select_event.group_id && select_event.group_id > 0 && this.is_group_permmision(select_event.group_id, 'event', 'read') || 
            //         (select_event.team_id && select_event.team_id > 0 && this.is_team_permmision(select_event.team_id, 'event', 'read'))) ) {
            //         continue;
            //     }

            //     // 업무 일지 읽기 권한 없으면 건너뛴다
            //     if( select_event.event_sub_type == EVENT_SUB_TYPE.REPORT && !(select_event.group_id && select_event.group_id > 0 && this.is_group_permmision(select_event.group_id, 'report', 'read') || 
            //         (select_event.team_id && select_event.team_id > 0 && this.is_team_permmision(select_event.team_id, 'report', 'read'))) ) {
            //         continue;
            //     }

            //     // 회의록 읽기 권한 없으면 건너뛴다
            //     if( select_event.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG && !(select_event.group_id && select_event.group_id > 0 && this.is_group_permmision(select_event.group_id, 'meetinglog', 'read') || 
            //         (select_event.team_id && select_event.team_id > 0 && this.is_team_permmision(select_event.team_id, 'meetinglog', 'read'))) ) {
            //         continue;
            //     }

            //     // 프로젝트 읽기 권한 없으면 건너뛴다
            //     if( select_event.event_sub_type == EVENT_SUB_TYPE.WORK && !(select_event.group_id && select_event.group_id > 0 && this.is_group_permmision(select_event.group_id, 'work', 'read') || 
            //         (select_event.team_id && select_event.team_id > 0 && this.is_team_permmision(select_event.team_id, 'work', 'read'))) ) {
            //         continue;
            //     }
            // }

            select_event.lunar_date_text = await vue.getAmPmTime(new Date(select_event.event_data.schedule_date.start), new Date(select_event.event_data.schedule_date.end), select_event.event_data.schedule_date.isAllDay, select_event.event_data.schedule_date.lunar_yn);

            // RGB값만 있다면 그대로 RGB를 사용, ARGB라면 ARGB를 RGB로 변환
            select_event.event_data.color = this.hodu_hex_color_process(select_event.event_data.color);

            const year_and_month : string = vue.getYearAndMonth(new Date(select_event.event_data.schedule_date.start));

            // 만들어진 year_and_month가 이미 있는지 체크
            let year_and_month_index : number = -1;
            const render_event_length : number = vue.render_events.length;
            for( let j = 0; j < render_event_length; j++ ) {
                
                // 이미 만들어졌다면 index를 담고 나가기
                if( vue.render_events[j].year_and_month == year_and_month ) {
                    year_and_month_index = j;
                    break;
                }
            }

            // 안만들어져 있다면 새로 추가하고 index 담기
            if( year_and_month_index == -1 ) {
                const year_and_month_last_date : Date = new Date(year_and_month.replace(/\./g, "-"));

                year_and_month_index = render_event_length;
                vue.render_events.push({
                    "year_and_month" : year_and_month,
                    "events"         : []
                })
            }

            // vue.render_events[year_and_month_index].events에 이벤트 데이터 넣기!
            vue.render_events[year_and_month_index].events.push(select_event);
            
            // 연속&반복 일정인 경우 첫일정의 연속일정 뒤쪽을 render_events[n].events에 미리 추가시켜준다
            const date_diff : number             = vue.getDateDiff(select_event.event_data.schedule_date.start, select_event.event_data.schedule_date.end);
            const rrule     : string | undefined = select_event.event_data.schedule_date.rrule;
            if( date_diff > 0 && vue.isRepeat(rrule == null ? "" : rrule) == true ) {
                const event_fragments : t_event[] = [];

                for( let j = 1; j <= date_diff; j++ ) {
                    const event_fragment : t_event = JSON.parse(JSON.stringify(select_event));
                    const start_date : Date = new Date(select_event.event_data.schedule_date.start); 
                    const new_start  : Date = new Date(start_date);
                    const end_date : Date = new Date(select_event.event_data.schedule_date.end); 

                    new_start.setDate(start_date.getDate() + j);

                    // 잘린 날짜가 마지막 일정의 날짜보다 미래의 날짜라면
                    if( vue.is_paging_end == false && vue.getDateDiff(new_start, last_event!.event_data.schedule_date.start) > 0 && 
                        new_start.getTime() > new Date(last_event!.event_data.schedule_date.start).getTime() ) {
                        continue;
                    }

                    event_fragment.event_data.schedule_date.start = new_start;
                    event_fragment.original_start                 = start_date;
                    event_fragment.original_end                   = end_date;
                    event_fragments.push(event_fragment);
                }

                // 잘라낸 일정을 original_end와 original_start를 넣고 reder_event에 넣기
                const event_fragment_length : number = event_fragments.length;
                for( let j = 0; j < event_fragment_length; j++ ) {
                    const event_fragment : t_event = event_fragments[j];
                    const year_and_month : string = vue.getYearAndMonth(new Date(event_fragment.event_data.schedule_date.start));

                    // 만들어진 year_and_month가 이미 있는지 체크
                    let year_and_month_index : number = -1;
                    const render_event_length : number = vue.render_events.length;
                    for( let k = 0; k < render_event_length; k++ ) {
                        
                        // 이미 만들어졌다면 index를 담고 나가기
                        if( vue.render_events[k].year_and_month == year_and_month ) {
                            year_and_month_index = k;
                            break;
                        }
                    }

                    // 안만들어져 있다면 새로 추가하고 index 담기
                    if( year_and_month_index == -1 ) {
                        year_and_month_index = render_event_length;
                        vue.render_events.push({
                            "year_and_month" : year_and_month,
                            "events"         : []
                        })
                    }

                    vue.render_events[year_and_month_index].events.push(event_fragment);
                }

            }
        }

        // events에서 연속일정, 반복일정을 찾는다
        const event_length : number = vue.events.length;
        for( let i = 0; i < event_length; i++ ) {

            const event : t_event = vue.events[i];
            const event_fragments : t_event[] = [];

            // 연속 일정이거나 반복 일정인 경우 처리
            const date_diff : number             = vue.getDateDiff(event.event_data.schedule_date.start, event.event_data.schedule_date.end);
            const rrule     : string | undefined = event.event_data.schedule_date.rrule;
            
            /**
             * 반복일정인 경우 - rrule.js 이용해서 쪼갬 (recurrence_end가 마지막 일정의 날짜보다 크다면 마지막 일정의 날짜까지만 UNTIL 설정)
             */
            if( vue.isRepeat(rrule == null ? "" : rrule) == true ) {

                let last_current_date_diff : number = vue.getDateDiff(event.event_data.schedule_date.recurrence_end, last_event!.event_data.schedule_date.start);
                
                // 현재 일정의 recurrence_end가 더 미래의 날짜라면 
                if( new Date(event.event_data.schedule_date.recurrence_end).getTime() > new Date(last_event!.event_data.schedule_date.start).getTime() ) {
                    last_current_date_diff = -last_current_date_diff;
                }

                const rrule_dtstart : string = `${vue.formatDateForRruleDTSTART(new Date(event.event_data.schedule_date.start))}`;
                const rrule_until   : string = `${vue.formatDateForRruleUNTIL( last_current_date_diff < 0 && vue.is_paging_end == false ? new Date(last_event!.event_data.schedule_date.start)
                                                                                                                                        : new Date(event.event_data.schedule_date.recurrence_end) )}`;

                const full_rrule : string = `DTSTART:${rrule_dtstart}\nRRULE:${rrule};UNTIL=${rrule_until}`;
                
                const rrule_obj : RRule | RRuleSet = rrulestr(full_rrule);
                // console.log(`${event.event_data.title} : ${rrule_obj.all()}`);
                
                let lunar_arr : any[] = [];
                if( event.event_data.schedule_date.lunar_yn == true && event.event_data.schedule_date.lunar_start != null && 
                        event.event_data.schedule_date.lunar_end != null ) {
                    const intercalation_obj : any = await vue.hodu_is_intercalation(event.event_data.schedule_date.start);
                    lunar_arr = await vue.hodu_lunar_to_solars(event.event_data.schedule_date.lunar_start.replace(/-/ig, '').substr(0, 8), intercalation_obj.is_intercalation);
                }

                // 반복 일정 쪼개서 넣기
                const rrule_all : Date[] = rrule_obj.all();
                const rrule_all_length : number = rrule_all.length;
                const start_date : Date = new Date(event.event_data.schedule_date.start); 
                const end_date   : Date = new Date(event.event_data.schedule_date.end); 
                for( let i = 1; i < rrule_all_length; i++ ) {
                    const event_fragment : t_event = JSON.parse(JSON.stringify(event));
                    let new_start : Date = new Date(start_date);
                    let new_end : Date = new Date(end_date);
                    const target_date : Date = rrule_all[i];

                    // exdate에 포함되어있는지 검사
                    let include_exdate : boolean = false;
                    const exdates : Date[] | undefined = event_fragment.event_data.schedule_date.exdate;
                    if( exdates != null ) {
                        const exdate_length : number = exdates != null ? exdates.length : 0;
                        for( let j = 0; j < exdate_length; j++ ) {
                            const exdate : Date = new Date(exdates[j]);
                            if( vue.getDateDiff(target_date, exdate) == 0 ) {
                                include_exdate = true;
                                break;
                            }
                        }
                    }

                    // exdate에 포함되는 날짜라면 건너뛴다
                    if( include_exdate == true ) {
                        continue;
                    }

                    // 날짜 조작
                    new_start.setFullYear(target_date.getFullYear());
                    new_start.setMonth(target_date.getMonth());
                    new_start.setDate(target_date.getDate());

                    new_end.setFullYear(target_date.getFullYear());
                    new_end.setMonth(target_date.getMonth());
                    new_end.setDate(target_date.getDate());

                    // 음력 처리(음력 반복은 1일짜리 일정밖에 없음)
                    if( event_fragment.event_data.schedule_date.lunar_yn == true && event_fragment.event_data.schedule_date.lunar_start != null && 
                        event_fragment.event_data.schedule_date.lunar_end != null ) {

                        if( lunar_arr[i] == null ) { break; }

                        // const new_lunar_start : Date = new Date(lunar_arr[i].lunar_ymd);
                        // new_lunar_start.setHours(0);
                        // new_lunar_start.setMinutes(0);
                        // new_lunar_start.setSeconds(0);
                        // new_lunar_start.setMilliseconds(0);

                        // const new_lunar_end : Date = new Date(lunar_arr[i].lunar_ymd);
                        // new_lunar_end.setHours(23);
                        // new_lunar_end.setMinutes(59);
                        // new_lunar_end.setSeconds(59);
                        // new_lunar_end.setMilliseconds(999);

                        event_fragment.event_data.schedule_date.lunar_start = lunar_arr[i].lunar_ymd;
                        event_fragment.event_data.schedule_date.lunar_end   = lunar_arr[i].lunar_ymd;

                        new_start = new Date(lunar_arr[i].solar_ymd);
                        new_start.setHours(0);
                        new_start.setMinutes(0);
                        new_start.setSeconds(0);
                        new_start.setMilliseconds(0);

                        new_end = new Date(lunar_arr[i].solar_ymd);
                        new_end.setHours(23);
                        new_end.setMinutes(59);
                        new_end.setSeconds(59);
                        new_end.setMilliseconds(999);
                    }
                    
                    event_fragment.original_start = start_date;
                    event_fragment.original_end   = end_date;
                    event_fragment.event_data.schedule_date.start = new_start;
                    event_fragment.event_data.schedule_date.end   = new_end;
                    event_fragments.push(event_fragment);
                }

            }

            // 연속일정, 반복일정, 연속 반복일정을 전부 쪼개서 보여주던 시절에 사용한 로직 필요하다면 사용할것
            // /**
            //  * 연속일정 Only인 경우 - 시작일 종료일 차이나는 일수 기준으로 자른다
            //  */
            // if( date_diff > 0 && vue.isRepeat(rrule == null ? "" : rrule) == false ) {

            //     for( let i = 1; i <= date_diff; i++ ) {
            //         const event_fragment : t_event = JSON.parse(JSON.stringify(event));
            //         const start_date : Date = new Date(event.event_data.schedule_date.start); 
            //         const new_start  : Date = new Date(start_date);
            //         // 종료일도 건드릴 필요가 있다면 할것

            //         new_start.setDate(start_date.getDate() + i);

            //         // 잘린 날짜가 마지막 일정의 날짜보다 미래의 날짜라면
            //         if( vue.is_paging_end == false && vue.getDateDiff(new_start, last_event.event_data.schedule_date.start) > 0 && 
            //             new_start.getTime() > new Date(last_event.event_data.schedule_date.start).getTime() ) {
            //             continue;
            //         }

            //         event_fragment.event_data.schedule_date.start = new_start;
            //         event_fragment.original_start                 = start_date;
            //         event_fragments.push(event_fragment);
            //     }

            // }

            // /**
            //  * 반복일정 Only인 경우 - rrule.js 이용해서 쪼갬 (recurrence_end가 마지막 일정의 날짜보다 크다면 마지막 일정의 날짜까지만 UNTIL 설정)
            //  */
            // if( date_diff == 0 && vue.isRepeat(rrule == null ? "" : rrule) == true ) {

            //     let last_current_date_diff : number = vue.getDateDiff(event.event_data.schedule_date.recurrence_end, last_event.event_data.schedule_date.start);
                
            //     // 현재 일정의 recurrence_end가 더 미래의 날짜라면 
            //     if( new Date(event.event_data.schedule_date.recurrence_end).getTime() > new Date(last_event.event_data.schedule_date.start).getTime() ) {
            //         last_current_date_diff = -last_current_date_diff;
            //     }

            //     const rrule_dtstart : string = `${vue.formatDateForRruleDTSTART(new Date(event.event_data.schedule_date.start))}`;
            //     const rrule_until   : string = `${vue.formatDateForRruleUNTIL( last_current_date_diff < 0 && vue.is_paging_end == false ? new Date(last_event.event_data.schedule_date.start)
            //                                                                                                                             : new Date(event.event_data.schedule_date.recurrence_end) )}`;

            //     const full_rrule : string = `DTSTART:${rrule_dtstart}\nRRULE:${rrule};UNTIL=${rrule_until}`;
                
            //     const rrule_obj : RRule | RRuleSet = rrulestr(full_rrule);
            //     // console.log(`${event.event_data.title} : ${rrule_obj.all()}`);

            //     let lunar_arr : any[] = [];
            //     if( event.event_data.schedule_date.lunar_yn == true && event.event_data.schedule_date.lunar_start != null && 
            //             event.event_data.schedule_date.lunar_end != null ) {
            //         lunar_arr = await vue.hodu_lunar_to_solars(event.event_data.schedule_date.lunar_start);
            //     }

            //     // 반복 일정 쪼개서 넣기
            //     const rrule_all : Date[] = rrule_obj.all();
            //     const rrule_all_length : number = rrule_all.length;
            //     const start_date : Date = new Date(event.event_data.schedule_date.start); 
            //     const end_date   : Date = new Date(event.event_data.schedule_date.end); 
            //     for( let i = 1; i < rrule_all_length; i++ ) {
            //         const event_fragment : t_event = JSON.parse(JSON.stringify(event));
            //         let new_start : Date = new Date(start_date);
            //         let new_end : Date = new Date(end_date);
            //         const target_date : Date = rrule_all[i];

            //         // exdate에 포함되어있는지 검사
            //         let include_exdate : boolean = false;
            //         const exdates : Date[] | undefined = event_fragment.event_data.schedule_date.exdate;
            //         if( exdates != null ) {
            //             const exdate_length : number = exdates != null ? exdates.length : 0;
            //             for( let j = 0; j < exdate_length; j++ ) {
            //                 const exdate : Date = new Date(exdates[j]);
            //                 if( vue.getDateDiff(target_date, exdate) == 0 ) {
            //                     include_exdate = true;
            //                     break;
            //                 }
            //             }
            //         }

            //         // exdate에 포함되는 날짜라면 건너뛴다
            //         if( include_exdate == true ) {
            //             continue;
            //         }

            //         // 날짜 조작
            //         new_start.setFullYear(target_date.getFullYear());
            //         new_start.setMonth(target_date.getMonth());
            //         new_start.setDate(target_date.getDate());

            //         new_end.setFullYear(target_date.getFullYear());
            //         new_end.setMonth(target_date.getMonth());
            //         new_end.setDate(target_date.getDate());

            //         // 음력 처리(음력 반복은 1일짜리 일정밖에 없음)
            //         if( event_fragment.event_data.schedule_date.lunar_yn == true && event_fragment.event_data.schedule_date.lunar_start != null && 
            //             event_fragment.event_data.schedule_date.lunar_end != null ) {

            //             const new_lunar_start : Date = new Date(lunar_arr[i].lunar_ymd);
            //             new_lunar_start.setHours(0);
            //             new_lunar_start.setMinutes(0);
            //             new_lunar_start.setSeconds(0);
            //             new_lunar_start.setMilliseconds(0);

            //             const new_lunar_end : Date = new Date(lunar_arr[i].lunar_ymd);
            //             new_lunar_end.setHours(23);
            //             new_lunar_end.setMinutes(59);
            //             new_lunar_end.setSeconds(59);
            //             new_lunar_end.setMilliseconds(999);

            //             event_fragment.event_data.schedule_date.lunar_start = new_lunar_start;
            //             event_fragment.event_data.schedule_date.lunar_end   = new_lunar_end;

            //             new_start = new Date(lunar_arr[i].solar_ymd);
            //             new_start.setHours(0);
            //             new_start.setMinutes(0);
            //             new_start.setSeconds(0);
            //             new_start.setMilliseconds(0);

            //             new_end = new Date(lunar_arr[i].solar_ymd);
            //             new_end.setHours(23);
            //             new_end.setMinutes(59);
            //             new_end.setSeconds(59);
            //             new_end.setMilliseconds(999);
            //         }
                    
            //         event_fragment.original_start = start_date;
            //         event_fragment.original_end   = end_date;
            //         event_fragment.event_data.schedule_date.start = new_start;
            //         event_fragment.event_data.schedule_date.end   = new_end;
            //         event_fragments.push(event_fragment);
            //     }

            // }

            // /**
            //  * 연속일정 AND 반복일정인 경우 - rrule.js이용해서 쪼갠후(recurrence_end가 마지막 일정의 날짜보다 크다면 마지막 일정의 날짜까지만 UNTIL 설정) 
            //  * 쪼개진 일정을 시작일 종료일 차이나는 일수 기준으로 다시 쪼갠다         
            //  */                                                                   
            // if( date_diff > 0 && vue.isRepeat(rrule == null ? "" : rrule) == true ) {
            //     let last_current_date_diff : number = vue.getDateDiff(event.event_data.schedule_date.recurrence_end, last_event.event_data.schedule_date.start);
                
            //     // 현재 일정의 recurrence_end가 더 미래의 날짜라면 
            //     if( new Date(event.event_data.schedule_date.recurrence_end).getTime() > new Date(last_event.event_data.schedule_date.start).getTime() ) {
            //         last_current_date_diff = -last_current_date_diff;
            //     }

            //     const rrule_dtstart : string = `${vue.formatDateForRruleDTSTART(new Date(event.event_data.schedule_date.start))}`;
            //     const rrule_until   : string = `${vue.formatDateForRruleUNTIL( last_current_date_diff < 0 && vue.is_paging_end == false ? new Date(last_event.event_data.schedule_date.start)
            //                                                                                                                             : new Date(event.event_data.schedule_date.recurrence_end) )}`;

            //     const full_rrule : string = `DTSTART:${rrule_dtstart}\nRRULE:${rrule};UNTIL=${rrule_until}`;
                
            //     const rrule_obj : RRule | RRuleSet = rrulestr(full_rrule);
            //     // console.log(`${event.event_data.title} : ${rrule_obj.all()}`);

            //     // 반복 일정 쪼개서 넣기
            //     const rrule_all : Date[] = rrule_obj.all();
            //     const rrule_all_length : number = rrule_all.length;
            //     const start_date : Date = new Date(event.event_data.schedule_date.start); 
            //     const end_date   : Date = new Date(event.event_data.schedule_date.end); 
            //     for( let i = 1; i < rrule_all_length; i++ ) {
            //         const event_fragment : t_event = JSON.parse(JSON.stringify(event));
            //         const new_start : Date = new Date(start_date);
            //         const new_end : Date = new Date(end_date);
            //         const target_date : Date = rrule_all[i];

            //         // exdate에 포함되어있는지 검사
            //         let include_exdate : boolean = false;
            //         const exdates : Date[] | undefined = event_fragment.event_data.schedule_date.exdate;
            //         if( exdates != null ) {
            //             const exdate_length : number = exdates != null ? exdates.length : 0;
            //             for( let j = 0; j < exdate_length; j++ ) {
            //                 const exdate : Date = new Date(exdates[j]);
            //                 if( vue.getDateDiff(target_date, exdate) == 0 ) {
            //                     include_exdate = true;
            //                     break;
            //                 }
            //             }
            //         }

            //         // exdate에 포함되는 날짜라면 건너뛴다
            //         if( include_exdate == true ) {
            //             continue;
            //         }

            //         // 날짜 조작
            //         new_start.setFullYear(target_date.getFullYear());
            //         new_start.setMonth(target_date.getMonth());
            //         new_start.setDate(target_date.getDate());

            //         new_end.setFullYear(target_date.getFullYear());
            //         new_end.setMonth(target_date.getMonth());
            //         new_end.setDate(target_date.getDate() + date_diff);
                    
            //         event_fragment.original_start = start_date;
            //         event_fragment.original_end   = end_date;
            //         event_fragment.event_data.schedule_date.start = new_start;
            //         event_fragment.event_data.schedule_date.end   = new_end;

            //         for( let j = 0; j <= date_diff; j++ ) {
            //             const event_fragment2 : t_event = JSON.parse(JSON.stringify(event_fragment));
            //             const start_date : Date = new Date(event_fragment.event_data.schedule_date.start); 
            //             const new_start  : Date = new Date(start_date);
            //             // 종료일도 건드릴 필요가 있다면 할것

            //             new_start.setDate(start_date.getDate() + j);

            //             // 잘린 날짜가 마지막 일정의 날짜보다 미래의 날짜라면
            //             if( vue.is_paging_end == false && vue.getDateDiff(new_start, last_event.event_data.schedule_date.start) > 0 && 
            //                 new_start.getTime() > new Date(last_event.event_data.schedule_date.start).getTime() ) {
            //                 continue;
            //             }

            //             event_fragment2.event_data.schedule_date.start = new_start;
            //             event_fragments.push(event_fragment2);
            //         }

            //         // event_fragments.push(event_fragment);
            //     }
            // }

            // 잘라낸 일정을 original_end와 original_start를 넣고 reder_event에 넣기
            const event_fragment_length : number = event_fragments.length;
            for( let j = 0; j < event_fragment_length; j++ ) {
                const event_fragment : t_event = event_fragments[j];
                const year_and_month : string = vue.getYearAndMonth(new Date(event_fragment.event_data.schedule_date.start));
                
                let year_and_month_index : number = -1;
                const render_event_length : number = vue.render_events.length;
                
                for( let k = 0; k < render_event_length; k++ ) {
                    
                    // 이미 만들어졌다면 index를 담고 나가기
                    if( vue.render_events[k].year_and_month == year_and_month ) {
                        year_and_month_index = k;
                        break;
                    }
                }
                

                // 안만들어져 있다면 새로 추가하고 index 담기
                if( year_and_month_index == -1 ) {
                    year_and_month_index = render_event_length;
                    vue.render_events.push({
                        "year_and_month" : year_and_month,
                        "events"         : []
                    })
                }

                vue.render_events[year_and_month_index].events.push(event_fragment);
            }
        }

        /**
         * 년, 월 정렬
         */
        this.render_events.sort((a : any, b : any) : number => {
            const a_start : Date = new Date(a.year_and_month.replace(/\./g, "-"));
            const b_start : Date = new Date(b.year_and_month.replace(/\./g, "-"));

            if( a_start.getTime() > b_start.getTime() ) {
                return 1;
            }
            
            else if( a_start.getTime() < b_start.getTime() ) {
                return -1;
            } 
            
            else {
                return 0;
            }

        });

        /**
         * events 일자별로 재정렬
         */
        const render_events_length = vue.render_events.length;
        for( let i = 0; i < render_events_length; i++ ) {
            this.render_events[i].events.sort((a : t_event, b : t_event) : number => {
                const a_start : Date = new Date(a.event_data.schedule_date.start); 
                const b_start : Date = new Date(b.event_data.schedule_date.start); 

                if( a_start.getTime() > b_start.getTime() ) {
                    return 1;
                }
                
                else if( a_start.getTime() < b_start.getTime() ) {
                    return -1;
                } 
                
                else {
                    return 0;
                }

            });
        }

        await this.hodu_hide_indicator();
    }

    /**
     * 이벤트 필터 검색
     */
    async searchEventForWebByFilter() : Promise<void> {

        if( this.is_api_call == true || this.is_paging_end == true ) {
            return;
        }

        this.is_api_call = true; 

        let owner_type  : string = this.scope;
        let owner_id    : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id :
                                   this.scope == OWNER_TYPE.GROUP    ? this.scope_group_id : this.scope_team_id;

        let calendar_id : string = this.calendar_id;
        let query : string = `?last_event_id=${this.last_event_id}`;
        query += `&search_event_type=${this.event_search_option.search_event_type}`;
        if( this.event_search_option.start_date       != null ) { query += `&start_date=${moment(this.event_search_option.start_date).utc().format()}`; }
        if( this.event_search_option.end_date         != null ) { query += `&end_date=${moment(this.event_search_option.end_date).utc().format()}`; }
        if( this.event_search_option.event_title      != null ) { query += `&event_title=${this.event_search_option.event_title}`; }
        if( this.event_search_option.event_owner_name != null ) { query += `&event_owner_name=${this.event_search_option.event_owner_name}`; }
        if( this.event_search_option.location_name    != null ) { query += `&location_name=${this.event_search_option.location_name}`; }
        query += `&has_photo=${this.event_search_option.has_photo}`;
        query += `&has_file=${this.event_search_option.has_file}`;
        query += `&has_chat=${this.event_search_option.has_chat}`;
        query += `&has_attend=${this.event_search_option.has_attend}`;
        query += `&has_vote=${this.event_search_option.has_vote}`;
        query += `&is_work_wait=${this.event_search_option.is_work_wait}`;
        query += `&is_work_start=${this.event_search_option.is_work_start}`;
        query += `&is_work_end=${this.event_search_option.is_work_end}`;

        // event_color 쿼리 생성
        if( this.event_search_option.event_color != null && this.event_search_option.event_color.length < 20 ) {
            const color_size : number = this.event_search_option.event_color.length;
             for( let i = 0; i < color_size; i++ ) {
                 if( this.event_search_option.event_color[i] == null || this.event_search_option.event_color[i].length < 7 ) {
                     continue;
                 }

                 query += `&event_color=${encodeURIComponent(this.event_search_option.event_color[i])}`;
                 if( this.event_search_option.event_color[i].length == 7 ) { query += `&event_color=${encodeURIComponent(this.event_search_option.event_color[i].replace(/#/, "#FF"))}`; }
                 if( this.event_search_option.event_color[i].length == 9 ) { query += `&event_color=${encodeURIComponent(this.event_search_option.event_color[i].replace(/#FF/, "#"))}`; }
             }
        }

        // groups 쿼리 생성
        if( this.user_group_role != null && this.user_group_role.length > 0 ) {
            const role_size : number = this.user_group_role.length;
            for( let i = 0; i < role_size; i++ ) {
                if ( this.user_group_role[i].group_id == null || this.user_group_role[i].group_id < 1 ) {
                    continue;
                }        
                
                // 그룹 달력이라면 해당 그룹만, 팀이라면 전부 제외
                if( this.scope == OWNER_TYPE.GROUP && this.scope_group_id != this.user_group_role[i].group_id ) { continue; }
                if( this.scope == OWNER_TYPE.TEAM ) { break; }

                query += `&groups=${Number(this.user_group_role[i].group_id)}`;
            }
        }

        // teams 쿼리 생성
        if( this.user_team_role != null && this.user_team_role.length > 0 ) {
            const role_size : number = this.user_team_role.length;
            for( let i = 0; i < role_size; i++ ) {
                if( this.user_team_role[i].team_id == null || this.user_team_role[i].team_id < 1 ){
                    continue;
                }

                // 그룹 달력이라면 해당 그룹의 팀, 팀이라면 해당 팀만
                if( this.scope == OWNER_TYPE.GROUP && this.scope_group_id != this.user_team_role[i].group_id ) { continue; }
                if( this.scope == OWNER_TYPE.TEAM  && this.scope_team_id  != this.user_team_role[i].team_id  ) { continue; }
                
                query += `&teams=${Number(this.user_team_role[i].team_id)}`;
            }
        }

        // 병원 예약 - 일반 달력인 경우
        if( this.hodu_d_group_data.length > 0 && this.scope == OWNER_TYPE.PERSONAL ) {
            const hopital_count : number = this.hodu_d_group_data.length;
            for( let i = 0; i < hopital_count; i++ ) {
                const doctor_count : number = this.hodu_d_group_data[i].teams.length;
                for( let j = 0; j < doctor_count; j++ ) {
                    const doctor_key : string = `${this.hodu_d_group_data[i].teams[j].biz_id}___${this.hodu_d_group_data[i].teams[j].department_code}___${this.hodu_d_group_data[i].teams[j].doctor_code}`; 

                    // 필터에 들어 있는 경우 건너 뜀
                    if( this.schedule_search_config.hodu_d_filter.indexOf(doctor_key) > -1 ) {
                        continue;
                    }

                    query += `&doctors=${doctor_key}`;
                }
            }
        }

        // 병원 예약 - 호두 D 달력인 경우
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD && this.scope_group_team_option.biz_id != null && this.scope_group_team_option.biz_id.length > 0 ) {
            query += `&biz_type=${this.scope_group_team_option.biz_type}&biz_id=${this.scope_group_team_option.biz_id}`;

            const group_appointment_filter_count : number = this.schedule_search_config.group_appointment_filter.length;
            for( const doctor_key of this.schedule_search_config.group_appointment_filter ) {
                query += `&doctors=${doctor_key}`;
            }
        }

        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar("disable", false);
        await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}/web/filter${query}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                await this.hodu_show_indicator(); 

                // null 체크
                if( response.data.data.events == null ) {
                    return;
                }

                await this.makeEventsToRenderEvents(response);

                // 반복일정으로 인해서 나오는 기간 바깥의 일정을 없앤다 (시작일, 종료일 중 하나라도 세팅 되어 있다면)
                if( this.event_search_option.start_date != null || this.event_search_option.end_date != null ) {
                    const render_count : number = this.render_events.length;
                    for( let i = (render_count - 1); i >= 0; i-- ) {
                        const render_date_string : string = `${this.render_events[i].year_and_month}.01`.replace(/\./g, "-");
                        const render_first_date : Date = new Date(moment(render_date_string).format());
                        const render_last_date : Date = new Date(render_first_date);
                        render_last_date.setMonth(render_first_date.getMonth() + 1);
                        render_last_date.setDate(0);

                        const start_date : Date = this.event_search_option.end_date != null ? new Date(moment(this.event_search_option.start_date).format())
                                                                                            : new Date(moment('1900-01-01 00:00:00').format());
                        const end_date : Date = this.event_search_option.end_date != null ? new Date(moment(this.event_search_option.end_date).format())
                                                                                          : new Date(moment('2050-12-31 23:59:59').format());

                        // 해당 달의 마지막 날이 시작일 보다 작을때는 자름
                        if( this.event_search_option.start_date != null && render_last_date.getTime() < new Date(moment(this.event_search_option.start_date).format()).getTime() ) {
                            this.render_events.splice(i, 1);
                            continue;
                        }

                        // 해당 달의 첫 날(render_date)이 종료일 보다 클때는 자름 
                        if( this.event_search_option.end_date != null && render_first_date.getTime() > end_date.getTime() ) {
                            this.render_events.splice(i, 1);
                            continue;
                        }

                        // 해당 월이 문제가 없다면 내부 일정의 시작일 ~ 종료일을 따져서 자른다 (연속일정은 그대로 내버려두고 반복일정만!)
                        const event_count : number = this.render_events[i].events.length;
                        for( let j = (event_count - 1); j >= 0; j-- ) {
                            const event_fragment : t_event = this.render_events[i].events[j];

                            // 반복일정이 아니라면 문제 없음
                            if( event_fragment.event_data.schedule_date.rrule == null || event_fragment.event_data.schedule_date.rrule.length < 1  ) {
                                continue;
                            }

                            // 반복일정의 시작일 ~ 종료일 어느쪽이든 걸치기만 하면 OK, 어느쪽에도 걸치지 못하면 자름
                            const fragment_start : Date = new Date(moment(event_fragment.event_data.schedule_date.start).format());
                            fragment_start.setHours(0);
                            fragment_start.setMinutes(0);
                            fragment_start.setSeconds(0);
                            fragment_start.setMilliseconds(0);

                            const fragment_end : Date = new Date(moment(event_fragment.event_data.schedule_date.end).format());
                            fragment_end.setHours(0);
                            fragment_end.setMinutes(0);
                            fragment_end.setSeconds(0);
                            fragment_end.setMilliseconds(0);

                            // 검색 시작일이 일정 종료 날짜 보다 큰 경우, 검색 종료일이 일정 시작 날짜보다 작은 경우 자른다
                            if( fragment_end.getTime() < start_date.getTime() || fragment_start.getTime() > end_date.getTime() ) {
                                this.render_events[i].events.splice(j, 1);
                            }

                        }

                        // 전부 처리 된 이후에 남은 일정이 0개라면 지운다
                        if( this.render_events[i].events.length < 1 ) { this.render_events.splice(i, 1); }

                    }
                }

                this.searchOptionVisible = false;
                this.$nextTick(() => { setTimeout(() => { if( this.is_first_api_call_complete == false ) { this.compareToday(); } }, 500); });
            })
            .catch((e) => { this.hodu_error_process(e, true, false); })
            .finally(async() => {
                if( this.render_events == null || this.render_events.length < 1 ) { this.events.splice(0, this.events.length); }
                this.is_api_call = false;
                await this.hodu_hide_indicator();

                // @ts-ignore
                $('#eventSearchDiv').mCustomScrollbar("update");
            });
    }

    /**
     * 오늘 날짜에서 가장 가까운 일정을 구한 뒤 스크롤
     */
    async compareToday() : Promise<void> {
        
        try {
            this.hodu_show_indicator();

            const today : Date = new Date();
            let search_array : any[] = JSON.parse(JSON.stringify(this.render_events));

            // 첫 중앙 값 기준으로 검색
            while( search_array.length > 2 ) {
                
                // 중앙 값 구하기
                const center_index : number = parseInt(String(search_array.length / 2));
                const center_render_event : any = search_array[center_index];
                const center_year_and_month_date : Date = new Date(center_render_event.year_and_month.replace(/\./g, "-"));


                // 중앙 값의 년월이 같은 경우
                if( center_year_and_month_date.getFullYear() == today.getFullYear() && 
                    center_year_and_month_date.getMonth() == today.getMonth() ) {
                    
                    // 해당 월의 일정만을 비교용으로 사용
                    search_array = [center_render_event];
                } 
                
                // 중앙 값이 년월이 다른 경우
                else {
                    // 오늘보다 중앙 값이 더 작다면 - 중앙 값과 중앙 값 이후의 데이터 사용
                    if( today.getTime() > center_year_and_month_date.getTime() ) {
                        const search_array_temp : any[] = JSON.parse(JSON.stringify(search_array));
                        search_array.splice(0, search_array.length);

                        const search_array_temp_length : number = search_array_temp.length;
                        for( let i = center_index; i < search_array_temp_length; i++) {
                            search_array.push(JSON.parse(JSON.stringify(search_array_temp[i])));
                        }

                        // search_array = search_array.slice(center_index, search_array.length);
                    }

                    // 오늘보다 중앙 값이 더 크다면 - 중앙 값과 중앙 값 이전의 데이터 사용
                    else {
                        const search_array_temp : any[] = JSON.parse(JSON.stringify(search_array));
                        search_array.splice(0, search_array.length);
                        
                        for( let i = 0; i <= center_index; i++) {
                            search_array.push(JSON.parse(JSON.stringify(search_array_temp[i])));
                        }

                        // search_array = search_array.slice(0, center_index + 1);
                    }
                }

                // 2개만 남았을때 둘 중 하나가 같은 년월이라면 해당 월의 일정데이터만 사용 
                if( search_array.length == 2 ) {
                    for( let search_obj of search_array ) {
                        
                        const search_obj_year_and_month_date : Date = new Date(search_obj.year_and_month.replace(/\./g, "-"));

                        // 년월이 같은 경우
                        if( search_obj_year_and_month_date.getFullYear() == today.getFullYear() && 
                            search_obj_year_and_month_date.getMonth() == today.getMonth() ) {
                            
                            // 해당 월의 일정만을 비교용으로 사용
                            search_array = [search_obj];
                            break;
                        }
                    }
                }
                    
            }
            
            // render_event의 일정 중 에서 가장 가까운 일정 하나를 구함
            if( search_array.length == 1 ) {
                
                const render_event : any = search_array[0];
                await this.scrollToClosedEventByRenderEvent(render_event, today);

                if( this.is_paging_end == true ) { this.is_first_api_call_complete = true; }
            }

            else if ( search_array.length == 2 ) {
                const first_year_and_month_date : Date = new Date(search_array[0].year_and_month.replace(/\./g, "-"));
                const second_year_and_month_date : Date = new Date(search_array[1].year_and_month.replace(/\./g, "-"));

                // 오늘 이전의 날짜 밖에 없는 경우 -> 가장 마지막 일정까지 스크롤됨
                if( second_year_and_month_date.getTime() < today.getTime() ) {
                    const render_event : any = search_array[1];
                    await this.scrollToClosedEventByRenderEvent(render_event, today);

                    if( this.is_paging_end == true ) { this.is_first_api_call_complete = true; }
                }

                // 오늘 이후의 날짜 밖에 없는 경우 -> 가장 처음 일정까지 스크롤
                else if( first_year_and_month_date.getTime() > today.getTime() ) {
                    const render_event : any = search_array[0];
                    await this.scrollToClosedEventByRenderEvent(render_event, today);

                    this.is_first_api_call_complete = true;
                }

                // 두 아이템의 사이에 오늘이 있는 경우 -> 두 아이템의 일정을 전부 비교 후 가장 가까운 일정까지 스크롤
                else {
                    await this.scrollToClosedEventByRenderEvents(search_array, today);
                    this.is_first_api_call_complete = true;
                } 
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        
        } finally {
            this.hodu_hide_indicator();
        }
    }

    /**
     * 해당 월의 가장 가까운 일정으로 스크롤
     */
    async scrollToClosedEventByRenderEvent(render_event : any, today : Date) : Promise<void> {
        let closed_date_diff = Number.MAX_SAFE_INTEGER; // 최대치
        let closed_event : t_event | null = null; 
        for( let event of render_event.events ) {
            const date_diff : number = this.getDateDiff((event as t_event).event_data.schedule_date.start, today);
            if( closed_date_diff > date_diff ) { 
                closed_date_diff = date_diff;
                closed_event = JSON.parse(JSON.stringify(event)); 
            }
        }

        // 오늘 날짜와 가장 비슷한 날의 제일 위쪽 일정으로 스크롤 하면 됨
        if( closed_event == null ) { return; }

        const target_html : JQuery<HTMLElement> = $(`#${closed_event.event_id}-${new Date(closed_event.event_data.schedule_date.start).getTime()}`);

        await this.$nextTick();

        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar("scrollTo", target_html.position().top, {
            timeout:0,
            scrollInertia:0,
        });
    }

    /**
     * 여러 월 중에 가장 가까운 일정으로 스크롤
     */
    async scrollToClosedEventByRenderEvents(render_events : any[], today : Date) : Promise<void> {
        let closed_date_diff = Number.MAX_SAFE_INTEGER; // 최대치
        let closed_event : t_event | null = null; 
        for( let render_event of render_events ) {
            for( let event of render_event.events ) {
                const date_diff : number = this.getDateDiff((event as t_event).event_data.schedule_date.start, today);
                if( closed_date_diff > date_diff ) { 
                    closed_date_diff = date_diff;
                    closed_event = JSON.parse(JSON.stringify(event)); 
                }
            }
        }

        // 오늘 날짜와 가장 비슷한 날의 제일 위쪽 일정으로 스크롤 하면 됨
        if( closed_event == null ) { return; }

        const target_html : JQuery<HTMLElement> = $(`#${closed_event.event_id}-${new Date(closed_event.event_data.schedule_date.start).getTime()}`);

        await this.$nextTick();

        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar("scrollTo", target_html.position().top, {
            timeout:0,
            scrollInertia:0,
        });
    }

    /**
     * 년, 월 string 받아오기
     */
    getYearAndMonth(start_date : Date) : string {
        const year : string = `${start_date.getFullYear()}`;
        const month : string = `0${start_date.getMonth() + 1}`.slice(-2); 
        return `${year}.${month}`;
    }

    /**
     * DTSTART 제작 : 일정 시작일
     */
    formatDateForRruleDTSTART(date : Date) : string {
        return `${dateFormat(date, "UTC:yyyymmdd")}T${dateFormat(date, "UTC:HHMMss")}Z`;
    }

    /**
     * UNTIL 제작 : 해당 날짜 -1일까지 뿌려줌
     */
    formatDateForRruleUNTIL(date : Date) : string {
        date.setDate(date.getDate() + 1); // 월의 마지막 일 이후로 넘어가면 자동으로 다음달로 계산해줌
        return `${dateFormat(date, "UTC:yyyymmdd")}`;
    }

    /**
     * 일자 받기
     */
    getDate(start_date : Date) : string {
        return `0${start_date.getDate()}`.slice(-2);
    }

    /**
     * 요일 반환
     */
    getDayOfWeek(start_date : Date) : string {
        switch( start_date.getDay() ) {
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";
        
            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "";
        } 
    }

    /**
     * 오늘인지 여부 반환
     */
    isToday(start_date : Date) : boolean {
        return (this.getDateDiff(new Date(), start_date) == 0);
    }

    /**
     * 다음 이벤트랑 같은 날의 일정인지 반환
     */
    isSameDay(events : t_event[], index : number) : boolean {
        try {
            if( events.length - 1 == index ) { return false; }

            const current_event_start : Date = new Date(events[index].event_data.schedule_date.start);
            const next_event_start : Date = new Date(events[index + 1].event_data.schedule_date.start);

            return current_event_start.getDate() == next_event_start.getDate();

        } catch(e) {
            return false;
        }
    }

    /**
     * 해당 일의 첫번째 날짜인지 반환
     */
    isFirstDayEvent(events : t_event[], index : number) : boolean {
        try {
            if( index == 0 ) { return true; }

            const current_event_start : Date = new Date(events[index].event_data.schedule_date.start);
            const prev_event_start : Date = new Date(events[index - 1].event_data.schedule_date.start);

            return current_event_start.getDate() != prev_event_start.getDate();

        } catch(e) {
            return false;
        }
    }

    /**
     * 과거 데이터인지 여부 반환
     */
    isPastDate(date : Date) : boolean {
        return date.getTime() < new Date().getTime()
    }

    /**
     * AM PM 시간 자리에 들어갈 텍스트 구하기
     */
    async getAmPmTime(start_date : Date, end_date : Date, is_all_day : boolean, lunar_yn : boolean) : Promise<string> {

        // 일반 일정
        if( is_all_day == false && lunar_yn == false ) {
            const hour : number = start_date.getHours() > 12 ? start_date.getHours() - 12 
                                                    : start_date.getHours() == 0 ? 12 : start_date.getHours();

            const hour_string : string = `0${hour}`.slice(-2);
            const min_string  : string = `0${start_date.getMinutes()}`.slice(-2);
            const am_pm_string : string = start_date.getHours() < 12 ? '오전' : '오후';
            
            let end_date_string : string = "";
            if( this.getDateDiff(start_date, end_date) > 0 ) {
                const end_year : string = `${end_date.getFullYear()}`;
                const end_month : string = `0${end_date.getMonth() + 1}`.slice(-2);
                const end_day : string = `0${end_date.getDate()}`.slice(-2);

                const end_hour : number = end_date.getHours() > 12 ? end_date.getHours() - 12 
                                                    : end_date.getHours() == 0 ? 12 : end_date.getHours();

                const end_hour_string : string = `0${end_hour}`.slice(-2);
                const end_min_string  : string = `0${end_date.getMinutes()}`.slice(-2);
                const end_am_pm_string : string = end_date.getHours() < 12 ? '오전' : '오후';
                const end_day_of_week : string = this.getDayOfWeekByDate(end_date);

                end_date_string = `~ ${end_year.substring(end_year.length - 2)}.${end_month}.${end_day} ${end_day_of_week} <span class="to_am_pm ${end_am_pm_string == '오전' ? 'am' : 'pm' }" style="margin-left : 2px;">${end_am_pm_string}</span> ${end_hour_string}:${end_min_string} 까지`;
            }

            return `<span class="from_am_pm ${am_pm_string == '오전' ? "am" : "pm" }" style="margin-left : 2px;">${am_pm_string}</span> ${hour_string}:${min_string} ${end_date_string}`;
        }

        // 종일 일정
        if( is_all_day == true && lunar_yn == false ) {
            return `종일`;
        }

        // 음력 일정
        if( lunar_yn == true ) {
            const lunar_date : string = await this.hodu_solar_to_lunar(start_date);
            
            // 음력 문자열 포맷 결정 되어야함
            const lunar_arr : string[] = lunar_date.split('-');

            return `음력 ${ lunar_arr[1] }월 ${ lunar_arr[2] }일 `;
        }

        // 에러
        return '';
    }

    /**
     * 작성자 이름 반환
     */
    getOwnerName(owner_id : number, owner_name : string) : string {
        if( owner_id == this.user_id ) {
            return `나(${this.user_name})`;
        }

        return owner_name;
    }

    /**
     * 반복 여부 반환
     */
    isRepeat(rrule : string) : boolean {
        return rrule != null && rrule.length > 0;
    }

    /**
     * 알람 존재 여부 반환
     */
    hasAlarm(alarm : t_event_alarm[]) : boolean {
        return alarm != null && alarm.length > 0;
    }

    /**
     * 장소 존재 여부 반환
     */
    hasLocation(location : t_event_location[]) : boolean {
        return location != null && location.length > 0;
    }

    /**
     * 전화번호 존재 여부 반환 (현재 0번째 인덱스만 사용 중, 나중에 배열로 바뀐다면 바꿔야함)
     */
    hasContact(contact : t_event_contact[]) : boolean {
        return contact != null && contact.length > 0 && contact[0].tel != null && contact[0].tel.length > 0;
    }

    /**
     * 노트 존재 여부 반환
     */
    hasNote(note : string) : boolean {
        return note != null && note.length > 0;
    }

    /**
     * 메모 존재 여부 반환
     */
    hasMemo(memo : string) : boolean {
        return memo != null && memo.length > 0;
    }

    /**
     * 사진 존재 여부 반환
     */
    hasImage(attachment : any) : boolean {
        return attachment != null && attachment.imgs != null && attachment.imgs.length > 0;
    }

    /**
     * 파일 존재 여부 반환
     */
    hasFile(attachment : any) : boolean {
        return attachment != null && attachment.files != null && attachment.files.length > 0;
    }

    /**
     * 투표 존재여부 반환
     */
    hasVote(vote : t_event_vote[]) : boolean {
        return vote != null && vote.length > 0;        
    }

    /**
     * 댓글 존재여부 반환
     */
    hasReply(exist_reply : boolean) {
        return exist_reply != null && exist_reply == true;
    }

    /**
     * 공유 일정인지 여부 반환
     */
    isShared(subscribe_users : number[], subscribe_groups : number[], subscribe_teams : number[]) : boolean {
        return ( subscribe_users  != null && subscribe_users.length > 0 ) ||
               ( subscribe_groups != null && subscribe_groups.length > 0 ) || 
               ( subscribe_teams  != null && subscribe_teams.length > 0 );
    }
    
    /**
     * 사용자의 참석여부 상태 텍스트 반환
     */
    getAttendText(event : t_event) : string {
        let attend_text : string = "참석 미정";

        switch( event.user_attend ) {
            case "Y": return "참석";
            case "N": return "참석 불가";
            case "-": return "참석 미정";
        }

        return attend_text;
    }

    /**
     * 투표의 진행 상태 텍스트 반환
     */
    getVoteText(event : t_event, return_class : boolean = false) : string {
        let vote_text : string = "";

        if( event.event_data.vote != null && event.event_data.vote.length > 0 ) {

            // 투표 종료일이 지난 경우
            if( new Date(event.event_data.vote[0].end_date).getTime() <= new Date().getTime() ) {
                vote_text = "투표/설문 종료";
                if( return_class ) { vote_text = "x_red"; }
            }

            // 투표 종료일이 자나지 않은 경우는 투표가 전부 조기 종료 됐는지 체크
            else {
                let is_vote_end_all = true;
                for( let vote of event.event_data.vote ) {
                    is_vote_end_all = vote.is_end && is_vote_end_all
                }

                vote_text = is_vote_end_all ? "투표/설문 종료" : "투표/설문 진행중"
                if( return_class ) { vote_text = is_vote_end_all ? "x_red" : "x_blue" }
            }

        }

        return vote_text;
    }

    /**
     * 참석, 투표 진행 상황 텍스트 반환
     */
    // getAttendVoteText(attend : boolean, user_attend : string, vote : t_event_vote[]) : string {

    //     // 투표가 있다면
    //     if( vote != null && vote.length > 0 ) {
            
    //         const target_date : Date = new Date();
    //         let end_of_vote : boolean = true;

    //         const event_vote_length : number = vote.length;
    //         for( let i = 0; i < event_vote_length; i++ ) {
    //             end_of_vote = end_of_vote && ( vote[i].is_end == true || new Date(vote[i].end_date).getTime() <= target_date.getTime() );
    //         }

    //         return `투표 ${ end_of_vote ? "종료" : "진행 중"}`;
    //     }

    //     if( attend == true ) {
    //         switch( user_attend ) {
    //             case 'Y':
    //                 return '참석';

    //             case 'N':
    //                 return '불참';

    //             default:
    //                 return "미정"; 
    //         }
    //     }

    //     return '';
    // }

    /**
     * 참석, 투표 진행 상황 텍스트 반환
     */
    getAttendVoteClass(attend : boolean, user_attend : string, vote : t_event_vote[]) : string {

        // 투표가 있다면
        if( vote != null && vote.length > 0 ) {
            
            const target_date : Date = new Date();
            let end_of_vote : boolean = true;

            const event_vote_length : number = vote.length;
            for( let i = 0; i < event_vote_length; i++ ) {
                end_of_vote = end_of_vote && ( vote[i].is_end == true || new Date(vote[i].end_date).getTime() <= target_date.getTime() );
            }

            return end_of_vote == true ? 'endVote' : 'ingVote';
        }

        if( attend == true ) {
            switch( user_attend ) {
                case 'Y':
                    return 'attendY';

                case 'N':
                    return 'attendN';

                default:
                    return "undef"; 
            }
        }

        return '';
    }

    /**
     * 스크롤 리스너 - 페이징 처리
     */
    @Throttle(500)
    async whileScrolling() : Promise<void> {
        // console.log(`${$('.schListDiv').height()}, ${$('#eventSearchDiv').find('.mCSB_container').css('top')}, ${window.innerHeight}`);

        try {
            const schedule_list_height : number | undefined = $('.schListDiv').height();
            let scroll_container_top : number = 0;
            let scroll_container_top_string : string = "";
            
            try {
                scroll_container_top_string = $('#eventSearchDiv').find('.mCSB_container').css('top');

                if(scroll_container_top_string) {
                    scroll_container_top_string = scroll_container_top_string.replace('px', '');
                    scroll_container_top = Math.abs(Number(scroll_container_top_string));
                } else {
                    scroll_container_top = 0;
                }
            } catch(e) {
                scroll_container_top = 0;
            }
            
            // console.log(`schedule_list_height : ${schedule_list_height}`);
            // console.log(`scroll_container_top : ${scroll_container_top}`);
            // console.log(`window.innerHeight : ${window.innerHeight}`);
            // console.log(`result : ${( ( schedule_list_height == null ? 0 : schedule_list_height ) - scroll_container_top - window.innerHeight )}`);

            // if( this.is_first_api_call_complete == false ) return;

            if( ( ( schedule_list_height == null ? 0 : schedule_list_height ) - scroll_container_top - window.innerHeight ) < 250 ) {
                if( this.is_event_filter_search == false ) { await this.searchEventForWeb(); }
                else { await this.searchEventForWebByFilter(); }
            }
        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
        
    }

    /**
     * 일정 보기
     */
    async viewEvent(eventAtomic : t_event) : Promise<void> {
        
        if( eventAtomic.event_data.approval && eventAtomic.event_data.approval.approval_uid && eventAtomic.event_data.approval.approval_uid.length > 0 ) {
            
            const approver : number[] = Array.from(eventAtomic.event_data.approval.approver ? eventAtomic.event_data.approval.approver : [], x => x.user_id);
            const receiver : number[] = Array.from(eventAtomic.event_data.approval.receive_reference ? eventAtomic.event_data.approval.receive_reference : [], x => x.user_id);
            
            // 작성자, 결재자, 수신참조만 기안서 화면으로 이동
            if( eventAtomic.event_data.event_owner_id == this.user_id || approver.indexOf(this.user_id) > -1 || receiver.indexOf(this.user_id) > -1 ) {
                this.hodu_router_push(`GROUP/${eventAtomic.group_id}/approval/${eventAtomic.event_data.approval.approval_uid}`);
            }

            return;
        }

        if( eventAtomic.event_sub_type == "APPOINTMENT" ) {
            if( !this.doSetAppointmentDetailInfo ) { return; }
            this.doSetAppointmentDetailInfo({
                event : eventAtomic,
                is_patient : true,
            })
            this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${eventAtomic.event_id}`);
            return;
        }

        // ONLY 연속 일정인 경우
        if( eventAtomic.original_start != null && this.getDateDiff(eventAtomic.original_start, eventAtomic.event_data.schedule_date.end) > 0 &&
            (eventAtomic.event_data.schedule_date.rrule == null || eventAtomic.event_data.schedule_date.rrule.length < 1) ) {
            eventAtomic.event_data.schedule_date.start = eventAtomic.original_start;
        }

        // 양력 반복 일정인 경우
        if( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.lunar_yn == false && 
            eventAtomic.original_start != null && eventAtomic.original_end != null ) {

            // 해당 반복일정의 첫 일정인지 구하기
            const dtStart : Date = new Date(eventAtomic.event_data.schedule_date.start);
            
            // DTSTART랑 UNTIL이 이미 들어있다면 제거후 재등록
            let rrule_string : string = eventAtomic.event_data.schedule_date.rrule;
            if( rrule_string.indexOf(';UNTIL') > -1 ) {
                rrule_string = rrule_string.substring(0, rrule_string.indexOf(';UNTIL'));
            }
            if( rrule_string.indexOf('FREQ') > - 1 ) {
                rrule_string = rrule_string.substring(rrule_string.indexOf('FREQ'));
            }
            eventAtomic.event_data.schedule_date.rrule = rrule_string;

            const rrule : RRule | RRuleSet = rrulestr(`DTSTART:${this.formatDateForRruleDTSTART(dtStart)}\nRRULE:${eventAtomic.event_data.schedule_date.rrule};UNTIL=${this.formatDateForRruleUNTIL(new Date(eventAtomic.event_data.schedule_date.recurrence_end))}`);

            this.doSetIsFirstRepeatEvent( new Date(eventAtomic.original_start).getTime() == new Date(eventAtomic.event_data.schedule_date.start).getTime() && 
                                          ( rrule.all()[0].getTime() == new Date(eventAtomic.event_data.schedule_date.start).getTime() ) );

            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : new Date(eventAtomic.original_start),
                original_end : new Date(eventAtomic.original_end)
            })
            
            // 연속&반복 일정이라면
            const diff : number = this.getDateDiff(eventAtomic.original_start, eventAtomic.original_end);
            if( diff > 0 ) {
                const end : Date =  new Date(eventAtomic.event_data.schedule_date.end);
                const start : Date =  new Date(eventAtomic.event_data.schedule_date.start);

                start.setDate(end.getDate() - diff);
                eventAtomic.event_data.schedule_date.start = start;
            }

        }
        
        // 음력 반복 일정
        else if ( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.lunar_yn == true &&
                  eventAtomic.original_start != null && eventAtomic.original_end != null ) {

            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : new Date(eventAtomic.original_start),
                original_end : new Date(eventAtomic.original_end)
            });
        }

        // 웹에서만 사용하는 칼럼 undefined로 변경
        eventAtomic.lunar_date_text    = undefined;
        eventAtomic.exist_reply        = undefined;
        eventAtomic.user_attend        = undefined;
        eventAtomic.work_status_info   = undefined;
        eventAtomic.work_template_info = undefined;
        eventAtomic.original_start     = undefined;
        eventAtomic.original_end       = undefined;

        // EventInfo에 이벤트 등록
        this.doSetEvent(eventAtomic);
        this.doSetEventCrudType(CRUD_TYPE.READ);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 일정 조회 페이지로 이동 
        this.hodu_router_push(`/event/${eventAtomic.event_id}`);
    }

    /**
     * 일정 검색 옵션이 바뀐 것을 감지
     */
    @Watch('schedule_search_config', { immediate : false, deep : true })
    @Debounce(100)
    onScheduleSearchConfigChanged(): void {
        this.doSelectScheduleList({"schedule_search_config" : this.schedule_search_config});
        this.newSearchEvent(true);
    }

    /**
     * 일정 검색 옵션 ON / OFF
     */
    searchOptionOnOff() : void {
        const vue = this;

        this.searchOptionVisible = !this.searchOptionVisible;

        if( this.searchOptionVisible ) { 

            if( this.event_search_option.start_date != null ) {
                const date_format_text : string = moment(this.event_search_option.start_date).format("YYYY.MM.DD");
                const week_of_day : string = this.getDayOfWeekByDate(this.event_search_option.start_date, "요일");
                const final_date_text : string = `${date_format_text} ${week_of_day}`;
                this.start_date_text = final_date_text;
            }

            if( this.event_search_option.end_date != null ) {
                const date_format_text : string = moment(this.event_search_option.end_date).format("YYYY.MM.DD");
                const week_of_day : string = this.getDayOfWeekByDate(this.event_search_option.end_date, "요일");
                const final_date_text : string = `${date_format_text} ${week_of_day}`;
                this.end_date_text = final_date_text;
            }

            setTimeout(() => { 
                const option : any = {
                    inline: false,
                    showOtherMonths: true,
                    selectOtherMonths: true,
                    dateFormat: 'yy-mm-dd',
                    monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                    dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                    yearSuffix : '.',
                    blankSpace : '',
                    changeYear : true,
                    yearRange  : '1970:2050',
                    onSelect: function (dateText, inst) {
                        const id : string | undefined = $(this).attr('id');

                        const selected_date : Date = new Date(dateText);
                        selected_date.setHours(0);
                        selected_date.setMinutes(0);
                        selected_date.setSeconds(0);
                        selected_date.setMilliseconds(0);

                        const date_format_text : string = moment(selected_date).format("YYYY.MM.DD");
                        const week_of_day : string = vue.getDayOfWeekByDate(selected_date, "요일");
                        
                        const final_date_text : string = `${date_format_text} ${week_of_day}`;

                        if( id == "search_sch_from" ) {
                            vue.start_date_text                = final_date_text;
                            vue.event_search_option.start_date = selected_date;

                            // end가 비어있지 않고 start보다 작다면 end를 start로 덮어씌운다
                            if( vue.event_search_option.end_date != null && new Date(vue.event_search_option.end_date).getTime() < selected_date.getTime() ) {
                                vue.end_date_text                = final_date_text;
                                vue.event_search_option.end_date = selected_date;
                            }

                        }

                        if( id == "search_sch_to" ) {
                            vue.end_date_text                = final_date_text;
                            vue.event_search_option.end_date = selected_date;

                            // start가 비어있지 않고 end보다 크다면 start를 end로 덮어씌운다
                            if( vue.event_search_option.start_date != null && new Date(vue.event_search_option.start_date).getTime() > selected_date.getTime() ) {
                                vue.start_date_text                = final_date_text;
                                vue.event_search_option.start_date = selected_date;
                            }
                        }
                    }
                };

                // @ts-ignore
                $('#search_sch_from').datepicker(option);

                // @ts-ignore
                $('#search_sch_to').datepicker(option);
            }, 10); 
        }
    }

    /**
     * 일정 검색 타입 변경
     */
    searchTypeChange(event : any) : void {
        this.event_search_option.search_event_type = event.target.value;

        const value : string = event.target.value;

        if( value != "WORK" ) {
            this.event_search_option.is_work_wait = false;
            this.event_search_option.is_work_start = false;
            this.event_search_option.is_work_end = false;
        }

        if( value == "WORK" ) {
            this.event_search_option.has_attend = false;
            this.event_search_option.has_vote   = false;
        }

    }

    /**
     * 파일 검색 허용 할 것인지 여부
     */
    checkFileEnabled(value : string) : boolean {
        
        // 특정 그룹, 팀이 선택 된 경우
        const reg : RegExp = new RegExp(/\d-\d/);
        if( reg.test(value) == true ) {
            let group_id : number = 0;
            let team_id : number = 0;

            try {
                const scope_ids : string[] = value.split("-");
                if( scope_ids[0] != null ) { group_id = Number(scope_ids[0]); }
                if( scope_ids[1] != null ) { team_id  = Number(scope_ids[1]); }

                const group_team_count : number = this.computedGroupAndTeam.length;
                for( let i = 0; i < group_team_count; i++ ) {
                    const group_team : any = this.computedGroupAndTeam[i];
                    if( group_team.group_id == group_id && group_team.team_id == team_id ) {
                        return group_team.limit_event_attachment > 0;
                    }
                }

            } catch(e) {
                this.hodu_error_process(e, false, false, false);
            }

            return false;
        }

        // 특정 그룹, 팀이 선택된게 아니라면 무조건 검색 허용
        return true;
    }

    /**
     * 해당 컬러가 검색 할 컬러에 포함되어있는지 여부 반환
     */
    isContainColor(color : string) : boolean {
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();
        return (this.event_search_option.event_color.indexOf(upper_color) > -1);
    }
    
    /**
     * 검색 컬러 변경
     */
    changeSearchColor(event : any, color : string) : void {
        const checked : boolean = event.target.checked;
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();

        const color_index : number = this.event_search_option.event_color.indexOf(upper_color);

        // 체크 하는 경우
        if( checked == true ) {
            if( color_index < 0 ) { this.event_search_option.event_color.push(upper_color); }
        }

        // 체크를 해제 하는 경우
        else {
            if( color_index > -1 ) { this.event_search_option.event_color.splice(color_index, 1); }
        }
    }

    /**
     * 모든 컬러가 선택 되었는지 여부
     */
    isCheckedAllColor() : boolean {
        return this.event_search_option.event_color.length >= 20;
    }

    /**
     * 작성자 영역을 보여줄것인지 (개인일정에서 본인이 작성한 일정은 보이지 않음)
     */
    isVisibleWriter(event : t_event) : boolean {
        if( event.event_data.event_owner_id == this.user_id ) {
            return ( event.group_id != null && event.group_id > 0 ) || (event.team_id != null && event.team_id > 0);
        }

        return true;
    }

    /**
     * 모든 색상 선택 체크 변경
     */
    changeCheckAllColor(event : any) : void {
        const checked : boolean = event.target.checked;
        this.event_search_option.event_color.splice(0, this.event_search_option.event_color.length);
        if( checked == true ) {
            for( let color of this.dc_color ) { this.event_search_option.event_color.push(color); }
            for( let color of this.lc_color ) { this.event_search_option.event_color.push(color); }
        }
    }

    /**
     * 검색 옵션 리셋
     */
    searchOptionReset() : void {
        this.start_date_text = null;
        this.end_date_text = null;

        const option : EventSearchOption = {
            search_event_type : "ALL",
            event_color : [],
            has_photo : false,
            has_file : false,
            has_chat : false,
            has_attend : false,
            has_vote : false,
            is_work_wait : false,
            is_work_start : false,
            is_work_end : false
        };

        if( this.doSetEventSearchOption != null ) { this.doSetEventSearchOption(option); }
    }

    /**
     * 업무 상태 텍스트 반환
     */
    getWorkStatusText(event : t_event) : string {
        if( !event.event_data.work ) { return ""; }

        switch( event.event_data.work.work_status_code ) {
            case "WAIT": return "대기";
            case "START": return "진행";
            case "END": return "완료";
            case "CANCEL": return "중단";
        }

        return "";
    }

    /**
     * 업무 타입인지 체크
     */
    isSubTypeWork(event : t_event) : boolean {

        if( event == null || event.event_sub_type == null || event.event_sub_type.length < 1 ) return false;

        const event_sub_type = event.event_sub_type;

        return this.isWork((event_sub_type as EVENT_SUB_TYPE)) == true || 
               this.isReport((event_sub_type as EVENT_SUB_TYPE)) == true ||
               this.isMeetingLog((event_sub_type as EVENT_SUB_TYPE)) == true;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#eventSearchDiv').mCustomScrollbar('destroy')
    	this.setScroll();
    }
    
}
