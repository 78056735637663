
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';
import { t_event } from '@/model/event';
import { hodu_color } from '@/common/color';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class AppointmentApplyModal extends Mixins(VueHoduCommon) {

    hodu_doc_enum : any = hodu_doc_enum;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State appointment_apply_modal_info !: hodu_doc_modal_info.AppointmentApplyModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentApplyModalInfo ?: (params : hodu_doc_modal_info.AppointmentApplyModalInfo) => void;

    event : t_event | null = null;
    doctor_name : string = "";
    department_name : string = "";

    memo : string = ""; // 거절시 거절사유, 승인시 준비사항 등

    async mounted() : Promise<void> {
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);
        this.getEvent();
    }

    /**
     * 예약 조회
     */
    getEvent() : void {
        const calendar_id : string = this.calendar_id;
        const scope       : string = OWNER_TYPE.GROUP;
        const scope_id    : number = this.scope_group_id;

        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${this.appointment_apply_modal_info.event_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                this.event = JSON.parse(JSON.stringify(response.data.data.event_data));

                if( this.event == null || this.event.event_data.appointment == null || this.department_info == null || this.doctor_info == null ) { return; }

                const department_code = this.event.event_data.appointment.department_code;
                const doctor_code     = this.event.event_data.appointment.doctor_code;

                const departments : hodu_doc_object.department_info[] = this.department_info.filter(item => item.department_code == department_code);
                const doctors : hodu_doc_object.doctor_info[] = this.doctor_info.filter(item => item.department_code == department_code && item.doctor_code == doctor_code);

                if( departments.length > 0 ) {
                    this.department_name = departments[0].department_name;
                }

                if( doctors.length > 0 ) {
                    this.doctor_name = doctors[0].doctor_name;
                }

                // 거절된 예약이라면 거절사유를 세팅한다
                if( this.event.event_data.appointment.appointment_status == hodu_doc_enum.appointment_status_code.DECLINE ) {
                    this.memo = this.event.event_data.appointment.appointment_decline_reason ? this.event.event_data.appointment.appointment_decline_reason : '';
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })
    }

    /**
     * 예약 수정
     */
    updateEvent() : void {
        
        if( this.event == null ) { return; }

        const calendar_id : string = this.calendar_id;
        const scope       : string = OWNER_TYPE.GROUP;
        const scope_id    : number = this.scope_group_id;

        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${this.appointment_apply_modal_info.event_id}`, API_METHOD.PUT, this.event.event_data)
                .then((response) => {
                    console.log(response);
                    
                    if( this.event == null || this.event.event_data.appointment == null ) { return; }

                    // 거절인 경우 재조회
                    if( this.event.event_data.appointment.appointment_status == hodu_doc_enum.appointment_status_code.DECLINE ) {
                        if( this.appointment_apply_modal_info.apply_callback ) { this.appointment_apply_modal_info.apply_callback(); }
                    }

                    // 승인인 경우 상세 페이지로 이동
                    if( this.event.event_data.appointment.appointment_status == hodu_doc_enum.appointment_status_code.CONFIRM ) {
                        if( this.appointment_apply_modal_info.apply_callback ) { this.appointment_apply_modal_info.apply_callback(); }
                        // this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${this.event.event_id}`);
                    }

                    this.closeModal();
                })
                .catch((e) => {
                    this.hodu_error_process(e, false, false, true);
                });
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetAppointmentApplyModalInfo == null ) { return; }

        this.doSetAppointmentApplyModalInfo({ 
            show_modal : false,
            event_id : '', 
        })
    }

    /**
     * 예약 거부
     */
    declineAppointment() : void {
        if( this.event == null || this.event.event_data.appointment == null ) { return; }

        this.event.event_data.appointment.appointment_status = hodu_doc_enum.appointment_status_code.DECLINE;
        this.event.event_data.appointment.appointment_decline_reason = this.memo;
        this.event.event_data.color = hodu_color.appointment_decline;

        this.updateEvent();
    }

    /**
     * 예약 승인
     */
    applyAppointment() : void {
        if( this.event == null || this.event.event_data.appointment == null ) { return; }

        this.event.event_data.appointment.appointment_status = hodu_doc_enum.appointment_status_code.CONFIRM;
        this.event.event_data.appointment.prescription = this.memo;
        this.event.event_data.color = hodu_color.appointment_confirm;

        this.updateEvent();
    }

}
