import Vue, { PluginObject } from "vue";
import axios from "axios";
import { hodu_local_storage } from '@/lib/HoduLocalStorage';
import { hodu_cookie } from '@/lib/HoduCookie';
import { getOSInfo, getBrowserInfo } from '@/mixin/VueHoduCommon';
const uuidV4 = require("uuid/v4");

declare var APP_VERSION: string;

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(async(cfg) => {
    if(sessionStorage != null) {

        // tslint:disable-next-line: typedef
        let device_uid : string | null = null;

        // device_uid를 session 스토리지에서 가져옴
        device_uid = sessionStorage.getItem("device_uid");
        const device_uid_cookie = hodu_cookie.get("device_uid");
        
        // 로컬스토리지에 값이 없다면 쿠키체크
        if( device_uid == null || device_uid.length < 1 ) {
            device_uid = device_uid_cookie;
            sessionStorage.setItem("device_uid", device_uid as string);
            if( localStorage != null ) { hodu_local_storage.setItem("device_uid",device_uid as string); }
        }

        // 쿠키를 체크해도 device_uid가 없다면 생성 후 local & session에 등록
        if( device_uid == null || device_uid.length < 1 ) {
            device_uid = uuidV4();
            sessionStorage.setItem("device_uid", device_uid as string);
            if( localStorage != null ) { hodu_local_storage.setItem("device_uid",device_uid as string); }
        }

        hodu_cookie.set("device_uid", device_uid as string);

        // 세션 토큰 받아와서 있다면 cfg 헤더에 등록 및 device-uid cfg 헤더에 등록
        const session_token = sessionStorage.getItem("session_token");
        if( session_token != null ) { cfg.headers["authorization"] = session_token; }
        cfg.headers["device-uid"] = device_uid;
        cfg.headers["device-os"] = "WEB";
            try {
                cfg.headers["app-version"] = APP_VERSION;
                cfg.headers["device-model"] = await getOSInfo();
                cfg.headers["device-os-ver"] = await getBrowserInfo();
            } catch(e) {
                cfg.headers["app-version"] = "5.8.32";
                cfg.headers["device-model"] = "unknown";
                cfg.headers["device-os-ver"] = "unknown";
            }
        }
        return cfg;
    },
    (err) => {
        // Do something with request error
        return Promise.reject(err);
    },
);

// Add a response interceptor
_axios.interceptors.response.use(
    (res) => {
        // Do something with response data
        return res;
    },
    (err) => {
        // Do something with response error
        return Promise.reject(err);
    },
);

const Plugin: PluginObject<any> = {
    install: (Vue) => {
        Vue.$http = _axios;
    },
};

Plugin.install = (Vue) => {
    Vue.$http = _axios;
    window.axios = _axios;

    Object.defineProperties(Vue.prototype, {
        $http: {
            get() {
                return _axios;
            },
        }
    });
};

Vue.use(Plugin);

export default Plugin;
