
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const OrganizationInfo = namespace('OrganizationInfo');

import OrganizationRankSetting from '@/components/organization/OrganizationRankSetting.vue';
import OrganizationApprovalSetting from '@/components/organization/OrganizationApprovalSetting.vue';
import OrganizationEmpWorkTypeSetting from '@/components/organization/OrganizationEmpWorkTypeSetting.vue';
import OrganizationEmpOffSetting from '@/components/organization/OrganizationEmpOffSetting.vue';
import OrganizationEnteredDateSetting from '@/components/organization/OrganizationEnteredDateSetting.vue';
import OrganizationDepartmentSetting from '@/components/organization/OrganizationDepartmentSetting.vue';
import OrganizationPositionSetting from '@/components/organization/OrganizationPositionSetting.vue';
import OrganizationWorkTypeSetting from '@/components/organization/OrganizationWorkTypeSetting.vue';
import OrganizationVacationTypeSetting from '@/components/organization/OrganizationVacationTypeSetting.vue';
import OrganizationHolidaySetting from '@/components/organization/OrganizationHolidaySetting.vue';
import OrganizationOffSetting from '@/components/organization/OrganizationOffSetting.vue';
import OrganizationQuitterSetting from '@/components/organization/OrganizationQuitterSetting.vue';

import { organization_enum } from '@/model/organization';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRankSetting, OrganizationApprovalSetting, OrganizationEmpWorkTypeSetting, OrganizationEmpOffSetting, 
        OrganizationEnteredDateSetting, OrganizationDepartmentSetting, OrganizationPositionSetting, 
        OrganizationWorkTypeSetting, OrganizationVacationTypeSetting, OrganizationHolidaySetting,
        OrganizationOffSetting, OrganizationQuitterSetting,
        ResizeObserver
    },
})
export default class OrganizationSetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;

    event_bus : Vue = new Vue();

    /**
     * @OrganizationInfo.State
     */
    @OrganizationInfo.State selected_tab !: organization_enum.ORGANIZATION_SETTING_TAB;

    /**
     * @OrganizationInfo.Action
     */
    @OrganizationInfo.Action doSetOrganizationSelectedTab ?: (params : organization_enum.ORGANIZATION_SETTING_TAB) => void;

    is_close_position : boolean = false;

    mounted() : void {
        // //직급설정
        // // 버튼 클릭시 라인과 팀 보이기
        // $(".arw").click(function(){
        //     // 리스트 인덱스 가져오기
        //     var getIndex = $(this).parent().index();
            
        //     // 버튼 클릭시 라인과 팀 보이기
        //     $(".fList").eq(getIndex).toggleClass("showTeam");

        // });//근무 타입 설정
        // $(".arw2").click(function(){
        //     // 리스트 인덱스 가져오기
        //     var getIndex = $(this).parent().index();
            
        //     // 버튼 클릭시 라인과 팀 보이기
        //     $(".fList2").eq(getIndex).toggleClass("showTeam");

        // });//연차설정
        // $(".arw3").click(function(){
        //     // 리스트 인덱스 가져오기
        //     var getIndex = $(this).parent().index();
            
        //     // 버튼 클릭시 라인과 팀 보이기
        //     $(".fList3").eq(getIndex).toggleClass("showTeam");

        // });

    }

    /**
     * 탭 선택
     */
    selectTab(tab : organization_enum.ORGANIZATION_SETTING_TAB) : void {
        this.doSetOrganizationSelectedTab?.(tab);
    }

    /**
     * 직원 기본 설정 탭
     */
    isRank() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.RANK;
    }

    /**
     * 직원 퇴사자 탭
     */
    isQuitter() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.QUITTER;
    }

    /**
     * 직원 결재 설정 탭
     */
    isApproval() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.APPROVAL;
    }

    /**
     * 직원 근무 설정 탭
     */
    isEmpWorkType() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.EMP_WORK_TYPE;
    }

    /**
     * 직원 연차 설정 탭
     */
    isEmpOff() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.EMP_OFF;
    }

    /**
     * 직원 입사일 설정 탭
     */
    isEnteredDate() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.ENTERED_DATE;
    }

    /**
     * 부서 설정 탭
     */
    isDepartment() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.DEPARTMENT;
    }

    /**
     * 부서 설정 탭
     */
    isPosition() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.POSITION;
    }

    /**
     * 근무 설정 탭
     */
    isWorkType() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.WORK_TYPE;
    }

    /**
     * 휴가 타입 탭
     */
    isVacationType() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.VACATION_TYPE;
    }

    /**
     * 법정공휴일 탭
     */
    isHoliday() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.HOLIDAY;
    }

    /**
     * 휴무일 탭
     */
    isOff() : boolean {
        return this.selected_tab == organization_enum.ORGANIZATION_SETTING_TAB.OFF;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        if( this.isRank() == true ) {
            this.event_bus.$emit('OrganizationRankSettingResize');
            return;
        }

        else if( this.isQuitter() == true ) {
            this.event_bus.$emit('OrganizationQuitterSettingResize');
            return;
        }

        else if( this.isEmpWorkType() == true ) {
            this.event_bus.$emit('OrganizationEmpWorkTypeSettingResize');
            return;
        }

        else if( this.isEmpOff() == true ) {
            this.event_bus.$emit('OrganizationEmpOffSettingResize');
            return;
        }

        else if( this.isEnteredDate() == true ) {
            this.event_bus.$emit('OrganizationEnteredDateSettingResize');
            return;
        }

        else if( this.isDepartment() == true ) {
            this.event_bus.$emit('OrganizationDepartmentSettingResize');
            return;
        }

        else if( this.isPosition() == true ) {
            this.event_bus.$emit('OrganizationPositionSettingResize');
            return;
        }

        else if( this.isWorkType() == true ) {
            this.event_bus.$emit('OrganizationWorkTypeSettingResize');
            return;
        }

        else if( this.isVacationType() == true ) {
            this.event_bus.$emit('OrganizationVacationTypeResize');
            return;
        }

        else if( this.isHoliday() == true ) {
            this.event_bus.$emit('OrganizationHolidaySettingResize');
            return;
        }

        else if( this.isOff() == true ) {
            this.event_bus.$emit('OrganizationOffSettingResize');
            return;
        }
    }

}
