
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_enum, organization_modal_interface } from '@/model/organization';

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

@Component({
    components: {
        OrganizationRecursion
    },
})
export default class OrganizationApprovalSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME ) {

                if( o1_name == o2_name ) {
                    // 같은 이름은 직급순
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_name > o2_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION ) {

                // 같은 직급은 이름순
                if( o1_pos_seq == o2_pos_seq ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0
                }
                else if( o1_pos_seq > o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
                else if( o1_pos_seq < o2_pos_seq ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.DEPARTMENT ) {
                const o1_dept_id   = o1.dept_id;
                const o2_dept_id   = o2.dept_id;

                const o1_dept_name = this.getDepartmentName(o1_dept_id);
                const o2_dept_name = this.getDepartmentName(o2_dept_id);

                if( o1_dept_name == o2_dept_name ) {

                    if( o1_dept_id == o2_dept_id ) {
                        // 같은 이름은 dept_id 순서 + 직급순
                        if( o1_pos_seq > o2_pos_seq ) return 1;
                        else if( o1_pos_seq < o2_pos_seq ) return -1;
                        return 0;
                    }
                    else if( o1_dept_id > o2_dept_id ) return 1;
                    else if( o1_dept_id < o2_dept_id ) return -1;
                    
                    return 0;
                }
                else if( o1_dept_name > o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_dept_name < o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if ( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.APPROVER ) {
                const o1_is_approver = o1.is_approver;
                const o2_is_approver = o2.is_approver;

                if( o1_is_approver == o2_is_approver ) {
                    // 같다면 직급 순
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_is_approver == false && o2_is_approver == true ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_is_approver == true && o2_is_approver == false ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if ( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.EMAIL ) {
                const o1_email = o1.user_email;
                const o2_email = o2.user_email;

                if( o1_email == o2_email ) {
                    // 이메일이 같다면 직급 순 (일반적으로 데이터가 들어있다면 같을 순 없지만 비어있다면 똑같을수 있음)
                    if( o1_pos_seq > o2_pos_seq ) return 1;
                    else if( o1_pos_seq < o2_pos_seq ) return -1;
                    return 0;
                }
                else if( o1_email > o2_email ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_email < o2_email ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            return 0;
        });

        // 검색
        if( this.search_query != null && this.search_query.length > 0 ) {
            employees = employees.filter(item => 
                                                 // 이름   
                                                 this.hodu_string_includes(item.user_name, this.search_query) ||
                                                 
                                                 // 부서
                                                 this.hodu_string_includes(this.getDepartmentName(item.dept_id), this.search_query) );
        }

        return employees;
    }

    /**
     * @ModalInfo.Action 
     */
    @ModalInfo.Action doSetOrganizationSignModalInfo ?: (params : organization_modal_interface.OrganizationSignModalInfo) => void;

    departments : any[] = [];
    selected_department : any = { dept_id : -1 }; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];
    search_query : string = "";

    sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE = organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.POSITION;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    department_scroll_height : number = 0;

    is_close_organization : boolean = false;
    
    async mounted() : Promise<void> {
        this.event_bus?.$on("OrganizationApprovalSettingResize", this.handleResize);

        //사인등록 모달
        // $('.addSign').click(function(){
        //     $('.signModalDiv').fadeIn(200);
        //     $('.modal').show();
        //     return false;
        // })

        //사인등록 모달 닫기
        // $('.modal .btns input.close').click(function(){
        //     $('.signModalDiv').fadeOut()
        // })

        await this.getTotalOrganizationData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_box_height = $('#organization_setting .title_box').outerHeight();
        const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            
        // 조직도 스크롤
        const all_height = $('#organization_setting .approval_content .wrapper .chk.all').outerHeight();
        const content_top_height = $('#organization_setting .approval_content .content_top').outerHeight();

        this.department_scroll_height = window.innerHeight - (title_box_height ? title_box_height : 0)
                                                           - (title_box2_height ? title_box2_height : 0)
                                                           - (content_top_height ? content_top_height : 0)
                                                           - (all_height ? all_height : 0);

        // 부서원 리스트 스크롤
        const fixed_div_height = $('#organization_setting .approval_content .section_scroll .fixedDiv').outerHeight();

        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (title_box2_height ? title_box2_height : 0)
                                           - (content_top_height ? content_top_height : 0)
                                           - (fixed_div_height ? fixed_div_height : 0)
        });
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 부서 이름 반환
     */
    getDepartmentName(dept_id : number) : string {
        try {
            const target = this.departments.filter(item => item.dept_id == dept_id);
            if( target.length < 1 ) return "-";
            return target[0].dept_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 직원 결재자 여부 변경
     */
    async changeEmployeeApprover(event, employee : any) : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getTotalOrganizationData();

            const is_approver_yn = event.target.value;

            // 결재자로 만들때 같은 부서에 같은 직급이고 결재자인 사람이 있는지 체크
            if( is_approver_yn == 'Y' ) {
                const target_pos_id = employee.pos_id;
                const target_dept_id = employee.dept_id;

                const target_emp = this.computedEmployee.filter(emp => emp.pos_id == target_pos_id && emp.dept_id == target_dept_id && emp.is_approver == true);

                if( target_emp.length > 0 ) {
                    $(event.target).val('N').prop("selected", true);
                    this.hodu_show_dialog('alert', "이미 결재자로 등록 된 같은 부서, 같은 직급인 직원이 있습니다", ['확인']);
                    return;
                }
            }

            // API 실행
            const data = JSON.parse(JSON.stringify(employee));
            data.is_approver = is_approver_yn == 'Y';

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.POST, data);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 직원 직급 변경 중 오류 발생");
            }

            await this.getTotalOrganizationData();

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직원 직급 변경 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        } finally {
            this.hodu_hide_indicator();
        }
    }

    /**
     * 사인 추가/수정 모달
     */
    showOrganizationSignModal(employee) : void {

        this.doSetOrganizationSignModalInfo?.({
            show_modal : true,
            group_id : employee.group_id,
            user_id : employee.user_id,
            callback : this.getTotalOrganizationData
        });

    }

    /**
     * 사인 삭제
     */
    async deleteSign(employee) : Promise<void> {

        if( confirm("정말로 사인을 삭제하시겠습니까?") == false ) {
            return;
        }
        
        try {
            const group_id = employee.group_id ?? 0;
            const user_id = employee.user_id ?? 0;

            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/${user_id}/sign`, API_METHOD.DELETE);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("사인 삭제 중 오류 발생");
            }
            
            this.getTotalOrganizationData();

        } catch(e) {
            this.hodu_show_dialog('cancel', "사인 삭제 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user_on.png');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
