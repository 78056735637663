
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const MenuInfo = namespace('MenuInfo');

import RightUserArea         from "@/components/right/RightUserArea.vue";
import RightNotificationList from "@/components/right/RightNotificationList.vue";
import RightPreferences      from "@/components/right/RightPreferences.vue";
import RightTodoList         from "@/components/right/RightTodoList.vue";
import RightTodoCreate       from "@/components/right/RightTodoCreate.vue";
import RightEtc              from "@/components/right/RightEtc.vue";
import RightFilter           from "@/components/right/RightFilter.vue";

import { ResizeObserver } from 'vue-resize';

@Component({
    components : {
        RightUserArea, RightNotificationList, RightPreferences, 
        RightTodoList, RightTodoCreate, RightEtc, RightFilter
    }
})
export default class RightArea extends Mixins(VueHoduCommon) {

    @MenuInfo.State user_profile_open           !: boolean;
    @MenuInfo.State notification_list_menu_open !: boolean;
    @MenuInfo.State preferences_menu_open       !: boolean;
    @MenuInfo.State todo_menu_open              !: boolean;
    @MenuInfo.State todo_create_or_update       !: boolean;
    @MenuInfo.State etc_menu_open               !: boolean;
    @MenuInfo.State filter_menu_open            !: boolean;

    mounted() : void {
     
    }
}

