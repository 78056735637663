
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { GROUP_TYPE, CALENDAR_TYPE, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import { GroupTeamInfo } from '@/store/modules/GroupInfo';
const CalendarInfo = namespace('CalendarInfo');
const GroupInfo    = namespace('GroupInfo');

@Component({

})
export default class LeftGroupList extends Mixins(VueHoduCommon) {

    /**
     * @CalendarInfo.Action
     */
    @CalendarInfo.Action doSetCalendarViewType ?: any; // calendar_view_type 업데이트

    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * @GroupInfo.Action
     */
    @GroupInfo.Action doGroupListType ?: any;
    
    /**
     * 개인캘린더에선 비즈 그룹 반환, 그룹캘린더, 팀캘린더에서는 해당 그룹 찾아서 그룹+팀 반환 
     */
    get getTargetGroupList() : GroupTeamInfo[] {
        if( this.scope == OWNER_TYPE.PERSONAL ) {
            return this.hodu_c_group_data.filter(item => item.biz_type != 'BIZD');
        }

        const group_team : GroupTeamInfo[] = [];
        const hodu_c_group_data_length : number = this.hodu_c_group_data.length;
        for( let i = 0; i < hodu_c_group_data_length; i++ ) {
            const group_info : GroupTeamInfo = this.hodu_c_group_data[i];
            if( group_info.group_id != this.scope_group_id ) {
                continue;
            }

            group_team.push(group_info);
            const team_length : number = group_info.teams.length;
            for( let j = 0; j < team_length; j++ ) {
                group_team.push(group_info.teams[j]);
            }
        }

        return group_team;
    }

    mounted() : void {
        if( this.scope == null || this.scope.length < 1 || this.scope == OWNER_TYPE.PERSONAL ) { this.doSetGroupMenuClose(false); }
        else { this.doSetGroupMenuClose(true); }
    }
    
    /**
     * 그룹 메뉴 ON / OFF
     */
    group_menu_click() : void {
        // if( this.left_control_box_flag == true ) {
        //     this.addGrpClick();
        //     this.doSetLeftControlBoxFlag(false);
        //     return;
        // }

        if( this.group_info_list == null || this.group_info_list.length < 1 ) {
            return;
        }
        
        // scope가 PERSONAL이 아닌데 그룹 하나밖에 존재하지 않는다면 해당그룹의 detail로 이동한다
        if( this.scope != OWNER_TYPE.PERSONAL && this.getTargetGroupList[0].teams.length == 0 ) {
            this.addGrpClick();
            return;
        }

        this.doSetGroupMenuClose(!this.group_menu_close);
    }

    /**
     * 기본 달력 : 그룹리스트, 비즈 달력 : 해당 그룹 정보
     */
    addGrpClick() : void {
        // 개인 달력 일때
        if( this.scope == OWNER_TYPE.PERSONAL ) {
            this.doGroupListType(9); // 무조건 전체 탭을 최우선으로
            this.hodu_router_push(`/group/${new Date().getTime()}`);
        }
        
        // 비즈 달력 일때
        else {
            this.doGroupId(this.scope_group_id);
            this.doTeamId(this.scope_team_id);
            this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
        }
    }

    /**
     * 해당 비즈 달력으로 이동
     */
    async groupListClick( group_data : GroupTeamInfo ) : Promise<void> {
        
        // 인증 안된 상태인데 호두홈, 호두키즈로 이동한다면 인증절차
        if( (this.auth_info == null || this.auth_info.auth_check == false) && (group_data.biz_type == 'BIZH' || group_data.group_info.project_type == 'kids') ) {
            try {
                let popup : Window | null = null;

                // window['skip_button_select'] = false;

                window['auth_callback'] = (user_info) => {
                    try {
                        this.doSetAuthInfo(user_info.auth_info);
                        this.doSetUserPhoneNumber(user_info.user_phone_number);
                        this.doSetCountryCode(user_info.country_code);
                        this.groupListClick(group_data);
                        
                        // console.log(authResult);
                        // this.firebase_auth(authResult, auth_check_later, (user_info) => {
                        //     try {
                        //         console.log(user_info);
                        //         this.doSetAuthInfo(user_info.auth_info);
                        //         this.doSetUserPhoneNumber(user_info.user_phone_number);
                        //         this.doSetCountryCode(user_info.country_code);

                        //         if( auth_check_later == true ) {
                        //             return;
                        //         }

                        //         this.groupListClick(group_data);
                        //     } catch(e) {
                        //         this.hodu_error_process(e, false, false, true);
                        //     }
                        // });
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    } finally {
                        popup?.close();
                    }
                }

                const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                const systemZoom = width / window.screen.availWidth;

                const w = 500;
                const h = 626;
                const l = (width - w) / 2 / systemZoom + dualScreenLeft;
                const t = (height - h) / 2 / systemZoom + dualScreenTop;

                popup = window.open(`/naver-auth.html?device_uid=${this.get_device_uid()}&session_token=${this.get_session_token()}`, `naver-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
            return;
        }

        // 달력으로 이동하는 옵션으로 선택했다면
        const preference = await this.get_user_preference('dashboard');
        if( preference != null && preference.preference != null && preference.preference[`${group_data.group_id}`] != null && preference.preference[`${group_data.group_id}`].move_option == 'CALENDAR' ) {
            
            // 이미 해당 그룹 달력이라면 달력 바꿀 필요없음
            if( this.scope_group_id == group_data.group_id && this.scope_team_id == group_data.team_id ) {
                return;
            }

            // scope 등을 쓰는 computed 데이터가 있을때 먼저 이동 하지 않으면 오류 발생함
            if( this.isHoduH(group_data.biz_type as any) && this.is_hodu_home_sub_manager(group_data.group_id) ) {
                this.hodu_router_push(`/home/visitant/${new Date().getTime()}`);
            }
            
            else {
                this.hodu_router_push(`/?${new Date().getTime()}`);
            }

            if( $('#bt_calContr').hasClass('on') == false ) {
                $('#container').find('#left_area').find('.left_inbox').addClass('on');
                $('.ui-datepicker-calendar-wrap').hide();
                $('#bt_calContr').addClass('on');

                const left_area_height : number | undefined = $('#left_area').outerHeight();
                const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

                const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
                const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

                let heightSize : number = left_area_height_final - left_inbox_height_final;
                $('.left_inbox02').height(heightSize+"px");
            }

            this.doSetGroupMenuClose(true);
            this.doSetMessageMenuClose(true);
            this.doSetWorkMenuClose(true);
            this.doSetCommunityMenuClose(true);
            this.doSetResidentMenuClose(true);

            this.doGroupId(group_data.group_id);
            this.doTeamId(group_data.team_id);

            this.doSetCalendarId(this.team_id > 0 ? `team-${this.team_id}` : `group-${this.group_id}`);
            this.doSetScope(this.team_id > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP);
            this.doSetScopeGroupId(this.group_id);
            this.doSetScopeTeamId(this.team_id);
            this.doSetScopeGroupTeamOption({
                group_team_name     : group_data.team_id > 0 ? group_data.team_name : group_data.group_name,
                group_team_color    : group_data.color,
                group_team_image    : "",
                group_team_descript : "",
                biz_id              : group_data.biz_id,
                biz_type            : group_data.biz_type
            });
            
            // 병원 달력인 경우 무조건 월 달력으로 변경 시킨다
            if( group_data.biz_type == GROUP_TYPE.BIZD ) {
                this.doSetLoadingBouncy(true);
                this.doSetLoadingEnd1(false);
                this.doSetLoadingEnd2(false);
                this.doSetLoadingEnd3(false);
                await this.hodu_loading_timer_exit();

                // 2020-11-26 LSJ - 병렬처리를 위해 await 제거했음
                Promise.all([this.get_hodu_d_info(group_data.biz_id), this.doSetCalendarViewType(CALENDAR_TYPE.DAY_GRID_MONTH)]).then(async() => {

                    await this.hodu_loading();
                    this.hodu_router_push(`/?${new Date().getTime()}`);

                    setTimeout(() => {
                        // @ts-ignore
                        $('.left_inbox02').mCustomScrollbar('destroy');
                        window['left_inbox02_mCustomScrollbar']();
                    }, 100);

                    if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
                
                });
                return;
            }

            await this.hodu_loading_timer_exit();
            await this.hodu_loading();

            setTimeout(() => {
                // @ts-ignore
                $('.left_inbox02').mCustomScrollbar('destroy');
                window['left_inbox02_mCustomScrollbar']();
            }, 100);

            if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
            return;
        }
        
        // 이동 옵션이 달력이 아니라면 대시보드로 이동
        this.doGroupId(group_data.group_id);
        this.doTeamId(group_data.team_id);
        this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
    }
}

