var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section_ce_fix"},[_c('div',{staticClass:"title_box"},[_c('a',{staticClass:"bt_back",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.movePrevPage.apply(null, arguments)}}},[_c('span',{staticClass:"blind"},[_vm._v("이전화면 가기")])]),_vm._m(0)]),_c('div',{staticClass:"default_form"},[_c('fieldset',[_c('legend',[_vm._v("기본 신청양식")]),_c('dl',{staticClass:"in_form"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('dd',[_c('input',{staticClass:"input_txt",staticStyle:{"width":"150px"},attrs:{"type":"text","id":"user_id","name":"user_id","maxlength":"6","placeholder":"관리자 아이디"},on:{"input":function($event){_vm.admin_user_id = $event.target.value}}})])]),_c('br'),_c('br'),_c('ul',{staticClass:"btns"},[_c('li',[_c('input',{staticClass:"btn_ok",attrs:{"type":"button","id":"btn_ok","value":"저장"},on:{"click":function($event){return _vm.biz_create_save()}}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h3',{staticClass:"title_doc",attrs:{"id":"viewTitle"}},[_c('span',{staticClass:"cl dc4"}),_vm._v("프리미엄 그룹 생성")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dt',[_c('label',{attrs:{"for":"biz_nm"}},[_vm._v("회사명")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dd',[_c('input',{staticClass:"input_txt size_m",attrs:{"type":"text","id":"biz_nm","name":"biz_nm","maxlength":"19","placeholder":"회사명을 입력하세요"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dt',[_c('label',{attrs:{"for":"is_cert"}},[_vm._v("인증여부")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dd',[_c('select',{staticClass:"input_txt size_s",attrs:{"id":"is_cert","name":"is_cert"}},[_c('option',{attrs:{"value":"true"}},[_vm._v("사용")]),_c('option',{attrs:{"value":"false"}},[_vm._v("미사용")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dt',[_c('label',{attrs:{"for":"biz_id"}},[_vm._v("USER_ID")])])
}]

export { render, staticRenderFns }