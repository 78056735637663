
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

// import { namespace } from 'vuex-class';
// const ModalInfo = namespace('ModalInfo');

import { hodu_doc_object } from '@/model/hodudoc';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class PatientListComp extends Mixins(VueHoduCommon) {

    /**
     * 환자 정렬 리스트
     */
    get computedPatientSortList() : hodu_doc_object.patient_info[] {

        let target_list : hodu_doc_object.patient_info[] = JSON.parse(JSON.stringify(this.patient_list));


        target_list = target_list.sort((item1, item2) : number => {

            // 이름 기준 정렬
            if( this.sort_type == 'NAME' ) {

                if( item1.user_name == item2.user_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.user_name < item2.user_name ? -1 : 1;
                }

                return item1.user_name > item2.user_name ? -1 : 1;

            }

            // 파일 개수 기준 정렬
            if( this.sort_type == 'FILE_COUNT' ) {

                if( item1.file_count == item2.file_count ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_count < item2.file_count ? -1 : 1;
                }

                return item1.file_count > item2.file_count ? -1 : 1;

            }

            return 0;
        })
        
        return target_list;

    }

    /**
     * @ModalInfo.Action
     */
    // @ModalInfo.Action doSetShowPatientAddModal ?: (params : boolean) => void;

    @Prop() event_bus !: Vue;

    patient_list : hodu_doc_object.patient_info[] = [];

    is_all_selected : boolean = false;

    // 정렬
    sort_type : 'NAME' | 'FILE_COUNT' = 'NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;
    
    mounted() : void {

        if( this.event_bus != null ) { 
            this.event_bus.$on('searchPatientList', this.getPatientsInfo);
            this.event_bus.$on('patientDelete', this.deletePatients);
            this.event_bus.$on('getSelectedPatient', this.getSelectedPatient); 
        }

        this.setScroll();
        this.getPatientsInfo();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#patient_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    /**
     * 환자 정보 조회
     */
    getPatientsInfo(search_word ?: string) : void {

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient?search_word=${search_word ? search_word : ''}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                this.patient_list.splice(0, this.patient_list.length);
                this.patient_list = this.patient_list.concat(response.data.data);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 모든 환자 체크 / 체크 해제
     */
    allPatientCheck(is_checked : boolean) : void {
        
        for( const patient of this.patient_list ) {
            const temp_patient : hodu_doc_object.patient_info = JSON.parse(JSON.stringify(patient));
            temp_patient.checked = is_checked;
            this.patient_list.splice(this.patient_list.indexOf(patient), 1, temp_patient);
        }

    }

    /**
     * 환자 체크 변경
     */
    patientCheckChanged() : void {

        const all_count : number = this.computedPatientSortList.length;
        const checked_count : number = this.computedPatientSortList.filter(item => item.checked == true).length;

        this.is_all_selected = (all_count == checked_count);
    }

    /**
     * 환자 삭제
     */
    deletePatients() : void {

        const selected_patients : hodu_doc_object.patient_info[] = this.computedPatientSortList.filter(item => item.checked == true); 

        if( selected_patients.length < 1 ) {
            this.hodu_show_dialog('alert', '삭제 할 환자를 선택해주세요', ["확인"], [() => {}]);
            return; 
        } 

        this.hodu_show_dialog('cancel', `환자를 ${ this.is_all_selected ? '전부 ' : '' }삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {
                if( selected_patients.length < 1 ) { return; }
                
                const delete_promise : Promise<void>[] = [];

                for( const patient of selected_patients ) {

                    const patient_id : number = patient.user_id;

                    delete_promise.push(this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient?patient_id=${patient_id}&group_id=${this.scope_group_id}`, API_METHOD.DELETE)
                        .then((response) => {
                            console.log(response);
                        })
                        .catch((e) => {
                            this.hodu_error_process(e, false, false, true);
                        }));

                }

                // 환자 삭제 병렬 처리
                Promise.all(delete_promise)
                    .then(() => {
                        this.is_all_selected = false;
                        this.getPatientsInfo();
                    }).catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });

            }
        ])
    }

    /**
     * 선택된 환자 목록 $emit
     */
    getSelectedPatient() : void {
        this.$emit('showPatientSendMessageModal', this.computedPatientSortList.filter(item => item.checked == true));
    }

    /**
     * 환자 등록
     */
    // showPatientAddModal() : void {
    //     if( this.doSetShowPatientAddModal ) { this.doSetShowPatientAddModal(true); }
    // }

    /**
     * 환자 상세
     */
    movePatientDetail(patient_id : number) : void {
        this.hodu_router_push(`/hospital/${new Date().getTime()}/patient/${patient_id}`);
    }
    
    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'NAME' | 'FILE_COUNT') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#patient_list_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
