
import { Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import { namespace } from 'vuex-class';
import { hodu_doc_modal_info, hodu_doc_table, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';

import moment from 'moment';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import DoctorOffDays from '@/components/hodu_d/DoctorOffDays.vue';
import { CommonColorPickerModalInfo, ImageCropModalInfo } from '../../store/modules/ModalInfo';
import { t_event, t_event_reply, t_event_file } from '@/model/event';

const ModalInfo = namespace('ModalInfo');
const HoduDocInfo = namespace('HoduDocInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        DoctorOffDays
    },
}) 
export default class Doctor extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDoctorTimeSettingModalInfo ?: (params : hodu_doc_modal_info.DoctorTimeSettingModalInfo) => void;
    @ModalInfo.Action doSetDoctorOffSettingModalInfo  ?: (params : hodu_doc_modal_info.DoctorOffSettingModalInfo) => void;
    @ModalInfo.Action doSetCommonColorPickerModalInfo ?: (params : CommonColorPickerModalInfo) => void;
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;

    base64url               : string = "";
    image_files             : any    = [];
    default_profile_image   : string = require("@/assets/images/contents/im_photoB.gif");
    
    o_doctor_info           : hodu_doc_object.doctor_info | null = null;
    is_create               : boolean = true;
    timetable_history       : hodu_doc_object.doctor_timetable_history[] = [];
    dayoff_info             : hodu_doc_table.t_hospital_doctor_day_offs[] = [];
    selectedTimetableIndex  : number = 0;
    selectedDepartIndex     : number = 0;

    async mounted() : Promise<void> {
        this.setScroll();
        const vue = this;
        
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        // 진료과가 없는 경우
        if( this.department_info == null || this.department_info.length < 1 ) {
            this.hodu_show_dialog("cancel", "진료과를 먼저 등록해주세요", ["확인"], [() => {
                this.movePrevPage();
            }]);
            return;
        }

        // 조회
        if( this.$route.params.doctor_code && this.doctor_info ) {
            for( const doctor of this.doctor_info ) {
                if( doctor.doctor_code == this.$route.params.doctor_code ) {
                    this.o_doctor_info = JSON.parse(JSON.stringify(doctor));
                    break;
                }
            }
            //! 필수 데이터 체크
            if ( this.o_doctor_info == null || 
                this.o_doctor_info.doctor_name == null || this.o_doctor_info.doctor_name.length < 1 ||
                this.o_doctor_info.doctor_code == null || this.o_doctor_info.doctor_code.length < 1 ||
                this.o_doctor_info.doctor_detail == null || 
                this.o_doctor_info.doctor_detail.color == null || this.o_doctor_info.doctor_detail.color.length < 1 || 
                this.o_doctor_info.doctor_detail.position == null || this.o_doctor_info.doctor_detail.position.length < 1 ||
                this.o_doctor_info.doctor_detail.license == null || this.o_doctor_info.doctor_detail.license.length < 1       ) {
                this.hodu_show_dialog("cancel", "필수 데이터가 존재하지 않습니다 잠시 후 다시 시도해주세요", ["확인"], [() => {
                    this.movePrevPage();
                }]);
                return;
            }
        }

        // this.getHospitalDepartment()
        this.is_create = this.o_doctor_info == null;

        if (this.is_create) {
            this.setDefaultTimeTableData();
        }else{
            this.getDoctorInfo();
        }

        $(".checkDiv a").click(function(){
            var getIndex = $(this).index();
            $(this).toggleClass("on");
            if (getIndex == 2) {
                $(this).siblings().removeClass("on");
            } else {
                
            }
        });

        // jquery-ui datepicker 옵션
        const datepicker_option = {
            inline: false,
            minDate : new Date(),
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText);
                inst.input.val(mnt.format('YYYY.MM.DD'));
                
                // 시작일 변경
                if( id == 'doctor_create_start_date' ) {
                    if (vue.o_doctor_info != null ) {
                        vue.o_doctor_info.start_date = mnt.toDate();
                    }
                } 

                // 종료일 변경
                else if( id == 'doctor_create_end_date' ) {
                    if (vue.o_doctor_info != null ) {
                        vue.o_doctor_info.end_date = mnt.toDate();
                    }
                }

            },
        }
        
        // @ts-ignore
        $('#doctor_create_start_date').datepicker(datepicker_option);

        // @ts-ignore
        $('#doctor_create_end_date').datepicker(datepicker_option);

    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const windowOuterHeight   : number | undefined = $(window).innerHeight();
        const titleBoxHeight      : number | undefined = $('.title_box').innerHeight();

        // @ts-ignore
        $('#scrollDiv').mCustomScrollbar({
            axis : 'y',
            mouseWheelPixels : 80,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : (windowOuterHeight == null ? 0 : windowOuterHeight) - (titleBoxHeight == null ? 0 : titleBoxHeight),
            scrollbarPosition : 'outside'
        });
    }

    /**
     * 그룹 이미지 추가
     */
    onClickProfileImage(event) : void {
        const file : File[] = event.target.files;
        this.addImage(file);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(file : File[]) : Promise<void> {
        const vue = this;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(file[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#cdImg').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                if( this.doSetImageCropModalInfo == null ) { return; }

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (1/1), // 1:1
                    image_url : url,
                    cancel_function : async() => {
                        $('#cdImg').val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        
                        this.base64url = cropped_url;

                        const resize_blob : Blob = await vue.hodu_image_resize(this.base64url);

                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = file[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, file[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = file[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        // 업로드용 파일 객체 담기
                        vue.image_files = [];
                        vue.image_files.push({
                            file : resize_file
                        });

                        $('#cdImg').val("");

                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지 삭제
     */
    deleteProfileImage() : void {
        if ( this.base64url ) {
            this.base64url = "";
            this.image_files = [];
        }
        if ( this.o_doctor_info != null ) { 
            this.o_doctor_info.doctor_detail.profile_image = ""
        }
    }

    getYMDstr(po_date: Date | string) : string {
        return moment(po_date).format("YYYY.MM.DD")
    }

    /**
     * doctor_work_code -> 종일/오전/오후/휴무 
     */
    getAmPmStr(value: hodu_doc_enum.doctor_work_code) : string {
        switch (value) {
            case hodu_doc_enum.doctor_work_code.FULL_TIME :
                return "종일"
            case hodu_doc_enum.doctor_work_code.AM :
                return "오전"
            case hodu_doc_enum.doctor_work_code.PM :
                return "오후"
            case hodu_doc_enum.doctor_work_code.OFF : 
                return "휴무"
            default :
                return ""
        }
    }

    /**
     * 의사 정보 초기값 세팅
     */
    setDefaultTimeTableData(): void {
        if ( this.hospital_info == null || this.department_info == null || this.department_info.length < 1) { return; }
        this.o_doctor_info = {
            hospital_name       : this.hospital_info.company_info.name,
            department_code     : this.department_info[this.selectedDepartIndex].department_code,
            department_name     : this.department_info[this.selectedDepartIndex].department_name,
            doctor_code         : "",
            doctor_name         : "",
            doctor_detail       : {
                color         : "#477FFF",
                position      : "EMP",
                license       : "SPCLT",
                career        : [""],
                profile_image : ""
            },
            start_date          : moment(new Date()).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate(),
            end_date            : new Date("2050-12-31 14:59:59Z"),
            is_current_schedule : false,
            timetable_id        : "",
            monday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
            tuesday             : hodu_doc_enum.doctor_work_code.FULL_TIME,
            wednesday           : hodu_doc_enum.doctor_work_code.FULL_TIME,
            thursday            : hodu_doc_enum.doctor_work_code.FULL_TIME,
            friday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
            saturday            : hodu_doc_enum.doctor_work_code.OFF,
            sunday              : hodu_doc_enum.doctor_work_code.OFF
        }

        let newInfo : hodu_doc_object.doctor_timetable_history = {
            timetable_id : "",
            date_text    : this.getListDateStr(this.o_doctor_info),
            list         : this.getTimeTableList(this.o_doctor_info)
        };
        
        this.timetable_history.push(newInfo);
    }
    
    /**
     * 색상 선택
     */
    async showCommonColorPickerModal() : Promise<void> {
        if ( this.o_doctor_info == null ) { return; }

        window['applyColor'] = this.applyColor;
        if( this.doSetCommonColorPickerModalInfo ) { 
            this.doSetCommonColorPickerModalInfo({
                show_modal      : true,
                selected_color  : this.o_doctor_info.doctor_detail.color,
                is_dc_only      : false,
            }); 
        }
    }

    /**
     * 컬러 픽커에서 선택한 값 적용
     */
    applyColor(hexColorStr: string){
        if ( this.o_doctor_info == null ) { return; }
        this.o_doctor_info.doctor_detail.color = hexColorStr;
    }

    /**
     * 색상 이름 반환
     */
    getColorName(color_string: string) : string {
        return this.hodu_color_dc_lc_label_text(color_string)
    }

    /**
     * 근무 기간 선택
     */
    selectContract(event: any) : void {
        this.selectedTimetableIndex = event.target.value as number;
    }

    addCareer(event) {
        if (this.o_doctor_info == null || this.o_doctor_info.doctor_detail.career[this.o_doctor_info.doctor_detail.career.length-1].length < 1 ) {
            return;
        }
        this.o_doctor_info.doctor_detail.career.push("")
    }

    deleteCareer(index: number) {
        if (this.o_doctor_info != null) {
            this.o_doctor_info.doctor_detail.career.splice(index, 1)
        }
    }

    /**
     * 의사 근무 시간 설정 모달 보이기
     */
    showDoctorTimeSettingModalInfo(is_create_work_time : boolean) : void {
        if ( this.o_doctor_info == null ) { return; }
        if( this.doSetDoctorTimeSettingModalInfo ) { 
            
            if ( is_create_work_time ) {
                if(this.hospital_info == null) { return; }
                let temp_doctor_info : hodu_doc_object.doctor_info = {
                    hospital_name       : this.hospital_info.company_info.name,
                    department_code     : this.o_doctor_info.department_code,
                    department_name     : this.o_doctor_info.department_name,
                    doctor_code         : "",
                    doctor_name         : "",
                    doctor_detail       : {
                        color         : "",
                        position      : "",
                        license       : "",
                        career        : [""],
                        profile_image : ""
                    },
                    start_date          : moment(new Date()).set("hours", 15).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate(),
                    end_date            : new Date("2050-12-31 14:59:59Z"),
                    is_current_schedule : false,
                    timetable_id        : "",
                    monday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    tuesday             : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    wednesday           : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    thursday            : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    friday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
                    saturday            : hodu_doc_enum.doctor_work_code.OFF,
                    sunday              : hodu_doc_enum.doctor_work_code.OFF
                }

                this.doSetDoctorTimeSettingModalInfo({
                    "show_modal"            : true,
                    "is_create_work_time"   : true,
                    "start_date"            : temp_doctor_info.start_date as Date,
                    "end_date"              : temp_doctor_info.end_date as Date,
                    "timetable"             : {
                        timetable_id : "",        // uuid
                        date_text    : "",        // "2020.02.15~2020.05.05"
                        list         : JSON.parse(JSON.stringify(this.getTimeTableList(temp_doctor_info)))
                    }
                }); 

            }else{
                this.doSetDoctorTimeSettingModalInfo({
                    "show_modal"            : true,
                    "is_create_work_time"   : false,
                    "start_date"            : new Date(),
                    "end_date"              : new Date(),
                    "timetable"             : JSON.parse(JSON.stringify(this.timetable_history[this.selectedTimetableIndex]))
                }); 
            }
            
        }
        window["createTimetable"]   = this.createTimetable;     // 근무표 새로 생성할 때
        window["updateTimetable"]   = this.updateTimetable;     // 근무표를 수정할 때
        window["addTimetable"]      = this.addTimetable;        // 근무표를 수정할 때
    }

    createTimetable(timetable_info : hodu_doc_object.doctor_timetable[], temp_doctor_info : hodu_doc_object.doctor_info | null = null) : void {
        if ( this.o_doctor_info == null ) { return; }

        let docInfo : hodu_doc_object.doctor_info;

        //! temp_doctor_info 가 존재하면 의사 조회하고 근무표 추가
        if ( temp_doctor_info != null ) {
            docInfo = temp_doctor_info;
        }
        //! temp_doctor_info 가 없다면 의사 생성에서 근무표 작성
        else{
            docInfo = this.o_doctor_info;
        }

        const list   = this.timetable_history[this.selectedTimetableIndex].list;
        for ( const info of timetable_info ) {
            switch (info.day_of_week) {
                case 0 :
                    docInfo.sunday = info.work_code;
                    break;
                case 1 :
                    docInfo.monday = info.work_code;
                    break;
                case 2 :
                    docInfo.tuesday = info.work_code;
                    break;
                case 3 :
                    docInfo.wednesday = info.work_code;
                    break;
                case 4 :
                    docInfo.thursday = info.work_code;
                    break;
                case 5 :
                    docInfo.friday = info.work_code;
                    break;
                case 6 :
                    docInfo.saturday = info.work_code;
                    break;
            }
        }

        //! temp_doctor_info 가 존재하면 의사 조회하고 근무표 추가
        if ( temp_doctor_info != null ) {
            this.addTimetable(timetable_info, temp_doctor_info.start_date as Date);
        }
        //! temp_doctor_info 가 없다면 의사 생성에서 근무표 작성
        else{
            this.timetable_history[this.selectedTimetableIndex].list = this.getTimeTableList(docInfo);
        }
        
    }

    /**
     * 의사 조회중 근무표 즉시 수정
     */
    async updateTimetable(timetable_info : hodu_doc_object.doctor_timetable[], timetable_id : string) : Promise<void> {
        
        if( this.o_doctor_info == null ) { return; }
        const biz_id            = this.scope_group_team_option.biz_id;
        const department_code   = this.o_doctor_info.department_code;
        const doctor_code       = this.o_doctor_info.doctor_code;
        
        let req_data = {
            work_day_info   : this.makeWorkInfoArray(timetable_info)
        }
        // console.log("HIS ==>> req_data");
        // console.log(req_data);
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/doctor/${department_code}/${doctor_code}/timetable/${timetable_id}`, API_METHOD.PUT, req_data)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                if (jsonData.length > 0) {
                    // console.log(jsonData);
                    this.getDoctorInfo();
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 의사 조회중 근무표 추가
     */
    async addTimetable(timetable_info : hodu_doc_object.doctor_timetable[], new_start_date: Date) : Promise<void> {
        
        if( this.o_doctor_info == null ) { return; }
        const biz_id            = this.scope_group_team_option.biz_id;
        const department_code   = this.o_doctor_info.department_code;
        const doctor_code       = this.o_doctor_info.doctor_code;
        
        let req_data = {
            biz_id          : this.scope_group_team_option.biz_id,
            department_code : this.o_doctor_info.department_code,
            doctor_code     : this.o_doctor_info.doctor_code,
            doctor_name     : this.o_doctor_info.doctor_name,
            start_date      : new_start_date,
            work_day_info   : this.makeWorkInfoArray(timetable_info)
        }
        // console.log("HIS ==>> req_data");
        // console.log(req_data);
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/doctor/${department_code}/${doctor_code}/timetable`, API_METHOD.POST, req_data)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                if (jsonData.length > 0) {
                    this.getDoctorInfo();
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 요일별 의사 근무표 데이터 가공
     */
    getTimeTableList(docInfo: hodu_doc_object.doctor_info) : hodu_doc_object.doctor_timetable[] {
        if ( this.hospital_setting_info == null ) { return []; }
        let larrO_timetable : hodu_doc_object.doctor_timetable[] = []; 

        try {
            const setting_info_cnt  : number = this.hospital_setting_info.length
        
            // 요일 정보 세팅
            larrO_timetable.push({ "day_of_week" : 0, "work_code" : docInfo.sunday   , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 1, "work_code" : docInfo.monday   , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 2, "work_code" : docInfo.tuesday  , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 3, "work_code" : docInfo.wednesday, "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 4, "work_code" : docInfo.thursday , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 5, "work_code" : docInfo.friday   , "am_text" : "", pm_text : "" });
            larrO_timetable.push({ "day_of_week" : 6, "work_code" : docInfo.saturday , "am_text" : "", pm_text : "" });
            
            // 해당 날짜에 적용 되는 병원 타임 테이블을 찾는다
            let hospital_timetable !: hodu_doc_object.hospital_setting_info[];
            hospital_timetable = this.getHospitalTimetableFromDoctorDate(docInfo);

            // 찾지 못한 경우 의사 정보로만 텍스트 세팅
            if( hospital_timetable == null ) {
                // // 세팅
                // for ( const docTimeTable of larrO_timetable ) {
                    
                // }
                
                return larrO_timetable;
            }

            for ( const docTimeTable of larrO_timetable ) {

                const doc_table : hodu_doc_object.doctor_timetable = JSON.parse(JSON.stringify(docTimeTable));

                for( const hosptialTimeTable of hospital_timetable ) {
                    if ( docTimeTable.day_of_week != Number(hosptialTimeTable.day_of_week) ) { continue; }

                    doc_table.am_text = "오전 휴무";
                    doc_table.pm_text = "오후 휴무";

                    if (doc_table.work_code == hodu_doc_enum.doctor_work_code.FULL_TIME) {
  
                        if( hosptialTimeTable.start_am_time && hosptialTimeTable.start_am_time.length > 0 &&
                            hosptialTimeTable.end_am_time   && hosptialTimeTable.end_am_time.length   > 0 ) {
                            doc_table.am_text = `오전 ${hosptialTimeTable.start_am_time.substring(0, 2)}:${hosptialTimeTable.start_am_time.substring(2, 4)} ~ ${hosptialTimeTable.end_am_time.substring(0, 2)}:${hosptialTimeTable.end_am_time.substring(2, 4)}`
                        }

                        if( hosptialTimeTable.start_pm_time && hosptialTimeTable.start_pm_time.length > 0 &&
                            hosptialTimeTable.end_pm_time   && hosptialTimeTable.end_pm_time.length   > 0 ) {
                            doc_table.pm_text = `오후 ${hosptialTimeTable.start_pm_time.substring(0, 2)}:${hosptialTimeTable.start_pm_time.substring(2, 4)} ~ ${hosptialTimeTable.end_pm_time.substring(0, 2)}:${hosptialTimeTable.end_pm_time.substring(2, 4)}`
                        }
                        
                    } 
                    
                    else if (doc_table.work_code == hodu_doc_enum.doctor_work_code.AM) {
                        
                        if( hosptialTimeTable.start_am_time && hosptialTimeTable.start_am_time.length > 0 &&
                            hosptialTimeTable.end_am_time   && hosptialTimeTable.end_am_time.length   > 0 ) {
                            doc_table.am_text = `오전 ${hosptialTimeTable.start_am_time.substring(0, 2)}:${hosptialTimeTable.start_am_time.substring(2, 4)} ~ ${hosptialTimeTable.end_am_time.substring(0, 2)}:${hosptialTimeTable.end_am_time.substring(2, 4)}`
                        }

                    }

                    else if (doc_table.work_code == hodu_doc_enum.doctor_work_code.PM) {
                        
                        if( hosptialTimeTable.start_pm_time && hosptialTimeTable.start_pm_time.length > 0 &&
                            hosptialTimeTable.end_pm_time   && hosptialTimeTable.end_pm_time.length   > 0 ) {
                            doc_table.pm_text = `오후 ${hosptialTimeTable.start_pm_time.substring(0, 2)}:${hosptialTimeTable.start_pm_time.substring(2, 4)} ~ ${hosptialTimeTable.end_pm_time.substring(0, 2)}:${hosptialTimeTable.end_pm_time.substring(2, 4)}`
                        }

                    }

                    larrO_timetable.splice(larrO_timetable.indexOf(docTimeTable), 1, doc_table);
                    break;
                }
            }
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
        
        return larrO_timetable;
    }

    /**
     * 의사 근무 시작~종료 기간에 따라 병원 근무표 반환
     */
    getHospitalTimetableFromDoctorDate(docInfo : hodu_doc_object.doctor_info) : hodu_doc_object.hospital_setting_info[] {
        let hospital_timetable !: hodu_doc_object.hospital_setting_info[];
        if ( this.hospital_setting_info == null ) { return []; } 

        // past
        if ( new Date(docInfo.end_date).getTime() < new Date().getTime() ) {
            hospital_timetable = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= new Date(docInfo.end_date).getTime() &&
                                                                        new Date(item.end_date).getTime()   >= new Date(docInfo.end_date).getTime() );
        }
        // future
        else if( new Date(docInfo.start_date).getTime() > new Date().getTime() ) {
            hospital_timetable = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= new Date(docInfo.start_date).getTime() &&
                                                                        new Date(item.end_date).getTime()   >= new Date(docInfo.start_date).getTime() );
        }
        // now
        else {
            hospital_timetable = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= new Date().getTime() &&
                                                                        new Date(item.end_date).getTime()   >= new Date().getTime() );
        }
        
        return hospital_timetable;
    }

    /**
     * 근무기간 선택시 보여질 문구 생성 ( 2020.01.01 ~ 2020.12.31 )
     */
    getListDateStr(timetable: hodu_doc_object.doctor_info) : string {
        let startDate = moment(timetable.start_date).format("YYYY.MM.DD");
        let endDate   = moment(timetable.end_date).format("YYYY.MM.DD");

        return startDate + "~" + endDate;
    }

    /**
     * 의사 근무표 및 휴일 조회
     */
    async getDoctorInfo() : Promise<void> {
        if (this.o_doctor_info == null) { return; }
        const biz_id            = this.scope_group_team_option.biz_id;
        const department_code   = this.o_doctor_info.department_code;
        const doctor_code       = this.o_doctor_info.doctor_code;

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/doctor/${department_code}/${doctor_code}/timetable`, API_METHOD.GET)
            .then((response) => {
                // let jsonData = JSON.stringify(response.data.data);
                let timetables : hodu_doc_object.doctor_info[] = response.data.data.timetable_history;
                let numOfTimetables = timetables.length;
                
                this.timetable_history = [];
                for (let i=0; i<numOfTimetables; i++) {
                    let timetable_info : hodu_doc_object.doctor_info = timetables[i]
                    let newInfo : hodu_doc_object.doctor_timetable_history = {
                        timetable_id : timetable_info.timetable_id,
                        date_text    : this.getListDateStr(timetable_info),
                        list         : this.getTimeTableList(timetable_info)
                    };
                    
                    this.timetable_history.push(newInfo);
                }

                this.dayoff_info = []
                this.dayoff_info = this.dayoff_info.concat(response.data.data.dayoff_info)
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    showDeleteDoctorModal() : void {
        if( this.o_doctor_info == null ) { return; }
        this.hodu_show_dialog("cancel", `정말 [${this.o_doctor_info.department_name} - ${this.o_doctor_info.doctor_name}] 선생님을 삭제하겠습니까?`, 
        ['아니오', '예'], 
        [
            () => {

            },
            async () => {
                if( this.o_doctor_info == null ) { return; }
                let body = {
                    doctor_codes : [this.o_doctor_info.doctor_code]
                }
                await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/doctor`, API_METHOD.DELETE, body)
                .then((response) => {
                    this.movePrevPage();
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
        ]);
    }

    // showDeleteWorkTimeModal() : void {
    //     this.hodu_show_dialog("cancel", `2020.04.01 ~ 2021.04.01 스케줄을 삭제하겠습니까?`, 
    //     ['아니오', '예'], 
    //     [
    //         () => {

    //         },
    //         () => {
    //             this.movePrevPage();
    //         }
    //     ]);
    // }

    /**
     * 진료과 선택
     */
    selectDepartment(event) : void {
        if( this.department_info == null || this.o_doctor_info == null ) { return; }
        this.selectedDepartIndex = event.target.value as number
        this.o_doctor_info.department_code = this.department_info[this.selectedDepartIndex].department_code
        this.o_doctor_info.department_name = this.department_info[this.selectedDepartIndex].department_name
    }

    /**
     * 의사 근무표 정보 조립
     */
    makeWorkInfoArray( timetable_list : hodu_doc_object.doctor_timetable[] ) : hodu_doc_table.t_hospital_doctors_timetable_work_day_info[] {
        let work_infos : hodu_doc_table.t_hospital_doctors_timetable_work_day_info[] = [];

        for ( const timetable of timetable_list) {
            work_infos.push({
                work_code   : timetable.work_code,
                day_of_week : timetable.day_of_week
            })
        }

        return work_infos;
    }

    /**
     * 파일 업로드
     */
    async uploadProfileImage() : Promise<void> {
        const vue = this;

        if ( this.base64url && this.image_files.length > 0 ) {
            const form_data : FormData = new FormData();

            // FormData 내용 생성
            const image_files_count : number = this.image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.image_files[i].file);
            }

            await this.hodu_temp_upload(form_data)
                .then(async(response) => {
                    const temp_files : t_event_file[] = response.data.data.temp_files;
                    await this.saveDoctor(temp_files[0]);
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });      
        }
        else {
            await this.saveDoctor();
        }
    }

    /**
     * 저장 전 유효성 검사
     */
    validationCheckBeforeSave() {
        let error_message    = "";
        let handling_message = "";
        
        if ( this.o_doctor_info == null ) {
            error_message = "데이터가 손실되었습니다 잠시 후 다시 시도해주세요"
            handling_message = "back"
        }
        else if ( this.o_doctor_info.doctor_name.trim().length < 1 ) {
            error_message = "이름을 입력해주세요"
            handling_message = "docNameId"
        }
        else if ( this.o_doctor_info.doctor_code.trim().length < 1 ) {
            error_message = "코드를 입력해주세요"
            handling_message = "docCodeId"
        }
        else if ( this.o_doctor_info.doctor_detail == null ) {
            error_message = "부가정보가 손실되었습니다 잠시 후 다시 시도해주세요"
            handling_message = "back"
        }
        else if ( this.o_doctor_info.doctor_detail.color.length < 1 ) {
            error_message = "색상을 다시 선택해주세요"
        }
        else if ( this.o_doctor_info.doctor_detail.position.length < 1 ) {
            error_message = "직급을 다시 선택해주세요"
        }
        else if ( this.o_doctor_info.doctor_detail.license.length < 1 ) {
            error_message = "자격을 다시 선택해주세요"
        }
        else{
            this.uploadProfileImage();
            return;
        }
        
        this.hodu_show_dialog('alert', error_message, ['확인'], [
            () => {
                if ( handling_message == "back") {
                    this.movePrevPage();
                }else if (handling_message.length > 1){
                    $("#" + handling_message ).focus();
                }
            }
        ]);
    }

    /**
     * 의사 정보 생성 및 수정
     */
    async saveDoctor(profile_image_file : t_event_file | null = null) : Promise<void> {
        if ( this.o_doctor_info == null ) { return }

        let req_data = {
            biz_id          : this.scope_group_team_option.biz_id,
            department_code : this.o_doctor_info.department_code,
            doctor_code     : this.o_doctor_info.doctor_code,
            doctor_name     : this.o_doctor_info.doctor_name,
            start_date      : this.o_doctor_info.start_date,
            doctor_info     : this.o_doctor_info.doctor_detail,
            work_day_info   : this.makeWorkInfoArray(this.timetable_history[this.selectedTimetableIndex].list),
            profile_image   : profile_image_file
        }
        // console.log(req_data);
        let url    = ""
        let method;
        if ( this.is_create ) {
            url = `api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/doctor`;
            method = API_METHOD.POST;
        }else{
            url = `api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/doctor/${this.o_doctor_info.department_code}/${this.o_doctor_info.doctor_code}`;
            method = API_METHOD.PUT;
        }
        
        await this.hodu_api_call(url, method, req_data)
            .then(async(response) => {
                this.movePrevPage();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    reloadDayOffs(jsonData: any[]) : void {
        this.dayoff_info.splice(0, this.dayoff_info.length)
        this.dayoff_info = this.dayoff_info.concat(jsonData)
    }

    inputDoctorName(event) {

        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_name = value;
        }
    }

    inputDoctorCode(event) {

        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_code = value;
        }
    }

    changeDoctorPosition(event) {
        
        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_detail.position = value;
        }
    }

    changeDoctorLicense(event) {
        
        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_detail.license = value;
        }
    }

    inputDoctorCareer(event, careerIndex) {

        const value = event.target.value;

        if( this.o_doctor_info != null ) {
            this.o_doctor_info.doctor_detail.career[careerIndex] = value;
        }
    }
}
