
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';
import { hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';
import { ResizeObserver } from 'vue-resize';
import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class HospitalDoctorList extends Mixins(VueHoduCommon) {

    /**
     * 의사 정렬 리스트
     */
    get computedDoctorSortList() : hodu_doc_object.doctor_info[] {
        let target_list : hodu_doc_object.doctor_info[] = JSON.parse(JSON.stringify(this.arrO_doctors));

        // 진료과 필터
        for( const department_code of this.department_filter ) {
            target_list = target_list.filter(item => item.department_code != department_code);
        }

        target_list = target_list.sort((item1, item2) : number => {

            if( this.sort_dir == SORT_TYPE.ASC ) {
                return item1.doctor_name < item2.doctor_name ? -1 : 1;
            }

            return item1.doctor_name > item2.doctor_name ? -1 : 1;
        })

        return target_list;
    }

    /**
     * 의사리스트를 기준으로 의사가 한명이라도 존재하는 진료과 리스트를 반환 
     */
    get computedDepartmentList() : hodu_doc_object.department_info[] {

        let target_list : hodu_doc_object.department_info[] = [];

        for( const doctor of this.arrO_doctors ) {
            
            const target_department : hodu_doc_object.department_info[] = target_list.filter(item => item.department_code == doctor.department_code);
            if( target_department.length > 0 ) { continue; }

            target_list.push({
                "department_code" : doctor.department_code,
                "department_name" : doctor.department_name
            })
        }

        return target_list;
    }

    arrO_doctors : hodu_doc_object.doctor_info[]  = [];
    arrO_dayNames : string[] = ["일", "월", "화", "수", "목", "금", "토"];
    default_profile_image : string = require("@/assets/images/contents/im_photoB.gif");

    sort_dir : SORT_TYPE = SORT_TYPE.ASC;
    department_filter_open : boolean = false;
    department_filter : string[] = [];

    created() : void {
        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/doctor`, API_METHOD.GET)
            .then((response) => {
                // let jsonData = JSON.stringify(response.data.data);
                this.arrO_doctors.splice(0, this.arrO_doctors.length);
                this.arrO_doctors = this.arrO_doctors.concat(response.data.data)
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    mounted() : void {
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#doctor_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    getDoctorStartEnd(doctor : hodu_doc_object.doctor_info) : string {
        return `${moment(doctor.start_date).format("YYYY-MM-DD")} ~ ${moment(doctor.end_date).format("YYYY-MM-DD")}`;
    }

    /**
     * 의사 생성 및 상세 화면으로 이동
     */
    moveDoctorCreate(index: number) : void {
        if (index == -2) {
            this.hodu_router_push(`/hospital/${new Date().getTime()}/doctor/detail`);
            return
        }
        let url = `/hospital/${new Date().getTime()}/doctor/create`;
        if (index >= 0) {
            url = `/hospital/${new Date().getTime()}/doctor/${this.computedDoctorSortList[index].doctor_code}`;
        }

        this.hodu_router_push(url);
    }

    isDoctorOff(docIndex: number, dofIndex: number) : any {
        switch (dofIndex) {
            case 0 :
                return this.computedDoctorSortList[docIndex].sunday
            case 1 :
                return this.computedDoctorSortList[docIndex].monday
            case 2 :
                return this.computedDoctorSortList[docIndex].tuesday
            case 3 :
                return this.computedDoctorSortList[docIndex].wednesday
            case 4 :
                return this.computedDoctorSortList[docIndex].thursday
            case 5 :
                return this.computedDoctorSortList[docIndex].friday
            case 6 :
                return this.computedDoctorSortList[docIndex].saturday
            }
    }

    getRegiTerm(docIndex: number) : string {
        let startStr : string = moment(this.computedDoctorSortList[docIndex].start_date).format("YYYY.MM.DD")
        let endStr   : string = moment(this.computedDoctorSortList[docIndex].end_date).format("YYYY.MM.DD")
        return startStr + " ~ " + endStr
    }

    isRetired(docIndex: number) : boolean {
        let value = false;
        if ( this.computedDoctorSortList[docIndex].end_date <= new Date() ) {
            return true;
        }
        return value;
    }

    /**
     * 진료과 필터 ON / OFF
     */
    departmentFilterOnOff() : void {
        this.department_filter_open = !this.department_filter_open;
    }

    /**
     * 진료과 필터 전체 체크
     */
    departmentFilterAll(event : Event) : void {
        // event.stopPropagation();

        // 전부 필터에 존재하지 않는다면
        if( this.department_filter.length < 1 ) {
            this.department_filter.splice(0 ,this.department_filter.length);
            for( const department of this.computedDepartmentList ) { this.department_filter.push(department.department_code); }
            return;
        }

        // 하나라도 필터에 존재한다면
        this.department_filter.splice(0 ,this.department_filter.length);
    }

    /**
     * 진료과 필터에 추가 / 제거
     */
    departmentFilterCheckOnOff(event : Event, value : string) {
        event.preventDefault();

        if ( this.department_filter.indexOf(value) < 0 ) { 
            this.department_filter.push(value);
            return;
        }

        this.department_filter.splice(this.department_filter.indexOf(value), 1);

    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange() : void {
        this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#doctor_list_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
