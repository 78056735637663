
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE } from '@/mixin/VueHoduCommon';

@Component({

})
export default class LeftMessage extends Mixins(VueHoduCommon) {
    
    mounted() : void {
        if( this.scope != null && this.scope != OWNER_TYPE.PERSONAL ) { this.doSetMessageMenuClose(true); }
    }

    /**
     * 메세지 메뉴 ON / OFF
     */
    messageMenuOnOff() : void {
        // if( this.left_control_box_flag == true ) {
        //     this.doSetMessageMenuClose(false);
        //     this.doSetLeftControlBoxFlag(false);
        //     return;
        // }

        this.doSetMessageMenuClose(!this.message_menu_close);
    }
    
    /**
     * 메세지 발송
     */
    goMessageSend() : void {
        this.hodu_router_push(`/message/${new Date().getTime()}/send`);
    }

    /**
     * 메세지 발송 현황
     */
    goMessageStatus() : void {
        this.hodu_router_push(`/message/${new Date().getTime()}/status`);
    }

    /**
     * 메세지 템플릿
     */
    goMessageTemplate() : void {
        this.hodu_router_push(`/message/${new Date().getTime()}/template`);
    }
    
}

