
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ApprovalInfo = namespace('ApprovalInfo');

import ApprovalDraftBox from '@/components/approval/ApprovalDraftBox.vue';
import ApprovalReceiveBox from '@/components/approval/ApprovalReceiveBox.vue';
import ApprovalFormBox from '@/components/approval/ApprovalFormBox.vue';
import ApprovalApproverFavorite from '@/components/approval/ApprovalApproverFavorite.vue';

import moment from 'moment';

import { approval_enum, approval_interface } from '@/model/approval';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ApprovalDraftBox, ApprovalReceiveBox, ApprovalFormBox, ApprovalApproverFavorite,
        ResizeObserver
    },
})
export default class Approval extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    event_bus : Vue = new Vue();

    /**
     * @ApprovalInfo.State
     */
    @ApprovalInfo.State selected_tab !: approval_enum.APPROVAL_BOX_TAB;
    @ApprovalInfo.State selected_draft_tab !: approval_enum.APPROVAL_DRAFT_BOX_TAB;
    @ApprovalInfo.State selected_receive_tab !: approval_enum.APPROVAL_RECEIVE_BOX_TAB;
    @ApprovalInfo.State filter !: approval_interface.ApprovalFilter;
    @ApprovalInfo.State draft_filter !: approval_interface.ApprovalFilter;
    @ApprovalInfo.State receive_filter !: approval_interface.ApprovalFilter;
    // @ApprovalInfo.State is_first_init_complete !: boolean;

    /**
     * @ApprovalInfo.Action
     */
    @ApprovalInfo.Action doSetApproval ?: (params : approval_interface.t_approval) => void;
    @ApprovalInfo.Action doSetSelectedTab ?: (params : approval_enum.APPROVAL_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedDraftTab ?: (params : approval_enum.APPROVAL_DRAFT_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedReceiveTab ?: (params : approval_enum.APPROVAL_RECEIVE_BOX_TAB) => void;
    @ApprovalInfo.Action doSetFilter ?: (params : approval_interface.ApprovalFilter) => void;
    @ApprovalInfo.Action doSetDraftFilter ?: (params : approval_interface.ApprovalFilter) => void;
    @ApprovalInfo.Action doSetReceiveFilter ?: (params : approval_interface.ApprovalFilter) => void;
    // @ApprovalInfo.Action doSetIsFirstInitComplete ?: (params : boolean) => void;

    // 검색 필터값
    // filter : approval_interface.ApprovalFilter = {
    //     search_query : "",
    //     start : new Date(),
    //     end : new Date(),
    //     is_emergency : true,
    //     is_importance : true,
    //     is_normal : true,
    //     importance_filter : [10, 20, 30],
    //     departments : [],
    //     dept_ids : []
    // };
    
    draft_approvals : any[] = [];
    receive_approvals : any[] =[];

    is_draft_open : boolean = true;
    is_receive_open : boolean = true;
    is_form_open : boolean = true;

    is_menu_open : boolean = false;

    all_templates : approval_interface.ApprovalTemplateBundle[] = [];
    templates : approval_interface.ApprovalTemplateBundle[] = [];

    none_class_template : any = null;
    default_template : any = null;
    vacation_template : any = null;
    business_trip_template : any = null;
    holiday_work_template : any = null;
    cash_disbursement_voucher_template : any = null;
    free_template : any = null;
    receive_template : any = null;

    async mounted() : Promise<void> {

        //필터링 메뉴
        // $(".bt_hambuger").click(function(){
        //     $(this).toggleClass("change");
		// 	$(".bt_hambuger").parent().siblings(".nav_item").toggleClass("open");

        // });

//분류 메뉴
        // $(".bt_hambuger2").click(function(){
        //     $(this).toggleClass("change");
		// 	$(".bt_hambuger2").parent().siblings(".nav_item2").toggleClass("open");

        // });        
//각 메뉴 펼치기-결재함
        // $(".dep1, .appbox1").click(function(){
        //     $(this).toggleClass("on");
        //     $(this).siblings('.appbox1').toggleClass("on");
        //     $(this).siblings('.dep1').toggleClass("on");
		// 	$(this).siblings('.ApprovalBox_menu').toggleClass("open");
        // });
        // $(".dep2, .appbox2").click(function(){
        //     $(this).toggleClass("on");
        //     $(this).siblings('.appbox2').toggleClass("on");
        //     $(this).siblings('.dep2').toggleClass("on");
		// 	$(this).siblings('.PreserveBox_menu').toggleClass("open");
        // });
        // $(".dep4, .appbox4").click(function(){
        //     $(this).toggleClass("on");
        //     $(this).siblings('.appbox4').toggleClass("on");
        //     $(this).siblings('.dep4').toggleClass("on");
		// 	$(this).siblings('.FormBox_menu').toggleClass("open");
        // });
        // $('#approval_list li').on('click', (event) => {
        //     event.preventDefault();
        //     this.hodu_router_push('/scope/scope_id/approval/approval_id');
        // })

        // 버튼 클릭시 라인과 팀 보이기
        // $(".arw").click(function(){
        //     // 리스트 인덱스 가져오기
        //     var getIndex = $(this).parent().index();
            
        //     // 버튼 클릭시 라인과 팀 보이기
        //     $(".fList").eq(getIndex).toggleClass("showTeam");

        // });        
        
        await this.getApproval();

        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {

            const title_height = $('.title_box').outerHeight();
            const write_height = $('.approval_menu .approval_wrtie').outerHeight();
            const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                    - (write_height ? write_height : 0);

            // @ts-ignore
            $('#approval_menu_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : scroll_height,
            });

        });
    }

    /**
     * 전자결재 조회
     */
    async getApproval() : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getOrganization();
            await this.getApprovalTemplateInfo();
            await Promise.all([this.getApporvalDraftBox(), this.getApporvalReceiveBox()]);
            await this.hodu_hide_indicator();
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            const receive_filter : approval_interface.ApprovalFilter = JSON.parse(JSON.stringify(this.receive_filter));
            receive_filter.departments.splice(0, receive_filter.departments.length);
            receive_filter.departments = receive_filter.departments.concat(response.data.data.dept_info);
            
            if( receive_filter.is_selecet_all_departments == true ) {
                receive_filter.dept_ids.splice(0, receive_filter.dept_ids.length);
                for( const department of receive_filter.departments ) { 
                    receive_filter.dept_ids.push(department.dept_id);
                }
            }

            await this.doSetReceiveFilter?.(receive_filter);

        } catch(e) {
            this.hodu_show_dialog('cancel', "조직도 조회 중 오류 발생", ['확인']);
            throw e;
        }

    }

    /**
     * 상신함 조회
     */
    async getApporvalDraftBox() : Promise<void> {

        try {
            // 검색 타입, 시작일, 종료일
            let query = "?search_mode=DRAFT";
            query += `&start_date=${moment(this.draft_filter.start).utc().format()}`;
            query += `&end_date=${moment(this.draft_filter.end).utc().format()}`;
            
            // 중요도
            for( const imp_seq of this.draft_filter.importance_filter ) {
                query += `&imp_seq=${imp_seq}`;
            }

            // 웹에서는 조회 할때 탭 조건을 걸지 않는다 (카운팅을 클라이언트에서 해야하기 떄문)
            // if( this.tab != approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL ) {
            //     query += `&approval_state=${this.tab}`;
            // }

            // 클라이언트 타임존
            const timezone = -(new Date().getTimezoneOffset());
            query += `&timezone=${timezone ? timezone : 540}`;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval${query}`, API_METHOD.GET, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("상신함 문서 조회 중 오류 발생");
            }

            if( this.isAttendanceOnly() == true ) {
                response.data.data.approval_info = response.data.data.approval_info.filter(item => item.approval_type == approval_enum.APPROVAL_TYPE.VACATION);
            }

            const type_filter : string[] = [];
            if( this.draft_filter.is_approval == true ) type_filter.push(approval_enum.APPROVAL_TYPE.BASIC);
            if( this.draft_filter.is_vacation == true ) type_filter.push(approval_enum.APPROVAL_TYPE.VACATION);
            if( this.draft_filter.is_businesstrip == true ) type_filter.push(approval_enum.APPROVAL_TYPE.BUSINESSTRIP);
            if( this.draft_filter.is_holiday_work == true ) type_filter.push(approval_enum.APPROVAL_TYPE.HOLIDAY_WORK);
            if( this.draft_filter.is_cash_disbursement_voucher == true ) type_filter.push(approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER);
            if( this.draft_filter.is_free == true ) type_filter.push(approval_enum.APPROVAL_TYPE.FREE);

            response.data.data.approval_info = response.data.data.approval_info.filter(item => type_filter.indexOf(item.approval_type) >= 0);

            this.draft_approvals.splice(0, this.draft_approvals.length);
            this.draft_approvals = this.draft_approvals.concat(response.data.data.approval_info);

        } catch(e) {
            this.hodu_show_dialog('cancel', "상신함 문서 조회 중 오류 발생", ['확인']);
            throw e;
        }

    }

    /**
     * 상신함 조회
     */
    async getApporvalReceiveBox() : Promise<void> {

        try {
            // 검색 타입, 시작일, 종료일
            let query = "?search_mode=RECEIVE";
            query += `&start_date=${moment(this.receive_filter.start).utc().format()}`;
            query += `&end_date=${moment(this.receive_filter.end).utc().format()}`;
            
            // 중요도
            for( const imp_seq of this.receive_filter.importance_filter ) {
                query += `&imp_seq=${imp_seq}`;
            }

            // 웹에서는 조회 할때 탭 조건을 걸지 않는다 (카운팅을 클라이언트에서 해야하기 떄문)
            // if( this.tab != approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL ) {
            //     query += `&approval_state=${this.tab}`;
            // }

            // 클라이언트 타임존
            const timezone = -(new Date().getTimezoneOffset());
            query += `&timezone=${timezone ? timezone : 540}`;

            // 부서
            if( this.receive_filter.dept_ids.length == 0 ) {
                this.receive_approvals.splice(0, this.receive_approvals.length);
                return;
            }

            for( const dept_id of this.receive_filter.dept_ids ) {
                query += `&dept_id=${dept_id}`;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval${query}`, API_METHOD.GET, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("수신함 문서 조회 중 오류 발생");
            }

            if( this.isAttendanceOnly() == true ) {
                response.data.data.approval_info = response.data.data.approval_info.filter(item => item.approval_type == approval_enum.APPROVAL_TYPE.VACATION);
            }

            const type_filter : string[] = [];
            if( this.receive_filter.is_approval == true ) type_filter.push(approval_enum.APPROVAL_TYPE.BASIC);
            if( this.receive_filter.is_vacation == true ) type_filter.push(approval_enum.APPROVAL_TYPE.VACATION);
            if( this.receive_filter.is_businesstrip == true ) type_filter.push(approval_enum.APPROVAL_TYPE.BUSINESSTRIP);
            if( this.receive_filter.is_holiday_work == true ) type_filter.push(approval_enum.APPROVAL_TYPE.HOLIDAY_WORK);
            if( this.receive_filter.is_cash_disbursement_voucher == true ) type_filter.push(approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER);
            if( this.receive_filter.is_free == true ) type_filter.push(approval_enum.APPROVAL_TYPE.FREE);

            response.data.data.approval_info = response.data.data.approval_info.filter(item => type_filter.indexOf(item.approval_type) >= 0)

            this.receive_approvals.splice(0, this.receive_approvals.length);
            this.receive_approvals = this.receive_approvals.concat(response.data.data.approval_info);

            

        } catch(e) {
            this.hodu_show_dialog('cancel', "수신함 문서 조회 중 오류 발생", ['확인']);
            throw e;
        }

    }

    /**
     * 양식 설정 조회
     */
    async getApprovalTemplateInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class?include_templates=${true}`, API_METHOD.GET, null, false);
            
            const approval_templates = response.data.data.approval_templates;

            this.all_templates.splice(0, this.all_templates.length)
            this.all_templates = this.all_templates.concat(approval_templates);

            this.templates.splice(0, this.templates.length)
            this.templates = this.templates.concat(approval_templates.filter(template_class => template_class.template_state == '10'));

            const none_class_template_class  = approval_templates.filter(template_class => template_class.template_state == '0');
            const default_template_class  = approval_templates.filter(template_class => template_class.template_state == '20');
            const vacation_template_class = approval_templates.filter(template_class => template_class.template_state == '30');
            const business_trip_template_class = approval_templates.filter(template_class => template_class.template_state == '40');
            const holiday_work_template_class = approval_templates.filter(template_class => template_class.template_state == '80');
            const cash_disbursement_voucher_template_class = approval_templates.filter(template_class => template_class.template_state == '60');
            const free_template_class = approval_templates.filter(template_class => template_class.template_state == '70');
            const receive_template_class = approval_templates.filter(template_class => template_class.template_state == '50');

            if( none_class_template_class.length > 0 ) this.none_class_template = none_class_template_class[0];
            if( default_template_class.length > 0 ) this.default_template = default_template_class[0];
            if( vacation_template_class.length > 0 ) this.vacation_template = vacation_template_class[0];
            if( business_trip_template_class.length > 0 )  this.business_trip_template = business_trip_template_class[0];
            if( holiday_work_template_class.length > 0 )  this.holiday_work_template = holiday_work_template_class[0];
            if( cash_disbursement_voucher_template_class.length > 0 )  this.cash_disbursement_voucher_template = cash_disbursement_voucher_template_class[0];
            if( free_template_class.length > 0 )  this.free_template = free_template_class[0];
            if( receive_template_class.length > 0 )  this.receive_template = receive_template_class[0];

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 메뉴 고정
     */
    menuToggle() {
        this.is_menu_open = !this.is_menu_open;
    }

    /**
     * 양식없는 기안서 작성
     */
    freeCreate() {
        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.FREE,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    /**
     * 기안서 작성으로 이동
     */
    approvalCreate() {

        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.BASIC,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }
    
    /**
     * 휴가원 작성으로 이동
     */
    vacationCreate() {

        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.VACATION,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    /**
     * 출장신청서 작성으로 이동
     */
    businessTripCreate() : void {
        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.BUSINESSTRIP,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    holidayWorkCreate() {
        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.HOLIDAY_WORK,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    /**
     * 지출결의서 작성으로 이동
     */
    cashDisbursementVoucherCreate() : void {
        if( this.doSetApproval == null ) return;

        const today = new Date();

        this.doSetApproval({
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        });

        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval/create`);
    }

    /**
     * 탭 선택
     */
    selectTab(tab : approval_enum.APPROVAL_BOX_TAB, sub_tab ?: approval_enum.APPROVAL_DRAFT_BOX_TAB | approval_enum.APPROVAL_RECEIVE_BOX_TAB) : void {
        
        // 양식 다운로드를 골랐는데 근태현황만 켜져있는경우는 반환
        if( tab == approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD && this.isAttendanceOnly() == true ) {
            return;
        }

        const before_tab = this.selected_tab;
        
        this.doSetSelectedTab?.(tab);

        // 서브탭 선택
        switch( this.selected_tab ) {
            case approval_enum.APPROVAL_BOX_TAB.DRAFT:
                if( sub_tab == null ) return;
                this.doSetSelectedDraftTab?.(sub_tab as approval_enum.APPROVAL_DRAFT_BOX_TAB);
                break;

            case approval_enum.APPROVAL_BOX_TAB.RECEIVE:
                if( sub_tab == null ) return;
                this.doSetSelectedReceiveTab?.(sub_tab as approval_enum.APPROVAL_RECEIVE_BOX_TAB);
                break;
        }

        this.getApproval();

        // 탭 재선택시 재조회 시키기
        if( before_tab == this.selected_tab && this.selected_tab == approval_enum.APPROVAL_BOX_TAB.APPROVER_FAVORITE ) {
            this.event_bus.$emit('getApprovalApproverFavorites');
        }
    } 

    /**
     * 선택된 탭이 상신함
     */
    isDraft() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.DRAFT;
    }

    /**
     * 상신함 탭 + 전체 탭
     */
    isDraftAll() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL;
    }

    /**
     * 상신함 탭 + 진행 탭
     */
    isDraftIng() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.ING;
    }

    /**
     * 상신함 탭 + 완료 탭
     */
    isDraftConfirm() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.COMPLETE;
    }

    /**
     * 상신함 탭 + 반려 탭
     */
    isDraftReject() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.REJECT;
    }

    /**
     * 상신함 탭 + 임시 탭
     */
    isDraftTemp() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.TEMP;
    }

    /**
     * 상신함 탭 + 취소 탭
     */
    isDraftCancel() : boolean {
        return this.isDraft() && this.selected_draft_tab == approval_enum.APPROVAL_DRAFT_BOX_TAB.CANCEL;
    }

    /**
     * 선택된 탭이 수신함
     */
    isReceive() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.RECEIVE;
    }

    /**
     * 수신함 탭 + 대기 탭
     */
    isReceiveWait() : boolean {
        return this.isReceive() && this.selected_receive_tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT;
    } 

    /**
     * 수신함 탭 + 요청 탭
     */
    isReceiveRequest() : boolean {
        return this.isReceive() && this.selected_receive_tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST;
    }

    /**
     * 수신함 탭 + 내역 탭
     */
    isReceiveHistory() : boolean {
        return this.isReceive() && this.selected_receive_tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.HISTORY;
    }

    /**
     * 수신함 탭 + 수신참조 탭
     */
    isReceiveReference() : boolean {
        return this.isReceive() && this.selected_receive_tab == approval_enum.APPROVAL_RECEIVE_BOX_TAB.REFERENCE;
    }

    /**
     * 선택된 탭이 양식함
     */
    isForm() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD || this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_SETTING;
    }

    /**
     * 양식 다운로드
     */
    isFormDownload() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD;
    }

    /**
     * 양식 설정
     */
    isFormSetting() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_SETTING;
    }

    /**
     * 결재자 즐겨찾기
     */
    isApproverFavorite() : boolean {
        return this.selected_tab == approval_enum.APPROVAL_BOX_TAB.APPROVER_FAVORITE;
    }

    /**
     * 근태관리 기능만 ON 인 상태
     */
    isAttendanceOnly() : boolean {
        return (this.isEnableGroupFeature(this.scope_group_id, 'approval') == false && this.isEnableGroupFeature(this.scope_group_id, 'attendance') == true);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#approval_menu_scroll').mCustomScrollbar('destroy');
        this.setScroll();

        if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.DRAFT ) this.event_bus.$emit('draftResize');
        else if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.RECEIVE ) this.event_bus.$emit('receiveResize');
        else if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD ) this.event_bus.$emit('formDownloadResize');
        else if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.FORM_SETTING ) this.event_bus.$emit('formSettingResize');
        else if( this.selected_tab == approval_enum.APPROVAL_BOX_TAB.APPROVER_FAVORITE ) this.event_bus.$emit('approvalApproverFavoriteResize');
    }

}
        

    
