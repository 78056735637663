
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { FILE_SIZE, API_METHOD } from '@/mixin/VueHoduCommon';

import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_table, hodu_doc_enum } from '@/model/hodudoc';
import { t_event, t_event_hodu_doc, t_event_file } from '@/model/event';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { ResizeObserver } from 'vue-resize';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class Patient extends Mixins(VueHoduCommon) {

    /**
     * 예약 년도 리스트 
     */
    get computedAppointmentYearList() : number[] {

        const years : number[] = [];

        for( const event of this.events ) {

            if( event.event_data == null ) { continue; }

            const target_date : Date = new Date(event.event_data.schedule_date.start);

            if( years.indexOf(target_date.getFullYear()) > -1 ) { continue; }

            years.push(target_date.getFullYear());

        }

        return years;
    }

    /**
     * 선택된 년도의 방문일
     */
    get computedAppointmentListBySelectedYear() : t_event[] {

        if( this.selected_appointment_year == null ) { return this.events; }

        const target_event_list : t_event[] = this.events.filter(item => item.event_data != null &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appointment_year
        );

        return target_event_list;
    }

    /**
     * 파일 년도 리스트 
     */
    get computedFileYearList() : number[] {

        const years : number[] = [];

        for( const file of this.file_list ) {

            if( file.audit_created == null ) { continue; }

            const target_date : Date = new Date(file.audit_created);

            if( years.indexOf(target_date.getFullYear()) > -1 ) { continue; }

            years.push(target_date.getFullYear());

        }

        return years;
    }

    /**
     * 선택된 년도의 파일 리스트
     */
    get computedFileListBySelectedYear() : hodu_doc_table.t_hospital_patient_file[] {
        
        // 선택된 년도가 없는 경우
        if( this.selected_file_year == null ) { return this.file_list; }

        const target_file_list : hodu_doc_table.t_hospital_patient_file[] = this.file_list.filter(item => item.audit_created != null && 
                                                                                                          new Date().getFullYear() == this.selected_file_year);

        return target_file_list;
    }

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentCreateModalInfo ?: (params : hodu_doc_modal_info.AppointmentCreateModalInfo) => void;
    @ModalInfo.Action doSetShowEventImagePreview ?: any;

    selected_patient : hodu_doc_object.patient_info | null = null;

    events : t_event[] = [];
    selected_appointment_year : number | null = null;

    file_list : hodu_doc_table.t_hospital_patient_file[] = [];
    selected_file_year : number | null = null;
    is_file_list_card_mode : boolean = false;
    
    async mounted() : Promise<void> {

        const patient_id = this.$route.params.patient_id;

        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        // 환자 정보 체크
        if( this.patient_info == null || this.patient_info.length < 1 || this.patient_info.filter(item => item.user_id == Number(patient_id)).length < 1 ) {
            
            this.hodu_show_dialog("alert", "환자 정보가 없습니다", ["확인"], [
                () => { this.movePrevPage(); }
            ])
            return;
        }

        this.selected_patient = this.patient_info.filter(item => item.user_id == Number(patient_id))[0];

        // 리스트로 보이기
        // $(".btns .dpTwo").click(function(){
        //     $(".thisPatientFileBox").addClass("dp2");
        //     $(".btns .dpOne").show();
        //     $(this).hide();

        // });

        // 박스형으로 보이기
        // $(".btns .dpOne").click(function(){
        //     $(".thisPatientFileBox").removeClass("dp2");
        //     $(".btns .dpTwo").show();
        //     $(this).hide();

        // });

        // API 호출 및 스크롤 설정 
        this.getEvents();
        this.getPatientsFileboxInfo();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {
            const title_box_height : number | undefined = $('.title_box').outerHeight();
            const basic_div_title_height : number | undefined = $('.basicDiv .title').outerHeight();
            const basic_div_first_div_height : number | undefined = $('.basicDiv .firstDiv').outerHeight();
            const appointment_header_li_height : number | undefined = $('.appointment_header_li').outerHeight();
            const file_box_title_div_height : number | undefined = $('.thisPatientFileBox .titleDiv').outerHeight();

            // @ts-ignore
            $('#appointment_list_scroll_by_patient').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (basic_div_title_height ? basic_div_title_height : 0)
                                            - (basic_div_first_div_height ? basic_div_first_div_height : 0)
                                            - (appointment_header_li_height ? appointment_header_li_height : 0)
                                            - 30 
            })

            // @ts-ignore
            $('#patient_file_box_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                                - (file_box_title_div_height ? file_box_title_div_height : 0)
            });
        })
    }

    /**
     * 환자 파일함 조회
     */
    getPatientsFileboxInfo() : void {

        if ( this.selected_patient == null ) { return; }

        const patient_id : number = this.selected_patient.user_id;

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${patient_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.file_list.splice(0, this.file_list.length)
                this.file_list = this.file_list.concat(response.data.data);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 방문기록 조회
     */
    getEvents() : void {
        
        const start : string = moment('1970-01-01 00:00:00').utc().format();
        const end   : string = moment('2050-12-31 23:59:59').utc().format();

        const calendar_id : string = this.calendar_id;
        const owner_type  : string = this.scope;
        const owner_id    : number = this.scope_group_id;

        let query : string = `?start=${start}&end=${end}&mycal=false&syscal=false&shared=false&biz_type=${this.scope_group_team_option.biz_type}&biz_id=${this.scope_group_team_option.biz_id}`;
    
        this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}${query}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                this.events.splice(0, this.events.length);
                let temp_events : t_event[] = this.events.concat(response.data.data.events.filter(item => item.event_data 
                    ? item.event_data.event_owner_id == ( this.selected_patient 
                        ? this.selected_patient.user_id
                        : 0)
                    : false
                ));

                // 요청, 거절 상태인 예약은 제외한다
                this.events = this.events.concat(temp_events.filter(item => item.event_data.appointment 
                        ? item.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.REQUEST &&
                          item.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.DECLINE &&
                          item.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.CONFIRM 
                        : false));

                // 시간 역순
                this.events.sort((item1, item2) : number => {
                    
                    if( new Date(item1.event_data.schedule_date.start).getTime() > new Date(item2.event_data.schedule_date.start).getTime() ) {
                        return -1;
                    } else if( new Date(item1.event_data.schedule_date.start).getTime() < new Date(item2.event_data.schedule_date.start).getTime() ) {
                        return 1;
                    }

                    return 0;
                });

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })
    }

    /**
     * 파일 삭제 API
     */
    deletePatientsFileboxFile(files : hodu_doc_table.t_hospital_patient_file[]) : void {
        
        if( this.selected_patient == null || files == null || files.length < 1 ) { return; }

        this.hodu_show_dialog('cancel', `${files[0].file_name}\n파일을 삭제 하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => { 

                if( this.selected_patient == null ) { return; }

                const patient_id : number = this.selected_patient.user_id;

                this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${patient_id}`, API_METHOD.DELETE, { "files" : files })
                    .then((response) => {
                        console.log(response);
                        this.get_hodu_d_info(this.scope_group_team_option.biz_id);
                        this.getPatientsFileboxInfo();
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });
            }
        ]);
    }

    /**
     * 환자 삭제
     */
    deletePatient() : void {

        if( this.selected_patient == null ) { return; } 

        this.hodu_show_dialog('cancel', `${this.selected_patient.user_name}\n환자를 삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            () => {
                if( this.selected_patient == null ) { return; }
                
                const patient_id : number = this.selected_patient.user_id;

                this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient?patient_id=${patient_id}&group_id=${this.scope_group_id}`, API_METHOD.DELETE)
                    .then((response) => {
                        console.log(response);
                        this.hodu_router_push(`/hospital/${new Date()}/patient`);
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    })

            }
        ])
    }

    /**
     * 방문일 년도 선택
     */
    selectAppointmentYear(value : string) : void {
        if ( value == null || value.length < 1 ) { 
            this.selected_appointment_year = null;
            this.getEvents();
            return;
        }

        this.selected_appointment_year = Number(value);
        this.getEvents();
    }

    /**
     * 예약 년도별 개수 반환
     */
    getAppointmentCountByYear(year : number) : number {
        return this.events.filter(item => item.event_data != null &&
                                          new Date(item.event_data.schedule_date.start).getFullYear() == year).length;
    }

    /**
     * 예약 상태에 따른 클래스 또는 텍스트를 반환
     */
    getAppointmentStatusClassOrText(appointment : t_event_hodu_doc | undefined, is_class : boolean = false) : string {

        if( appointment == null ) return '';

        switch(appointment.appointment_status) {
            case hodu_doc_enum.appointment_status_code.REQUEST  : return is_class ? "ing"       : "요청";
            case hodu_doc_enum.appointment_status_code.DECLINE  : return is_class ? "reject"    : "거절";
            case hodu_doc_enum.appointment_status_code.CONFIRM  : return is_class ? "confirmed" : "확정";
            case hodu_doc_enum.appointment_status_code.RECEIPT  : return is_class ? "made"      : "접수";
            case hodu_doc_enum.appointment_status_code.DIAGNOSIS: return is_class ? "seen"      : "진료";
            case hodu_doc_enum.appointment_status_code.NOSHOW   : return is_class ? "noshow"    : "노쇼";
            default: break;
        }

        return "";
    }

    /**
     * 파일리스트 보기 모드 변경
     */
    fileListCardModeOnOff() : void {
        this.is_file_list_card_mode = !this.is_file_list_card_mode;
    }

    /**
     * 파일함 년도 선택
     */
    selectFileYear(value : string) : void {
        if ( value == null || value.length < 1 ) { 
            this.selected_file_year = null;
            this.getPatientsFileboxInfo();
            return;
        }

        this.selected_file_year = Number(value);
        this.getPatientsFileboxInfo();
    }

    /**
     * 파일 년도별 개수 반환
     */
    getFileCountByYear(year : number) : number {
        return this.file_list.filter(item => item.audit_created != null &&
                                             new Date(item.audit_created).getFullYear() == year).length;
    }

    /**
     * 파일 용량 텍스트
     */
    getFileSizeText(file_size : number) : string {

        let size : string = FILE_SIZE.B;
        while( file_size > 1024 && size != FILE_SIZE.GB ) {
            file_size /= 1024;

            // 사이즈는 GB까지만 표현한다
            switch( size ) {
                case FILE_SIZE.B:
                    size = FILE_SIZE.KB;
                    break;

                case FILE_SIZE.KB:
                    size = FILE_SIZE.MB;
                    break;

                case FILE_SIZE.MB:
                    size = FILE_SIZE.GB;
                    break;
            } 
        }

        return `${file_size.toFixed(2)} ${size}`;
    }
    
    /**
     * 배경화면 반환
     */
    getBackgroundImage(file : hodu_doc_table.t_hospital_patient_file) : string {
        if( new RegExp(escape('image')).test(escape(file.file_type)) == false ) {
            return 'none';
        }

        return `url(app_images/${file.file_path})`;
    }

    /**
     * 파일 추가
     */
    async addFile(files : File[]) : Promise<void> {

        const vue = this;

        if( this.selected_patient == null ) { return; }


        await this.hodu_show_indicator();

        const file_count : number = files.length;
        const event_files : t_event_file[] = [];
        
        // 파일 용량 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > this.DEFAULT_FILE_MAX_SIZE ) {
                alert(`${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`);
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 이미지 파일이면 미리보기용 URL을 생성한다
            // let url : string = "";
            // if( new RegExp(escape('image')).test(escape(files[i].type)) == true ) {
                
            //     let blob : Blob | null = null;

            //     // 리사이즈
            //     await this.fileReaderPromise(files[i])
            //         .then(async(pe_fr : any) => {
            //             if( pe_fr.target == null || pe_fr.target.result == null ) {
            //                 return;
            //             }

            //             let base64url : string = "";

            //             if( pe_fr.target.result instanceof ArrayBuffer ){
            //                 const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
            //                 const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
            //                 base64url = decodeURIComponent(url);
            //             } else {
            //                 base64url = pe_fr.target.result;
            //             }

            //             // 이미지 리사이즈
            //             blob = await vue.hodu_image_resize(base64url);

            //         })
                
            //     url = URL.createObjectURL(blob ? blob : files[i]);
            // }

            const url : string = URL.createObjectURL(files[i]);
            
            event_files.push({
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: url,                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            })

        }

        const form_data : FormData = new FormData();
            
        // FormData 내용 생성
        const image_or_files_count : number = files.length;
        for( let i = 0; i < image_or_files_count; i++ ) {
            form_data.append('file', files[i]);
        }

        // 임시 파일 업로드
        await this.hodu_temp_upload(form_data)
            .then(async(response) => {
                console.log(response);
                const temp_files : t_event_file[] = response.data.data.temp_files;

                // 임시파일 데이터로 객체 대체하기
                const file_count : number = event_files.length;
                for( let i = 0; i < image_or_files_count; i++ ) {
                    event_files.splice(i, 1, temp_files[i]);    
                }

            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        // 환자 파일함에 등록
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${this.selected_patient.user_id}&group_id=${this.scope_group_id}`, API_METHOD.POST, { "event_files" : event_files })
            .then(async(response) => {
                console.log(response);
                this.getPatientsFileboxInfo();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

        // 파일 input 비우기
        $('#addFile').val("");

        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 promise
     */
    // fileReaderPromise(file : File | Blob) : Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         const fileReader : FileReader = new FileReader();
    //         fileReader.onload = (fr) => resolve(fr);
    //         fileReader.onerror = () => reject();
    //         fileReader.readAsDataURL(file);
    //     });
    // }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        // this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        // DO NOTHING, 나중에 효과 생길때 사용
        // this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        // this.file_drag = false;
        this.addFile(event.dataTransfer.files);
    }

    /**
     * 파일 클릭
     */
    fileClick(file : hodu_doc_table.t_hospital_patient_file) : void {

        // 이미지는 미리보기
        if( new RegExp(escape('image')).test(escape(file.file_type)) == true ) {
            this.imagePreviewOpen(file);
            return;
        }   

        this.fileDownload(file);
    }

    /**
     * 이미지 미리보기
     */
    imagePreviewOpen(image : hodu_doc_table.t_hospital_patient_file) : void {
        
        const image_files : t_event_file[] = []

        const images : hodu_doc_table.t_hospital_patient_file[] = this.file_list.filter(item => new RegExp(escape('image')).test(item.file_type) == true);
        const image_index : number = images.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        for( const image of images ) {
            image_files.push({
                name : image.file_name,
                url : image.file_path,
                mimeType : image.file_type,
                size : image.file_size,
                date : image.audit_created ? image.audit_created : new Date()
            })
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : image_files
        });
        this.doSetShowEventImagePreview(true);
    }

    // 파일 다운로드 (조회)
    fileDownload(file : hodu_doc_table.t_hospital_patient_file) : void {
        this.hodu_download(`/app_images/${file.file_path}`, file.file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 예약 생성 모달
     */
    showAppointmentCreateModal() : void {
        if( this.doSetAppointmentCreateModalInfo == null || this.hospital_setting_info == null || this.selected_patient == null ) { return; }
        
        const current : Date = new Date();

        const settings : hodu_doc_object.hospital_setting_info[] = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= current.getTime() &&
                                                                                                             new Date(item.end_date).getTime()   >= current.getTime() );
        
        const term : number = settings[0].reservation_term;
        const max_count : number = Math.floor(60 / term);

        const current_count = Math.floor(current.getMinutes() / term);

        // 현재 시각이 마지막 텀 이후라면 1시간 후 0분으로, 아니라면 다음 텀 시간으로
        let target_date = moment(current).set("minute", term * (current_count + 1))
                                         .set("second", 0)
                                         .set("millisecond", 0).toDate();
                                         
        if( max_count <= current_count ) {
            target_date = moment(current).add("hour", 1)
                                         .set("minute", 0).toDate();
        }

        this.doSetAppointmentCreateModalInfo({ 
            "show_modal"            : true,
            "is_create_one_patient" : true, 
            "department_code"       : "",
            "doctor_code"           : "",
            "original_timetable"    : target_date,
            "patient_info"          : this.selected_patient
        });
    
    }

    /**
     * 예약 상세로 이동
     */
    moveAppointmentDetail(event_id : string | undefined) : void {
        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event_id}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#patient_file_box_list_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#appointment_list_scroll_by_patient').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
