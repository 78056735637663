
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, GROUP_TYPE, OWNER_TYPE, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');

import { hodu_cookie } from '@/lib/HoduCookie';

import { ResizeObserver } from 'vue-resize';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

@Component({
    components: {
        ResizeObserver
    },
})
export default class FileBox extends Mixins(VueHoduCommon) {

    get computedHoduC() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.hodu_c_list));

        if( this.is_card_view == false && this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list = target_list.sort((item, item2) : number => {

            const is_fave = item.is_favorite;
            const is_fave2 = item2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.dir_name == item2.dir_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.dir_name > item2.dir_name ? 1 : -1;
                }

                return item.dir_name < item2.dir_name ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {
                
                // 날짜가 같다면
                if( new Date(item.audit_modified).getTime() == new Date(item2.audit_modified).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item.audit_modified).getTime() > new Date(item2.audit_modified).getTime() ? 1 : -1;
                }

                return new Date(item.audit_modified).getTime() < new Date(item2.audit_modified).getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    get computedHoduD() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.hodu_d_list));

        if( this.is_card_view == false && this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list = target_list.sort((item, item2) : number => {

            const is_fave = item.is_favorite;
            const is_fave2 = item2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.dir_name == item2.dir_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.dir_name > item2.dir_name ? 1 : -1;
                }

                return item.dir_name < item2.dir_name ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {
                
                // 날짜가 같다면
                if( new Date(item.audit_modified).getTime() == new Date(item2.audit_modified).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item.audit_modified).getTime() > new Date(item2.audit_modified).getTime() ? 1 : -1;
                }

                return new Date(item.audit_modified).getTime() < new Date(item2.audit_modified).getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    get computedSearchFiles() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.search_files));

        if( this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list.sort((o1, o2) => {

            const o1_group_type = o1.group_type;
            const o2_group_type = o2.group_type;

            if( o1_group_type != o2_group_type ) {
                if( o1_group_type == 'BIZC' && o2_group_type == 'BIZD' ) return -1;
                else if( o1_group_type == 'BIZD' && o2_group_type == 'BIZC' ) return 1;
            }

            const o1_path_dirs = o1.path_dirs;
            const o2_path_dirs = o2.path_dirs;

            let o1_path_names = ""; 
            let o2_path_names = "";
            
            const o1_path_dirs_length = o1_path_dirs.length;
            for( let i = 0; i < o1_path_dirs_length; i++ ) {
                const o1_path_dir = o1_path_dirs[i];
                if( i > 0 ) o1_path_names += "_";
                o1_path_names += o1_path_dir.dir_name;
            }
            
            const o2_path_dirs_length = o2_path_dirs.length;
            for( let i = 0; i < o2_path_dirs_length; i++ ) {
                const o2_path_dir = o2_path_dirs[i];
                if( i > 0 ) o2_path_names += "_";
                o2_path_names += o2_path_dir.dir_name;
            }

            // 노드, 트리 형식 정렬에 사용하기 좋음
            return o1_path_names.localeCompare(o2_path_names);
        });

        // 비밀번호 중간에 걸려있으면 제외시켜야함 && 패스워드 걸린 디렉토리는 디렉토리 이름이 검색된게 아니면 검색 리스트에서 제외되어야함
        target_list = target_list.filter(item => this.isMiddleDirHasPassword(item) == false && this.isPassHasPasswordDirRule(item) == true);

        // 비밀번호 걸린 디렉토리는 파일이 노출되어서는 안됨
        for( const item of target_list ) {
            if( item.has_password == true ) {
                item.files.splice(0, item.files.length);
            }
        }

        return target_list;
    }

    /**
     * @GroupInfo.Action
     */
    @GroupInfo.Action doSelectedDirUuid ?: (params : string) => void;

    hodu_c_list : any[] = [];
    hodu_d_list : any[] = [];

    is_card_view : boolean = true;
    file_box_tab : 'ALL' | 'PREMIUM' | 'HODUDOC' = 'ALL';
    show_fave_only : boolean = false;
    open_biz_id : string = "";

    // 정렬
    sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE' = 'DIR_NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    file_search_query : string = "";
    search_files : any[] = [];
    close_search_dir_uuids : string[] = [];
    
    mounted() : void {

        const group_file_box_is_card_view = hodu_cookie.get('group_file_box_is_card_view');
        if( group_file_box_is_card_view != null ) {
            this.is_card_view = (group_file_box_is_card_view === 'true');
        }
        
        // //리스트형으로 보기
        // $(".btns .dpTwo").click(function(){
        //     $(".section_ce_fix").addClass("dp2");
        //     $(".section_ce_fix").removeClass("dp1");
        //     $(".btns .dpOne").show();
        //     $(this).hide();

        // });

        // //박스형으로 보기
        // $(".btns .dpOne").click(function(){
        //     $(".section_ce_fix").removeClass("dp2");
        //     $(".section_ce_fix").addClass("dp1");
        //     $(".btns .dpTwo").show();
        //     $(this).hide();
        // });

        // 즐겨찾기 정렬
        // $(".faveStar").click(function(){
        //     $(this).toggleClass("fave_on");
        // });

        // $(".faveStar").click(function(){
        //     $(this).addClass('fave_on').siblings().removeClass();
        // })

        //서브폴더 열기
        // $(".seeSubFolder").click(function(){
        //     $(this).parent().addClass("on");
        // });

        //서브폴더 닫기
        // $(".closeSub").click(function(){
        //     $(this).parent().parent().parent().removeClass("on");
        // });

        this.getFileBoxDirAndFile();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {

            const title_box_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const sort_header_height : number | undefined = this.is_card_view == true ? 0 : $('.sortHeader').outerHeight();
            const search_sort_header_height : number | undefined = $('.grp_file_search .sortHeader').outerHeight();

            // @ts-ignore
            $('#filebox_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (sort_header_height ? sort_header_height : 0),
            });

            // @ts-ignore
            $('#search_filebox_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (search_sort_header_height ? search_sort_header_height : 0),
            });
        });
        
    }

    /**
     * 유저와 관련된 파일함 전체 조회
     */
    async getFileBoxDirAndFile() : Promise<void> {

        await this.hodu_api_call(`api/v1/user/me/file`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.hodu_c_list.splice(0, this.hodu_c_list.length);
                this.hodu_c_list = this.hodu_c_list.concat(response.data.data.hodu_c_list);

                this.hodu_d_list.splice(0, this.hodu_d_list.length);
                this.hodu_d_list = this.hodu_d_list.concat(response.data.data.hodu_d_list);

            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });

    }

    /**
     * 카드 뷰 모드 ON / OFF
     */
    cardViewModeOnOff() : void {
        this.is_card_view = !this.is_card_view;

        if( this.open_biz_id != null && this.open_biz_id.length > 0 ) {
            
            const scroll_container = $(`#${this.open_biz_id}_scroll`);

            if( this.is_card_view == true ) {

                this.$nextTick(() => {
                
                    const li_height : number | undefined = $(`#${this.open_biz_id}_li`).innerHeight();
                    const li_title_height : number | undefined = $(`#${this.open_biz_id}_li .subFoldertitleDiv`).innerHeight();

                    // @ts-ignore
                    scroll_container.mCustomScrollbar({
                        axis : 'y',
                        scrollbarPosition : 'outside',
                        mouseWheelPixels : 100,
                        scrollInertia : 60,
                        autoDraggerLength : false,
                        setHeight : (li_height ? li_height : 0) - (li_title_height ? li_title_height : 0)
                    });

                });

            }

            else {
                // @ts-ignore
                scroll_container.mCustomScrollbar('destroy');
            }
            
        }

        hodu_cookie.set('group_file_box_is_card_view', String(this.is_card_view));
        
        this.$nextTick(() => this.handleResize());
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 호두 C - 그룹 파일 개수 반환
     */
    getFileCount(group : any) : number {
        let file_count : number = 0;
        for( const dir of group.dir_list ) { file_count += dir.path_files.length; }
        return file_count;
    }

    /**
     * 호두 C & D - 마지막 업데이트 된 날짜
     */
    getLastUpdate(group : any) : string {

        let target_date : Date | null = null;
        for( const dir of (group.dir_list ? group.dir_list : group.file_list) ) {
            
            // 첫 데이터
            if( target_date == null ) {
                target_date = new Date(dir.audit_modified);
                continue;
            }

            // 비교
            if( target_date.getTime() < new Date(dir.audit_modified).getTime() ) {
                target_date = new Date(dir.audit_modified);
            }
        }

        // 리스트가 없다면
        if( target_date == null ) { return ''; }

        return `${this.hodu_date_to_format_string(target_date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(target_date)}`;
    }

    /**
     * 그룹 파일함 폴더 열기
     */
    openGroupFilxBox(biz_id : string) : void {
        this.open_biz_id = biz_id;

        if( this.is_card_view == false ) { return; } 

        this.$nextTick(() => {
            
            const li_height : number | undefined = $(`#${biz_id}_li`).innerHeight();
            const li_title_height : number | undefined = $(`#${biz_id}_li .subFoldertitleDiv`).innerHeight();

            // @ts-ignore
            $(`#${biz_id}_scroll`).mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : (li_height ? li_height : 0) - (li_title_height ? li_title_height : 0)
            });

        });
    }

    /**
     * 그룹 파일함 폴더 닫기
     */
    closeFileBox() : void {

        if( this.is_card_view == true ) { 
            // @ts-ignore
            $(`#${this.open_biz_id}_scroll`).mCustomScrollbar('destroy');
        }

        this.open_biz_id = "";
    }

    /**
     * 파일 검색 쿼리 변경 감지
     */
    @Debounce(250)
    @Watch('file_search_query')
    async watchFileSearchQuery() : Promise<void> {

        this.close_search_dir_uuids.splice(0, length);

        if( this.file_search_query.length < 1 ) {
            this.search_files.splice(0, this.search_files.length);
            return;
        }

        try {
            const response = await this.hodu_api_call(`api/v1/file?search_query=${this.file_search_query}`, API_METHOD.GET, null, false);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.groups ) {
                throw new Error("검색 중 오류 발생");
            }

            this.search_files.splice(0, this.search_files.length);
            this.search_files = this.search_files.concat(response.data.data.groups);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        this.$nextTick(() => this.handleResize()); 
    }

    getGrpHtml(dir) {

        // <i class="clr dc6"></i>
        // 비밀번호 파일 pswFolder 클래스 추가
        // <span>{{ dir.group_name + `${ dir.group_type == 'BIZC' ? " / " : "" }` }} </span> 
        // <span :class="{ pswFolder : dir.has_password == true }" v-if="dir.group_type == 'BIZC'">
        //     <i class="ic-password">비밀번호 여부</i>
        //     {{ dir.dir_name }}
        //     <mark>호두C</mark>
        // </span>

        if( dir == null ) return '';

        let html = `<i class="clr ${this.hodu_color_dc_lc(dir.group_color)}"></i>`;
        html += `<span>${ dir.group_name }</span>`;

        if( dir.group_type != 'BIZC' || dir.path_dirs == null || dir.path_dirs.length < 1 ) {
            return html;
        }

        const SHOW_DIR_TREE_MAX_COUNT = 4;

        const dir_count = dir.path_dirs.length;
        for( let i = 0; i < dir_count; i++ ) {
            const path_dir = dir.path_dirs[i];

            if( dir_count > SHOW_DIR_TREE_MAX_COUNT ) {
                if( (SHOW_DIR_TREE_MAX_COUNT / 2) - 1 < i && i < dir_count - (SHOW_DIR_TREE_MAX_COUNT / 2) ) {
                    if( i == dir_count - (SHOW_DIR_TREE_MAX_COUNT / 2) - 1 ) html += ` / ··· `;
                    continue;
                }
            }

            const mark_text = path_dir.dir_name.replaceAll(this.file_search_query, `<mark>${this.file_search_query}</mark>`);

            html += ` / <span class="${ path_dir.has_password == true ? "pswFolder" : "" }"><i class="ic-password">비밀번호 여부</i>${mark_text}</span>`;
        }

        return html;
    }

    getFileGrpHtml(file) {

        if( file == null ) return '';

        return file.file_name.replaceAll(this.file_search_query, `<mark>${this.file_search_query}</mark>`)

    }

    /**
     * 배경화면 반환
     */
    getBackgroundImage(file : any) : string {
        if( new RegExp(escape('image')).test(escape(file.file_type)) == false ) {
            return 'none';
        }

        return file.file_path.indexOf('patient_file') > -1 ? `url(app_images/${file.file_path})` : `url(app_images/group_file/${file.file_path})`;
    }

    /**
     * 그룹 파일함 검색 리스트 폴더 열기
     */
    searchFileBoxOnOff(dir) : void {
        
        if( dir == null ) return;

        let dir_uuid = dir.group_type == 'BIZC' ? dir.dir_uuid : dir.biz_id;

        let index = this.close_search_dir_uuids.indexOf(dir_uuid);
        if( index < 0 ) {
            this.close_search_dir_uuids.push(dir_uuid);
        }
        else {
            this.close_search_dir_uuids.splice(index, 1);
        }

    }

    /**
     * 파일함 즐겨찾기 ON / OFF
     */
    async insertFileDirFavorite(group_id : number, dir_uuid : string, is_root : boolean) {

        try {

            const response = await this.hodu_api_call(`/api/v1/fileDirFavorite/${group_id}/${dir_uuid}?is_root=${is_root}`, API_METHOD.POST);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("파일함 즐겨찾기 ON / OFF 중 오류 발생");
            }

            // 재조회
            this.getFileBoxDirAndFile();

            if( this.file_search_query != null && this.file_search_query.length > 0 ) {
                this.watchFileSearchQuery();
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일함 즐겨찾기 설정 중 오류 발생", ['확인']);
        }
    }

    /**
     * 디렉토리 비밀번호 입력
     */
    password_input(group : any, dir : any) {

        let uuid : string = dir.dir_uuid;

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }

                    this.hodu_router_push(`/?${new Date().getTime()}`, async() => {
                        await this.calendarChange(group);
                        this.doSelectedDirUuid?.(dir != null ? dir.dir_uuid : "");
                        this.hodu_router_push(`/group/filebox`);
                    });

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });
    }

    /**
     * 파일함 디렉토리로 이동
     */
    async moveFileDir(group : any, dir ?: any) : Promise<void> {

        if( dir != null && dir.has_password == true ) {
            this.password_input(group, dir);
            return;
        }
        
        // 개인 달력인 경우 그룹 달력으로 이동 시킨 후 이동한다
        if( group.group_type != GROUP_TYPE.BIZC ) return;

        this.hodu_router_push(`/?${new Date().getTime()}`, async() => {
            await this.calendarChange(group);
            this.doSelectedDirUuid?.(dir != null ? dir.dir_uuid : ""); 
            this.hodu_router_push(`/group/filebox`);
        });
        
    }

    moveHoduDocFileBox(group) {
        this.hodu_router_push(`/hospital/file/${group.biz_id}`);
    }

    // moveFileBox() : void {
    //     this.hodu_router_push(`/filebox?${new Date().getTime()}`);
    // }

    // moveGroupFileBox(group) : void {

    //     // 달력 변경
    //     this.hodu_router_push(`/group/filebox?${new Date().getTime()}`);
    // }

    // moveGroupFileList() : void {
    //     this.hodu_router_push(`/group/filebox/dir_id?${new Date().getTime()}`);
    // }

    async calendarChange(group) {
        if( $('#bt_calContr').hasClass('on') == false ) {
            $('.ui-datepicker-calendar-wrap').hide();
            $('#bt_calContr').addClass('on');
        }

        this.doSetGroupMenuClose(true);
        this.doSetMessageMenuClose(true);
        this.doSetWorkMenuClose(true);
        this.doSetCommunityMenuClose(true);
        this.doSetResidentMenuClose(true);

        this.doGroupId(group.group_id);
        this.doTeamId(0);

        this.doSetCalendarId(`group-${group.group_id}`);
        this.doSetScope(OWNER_TYPE.GROUP);
        this.doSetScopeGroupId(group.group_id);
        this.doSetScopeTeamId(0);
        this.doSetScopeGroupTeamOption({
            group_team_name     : group.group_name,
            group_team_color    : group.group_color,
            group_team_image    : "",
            group_team_descript : "",
            biz_id              : group.biz_id,
            biz_type            : GROUP_TYPE.BIZC,
        });

        await this.hodu_loading_timer_exit();
        await this.hodu_loading();

        setTimeout(() => {
            // @ts-ignore
            $('.left_inbox02').mCustomScrollbar('destroy');
            window['left_inbox02_mCustomScrollbar']();
        }, 100);

        if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
    }

    isMiddleDirHasPassword(dir) {

        if( dir == null || dir.path_dirs == null || dir.path_dirs.length <= 1 ) return false; 

        let is_middle_dir_has_password : boolean = false;

        let count = dir.path_dirs.length;

        for( let i = 0; i < (count - 1); i++ ) {
            const path_dir = dir.path_dirs[i];

            if( path_dir.has_password == true ) {
                is_middle_dir_has_password = true;
                break;
            }
        }

        return is_middle_dir_has_password;
    }

    isPassHasPasswordDirRule(dir) {

        if( dir == null ) return false;
        if( dir.has_password == false ) return true;

        let is_pass_has_password_dir_rule : boolean = new RegExp(this.file_search_query).test(dir.dir_name);

        return is_pass_has_password_dir_rule;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        // @ts-ignore
        $('#filebox_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#search_filebox_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
