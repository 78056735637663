
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';
import { CommonDatepickerModalInfo } from '@/store/modules/ModalInfo';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationWorkTypeSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 해당 근무 타입을 사용하는 직원들 정보를 포함 시킨 근무타입 정보
     */
    get computedWorkTypes() : any[] {

        let work_types : any[] = JSON.parse(JSON.stringify(this.work_types));
        
        for( const work_type of work_types ) {
            if( work_type.employees == null ) work_type.employees = []; 
            work_type.employees.splice(0, work_type.employees.length);
            work_type.employees = work_type.employees.concat(this.employees.filter(employee => employee.work_type_id == work_type.work_type_id));
        }

        return work_types;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationWorkTypeDetailModalInfo ?: (params : organization_modal_interface.OrganizationWorkTypeDetailModalInfo) => void; 
    @ModalInfo.Action doSetOrganizationWorkTypeAddModalInfo    ?: (params : organization_modal_interface.OrganizationWorkTypeAddModalInfo) => void;
    @ModalInfo.Action doSetCommonDatepickerModalInfo ?: (params : CommonDatepickerModalInfo) => void;

    work_types : any[] = [];
    employees : any[] = [];
    
    async mounted() : Promise<void> {
        this.event_bus?.$on('OrganizationWorkTypeSettingResize', this.handleResize);

        await this.getTotalOrganizationData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {

            const title_box_height = $('#organization_setting .title_box').outerHeight();
            const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            const title_box3_height = $('#organization_setting .title_box3').outerHeight();
            const sort_header_height = $('#organization_setting #organization_work_type_setting .sortHeader').outerHeight();

            // @ts-ignore
            $('#organization_work_type_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                               - (title_box2_height ? title_box2_height : 0)
                                               - (title_box3_height ? title_box3_height : 0)
                                               - (sort_header_height ? sort_header_height : 0)
            });

        });
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationWorktype();
            await this.getOrganizationEmp();
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 근무 타입 조회
     */
    async getOrganizationWorktype() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_type_info ) {
                throw new Error("조직도 근무 타입 조회 중 오류 발생");
            }

            this.work_types.splice(0, this.work_types.length);
            this.work_types = this.work_types.concat(response.data.data.work_type_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);
        } catch(e) {
            throw e;
        }
    }

    /**
     * 근무 타입 상세 모달
     */
    showOrganizationWorkTypeDetailModalInfo(work_type_id : number) : void {
        console.log(work_type_id);
        this.doSetOrganizationWorkTypeDetailModalInfo?.({ show_modal : true, work_type_id : work_type_id, callback : this.getTotalOrganizationData });
    }

    /**
     * 근무 타입 추가 / 수정 모달
     */
    showOrganizationWorkTypeAddModalInfo(is_create : boolean, work_type_id : number = 0) : void {
        this.doSetOrganizationWorkTypeAddModalInfo?.({ show_modal : true, is_create : is_create, work_type_id : work_type_id, callback : this.getTotalOrganizationData });
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');

        console.log(replace_image);

        if( jquery_taget.hasClass('last') ) {
            jquery_taget.parent().find('span.img.last').css('background-image', `url(${replace_image})`);
        }
        else {
            jquery_taget.parent().find('span.img.first').css('background-image', `url(${replace_image})`);
        }
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 근태현황 시작일 설정
     */
    setAttedanceStart() : void {
        
        if( this.get_group_role(this.scope_group_id) != 'GROUP_MANAGER' && this.get_group_role(this.scope_group_id) != 'ADMIN' ) return;

        this.doSetCommonDatepickerModalInfo?.({
            show_modal : true,
            default_date : this.getStart() == '' ? new Date() : this.yyyymmddToDate(this.getStart()),
            callback : async(selected_date : Date) => {

                this.hodu_show_dialog("alert", `근태현황 시작일을 '${this.hodu_date_to_format_string(selected_date, 'YYYY년 MM월 DD일')}'로 설정 하시겠습니까?`, ['아니오', '예'], [
                    () => {},
                    async() => {
                        try {
                            const value = this.hodu_date_to_format_string(selected_date, "YYYYMMDD");

                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/attendance?key=start&feature_string=${value}`, API_METHOD.PUT);

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("근태 현황 시작일 설정 중 오류 발생");
                            }

                        }
                        catch(e) {
                            this.hodu_error_process(e, false, false, true);
                            this.hodu_show_dialog('cancel', '근태 현황 시작일 설정 중 오류 발생', ['확인']);
                        } finally {
                            this.get_group_role_service();
                        }
                    }
                ])

            }
        })
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_work_type_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
