
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

import { ImageCropModalInfo } from '@/store/modules/ModalInfo';
import { t_event_file } from '@/model/event';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupCreate extends Mixins(VueHoduCommon) {

    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;
    
    group_data : any = {
        group_id: 0,
        group_type: "",
        biz_id: "",
        teams: [],
        create_user_id: 0,
        group_info: {
            group_name: "",
            description: "",
            image: "",
            group_color: "#477FFF"
        },
        group_policy: {
            is_public: true,
            is_appr_required: false
        },
        group_features: {},
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: 0
    }

    is_created                 : boolean = true;
    is_deleteFlag              : boolean = false;
    image_drag                 : boolean = false;
    base64url                  : string  = "";
    image_files                : any     = [];
    stamp_image_files          : any     = [];
    group_no_image = null;

    color_pick : boolean = false;

    scroll_height : number = 0;

    company_name : string = "";
    company_address : string = "";
    company_ceo : string = "";
    stamp_blob_url : string  = "";

    async mounted() {

        if ( this.group_id > 0 ) {
            this.is_created = false;

            // 삭제 퍼미션 체크
            this.is_deleteFlag = this.is_group_permmision(this.group_id, "group_info", "delete");

            await this.getGroupDetail();
        }
        else {
            this.group_no_image = require("@/assets/images/contents/img"+this.group_color_to_class_name(this.group_data.group_info.group_color)+".png");
        }

        this.setScroll();
    }

    setScroll() {
        const title_height      : number | undefined = $('.title_box').outerHeight();
        const setting_bg_height  : number | undefined = $('.settingBg').outerHeight();

        this.scroll_height = window.innerHeight - (title_height ?? 0) - (setting_bg_height ?? 0);
    }

    /**
     * 그룹 단건 조회
     */
    async getGroupDetail() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getDetail/${this.group_id}/0`, API_METHOD.GET, null)
            .then(async(response) => {
                if ( !response.data.data.data ) {
                    this.hodu_show_dialog("cancel", "그룹 조회중 오류가 발생하였습니다.", ['확인'], [() => { vue.movePrevPage(); }]);
                    return;
                }

                let data = response.data.data.data;
                this.group_data.group_info.group_name         = data.group_info.group_name;
                this.group_data.group_info.group_color        = data.group_info.group_color;
                this.group_data.group_info.image              = data.group_info.image;
                this.group_data.group_info.description        = data.group_info.description;
                this.group_data.group_info.company_name       = (data.group_info.company_name ?? "");
                this.group_data.group_info.company_address    = (data.group_info.company_address ?? "");
                this.group_data.group_info.company_ceo        = (data.group_info.company_ceo ?? "");
                this.group_data.group_info.stamp_image        = (data.group_info.stamp_image ?? "");
                this.group_data.group_policy.is_appr_required = data.group_policy.is_appr_required;
                this.group_data.group_policy.is_public        = data.group_policy.is_public;

                this.company_name = this.group_data.group_info.company_name;
                this.company_address = this.group_data.group_info.company_address;
                this.company_ceo = this.group_data.group_info.company_ceo;

                if ( this.group_data.group_info.group_color.length == 9 ) {
                    this.group_data.group_info.group_color = "#" + this.group_data.group_info.group_color.substring(3, this.group_data.group_info.group_color.length);
                }

                if ( this.group_data.group_info.image == "" ) {
                    this.noGroupImage();
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 승인 변경
     */
    setApproveChange(any) : void {
        this.group_data.group_policy.is_appr_required = any.target.checked;
    }

    /**
     * 그룹 삭제
     */
    groupDelete() : void {
        let lb_flag : boolean = this.is_group_permmision(this.group_id, "group_info", "delete");

        if ( !lb_flag ) {
            return;
        }

        this.hodu_show_dialog("alert", "그룹을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.groupDeleteService();
            },
        ]);
    }

    /**
     * 그룹 삭제
     */
    groupDeleteService() : void {
        const vue = this;

        this.hodu_api_call(`api/v1/groups/updateGroupDelete/${this.group_id}`, API_METHOD.DELETE, null)
            .then(async(response) => {
                await vue.reset_group_initialization();
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 그룹 생성
     */
    async saveValidator() : Promise<void> {
        if ( this.group_data.group_info.group_name.length < 2 || this.group_data.group_info.group_name.length > 20 ) {
            this.hodu_show_dialog("alert", "그룹명은 최소 2글자 부터 최대 20글자 입니다.", ['확인']);
            return;
        }

        // 그룹 명에 호두닥이 포함 될 수 없음
        if( new RegExp('호두닥').test(this.group_data.group_info.group_name) == true ) {
            this.hodu_show_dialog("alert", '"호두닥"은 그룹명에 사용 하실 수 없습니다', ['확인']);
            return;
        }

        // 그룹 소개글 제한 없음
        // if ( this.group_data.group_info.description.length > 20 ) {
        //     this.hodu_show_dialog("alert", "그룹 소개글은 최대 20글자 입니다.", ['확인']);
        //     return;
        // }

        if( this.group_id == 0 && !(this.base64url && this.image_files.length > 0) ) {
            this.hodu_show_dialog("alert", "기본 이미지로 그룹을 생성하시겠습니까?", ['아니오', '예'], [
                () => {},
                () => {
                    this.groupFileSave();
                },
            ]);
            return;
        }

        this.hodu_show_dialog("alert", "그룹 " + (this.group_id == 0 ? "생성" : "수정") + " 하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.groupFileSave();
            },
        ]);
    }

    /**
     * 파일 업로드
     */
    async groupFileSave() : Promise<void> {
        const vue = this;

        let temp_image_files : t_event_file[] = [];
        let temp_stamp_image_files : t_event_file[] = [];

        if ( this.image_files.length > 0 ) {
            const form_data : FormData = new FormData();

            // FormData 내용 생성
            const image_files_count : number = this.image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.image_files[i].file);
            }

            try {
                const response = await this.hodu_temp_upload(form_data);
                temp_image_files = response.data.data.temp_files;
            } catch(e) {
                this.hodu_error_process(e, true, false);
                return;
            }
        }

        if( this.stamp_image_files.length > 0 ) {
            const form_data : FormData = new FormData();

            // FormData 내용 생성
            const image_files_count : number = this.stamp_image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.stamp_image_files[i].file);
            }

            try {
                const response = await this.hodu_temp_upload(form_data);
                temp_stamp_image_files = response.data.data.temp_files;
            } catch(e) {
                this.hodu_error_process(e, true, false);
                return;
            }
        }

        this.group_id == 0 ? await this.groupCreateSave(temp_image_files, temp_stamp_image_files) 
                           : await this.groupModifySave(temp_image_files, temp_stamp_image_files);
    }

    /**
     * 그룹 생성
     */
    async groupCreateSave(po_files : t_event_file[], stamp_files : t_event_file[]) : Promise<void> {
        const vue = this;

        this.group_data.group_type             = "GROUP";
        this.group_data.biz_id                 = "";
        // this.group_data.group_policy.is_public = true;

        this.group_data.group_info.company_name = this.company_name;
        this.group_data.group_info.company_address = this.company_address;
        this.group_data.group_info.company_ceo = this.company_ceo;

        let param_data = {group: this.group_data}

        if ( null != po_files && po_files.length > 0 ) {
            param_data["group_file"] = po_files[0];
        }

        if ( null != stamp_files && stamp_files.length > 0 ) {
            param_data["stamp_file"] = stamp_files[0];
        }

        await this.hodu_api_call(`api/v1/groups`, API_METHOD.POST, param_data)
            .then(async(response) => {
                await this.get_group_role_service();
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 그룹 수정
     */
    async groupModifySave(po_files : t_event_file[], stamp_files : t_event_file[]) : Promise<void> {
        const vue = this;

        this.group_data.group_info.company_name = this.company_name;
        this.group_data.group_info.company_address = this.company_address;
        this.group_data.group_info.company_ceo = this.company_ceo;

        let param_data = {group: this.group_data}

        if ( null != po_files && po_files.length > 0 ) {
            param_data["group_file"] = po_files[0];
        }

        if ( null != stamp_files && stamp_files.length > 0 ) {
            param_data["stamp_file"] = stamp_files[0];
        }

        await this.hodu_api_call(`api/v1/groups/updateGroup/${this.group_id}`, API_METHOD.PUT, param_data)
            .then(async(response) => {

                if( this.group_id == this.scope_group_id ) {
                    await this.get_group_role_service();
                    const groups = this.all_group_data.filter(group => group.group_id == this.scope_group_id);
                    if( groups.length > 0 ) {

                        const group = groups[0];

                        this.doSetScopeGroupTeamOption({
                            group_team_name : group.group_name,
                            group_team_color : group.color,
                            group_team_image : `${this.make_group_profile_image_url(this.scope_group_id)}?t=${new Date().getTime()}`,
                            group_team_descript : this.group_data.group_info.description,
                            biz_id : group.biz_id,
                            biz_type : group.biz_type
                        });

                    }
                }

                await vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 색상선택
     */
    colorChange(color) : void {
        this.color_pick = false;
        this.group_data.group_info.group_color = color;
        this.noGroupImage();
    }

    /**
     * 색상 선택 boolean
     */
    colorPick() : void {
        this.color_pick = !this.color_pick;
    }

    /**
     * 이미지 삭제
     */
    imageDelete() : void {
        if ( this.base64url ) {
            this.base64url = "";
            this.image_files = [];
        }

        if ( this.group_data.group_info.image ) {
            this.group_data.group_info.image = "";
        }

        this.noGroupImage();
    }

    /**
     * 이미지 변경 (업로드 안되어있을때)
     */
    noGroupImage() : void {
        this.group_no_image = require("@/assets/images/contents/img"+this.group_color_to_class_name(this.group_data.group_info.group_color)+".png");
    }

    /**
     * 이미지 드래그해서 이미지 영역에 올려놨을때
     */
    imageDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.image_drag = true;

    }

    /**
     * 이미지 드래그해서 이미지 영역에서 벗어났을때
     */
    imageDragLeave() : void {
        this.image_drag = false;
    }

    /**
     * 이미지를 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    imageDrop(event) : void {
        this.image_drag = false;
        this.addImage(event.dataTransfer.files);
    }

    /**
     * 그룹 이미지 추가
     */
    addImageNormal(event) : void {
        const file : File[] = event.target.files;
        this.addImage(file);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(file : File[]) : Promise<void> {
        const vue = this;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(file[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#cdImg').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                if( this.doSetImageCropModalInfo == null ) { return; }

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (4/3), // 4:3
                    image_url : url,
                    cancel_function : async() => {
                        $('#cdImg').val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        
                        this.base64url = cropped_url;

                        const resize_blob : Blob = await vue.hodu_image_resize(this.base64url);

                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = file[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, file[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = file[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        // 업로드용 파일 객체 담기
                        vue.image_files = [];
                        vue.image_files.push({
                            file : resize_file
                        });

                        $('#cdImg').val("");

                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 이미지 드래그해서 이미지 영역에 올려놨을때
     */
    stampImageDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.image_drag = true;
    }

    /**
     * 이미지 드래그해서 이미지 영역에서 벗어났을때
     */
    stampImageDragLeave() : void {
        this.image_drag = false;
    }

    /**
     * 도장 이미지를 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    stampImageDrop(event) : void {
        this.image_drag = false;
        this.addStampImage(event.dataTransfer.files);
    }

    /**
     * 도장 이미지 추가
     */
    addStampImageNormal(event) : void {
        const file : File[] = event.target.files;
        this.addStampImage(file);
    }

    async addStampImage(file : File[]) : Promise<void> {

        const vue = this;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(file[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#stampImg').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const resize_blob : Blob = await vue.hodu_image_resize(base64url);
                const url :string = URL.createObjectURL(resize_blob);

                vue.stamp_blob_url = url;

                // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                let resize_file : File = file[0];
                try {
                    resize_file = await this.hodu_blob_to_file(resize_blob, file[0].name);
                } catch(e) {
                    try {
                        (resize_blob as any).lastModifiedDate = new Date();
                        (resize_blob as any).name = file[0].name;
                        resize_file = (resize_blob as any);
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    }
                }

                vue.stamp_image_files.splice(0, vue.stamp_image_files.length);
                vue.stamp_image_files.push({
                    file : resize_file
                });

                $('#stampImg').val("");

            })
            .catch((e) => {
                console.log('filereader promise error', e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.setScroll();
    }

}
