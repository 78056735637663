
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { CommonColorPickerModalInfo, CommonInputModalInfo } from '@/store/modules/ModalInfo';
import { hodu_color } from '@/common/color';

import { chat_interface, chat_modal_interface } from '@/model/chat';
import { t_event_file } from '@/model/event';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ChattingRight extends Mixins(VueHoduCommon) {

    @Prop() event_bus                !: Vue;
    @Prop() connected_channel        !: chat_interface.I_CHANNEL_INFO | null;
    @Prop() users_online             !: Object
    @Prop() is_chatting_room_manager !: boolean;
    
    /**
     * 유저 리스트
     */
    get computedUserList() : any[] {

        let target_users : any[] = []; 

        if( this.connected_channel == null || this.connected_channel.users == null ) { return []; }

        target_users = target_users.concat(this.connected_channel.users.filter(
            item => this.hodu_string_includes(item.user_name, this.user_search_query) &&
                    item.leaved_flag == false 
        ));

        // sort 순서
        // 1. 나
        // 2. 방장
        // 3. 이름 오름차순
        target_users.sort((o1, o2) : number => {

            if( o1.user_id == this.user_id ) {
                return -1;
            }

            if( o2.user_id == this.user_id ) {
                return 1;
            }

            const o1_online = this.users_online[o1.user_id];
            const o2_online = this.users_online[o2.user_id];
            
            if( o1_online != null && o2_online == null ) {
                return -1;
            } 

            if( o1_online == null && o2_online != null ) {
                return 1;
            }

            if( o1.user_role.toUpperCase() == "OWNER" && o2.user_role.toUpperCase() != "OWNER" ) {
                return -1;
            }

            if( o1.user_role.toUpperCase() != "OWNER" && o2.user_role.toUpperCase() == "OWNER" ) {
                return 1;
            }

            if( o1.user_name < o2.user_name ) {
                return -1;
            }

            if( o1.user_name > o2.user_name ) {
                return 1;
            }

            return 0;
        });

        return target_users;
    }

    /**
     * 년도별 이미지 리스트
     */
    get computedImageListByYear() : any[] {

        const target_list : any[] = [];

        target_list.push({
            "year" : "전체",
            "images" : this.all_images
        })

        for( const image of this.all_images ) {
             
            const year = moment(image.date).format('YYYY'); 

            const target_year = target_list.filter(item => item.year == year);

            if( target_year.length < 1 ) {
                
                target_list.push({
                    "year" : year,
                    "images" : [image] 
                });

                continue;
            }

            target_year[0].images.push(image);

        }

        // 정렬
        for( const target of target_list ) {
            target.images.sort((o1, o2) : number => {
                
                // 최신순
                if( this.selected_file_sort == "TIME" ) {
                    const o1_time = moment(o1.date).toDate().getTime();
                    const o2_time = moment(o2.date).toDate().getTime();
                    
                    if( o1_time == o2_time ) { return 0; }
                    return o1_time < o2_time ? 1 : -1;
                }

                // 가나다순
                if( this.selected_file_sort == 'NAME' ) {
                    const o1_name = o1.name;
                    const o2_name = o2.name;
                    
                    if( o1_name == o2_name ) { return 0; }
                    return o1_name < o2_name ? -1 : 1;
                }

                // 파일크기순
                if( this.selected_file_sort == 'SIZE' ) {
                    const o1_size = o1.size;
                    const o2_size = o2.size;
                    
                    if( o1_size == o2_size ) { return 0; }
                    return o1_size < o2_size ? 1 : -1;
                }

                return 0;
            });
        }

        return target_list;
    }

    /**
     * 년도별 파일 리스트
     */
    get computedFileListByYear() : any[] {
        
        const target_list : any[] = [];

        target_list.push({
            "year" : "전체",
            "files" : this.all_files
        })

        for( const file of this.all_files ) {
             
            const year = moment(file.date).format('YYYY'); 

            const target_year = target_list.filter(item => item.year == year);

            if( target_year.length < 1 ) {
                
                target_list.push({
                    "year" : year,
                    "files" : [file] 
                });

                continue;
            }

            target_year[0].files.push(file);

        }

        // 정렬
        for( const target of target_list ) {
            target.files.sort((o1, o2) : number => {

                // 최신순
                if( this.selected_file_sort == "TIME" ) {
                    const o1_time = moment(o1.date).toDate().getTime();
                    const o2_time = moment(o2.date).toDate().getTime();
                    
                    if( o1_time == o2_time ) { return 0; }
                    return o1_time < o2_time ? 1 : -1;
                }

                // 가나다순
                if( this.selected_file_sort == 'NAME' ) {
                    const o1_name = o1.name;
                    const o2_name = o2.name;
                    
                    if( o1_name == o2_name ) { return 0; }
                    return o1_name < o2_name ? -1 : 1;
                }

                // 파일크기순
                if( this.selected_file_sort == 'SIZE' ) {
                    const o1_size = o1.size;
                    const o2_size = o2.size;
                    
                    if( o1_size == o2_size ) { return 0; }
                    return o1_size < o2_size ? 1 : -1;
                }
                
                return 0;
            });
        }

        return target_list;
    }

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventImagePreview              ?: any;
    @ModalInfo.Action doSetCommonColorPickerModalInfo         ?: (params : CommonColorPickerModalInfo) => void;
    @ModalInfo.Action doSetChattingRoomInfoModalInfo          ?: (params : chat_modal_interface.ChattingRoomInfoModalInfo) => void;

    all_images : t_event_file[] = [];
    all_files : t_event_file[] = [];

    selected_file_year : string = "ALL"; // ALL, 2020, 2021
    selected_file_sort : string = "TIME"; // TIME, NAME, SIZE

    TAB : string = "USER"; // USER, IMAGE, FILE
    is_setting_open : boolean = false;

    user_search_query : string = "";

    mounted() : void {
        this.event_bus?.$on('handleRightResize', this.handleResize);
        this.event_bus?.$on('imageOrFileAdd', this.imageOrFileAdd);
        this.event_bus?.$on('imageOrFileDelete', this.imageOrFileDelete);

        this.getFilesAndImages();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const main_title_height : number | undefined = $('.section_ce_fix .main-title').outerHeight();
        const chatroom_title_height : number | undefined = $('.section_ce_fix .rightDiv .chatroomTitle').outerHeight();
        const chatroom_button_height : number | undefined = $('.section_ce_fix .rightDiv .chatroomBtns').outerHeight();
        const search_bar_height : number | undefined = $('.section_ce_fix .rightDiv .schPeepsDiv').outerHeight();
        const edit_div_height : number | undefined = $('.section_ce_fix .rightDiv .magDiv .magUl .mateList .editDiv').outerHeight();
        const img_file_sch_div_height : number | undefined = $('.section_ce_fix .rightDiv .imgFileSchDiv').outerHeight();

        if( this.TAB == 'USER' ) {

            // @ts-ignore
            $('#chat-user-list-scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - ( main_title_height == null ? 0 : main_title_height )
                                               - ( chatroom_title_height == null ? 0 : chatroom_title_height )
                                               - ( chatroom_button_height == null ? 0 : chatroom_button_height )
                                               - ( search_bar_height == null ? 0 : search_bar_height )
                                               - ( edit_div_height == null ? 0 : edit_div_height )
            });

        }

        else if( this.TAB == 'IMAGE' ) {

            // @ts-ignore
            $('#chat-image-list-scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - ( main_title_height == null ? 0 : main_title_height )
                                               - ( chatroom_title_height == null ? 0 : chatroom_title_height )
                                               - ( chatroom_button_height == null ? 0 : chatroom_button_height )
                                               - ( img_file_sch_div_height == null ? 0 : img_file_sch_div_height )
            });

        }

        else if( this.TAB == 'FILE' ) {

            // @ts-ignore
            $('#chat-file-list-scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - ( main_title_height == null ? 0 : main_title_height )
                                               - ( chatroom_title_height == null ? 0 : chatroom_title_height )
                                               - ( chatroom_button_height == null ? 0 : chatroom_button_height )
                                               - ( img_file_sch_div_height == null ? 0 : img_file_sch_div_height )
            });

        }

        

    }

    /**
     * 모든 이미지 조회
     */
    async getAllImages() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_images`, API_METHOD.GET, null, false);

            console.log(response);

            this.all_images.splice(0, this.all_images.length);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.files ) {
                throw new Error("이미지 조회 실패");
            }

            this.all_images = this.all_images.concat(response.data.data.files);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 모든 파일 조회
     */
    async getAllFiles() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_files`, API_METHOD.GET, null, false);

            console.log(response);

            this.all_files.splice(0, this.all_files.length);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.files ) {
                throw new Error("파일 조회 실패");
            }

            this.all_files = this.all_files.concat(response.data.data.files);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 파일, 이미지 조회 병렬처리
     */
    async getFilesAndImages() : Promise<void> {
        Promise.all([this.getAllImages(), this.getAllFiles()]);
    }

    /**
     * 이미지, 파일 웹소켓에서 받으면 추가
     */
    imageOrFileAdd(files : t_event_file[]) : void {
        for( const file of files ) {
            // 이미지
            if( new RegExp('image').test(file.mimeType) == true ) {
                this.all_images.push(file);
                continue;
            }

            // 파일
            this.all_files.push(file);
        }
    }

    /**
     * 모든 이미지 삭제 또는 모든 파일 삭제
     */
    imageOrFileDelete(type : string) : void {
        if( type == 'IMAGE' ) {
            this.all_images.splice(0, this.all_images.length);
            return;
        }

        this.all_files.splice(0, this.all_files.length);
    }

    /**
     * 채팅방 나가기
     */
    async leave() : Promise<void> {

        this.hodu_show_dialog("cancel", `정말로 채팅방에서 나가시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {

                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }

                if( this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id)[0] == null ) {
                    return;
                }

                if( this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id)[0].user_role == 'OWNER' ) {
                    this.hodu_show_dialog("cancel", "방장은 채팅방에서 나갈 수 없습니다", ['확인']);
                    return;
                }

                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/leave`, API_METHOD.GET);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 나가기 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 나가기 중 오류 발생", ['확인']);
                }
                
            },
        ]);
    }

    /**
     * 채팅방 멤버 역할 변경
     */
    async promoteMember(user : chat_interface.t_chat_channel_user) : Promise<void> {

        // 방장 권한 부여
        if( user.user_role != 'OWNER' ) {
            this.hodu_show_dialog('alert', `정말로 ${user.user_name}님께 방장 권한을 부여 하시겠습니까?`, ['아니오', '예'], [
                () => {},
                async() => {

                    try {
                        const first_response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/users/${user.user_id}/OWNER`, API_METHOD.PUT);

                        console.log(first_response);

                        if( !first_response || !this.isHttpStatusSuccess(first_response.status) || !first_response.data ) {
                            throw new Error("방장 권한 부여 실패");
                        }

                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                        this.hodu_show_dialog("cancel", "방장 권한 부여 중 오류 발생", ['확인']);
                    }

                }
            ]);

            return;
        } 

        // 방장 권한 없애기
        this.hodu_show_dialog('alert', `정말로 ${user.user_name}님의 방장 권한을 제거 하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {

                try {
                    const first_response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/users/${user.user_id}/MEMBER`, API_METHOD.PUT);

                    console.log(first_response);

                    if( !first_response || !this.isHttpStatusSuccess(first_response.status) || !first_response.data ) {
                        throw new Error("방장 권한 제거 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "방장 권한 제거 중 오류 발생", ['확인']);
                }

            }
        ]);
        
    }

    /**
     * 채팅방 강퇴
     */
    async kickMember(user : chat_interface.t_chat_channel_user) : Promise<void> {
        
        this.hodu_show_dialog("cancel", `정말로 ${user.user_name}님을 강퇴시키시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {

                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/users/${user.user_id}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 멤버 강퇴 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 멤버 강퇴 중 오류 발생", ['확인']);
                }
                
            },
        ]);
        
    }

    /**
     * 탭 변경
     */
    tabChange(tab : string) : void {
        if ( this.TAB == tab ) { return; }

        this.TAB = tab;
        this.selected_file_year = 'ALL';
        this.selected_file_sort = 'TIME';
        this.user_search_query = "";
        this.$nextTick(() => this.handleResize());
    }

    /**
     * 온라인 멤버의 수
     */
    getOnlineMemberCount() : number {
        let count = 0;

        if( this.connected_channel == null || this.connected_channel.users == null ) { return 0; }

        for( const user of this.connected_channel.users ) {
            if( this.users_online[`${user.user_id}`] == null ) { continue; }
            count++;
        }

        return count;
    }

    /**
     * 초대
     */
    invite() : void {
        this.$emit('invite');
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('p.mPic').addClass('no-img');
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        $(event.target).parent().find('a.img').addClass("no-img");
    }

    /**
     * 이미지 열기
     */
    imageOpen(files : t_event_file[], file : t_event_file) : void {
        const image_index : number = files.indexOf(file);

        if( image_index == -1 ){
            return;
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : files,
            "type" : "CHAT"
        });
        this.doSetShowEventImagePreview(true);
    }

    /**
     * 파일 다운로드
     */
    download(file : t_event_file) : void {
        this.hodu_download(`/chat_data/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 모든 메세지 삭제
     */
    deleteAllMessages() : void {
        
        this.hodu_show_dialog('cancel', '정말 모든 메세지를 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_messages`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 모든 메세지 삭제 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 모든 메세지 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 모든 사진 삭제
     */
    deleteAllImages() : void {
        
        this.hodu_show_dialog('cancel', '정말 모든 사진을 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_images`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 모든 사진 삭제 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 모든 사진 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 모든 파일 삭제
     */
    deleteAllFiles() : void {
        
        this.hodu_show_dialog('cancel', '정말 모든 파일을 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/all_files`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 모든 파일 삭제 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 모든 파일 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 채널 삭제
     */
    deleteChattingRoom() : void {
        
        this.hodu_show_dialog('cancel', '정말 채팅방을 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 삭제 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 컬러에 따른 클래스 반환
     */
    getChattingColorClass() : string {

        if( this.connected_channel == null || this.connected_channel.info == null || this.connected_channel.info.color == null ) { return "bgc7"; }

        switch( this.hodu_hex_color_process(this.connected_channel.info.color).toUpperCase() ) {
            case hodu_color.chat_color_0.toUpperCase(): return "bgc0";
            case hodu_color.chat_color_1.toUpperCase(): return "bgc1";
            case hodu_color.chat_color_2.toUpperCase(): return "bgc2";
            case hodu_color.chat_color_3.toUpperCase(): return "bgc3";
            case hodu_color.chat_color_4.toUpperCase(): return "bgc4";
            case hodu_color.chat_color_5.toUpperCase(): return "bgc5";
            case hodu_color.chat_color_6.toUpperCase(): return "bgc6";
            case hodu_color.chat_color_7.toUpperCase(): return "bgc7";
            case hodu_color.chat_color_8.toUpperCase(): return "bgc8";
            case hodu_color.chat_color_9.toUpperCase(): return "bgc9";
        }

        return `bgc7`;
    }
 
    /**
     * 색상 선택 모달
     */
    showColorPicker() : void {
        this.doSetCommonColorPickerModalInfo?.({
            show_modal     : true,
            selected_color : this.connected_channel?.info?.color ?? hodu_color.chat_color_7,
            is_dc_only     : true,
            dc_color       : this.hodu_chat_color
        }); 

        window['applyColor'] = async(color : string) => {

            try {
                const post_data = {
                    "type" : this.connected_channel?.type,
                    "sub_type" : this.connected_channel?.sub_type,
                    "ref_id" : this.connected_channel?.ref_id,
                    "is_private" : this.connected_channel?.is_private,
                    "info" : this.connected_channel?.info 
                };

                post_data.info!.color = color;

                const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}`,API_METHOD.PUT, post_data);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                    throw new Error("채팅방 배경색 적용 실패");
                }

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "채팅방 배경색 적용 중 오류 발생", ['확인']);
            }

        };
    }

    

    /**
     * 채팅방 이름 변경
     */
    updateChattingRoom() : void {

        if( this.connected_channel == null ) return;

        const users = this.connected_channel?.users?.filter(item => String(item.user_id) == String(this.user_id)) ?? [];

        if( users.length < 1 ) {
            throw new Error("채팅방 이름 변경 중 오류 발생");
        }

        const post_data = JSON.parse(JSON.stringify(users[0]));

        const content = (post_data.user_info.title ?? "").trim().length > 0 ? post_data.user_info.title.trim() : (this.connected_channel.info ? this.connected_channel.info.title : "");

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "채팅방 이름 변경",
            subtitle : "채팅방 이름",
            placeholder : "채팅방 이름을 입력해주세요",
            content : content,
            cancel : () => {},
            save : async(title) => {
                try {

                    if( post_data.user_info == null ) post_data.user_info = {};
                    post_data.user_info.title = title;

                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/users/${this.user_id}`, API_METHOD.PUT, post_data);
                    
                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 이름 변경 실패");
                    }

                    // const post_data = {
                    //     "type" : this.connected_channel?.type,
                    //     "sub_type" : this.connected_channel?.sub_type,
                    //     "ref_id" : this.connected_channel?.ref_id,
                    //     "is_private" : this.connected_channel?.is_private,
                    //     "info" : this.connected_channel?.info 
                    // };

                    // post_data.info!.title = title;

                    // const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}`,API_METHOD.PUT, post_data);

                    // console.log(response);

                    // if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                    //     throw new Error("채팅방 이름 변경 실패");
                    // }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 이름 변경 중 오류 발생", ['확인']);
                }
            }
        });

    }

    /**
     * 채팅방 설정 모달
     */
    showChattingRoomInfoModal() : void {

        if( this.doSetChattingRoomInfoModalInfo == null ) { return; }

        this.doSetChattingRoomInfoModalInfo({
            show_modal : true
        });

    }

    /**
     * 클라이언트 여러개 열었을때를 대비해서 권한이 사라졌는데 환경설정에 들어가있다면 환경설정을 닫음
     */
    @Watch('is_chatting_room_manager')
    changeRole() : void {
        if( this.is_chatting_room_manager == false ) {
            this.is_setting_open = false;
        }
    }

    /**
     * 유저네임 반환
     */
    getUserName(user : any) : string {

        if( isNaN(Number(user.user_id)) == false ) {
            const friend = this.getFriend(Number(user.user_id));

            if( friend != null ) {
                return this.getFriendName(friend);       
            }
        }

        return user.user_name;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#chat-user-list-scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#chat-image-list-scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#chat-file-list-scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
