
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함

/**
 * Component 선언 및 extends Mixins(Vue)
 */
@Component({
    components: {
        
    },
})
export default class TitleBox extends Mixins(Vue) {

    @Prop() title !: string;
    @Prop() search_enable !: boolean;
    @Prop() back_disable !: boolean;
    @Prop({ default : false }) manual_disable !: boolean;

    mounted() : void {

    }

    movePrevPage() : void {
        this.$router.go(-1);
    }

    searchOn() : void {
        this.$emit('searchOn', true);
    }

}
