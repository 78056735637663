
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, EVENT_SUB_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import moment from 'moment';

import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';
import { t_event_json } from '@/model/event';
import { hodu_color } from '@/common/color';

@Component({
    components: {
        
    },
}) export default class AppointmentCreateModal extends Mixins(VueHoduCommon) {

    /**
     * 선택되서 들어온 진료과의 의사 리스트 반환
     */
    get computedDepartmentDoctor() : hodu_doc_object.doctor_info[] {

        let department_doctor : hodu_doc_object.doctor_info[] = []; 
        
        if( this.doctor_info != null ) {

            // 코드가 없다면 전체리스트를 가져온다
            if( this.appointment_create_modal_info.department_code == null || this.appointment_create_modal_info.department_code.length < 1 ) {
                return this.doctor_info;
            }

            department_doctor = department_doctor.concat(this.doctor_info.filter(item => item.department_code == this.appointment_create_modal_info.department_code));
        }

        return department_doctor;
    }

    /**
     * 선택된 날의 첫번째 날 부터 이번달 말까지 리스트 반환
     */
    get computedDayList() : Date[] {

        let current_month_day_list : Date[] = [];

        if( this.appointment_create_modal_info != null && this.appointment_create_modal_info.original_timetable != null && 
            this.hospital_setting_info         != null && this.selected_doctor != null ) {

            const target_date : Date = moment(this.appointment_create_modal_info.original_timetable).set('hour', 0)
                                                                                                    .set('minute', 0)
                                                                                                    .set('second', 0)
                                                                                                    .set('millisecond', 0).toDate();
            const first_day : Date = new Date(target_date);
            first_day.setDate(1);

            const today : Date = moment().set('hour', 0)
                                        .set('minute', 0)
                                        .set('second', 0)
                                        .set('millisecond', 0).toDate();

            // 해당 월의 첫날이 오늘보다 옛날이라면 오늘을 기준으로 만든다
            if( first_day.getTime() < today.getTime() ) {
                target_date.setDate(today.getDate());
            }

            // 오늘 이후의 날짜였다면 1일부터 만든다
            else {
                target_date.setDate(1);
            }

            const final : Date = new Date();
            final.setMonth(final.getMonth() + 1);
            final.setDate(0);

            for( let i = target_date.getDate(); i <= final.getDate(); i++) {
                const target : Date = new Date(target_date);
                target.setDate(target.getDate() + i - target.getDate()); 

                // 해당 날짜에 병원이 OFF인 경우, 의사가 OFF인 경우는 제외시켜야함
                // 병원 정보
                const settings = this.hospital_setting_info.filter(item => new Date(item.start_date).getTime() <= target.getTime() &&
                                                                           new Date(item.end_date).getTime()   >= target.getTime() &&
                                                                           Number(item.day_of_week)            == target.getDay() );
                                                                           
                if( settings.length < 1 ) { continue; }

                // 오전, 오후 둘 다 휴무인 날은 리스트에 추가시키지 않고 넘어간다
                if( settings[0].start_am_time == null && settings[0].start_pm_time == null ) { continue; }

                // 의사 정보
                let work_code : hodu_doc_enum.doctor_work_code = hodu_doc_enum.doctor_work_code.OFF;
                switch(target.getDay()) {
                    case 0:
                        work_code = this.selected_doctor.sunday;
                        break;

                    case 1:
                        work_code = this.selected_doctor.monday;
                        break;

                    case 2:
                        work_code = this.selected_doctor.tuesday;
                        break;

                    case 3:
                        work_code = this.selected_doctor.wednesday;
                        break;

                    case 4:
                        work_code = this.selected_doctor.thursday;
                        break;

                    case 5:
                        work_code = this.selected_doctor.friday;
                        break;

                    case 6:
                        work_code = this.selected_doctor.saturday;
                        break;

                }

                // 의사가 해당 요일에 OFF 인 경우 날짜 리스트에 추가시키지 않는다
                if( work_code == hodu_doc_enum.doctor_work_code.OFF ) { continue; }

                // 병원, 의사 정보를 조합해서 병원은 AM인데 의사는 PM인 경우와 같이 서로 반대라면 제외시킨다
                if( work_code == hodu_doc_enum.doctor_work_code.AM && settings[0].start_am_time == null || 
                    work_code == hodu_doc_enum.doctor_work_code.PM && settings[0].start_pm_time == null ) { continue; } 
                
                current_month_day_list.push(target);
            }
        }
        
        return current_month_day_list; 
    }

    /**
     * 검색 된 환자 리스트 반환
     */
    get computedPatientList() : hodu_doc_object.patient_info[] {

        let patient_list : hodu_doc_object.patient_info[] = [];

        if( this.patient_info != null ) {

            patient_list = this.patient_info.filter(item => this.hodu_string_includes(item.user_name, this.patient_query) == true);

        }

        return patient_list;
    }

    /**
     * 불가능한 시간(사람이 전부 예약한 경우는 제외)은 제외한 available_list를 보여준다
     */
    get computedAvailableList() : hodu_doc_object.appointment_available_time_info[] {
        return this.available_list.filter(item => item.available == true || ( item.available == false && item.coalesce == item.reservation_term_by_people ) );
    }

    /**
     * 선택된 시간의 예약 정보를 반환
     */
    get computedSelectedTimeAppointment() : hodu_doc_object.appointment_by_time[] { 
        
        const key = this.hodu_date_to_format_string(this.selected_date, 'HH:mm');

        const time_list : hodu_doc_object.appointment_by_time_list[] = this.appointment_by_time_list.filter(item => item.date_key == key);
        
        if( time_list.length < 1 ) { return []; }

        return time_list[0].appointments;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State appointment_create_modal_info !: hodu_doc_modal_info.AppointmentCreateModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentCreateModalInfo ?: (params : hodu_doc_modal_info.AppointmentCreateModalInfo) => void;
    
    available_list : hodu_doc_object.appointment_available_time_info[] = [];
    appointment_by_time_list : hodu_doc_object.appointment_by_time_list[] = [];
    selected_doctor : hodu_doc_object.doctor_info | null = null;
    selected_date : Date = new Date();
    selecetd_date_temp : Date | null = null;
    selected_patient : hodu_doc_object.patient_info = {
        user_id : 0,
        user_name : "",
        user_phone_number : "",
        user_email : "",
        nok_count : 0,
        file_count : 0,
        checked : false,
    }
    memo : string = "";
    is_search_patient : boolean = true;
    patient_query : string = "";

    async mounted() : Promise<void> {
        // $(".patient_schBtn").click(function(){
        //     $(".findResult").toggleClass("on");
        // });

        this.is_search_patient = this.appointment_create_modal_info.is_create_one_patient == false;
    
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        // 의사 정보 및 선택한 시간 정보가 없을때 오류 처리
        if( this.doctor_info == null || this.doctor_info.length < 1 || this.appointment_create_modal_info.original_timetable == null ) {
            this.closeModal();
            this.hodu_show_dialog("cancel", "오류 발생\n잠시 후 다시 시도해주세요", ["확인"], [() => {}]);
            return;
        }

        // 선택해서 들어온 의사 및 시간으로 세팅
        this.selected_doctor = this.appointment_create_modal_info.is_create_one_patient == false 
            ? this.computedDepartmentDoctor.filter(item => item.doctor_code == this.appointment_create_modal_info.doctor_code)[0]
            : this.computedDepartmentDoctor[0];

        this.selected_date   = new Date(this.appointment_create_modal_info.original_timetable);

        if( this.appointment_create_modal_info.patient_info != null ) {
            this.selected_patient = JSON.parse(JSON.stringify(this.appointment_create_modal_info.patient_info));
        }

        // 예약 시간 정보 조회
        Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()])
            .then(() => { this.setScroll(); })
    }

    setScroll() : void {
        
        const modal_content_height : number | undefined = $('#appointment_create_modal .modal_content').outerHeight();
        
        const left_select_docs_height : number | undefined = $('#appointment_create_modal .modal_content .left_content .selectDocs').outerHeight();
        const left_select_div_height : number | undefined = $('#appointment_create_modal .modal_content .left_content .selectDiv').outerHeight();
        
        const main_content_find_div_hegiht : number | undefined = $('#appointment_create_modal .modal_content .main_content .findDiv').outerHeight();

        // @ts-ignore
        $('#modal_appointment_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : (modal_content_height ? modal_content_height : 0) - (left_select_docs_height ? left_select_docs_height : 0)
                                                                          - (left_select_div_height  ? left_select_div_height  : 0),
        });

        // @ts-ignore
        $('#patient_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : (modal_content_height ? modal_content_height : 0),
        });
    }

    /**
     * 해당 날짜의 예약 가능, 불가능 여부 정보 조회
     */
    async getAvailableAppointmentTime() : Promise<void> {

        if( this.selected_doctor == null ) { return; }
        
        const start = moment(this.selected_date).format('YYYY-MM-DD'); // 조회일
        const department_code = this.selected_doctor.department_code;  // 진료과 코드
        const doctor_code = this.selected_doctor.doctor_code;          // 의사 코드

        if( department_code == null || department_code.length < 1 || doctor_code == null || doctor_code.length < 1 ) { return; }

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/availability?start=${start}&department_code=${department_code}&doctor_code=${doctor_code}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                
                this.available_list.splice(0, this.available_list.length);
                this.available_list = this.available_list.concat(response.data.data);

                // 조회 결과가 없는 경우 throw?
                if( this.available_list.length < 1 ) {
                    return;
                }
                
                // 오늘 날짜인 경우 이미 시간이 지난 예약에 대해서 삭제한다
                const current_day : Date = new Date();
                if( this.selected_date.getFullYear() == current_day.getFullYear() &&
                    this.selected_date.getMonth()    == current_day.getMonth()    && 
                    this.selected_date.getDate()     == current_day.getDate() ) {

                    this.available_list = this.available_list.filter((item) => moment(item.original_timetable).toDate().getTime() >= new Date().getTime());
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true); 
            });
    }

    /**
     * 시간별 예약 리스트 조회
     */
    async getAppointmentByTime() : Promise<void> {
        
        if( this.selected_doctor == null ) { return; }

        const start = moment(this.selected_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format();      // 조회일
        const end   = moment(this.selected_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).utc().format(); // 조회일
        const department_code = this.selected_doctor.department_code; // 진료과 코드
        const doctor_code = this.selected_doctor.doctor_code;         // 의사 코드

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/appointmentByTime?start=${start}&end=${end}&department_code=${department_code}&doctor_code=${doctor_code}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                const appointment_by_time : hodu_doc_object.appointment_by_time[] = response.data.data;

                // 비우기
                this.appointment_by_time_list.splice(0, this.appointment_by_time_list.length);

                // 데이터 가공
                for( const appointment of appointment_by_time ) {
                    const key : string = this.hodu_date_to_format_string(appointment.start, 'HH:mm');

                    if( this.appointment_by_time_list.filter(item => item.date_key == key).length > 0 ) {
                        this.appointment_by_time_list.filter(item => item.date_key == key)[0].appointments.push(appointment);
                        continue;
                    }

                    this.appointment_by_time_list.push({
                        date_key : key,
                        appointments : [appointment]
                    })
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            });

    }

    /**
     * 예약 삭제
     */
    deleteEvent(event_id : string) : void {

        const calendar_id : string = this.calendar_id;
        const scope       : string = OWNER_TYPE.GROUP;
        const scope_id    : number = this.scope_group_id;
        
        const url : string = `api/v1/calendars/${ calendar_id }/events/${ scope }/${ scope_id }/${ event_id }?push_yn=false`;

        this.hodu_api_call(url, API_METHOD.DELETE)
            .then((response) => { 
                Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()])
                
                // 재조회 시키기
                if( this.appointment_create_modal_info.is_create_one_patient == false ) {
                    window['leftSelectAvailableList']();
                    window['rightSelectAvailableList']();
                }
            })
            .catch((e) => { 
                this.hodu_error_process(e, false, false, true); 
            });
    }

    /**
     * 의사 선택
     */
    selectDoctor(value : string) : void {

        const doc_list : hodu_doc_object.doctor_info[] = this.computedDepartmentDoctor.filter(item => item.doctor_code == value);

        if( doc_list.length < 1 ) { return; }

        this.selected_doctor = JSON.parse(JSON.stringify(doc_list[0]));
        Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()])
    }

    /**
     * 일자 선택
     */
    selectDate(date : number) : void {
        this.selecetd_date_temp = new Date(this.selected_date);
        this.selected_date = moment(this.selected_date).set('date', date).toDate();
        Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()])
            .then(() => {
                
                if( this.computedAvailableList.length < 1 && this.selecetd_date_temp != null ) {
                    alert("예약 할 수 있는 시간이 없습니다");
                    this.selected_date = new Date(this.selecetd_date_temp);
                    this.selecetd_date_temp = null;
                    Promise.all([this.getAvailableAppointmentTime(), this.getAppointmentByTime()]);
                }

            })
    }

    /**
     * 시간 선택
     */
    selectTime(hour : number, min : number) : void {
        this.selected_date = moment(this.selected_date).set('hour', hour).set('minute', min).toDate();
    }

    /**
     * 예약(일정) 삭제 권한 반환
     */
    getAppointmentDeletePermission() : boolean {
        return this.is_group_permmision(this.scope_group_id, "event", "delete");
    }

    /**
     * 검색 ON / OFF
     */
    searchOnOff() : void {
        this.is_search_patient = !this.is_search_patient;
    }

    /**
     * 환자 선택
     */
    setPatientInfo(patient : hodu_doc_object.patient_info) : void {
        this.is_search_patient = false;
        this.patient_query = "";

        this.selected_patient = JSON.parse(JSON.stringify(patient));
        this.$nextTick(() => { $('#patientMemo').focus(); });
    }
    
    /**
     * 저장 가능 여부 반환
     */
    isSaveable() : boolean {
        const list : hodu_doc_object.appointment_available_time_info[] = this.computedAvailableList.filter(item => new Date(item.original_timetable).getTime() == new Date(this.selected_date).getTime());

        if( list.length < 1 ) { return false; }

        return list[0].available;
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {

        if( this.doSetAppointmentCreateModalInfo == null ) { return; }
        
        this.doSetAppointmentCreateModalInfo({
            "show_modal" : false,
            "is_create_one_patient" : false,
        })
    }

    /**
     * 예약 등록
     */
    saveAppointment() : void {
        
        // 이름은 필수 조건이다
        if( this.selected_patient.user_name.length < 1 || this.selected_doctor == null || this.hospital_info == null ) { return; }

        const available_list = this.computedAvailableList.filter(item => new Date(item.original_timetable).getTime() == this.selected_date.getTime());

        if( available_list.length < 1 ) { return; }

        const calendar_id : string = this.calendar_id;
        const scope       : string = OWNER_TYPE.GROUP;
        const scope_id    : number = this.scope_group_id;

        const event_data : t_event_json = {
            version : "1",
            status : "",
            priority : 0,
            event_sub_type : EVENT_SUB_TYPE.APPOINTMENT,
            title : `${this.hospital_info.company_info.name} 예약 ${this.hodu_date_to_format_string(this.selected_date, 'HH:mm')}`,
            color : hodu_color.hodu_dc_7,
            schedule_date: {
                start : this.selected_date,
                end : moment(this.selected_date).add(available_list[0].reservation_term, 'minute').toDate(),
                isAllDay : false,
                lunar_yn : false,
                recurrence_end : moment(this.selected_date).add(available_list[0].reservation_term, 'minute').toDate(),
                isContinuos : false,
                isIgnore : false,
            },
            contacts : [],
            location : [],
            attachment : {
                imgs : [],
                files : [],
            },
            vote: [],
            attend : false,
            appointment : {
                biz_id : this.scope_group_team_option.biz_id,
                department_code : this.selected_doctor.department_code,
                doctor_code : this.selected_doctor.doctor_code,
                doctor_name : this.selected_doctor.doctor_name,
                patient_name : this.selected_patient.user_name,
                nok_name : this.user_name,
                nok_phone_number : this.selected_patient.user_phone_number,
                symptoms : this.memo,
                prescription : null,
                appointment_status : hodu_doc_enum.appointment_status_code.CONFIRM,
                appointment_decline_reason : null,
            },
            event_owner_name : this.selected_patient.user_name,
            event_owner_id : this.selected_patient.user_id,
            event_owner_group_id : this.scope_group_id,
            event_owner_group_name : this.scope_group_team_option.group_team_name,
            is_private : false,
        }

        // 예약 등록 API
        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}?event_type=${scope}`, API_METHOD.POST, event_data)
            .then((response) => {
                // 재조회 시키기
                if( this.appointment_create_modal_info.is_create_one_patient == false ) {
                    window['leftSelectAvailableList']();
                    window['rightSelectAvailableList']();
                }

                this.closeModal();
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            })
    }

}
