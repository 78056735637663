import { t_event_file } from "./event";

/**
 * Interface
 */
export namespace approval_interface {

    /**
     * 전자결재 기안서 
     */
    export interface t_approval {
        approval_uid : string;
        approval_rev : number;
        group_id : number;
        approval_type : approval_enum.APPROVAL_TYPE;
        approval_subtype_id : number;
        user_id : number;
        dept_id : number;
        pos_id : number;
        imp_seq : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL;
        // requested ?: Date | string;
        // expired ?: Date | string;
        receive_reference ?: t_approval_receive_reference[];
        contents : t_approval_contents;
        approval_state : string;
        approval_cur : number;
        approval_total : number;
        approval_document_num : string;
        approver : t_approval_approver[];
        audit_created ?: Date | string;
        audit_created_id ?: number;
        audit_modified ?: Date | string;
        audit_modify_id ?: number;
        audit_delete_flag ?: boolean;

        top_page_count ?: number;
        is_long_comment ?: boolean;
        line_count ?: number;
        is_copy ?: boolean;
        event_reply_id ?: string;
    }

    /**
     * 전자결재 기안서 내용
     */
    export interface t_approval_contents {
        title : string;
        comment : string;
        color : string;
        
        vacation_type_id ?: number;
        vacation_type_name ?: number;
        vacation_type_daycount ?: number;
        vacation_start ?: Date | string;
        vacation_end ?: Date | string;
        vacation_selected ?: string[];
        vacation_am_pm ?: string;
        vacation_start_time ?: string;
        vacation_end_time ?: string;

        // business_trip_type_id ?: number; // 1 : 종일, 2 : 오전, 3 : 오후
        // business_trip_type_name ?: string;
        // business_trip_type_daycount ?: number;
        // business_trip_selected ?: string[];
        // business_trip_am_pm ?: string;
        // business_trip_start_time ?: string;
        // business_trip_end_time ?: string;
        
        // retention_period ?: Date | string; // 문서자체의 보존기간 사용안함

        files : t_approval_contents_file[];

        transactions ?: t_approval_contents_transaction[];
        transaction_files ?: t_event_file[];
    }

    /**
     * 기안서 파일
     */
    export interface t_approval_contents_file {
        name: string;                     // 원본 파일명
        mimeType: string;                 // MIME TYPE
        url: string;                      // 파일 경로
        size: number;                     // 파일 크기
        date: Date | string;              // 저장 날짜
        hash: string;
        keep_year: number;

        // 임시 파일전용
        temp?: {                     // 서버에 임시로 저장된 파일 여부
            index?: number           // 다중 파일 업로드시 순서
            field_name?: string      // 파일 업로드시 필드 이름
            filepath: string;             // 임시 파일 경로
        };
        
        template_class_id : number;
        template_class_name : string;
        template_id : number;
        template_name : string;
    }

    export interface t_approval_contents_transaction {
        "subject" : string;
        "date" : Date;
        "price" : string;
        "account" : string;
        "remark" : string;
    }
    
    /**
     * 전자결재 양식 화면 VO
     */
    export interface ApprovalTemplateBundle {
        template_class_id : number;
        template_class_name : string;
        files : t_approval_template[];
        is_fold : boolean;
        is_selected : boolean;
        template_state : string;
    }

    /**
     * 전자결재 양식 분류
     */
    export interface t_approval_template_class {
        group_id : number;
        template_class_id : number;
        template_class_name : string;
        template_state : string;
        contents : any;
        audit_created ?: Date | string;
        audit_create_id ?: number;
        audit_modified ?: Date | string;
        audit_modify_id ?: number;
        audit_delete_flag ?: boolean;
    }

    /**
     * 전자결재 기안서 양식
     */
    export interface t_approval_template {
        group_id : number;
        template_class_id : number;
        template_id : number;
        template_name : string;
        contents : t_approval_template_contents;
        audit_created ?: Date | string;
        audit_create_id ?: number;
        audit_modified ?: Date | string;
        audit_modify_id ?: number;
        audit_delete_flag ?: boolean;
        approver ?: t_approval_approver[];
        self_approver ?: t_approval_approver[];
    }

    /**
     * 전자결재 기안서 양식 파일 정보
     */
    export interface t_approval_template_contents {
        file_url : string;
        file_name : string;
        file_size : number;
        mime_type : string;
        keep_year : number;
    }

    /**
     * 전자결재 기안서 수신참조 데이터
     */
    export interface t_approval_receive_reference {
        user_id : number;
        pos_name : string;
        dept_name : string;
        user_name : string;
        pos_id : number,
        dept_id : number,
        user_pic : string | null;
    }

    /**
     * 전자결재 기안서 상신자 + 승인자 데이터
     */
    export interface t_approval_approver {
        rev : number;
        seq : number;
        state : approval_enum.APPROVAL_APPROVER_STATE;
        date ?: Date | string;
        comment : string;
        user_id : number;
        pos_name : string;
        dept_name : string;
        user_name : string;
        pos_id : number,
        dept_id : number,
        user_pic : string | null;
    }

    /**
     * 전자결재 상신함, 수신함 검색 필터 인터페이스
     */
    export interface ApprovalFilter {
        search_query : string;
        start : Date;
        end : Date;
        is_emergency : boolean;
        is_importance : boolean;
        is_normal : boolean;
        importance_filter : number[];
        departments : any[];
        dept_ids : number[];
        is_selecet_all_departments : boolean;
        is_approval : boolean;
        is_vacation : boolean;
        is_businesstrip : boolean;
        is_holiday_work : boolean;
        is_cash_disbursement_voucher : boolean;
        is_free : boolean;
    }

}

/**
 * Modal Store Interface
 */
export namespace approval_modal_interface {

    /**
     * 전자결재 메시지 모달 (현재 사용 안함)
     */
    export interface ApprovalMessageModalInfo {
        show_modal : boolean;
    }

    /**
     * 전자결재 결재자 선택 모달
     */
    export interface ApproverSelectModalInfo {
        show_modal : boolean;
        is_approver : boolean;
        is_default_setting : boolean;
        is_self_approve : boolean;
        selected ?: any[];
        callback ?: Function; 
    }

    /**
     * 전자결재 결재자 처리 모달 
     */
    export interface ApprovalProcessModalInfo {
        show_modal : boolean;
        callback ?: Function;
    }

    /**
     * 전자결재 파일 없이 양식 추가 / 선택 모달
     */
     export interface ApprovalTemplateExceptFileModalInfo {
        show_modal : boolean;
        callback ?: Function;
    }

    /**
     * 전자결재 양식 추가 / 선택 모달
     */
    export interface ApprovalFileModalInfo {
        show_modal  : boolean;
        is_create  ?: boolean;
        template_class_id ?: number;
        callback   ?: Function;
    }

    /**
     * 전자결재 양식 수정 모달
     */
    export interface ApprovalFormModifyModalInfo {
        show_modal : boolean;
        template_class_id : number;
        template_id : number;
        callback ?: Function;
    }

    /**
     * 결재자 즐겨찾기 설정
     */
    export interface ApprovalApproverFavoriteModalInfo {
        show_modal : boolean;
        favorite_id ?: string;
        approver ?: approval_interface.t_approval_approver[];
        receive_reference ?: approval_interface.t_approval_receive_reference[];
        callback ?: Function;
    }

    export interface ApprovalReplyModalInfo {
        show_modal : boolean;
        group_id : number;
        approval_uid : string;
        callback ?: Function;
    }

}

/**
 * Enum
 */
export namespace approval_enum {

    /**
     * 기안서 상태
     */
    export enum APPROVAL_STATE {
        ING = "ING",
        CANCEL = "CANCEL",
        COMPLETE = "COMPLETE",
        REJECT = "REJECT",
        TEMP = "TEMP",
    }

    /**
     * 기안자, 결재자 상태
     */
    export enum APPROVAL_APPROVER_STATE {
        REQUEST = "REQUEST", // 기안자
        CANCEL = "CANCEL",   // 기안자
        WAIT = "WAIT",       // 결재자 대기 상태
        ING = "ING",         // 결재자 차례
        CONFIRM = "CONFIRM", // 결재자 승인
        REJECT = "REJECT",   // 결재자 반려
    }


    /**
     * 전자결재 탭
     */
    export enum APPROVAL_BOX_TAB {
        DRAFT = "DRAFT",
        RECEIVE = "RECEIVE",
        FORM_DOWNLOAD = "FORM_DOWNLOAD",
        FORM_SETTING = "FORM_SETTING",
        APPROVER_FAVORITE = "APPROVER_FAVORITE"
    }

    /**
     * 전자결재 상신함 탭
     */
    export enum APPROVAL_DRAFT_BOX_TAB {
        ALL = "ALL",
        ING = "ING",
        COMPLETE = "COMPLETE",
        REJECT = "REJECT",
        TEMP = "TEMP",
        CANCEL = "CANCEL",
    }
    
    /**
     * 전자결재 수신함 탭
     */
    export enum APPROVAL_RECEIVE_BOX_TAB {
        WAIT = "WAIT",
        REQUEST = "REQUEST",
        HISTORY = "HISTORY",
        REFERENCE = "REFERENCE"
    }

    /**
     * 전자결재 중요도
     */
    export enum APPROVAL_IMPORTANCE_FILTER {
        EMERGENCY = 10,
        IMPORTANCE = 20,
        NORMAL = 30,
    }

    /**
     * 전자결재 상신함 정렬 타입
     */
    export enum APPROVAL_DRAFT_OR_RECEIVE_SORT_TYPE {
        TITLE = "TITLE",              // 제목
        TIME = "TIME",                // 날짜
        STATUS = "STATUS",            // 현황 progress 진행도
        DRAFTER = "DRAFTER",          // 기안자
        ORGANIZATION = "ORGANIZATION" // 부서
    }

    /**
     * 기안서 타입
     */
    export enum APPROVAL_TYPE {
        BASIC = "BASIC",
        VACATION = "VACATION",
        BUSINESSTRIP = "BUSINESSTRIP",
        HOLIDAY_WORK = "HOLIDAY_WORK",
        CASH_DISBURSEMENT_VOUCHER = "CASH_DISBURSEMENT_VOUCHER",
        FREE = "FREE",
    }

    /**
     * 양식 설정 기본 결재자, 수신참조 탭
     */
    export enum APPROVAL_DEFAULT_TAB {
        APPROVER = "APPROVER",
        RECEIVER = "RECEIVER"
    }
}