
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { GROUP_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupModalInfo, GroupListConfirmModalData } from '@/store/modules/ModalInfo';
const GroupInfo  = namespace('GroupInfo');
const ModalInfo  = namespace('ModalInfo');
const dateFormat = require('dateformat');

import GroupListConfirmModal from '@/components/GroupListConfirmModal.vue';
import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component ({
    components : {
        GroupListConfirmModal, ResizeObserver
    }
})
export default class GroupList extends Mixins(VueHoduCommon) {
	/**
	 * GroupInfo.State
	 */
	@GroupInfo.State list_type !: number;

	/**
	 * ModalInfo.State
	 */
	@ModalInfo.State group_modal_info !: GroupModalInfo;
	@ModalInfo.Action doSetHomeResidentJoinModalInfo ?: (params : hodu_home_modal_info.HomeResidentJoinModalInfo) => void;
	
	/**
	 * ModalInfo.Action
	 */
	@ModalInfo.Action doSetGroupModalInfo ?: any;
	
	/**
	 * GroupInfo.Action
	 */
	@GroupInfo.Action doGroupListType ?: any;

    /**
     * computed
     */
    get computedGroupList() : any[] {

        let group_array : any[] = JSON.parse(JSON.stringify(this.group_list_data));
		
		// 키즈가 아닌것 || 키즈이면서 가입 된 것
		group_array = group_array.filter(item => item.group_info.project_type != 'kids' || (item.group_info.project_type == 'kids' && item.appr_state == 'A'));

        // 미니 리스트일때는 정렬 조건에 따라서 보여준다
        if( this.group_sort_target != null ) {
            group_array.sort((obj1 : any, obj2 : any) : number => {
                // 이름 오름차순
                if( this.group_sort_target == "NAME" && this.group_sort_direction == "ASC" ) {
                    if( obj1.group_info.group_name  > obj2.group_info.group_name ) { return  1; } 
                    if( obj1.group_info.group_name  < obj2.group_info.group_name ) { return -1; } 
                    if( obj1.group_info.group_name == obj2.group_info.group_name ) { return  0; }
                }

                // 이름 내림차순
                if( this.group_sort_target == "NAME" && this.group_sort_direction == "DESC" ) {
                    if( obj1.group_info.group_name  > obj2.group_info.group_name ) { return -1; } 
                    if( obj1.group_info.group_name  < obj2.group_info.group_name ) { return  1; } 
                    if( obj1.group_info.group_name == obj2.group_info.group_name ) { return  0; }
                }

                return 0;
            })
        } 

        // 전체 리스트 조회일땐 프리미엄을 먼저 보여준다!
        if( this.list_type == 9 ) {
            return group_array.sort((obj1, obj2) : number => {
                if( obj1.biz_id != null && obj1.biz_id.length > 0 && (obj2.biz_id == null || obj2.biz_id.length < 1) ) {
                    return -1;
                } 
                
                else if( (obj1.biz_id == null || obj1.biz_id.length < 1) && obj2.biz_id != null && obj2.biz_id.length > 0 ) {
                    return 1;
                }

                else {
                    return 0;
                }

            });
        }

        return group_array;
    }

	/**
	 * 변수 선언부
	 */
    group_list_data : any[] = [];
    group_sort_target : string | null = null;
	group_sort_direction : string = "ASC";
	
	is_list : boolean = false;

	dummy_image = "@/assets/images/footer/g1.jpg";

	async beforeMount() {
		await this.reset_biz_initialization();
		await this.reset_group_initialization();
	}

    async mounted() {
		const vue = this;

		if ( !this.list_type ) {
			this.doGroupListType(1);
		}

		// 검색 엔터키
		$('#group_search').keydown(function(key) {
			if ( key.keyCode == 13 ) {
				vue.getGroupList();
			}
		});

		// 검색 버튼 클릭시 searchDiv 띄우기
        $("#section_ce .schBtn").click(function(){
            $("#section_ce .searchDiv").addClass("on");
			vue.$nextTick(() => $('#group_search').focus());
        });

        // 검색 닫기 버튼 클릭시 searchDiv 없애기
        $("#section_ce .searchDiv .closeBtn").click(function(){
			$("#section_ce .searchDiv").removeClass("on");
			$('#group_search').val("");
			vue.getGroupList();
		});
		
        this.setScroll();
        
        /* 화살표 클릭시 팀 보여주기 */
        $(".selectTitle").click(function(){
            $(this).siblings().toggleClass("on");
        });

        // 소팅헤더 클릭시ㅣ 드랍다운 보이기
		$(".sortHeader > li > a").click(function(){
			$(this).siblings('.dropdown').toggleClass("on");
        });
        
        // 오른쪽 디스플레이 버튼
		$(".btns .dpTwo").click(function(){
			$(".section_ce_fix").removeClass("dpOne");
			$(".section_ce_fix").addClass("dpTwo");
			vue.is_list = true;
		});
		$(".btns .dpOne").click(function(){
			$(".section_ce_fix").removeClass("dpTwo");
            $(".section_ce_fix").addClass("dpOne");
			vue.group_sort_target = null;
			vue.is_list = false;
		});

        this.getGroupList();

        window["getGroupList"]           = this.getGroupList;
        window["groupListConfirmCancel"] = this.groupListConfirmCancel;
        window["groupListConfirm"]       = this.groupListConfirm;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();

        // @ts-ignore
        $('#grpScroll').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight ),
            scrollbarPosition : 'outside',
        });

        // @ts-ignore
        $(".content").mCustomScrollbar({
			mouseWheelPixels: 500
        });
    }

    /**
     * 모달창 아니오
     */
    groupListConfirmCancel() : void {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 모달창 예
     */
    groupListConfirm(po_data) : void {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;

        // 가입
        if ( po_data.user_id == 0 || po_data.appr_state == "" ) {
			this.hodu_api_call(`api/v1/groups/groupMemberInsert/${po_data.group_id}`, API_METHOD.POST, {is_appr_required: po_data.is_appr_required})
                .then(async(response) => {
                    this.getGroupList();
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        // 승인 취소
        if ( po_data.user_id > 0 && (po_data.appr_state == "R" || po_data.appr_state == "E" || po_data.appr_state == "T") ) {
			this.hodu_api_call(`api/v1/groups/groupMemberDelete/${po_data.group_id}/${this.user_id}`, API_METHOD.DELETE, null)
                .then(async(response) => {
                    this.getGroupList();
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 모달 OPEN
     */
    openModal(group) : void {

		if( group.group_type == 'BIZH' ) {
			this.homeJoin(group);
			return;
		}

        let param_data : GroupListConfirmModalData = {
            modal_type       : "join_cancel",
            type             : "GROUP",
            group_id         : group.group_id,
            team_id          : 0,
            color            : group.user_group_color,
            image            : group.group_info.image,
            description      : group.group_info.description,
            name             : group.group_info.group_name,
            user_id          : group.user_id,
            is_appr_required : group.group_policy.is_appr_required,
            appr_state       : group.appr_state,
            member_created   : group.group_member_created
        }

        this.group_modal_info.show_group_list_confirm = true;
        this.group_modal_info.data = param_data;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 그룹 조회
     */
    async getGroupList() : Promise<void> {
        const vue = this;

        this.group_list_data.splice(0, this.group_list_data.length);

        // 파라미터 변수
        let   search            = String($('#group_search').val());
        const sort              = "CA";   // CA : 등록일 오름차순, CD : 등록일 내림차순, NA : 그룹명 오름차순, ND : 그룹명 내림차순
		const favorite_group_id = [];
		
		if ( search.trim().length < 1 ) {
			search = "";
		}

		await this.hodu_api_call(`api/v1/groups?list_type=${this.list_type}&search=${search}&sort=${sort}&favorite_group_id=${favorite_group_id}`, API_METHOD.GET, null, false)
			.then(async(response) => {

				await this.get_group_role_service();
				this.group_list_data.splice(0, this.group_list_data.length);
				this.group_list_data = this.group_list_data.concat(response.data.data.list);

				const group_count : number = this.group_list_data.length
				for( let i = group_count - 1; i >= 0; i-- ) {
					const data : any = JSON.parse(JSON.stringify(this.group_list_data[i]));

					if ( data.user_group_color == null || data.user_group_color.length < 1 ) {
						data.user_group_color = data.type == 'GROUP' ? data.group_info.group_color : data.group_info.team_color;
					}

					// BIZD 그룹 일 경우 그룹 리스트에서 보이지 않는다
					if( data.group_type == GROUP_TYPE.BIZD ) {
						this.group_list_data.splice(i, 1);
						continue; 

						// 해당 부분은 의료진은 볼수있도록 한 부분인데 HODU D는 전용 로그인 페이지로만 들어갈수있도록 바뀌어야하기에 숨기도록 변경
						// const role_array : any[] = this.user_group_role.filter(item => item.group_id == data.group_id);
						
						// if( role_array == null || role_array.length < 1 ) {
						// 	this.group_list_data.splice(i, 1); 
						// 	continue; 
						// }

						// if( role_array[0].group_role == this.GroupAuth.GROUP_USER ) {
						// 	this.group_list_data.splice(i, 1);
						// 	continue;
						// }

					}

					data.user_group_color = await vue.hodu_hex_color_process(data.user_group_color);
					data.group_member_created = dateFormat(data.group_member_created, 'yyyy.mm.dd');
					vue.group_list_data.splice(i, 1, data);
				}

			})
			.catch(async(e) => {
				this.hodu_error_process(e, true, false);
			});
    }

    /**
     * 그룹이동
     */
    groupDetailGo(data) : void {

		console.log(data);

		// 인증 안된 상태인데 호두홈, 호두키즈로 이동한다면 인증절차
        if( (this.auth_info == null || this.auth_info.auth_check == false) && (data.group_type == 'BIZH' || data.group_info.project_type == 'kids') ) {
            try {
                let popup : Window | null = null;

                // window['skip_button_select'] = false;

                window['auth_callback'] = (user_info) => {
                    try {
						this.doSetAuthInfo(user_info.auth_info);
						this.doSetUserPhoneNumber(user_info.user_phone_number);
						this.doSetCountryCode(user_info.country_code);
						this.groupDetailGo(data);

                        // console.log(authResult);
                        // this.firebase_auth(authResult, auth_check_later, (user_info) => {
                        //     try {
                        //         console.log(user_info);
                        //         this.doSetAuthInfo(user_info.auth_info);
                        //         this.doSetUserPhoneNumber(user_info.user_phone_number);
                        //         this.doSetCountryCode(user_info.country_code);

                        //         if( auth_check_later == true ) {
                        //             return;
                        //         }

                        //         this.groupDetailGo(data);
                        //     } catch(e) {
                        //         this.hodu_error_process(e, false, false, true);
                        //     }
                        // });
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    } finally {
                        popup?.close();
                    }
                }

                const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                const systemZoom = width / window.screen.availWidth;

                const w = 500;
                const h = 626;
                const l = (width - w) / 2 / systemZoom + dualScreenLeft;
                const t = (height - h) / 2 / systemZoom + dualScreenTop;

                popup = window.open(`/naver-auth.html?device_uid=${this.get_device_uid()}&session_token=${this.get_session_token()}`, `naver-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
            return;
        }

        this.doGroupStatisticsStartDate("");
		this.doGroupId(data.group_id);
        this.doTeamId(data.team_id);
        this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
    }

    /**
     * 그룹생성
     */
    makeGroup() : void {
        this.reset_group_initialization();
        this.hodu_router_push(`/group/${new Date().getTime()}/create`);
	}
	
	/**
	 * 탭 변경
	 */
	listTypeChange(val) : void {
		this.doGroupListType(val);
		this.getGroupList();
		$(".selectTitle").siblings().toggleClass("on");
    }
    
    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#grpScroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $(".content").mCustomScrollbar('destroy');

    	this.setScroll();
	}
	
	/**
	 * 즐겨찾기 변경
	 */
	async group_favorite(po_event, po_data) : Promise<void> {

		if( po_data.type == 'GROUP' ) {
			this.hodu_api_call(`api/v1/groupFavorite/${po_data.group_id}`, API_METHOD.POST, {}, false)
				.then(async(response) => {
					// // 즐겨찾기 에서는 재조회
					// if ( this.list_type == 3 ) {
					// 	this.getGroupList();
					// }
					// else {
					// 	po_data.favorite = (po_data.favorite > 0 ? 0 : 1);
					// }
					this.getGroupList();
				})
				.catch(async(e) => {
					this.hodu_error_process(e, true, false);
				});
		}
		else {
			try {
				const response = await this.hodu_api_call(`api/v1/teams/favorite/${po_data.group_id}/${po_data.team_id}`, API_METHOD.POST, {}, false);

				console.log(response);

				if( !response || !this.isHttpStatusSuccess(response.status) ) {
					throw new Error("팀 즐겨찾기 변경 중 오류 발생");
				}

			} catch(e) {
				this.hodu_error_process(e, false, false, true);
				this.hodu_show_dialog('cancel', "즐겨찾기 변경 중 오류 발생", ['확인']);
			} finally {
				this.getGroupList();
			}
		}

		
    }

    /**
     * 미니 리스트에서 그룹 이름으로 sort
     */
    groupNameSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.group_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.group_sort_direction ) {
                case "ASC":
                    this.group_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.group_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.group_sort_target = "NAME";
            this.group_sort_direction = "ASC";
        }
        
	}

	/**
	 * 그룹 li span.img 의 이미지 URL
	 */
	getGroupImageUrlStyle(data : any) : string {
		if( !data.group_info.image ) { return ''; }

		return `backgroundImage : url('app_images/${data.group_info.image}')`;
	}
	
	/**
	 * 그룹 이미지 에러
	 */
	groupImageError(event : any, data : any) : void {
		$(event.target).parent().find('span.img').removeAttr("style");
		$(event.target).parent().find('span.img').addClass(`img${this.group_color_to_class_name(data.user_group_color)}`);
	}

	/**
	 * 알람 ON, OFF 여부 반환 
	 */
	isAlarmOff(data) : boolean {
		const group_id = data.group_id;
		const team_id = data.team_id;

		if( this.user_preference == null ) return false;
		
		for( const preference of this.user_preference ) {
			if( data.type == 'GROUP' && preference.cate != 'group_alarm' ) continue;
			if( data.type == 'TEAM' && preference.cate != 'team_alarm' ) continue;
			if( preference.preference == null || preference.preference[`${data.type == 'GROUP' ? group_id : team_id }`] == null ) return false;
			return preference.preference[`${data.type == 'GROUP' ? group_id : team_id }`] == 'false';
		}

		return false;
	}

	/**
	 * 병원 리스트로 이동
	 */
	moveHospitalList() : void {
		this.hodu_router_push("/hospital");
	}

	/**
	 * 이미지 로드
	 */
	// onLoadDummyImage(event) : void {
	// 	console.log("이미지 로드");
	// 	console.log(event);
	// 	$(event.target).parent().find('span.img').css('background-image', `url('${$(event.target)[0].currentSrc}')`)
	// }

	homeJoin(group) : void {

		// 인증 안된 상태인데 호두홈, 호두키즈로 이동한다면 인증절차
        if( (this.auth_info == null || this.auth_info.auth_check == false) && (group.group_type == 'BIZH' || group.group_info.project_type == 'kids') ) {
            try {
                let popup : Window | null = null;

                window['skip_button_select'] = false;

                window['auth_callback'] = (user_info) => {
                    try {
						this.doSetAuthInfo(user_info.auth_info);
						this.doSetUserPhoneNumber(user_info.user_phone_number);
						this.doSetCountryCode(user_info.country_code);

						this.doSetHomeResidentJoinModalInfo?.({
							show_modal : true,
							group_id : group.group_id,
							is_invite : false,
						});

                        // console.log(authResult);
                        // this.firebase_auth(authResult, auth_check_later, (user_info) => {
                        //     try {
                        //         console.log(user_info);
                        //         this.doSetAuthInfo(user_info.auth_info);
                        //         this.doSetUserPhoneNumber(user_info.user_phone_number);
                        //         this.doSetCountryCode(user_info.country_code);

                        //         if( auth_check_later == true ) {
                        //             return;
                        //         }

                        //         this.doSetHomeResidentJoinModalInfo?.({
						// 			show_modal : true,
						// 			group_id : group.group_id,
						// 			is_invite : false,
						// 		    });

                        //     } catch(e) {
                        //         this.hodu_error_process(e, false, false, true);
                        //     }
                        // });
						
                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    } finally {
                        popup?.close();
                    }
                }

                const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                const systemZoom = width / window.screen.availWidth;

                const w = 500;
                const h = 626;
                const l = (width - w) / 2 / systemZoom + dualScreenLeft;
                const t = (height - h) / 2 / systemZoom + dualScreenTop;

                popup = window.open(`/naver-auth.html?device_uid=${this.get_device_uid()}&session_token=${this.get_session_token()}`, `naver-auth`, `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
            return;
        }

		this.doSetHomeResidentJoinModalInfo?.({
			show_modal : true,
			group_id : group.group_id,
			is_invite : false
		});
	}

}
