
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const MenuInfo = namespace('MenuInfo');
const TodoInfo = namespace('TodoInfo');

import { t_todo } from '@/model/osm'; 

const dateFormat = require('dateformat');

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
}) export default class RightTodoList extends Mixins(VueHoduCommon) {

    /**
     * @MenuInfo.Action
     */
    @MenuInfo.Action doSetTodoMenuOpen    ?: any;
    @MenuInfo.Action doTodoCreateOrUpdate ?: any;

    /**
     * @TodoInfo.State
     */
    @TodoInfo.State  todoSelectFlag       !: boolean;
    
    /**
     * @TodoInfo.Action
     */
    @TodoInfo.Action doSetTodo            ?: any;
    @TodoInfo.Action doSetTodoSelectFlag  ?: any;
    
    view_all : boolean = true;
    view_ing : boolean = false;
    view_end : boolean = false;

    all_todo_count : number = 0;
    ing_todo_count : number = 0;
    end_todo_count : number = 0;

    todo_list : t_todo[] = [];
    target_list : t_todo[] = [];

    is_multi_delete_mode : boolean = false;
    multi_select_list : number[] = [];

    async mounted() : Promise<void> {

        // 상단 삭제 버튼 클릭시 .notice 디브에 edit 추가
        // $(".toDoBtnEdit").click(function(){
        //     $(".notice").addClass("edit");

        // });

        // .notice 디브에 edit 추가 시 취소버튼
        $(".toDoBtnEditCancel").click(function(){
            $(".notice").removeClass("edit");

        });

        // .notice.edit에서 삭제 체크박스 클릭시 리스트에 delOn 추가
        $(".notice .title").click(function(){
            $(this).parent().parent().parent().toggleClass("delOn");
        });

        

        this.setScroll();
        await this.getTodoList();

        if( this.ing_todo_count > 0 ) {
            this.view_all = false;
            this.view_ing = true;
            this.target_list = this.todo_list.filter((todo) => todo.todo_info.is_end == false );
        }

        window['getTodoList'] = this.getTodoList;
    }

    beforeDestroy() {
        window['getTodoList'] = null;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.noti_titlebox.mToDo').outerHeight();
        const tab_height : number | undefined = $('.notiWrap .tabs').outerHeight();

        // @ts-ignore
        $('#todoListScroll').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ) - ( tab_height == null || this.is_multi_delete_mode == true ? 0 : tab_height),
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 개인 할 일 조회
     */
    async getTodoList() : Promise<void> {
        await this.hodu_api_call(`api/v1/todo`, API_METHOD.GET)
            .then(async(response) => {
                // console.log(JSON.stringify(response));
                this.todo_list = response.data.data.todo_list;

                if( this.view_all == true ) {
                    this.target_list = this.todo_list;
                }

                if( this.view_ing == true ) {
                    this.target_list = this.todo_list.filter((todo) => todo.todo_info.is_end == false );
                }

                if( this.view_end == true ) {
                    this.target_list = this.todo_list.filter((todo) => todo.todo_info.is_end == true );
                }
                
                // 정렬
                await this.todoListSort();

                // 카운트 구하기 (전체, 진행 중, 종료)
                this.all_todo_count = this.todo_list.length;
                this.ing_todo_count = 0;
                this.end_todo_count = 0;

                for( let i = 0; i < this.all_todo_count; i++ ){
                    if( this.todo_list[i].todo_info.is_end == true) {
                        this.end_todo_count++;
                    } else {
                        this.ing_todo_count++;
                    }
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
        
    }

    /**
     * 할일 check on/off
     */
    todoClick(todo : t_todo) : void {

        // is_end를 반대로 바꿔줘야 이제 되야 할 상태를 나타냄
        const clone_todo : t_todo = JSON.parse(JSON.stringify(todo));
        clone_todo.todo_info.is_end = !clone_todo.todo_info.is_end;

        this.hodu_api_call(`api/v1/todo/${clone_todo.todo_id}`, API_METHOD.PUT, clone_todo, false)
            .then((response) => {

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 할 일 todo_list 정렬
     */
    async todoListSort() : Promise<void> {
        await this.todo_list.sort((a : t_todo, b : t_todo) => {
                
            // 한쪽은 종료되고 한쪽은 진행 중일때
            if( a.todo_info.is_end != b.todo_info.is_end ){
                
                // 왼쪽이 종료 된 경우
                if( a.todo_info.is_end == true ){
                    return 1;

                // 오른쪽이 종료 된 경우
                } else {
                    return -1;
                }

            // 둘다 종료 되었거나 둘다 진행중일때
            } else {
                // 시작일 오름차순 기준, 다른 정렬 기준 추가 되면 더 추가 해야함
                const aStartTimeMillis : number = new Date(a.todo_info.start).getTime();
                const bStartTimeMillis : number = new Date(b.todo_info.start).getTime();

                return aStartTimeMillis < bStartTimeMillis ? -1 : (aStartTimeMillis == bStartTimeMillis ? 0 : 1);
                
            }
        });
    }

    /**
     * 시작일 종료일에 맞는 포맷으로 string 반환
     */
    getDateByFormat(date : Date) : string {
        return dateFormat(date, 'yyyy.mm.dd');
    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    allView() : void {
        if( this.is_multi_delete_mode == true ) { return; }

        this.view_all = true;
        this.view_ing = false;
        this.view_end = false;

        this.getTodoList();
    }

    ingView() : void {
        if( this.ing_todo_count < 1 || this.is_multi_delete_mode == true ) { return; }

        this.view_all = false;
        this.view_ing = true;
        this.view_end = false;

        this.getTodoList();
    }

    endView() : void {
        if( this.end_todo_count < 1 || this.is_multi_delete_mode == true ) { return; }

        this.view_all = false;
        this.view_ing = false;
        this.view_end = true;

        this.getTodoList();
    }

    /**
     * more 버튼 클릭
     */
    moreButtonClick(todo : t_todo) : void {
        const todo_index : number = this.target_list.indexOf(todo); 
        todo.todo_info.more_on = true; 

        if( todo_index > -1 ){
            this.target_list.splice(todo_index, 1, todo);
        }
    }

    /**
     * li에서 마우스가 떠났을때의 이벤트
     */
    todoMouseLeave(todo : t_todo) : void {
        if( todo.todo_info.more_on == false ){
            return;
        }

        const todo_index : number = this.target_list.indexOf(todo); 
        todo.todo_info.more_on = false; 

        if( todo_index > -1 ){
            this.target_list.splice(todo_index, 1, todo);
        }
    }

    /**
     * 개인 할 일 작성버튼 클릭
     */
    createTodo() : void {
        this.doSetTodo({
            todo_id : "",          
            user_id : 0,        
            last_update_tag : "",  
            audit_modified : new Date(),  
            audit_delete_flag : false,
            todo_info : {
                summary : "",
                start : new Date(),
                end : null,
                is_end : false
            }        
        });
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(true);
    }

    /**
     * 수정 버튼 클릭
     */
    modifyTodo(todo : t_todo) : void {
        todo.todo_info.more_on = false;
        this.doSetTodo(JSON.parse(JSON.stringify(todo)));
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(true);
    }

    /**
     * 삭제 버튼 클릭
     */
    deleteButtonClick(todo : t_todo) : void {
        const todo_index : number = this.target_list.indexOf(todo); 
        todo.todo_info.deleteButtonClicked = true; 

        if( todo_index > -1 ){
            this.target_list.splice(todo_index, 1, todo);
        }
    }

    /**
     * 개인 할 일 삭제
     */
    todoDelete(index : number) : void {

        this.hodu_api_call(`api/v1/todo/${this.target_list[index].todo_id}`, API_METHOD.DELETE)
            .then((response) => {
                this.target_list.splice(index, 1);
                this.getTodoList();
            })
            .catch((e) =>{
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 삭제 확인 창에서 아니오 버튼 클릭
     */
    todoDeleteRefuse(todo : t_todo) : void {
        const todo_index : number = this.target_list.indexOf(todo); 
        todo.todo_info.deleteButtonClicked = false; 

        if( todo_index > -1 ){
            this.target_list.splice(todo_index, 1, todo);
        }
    }

    @Watch('todo_list', { immediate : false, deep : true })
    changeCount() : void {
        // 카운트 구하기 (전체, 진행 중, 종료)
        this.all_todo_count = this.todo_list.length;
        this.ing_todo_count = 0;
        this.end_todo_count = 0;

        for( let i = 0; i < this.all_todo_count; i++ ){
            if( this.todo_list[i].todo_info.is_end == true) {
                this.end_todo_count++;
            } else {
                this.ing_todo_count++;
            }
        }
    }

    @Watch('todoSelectFlag')
    reviewTodoList() : void {

        if( this.todoSelectFlag == false ){
            return;
        }

        this.getTodoList();
        this.doSetTodoSelectFlag(false);
    }

    /**
     * expired된 todo인지 구한다
     */
    isExpired(todo : t_todo) : boolean {
        return todo.todo_info.is_end == false && todo.todo_info.end != null && (new Date().getTime() >= new Date(moment(todo.todo_info.end).format()).getTime());
    }

    /**
     * Summary 가공 후 반환
     */
    getSummary(summary : string) : string {
        let return_summary : string = '<p style="min-height : 13px;">';
        return_summary += summary.replace(/\n/g, '</p><p style="min-height : 13px;">');
        return_summary += '</p>';

        return return_summary;
    }

    /**
     * 다중 삭제 모드
     */
    multiDeleteMode() : void {
        this.is_multi_delete_mode = true;
        this.$nextTick(() => { this.handleResize(); });
    }

    /**
     * 리스트 모드
     */
    listMode() : void {
        this.is_multi_delete_mode = false;
        this.multi_select_list.splice(0, this.multi_select_list.length);
        this.$nextTick(() => { this.handleResize(); });
    }

    /**
     * 할 일 다중 삭제 리스트 선택 ON / OFF
     */
    changeMultiSelect(event : any, index : number) : void {

        const multi_select_list_index_of : number = this.multi_select_list.indexOf(index);

        // 선택한 할 일이 이미 리스트에 들어있지 않다면 추가 
        if ( event.target.checked == true && multi_select_list_index_of == -1 ) {
            this.multi_select_list.push(index);
        } 

        // 선택 취소한 할 일이 리스트에 들어있는 경우 제거
        if( event.target.checked == false && multi_select_list_index_of != -1 ) {
            this.multi_select_list.splice(multi_select_list_index_of, 1);
        }

    }

    /**
     * 할 일 다중 삭제
     */
    deleteAll() : void {
        if( this.multi_select_list.length < 1 ) { return; }
        
        this.hodu_show_indicator();

        const todo_ids : string[] = [];

        for( const index of this.multi_select_list ) { todo_ids.push(this.target_list[index].todo_id); }

        this.hodu_api_call("api/v1/todo/todos", API_METHOD.DELETE, { "todo_ids" : todo_ids })
            .then(async() => {
                this.target_list.splice(0, this.target_list.length);
                await this.getTodoList();
                this.listMode();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#todoListScroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }
}

