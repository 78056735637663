
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import KidsAttendanceDayGridMonth from '@/components/kids/KidsAttendanceDayGridMonth.vue';
import KidsAttendanceListMonth from '@/components/kids/KidsAttendanceListMonth.vue';
import KidsAttendanceListWeek from '@/components/kids/KidsAttendanceListWeek.vue';
import KidsAttendanceListDay from '@/components/kids/KidsAttendanceListDay.vue';
import KidsAttendanceMyList from '@/components/kids/KidsAttendanceMyList.vue';

import { attendance_enum } from '@/model/attendance';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        KidsAttendanceDayGridMonth, KidsAttendanceListMonth, KidsAttendanceListWeek, KidsAttendanceListDay, KidsAttendanceMyList,
        ResizeObserver
    },
})
export default class KidsAttendance extends Mixins(VueHoduCommon) {

    /**
     * @AttendanceInfo.State
     */
    @AttendanceInfo.State calendar_view_type !: attendance_enum.ATTENDANCE_CALENDAR_TYPE;

    /**
     * @AttendanceInfo.Action
     */
    @AttendanceInfo.Action doSetCalendarViewType ?: (params : attendance_enum.ATTENDANCE_CALENDAR_TYPE) => void;

    event_bus : Vue = new Vue();

    mounted() : void {
        
    }

    /**
     * 월 달력
     */
    isDayGridMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.DAYGRID_MONTH;
    }

    /**
     * 월별 리스트
     */
    isListMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_MONTH;
    }

    /**
     * 주별 리스트
     */
    isListWeek() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_WEEK;
    }

    /**
     * 일별 리스트
     */
    isListDay() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_DAY;
    }

    /**
     * 내 현황 달력
     */
    isMyCalendar() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.CALENDAR;
    }

    /**
     * 내 현황 리스트
     */
    isMyList() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST;
    }

    /**
     * 달력 변경
     */
    changeCalendar(calendar_type : attendance_enum.ATTENDANCE_CALENDAR_TYPE) : void {
        this.doSetCalendarViewType?.(calendar_type);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        if( this.isDayGridMonth() == true ) this.event_bus.$emit('daygridMonthResize');
        else if( this.isListDay() == true ) this.event_bus.$emit('listDayResize');
        else if( this.isListWeek() == true ) this.event_bus.$emit('listWeekResize');
        else if( this.isListMonth() == true ) this.event_bus.$emit('listMonthResize');
        else if( this.isMyCalendar() == true ) this.event_bus.$emit('myCalendarResize');
        else if( this.isMyList() == true ) this.event_bus.$emit('myListResize');
    }

}
