
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { FILE_SIZE, API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';
import { hodu_doc_object, hodu_doc_table } from '@/model/hodudoc';

import JSZip from 'jszip';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {

    },
})
export default class PatientFileBoxFileList extends Mixins(VueHoduCommon) {

    /**
     * 파일 정렬 리스트
     */
    get computedFileSortList() : hodu_doc_table.t_hospital_patient_file[] {
        
        let target_list = JSON.parse(JSON.stringify(this.file_list));

        target_list = target_list.sort((item1, item2) : number => {

            // 파일 이름 기준 정렬
            if( this.sort_type == 'NAME' ) {

                if( item1.file_name == item2.file_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_name < item2.file_name ? -1 : 1;
                }

                return item1.file_name > item2.file_name ? -1 : 1;

            }

            // 파일 용량 기준 정렬
            if( this.sort_type == 'SIZE' ) {

                if( item1.file_size == item2.file_size ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_size < item2.file_size ? -1 : 1;
                }

                return item1.file_size > item2.file_size ? -1 : 1;

            }

            // 파일 올린 날짜 기준 정렬
            if( this.sort_type == 'DATE' ) {

                if( new Date(item1.audit_created).getTime() == new Date(item2.audit_created).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item1.audit_created).getTime() < new Date(item2.audit_created).getTime() ? -1 : 1;
                }

                return new Date(item1.audit_created).getTime() > new Date(item2.audit_created).getTime() ? -1 : 1;

            }

            return 0;
        })

        return target_list;
    }

    @Prop() event_bus !: Vue;

    @Prop() selected_patient !: hodu_doc_object.patient_info | null;
    
    file_list : hodu_doc_table.t_hospital_patient_file[] = [];
    
    is_all_selected : boolean = false;

    // 정렬
    sort_type : 'NAME' | 'SIZE' | 'DATE' = 'DATE';
    sort_dir  : SORT_TYPE = SORT_TYPE.DESC;

    mounted() : void {

        
        if( this.event_bus != null ) { 
            this.event_bus.$on('fileListResize', this.handleResize);
            this.event_bus.$on('patientFileDelete', this.patientFileDelete);
            this.event_bus.$on('patientFileDownload', this.patientFileDownload);
            this.event_bus.$on('reselectFileList', this.getPatientsFileboxInfo); 
        }

        this.setScroll();

        // 리스트로 보이기
        // $(".btnBox .dpTwo").click(function(){
        //     $(".rightAreaDiv").addClass("dp2");
        //     $(".btnBox .dpOne").show();
        //     $(this).hide();

        // });

        // 박스형으로 보이기
        // $(".btnBox .dpOne").click(function(){
        //     $(".rightAreaDiv").removeClass("dp2");
        //     $(".btnBox .dpTwo").show();
        //     $(this).hide();

        // });
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        this.$nextTick(() => {
            const title_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

            // @ts-ignore
            $('#patient_filebox_file_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
            });
        });

    }

    /**
     * 환자 파일함 조회
     */
    getPatientsFileboxInfo() : void {

        if ( this.selected_patient == null ) { return; }

        const patient_id : number = this.selected_patient.user_id;

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${patient_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.file_list.splice(0, this.file_list.length)
                this.file_list = this.file_list.concat(response.data.data);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    
    /**
     * 파일 삭제 API
     */
    deletePatientsFileboxFile(files : hodu_doc_table.t_hospital_patient_file[]) : void {

        if( this.selected_patient == null ) { return; }

        const patient_id : number = this.selected_patient.user_id;

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/patient/file?patient_id=${patient_id}`, API_METHOD.DELETE, { "files" : files })
            .then((response) => {
                console.log(response);
                this.get_hodu_d_info(this.scope_group_team_option.biz_id);
                this.getPatientsFileboxInfo();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })

    }

    /**
     * 선택 된 모든 파일 삭제 클릭 이벤트
     */
    patientFileDelete() : void {
        
        const selected_files : hodu_doc_table.t_hospital_patient_file[] = this.computedFileSortList.filter(item => item.checked == true);

        if( selected_files.length < 1 ) {
            this.hodu_show_dialog('alert', '삭제 할 파일을 선택해주세요', ["확인"], [() => {}]);
            return;
        }

        this.hodu_show_dialog('cancel', `파일을 ${ this.is_all_selected ? '전부 ' : '' }삭제 하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => { this.deletePatientsFileboxFile(selected_files); }
        ]);
    }

    /**
     * 한개의 파일만 삭제 클릭 이벤트
     */
    deleteSingleFile(file : hodu_doc_table.t_hospital_patient_file) : void {

        this.hodu_show_dialog('cancel', `${file.file_name}\n파일을 삭제 하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => { this.deletePatientsFileboxFile([file]); }
        ]);

    }

    /**
     * 선택 된 모든 파일 다운로드 클릭 이벤트
     */
    async patientFileDownload() : Promise<void> {
        
        const selected_files : hodu_doc_table.t_hospital_patient_file[] = this.computedFileSortList.filter(item => item.checked == true);

        if( selected_files.length < 1 ) {
            this.hodu_show_dialog('alert', '다운로드 할 파일을 선택해주세요', ["확인"], [() => {}]);
            return;
        }

        // 파일 1개라면
        if( selected_files.length == 1 ) {
            this.downloadSingleFile(selected_files[0]);
            return;
        }

        // 파일 여러개는 zip 파일로 받는다

        await this.hodu_show_indicator();

        const zip = new JSZip();

        const file_promise : Promise<void>[] = [];

        for( const file of selected_files ) {

            // 파일 다운로드 Promise
            const promise = this.hodu_download(`/app_images/${file.file_path}`, "", true)
                .then((response) => {
                    console.log(response);

                    // 다운로드한 파일은 zip 파일 구성에 추가
                    zip.file(file.file_name, new Blob([response.data]));
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });

            // 병렬처리용 Promise 배열에 넣기
            file_promise.push(promise);
        }

        Promise.all(file_promise)
            .then(async() => {

                // zip 파일 압축
                await zip.generateAsync({type:"blob"}).then((content) => {
                    
                    // 압축 완료시 나오는 blob으로 다운로드
                    try {
                        this.hodu_download_blob(content, `hodu_download_${new Date().getTime()}.zip`);
                    } catch(e) {
                        this.hodu_error_process(e, true, false);
                    }

                });
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            }).finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 한개의 파일만 다운로드
     */
    downloadSingleFile(file : hodu_doc_table.t_hospital_patient_file) : void {
        this.hodu_download(`/app_images/${file.file_path}`, file.file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 모든 파일 체크 / 체크 해제
     */
    allFileCheck(is_checked : boolean) : void {
        
        for( const file of this.file_list ) {
            const temp_file : hodu_doc_table.t_hospital_patient_file = JSON.parse(JSON.stringify(file));
            temp_file.checked = is_checked;
            this.file_list.splice(this.file_list.indexOf(file), 1, temp_file);
        }

    }

    /**
     * 파일 체크 변경
     */
    fileCheckChanged() : void {

        const all_count : number = this.computedFileSortList.length;
        const checked_count : number = this.computedFileSortList.filter(item => item.checked == true).length;

        this.is_all_selected = (all_count == checked_count);
    }

    /**
     * 파일 용량 텍스트
     */
    getFileSizeText(file_size : number) : string {

        let size : string = FILE_SIZE.B;
        while( file_size > 1024 && size != FILE_SIZE.GB ) {
            file_size /= 1024;

            // 사이즈는 GB까지만 표현한다
            switch( size ) {
                case FILE_SIZE.B:
                    size = FILE_SIZE.KB;
                    break;

                case FILE_SIZE.KB:
                    size = FILE_SIZE.MB;
                    break;

                case FILE_SIZE.MB:
                    size = FILE_SIZE.GB;
                    break;
            } 
        }

        return `${file_size.toFixed(2)} ${size}`;
    }

    /**
     * 배경화면 반환
     */
    getBackgroundImage(file : hodu_doc_table.t_hospital_patient_file) : string {
        if( new RegExp(escape('image')).test(escape(file.file_type)) == false ) {
            return 'none';
        }

        return `url(app_images/${file.file_path})`;
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'NAME' | 'SIZE' | 'DATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#patient_filebox_file_list_scroll').mCustomScrollbar('destroy');
        this.setScroll();
        window['left_inbox02_mCustomScrollbar']();
    }

    /**
     * 환자 선택 변경
     */
    @Watch('selected_patient')
    changedSelectedPatient() : void {
        this.handleResize();
        this.is_all_selected = false;
        this.getPatientsFileboxInfo();
    }
    
}

