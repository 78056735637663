import { render, staticRenderFns } from "./LeftAdminPage.vue?vue&type=template&id=54d41841&scoped=true&"
import script from "./LeftAdminPage.vue?vue&type=script&lang=ts&"
export * from "./LeftAdminPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d41841",
  null
  
)

export default component.exports