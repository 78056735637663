
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');

import { EventWorkAssignmentInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupPermmision extends Mixins(VueHoduCommon) {

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEventWorkAssignmentInfo ?: (params : EventWorkAssignmentInfo) => void;

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * 변수 선언부
     */
    perm_data = {"group": [/*{name: "파일", list: [{cate: "file", type: "read", name: "조회,읽기"},
                                                {cate: "file", type: "create", name: "파일 업로드"},
                                                {cate: "file", type: "delete", name: "파일 삭제"}]},*/
                           {name: "팀관리", list: [{cate: "team", type: "create", name: "팀 생성"},
                                                 {cate: "team", type: "delete", name: "팀 삭제"},
                                                 {cate: "team", type: "modify", name: "팀 수정(팀 승인, 팀 멤버탈퇴, 팀 초대)"}]},
                           {name: "일정", list: [{cate: "event", type: "read", name: "조회"},
                                                {cate: "event", type: "create", name: "등록"},
                                                {cate: "event", type: "delete", name: "삭제"},
                                                {cate: "event", type: "modify", name: "수정"},
                                                {cate: "event", type: "modify_prev", name: "과거 일정 편집 금지"},
                                                {cate: "event", type: "delete_prev", name: "과거 일정 삭제 금지"},
                                                {cate: "event", type: "private_read", name: "특정인 지정 열람"}]},
                           {name: "멤버", list: [{cate: "member", type: "read", name: "보기"},
                                                {cate: "member", type: "delete", name: "멤버 삭제(강퇴)"},
                                                {cate: "member", type: "invite", name: "초대"},
                                                {cate: "member", type: "modify", name: "수정 (권한변경)"}]},
                           {name: "인사정보", list: [{cate: "personal_record", type: "update", name: "등록·수정", perm_type : "select", members : null },
                                                    {cate: "personal_record", type: "read", name: "열람" }]},
                           {name: "공지사항", list: [{cate: "notice", type: "read", name: "조회"},
                                                   {cate: "notice", type: "create", name: "등록"},
                                                   {cate: "notice", type: "delete", name: "수정"},
                                                   {cate: "notice", type: "modify", name: "삭제"}]},
                           {name: "그룹관리", list: [{cate: "group_info", type: "delete", name: "삭제"},
                                                   {cate: "group_info", type: "modify", name: "수정"},
                                                   {cate: "group_info", type: "approve", name: "승인"}]},
                           {name: "업무일지", list: [/*{cate: "report", type: "read", name: "조회"},*/
                                                    {cate: "report", type: "create", name: "등록"},
                                                    {cate: "report", type: "delete", name: "삭제"},
                                                    {cate: "report", type: "modify", name: "수정"},
                                                    {cate: "report", type: "private_read", name: "특정인 지정 열람"}]},
                           {name: "회의록", list: [/* {cate: "meetinglog", type: "read", name: "조회"}, */
                                                   {cate: "meetinglog", type: "create", name: "등록"},
                                                   {cate: "meetinglog", type: "delete", name: "삭제"},
                                                   {cate: "meetinglog", type: "modify", name: "수정"},
                                                   {cate: "meetinglog", type: "private_read", name: "특정인 지정 열람"}]}, 
                           {name: "프로젝트", list: [{cate: "work", type: "read", name: "조회"},
                                                    {cate: "work", type: "create", name: "등록"},
                                                    {cate: "work", type: "delete", name: "삭제"},
                                                    {cate: "work", type: "modify", name: "수정"},
                                                    {cate: "work", type: "private_read", name: "특정인 지정 열람"}]}],
                 "team" : [{name: "일정", list: [{cate: "event", type: "read", name: "조회"},
                                                {cate: "event", type: "create", name: "등록"},
                                                {cate: "event", type: "delete", name: "삭제"},
                                                {cate: "event", type: "modify", name: "수정"},
                                                {cate: "event", type: "modify_prev", name: "과거 일정 편집 금지"},
                                                {cate: "event", type: "delete_prev", name: "과거 일정 삭제 금지"},
                                                {cate: "event", type: "private_read", name: "특정인 지정 열람"}]},
                           {name: "멤버", list: [{cate: "member", type: "read", name: "보기"},
                                                {cate: "member", type: "delete", name: "멤버 삭제(강퇴)"},
                                                {cate: "member", type: "invite", name: "초대"},
                                                {cate: "member", type: "modify", name: "수정 (권한변경)"}]},
                           {name: "공지사항", list: [{cate: "notice", type: "read", name: "조회"},
                                                   {cate: "notice", type: "create", name: "등록"},
                                                   {cate: "notice", type: "delete", name: "수정"},
                                                   {cate: "notice", type: "modify", name: "삭제"}]},
                           {name: "팀관리", list: [{cate: "team_info", type: "modify", name: "수정"},
                                                 {cate: "team_info", type: "delete", name: "삭제"},
                                                 {cate: "team_info", type: "approve", name: "승인"}]},
                           {name: "업무일지", list: [/*{cate: "report", type: "read", name: "조회"}, */
                                                    {cate: "report", type: "create", name: "등록"},
                                                    {cate: "report", type: "delete", name: "삭제"},
                                                    {cate: "report", type: "modify", name: "수정"},
                                                    {cate: "report", type: "private_read", name: "특정인 지정 열람"}]},
                           {name: "회의록", list: [/*{cate: "meetinglog", type: "read", name: "조회"},*/
                                                   {cate: "meetinglog", type: "create", name: "등록"},
                                                   {cate: "meetinglog", type: "delete", name: "삭제"},
                                                   {cate: "meetinglog", type: "modify", name: "수정"},
                                                   {cate: "meetinglog", type: "private_read", name: "특정인 지정 열람"}]},  
                           {name: "프로젝트", list: [{cate: "work", type: "read", name: "조회"},
                                                {cate: "work", type: "create", name: "등록"},
                                                {cate: "work", type: "delete", name: "삭제"},
                                                {cate: "work", type: "modify", name: "수정"},
                                                {cate: "work", type: "private_read", name: "특정인 지정 열람"}]}]};

    arr_perm   : any = [];
    permmision : any = null;
    personal_record_perm : any = null;

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() {
        const vue = this;
        this.setScroll();

        // 권한 조회
        await this.getRole();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight : number | undefined = $('.settingBg').outerHeight();
        const headerHeight : number | undefined = $('#permission_header').outerHeight();

        // @ts-ignore
        $('#permission_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : window.innerHeight - ( titleHeight == null ? 0 : titleHeight ) - ( settingBgHeight == null ? 0 : settingBgHeight ) - ( headerHeight == null ? 0 : headerHeight ),
        });
    }

    /**
     * 권한 조회
     */
    async getRole() : Promise<void> {
        
        this.arr_perm = [];
        this.permmision = null;
        let url = (this.team_id > 0 ? `api/v1/teams/getPermmision/${this.team_id}/${this.group_id}` : `api/v1/groups/getPermmision/${this.group_id}`);

        try {

            const response = await this.hodu_api_call(url, API_METHOD.GET, null);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list ) {
                throw new Error("권한 조회 오류");
            }

            this.permmision = response.data.data.list;

            this.arr_perm = (this.team_id > 0 ? JSON.parse(JSON.stringify(this.perm_data.team)) : JSON.parse(JSON.stringify(this.perm_data.group)));

            if( this.team_id < 1 ) {
                const permission_response = await this.hodu_api_call(`/api/v1/groups/${this.group_id}/personal_record/permission`, API_METHOD.GET);

                console.log(permission_response);

                if( permission_response == null || !this.isHttpStatusSuccess(permission_response.status) || !permission_response.data || !permission_response.data.data ) {
                    throw new Error("인사정보 권한 조회 오류");
                }

                const personal_record_perm = permission_response.data.data.permission;

                // personal_record.update 찾아서 변수 업데이트

                let change_complete = false;
                const copy_perm_data = JSON.parse(JSON.stringify(this.perm_data))
                for( const perm of copy_perm_data.group ) {

                    if( perm.name == '인사정보' ) {
                        for( const data of perm.list ) {
                            if( data.type == 'update' ) {       
                                data.members = personal_record_perm;
                                change_complete = true;
                            }
                        }
                    }

                    if( change_complete == true ) {
                        break;
                    }
                }

                this.perm_data = JSON.parse(JSON.stringify(copy_perm_data));
                this.arr_perm = (this.team_id > 0 ? JSON.parse(JSON.stringify(this.perm_data.team)) : JSON.parse(JSON.stringify(this.perm_data.group)));
            }

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
    }

    /**
     * 값 변경
     */
    setPermmisionChange(any, ps_auth, ps_cate, ps_type) : void {
        if ( null == this.permmision || this.permmision.length < 1 ) {
            return;
        }

        for ( let i = 0; i < this.permmision.length; i++ ) {
            if ( this.permmision[i].role == ps_auth ) {
                this.permmision[i].permmision[ps_cate][ps_type] = any.target.checked;
                break;
            }
        }
    }

    /**
     * 저장
     */
    async setSave() : Promise<void> {
        await this.hodu_api_call(`api/v1/groupsweb/setPermmision/${this.group_id}/${this.team_id}`, API_METHOD.POST, this.permmision)
            .then(async(response) => {
                this.hodu_show_dialog("success", "저장되었습니다", ['확인']);
                await this.get_group_role_service();
                await this.getRole();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 값 가져오기
     */
    getPermmisionValue(ps_auth, ps_cate, ps_type) : boolean {
        if ( null == this.permmision || this.permmision.length < 1 ) {
            return false;
        }

        for ( let i = 0; i < this.permmision.length; i++ ) {
            let lo_data = this.permmision[i];

            if ( lo_data.role == ps_auth ) {
                return lo_data.permmision[ps_cate][ps_type];
            }
        }

        return false;
    }

    /**
     * 초기화
     */
    permmisionReset() : void {
        this.getRole();
    }

    async member_select(content) : Promise<void> {

        if( content.cate == 'personal_record' && content.type == 'update' ) {

            // 최종관리자만 수정 가능
            if( this.get_group_role(this.group_id) != 'ADMIN' ) {
                return;
            }
            
            // 인사정보 권한 조회
            let select_user_id : number[] = [];
            try {

                const response = await this.hodu_api_call(`/api/v1/groups/${this.group_id}/personal_record/permission`, API_METHOD.GET);

                console.log(response);

                if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("인사정보 권한 조회 오류");
                }

                const permission = response.data.data.permission;
                
                if( permission != null && permission.length > 0 ) {
                    for( const perm of permission ) select_user_id.push(perm.user_id);
                }

                this.doSetEventWorkAssignmentInfo?.({
                    show_event_work_assignment : true,
                    selected_user_ids : select_user_id,
                    is_group_member_select : true,
                    select_max_count : 3,
                    confirm_function : async(user_ids : number[]) => {
   
                        try {

                            this.doSetEventWorkAssignmentInfo?.({
                                show_event_work_assignment : false,
                                selected_user_ids          : [],
                                confirm_function           : () => {}
                            });

                            let body_user_id : number[] = [];

                            if( user_ids.length > 0 ) {
                                body_user_id = body_user_id.concat(user_ids);
                            }

                            const response = await this.hodu_api_call(`/api/v1/groups/${this.group_id}/personal_record/permission`, API_METHOD.POST, {
                                "user_id" : body_user_id
                            });

                            console.log(response);

                            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                                throw new Error("인사정보 권한 업데이트 오류");
                            }

                            this.getRole();

                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                        }
                        
                    }
                })

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }
            
        }
    }

    delete_select_member(event, content, user_id) {
        event.stopPropagation();

        // 인사정보 권한은 최종관리자만 수정 가능
        if( content.cate == 'personal_record' && content.type == 'update' && this.get_group_role(this.group_id) != 'ADMIN' ) {
            return;
        }

        this.hodu_show_dialog("cancel", "선택한 멤버의 권한을 제거하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                
                if( content.cate == 'personal_record' && content.type == 'update' ) {
                    try {

                        let user_ids : number[] = [];
                        let is_find_personal_record_update = false;
                        for( const perm of this.perm_data.group ) {

                            if( perm.name == '인사정보' ) {
                                for( const data of perm.list ) {
                                    if( data.type == 'update' ) {       
                                        for( const member of (data.members ?? []) ) {
                                            if( (member as any).user_id != null && user_id != (member as any).user_id ) user_ids.push((member as any).user_id)
                                        }
                                        is_find_personal_record_update = true;
                                    }
                                }
                            }

                            if( is_find_personal_record_update == true ) {
                                break;
                            }
                        }

                        const response = await this.hodu_api_call(`/api/v1/groups/${this.group_id}/personal_record/permission`, API_METHOD.POST, {
                            "user_id" : user_ids
                        });

                        console.log(response);

                        if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                            throw new Error("인사정보 권한 업데이트 오류");
                        }

                        this.getRole();

                    } catch(e) {
                        this.hodu_error_process(e, false, false, true);
                    }
                }

            }
        ])
    }

    block_switch(event, content, auth) {
        if( content.cate == 'personal_record' && content.type == 'read' && auth == this.GroupAuth.GROUP_USER ) {
            event.preventDefault();
        }
    }

    /**
     * 리스트 접기 펼치기
     */
    clickPermissionTitle(lo_data, index) {
        lo_data.listOff = lo_data.listOff == null ? true : !lo_data.listOff;
        this.arr_perm.splice(index, 1, lo_data);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#permission_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }
}
