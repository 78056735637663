
import { Vue, Component, Mixins, Watch, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { hodu_doc_object } from '@/model/hodudoc';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class AppointmentPreviewList extends Mixins(VueHoduCommon) {
    
    @Prop() event_bus  !: Vue;
    @Prop() start_date !: Date;

    appointment_daily_list : hodu_doc_object.appointment_daily_info[] = [];
    selected_date : Date = new Date();
    ol_width_style = "";

    async mounted() : Promise<void> {
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        if( this.event_bus != null ) { this.event_bus.$on('previewResize', this.handleResize); }
        

        // start_date의 월의 첫 날, 마지막 날을 기준으로 예약 기간별 리스트를 조회한다
        const start : Date = new Date(this.start_date);
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const end : Date = new Date(this.start_date);
        end.setMonth(end.getMonth() + 1);
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        this.selected_date = new Date(this.start_date);

        await this.getAppointmentCountByRange(start, end);
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const li_width : number | undefined = $('.reservationsData li').outerWidth();
        this.ol_width_style = `width : ${(this.appointment_daily_list.length * ( (li_width ? li_width : 72) + 10 ) + 60)}px !important`;

        const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
        const right_area_controller_width : number | undefined = $('#right_area_controller').outerWidth();

        const scroll_width : number = window.innerWidth - (left_area_width ? left_area_width : 0) 
                                                        - (right_area_controller_width ? right_area_controller_width : 0);
        // @ts-ignore
        $('.previewContent').mCustomScrollbar({
            axis : 'x',
            // scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setWidth : scroll_width,
        });

        this.$nextTick(() => {
            $('.previewContent .mCSB_container').width(this.appointment_daily_list.length * ( (li_width ? li_width : 72) + 10 ) + 60);

            // 오늘과 같은 년월 이라면 오늘 날짜를 가운데로 두도록 스크롤
            if( new Date().getFullYear() == this.selected_date.getFullYear() && new Date().getMonth() == this.selected_date.getMonth() ) {
                const max_item : number = Math.ceil(scroll_width / ( (li_width ? li_width : 72) + 10 ));
                const center : number = Math.ceil(max_item / 2);
                const item_index = new Date().getDate() - 1;

                // @ts-ignore
                $('.previewContent').mCustomScrollbar('scrollTo', `#daily_${item_index - center}`, { scrollInertia : 0 });
            }
        })
    }

    /**
     * 예약 기간별 리스트 조회
     */
    async getAppointmentCountByRange(start_date : Date, end_date : Date) : Promise<void> {
        start_date = start_date instanceof Date ? start_date : new Date(start_date);
        end_date   = end_date   instanceof Date ? end_date   : new Date(end_date);

        const start : string = moment(start_date).utc().format();
        const end   : string = moment(end_date).utc().format();

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/appointmentCountByRange?start=${start}&end=${end}`, API_METHOD.GET)
            .then((response) => {
                
                // 병원 규칙이 없는 경우 throw
                if( this.hospital_setting_info == null ) {
                    throw new Error("병원 정보를 찾을 수 없습니다\n잠시 후 다시 시도해주세요");
                }

                console.log(response);
                this.appointment_daily_list.splice(0, this.appointment_daily_list.length);
                this.appointment_daily_list = this.appointment_daily_list.concat(response.data.data);

                for( const appointment of this.appointment_daily_list ) {
                    const appointment_daily_obj : hodu_doc_object.appointment_daily_info = JSON.parse(JSON.stringify(appointment));
                    
                    const target_date : Date = new Date(appointment_daily_obj.orig_date);
                    const target_day_of_week : number = target_date.getDay();

                    // 해당 날짜에 맞는 병원 규칙을 가져온다
                    const hospital_daily_setting : hodu_doc_object.hospital_setting_info = this.hospital_setting_info.filter(
                        item => item.day_of_week == target_day_of_week &&
                        new Date(item.start_date).getTime() <= target_date.getTime() &&
                        new Date(item.end_date).getTime()   >= target_date.getTime() 
                    )[0];

                    // 병원 규칙을 찾지 못한 경우 휴무 처리
                    if( hospital_daily_setting == null ) {
                        appointment_daily_obj.is_work_day = false;
                        this.appointment_daily_list.splice(this.appointment_daily_list.indexOf(appointment), 1, appointment_daily_obj);
                        continue;
                    }

                    // 병원 오전, 오후 둘다 휴무라면 휴무 처리
                    appointment_daily_obj.is_work_day = true;
                    if( ( hospital_daily_setting.start_am_time == null || hospital_daily_setting.start_am_time.length < 1 ) &&
                        ( hospital_daily_setting.end_am_time   == null || hospital_daily_setting.end_am_time.length   < 1 ) ) {
                        appointment_daily_obj.is_work_day = false;
                    }

                    // 기존 배열 아이템 대체
                    this.appointment_daily_list.splice(this.appointment_daily_list.indexOf(appointment), 1, appointment_daily_obj);
                    this.handleResize();
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 해당 일자 예약 리스트 조회
     */
    getDailyAppointmentList(daily : hodu_doc_object.appointment_daily_info) : void {
        if( daily.is_work_day == false ) { return; }
        this.$emit('dailyAppointments', new Date(daily.orig_date))
    }

    /**
     * 년도 선택
     */
    changeSelectedDateYear(year : number) : void {
        this.selected_date.setDate(1);
        this.selected_date.setFullYear(year);

        // start_date의 월의 첫 날, 마지막 날을 기준으로 예약 기간별 리스트를 조회한다
        const start : Date = new Date(this.selected_date);
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const end : Date = new Date(this.selected_date);
        end.setMonth(end.getMonth() + 1);
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        this.getAppointmentCountByRange(start, end);
    }

    /**
     * 월 선택
     */
    changeSelectedDateMonth(month : number) : void {
        this.selected_date.setDate(1);
        this.selected_date.setMonth(month);

        // start_date의 월의 첫 날, 마지막 날을 기준으로 예약 기간별 리스트를 조회한다
        const start : Date = new Date(this.selected_date);
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const end : Date = new Date(this.selected_date);
        end.setMonth(end.getMonth() + 1);
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        this.getAppointmentCountByRange(start, end);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('.previewContent').mCustomScrollbar('destroy');
        this.ol_width_style = "";
    	this.setScroll();
    }

    /**
     * left_control_box_flag 조정시 애니메이션 완료 후 리사이즈
     */
    @Watch('left_control_box_flag')
    watchLeftControlBoxFlag() : void {
        setTimeout(() => { this.handleResize(); }, 100);
    }

}
