import Vue from "vue";
import router from "./router";
import store from "./store";
import i18n from "./vue-i18n";

import { initFirebase } from "./firebase";

// import "./registerServiceWorker";
import "jquery";
import "./plugins/http";

import "./assets/css/basic.css";
import "./assets/css/datepicker.css";
import "./assets/css/fullcalendar.css";
import "./assets/css/jquery.mCustomScrollbar.css";
import "./assets/css/common.css";
import "./assets/css/ware_common.css";
import "./assets/css/common_modal.css";
import "./assets/css/default.css";
import "./assets/css/group_detail.css";
import "./assets/css/print.css";
// import './assets/css/fullcalendar.print.css';

import "vue-resize/dist/vue-resize.css";

// import "@/assets/js/jquery.mCustomScrollbar.concat.min.js";
require("@/assets/js/jquery.mCustomScrollbar.concat.min.js");

// import "@/assets/js/jquery-ui.custom.min.js";
require("@/assets/js/jquery-ui.custom.min.js"); // 커스텀한 jquery-ui min.js
// require("@/assets/js/jquery-ui.js");         // 커스텀한 jquery-ui 원본 (수정한거 확인하려면 '// custom' 로 검색)

// @ts-ignore
// tslint:disable-next-line: max-line-length typedef
jQuery.browser = {}; (function () {jQuery.browser.msie = false; jQuery.browser.version = 0; if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) { jQuery.browser.msie = true; jQuery.browser.version = RegExp.$1; } })();

require("@/assets/datepick/css/jquery.datepick.css"); 
require("@/assets/datepick/js/jquery.plugin.js"); 
require("@/assets/datepick/js/jquery.datepick.js");
require("@/assets/datepick/js/jquery.datepick-ko.js");

import VueAppleSignin from 'vue-apple-signin';

Vue.use(VueAppleSignin, {
    clientId: 'com.hoduware.app',
    scope: 'name email',
    redirectURI: `${location.protocol}//${location.hostname}/api/v1/apple`,
    state: 'c029b537c0d64a0f935298586f77e01b',
    popup: true
});

import App from "./App.vue";
import LoginApp from "./LoginApp.vue";
import WeeklyCalendar from "./components/WeeklyCalendar.vue";

const is_login_app : boolean = window.location.hostname.includes("login");

if( !is_login_app ) {

    initFirebase();

    window["HoduApp"] = new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount("#app");

    window["WeeklyCalendar"] = new Vue({
        router,
        store,
        i18n,
        render: h => h(WeeklyCalendar)
    }).$mount("#weekly-calendar");

}

else {
    window["HoduLoginApp"] = new Vue({
        router,
        store,
        i18n,
        render: h => h(LoginApp)
    }).$mount("#app");
}