
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { t_event } from '../../model/event';
import { namespace } from 'vuex-class';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { hodu_doc_table, hodu_doc_object } from '../../model/hodudoc';
import moment from 'moment';

const HoduDocInfo = namespace('HoduDocInfo');
declare var daum: any;

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalList extends Mixins(VueHoduCommon) {

    get sortedHospitalList() : hodu_doc_object.my_hospital_info[] {
        let new_list = this.hodu_d_list
        
        return new_list
    }


    get computedAppointsYears() : number[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }
        
        let year_list : number[] = []

        for (let appoint of this.hodu_d_list[this.selected_hospital_index].appointments ) {
            let year = new Date(appoint.event_data.schedule_date.start).getFullYear();
            if ( year_list.includes(year) == false ) {
                year_list.push(year)
            }
        }

        year_list.sort((year1: number, year2: number) : number => {
            if( year1  > year2 ) { return  1; }
            if( year1  < year2 ) { return -1; }
            if( year1 == year2 ) { return  0; }
            return 0;
        })

        if ( year_list.length < 1 ) {
            year_list.push(new Date().getFullYear())
        }
        return year_list;
    }

    /**
     * 접수
     */
    get receiptAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let receiptAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "RECEIPT" &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        receiptAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return receiptAppoints;
    }
    
    get requestAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let requestAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "REQUEST"  &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        requestAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return requestAppoints;
    }

    get declineAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let declineAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "DECLINE" &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        declineAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return declineAppoints;
    }

    get confirmAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let confirmAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "CONFIRM" && 
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        confirmAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return confirmAppoints;
    }

    get diagnosisAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let diagnosisAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "DIAGNOSIS" && 
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        diagnosisAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return diagnosisAppoints;
    }

    get noshowAppointments() : t_event[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].appointments == null ) { return []; }

        let noshowAppoints = this.hodu_d_list[this.selected_hospital_index].appointments.filter(item => 
            item.event_data.appointment != null && item.event_data.appointment.appointment_status == "NOSHOW" &&
            new Date(item.event_data.schedule_date.start).getFullYear() == this.selected_appoints_year &&
            (this.selected_appoints_month != 100 ? (new Date(item.event_data.schedule_date.start).getMonth()+1) == this.selected_appoints_month : true )
        );
        noshowAppoints.sort((appoint1 : t_event, appoint2 : t_event) : number => {
            if( appoint1.event_data.schedule_date.start  > appoint2.event_data.schedule_date.start ) { return -1; } 
            if( appoint1.event_data.schedule_date.start  < appoint2.event_data.schedule_date.start ) { return  1; } 
            if( appoint1.event_data.schedule_date.start == appoint2.event_data.schedule_date.start ) { return  0; }
            return 0;
        });
        return noshowAppoints;
    }

    get computedFilesYears() : number[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].files == null ) { return [2020]; }
        
        let year_list : number[] = []

        for (let file of this.hodu_d_list[this.selected_hospital_index].files ) {
            if ( file.audit_modified == null ) {
                continue;
            }
            let year = new Date(file.audit_modified).getFullYear();
            if ( year_list.includes(year) == false ) {
                year_list.push(year)
            }
        }

        year_list.sort((year1: number, year2: number) : number => {
            if( year1  > year2 ) { return  1; }
            if( year1  < year2 ) { return -1; }
            if( year1 == year2 ) { return  0; }
            return 0;
        })

        if ( year_list.length < 1 ) {
            year_list.push(new Date().getFullYear())
        }
        return year_list;
    }

    /**
     * 년 월에 해당하는 파일
     */
    get computedFiles() : hodu_doc_table.t_hospital_patient_file[] {
        if ( this.hodu_d_list == null || this.hodu_d_list[this.selected_hospital_index] == null || this.hodu_d_list[this.selected_hospital_index].files == null ) { return []; }

        // let new_files = this.hodu_d_list[this.selected_hospital_index].files.filter(item =>
        //     item.audit_modified != null ?
        //         new Date(item.audit_modified).getFullYear() == this.selected_file_year &&
        //         (this.selected_file_year != 100 ? (new Date(item.audit_modified).getMonth()+1) == this.selected_appoints_month : true ) 
        //     : false
        // );
        // new_files.sort((file1 : hodu_doc_table.t_hospital_patient_file, file2 : hodu_doc_table.t_hospital_patient_file) : number => {
        //     if( file1.audit_modified == null || file2.audit_modified == null ) { return 0; }
        //     if( file1.audit_modified  > file2.audit_modified ) { return -1; } 
        //     if( file1.audit_modified  < file2.audit_modified ) { return  1; } 
        //     if( file1.audit_modified == file2.audit_modified ) { return  0; }
        //     return 0;
        // });
        // return new_files;
        return this.hodu_d_list[this.selected_hospital_index].files;
    }

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;

    hodu_d_list    : hodu_doc_object.my_hospital_info[]  = [];
    selected_hospital_index : number = 0;

    month_list : number[] = [];
    files_month_list : number[] = [];

    // selected_appoints_year  : number = new Date().getFullYear();
    selected_appoints_year  : number = 2020;
    selected_appoints_month : number = 100;

    selected_file_year  : number = 2020;
    selected_file_month : number = 100;

    sort_hospital_name_asc   : boolean = true;
    sort_hospital_last_treat_asc : boolean = true;

    map : any;
    default_profile_image : string = require("@/assets/images/contents/im_photoB.gif");
    // require("@/assets/images/contents/img"+this.group_color_to_class_name+".png");
    
    created() {
        this.month_list.push(100)
        this.files_month_list.push(100)
        for (let i=1; i<=12; i++) {
            this.month_list.push(i)
            this.files_month_list.push(i)
        }
    }

    async mounted() : Promise<void> {
        $(".grp2 .btnBox a").click(function(){
            var getIndex = $(this).index();

            $(this).siblings().removeClass("on");
            $(this).addClass("on");

            $(".contentOnRight ul.menuUl > li").eq(getIndex).siblings().removeClass("on");
            $(".contentOnRight ul.menuUl > li").eq(getIndex).addClass("on");
        });

        // 의료진 학력 경력 더 보기 버튼 클릭 시
        $(".seeMoreDetail").click(function(){
            $(this).parent().parent().parent().addClass("on");

        });

        // .titleBtn 클릭 시
        $(".directionList .dirDiv").click(function(){
            $(".directionList").addClass("mapOff");

        });

        // .titleBtn 클릭 후 암전된 지도 클릭 시 다시 보이기
        $(".contentOnRight .directionList .bg").click(function(){
            $(".directionList").removeClass("mapOff");

        });

        await this.get_group_role_service();
        await this.getHoduDList()

        let container = document.getElementById('mapView');
        let mapOption = {
            center: new daum.maps.LatLng(
                37.566826,
                126.9786567
            ), // 지도의 중심좌표
            level: 3 // 지도의 확대 레벨
        }; 

        this.map = new daum.maps.Map(container, mapOption); //지도 생성 및 객체 리턴

        this.$nextTick(() => {
            this.setScroll();
        })
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const main_height  : number | undefined = $('#grpScroll .viewGroup .content .main').outerHeight();
        const sort_header_height : number | undefined = $('#groupTeamHeader').outerHeight();

        // @ts-ignore
        $('#group_team_list').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (main_height ? main_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    async getHoduDList() {
        await this.hodu_api_call(`api/v1/user/me/hodudoc`, API_METHOD.GET)
            .then((response) => {
                let jsonData = JSON.parse(JSON.stringify(response.data.data));
                this.hodu_d_list.splice(0, this.hodu_d_list.length)
                this.hodu_d_list = this.hodu_d_list.concat(jsonData.hodu_d_list);
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    openCareer(event){

    }

    getPositionStr(position : string) : string {
        switch (position) {
            case "DIR" :
                return "원장";

            case "DPT" :
                return "부원장";

            case "EMP" :
                return "직원";

            default :
                return "";
        }
    }

    /**
     * 예약 상세로 이동
     */
    moveAppointmentDetail(event_id : string) : void {

        if( !this.doSetAppointmentDetailInfo ) { return; }

        this.doSetAppointmentDetailInfo({
            event : null,
            is_patient : true,
        });

        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event_id}`);
    }

    /**
     * 병원 리스트 클릭 이벤트 (병원 체인지)
     */
    changeHospital(index: number) {
        this.selected_hospital_index = index
        this.setNearestYearOfAppoints()
        this.selected_appoints_month = 100
        this.setNearestYearOfFiles()
        this.selected_file_month = 100
    }

    /**
     * 내 진료에서 현재 년도랑 가장 가까운 년도 선택
     */
    setNearestYearOfAppoints(){
        var target = new Date().getFullYear(); // 현재 년도 값
        var near = new Date().getFullYear();
        var abs = 0;
        var min = 10; // +- 몇년까지?

        for(var i = 0; i < this.computedAppointsYears.length; i++) {
            abs = ( (this.computedAppointsYears[i] - target) < 0) ? -(this.computedAppointsYears[i] - target) : (this.computedAppointsYears[i] - target)
            if(abs < min) {
                min = abs
                near = this.computedAppointsYears[i]
            }
        }
        this.selected_appoints_year = near
    }

    /**
     * 파일함에서 현재 년도랑 가장 가까운 년도 선택
     */
    setNearestYearOfFiles(){
        var target = new Date().getFullYear(); // 현재 년도 값
        var near = new Date().getFullYear();
        var abs = 0;
        var min = 10; // +- 몇년까지?

        for(var i = 0; i < this.computedFilesYears.length; i++) {
            abs = ( (this.computedFilesYears[i] - target) < 0) ? -(this.computedFilesYears[i] - target) : (this.computedFilesYears[i] - target)
            if(abs < min) {
                min = abs
                near = this.computedFilesYears[i]
            }
        }
        this.selected_file_year = near
    }

    /**
     * 내 진료 년도 변경 이벤트
     */
    changeAppointmentsYear(event) : void {
        this.selected_appoints_year = Number(event.target.value)
    }

    /**
     * 내 진료 월 변경 이베트
     */
    changeAppointmentsMonth(event) {
        this.selected_appoints_month = Number(event.target.value)
    }

    /**
     * 내 진료 리스트에 년월일 (요일)
     */
    getDateString(date : string | Date) : string {
        return `${moment(date).format('YYYY년 MM월 DD일')} (${this.getDayName(new Date(date).getDay())})`;
    }

    /**
     * 파일함 년도 변경
     */
    changeFileYear(event) {
        this.selected_file_year = Number(event.target.value)
    }
    
    /**
     * 파일함 월 변경
     */
    changeFileMonth(event) {
        this.selected_file_month = Number(event.target.value)
    }

    getMB(file_size : string | number) : number {
        let fileSize = Number(file_size)
        fileSize = Math.round(fileSize/1024)
        return fileSize
    }

    /**
     * 내 진료 리스트에 시간
     */
    getTimeString(date : string | Date) : string {
        let amPm = moment(date).format('A') == "AM" ? "오전" : "오후"
        return `${amPm} ${moment(date).format('hh시 mm분')}`;
    }
    
    /**
     * 병원소개에서 진료시간 문자열
     */
    getWorkSchedule(index) : string {
        if ( this.hodu_d_list.length < 1 || this.hodu_d_list[index] == null || this.hodu_d_list[index].time_tables == null ) {return '';}
        let timetables = this.hodu_d_list[index].time_tables

        let current_timetable : any;
        for ( let timetable of timetables ) {
            let start_date = new Date(timetable.start_date)
            let end_date   = new Date(timetable.end_date)
            
            //! 현재 년도에 해당하는 근무표가 존재한다면
            if ( start_date < new Date() && new Date() < end_date ) {
                current_timetable = timetable
            }
        }
        if ( current_timetable == null ) { 
            return '-' 
        }
        else{
            let final_string = ""
            for (let i=1; i<=6; i++) {
                let dayWork = current_timetable.work_info[i];
                final_string += `(${this.getDayName(i)})`
                
                let start_hh = dayWork.start_am_time != null ? String(dayWork.start_am_time).slice(0,2) : '오전 휴원'
                let start_mm = dayWork.start_am_time != null ? ":" + String(dayWork.start_am_time).slice(2,4) : ''
                let end_hh   = dayWork.end_pm_time   != null ? String(dayWork.end_pm_time).slice(0,2) : (dayWork.end_am_time != null ? String(dayWork.end_am_time).slice(0,2) : '오후 휴원')
                let end_mm   = dayWork.end_pm_time   != null ? ":" + String(dayWork.end_pm_time).slice(2,4) : (dayWork.end_am_time != null ? String(dayWork.end_am_time).slice(2,4) : '')

                final_string += ` ${start_hh}${start_mm} - ${end_hh}${end_mm} | `;

                if ( i == 6 ) {
                    dayWork = current_timetable.work_info[0];
                    final_string += `(${this.getDayName(0)})`
                
                    let start_hh = dayWork.start_am_time != null ? String(dayWork.start_am_time).slice(0,2) : '오전 휴원'
                    let start_mm = dayWork.start_am_time != null ? ":" + String(dayWork.start_am_time).slice(2,4) : ''
                    let end_hh   = dayWork.end_pm_time   != null ? String(dayWork.end_pm_time).slice(0,2) : (dayWork.end_am_time != null ? String(dayWork.end_am_time).slice(0,2) : '오후 휴원')
                    let end_mm   = dayWork.end_pm_time   != null ? ":" + String(dayWork.end_pm_time).slice(2,4) : (dayWork.end_am_time != null ? String(dayWork.end_am_time).slice(2,4) : '')

                    final_string += ` ${start_hh}${start_mm} - ${end_hh}${end_mm}`;
                }
            }
            return final_string;
        }
    }

    /**
     * 병원소개 웹사이트
     */
    getWebSite() : string {
        if ( this.hodu_d_list.length < 1 || this.hodu_d_list[this.selected_hospital_index].hospital_info == null || this.hodu_d_list[this.selected_hospital_index].hospital_info.web_url == null ) { return "-"; }
        return this.hodu_d_list[this.selected_hospital_index].hospital_info.web_url
    }

    /**
     * 병원소개 소개글
     */
    getDescript() : string {
        if ( this.hodu_d_list.length < 1 || this.hodu_d_list[this.selected_hospital_index].hospital_info == null || this.hodu_d_list[this.selected_hospital_index].hospital_info.descript == null ) { return "-"; }
        return this.hodu_d_list[this.selected_hospital_index].hospital_info.descript
    }

}
