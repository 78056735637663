import { CRUD_TYPE, EVENT_SUB_TYPE, SHARE_OPTION } from '@/mixin/VueHoduCommon';
import { approval_modal_interface } from '@/model/approval';
import { attendance_modal_interface } from '@/model/attendance';
import { chat_modal_interface } from '@/model/chat';
import { hodu_doc_modal_info } from '@/model/hodudoc';
import { hodu_home_modal_info } from '@/model/hoduhome';
import { organization_modal_interface } from '@/model/organization';
import { ActionTree, GetterTree, MutationTree } from "vuex";

export const namespaced = true;


export interface ModalInfoState {
    show_event_short_create             : boolean;
    show_event_repeat_modify            : boolean;
    show_event_daum_map                 : boolean;
    show_event_image_preview            : boolean;
    show_event_share                    : boolean;
    event_share_status_info             : EventShareStatusInfo;
    event_email_share_modal_info        : EventEmailShareModalInfo;
    show_event_attend_result            : boolean;
    show_event_vote_create              : boolean;
    show_event_vote_result              : boolean;
    show_event_reply                    : boolean;
    event_work_assignment_info          : EventWorkAssignmentInfo;
    show_event_work_process             : boolean;
    show_work_status                    : boolean;
    show_work_default_status            : boolean;
    show_work_pdf_modal                 : boolean;
    group_modal_info                    : GroupModalInfo;
    show_group_to_biz_modal             : boolean;
    group_team_other_out_info           : GroupTeamOtherOutModalInfo;
    group_notice_read_info              : GroupNoticeReadInfo;
    group_board_read_info               : GroupBoardReadInfo;
    show_message_send_status            : boolean;
    show_group_add_file_box             : boolean;
    group_add_file_modal_info           : GroupAddFileModalInfo;
    group_add_file_box_new_info         : GroupAddFileModalNewInfo;
    event_import_modal_info             : EventImportModalInfo;
    group_notice_reply_modal_info       : GroupNoticeReplyModalInfo;
    group_board_reply_modal_info        : GroupBoardReplyModalInfo;
    event_history_modal_info            : EventHistoryModalInfo;
    event_export_modal_info             : EventExportModalInfo;
    event_read_modal_info               : EventReadModalInfo
    group_member_import_modal_info      : GroupMemberImportModalInfo;
    equipment_import_modal_info         : EquipmentImportModalInfo;
    group_survey_template_modal_info    : GroupSurveyTemplateModalInfo;
    group_survey_choice_list_modal_info : GroupSurveyChoiceListModalInfo;
    group_filebox_move_modal_info       : GroupFileboxMoveModalInfo;
    
    /**
    * 공통
    */
    image_crop_modal_info : ImageCropModalInfo;
    message_modal_info : MessageModalInfo;
    common_color_picker_modal_info : CommonColorPickerModalInfo;
    common_input_modal_info : CommonInputModalInfo;
    common_datepicker_modal_info : CommonDatepickerModalInfo;
    common_select_friend_and_group_team_modal_info : CommonSelectFriendAndGroupTeamModalInfo;
    common_premium_expire_modal_info : CommonPremiumExpireModalInfo;
    
    /**
    * HODU D
    */
    appointment_create_modal_info    : hodu_doc_modal_info.AppointmentCreateModalInfo;
    doctor_time_setting_modal_info   : hodu_doc_modal_info.DoctorTimeSettingModalInfo;
    doctor_off_setting_modal_info    : hodu_doc_modal_info.DoctorOffSettingModalInfo;
    day_off_setting_modal_info       : hodu_doc_modal_info.DayOffSettingModalInfo;
    show_patient_add_modal           : boolean;
    patient_send_message_modal_info  : hodu_doc_modal_info.PatientSendMessageModalInfo;
    department_setting_modal_info    : hodu_doc_modal_info.DepartmentSettingModalInfo;
    show_hospital_off_setting_modal  : boolean;
    appointment_apply_modal_info     : hodu_doc_modal_info.AppointmentApplyModalInfo;
    appointment_list_modal_info      : hodu_doc_modal_info.AppointmentListModalInfo;
    hospital_time_setting_modal_info : hodu_doc_modal_info.HospitalTimeSettingModalInfo;
    login_hospital_select_modal_info : LoginHospitalModalInfo;
    
    /**
    * HODU H
    */
    home_building_create_modal_info        : hodu_home_modal_info.HomeBuildingCreateModalInfo;
    home_contact_create_modal_info         : hodu_home_modal_info.HomeContactCreateModalInfo;
    home_resident_car_request_modal_info   : hodu_home_modal_info.HomeResidentCarRequestModalInfo;
    home_resident_info_modal_info          : hodu_home_modal_info.HomeResidentInfoModalInfo;
    home_visitant_create_modal_info        : hodu_home_modal_info.HomeVisitantCreateModalInfo;
    home_visitant_info_modal_info          : hodu_home_modal_info.HomeVisitantInfoModalInfo; 
    equipment_management_detail_modal_info : hodu_home_modal_info.EquipmentManagementDetailModalInfo;
    supply_management_detail_modal_info    : hodu_home_modal_info.SupplyManagementDetailModalInfo;
    home_visitant_amano_info_modal_info    : hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo;
    home_resident_join_modal_info          : hodu_home_modal_info.HomeResidentJoinModalInfo; 
    
    /**
    * 채팅방
    */
    chatting_room_create_modal_info         : chat_modal_interface.ChattingRoomCreateModalInfo;
    chatting_room_info_modal_info           : chat_modal_interface.ChattingRoomInfoModalInfo;
    chatting_room_invite_confirm_modal_info : ChattingRoomInviteConfirmModalInfo;
    show_chatting_room_invite_modal         : boolean;

    /**
     * 전자결재
     */
    approval_message_modal_info              : approval_modal_interface.ApprovalMessageModalInfo;
    approver_select_modal_info               : approval_modal_interface.ApproverSelectModalInfo;
    approval_process_modal_info              : approval_modal_interface.ApprovalProcessModalInfo;
    approval_template_except_file_modal_info : approval_modal_interface.ApprovalTemplateExceptFileModalInfo;
    approval_file_modal_info                 : approval_modal_interface.ApprovalFileModalInfo;
    approval_form_modify_modal_info          : approval_modal_interface.ApprovalFormModifyModalInfo;
    approval_approver_favorite_modal_info    : approval_modal_interface.ApprovalApproverFavoriteModalInfo;
    approval_reply_modal_info                : approval_modal_interface.ApprovalReplyModalInfo;

    /**
     * 조직도 (근태관리, 전자결재 사용)
     */
    organization_modal_info                            : organization_modal_interface.OrganizationModalInfo;
    organization_modal2_info                           : organization_modal_interface.OrganizationModal2Info;
    organization_vacation_type_modal_info              : organization_modal_interface.OrganizationVacationTypeModalInfo;
    organization_work_type_detail_modal_info           : organization_modal_interface.OrganizationWorkTypeDetailModalInfo;
    organization_work_type_add_modal_info              : organization_modal_interface.OrganizationWorkTypeAddModalInfo;
    organization_off_modal_info                        : organization_modal_interface.OrganizationOffModalInfo;
    organization_off_add_modal_info                    : organization_modal_interface.OrganizationOffAddModalInfo;
    organization_vacation_add_modal_info               : organization_modal_interface.OrganizationVacationAddModlInfo;
    organization_vacation_detail_modal_info            : organization_modal_interface.OrganizationVacationDetailModalInfo;
    organization_my_vacation_detail_modal_info         : organization_modal_interface.OrganizationMyVacationDetailModalInfo;
    organization_sign_modal_info                       : organization_modal_interface.OrganizationSignModalInfo;
    organization_emp_personal_record_detail_modal_info : organization_modal_interface.OrganizationEmpPersonalRecordDetailModalInfo; 
    organization_emp_personal_record_import_modal_info : organization_modal_interface.OrganizationEmpPersonalRecordImportModalInfo; 
    organization_emp_personal_record_print_modal_info  : organization_modal_interface.OrganizationEmpPersonalRecordPrintModalInfo; 
    organization_default_emp_off_modal_info            : organization_modal_interface.OrganizationDefaultEmpOffModalInfo; 

    /**
     * 근태관리
     */
    attendance_request_modal_info : attendance_modal_interface.AttendanceRequestModalInfo;
}

export interface ChattingRoomInviteConfirmModalInfo {
    show_modal : boolean;
    user_infos : any[];
    close_callback ?: Function;
}

export interface CommonColorPickerModalInfo {
    show_modal: boolean;
    selected_color : string;
    is_dc_only : boolean;
    dc_color ?: string[];
    lc_color ?: string[];
}

export interface CommonInputModalInfo {
    show_modal   : boolean;
    title       ?: string;
    subtitle    ?: string;
    content     ?: string;
    placeholder ?: string;
    radio       ?: any;
    is_password ?: boolean;
    cancel      ?: Function;
    save        ?: Function;
    save_text   ?: string;
}

export interface CommonDatepickerModalInfo {
    show_modal    : boolean;
    default_date ?: Date;
    callback     ?: Function;
}

export interface CommonSelectFriendAndGroupTeamModalInfo {
    show_modal : boolean;
    title ?: string;                      // 제목
    share_option ?: SHARE_OPTION;         // 일정 공유 타입 use_share_option == true 일때만 사용 
    event_sub_type ?: EVENT_SUB_TYPE;     // 일정 서브 타입 use_share_option == true 일때만 사용
    event_crud_type ?: CRUD_TYPE;         // 일정 crud 타입 use_share_option == true 일때만 사용
    event_calendar_id ?: string;          // 일정 메일 공유에서만 사용
    event_scope ?: string;                // 일정 메일 공유에서만 사용
    event_scope_id ?: number;             // 일정 메일 공유에서만 사용
    event_id ?: string;                   // 일정 메일 공유에서만 사용
    event_start ?: Date;                  // 일정 메일 공유에서만 사용
    event_end ?: Date;                    // 일정 메일 공유에서만 사용
    event_title ?: string;                // 일정 메일 공유에서만 사용
    calendar_scope_group_id ?: number;    // 목록에서 제외할 그룹&팀의 group_id
    calendar_scope_team_id ?: number;     // 목록에서 제외할 그룹&팀의 team_id
    selected_friend_user_ids ?: number[]; // 기존에 선택 된 값 유지용
    selected_user_ids ?: number[];        // 선택된 유저 아이디 리스트 (중복 제거됨) 
    selected_group_ids ?: number[];       // 선택된 그룹 아이디 리스트 (중복 제거됨)
    selected_team_ids ?: number[];        // 선택된 팀 아이디 리스트 (중복 제거됨)
    group_user_selected_key ?: string[];  // 기존에 선택 된 값 유지용
    team_user_selected_key ?: string[];   // 기존에 선택 된 값 유지용
    use_share_option ?: boolean;          // 공유 옵션 사용 여부 (일정 공유로 사용할때 전용)
    use_group_select_only ?: boolean;     // 그룹&팀 탭 만을 사용 가능 하다
    use_user_select_only ?: boolean;      // friend_select_only가 아님! 그룹, 팀 안의 사용자를 선택 할 수 있음!
    use_need_user_email ?: boolean;       // 이메일이 있는 친구만 불러온다
    callback ?: Function;                 // 저장시 콜백
}

export interface CommonPremiumExpireModalInfo {
    show_modal : boolean;
    data : any;
}

export interface EventShareStatusInfo {
    show_event_share_status : boolean;
    event_id : string;
    calendar_id : string;
    scope : string;
    scope_id : number;
}

/**
* 일정 메일 공유 모달 정보
*/
export interface EventEmailShareModalInfo {
    show_modal : boolean;
    calendar_id : string;
    scope : string;
    scope_id : number;
    event_id : string;
    start : Date;
    end : Date;
    title ?: string;
    sender_email ?: string;
}

export interface EventWorkAssignmentInfo {
    show_event_work_assignment : boolean;
    selected_user_ids          : number[];
    is_group_member_select    ?: boolean; 
    select_max_count          ?: number; 
    confirm_function          ?: Function;
}

export interface GroupAddFileModalInfo {
    is_create : boolean;
    dir_uuid : string;
    dir_name : string;
    group_id : number;
}

export interface GroupAddFileModalNewInfo {
    show_modal : boolean;
    is_create : boolean;
    dir_uuid : string;
    group_id : number;
    // dir_name : string;
    // dir_color : string;
    // dir_icon : number;
    up_dir_uuid ?: string;
    create_function ?: Function;
    update_function ?: Function;
}

export interface GroupTeamOtherOutModalInfo {
    show_modal : boolean;
    data : any;
}

export interface GroupModalInfo {
    show_group_list_confirm : boolean;
    data                    : any;
}

export interface GroupListConfirmModalData {
    modal_type       : string;
    type             : string;       // GROUP , TEAM
    group_id         : number;
    team_id          : number;
    color            : string;       // 색상
    image            : string;       // 이미지
    description      : string;       // 소개글
    name             : string;       // 그룹명,팀명
    user_id          : number;
    is_appr_required : boolean;      // 승인여부
    appr_state       : string;
    member_created   : string;
}

export interface GroupTeamOtherOutModalData {
    group_id          : number;
    team_id           : number;
    user_id           : number;
    user_name         : string;
    image             : string;
    user_phone_number : string;
    user_email        : string;
    role              : string;
}

export interface GroupNoticeReadInfo {
    show_modal : boolean;
    noti_uid : string;
}

export interface GroupBoardReadInfo {
    show_modal : boolean;
    group_id : number;
    board_id : string;
}

export interface LoginHospitalModalInfo{
    show_modal      : boolean;
    hospital_list   : any[];
    callback       ?: Function;
}

export interface EventImportModalInfo {
    show_modal : boolean;
}

export interface GroupNoticeReplyModalInfo {
    show_modal : boolean;
    noti_uid : string;
    callback ?: Function;
}

export interface GroupBoardReplyModalInfo {
    show_modal : boolean;
    group_id : number;
    board_id : string;
    callback ?: Function;
}

/**
 * 일정 히스토리 모달
 */
 export interface EventHistoryModalInfo {
    show_modal : boolean;
    event_id : string;
}

/**
 * 일정 내보내기 모달
 */
export interface EventExportModalInfo {
    show_modal : boolean;
}

/**
 * 일정 읽음 여부 모달
 */
export interface EventReadModalInfo {
    show_modal : boolean;
    event_id : string;
    repeat_seq : number;
    calendar_id : string;
    owner_type : string;
    owner_id : number;
}

export interface GroupMemberImportModalInfo {
    show_modal : boolean;
    group_id : number;
    team_id : number;
}

export interface EquipmentImportModalInfo {
    show_modal : boolean;
    group_id : number;
}

export interface GroupSurveyTemplateModalInfo {
    show_modal : boolean;
    callback ?: Function;
}

export interface GroupSurveyChoiceListModalInfo {
    show_modal : boolean;
    data : any;
}

export interface GroupFileboxMoveModalInfo {
    show_modal : boolean;
    type : string;
    file_uuid ?: string;
    dir_uuid ?: string;
    callback ?: Function;
}

export interface ImageCropModalInfo {
    show_modal        : boolean;
    crop_image_ratio ?: number;
    image_url        ?: string;
    cancel_function  ?: Function;
    confirm_function ?: Function;
}

export interface MessageModalInfo {
    show_message_modal : boolean;
    message_type       : string;
    message            : string;
    button_text        : string[];
}

export const state : ModalInfoState = {
    show_event_short_create  : false,
    show_event_repeat_modify : false,
    show_event_daum_map      : false,
    show_event_image_preview : false,
    show_event_share         : false,
    event_share_status_info : {
        show_event_share_status : false,
        event_id : "",
        calendar_id : "",
        scope : "",
        scope_id : 0,
    },
    event_email_share_modal_info : {
        show_modal : false,
        calendar_id : "",
        scope : "",
        scope_id : 0,
        event_id : "",
        start : new Date(),
        end : new Date()
    },
    show_event_attend_result   : false,
    show_event_vote_create     : false,
    show_event_vote_result     : false,
    show_event_reply           : false,
    event_work_assignment_info : {
        show_event_work_assignment : false,
        selected_user_ids          : []                   
    }, 
    show_event_work_process  : false,
    show_work_status         : false,
    show_work_default_status : false,
    show_work_pdf_modal      : false,
    
    group_modal_info : {
        show_group_list_confirm : false,
        data                    : null
    },
    
    show_group_to_biz_modal : false,
    
    group_team_other_out_info : {
        show_modal : false,
        data       : null
    },
    
    group_notice_read_info : {
        show_modal : false,
        noti_uid : ""
    },

    group_board_read_info : {
        show_modal : false,
        group_id : 0,
        board_id : "",
    },
    
    show_message_send_status : false,
    show_group_add_file_box  : false,
    
    group_add_file_modal_info : {
        is_create : true,
        dir_uuid  : "",
        dir_name  : "",
        group_id  : 0
    },
    
    group_add_file_box_new_info : {
        show_modal : false,
        is_create : true,
        dir_uuid : "",
        group_id : 0,
        // dir_name : "",
        // group_id : 0,
        // dir_color : "",
        // dir_icon : 0,
    },
    
    event_import_modal_info : {
        show_modal : false    
    },

    group_notice_reply_modal_info : {
        show_modal : false,
        noti_uid : ""
    },

    group_board_reply_modal_info : {
        show_modal : false,
        group_id : 0,
        board_id : "",
    },

    event_history_modal_info : {
        show_modal : false,
        event_id : "",
    },

    event_export_modal_info : {
        show_modal : false,
    },

    event_read_modal_info : {
        show_modal : false,
        event_id : "",
        repeat_seq : 0,
        calendar_id : "",
        owner_type : "",
        owner_id : 0,
    },

    group_member_import_modal_info : {
        show_modal : false,
        group_id : 0,
        team_id : 0
    },

    equipment_import_modal_info : {
        show_modal : false,
        group_id : 0,
    },

    group_survey_template_modal_info : {
        show_modal : false,
    },

    group_survey_choice_list_modal_info : {
        show_modal : false,
        data : null
    },
    
    group_filebox_move_modal_info : {
        show_modal : false,
        type : "",
    },
    
    /**
    * 공통
    */
    image_crop_modal_info: { show_modal : false, crop_image_ratio : 1 },
    message_modal_info : {
        show_message_modal : false,
        message_type       : "",
        message            : "",
        button_text        : []
    },
    common_color_picker_modal_info : {
        show_modal : false,
        selected_color : "",
        is_dc_only : false
    },
    common_input_modal_info : {
        show_modal : false,
        title : "",
        subtitle : "",
        content : "",
        placeholder : "",
        is_password : false,
        radio : {
            title : "",
            list : []
        }
    },
    common_datepicker_modal_info : {
        show_modal : false
    },
    common_select_friend_and_group_team_modal_info : {
        show_modal : false
    },
    common_premium_expire_modal_info : {
        show_modal : false,
        data : {}
    },

    /**
    * HODU D
    */
    appointment_create_modal_info    : {
        show_modal : false,
        is_create_one_patient : false,
    },
    doctor_time_setting_modal_info   : {
        show_modal : false,
        is_create_work_time : false,
        start_date : new Date(),
        end_date : new Date(),
        timetable : {
            timetable_id : "",
            date_text    : "",
            list         : []
        }
    },
    doctor_off_setting_modal_info    : {
        show_modal : false,
        is_create : true,
    },
    day_off_setting_modal_info       : {
        show_modal : false,
        is_create  : true,
        is_doctor  : false,
        day_off    : null
    },
    show_patient_add_modal           : false,
    patient_send_message_modal_info  : {
        show_modal : false,
        selected_patients : [],
    },
    department_setting_modal_info    : {
        show_modal : false,
        is_create  : false,
        department : {
            department_code : "",
            department_name : ""
        }
    },
    show_hospital_off_setting_modal  : false,
    appointment_apply_modal_info     : {
        show_modal : false,
        event_id : "",
    },
    appointment_list_modal_info      : {
        show_modal : false,
        events : [],
    },
    hospital_time_setting_modal_info : {
        show_modal : false,
        start_date : new Date(),
        is_create  : true,
    },
    
    login_hospital_select_modal_info : {
        show_modal      : false,
        hospital_list   : []
    },
    
    /**
    * HODU H
    */
    home_building_create_modal_info : {
        show_modal : false
    },
    
    home_contact_create_modal_info : {
        show_modal : false
    },
    
    home_resident_car_request_modal_info : {
        show_modal : false
    },
    
    home_resident_info_modal_info : {
        show_modal : false
    },
    
    home_visitant_create_modal_info : {
        show_modal : false
    },
    
    home_visitant_info_modal_info : {
        show_modal : false
    },
    
    equipment_management_detail_modal_info : {
        show_modal : false,
        uid : "",
        seq : 0,
    },

    supply_management_detail_modal_info : {
        show_modal : false,
        uid : "",
        seq : 0,
    },

    home_visitant_amano_info_modal_info : {
        show_modal : false,
        amano_info : null,
    },

    home_resident_join_modal_info : {
        show_modal : false,
        group_id : 0,
        is_invite : false,
    },
    
    /**
    * 채팅방
    */
    chatting_room_create_modal_info : { show_modal : false },
    chatting_room_invite_confirm_modal_info : {
        show_modal : false,
        user_infos : [],
    },
    show_chatting_room_invite_modal : false,
    chatting_room_info_modal_info : {
        show_modal : false,
    },

    /**
     * 전자결재
     */
    approval_message_modal_info : { show_modal : false },
    approver_select_modal_info : { show_modal : false, is_approver : false, is_default_setting : false, is_self_approve : false },
    approval_process_modal_info : { show_modal : false },
    approval_template_except_file_modal_info : { show_modal : false },
    approval_file_modal_info : { show_modal : false },
    approval_form_modify_modal_info : { show_modal : false, template_class_id : 0, template_id : 0 },
    approval_approver_favorite_modal_info : { show_modal : false },
    approval_reply_modal_info : { show_modal : false, group_id : 0, approval_uid : '' },

    /**
     * 조직도 (근태관리, 전자결재 사용)
     */
    organization_modal_info : { show_modal : false },
    organization_modal2_info : { show_modal : false, dept_id : 0 },
    organization_vacation_type_modal_info : { show_modal : false, is_create : true },
    organization_work_type_detail_modal_info : { show_modal : false, work_type_id : 0 },
    organization_work_type_add_modal_info : { show_modal : false, is_create : true, work_type_id : 0 },
    organization_off_modal_info : { show_modal : false, user_id : 0 },
    organization_off_add_modal_info : { show_modal : false, is_create : true, off_id : 0 },
    organization_vacation_add_modal_info : { show_modal : false },
    organization_vacation_detail_modal_info : { show_modal : false, approval_uid : "" },
    organization_my_vacation_detail_modal_info : { show_modal : false, approval_uid : "" },
    organization_sign_modal_info : { show_modal : false },
    organization_emp_personal_record_detail_modal_info : { show_modal : false, group_id : 0, user_id : 0 },
    organization_emp_personal_record_import_modal_info : { show_modal : false },
    organization_emp_personal_record_print_modal_info : { show_modal : false, group_id : 0, user_id : 0, is_quitter : false },
    organization_default_emp_off_modal_info : { show_modal : false, group_id : 0 },

    /**
     * 근태관리
     */
    attendance_request_modal_info : { show_modal : false, user_id : 0, yyyymmdd : '' }
};

// getters
export const getters: GetterTree<ModalInfoState, any> = {
    
    isShowEventShortCreate: (state) => {
        return state.show_event_short_create;
    },
    
    isShowEventRepeatModify: (state) => {
        return state.show_event_repeat_modify;
    },
    
    isShowEventDaumMap: (state) => {
        return state.show_event_daum_map;
    },
    
    isShowEventImagePreview: (state) => {
        return state.show_event_image_preview;
    },
    
    isShowEventShare: (state) => {
        return state.show_event_share;
    },
    
    getEventShareStatusInfo: (state) => {
        return state.event_share_status_info;
    },
    
    getEventEmailShareModalInfo: (state) => {
        return state.event_email_share_modal_info;
    },
    
    isShowEventAttendResult: (state) => {
        return state.show_event_attend_result;
    },
    
    isShowEventVoteCreate: (state) => {
        return state.show_event_vote_create;
    },
    
    isShowEventVoteResult: (state) => {
        return state.show_event_vote_result;
    },
    
    isShowEventReply: (state) => {
        return state.show_event_reply;
    },
    
    getEventWorkAssignmentInfo: (state) => {
        return state.event_work_assignment_info;
    },
    
    isShowEventWorkProcess: (state) => {
        return state.show_event_work_process;
    },
    
    isShowWorkStatus: (state) => {
        return state.show_work_status;
    },
    
    isShowWorkDefaultStatus: (state) => {
        return state.show_work_default_status;
    },
    
    isShowWorkPdfModal: (state) => {
        return state.show_work_pdf_modal;
    },
    
    isShowGroupModalInfo: (state) => {
        return state.group_modal_info;
    },
    
    isShowGroupToBizModal: (state) => {
        return state.show_group_to_biz_modal;
    },
    
    isShowGroupTeamOtherOutModalInfo: (state) => {
        return state.group_team_other_out_info;
    },
    
    getGroupNoticeReadInfo: (state) => {
        return state.group_notice_read_info;
    },

    getGroupBoardReadInfo: (state) => {
        return state.group_board_read_info;
    },
    
    isShowMessageSendStatus: (state) => {
        return state.show_message_send_status;
    },
    
    isShowGroupAddFileBox: (state) => {
        return state.show_group_add_file_box;
    },
    
    getGroupAddFileModalInfo: (state) => {
        return state.group_add_file_modal_info;
    },
    
    getGroupAddFileModalNewInfo: (state) => {
        return state.group_add_file_box_new_info;
    },
    
    getEventImportModalInfo: (state) => {
        return state.event_import_modal_info;
    },

    getGroupNoticeReplyModalInfo: (state) => {
        return state.group_notice_reply_modal_info;
    },

    getGroupBoardReplyModalInfo: (state) => {
        return state.group_board_reply_modal_info;
    },

    getEventHistoryModalInfo: (state) => {
        return state.event_history_modal_info;
    },

    getEventExportModalInfo: (state) => {
        return state.event_export_modal_info;
    },

    getEventReadModalInfo: (state) => {
        return state.event_read_modal_info;
    },

    getGroupMemberImportModalInfo: (state) => {
        return state.group_member_import_modal_info;
    },

    getEquipmentImportModalInfo: (state) => {
        return state.equipment_import_modal_info;
    },

    getGroupSurveyTemplateModalInfo: (state) => {
        return state.group_survey_template_modal_info;
    },
    
    getGroupSurveyChoiceListModalInfo: (state) => {
        return state.group_survey_choice_list_modal_info;
    },

    getGroupFileboxMoveModalInfo: (state) => {
        return state.group_filebox_move_modal_info;
    },
    
    /**
    * 공통
    */
    getImageCropModalInfo: (state) => {
        return state.image_crop_modal_info;
    },
    
    getMessageModalInfo: (state) => {
        return state.message_modal_info;
    },
    
    getCommonColorPickerModalInfo: (state) => {
        return state.common_color_picker_modal_info;
    },
    
    getCommonInputModalInfo: (state) => {
        return state.common_input_modal_info;
    },

    getCommonDatepickerModalInfo: (state) => {
        return state.common_datepicker_modal_info;
    },

    getCommonSelectFriendAndGroupTeamModalInfo: (state) => {
        return state.common_select_friend_and_group_team_modal_info;
    },

    getCommonPremiumExpireModalInfo: (state) => {
        return state.common_premium_expire_modal_info;
    },

    /**
    * HODU D
    */
    getAppointmentCreateInfo: (state) => {
        return state.appointment_create_modal_info;
    },
    
    getDoctorTimeSettingModalInfo: (state) => {
        return state.doctor_time_setting_modal_info;
    },
    
    getDoctorOffSettingModalInfo: (state) => {
        return state.doctor_off_setting_modal_info;
    },
    
    getDayOffSettingModalInfo: (state) => {
        return state.day_off_setting_modal_info;
    },
    
    isShowPatientAddModal: (state) => {
        return state.show_patient_add_modal;
    },
    
    getPatientSendMessageModalInfo: (state) => {
        return state.patient_send_message_modal_info;
    },
    
    isShowDepartmentSettingModal: (state) => {
        return state.department_setting_modal_info;
    },
    
    isShowHospitalOffSettingModal: (state) => {
        return state.show_hospital_off_setting_modal;
    },
    
    getAppointmentApplyModalInfo: (state) => {
        return state.appointment_apply_modal_info;
    },
    
    getAppointmentListModalInfo: (state) => {
        return state.appointment_list_modal_info;
    },
    
    getHospitalTimeSettingModalInfo: (state) => {
        return state.hospital_time_setting_modal_info;
    },
    
    getLoginHospitalSelectInfo: (state) => {
        return state.login_hospital_select_modal_info;
    },
    
    /**
    * HODU H
    */
    getHomeBuildingCreateModalInfo : (state) => {
        return state.home_building_create_modal_info;
    },
    
    getHomeContactCreateModalInfo : (state) => {
        return state.home_contact_create_modal_info;
    },
    
    getHomeResidentCarRequestModalInfo : (state) => {
        return state.home_resident_car_request_modal_info;
    },
    
    getHomeResidentInfoModalInfo : (state) => {
        return state.home_resident_info_modal_info;
    },
    
    getHomeVisitantCreateModalInfo : (state) => {
        return state.home_visitant_create_modal_info;
    },
    
    getHomeVisitantInfoModalInfo : (state) => {
        return state.home_visitant_info_modal_info;
    },

    getEquipmentManagementDetailModalInfo : (state) => {
        return state.equipment_management_detail_modal_info;
    },

    getSupplyManagementDetailModalInfo : (state) => {
        return state.supply_management_detail_modal_info;
    },

    getHomeVisitantAmanoInfoModalInfo : (state) => {
        return state.home_visitant_amano_info_modal_info;
    },

    getHomeResidentJoinModalInfo : (state) => {
        return state.home_resident_join_modal_info;
    },
    
    /**
    * 채팅방
    */
    getChattingRoomCreateModalInfo: (state) => {
        return state.chatting_room_create_modal_info;
    },
    
    getChattingRoomInviteConfirmModalInfo: (state) => {
        return state.chatting_room_invite_confirm_modal_info;
    },
    
    isShowChattingRoomInviteModal: (state) => {
        return state.show_chatting_room_invite_modal;
    },
    
    getChattingRoomInfoModalInfo: (state) => {
        return state.chatting_room_info_modal_info;
    },

    /**
     * 전자결재
     */
    getApprovalMessageModalInfo: (state) => {
        return state.approval_message_modal_info;
    },

    getApproverSelectModalInfo: (state) => {
        return state.approver_select_modal_info;
    },

    getApprovalProcessModalInfo: (state) => {
        return state.approval_process_modal_info;
    },

    getApprovalTemplateExceptFileModalInfo: (state) => {
        return state.approval_template_except_file_modal_info;
    },

    getApprovalFileModalInfo: (state) => {
        return state.approval_file_modal_info;
    },

    getApprovalFormModifyModalInfo: (state) => {
        return state.approval_form_modify_modal_info;
    },

    getApprovalApproverFavoriteModalInfo: (state) => {
        return state.approval_approver_favorite_modal_info;
    },

    getApprovalReplyModalInfo: (state) => {
        return state.approval_reply_modal_info;
    },

    /**
     * 조직도 (근태관리, 전자결재 사용)
     */
    getOrganizationModalInfo: (state) => {
        return state.organization_modal_info;
    },

    getOrganizationModal2Info: (state) => {
        return state.organization_modal2_info;
    },

    getOrganizationVacationTypeModalInfo: (state) => {
        return state.organization_vacation_type_modal_info;
    },
    
    getOrganizationWorkTypeDetailModalInfo: (state) => {
        return state.organization_work_type_detail_modal_info;
    },

    getOrganizationWorkTypeAddModalInfo : (state) => {
        return state.organization_work_type_add_modal_info;
    },

    getOrganizationOffModalInfo : (state) => {
        return state.organization_off_modal_info;
    },

    getOrganizationOffAddModalInfo : (state) => {
        return state.organization_off_add_modal_info;
    },

    getOrganizationVacationAddModlInfo : (state) => {
        return state.organization_vacation_add_modal_info;
    },

    getOrganizationVacationDetailModalInfo : (state) => {
        return state.organization_vacation_detail_modal_info;
    },

    getOrganizationMyVacationDetailModalInfo : (state) => {
        return state.organization_my_vacation_detail_modal_info;
    },

    getOrganizationSignModalInfo : (state) => {
        return state.organization_sign_modal_info;
    },

    getOrganizationEmpPersonalRecordDetailModalInfo : (state) => {
        return state.organization_emp_personal_record_detail_modal_info;
    },

    getOrganizationEmpPersonalRecordImportModalInfo : (state) => {
        return state.organization_emp_personal_record_import_modal_info;
    },

    getOrganizationEmpPersonalRecordPrintModalInfo : (state) => {
        return state.organization_emp_personal_record_print_modal_info;
    },

    getOrganizationDefaultEmpOffModalInfo : (state) => {
        return state.organization_default_emp_off_modal_info;
    },

    /**
     * 근태관리
     */
    getAttendanceRequestModalInfo : (state) => {
        return state.attendance_request_modal_info;
    },

};

// action
export const actions: ActionTree<ModalInfoState, ModalInfoState> = {
    
    doSetShowEventShortCreate: ({commit, state}, show_event_short_create : boolean) => {
        console.log("actions.doSetShowEventShortCreate");
        commit("setShowEventShortCreate", show_event_short_create);
    },
    
    doSetShowEventRepeatModify: ({commit, state}, show_event_repeat_modify : boolean) => {
        console.log("actions.doSetShowEventRepeatModify");
        commit("setShowEventRepeatModify", show_event_repeat_modify);
    },
    
    doSetShowEventDaumMap: ({commit, state}, show_event_daum_map : boolean) => {
        console.log("actions.doSetShowEventDaumMap");
        commit("setShowEventDaumMap", show_event_daum_map);
    },
    
    doSetShowEventImagePreview: ({commit, state}, show_event_image_preview : boolean) => {
        console.log("actions.doSetShowEventImagePreview");
        commit("setShowEventImagePreview", show_event_image_preview);
    },
    
    doSetShowEventShare: ({commit, state}, show_event_share : boolean) => {
        console.log("actions.doSetShowEventShare");
        commit("setShowEventShare", show_event_share);
    },
    
    doSetEventShareStatusInfo: ({commit, state}, event_share_status_info : EventShareStatusInfo) => {
        console.log("actions.doSetEventShareStatusInfo");
        commit("setEventShareStatusInfo", event_share_status_info);
    },
    
    doSetEventEmailShareModalInfo: ({commit, state}, event_email_share_modal_info : EventEmailShareModalInfo) => {
        console.log("actions.doSetEventEmailShareModalInfo");
        commit("setEventEmailShareModalInfo", event_email_share_modal_info);
    },
    
    doSetShowEventAttendResult: ({commit, state}, show_event_attend_result : boolean) => {
        console.log("actions.doSetShowEventAttendResult");
        commit("setShowEventAttendResult", show_event_attend_result);
    },
    
    doSetShowEventVoteCreate: ({commit, state}, show_event_vote_create : boolean) => {
        console.log("actions.doSetShowEventVoteCreate");
        commit("setShowEventVoteCreate", show_event_vote_create);
    },
    
    doSetShowEventVoteResult: ({commit, state}, show_event_vote_result : boolean) => {
        console.log("actions.doSetShowEventVoteResult");
        commit("setShowEventVoteResult", show_event_vote_result);
    },
    
    doSetShowEventReply: ({commit, state}, show_event_reply : boolean) => {
        console.log("actions.doSetShowEventReply");
        commit("setShowEventReply", show_event_reply);
    },
    
    doSetEventWorkAssignmentInfo: ({commit, state}, event_work_assignment_info : EventWorkAssignmentInfo) => {
        console.log("actions.doSetEventWorkAssignmentInfo");
        commit("setEventWorkAssignmentInfo", event_work_assignment_info);
    },
    
    doSetShowEventWorkProcess: ({commit, state}, show_event_work_process : boolean) => {
        console.log("actions.doSetShowEventWorkProcess");
        commit("setShowEventWorkProcess", show_event_work_process);
    },
    
    doSetShowWorkStatus: ({commit, state}, show_work_status : boolean) => {
        console.log("actions.doSetShowWorkStatus");
        commit("setShowWorkStatus", show_work_status);
    },
    
    doSetShowWorkDefaultStatus: ({commit, state}, show_work_default_status : boolean) => {
        console.log("actions.doSetShowWorkDefaultStatus");
        commit("setShowWorkDefaultStatus", show_work_default_status);
    },

    doSetShowWorkPdfModal: ({commit, state}, show_work_pdf_modal : boolean) => {
        console.log("actions.doSetShowWorkPdfModal");
        state.show_work_pdf_modal = show_work_pdf_modal;
    },
    
    doSetGroupModalInfo: ({commit, state}, group_modal_info : GroupModalInfo) => {
        console.log("actions.doSetGroupModalInfo");
        commit("setGroupModalInfo", group_modal_info);
    },
    
    doSetShowGroupToBizModal: ({commit, state}, show_group_to_biz_modal : boolean) => {
        console.log("actions.doSetShowGroupToBizModal");
        commit("setShowGroupToBizModal", show_group_to_biz_modal);
    },
    
    doSetGroupTeamOtherOutModalInfo: ({commit, state}, group_team_other_out_info : GroupTeamOtherOutModalInfo) => {
        console.log("actions.doSetGroupTeamOtherOutModalInfo");
        commit("setGroupTeamOtherOutModalInfo", group_team_other_out_info);
    },
    
    doSetGroupNoticeReadInfo: ({commit, state}, group_notice_read_info : GroupNoticeReadInfo) => {
        console.log("actions.doSetGroupNoticeReadInfo");
        commit("setGroupNoticeReadInfo", group_notice_read_info);
    },

    doSetGroupBoardReadInfo: ({commit, state}, group_board_read_info : GroupBoardReadInfo) => {
        console.log("actions.doSetGroupBoardReadInfo");
        commit("setGroupBoardReadInfo", group_board_read_info);
    },
    
    doSetLoginHospitalModalInfo: ({commit, state}, login_hospital_select_modal_info : LoginHospitalModalInfo) => {
        console.log("actions.doSetLoginHospitalModalInfo");
        commit("setLoginHospitalModalInfo", login_hospital_select_modal_info);
    },
    
    doSetMessageSendStatus: ({commit, state}, show_message_send_status : boolean) => {
        console.log("actions.doSetMessageSendStatus");
        commit("setMessageSendStatus", show_message_send_status);
    },
    
    doSetShowGroupAddFileBox: ({commit, state}, show_group_add_file_box : boolean) => {
        console.log("actions.doSetShowGroupAddFileBox");
        commit("setShowGroupAddFileBox", show_group_add_file_box);
    },
    
    doSetGroupAddFileModalInfo: ({commit, state}, group_add_file_modal_info : GroupAddFileModalInfo) => {
        console.log("actions.doSetGroupAddFileModalInfo");
        commit("setGroupAddFileModalInfo", group_add_file_modal_info);
    },
    
    doSetGroupAddFileModalNewInfo: ({commit, state}, group_add_file_box_new_info : GroupAddFileModalNewInfo) => {
        console.log("actions.doSetGroupAddFileModalNewInfo");
        commit("setGroupAddFileModalNewInfo", group_add_file_box_new_info);
    },
    
    doSetEventImportModalInfo: ({commit, state}, event_import_modal_info : EventImportModalInfo) => {
        console.log("actions.doSetEventImportModalInfo");
        commit("setEventImportModalInfo", event_import_modal_info);
    },

    doSetGroupNoticeReplyModalInfo: ({commit, state}, group_notice_reply_modal_info : GroupNoticeReplyModalInfo) => {
        console.log(`actions.doSetGroupNoticeReplyModalInfo ${JSON.stringify(group_notice_reply_modal_info)}`);
        state.group_notice_reply_modal_info.show_modal = group_notice_reply_modal_info.show_modal;
        state.group_notice_reply_modal_info.noti_uid = group_notice_reply_modal_info.noti_uid;
        state.group_notice_reply_modal_info.callback = group_notice_reply_modal_info.callback;
    },

    doSetGroupBoardReplyModalInfo: ({commit, state}, group_board_reply_modal_info : GroupBoardReplyModalInfo) => {
        console.log(`actions.doSetGroupBoardReplyModalInfo ${JSON.stringify(group_board_reply_modal_info)}`);
        state.group_board_reply_modal_info.show_modal = group_board_reply_modal_info.show_modal;
        state.group_board_reply_modal_info.group_id = group_board_reply_modal_info.group_id;
        state.group_board_reply_modal_info.board_id = group_board_reply_modal_info.board_id;
        state.group_board_reply_modal_info.callback = group_board_reply_modal_info.callback;
    },

    doSetEventHistoryModalInfo: ({commit, state}, event_history_modal_info : EventHistoryModalInfo) => {
        console.log(`actions.doSetEventHistoryModalInfo ${JSON.stringify(event_history_modal_info)}`);
        state.event_history_modal_info.show_modal = event_history_modal_info.show_modal;
        state.event_history_modal_info.event_id = event_history_modal_info.event_id;
    },

    doSetEventExportModalInfo: ({commit, state}, event_export_modal_info : EventExportModalInfo) => {
        console.log(`actions.doSetEventExportModalInfo ${JSON.stringify(event_export_modal_info)}`);
        state.event_export_modal_info.show_modal = event_export_modal_info.show_modal;
    },

    doSetEventReadModalInfo: ({commit, state}, event_read_modal_info : EventReadModalInfo) => {
        console.log(`actions.doSetEventReadModalInfo ${JSON.stringify(event_read_modal_info)}`);
        state.event_read_modal_info.show_modal = event_read_modal_info.show_modal;
        state.event_read_modal_info.event_id = event_read_modal_info.event_id;
        state.event_read_modal_info.repeat_seq = event_read_modal_info.repeat_seq;
        state.event_read_modal_info.calendar_id = event_read_modal_info.calendar_id;
        state.event_read_modal_info.owner_type = event_read_modal_info.owner_type;
        state.event_read_modal_info.owner_id = event_read_modal_info.owner_id;
    },

    doSetGroupMemberImportModalInfo: ({commit, state}, group_member_import_modal_info : GroupMemberImportModalInfo) => {
        console.log(`actions.doSetGroupMemberImportModalInfo ${JSON.stringify(group_member_import_modal_info)}`);
        state.group_member_import_modal_info.show_modal = group_member_import_modal_info.show_modal;
        state.group_member_import_modal_info.group_id = group_member_import_modal_info.group_id;
        state.group_member_import_modal_info.team_id = group_member_import_modal_info.team_id;
    },

    doSetEquipmentImportModalInfo: ({commit, state}, equipment_import_modal_info : EquipmentImportModalInfo) => {
        console.log(`actions.doSetEquipmentImportModalInfo ${JSON.stringify(equipment_import_modal_info)}`);
        state.equipment_import_modal_info.show_modal = equipment_import_modal_info.show_modal;
        state.equipment_import_modal_info.group_id = equipment_import_modal_info.group_id;
    },

    doSetGroupSurveyTemplateModalInfo: ({commit, state}, group_survey_template_modal_info : GroupSurveyTemplateModalInfo) => {
        console.log(`actions.doSetGroupSurveyTemplateModalInfo ${JSON.stringify(group_survey_template_modal_info)}`);
        state.group_survey_template_modal_info.show_modal = group_survey_template_modal_info.show_modal;
        state.group_survey_template_modal_info.callback = group_survey_template_modal_info.callback;
    },

    doSetGroupSurveyChoiceListModalInfo: ({commit, state}, group_survey_choice_list_modal_info : GroupSurveyChoiceListModalInfo) => {
        console.log(`actions.doSetGroupSurveyChoiceListModalInfo ${JSON.stringify(group_survey_choice_list_modal_info)}`);
        state.group_survey_choice_list_modal_info.show_modal = group_survey_choice_list_modal_info.show_modal;
        state.group_survey_choice_list_modal_info.data = group_survey_choice_list_modal_info.data;
    },

    doSetGroupFileboxMoveModalInfo: ({commit, state}, group_filebox_move_modal_info : GroupFileboxMoveModalInfo) => {
        console.log(`actions.doSetGroupFileboxMoveModalInfo ${JSON.stringify(group_filebox_move_modal_info)}`);
        state.group_filebox_move_modal_info.show_modal = group_filebox_move_modal_info.show_modal;
        state.group_filebox_move_modal_info.type = group_filebox_move_modal_info.type;
        state.group_filebox_move_modal_info.file_uuid = group_filebox_move_modal_info.file_uuid;
        state.group_filebox_move_modal_info.dir_uuid = group_filebox_move_modal_info.dir_uuid;
        state.group_filebox_move_modal_info.callback = group_filebox_move_modal_info.callback;
    },
    
    /**
    * 공통
    */
    doSetImageCropModalInfo: ({commit, state}, image_crop_modal_info : ImageCropModalInfo) => {
        console.log("actions.doSetImageCropModalInfo");
        commit("setImageCropModalInfo", image_crop_modal_info);
    },
    
    doSetMessageModalInfo: ({commit, state}, message_modal_info : MessageModalInfo) => {
        console.log("actions.doSetMessageModalInfo");
        commit("setMessageModalInfo", message_modal_info);
    },
    
    doSetCommonColorPickerModalInfo: ({commit, state}, common_color_picker_modal_info : CommonColorPickerModalInfo) => {
        console.log("actions.doSetCommonColorPickerModalInfo");
        commit("setCommonColorPickerModalInfo", common_color_picker_modal_info);
    },

    doSetCommonInputModalInfo: ({commit, state}, common_input_modal_info : CommonInputModalInfo) => {
        console.log("actions.doSetCommonInputModalInfo");
        state.common_input_modal_info.show_modal  = common_input_modal_info.show_modal;
        state.common_input_modal_info.title       = common_input_modal_info.title;
        state.common_input_modal_info.subtitle    = common_input_modal_info.subtitle;
        state.common_input_modal_info.content     = common_input_modal_info.content;
        state.common_input_modal_info.placeholder = common_input_modal_info.placeholder;
        state.common_input_modal_info.radio       = common_input_modal_info.radio;
        state.common_input_modal_info.is_password = common_input_modal_info.is_password;
        state.common_input_modal_info.cancel      = common_input_modal_info.cancel;
        state.common_input_modal_info.save        = common_input_modal_info.save;
        state.common_input_modal_info.save_text   = common_input_modal_info.save_text;
    },

    doSetCommonDatepickerModalInfo: ({commit, state}, common_datepicker_modal_info : CommonDatepickerModalInfo) => {
        console.log("actions.doSetCommonInputModalInfo");
        state.common_datepicker_modal_info.show_modal   = common_datepicker_modal_info.show_modal;
        state.common_datepicker_modal_info.default_date = common_datepicker_modal_info.default_date;
        state.common_datepicker_modal_info.callback     = common_datepicker_modal_info.callback;
    },

    doSetCommonSelectFriendAndGroupTeamModalInfo: ({commit, state}, common_select_friend_and_group_team_modal_info : CommonSelectFriendAndGroupTeamModalInfo) => {
        console.log(`actions.doSetCommonSelectFriendAndGroupTeamModalInfo ${JSON.stringify(common_select_friend_and_group_team_modal_info)}`);
        state.common_select_friend_and_group_team_modal_info.show_modal = common_select_friend_and_group_team_modal_info.show_modal;
        state.common_select_friend_and_group_team_modal_info.title = common_select_friend_and_group_team_modal_info.title;
        state.common_select_friend_and_group_team_modal_info.share_option = common_select_friend_and_group_team_modal_info.share_option;
        state.common_select_friend_and_group_team_modal_info.event_sub_type = common_select_friend_and_group_team_modal_info.event_sub_type;
        state.common_select_friend_and_group_team_modal_info.event_crud_type = common_select_friend_and_group_team_modal_info.event_crud_type;
        state.common_select_friend_and_group_team_modal_info.event_calendar_id = common_select_friend_and_group_team_modal_info.event_calendar_id;
        state.common_select_friend_and_group_team_modal_info.event_scope = common_select_friend_and_group_team_modal_info.event_scope;
        state.common_select_friend_and_group_team_modal_info.event_scope_id = common_select_friend_and_group_team_modal_info.event_scope_id;
        state.common_select_friend_and_group_team_modal_info.event_id = common_select_friend_and_group_team_modal_info.event_id; 
        state.common_select_friend_and_group_team_modal_info.event_start = common_select_friend_and_group_team_modal_info.event_start; 
        state.common_select_friend_and_group_team_modal_info.event_end = common_select_friend_and_group_team_modal_info.event_end; 
        state.common_select_friend_and_group_team_modal_info.event_title = common_select_friend_and_group_team_modal_info.event_title; 
        state.common_select_friend_and_group_team_modal_info.calendar_scope_group_id = common_select_friend_and_group_team_modal_info.calendar_scope_group_id; 
        state.common_select_friend_and_group_team_modal_info.calendar_scope_team_id = common_select_friend_and_group_team_modal_info.calendar_scope_team_id; 
        
        if( state.common_select_friend_and_group_team_modal_info.selected_friend_user_ids == null || common_select_friend_and_group_team_modal_info.selected_friend_user_ids == null ) {
            state.common_select_friend_and_group_team_modal_info.selected_friend_user_ids = common_select_friend_and_group_team_modal_info.selected_friend_user_ids;
        }
        else {
            state.common_select_friend_and_group_team_modal_info.selected_friend_user_ids.splice(0, state.common_select_friend_and_group_team_modal_info.selected_friend_user_ids.length);
            state.common_select_friend_and_group_team_modal_info.selected_friend_user_ids = state.common_select_friend_and_group_team_modal_info.selected_friend_user_ids.concat(common_select_friend_and_group_team_modal_info.selected_friend_user_ids);
        }

        if( state.common_select_friend_and_group_team_modal_info.selected_user_ids == null || common_select_friend_and_group_team_modal_info.selected_user_ids == null ) {
            state.common_select_friend_and_group_team_modal_info.selected_user_ids = common_select_friend_and_group_team_modal_info.selected_user_ids;
        }
        else {
            state.common_select_friend_and_group_team_modal_info.selected_user_ids.splice(0, state.common_select_friend_and_group_team_modal_info.selected_user_ids.length);
            state.common_select_friend_and_group_team_modal_info.selected_user_ids = state.common_select_friend_and_group_team_modal_info.selected_user_ids.concat(common_select_friend_and_group_team_modal_info.selected_user_ids);
        }
        
        if( state.common_select_friend_and_group_team_modal_info.selected_group_ids == null || common_select_friend_and_group_team_modal_info.selected_group_ids == null ) {
            state.common_select_friend_and_group_team_modal_info.selected_group_ids = common_select_friend_and_group_team_modal_info.selected_group_ids;
        }
        else {
            state.common_select_friend_and_group_team_modal_info.selected_group_ids.splice(0, state.common_select_friend_and_group_team_modal_info.selected_group_ids.length);
            state.common_select_friend_and_group_team_modal_info.selected_group_ids = state.common_select_friend_and_group_team_modal_info.selected_group_ids.concat(common_select_friend_and_group_team_modal_info.selected_group_ids);
        }

        if( state.common_select_friend_and_group_team_modal_info.selected_team_ids == null || common_select_friend_and_group_team_modal_info.selected_team_ids == null ) {
            state.common_select_friend_and_group_team_modal_info.selected_team_ids = common_select_friend_and_group_team_modal_info.selected_team_ids;
        }
        else {
            state.common_select_friend_and_group_team_modal_info.selected_team_ids.splice(0, state.common_select_friend_and_group_team_modal_info.selected_team_ids.length);
            state.common_select_friend_and_group_team_modal_info.selected_team_ids = state.common_select_friend_and_group_team_modal_info.selected_team_ids.concat(common_select_friend_and_group_team_modal_info.selected_team_ids);
        }

        if( state.common_select_friend_and_group_team_modal_info.group_user_selected_key == null || common_select_friend_and_group_team_modal_info.group_user_selected_key == null ) {
            state.common_select_friend_and_group_team_modal_info.group_user_selected_key = common_select_friend_and_group_team_modal_info.group_user_selected_key;
        }
        else {
            state.common_select_friend_and_group_team_modal_info.group_user_selected_key.splice(0, state.common_select_friend_and_group_team_modal_info.group_user_selected_key.length);
            state.common_select_friend_and_group_team_modal_info.group_user_selected_key = state.common_select_friend_and_group_team_modal_info.group_user_selected_key.concat(common_select_friend_and_group_team_modal_info.group_user_selected_key);
        }

        if( state.common_select_friend_and_group_team_modal_info.team_user_selected_key == null || common_select_friend_and_group_team_modal_info.team_user_selected_key == null ) {
            state.common_select_friend_and_group_team_modal_info.team_user_selected_key = common_select_friend_and_group_team_modal_info.team_user_selected_key;
        }
        else {
            state.common_select_friend_and_group_team_modal_info.team_user_selected_key.splice(0, state.common_select_friend_and_group_team_modal_info.team_user_selected_key.length);
            state.common_select_friend_and_group_team_modal_info.team_user_selected_key = state.common_select_friend_and_group_team_modal_info.team_user_selected_key.concat(common_select_friend_and_group_team_modal_info.team_user_selected_key);
        }

        state.common_select_friend_and_group_team_modal_info.use_share_option = common_select_friend_and_group_team_modal_info.use_share_option;
        state.common_select_friend_and_group_team_modal_info.use_group_select_only = common_select_friend_and_group_team_modal_info.use_group_select_only;
        state.common_select_friend_and_group_team_modal_info.use_user_select_only = common_select_friend_and_group_team_modal_info.use_user_select_only;
        state.common_select_friend_and_group_team_modal_info.use_need_user_email = common_select_friend_and_group_team_modal_info.use_need_user_email;

        state.common_select_friend_and_group_team_modal_info.callback = common_select_friend_and_group_team_modal_info.callback;
    },

    doSetCommonPremiumExpireModalInfo: ({commit, state}, common_premium_expire_modal_info : CommonPremiumExpireModalInfo) => {
        console.log(`actions.doSetCommonPremiumExpireModalInfo ${JSON.stringify(common_premium_expire_modal_info)}`);
        state.common_premium_expire_modal_info.show_modal = common_premium_expire_modal_info.show_modal;
        state.common_premium_expire_modal_info.data = common_premium_expire_modal_info.data;
    },
    
    /**
    * HODU D
    */
    doSetAppointmentCreateModalInfo: ({commit, state}, appointment_create_modal_info : hodu_doc_modal_info.AppointmentCreateModalInfo) => {
        console.log("actions.doSetAppointmentCreateModalInfo");
        commit("setAppointmentCreateInfo", appointment_create_modal_info);
    },
    
    doSetDoctorTimeSettingModalInfo: ({commit, state}, doctor_time_setting_modal_info : hodu_doc_modal_info.DoctorTimeSettingModalInfo) => {
        console.log("actions.doSetDoctorTimeSettingModalInfo");
        commit("setDoctorTimeSettingModalInfo", doctor_time_setting_modal_info);
    },
    
    doSetDoctorOffSettingModalInfo: ({commit, state}, doctor_off_setting_modal_info : hodu_doc_modal_info.DoctorOffSettingModalInfo) => {
        console.log("actions.doSetDoctorOffSettingModalInfo");
        commit("setDoctorOffSettingModalInfo", doctor_off_setting_modal_info);
    },
    
    doSetDayOffSettingModalInfo: ({commit, state}, day_off_setting_modal_info : hodu_doc_modal_info.DayOffSettingModalInfo) => {
        console.log("actions.doSetDayOffSettingModalInfo");
        commit("setDayOffSettingModalInfo", day_off_setting_modal_info);
    },
    
    doSetShowPatientAddModal: ({commit, state}, show_patient_add_modal : boolean) => {
        console.log("actions.doSetShowPatientAddModal");
        commit("setShowPatientAddModal", show_patient_add_modal);
    },
    
    doSetPatientSendMessageModalInfo: ({commit, state}, patient_send_message_modal_info : boolean) => {
        console.log("actions.doSetPatientSendMessageModalInfo");
        commit("setPatientSendMessageModalInfo", patient_send_message_modal_info);
    },
    
    doSetDepartmentSettingModalInfo: ({commit, state}, department_setting_modal_info : hodu_doc_modal_info.DepartmentSettingModalInfo) => {
        console.log("actions.doSetDepartmentSettingModalInfo");
        commit("setDepartmentSettingModalInfo", department_setting_modal_info);
    },
    
    doSetShowHospitalOffSettingModal: ({commit, state}, show_hospital_off_setting_modal : boolean) => {
        console.log("actions.doSetShowHospitalOffSettingModal");
        commit("setShowHospitalOffSettingModal", show_hospital_off_setting_modal);
    },
    
    doSetAppointmentApplyModalInfo: ({commit, state}, appointment_apply_modal_info : hodu_doc_modal_info.AppointmentApplyModalInfo) => {
        console.log("actions.doSetAppointmentApplyModalInfo");
        commit("setAppointmentApplyModalInfo", appointment_apply_modal_info);
    },
    
    doSetAppointmentListModalInfo: ({commit, state}, appointment_list_modal_info : boolean) => {
        console.log("actions.doSetAppointmentListModalInfo");
        commit("setAppointmentListModalInfo", appointment_list_modal_info);
    },
    
    doSetHospitalTimeSettingModalInfo: ({commit, state}, hospital_time_setting_modal_info : hodu_doc_modal_info.HospitalTimeSettingModalInfo) => {
        console.log("actions.doSetHospitalTimeSettingModalInfo");
        commit("setHospitalTimeSettingModalInfo", hospital_time_setting_modal_info);
    },
    
    /**
    * HODU H
    */
    doSetHomeBuildingCreateModalInfo : ({commit, state}, home_building_create_modal_info : hodu_home_modal_info.HomeBuildingCreateModalInfo) => {
        console.log("actions.doSetHomeBuildingCreateModalInfo");
        state.home_building_create_modal_info.show_modal = home_building_create_modal_info.show_modal;
    },
    
    doSetHomeContactCreateModalInfo : ({commit, state}, home_contact_create_modal_info : hodu_home_modal_info.HomeContactCreateModalInfo) => {
        console.log("actions.doSetHomeContactCreateModalInfo");
        state.home_contact_create_modal_info.show_modal = home_contact_create_modal_info.show_modal;
        state.home_contact_create_modal_info.contact    = home_contact_create_modal_info.contact;
    },
    
    doSetHomeResidentCarRequestModalInfo : ({commit, state}, home_resident_car_request_modal_info : hodu_home_modal_info.HomeResidentCarRequestModalInfo) => {
        console.log("actions.doSetHomeResidentCarRequestModalInfo");
        state.home_resident_car_request_modal_info.show_modal = home_resident_car_request_modal_info.show_modal;
        state.home_resident_car_request_modal_info.resident = home_resident_car_request_modal_info.resident;
        state.home_resident_car_request_modal_info.car = home_resident_car_request_modal_info.car;
        state.home_resident_car_request_modal_info.callback = home_resident_car_request_modal_info.callback;
    },
    
    doSetHomeResidentInfoModalInfo : ({commit, state}, home_resident_info_modal_info : hodu_home_modal_info.HomeResidentInfoModalInfo) => {
        console.log("actions.doSetHomeResidentInfoModalInfo");
        state.home_resident_info_modal_info.show_modal = home_resident_info_modal_info.show_modal;
        state.home_resident_info_modal_info.resident = home_resident_info_modal_info.resident;
    },
    
    doSetHomeVisitantCreateModalInfo : ({commit, state}, home_visitant_create_modal_info : hodu_home_modal_info.HomeVisitantCreateModalInfo) => {
        console.log("actions.doSetHomeVisitantCreateModalInfo");
        state.home_visitant_create_modal_info.show_modal = home_visitant_create_modal_info.show_modal;
    },
    
    doSetHomeVisitantInfoModalInfo : ({commit, state}, home_visitant_info_modal_info : hodu_home_modal_info.HomeVisitantInfoModalInfo) => {
        console.log("actions.doSetHomeVisitantInfoModalInfo");
        state.home_visitant_info_modal_info.show_modal = home_visitant_info_modal_info.show_modal;
        state.home_visitant_info_modal_info.car        = home_visitant_info_modal_info.car;
    },

    doSetEquipmentManagementDetailModalInfo : ({commit, state}, equipment_management_detail_modal_info : hodu_home_modal_info.EquipmentManagementDetailModalInfo) => {
        console.log("actions.doSetEquipmentManagementDetailModalInfo");
        state.equipment_management_detail_modal_info.show_modal = equipment_management_detail_modal_info.show_modal;
        state.equipment_management_detail_modal_info.uid        = equipment_management_detail_modal_info.uid;
        state.equipment_management_detail_modal_info.seq        = equipment_management_detail_modal_info.seq;
        state.equipment_management_detail_modal_info.callback   = equipment_management_detail_modal_info.callback;
    },

    doSetSupplyManagementDetailModalInfo : ({commit, state}, supply_management_detail_modal_info : hodu_home_modal_info.SupplyManagementDetailModalInfo) => {
        console.log("actions.doSetSupplyManagementDetailModalInfo");
        state.supply_management_detail_modal_info.show_modal = supply_management_detail_modal_info.show_modal;
        state.supply_management_detail_modal_info.uid        = supply_management_detail_modal_info.uid;
        state.supply_management_detail_modal_info.seq        = supply_management_detail_modal_info.seq;
        state.supply_management_detail_modal_info.callback   = supply_management_detail_modal_info.callback;
    },

    doSetHomeVisitantAmanoInfoModalInfo : ({commit, state}, home_visitant_amano_info_modal_info : hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo) => {
        console.log("actions.doSetHomeVisitantAmanoInfoModalInfo");
        state.home_visitant_amano_info_modal_info.show_modal = home_visitant_amano_info_modal_info.show_modal;
        state.home_visitant_amano_info_modal_info.amano_info = (home_visitant_amano_info_modal_info.amano_info ? JSON.parse(JSON.stringify(home_visitant_amano_info_modal_info.amano_info)) : null);
    },

    doSetHomeResidentJoinModalInfo : ({commit, state}, home_resident_join_modal_info : hodu_home_modal_info.HomeResidentJoinModalInfo) => {
        console.log("actions.doSetHomeResidentJoinModalInfo");
        state.home_resident_join_modal_info.show_modal = home_resident_join_modal_info.show_modal;
        state.home_resident_join_modal_info.group_id = home_resident_join_modal_info.group_id;
        state.home_resident_join_modal_info.is_invite = home_resident_join_modal_info.is_invite;
    },

    /**
    * 채팅방
    */
    doSetChattingRoomCreateModalInfo: ({commit, state}, chatting_room_create_modal_info : chat_modal_interface.ChattingRoomCreateModalInfo) => {
        console.log(`actions.doSetChattingRoomCreateModalInfo ${JSON.stringify(chatting_room_create_modal_info)}`);
        state.chatting_room_create_modal_info.show_modal = chatting_room_create_modal_info.show_modal;
        state.chatting_room_create_modal_info.crud_type  = chatting_room_create_modal_info.crud_type;
        state.chatting_room_create_modal_info.callback   = chatting_room_create_modal_info.callback;
    },
    
    doSetChattingRoomInviteConfirmModalInfo: ({commit, state}, chatting_room_invite_confirm_modal_info : ChattingRoomInviteConfirmModalInfo) => {
        console.log(`actions.doSetChattingRoomInviteConfirmModalInfo ${JSON.stringify(chatting_room_invite_confirm_modal_info)}`);
        state.chatting_room_invite_confirm_modal_info.show_modal = chatting_room_invite_confirm_modal_info.show_modal;
        state.chatting_room_invite_confirm_modal_info.close_callback = chatting_room_invite_confirm_modal_info.close_callback;
        
        state.chatting_room_invite_confirm_modal_info.user_infos.splice(0, state.chatting_room_invite_confirm_modal_info.user_infos.length);
        for( const user_info of chatting_room_invite_confirm_modal_info.user_infos ) {
            state.chatting_room_invite_confirm_modal_info.user_infos.push(user_info);
        }
    },
    
    doSetShowChattingRoomInviteModal: ({commit, state}, show_chatting_room_invite_modal : boolean) => {
        console.log(`actions.doSetShowChattingRoomInviteModal ${JSON.stringify(show_chatting_room_invite_modal)}`);
        state.show_chatting_room_invite_modal = show_chatting_room_invite_modal;
    },
    
    doSetChattingRoomInfoModalInfo: ({commit, state}, chatting_room_info_modal_info : chat_modal_interface.ChattingRoomInfoModalInfo) => {
        console.log(`actions.doSetChattingRoomInfoModalInfo ${JSON.stringify(chatting_room_info_modal_info)}`);
        state.chatting_room_info_modal_info.show_modal = chatting_room_info_modal_info.show_modal;
    },

    /**
     * 전자결재
     */
    doSetApprovalMessageModalInfo: ({commit, state}, approval_message_modal_info : approval_modal_interface.ApprovalMessageModalInfo) => {
        console.log(`actions.doSetApprovalMessageModalInfo ${JSON.stringify(approval_message_modal_info)}`);
        state.approval_message_modal_info.show_modal = approval_message_modal_info.show_modal;
    },

    doSetApproverSelectModalInfo: ({commit, state}, approver_select_modal_info : approval_modal_interface.ApproverSelectModalInfo) => {
        console.log(`actions.doSetApproverSelectModalInfo ${JSON.stringify(approver_select_modal_info)}`);
        state.approver_select_modal_info.show_modal = approver_select_modal_info.show_modal;
        state.approver_select_modal_info.is_approver = approver_select_modal_info.is_approver;
        state.approver_select_modal_info.is_default_setting = approver_select_modal_info.is_default_setting;
        state.approver_select_modal_info.is_self_approve = approver_select_modal_info.is_self_approve;
        
        // 선택된 사람
        if( state.approver_select_modal_info.selected == null || approver_select_modal_info.selected == null ) {
            state.approver_select_modal_info.selected = approver_select_modal_info.selected;
        }
        else {
            state.approver_select_modal_info.selected.splice(0, state.approver_select_modal_info.selected.length);
            state.approver_select_modal_info.selected = state.approver_select_modal_info.selected.concat(approver_select_modal_info.selected as any[]);
        }

        state.approver_select_modal_info.callback = approver_select_modal_info.callback;

    },

    doSetApprovalProcessModalInfo: ({commit, state}, approval_process_modal_info : approval_modal_interface.ApprovalProcessModalInfo) => {
        console.log(`actions.doSetApprovalProcessModalInfo ${JSON.stringify(approval_process_modal_info)}`);
        state.approval_process_modal_info.show_modal = approval_process_modal_info.show_modal;
        state.approval_process_modal_info.callback = approval_process_modal_info.callback;
    },

    doSetApprovalTemplateExceptFileModalInfo: ({commit, state}, approval_template_except_file_modal_info : approval_modal_interface.ApprovalTemplateExceptFileModalInfo) => {
        console.log(`actions.doSetApprovalTemplateExceptFileModalInfo ${JSON.stringify(approval_template_except_file_modal_info)}`);
        state.approval_template_except_file_modal_info.show_modal = approval_template_except_file_modal_info.show_modal;
        state.approval_template_except_file_modal_info.callback = approval_template_except_file_modal_info.callback;
    },

    doSetApprovalFileModalInfo: ({commit, state}, approval_file_modal_info : approval_modal_interface.ApprovalFileModalInfo) => {
        console.log(`actions.doSetApprovalFileModalInfo ${JSON.stringify(approval_file_modal_info)}`);
        state.approval_file_modal_info.show_modal = approval_file_modal_info.show_modal;
        state.approval_file_modal_info.is_create = approval_file_modal_info.is_create;
        state.approval_file_modal_info.template_class_id = approval_file_modal_info.template_class_id;
        state.approval_file_modal_info.callback = approval_file_modal_info.callback;
    },

    doSetApprovalFormModifyModalInfo: ({commit, state}, approval_form_modify_modal_info : approval_modal_interface.ApprovalFormModifyModalInfo) => {
        console.log(`actions.doSetApprovalFormModifyModalInfo ${JSON.stringify(approval_form_modify_modal_info)}`);
        state.approval_form_modify_modal_info.show_modal        = approval_form_modify_modal_info.show_modal;
        state.approval_form_modify_modal_info.template_class_id = approval_form_modify_modal_info.template_class_id;
        state.approval_form_modify_modal_info.template_id       = approval_form_modify_modal_info.template_id;
        state.approval_form_modify_modal_info.callback          = approval_form_modify_modal_info.callback;
    },

    doSetApprovalApproverFavoriteModalInfo: ({commit, state}, approval_approver_favorite_modal_info : approval_modal_interface.ApprovalApproverFavoriteModalInfo) => {
        console.log(`actions.doSetApprovalApproverFavoriteModalInfo ${JSON.stringify(approval_approver_favorite_modal_info)}`);
        state.approval_approver_favorite_modal_info.show_modal = approval_approver_favorite_modal_info.show_modal;
        state.approval_approver_favorite_modal_info.favorite_id = approval_approver_favorite_modal_info.favorite_id;

        if( state.approval_approver_favorite_modal_info.approver == null || approval_approver_favorite_modal_info.approver == null ) {
            state.approval_approver_favorite_modal_info.approver = approval_approver_favorite_modal_info.approver;
        }
        else {
            state.approval_approver_favorite_modal_info.approver.splice(0, state.approval_approver_favorite_modal_info.approver.length);
            state.approval_approver_favorite_modal_info.approver = state.approval_approver_favorite_modal_info.approver.concat(approval_approver_favorite_modal_info.approver as any[]);
        }

        if( state.approval_approver_favorite_modal_info.receive_reference == null || approval_approver_favorite_modal_info.receive_reference == null ) {
            state.approval_approver_favorite_modal_info.receive_reference = approval_approver_favorite_modal_info.receive_reference;
        }
        else {
            state.approval_approver_favorite_modal_info.receive_reference.splice(0, state.approval_approver_favorite_modal_info.receive_reference.length);
            state.approval_approver_favorite_modal_info.receive_reference = state.approval_approver_favorite_modal_info.receive_reference.concat(approval_approver_favorite_modal_info.receive_reference as any[]);
        }

        state.approval_approver_favorite_modal_info.callback = approval_approver_favorite_modal_info.callback;
    },

    doSetApprovalReplyModalInfo: ({commit, state}, approval_reply_modal_info : approval_modal_interface.ApprovalReplyModalInfo) => {
        console.log(`actions.doSetApprovalReplyModalInfo ${JSON.stringify(approval_reply_modal_info)}`);
        state.approval_reply_modal_info.show_modal = approval_reply_modal_info.show_modal;
        state.approval_reply_modal_info.group_id = approval_reply_modal_info.group_id;
        state.approval_reply_modal_info.approval_uid = approval_reply_modal_info.approval_uid;
        state.approval_reply_modal_info.callback = approval_reply_modal_info.callback;
    },

    /**
     * 조직도 (근태관리, 전자결재 사용)
     */
    doSetOrganizationModalInfo: ({commit, state}, organization_modal_info : organization_modal_interface.OrganizationModalInfo) => {
        console.log(`actions.doSetOrganizationModalInfo ${JSON.stringify(organization_modal_info)}`);
        state.organization_modal_info.show_modal = organization_modal_info.show_modal;
        state.organization_modal_info.is_select_only = organization_modal_info.is_select_only;
        state.organization_modal_info.callback = organization_modal_info.callback;
    },

    doSetOrganizationModal2Info: ({commit, state}, organization_modal2_info : organization_modal_interface.OrganizationModal2Info) => {
        console.log(`actions.doSetApprovalFormDetailModal2Info ${JSON.stringify(organization_modal2_info)}`);
        state.organization_modal2_info.show_modal = organization_modal2_info.show_modal;
        state.organization_modal2_info.dept_id = organization_modal2_info.dept_id;
        state.organization_modal2_info.callback = organization_modal2_info.callback;
    },

    doSetOrganizationVacationTypeModalInfo: ({commit, state}, organization_vacation_type_modal_info : organization_modal_interface.OrganizationVacationTypeModalInfo) => {
        console.log(`actions.doSetOrganizationVacationTypeModalInfo ${JSON.stringify(organization_vacation_type_modal_info)}`);
        state.organization_vacation_type_modal_info.show_modal = organization_vacation_type_modal_info.show_modal;
        state.organization_vacation_type_modal_info.is_create = organization_vacation_type_modal_info.is_create;
        state.organization_vacation_type_modal_info.vacation_type_id = organization_vacation_type_modal_info.vacation_type_id;
        state.organization_vacation_type_modal_info.title = organization_vacation_type_modal_info.title;
        state.organization_vacation_type_modal_info.day = organization_vacation_type_modal_info.day;
        state.organization_vacation_type_modal_info.pay = organization_vacation_type_modal_info.pay;
        state.organization_vacation_type_modal_info.callback = organization_vacation_type_modal_info.callback;
    },

    doSetOrganizationWorkTypeDetailModalInfo: ({commit, state}, organization_work_type_detail_modal_info : organization_modal_interface.OrganizationWorkTypeDetailModalInfo) => {
        console.log(`actions.doSetOrganizationWorkTypeDetailModalInfo ${JSON.stringify(organization_work_type_detail_modal_info)}`);
        state.organization_work_type_detail_modal_info.show_modal = organization_work_type_detail_modal_info.show_modal;
        state.organization_work_type_detail_modal_info.work_type_id = organization_work_type_detail_modal_info.work_type_id;
        state.organization_work_type_detail_modal_info.callback = organization_work_type_detail_modal_info.callback;
    },

    doSetOrganizationWorkTypeAddModalInfo: ({commit, state}, organization_work_type_add_modal_info : organization_modal_interface.OrganizationWorkTypeAddModalInfo) => {
        console.log(`actions.doSetOrganizationWorkTypeAddModalInfo ${JSON.stringify(organization_work_type_add_modal_info)}`);
        state.organization_work_type_add_modal_info.show_modal = organization_work_type_add_modal_info.show_modal;
        state.organization_work_type_add_modal_info.is_create = organization_work_type_add_modal_info.is_create;
        state.organization_work_type_add_modal_info.work_type_id = organization_work_type_add_modal_info.work_type_id;
        state.organization_work_type_add_modal_info.callback = organization_work_type_add_modal_info.callback;
    },

    doSetOrganizationOffModalInfo: ({commit, state}, organization_off_modal_info : organization_modal_interface.OrganizationOffModalInfo) => {
        console.log(`actions.doSetOrganizationOffModalInfo ${JSON.stringify(organization_off_modal_info)}`);
        state.organization_off_modal_info.show_modal = organization_off_modal_info.show_modal;
        // state.organization_off_modal_info.is_create = organization_off_modal_info.is_create;
        state.organization_off_modal_info.user_id = organization_off_modal_info.user_id;
        // if( state.organization_off_modal_info.user_ids == null || organization_off_modal_info.user_ids == null ) {
        //     state.organization_off_modal_info.user_ids = organization_off_modal_info.user_ids;
        // }
        // else {
        //     state.organization_off_modal_info.user_ids.splice(0, state.organization_off_modal_info.user_ids.length);
        //     state.organization_off_modal_info.user_ids = state.organization_off_modal_info.user_ids.concat(organization_off_modal_info.user_ids);
        // }
        // state.organization_off_modal_info.is_only_setting_term = organization_off_modal_info.is_only_setting_term;
        state.organization_off_modal_info.callback = organization_off_modal_info.callback;
    },

    doSetOrganizationOffAddModalInfo: ({commit, state}, organization_off_add_modal_info : organization_modal_interface.OrganizationOffAddModalInfo) => {
        console.log(`actions.doSetOrganizationOffAddModalInfo ${JSON.stringify(organization_off_add_modal_info)}`);
        state.organization_off_add_modal_info.show_modal = organization_off_add_modal_info.show_modal;
        state.organization_off_add_modal_info.is_create = organization_off_add_modal_info.is_create;
        state.organization_off_add_modal_info.off_id = organization_off_add_modal_info.off_id;
        state.organization_off_add_modal_info.callback = organization_off_add_modal_info.callback;
    },

    doSetOrganizationVacationAddModlInfo: ({commit, state}, organization_vacation_add_modal_info : organization_modal_interface.OrganizationVacationAddModlInfo) => {
        console.log(`actions.doSetOrganizationVacationAddModlInfo ${JSON.stringify(organization_vacation_add_modal_info)}`);
        state.organization_vacation_add_modal_info.show_modal = organization_vacation_add_modal_info.show_modal;
        state.organization_vacation_add_modal_info.callback = organization_vacation_add_modal_info.callback;
    },

    doSetOrganizationVacationDetailModalInfo: ({commit, state}, organization_vacation_detail_modal_info : organization_modal_interface.OrganizationVacationDetailModalInfo) => {
        console.log(`actions.doSetOrganizationVacationDetailModalInfo ${JSON.stringify(organization_vacation_detail_modal_info)}`);
        state.organization_vacation_detail_modal_info.show_modal = organization_vacation_detail_modal_info.show_modal;
        state.organization_vacation_detail_modal_info.approval_uid = organization_vacation_detail_modal_info.approval_uid;
        state.organization_vacation_detail_modal_info.callback = organization_vacation_detail_modal_info.callback;
    },

    doSetOrganizationMyVacationDetailModalInfo: ({commit, state}, organization_my_vacation_detail_modal_info : organization_modal_interface.OrganizationMyVacationDetailModalInfo) => {
        console.log(`actions.doSetOrganizationMyVacationDetailModalInfo ${JSON.stringify(organization_my_vacation_detail_modal_info)}`);
        state.organization_my_vacation_detail_modal_info.show_modal = organization_my_vacation_detail_modal_info.show_modal;
        state.organization_my_vacation_detail_modal_info.approval_uid = organization_my_vacation_detail_modal_info.approval_uid;
        state.organization_my_vacation_detail_modal_info.callback = organization_my_vacation_detail_modal_info.callback;
    },

    doSetOrganizationSignModalInfo: ({commit, state}, organization_sign_modal_info : organization_modal_interface.OrganizationSignModalInfo) => {
        console.log(`actions.doSetOrganizationSignModalInfo ${JSON.stringify(organization_sign_modal_info)}`);
        state.organization_sign_modal_info.show_modal = organization_sign_modal_info.show_modal;
        state.organization_sign_modal_info.group_id = organization_sign_modal_info.group_id;
        state.organization_sign_modal_info.user_id = organization_sign_modal_info.user_id;
        state.organization_sign_modal_info.callback = organization_sign_modal_info.callback;
    },

    doSetOrganizationEmpPersonalRecordDetailModalInfo: ({commit, state}, organization_emp_personal_record_detail_modal_info : organization_modal_interface.OrganizationEmpPersonalRecordDetailModalInfo) => {
        console.log(`actions.doSetOrganizationEmpPersonalRecordDetailModalInfo ${JSON.stringify(organization_emp_personal_record_detail_modal_info)}`);
        state.organization_emp_personal_record_detail_modal_info.show_modal = organization_emp_personal_record_detail_modal_info.show_modal;
        state.organization_emp_personal_record_detail_modal_info.group_id = organization_emp_personal_record_detail_modal_info.group_id;
        state.organization_emp_personal_record_detail_modal_info.user_id = organization_emp_personal_record_detail_modal_info.user_id;
    },

    doSetOrganizationEmpPersonalRecordImportModalInfo: ({commit, state}, organization_emp_personal_record_import_modal_info : organization_modal_interface.OrganizationEmpPersonalRecordImportModalInfo) => {
        console.log(`actions.doSetOrganizationEmpPersonalRecordImportModalInfo ${JSON.stringify(organization_emp_personal_record_import_modal_info)}`);
        state.organization_emp_personal_record_import_modal_info.show_modal = organization_emp_personal_record_import_modal_info.show_modal;
        state.organization_emp_personal_record_import_modal_info.callback = organization_emp_personal_record_import_modal_info.callback;
    },

    doSetOrganizationEmpPersonalRecordPrintModalInfo: ({commit, state}, organization_emp_personal_record_print_modal_info : organization_modal_interface.OrganizationEmpPersonalRecordPrintModalInfo) => {
        console.log(`actions.doSetOrganizationEmpPersonalRecordPrintModalInfo ${JSON.stringify(organization_emp_personal_record_print_modal_info)}`);
        state.organization_emp_personal_record_print_modal_info.show_modal = organization_emp_personal_record_print_modal_info.show_modal;
        state.organization_emp_personal_record_print_modal_info.group_id = organization_emp_personal_record_print_modal_info.group_id;
        state.organization_emp_personal_record_print_modal_info.user_id = organization_emp_personal_record_print_modal_info.user_id;
        state.organization_emp_personal_record_print_modal_info.is_quitter = organization_emp_personal_record_print_modal_info.is_quitter;
    },

    doSetOrganizationDefaultEmpOffModalInfo: ({commit, state}, organization_default_emp_off_modal_info : organization_modal_interface.OrganizationDefaultEmpOffModalInfo) => {
        console.log(`actions.doSetOrganizationDefaultEmpOffModalInfo ${JSON.stringify(organization_default_emp_off_modal_info)}`);
        state.organization_default_emp_off_modal_info.show_modal = organization_default_emp_off_modal_info.show_modal;
        state.organization_default_emp_off_modal_info.group_id = organization_default_emp_off_modal_info.group_id;
        state.organization_default_emp_off_modal_info.callback = organization_default_emp_off_modal_info.callback;
    },

    /**
     * 근태관리
     */
    doSetAttendanceRequestModalInfo: ({commit, state}, attendance_request_modal_info : attendance_modal_interface.AttendanceRequestModalInfo) => {
        console.log(`actions.doSetAttendanceRequestModalInfo ${JSON.stringify(attendance_request_modal_info)}`);
        state.attendance_request_modal_info.show_modal = attendance_request_modal_info.show_modal;
        state.attendance_request_modal_info.user_id = attendance_request_modal_info.user_id;
        state.attendance_request_modal_info.yyyymmdd = attendance_request_modal_info.yyyymmdd;
        state.attendance_request_modal_info.callback = attendance_request_modal_info.callback;
    },
};

// mutation
export const mutations: MutationTree<ModalInfoState> = {
    
    setShowEventShortCreate: (state, payload : boolean) => {
        console.log(`mutations.setShowEventShortCreate ${JSON.stringify(payload)}`);
        state.show_event_short_create = payload;
    },
    
    setShowEventRepeatModify: (state, payload : boolean) => {
        console.log(`mutations.setShowEventRepeatModify ${JSON.stringify(payload)}`);
        state.show_event_repeat_modify = payload;
    },
    
    setShowEventDaumMap: (state, payload : boolean) => {
        console.log(`mutations.setShowEventDaumMap ${JSON.stringify(payload)}`);
        state.show_event_daum_map = payload;
    },
    
    setShowEventImagePreview: (state, payload : boolean) => {
        console.log(`mutations.setShowEventImagePreview ${JSON.stringify(payload)}`);
        state.show_event_image_preview = payload;
    },
    
    setShowEventShare: (state, payload : boolean) => {
        console.log(`mutations.setShowEventShare ${JSON.stringify(payload)}`);
        state.show_event_share = payload;
    },
    
    setEventShareStatusInfo: (state, payload : EventShareStatusInfo) => {
        console.log(`mutations.setEventShareStatusInfo ${JSON.stringify(payload)}`);
        state.event_share_status_info.show_event_share_status = payload.show_event_share_status;
        state.event_share_status_info.event_id                = payload.event_id;
        state.event_share_status_info.calendar_id             = payload.calendar_id;
        state.event_share_status_info.scope                   = payload.scope;
        state.event_share_status_info.scope_id                = payload.scope_id;
    },
    
    setEventEmailShareModalInfo: (state, payload : EventEmailShareModalInfo) => {
        console.log(`mutations.setEventEmailShareModalInfo ${JSON.stringify(payload)}`);
        state.event_email_share_modal_info.show_modal   = payload.show_modal;
        state.event_email_share_modal_info.calendar_id  = payload.calendar_id;
        state.event_email_share_modal_info.scope        = payload.scope;
        state.event_email_share_modal_info.scope_id     = payload.scope_id;
        state.event_email_share_modal_info.event_id     = payload.event_id;
        state.event_email_share_modal_info.start        = new Date(payload.start);
        state.event_email_share_modal_info.end          = new Date(payload.end);
        state.event_email_share_modal_info.title        = payload.title;
        state.event_email_share_modal_info.sender_email = payload.sender_email;
    },
    
    setShowEventAttendResult: (state, payload : boolean) => {
        console.log(`mutations.setShowEventAttendResult ${JSON.stringify(payload)}`);
        state.show_event_attend_result = payload;
    },
    
    setShowEventVoteCreate: (state, payload : boolean) => {
        console.log(`mutations.setShowEventVoteCreate ${JSON.stringify(payload)}`);
        state.show_event_vote_create = payload;
    },
    
    setShowEventVoteResult: (state, payload : boolean) => {
        console.log(`mutations.setShowEventVoteResult ${JSON.stringify(payload)}`);
        state.show_event_vote_result = payload;
    },
    
    setShowEventReply: (state, payload : boolean) => {
        console.log(`mutations.setShowEventReply ${JSON.stringify(payload)}`);
        state.show_event_reply = payload;
    },
    
    setEventWorkAssignmentInfo: (state, payload : EventWorkAssignmentInfo) => {
        console.log(`mutations.setEventWorkAssignmentInfo ${JSON.stringify(payload)}`);
        state.event_work_assignment_info.show_event_work_assignment = payload.show_event_work_assignment;
        
        state.event_work_assignment_info.selected_user_ids.splice(0, state.event_work_assignment_info.selected_user_ids.length);
        const seleted_user_ids_length : number = payload.selected_user_ids.length;
        for( let i = 0; i < seleted_user_ids_length; i++ ) {
            state.event_work_assignment_info.selected_user_ids.push(payload.selected_user_ids[i]);
        }
        
        state.event_work_assignment_info.is_group_member_select = payload.is_group_member_select;
        state.event_work_assignment_info.select_max_count = payload.select_max_count;
        
        state.event_work_assignment_info.confirm_function = payload.confirm_function;
    },
    
    setShowEventWorkProcess: (state, payload : boolean) => {
        console.log(`mutations.setShowEventWorkProcess ${JSON.stringify(payload)}`);
        state.show_event_work_process = payload;
    },
    
    setShowWorkStatus: (state, payload : boolean) => {
        console.log(`mutations.setShowWorkStatus ${JSON.stringify(payload)}`);
        state.show_work_status = payload;
    },
    
    setShowWorkDefaultStatus: (state, payload : boolean) => {
        console.log(`mutations.setShowWorkDefaultStatus ${JSON.stringify(payload)}`);
        state.show_work_default_status = payload;
    },
    
    setGroupModalInfo:(state, payload : GroupModalInfo) => {
        console.log(`mutations.setGroupModalInfo ${JSON.stringify(payload)}`);
        state.group_modal_info.show_group_list_confirm = payload.show_group_list_confirm;
        state.group_modal_info.data                    = payload.data;
    },
    
    setShowGroupToBizModal:(state, payload : boolean) => {
        console.log(`mutations.setShowGroupToBizModal ${JSON.stringify(payload)}`);
        state.show_group_to_biz_modal = payload;
    },
    
    setGroupTeamOtherOutModalInfo:(state, payload : GroupTeamOtherOutModalInfo) => {
        console.log(`mutations.setGroupTeamOtherOutModalInfo ${JSON.stringify(payload)}`);
        state.group_team_other_out_info.show_modal = payload.show_modal;
        state.group_team_other_out_info.data       = payload.data;
    },
    
    setGroupNoticeReadInfo:(state, payload : GroupNoticeReadInfo) => {
        console.log(`mutations.setGroupNoticeReadInfo ${JSON.stringify(payload)}`);
        state.group_notice_read_info.show_modal = payload.show_modal;
        state.group_notice_read_info.noti_uid   = payload.noti_uid;
    },

    setGroupBoardReadInfo:(state, payload : GroupBoardReadInfo) => {
        console.log(`mutations.setGroupBoardReadInfo ${JSON.stringify(payload)}`);
        state.group_board_read_info.show_modal = payload.show_modal;
        state.group_board_read_info.group_id = payload.group_id;
        state.group_board_read_info.board_id = payload.board_id;
    },
    
    setLoginHospitalModalInfo:(state, payload : LoginHospitalModalInfo) => {
        console.log(`mutations.setLoginHospitalModalInfo ${JSON.stringify(payload)}`);
        state.login_hospital_select_modal_info.show_modal    = payload.show_modal;
        state.login_hospital_select_modal_info.hospital_list = payload.hospital_list;
        state.login_hospital_select_modal_info.callback      = payload.callback;
    },
    
    setMessageSendStatus: (state, payload : boolean) => {
        console.log(`mutations.setMessageSendStatus ${JSON.stringify(payload)}`);
        state.show_message_send_status = payload;
    },
    
    setShowGroupAddFileBox: (state, payload : boolean) => {
        console.log(`mutations.setShowGroupAddFileBox ${JSON.stringify(payload)}`);
        state.show_group_add_file_box = payload;
    },
    
    setGroupAddFileModalInfo: (state, payload : GroupAddFileModalInfo) => {
        console.log(`mutations.setGroupAddFileModalInfo ${JSON.stringify(payload)}`);
        state.group_add_file_modal_info.is_create = payload.is_create;
        state.group_add_file_modal_info.dir_uuid = payload.dir_uuid;
        state.group_add_file_modal_info.dir_name = payload.dir_name;
        state.group_add_file_modal_info.group_id = payload.group_id;
    },
    
    setGroupAddFileModalNewInfo: (state, payload : GroupAddFileModalNewInfo) => {
        console.log(`mutations.setGroupAddFileModalNewInfo ${JSON.stringify(payload)}`);
        state.group_add_file_box_new_info.show_modal  = payload.show_modal;
        state.group_add_file_box_new_info.is_create   = payload.is_create;
        state.group_add_file_box_new_info.dir_uuid    = payload.dir_uuid;
        state.group_add_file_box_new_info.group_id    = payload.group_id;
        state.group_add_file_box_new_info.up_dir_uuid = payload.up_dir_uuid;
        
        state.group_add_file_box_new_info.create_function = payload.create_function;
        state.group_add_file_box_new_info.update_function = payload.update_function;
    },
    
    setEventImportModalInfo: (state, payload : EventImportModalInfo) => {
        console.log(`mutations.setEventImportModalInfo ${JSON.stringify(payload)}`);
        state.event_import_modal_info.show_modal = payload.show_modal;
    },
    
    /**
    * 공통
    */
    setImageCropModalInfo: (state, payload : ImageCropModalInfo) => {
        console.log(`mutations.setImageCropModalInfo ${JSON.stringify(payload)}`);
        state.image_crop_modal_info.show_modal       = payload.show_modal;
        state.image_crop_modal_info.crop_image_ratio = payload.crop_image_ratio;
        state.image_crop_modal_info.image_url        = payload.image_url;
        state.image_crop_modal_info.cancel_function  = payload.cancel_function;
        state.image_crop_modal_info.confirm_function = payload.confirm_function;
    },
    
    setMessageModalInfo: (state, payload : MessageModalInfo) => {
        console.log(`mutations.setMessageModalInfo ${JSON.stringify(payload)}`);
        state.message_modal_info.show_message_modal = payload.show_message_modal;
        state.message_modal_info.message_type       = payload.message_type;
        state.message_modal_info.message            = payload.message;
        
        state.message_modal_info.button_text.splice(0, state.message_modal_info.button_text.length);
        for( let i = 0; i < payload.button_text.length; i++) {
            state.message_modal_info.button_text.push(payload.button_text[i]);
        }
    },
    
    setCommonColorPickerModalInfo: (state, payload : CommonColorPickerModalInfo) => {
        console.log(`mutations.setCommonColorPickerModalInfo ${JSON.stringify(payload)}`);
        state.common_color_picker_modal_info.show_modal     = payload.show_modal;
        state.common_color_picker_modal_info.selected_color = payload.selected_color;
        state.common_color_picker_modal_info.is_dc_only     = payload.is_dc_only;
        state.common_color_picker_modal_info.dc_color       = payload.dc_color;
        state.common_color_picker_modal_info.lc_color       = payload.lc_color;
    },
    
    /**
    * HODU D
    */
    setAppointmentCreateInfo: (state, payload : hodu_doc_modal_info.AppointmentCreateModalInfo) => {
        console.log(`mutations.setAppointmentCreateInfo ${JSON.stringify(payload)}`);
        state.appointment_create_modal_info.show_modal            = payload.show_modal;
        state.appointment_create_modal_info.is_create_one_patient = payload.is_create_one_patient;
        state.appointment_create_modal_info.department_code       = payload.department_code;
        state.appointment_create_modal_info.doctor_code           = payload.doctor_code;
        state.appointment_create_modal_info.original_timetable    = payload.original_timetable;
        state.appointment_create_modal_info.patient_info          = payload.patient_info;
    },
    
    setDoctorTimeSettingModalInfo: (state, payload : hodu_doc_modal_info.DoctorTimeSettingModalInfo) => {
        console.log(`mutations.setDoctorTimeSettingModalInfo ${JSON.stringify(payload)}`);
        state.doctor_time_setting_modal_info.show_modal             = payload.show_modal;
        state.doctor_time_setting_modal_info.is_create_work_time    = payload.is_create_work_time;
        state.doctor_time_setting_modal_info.start_date             = payload.start_date;
        state.doctor_time_setting_modal_info.end_date               = payload.end_date;
        state.doctor_time_setting_modal_info.timetable              = payload.timetable;
    },
    
    setDoctorOffSettingModalInfo: (state, payload : hodu_doc_modal_info.DoctorOffSettingModalInfo) => {
        console.log(`mutations.setDoctorOffSettingModalInfo ${JSON.stringify(payload)}`);
        state.doctor_off_setting_modal_info.show_modal = payload.show_modal;
        state.doctor_off_setting_modal_info.is_create  = payload.is_create;
    },
    
    setDayOffSettingModalInfo: (state, payload : hodu_doc_modal_info.DayOffSettingModalInfo) => {
        console.log(`mutations.setDayOffSettingModalInfo ${JSON.stringify(payload)}`);
        state.day_off_setting_modal_info.show_modal = payload.show_modal;
        state.day_off_setting_modal_info.is_create  = payload.is_create;
        state.day_off_setting_modal_info.is_doctor  = payload.is_doctor;
        state.day_off_setting_modal_info.day_off    = payload.day_off;
    },
    
    setShowPatientAddModal: (state, payload : boolean) => {
        console.log(`mutations.setShowPatientAddModal ${JSON.stringify(payload)}`);
        state.show_patient_add_modal = payload;
    },
    
    setPatientSendMessageModalInfo: (state, payload : hodu_doc_modal_info.PatientSendMessageModalInfo) => {
        console.log(`mutations.setPatientSendMessageModalInfo ${JSON.stringify(payload)}`);
        state.patient_send_message_modal_info.show_modal = payload.show_modal;
        state.patient_send_message_modal_info.selected_patients.splice(0, state.patient_send_message_modal_info.selected_patients.length);
        for( const patient of payload.selected_patients ) { state.patient_send_message_modal_info.selected_patients.push(patient); }
    },
    
    setDepartmentSettingModalInfo: (state, payload : hodu_doc_modal_info.DepartmentSettingModalInfo) => {
        console.log(`mutations.setDepartmentSettingModalInfo ${JSON.stringify(payload)}`);
        state.department_setting_modal_info.show_modal = payload.show_modal;
        state.department_setting_modal_info.is_create  = payload.is_create;
        state.department_setting_modal_info.department = payload.department;
    },
    
    setShowHospitalOffSettingModal: (state, payload : boolean) => {
        console.log(`mutations.setShowHospitalOffSettingModal ${JSON.stringify(payload)}`);
        state.show_hospital_off_setting_modal = payload;
    },
    
    setAppointmentApplyModalInfo: (state, payload : hodu_doc_modal_info.AppointmentApplyModalInfo) => {
        console.log(`mutations.setAppointmentApplyModalInfo ${JSON.stringify(payload)}`);
        state.appointment_apply_modal_info.show_modal     = payload.show_modal;
        state.appointment_apply_modal_info.event_id       = payload.event_id;
        state.appointment_apply_modal_info.apply_callback = payload.apply_callback;
    },
    
    setAppointmentListModalInfo: (state, payload : hodu_doc_modal_info.AppointmentListModalInfo) => {
        console.log(`mutations.setAppointmentListModalInfo ${JSON.stringify(payload)}`);
        state.appointment_list_modal_info.show_modal = payload.show_modal;
        state.appointment_list_modal_info.events.splice(0, state.appointment_list_modal_info.events.length);
        for( const event of payload.events ) { state.appointment_list_modal_info.events.push(event); }
    },
    
    setHospitalTimeSettingModalInfo: (state, payload : hodu_doc_modal_info.HospitalTimeSettingModalInfo) => {
        console.log(`mutations.setHospitalTimeSettingModalInfo ${JSON.stringify(payload)}`);
        state.hospital_time_setting_modal_info.show_modal = payload.show_modal;
        state.hospital_time_setting_modal_info.start_date = payload.start_date;
        state.hospital_time_setting_modal_info.is_create  = payload.is_create;
    },
    
};      