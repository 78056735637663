
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class GroupFileBoxRecursion2 extends Mixins(VueHoduCommon) {

    @Prop() level !: number;
    @Prop() dirs !: any[];
    @Prop() dir_uuid !: string;
    @Prop() dir_closed_map !: Map<string, boolean>;

    mounted() : void {
        
    }

    selectFileBox(dir_uuid : string) {
        this.$emit('select', dir_uuid, false);
    }

    /**
     * 폴더 토글
     */
    toggleDir(dir : any, complete_password_input : boolean = false) : void {

        // if( dir.is_closed == true && dir.has_password == true && complete_password_input == false ) {
        //     this.password_input(dir);
        //     return;
        // }

        dir.is_closed = !dir.is_closed;
        this.$forceUpdate();
        this.dir_closed_map.set(dir.dir_uuid, dir.is_closed);
    }

    /**
     * 디렉토리 비밀번호 입력
     */
    // password_input(dir : any) {

    //     let uuid : string = dir.dir_uuid;

    //     this.doSetCommonInputModalInfo?.({
    //         show_modal : true,
    //         title : "비밀번호 입력",
    //         subtitle : "비밀번호",
    //         placeholder : "비밀번호를 입력해주세요",
    //         is_password : true,
    //         save_text : "확인",
    //         cancel : () => {

    //         },
    //         save : async(password) => {

    //             try {

    //                 const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
    //                     "password" : password 
    //                 });

    //                 if( !response || !this.isHttpStatusSuccess(response.status) ) {
    //                     throw new Error("파일함 비밀번호 체크 중 오류 발생");
    //                 }
                    
    //                 this.toggleDir(dir, true);

    //             } catch(e) {
    //                 this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
    //             }

    //         },
    //     });
    // }

}
