
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, CRUD_TYPE, EVENT_SUB_TYPE, OWNER_TYPE, SHARE_OPTION } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const WorkInfo = namespace('WorkInfo');

import ScheduleBoxDatepicker from '@/components/ui/ScheduleBoxDatepicker.vue';

import moment from 'moment';

import { t_event } from '@/model/event';

import { WorkSearchInfo } from '@/store/modules/WorkInfo';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ScheduleBoxDatepicker
    },
})
export default class MeetingLogListComp extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;
    @Prop() search_query !: string;

    get computedMeetingLogList() : t_event[] {

        let computed_list : t_event[] = [];
        computed_list = computed_list.concat(this.events);

        if( this.search_query != null && this.search_query.length > 0 ) {

            computed_list.splice(0, computed_list.length);

            // 제목, 참석자, 회의장소, 메모(업무 내용), 노트, 작성자에 검색이 매칭이 된다면
            computed_list = computed_list.concat(this.events.filter(event => this.hodu_string_includes(event.event_data.title, this.search_query) == true || 
                                                                             this.hodu_string_includes((event.event_data.attendees ? event.event_data.attendees : ""), this.search_query) == true ||
                                                                             this.hodu_string_includes((event.event_data.meeting_location ? event.event_data.meeting_location : ""), this.search_query) == true ||
                                                                             this.hodu_string_includes((event.event_data.memo ? event.event_data.memo : ""), this.search_query) == true ||
                                                                             this.hodu_string_includes((event.event_data.note ? event.event_data.note : ""), this.search_query) == true ||
                                                                             this.hodu_string_includes((event.event_data.event_owner_name ? event.event_data.event_owner_name : ""), this.search_query) == true));
            
        }

        // 정렬
        computed_list.sort((o1, o2) : number => {

            const o1_time = new Date(o1.event_data.schedule_date.start);
            const o2_time = new Date(o2.event_data.schedule_date.start);

            const o1_group_team = o1.team_id && o1.team_id > 0 ? (o1.event_data.event_owner_team_name ? o1.event_data.event_owner_team_name : "") : (o1.event_data.event_owner_group_name ? o1.event_data.event_owner_group_name : "");
            const o2_group_team = o2.team_id && o2.team_id > 0 ? (o2.event_data.event_owner_team_name ? o2.event_data.event_owner_team_name : "") : (o2.event_data.event_owner_group_name ? o2.event_data.event_owner_group_name : "");

            const o1_title = o1.event_data.title;
            const o2_title = o2.event_data.title;

            const o1_name = o1.event_data.event_owner_name ? o1.event_data.event_owner_name : "";
            const o2_name = o2.event_data.event_owner_name ? o2.event_data.event_owner_name : "";

            // 날짜
            if( this.sort_type == "TIME" ) {
                if( o1_time.getTime()  > o2_time.getTime() ) return this.sort_direction == "ASC" ?  1 : -1;
                else if( o1_time.getTime()  < o2_time.getTime() ) return this.sort_direction == "ASC" ? -1 :  1; 
                else if( o1_time.getTime() == o2_time.getTime() ) return 0;
            }

            // 그룹·팀 이름
            else if( this.sort_type == "GROUP_TEAM" ) {
                if( o1_group_team  > o2_group_team ) return this.sort_direction == "ASC" ?  1 : -1;
                else if( o1_group_team  < o2_group_team ) return this.sort_direction == "ASC" ? -1 :  1; 
                else if( o1_group_team == o2_group_team ) return 0;
            }

            // 제목
            else if( this.sort_type == "TITLE" ) {
                if( o1_title  > o2_title ) return this.sort_direction == "ASC" ?  1 : -1;
                else if( o1_title  < o2_title ) return this.sort_direction == "ASC" ? -1 :  1; 
                else if( o1_title == o2_title ) return 0;
            }

            // 작성자
            else if( this.sort_type == "NAME" ) {
                if( o1_name  > o2_name ) return this.sort_direction == "ASC" ?  1 : -1;
                else if( o1_name  < o2_name ) return this.sort_direction == "ASC" ? -1 :  1; 
                else if( o1_name == o2_name ) return 0;
            }

            return 0;
        });

        return computed_list;
    }

    /**
     * @WorkInfo.State
     */
    @WorkInfo.State meetinglog_search_info !: WorkSearchInfo;

    /**
     * @WorkInfo.Action
     */
    @WorkInfo.Action doSetMeetinglogSearchInfo ?: (params : WorkSearchInfo) => void;

    start : Date = new Date();
    end : Date = new Date();

    events : t_event[] = [];

    sort_type : string = "TIME";
    sort_direction : string = "DESC";

    timeout_ms = 500; // 새로고침시 권한 조회가 너무 느린경우 충돌을 일으킴 (이것은 임시방편이고 조회전 설정을 스토어에 등록해놓는것이 좋아보임)

    mounted() : void {
        this.event_bus?.$on('handleResize', this.handleResize);

        this.end = moment().set('hour',0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
        this.start = moment(this.end).add('week', -1).toDate();

        if( this.meetinglog_search_info.search_start_date ) {
            this.start = new Date(this.meetinglog_search_info.search_start_date);
        }

        if( this.meetinglog_search_info.search_end_date ) {
            this.end = new Date(this.meetinglog_search_info.search_end_date);
        }

        this.setScroll();
        this.getEvent();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height      : number | undefined = $('.title_box').outerHeight();
        const setting_bg_height : number | undefined = $('.settingBg').outerHeight();
        const header_height     : number | undefined = $('.sortHeader').outerHeight();

        const scroll_hegiht : number = window.innerHeight - ( title_height ? title_height : 0 ) 
                                                          - ( setting_bg_height ? setting_bg_height : 0 ) 
                                                          - ( header_height ? header_height : 0 );
        // @ts-ignore
        $('#meeting_log_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : scroll_hegiht
        });

    }

    /**
     * 업무일지 조회
     */
    @Watch('search_query', { deep : true, immediate : false })
    async getEvent() : Promise<void> { 
        if( this.scope == OWNER_TYPE.PERSONAL ) return;

        setTimeout(async() => {
            if( this.getWorkReadPermission() == false ) {
                this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
                return;
            }

            try {
                let owner_type  : string = this.scope;
                let owner_id    : number = this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id;
                let calendar_id : string = this.calendar_id;

                const start_query : string = moment(this.start).utc().format();
                const end_query : string = moment(this.end).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).utc().format();

                let query : string = `?start=${start_query}&end=${end_query}&mycal=false&syscal=false&shared=false`;

                query += this.scope == OWNER_TYPE.GROUP ? `&groups=${owner_id}` : `&teams=${owner_id}`;

                // 그룹 달력인 경우 가입된 팀도 조회한다
                if( this.scope == OWNER_TYPE.GROUP ) {
                    const role_size : number = this.user_team_role.length;
                    for( let i = 0; i < role_size; i++ ) {
                        const team_role = this.user_team_role[i];
                        if( team_role.team_id == null || team_role.team_id < 1 || this.scope_group_id != team_role.group_id ){
                            continue
                        }

                        query += `&teams=${Number(this.user_team_role[i].team_id)}`;
                    }
                }

                let groups : number[] = [];
                let teams : number[] = [];

                if( this.user_group_role != null && this.user_group_role.length > 0 ) {
                    for( const group_role of this.user_group_role ) {
                        if( group_role.group_id == null || group_role.group_id < 1 ) {
                            continue
                        }
                        groups.push(group_role.group_id);
                    }
                }

                if( this.user_team_role != null && this.user_team_role.length > 0 ) {
                    for( const team_role of this.user_team_role ) {
                        if( team_role.team_id == null || team_role.team_id < 1 ) {
                            continue
                        }
                        teams.push(team_role.team_id);
                    }
                }
            
                const response = await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}${query}`, API_METHOD.GET);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.events ) {
                    throw new Error("회의록 조회 중 오류 발생");
                }

                const temp_events : t_event[] = [];
                const response_events : t_event[] = response.data.data.events.filter(item => item.event_sub_type == EVENT_SUB_TYPE.MEETINGLOG);

                for( const event of response_events ) {

                    if ( event == null || event.event_data == null ) {
                        temp_events.push(event);
                        continue;
                    }

                    const event_data = event.event_data;

                    // 숨김 처리 아니라면 추가
                    if( (event_data.is_private ?? false) == false ) {
                        temp_events.push(event);
                        continue;
                    }

                    // 본인 작성이면 숨김상태여도 그대로 추가
                    if( event_data.event_owner_id == this.user_id ) {
                        temp_events.push(event);
                        continue;
                    }

                    // 권한 구하기
                    let is_permitted : boolean = false;
                    if( event.team_id > 0 ) {
                        if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                            is_permitted = this.is_team_permmision(event.team_id, "event", "private_read");
                        }
                        else if( this.isMeetingLog(event.event_sub_type) ) {
                            is_permitted = this.is_team_permmision(event.team_id, "meetinglog", "private_read");
                        }
                        else if( this.isReport(event.event_sub_type) ) {
                            is_permitted = this.is_team_permmision(event.team_id, "report", "private_read");
                        }
                        else if( this.isWork(event.event_sub_type) ) {
                            is_permitted = this.is_team_permmision(event.team_id, "work", "private_read");
                        }
                    }
                    else if( event.group_id > 0 ) {
                        if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                            is_permitted = this.is_group_permmision(event.group_id, "event", "private_read");
                        }
                        else if( this.isMeetingLog(event.event_sub_type) ) {
                            is_permitted = this.is_group_permmision(event.group_id, "meetinglog", "private_read");
                        }
                        else if( this.isReport(event.event_sub_type) ) {
                            is_permitted = this.is_group_permmision(event.group_id, "report", "private_read");
                        }
                        else if( this.isWork(event.event_sub_type) ) {
                            is_permitted = this.is_group_permmision(event.group_id, "work", "private_read");
                        }
                    }

                    if( is_permitted == true ) {
                        temp_events.push(event);
                        continue;
                    }

                    // 프로젝트의 경우 담당자면 볼 수 있음
                    if( event.event_sub_type, "WORK" && event_data.work != null ) {

                        if( event_data.work?.assignment_type == "ALL" ) {
                            temp_events.push(event);
                            continue;
                        }

                        const assign_user_ids = event_data.work!.assign_user_ids;
                        if( assign_user_ids != null && assign_user_ids.indexOf(this.user_id) > -1 ) {
                            temp_events.push(event);
                            continue;
                        }

                    }

                    // 숨김 처리인데 개인 공유 받은 경우 추가
                    if( event.subscribe_users != null && event.subscribe_users.indexOf(this.user_id) > -1 ) {
                        temp_events.push(event);
                        continue;
                    }

                    // 숨김 처리인데 그룹 공유 받은 경우 추가
                    for( const group_id of groups ) {
                        if ( event.subscribe_groups != null && event.subscribe_groups.indexOf(group_id) > -1 ) {
                            temp_events.push(event);
                            break;
                        }
                    }

                    if( temp_events.indexOf(event) > -1 ) continue;

                    // 숨김 처리인데 팀 공유 받은 경우 추가
                    for( const team_id of teams ) {
                        if ( event.subscribe_teams != null && event.subscribe_teams.indexOf(team_id) > -1) {
                            temp_events.push(event);
                            break;
                        }
                    }

                    if( temp_events.indexOf(event) > -1 ) continue;
                }

                this.events.splice(0, this.events.length);
                this.events = this.events.concat(temp_events);

            } catch(e) {
                this.hodu_error_process(e, true, false);
            } finally {
                this.timeout_ms = 1;
            }
        }, this.timeout_ms);
        
    }

    /**
     * 날짜 선택
     */
    onDateSelect(dateText, inst) {
        if( inst.id == 'meeting_log_fr_ymd' ) {
            this.start = moment(dateText).toDate();
            if( this.end.getTime() < this.start.getTime() ) this.end = moment(dateText).toDate();
        }

        else {
            this.end = moment(dateText).toDate();
            if( this.end.getTime() < this.start.getTime() ) this.start = moment(dateText).toDate();
        }

        this.meetinglog_search_info.search_start_date = this.start;
        this.meetinglog_search_info.search_end_date   = this.end;
        this.doSetMeetinglogSearchInfo?.(this.meetinglog_search_info);

        this.getEvent();
    }

    /**
     * 이전 날짜
     */
    prev() : void {
        const diff = this.getDateDiff(this.start, this.end);

        this.start = moment(this.start).set("date", this.start.getDate() - diff - 1).toDate();
        this.end = moment(this.end).set("date", this.end.getDate() - diff - 1).toDate();

        this.meetinglog_search_info.search_start_date = this.start;
        this.meetinglog_search_info.search_end_date   = this.end;
        this.doSetMeetinglogSearchInfo?.(this.meetinglog_search_info);

        this.getEvent();
    }

    /**
     * 다음 날짜
     */
    next() : void {
        const diff = this.getDateDiff(this.start, this.end);

        this.start = moment(this.start).set("date", this.start.getDate() + diff + 1).toDate();
        this.end = moment(this.end).set("date", this.end.getDate() + diff + 1).toDate();

        this.meetinglog_search_info.search_start_date = this.start;
        this.meetinglog_search_info.search_end_date   = this.end;
        this.doSetMeetinglogSearchInfo?.(this.meetinglog_search_info);

        this.getEvent();
    }

    /**
     * 오늘
     */
    today() : void {
        this.start = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
        this.end = moment(this.start).toDate();

        this.meetinglog_search_info.search_start_date = this.start;
        this.meetinglog_search_info.search_end_date   = this.end;
        this.doSetMeetinglogSearchInfo?.(this.meetinglog_search_info);
        
        this.getEvent();
    }

    /**
     * 날짜 반환
     */
    getDateText(event : t_event) : string {
        const start_string : string = `${this.hodu_date_to_format_string(event.event_data.schedule_date.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(event.event_data.schedule_date.start)}`;
        const end_string : string = `${this.hodu_date_to_format_string(event.event_data.schedule_date.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(event.event_data.schedule_date.start)}`;

        return start_string == end_string ? start_string : `${start_string} - ${end_string}`;
    }

    /**
     * 회의록 단건 클릭 (회의록 조회로 이동)
     */
    selectMeetingLog(ev : any, event : t_event) : void {
        
        if( this.getWorkReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.READ);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 일정 등록 페이지로 이동 
        this.hodu_router_push(`/event/${ event.event_id }`);
    }

    /**
     * 그룹, 팀 정렬 
     */
    changeSortType(sort_type : string) : void {
        if( this.sort_type != sort_type ) {
            this.sort_type = sort_type;
            this.sort_direction = 'ASC';
            return;
        }

        this.sort_direction = this.sort_direction == 'ASC' ? 'DESC' : 'ASC';
    }

    /**
     * 업무 읽기 권한 반환
     */
    getWorkReadPermission() : boolean {
        
        // 그룹 권한 체크
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "work", "read");
        } 
        
        // 팀 권한 체크
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "work", "read");
        } 
        
        // 개인은 불가능
        else {
            return false;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#meeting_log_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
