
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_enum, organization_modal_interface } from '@/model/organization';
import { approval_enum } from '@/model/approval';

import moment from 'moment';

import { directive as onClickaway } from 'vue-clickaway';

@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        
    },
})
export default class OrganizationMyVacationList extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;

    @Prop() event_bus !: Vue;

    /**
     * 본인 휴가원 
     */
    get computedApproval() : any[] {
        let target = this.approvals.filter(item => item.approval_type == approval_enum.APPROVAL_TYPE.VACATION && item.approval_state != approval_enum.APPROVAL_STATE.TEMP);
        
        if( this.filter.cancel == false ) {
            target = target.filter(item => item.approval_state != approval_enum.APPROVAL_STATE.CANCEL);
        }

        if( this.filter.ing == false ) {
            target = target.filter(item => item.approval_state != approval_enum.APPROVAL_STATE.ING);
        }

        if( this.filter.complete == false ) {
            target = target.filter(item => item.approval_state != approval_enum.APPROVAL_STATE.COMPLETE);
        }
        
        if( this.filter.reject == false ) {
            target = target.filter(item => item.approval_state != approval_enum.APPROVAL_STATE.REJECT);
        }

        target.sort((o1, o2) : number => {

            const o1_requested = new Date(moment(o1.audit_created).format('YYYY-MM-DD'));
            const o2_requested = new Date(moment(o2.audit_created).format('YYYY-MM-DD'));

            const o1_start = o1.contents.vacation_start ? o1.contents.vacation_start : o1.contents.vacation_selected[0];
            const o2_start = o2.contents.vacation_start ? o2.contents.vacation_start : o2.contents.vacation_selected[0];

            const o1_daycount = Number(o1.contents.vacation_type_daycount);
            const o2_daycount = Number(o2.contents.vacation_type_daycount);

            if( this.sort_type == organization_enum.ORGANIZATION_VACATION_SORT_TYPE.REQUEST ) {
                if( o1_requested.getTime() > o2_requested.getTime() ) return this.sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_requested.getTime() < o2_requested.getTime() ) return this.sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else {
                    // 같은 날짜라면 휴가 날짜 최신순
                    if( o1_start > o2_start ) return -1;
                    else if( o1_start < o2_start ) return 1;
                }
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_VACATION_SORT_TYPE.VACATION ) {
                if( o1_start > o2_start ) return this.sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_start < o2_start ) return this.sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else {
                    // 같은 날짜라면 요청일 최신순
                    if( o1_requested.getTime() > o2_requested.getTime() ) return -1;
                    else if( o1_requested.getTime() < o2_requested.getTime() ) return 1;
                }
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_VACATION_SORT_TYPE.DAYCOUNT ) {
                if( o1_daycount > o2_daycount ) return this.sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_daycount < o2_daycount ) return this.sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else {
                    // 같은 날짜라면 요청일 최신순
                    if( o1_requested.getTime() > o2_requested.getTime() ) return -1;
                    else if( o1_requested.getTime() < o2_requested.getTime() ) return 1;
                }
            }

            return 0;
        })

        return target; 
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationAddModlInfo ?: (params : organization_modal_interface.OrganizationVacationAddModlInfo) => void;
    @ModalInfo.Action doSetOrganizationMyVacationDetailModalInfo ?: (params : organization_modal_interface.OrganizationMyVacationDetailModalInfo) => void;

    start : Date = new Date();
    end : Date = new Date();

    start_text : string = "";
    end_text : string = "";

    approvals : any[] = [];

    sort_type : organization_enum.ORGANIZATION_VACATION_SORT_TYPE = organization_enum.ORGANIZATION_VACATION_SORT_TYPE.REQUEST;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    state_filter_open : boolean = false;

    filter : any = {
        cancel : true,
        ing : true,
        complete : true,
        reject : true
    }

    async mounted() : Promise<void> {

        this.event_bus.$on('userResize', this.handleResize);

        this.start = moment().set('month', 0).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
        this.end = moment().set('month', 11).set('date', 31).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        this.makeDateText();
        this.datepickerInit();
        
        await this.getApproval();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            const title_box_height = $('#organization_vacation .title_box').outerHeight();
            const schedule_box_height = $('#organization_vacation .schedule_box').outerHeight();
            const sort_Header_height = $('#organization_vacation .content .sortHeader').outerHeight();

            const scroll_height = window.innerHeight - (title_box_height ? title_box_height : 0)
                                                    - (schedule_box_height ? schedule_box_height : 0)
                                                    - (sort_Header_height ? sort_Header_height : 0);   

            // @ts-ignore
            $('#organization_my_vacation_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : scroll_height,
            });
        });
    }

    /**
     * 전자결재 조회
     */
    async getApproval() : Promise<void> {
        try {
            // 검색 타입, 시작일, 종료일
            let query = "?search_mode=DRAFT";
            query += `&start_date=${moment(this.start).utc().format()}`;
            query += `&end_date=${moment(this.end).utc().format()}`;

            // 클라이언트 타임존
            const timezone = -(new Date().getTimezoneOffset());
            query += `&timezone=${timezone ? timezone : 540}`;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval${query}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("상신함 문서 조회 중 오류 발생");
            }

            this.approvals.splice(0, this.approvals.length);
            this.approvals = this.approvals.concat(response.data.data.approval_info);

        } catch(e) {
            this.hodu_show_dialog('cancel', "상신함 문서 조회 중 오류 발생", ['확인']);
            throw e;
        }
    }

    /**
     * 날짜 텍스트 생성
     */
    makeDateText() : void {
        this.start_text = this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD');
        this.end_text = this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD');
    }

    /**
     * datepicker 초기화
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")}`);
                    
                    if( inst.id == 'organization_my_vacation_list_start' ) {
                        this.start = moment(selected_date).toDate();
                        
                        if( this.start.getTime() > this.end.getTime() ) {
                            this.end = moment(this.start).toDate();
                        }
                    }

                    else if( inst.id == 'organization_my_vacation_list_end' ) {
                        this.end = moment(selected_date).toDate();

                        if( this.start.getTime() > this.end.getTime() ) {
                            this.start = moment(this.end).toDate();
                        }
                    }

                    this.makeDateText();
                    this.getApproval();
                },
            }

            // @ts-ignore
            $('#organization_my_vacation_list_start').datepicker(datepicker_option);

            // @ts-ignore
            $('#organization_my_vacation_list_end').datepicker(datepicker_option);
        });
    }

    /**
     * 이전 날짜
     */
    prev() : void {
        const diff = this.getDateDiff(this.start, this.end);

        this.start = moment(this.start).set("date", moment(this.start).get('date') - diff - 1).toDate();
        this.end = moment(this.end).set("date", moment(this.end).get('date') - diff - 1).toDate();

        this.makeDateText();
        this.getApproval();
    }

    /**
     * 다음 날짜
     */
    next() : void {
        const diff = this.getDateDiff(this.start, this.end);

        this.start = moment(this.start).set("date", moment(this.start).get('date') + diff + 1).toDate();
        this.end = moment(this.end).set("date", moment(this.end).get('date') + diff + 1).toDate();

        this.makeDateText();
        this.getApproval();
    }

    /**
     * 휴가 날짜 텍스트 반환
     */
    getDateText(approval : any) : string {

        if( this.hodu_string_validation(approval.contents.vacation_start, '') != "" && this.hodu_string_validation(approval.contents.vacation_end, '') != '' ) {

            const vacation_start = approval.contents.vacation_start;
            const vacation_end = approval.contents.vacation_end;

            if( vacation_start == vacation_end ) {
                const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');

                let str = `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)}`;

                // if( approval.contents.vacation_type_daycount > 0 && approval.contents.vacation_type_daycount < 1 ) {
                if( approval.contents.vacation_type_daycount == 0.5 ) {
                    let start_hour = Number(approval.contents.vacation_start_time.substring(0,2));
                    let start_min = Number(approval.contents.vacation_start_time.substring(2,4));
                    let start_am_pm = 'AM';

                    if( start_hour >= 12 ) start_am_pm = "PM";
                    if( start_hour >  12 ) start_hour -= 12;
                    if( start_hour == 0  ) start_hour = 12;

                    let end_hour = Number(approval.contents.vacation_end_time.substring(0,2));
                    let end_min = Number(approval.contents.vacation_end_time.substring(2,4));
                    let end_am_pm = 'AM';

                    if( end_hour >= 12 ) end_am_pm = "PM";
                    if( end_hour >  12 ) end_hour -= 12;
                    if( end_hour == 0  ) end_hour = 12;

                    str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
                }

                return str;
            }

            const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
            const target_end = [vacation_end.substring(0,4), vacation_end.substring(4,6), vacation_end.substring(6,8)].join('-');

            return `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)} ~ ${this.hodu_date_to_format_string(target_end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_end)}`;
            
        }

        let str = "";

        for( const selected_date of approval.contents.vacation_selected ) {
            if( str.length > 0 ) str += ", ";
            const date = new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-'));
            str += `${this.hodu_date_to_format_string(date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
        }

        if( approval.contents.vacation_selected.length == 1 ) {
            // if( approval.contents.vacation_type_daycount > 0 && approval.contents.vacation_type_daycount < 1 ) {
            if( approval.contents.vacation_type_daycount == 0.5 ) {
                let start_hour = Number(approval.contents.vacation_start_time.substring(0,2));
                let start_min = Number(approval.contents.vacation_start_time.substring(2,4));
                let start_am_pm = 'AM';

                if( start_hour >= 12 ) start_am_pm = "PM";
                if( start_hour >  12 ) start_hour -= 12;
                if( start_hour == 0  ) start_hour = 12;

                let end_hour = Number(approval.contents.vacation_end_time.substring(0,2));
                let end_min = Number(approval.contents.vacation_end_time.substring(2,4));
                let end_am_pm = 'AM';

                if( end_hour >= 12 ) end_am_pm = "PM";
                if( end_hour >  12 ) end_hour -= 12;
                if( end_hour == 0  ) end_hour = 12;

                str += ` ${this.amPmStringToLocaleAmPmString(`${`0${start_hour}`.slice(-2)}:${`0${start_min}`.slice(-2)} ${start_am_pm}`)} ~ ${this.amPmStringToLocaleAmPmString(`${`0${end_hour}`.slice(-2)}:${`0${end_min}`.slice(-2)} ${end_am_pm}`)}`;
            }
        }
        
        return str;
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;

        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 상태에 따른 텍스트 반환
     */
    getStatusText(approval : any) : string {
        switch(approval.approval_state) {
            case approval_enum.APPROVAL_STATE.ING:
                return "대기";
            case approval_enum.APPROVAL_STATE.CANCEL:
                return "취소";
            case approval_enum.APPROVAL_STATE.COMPLETE:
                return "승인";
            case approval_enum.APPROVAL_STATE.REJECT:
                return "반려";
        }

        return "";
    }

    /**
     * 휴가 추가
     */
    add() : void {
        this.doSetOrganizationVacationAddModlInfo?.({
            show_modal : true,
            callback : () => {
                this.getApproval();
            }
        })
    }

    /**
     * 휴가 상세
     */
    detail(approval : any) : void {
        this.doSetOrganizationMyVacationDetailModalInfo?.({
            show_modal : true,
            approval_uid : approval.approval_uid,
            callback : () => {
                this.getApproval();
            }
        });
    }

    /**
     * 정렬
     */
    sort(sort_type : organization_enum.ORGANIZATION_VACATION_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 승인 상태 필터 토글
     */
    stateFilterToggle() : void {
        this.state_filter_open = !this.state_filter_open; 
    }

    /**
     * 필터 바깥쪽 클릭 이벤트
     */
    filterOutsideClick() : void {
        this.state_filter_open = false;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_my_vacation_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
