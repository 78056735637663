
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class SupplyManagementCreate extends Mixins(VueHoduCommon) {

    uid : string = "";
    seq : number = 0;
    type : string = "";

    is_create : boolean = false;

    supply : any = null;
    supplies : any[] = [];
    management : any = null;
    managements : any[] = [];

    supply_name : string = "";

    count : string = "0";
    unit_price : string = "";
    total_price : string = "";
    vendor : string = "";
    vendor_tel : string = "";
    purchase_date : Date = new Date();
    desc : string = "";

    default_image = require("@/assets/images/contents/hoduhome_car_camera.svg");

    beforeMount() : void {

        this.uid = this.$route.params.uid;

        try {
            this.seq = Number(this.$route.params.seq);
            if( isNaN(this.seq) ) this.seq = -1;
        } catch(e) {
            this.seq = -1;
        }

        if( this.seq < 0 ) {
            this.is_create = true;
        }
        
        this.type = this.$route.params.type;

        // if( this.uid == null || this.uid.trim().length < 1 || this.type == null || this.type.trim().length < 1 ) {
        //     this.hodu_show_dialog('cancel', '비품 관리 이력 등록에 필요한 데이터가 부족합니다', ['확인'], [() => { this.movePrevPage(); }])
        // }
    }

    mounted() : void {
        this.setScroll();

        // 작성 (장비 선택 함)
        if( this.is_create == true && this.uid != 'all' ) {
            this.getHomeSupply();
        }
        // 작성 (장비 선택 안함)
        else if( this.is_create == true && this.uid == 'all' ) {
            this.getHomeSupplies();
        }
        // 수정
        else {
            this.getHomeSupplyManagement();
        }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0);

        // @ts-ignore
        $('#supplyManagement_detail').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * 비품 조회
     */
    async getHomeSupply() : Promise<void> {
        try {
            const uid = this.uid == 'all' ? this.supply.uid : this.uid;
            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.supply ) {
                throw new Error("비품 조회 중 오류 발생");
            }

            this.supply = JSON.parse(JSON.stringify(response.data.data.supply));

            this.managements.splice(0, this.managements.length);
            this.managements = this.managements.concat(this.supply.managements);

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 비품 리스트 조회
     */
    async getHomeSupplies() : Promise<void> {
        
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply`, API_METHOD.GET);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.supplies ) {
                throw new Error("비품 리스트 조회 중 오류 발생");
            }

            this.supplies.splice(0, this.supplies.length);
            this.supplies = this.supplies.concat(response.data.data.supplies);

            if( this.supplies.length < 1 ) {
                this.hodu_show_dialog('cancel', "비품이 하나도 존재하지 않습니다", ['확인'], [() => { this.movePrevPage(); }]);
                return;
            }

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 리스트 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 비품 관리 이력 조회
     */
    async getHomeSupplyManagement() : Promise<void> {
        try {
            const uid = this.uid;
            const seq = this.seq;

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${uid}/management/${seq}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.management ) {
                throw new Error("비품 관리 이력 조회 중 오류 발생");
            }

            this.management = JSON.parse(JSON.stringify(response.data.data.management));

            this.type = this.management.management_data.type;
            this.supply = JSON.parse(JSON.stringify(this.management.supply));

            this.count = String(this.management.management_data.count);
            
            if( this.type == 'inbound' ) {
                this.unit_price = String(this.management.management_data.unit_price);
                this.total_price = String(this.management.management_data.total_price);
                this.vendor = this.management.management_data.vendor;
                this.vendor_tel = this.management.management_data.vendor_tel;
                this.purchase_date = this.hodu_yyyymmdd_to_date(this.management.management_data.purchase_date);
            }

            this.desc = this.management.management_data.desc;

            this.managements.splice(0, this.managements.length);
            this.managements = this.managements.concat(this.management.managements);

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 관리 이력 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 이전 재고수
     */
    getPrevStock() : number {

        if( this.managements == null || this.managements.length < 1 ) return 0;

        if( this.management == null ) {
            let stock : number = 0;

            for( const management of this.managements ) {
                stock += (management.management_data.type == 'inbound') ? (management.management_data.count) : -(management.management_data.count)
            }

            return stock;
        }

        let filter = this.management.managements;

        let index = -1;
        for( const management of filter ) {
            if( management.uid == this.management.uid && management.seq == this.management.seq ) index = filter.indexOf(management) + 1;
        }

        if( index < 0 || index >= filter.length ) {
            return 0; 
        }

        let stock : number = 0;
        let array_count : number = filter.length;

        for( let i = (array_count - 1); i >= index; i-- ) {
            const management = filter[i]
            if ( management.management_data.type == 'inbound' ) stock += management.management_data.count;
            if ( management.management_data.type == 'outbound' ) stock -= management.management_data.count;
        }

        return stock;
    }

    /**
     * 재고수
     */
    getStock() : number {

        let stock : number = this.getPrevStock();

        let count : number = Number(this.count);

        if( isNaN(count) ) {
            count = 0;
        }

        stock += (this.type == 'inbound') ? count : -(count);

        return stock;
    }

    /**
     * 이전 단가
     */
    getPrevUnitPrice() : number {

        if( this.managements == null || this.managements.length < 1 ) return 0;

        if( this.management == null ) {
            const inbound = this.managements.filter(management => management.management_data.type == 'inbound');

            if( inbound.length > 0 ) {
                return inbound[0].management_data.unit_price;
            } 
            else {
                return 0;
            }

        }
        
        let filter = this.management.managements.filter(management => management.management_data.type == 'inbound');

        let index = -1;
        for( const management of filter ) {
            if( management.uid == this.management.uid && management.seq == this.management.seq ) index = filter.indexOf(management) + 1;
        }

        if( index < 0 || index >= (filter.length - 1) ) {
            return 0; 
        }

        return filter[index + 1].management_data.unit_price;
    }

    @Watch('supply_name')
    watchSupplyName() : void {
        console.log(this.supply_name);

        const target = this.supplies.filter(supply => supply.supply_data.name == this.supply_name);
        if( target.length > 0 ) {
            this.supply = JSON.parse(JSON.stringify(target[0]));
            this.getHomeSupply();
        }
        else {
            this.supply = null;
        }
    }

    /**
     * 숫자 전용 input
     */
    onlyNumber (event) {
        let keyCode = (event.keyCode ? event.keyCode : event.which);
        if ((keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    /**
     * 저장
     */
    async save() : Promise<void> {

        this.count = String(this.count);
        this.unit_price = String(this.unit_price);
        this.total_price = String(this.total_price);

        if( this.count == null || this.count.trim().length < 1 ) {
            this.hodu_show_dialog("alert", `${ this.type == 'inbound' ? '입고' : '출고' }량을 입력해주세요`, ['확인'], [() => { $('#buyCount_text').focus(); }]);
            return;
        }
        
        if( isNaN(Number(this.count.replaceAll(",", "").trim())) == true ) {
            this.hodu_show_dialog("alert", `${ this.type == 'inbound' ? '입고' : '출고' }량에는 숫자만 입력해주세요`, ['확인'], [() => { $('#buyCount_text').focus(); }]);
            return;
        }

        if( Number(this.count.replaceAll(",", "").trim()) < 0 ) {
            this.hodu_show_dialog("alert", `${ this.type == 'inbound' ? '입고' : '출고' }량에는 양수만 입력해주세요`, ['확인'], [() => { $('#buyCount_text').focus(); }]);
            return;
        }

        if( this.count.replaceAll(",", "").trim().includes('.') == true ) {
            this.hodu_show_dialog("alert", `${ this.type == 'inbound' ? '입고' : '출고' }량에는 정수만 입력해주세요`, ['확인'], [() => { $('#buyCount_text').focus(); }]);
            return;
        }

        if( this.type == 'inbound' ) {
            if( this.unit_price == null || this.unit_price.trim().length < 1 ) {
                this.hodu_show_dialog("alert", "입고 단가를 입력해주세요", ['확인'], [() => { $('#buyPrice_text').focus(); }]);
                return;
            }

            if( isNaN(Number(this.unit_price.replaceAll(",", "").trim())) == true ) {
                this.hodu_show_dialog("alert", "입고 단가에는 숫자만 입력해주세요", ['확인'], [() => { $('#buyPrice_text').focus(); }]);
                return;
            }

            if( Number(this.unit_price.replaceAll(",", "").trim()) < 0 ) {
                this.hodu_show_dialog("alert", "입고 단가에는 양수만 입력해주세요", ['확인'], [() => { $('#buyPrice_text').focus(); }]);
                return;
            }

            if( this.unit_price.replaceAll(",", "").trim().includes('.') == true ) {
                this.hodu_show_dialog("alert", "입고 단가에는 정수만 입력해주세요", ['확인'], [() => { $('#buyPrice_text').focus(); }]);
                return;
            }

            if( this.total_price == null || this.total_price.trim().length < 1 ) {
                this.hodu_show_dialog("alert", "구입 총액을 입력해주세요", ['확인'], [() => { $('#finalPrice_text').focus(); }]);
                return;
            }

            if( isNaN(Number(this.total_price.replaceAll(",", "").trim())) == true ) {
                this.hodu_show_dialog("alert", "구입 총액에는 숫자만 입력해주세요", ['확인'], [() => { $('#finalPrice_text').focus(); }]);
                return;
            }

            if( Number(this.total_price.replaceAll(",", "").trim()) < 0 ) {
                this.hodu_show_dialog("alert", "구입 총액에는 양수만 입력해주세요", ['확인'], [() => { $('#finalPrice_text').focus(); }]);
                return;
            }

            if( this.total_price.replaceAll(",", "").trim().includes('.') == true ) {
                this.hodu_show_dialog("alert", "구입 총액에는 정수만 입력해주세요", ['확인'], [() => { $('#finalPrice_text').focus(); }]);
                return;
            }
        }

        try {

            let body = {
                type : this.type,
                count :Number(this.count.replaceAll(",", "").trim()),
                unit_price : Number(this.unit_price.replaceAll(",", "").trim()),
                total_price : Number(this.total_price.replaceAll(",", "").trim()),
                vendor : this.vendor.trim(),
                vendor_tel : this.vendor_tel.trim(),
                purchase_date : this.hodu_date_to_format_string(this.purchase_date, 'YYYYMMDD'),
                desc : this.desc.trim(),
                date : this.hodu_date_to_format_string(new Date(), 'YYYYMMDD')
            }

            const uid = this.uid == 'all' ? this.supply.uid : this.uid;

            if( this.is_create ) {

                const response = await this.hodu_api_call(`/api/v1/home/${this.scope_group_id}/supply/${uid}/management`, API_METHOD.POST, body);
    
                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("비품 관리 이력 등록 중 오류 발생");
                } 
    
                this.movePrevPage();
            }

            else {

                const response = await this.hodu_api_call(`/api/v1/home/${this.scope_group_id}/supply/${uid}/management/${this.seq}`, API_METHOD.PUT, body);
    
                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("비품 관리 이력 수정 중 오류 발생");
                } 
    
                this.movePrevPage();
            }

        } catch(e) {
            this.hodu_show_dialog("cancel", "비품 관리 이력 저장 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    @Watch("count")
    @Watch("unit_price")
    compute() : void {

        let count = Number(String(this.count).trim());
        let unit_price = Number(String(this.unit_price).trim());

        if( isNaN(count) || isNaN(unit_price) ) {
            this.total_price = "";
        }

        this.total_price = String(count * unit_price);

    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#supplyManagement_detail').mCustomScrollbar("destroy");

        this.setScroll();
    }

}
