
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { approval_interface, approval_modal_interface } from '@/model/approval';
import { t_event_file } from '@/model/event';

@Component({
    components: {
        
    },
})
export default class ApprovalFormModifyModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State approval_form_modify_modal_info !: approval_modal_interface.ApprovalFormModifyModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalFormModifyModalInfo ?: (params : approval_modal_interface.ApprovalFormModifyModalInfo) => void;

    template : approval_interface.t_approval_template | null = null;
    template_classes : approval_interface.t_approval_template_class[] = [];
    accept_mime_type = ".pdf, .csv, .xls, .xlsx, .doc, .docx, .rtf";

    new_file : File | null = null;

    async mounted() : Promise<void> {
        await this.getApprovalTemplateClasses();
        await this.getApprovalTemplateInfo();
    }   

    async getApprovalTemplateClasses() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.GET, null, false);
            
            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_templates ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }
            
            this.template_classes.splice(0, this.template_classes.length);
            this.template_classes = this.template_classes.concat(response.data.data.approval_templates.filter(template => template.template_state == '0' || template.template_state == '10'));

            // 분류 없음이 가장 먼저 나옴
            this.template_classes.sort((o1, o2) : number => {
                if( o1.template_state > o2.template_state ) return 1;
                else if( o1.template_state < o2.template_state ) return -1;
                else if( o1.template_class_id > o2.template_class_id ) return 1;
                else if( o1.template_class_id < o2.template_class_id ) return -1;
                return 0;
            });
            
        } catch(e) {
            throw e;
        }
    }

    /**
     * 양식 정보 조회
     */
    async getApprovalTemplateInfo() : Promise<void> {

        try {

            const template_class_id = this.approval_form_modify_modal_info.template_class_id;
            const template_id = this.approval_form_modify_modal_info.template_id;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form?template_class_id=${template_class_id}&template_id=${template_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_template_info ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }

            this.template = response.data.data.approval_template_info;

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("전자결재 양식 정보 조회 중 오류 발생");
            this.approval_form_modify_modal_info.callback?.();
            this.close();
        }

    }

    /**
     * 양식 이름 수정
     */
    inputFormName(event) : void {
        if( this.template == null ) return;
        this.template.template_name = event.target.value;
        if( $('#form_name').hasClass("wrong") ) $('#form_name').removeClass("wrong");
    }
    
    /**
     * 양식 파일명 수정
     */
    // inputFormFileName(event) : void {
    //     if( this.template == null ) return;
    //     this.template.contents.file_name = event.target.value;
    //     if( $('#form_file_name').hasClass("wrong") ) $('#form_file_name').removeClass("wrong");
    // }

    /**
     * 파일 수정
     */
    fileChange() : void {
        if( this.template == null ) return;
        $('#form_file').trigger('click');
    }

    /**
     * 파일 수정 기능
     */
    fileChangeFunction(event) : void {
        console.log(event);

        if( this.template == null || event.target.files.length < 1 ) {
            $('#form_file').val("");
            return;
        }

        for ( const file of event.target.files ) {

            if( file.size > this.DEFAULT_FILE_MAX_SIZE ) {
                alert(`${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`);
                $('#form_file').val("");
                this.new_file = null;
                return;
            }

            // 확장자가 없는 파일
            if( file.name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#form_file').val("");
                this.new_file = null;
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(file.name.substring(file.name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ file.name.substring(file.name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#form_file').val("");
                this.new_file = null;
                return;
            }

            // TODO 전용 확장자 추가 확인 (어느 순간부터 확장자 제한을 해도 브라우저 파일 탐색기에서 모든 파일을 볼수있게 변경됐음)

            this.new_file = file;
            break;
        }

    }


    /**
     * 파일 변경 취소
     */
    cancelFileChange() : void {
        this.new_file = null;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetApprovalFormModifyModalInfo?.({
            show_modal : false,
            template_class_id : 0,
            template_id : 0
        });
    }

    /**
     * 유효성 검사
     */
    checkValid() : boolean {

        if( this.template == null ) return false;

        if( this.template.template_name.trim().length < 1 ) {
            alert("양식 이름을 입력 해주세요");
            $('#form_name').addClass("wrong");
            return false;
        }

        // if( this.template.contents.file_name.trim().length < 1 ) {
        //     alert("양식 파일명을 입력 해주세요");
        //     $('#form_file_name').addClass("wrong");
        //     return false;
        // }

        return true;
    }

    /**
     * 양식 수정
     */
    async save() : Promise<void> {

        try {
            
            if( this.template == null ) return;

            if( this.checkValid() == false ) return;

            let temp_file : t_event_file | null = null;
            if( this.new_file != null ) {
                const form_data : FormData = new FormData();
                form_data.append('file', this.new_file);

                try {
                    const response = await this.hodu_temp_upload(form_data);
                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.temp_files || response.data.data.temp_files.length < 1 ) {
                        throw new Error("양식 파일 임시 업로드 실패");
                    }

                    const temp_files : t_event_file[] = response.data.data.temp_files;
                    temp_file = temp_files[0];

                    this.template.contents.file_name = temp_file.name;
                    this.template.contents.file_size = temp_file.size;
                    this.template.contents.mime_type = temp_file.mimeType;

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    alert("양식 파일 업로드 중 오류 발생");
                    return;
                }
            }

            this.template.contents.keep_year = Number(this.template.contents.keep_year);
            this.template.template_class_id = Number(this.template.template_class_id);

            const template_class_id = this.approval_form_modify_modal_info.template_class_id;
            const template_id = this.approval_form_modify_modal_info.template_id;

            // 파일 변경 했다면
            if( temp_file ) this.template['file'] = temp_file;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template_class_id}/${template_id}?is_approver_modify=false`, API_METHOD.PUT, this.template);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("양식 정보 수정 중 오류 발생");
            }

            this.approval_form_modify_modal_info.callback?.();
            this.close();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("양식 정보 수정 중 오류 발생");
        }
        
    }

}
