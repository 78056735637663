import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

/**
 * 그룹 공지사항 알림 noti_type == 'GROUP_NOTICE' 처리
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 */
export async function group_notice_notification_process(vue : VueHoduCommon, data : any) {

    if( data.noti_type != 'GROUP_NOTICE' ) return;

    get_noti_group_or_team_info(vue, data);
}

/**
 * scope, scope_id로 그룹 또는 팀 정보 가져오기
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 */
async function get_noti_group_or_team_info(vue : VueHoduCommon, data : any) : Promise<void> {
    
    let scope : string = data.scope;
    let scope_id : number = data.scope_id;

    if( !scope ) {
        scope = data.team_id > 0 ? OWNER_TYPE.TEAM : data.group_id > 0 ? OWNER_TYPE.GROUP : OWNER_TYPE.PERSONAL;
    }

    if( !scope_id ) {
        scope_id = data.team_id > 0 ? data.team_id : data.group_id > 0 ? data.group_id : 0;
    }

    try {
        const response = await vue.hodu_api_call(`api/v1/groupsweb/notification/${scope}/${scope_id}/info`, API_METHOD.GET);

        if( !response || !vue.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.group_or_team_info ) {
			throw new Error("그룹, 팀 정보 조회 중 오류 발생");
        }

        const group_or_team_info : any = response.data.data.group_or_team_info;

        vue.doGroupId(group_or_team_info.group_id);
        vue.doTeamId(group_or_team_info.team_id);
        vue.doGroupTeamOption({
            group_team_name : group_or_team_info.group_team_name,
            group_team_color : group_or_team_info.group_team_color,
            group_team_image : group_or_team_info.group_team_image,
            group_team_descript : group_or_team_info.group_team_descript,
            biz_id : group_or_team_info.biz_id,            
            noti_uid : ""
        });

        // 개인 달력이 아니라면 개인 달력으로 이동
        if( vue.scope != OWNER_TYPE.PERSONAL ) { go_personal_calendar(vue); }
        vue.hodu_router_push(`/group/${new Date().getTime()}/notice`);

    } catch(e) {
        vue.hodu_error_process(e, true, false);
    }
}

/**
 * 개인 달력으로 이동
 * @param  {VueHoduCommon} vue
 */
async function go_personal_calendar(vue : VueHoduCommon) : Promise<void> {
    vue.doSetGroupMenuClose(false);
    vue.doSetCalendarId(`personal-${vue.user_id}`);
    vue.doSetScope(OWNER_TYPE.PERSONAL);  
    vue.doSetScopeGroupId(0);  
    vue.doSetScopeTeamId(0);  
    vue.doSetScopeGroupTeamOption({
        group_team_name : '',
        group_team_color : '#FFFFFF',
        group_team_image : '',
        group_team_descript : '',
        biz_id : '',
        biz_type : ''
    });

    await vue.hodu_loading_timer_exit();
    await vue.hodu_loading();

    setTimeout(() => {
        // @ts-ignore
        $('.left_inbox02').mCustomScrollbar('destroy');
        window['left_inbox02_mCustomScrollbar']();
    }, 100);

    if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
}