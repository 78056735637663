
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { OWNER_TYPE, CRUD_TYPE, API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupTeamInfo } from '@/store/modules/GroupInfo';
import { EventShareInfo } from '@/store/modules/EventInfo';
import { t_event } from '@/model/event';
import { chat_modal_interface, chat_interface } from '../../../model/chat';
import { t_user } from '@/model/osm';

@Component({

}) export default class ChattingRoomCreateModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State chatting_room_create_modal_info !: chat_modal_interface.ChattingRoomCreateModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetChattingRoomCreateModalInfo ?: (params : chat_modal_interface.ChattingRoomCreateModalInfo) => void;

    group_team_list        : GroupTeamInfo[] = [];
    group_team_search_list : GroupTeamInfo[] = [];

    user_ids  : number[] = []; // 선택된 user_id  담을 배열(group_user_ids와 team_user_ids를 for문으로 돌려서 중복 제거해야함)
    group_ids : number[] = []; // 선택된 group_id 담을 배열
    team_ids  : number[] = []; // 선택된 team_id  담을 배열

    group_user_ids : number[][] = []; // 그룹별 선택 유저 담을 배열
    team_user_ids  : number[][] = []; // 팀별 선택 유저 담을 배열

    view_search_group_team : boolean = false;
    view_search_member     : boolean = false;

    view_group_team_member : boolean = false;

    member_target    : string = "";
    member_target_id : number = 0;

    group_team_members        : any = [];
    group_team_search_members : any = [];

    member_check_all     : boolean = false;
    member_checked_count : number  = 0;

    group_team_sort_target    : string | null = null;   // NAME, COUNT 
    group_team_sort_direction : string = "ASC";         // ASC, DESC
    member_sort_target        : string | null = null;   // NAME, ROLE
    member_sort_direction     : string = "ASC";         // ASC, DESC

    group_team_search_query        : string = "";
    group_team_member_search_query : string = "";

    async beforeMount() {

        this.group_team_list = JSON.parse(JSON.stringify(this.group_info_list));

        // 그룹, 팀 공유 되었는지 여부 조사
        const group_count : number = this.group_team_list.length;
        for( let i = 0; i < group_count; i++ ) {

            // 그룹 공유 조사
            if( this.group_ids.indexOf(this.group_team_list[i].group_id) > -1 ) {
                const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[i]));
                group_obj.shareChecked = true;
                this.group_team_list.splice(i, 1, group_obj);
            }

            // 팀 공유 조사
            const team_count : number = this.group_team_list[i].teams.length;
            for( let j = 0; j < team_count; j++ ) {
                if( this.team_ids.indexOf(this.group_team_list[i].teams[j].team_id) > -1 ) {
                    const team_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[i].teams[j]));
                    team_obj.shareChecked = true;
                    this.group_team_list[i].teams.splice(j, 1, team_obj);
                }
            }
        }

        // group_user_ids에 해당 그룹 데이터가 있다면 shareChecked = true;
        const group_user_ids_count : number = this.group_user_ids.length;
        for( let i = 0; i < group_user_ids_count; i++ ) { 
            if( this.group_user_ids[i] == null || this.group_user_ids[i].length < 1 ) {
                continue;
            }

            for( let j = 0; j < group_count; j++ ) {
                if( this.group_team_list[j].group_id == i ) {
                    const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[j]));
                    group_obj.shareChecked = true;
                    this.group_team_list.splice(j, 1, group_obj);
                }
            }
        }

        // team_user_ids에 해당 팀 데이터가 있다면 shareChecked = true;    
        const team_user_ids_count : number = this.team_user_ids.length;
        for( let i = 0; i < team_user_ids_count; i++ ) {
            if( this.team_user_ids[i] == null || this.team_user_ids[i].length < 1 ) {
                continue;
            }

            for( let j = 0; j < group_count; j++ ) {
                
                const team_count : number = this.group_team_list[j].teams.length;
                for( let k = 0; k < team_count; k++ ) {
                    if( this.group_team_list[j].teams[k].team_id == i ) {
                        const team_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[j].teams[k]));
                        team_obj.shareChecked = true;
                        this.group_team_list[j].teams.splice(k, 1, team_obj);
                    }
                }

            }

        }
        
        this.group_team_search_list = JSON.parse(JSON.stringify(this.group_team_list));
    }

    mounted() {
    
        // @ts-ignore
        $('.shareUlDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : 316,
            scrollbarPosition : 'outside',
        });

        // @ts-ignore
        $('.peepsUlDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : 316,
            scrollbarPosition : 'outside',
        });

        if( this.group_info_list == null ) {
            return;
        }
    }

    /**
     * 멤버 카운트 클릭 : 멤버 목록 띄우기
     */
    async memberCountClick(target : string, group_id : number, team_id : number) : Promise<void> {
        const vue = this;

        this.member_target = target;
        this.member_target_id = target == 'TEAM' ? team_id : group_id;

        /**
         * 기존에 있던 멤버리스트 비움
         */
        this.group_team_members.splice(0, this.group_team_members.length);

        // api/v1/groups/getGroupMember/{group_id} 또는 api/v1/teams/getTeamMember/{group_id}/{team_id}
        this.hodu_api_call(`api/v1/${ target == "GROUP" ? 'groups' : 'teams' }/${ target == "GROUP" ? 'getGroupMember' : 'getTeamMember' }/${group_id}${ team_id > 0 ? `/${team_id}` : ''}`, API_METHOD.GET)
            .then(async response => {
                console.log(JSON.stringify(response));

                vue.group_team_members = response.data.data.list;
                vue.member_checked_count = 0;

                let user_index : number = -1;
                vue.member_check_all = true;

                const member_count : number = vue.group_team_members.length;
                for( let i = 0; i < member_count; i++ ) {
                    
                    if( this.user_id == vue.group_team_members[i].user_id ) {
                        user_index = i;
                    }
                    
                    // 타겟이 그룹일때
                    if( target == 'GROUP' ) {
                        
                        // 해당 그룹이 체크 된 경우 전부 true로
                        const group_index : number = vue.group_ids.indexOf(group_id);
                        if( group_index > -1 ) {
                            if( vue.user_id != vue.group_team_members[i].user_id ) {
                                vue.member_checked_count++;
                            }
                            vue.group_team_members[i].checked = true;
                            continue;
                        } 

                        // group_user_ids에 해당 팀의 값이 없을때
                        if( vue.group_user_ids[`${group_id}`] == null ) {
                            vue.group_team_members[i].checked = false;
                            continue;
                        }
                        
                        // 그룹 ids 정보에 해당 유저의 아이디가 들어있는지 안들어있는지 여부 등록
                        const group_user_index : number =vue.group_user_ids[`${group_id}`].indexOf(vue.group_team_members[i].user_id);
                        vue.group_team_members[i].checked = group_user_index == -1 ? false : true;

                        if( vue.group_team_members[i].checked == true ) {
                            vue.member_checked_count++;
                        }
                    }

                    // 타겟이 팀일때
                    else {

                        // 해당 팀이 체크 된 경우 전부 true로
                        const team_index : number = vue.group_ids.indexOf(group_id);
                        if( team_index > -1 ) {
                            if( vue.user_id != vue.group_team_members[i].user_id ) {
                                vue.member_checked_count++;
                            }
                            vue.group_team_members[i].checked = true;
                            continue;
                        } 
                        
                        // team_user_ids에 해당 팀의 값이 없을때
                        if( vue.team_user_ids[`${team_id}`] == null ) {
                            vue.group_team_members[i].checked = false;
                            continue;
                        }

                        // 팀 ids 정보에 해당 유저의 아이디가 들어있는지 안들어있는지 여부 등록
                        const team_user_index : number = vue.team_user_ids[`${team_id}`].indexOf(vue.group_team_members[i].user_id);
                        vue.group_team_members[i].checked = team_user_index == -1 ? false : true;

                        if( vue.group_team_members[i].checked == true ) {
                            vue.member_checked_count++;
                        }
                    }
                }

                // 사용자 본인 제외
                vue.group_team_members.splice(user_index, 1);

                // 하나라도 체크된 상태가 아니였다면 member_check_all = false;
                const new_member_count : number = vue.group_team_members.length;
                for( let i = 0; i < new_member_count; i++ ) {
                    
                    if( vue.group_team_members[i].checked == false ) {
                        vue.member_check_all = false;
                    }
                }

                // 멤버 보기
                vue.view_group_team_member = true;
                
                vue.group_team_search_members.splice(0, vue.group_team_search_members.length);
                vue.group_team_search_members = vue.group_team_search_members.concat(vue.group_team_members);
            })
            .catch(async e => {
                this.hodu_error_process(e, false, false);
            });

    }

    /**
     * 생성 또는 확인 버튼 클릭
     */
    async shareOrConfirmButtonClick() : Promise<void> {
        
        // 그룹, 팀 멤버 리스트 보는 중
        if( this.view_group_team_member == true ) {
            this.memberShareConfirm();
            this.view_group_team_member         = false;
            this.view_search_member             = false;
            this.group_team_member_search_query = "";
        }

        // 그룹, 팀 리스트 보는 중
        else {
            
            let user_infos : chat_interface.t_chat_channel_user[] | null = null;

            // user_ids, group_ids, team_ids 전부 유저 정보로 변환
            if( this.user_ids.length > 0 || this.group_ids.length > 0 || this.team_ids.length > 0 ) {
                try {
                    user_infos = await this.getUserInfoByGroupIdsTeamIds();
                    user_infos?.forEach(element => element.user_id = String(element.user_id));
                } catch(e) {
                    this.hodu_error_process(e, false, false);
                    return;
                }
            }   

            // 채팅방 멤버 초대 웹소켓
            if( this.chatting_room_create_modal_info.crud_type == CRUD_TYPE.UPDATE ) {

                if( user_infos == null || user_infos.length < 1 ) {
                    alert("채팅에 초대할 대상을 선택해주세요");
                    return;
                }

                this.chatting_room_create_modal_info.callback?.(user_infos);
                this.closeModal();
                return;
            }

            // 채팅방 생성 API
            const post_data : chat_interface.I_CHANNEL_CREATE_INFO = {
                channel_info : {
                    type : 'CHAT',                 
                    sub_type : OWNER_TYPE.PERSONAL,
                    ref_id : '',
                    is_private : true,
                    info: { "title": '' }
                },
                invite_user_infos : user_infos ? user_infos : []
            }

            try {
                const response = await this.hodu_api_call(`api/v1/chat/channel_info/`, API_METHOD.POST, post_data);

                console.log(response);
                
                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.channel ) {
                    throw new Error("채팅방 등록 실패");
                }

                this.chatting_room_create_modal_info.callback?.(response.data.data.channel);
                this.closeModal();

            } catch(e) {
                this.hodu_error_process(e, false, false);
            }
        }
    }

    /**
     * user_ids, group_ids, team_ids로 유저정보 받아오기
     */
    async getUserInfoByGroupIdsTeamIds() : Promise<chat_interface.t_chat_channel_user[]> {

        const post_data = {
            user_ids : this.user_ids,
            group_ids : this.group_ids,
            team_ids : this. team_ids
        }

        try {
            const response = await this.hodu_api_call('api/v1/groups/find/user', API_METHOD.POST, post_data);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || 
                !response.data.data.user_info  ) {
                throw new Error("유저 정보 조회 실패");
            }

            return response.data.data.user_info;

        } catch(e) {
            this.hodu_error_process(e, false, false);
            return [];
        }
    }

    /**
     * 그룹, 팀 이미지 에러 날시 그룹색상에 맞는 Default 그룹이미지를 보여주도록 클래스를 부여한다 
     */
    imageError(event, group_info : GroupTeamInfo) : void {
        let temp_class : string = "";
        switch( new String(group_info.color).toUpperCase().slice(0, 7) ) {
            /**
             * dc - 진한색
             */
            case '#FF6363':
                temp_class = 'imgDc0';
                break;

            case '#FFA70E':
                temp_class = 'imgDc1';
                break;

            case '#FFC72F':
                temp_class = 'imgDc2';
                break;

            case '#FF198B':
                temp_class = 'imgDc3';
                break;

            case '#00B2C7':
                temp_class = 'imgDc4';
                break;

            case '#13D08B':
                temp_class = 'imgDc5';
                break;

            case '#4DBAFF':
                temp_class = 'imgDc6';
                break;

            case '#477FFF':
                temp_class = 'imgDc7';
                break;

            case '#6854FF':
                temp_class = 'imgDc8';
                break;

            case '#35405A':
                temp_class = 'imgDc9';
                break;
            
            /**
             * lc - 옅은색 (현재 그룹에 옅은색은 없지만 대비용으로 만들어 놓음)
             */
            case '#B9A494':
                temp_class = 'imgLc0';
                break;

            case '#E67D9B':
                temp_class = 'imgLc1';
                break;

            case '#FF9898':
                temp_class = 'imgLc2';
                break;

            case '#AB8DD6':
                temp_class = 'imgLc3';
                break;

            case '#F3AC77':
                temp_class = 'imgLc4';
                break;

            case '#E3D37A':
                temp_class = 'imgLc5';
                break;

            case '#8DD6A0':
                temp_class = 'imgLc6';
                break;

            case '#7197ED':
                temp_class = 'imgLc7';
                break;

            case '#8DAED6':
                temp_class = 'imgLc8';
                break;

            case '#6F7A93':
                temp_class = 'imgLc9';
                break;

            default:
                temp_class = 'imgDc7';
                break;

        }

        $(event.target).parent().find('.img').addClass(temp_class);
    }

    /**
     * 팀 리스트 펼치기 / 접기 
     */
    async showTeamList( index :number, group_team_info : GroupTeamInfo ) : Promise<void> {

        group_team_info.shareListOn = group_team_info.shareListOn == null || group_team_info.shareListOn == false ? true : false;

        if( this.group_team_search_list == null ) {
            return;
        }

        this.group_team_search_list.splice(index, 1, group_team_info);
        await this.setOriginalGroupTeamList();
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 그룹, 팀 선택 or 선택 해제
     */
    async checkGroupOrTeam(group_index : number, team_index : number, target : string, target_id : number) : Promise<void> {

        if( this.group_team_search_list == null ) {
            return;
        }

        // 그룹 선택 or 선택 해제
        if( target == "GROUP" ) {
            const group_ids_index : number = this.group_ids.indexOf(target_id);
            const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[group_index]));
            
            // 체크 해제라면
            if( group_obj.shareChecked == false ) {
                // group_ids에 있다면 제거
                if( this.group_ids.indexOf(group_obj.group_id) > - 1) {
                    this.group_ids.splice(this.group_ids.indexOf(group_obj.group_id), 1);
                }
                
                // group_user_ids[group_id]에 데이터가 있다면 제거
                if( this.group_user_ids[group_obj.group_id] != null && this.group_user_ids[group_obj.group_id].length > 0 ) {
                    this.group_user_ids[group_obj.group_id].splice(0, this.group_user_ids[group_obj.group_id].length);
                }
            }

            // 체크라면
            else {
                this.group_ids.push(target_id);
            }

        }

        // 팀 선택 or 선택 해제
        else {
            const team_ids_index : number = this.team_ids.indexOf(target_id);
            const team_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[group_index].teams[team_index]));

            // 체크 해제라면
            if( team_obj.shareChecked == false ) {
                // team_ids에 있다면 제거
                if( this.team_ids.indexOf(team_obj.team_id) > - 1) {
                    this.team_ids.splice(this.team_ids.indexOf(team_obj.team_id), 1);
                }
                
                // team_user_ids[team_id]에 데이터가 있다면 제거
                if( this.team_user_ids[team_obj.team_id] != null && this.team_user_ids[team_obj.team_id].length > 0 ) {
                    this.team_user_ids[team_obj.team_id].splice(0, this.team_user_ids[team_obj.team_id].length);
                }
            }

            // 체크라면
            else {
                this.team_ids.push(target_id);
            }

        }

        // 체크 값 계산
        await this.calculateCheck();
        await this.setOriginalGroupTeamList();
    }

    /**
     * 멤버 전체 선택
     */
    memberAllSelect() : void {
        const member_search_count : number = this.group_team_search_members.length;
        for( let i = 0; i < member_search_count; i++ ) {
            const member_obj : any = JSON.parse(JSON.stringify(this.group_team_search_members[i]));
            member_obj.checked = ( this.member_check_all == true ); // 전체선택값
            this.group_team_search_members.splice(i, 1, member_obj);
        }

        const member_original_count : number = this.group_team_members.length;
        for( let i = 0; i < member_original_count; i++ ) {
            const member_obj : any = JSON.parse(JSON.stringify(this.group_team_members[i]));
            member_obj.checked = ( this.member_check_all == true ); // 전체선택값
            this.group_team_members.splice(i, 1, member_obj);
        }

        if( this.member_check_all == false ) { this.member_checked_count = 0; }
    }

    /**
     * 그룹, 팀 멤버 클릭시 count 변경 및 전체 선택 체크 변경
     */
    memberClick(click_index : number) : void {
        this.member_checked_count = this.getMemberCheckedCount(click_index);
        this.member_check_all     = this.member_checked_count == this.group_team_members.length
    }

    /**
     * 그룹, 팀 멤버가 체크된게 몇명인지 반환
     */
    getMemberCheckedCount(click_index : number) : number {
        try {
            let checked_memeber_count : number = 0;
                
            const member_count : number = this.group_team_search_members.length;
            for( let i = 0; i < member_count; i++ ) {
                if( this.group_team_search_members[i].checked == true ) {
                    checked_memeber_count++;
                }
            }

            return checked_memeber_count;

        } catch(e) {
            this.hodu_error_process(e, false, false);
            return 0;
        }

    }

    /**
     * 취소버튼 클릭 
     * 1. 그룹, 팀리스트 보는 중에는 Modal이 닫힘
     * 2. 그룹, 팀의 멤버 리스트를 보는 중에는 그룹, 팀 리스트로 변경
     */
    cancelButtonClick() : void {
        
        // 그룹, 팀 멤버 리스트 보는 중
        if( this.view_group_team_member == true ) {
            this.view_group_team_member         = false;
            this.view_search_member             = false;
            this.group_team_member_search_query = "";
        } 
        
        // 그룹, 팀 리스트 보는 중
        else {
            this.closeModal();
        }
    }

    /**
     * 멤버 공유 확인
     */
    async memberShareConfirm() : Promise<void> {
        
        let shareChecked : boolean = false;

        // 그룹
        if( this.member_target == 'GROUP' ) {
            // group_user_ids[target_id]를 NULL 체크 후 group_user_ids[target_id] = []로 초기화
            if( this.group_user_ids[this.member_target_id] == null ) {
                this.group_user_ids[this.member_target_id] = [];
            }
            
            // group_user_ids[target_id]를 완전히 비운다
            this.group_user_ids[this.member_target_id].splice(0, this.group_user_ids[this.member_target_id].length);

            // 전부 체크 되어 있다면 (this.member_check_all == true)
            if( this.member_check_all == true ) {
                // group_user_ids[target_id]를 전부 비운다
                this.group_user_ids[this.member_target_id].splice(0, this.group_user_ids[this.member_target_id].length);

                // group_ids 에 해당 target_id를 집어넣는다
                this.group_ids.push(this.member_target_id);
            }

            // 하나라도 체크 되어 있지 않다면 (this.member_check_all == false)
            else {
                // group_ids 에서 해당 target_id를 제거한다
                if( this.group_ids.indexOf(this.member_target_id) > -1 ) {
                    this.group_ids.splice(this.group_ids.indexOf(this.member_target_id), 1);
                }

                // group_user_ids에 체크된 유저 아이디들을 전부 집어넣는다
                const member_count : number = this.group_team_search_members.length;
                for( let i = 0; i < member_count; i++ ) {
                    if( this.group_team_search_members[i].checked == true ) {
                        this.group_user_ids[this.member_target_id].push(this.group_team_search_members[i].user_id);
                    }
                }
            }

            // group_ids에 target_id도 들어있지 않고 group_user_ids[target_id]도 비어있다면 해당 그룹은 선택되지 않은것 (반대로 둘 중 하나라도 만족하면 선택된 것)
            shareChecked = ( this.group_ids.indexOf(this.member_target_id) > -1 ) || ( this.group_user_ids[this.member_target_id].length > 0 );
        }

        // 팀
        else {
            
            // team_user_ids[member_target_id]를 NULL 체크 후 team_user_ids = []로 초기화
            if( this.team_user_ids[this.member_target_id] == null ) {
                this.team_user_ids[this.member_target_id] = [];
            }

            // team_user_ids[target_id]를 완전히 비운다
            this.team_user_ids[this.member_target_id].splice(0, this.team_user_ids[this.member_target_id].length);

            // 전부 체크 되어 있다면 (this.member_check_all == true)
            if( this.member_check_all == true ) {
                // team_user_ids[target_id]를 전부 비운다
                this.team_user_ids[this.member_target_id].splice(0, this.team_user_ids[this.member_target_id].length);

                // team_ids 에 해당 target_id를 집어넣는다
                this.team_ids.push(this.member_target_id);
            }

            // 하나라도 체크 되어 있지 않다면 (this.member_check_all == false)
            else {
                // team_ids 에서 해당 target_id를 제거한다
                if( this.team_ids.indexOf(this.member_target_id) > -1 ) {
                    this.team_ids.splice(this.team_ids.indexOf(this.member_target_id), 1);
                }

                // group_user_ids에 체크된 유저 아이디들을 전부 집어넣는다
                const member_count : number = this.group_team_search_members.length;
                for( let i = 0; i < member_count; i++ ) {
                    if( this.group_team_search_members[i].checked == true ) {
                        this.team_user_ids[this.member_target_id].push(this.group_team_search_members[i].user_id);
                    }
                }
            }

            // team_ids에 target_id도 들어있지 않고 team_user_ids[target_id]도 비어있다면 해당 그룹은 선택되지 않은것 (반대로 둘 중 하나라도 만족하면 선택된 것)
            shareChecked = ( this.team_ids.indexOf(this.member_target_id) > -1 ) || ( this.team_user_ids[this.member_target_id].length > 0 );
        }

        await this.groupTeamSelect(shareChecked);
        await this.calculateCheck();
    }

    /**
     * target, target_id를 이용해서 group_team_search_list에서 찾아내서 shareChecked를 업데이트 한다
     */
    async groupTeamSelect(shareChecked : boolean) : Promise<void> { 
        const group_length : number = this.group_team_search_list.length;
        for( let i = 0; i < group_length; i++ ) {
            
            // 타겟이 그룹인 경우
            if( this.member_target == 'GROUP' ) {
                if( this.group_team_search_list[i].group_id == this.member_target_id ) {
                    const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[i]));
                    group_obj.shareChecked = shareChecked;
                    this.group_team_search_list.splice(i, 1, group_obj);
                }
            }

            // 타겟이 팀인 경우
            else {
                if( this.group_team_search_list[i].teams == null ) {
                    this.group_team_search_list[i].teams = [];
                }   

                const team_length : number = this.group_team_search_list[i].teams.length;
                for( let j = 0; j < team_length; j++ ) {
                    if( this.group_team_search_list[i].team_id == this.member_target_id ) {
                        const team_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[i].teams[j]));
                        team_obj.shareChecked = shareChecked;
                        this.group_team_search_list[i].teams.splice(j, 1, team_obj);
                    }
                }
            }

        } 

        await this.setOriginalGroupTeamList();
    }

    /**
     * group_user_ids와 team_user_ids 계산해서 user_ids 생산
     */
    async calculateCheck() : Promise<void> {
        this.user_ids.splice(0, this.user_ids.length);
        
        // user_ids가 중복되지 않게 group_user_ids => user_ids로 옮김
        const group_user_ids_length : number = this.group_user_ids.length;
        for( let i = 0; i < group_user_ids_length; i++ ) {
            if( this.group_user_ids[i] == null ) {
                continue;
            }

            const group_user_ids_i_length : number = this.group_user_ids[i].length;
            for( let j = 0; j < group_user_ids_i_length; j++ ) {
                if( this.user_ids.indexOf(this.group_user_ids[i][j]) == -1 ) {
                    this.user_ids.push(this.group_user_ids[i][j]);
                }
            }
        }

        // user_ids가 중복되지 않게 team_user_ids => user_ids로 옮김
        const team_user_ids_length : number = this.team_user_ids.length;
        for( let i = 0; i < team_user_ids_length; i++ ) {
            if( this.team_user_ids[i] == null ) {
                continue;
            }

            const team_user_ids_i_length : number = this.team_user_ids[i].length;
            for( let j = 0; j < team_user_ids_i_length; j++ ) {
                if( this.user_ids.indexOf(this.team_user_ids[i][j]) == -1 ) {
                    this.user_ids.push(this.team_user_ids[i][j]);
                }
            }
        }

        this.user_ids.sort();
    }

    /**
     * 검색 버튼 toggle
     */
    searchButtonClick() : void {
        
        // 그룹, 팀 리스트 보는 중 일때
        if( this.view_group_team_member == false ) {
            this.view_search_group_team = !this.view_search_group_team;
        }

        // 멤버 리스트 보는 중 일때
        else {
            this.view_search_member = !this.view_search_member;
        }

        // 그룹, 팀 검색을 끄는 경우
        if( this.view_search_group_team == false ) {
            this.group_team_search_query = '';
        }

        // 멤버 검색을 끄는 경우
        if( this.view_search_member == false ) {
            this.group_team_member_search_query = '';
        }
    }

    /**
     * 그룹, 팀 리스트 이름 기준 정렬
     */
    groupTeamNameSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.group_team_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.group_team_sort_direction ) {
                case "ASC":
                    this.group_team_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.group_team_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.group_team_sort_target = "NAME";
            this.group_team_sort_direction = "ASC";
        }
        
        if( this.group_team_sort_target == null ) { this.searchGroupAndTeamFunction(); } 
        else { this.groupTeamSort(); }
    }

    /**
     * 그룹, 팀 리스트 멤버수 기준 정렬
     */
    groupTeamCountSort() : void {

        // 이미 COUNT Sort 중이라면
        if( this.group_team_sort_target == "COUNT" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.group_team_sort_direction ) {
                case "ASC":
                    this.group_team_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.group_team_sort_target = null;
                    break; 
            }
        }
        
        // COUNT Sort가 아니였다면
        else {
            this.group_team_sort_target = "COUNT";
            this.group_team_sort_direction = "ASC";
        }

        if( this.group_team_sort_target == null ) { this.searchGroupAndTeamFunction(); } 
        else { this.groupTeamSort(); }
    }

    /**
     * 그룹, 팀 정렬
     */
    groupTeamSort() : void {
        
        const sort_function : (a : GroupTeamInfo, b : GroupTeamInfo) => number = (o1 : GroupTeamInfo, o2 : GroupTeamInfo) : number => {
            // 그룹, 팀 이름 오름차순
            if( this.group_team_sort_target == "NAME" && this.group_team_sort_direction == "ASC" ) {
                if( o1.team_name == null || o1.team_name.length < 1 || o2.team_name == null || o2.team_name.length < 1 ) {
                    if( o1.group_name  > o2.group_name ) { return  1; } 
                    if( o1.group_name  < o2.group_name ) { return -1; } 
                    if( o1.group_name == o2.group_name ) { return  0; }
                } 

                else {
                    if( o1.team_name  > o2.team_name ) { return  1; } 
                    if( o1.team_name  < o2.team_name ) { return -1; } 
                    if( o1.team_name == o2.team_name ) { return  0; }
                }
            }

            // 그룹, 팀 이름 내림차순
            if( this.group_team_sort_target == "NAME" && this.group_team_sort_direction == "DESC" ) {
                if( o1.team_name == null || o1.team_name.length < 1 || o2.team_name == null || o2.team_name.length < 1 ) {
                    if( o1.group_name  > o2.group_name ) { return -1; } 
                    if( o1.group_name  < o2.group_name ) { return  1; } 
                    if( o1.group_name == o2.group_name ) { return  0; }
                }

                else {
                    if( o1.team_name  > o2.team_name ) { return -1; } 
                    if( o1.team_name  < o2.team_name ) { return  1; } 
                    if( o1.team_name == o2.team_name ) { return  0; }
                }
            }

            // 그룹, 팀 멤버 수 오름차순
            if( this.group_team_sort_target == "COUNT" && this.group_team_sort_direction == "ASC" ) {
                if( o1.user_count  > o2.user_count ) { return  1; } 
                if( o1.user_count  < o2.user_count ) { return -1; } 
                if( o1.user_count == o2.user_count ) { return  0; } 
            }

            // 그룹, 팀 멤버 수 내림차순
            if( this.group_team_sort_target == "COUNT" && this.group_team_sort_direction == "DESC" ) {
                if( o1.user_count  > o2.user_count ) { return -1; } 
                if( o1.user_count  < o2.user_count ) { return  1; } 
                if( o1.user_count == o2.user_count ) { return  0; } 
            }

            return 0;
        };

        // 팀 정렬
        const group_count : number = this.group_team_search_list.length;
        for( let i = 0; i < group_count; i++ ) {
            const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_search_list[i]));
            if( group_obj.teams == null || group_obj.teams.length < 1 ) { continue; }

            const team_array : GroupTeamInfo[] = JSON.parse(JSON.stringify(group_obj.teams)); 
            team_array.sort(sort_function);

            group_obj.teams.splice(0, group_obj.teams.length);
            group_obj.teams = group_obj.teams.concat(team_array);
            this.group_team_search_list.splice(i, 1, group_obj);
        }

        // 그룹 정렬
        this.group_team_search_list.sort(sort_function);
    }

    /**
     * 멤버 리스트 이름 기준 정렬
     */
    memberNameSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.member_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.member_sort_direction ) {
                case "ASC":
                    this.member_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.member_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.member_sort_target = "NAME";
            this.member_sort_direction = "ASC";
        }
        
        if( this.member_sort_target == null ) { this.searchMemberFunction(); } 
        else { this.memberSort(); }
    }

    /**
     * 멤버 리스트 직급 기준 정렬
     */
    memberRoleSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.member_sort_target == "ROLE" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.member_sort_direction ) {
                case "ASC":
                    this.member_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.member_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.member_sort_target = "ROLE";
            this.member_sort_direction = "ASC";
        }
        
        if( this.member_sort_target == null ) { this.searchMemberFunction(); } 
        else { this.memberSort(); }
    }

    /**
     * 멤버 정렬 함수
     */
    memberSort() : void {
        this.group_team_search_members.sort((o1 : any, o2 : any) : number => {
            // 이름 오름차순
            if( this.member_sort_target == "NAME" && this.member_sort_direction == "ASC" ) {
                if( o1.user_info.user_name  > o2.user_info.user_name ) { return  1; } 
                if( o1.user_info.user_name  < o2.user_info.user_name ) { return -1; } 
                if( o1.user_info.user_name == o2.user_info.user_name ) { return  0; }
            }

            // 이름 내림차순
            if( this.member_sort_target == "NAME" && this.member_sort_direction == "DESC" ) {
                if( o1.user_info.user_name  > o2.user_info.user_name ) { return -1; } 
                if( o1.user_info.user_name  < o2.user_info.user_name ) { return  1; } 
                if( o1.user_info.user_name == o2.user_info.user_name ) { return  0; }
            }

            // 권한 오름차순
            if( this.member_sort_target == "ROLE" && this.member_sort_direction == "ASC" ) {
                if( o1.role_level  > o2.role_level ) { return -1; }
                if( o1.role_level  < o2.role_level ) { return  1; }
                if( o1.role_level == o2.role_level ) { return  0; }
            }

            // 권한 내림차순
            if( this.member_sort_target == "ROLE" && this.member_sort_direction == "DESC" ) {
                if( o1.role_level  > o2.role_level ) { return  1; }
                if( o1.role_level  < o2.role_level ) { return -1; }
                if( o1.role_level == o2.role_level ) { return  0; }
            }
            
            return 0;
        });
    }

    /**
     * 그룹, 팀 검색
     */
    @Watch('group_team_search_query')
    changeGroupTeamSearchQuery() : void {
        this.searchGroupAndTeamFunction();
        this.groupTeamSort();
    }

    /**
     * 그룹 팀 검색 함수
     */
    searchGroupAndTeamFunction() : void {
        const original_list : GroupTeamInfo[] = JSON.parse(JSON.stringify(this.group_team_list));

        this.group_team_search_list.splice(0, this.group_team_search_list.length);
        if( this.group_team_search_query == '' ) {
            this.group_team_search_list = this.group_team_search_list.concat(this.group_team_list);
            return;
        }
        
        const group_count : number = original_list.length;
        for( let i = 0; i < group_count; i++ ) {
            const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(original_list[i]));
            
            // 그룹 이름이 매칭 된다면 push
            if( group_obj.group_name.indexOf(this.group_team_search_query) > -1 ) {
                
                // 팀 중에도 매칭되는게 있다면 펼쳐서 보여준다
                if( group_obj.teams.filter(team => team.team_name.indexOf(this.group_team_search_query) > - 1).length > 0 ) {
                    group_obj.shareListOn = true;
                }

                this.group_team_search_list.push(group_obj);
                continue;
            }

            group_obj.teams = group_obj.teams.filter(team => team.team_name.indexOf(this.group_team_search_query) > - 1);
            
            // 팀 이름이 하나라도 매칭 된다면 push
            if( group_obj.teams.length > 0 ) {

                // 팀 이름만 매칭되는 경우는 리스트를 펼쳐준다
                if( this.group_team_search_query != '') {
                    group_obj.shareListOn = true;
                }

                this.group_team_search_list.push(group_obj);
            }
            
        }
    }

    /**
     * 그룹, 팀 선택 및 펼치기 데이터 조작시 원본에도 똑같이 만들어준다 
     */
    async setOriginalGroupTeamList() : Promise<void> {
        const search_count : number = this.group_team_search_list.length;
        for( let i = 0; i < search_count; i++ ) {
            const search_obj : GroupTeamInfo = this.group_team_search_list[i];

            // group_id가 같다면 원본 데이터 바꾸기
            const original_count : number = this.group_team_list.length;
            for( let j = 0; j < original_count; j++ ) {
                const original_obj : GroupTeamInfo = JSON.parse(JSON.stringify(this.group_team_list[j]));

                if( ( search_obj.group_id != original_obj.group_id ) ) {
                    continue;
                }

                // 최신화 해야하는 데이터 변경
                original_obj.shareChecked = search_obj.shareChecked == null ? false : search_obj.shareChecked;
                original_obj.shareListOn  = search_obj.shareListOn  == null ? false : search_obj.shareListOn;
                this.group_team_list.splice(j, 1, original_obj);
                
                // 팀 비교
                const search_team_count : number = search_obj.teams.length;
                for( let k = 0; k < search_team_count; k++ ) {
                    const search_team_obj : GroupTeamInfo = search_obj.teams[k];

                    // team_id가 같다면 원본 데이터 바꾸기
                    const original_team_count : number = original_obj.teams.length;
                    for( let l = 0; l < original_team_count; l++ ) {
                        const original_team_obj : GroupTeamInfo = original_obj.teams[l];

                        if( ( search_team_obj.team_id != original_team_obj.team_id ) ) {
                            continue;
                        }

                        // 최신화 해야하는 데이터 변경
                        original_team_obj.shareChecked = search_team_obj.shareChecked == null ? false : search_team_obj.shareChecked;
                        original_team_obj.shareListOn  = search_team_obj.shareListOn  == null ? false : search_team_obj.shareListOn;
                        this.group_team_list[j].teams.splice(l, 1, original_team_obj);
                    }
                }
            }

        }

    }

    /**
     * 유저 검색
     */
    @Watch('group_team_member_search_query')
    changeGroupTeamMemberSearchQuery() : void {
        this.searchMemberFunction();
        this.memberSort();
    }

    /**
     * 멤버 검색 함수
     */
    searchMemberFunction() : void {
        const original_list : GroupTeamInfo[] = JSON.parse(JSON.stringify(this.group_team_list));

        this.group_team_search_members.splice(0, this.group_team_search_members.length);
        if( this.group_team_member_search_query == '' ) {
            this.group_team_search_members = this.group_team_search_members.concat(this.group_team_members);
            return;
        }

        this.group_team_search_members = this.group_team_members.filter(member => member.user_info.user_name.indexOf(this.group_team_member_search_query) > -1);
    }

    /**
     * modal 닫기
     */
    closeModal() : void {
        this.doSetChattingRoomCreateModalInfo?.({ show_modal : false });
    }

}


