
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import AnnouncementExpectedList from '@/components/hodu_h/AnnouncementExpectedList.vue';
import AnnouncementCompleteList from '@/components/hodu_h/AnnouncementCompleteList.vue';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        AnnouncementExpectedList, AnnouncementCompleteList, ResizeObserver
    },
})
export default class AnnouncementList extends Mixins(VueHoduCommon) {

    event_bus : Vue = new Vue();

    tab : string = "EXPECTED";

    is_search_div_open : boolean = false;
    search_query : string = "";

    beforeMount() : void {
        this.tab = (this.get_group_role(this.scope_group_id) == 'GROUP_MANAGER' || this.get_group_role(this.scope_group_id) == 'ADMIN') ? "EXPECTED" : "COMPLETE";
    }

    mounted() : void {

        //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.aptAnn_con > ul').hide();
        // 	$('.aptAnn_con > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });
    }

    moveCreate() : void {
        this.hodu_router_push(`/home/announcement/create`);
    }

    /**
     * 검색
     */
    search() : void {
        this.is_search_div_open = true;
        this.$nextTick(() => $('#announcement_search').focus());
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        if( this.tab == 'EXPECTED' ) this.event_bus.$emit('expected_scroll');
        if( this.tab == 'COMPLETE' ) this.event_bus.$emit('complete_scroll');
    }

}
