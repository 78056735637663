
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { EventEmailShareModalInfo } from '@/store/modules/ModalInfo';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class EventEmailShareModal extends Mixins(VueHoduCommon) {

    /**
     * 정렬, 검색 결과 등 반영하고 나온 그룹, 팀 타겟 리스트
     */
    get computedTargetList() : any[] {

        let target_list : any[] = [];
        
        target_list = target_list.concat(this.target_list.filter(item => true));

        // 그룹 이름 또는 팀 이름 검색
        if( this.group_team_search_query.length > 0 ) {
            
            const temp : any[] = [];
            for( const group of target_list ) {
                const group_name : string = group.group_info.group_name;

                if( this.hodu_string_includes(group_name, this.group_team_search_query) ) {
                    temp.push(group);
                    continue;
                }
                
                for( const team of group.teams ) {
                    const team_name : string = team.team_info.team_name;
                    team.isNotincluded = !this.hodu_string_includes(team_name, this.group_team_search_query);
                }

                if( group.teams.filter(item => item.isNotincluded == false).length > 0 ) temp.push(group);
            }

            target_list.splice(0, target_list.length);
            target_list = target_list.concat(temp);
        }

        // 정렬 함수 (그룹, 팀 모두 적용해야하기 때문)
        const sort_function = (o1, o2) : number => {

            // 그룹, 팀 이름 오름차순
            if( this.group_team_sort_type == "NAME" && this.group_team_sort_direction == "ASC" ) {
                if( o1.team_info == null || o1.team_info.team_name == null || o1.team_info.team_name.length < 1 || 
                    o2.team_info == null || o2.team_info.team_name == null || o2.team_info.team_name.length < 1 ) {
                    if( o1.group_info.group_name  > o2.group_info.group_name ) { return  1; } 
                    if( o1.group_info.group_name  < o2.group_info.group_name ) { return -1; } 
                    if( o1.group_info.group_name == o2.group_info.group_name ) { return  0; }
                } 

                else {
                    if( o1.team_info.team_name  > o2.team_info.team_name ) { return  1; } 
                    if( o1.team_info.team_name  < o2.team_info.team_name ) { return -1; } 
                    if( o1.team_info.team_name == o2.team_info.team_name ) { return  0; }
                }
            }

            // 그룹, 팀 이름 내림차순
            if( this.group_team_sort_type == "NAME" && this.group_team_sort_direction == "DESC" ) {
                if( o1.team_info == null || o1.team_info.team_name == null || o1.team_info.team_name.length < 1 || 
                    o2.team_info == null || o2.team_info.team_name == null || o2.team_info.team_name.length < 1 ) {
                    if( o1.group_info.group_name  > o2.group_info.group_name ) { return -1; } 
                    if( o1.group_info.group_name  < o2.group_info.group_name ) { return  1; } 
                    if( o1.group_info.group_name == o2.group_info.group_name ) { return  0; }
                }

                else {
                    if( o1.team_info.team_name  > o2.team_info.team_name ) { return -1; } 
                    if( o1.team_info.team_name  < o2.team_info.team_name ) { return  1; } 
                    if( o1.team_info.team_name == o2.team_info.team_name ) { return  0; }
                }
            }

            // 그룹, 팀 멤버 수 오름차순
            if( this.group_team_sort_type == "COUNT" && this.group_team_sort_direction == "ASC" ) {
                
                if( o1.team_info == null || o1.team_info.team_name == null || o1.team_info.team_name.length < 1 || 
                    o2.team_info == null || o2.team_info.team_name == null || o2.team_info.team_name.length < 1 ) {
                    if( o1.group_member_count  > o2.group_member_count ) { return  1; } 
                    if( o1.group_member_count  < o2.group_member_count ) { return -1; } 
                    if( o1.group_member_count == o2.group_member_count ) { return  0; } 
                } 

                else {
                    if( o1.team_member_count  > o2.team_member_count ) { return  1; } 
                    if( o1.team_member_count  < o2.team_member_count ) { return -1; } 
                    if( o1.team_member_count == o2.team_member_count ) { return  0; } 
                }
                
            }

            // 그룹, 팀 멤버 수 내림차순
            if( this.group_team_sort_type == "COUNT" && this.group_team_sort_direction == "DESC" ) {

                if( o1.team_info == null || o1.team_info.team_name == null || o1.team_info.team_name.length < 1 || 
                    o2.team_info == null || o2.team_info.team_name == null || o2.team_info.team_name.length < 1 ) {
                    if( o1.group_member_count  > o2.group_member_count ) { return -1; } 
                    if( o1.group_member_count  < o2.group_member_count ) { return  1; } 
                    if( o1.group_member_count == o2.group_member_count ) { return  0; } 
                } 

                else {
                    if( o1.team_member_count  > o2.team_member_count ) { return -1; } 
                    if( o1.team_member_count  < o2.team_member_count ) { return  1; } 
                    if( o1.team_member_count == o2.team_member_count ) { return  0; } 
                }

            }

            return 0;
        };

        // 정렬
        for( const group of target_list ) group.teams.sort(sort_function);
        target_list.sort(sort_function);

        return target_list;
    }

    /**
     * 선택된 그룹, 팀의 멤버 
     */
    get computedTargetMember() : any[] {

        let target_member_list : any[] = [];

        if ( this.selected_target == null ) return target_member_list;

        if( this.selected_target.group_member != null ) {
            target_member_list = target_member_list.concat(this.selected_target.group_member);
        }

        if( this.selected_target.team_member != null ) {
            target_member_list = target_member_list.concat(this.selected_target.team_member);
        }

        // 검색
        if( this.member_search_query.length > 0 ) {

            const temp : any[] = target_member_list.filter((member) => 
                this.hodu_string_includes(member.user_name, this.member_search_query) ||
                this.hodu_string_includes(member.user_email, this.member_search_query));

            target_member_list.splice(0, target_member_list.length);
            target_member_list = target_member_list.concat(temp);
        }

        // 정렬
        target_member_list.sort((o1, o2) : number => {

            // 그룹, 팀 이름 오름차순
            if( this.member_sort_type == "NAME" && this.member_sort_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 그룹, 팀 이름 내림차순
            if( this.member_sort_type == "NAME" && this.member_sort_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            return 0;
        });

        return target_member_list;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State event_email_share_modal_info !: EventEmailShareModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventEmailShareModalInfo ?: (parms : EventEmailShareModalInfo) => void;

    target_list : any[] = [];

    user_ids : string[] = [];
    group_ids : number[] = [];
    team_ids : string[] = [];

    user_info_map : Map<string, string> = new Map();

    group_team_search_mode : boolean = false;
    group_team_search_query : string = "";
    group_team_sort_type : string = "NAME";
    group_team_sort_direction : string = "ASC";

    member_search_mode : boolean = false;
    member_search_query : string = "";
    member_sort_type : string = "NAME";
    member_sort_direction : string = "ASC";

    selected_target : any = null;

    mounted() : void {
        this.getTargetEventShareByEmail();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        // @ts-ignore
        $('.shareUlDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : 316,
            scrollbarPosition : 'outside',
        });

        // @ts-ignore
        $('.peepsUlDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : 316,
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 이메일 공유 대상 조회
     */
    async getTargetEventShareByEmail() : Promise<void> {

        const url = `api/v1/calendars/${this.event_email_share_modal_info.calendar_id}/events/${this.event_email_share_modal_info.scope}/${this.event_email_share_modal_info.scope_id}/${this.event_email_share_modal_info.event_id}/share/email`;

        try {
            const response = await this.hodu_api_call(url, API_METHOD.GET);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.target ) {
                throw new Error("이메일 공유 대상 조회 실패");
            }

            if( this.auth_info == null || this.auth_info.auth_check == false ) {
                response.data.data.target = response.data.data.target.filter(
                    group => group.group_type != 'BIZH' && group.group_info.project_type != 'kids' 
                );
            }

            this.target_list.splice(0, this.target_list.length);
            this.target_list = this.target_list.concat(response.data.data.target);

        } catch(e) {
            this.hodu_error_process(e, false, false);
        }

    }

    /**
     * 검색 모드 변환
     */
    searchModeToggle() : void {

        // 그룹, 팀
        if( this.selected_target == null ) {
            this.group_team_search_mode = !this.group_team_search_mode;

            if( this.group_team_search_mode == false ) this.group_team_search_query = "";
        }
        
        // 멤버
        if( this.selected_target != null ) {
            this.member_search_mode = !this.member_search_mode;

            if( this.member_search_mode == false ) this.member_search_query = "";
        }
    }

    /**
     * 이미지 에러 발생시
     */
    imageError(event : any, color : string) : void {
        let temp_class : string = "";
        switch( this.hodu_hex_color_process(color).toUpperCase() ) {
            /**
             * dc - 진한색
             */
            case '#FF6363':
                temp_class = 'imgDc0';
                break;

            case '#FFA70E':
                temp_class = 'imgDc1';
                break;

            case '#FFC72F':
                temp_class = 'imgDc2';
                break;

            case '#FF198B':
                temp_class = 'imgDc3';
                break;

            case '#00B2C7':
                temp_class = 'imgDc4';
                break;

            case '#13D08B':
                temp_class = 'imgDc5';
                break;

            case '#4DBAFF':
                temp_class = 'imgDc6';
                break;

            case '#477FFF':
                temp_class = 'imgDc7';
                break;

            case '#6854FF':
                temp_class = 'imgDc8';
                break;

            case '#35405A':
                temp_class = 'imgDc9';
                break;
            
            /**
             * lc - 옅은색 (현재 그룹에 옅은색은 없지만 대비용으로 만들어 놓음)
             */
            case '#B9A494':
                temp_class = 'imgLc0';
                break;

            case '#E67D9B':
                temp_class = 'imgLc1';
                break;

            case '#FF9898':
                temp_class = 'imgLc2';
                break;

            case '#AB8DD6':
                temp_class = 'imgLc3';
                break;

            case '#F3AC77':
                temp_class = 'imgLc4';
                break;

            case '#E3D37A':
                temp_class = 'imgLc5';
                break;

            case '#8DD6A0':
                temp_class = 'imgLc6';
                break;

            case '#7197ED':
                temp_class = 'imgLc7';
                break;

            case '#8DAED6':
                temp_class = 'imgLc8';
                break;

            case '#6F7A93':
                temp_class = 'imgLc9';
                break;

            default:
                temp_class = 'imgDc7';
                break;

        }

        $(event.target).parent().find('.img').addClass(temp_class);
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 그룹의 팀 리스트 ON / OFF
     */
    teamListOnOff(group : any) : void {
        const group_copy = JSON.parse(JSON.stringify(group));
        
        if( group_copy.list_on == null ) group_copy.list_on = true;
        else group_copy.list_on = !group_copy.list_on;

        const index = this.target_list.indexOf(group);
        if( index > -1 ) this.target_list.splice(index, 1, group_copy);
    }

    /**
     * 타겟 선택
     */
    selectTarget(target : any) : void {
        this.selected_target = target;
    }

    /**
     * 그룹 선택
     */
    groupSelect(event : any, group : any) : void {
        const key = group.group_id;

        const checked : boolean = event.target.checked;
        const index = this.group_ids.indexOf(key);

        if( checked ) {

            if( index < 0 ) {
                this.group_ids.push(key);
            }
            
            // 그룹 멤버 전체 선택
            for( const member of group.group_member ) {
                const member_copy = JSON.parse(JSON.stringify(member));
                member_copy.checked = true;
                group.group_member.splice(group.group_member.indexOf(member), 1, member_copy);
            }
        }

        else if( !checked ) {

            if( index > - 1 ) {
                this.group_ids.splice(index, 1);
            }

            // 그룹 멤버 전체 선택 해제
            for( const member of group.group_member ) {
                const member_copy = JSON.parse(JSON.stringify(member));
                member_copy.checked = false;
                group.group_member.splice(group.group_member.indexOf(member), 1, member_copy);
            }
        }

        // user_ids 에서 item.split('-')[0] == group.group_id 인 녀석들을 전부 제거해야함
        const temp = this.user_ids.filter(item => item.split('-')[0] != group.group_id && item.split('-')[1] == '0');
        this.user_ids.splice(0, this.user_ids.length);
        this.user_ids = this.user_ids.concat(temp);
    }

    /**
     * 팀 선택
     */
    teamSelect(event : any, team : any) : void {
        const key = `${team.group_id}-${team.team_id}`;

        const checked : boolean = event.target.checked;
        const index = this.team_ids.indexOf(key);

        if( checked ) {

            if( index < 0 ) {
                this.team_ids.push(key);
            }

            // 팀 멤버 전체 선택
            for( const member of team.team_member ) {
                const member_copy = JSON.parse(JSON.stringify(member));
                member_copy.checked = true;
                team.team_member.splice(team.team_member.indexOf(member), 1, member_copy);
            }
        }

        else if( !checked && index > -1 ) {
            this.team_ids.splice(index, 1);
            
            // 팀 멤버 전체 선택 해제
            for( const member of team.team_member ) {
                const member_copy = JSON.parse(JSON.stringify(member));
                member_copy.checked = false;
                team.team_member.splice(team.team_member.indexOf(member), 1, member_copy);
            }
        }

        // user_ids 에서 item.split('-')[1] == team.team_id 인 녀석들을 전부 제거해야함
        const temp = this.user_ids.filter(item => item.split('-')[1] != team.team_id);
        this.user_ids.splice(0, this.user_ids.length);
        this.user_ids = this.user_ids.concat(temp);
    }

    /**
     * 멤버 선택
     */
    async memberSelect() : Promise<void> {

        const team_id = this.selected_target.team_id != null ? this.selected_target.team_id : 0;

        // 그룹 또는 팀 멤버가 전체 선택됐다면 group_ids, team_ids에 추가 && user_ids 정리
        if( this.isMemberAllChecked() ) {

            if( team_id > 0 ) {
                const team_key = `${this.selected_target.group_id}-${team_id}`;
                const index = this.team_ids.indexOf(team_key);
                if( index < 0 ) this.team_ids.push(team_key);
            }
            
            else {
                const group_key = this.selected_target.group_id;
                const index = this.group_ids.indexOf(group_key);
                if( index < 0 ) this.group_ids.push(group_key);
            }

            for( const member of (this.selected_target.team_member ?? this.selected_target.group_member) ) {
                const user_key = `${this.selected_target.group_id}-${team_id}-${member.user_id}`;
                const index = this.user_ids.indexOf(user_key);

                if( index > -1 ) this.user_ids.splice(index, 1);
            }
        }

        // 전부 선택된게 아니라면 user_ids에 추가, group_ids 또는 team_ids에서 제거
        else {
            for( const member of (this.selected_target.team_member ?? this.selected_target.group_member) ) {
                const user_key = `${this.selected_target.group_id}-${team_id}-${member.user_id}`;
                const index = this.user_ids.indexOf(user_key);
                if( index < 0 && member.checked ) this.user_ids.push(user_key);
                else if( index > -1 && !member.checked ) this.user_ids.splice(index, 1);
            }

            if( team_id > 0 ) {
                const team_key = `${this.selected_target.group_id}-${team_id}`;
                const index = this.team_ids.indexOf(team_key);
                if( index > -1 ) this.team_ids.splice(index, 1);
            }

            else {
                const group_key = this.selected_target.group_id;
                const index = this.group_ids.indexOf(group_key);
                if( index > -1 ) this.group_ids.splice(index, 1);
            }
        }

        if( team_id > 0 ) {
            // team_ids item.split('-')[1] == team_id 또는 user_ids의 item.split('-')[1] == team_id 인게 하나라도 있다면 true 아니라면 false
            const team_count = this.team_ids.filter(team_key => team_key.split('-')[1] == team_id).length;
            const user_count = this.user_ids.filter(user_key => user_key.split('-')[1] == team_id).length;
            this.selected_target.checked = team_count + user_count > 0;
        }

        else {
            // group_ids item == group_id 또는 user_ids의 item.split('-')[0] == group_id 인게 하나라도 있다면 true 아니라면 false
            const group_count = this.group_ids.filter(group_key => group_key == this.selected_target.group_id).length;
            const user_count = this.user_ids.filter(user_key => user_key.split('-')[0] == this.selected_target.group_id && user_key.split('-')[1] == '0').length;
            this.selected_target.checked = group_count + user_count > 0;
        }
    }

    /**
     * 전체 멤버 선택 / 선택 해제
     */
    selectAllMember(event : any) : void {
        const checked = event.target.checked;
        const team_id = this.selected_target.team_id != null ? this.selected_target.team_id : 0;

        for( const member of ( ( team_id > 0 ) 
                               ? this.selected_target.team_member 
                               : this.selected_target.group_member) ) {
                
            const member_copy = JSON.parse(JSON.stringify(member));
            member_copy.checked = checked;
            
            if( team_id > 0 ) this.selected_target.team_member.splice(this.selected_target.team_member.indexOf(member), 1, member_copy);
            else this.selected_target.group_member.splice(this.selected_target.group_member.indexOf(member), 1, member_copy);
        }
    }

    /**
     * 선택된 수
     */
    getSelectedMemberCount() : number {

        let member_check_count : number = 0;

        if( this.selected_target == null ) return 0;

        if( (this.selected_target.team_id ?? 0) > 0 ) {
            member_check_count = this.selected_target.team_member.filter(item => item.checked == true).length;
        }

        else {
            member_check_count = this.selected_target.group_member.filter(item => item.checked == true).length;
        }

        return member_check_count; 
    }
    
    /**
     * 멤버 전체가 선택되어 있는가 반환
     */
    isMemberAllChecked() : boolean {

        let member_all_checked : boolean = false;

        if( this.selected_target == null ) return false;

        if( (this.selected_target.team_id ?? 0) > 0 ) {
            member_all_checked = this.selected_target.team_member.length == this.getSelectedMemberCount();
        }

        else {
            member_all_checked = this.selected_target.group_member.length == this.getSelectedMemberCount();
        }

        return member_all_checked;
    }

    /**
     * 그룹, 팀 정렬 
     */
    groupTeamSort(sort_type : string) : void {
        if( this.group_team_sort_type != sort_type ) {
            this.group_team_sort_type = sort_type;
            this.group_team_sort_direction = 'ASC';
            return;
        }

        this.group_team_sort_direction = this.group_team_sort_direction == 'ASC' ? 'DESC' : 'ASC';
    }

    /**
     * 멤버 정렬
     */
    memberSort(sort_type : string) : void {
        if( this.member_sort_type != sort_type ) {
            this.member_sort_type = sort_type;
            this.member_sort_direction = 'ASC';
            return;
        }

        this.member_sort_direction = this.member_sort_direction == 'ASC' ? 'DESC' : 'ASC';
    }

    /**
     * 취소 클릭
     */
    async cancel() : Promise<void> {

        if( this.selected_target != null ) {

            const group_id = this.selected_target.group_id;
            const team_id = this.selected_target.team_id != null ? this.selected_target.team_id : 0;

            // checked 정리
            if( team_id > 0 ) {

                for( const member of this.selected_target.team_member ) {
                    const member_copy = JSON.parse(JSON.stringify(member));

                    // team 이 선택됨 or user가 선택됨
                    const index_team = this.team_ids.indexOf(`${member.group_id}-${member.team_id}`);
                    const index_user = this.user_ids.indexOf(`${member.group_id}-${member.team_id}-${member.user_id}`);

                    member_copy.checked = index_team > -1 || index_user > - 1;
                    this.selected_target.team_member.splice(this.selected_target.team_member.indexOf(member), 1, member_copy);
                }
            }

            else {
                for( const member of this.selected_target.group_member ) {
                    const member_copy = JSON.parse(JSON.stringify(member));

                    // group 이 선택됨 or user가 선택됨
                    const index_group = this.group_ids.indexOf(member.group_id);
                    const index_user = this.user_ids.indexOf(`${member.group_id}-${member.team_id}-${member.user_id}`);

                    member_copy.checked = index_group > -1 || index_user > - 1;
                    this.selected_target.group_member.splice(this.selected_target.group_member.indexOf(member), 1, member_copy);
                }
            }

            this.selected_target = null;
            this.member_search_query = "";
            this.member_search_mode = false;
            return;
        }

        this.closeModal();
    }

    /**
     * 공유 or 확인
     */
    async share() : Promise<void> {

        if( this.selected_target != null ) {
            await this.memberSelect();
            await this.cancel();
        }

        // 최종 가공
        else {
            for( const user_key of this.user_ids ) {
                const group_id = Number(user_key.split('-')[0]);
                const team_id = Number(user_key.split('-')[1]);
                const user_id = Number(user_key.split('-')[2]);

                const groups = this.target_list.filter(group => group.group_id == group_id);

                if( groups.length < 1 ) continue;

                if( team_id > 0 ) {
                    const teams = groups[0].teams.filter(team => team.team_id == team_id);

                    if( teams.length < 1 ) continue;

                    const members = teams[0].team_member.filter(member => member.user_id == user_id);
                    
                    if( members.length < 1 ) continue;

                    this.user_info_map.set(members[0].user_email, members[0].user_name);
                }

                else {
                    const members = groups[0].group_member.filter(member => member.user_id == user_id);
                    
                    if( members.length < 1 ) continue;

                    this.user_info_map.set(members[0].user_email, members[0].user_name);
                }
            }

            for( const group_id of this.group_ids ) {
                const groups = this.target_list.filter(group => group.group_id == group_id);

                if( groups.length < 1 ) continue;

                for( const member of groups[0].group_member ) {
                    this.user_info_map.set(member.user_email, member.user_name);
                }
            }

            for( const team_key of this.team_ids ) {
                const group_id = Number(team_key.split('-')[0]);
                const team_id = Number(team_key.split('-')[1]);

                const groups = this.target_list.filter(group => group.group_id == group_id);

                if( groups.length < 1 ) continue;

                const teams = groups[0].teams.filter(team => team.team_id == team_id);

                if( teams.length < 1 ) continue;

                for( const member of teams[0].team_member ) {
                    this.user_info_map.set(member.user_email, member.user_name);
                }
            }
            
            // console.log(this.user_info_map);
            // console.log(JSON.stringify(this.user_info_map));
        
            const user_info_array : any[] = [];
            for( const [email, name] of this.user_info_map ) {
                user_info_array.push({ email, name });
            }

            // console.log(user_info_array);
            // console.log(encodeURIComponent(JSON.stringify(user_info_array)));

            let is_all_day = false;
            if( this.event_email_share_modal_info.end.getFullYear() == this.event_email_share_modal_info.start.getFullYear() &&
                this.event_email_share_modal_info.end.getMonth()    == this.event_email_share_modal_info.start.getMonth()    &&
                this.event_email_share_modal_info.end.getDate()     == this.event_email_share_modal_info.start.getDate() ) {
                
                let event_time = this.event_email_share_modal_info.end.getTime() - this.event_email_share_modal_info.start.getTime();

                // 23시간 59분 차이가 난다면 
                let all_day_time = (23 * 60 * 60 * 1000) + (59 * 60 * 1000);

                if( event_time >= all_day_time ) is_all_day = true;
            }

            const start = encodeURIComponent(`${this.hodu_date_to_format_string(this.event_email_share_modal_info.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.event_email_share_modal_info.start, '요일')}${ !is_all_day ? ` ${this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(this.event_email_share_modal_info.start, 'hh:mm a'))}` : '' }`);
            const end = encodeURIComponent(`${this.hodu_date_to_format_string(this.event_email_share_modal_info.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.event_email_share_modal_info.end, '요일')}${ !is_all_day ? ` ${this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(this.event_email_share_modal_info.end, 'hh:mm a'))}` : '' }`);
            const start_timestamp = this.event_email_share_modal_info.start.getTime();
            const end_timestamp = this.event_email_share_modal_info.end.getTime();

            const title = this.event_email_share_modal_info.title;

            let user_info_query = encodeURIComponent(JSON.stringify(user_info_array));
            let query : string = `event_id=${this.event_email_share_modal_info.event_id}&scope=${this.event_email_share_modal_info.scope}&scope_id=${this.event_email_share_modal_info.scope_id}&start=${start}&start_timestamp=${start_timestamp}&end=${end}&end_timestamp=${end_timestamp}&title=${title}&sender_email=${this.event_email_share_modal_info.sender_email}&user_info=${user_info_query}`;
            window.open(`/mail_share.html?&${query}`, "hodu_email_share", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);

            await this.cancel();
            return;
        }
    }

    /**
     * 모달 종료
     */
    closeModal() : void {
        this.doSetEventEmailShareModalInfo?.({ show_modal : false, calendar_id : "", event_id : "", scope : "", scope_id : 0, start : new Date(), end : new Date()});
    }

}
