
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const WorkInfo = namespace('WorkInfo');

import { ResizeObserver } from 'vue-resize'
import { t_work_template } from '@/model/osm';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class WorkTemplateAdd extends Mixins(VueHoduCommon) {
    
    /**
     * @WorkInfo.State
     */
    @WorkInfo.State work_template_id !: string;

    template : t_work_template | null = null;
    is_create : boolean = true;

    step_count : number = 0;

    section_two_list_on   : boolean = false;
    section_three_list_on : boolean = false;

    async mounted() : Promise<void> {
        
		// 업무 색상 선택
		$(".content .second .clr input").click(function(){
			$(this).parent().siblings(".pickColour").toggleClass("on");
        });
        
        // 생성인지 조회(수정)인지 구분
        if( this.work_template_id != null && this.work_template_id.length > 0 ) {
            this.is_create = false;
        }

        // 업무 템플릿 작성 중에 업무 작성 권한이 없다면 페이지 이동
        if( this.is_create == true && this.getWorkPermission('create') == false ) {
            this.movePrevPage();
            return;
        }

        // 업무 템플릿 수정 중에 업무 수정 권한이 없다면 페이지 이동
        if( this.is_create == false && this.getWorkPermission('modify') == false ) {
            this.movePrevPage();
            return;
        }

        this.setScroll();

        // 조회라면 서버에서 조회
        if( this.is_create == false ) {
            await this.selectWorkTemplate();
        }

        // 생성이라면 초기값 부여
        else {

            this.template = {
                template_id : '',
                template_type : 'CHECK',
                scope : this.scope,
                scope_id : this.scope == OWNER_TYPE.TEAM ? this.scope_team_id : this.scope_group_id,
                last_update_tag : '0',
                audit_modified : new Date(),
                audit_delete_flag : false,
                audit_user_id : this.user_id, 
                template_info : {
                    name : '',
                    content : [
                        { color : '', title : '' },
                        { color : '', title : '' },
                        { color : '', title : '' },
                        { color : '', title : '' },
                        { color : '', title : '' }
                    ],
                    is_multi_select : false
                },
            }

        }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height : number | undefined = $('.title_box').outerHeight();
        const final_height : number             = window.innerHeight - ( title_height ? title_height : 0 );
        
        // @ts-ignore
        $('#workAddScroll').mCustomScrollbar({
            axis : 'y',
            setHeight : final_height,
            scrollbarPosition : 'outside'
        })
    }

    /**
     * 업무 템플릿 조회
     */
    async selectWorkTemplate() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template/select/${ this.work_template_id }`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                vue.template = response.data.data.template;

                if( vue.template != null ) {
                    vue.step_count = vue.template.template_info.content.length;
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });
    }

    /**
     * 업무 다중 선택 변경
     */
    changeIsMultiSelectType(is_multi_select : boolean) : void {
        if( this.template == null ) {
            return;
        }

        this.template.template_info.is_multi_select = is_multi_select;
        this.section_two_list_on = false;
    }

    /**
     * 업무 타입 선택 리스트 ON / OFF
     */
    sectionTwoListOnOff() : void {
        if( this.template == null || this.template.template_info.content.length < 2 ) {
            return;
        }

        this.section_two_list_on   = !this.section_two_list_on;
        this.section_three_list_on = false;
    }

    /**
     * 다중 선택 리스트 ON / OFF
     */
    sectionThreeListOnOff() : void {        
        this.section_two_list_on   = false;
        this.section_three_list_on = !this.section_three_list_on;
    }

    /**
     * 업무 개수 변경
     */
    workProcessCountChange(n : number) : void {
        if( this.template == null ) {
            this.section_three_list_on = false;
            return;
        }

        // 기존 업무 개수와 같은 경우
        if( this.template.template_info.content.length == n ) {
            this.section_three_list_on = false;
            return;
        }

        // 기존 업무 개수보다 많은 경우
        else if ( this.template.template_info.content.length < n ) {
            const repeat_count : number = n - this.template.template_info.content.length;
            for( let i = 0; i < repeat_count; i++ ) {
                this.template.template_info.content.push({ color : '', title : '' });
            }
        }

        // 기존 업무 개수보다 적은 경우 
        else {
            const template_count : number = this.template.template_info.content.length;
            for( let i = ( template_count - 1 ); i >= n; i-- ) {
                this.template.template_info.content.splice(i, 1);
            }
        }

        // 1개일때 자동으로 다중선택 금지
        if( n == 1 ) { this.template.template_info.is_multi_select = false; }

        this.section_three_list_on = false;
    }

    /**
     * 업무 컬러 선택창 ON / OFF
     */
    workProcessColorListOnOff(content : any, index : number) : void {
        if( this.template == null ) {
            return;
        }

        // 나머지 전부 닫기
        for( let template_content of this.template.template_info.content ) {
            if( this.template.template_info.content.indexOf(template_content) != index ) {
                template_content.colorOn = false;
                this.template.template_info.content.splice(this.template.template_info.content.indexOf(template_content), 1, template_content);
            }
        }

        content.colorOn = ( content.colorOn == null || content.colorOn == false ) ? true : false;
        this.template.template_info.content.splice(index, 1, content);
    }

    /**
     * 업무 컬러 바꾸기
     */
    changeColor(content : any, index : number, color : string) : void {
        if( this.template == null ) {
            return;
        }

        // 중복된 컬러 클릭 안되게
        const content_count : number = this.template.template_info.content.length;
        for( let i = 0; i < content_count; i++ ) {
            if( this.hodu_hex_color_process(color, '#FFFFFF').toUpperCase() == this.hodu_hex_color_process(this.template.template_info.content[i].color, '#FFFFFF').toUpperCase() ) {
                this.hodu_show_dialog('alert', "이미 선택 된 색상 입니다", ["확인"]);
                return;
            }
        }

        content.color   = color;
        content.colorOn = false;
        this.template.template_info.content.splice(index, 1, content);
    }

    /**
     * 템플릿 저장
     */
    async saveTemplate() : Promise<void> {
        if( this.template == null ) {
            return;
        }

        if( this.checkValid() == false ) {
            return;
        }

        // 업무 템플릿 생성
        if( this.is_create == true ) {
            await this.insertOrUpdateWorkTemplate();
        }

        // 업무 템플릿 조회
        else {
            if( this.step_count == this.template.template_info.content.length ) {
                await this.insertOrUpdateWorkTemplate();
                return;
            }

            await this.hodu_show_dialog('alert', "업무 개수가 달라지면<br>기존에 수행한 업무 데이터가 전부 사라집니다<br>정말 업무 템플릿을 수정하시겠습니까?",
                                  ['아니오', '예'], [() => {}, () => { this.insertOrUpdateWorkTemplate(true) } ]);
        }
    }

    /**
     * 업무 템플릿 insert 또는 update
     */
    async insertOrUpdateWorkTemplate(update_step_count : boolean = false) : Promise<void> {
        const vue = this;

        if( this.template == null ) {
            return;
        }

        // 제목, 컬러 비어있는 칸 제거
        let li_contentSize = this.template.template_info.content.length;
        for ( let i = (li_contentSize - 1); i >= 0; i-- ){
            let ls_title : string = this.template.template_info.content[i].title.trim();
            let ls_color : string = this.template.template_info.content[i].color;

            if( ( ls_title == null || ls_title.length < 1 ) ||
                    ( ls_color == null || ls_color.length < 1 ) ) {
                this.template.template_info.content.splice(i, 1)
            }
        }

        const url : string = `api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template?update_template_count=${ update_step_count }`;
        this.hodu_api_call(url, API_METHOD.POST, this.template)
            .then((response) => {
                console.log(response);
                vue.movePrevPage();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 데이터 체크
     */
    checkValid() : boolean {
        if( this.template == null ) {
            this.hodu_show_dialog('alert', "데이터 오류 잠시 후 다시 시도해주세요", ["확인"], [() => { this.movePrevPage(); }]);
            return false;
        }

        // 업무 제목 체크
        if( this.template.template_info.name.trim().length < 2 ) {
            this.hodu_show_dialog('alert', "업무 제목을 2자 이상 입력 해주세요", ["확인"]);
            return false;
        }

        // 업무 제목 체크
        if( this.template.template_info.name.trim().length > 25 ) {
            this.hodu_show_dialog('alert', "업무 제목을 25자 이내로 입력 해주세요", ["확인"]);
            return false;
        }

        // 제대로 입력한게 1개 이상인지 체크
        let li_count : number = 0;
        let li_contentSize : number = this.template.template_info.content.length;
        for( let i = 0; i < li_contentSize; i++ ){
            let ls_title : string = this.template.template_info.content[i].title.trim();
            let ls_color : string = this.template.template_info.content[i].color;

            // 내용, 색상 둘다 입력되어있는 경우
            if( ( ls_title != null && ls_title.length > 0 ) &&
                    ( ls_color != null && ls_color.length > 0 ) ) {
                li_count++;
            }
        }

        // 제대로 입력한 업무 내용이 1개 미만일때
        if( li_count < 1 ){
            this.hodu_show_dialog('alert', "업무 내용 및 색상을 1개 이상 입력 해주세요", ["확인"]);
            return false;
        }

        // FLOW, CHECK 둘다 체크해야하는내용
        for( let i = 0; i < li_contentSize; i++ ){
            let ls_title : string = this.template.template_info.content[i].title.trim();
            let ls_color : string = this.template.template_info.content[i].color;

            // 내용은 있는데 색상이 없는경우 or 색상은 있는데 내용이 없는 경우 => XOR (exclusive or)
            if( ( ls_title == null || ls_title.length < 1 ) !==
                    ( ls_color == null || ls_color.length < 1 ) ) {
                this.hodu_show_dialog('alert', `${ (i + 1) }번째 항목의 내용 및 색상을 입력 해주세요`, ["확인"]);
                return false;
            }
        }

        // FLOW일때 내용 index별 체크
        if( this.template.template_type == 'FLOW' ){
            for ( let i = (li_contentSize - 1); i >= 0; i-- ){
                let ls_title : string = this.template.template_info.content[i].title.trim();
                let ls_color : string = this.template.template_info.content[i].color;

                // 내용, 색상 둘다 입력되어있는 경우
                if( ( ls_title != null && ls_title.length > 0 ) &&
                        ( ls_color != null && ls_color.length > 0 ) ) {

                    // 이전 항목이 입력되어있지 않는 경우를 찾는다
                    for( let j = 0; j < i; j++){
                        let ls_beforeTitle : string = this.template.template_info.content[j].title.trim();
                        let ls_beforeColor : string = this.template.template_info.content[j].color;

                        if( ( ls_beforeTitle == null || ls_beforeTitle.length < 1 ) ||
                                ( ls_beforeColor == null || ls_beforeColor.length < 1 ) ) {
                            this.hodu_show_dialog('alert', `${ (j + 1) }번째 항목의 내용 및 색상을 입력 해주세요`, ["확인"]);
                            return false;
                        }

                    }

                }

            }
        }

        return true;
    }

    /**
     * 업무 템플릿 삭제
     */
    deleteTemplate() : void {
        const vue = this;

        if( this.template == null ) {
            return;
        }

        const template_id : string = this.template.template_id;

        this.hodu_show_dialog('cancel', "해당 업무 템플릿을 삭제하시겠습니까?",['아니오', '예'], 
            [() => {}, () => {
                const url : string = `api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template/${ template_id }`; 
                this.hodu_api_call(url, API_METHOD.DELETE)
                    .then((response) => {
                        console.log(response);
                        vue.movePrevPage();
                    })
                    .catch((e) => {
                        this.hodu_error_process(e, true, false);
                    });
            } ]);
    }

    /**
     * 업무 권한 구하기
     * 업무 작성 권한(create) - 템플릿 작성 권한
     * 업무 읽기 권한(read)   - 템플릿 리스트 조회 권한
     * 업무 수정 권한(modify) - 템플릿 수정 권한
     * 업무 삭제 권한(delete) - 템플릿 삭제 권한
     */
    getWorkPermission(crud_type : string) : boolean {
        
        // 그룹
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, 'work', crud_type);
        } 
        
        // 팀
        else if ( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, 'work', crud_type);
        } 

        // 그 외의 경우는 불가능
        else {
            return false;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#workAddScroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
