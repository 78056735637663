
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD, GROUP_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const MenuInfo = namespace('MenuInfo');

import moment from 'moment';

import { directive as onClickaway } from 'vue-clickaway';
import { hodu_local_storage, local_storage_info } from '@/lib/HoduLocalStorage';

@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        
    },
})
export default class RightController extends Mixins(VueHoduCommon) {

    /**
     * @CalendarInfo.State 
     */
    @CalendarInfo.State calendar_view_type !: string;

    /**
     * @MenuInfo.State
     */
    @MenuInfo.State user_profile_open           !: boolean;
    @MenuInfo.State notification_list_menu_open !: boolean;
    @MenuInfo.State preferences_menu_open       !: boolean;
    @MenuInfo.State todo_menu_open              !: boolean;
    @MenuInfo.State todo_create_or_update       !: boolean;
    @MenuInfo.State etc_menu_open               !: boolean;
    @MenuInfo.State filter_menu_open            !: boolean;
    
    /**
     * @MenuInfo.Action
     */
    @MenuInfo.Action doSetUserProfileOpen          ?: any;
    @MenuInfo.Action doSetNotificationListMenuOpen ?: any;
    @MenuInfo.Action doSetPreferencesMenuOpen      ?: any;
    @MenuInfo.Action doSetTodoMenuOpen             ?: any;
    @MenuInfo.Action doTodoCreateOrUpdate          ?: any;
    @MenuInfo.Action doSetEtcMenuOpen              ?: any;
    @MenuInfo.Action doSetFilterMenuOpen           ?: (params : boolean) => void;

    profile_image_error : boolean = false;
    userImageUrl : string = require("@/assets/images/contents/im_photoB.gif");
    userDefaultImageUrl : string = require("@/assets/images/contents/im_photoB.gif");

    is_remain_noti_and_todo : number | undefined;
    notificatin_count : number = 0;
    todo_count : number = 0;

    beforeMount() : void {
        this.userImageUrl = `/app_images/profile/user/${Math.floor((this.user_id != null ? this.user_id : 0) / 10000)}/${this.user_id}.jpg`;
    }

    mounted() : void {
        this.getNotificationCount();
        this.getTodoCount();

        // 안 읽은 알림이 있는지, 기간 내의(오늘이 시작일 이후) 완료되지 않은 할 일이 있는지 여부 조회 (1분 마다 조회)
        this.is_remain_noti_and_todo = window.setInterval(() => {
            this.getNotificationCount();
            this.getTodoCount();
        }, 60000);

        // App.vue에서 메뉴가 닫힐때 개수 가져오는 함수 호출
        window['getNotificationCount'] = this.getNotificationCount;
        window['getTodoCount'] = this.getTodoCount;
    }

    /**
     * 종료 전 interval 제거
     */
    beforeDestroy() : void {
        if( this.is_remain_noti_and_todo ) { clearInterval(this.is_remain_noti_and_todo); }
    }

    /**
     * 안 읽은 알림 개수 조회
     */
    getNotificationCount() : void {
        
        this.hodu_api_call(`api/v1/notification/count?current_date=${moment(new Date()).utc().format()}&is_web=true`, API_METHOD.GET, null, false)
            .then((response) => {
                // console.log(response);
                this.notificatin_count = response.data.data.notificatin_count;

                if( this.notificatin_count < 0 ) {
                    this.notificatin_count = 0;
                }
            })
            .catch((e) => {
                this.notificatin_count = 0;
            });
    }

    /**
     * 완료하지 않은 개인 할 일 개수 조회
     */
    getTodoCount() : void {
        this.hodu_api_call('api/v1/todo/count', API_METHOD.GET, null, false)
            .then((response) => {
                // console.log(response);
                this.todo_count = response.data.data.todo_count;

                if( this.todo_count < 0 ) {
                    this.todo_count = 0;
                }
            })
            .catch((e) => {
                this.todo_count = 0;
            });
    }

    /**
     * 프로필 이미지 에러
     */
    profileImageError() : void {
        this.profile_image_error = true;
    }
    
    /**
     * 프로필 클릭
     */
    userProfileClick() : void {
        this.doSetUserProfileOpen(!this.user_profile_open);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(false);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(false);
    }

    /**
     * 알림 리스트 메뉴 클릭
     */
    notificationListMenuClick() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(!this.notification_list_menu_open);
        this.doSetPreferencesMenuOpen(false);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(false);

        // 알림 메뉴가 닫힐때 안 읽은 알림 개수 재조회
        if( this.notification_list_menu_open == false ) { this.getNotificationCount(); }
    }

    /**
     * 환경설정 메뉴 클릭
     */
    preferencesMenuClick() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(!this.preferences_menu_open);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(false);
    }

    /**
     * 할 일 메뉴 클릭
     */
    todoMenuOpenClick() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(false);

        // 할 일 리스트, 할 일 작성&수정 둘 중 하나라도 열려 있을때 닫는다
        if( this.todo_menu_open == true || this.todo_create_or_update == true ) {
            this.doSetTodoMenuOpen(false);
            this.doTodoCreateOrUpdate(false);

            // 할 일 메뉴가 닫힐때 남은 할 일 개수를 재조회
            this.getTodoCount();
            return;
        }
        
        // 닫혀 있을때는 todoList를 연다
        this.doSetTodoMenuOpen(true);
    }

    /**
     * 기타메뉴 클릭
     */
    etcMenuClick() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(false);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(!this.etc_menu_open);
        this.doSetFilterMenuOpen?.(false);
    }

    /**
     * 환경설정이 보일지의 여부
     */
    getPreferencesVisibility() : boolean {

        // 개인 달력
        if( this.scope == OWNER_TYPE.PERSONAL ) {
            return true; // TODO 나중에 개인 환경 설정 나오면 true로 변경 후 작업
        }

        // 호두 D, 호두 H는 일반 파일함 사용 안함
        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD || this.scope_group_team_option.biz_type == GROUP_TYPE.BIZH ) { return false; }

        // 프리미엄 그룹 달력 && 그룹 관리자 일때만 보임
        if( this.scope != OWNER_TYPE.PERSONAL && this.scope_group_team_option.biz_id != null && this.scope_group_team_option.biz_id.length > 0 &&
            (this.get_group_role(this.scope_group_id) == 'GROUP_MANAGER' || this.get_group_role(this.scope_group_id) == 'ADMIN') ) {
            return true; 
        }
        
        return false;
    }

    /**
     * 프린트
     */
    printCalendar() : void {
        window.print();
    }

    /**
     * 필터메뉴 열기 / 닫기
     */
    filterMenuOnOff() : void {
        this.doSetUserProfileOpen(false);
        this.doSetNotificationListMenuOpen(false);
        this.doSetPreferencesMenuOpen(false);
        this.doSetTodoMenuOpen(false);
        this.doTodoCreateOrUpdate(false);
        this.doSetEtcMenuOpen(false);
        this.doSetFilterMenuOpen?.(!this.filter_menu_open);
    }

    /**
     * 이미지 에러가 난 상태에서 프로필 변경시 반영을 하기 위함
     */
    @Watch('image_time')
    watchImageTimeTag() : void {
        this.profile_image_error = false;
    }
}

