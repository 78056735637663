
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
}) export default class ApartmentSetting extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeBuildingCreateModalInfo ?: (params : hodu_home_modal_info.HomeBuildingCreateModalInfo) => void;
    @ModalInfo.Action doSetHomeContactCreateModalInfo  ?: (params : hodu_home_modal_info.HomeContactCreateModalInfo) => void;

    apartment_building_list : any[] = [];
    apartment_contact_list : any[] = [];

    auto_apply_appointment : boolean = false;
    resident_car_approve_enable : boolean = true;

    proposal_enable : boolean = true;
    default_max_parking_number : number = 2;

    tab : string = "TEL"; // TEL, MAX_PARKING
    is_right_menu_open = true;

    tts_voice_number : string = "1";

    mounted() : void {
        this.getHoduHomeInfo();

        // window['createBuilding'] = this.createBuilding;
        window['createHomeContact'] = this.createHomeContact;
        window['updateHomeContact'] = this.updateHomeContact;
        window['deleteHomeContact'] = this.deleteHomeContact;
        this.setScroll();

        //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.left_con > ul').hide();
        // 	$('.left_con > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });

        //메뉴 on/off
        // $('.config_btn').off("click").on("click", function(){
        // 	if($(".config_btn, #apartment-config-scroll, .leftScroll").hasClass("on")){
        //         $(".config_btn, #apartment-config-scroll, .leftScroll").removeClass("on");
        //     } else {
        //         $(".config_btn, #apartment-config-scroll, .leftScroll").addClass("on");
        //     }
        // });
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('.title_box').outerHeight();
        const titleDivHeight : number | undefined = $('.titleDiv').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();
        
        // const apartmentContactHeight : number | undefined = $('.apartment-contact').outerHeight();
        // const apartmentContactTitleDivHeight : number | undefined = $('.apartment-contact .titleDiv').outerHeight();

        const apartmentConfigHeight : number | undefined = $('.apartment-config').outerHeight();
        const apartmentConfigTitleDivHeight : number | undefined = $('.apartment-config .titleDiv').outerHeight();

        // @ts-ignore
        $('#aptSet_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (titleDivHeight ? titleDivHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });

        // @ts-ignore
        $('#aptSet_building_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (titleDivHeight ? titleDivHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });

        // @ts-ignore
        // $('.telDiv.scroll').mCustomScrollbar({
        //     axis : 'y',
        //     scrollbarPosition : 'outside',
        //     mouseWheelPixels : 100,
        //     scrollInertia : 60,
        //     autoDraggerLength : false,
        //     setHeight : (apartmentContactHeight ? apartmentContactHeight : 0) - (apartmentContactTitleDivHeight ? apartmentContactTitleDivHeight : 0) 
        // });

        // @ts-ignore
        $('#apartment-config-scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (titleDivHeight ? titleDivHeight : 0)
        });

    }

    /**
     * 호두홈 정보 조회
     */
    async getHoduHomeInfo() : Promise<void> {
        
        await this.hodu_api_call(`api/v1/home/info/${this.scope_group_team_option.biz_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                const home_info : any = response.data.data.home_info; 

                this.apartment_building_list.splice(0, this.apartment_building_list.length);

                if( home_info.building_info != null ) {
                    this.apartment_building_list = this.apartment_building_list.concat(home_info.building_info);
                }

                this.apartment_contact_list.splice(0, this.apartment_contact_list.length);
                
                if( home_info.company_info.home_contacts != null ) {
                    this.apartment_contact_list = this.apartment_contact_list.concat(home_info.company_info.home_contacts);
                }

                this.auto_apply_appointment      = home_info.company_info.auto_apply_appointment      != null ? home_info.company_info.auto_apply_appointment      : false;
                this.resident_car_approve_enable = home_info.company_info.resident_car_approve_enable != null ? home_info.company_info.resident_car_approve_enable : true;
                this.proposal_enable             = home_info.company_info.proposal_enable             != null ? home_info.company_info.proposal_enable             : true;
                this.default_max_parking_number  = home_info.company_info.max_parking_number          != null ? home_info.company_info.max_parking_number          : 2;   
                this.tts_voice_number            = home_info.company_info.tts_voice_number            != null ? home_info.company_info.tts_voice_number            : '1';  
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 호두홈 그룹 정보 수정
     */
    updateHoduHomeInfo(data : any) : void {

        this.hodu_api_call(`api/v1/home/info/${this.scope_group_team_option.biz_id}`, API_METHOD.PUT, data)
            .then((response) => {
                console.log(response);
                this.getHoduHomeInfo();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });

    }

    /**
     * 동 추가 모달
     */
    showHomeBuildingCreateModal() : void {
        if( this.doSetHomeBuildingCreateModalInfo == null ) { return; }

        this.doSetHomeBuildingCreateModalInfo({
            show_modal : true
        });
    }

    /**
     * 동 추가
     */
    // async createBuilding(building : any) : Promise<void> {
    //     await this.getHoduHomeInfo();

    //     if( this.apartment_building_list.filter(item => item.key != building.key && item.name == building.name).length > 0 ) {
    //         this.hodu_show_dialog('alert', '이름이 중복되는 동이 있습니다', ['확인']);
    //         return;
    //     }

    //     this.apartment_building_list.push(building);
    //     this.updateHoduHomeInfo({ "max_parking_number" : this.apartment_building_list });
    // }

    /**
     * 동 최대 차량 수 수정
     */
    async updateBuilding(building : any, count : number) : Promise<void> {
        await this.getHoduHomeInfo();

        const target_list = this.apartment_building_list.filter(item => item.team_id == building.team_id);
        if( target_list.length < 1 ) {
            this.hodu_show_dialog('cancel', '수정 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        building = JSON.parse(JSON.stringify(target_list[0]));
        building.team_info.max_parking_number = count;

        let param_data = { team: building }

        await this.hodu_api_call(`api/v1/teams/${building.team_id}/${building.group_id}`, API_METHOD.PUT, param_data)
            .then(async(response) => {
                this.getHoduHomeInfo();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 동 삭제
     */
    async deleteBuilding(building : any) : Promise<void> {
        await this.getHoduHomeInfo();

        const target_list = this.apartment_building_list.filter(item => item.key == building.key);
        if( target_list.length < 1 ) {
            this.hodu_show_dialog('cancel', '삭제 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        const target_index = this.apartment_building_list.indexOf(target_list[0]);
        if( target_index < 0 ) {
            this.hodu_show_dialog('cancel', '삭제 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        this.apartment_building_list.splice(target_index, 1);
        this.updateHoduHomeInfo({ "max_parking_number" : this.apartment_building_list });
    }

    /**
     * 연락처 추가 모달
     */
    showHomeContactCreateModal(contact : any) : void {
        if( this.doSetHomeContactCreateModalInfo == null ) { return; }

        this.doSetHomeContactCreateModalInfo({
            show_modal : true,
            contact : contact ? contact : null
        });
    }

    /**
     * 연락처 추가
     */
    async createHomeContact(contact : any) : Promise<void> {
        await this.getHoduHomeInfo();

        if( this.apartment_contact_list.filter(item => item.key != contact.key && ( item.name == contact.name || item.tel.replace(/-/ig, '') == contact.tel.replace(/-/ig, '') ) ).length > 0 ) {
            this.hodu_show_dialog('alert', '이름이나 번호가 중복되는 연락처가 있습니다', ['확인']);
            return;
        }

        this.apartment_contact_list.push(contact);
        this.updateHoduHomeInfo({ "home_contacts" : this.apartment_contact_list });
    }   

    /**
     * 연락처 수정
     */
    async updateHomeContact(contact : any) : Promise<void> {
        await this.getHoduHomeInfo();

        if( this.apartment_contact_list.filter(item => item.key != contact.key && ( item.name == contact.name || item.tel.replace(/-/ig, '') == contact.tel.replace(/-/ig, '') ) ).length > 0 ) {
            this.hodu_show_dialog('alert', '이름이나 번호가 중복되는 연락처가 있습니다', ['확인']);
            return;
        }

        const target_list = this.apartment_contact_list.filter(item => item.key == contact.key)
        if( target_list.length < 1 ) {
            this.hodu_show_dialog('cancel', '연락처 수정 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        const target_index = this.apartment_contact_list.indexOf(target_list[0]);
        if( target_index < 0 ) {
            this.hodu_show_dialog('cancel', '연락처 수정 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        this.apartment_contact_list.splice(target_index, 1, contact);
        this.updateHoduHomeInfo({ "home_contacts" : this.apartment_contact_list });
    }

    /**
     * 연락처 삭제
     */
    async deleteHomeContact(key : string) : Promise<void> {
        await this.getHoduHomeInfo();

        const target_list = this.apartment_contact_list.filter(item => item.key == key)
        if( target_list.length < 1 ) {
            this.hodu_show_dialog('cancel', '연락처 삭제 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        const target_index = this.apartment_contact_list.indexOf(target_list[0]);
        if( target_index < 0 ) {
            this.hodu_show_dialog('cancel', '연락처 삭제 대상을 찾을 수 없습니다', ['확인']);
            return;
        }

        this.apartment_contact_list.splice(target_index, 1);
        this.updateHoduHomeInfo({ "home_contacts" : this.apartment_contact_list });
    }

    /**
     * 방문차량 자동 승인 여부 변경
     */
    changeAutoApplyVistant() : void {
        this.updateHoduHomeInfo({ "auto_apply_appointment" : this.auto_apply_appointment });
    }

    /**
     * 입주차량 승인 여부
     */
    changeResidentCarApply() : void {
        this.updateHoduHomeInfo({ "resident_car_approve_enable" : this.resident_car_approve_enable });
    }

    /**
     * 동 생성시 기본으로 들어갈 주차 가능 차량 수 변경
     */
    changeMaxParkingNumber(count : string) : void {
        this.updateHoduHomeInfo({ "max_parking_number" : Number(count) });
    }

    /**
     * 건의함 사용 여부 변경
     */
    changeProposalEnable() : void {
        this.updateHoduHomeInfo({ "proposal_enable" : this.proposal_enable });
    }

    /**
     * 안내방송 목소리 변경
     */
    changeTtsVoiceNumber() : void {
        this.updateHoduHomeInfo({ "tts_voice_number" : Number(this.tts_voice_number) });
    }   

    /**
     * 메세지 ON / OFF 여부
     */
    messageChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'message');
    }

    /**
     * 프리미엄 기능 ON / OFF
     */
    async premiumFeaturesOnOff(event : any, target : string) : Promise<void> {
        let featureOnOff : boolean = event.target.checked;

        // 그룹 기능 변경하는 API 호출 [(groups.ts : groupFeatureChange) - ( '/groups' : '/:group_id/features/:feature' )]
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/${target}?feature_flag=${featureOnOff}`, API_METHOD.PUT);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("프리미엄 기능 ON / OFF 중 오류 발생");
            }

            await this.get_group_role_service();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }     
            
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#aptSet_list_scroll").mCustomScrollbar('destroy');
        
        // @ts-ignore
        $("aptSet_building_list_scroll").mCustomScrollbar('destroy');
        
        // @ts-ignore
        // $('.telDiv.scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#apartment-config-scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
