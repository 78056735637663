
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');
const ModalInfo = namespace('ModalInfo');

import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';
import { t_event_hodu_doc, t_event } from '@/model/event';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class AppointmentListModal extends Mixins(VueHoduCommon) {

    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State appointment_list_modal_info !: hodu_doc_modal_info.AppointmentListModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentListModalInfo ?: (params : hodu_doc_modal_info.AppointmentListModalInfo) => void;

    mounted() : void {
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        // @ts-ignore
        $('#modal_appointment_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
        });
        
    }

    /**
     * 스테이터스에 따른 텍스트 반환
     */
    getStatusClassOrText(appointment : t_event_hodu_doc | undefined, is_get_class : boolean = false) : string {

        if( appointment == null ) return '';

        switch( appointment.appointment_status ) {
            case hodu_doc_enum.appointment_status_code.REQUEST:   return is_get_class ? "ing"       : "요청";
            case hodu_doc_enum.appointment_status_code.DECLINE:   return is_get_class ? "reject"    : "거절";
            case hodu_doc_enum.appointment_status_code.CONFIRM:   return is_get_class ? "confirmed" : "확정";
            case hodu_doc_enum.appointment_status_code.RECEIPT:   return is_get_class ? "made"      : "접수";
            case hodu_doc_enum.appointment_status_code.DIAGNOSIS: return is_get_class ? "seen"      : "진료";
            case hodu_doc_enum.appointment_status_code.NOSHOW:    return is_get_class ? "noshow"    : "노쇼";
            default: return "";
        }
        
    }

    /**
     * 리스트를 클릭한 경우
     */
    clickAppointmentList(event : t_event) : void {
        
        if( event.event_data.appointment == null ) { return; }

        const status : hodu_doc_enum.appointment_status_code = event.event_data.appointment.appointment_status;

        // 요청, 거절, 확정은 AppointmentList.vue
        if( status == hodu_doc_enum.appointment_status_code.REQUEST || status == hodu_doc_enum.appointment_status_code.DECLINE || status == hodu_doc_enum.appointment_status_code.CONFIRM ) {
            this.moveAppointmentList();
            return;
        }

        // 노쇼, 접수, 진료는 Appointment.vue
        if( this.doSetAppointmentDetailInfo ) {
            this.doSetAppointmentDetailInfo({ "is_patient" : false, "event" : event });
            this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event.event_id}`);
            this.closeModal();
        }
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetAppointmentListModalInfo ) { this.doSetAppointmentListModalInfo({ "show_modal" : false, "events" : [] }); }
    }
    
    // moveAppointmentDetail() : void {

    //     if( !this.doSetAppointmentDetailInfo ) { return; }

    //     this.doSetAppointmentDetailInfo({
    //         event : null, // 이벤트
    //         is_patient : false,
    //     });

    //     this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/event_id`);
    //     this.closeModal();
    // }

    /**
     * 예약 리스트로 이동
     */
    moveAppointmentList() : void {
        
        if( this.appointment_list_modal_info.events.length < 1 ) { return; }

        const lo_event : t_event = this.appointment_list_modal_info.events[0];

        this.hodu_router_push(`/hospital/${new Date(lo_event.event_data.schedule_date.start).getTime()}/appointment?criteria=${hodu_doc_enum.appointment_select_criteria.DAILY}`);
        this.closeModal();
    }

    /**
     * 리사이즈 감지 - 브라우저 최소 크기 1024 * 768 제한? 
     */
    handleResize() : void {

        // @ts-ignore
        $('#modal_appointment_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
