
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import ResidentMoveIntoRequestTimeSetting from '@/components/hodu_h/ResidentMoveIntoRequestTimeSetting.vue';
import ResidentMoveIntoRequestLineSetting from '@/components/hodu_h/ResidentMoveIntoRequestLineSetting.vue';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResidentMoveIntoRequestTimeSetting, ResidentMoveIntoRequestLineSetting, ResizeObserver
    },
})
export default class ResidentMoveIntoRequestSetting extends Mixins(VueHoduCommon) {

    category : string = 'TIME';

    mounted() : void {
        //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.settingCon > div').hide();
        // 	$('.settingCon > div').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });
        
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('.title_box').outerHeight();
        const titleDivHeight : number | undefined = $('.titleDiv').outerHeight();

        // @ts-ignore
        $('#request_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (titleDivHeight ? titleDivHeight : 0),
        });
    }

    /**
     * 리사이즈 감지
     */
    @Watch("category")
    handleResize() : void {
        this.$nextTick(() => {
            // @ts-ignore
            $("#request_setting_scroll").mCustomScrollbar('destroy');

            this.setScroll();
        });
    }

}
