
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import {hodu_doc_object, hodu_doc_modal_info} from "@/model/hodudoc";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    }
}) 
export default class DepartmentSettingModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDepartmentSettingModalInfo ?: (params : hodu_doc_modal_info.DepartmentSettingModalInfo) => void;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State department_setting_modal_info !: hodu_doc_modal_info.DepartmentSettingModalInfo

    department_name : string = "";

    mounted() : void {
        $('#department_name').focus();
        if ( this.department_setting_modal_info.department != null ) {
            
            this.department_name = new String(this.department_setting_modal_info.department.department_name) as string
            console.log(this.department_name);
        }
    }

    save(){
        if ( this.department_setting_modal_info.department == null ) { return; }
        if ( this.department_setting_modal_info.is_create == false && this.department_setting_modal_info.department.department_name == this.department_name ) {
            return;
        }

        if (this.department_name.length < 1) {
            alert('진료과명을 입력하세요')
            return
        }
        if (this.department_setting_modal_info.department.department_code.length < 1) {
            alert('진료과 코드를 입력하세요')
            return
        }

        const biz_id = this.scope_group_team_option.biz_id;
        let body = {
            department_code : this.department_setting_modal_info.department.department_code,
            department_name : this.department_name
        }

        if ( this.department_setting_modal_info.is_create ) {
            this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/department`, API_METHOD.POST, body)
            .then((response) => {
                
                let jsonData = JSON.stringify(response.data.data);
                // console.log(jsonData);
                
                window['getDepartments']("BOTTOM");
                this.close()
            })
            .catch((e) => {
                if( e.response ) {
                    alert(e.response.data);
                    return;
                }

                this.hodu_error_process(e, false, false, true);
            });
        
        }else{
            this.hodu_api_call(`api/v1/hodudoc/hospital/${biz_id}/department`, API_METHOD.PUT, body)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                console.log(jsonData);
                
                window['getDepartments']();
                this.close()
            })
            .catch((e) => {
                if( e.response ) {
                    alert(e.response.data);
                    return;
                }

                this.hodu_error_process(e, false, false, true);
            });
        }
    }

    close(){
        if( this.doSetDepartmentSettingModalInfo ) { 
            this.doSetDepartmentSettingModalInfo({
                show_modal : false,
                is_create  : null,
                department : null
            })
        }
    }

    inputDepartmentCode(event) {
        const value = event.target.value;
        
        if( this.department_setting_modal_info.department != null ) {
            this.department_setting_modal_info.department.department_code = value;
        }
    }

}
