
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

@Component({
    components: {
        
    },
})
export default class HomeResidentJoinModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_resident_join_modal_info !: hodu_home_modal_info.HomeResidentJoinModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeResidentJoinModalInfo ?: (params : hodu_home_modal_info.HomeResidentJoinModalInfo) => void;

    is_join : boolean = true;
    appr_state = "";
    name : string = "";
    building : string = "";
    unit : string = "";
    manager_tel : string = "";
    
    mounted() : void {
        this.getResidentInfo();
    }

    /**
     * 가입 신청 내역 조회
     */
    async getResidentInfo() : Promise<void> {
        try {

            const group_id = this.home_resident_join_modal_info.group_id;
            const user_id = this.user_id;

            const response = await this.hodu_api_call(`api/v1/home/${group_id}/resident/${user_id}`, API_METHOD.GET);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("호두홈 가입 정보 조회 실패");
            }

            this.is_join = (response.data.data.resident == null);

            if( this.is_join ) {
                await this.updateResidentNewInfo();
                return;
            }

            if( !this.is_join ) {
                const resident = response.data.data.resident;
                const resident_info = resident.resident_info;
                const basic_info = resident.basic_info;

                this.name = basic_info.nickname;
                this.building = resident_info.building;
                this.unit = resident_info.unit;

                this.appr_state = resident.appr_state;

                this.manager_tel = resident.manager_tel;

                if( this.appr_state == 'A' ) {
                    alert("가입 완료");
                    this.close();
                    return;
                }
            }

        }
        catch(e) {
            alert("호두홈 가입 정보 조회 실패");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    /**
     * 자동가입 절차
     */
    async updateResidentNewInfo() : Promise<void> {
        try {

            const group_id = this.home_resident_join_modal_info.group_id;
            const user_id = this.user_id;
            
            const response = await this.hodu_api_call(`api/v1/home/resident/new/${group_id}/${user_id}`, API_METHOD.PUT);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("호두홈 자동가입 시도 실패");
            }

            // 매칭되는 정보 없기 때문에 수동가입
            if( response.data.data.resident == null ) {
                return;
            }

            const group_response = await this.hodu_api_call(`api/v1/groups/groupMemberInsert/${group_id}`, API_METHOD.POST, {
                "is_appr_required" : false,
            });

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("호두홈 자동가입 시도 실패");
            }

            alert("가입 완료");
            this.close();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    close() : void {
        this.doSetHomeResidentJoinModalInfo?.({ "show_modal" : false, "group_id" : 0, "is_invite" : false });
        
        // 그룹 재조회
        window["getGroupList"]?.();
        this.get_group_role_service();
    }
    
    async join() : Promise<void> {

        const group_id = this.home_resident_join_modal_info.group_id;

        // 가입 신청
        if( this.is_join == true ) {

            try {

                if( this.building.length < 1 || this.unit.length < 1 || this.name.length < 1 ) {
                    alert("성명, 전화번호, 동/호 정보를 전부 입력 해주세요");
                    return;
                }

                if( isNaN(Number(this.building)) || isNaN(Number(this.unit)) ) {
                    alert("동/호 정보는 숫자로만 입력 해주세요");
                    return;
                }

                const response = await this.hodu_api_call(`api/v1/groups/groupMemberInsert/${group_id}`, API_METHOD.POST, {
                    "is_appr_required" : this.home_resident_join_modal_info.is_invite == false ? true : false,
                    "resident_info" : {
                        "building": Number(this.building),
                        "unit": this.unit,
                        "phone": this.user_phone_number,
                        "name": this.name
                    },
                });

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("호두홈 가입 중 오류 발생");
                }

                if( this.home_resident_join_modal_info.is_invite == true ) {
                    this.doGroupId(group_id);
                    this.doTeamId(0);
                    this.doGroupStatisticsStartDate("");
                    this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
                }

                this.close();
                return;

            } catch(e) {
                alert("호두홈 가입 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
                this.close();
                return;
            }
        }

        // 가입 신청 취소
        if( confirm("정말로 가입 취소 하시겠습니까?") == true ) {
            try {
                const response = await this.hodu_api_call(`api/v1/groups/groupMemberDelete/${group_id}/${this.user_id}`, API_METHOD.DELETE, null);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("호두홈 가입 취소 중 오류 발생");
                }
                
                this.close();
                return;

            } catch(e) {
                alert("호두홈 가입 취소 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
                this.close();
                return;
            }
        }
    }

}
