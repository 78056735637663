
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const PopupInfo = namespace('PopupInfo');

@Component({
    components: {
        
    },
})
export default class Popup extends Mixins(VueHoduCommon) {
    
    @Prop() id !: string;
    @Prop() src !: string;

    /**
     * @PopupInfo.State
     */
    @PopupInfo.State show_popup !: boolean;

    /**
     * @PopupInfo.Action
     */
    @PopupInfo.Action doSetShowPopup ?: (params : boolean) => void;

    mounted() {

    }

    isModalOpen() : boolean {
        return this.show_popup;
    }

    open() : void {
        this.$emit('open');
    }

    close() : void {
        if( this.doSetShowPopup ) this.doSetShowPopup(false);
    }

    /**
     * 오늘 하루 보지 않기
     */
    notViewToday() : void {
        const today = new Date();
        const today_key = `${today.getFullYear()}${`0${today.getMonth() + 1}`.slice(-2)}${`0${today.getDate()}`.slice(-2)}`;
        localStorage.setItem(`${this.id}_not_view_today`, today_key);
        this.close();
    }

}
