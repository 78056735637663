
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class Equipment extends Mixins(VueHoduCommon) {

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEquipmentManagementDetailModalInfo ?: (params : hodu_home_modal_info.EquipmentManagementDetailModalInfo) => void;

    uid : string = "";

    equipment : any = null; 

    is_off_spec : boolean = false;
    
    image_view_seq : number = 0;

    default_image = require("@/assets/images/contents/hoduhome_car_camera.svg");

    beforeMount() : void {
        this.uid = this.$route.params.uid;

        if( this.uid == null || this.uid.trim().length < 1 ) {
            this.hodu_show_dialog('cancel', '장비 조회에 필요한 데이터가 부족합니다', ['확인'], [() => { this.movePrevPage(); }])
        }
    }

    mounted() : void {
        //제원 on/off
        // $('.eqinfor03_btn').off("click").on("click", function(){
        // 	if($(".eqinfor03_btn, .eqinfor03_box").hasClass("off")){
        //         $(".eqinfor03_btn, .eqinfor03_box").removeClass("off");
        //     } else {
        //         $(".eqinfor03_btn, .eqinfor03_box").addClass("off");
        //     }
        // });

        this.getHomeEquipment();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0);

        // @ts-ignore
        $('#equipment_detail').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * 장비 조회
     */
    async getHomeEquipment() : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${this.uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.equipment ) {
                throw new Error("장비 조회 중 오류 발생");
            }

            this.equipment = JSON.parse(JSON.stringify(response.data.data.equipment));
            this.$nextTick(() => this.setScroll());

        } catch(e) {
            this.hodu_show_dialog('cancel', "장비 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 장비 삭제
     */
    async deleteHomeEquipment() : Promise<void> {

        this.hodu_show_dialog('cancel', "장비를 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {

                try {

                    const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${this.uid}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("장비 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_show_dialog('cancel', "장비 삭제 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
                
            }
        ]);

    }

    /**
     * 이미지 슬라이드
     */
    @Throttle(100)
    imageSeqMove(num : number) {
        if( this.image_view_seq + num < 0 || this.image_view_seq + num >= this.equipment.equipment_data.images.length ) {
            return;
        }
        this.image_view_seq = this.image_view_seq + num;
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 장비 수정
     */
    moveEquipmentModify() : void {
        this.hodu_router_push(`/home/equipment/modify/${this.uid}`);
    }

    /**
     * 장비 관리 이력 생성
     */
    moveEquipmentManagementCreate() : void {
        this.hodu_router_push(`/home/equipment/${this.uid}/management/create`);
    }

    /**
     * 장비 관리 이력 모달
     */
    showEquipmentManagementDetail(seq) : void {
        this.doSetEquipmentManagementDetailModalInfo?.({ 
            show_modal : true, 
            uid : this.uid, 
            seq : seq,
            callback : this.getHomeEquipment 
        });
    }

    /**
     * QR라벨
     */
    qrcode() : void {

        if( this.equipment == null ) return;
        
        let query = ``;
        query += `group_id=${this.scope_group_id}`;
        query += `&uid=${this.uid}`;
        query += `&name=${encodeURIComponent(this.equipment.equipment_data.name)}`;

        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;

        const w = 491;
        const h = 420;
        const l = (width - w) / 2 / systemZoom + dualScreenLeft;
        const t = (height - h) / 2 / systemZoom + dualScreenTop;

        window.open(`/equipment_qr_label.html?${query}`, "equipment_qr_code", `toolbar=0, scrollbars=0, resizable=0, width=${w}, height=${h}, left=${l}, top=${t}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#equipment_detail').mCustomScrollbar("destroy");

        this.setScroll();
    }

}
