
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

const firebase = require('firebase');
import 'firebaseui/dist/firebaseui.css';

@Component({
    components: {
        
    },
})
export default class Auth extends Mixins(VueHoduCommon) {

    async mounted() : Promise<void> {
        
        // const vue = this;
        
        // let firebaseui;

        // let type = navigator.appName;
        // let country_code = 'ko-KR';
        // if (type == 'Netscape') {
        //     country_code = navigator.language;
        // } else {
        //     country_code = navigator['userLanguage'];
        // }

        // // 한국에서는 한글 쓰고 그 외에는 전부 영어
        // console.log(country_code);
        // if( country_code == "ko-KR" ) {
        //     firebaseui = await import('firebaseui/dist/npm__ko');
        // }
        // else {
        //     firebaseui = await import('firebaseui');
        // }

        // console.log(firebase);
        // console.log(firebaseui);
        
        // try {
        //     const ui = new firebaseui.auth.AuthUI(firebase.auth());

        //     console.log(ui);

        //     ui.start('#firebaseui-auth-container', {
        //         signInSuccessUrl : '/#/login',
        //         signInOptions: [
        //             {
        //                 provider : firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        //                 recaptchaParameters: {
        //                     type: 'image', // 'audio'
        //                     size: 'normal', // 'invisible' or 'compact'
        //                     badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
        //                 },
        //                 defaultCountry: 'KR'
        //             }
        //         ],
        //         callbacks : {
        //             signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        //                 // User successfully signed in.
        //                 // Return type determines whether we continue the redirect automatically
        //                 // or whether we leave that to developer to handle.

        //                 // console.log(authResult);
        //                 // console.log(redirectUrl);

        //                 try {
        //                     vue.hodu_router_push(`/login?auth_redirect=true&phone_number=${authResult.user.phoneNumber ?? ''}`);
        //                 } catch(e) {
        //                     vue.hodu_error_process(e, false, false, true);
        //                 }
        //             },
        //             uiShown: function() {
        //                 // The widget is rendered.
        //                 // Hide the loader.
        //             }
        //         }
        //     });

        // } catch(e) {
        //     this.hodu_error_process(e, false, false, true);
        // }
    }

}
