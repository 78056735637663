
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함

/**
 * Component 선언 및 extends Mixins(Vue)
 */
@Component({
    components: {
        
    },
})
export default class FloatAdd extends Mixins(Vue) {

    mounted() : void {
        
    }

    add() : void {
        this.$emit('add');
    }

}
