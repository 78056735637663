
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const MenuInfo = namespace('MenuInfo');
const TodoInfo = namespace('TodoInfo');

import { t_todo } from '@/model/osm';

const dateFormat = require('dateformat');

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';

@Component({
    components : {
        ResizeObserver
    }
}) export default class RightTodoCreate extends Mixins(VueHoduCommon) {
    /**
     * @MenuInfo.State
     */
    @MenuInfo.State  todo_create_or_update !: boolean;

    /**
     * @MenuInfo.Action
     */
    @MenuInfo.Action doSetTodoMenuOpen     ?: any;
    @MenuInfo.Action doTodoCreateOrUpdate  ?: any;

    /**
     * @TodoInfo.State
     */
    @TodoInfo.State todo !: t_todo;

    /**
     * @TodoInfo.Action
     */
    @TodoInfo.Action doSetTodoSelectFlag ?: any;

    todo_list : t_todo[] = [];

    startDateString : string = "";
    endDateString   : string = "";

    existEnd : boolean = false;
    date_term : number = 0;

    is_create : boolean = true;

    mounted() {

        // $('.first').on( 'keyup', 'textarea', function (e){
        //     $(this).css('height', 'auto' );
            
        //     $(this).height( this.scrollHeight );
        // });
        // $('.first').find( 'textarea' ).keyup();

        
        this.existEnd = (this.todo.todo_info.end != null);                                                                           // 종료일 존재 여부 확인
        this.date_term = this.todo.todo_info.end == null ? 0 : this.getDateDiff(this.todo.todo_info.start, this.todo.todo_info.end); // 시작일-종료일 날짜 간격 확인

        const vue = this;

        // 생성인지 체크
        this.is_create = this.todo.todo_id == null || this.todo.todo_id.length < 1;
        if( this.is_create ) {
            // 시작일 시간 00:00:00.000로 
            const start_date : Date = new Date(this.todo.todo_info.start);
            start_date.setHours(0);
            start_date.setMinutes(0);
            start_date.setSeconds(0);
            start_date.setMilliseconds(0);
            this.todo.todo_info.start = start_date;

            // 종료일 존재시
            if( this.existEnd == true ) {
                // 종료일 시간 23:59:59.999로 
                const end_date : Date = new Date(this.todo.todo_info.start);
                end_date.setHours(23);
                end_date.setMinutes(59);
                end_date.setSeconds(59);
                end_date.setMilliseconds(999);
                this.todo.todo_info.end = end_date;
            }
        }


        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {

                // 다른 달일 경우 그냥 datePicker만 이동
                // if( new Date(dateText).getMonth() != inst.drawMonth ) {
                    
                    // TODO 현재 inline = false일때 고정시키는법을 찾지 못했음 setTimeout을 이용한 방법도 제대로 먹히지 않음

                    // @ts-ignore
                    // $('#datepicker').datepicker('setDate', new Date(dateText));
                    // return;
                // }

                const todo_length : number = vue.todo_list.length;
                const selected_date : Date = new Date(dateText);

                if($(this).attr('id')=='start'){
                    // 시작일 시간 00:00:00로 
                    selected_date.setHours(0);
                    selected_date.setMinutes(0);
                    selected_date.setSeconds(0);
                    selected_date.setMilliseconds(0);

                    for( let i = 0; i < todo_length; i++ ) {
                        vue.todo_list[i].todo_info.start = selected_date;

                        // end가 null이 아니라면 date_term에 따라서 END날짜 설정
                        if( vue.todo_list[i].todo_info.end != null ) {
                            const temp_date : Date = new Date(new Date(vue.todo_list[i].todo_info.start).getTime());
                            temp_date.setDate(new Date(vue.todo_list[i].todo_info.start).getDate() + vue.date_term);

                            // 종료일 시간 23:59:59.999로 
                            temp_date.setHours(23);
                            temp_date.setMinutes(59);
                            temp_date.setSeconds(59);
                            temp_date.setMilliseconds(999);
                            
                            vue.todo_list[i].todo_info.end = temp_date;
                        }
                    }

                } else {
                    for( let i = 0; i < todo_length; i++ ) {
                        // 종료일자가 시작일보다 빠른 경우 시작일을 종료일로 변경한다
                        if( new Date(dateText).getTime() < new Date(vue.todo_list[i].todo_info.start).getTime() ) {
                            // 시작일 시간 00:00:00.000로
                            const new_start : Date = new Date(dateText); 
                            new_start.setHours(0);
                            new_start.setMinutes(0);
                            new_start.setSeconds(0);
                            new_start.setMilliseconds(0);

                            vue.todo_list[i].todo_info.start = new_start;
                        }
                        
                        // 종료일 시간 23:59:59.999로 
                        selected_date.setHours(23);
                        selected_date.setMinutes(59);
                        selected_date.setSeconds(59);
                        selected_date.setMilliseconds(999);

                        vue.todo_list[i].todo_info.end = selected_date;
                        vue.date_term = vue.getDateDiff(vue.todo_list[i].todo_info.start, vue.todo_list[i].todo_info.end);    
                    }
                }
            },
        };

        // @ts-ignore
        $('#start').datepicker(option);

        // @ts-ignore
        $('#deadline').datepicker(option);


        // 리스트에 집어넣기
        this.todo_list.push(this.todo);
        this.startEndDateStringMake();
        setTimeout(() => {
            const todo_text = document.getElementById('todo_text_0');
            if( todo_text == null ) { return; } 
            
            $(todo_text).focus(); 
            $(todo_text).css('height', 'auto');
            $(todo_text).height(todo_text.scrollHeight);
        }, 10);

        setTimeout(() => { this.setScroll(); }, 100)
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height : number | undefined = $('.noti_titlebox.mToDo').outerHeight();
        const date_box_height : number | undefined = $('.notiWrap .second').outerHeight();

        // @ts-ignore
        $('#todoScroll').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ) - ( date_box_height == null ? 0 : date_box_height),
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 종료일 없음 버튼 ON / OFF (종료일 없앰, 종료일 다시 필요하면 사용)
     */
    // clickNoEnd() : void {
    //     this.existEnd = !this.existEnd;

    //     if( this.existEnd == false ){
    //         this.date_term = 0;
    //         this.todo.todo_info.end = null;
    //     } else {
    //         this.date_term = 0;
    //         this.todo.todo_info.end = new Date(this.todo.todo_info.start);
    //     }
    // }

    /**
     * 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일요일";

            case 1:
                return "월요일";

            case 2:
                return "화요일";

            case 3:
                return "수요일";

            case 4:
                return "목요일";

            case 5:
                return "금요일";

            case 6:
                return "토요일";

            default:
                return "?";
        }
    }

    /**
     * 시작, 종료일 텍스트 생성
     */
    startEndDateStringMake() : void {
        if( this.todo_list.length < 1 ) { return; }

        const startYear : string = `${new Date(this.todo_list[0].todo_info.start).getFullYear()}`; 
        const endYear   : string = `${this.todo_list[0].todo_info.end == null ? 0 : new Date(this.todo_list[0].todo_info.end).getFullYear()}`;
        
        const startMonth : string = `0${new Date(this.todo_list[0].todo_info.start).getMonth() + 1}`.slice(-2);
        const endMonth   : string = `0${this.todo_list[0].todo_info.end == null ? 0 : new Date(this.todo_list[0].todo_info.end).getMonth() + 1}`.slice(-2);

        const startDay : string = `0${new Date(this.todo_list[0].todo_info.start).getDate()}`.slice(-2);
        const endDay   : string = `0${this.todo_list[0].todo_info.end == null ? 0 : new Date(this.todo_list[0].todo_info.end).getDate()}`.slice(-2);

        this.startDateString = `${startYear}.${startMonth}.${startDay} ${this.getDayOfWeek(new Date(this.todo_list[0].todo_info.start))}`;
        this.endDateString   = this.todo_list[0].todo_info.end == null ? "" : `${endYear}.${endMonth}.${endDay} ${this.getDayOfWeek(new Date(this.todo_list[0].todo_info.end))}`;
    }

    /**
     * 개인 할 일 삭제
     */
    deleteTodo() : void {
        if( this.todo_list.length < 1 ) { this.todo_list.push(this.todo); }
        this.hodu_api_call(`api/v1/todo/${this.todo_list[0].todo_id}`, API_METHOD.DELETE)
            .then((response) => {   
                this.doTodoCreateOrUpdate(false);
                this.doSetTodoMenuOpen(true);
                this.doSetTodoSelectFlag(true);
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 개인 할 일 생성 또는 수정 취소
     */
    cancelTodoCreate() : void {
        this.doTodoCreateOrUpdate(false);
        this.doSetTodoMenuOpen(true);
        this.doSetTodoSelectFlag(true);
    }

    /**
     * 개인 할 일 생성 또는 수정
     */
    createOrUpdateTodo() : void {

        // 필수 데이터 확인
        if( this.todo_list.length < 1 ) {
            this.hodu_show_dialog('alert', '할 일 데이터가 없습니다.', ['확인'], [() => {
                this.todo_list.push(this.todo);
            }]);
            return;
        }

        // 제목 체크
        for( let i = 0; i < this.todo_list.length; i++ ) {
            this.todo_list[i].todo_info.summary = this.todo_list[i].todo_info.summary.trim();
            if( this.todo_list[i].todo_info.summary == null || this.todo_list[i].todo_info.summary.length < 2 ){
                this.hodu_show_dialog('alert', '두 글자 이상 입력 해주세요', ['확인'], [() => {
                    $(`#todo_text_${i}`).focus();
                }]);
                return;
            }
        }


        // 생성 
        if( this.todo.todo_id == null || this.todo.todo_id.length < 1 ){
            this.createTodos();

        // 수정
        } else {
            this.updateTodo();
        }
    }

    /**
     * 개인 할 일 생성 API 통신
     */
    createTodos() : void {
        this.hodu_api_call(`api/v1/todo/todos`, API_METHOD.POST, { "todo_list" : this.todo_list })
            .then((response) => {
                this.doTodoCreateOrUpdate(false);
                this.doSetTodoMenuOpen(true);
                this.doSetTodoSelectFlag(true);
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 개인 할 일 수정 API 통신
     */
    updateTodo() : void {
        if( this.todo_list.length < 1 ) { this.todo_list.push(this.todo); }
        this.hodu_api_call(`api/v1/todo/${this.todo_list[0].todo_id}`, API_METHOD.PUT, this.todo_list[0])
            .then((response) => {
                this.doTodoCreateOrUpdate(false);
                this.doSetTodoMenuOpen(true);
                this.doSetTodoSelectFlag(true);
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    @Watch('todo_list', { immediate : false, deep : true })
    changeTodo() : void {
        if( this.todo_list.length < 1 ) { return; }

        // this.existEnd = (this.todo.todo_info.end != null);                                                                                                // 종료일 존재 여부 확인
        this.date_term = this.todo_list[0].todo_info.end == null ? 0 : this.getDateDiff(this.todo_list[0].todo_info.start, this.todo_list[0].todo_info.end); // 시작일-종료일 날짜 간격 확인
        
        this.startEndDateStringMake();
    }

    /**
     * 할 일 늘리기
     */
    addTodo() : void {
        if( this.todo_list.length < 1 ) { this.todo_list.push(this.todo); }
        const new_todo : t_todo = JSON.parse(JSON.stringify(this.todo_list[0]));
        new_todo.todo_info.summary = '';
        this.todo_list.push(new_todo);

        setTimeout(() => { 
            $(`#todo_text_${this.todo_list.length - 1}`).focus(); 

            // @ts-ignore
            $('#todoScroll').mCustomScrollbar('scrollTo', 'bottom');
        }, 10);
    }

    /**
     * 할 일 삭제
     */
    removeTodo(index : number) : void {
        if( this.todo_list.length <= index ) { return; }
        this.todo_list.splice(index, 1);
    }

    /**
     * textarea 높이 자동조절
     */
    textareaHeightAuto(event) : void {
        $(event.target).css('height', 'auto');
        $(event.target).height(event.target.scrollHeight);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#todoScroll').mCustomScrollbar('destroy');
        this.setScroll();
    }

}

