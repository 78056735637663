
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class ApprovalTemplateExceptFileModal extends Mixins(VueHoduCommon) {

    mounted() : void {
        
    }

}
