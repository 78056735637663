
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupAddFileModalNewInfo } from '@/store/modules/ModalInfo';
import { hodu_color } from '@/common/color';

@Component({

}) export default class GroupAddFileBoxNew extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_add_file_box_new_info !: GroupAddFileModalNewInfo;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupAddFileModalNewInfo ?: (params : GroupAddFileModalNewInfo) => void;

    dir : any = null;
    original_dir : any = null;

    is_wrong_text : boolean = false;
    is_wrong_password : boolean = false;
    is_color_view_mode : boolean = true;

    password : string = "";

    async mounted() : Promise<void> {
        //비밀번호 설정 체크시 toggle
        // $('#check_psw').change(function(){ 
        //     $('#psw_text').toggle();         
        // })

        // 폴더 색상과 타입 선택
        // $(".alterFolderDiv .title").click(function(){
        //     var getIndex = $(this).index();
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");
            
        //     // 색상선택시 색상 ul 보이기
        //     if ( getIndex == 0 ) {
        //         $(".typeUl").hide();
        //         $(".clrUl").show();
        //     // 색상선택시 타입 ul 보이기
        //     } else {
        //         $(".clrUl").hide();
        //         $(".typeUl").show();
        //     }

        // });

        // 색상 선택
        // $(".selectUl.clrUl li a").click(function(){
        //     var forColour = $(this).parent().index();
            
        //     $(this).parent().siblings().find("a").removeClass("on");
        //     $(this).addClass("on");

        //     // 색 변경
        //     for( let i = 0; i < 10; i++ ) {
        //         if( $('.alterFolderDiv .folder').hasClass(`folder_dc${i}`) ) {
        //             $('.alterFolderDiv .folder').removeClass(`folder_dc${i}`)
        //             break;
        //         }
        //     }
        //     // 폴더 사라짐 방지
        //     $('.alterFolderDiv .folder').addClass(`folder_dc${forColour}`);

        // });

        // // 타입 선택
        // $(".selectUl.typeUl li a").click(function(){
        //     var fc = $(this).parent().index();
            
        //     $(this).parent().siblings().find("a").removeClass("on");
        //     $(this).addClass("on");
            
            
        //     // 색 변경
        //     for( let i = 0; i < 11; i++ ) {
        //         if( $('.alterFolderDiv .folder span').hasClass(`ic_fd_bk${i}`) ) {
        //             $('.alterFolderDiv .folder span').removeClass(`ic_fd_bk${i}`)
        //             break;
        //         }
        //     }
        //     $('.alterFolderDiv .folder span').addClass(`ic_fd_bk${fc}`);
        // });

        if( this.group_add_file_box_new_info.is_create == false ) {

            try {

                const response = await this.hodu_api_call(`api/v1/groups/${this.group_add_file_box_new_info.group_id}/file/${this.group_add_file_box_new_info.dir_uuid}`, API_METHOD.GET);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dir ) {
                    throw Error("파일함 조회 중 오류 발생");
                }

                this.dir = response.data.data.dir;
                this.original_dir = JSON.parse(JSON.stringify(this.dir));

            } catch(e) {
                console.log(e);
            }

        }
        else {
            this.dir = {
                "group_id" : this.group_add_file_box_new_info.group_id,
                "dir_name" : "",
                "dir_color" : hodu_color.hodu_dc_2,
                "dir_icon" : 0,
                "has_password" : false,
                "up_dir_uuid" : this.group_add_file_box_new_info.up_dir_uuid
            };
            this.original_dir = JSON.parse(JSON.stringify(this.dir));
        }
        
    }

    /**
     * 그룹 파일함 생성
     */
    async createGroupFileDir() : Promise<void> {

        if( this.dir.dir_name.trim().length < 1 || this.dir.dir_name.trim().length > 20 ) {
            this.is_wrong_text = true;
            $('#dir_text').focus();
            return;
        }
        
        if( this.group_add_file_box_new_info.is_create == true && this.dir.has_password == true && this.password.trim().length < 1 ) {
            this.is_wrong_password = true;
            $('#psw_text').focus();
            return;
        }

        await this.hodu_api_call(`api/v1/groupsweb/setGroupFileDir/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.POST, {
            name: this.dir.dir_name.trim(),
            color: this.dir.dir_color,
            icon: this.dir.dir_icon,
            password : this.dir.has_password ? this.password.trim() : undefined,
            up_dir_uuid : this.dir.up_dir_uuid ? this.dir.up_dir_uuid : undefined,
        }).then(async(response) => {
            
            // 디렉토리 재조회
            if( this.group_add_file_box_new_info.create_function ) { this.group_add_file_box_new_info.create_function(); }

        }).catch(async(e) => {
            this.hodu_error_process(e, true, false);
        }).finally(() => {
            this.closeModal();
        });

    }

    /**
     * 파일함 삭제
     */
    async deleteGroupFileDir() : Promise<void> {

        if( confirm("파일함을 삭제하시겠습니까?") == false ) { return; }

        await this.hodu_api_call(`api/v1/groupsweb/setGroupFileDir/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.DELETE, {
            dir_uuid : this.group_add_file_box_new_info.dir_uuid
        }).then(async(response) => {

            // 디렉토리 재조회
            if( this.group_add_file_box_new_info.update_function ) { this.group_add_file_box_new_info.update_function(true, this.group_add_file_box_new_info.dir_uuid); }

        }).catch(async(e) => {
            this.hodu_error_process(e, true, false);
        }).finally(() => {
            this.closeModal();
        });

    }

    /**
     * 파일함 업데이트
     */
    async updateGroupFileDir() : Promise<void> {

        if( this.dir.dir_name.trim().length < 1 || this.dir.dir_name.trim().length > 20 ) {
            this.is_wrong_text = true;
            $('#dir_text').focus();
            return;
        }

        // 기존에 비밀번호 없을때만 체크
        if( this.original_dir.has_password == false && this.dir.has_password == true && this.password.trim().length < 1 ) {
            this.is_wrong_password = true;
            $('#psw_text').focus();
            return;
        }

        await this.hodu_api_call(`api/v1/groupsweb/setGroupFileDir/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.PUT, {
            dir_uuid: this.group_add_file_box_new_info.dir_uuid,
            name: this.dir.dir_name.trim(),
            color: this.dir.dir_color,
            icon: this.dir.dir_icon,
            password : this.dir.has_password && this.password.trim().length > 0 ? this.password.trim() : undefined
        }).then(async(response) => {
            
            // 디렉토리 재조회
            if( this.group_add_file_box_new_info.update_function ) { this.group_add_file_box_new_info.update_function(); }

        }).catch(async(e) => {
            this.hodu_error_process(e, true, false);
        }).finally(() => {
            this.closeModal();
        });

    }

    /**
     * 컬러 뷰 모드 플래그 세팅
     */
    setColorViewMode(flag : boolean) : void {
        this.is_color_view_mode = flag;
    }

    /**
     * 선택된 색상인지 여부 반환
     */
    isSelectedColor(index : number)  : boolean {
        switch(index) {
            case 0: return this.dir.dir_color == hodu_color.hodu_dc_0;
            case 1: return this.dir.dir_color == hodu_color.hodu_dc_1;
            case 2: return this.dir.dir_color == hodu_color.hodu_dc_2;
            case 3: return this.dir.dir_color == hodu_color.hodu_dc_3;
            case 4: return this.dir.dir_color == hodu_color.hodu_dc_4;
            case 5: return this.dir.dir_color == hodu_color.hodu_dc_5;
            case 6: return this.dir.dir_color == hodu_color.hodu_dc_6;
            case 7: return this.dir.dir_color == hodu_color.hodu_dc_7;
            case 8: return this.dir.dir_color == hodu_color.hodu_dc_8;
            case 9: return this.dir.dir_color == hodu_color.hodu_dc_9;
        }

        return false;
    }

    /**
     * 색상 선택
     */
    selectColor(index : number) : void {
        this.dir.dir_color = hodu_color[`hodu_dc_${index}`];
    }

    /**
     * 선택된 아이콘 여부
     */
    isSelectedIcon(index : number)  : boolean {
        return index == this.dir.dir_icon;
    }

    /**
     * 아이콘 선택
     */
    selectIcon(index : number) : void {
        this.dir.dir_icon = index;
    }

    /**
     * 제목 입력
     */
    inputDirName(value : string) : void {
        this.dir.dir_name = value;
        this.is_wrong_text = false;
    }

    /**
     * 비밀번호 입력
     */
    inputPassword(value : string) : void {
        this.password = value;
        this.is_wrong_password = false;
    }

    /**
     * 모달 닫기 
     */
    closeModal() : void {
        if( this.doSetGroupAddFileModalNewInfo == null ) { return; } 

        this.doSetGroupAddFileModalNewInfo({ 
            "show_modal" : false, 
            "is_create" : true, 
            "dir_uuid" : "",
            "group_id" : 0,
        });
    }

}
