
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

import { organization_modal_interface } from '@/model/organization';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion
    },
})
export default class OrganizationModal extends Mixins(VueHoduCommon) {

    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_modal_info !: organization_modal_interface.OrganizationModalInfo

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationModalInfo ?: (params : organization_modal_interface.OrganizationModalInfo) => void; 

    departments : any[] = [];
    selected_department : any = null; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    async mounted() : Promise<void> {
        // // 버튼 클릭시 라인과 팀 보이기
        // $(".arw").click(function(){
        //     // 리스트 인덱스 가져오기
        //     var getIndex = $(this).parent().index();
            
        //     // 버튼 클릭시 라인과 팀 보이기
        //     $(".fList").eq(getIndex).toggleClass("showTeam");

        // });
        await this.getOrganization();
    }

    /**
     * 조직도 부서 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

            if( this.selected_department == null ) this.selected_department = this.departments.filter(item => item.dept_id_array.length == 1)[0];

        } catch(e) {
            alert( "조직도 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 타이틀 변경
     */
    @Debounce(500)
    async inputDepartmentTitle(event) : Promise<void> {
        if( event.target.value.trim().length < 1 ) return;
        this.selected_department.dept_name = event.target.value.trim();
        this.update();
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.$nextTick(() => this.updateHeight());
    }

    /**
     * 전체 높이 업데이트
     */
    updateHeight() : void {
        for( const department of this.departments ) {
            const level = department.dept_id_array.length; 

            if( level == 2 ) {
                const target_dept_id = department.dept_id;
                const parent_li_height = $(`#modal-organization-li-${department.dept_id}`).outerHeight();
                const last_child_li_height = $(`#modal-organization-li-${department.dept_id} > ul.sub2 > li:last-child`).outerHeight(); 
                const final_height = (parent_li_height ? parent_li_height : 0) - (last_child_li_height ? last_child_li_height : 0) + 15;
                department.vertical_height = final_height;

                // 맵에 세팅
                this.$nextTick(() => { this.department_height_map.set(target_dept_id, final_height); }); 
            }

            else if( level >= 3 ) {
                // 현재 컴포넌트 높이 업데이트
                const target_dept_id = department.dept_id;

                const parent_li_height = $(`#modal-organization-li-${department.dept_id}`).outerHeight();
                const last_child_li_height = $(`#modal-organization-li-${department.dept_id} > ul.level4 > li > ul.sub2 > li:last-child`).outerHeight();
                const final_height = (parent_li_height ? parent_li_height : 0) - (last_child_li_height ? last_child_li_height : 0) + 12;

                department.vertical_height = final_height;
                if( department.is_closed == true ) department.vertical_height = 0;

                // 맵에 세팅
                this.$nextTick(() => { this.department_height_map.set(target_dept_id, final_height); });
            }       

        }
    }
    
    /**
     * 부서 선택(라디오 버튼 클릭 후 변경 됐을때)
     */
    change(event, department) : void {
        if( event.target.checked == true ) {
            this.select(department);
        }
    }

    /**
     * 아이템 추가
     */
    async add() : Promise<void> {
        try {
            if( this.selected_department == null ) return;
            const new_department = JSON.parse(JSON.stringify(this.selected_department));
            
            await this.getOrganization();
            
            this.selected_department.is_closed = false;
            this.department_closed_map.set(this.selected_department.dept_id, false);

            new_department.dept_name = "새로운 부서";

            // 같은 레벨에 있는 부서 수를 구해서 dept_seq 세팅
            let same_level_departments = JSON.parse(JSON.stringify(this.departments));
            for( const id of this.selected_department.dept_id_array ) {
                same_level_departments = same_level_departments.filter(item => item.dept_id_array.indexOf(id) > -1);
            }
            same_level_departments = same_level_departments.filter(item => item.dept_id_array.length == new_department.dept_id_array.length);
            new_department.dept_seq = same_level_departments.length;

            // insert API
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.POST, { 
                "dept_name"     : new_department.dept_name,
                "dept_id_array" : new_department.dept_id_array,  
                "dept_seq"      : new_department.dept_seq
            });

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 정보 추가 중 오류 발생");
            }

            this.departments.push(response.data.data.dept_info);
            this.select(response.data.data.dept_info);

            this.organization_modal_info.callback?.();

        } catch(e) {
            alert("조직도 부서 정보 추가 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 부서명 업데이트
     */
    async update() : Promise<void> {
        try {
            if( this.selected_department == null ) return;
            
            // update api
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/${this.selected_department.dept_id}`, API_METHOD.PUT, { "dept_name" : this.selected_department.dept_name }, false);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 정보 수정 중 오류 발생");
            }

            this.getOrganization();

            this.organization_modal_info.callback?.();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 부서 삭제
     */
    async deleteDepartment() : Promise<void> {
        try {   
            if( this.selected_department == null ) return;
            if( confirm(`정말로 삭제하시겠습니까?\n'${this.selected_department.dept_name}'`) == false ) return;
            
            // delete api
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/${this.selected_department.dept_id}`, API_METHOD.DELETE);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 부서 정보 삭제 중 오류 발생");
            }

            this.selected_department = null;
            await this.getOrganization();
            this.updateHeight();

            this.organization_modal_info.callback?.();

        } catch(e) {
            alert("조직도 부서 정보 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 모달 닫기
     */
    close() : void {
        if( this.organization_modal_info.is_select_only == false ) this.organization_modal_info.callback?.();
        this.doSetOrganizationModalInfo?.({ show_modal : false });
    }

    /**
     * 선택된 부서 넘겨줌
     */
    save() : void {
        this.organization_modal_info.callback?.(this.selected_department);
        this.doSetOrganizationModalInfo?.({ show_modal : false });
    }

}
