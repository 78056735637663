
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import TitleBox from '@/components/ui/TitleBox.vue';

@Component({
    components: {
        TitleBox
    },
})
export default class BizApply extends Mixins(VueHoduCommon) {

    group_id : string = "";
    company_name : string = "";
    tel : string = "";
    email : string = "";
    head_count : string = "";

    mounted() : void {
        
    }

    /**
     * 초기화
     */
    reset() : void {
        this.group_id = "";
        this.company_name = "";
        this.tel = "";
        this.email = "";
        this.head_count = "";
    }

    /**
     * 승인
     */
    apply() : void {

        if( this.checkValid() == false ) return; 

        this.hodu_show_dialog('alert', "정말로 프리미엄 그룹으로 변경 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/bizGroupChange/${this.group_id}`, API_METHOD.PUT, {
                        "name" : this.company_name,
                        "tel" : this.tel,
                        "email" : this.email,
                        "head_count" : this.head_count
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("프리미엄 그룹 변경 중 오류 발생");
                    }

                    this.reset();
                    this.hodu_show_dialog('success', "프리미엄 그룹 변경 완료", ['확인']);

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog('cancel', "프리미엄 그룹 변경 중 오류 발생", ['확인']);
                }
            }
        ]);

    }

    /**
     * 유효성 검사
     */
    checkValid() : boolean {
        
        this.group_id = this.group_id.trim();
        this.company_name = this.company_name.trim(); 
        this.tel = this.tel.trim();
        this.email = this.email.trim(); 
        this.head_count = this.head_count.trim(); 

        // 그룹 ID 입력 안함
        if( this.group_id == null || this.group_id.length < 1 ) {
            alert("그룹 ID를 입력해주세요")
            $('#biz-input-group-id').addClass('wrong');
            $('#biz-input-group-id').focus();
            return false;
        }

        if( new RegExp(/[0-9]{1,}/).test(this.group_id) == false || new RegExp(/\.+/).test(this.group_id) == true || new RegExp(/-+/).test(this.group_id) == true ) {
            alert("그룹 ID에는 정수인 숫자만 입력해주세요")
            $('#biz-input-group-id').addClass('wrong');
            $('#biz-input-group-id').focus();
            return false;
        }

        // 회사명 입력 안함
        if( this.company_name == null || this.company_name.length < 1 ) {
            alert("회사명을 입력해주세요")
            $('#biz-input-company-name').addClass('wrong');
            $('#biz-input-company-name').focus();
            return false;
        }

        // 담당자 연락처 입력 안함
        if( this.tel == null || this.tel.length < 1 ) {
            alert("담당자 연락처를 입력해주세요")
            $('#biz-input-tel').addClass('wrong');
            $('#biz-input-tel').focus();
            return false;
        }

        // 담당자 이메일 입력 안함
        if( this.email == null || this.email.length < 1 ) {
            alert("담당자 이메일을 입력해주세요")
            $('#biz-input-email').addClass('wrong');
            $('#biz-input-email').focus();
            return false;
        }

        // 사원수를 입력해주세요
        if( this.head_count == null || this.head_count.length < 1 ) {
            alert("사원수를 입력해주세요")
            $('#biz-input-head-count').addClass('wrong');
            $('#biz-input-head-count').focus();
            return false;
        }

        return true;
    }

    @Watch('group_id')
    changeGroupId() : void {
        $('#biz-input-group-id').removeClass('wrong');
    }

    @Watch('company_name')
    changeCompanyName() : void {
        $('#biz-input-company-name').removeClass('wrong');
    }

    @Watch('tel')
    changeTel() : void {
        $('#biz-input-tel').removeClass('wrong');
    }

    @Watch('email')
    changeEmail() : void {
        $('#biz-input-email').removeClass('wrong');
    }

    @Watch('head_count')
    changeHeadCount() : void {
        $('#biz-input-head-count').removeClass('wrong');
    }

}
