
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';

import { approval_enum, approval_interface } from '@/model/approval';
import { organization_modal_interface } from '@/model/organization';

import moment from 'moment';

// TODO 사용하면 vacation_selected 대응 해야함
@Component({
    components: {
        HoduInlineDatePicker
    },
})
export default class OrganizationVacationAddModal extends Mixins(VueHoduCommon) {

    /**
     * 승인자 대상 직원
     */
    get computedEmployee() : any[] {
        
        const target = this.employees.filter(item => item.user_id != this.user_id && item.dept_id != null && item.pos_id != null);

        target.sort((o1, o2) : number => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            if( o1_pos_seq > o2_pos_seq ) return 1;
            else if( o1_pos_seq < o2_pos_seq ) return -1;
            else if( o1_name > o2_name ) return 1;
            else if( o1_name < o2_name ) return -1;

            return 0;
        });

        return target;
    }

    /**
     * 선택된 휴가 타입
     */
    get computedSelectedVacationType() : any {

        if( this.approval == null || this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ||
            this.vacation_types == null || this.vacation_types.length < 1 ) {
            return null;
        }

        return this.vacation_types.filter(item => item.vacation_type_id == this.selected_vacation_type_id)[0];
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_vacation_add_modal_info !: organization_modal_interface.OrganizationVacationAddModlInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationAddModlInfo ?: (params : organization_modal_interface.OrganizationVacationAddModlInfo) => void;

    event_bus : Vue = new Vue();
    
    approval : approval_interface.t_approval | null = null;

    departments : any[] = [];
    position : any[] = [];
    employees : any[] = [];
    vacation_types : any[] = [];

    my_employee_info : any = null;
    my_position_info : any = null;
    my_department_info : any = null;

    selected_approver_id : string = "";
    selected_vacation_type_id : string = "";

    start : Date = new Date();
    end : Date = new Date();
    is_start_select : boolean = true;

    start_text : string = "";
    end_text : string = "";

    mounted() : void {

        const today = new Date();

        this.approval = {
            "approval_uid"  : "",
            "approval_rev"  : 1,
            "group_id"      : this.scope_group_id,
            "approval_type" : approval_enum.APPROVAL_TYPE.VACATION,
            "approval_subtype_id" : 0,
            "user_id" : this.user_id,
            "dept_id" : 0,
            "pos_id" : 0,
            "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
            "receive_reference" : [],
            "contents" : {
                "title" : "",
                "comment" : "",
                "color" : "#477FFF",
                "files" : [],
            },
            "approval_state" : approval_enum.APPROVAL_STATE.ING,
            "approval_cur" : 0,
            "approval_total" : 0,
            "approval_document_num" : "",
            "approver" : [],
            "audit_created" : today
        }

        this.getTotalOrganizationData();
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        
        try {

            if( this.approval == null  ) return;

            await this.hodu_show_indicator();
            await Promise.all([this.getOrganizationPosition(), this.getOrganization(), this.getOrganizationEmp(), this.getOrganizationVacationTypeInfo()]);
            await this.hodu_hide_indicator();

            // 본인 정보 찾기 (my_employee_info, my_position_info, my_department_info)
            if( this.my_employee_info == null ) {
                alert("직원 정보가 존재하지 않습니다\n관리자에게 문의하세요");
                this.close();
                return;
            }

            for( const department of this.departments ) {
                if( this.my_employee_info.dept_id == department.dept_id ) {
                    this.my_department_info = department;
                    this.approval.dept_id = department.dept_id;
                    break;
                }
            }

            if( this.my_department_info == null ) {
                alert("부서가 설정되어 있지 않습니다\n관리자에게 문의하세요");
                this.close();
                return;
            }

            for( const pos of this.position ) {
                if( this.my_employee_info.pos_id == pos.pos_id ) {
                    this.my_position_info = pos;
                    this.approval.pos_id = pos.pos_id;
                    break;
                }
            }

            if( this.my_position_info == null ) {
                alert("직급이 설정되어 있지 않습니다\n관리자에게 문의하세요");
                this.close();
                return;
            }

            // 제목을 '휴가원'으로 지정하고 변경 불가능
            this.approval.contents.title = '휴가원';
            
            if( this.vacation_types == null || this.vacation_types.length < 1 ) {
                alert("휴가 종류가 설정 되어 있지 않습니다\n관리자에게 문의하세요");
                this.close();
                return;
            }

            if( this.computedEmployee.length < 1 ) {
                alert("휴가원을 승인 해 줄 대상이 존재하지 않습니다");
                this.close();
                return;
            }
            
            this.selected_approver_id = this.computedEmployee[0].user_id;
            this.selected_vacation_type_id = this.vacation_types[0].vacation_type_id;
            
            this.start = moment().toDate();
            this.end = moment(this.start).toDate();

            if( Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) > 1 ) {
                this.end = moment(this.start).set('date', this.start.getDate() +  Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) - 1).toDate();
            }

            this.makeDateText();

        } catch(e) {
            alert("조직도 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

            for( const employee of this.employees ) {
                if( employee.user_id == this.user_id ) {
                    this.my_employee_info = employee;
                    break;
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 휴가타입 조회
     */
    async getOrganizationVacationTypeInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.vacation_type_info ) {
                throw new Error("휴가타입 조회 중 오류 발생")
            }

            this.vacation_types.splice(0, this.vacation_types.length);
            this.vacation_types = this.vacation_types.concat(response.data.data.vacation_type_info);
            this.vacation_types.sort((o1, o2) : number => {
                if( o1.vacation_type_id > o2.vacation_type_id ) return 1;
                else return -1;
            });

        } catch(e) {
            throw e;
        }
    }

    /**
     * 날짜 텍스트 생성
     */
    makeDateText() : void {
        this.start_text = `${this.hodu_date_to_format_string(this.start, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.start, '요일')}`;
        this.end_text = `${this.hodu_date_to_format_string(this.end, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.end, '요일')}`;
    }
    
    /**
     * 시작일, 종료일 달력 세팅 변경
     */
    set(is_start_select : boolean) : void {
        this.is_start_select = is_start_select;
        this.changeDate(this.is_start_select == true ? this.start : this.end);
    }

    /**
     * 달력 선택 됨
     */
    select(date_string : string , data : any) : void {
        console.log(date_string);

        const daycount = Number(this.computedSelectedVacationType.vacation_type_daycount);

        if( this.is_start_select == true ) {
            this.start = new Date(date_string);
            this.end = new Date(date_string);
            
            // 휴가 타입의 휴가 일수 계산 후 end 세팅
            if( daycount > 1 ) {
                this.end = moment(this.end).set('date', moment(this.end).get('date') + daycount - 1).toDate();
            }
        }
        else {
            this.end = new Date(date_string);
            this.start = new Date(date_string);

            // 휴가 타입의 휴가 일수 계산 후 start 세팅
            if( daycount > 1 ) {
                this.start = moment(this.end).set('date', moment(this.end).get('date') - daycount + 1).toDate();
            }
        }

        this.makeDateText();
    }

    /**
     * 달력 년, 월 변경 됨
     */
    change(year: number, month: number, inst: any) : void {
        // DO NOTHING
    }

    /**
     * datepicker 선택 된 날짜 변경
     */
    changeDate(target_date : Date) : void {
        this.event_bus.$emit('changeDate', target_date);
    }

    /**
     * 휴가타입 변경
     */
    @Watch('selected_vacation_type_id')
    changeSelectedVacationTypeId() : void {
        this.start = moment().toDate();
        this.end = moment(this.start).toDate();

        if( Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) > 1 ) {
            this.end = moment(this.start).set('date', this.start.getDate() +  Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) - 1).toDate();
        }

        this.start_text = `${this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.start)}`;
        this.end_text = `${this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.end)}`;
        
        this.changeDate( this.is_start_select == true ? this.start : this.end );

        this.$nextTick(() => this.makeDateText());
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 부서 이름 반환
     * level 1 : 최상위 부서 이름 반환
     * level 2 : 최상위 부서 바로 아래의 부서 이름 하나만 반환
     * level n (n > 2) : level 2 / level 3 / level 4 ... 
     */
    getDepartmentName(dept_id : number) : string {
        try {
            let dept_name = "";
            const target = this.departments.filter(item => item.dept_id == dept_id)[0];

            let target_departments : any[] = [];
            for( const department of this.departments ) {
                if( target.dept_id_array.indexOf(department.dept_id) > -1 ) target_departments.push(department);
            }

            target_departments.sort((o1, o2) : number => {
                const o1_length = o1.dept_id_array.length;
                const o2_length = o2.dept_id_array.length;

                if( o1_length > o2_length ) return 1;
                else if( o1_length < o2_length ) return -1;
                return 0;
            });

            for( const department of target_departments ) {
                if( target_departments.length >= 2 && target_departments.indexOf(department) == 0 ) continue;

                if( dept_name.length > 0 ) dept_name += " / ";
                dept_name += department.dept_name;
            }
        
            return dept_name;
        } catch(e) {
            console.log(e);
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationVacationAddModlInfo?.({ show_modal : false });
    }

    /**
     * 저장
     */
    async save() : Promise<void> {
        if( this.approval == null ) return;

        if( this.approval.contents.comment.trim().length < 1 ) {
            alert("휴가 사유를 입력 해주세요");
            return;
        }

        try {
            this.approval.approver.push({
                seq : 0,
                rev : 1,
                state : approval_enum.APPROVAL_APPROVER_STATE.REQUEST,
                comment : "",
                user_id : this.user_id,
                user_name : this.user_name,
                dept_id : this.my_department_info.dept_id,
                dept_name : this.getDepartmentName(this.my_department_info.dept_id),
                pos_id : this.my_position_info.pos_id,
                pos_name : this.getPositionName(this.my_position_info.pos_id),
                date : new Date(),
                user_pic : this.my_employee_info.user_pic
            });

            const employees = this.computedEmployee.filter(employee => employee.user_id == this.selected_approver_id);
            
            if( employees.length < 1) {
                alert("휴가 등록 중 오류 발생");
                return;
            }

            const employee = employees[0];
            
            this.approval.approver.push({
                seq : 1,
                rev : 1,
                state : approval_enum.APPROVAL_APPROVER_STATE.ING,
                comment : "",
                user_id : employee.user_id,
                user_name : employee.user_name,
                dept_id : employee.dept_id,
                dept_name : this.getDepartmentName(employee.dept_id),
                pos_id : employee.pos_id,
                pos_name : this.getPositionName(employee.pos_id),
                user_pic : this.my_employee_info.user_pic
            });

            this.approval.contents.title = this.approval.contents.title.trim();
            this.approval.contents.comment = this.approval.contents.comment.trim();
            this.approval['comment'] = {};
            this.approval.approval_cur = 0;
            this.approval.approval_total = this.approval.approver.length - 1;
            // this.approval.requested = new Date();
            // this.approval.expired = new Date();

            this.approval.contents.vacation_type_id = this.computedSelectedVacationType.vacation_type_id;
            this.approval.contents.vacation_type_name = this.computedSelectedVacationType.vacation_type_name;
            this.approval.contents.vacation_type_daycount = this.computedSelectedVacationType.vacation_type_daycount;
            this.approval.contents.vacation_start = moment(this.start).format('YYYYMMDD');
            this.approval.contents.vacation_end = moment(this.end).format('YYYYMMDD');

            // if( this.computedSelectedVacationType.vacation_type_daycount < 1 ) {    
            if( this.computedSelectedVacationType.vacation_type_daycount == 0.5 ) {    
                this.approval.contents.vacation_am_pm = (this.computedSelectedVacationType.contents != null && this.computedSelectedVacationType.contents.amPm != null) ? this.computedSelectedVacationType.contents.amPm : 'AM';
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval`, API_METHOD.POST, this.approval);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("휴가 등록 중 오류 발생");
            }

            this.organization_vacation_add_modal_info.callback?.();
            this.close();

        } catch(e) {
            alert("휴가 등록 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    inputComment(event) {
        const value = event.target.value;

        if( this.approval != null ) {
            this.approval.contents.comment = value
        }
    }

}
