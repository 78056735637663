
import { Vue, Component, Mixins, Watch, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');
const ModalInfo = namespace('ModalInfo');

import moment from 'moment';
import { attendance_enum, attendance_modal_interface } from '@/model/attendance';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class AttendanceListDay extends Mixins(VueHoduCommon) {

    attendance_enum : any = attendance_enum;

    @Prop() event_bus !: Vue;

    /**
     * 정렬된 리스트
     */
    get computedAttendList() : any[] {
        const computed_list : any[] = JSON.parse(JSON.stringify(this.attend_list.filter(attend => attend.is_dayoff == false)));

        computed_list.sort((o1, o2) : number => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;
            if( this.sort_type == attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.USER_NAME ) {

                if( o1_name == o2_name ) return 0;
                else if( o1_name > o2_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.DEPARTMENT_NAME ) {

                const o1_dept_name = o1.dept_name;
                const o2_dept_name = o2.dept_name;

                if( o1_dept_name == null || o2_dept_name == null ) { 
                    if( o1_dept_name == null && o2_dept_name == null ) {
                        if( o1_name == o2_name ) return 0;
                        else if( o1_name > o2_name ) return  1;
                        else if( o1_name < o2_name ) return -1;
                    }
                    else if( o1_dept_name == null ) return  1;
                    else if( o2_dept_name == null ) return -1;
                }

                if( o1_dept_name == o2_dept_name ) {
                    if( o1_name == o2_name ) return 0;
                    else if( o1_name > o2_name ) return  1;
                    else if( o1_name < o2_name ) return -1;
                }
                else if( o1_dept_name > o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_dept_name < o2_dept_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.START ) {

                const o1_start = o1.start_time;
                const o2_start = o2.start_time;

                if( o1_start == null || o2_start == null ) { 
                    if( o1_start == null && o2_start == null ) {
                        if( o1_name == o2_name ) return 0;
                        else if( o1_name > o2_name ) return  1;
                        else if( o1_name < o2_name ) return -1;
                    }
                    else if( o1_start == null ) return  1;
                    else if( o2_start == null ) return -1;
                }

                if( o1_start == o2_start ) {
                    if( o1_name == o2_name ) return 0;
                    else if( o1_name > o2_name ) return  1;
                    else if( o1_name < o2_name ) return -1;
                }
                else if( o1_start > o2_start ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_start < o2_start ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.END ) {
                
                const o1_start = o1.start_time;
                const o2_start = o2.start_time;
                const o1_end = o1.end_time;
                const o2_end = o2.end_time;

                if( o1_end == null || o2_end == null ) { 
                    if( o1_end == null && o2_end == null ) {
                        
                        if( o1_start == null || o2_start == null ) { 
                            if( o1_start == null && o2_start == null ) {
                                if( o1_name == o2_name ) return 0;
                                else if( o1_name > o2_name ) return  1;
                                else if( o1_name < o2_name ) return -1;
                            }
                            else if( o1_start == null ) return  1;
                            else if( o2_start == null ) return -1;
                        }

                        if( o1_start == o2_start ) {
                            if( o1_name == o2_name ) return 0;
                            else if( o1_name > o2_name ) return  1;
                            else if( o1_name < o2_name ) return -1;
                        }
                        else if( o1_start > o2_start ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                        else if( o1_start < o2_start ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;

                    }
                    else if( o1_end == null ) return  1;
                    else if( o2_end == null ) return -1;
                }

                if( o1_end == o2_end ) {
                    if( o1_name == o2_name ) return 0;
                    else if( o1_name > o2_name ) return  1;
                    else if( o1_name < o2_name ) return -1;
                }
                else if( o1_end > o2_end ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_end < o2_end ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            return 0;
        });

        return computed_list;
    }

    /**
     * @CalendarInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @CalendarInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAttendanceRequestModalInfo ?: (params : attendance_modal_interface.AttendanceRequestModalInfo) => void;

    date_string : string = "";

    attend_list : any[] = [];
    total_attend : any = null;

    sort_type : attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE = attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE.START;
    sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    mounted() : void {
        this.event_bus.$on('listDayResize', this.handleResize);

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');
        this.datepickerInit();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const schedule_box_height = $('.schedule_box').outerHeight();
        const sort_header_height = $('.sortHeader').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                 - (schedule_box_height ? schedule_box_height : 0)
                                                 - (sort_header_height ? sort_header_height : 0);

        // @ts-ignore
        $('#day_attend_member_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });

        // @ts-ignore
        $('#day_attend_summary_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * 데이터 조회
     */
    @Watch('date_string')
    async getEmpAttendDataDayInfo() : Promise<void> {

        try {
            const date_string = this.date_string.replaceAll(/\./ig, "");

            // 근태현황 시작일이 적용 안됐다면 전부 제외
            if( this.getAttendanceStart() == '' ) {
                this.total_attend = JSON.parse(JSON.stringify({
                    awol_cnt:"0",
                    late_cnt:"0",
                    normal_cnt:"0",
                    uncheck_cnt:"0",
                    vacation_cnt:"0"
                }));
                this.attend_list.splice(0, this.attend_list.length);
                return;
            }

            // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
            if( Number(this.getAttendanceStart()) > Number(date_string) ) {
                this.total_attend = JSON.parse(JSON.stringify({
                    awol_cnt:"0",
                    late_cnt:"0",
                    normal_cnt:"0",
                    uncheck_cnt:"0",
                    vacation_cnt:"0"
                }));
                this.attend_list.splice(0, this.attend_list.length);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/data/day?date_string=${date_string}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_day_count_info || !response.data.data.attend_emp_day_data_info ) {
                throw new Error("일일 근태현황 데이터 조회 중 오류 발생");
            }

            this.total_attend = JSON.parse(JSON.stringify(response.data.data.attend_emp_day_count_info));
            
            this.attend_list.splice(0, this.attend_list.length);
            this.attend_list = this.attend_list.concat(response.data.data.attend_emp_day_data_info);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "데이터 조회 중 오류 발생", ['확인']);
        }

    }

    /**
     * 직원 근태 상태 변경
     */
    async updateOrganizationAttend(event : any, attend : any) : Promise<void> {
        
        const value = event.target.value;

        try {
            const data = JSON.parse(JSON.stringify(attend));
            data.attend_state = value;
            data.attend_type  = 10;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/${this.date_string.replaceAll(/\./ig, "")}`, API_METHOD.PUT, data);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("근무 상태 변경 중 오류 발생");
            }

            this.getEmpAttendDataDayInfo();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "근무 상태 변경 중 오류 발생", ['확인']);
        }

    }

    /**
     * 새로고침
     */
    async refresh() : Promise<void> {
        await this.hodu_show_indicator();
        await this.getEmpAttendDataDayInfo();
        await this.hodu_hide_indicator();
    }

    /**
     * 날짜 선택
     */
    datepickerInit() : void {

        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const date_moment = moment(dateText);
                
                vue.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
                vue.doSetSelectedDate(date_moment.toDate());
                vue.date_string = moment(vue.selected_date).format('YYYY.MM.DD');

                // @ts-ignore
                $('#datepicker').datepicker('setDate', vue.selected_date);
            },
        };

        // @ts-ignore
        $('#attendance-list-day-current-date').datepicker(option);
    }

    /**
     * 이전 날짜로 이동
     */
    async handlePrev() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.set('date', date_moment.get('date') - 1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 다음 날짜로 이동
     */
    async handleNext() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.set('date', date_moment.get('date') + 1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 오늘 날짜로 이동
     */
    async handleToday() : Promise<void> {
        
        const date_moment = moment();

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date());
    }

    /**
     * 달력 이동 해야 되는 날짜가 바뀐 것을 감지
     */
    @Watch('go_to_date')
    onDateChange() : void {
        const date_moment = moment(this.go_to_date);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM.DD');
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('span.img').addClass('no-image');
    }

    /**
     * 시간 텍스트 가져오기
     */
    getTimeText(time_text : string) : string {
        if( time_text == null || time_text.length < 6 ) return '';
        const time = [time_text.substring(0,2), time_text.substring(2,4), time_text.substring(4,6)].join(':');
        const target_date = moment(moment(this.selected_date).format('YYYY-MM-DD') + ` ${time}`).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    } 

    /**
     * 정렬
     */
    sort(sort_type : attendance_enum.ATTENDANCE_LIST_DAY_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * '정상' 상태인지 판단
     */
    isNormal(attend : any) : boolean {

        // 오늘 날짜면 
        const current_date_string = this.date_string.replaceAll(/\./ig, '');
        const today_date_string = moment().format('YYYYMMDD');

        // 미래 날짜는 무조건 false
        if( Number(current_date_string) > Number(today_date_string) ) {
            return false;
        }

        // 해당 일자의 work_cnt == 0 일때 (휴가 or 근무하는날 아님?) 무조건 정상
        if( Number(attend.work_cnt) == 0 ) {
            return true;
        }

        // 오늘 날짜는 출근 시각만 찍혔어도 정상 취급
        if( current_date_string == today_date_string && attend.attend_state == '20' && attend.start_time != null ) {
            return true;
        }

        // 그 외 정상 상태인 경우 true, 아니라면 false
        return attend.attend_state == '10';
    }

    /**
     * 오늘 여부
     */
    isToday() : boolean {
        return this.date_string == moment().format('YYYY.MM.DD');
    }

    /**
     * 휴가 이름 반환
     */
    getVacationName(vacation_names) : string {
        let name = "";

        for( const vacation_name of vacation_names ) {
            if( name.length > 0 ) name += ", ";
            name += vacation_name;
        }

        return name;
    }

    /**
     * 상태값 텍스트 반환
     */
    getStatusText(attend) : string {

        if( !( this.isNormal(attend) == true || attend.attend_state == '30' || attend.attend_state == '40' ) ) {
            return "미체크";
        }

        const current_date_string = this.date_string.replaceAll(/\./ig, '');
        const today_date_string = moment().format('YYYYMMDD');
        
        // 오늘 날짜는 출근 시각만 찍힌경우 근무중
        if( current_date_string == today_date_string && attend.attend_state == '20' && attend.start_time != null ) return "근무중";
        else if( this.isNormal(attend) == true ) return "정상";
        else if( attend.attend_state == '30' ) return "지각";
        else if( attend.attend_state == '40' ) return "결근";
        
        return "";
    } 

    /**
     * 요청사항 모달
     */
    showAttendanceRequestModal(attend) : void {

        console.log(attend);

        this.doSetAttendanceRequestModalInfo?.({ 
            show_modal : true,
            user_id : attend.user_id,
            yyyymmdd : this.date_string.replaceAll(/\./ig, ''),
            callback : this.refresh
        });
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getAttendanceStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;

        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 근무 안하는날 텍스트 반환 
     */
    getOffText(attend) : string {

        if( attend.attend_type == null ) {
            return '휴일';
        }

        if( attend.attend_type.indexOf(20) > -1 && attend.attend_type.indexOf(30) > -1 ) {
            return '휴가·출장';
        }

        if( attend.attend_type.indexOf(20) > -1 ) {
            return '휴가';
        }

        if( attend.attend_type.indexOf(30) > -1 ) {
            return '출장';
        }

        return '휴일';
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#day_attend_member_list_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#day_attend_summary_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }
}
