
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupTeamOtherOutModalInfo, GroupTeamOtherOutModalData } from '@/store/modules/ModalInfo';
const ModalInfo = namespace('ModalInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class GroupTeamOtherOutModal extends Mixins(VueHoduCommon) {
    /**
     * ModalInfo.State
     */
    @ModalInfo.State group_team_other_out_info !: GroupTeamOtherOutModalInfo;

    /**
     * 변수 선언부
     */
    data  !: GroupTeamOtherOutModalData;
    name   : string = "";
    role   : string = "";
    email  : string = "";
    phone  : string = "";
    image  : string = "";

    user_profile_no_image = require("@/assets/images/contents/im_photoB.gif");

    async mounted() {
        if ( null != this.group_team_other_out_info.data ) {
            this.data  = this.group_team_other_out_info.data;
            this.name  = this.data.user_name;
            this.email = this.data.user_email;
            this.phone = this.data.user_phone_number;
            this.image = this.data.image;

            if ( this.data.team_id == 0 ) {
                this.role = this.data.role == this.GroupAuth.ADMIN ? '최종관리자' : (this.data.role == this.GroupAuth.GROUP_MANAGER ? "관리자" 
                                                                                 : (this.data.role == this.GroupAuth.GROUP_SUB_MANAGER ? this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? '보안실' : "부관리자" 
                                                                                                                                       : this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? '입주자' : "멤버"));
            }
            else {
                this.role = this.data.role == this.GroupAuth.ADMIN ? '최종관리자' :  (this.data.role == this.GroupAuth.TEAM_MANAGER ? this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? '동장' : "팀장" 
                                                                                 : this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? '입주자' : "멤버");
            }
        }
    }

    groupTeamOtherOutCancel() : void {
        window["groupTeamOtherOutCancel"]();
    }

    groupTeamOtherOutConfirm() : void {
        window["groupTeamOtherOutConfirm"](this.data);
    }    
}
