
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_interface, organization_modal_interface } from '@/model/organization';

@Component({
    components: {
        
    },
})
export default class OrganizationSignModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State 
     */
    @ModalInfo.State organization_sign_modal_info !: organization_modal_interface.OrganizationSignModalInfo;

    /**
     * @ModalInfo.Action 
     */
    @ModalInfo.Action doSetOrganizationSignModalInfo ?: (params : organization_modal_interface.OrganizationSignModalInfo) => void;

    employee : any = null;

    is_create : boolean = true;
    
    // 업로드 이전
    file_obj : any = null;
    file : File | null = null;

    mounted() : void {
        this.getEmployee();
    }

    /**
     * 직원 정보 가져오기
     */
    async getEmployee() : Promise<void> {

        try {
            const group_id = this.organization_sign_modal_info.group_id ?? 0;
            const user_id = this.organization_sign_modal_info.user_id ?? 0;

            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp?user_id=${user_id}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("직원 정보 조회 중 오류 발생");
            }

            this.employee = JSON.parse(JSON.stringify(response.data.data.emp_info));

            this.is_create = (this.employee.contents.sign == null);
            if( this.is_create == false ) {
                this.file_obj = JSON.parse(JSON.stringify(this.employee.contents.sign));
            }

        } catch(e) {
            alert("직원 정보 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }

    }

    changeImageTrigger() : void {
        $('#cdImg').trigger('click');
    }

    async changeImage(event) : Promise<void> {
        const files : File[] = event.target.files;

        console.log(files);

        await this.hodu_show_indicator();
        try {

            const fr = await this.fileReaderPromise(files[0]);

            // onloadend로 들어온 경우
            if( fr == null ) {
                $('#cdImg').val("");
                return;
            }

            if( fr.target == null || fr.target.result == null ){
                return;
            }

            let base64url : string = "";

            if( fr.target.result instanceof ArrayBuffer ){
                const arrayBuffer : Uint8Array = new Uint8Array(fr.target.result);
                const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                base64url = decodeURIComponent(url);
            } else {
                base64url = fr.target.result;
            }

            const blob : Blob = await this.hodu_image_resize(base64url);
            const url :string = URL.createObjectURL(blob);

            let resize_file : File = files[0];
            try{
                resize_file = await this.hodu_blob_to_file(blob, files[0].name);
            } catch(e) {
                try {
                    (blob as any).lastModifiedDate = new Date();
                    (blob as any).name = files[0].name;
                    resize_file = (blob as any);
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                }
            }

            const file_obj = {
                name: resize_file.name,         // 원본 파일명
                mimeType: resize_file.type,     // MIME TYPE
                url: URL.createObjectURL(blob), // 파일 경로
                size: resize_file.size,         // 파일 크기
                date: new Date()                // 저장 날짜
            }

            this.file_obj = JSON.parse(JSON.stringify(file_obj));
            this.file = files[0];

            // vue.event.event_data.attachment.imgs.push({
            //     name: resize_file.name,         // 원본 파일명
            //     mimeType: resize_file.type,     // MIME TYPE
            //     url: URL.createObjectURL(blob), // 파일 경로
            //     size: resize_file.size,         // 파일 크기
            //     date: new Date()                // 저장 날짜
            // })

            // // 업로드용 파일 객체 담기
            // vue.image_files.push({
            //     index : ( vue.event.event_data.attachment.imgs.length - 1 ), 
            //     file : resize_file
            // });
            $('#cdImg').val("");

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 사인 이미지 저장
     */
    async save() : Promise<void> {

        if( this.file != null ) {

            const group_id = this.organization_sign_modal_info.group_id ?? 0;
            const user_id = this.organization_sign_modal_info.user_id ?? 0;

            try {

                const form_data : FormData = new FormData();
                form_data.append('file', this.file);

                const upload_response = await this.hodu_temp_upload(form_data);
                
                console.log(upload_response);

                if( !upload_response || !this.isHttpStatusSuccess(upload_response.status) || !upload_response.data || !upload_response.data.data ) {
                    throw new Error("이미지 임시 업로드 실패");
                }

                const temp_files : any[] = upload_response.data.data.temp_files;

                if( temp_files.length < 1 ) {
                    throw new Error("이미지 임시 업로드 실패");
                }

                const insert_or_update_response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/${user_id}/sign`, API_METHOD.POST, {
                    "files" : temp_files
                });

                console.log(insert_or_update_response);

                if( !insert_or_update_response || !this.isHttpStatusSuccess(insert_or_update_response.status) ) {
                    throw new Error("사인 이미지 등록 실패");
                }

                this.organization_sign_modal_info.callback?.();
                this.close();
                
            } catch(e) {
                alert("사인 등록 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
                return;
            }
        }
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationSignModalInfo?.({ show_modal : false });
    }

}
