
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { EVENT_SUB_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
const dateFormat = require('dateformat');
const moment = require('moment');
const GroupInfo  = namespace('GroupInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class GroupStatistics extends Mixins(VueHoduCommon) {
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_statistics_start_date !: string;

    /**
     * Prop 변수 선언부
     */
    @Prop() private group_id !: number;
    @Prop() private team_id !: number;
    
    /**
     * 변수 선언부
     */    
    start_date : string = "";
    end_date   : string = "";
    event      : any    = [];
    files      : any    = [];
    arr                 = new Array();

    async mounted() {
        const vue = this;

        // date_picker
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                vue.setWeekDate(new Date(dateText));
            },
        };

        // @ts-ignore
        $('#weekDayChange').datepicker(option);

        // 차트 일정 업무 파일별로 보기
        $(".description p").click(function(){
            var getIndexx =  $(this).index();
            $(this).parent().siblings().find("a").removeClass("on");
            
            $(this).siblings().removeClass("on");
            $(this).toggleClass("on");
            
            if (getIndexx == 0 && $(this).hasClass("on")) {
                $(".first .chartUl").removeClass("file");
                $(".first .chartUl").removeClass("work");
                $(".first .chartUl").addClass("schedule");
                $(this).siblings().css({opacity:0.5});
                $(this).css({opacity:1});
            } else if (getIndexx == 1 && $(this).hasClass("on")) {
                $(".first .chartUl").removeClass("file");
                $(".first .chartUl").removeClass("schedule");
                $(".first .chartUl").addClass("work");
                $(this).siblings().css({opacity:0.5});
                $(this).css({opacity:1});
            } else if (getIndexx == 2 && $(this).hasClass("on")) {
                $(".first .chartUl").removeClass("schedule");
                $(".first .chartUl").removeClass("work");
                $(".first .chartUl").addClass("file");
                $(this).siblings().css({opacity:0.5});
                $(this).css({opacity:1});
            } else if ( $(".description p").not("on") ) {
                $(".first .chartUl").removeClass("file");
                $(".first .chartUl").removeClass("work");
                $(".first .chartUl").removeClass("schedule");
                $(".description p").css({opacity:1});
            } else {
                // DO NOTHING
            }
        });        

        this.start_date = this.group_statistics_start_date;

        this.setWeekDate(this.start_date ? new Date(this.start_date) : new Date());
    }

    /**
     * 조회
     */
    async getList() : Promise<void> {
        const vue  = this;
        this.event = [];
        this.files = [];

        let max_create_count : number = 10;

        await this.hodu_api_call(`api/v1/groupsweb/getGroupStatistics/${this.group_id}/${this.team_id}?start_date=${this.start_date}&end_date=${this.end_date}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                this.event = response.data.data.event;
                this.files = response.data.data.files;
                this.arr   = new Array(7);

                // --------------------------------------------------------
                // 배열 만들기
                // --------------------------------------------------------
                for ( let i = 0; i < 7; i++ ) {
                    let mmdd    = this.getDayTerm(new Date(this.start_date), i, "mm.dd");
                    let ymd     = this.getDayTerm(new Date(this.start_date), i, "yyyy-mm-dd");

                    this.arr[i] = {mmdd: mmdd, ymd: ymd, yoil: this.getYoil(ymd), basic_count: 0, basic_persent: 0, work_count: 0, work_persent: 0, file_count: 0, file_persent: 0};
                }

                // --------------------------------------------------------
                // 일정,업무 만들기
                // --------------------------------------------------------
                for ( let i = 0; i < this.event.length; i++ ) {
                    let lo_data    = this.event[i];
                    let li_persent = 0;

                    if ( lo_data.event_sub_type == EVENT_SUB_TYPE.SCHEDULE ) {
                        let yoil_idx = this.getYoilIdx(moment(new Date(lo_data.day)).format('YYYY.MM.DD'));
                        let count = this.arr[yoil_idx].basic_count + 1;

                        this.arr[yoil_idx].basic_count = count;

                        (count >= max_create_count ) ? li_persent = 100 : li_persent = (count / max_create_count * 100);

                        this.arr[yoil_idx].basic_persent = li_persent;
                    }
                    else if ( lo_data.event_sub_type == EVENT_SUB_TYPE.WORK ) {
                        let yoil_idx = this.getYoilIdx(moment(new Date(lo_data.day)).format('YYYY.MM.DD'));
                        let count = this.arr[yoil_idx].work_count + 1;

                        this.arr[yoil_idx].work_count = this.arr[this.getYoilIdx(moment(new Date(lo_data.day)).format('YYYY.MM.DD'))].work_count + 1;

                        (count >= max_create_count ) ? li_persent = 100 : li_persent = (count / max_create_count * 100);

                        this.arr[yoil_idx].work_persent = li_persent;
                    }
                }

                // --------------------------------------------------------
                // 파일 만들기
                // --------------------------------------------------------      
                for ( var i = 0; i < this.files.length; i++ ) {
                    let lo_data    = this.files[i];
                    let li_persent = 0;
                    let yoil_idx   = this.getYoilIdx(moment(new Date(lo_data.day)).format('YYYY.MM.DD'));
                    let count      = this.arr[yoil_idx].file_count + 1;

                    this.arr[yoil_idx].file_count = count;

                    (count >= max_create_count ) ? li_persent = 100 : li_persent = (count / max_create_count * 100);

                    this.arr[yoil_idx].file_persent = li_persent;                    
                }                

                // --------------------------------------------------------
                // 일정,업무 만들기
                // --------------------------------------------------------
                /*
                for ( let i = 0; i < this.event.length; i++ ) {
                    let lo_data    = this.event[i];
                    let li_persent = 0;

                    if ( lo_data.event_sub_type == "BASIC" ) {
                        this.arr[this.getYoilIdx(lo_data.day)].basic_count = lo_data.count;

                        (lo_data.count >= max_create_count ) ? li_persent = 100 : li_persent = (lo_data.count / max_create_count * 100);

                        this.arr[this.getYoilIdx(lo_data.day)].basic_persent = li_persent;
                    }
                    else {
                        this.arr[this.getYoilIdx(lo_data.day)].work_count = lo_data.count;

                        (lo_data.count >= max_create_count ) ? li_persent = 100 : li_persent = (lo_data.count / max_create_count * 100);

                        this.arr[this.getYoilIdx(lo_data.day)].work_persent = li_persent;
                    }
                }

                // --------------------------------------------------------
                // 파일 만들기
                // --------------------------------------------------------      
                for ( var i = 0; i < this.files.length; i++ ) {
                    let lo_data    = this.files[i];
                    let li_persent = 0;

                    this.arr[this.getYoilIdx(lo_data.day)].file_count = lo_data.count;

                    (lo_data.count >= max_create_count ) ? li_persent = 100 : li_persent = (lo_data.count / max_create_count * 100);

                    this.arr[this.getYoilIdx(lo_data.day)].file_persent = li_persent;                    
                }
                */
            })
            .catch(async(e) => {
                //this.hodu_error_process(e, true, false);
            });
    }    

    /**
     * 날짜 세팅
     */
    setWeekDate(date) : void {
        this.start_date = dateFormat(date, "yyyy-mm-dd");
        this.start_date = this.getDayTerm(new Date(this.start_date), (this.getYoilIdx(this.start_date) * -1), "yyyy-mm-dd");

        // 새로고침 시 날짜 저장
        this.doGroupStatisticsStartDate(this.start_date);

        this.end_date   = this.getDayTerm(new Date(this.start_date), 6, "yyyy-mm-dd");
        let text = (this.start_date.substring(0,4) == this.end_date.substring(0,4) ? 
                    moment(this.start_date).format('YYYY.MM.DD') + " - " + moment(this.end_date).format('YYYY.MM.DD').substring(5,10) : 
                    moment(this.start_date).format('YYYY.MM.DD') + " - " + moment(this.end_date).format('YYYY.MM.DD'));

        $('#weekDayChange').val(text);
        this.getList();
    }

    /**
     * 요일 구하기
     */
    getYoil(date) : string {
        var week = ['일', '월', '화', '수', '목', '금', '토'];
        var dayOfWeek = week[new Date(date).getDay()];
        return dayOfWeek;
    }

    /**
     * 요일 구하기
     */
    getYoilIdx(date) : number {
        var dayOfWeek = new Date(date).getDay();
        return dayOfWeek;
    }    

    /**
     * Day Term 구하기
     */
    getDayTerm(date, term, format) : string {
        return dateFormat(moment(date).add(term,'day'), format);
    }

    /**
     * 몇번째 주인지 구하기
     */
    getWeekOfMonth(dateObj) : number {
        var _date = dateObj.getDate();
        var _dayOfWeek = dateObj.getDay();
        
        return Math.ceil((6 + _date - _dayOfWeek) / 7);
    }
}
