import { render, staticRenderFns } from "./KidsAttendanceListMonth.vue?vue&type=template&id=64437f07&scoped=true&"
import script from "./KidsAttendanceListMonth.vue?vue&type=script&lang=ts&"
export * from "./KidsAttendanceListMonth.vue?vue&type=script&lang=ts&"
import style0 from "./KidsAttendanceListMonth.vue?vue&type=style&index=0&id=64437f07&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64437f07",
  null
  
)

export default component.exports