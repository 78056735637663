
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, {API_METHOD} from '@/mixin/VueHoduCommon';

import { t_event_file } from '@/model/event';
import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
import { approval_modal_interface, approval_interface } from '@/model/approval';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ApprovalFileModal extends Mixins(VueHoduCommon) {

    /**
     * 분류 중 템플릿이 존재하는 리스트
     */
    get computedClassExistTemplate() : any[] {
        return this.template_classes.filter(item => item['files'] != null && item['files'].length > 0);
    }

    /**
     * 선택된 분류의 양식
     */
    get computedSelectedClassTemplate() : any[] {
        return this.computedClassExistTemplate[this.selected_class_index]['files'];
    }

    accept_mime_type = ".pdf"
    
    arrO_files : File[] = []
    template_files : any[] = []
    template_classes : approval_interface.t_approval_template_class[] = []
    keep_years : number[] = [1,2,3,4,5]
    
    selected_class_index = 0
    selected_template_index = 0
    selected_file_index = 0

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State approval_file_modal_info !: approval_modal_interface.ApprovalFileModalInfo

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalFileModalInfo ?: (params : approval_modal_interface.ApprovalFileModalInfo) => void;
    
    mounted() : void {
        
        if ( this.approval_file_modal_info.is_create ) {
            this.accept_mime_type = ".pdf, .csv, .xls, .xlsx, .doc, .docx, .rtf"
        }

        this.getApprovalTemplateClasses()
    }

    async getApprovalTemplateClasses() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class?include_templates=${true}`, API_METHOD.GET, null, false);
            
            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }
            
            this.template_classes.splice(0, this.template_classes.length);
            this.template_classes = this.template_classes.concat(response.data.data.approval_templates.filter(template => template.template_state == '0' || template.template_state == '10'));

            // 분류 없음이 가장 먼저 나옴
            this.template_classes.sort((o1, o2) : number => {
                if( o1.template_state > o2.template_state ) return 1;
                else if( o1.template_state < o2.template_state ) return -1;
                else if( o1.template_class_id > o2.template_class_id ) return 1;
                else if( o1.template_class_id < o2.template_class_id ) return -1;
                return 0;
            });

            if ( this.approval_file_modal_info.template_class_id != null ) {
                const size = this.approval_file_modal_info.is_create == true ? this.template_classes.length : this.computedClassExistTemplate.length
                for (let i=0; i<size; i++) {
                    const tempClass = this.approval_file_modal_info.is_create == true ? this.template_classes[i] : this.computedClassExistTemplate[i];
                    if ( tempClass.template_class_id == this.approval_file_modal_info.template_class_id ) {
                        this.selected_class_index = i
                    }
                }
            }


        } catch(e) {
            throw e;
        }
    }

    /**
     * 파일 추가
     */
    async uploadFiles() : Promise<void> {
        if ( this.arrO_files.length < 1 ) {
            return
        }

        const files_length : number   = this.arrO_files.length;
        const form_data    : FormData = new FormData();

        for ( let i = 0; i < files_length; i++ ) {
            form_data.append('file', this.arrO_files[i]);
        }

        try {
            const response = await this.hodu_temp_upload(form_data);
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("양식 파일 임시 업로드 실패");
            }
            
            const temp_files : t_event_file[] = response.data.data.temp_files;
            await this.insertTemplateFiles(temp_files);
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("양식 파일 업로드 중 오류 발생");
        }

        
    }

    // /**
    //  * 파일 저장
    //  */
    async insertTemplateFiles(temp_files : t_event_file[]) : Promise<void> {
        
        let body = {
            templates : this.template_files,
            files     : temp_files
        }

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form`, API_METHOD.POST, body)
            
            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("양식 파일 업로드 실패");
            }

            this.approval_file_modal_info.callback?.()
            this.cancel();

        } catch(e) {
            throw e;
        }

    }

    addFiles(event) : void {
        for (const file of event.target.files) {
            this.arrO_files.push(file as File);
        }

        const files : File[] = (event.target.files as File[]);

        for (const file of files) {

            if( file.size > this.DEFAULT_FILE_MAX_SIZE ) {
                alert(`${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`);
                $('#btn_grp').val("");
                return;
            }

            // 확장자가 없는 파일
            if( file.name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#btn_grp').val("");
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(file.name.substring(file.name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ file.name.substring(file.name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#btn_grp').val("");
                return;
            }

            // TODO 전용 확장자 추가 확인 (어느 순간부터 확장자 제한을 해도 브라우저 파일 탐색기에서 모든 파일을 볼수있게 변경됐음)
            
            const approval_template : approval_interface.t_approval_template = {
                group_id : this.scope_group_id,
                template_class_id : this.approval_file_modal_info.is_create ? this.template_classes[this.selected_class_index].template_class_id : this.computedClassExistTemplate[this.selected_class_index].template_class_id,
                template_id : this.approval_file_modal_info.is_create ? 0 : this.computedSelectedClassTemplate[0].template_id,
                template_name : file.name,
                contents : {
                    file_url  : "",
                    file_name : file.name,
                    file_size : file.size,
                    mime_type : file.type,
                    keep_year : 1
                },
                audit_created : "",
                audit_create_id : 0,
                audit_modified : "",
                audit_modify_id : 0,
                audit_delete_flag : false,
            }

            // 기안서 생성
            if( this.approval_file_modal_info.is_create == false ) {
                const target_approver : any[] = [];
                for( const approver of this.computedSelectedClassTemplate[0].approver ) target_approver.push(JSON.parse(JSON.stringify(approver)));
                approval_template.approver = this.approval_file_modal_info.is_create ? [] : target_approver;

                // 템플릿 이름으로 검색해서 걸린 첫번째 template_class_id, template_id를 부여한다
                let is_find : boolean = false;
                for( const template_class of this.computedClassExistTemplate ) {
                    for( const template_file of template_class['files'] ) {
                        console.log(`${template_class.template_class_name}-${template_file.template_name}`);
                        
                        if( this.hodu_string_includes(approval_template.contents.file_name, template_file.template_name) ) {
                            is_find = true;
                            approval_template.approver = template_file.approver;
                            approval_template.self_approver = template_file.self_approver;
                            approval_template.template_class_id = template_file.template_class_id;
                            approval_template.template_id = template_file.template_id;
                            break;
                        }
                    }

                    if( is_find == true ) {
                        break;
                    }
                }
            }

            this.template_files.push(approval_template);
        }
        
        this.selected_file_index = this.template_files.length - 1;

        this.$forceUpdate();
        this.$nextTick(() => {

            if( this.template_files == null || this.template_files.length < 1 ) {
                return;
            }

            const target_class = this.computedClassExistTemplate.filter(item => this.template_files[this.selected_file_index].template_class_id == item.template_class_id);
            this.selected_class_index = target_class.length > 0 ? this.computedClassExistTemplate.indexOf(target_class[0]) : 0;
            
            const target_template = this.computedSelectedClassTemplate.filter(item => this.template_files[this.selected_file_index].template_id == item.template_id);            
            this.selected_template_index = target_template.length > 0 ? this.computedSelectedClassTemplate.indexOf(target_template[0]) : 0;

            this.$forceUpdate();
        });
        
        $('#btn_grp').val("");
    }

    selectedFile(index: number) {
        this.selected_file_index = index

        if( this.approval_file_modal_info.is_create == false ) {
            this.$nextTick(() => {
                const target_class = this.computedClassExistTemplate.filter(item => this.template_files[this.selected_file_index].template_class_id == item.template_class_id);
                this.selected_class_index = target_class.length > 0 ? this.computedClassExistTemplate.indexOf(target_class[0]) : 0;
                
                const target_template = this.computedSelectedClassTemplate.filter(item => this.template_files[this.selected_file_index].template_id == item.template_id);
                this.selected_template_index = target_template.length > 0 ? this.computedSelectedClassTemplate.indexOf(target_template[0]) : 0;

                this.$forceUpdate();
            });
        }
    }

    deleteFile(index: number) {
        this.template_files.splice(index, 1)
        this.arrO_files.splice(index, 1)
    }

    changeClass(event) : void {

        if( event.target == null ) return;

        const class_id = event.target.value
        const selectedClass = this.template_classes.filter(item => item.template_class_id == class_id)[0];

        if( selectedClass == null ) return;
        
        this.selected_class_index = this.approval_file_modal_info.is_create ? this.template_classes.indexOf(selectedClass) : this.computedClassExistTemplate.indexOf(selectedClass);
        this.selected_template_index = 0;

        const selectedTemplate = this.computedSelectedClassTemplate[0];

        if( selectedTemplate == null ) return;

        if( this.approval_file_modal_info.is_create == false ) {
            this.template_files[this.selected_file_index].contents.keep_year = selectedTemplate.contents.keep_year;
            this.template_files[this.selected_file_index].approver = selectedTemplate.approver;
            this.template_files[this.selected_file_index].template_class_id = selectedClass.template_class_id;
            this.template_files[this.selected_file_index].template_id = selectedTemplate.template_id;
        }
    }

    changeTemplate(event) : void {

        if( event.target == null ) return;

        const template_id = event.target.value
        const selectedTemplate = this.computedSelectedClassTemplate.filter(item => item.template_id == template_id)[0];

        if( selectedTemplate == null ) return;
        
        this.selected_template_index = this.computedSelectedClassTemplate.indexOf(selectedTemplate);
        this.template_files[this.selected_file_index].approver = selectedTemplate.approver;
        this.template_files[this.selected_file_index].contents.keep_year = selectedTemplate.contents.keep_year;
        this.template_files[this.selected_file_index].template_id = selectedTemplate.template_id;
    }

    changeKeepYear(event) : void {
        let selectedYear = event.target.value as number
        this.template_files[this.selected_file_index].contents.keep_year = selectedYear
    }

    getTemplateClassNameById(id : number) : string {
        const target = this.template_classes.filter(item => item.template_class_id == id);

        if( target.length > 0 )  {
            return target[0].template_class_name;
        }

        return "";
    }

    getTemplateNameById(id : number) : string {
        for( const template_class of this.template_classes ) {
            for( const template of template_class['files'] ) {
                if( template.template_id == id ) return template.template_name;
            }
        }

        return "";
    }

    cancel() : void {
        this.doSetApprovalFileModalInfo?.({show_modal:false})
    }

    async save() : Promise<void> {

        for( const file of this.template_files ) {
            if( file.template_name == null || file.template_name.trim().length < 1 ) {
                alert(`${this.template_files.indexOf(file) + 1}번째 양식의 이름을 입력해주세요`)
                return;
            }
        }

        if ( this.approval_file_modal_info.is_create != null && this.approval_file_modal_info.is_create == true && this.arrO_files.length > 0 ) {
            this.uploadFiles()
        } else{
            try {
                for( const template_file of this.template_files ) {
                    template_file.template_class_name = this.getTemplateClassNameById(template_file.template_class_id);
                    template_file.template_name = this.getTemplateNameById(template_file.template_id);
                }

                await this.approval_file_modal_info.callback?.(this.template_files, this.arrO_files);
                this.cancel();
            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                alert("기안서 파일 업로드 중 오류 발생");
            }
        }
    }

}
