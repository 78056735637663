import {GetterTree, MutationTree , ActionTree} from "vuex";

export const namespaced = true;

export interface WorkInfoState {
    work_template_id : string;
    work_search_info : WorkSearchInfo;
    report_search_info : WorkSearchInfo;
    meetinglog_search_info : WorkSearchInfo;
    work_status_info : WorkStatusInfo;
}

export interface WorkSearchInfo {
    search_query ?: string;
    search_start_date ?: Date;
    search_end_date ?: Date;
    search_template_type ?: string;
    search_work_status ?: string;
}

export interface WorkStatusInfo {
    calendar_id : string;
    event_id : string;
    scope : string;
    scope_id : number;
}

export const state: WorkInfoState = {
    work_template_id : "",
    work_search_info : { search_query : "" },
    report_search_info : { search_query : "" },
    meetinglog_search_info : { search_query : "" },
    work_status_info : {
        calendar_id : "",
        event_id : "",
        scope : "",
        scope_id : 0
    }
}

// getters
export const getters: GetterTree<WorkInfoState, any> = {
    getWorkTemplateId: (state) => {
        return state.work_template_id;
    },

    getWorkSearchInfo: (state) => {
        return state.work_search_info;
    },

    getReportSearchInfo: (state) => {
        return state.report_search_info;
    },

    getMeetinglogSearchInfo: (state) => {
        return state.meetinglog_search_info;
    },

    getWorkStatusInfo: (state) => {
        return state.work_status_info;
    },
}

// action
export const actions: ActionTree<WorkInfoState, WorkInfoState> = {
    doSetWorkTemplateId: ({commit, state}, work_template_id : string) => {
        console.log("actions.doSetWorkTemplateId");
        commit("setWorkTemplateId", work_template_id);
    },
    
    doSetWorkSearchInfo: ({commit, state}, work_search_info : WorkSearchInfo) => {
        console.log("actions.doSetWorkSearchInfo");
        commit("setWorkSearchInfo", work_search_info);
    },

    doSetReportSearchInfo: ({commit, state}, report_search_info : WorkSearchInfo) => {
        console.log(`actions.doSetReportSearchInfo ${JSON.stringify(report_search_info)}`);
        state.report_search_info.search_query         = report_search_info.search_query ? report_search_info.search_query : "";
        state.report_search_info.search_start_date    = report_search_info.search_start_date;
        state.report_search_info.search_end_date      = report_search_info.search_end_date;
        state.report_search_info.search_template_type = report_search_info.search_template_type;
        state.report_search_info.search_work_status   = report_search_info.search_work_status;

        if( sessionStorage != null ){
            sessionStorage.setItem("work_info", JSON.stringify(state));
        }
    },
    
    doSetMeetinglogSearchInfo: ({commit, state}, meetinglog_search_info : WorkSearchInfo) => {
        console.log(`actions.doSetMeetinglogSearchInfo ${JSON.stringify(meetinglog_search_info)}`);
        state.meetinglog_search_info.search_query         = meetinglog_search_info.search_query ? meetinglog_search_info.search_query : "";
        state.meetinglog_search_info.search_start_date    = meetinglog_search_info.search_start_date;
        state.meetinglog_search_info.search_end_date      = meetinglog_search_info.search_end_date;
        state.meetinglog_search_info.search_template_type = meetinglog_search_info.search_template_type;
        state.meetinglog_search_info.search_work_status   = meetinglog_search_info.search_work_status;

        if( sessionStorage != null ){
            sessionStorage.setItem("work_info", JSON.stringify(state));
        }
    },

    doSetWorkStatusInfo: ({commit, state}, work_status_info : WorkStatusInfo) => {
        console.log("actions.doSetWorkStatusInfo");
        commit("setWorkStatusInfo", work_status_info);
    },
}

// mutation
export const mutations: MutationTree<WorkInfoState> = {
    setWorkInfo: (state, payload : WorkInfoState) => {
        console.log(`mutations.setWorkInfo ${JSON.stringify(payload)}`);
        state.work_template_id                      = payload.work_template_id;
        
        state.work_search_info.search_query         = payload.work_search_info.search_query;
        state.work_search_info.search_start_date    = payload.work_search_info.search_start_date;
        state.work_search_info.search_end_date      = payload.work_search_info.search_end_date;
        state.work_search_info.search_template_type = payload.work_search_info.search_template_type;
        state.work_search_info.search_work_status   = payload.work_search_info.search_work_status;

        state.report_search_info.search_query      = payload.report_search_info.search_query;
        state.report_search_info.search_start_date = payload.report_search_info.search_start_date;
        state.report_search_info.search_end_date   = payload.report_search_info.search_end_date;

        state.meetinglog_search_info.search_query      = payload.meetinglog_search_info.search_query;
        state.meetinglog_search_info.search_start_date = payload.meetinglog_search_info.search_start_date;
        state.meetinglog_search_info.search_end_date   = payload.meetinglog_search_info.search_end_date;

        if( sessionStorage != null ){
            sessionStorage.setItem("work_info", JSON.stringify(state));
        }
    },

    setWorkTemplateId: (state, payload : string) => {
        console.log(`mutations.setWorkTemplateId ${JSON.stringify(payload)}`);
        state.work_template_id = payload;

        if( sessionStorage != null ){
            sessionStorage.setItem("work_info", JSON.stringify(state));
        }
    },

    setWorkSearchInfo: (state, payload : WorkSearchInfo) => {
        console.log(`mutations.setWorkSearchInfo ${JSON.stringify(payload)}`);
        state.work_search_info.search_query         = payload.search_query ? payload.search_query : "";
        state.work_search_info.search_start_date    = payload.search_start_date;
        state.work_search_info.search_end_date      = payload.search_end_date;
        state.work_search_info.search_template_type = payload.search_template_type;
        state.work_search_info.search_work_status   = payload.search_work_status;

        if( sessionStorage != null ){
            sessionStorage.setItem("work_info", JSON.stringify(state));
        }
    },

    setWorkStatusInfo: (state, payload : WorkStatusInfo) => {
        console.log(`mutations.setWorkStatusInfo ${JSON.stringify(payload)}`);
        state.work_status_info.calendar_id = payload.calendar_id;
        state.work_status_info.event_id    = payload.event_id;
        state.work_status_info.scope       = payload.scope;
        state.work_status_info.scope_id    = payload.scope_id;
    }
}