
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

@Component({
    components : {

    }
}) export default class HoduInlineDatePicker extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue; // Event Bus

    @Prop() dp_id    !: string; // 최상단 div에 부여할 ID
    @Prop() dp_class !: string; // 최상단 div에 부여할 클래스

    @Prop() min_date !: Date; // 선택 가능한 날짜 최소치
    @Prop() max_date !: Date; // 선택 가능한 날짜 최대치
    @Prop() default_date !: Date;

    @Prop() changeYear !: boolean;

    @Prop() other_month_selectable !: boolean; // 다른 달의 날짜를 클릭 했을 때 날짜 변경 emit 실행 여부

    mounted() : void {
        
        // 이벤트 버스를 사용하는 경우 날짜 변경 함수 연결
        if( this.event_bus != null ) { this.event_bus.$on('changeDate', this.changeDate); }

        // @ts-ignore
        $(`#${this.dp_id}`).datepicker({
            minDate : this.min_date ? this.min_date : null,
            maxDate : this.max_date ? this.max_date : null,
            defaultDate : this.default_date ? this.default_date : null,
            showOtherMonths : true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            changeYear : this.changeYear != null ? this.changeYear : false,
            monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix: '.',
            showMonthAfterYear: true,
            yearRange  : '1900:2050',
            onSelect: (date_string : string , data : any) => {
                
                // 다른 달 선택 가능 여부에 따라서 진행
                if( new Date(date_string).getMonth() != data.drawMonth ) {
                    this.changeDate(date_string);
                    if( !this.other_month_selectable ) { return; }
                }

                this.$emit('select', date_string, data);
            },
            onChangeMonthYear: (year: number, month: number, inst: any) => {
                this.$emit('changeMonthYear', year, month, inst);
            },
        });

    }

    /**
     * 날짜 변경
     */
    changeDate(date : Date | string) : void {
        date = date instanceof Date ? date : new Date(date);

        // @ts-ignore
        $(`#${this.dp_id}`).datepicker('setDate', date);
    }

}
