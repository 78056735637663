
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import moment from 'moment';

@Component({
    components: {
        
    },
})
export default class HomeVisitantAmanoInfoModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_visitant_amano_info_modal_info !: hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeVisitantAmanoInfoModalInfo ?: (params : hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo) => void;

    car : any = null;

    mounted() : void {
        this.car = JSON.parse(JSON.stringify(this.home_visitant_amano_info_modal_info.amano_info.car));
    }

    /**
     * 차량 상태값 텍스트 반환
     */
    getCarStateText(state) : string {

        switch(state) {
            case "EXPECTED": return "예정";
            case "IN": return "입차";
            case "OUT": return "출차";
        }

        return '';
    }

    /**
     * 방문 예정 날짜
     */
    getVisitDate(dtm) : string {
        const date = new Date([dtm.substring(0,4), dtm.substring(4,6), dtm.substring(6,8)].join('-'));
        return `${this.hodu_date_to_format_string(date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
    }

    /**
     * 방문 예정 날짜 오전/오후
     */
    getVisitAmPm(dtm) : string {
        const hour = Number(dtm.substring(8,10));

        return hour >= 0 && hour <= 11 ? "오전" : "오후";
    }

    /**
     * 방문 예정 날짜 시간
     */
    getVisitTime(dtm) : string {

        const hour = Number(dtm.substring(8,10));
        const min = Number(dtm.substring(10,12));

        const is_am = hour >= 0 && hour <= 11;

        let hour_min = "";
        if( is_am && hour == 0 ) {
            hour_min = `12:${`0${min}`.slice(-2)}`;
        }
        else if( is_am || (!is_am && hour == 12) ) {
            hour_min = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)}`;
        }
        else {
            hour_min = `${`0${hour - 12}`.slice(-2)}:${`0${min}`.slice(-2)}`;
        }

        return hour_min;
    }

    /**
     * 방문기간
     */
    getVisitPeriod(car) : string {
        const start = car.startDtm;
        const end = car.endDtm;

        const start_yyyy_mm_dd = [start.substring(0,4), start.substring(4,6), start.substring(6,8)].join('-');
        const start_hh_mm_ss = [start.substring(8,10), start.substring(10,12), start.substring(12,14)].join(':');

        const end_yyyy_mm_dd = [end.substring(0,4), end.substring(4,6), end.substring(6,8)].join('-');
        const end_hh_mm_ss = [end.substring(8,10), end.substring(10,12), end.substring(12,14)].join(':');

        const start_date = moment(`${start_yyyy_mm_dd} ${start_hh_mm_ss}`).toDate();
        const end_date = moment(`${end_yyyy_mm_dd} ${end_hh_mm_ss}`).toDate();

        const period_ms = end_date.getTime() - start_date.getTime();

        // 시간 차이 60분(1시간) 미만
        if( period_ms < 60 * 60 * 1000 ) {
            return `${Math.floor(period_ms / 60 / 1000)}분`;
        }

        // 시간 차이 24시간(1일) 미만
        if( period_ms < 24 * 60 * 60 * 1000 ) {
            return `${Math.floor(period_ms / 60 / 60 / 1000)}시간`;
        }

        // 시간 차이 24시간(1일) 이상
        return `${Math.floor(period_ms / 24 / 60 / 60 / 1000)}일`;
    }

    /**
     * 닫기
     */
    close() : void {
        this.doSetHomeVisitantAmanoInfoModalInfo?.({
            show_modal : false,
            amano_info : null
        })
    }

}
