
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class Supply extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetSupplyManagementDetailModalInfo ?: (params : hodu_home_modal_info.SupplyManagementDetailModalInfo) => void;

    uid : string = "";
    supply : any = null;

    default_image = require("@/assets/images/contents/hoduhome_car_camera.svg");

    beforeMount() : void {
        this.uid = this.$route.params.uid;

        if( this.uid == null || this.uid.trim().length < 1 ) {
            this.hodu_show_dialog('cancel', '비품 조회에 필요한 데이터가 부족합니다', ['확인'], [() => { this.movePrevPage(); }])
        }
    }

    mounted() : void {
        this.getHomeSupply();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0);

        // @ts-ignore
        $('#supply_detail').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * 비품 조회
     */
    async getHomeSupply() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${this.uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.supply ) {
                throw new Error("비품 조회 중 오류 발생");
            }

            this.supply = JSON.parse(JSON.stringify(response.data.data.supply));

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 비품 삭제
     */
    async deleteHomeSupply() : Promise<void> {

        this.hodu_show_dialog('cancel', "비품을 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {

                try {

                    const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/${this.uid}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("비품 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_show_dialog('cancel', "비품 삭제 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
                
            }
        ]);

    }

    /**
     * 재고수 반환
     */
    getStock(managements, current_management) : number {

        const index = managements.indexOf(current_management);
        
        let stock : number = 0;
        let array_count : number = managements.length;

        for( let i = (array_count - 1); i >= index; i-- ) {
            const management = managements[i]
            if ( management.management_data.type == 'inbound' ) stock += management.management_data.count;
            if ( management.management_data.type == 'outbound' ) stock -= management.management_data.count;
        }

        return stock;
    }

    /**
     * 비품 수정
     */
    moveSupplyModify() : void {
        this.hodu_router_push(`/home/supply/modify/${this.uid}`);
    }

    /**
     * 비품 입·출고 내역 등록
     */
    moveSupplyManagementCreate(type) : void {
        this.hodu_router_push(`/home/supply/${this.uid}/management/${type}/create`);
    }

    /**
     * 비품 입·출고 내역 상세
     */
    showSupplyManagementDetail(seq) : void {
        this.doSetSupplyManagementDetailModalInfo?.({
            show_modal : true,
            uid : this.uid,
            seq : seq,
            callback : this.getHomeSupply
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#supply_detail').mCustomScrollbar("destroy");

        this.setScroll();
    }

}
