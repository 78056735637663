
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class HoduLimitTextArea extends Mixins(VueHoduCommon) {

    @Prop({ "default" : "" }) value !: string;
    @Prop({ "default" : -1 }) max !: number; // -1은 제한 없음
    @Prop() rows !: number;
    @Prop({ "default" : "" }) placeholder !: string;
    @Prop({ "default" : false }) only_number !: boolean;
    @Prop({ "default" : false }) is_currency !: boolean;
    @Prop({ "default" : false }) disable_enter !: boolean;

    get computedValue() {
        return this.value;
    }

    set computedValue(v) {
        this.$emit("input", v);
    }

    mounted() : void {
        
    }

    onKeyDown(event) {

        // 엔터 금지
        if ( this.disable_enter == true && event.keyCode == 13 ) {
            event.preventDefault();
            return;
        }

    }

    inputValue(event) {
        let value : string = event.target.value;

        // 빈 문자열이면 바로 아무 처리 안하고 종료
        if( value.length == 0 ) {
            this.computedValue = '';
            this.$forceUpdate();
            return;
        }

        // 글자 최대 길이
        if( this.max > -1 ) {
            value = value.slice(0, this.max);
        }

        // 숫자만 입력 가능
        if( this.only_number == true && this.is_currency == false ) {
            value = value.replace(/[^0-9]+/ig, "");
        }

        // 숫자 + 마이너스 입력 가능
        if( this.only_number == true && this.is_currency == true ) {
            value = value.replace(/[^-0-9]+/ig, "");
            value = this.number_price_format(value);
        }

        this.computedValue = value;

        this.$forceUpdate();
    }

}
