
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
import { EventImagePreviewInfo } from '@/store/modules/EventInfo';
import { t_event_file } from '@/model/event';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver, Hooper, Slide
    },
}) export default class EventImagePreview extends Mixins(VueHoduCommon) {
    
    // this.$refs.hooperSlide 사용
    $refs !: { hooperSlide : HTMLFormElement; }

    /**
     * @EventInfo.State
     */
    @EventInfo.State  event_image_preview_info   !: EventImagePreviewInfo;
    
    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventImagePreview ?: any;

    view_index : number = 0;
    files : t_event_file[] = [];

    beforeMount() : void {
        this.view_index = this.event_image_preview_info.selected_index == null ? 0 : this.event_image_preview_info.selected_index;
        this.files = this.event_image_preview_info.files == null ? [] : this.event_image_preview_info.files;
    }

    mounted() {
    
    }

    /**
     * 이미지 선택
     */
    selectImage(new_index : number) : void {
        this.$refs.hooperSlide.slideTo(new_index);
    }

    /**
     * 이미지 미리보기 닫기
     */
    imagePreviewModalClose() : void {
        this.doSetShowEventImagePreview(false);
    }

    /**
     * 이미지 로드 완료
     */
    imageLoadComplete(index : number) : void {
        this.setImageCss(index);
    }

    /**
     * 너비, 높이 css 반환
     */
    setImageCss(index : number) : void {
        
        const img_id : string = `slideImage_${index}`;
        const img : JQuery<HTMLElement> = $(`#${img_id}`);

        let image_original_width  : number | undefined = img.data("image_original_width");
        let image_original_height : number | undefined = img.data("image_original_height");

        if( !image_original_width || image_original_width < 1 || !image_original_height || image_original_height < 1 ) {
            image_original_width  = img.width();
            image_original_height = img.height();

            if( !image_original_width || !image_original_height ) { return; }

            img.data("image_original_width" , image_original_width  ? image_original_width  : 0);
            img.data("image_original_height", image_original_height ? image_original_height : 0);
        }

        const image_max_width : number = 1000;
        const image_max_height : number = window.innerHeight - 100 - 20; // 100px는 하단 영역, 20px는 기본 마진

        let resized_image_width : number = image_original_width; 
        let resized_image_height : number = image_original_height;
        let image_ratio : number = (image_original_width / image_original_height);

        // height가 image_max_height 보다 높은 경우 max_height로 줄이고 width를 비율에 맞게 변경한다
        if( resized_image_height > image_max_height ) {
            resized_image_height = image_max_height;
            resized_image_width  = resized_image_height * image_ratio;
        }
        
        // width가 image_max_width 보다 높은 경우 max_width로 줄이고 height를 비율에 맞게 변경한다
        if( resized_image_width > image_max_width ) {
            resized_image_width  = image_max_width;
            resized_image_height = resized_image_width / image_ratio;
        }

        img.width(resized_image_width);
        img.height(resized_image_height);

        // 높이가 image_max_height 보다 작다면 가운데를 맞춰준다
        if( image_max_height > resized_image_height ) {
            const image_top_margin : number = (((image_max_height) - resized_image_height) / 2) + 10;
            img.css("margin-top", `${image_top_margin}px`);
        }

        else {
            img.css("margin-top", "10px");
        }

        img.show();
    }

    /**
     * 현재 보고있는 인덱스로 세팅
     */
    updateViewIndex(payload : any) : void {
        this.view_index = payload.currentSlide;
    }

    /**
     * 이전
     */
    clickImagePrev() : void {
        this.$refs.hooperSlide.slidePrev();
    }

    /**
     * 이후
     */
    clickImageNext() : void {
        this.$refs.hooperSlide.slideNext();
    }

    /**
     * 댓글 이미지 삭제
     */
    async imageDelete() : Promise<void> {
        if( !this.event_image_preview_info.reply_delete_function ) { return;  }

        if( confirm("사진을 삭제 하시겠습니까?\n묶음 사진은 전부 같이 삭제 됩니다") == true ) {
            await this.event_image_preview_info.reply_delete_function(
                this.event_image_preview_info.js_event,
                this.event_image_preview_info.reply,
                this.event_image_preview_info.dayIndex,
                this.event_image_preview_info.reply_index
            );
            this.imagePreviewModalClose();            
        } 
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        const file_count : number = this.files.length;
        for( let i = 0; i < file_count; i++ ) { this.setImageCss(i); }
    }
}
