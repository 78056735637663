/**
 * Interface
 */
export namespace attendance_interface {

}

/**
 * Modal Store Interface
 */
export namespace attendance_modal_interface {

    export interface AttendanceRequestModalInfo {
        show_modal : boolean,
        user_id : number,
        yyyymmdd : string,
        callback ?: Function
    }

}

/**
 * Enum
 */
export namespace attendance_enum {

    export enum ATTENDANCE_CALENDAR_TYPE {
        TOTAL_LIST_YEAR = "TOTAL_LIST_YEAR", // 직원 근태 현황 - 연별 통합 리스트
        TOTAL_LIST_MONTH = "TOTAL_LIST_MONTH", // 직원 근태 현황 - 월별 통합 리스트
        DAYGRID_MONTH = "DAYGRID_MONTH", // 직원 근태 현황 - 월별달력
        LIST_MONTH = "LIST_MONTH", // 직원 근태 현황 - 월별리스트
        LIST_WEEK = "LIST_WEEK", // 직원 근태 현황 - 주별리스트
        LIST_DAY = "LIST_DAY", // 직원 근태 현황 - 일별리스트
        CALENDAR = "CALENDAR", // 내 근태 현황 - 달력
        LIST = "LIST" // 내 근태 현황 - 리스트
    }

    export enum ATTENDANCE_LIST_DAY_SORT_TYPE {
        USER_NAME = "USER_NAME",
        DEPARTMENT_NAME = "DEPARTMENT_NAME",
        START = "START",
        END = "END",
    }

}