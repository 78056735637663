
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { t_event_file } from '@/model/event';
import { namespace } from 'vuex-class';
const ModalInfo  = namespace('ModalInfo');
const GroupInfo  = namespace('GroupInfo');

import { GroupAddFileModalNewInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

import moment from 'moment';
import { hodu_color } from '@/common/color';
const dateFormat = require('dateformat');

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupFileList extends Mixins(VueHoduCommon) {
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowGroupAddFileBox      ?: any;   
    @ModalInfo.Action doSetGroupAddFileModalInfo    ?: any;
    @ModalInfo.Action doSetGroupAddFileModalNewInfo ?: (params : GroupAddFileModalNewInfo) => void;
    
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * 변수 선언부
     */
    selected_dir          : any     = null;
    arr_dir               : any     = [];
    arr_files             : any     = [];
    original_files        : any     = [];
    dir_uuid              : string  = "";
    header_file_dir_name  : string  = "";
    header_file_count     : number  = 0;
    header_file_dir_color : string  = "";
    header_file_dir_icon  : number  = 0;
    is_dir_show           : boolean = false;
    is_file_drag          : boolean = false;
    has_password          : boolean = false;

    file_sort_target : string = 'TIME';
    file_sort_direction : string = "DESC";

    is_open_dir_list : boolean = false;

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() : Promise<void> {
        
        const vue = this;

        // 프리미엄 그룹 아이디가 없다면
        if ( !this.scope_group_team_option.biz_id ) {
            this.movePrevPage();
            return;
        }

        // 그룹 파일함 읽기 권한
        if ( !this.is_group_permmision(this.group_id, 'file', 'read') ) {
            this.movePrevPage();
            return;
        }

        // this.setScroll();

        // var file_drag = false;

        // 왼쪽 파일함 선택 시
        // $(".folderUl li a.goDets").click(function(){
        //     $(this).parent().siblings().removeClass("on");
        //     $(this).parent().addClass("on");

        // });

        // $(".folderMenu").click(function(){
        //     $(".section_ce_fix").toggleClass("dirOn");

        // });

        // 파일을 드래그하고 해당 영역위에 올렸을때 발생하는 이벤트
        // $('body').on('dragover', function(e){
        //     e.stopPropagation();
        //     e.preventDefault();
        //     $(".bg4file").css('display','block');
            
        //     file_drag = true;
        // });

        // 파일을 드래그하고 해당 영역을 떠났을때 발생하는 이벤트
        // $('.bg4file').on('dragleave', function(e){
        //     if(file_drag){
        //         $(".bg4file").css('display','none');
        //     }

        //     file_drag = false;
        // });

        // 이미지 파일 Drag & Drop 이벤트
        // $('.bg4file').on('drop', function (e) {
        //     e.stopPropagation();
        //     e.preventDefault();

        //     file_drag = false;

        //     $(".bg4file").css('display','none');
        //     $('#newFileModalBtn').trigger('click');
        // });

        /*
        // 블락킹 bg 클릭시 on빼기
        $(".blckng").click(function(){
			$(".selectTitle.box").removeClass("on");
			$(".dropdown.box").removeClass("on");
			$(".blckng").removeClass("on");
        });
        */

        // 기본파일함(h3.selectTitle.box) 선택시 
        /*
        $(".selectTitle.box").click(function(){
            $(this).addClass("on");
            $(".dropdown.box").addClass("on");
            $(".blckng").addClass("on");
        });
        */

       this.dir_uuid = this.$route.params.dir_id;

        // 파일함 조회
        await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
        await this.fileSort()
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();
        const getHeight        : number = (titleHeight ? titleHeight : 0) + (settingBgHeight ? settingBgHeight : 0) + (sortHeaderHeight ? sortHeaderHeight : 0);
        const finalHeight      : number | undefined = window.outerHeight - getHeight;
        const finalHeight2     : number | undefined = window.innerHeight - 186;

        // @ts-ignore
        $('.content_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : finalHeight2,
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
        });

        // @ts-ignore
        $('#dir_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0),
        });
    }
    
    async getFileDir() : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.group_id}/file/${this.dir_uuid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dir ) {
                throw Error("파일함 조회 중 오류 발생");
            }

            this.selected_dir = JSON.parse(JSON.stringify(response.data.data.dir));
            
            this.header_file_dir_name = this.selected_dir.dir_name;
            this.header_file_dir_color = this.selected_dir.dir_color;
            this.header_file_dir_icon = this.selected_dir.dir_icon;
            this.has_password = this.selected_dir.has_password;

            this.arr_files.splice(0, this.arr_files.length);
            this.arr_files = this.arr_files.concat(this.selected_dir.files);

            this.original_files = JSON.parse(JSON.stringify(this.arr_files));

            if ( this.is_dir_show ) {
                this.is_dir_show = false;
            }

            this.handleResize();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', '파일함이 존재하지 않습니다', ['확인'], [() => { this.movePrevPage(); }]);
        }

    }

    /**
     * 파일함 조회
     */
    async getGroupFiles() : Promise<void> {

        await this.hodu_api_call(`api/v1/groupsweb/getGroupFiles/${this.group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null)
            .then(async(response) => {

                this.arr_dir.splice(0, this.arr_dir.length);
                this.arr_dir = this.arr_dir.concat(response.data.data.files);

                if( this.arr_dir.length < 1 ) {
                    this.hodu_show_dialog('cancel', '먼저 파일함을 생성해주세요', ['확인'], [() => { this.movePrevPage(); }]);
                    return;
                }

                // this.header_file_count = this.arr_dir.length;
                // this.header_file_dir_name = "";

                // if ( this.dir_uuid == "" ) {
                //     if( this.header_file_count > 0 ) { this.dirChange(this.arr_dir[0].dir_uuid); }
                // }
                // else {
                //     let lb_isFlag = false;

                //     for (let i = 0; i < this.arr_dir.length; i++) {
                //         if ( this.dir_uuid == this.arr_dir[i].dir_uuid ) {
                //             lb_isFlag = true;
                //             this.dirChange(this.arr_dir[i].dir_uuid);
                //             break;
                //         }
                //     }

                //     if ( !lb_isFlag ) {
                //         if( this.header_file_count > 0 ) { this.dirChange(this.arr_dir[0].dir_uuid); }
                //     }
                // }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 파일함 추가 모달
     */
    showGroupAddFileBox() : void {


        if( !this.doSetGroupAddFileModalNewInfo ) { return; }

        this.doSetGroupAddFileModalNewInfo({
            show_modal : true,
            is_create: true,
            dir_uuid: "",
            group_id: this.scope_group_id,
            up_dir_uuid: this.selected_dir.dir_uuid,
            create_function: async() => { 
                await Promise.all([await this.getFileDir(), await this.getGroupFiles()]); 
                await this.fileSort();
            }
        });
    }

    /**
     * 파일 추가
     */
    async addFile(event) : Promise<void> {
        if ( this.dir_uuid == "" ) {
            $('#addFile').val('');
            this.hodu_show_dialog("alert", "파일함을 생성해주세요", ['확인']);
            return;
        }

        const files : File[] = event.target.files;

        if ( files.length < 1 ) {
            $('#addFile').val('');
            return;
        }

        const files_length : number   = files.length;
        const form_data    : FormData = new FormData();

        for ( let i = 0; i < files_length; i++ ) {
            if( files[i].size > this.DEFAULT_FILE_MAX_SIZE ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", `${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`, ['확인']);
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", "확장자가 있는 파일만 업로드 가능 합니다", ['확인']);
                return;
            } 

            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", `${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`, ['확인']);
                return;
            }

            let file_data = {
                name: files[i].name,
                mimeType: files[i].type,
                url: '',
                size: files[i].size,
                date: new Date(),
            }

            form_data.append('file', files[i]);
        }

        await this.hodu_temp_upload(form_data)
            .then(async(response) => {
                const temp_files : t_event_file[] = response.data.data.temp_files;
                await this.groupFileSave(temp_files, false);
            })
            .catch(async(e) => {
                $('#addFile').val('');
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 파일 저장
     */
    async groupFileSave(po_files : t_event_file[], pb_overLap : boolean) : Promise<void> {
        const vue = this;
        let param_data = {file: po_files}
        
        await this.hodu_api_call(`api/v1/groupsweb/setGroupFile/${this.group_id}/${this.scope_group_team_option.biz_id}/${this.dir_uuid}/${pb_overLap}`, API_METHOD.POST, param_data)
            .then(async(response) => {
                if ( response.data.data.overlap_files.length < 1 ) {
                    await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
                    await this.fileSort();
                }
                else {
                    this.overlapFileSave(response.data.data.overlap_files);
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        $('#addFile').val('');
    }

    /**
     * 중복된 파일에 한해서 파일 저장
     */
    async overlapFileSave(po_files : t_event_file[]) : Promise<void> {
        if ( po_files.length < 1 ) {
            await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
            await this.fileSort();
            return;
        }

        let upload_files : t_event_file[] = [];

        for ( let i = 0; i < po_files.length; i++ ) {
            /*
            this.hodu_show_dialog("alert", po_files[i].name + " 중복된 파일입니다. \n\n파일을 덮어씌우시겠습니까?", ['아니오', '예'], [
                () => {},
                () => {
                    upload_files.push(po_files[i]);
                },
            ]);
            */

            if ( confirm(po_files[i].name + "\n동일한 파일명이 존재합니다.\n파일을 덮어씌우시겠습니까?") ) {
                upload_files.push(po_files[i]);
            }
        }

        if ( upload_files.length > 0 ) {
            this.groupFileSave(upload_files, true);
        }
        else {
            await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
            await this.fileSort();
        }
    }

    /**
     * BYTE TO SIZE
     */
    byteToSize(byte, decimals = 2) : string {
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];        
    }

    /**
     * 날짜 시간 변환
     */
    dateToString(date, format) : string {
        let returnVal = dateFormat(date, format);
        if ( format == "HH:MM" ) {
            const hour : number = Number(returnVal.substring(0,2));
            const min  : string = returnVal.substring(3,5);
            if( hour > 12 ) {
                returnVal = `오후 ${ `0${hour - 12}`.slice(-2) }:${min}`;
            }

            else if( hour == 12 ) {
                returnVal = `오후 12:${min}`;
            }

            else if( hour == 0 ) {
                returnVal = `오전 12:${min}`;
            }

            else {
                returnVal = `오전 ${ `0${hour}`.slice(-2) }:${min}`;
            }
        }

        return returnVal;
    }

    /**
     * 확장자 뽑기
     */
    getExtensionOfFilename(filename) : string {
        var _fileLen = filename.length;
        var _lastDot = filename.lastIndexOf('.');
    
        // 확장자 명만 추출한 후 소문자로 변경
        var _fileExt = filename.substring(_lastDot, _fileLen).toLowerCase();
 
        if ( _fileExt == ".png" || _fileExt == ".jpg" || _fileExt == ".jpeg" || _fileExt == ".bmp" || _fileExt == ".gif" ) {
            return "img";
        }
        else {
            return _fileExt.replace(".", "");
        }
    }

    /**
     * 폴더 변경
     */
    dirChange(p_dir_uuid : string, is_click : boolean = false) : void {
        
        for (let i = 0; i < this.arr_dir.length; i++) {
            let lo_data = this.arr_dir[i];

            if ( p_dir_uuid == lo_data.dir_uuid ) {

                if( this.dir_uuid != lo_data.dir_uuid && lo_data.has_password == true && is_click == true ) {
                    this.password_input(p_dir_uuid);
                    return;
                }

                this.selected_dir = JSON.parse(JSON.stringify(lo_data));

                this.dir_uuid = p_dir_uuid;
                this.header_file_dir_name = lo_data.dir_name;
                this.header_file_dir_color = lo_data.dir_color;
                this.header_file_dir_icon = lo_data.dir_icon;
                this.has_password = lo_data.has_password;

                if ( lo_data.files.length < 1 ) {
                    this.arr_files = [];
                }
                else {
                    this.arr_files = lo_data.files;
                }

                this.original_files = JSON.parse(JSON.stringify(this.arr_files));
            }
        }

        if ( this.is_dir_show ) {
            this.is_dir_show = false;
        }

        //$(".blckng").removeClass("on");
        //$(".selectTitle.box").removeClass("on");
        //$(".dropdown.box").removeClass("on");

        this.handleResize();
    }

    /**
     * 폴더함 선택
     */
    setionChange() : void {
        this.is_dir_show = !this.is_dir_show;
    }

    moreBtnClick(pi_index) : void {
        $('#file_list_li_' + pi_index).addClass("open");
    }

    moreBtnClose(pi_index) : void {
        $('#file_list_li_' + pi_index).removeClass("open");
    }

    moreBtnFileDelete(pi_index, ps_file_uuid) : void {
        $('#file_list_li_' + pi_index).removeClass("open");

        this.hodu_api_call(`api/v1/groupsweb/deleteGroupFile/${ps_file_uuid}/${this.scope_group_team_option.biz_id}`, API_METHOD.DELETE, {})
            .then(async(response) => {
                await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
                await this.fileSort();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });       
    }

    fileDownLoad(ps_path, ps_file_name) : void { 
        this.hodu_download(`/app_images/group_file/${ps_path}`, ps_file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 파일 들고 들어 온 경우
     */
    fileDragOver(event : any) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.is_file_drag = true;
    }
    
    /**
     * 파일 나간 경우
     */
    fileDragLeave(event : any) : void {
        this.is_file_drag = false;
    }
    
    /**
     * 파일 드랍
     */
    fileDrop(event : any) : void {
        this.is_file_drag = false;
        event.target.files = event.dataTransfer.files;
        this.addFile(event);
    }

    /**
     * 파일 이름 기준 정렬
     */
    fileNameSort() : void {
        // 이미 FILE_NAME Sort 중이라면
        if( this.file_sort_target == "FILE_NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.file_sort_direction ) {
                case "ASC":
                    this.file_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.file_sort_target = 'ASC';
                    break; 
            }
        }
        
        // FILE_NAME Sort가 아니였다면
        else {
            this.file_sort_target = "FILE_NAME";
            this.file_sort_direction = "ASC";
        }

        this.fileSort();
    }

    /**
     * 파일 용량 기준 정렬
     */
    sizeSort() : void {
        // 이미 SIZE Sort 중이라면
        if( this.file_sort_target == "SIZE" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.file_sort_direction ) {
                case "ASC":
                    this.file_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.file_sort_target = 'ASC';
                    break; 
            }
        }
        
        // SIZE Sort가 아니였다면
        else {
            this.file_sort_target = "SIZE";
            this.file_sort_direction = "ASC";
        }

        this.fileSort();
    }

    /**
     * 등록한 유저 이름 기준 정렬
     */
    userNameSort() : void {
        // 이미 USER_NAME Sort 중이라면
        if( this.file_sort_target == "USER_NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.file_sort_direction ) {
                case "ASC":
                    this.file_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.file_sort_target = 'ASC';
                    break; 
            }
        }
        
        // USER_NAME Sort가 아니였다면
        else {
            this.file_sort_target = "USER_NAME";
            this.file_sort_direction = "ASC";
        }

        this.fileSort();
    }

    /**
     * 등록일 기준 정렬
     */
    timeSort() : void {
        // 이미 TIME Sort 중이라면
        if( this.file_sort_target == "TIME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.file_sort_direction ) {
                case "ASC":
                    this.file_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.file_sort_target = "ASC";
                    break; 
            }
        }
        
        // TIME Sort가 아니였다면
        else {
            this.file_sort_target = "TIME";
            this.file_sort_direction = "ASC";
        }
        
        this.fileSort();
    }

    /**
     * 파일 정렬
     */
    fileSort() : void {

        this.selected_dir.dirs.sort((o1 : any, o2 : any) : number => {
            // 파일 이름 오름차순
            if( this.file_sort_target == "FILE_NAME" && this.file_sort_direction == "ASC" ) {
                if( o1.dir_name  > o2.dir_name ) { return  1; } 
                if( o1.dir_name  < o2.dir_name ) { return -1; } 
                if( o1.dir_name == o2.dir_name ) { return  0; }
            }

            // 파일 이름 내림차순
            if( this.file_sort_target == "FILE_NAME" && this.file_sort_direction == "DESC" ) {
                if( o1.dir_name  > o2.dir_name ) { return -1; } 
                if( o1.dir_name  < o2.dir_name ) { return  1; } 
                if( o1.dir_name == o2.file_name ) { return  0; }
            }

            const o1_file_size = this.dirFileSizeNumber(o1);
            const o2_file_size = this.dirFileSizeNumber(o2);

            // 파일 용량 오름차순
            if( this.file_sort_target == "SIZE" && this.file_sort_direction == "ASC" ) {
                if( o1_file_size  > o2_file_size ) { return  1; } 
                if( o1_file_size  < o2_file_size ) { return -1; } 
                if( o1_file_size == o2_file_size ) { return  0; }
            }

            // 파일 용량 내림차순
            if( this.file_sort_target == "SIZE" && this.file_sort_direction == "DESC" ) {
                if( o1_file_size  > o2_file_size ) { return -1; } 
                if( o1_file_size  < o2_file_size ) { return  1; } 
                if( o1_file_size == o2_file_size ) { return  0; }
            }

            // 유저 이름 오름차순
            if( this.file_sort_target == "USER_NAME" && this.file_sort_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 유저 이름 내림차순
            if( this.file_sort_target == "USER_NAME" && this.file_sort_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 업데이트 시간 오름차순
            if( this.file_sort_target == "TIME" && this.file_sort_direction == "ASC" ) {
                if( new Date(moment(o1.audit_modified).format()).getTime()  > new Date(moment(o2.audit_modified).format()).getTime() ) { return  1; } 
                if( new Date(moment(o1.audit_modified).format()).getTime()  < new Date(moment(o2.audit_modified).format()).getTime() ) { return -1; } 
                if( new Date(moment(o1.audit_modified).format()).getTime() == new Date(moment(o2.audit_modified).format()).getTime() ) { return  0; }
            }

            // 업데이트 시간 내림차순
            if( this.file_sort_target == "TIME" && this.file_sort_direction == "DESC" ) {
                if( new Date(moment(o1.audit_modified).format()).getTime()  > new Date(moment(o2.audit_modified).format()).getTime() ) { return -1; } 
                if( new Date(moment(o1.audit_modified).format()).getTime()  < new Date(moment(o2.audit_modified).format()).getTime() ) { return  1; } 
                if( new Date(moment(o1.audit_modified).format()).getTime() == new Date(moment(o2.audit_modified).format()).getTime() ) { return  0; }
            }

            return 0;
        });

        this.arr_files.sort((o1 : any, o2 : any) : number => {
            // 파일 이름 오름차순
            if( this.file_sort_target == "FILE_NAME" && this.file_sort_direction == "ASC" ) {
                if( o1.file_name  > o2.file_name ) { return  1; } 
                if( o1.file_name  < o2.file_name ) { return -1; } 
                if( o1.file_name == o2.file_name ) { return  0; }
            }

            // 파일 이름 내림차순
            if( this.file_sort_target == "FILE_NAME" && this.file_sort_direction == "DESC" ) {
                if( o1.file_name  > o2.file_name ) { return -1; } 
                if( o1.file_name  < o2.file_name ) { return  1; } 
                if( o1.file_name == o2.file_name ) { return  0; }
            }

            // 파일 용량 오름차순
            if( this.file_sort_target == "SIZE" && this.file_sort_direction == "ASC" ) {
                if( o1.file_size  > o2.file_size ) { return  1; } 
                if( o1.file_size  < o2.file_size ) { return -1; } 
                if( o1.file_size == o2.file_size ) { return  0; }
            }

            // 파일 용량 내림차순
            if( this.file_sort_target == "SIZE" && this.file_sort_direction == "DESC" ) {
                if( o1.file_size  > o2.file_size ) { return -1; } 
                if( o1.file_size  < o2.file_size ) { return  1; } 
                if( o1.file_size == o2.file_size ) { return  0; }
            }

            // 유저 이름 오름차순
            if( this.file_sort_target == "USER_NAME" && this.file_sort_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 유저 이름 내림차순
            if( this.file_sort_target == "USER_NAME" && this.file_sort_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 업데이트 시간 오름차순
            if( this.file_sort_target == "TIME" && this.file_sort_direction == "ASC" ) {
                if( new Date(moment(o1.audit_created).format()).getTime()  > new Date(moment(o2.audit_created).format()).getTime() ) { return  1; } 
                if( new Date(moment(o1.audit_created).format()).getTime()  < new Date(moment(o2.audit_created).format()).getTime() ) { return -1; } 
                if( new Date(moment(o1.audit_created).format()).getTime() == new Date(moment(o2.audit_created).format()).getTime() ) { return  0; }
            }

            // 업데이트 시간 내림차순
            if( this.file_sort_target == "TIME" && this.file_sort_direction == "DESC" ) {
                if( new Date(moment(o1.audit_created).format()).getTime()  > new Date(moment(o2.audit_created).format()).getTime() ) { return -1; } 
                if( new Date(moment(o1.audit_created).format()).getTime()  < new Date(moment(o2.audit_created).format()).getTime() ) { return  1; } 
                if( new Date(moment(o1.audit_created).format()).getTime() == new Date(moment(o2.audit_created).format()).getTime() ) { return  0; }
            }

            return 0;
        });
    }

    /**
     * 디렉토리 수정
     */
    modifyDir(event, dir ?: any) : void {

        if( dir == null && (this.selected_dir == null || this.selected_dir.audit_user_id != this.user_id) ) return; 
        else if( dir != null && dir.audit_user_id != this.user_id ) return; 

        this.doSetGroupAddFileModalNewInfo?.({
            show_modal : true,
            is_create: false,
            dir_uuid: dir != null ? dir.dir_uuid : this.dir_uuid,
            group_id: this.scope_group_id,
            // dir_name: this.header_file_dir_name,
            // dir_color: this.header_file_dir_color,
            // dir_icon: this.header_file_dir_icon,
            update_function: async(is_delete : boolean, dir_uuid : string) => {
                
                if( is_delete && dir_uuid == this.$route.params.dir_id ) { 
                    this.dir_uuid = ""; 
                }

                if( is_delete && dir_uuid != this.$route.params.dir_id ) { 
                    this.dir_uuid = this.$route.params.dir_id; 
                }

                await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
                await this.fileSort();
            }
        });

    }

    delete_password(dir) {

        const uuid = dir.dir_uuid;
        
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }

                    const delete_response = await this.hodu_api_call(`api/v1/groups/${dir.group_id}/file/${uuid}/password`, API_METHOD.DELETE);

                    console.log(delete_response);

                    if( !delete_response || !this.isHttpStatusSuccess(delete_response.status) ) {
                        throw new Error("파일함 비밀번호 삭제 중 오류 발생");
                    }
                    
                    this.getGroupFiles();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });

    }

    /**
     * 디렉토리 리스트 ON / OFF
     */
    dirListOpenOnOff() : void {
        this.is_open_dir_list = !this.is_open_dir_list;
    }

    /**
     * 디렉토리 비밀번호 입력
     */
    password_input(uuid : string) {

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {
               
                // 패스워드 체크
                try {
                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }

                    for (let i = 0; i < this.arr_dir.length; i++) {
                        let lo_data = this.arr_dir[i];

                        if ( uuid == lo_data.dir_uuid ) {

                            this.dir_uuid = uuid;
                            this.header_file_dir_name = lo_data.dir_name;
                            this.header_file_dir_color = lo_data.dir_color;
                            this.header_file_dir_icon = lo_data.dir_icon;
                            this.has_password = lo_data.has_password;

                            if ( lo_data.files.length < 1 ) {
                                this.arr_files = [];
                            }
                            else {
                                this.arr_files = lo_data.files;
                            }

                            this.original_files = JSON.parse(JSON.stringify(this.arr_files));
                        }
                    }

                    if ( this.is_dir_show ) {
                        this.is_dir_show = false;
                    }

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                } finally {
                    this.handleResize();
                }

            },
        });
    }

    /**
     * 파일보관함 리스트로 이동
     */
     goGroupFileList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        
        this.doGroupId(this.scope_group_id);
        this.doTeamId(this.scope_team_id);
        this.doGroupTeamOption(JSON.parse(JSON.stringify(this.scope_group_team_option)));
        this.hodu_router_push(`/group/${new Date().getTime()}/file`);
    }

    dirFileSize(dir) {

        let byte = 0;

        for( const path_file of dir.path_files ) byte += path_file.file_size;
          
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = 2;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]; 
    }

    dirFileSizeNumber(dir) {
        let byte = 0;
        for( const path_file of dir.path_files ) byte += path_file.file_size;
        return byte; 
    }

    async moveFileDir(dir) {

        if( this.dir_uuid == dir.dir_uuid ) {
            await Promise.all([await this.getFileDir(), await this.getGroupFiles()]);
            await this.fileSort();
            return;
        }

        // 비밀번호를 가진 하위 폴더와 경로가 완전히 다른 폴더는 비밀번호 체크 후 진입
        if( dir.has_password == true && this.selected_dir.route_file_dirs.filter(di => di.dir_uuid == dir.dir_uuid).length < 1 ) {

            this.doSetCommonInputModalInfo?.({
                show_modal : true,
                title : "비밀번호 입력",
                subtitle : "비밀번호",
                placeholder : "비밀번호를 입력해주세요",
                is_password : true,
                save_text : "확인",
                cancel : () => {

                },
                save : async(password) => {

                    try {

                        const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${dir.dir_uuid}/password`, API_METHOD.POST, {
                            "password" : password 
                        });

                        if( !response || !this.isHttpStatusSuccess(response.status) ) {
                            throw new Error("파일함 비밀번호 체크 중 오류 발생");
                        }
                        
                        this.hodu_router_push(`/group/${new Date().getTime()}/file/${dir.dir_uuid}`);

                    } catch(e) {
                        this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                    }

                },
            });

        }
        else {
            this.hodu_router_push(`/group/${new Date().getTime()}/file/${dir.dir_uuid}`);
        }

    }

    menu_open(target_dir) {

        if( this.selected_dir == null ) return;

        for( const dir of this.selected_dir.dirs ) {
            if( dir.dir_uuid == target_dir.dir_uuid ) continue;
            dir.open_menu = false;
        }

        if( target_dir.open_menu == null ) {
            target_dir.open_menu = true;
        }
        else {
            target_dir.open_menu = !target_dir.open_menu;
        }
        this.$forceUpdate();
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $(".content_scroll").mCustomScrollbar('destroy');

        // @ts-ignore
        $('#dir_scroll').mCustomScrollbar('destroy');

    	this.$nextTick(() => this.setScroll());
    }
    
}
