
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupFileboxMoveModalInfo } from '@/store/modules/ModalInfo';

import GroupFileBoxRecursion2 from '@/components/GroupFileBoxRecursion2.vue';

@Component({
    components: {
        GroupFileBoxRecursion2
    },
})
export default class GroupFileboxMoveModal extends Mixins(VueHoduCommon) {

    /**
     * 파일함 디렉토리 트리
     */
    get computedDirTree() : any {

        let copy_dirs = JSON.parse(JSON.stringify(this.all_dirs));

        copy_dirs.sort((o1, o2) => {

            const is_fave = o1.is_favorite;
            const is_fave2 = o2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            const o1_dir_name = o1.dir_name;
            const o2_dir_name = o2.dir_name;

            if( o1_dir_name < o2_dir_name ) return -1;
            else if( o1_dir_name > o2_dir_name ) return 1;

            return 0;
        });

        let max_level = 0;
        for( const dir of copy_dirs ) {
            if( dir.dir_path.length > max_level ) max_level = dir.dir_path.length;
            dir['dirs'] = [];
            dir['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.dir_closed_map.get(dir.dir_uuid);
            if( is_closed != null ) {
                dir['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_dirs = copy_dirs.filter(item => item.dir_path.length == search_level);
            const next_search_dirs = copy_dirs.filter(item => item.dir_path.length == search_next_level); 

            for( const next_search_dir of next_search_dirs ) {
                const next_search_dir_uuid = next_search_dir.dir_uuid;
                next_search_dir.level = search_next_level;

                for( const current_search_dir of current_search_dirs ) {
                    const current_search_dir_uuid = current_search_dir.dir_uuid;
                    current_search_dir.level = search_level;

                    if( current_search_dir.dir_path.indexOf(next_search_dir_uuid) > -1 ) {
                        next_search_dir.dirs.push(current_search_dir);
                    }

                }
            }
            
            search_level--;
        }

        const top_dirs = copy_dirs.filter(item => item.dir_path.length == 1);
        if( top_dirs == null || top_dirs.length < 1 ) return null;

        return top_dirs;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_filebox_move_modal_info !: GroupFileboxMoveModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupFileboxMoveModalInfo ?: (params : GroupFileboxMoveModalInfo) => void;

    all_dirs : any[] = [];
    dir_closed_map : Map<string, boolean> = new Map();
    dir_uuid : string | null = null;

    mounted() : void {
        // 왼쪽 메뉴 dropdown2
        $(".tree_has_child > .subMore").click(function(){
            $(this).parent().siblings().toggleClass("open");
            $(this).parent().siblings().siblings().toggleClass("open");
            $(this).toggleClass("open");
        });

        this.getGroupFiles();
    }

    /**
     * 그룹 파일함 전체 조회
     */
    async getGroupFiles() : Promise<void> {
        await this.hodu_api_call(`api/v1/groupsweb/getGroupFiles/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                
                this.all_dirs.splice(0, this.all_dirs.length);
                this.all_dirs = this.all_dirs.concat(response.data.data.all_dirs);

            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    selectFileBox(dir_uuid) {
        this.dir_uuid = dir_uuid;
    }

    toggleDir(dir, complete_password_input : boolean = false) {


        // if( dir.is_closed == true && dir.has_password == true && complete_password_input == false ) {
        //     this.password_input(dir);
        //     return;
        // }

        dir.is_closed = !dir.is_closed;
        this.$forceUpdate();
        this.dir_closed_map.set(dir.dir_uuid, dir.is_closed);

    }

    /**
     * 디렉토리 비밀번호 입력
     */
    // password_input(dir : any) {

    //     let uuid : string = dir.dir_uuid;

    //     this.doSetCommonInputModalInfo?.({
    //         show_modal : true,
    //         title : "비밀번호 입력",
    //         subtitle : "비밀번호",
    //         placeholder : "비밀번호를 입력해주세요",
    //         is_password : true,
    //         save_text : "확인",
    //         cancel : () => {

    //         },
    //         save : async(password) => {

    //             try {

    //                 const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
    //                     "password" : password 
    //                 });

    //                 if( !response || !this.isHttpStatusSuccess(response.status) ) {
    //                     throw new Error("파일함 비밀번호 체크 중 오류 발생");
    //                 }
                    
    //                 this.toggleDir(dir, true);

    //             } catch(e) {
    //                 this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
    //             }

    //         },
    //     });
    // }

    async save() {

        try {

            if( this.group_filebox_move_modal_info.type == 'FILE' && (this.dir_uuid == null || this.dir_uuid.length < 1) ) {
                alert("이동할 파일함을 선택해주세요")
                return;
            }

            if( this.group_filebox_move_modal_info.type == 'DIR' && this.dir_uuid == null ) {
                alert("이동할 파일함을 선택해주세요")
                return;
            }

            let response : any = null;

            switch( this.group_filebox_move_modal_info.type ) {
                case 'DIR':
                    response = await this.hodu_api_call(`api/v1/groupsweb/moveGroupFileDir/${this.group_filebox_move_modal_info.dir_uuid}?dest_dir_uuid=${this.dir_uuid}`, API_METHOD.PUT);    
                    break;

                case 'FILE':
                    response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/file/${this.group_filebox_move_modal_info.file_uuid}/${this.dir_uuid}`, API_METHOD.PUT);
                    break;
            }

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("이동 중 오류 발생");
            }

            this.group_filebox_move_modal_info.callback?.();
            this.close();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("이동 중 오류 발생");
        }

    }

    close() {
        this.doSetGroupFileboxMoveModalInfo?.({
            show_modal : false,
            type : "",
        });
    }

}
