
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {

    }
})
export default class GroupToBizModal extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowGroupToBizModal ?: any;

    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;

    is_create_mode : boolean = false;

    name : string = "";
    tel : string = "";
    email : string = "";

    mounted() : void {
        $(".pre_ul .pre_txt").click(function(){
            $(this).parent().siblings(".div_2").addClass("on");
        });
        $(".pre_ul li .div_2").mouseleave(function(){
            $(this).removeClass("on");
        });

        this.tel = this.user_phone_number;
    }

    /**
     * Modal 닫기
     */
    closeModal() : void {
        window['groupChangeService'] = null;
        this.doSetShowGroupToBizModal(false);
    }

    /**
     * 그룹 비즈로 전환
     */
    groupChangeService() : void {
        this.is_create_mode = true;
    }

    /**
     * 이전으로
     */
    prev() : void {
        this.is_create_mode = false;
        
        this.$nextTick(() => {
            $(".pre_ul .pre_txt").click(function(){
                $(this).parent().siblings(".div_2").addClass("on");
            });
            $(".pre_ul li .div_2").mouseleave(function(){
                $(this).removeClass("on");
            });
        });
    }

    /**
     * 유효성 검사
     */
    checkValid() : boolean {
        
        this.name = this.name.trim(); 
        this.tel = this.tel.trim();
        this.email = this.email.trim(); 

        // 회사명 입력 안함
        if( this.name == null || this.name.length < 1 ) {
            alert("회사명을 입력해주세요")
            $('#pre-input-company-name').addClass('wrong');
            $('#pre-input-company-name').focus();
            return false;
        }

        // 담당자 연락처 입력 안함
        if( this.tel == null || this.tel.length < 1 ) {
            alert("담당자 연락처를 입력해주세요")
            $('#pre-input-tel').addClass('wrong');
            $('#pre-input-tel').focus();
            return false;
        }

        // 담당자 이메일 입력 안함
        if( this.email == null || this.email.length < 1 ) {
            alert("담당자 이메일을 입력해주세요")
            $('#pre-input-email').addClass('wrong');
            $('#pre-input-email').focus();
            return false;
        }

        return true;
    }

    /**
     * 프리미엄 신청
     */
    async submit() : Promise<void> {
        if( this.checkValid() == false ) return ;

        // 변경 + 이메일
        try {

            await this.hodu_show_indicator();

            // 프리미엄 그룹으로 변경 API
            const response = await this.hodu_api_call(`api/v1/groupsweb/bizGroupChange/${this.group_id}`, API_METHOD.PUT, {
                "name" : this.name,
                "tel" : this.tel,
                "email" : this.email
            }, false);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("프리미엄 그룹 변경 중 오류 발생");
            }

            try {
                let head_count = 0;

                const target = this.group_info_list.filter(item => item.group_id == this.group_id);

                if( target.length > 0 ) {
                    head_count = target[0].user_count;
                }

                await this.hodu_api_call(`api/v1/voucher?is_voucher=false`, API_METHOD.POST, {
                    "company_name" : this.name,
                    "manager_name" : this.user_name,
                    "email" : this.email,
                    "phone_number" : this.tel,
                    "head_count" : head_count
                }, false);
                
            } catch(e) {
                this.hodu_error_process(e, false, false, true);
            }

            window['groupChangeService']?.();
            this.doSetShowGroupToBizModal(false);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("프리미엄 그룹 변경 중 오류 발생");
        } finally {
            await this.hodu_hide_indicator();
        }
        
        
    }

    @Watch('name')
    changeCompanyName() : void {
        $('#pre-input-company-name').removeClass('wrong');
    }

    @Watch('tel')
    changeTel() : void {
        $('#pre-input-tel').removeClass('wrong');
    }

    @Watch('email')
    changeEmail() : void {
        $('#pre-input-email').removeClass('wrong');
    }

}
