
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { t_notification } from '@/model/osm';

const EtcInfo    = namespace('EtcInfo');
const dateFormat = require('dateformat');

declare var CKEDITOR: any;

@Component({
    components : {
    }
})
export default class HoduNoticeDetail extends Mixins(VueHoduCommon) {
    @EtcInfo.State hodu_noti_uid !: string;
    @EtcInfo.Action doHoduNotiUid ?: any;

    admin_flag      : boolean = false;
    title           : string  = "";
    body            : string  = "";
    o_notice        : any     = null;
    start_date      : string  = "";
    audit_user_name : string  = "";
    audit_user_pic  : string  = "";
    admin_image_url : string  = require('@/assets/images/contents/ic_hoduShare.png');  

    async mounted() : Promise<void> {
        if ( !this.hodu_noti_uid ) {
            alert('uid 가 잘못되었습니다');
            this.doHoduNotiUid("");
            this.movePrevPage();
            return;
        }

        this.admin_flag = await this.getAdminCheck();

        // 공지 상세
        this.getNoticeDetatil();
    }

    /**
     * 공지사항 상세화면 조회
     */
    async getNoticeDetatil() : Promise<void> {
        const vue = this;

        $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 290));

        await this.hodu_api_call(`api/v1/groupsweb/getNoticeDetail/0/0?uid=${this.hodu_noti_uid}`, API_METHOD.GET, {}, false)
            .then(async(response) => {
                // 데이터가 없을경우
                if ( !response.data.data.noti_data ) {
                    vue.movePrevPage();
                    return;
                }
                else {
                    this.o_notice        = response.data.data.noti_data;
                    this.title           = this.o_notice.noti_info.title;
                    this.body            = this.o_notice.noti_data.body;
                    this.start_date      = dateFormat(this.o_notice.noti_info.start_date, 'yyyy.mm.dd');
                    this.audit_user_name = this.o_notice.audit_user_name;

                    if ( this.o_notice.audit_user_pic ) {
                        this.audit_user_pic = '/app_images/' + this.o_notice.audit_user_pic;
                    }
                    else {
                        this.audit_user_pic = require("@/assets/images/contents/im_photoB.gif");
                    }
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, true);
            });   
    }

    /**
     * 공지사항 수정
     */
    actionModify() : void { 
        this.doHoduNotiUid(this.hodu_noti_uid);
        this.hodu_router_push(`/hodu/${new Date().getTime()}/notice_create`);
    }
    
    /**
     * 공지사항 삭제
     */
    async actionDelete() : Promise<void> { 
        const vue = this;

        this.hodu_show_dialog("alert", "공지사항을 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async () => {
                await this.hodu_api_call(`api/v1/notification/notice/${this.hodu_noti_uid}/HODU/0/0`, API_METHOD.DELETE, {})
                    .then(async(response) => {
                        this.doHoduNotiUid("");
                        vue.movePrevPage();
                    })
                    .catch(async(e) => {
                        this.doHoduNotiUid("");
                        this.hodu_error_process(e, true, true);
                    });    
            },
        ]);
    }
}
