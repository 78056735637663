
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { t_notification } from '@/model/osm';

const GroupInfo = namespace('GroupInfo');
const dateFormat = require('dateformat');

// import CKEDITOR from '';
declare var CKEDITOR: any;

// import CKEditor from '@ckeditor/ckeditor5-vue';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import '@ckeditor/ckeditor5-build-classic/build/translations/ko';

@Component({
    components : {
        // ckeditor: CKEditor.component
    }
})
export default class GroupNoticeCreate extends Mixins(VueHoduCommon) {
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * 변수 선언부
     */
    noti_uid   : string  = "";
    is_created : boolean = true;
    is_shared  : boolean = false;

    o_notice : t_notification = {
        noti_uid : "",
        noti_type : "",
        noti_sub_type: "",
        group_id: 0,
        team_id: 0,
        team_ids: [],
        group_ids: [],
        user_ids: [],
        noti_info: {
            title: "",
            start_date: null,
            end_date: null,
            calendar_id: "",
            event_id: "",
            event_sub_type: "",
            shared: "",
            scope: "",
            scope_id: 0
        },
        noti_data: {
            body: "",
            body_type: null
        },
        meta: "",
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: 0
    };

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }    

    async mounted() : Promise<void> {
        const vue = this;

        // 리사이즈시 322를 제외한 section_ce하이트로 하단에 하이트 변경
        let height_ori = $("#section_ce").outerHeight();
        let height = (height_ori == null ? 0 : height_ori) - 332;
        
        // 공지사항 수정일때 사용함..
        if ( this.group_team_option.noti_uid ) {
            this.noti_uid   = this.group_team_option.noti_uid;
            this.is_created = false;
        }
        else {
            this.noti_uid   = "";
            this.is_created = true;
            this.o_notice.noti_info.start_date = new Date();
            this.setNoticeStartYmdDisplay();
        }

        // date_picker
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                vue.o_notice.noti_info.start_date = new Date(dateText)
                vue.setNoticeStartYmdDisplay();
            },
        };

        // @ts-ignore
        $('#noti_datepicker').datepicker(option);        

        // 공지 상세
        this.getNoticeDetatil();
        
        /*
		setTimeout(() => {
			$("#cke_modal_noti_contents .cke_contents").attr('style', 'height : ' + height + 'px !important');
		}, 100);
		
		CKEDITOR.on( 'instanceReady', function( e ) {
			let editor = e.editor,body = CKEDITOR.document.getBody();
			editor.on( 'focus', function() {
				// texArea에 포커스가 있을때 datePicker none 처리
				$('#ui-datepicker-div').css("display", "none");
			});
		});

		let ckeditorOption : any = {
			uploadUrl: "/hoduweb/upload/ckeditorImgdrag.do",  // 이게 드래그 드롭을 위한 URL
			filebrowserUploadUrl: "/hoduweb/upload/ckeditorFileUpload.do",  // 파일업로드를 위한 URL
			filebrowserImageUploadUrl : "/hoduweb/upload/ckeditorImg.do",
			filebrowserUploadMethod : 'xhr',
		};
		CKEDITOR.replace( 'modal_noti_contents', ckeditorOption);
		
		ckeditorOption.resize_maxWidth 		= 652;
		CKEDITOR.replace( 'modal_condetail_contents', ckeditorOption);

        CKEDITOR.replace( 'modal_concomplte_contents', ckeditorOption);
        */
    }

    /**
     * 공지 시작일 표시
     */
    setNoticeStartYmdDisplay() : void {
        $('#noti_datepicker').val(dateFormat(this.o_notice.noti_info.start_date, "yyyy.mm.dd"));
    }

    /**
     * 공지사항 상세화면 조회
     */
    async getNoticeDetatil() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getNoticeDetail/${this.group_id}/${this.team_id}?uid=${this.noti_uid}`, API_METHOD.GET, {}, false)
            .then(async(response) => {
                if ( this.team_id > 0 ) {
                    this.is_shared = true;
                }
                else {
                    this.is_shared = (response.data.data.team_count.team_count > 0 ? true : false);
                }

                if ( this.is_created == false ) {
                    // 데이터가 없을경우
                    if ( !response.data.data.noti_data ) {
                        if ( vue.group_team_option.noti_uid ) {
                            delete vue.group_team_option.noti_uid;
                        }

                        vue.movePrevPage();
                        return;
                    }
                    else {
                        this.o_notice = response.data.data.noti_data;
                        this.setNoticeStartYmdDisplay();
                    }
                }

                return;
            })
            .catch(async(e) => {
                if ( vue.group_team_option.noti_uid ) {
                    delete vue.group_team_option.noti_uid;
                }

                this.hodu_error_process(e, true, true);
            });        
    }
    
    /**
     * 저장
     */
    async actionSave() : Promise<void> {
        const vue = this;

        if ( this.o_notice.noti_info.title.trim().length < 1 ) {
            alert('제목을 입력하세요');
            return;
        }

        if ( this.o_notice.noti_data.body.trim().length < 1 ) {
            alert('내용을 입력하세요');
            return;
        }

        if ( this.is_created ) {
            this.o_notice.noti_type = "GROUP_NOTICE";
            this.o_notice.noti_data.body_type = "TEXT";
            this.o_notice.noti_info.end_date = new Date("2050.12.31");

            if ( this.team_id == 0 ) {
                this.o_notice.group_id = this.group_id;
            }
            else {
                this.o_notice.group_id = this.group_id;
                this.o_notice.team_id  = this.team_id;
                this.o_notice.team_ids = [this.team_id];
            }
        }

        if ( this.team_id > 0 ) {
            if ( this.o_notice.noti_info.shared == "G" ) {
                this.o_notice.group_ids = [this.group_id];
            }
            else {
                this.o_notice.group_ids = [];
            }
        }
        else {
            this.o_notice.group_ids = [this.group_id]
        }

        await this.hodu_api_call(`api/v1/notification/notice/${this.team_id == 0 ? 'GROUP' : 'TEAM'}`, this.is_created ? API_METHOD.POST : API_METHOD.PUT, {notification: this.o_notice})
            .then(async(response) => {
                if ( this.is_created ) {
                    if ( vue.group_team_option.noti_uid ) {
                        delete vue.group_team_option.noti_uid;
                    }
                }
                
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });        
    }

    /**
     * 공유 변경
     */
    sharedChange(any) : void {
        let ls_shared = this.o_notice.noti_info.shared;

        if ( ls_shared == "" ) {
            this.o_notice.noti_info.shared = (this.team_id == 0 ? "T" : "G");
        }
        else {
            this.o_notice.noti_info.shared = "";
        }
    }    
}
