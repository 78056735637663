
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class AnnouncementCompleteList extends Mixins(VueHoduCommon) {
    
    @Prop() event_bus !: Vue;
    @Prop() search_query !: string;
    
    /**
     * 방송 완료 리스트
     */
    get computedCompleteList() : any[] {

        let list : any[] = JSON.parse(JSON.stringify(this.announcements));

        list = list.filter(item => item.noti_info && item.noti_info.tts && item.noti_info.tts.complete_yn == 'Y');

        if( this.search_query.trim().length > 0 ) {
            list = list.filter(item => this.hodu_string_includes(item.noti_info.title, this.search_query) || 
                                       this.hodu_string_includes(item.noti_data.body, this.search_query));
        }

        list.sort((o1, o2) : number => {

            const o1_start_time = new Date(o1.noti_info.tts.start_time).getTime();
            const o2_start_time = new Date(o2.noti_info.tts.start_time).getTime();

            if( o1_start_time < o2_start_time ) return 1;
            else if( o1_start_time > o2_start_time ) return -1;

            return 0;
        });

        return list;
    }

    announcements : any[] = [];
    audio = new Audio();

    mounted() : void {
        this.event_bus?.$on('complete_scroll', this.handleResize);
        this.getTTSList();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {

            const titleHeight  : number | undefined = $('#announcement_list .title_box').outerHeight();
            const title2Height : number | undefined = $('#announcement_list .title_div').outerHeight();
            const sortHeaderHeight : number | undefined = $('#announcement_list .sortHeader').outerHeight();

            // @ts-ignore
            $('#aptAnn_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                            - (title2Height ? title2Height : 0)
                                            - (sortHeaderHeight ? sortHeaderHeight : 0)
            });

        });
    }

    /**
     * 안내방송 리스트 조회
     */
    async getTTSList() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}?page=0&size=0`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list ) {
                throw new Error("안내방송 조회 중 오류 발생");
            }

            this.announcements.splice(0, this.announcements.length);
            this.announcements = this.announcements.concat(response.data.data.list);

        } catch(e) {
            this.hodu_show_dialog("cancel", "안내방송 조회 중 오류 발생", ["확인"]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 미리 듣기
     */
    async setPreTTS(noti_uid) : Promise<void> {
        this.audio.pause();
        this.audio.src = `app_images/tts/${noti_uid}.mp3`;
        this.audio.play();
    }

    /**
     * 안내방송 상세 페이지 이동
     */
    moveDetail(uid) : void {
        this.hodu_router_push(`/home/announcement/${uid}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#aptAnn_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
