
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class ResidentJoin extends Mixins(VueHoduCommon) {

    /**
     * 전부 체크 된 경우
     */
    get isAllApply() : boolean {
        const residents : any[] = [];
        for( const request of this.computedRequestListByTargetBuilding ) {
            residents.push(request.main_resident);
            for( const sub_resident of request.sub_residents ) {
                residents.push(sub_resident);
            }
        }

        return residents.filter(resident => resident.appr_state != 'A').length < 1;
    }

    /**
     * 선택된 동의 리스트만 반환 
     */
    get computedRequestListByTargetBuilding() : any[] {
        let requests : any[] = JSON.parse(JSON.stringify(this.requests));

        // 검색 생기면 검색 조건 바꿔서 사용
        // requests = requests.filter(item => new RegExp(escape(this.search_word)).test(escape(item.car_info.car_number)) == true);

        if( this.target_building == 'TARGET_ALL') {
            return requests;
        }

        requests = requests.filter(item => item.building == this.target_building);
        
        return requests;
    }

    buildings : any[] = [];
    requests : any[] = [];

    target_building : string = "TARGET_ALL";
    check_user_id_list : number[] = [];

    mounted() : void {
        // 중복동호수 열기
        // $(".overlapP").click(function(){
        //     $(this).parent().parent().toggleClass("on");
        // });

        this.getInfo();
        this.setScroll();        
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height : number | undefined = $('#resident_join .title_box').outerHeight();
        const setting_bg_height : number | undefined = $('#resident_join .settingBg').outerHeight();
        const sort_header_height : number | undefined = $('#resident_join .sortHeader').outerHeight();

        // @ts-ignore
        $('#join_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height == null ? 0 : title_height) 
                                           - (setting_bg_height == null ? 0 : setting_bg_height)
                                           - (sort_header_height == null ? 0 : sort_header_height)
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        this.$nextTick(() => {
            
            // @ts-ignore
            $('#join_list_scroll').mCustomScrollbar('destroy');

            this.setScroll();
        });
        
    }

    /**
     * 전체 조회
     */
    async getInfo() {
        await this.getBuildings();
        await this.getResidentJoinRequests();
    }

    /**
     * 동 조회
     */
    async getBuildings() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/building`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.buildings ) {
                throw new Error("동 조회 중 오류 발생");
            }

            this.buildings.splice(0, this.buildings.length);
            this.buildings = this.buildings.concat(response.data.data.buildings);

        } catch(e) {
            this.hodu_show_dialog("cancel", "동 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 가입 요청 조회
     */
    async getResidentJoinRequests() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/resident/join`, API_METHOD.GET, null, false);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.requests ) {
                throw new Error("입주민 가입 신청 리스트 조회 실패");
            }

            this.requests.splice(0, this.requests.length);
            this.requests = this.requests.concat(response.data.data.requests);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 조회 대상 동을 변경
     */
    changeTargetBuilding(value : string) : void {
        this.allCheckOff();
        this.target_building = value;
    }

    /**
     * 중복 동&호 열기 / 닫기
     */
    listOnOff(obj : any) {
        const index = this.computedRequestListByTargetBuilding.indexOf(obj);
        const copy_obj = JSON.parse(JSON.stringify(obj));
        copy_obj.listOnOff = (copy_obj.listOnOff ? false : true);

        this.computedRequestListByTargetBuilding.splice(index, 1, copy_obj);
        this.$forceUpdate();
    } 

    /**
     * 체크 ON / OFF
     */
    checkUser(event, user_id : number) {
        const index = this.check_user_id_list.indexOf(user_id);

        const checked = event.target.checked;

        if( checked == true && index < 0 ) {
            this.check_user_id_list.push(user_id);
        }
        else if( checked == false && index > -1 ) {
            this.check_user_id_list.splice(index, 1);
        }
    }

    /**
     * 전체 체크 여부
     */
    isAllCheck() : boolean {
        const residents : any[] = [];
        for( const request of this.computedRequestListByTargetBuilding ) {
            if( request.main_resident.appr_state != 'A' ) residents.push(request.main_resident);
            for( const sub_resident of request.sub_residents ) {
                if( sub_resident.appr_state != 'A' ) residents.push(sub_resident);
            }
        }

        return residents.length == this.check_user_id_list.length;
    }

    /**
     * 체크 전체 해제
     */
    allCheckOff() : void {
        this.check_user_id_list.splice(0, this.check_user_id_list.length);
    }

    checkAll(event) : void {

        const checked = event.target.checked;

        if( checked == true ) {
            const residents : any[] = [];
            for( const request of this.computedRequestListByTargetBuilding ) {
                if( request.main_resident.appr_state != 'A' ) residents.push(request.main_resident);
                for( const sub_resident of request.sub_residents ) {
                    if( sub_resident.appr_state != 'A' ) residents.push(sub_resident);
                }
            }
            this.allCheckOff();
            for( const resident of residents ) {
                this.check_user_id_list.push(resident.user_id);
            }
        }
        else {
            this.allCheckOff();
        }
    }
 
    /**
     * 체크된 요청 전부 승인
     */
    async applyCheckedRequests() : Promise<void> {

        if ( this.check_user_id_list.length < 1 ) {
            this.hodu_show_dialog("alert", "승인할 입주자 가입 신청을 선택해주세요", ['확인']);
            return;
        }

        this.hodu_show_dialog("alert", `승인 하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {
                const promise_array : Promise<any>[] = [];
                for( const user_id of this.check_user_id_list ) {
                    promise_array.push(this.updateApprove(user_id, 'A'));
                }
                Promise.all(promise_array); 
            },
        ]);
    }

    /**
     * 승인 / 거절 처리 전 물어보기
     */
    async showApproveMessageDialog(user_id : number, appr_state : string) {
        this.hodu_show_dialog("alert", `${appr_state == 'A' ? '승인' : '거절'} 하시겠습니까?`, ['아니오', '예'], [
            () => {},
            () => { this.updateApprove(user_id, appr_state); },
        ]);
    }
    
    /**
     * 승인 업데이트
     */
    async updateApprove(user_id : number, appr_state : string) {
        let approve_obj = {
            group_id        : this.scope_group_id,
            team_id         : 0,
            group_team_name : this.scope_group_team_option.group_team_name,
            user_id         : user_id,
            appr_state      : appr_state
        }

        try {
            const response = await this.hodu_api_call(`api/v1/groups/updateApprov`, API_METHOD.PUT, {arrO_list: [approve_obj]});

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("입주자 가입 신청 처리 중 오류 발생");
            }

            this.allCheckOff();
            this.getInfo();

        } catch(e) {
            this.hodu_show_dialog("cancel", "입주자 가입 신청 처리 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    } 
}
