
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationVacationTypeSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationTypeModalInfo  ?: (params : organization_modal_interface.OrganizationVacationTypeModalInfo) => void;

    vacation_type_list : any[] = [];

    mounted() : void {
        this.event_bus?.$on("OrganizationVacationTypeResize", this.handleResize);
        this.getOrganizationVacationTypeInfo();
        this.setScroll();    
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const title2_height = $('.title_box2').outerHeight();
        const sort_header_height = $('#organization_vacation_type .sortHeader').outerHeight();

        // @ts-ignore
        $('#organization_vacation_type_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) 
                                           - (title2_height ? title2_height : 0) 
                                           - (sort_header_height ? sort_header_height : 0),
        });
    }

    /**
     * 휴가타입 조회
     */
    async getOrganizationVacationTypeInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.vacation_type_info ) {
                throw new Error("휴가타입 조회 중 오류 발생")
            }

            this.vacation_type_list.splice(0, this.vacation_type_list.length);
            this.vacation_type_list = this.vacation_type_list.concat(response.data.data.vacation_type_info);
            this.vacation_type_list.sort((o1, o2) : number => {
                if( o1.vacation_type_id > o2.vacation_type_id ) return 1;
                else return -1;
            });

        } catch(e) {
            this.hodu_show_dialog('cancel', '휴가타입 조회 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 추가
     */
    add() : void {
        this.doSetOrganizationVacationTypeModalInfo?.({
            show_modal : true,
            is_create : true,
            callback : this.getOrganizationVacationTypeInfo
        })
    }

    /**
     * 수정
     */
    edit(vacation_type : any) : void {
        this.doSetOrganizationVacationTypeModalInfo?.({
            show_modal : true,
            is_create : false,
            vacation_type_id : vacation_type.vacation_type_id,
            title : vacation_type.vacation_type_name,
            day : Number(this.processDayCount(vacation_type.vacation_type_daycount)),
            pay : vacation_type.vacation_dedution_yn,
            callback : this.getOrganizationVacationTypeInfo
        })
    }

    /**
     * 삭제
     */
    deleteVacationType(vacation_type : any) : void {
        this.hodu_show_dialog('cancel', `정말로 삭제하시겠습니까?\n'${vacation_type.vacation_type_name}'`, ['아니오', '예'], [
            () => {},
            async() => {

                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype/${vacation_type.vacation_type_id}`, API_METHOD.DELETE);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("휴가타입 삭제 중 오류 발생");
                    }

                    this.getOrganizationVacationTypeInfo();
                    
                } catch(e) {
                    this.hodu_show_dialog('cancel', '휴가타입 삭제 중 오류 발생', ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }

            }
        ])
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#organization_vacation_type_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
