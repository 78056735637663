import { t_event_file } from './event';
import { CRUD_TYPE } from '@/mixin/VueHoduCommon';

/**
 * Interface
 */
export namespace chat_interface {

    /**
     * 채널 정보
     */
    export interface I_CHANNEL_INFO {
        channel_uid? : string;
        type? : string;                 
        sub_type? : string;         // GROUP | TEAM
        ref_id? : string;           // GROUP_Id | TEAM_ID
        is_private? : boolean;      // 비공개 여부
        info?: I_CHANNEL_INFO_DATA; // 방 정보 , {title:"",img : "",...}
        created? :Date;
        deleted? :Date;
        deleted_flag? : boolean;
        msg_idx?:number;
    
        users? :t_chat_channel_user[];
        users_read? :{[key:string]:number | string};
        users_online? :{[key:string]:number | string};
    
        channel_url? : string | null;   // 체널 접속 경로
        last_msg? : Object;
    }

    /**
     * 채널 정보 info
     */
    export interface I_CHANNEL_INFO_DATA {
        title?:string;
        color?:string;
        banner?:any;
        banner_thumb?:any;
    }
    
    /**
     * 채널 유저 정보
     */
    export interface t_chat_channel_user {
        channel_uid?: string;
        user_id?: string;                       // 필수
        user_name: string;                      
        user_type?: string;
        user_role?: string;
        join_date?: string;
        leaved_date?: Date | null;
        leaved_flag?: boolean;
        user_info?: Object;
        channel_online?: boolean;
    }

    /**
     * 메시지
     */
    export interface I_CHAT_MESSAGE
    {
        channel_uid? : string;
        idx? : number;
        user_id? : string;
        type : chat_enum.MSG_TYPE;
        sub_type : chat_enum.MSG_SERVER_TYPE | chat_enum.MSG_CHANNEL_TYPE;
        send_date? :  number; // Timestamp
        data? : any | I_CHAT_MESSAGE_DATA;
        deleted_flag? : boolean;
    }

    // type : MSG
    export interface I_CHAT_MESSAGE_DATA {
        msg? : string;
        att? : t_event_file[];
    }

    /**
     * 채널의 마지막 메시지
     */
    export interface I_LAST_CHANNEL_MESSAGES {
        [key:string]:I_CHAT_MESSAGE;
    }

    /**
     * 일별 채팅 리스트
     */
    export interface I_CHAT_DAY_MESSAGE {
        date : number;
        chat : I_CHAT_MESSAGE[];
    }

    /**
     * 채팅방 생성 인터페이스
     */
    export interface I_CHANNEL_CREATE_INFO  {
        channel_info : I_CHANNEL_INFO;
        invite_user_infos: t_chat_channel_user[];
    }

}

/**
 * Modal Store Interface
 */
export namespace chat_modal_interface {
    
    /**
     * 채팅방 생성 모달 인터페이스
     */
    export interface ChattingRoomCreateModalInfo {
        show_modal : boolean;
        crud_type ?: CRUD_TYPE;
        callback ?: Function;
    }

    /**
     * 채팅방 정보 모달 인터페이스
     */
    export interface ChattingRoomInfoModalInfo {
        show_modal : boolean;
    }

}

/**
 * Enum
 */
export namespace chat_enum {

    /**
     * 메시지 타입
     */
    export enum MSG_TYPE {
        NOTICE = 'NOTICE',
        SERVER = 'SERVER',
        CHANNEL = 'CHANNEL',
        PUSH = 'PUSH',
    }

    /**
     * 접속 관련 타입
     */
    export enum MSG_SERVER_TYPE {
        CONNECTED ='CONNECTED',
        DISCONNECTED = 'DISCONNECTED', 
        ERROR = "ERROR",
    }

    /**
     * 채널 메시지 타입
     */
    export enum MSG_CHANNEL_TYPE {
        CREATE  = 'CREATE',                       // 체널 생성
        DESTROY = 'DESTROY',                      // 체널 제거(방장만 가능)
        MODIFY  = 'MODIFY',                       // 체널 수정(방장만 가능)

        MEMBER_CHOWN = 'MEMBER_CHOWN',            // 방장 변경 (방장만 가능)

        MEMBER_JOIN ='MEMBER_JOIN',               // 체널에 참여
        MEMBER_LEAVE ='MEMBER_LEAVE',             // 체널에서 떠남

        MEMBER_ONLINE = "MEMBER_ONLINE",          // 체널에 들어옴 (참여와 다름)
        MEMBER_OFFLINE = "MEMBER_OFFLINE",        // 체널에서 나감 (떠남과 다름 ,백버튼으로 나간경우)

        MEMBER_KICK ='MEMBER_KICK',               // 체널에서 쫒겨남(쫓아내기)

        MEMBER_MODIFY ='MEMBER_MODIFY',           // 사용자 정보 수정

        MSG ='MSG',                               // 메시지
        MSG_LIST ='MSG_LIST',                     // 과거 메시지 가져오기 offset=0 , size= 30 (MAX 100)
        MSG_DEL ='MSG_DEL',                       // 메시지 삭제
        MSG_DEL_ALL ='MSG_DEL_ALL',               // 모든 메시지 삭제
        MSG_DEL_ALL_IMAGE ='MSG_DEL_ALL_IMAGE',   // 모든 이미지 삭제
        MSG_DEL_ALL_FILE ='MSG_DEL_ALL_FILE',     // 모든 파일 삭제
        READ ='READ',                             // 읽음 idx
        INFO ='INFO',                             // 체널 정보
        ERROR="ERROR"
    }

}