
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { SORT_TYPE, OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');
const ModalInfo   = namespace('ModalInfo');

import { ResizeObserver } from 'vue-resize';

import AppointmentPreviewList from '@/components/hodu_d/AppointmentPreviewList.vue';

import moment from 'moment';

import { hodu_doc_object, hodu_doc_enum, hodu_doc_modal_info } from '@/model/hodudoc';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { t_event } from '@/model/event';
import { hodu_color } from '@/common/color';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        AppointmentPreviewList, ResizeObserver
    },
}) 
export default class AppointmentList extends Mixins(VueHoduCommon) {

    hodu_doc_enum : any = hodu_doc_enum;

    /**
     * 정렬된 예약 리스트
     */
    get computedAppointmentSortList() : hodu_doc_object.appointment_info[] {

        let sort_appointment_list : hodu_doc_object.appointment_info[] = JSON.parse(JSON.stringify(this.appointment_list));

        // 상태값 필터
        for( const status of this.status_filter ) {
            sort_appointment_list = sort_appointment_list.filter(item => item.status != status);
        }

        // 진료과 필터
        for( const department_code of this.department_filter ) {
            sort_appointment_list = sort_appointment_list.filter(item => item.department_code != department_code);
        }

        // 의사 필터
        for( const code of this.doctor_filter ) {
            sort_appointment_list = sort_appointment_list.filter(item => `${item.department_code}___${item.doctor_code}` != code);
        }

        // 정렬
        sort_appointment_list = sort_appointment_list.sort((item1, item2) : number => {
            
            const target_date_item1 : Date = new Date(item1.start_date); 
            const target_date_item2 : Date = new Date(item2.start_date); 

            // 아래로 내려가는 타입 여부 체크 (거절, 노쇼, 진료)
            // const is_down_type_item1 : boolean = item1.status == hodu_doc_enum.appointment_status_code.DECLINE ||
            //                                      item1.status == hodu_doc_enum.appointment_status_code.NOSHOW  ||
            //                                      item1.status == hodu_doc_enum.appointment_status_code.DIAGNOSIS;

            // const is_down_type_item2 : boolean = item2.status == hodu_doc_enum.appointment_status_code.DECLINE ||
            //                                      item2.status == hodu_doc_enum.appointment_status_code.NOSHOW  ||
            //                                      item2.status == hodu_doc_enum.appointment_status_code.DIAGNOSIS;

            // sort level 지정 (높을수록 밑에 나옴)
            let item1_sort_level : number = 0;
            switch( item1.status ) {
                case hodu_doc_enum.appointment_status_code.REQUEST  : item1_sort_level = 0; break;
                case hodu_doc_enum.appointment_status_code.CONFIRM  : item1_sort_level = 1; break;
                case hodu_doc_enum.appointment_status_code.RECEIPT  : item1_sort_level = 2; break;
                case hodu_doc_enum.appointment_status_code.DIAGNOSIS: item1_sort_level = 3; break;
                case hodu_doc_enum.appointment_status_code.NOSHOW   : item1_sort_level = 4; break;
                case hodu_doc_enum.appointment_status_code.DECLINE  : item1_sort_level = 5; break;
            }

            let item2_sort_level : number = 0;
            switch( item2.status ) {
                case hodu_doc_enum.appointment_status_code.REQUEST  : item2_sort_level = 0; break;
                case hodu_doc_enum.appointment_status_code.CONFIRM  : item2_sort_level = 1; break;
                case hodu_doc_enum.appointment_status_code.RECEIPT  : item2_sort_level = 2; break;
                case hodu_doc_enum.appointment_status_code.DIAGNOSIS: item2_sort_level = 3; break;
                case hodu_doc_enum.appointment_status_code.NOSHOW   : item2_sort_level = 4; break;
                case hodu_doc_enum.appointment_status_code.DECLINE  : item2_sort_level = 5; break;
            }
            
            // 다른 sort_level 인 경우
            if( item1_sort_level != item2_sort_level ) {
                return item1_sort_level < item2_sort_level ? -1 : 1;
            }

            // 같은 타입인 경우 sort_type 에 따라서 정렬
            else {

                // 시간 기준 정렬
                if( this.sort_type == hodu_doc_enum.appointment_sort_type.TIME ) {
                    
                    // 같은 시간
                    if( target_date_item1.getTime() == target_date_item2.getTime() ) { return 0; }

                    // ASC
                    if( this.sort_dir == SORT_TYPE.ASC ) {
                        return target_date_item1.getTime() > target_date_item2.getTime() ? 1 : -1;
                    }

                    // DESC
                    return target_date_item1.getTime() < target_date_item2.getTime() ? 1 : -1;

                }

                // 진료과 기준 정렬
                else if ( this.sort_type == hodu_doc_enum.appointment_sort_type.DEPARTMENT ) {
                    
                    // 같은 진료과
                    if( item1.department_name == item2.department_name ) { return 0; }

                    // ASC
                    if( this.sort_dir == SORT_TYPE.ASC ) {
                        return item1.department_name > item2.department_name ? 1 : -1;
                    }

                    // DESC
                    return item1.department_name < item2.department_name ? 1 : -1;

                }

                // 의사 기준 정렬
                else if ( this.sort_type == hodu_doc_enum.appointment_sort_type.DOCTOR ) {

                    // 같은 의사
                    if( item1.doctor_name == item2.doctor_name ) { return 0; }

                    // ASC
                    if( this.sort_dir == SORT_TYPE.ASC ) {
                        return item1.doctor_name > item2.doctor_name ? 1 : -1;
                    }

                    // DESC
                    return item1.doctor_name < item2.doctor_name ? 1 : -1;

                }
                
                // 환자 기준 정렬
                else if ( this.sort_type == hodu_doc_enum.appointment_sort_type.PATIENT ) {

                    // 같은 환자
                    if( item1.patient_name == item2.patient_name ) { return 0; }

                    // ASC
                    if( this.sort_dir == SORT_TYPE.ASC ) {
                        return item1.patient_name > item2.patient_name ? 1 : -1;
                    }

                    // DESC
                    return item1.patient_name < item2.patient_name ? 1 : -1;

                }

            }

            return 0;
        })

        return sort_appointment_list;
    }

    /**
     * 의사가 한 명이라도 존재하는 진료과만 반환
     */
    get computedDoctorExistDepartment() : hodu_doc_object.department_info[] {

        let doctor_exist_department : hodu_doc_object.department_info[] = [];

        if( this.department_info != null && this.doctor_info != null ) {

            for( const department of this.department_info ) {

                if( this.doctor_info.filter(item => item.department_code == department.department_code).length > 0 ) {
                    doctor_exist_department.push(department);
                }

            }

        }

        return doctor_exist_department;
    }

    /**
     * 필터에 들어 있는 진료과를 제외한 의사 리스트 반환
     */
    get computedNotFilteredDoctor() : hodu_doc_object.doctor_info[] {

        let doctor_not_filtered_list : hodu_doc_object.doctor_info[] = [];

        if( this.doctor_info != null ) {

            doctor_not_filtered_list = doctor_not_filtered_list.concat(this.doctor_info);

            for( const department_code of this.department_filter ) {        
                const temp : hodu_doc_object.doctor_info[] = JSON.parse(JSON.stringify(doctor_not_filtered_list.filter(item => item.department_code != department_code)));                
                doctor_not_filtered_list.splice(0, doctor_not_filtered_list.length);
                doctor_not_filtered_list = doctor_not_filtered_list.concat(temp);

                // 의사 필터에도 제거
                this.doctor_filter = this.doctor_filter.filter(item => new RegExp(`${department_code}___`).test(item) == false);
            }
        }

        return doctor_not_filtered_list;
    }

    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentApplyModalInfo ?: (params : hodu_doc_modal_info.AppointmentApplyModalInfo) => void;

    event_bus : Vue = new Vue();

    start_date : Date = new Date();
    end_date : Date = new Date();

    is_search_open : boolean = false;
    preview_close : boolean = true;

    search_word : string = "";

    appointment_list : hodu_doc_object.appointment_info[] = [];

    appointment_select_criteria : hodu_doc_enum.appointment_select_criteria = hodu_doc_enum.appointment_select_criteria.DIRECT;
    date_term : number = 0;

    // 정렬
    sort_type : hodu_doc_enum.appointment_sort_type = hodu_doc_enum.appointment_sort_type.TIME;
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    // 필터
    is_status_filter_open     : boolean = false;
    is_department_filter_open : boolean = false;
    is_doctor_filter_open     : boolean = false;

    status_filter : hodu_doc_enum.appointment_status_code[] = [];
    department_filter : string[] = []; // ${department_code}
    doctor_filter : string[] = [];     // ${department_code}___${doctor_code}

    get_appointments_interval : number | undefined;

    beforeMount() : void {
    
        // 넘어온 날짜
        const tag = Number(this.$route.params.tag);

        this.start_date = moment(tag).set("hour", 0)
                                  .set("minute", 0)
                                  .set("second", 0)
                                  .set("millisecond", 0).toDate();

        this.end_date = moment(this.start_date).set("hour", 23)
                                               .set("minute", 59)
                                               .set("second", 59)
                                               .set("millisecond", 999).toDate();
    }

    mounted() : void {
        const vue = this;

        this.getAppointments();

        // 2초마다 예약 재조회
        this.get_appointments_interval = window.setInterval(async() => {
            await this.getAppointments(false);
        }, 2000);

        this.setScroll(); 

        // $(".sortHeader a.sort").click(function(event){
        //     event.stopPropagation();

        //     // 선택외 다른 dropdown닫기
        //     $(this).parent().siblings().find(".dropdown").removeClass("on");
        //     // 선택된 dropdown만 열기
        //     $(this).parent().find(".dropdown").toggleClass("on");
            
        // });

        // $(".showMore").click(function(){
        //     // 
        //     $(this).toggleClass("on");
        //     $(".previewContent").toggleClass("on");
            
        // });

        // jquery-ui datepicker 옵션
        const datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText);
                inst.input.val(mnt.format('YYYY.MM.DD') + ` ${vue.getDayOfWeekByDate(mnt.toDate())}`);

                // 직접 간격 선택시 
                if( vue.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.DIRECT ) {
                    
                    if( id == 'reservation_list_fr_ymd' ) {
                        vue.start_date = mnt.set("hour", 0)
                                            .set("minute", 0)
                                            .set("second", 0)
                                            .set("millisecond", 0).toDate();

                        // 종료일이 시작일보다 작아진 경우
                        if( vue.end_date.getTime() < vue.start_date.getTime() ) {
                            vue.end_date = mnt.set("hour", 23)
                                              .set("minute", 59)
                                              .set("second", 59)
                                              .set("millisecond", 999).toDate();
                        }
                    }

                    if( id == 'reservation_list_to_ymd' ) {
                        vue.end_date = mnt.set("hour", 23)
                                          .set("minute", 59)
                                          .set("second", 59)
                                          .set("millisecond", 999).toDate();

                        // 종료일이 시작일보다 작아진 경우
                        if( vue.end_date.getTime() < vue.start_date.getTime() ) {
                            vue.start_date = mnt.set("hour", 0)
                                                .set("minute", 0)
                                                .set("second", 0)
                                                .set("millisecond", 0).toDate();
                        }
                    }

                    vue.date_term = vue.getDateDiff(vue.start_date, vue.end_date);

                    // 재조회
                    vue.getAppointments();

                    return;
                }

                vue.start_date = mnt.set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .set("millisecond", 0).toDate();

                vue.end_date = mnt.set("hour", 23)
                                  .set("minute", 59)
                                  .set("second", 59)
                                  .set("millisecond", 999).toDate();

                // 시작일 변경 (주별)
                if( id == 'reservation_list_fr_ymd' && vue.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
                    vue.end_date = moment(vue.end_date).set("date", vue.end_date.getDate() + 7).toDate();
                } 

                // 종료일 변경 (주별)
                else if( id == 'reservation_list_to_ymd' && vue.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
                    vue.start_date = moment(vue.start_date).set("date", vue.start_date.getDate() - 7).toDate();
                }

                vue.date_term = vue.getDateDiff(vue.start_date, vue.end_date);

                // 재조회
                vue.getAppointments();

            },
        };

        // @ts-ignore
        $('#reservation_list_fr_ymd').datepicker(datepicker_option);

        // @ts-ignore
        $('#reservation_list_to_ymd').datepicker(datepicker_option);
    }

    /**
     * 종료 전 interval 제거
     */
    beforeDestroy() : void {
        if( this.get_appointments_interval ) { clearInterval(this.get_appointments_interval); }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_box_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const preview_content_height : number | undefined = $('.previewContent').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#appointment_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (schedule_box_height ? schedule_box_height : 0)
                                           - (preview_content_height ? preview_content_height : 0)
                                           - (sort_header_height ? sort_header_height : 0),
        });

    }

    /**
     * 예약 리스트 가져오기
     */
    getAppointments(is_show_error_report : boolean = true) : void {

        if( this.search_word != null && this.search_word.length > 0 && this.search_word.length < 2 ) {
            this.hodu_show_dialog('alert', '검색어를 2자 이상 입력해주세요', ["확인"], [() => {}]);
            return;
        }

        const start : string = moment(this.start_date).utc().format();
        const end   : string = moment(this.end_date).utc().format();

        const search_word : string = encodeURIComponent(this.search_word);

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/appointment?start=${start}&end=${end}&search_word=${search_word}`, API_METHOD.GET, null, false)
            .then((response) => {
                console.log(response);

                this.appointment_list.splice(0, this.appointment_list.length);
                this.appointment_list = this.appointment_list.concat(response.data.data);

            })  
            .catch((e) => {
                this.hodu_error_process(e, true, false, !is_show_error_report);
            })
    }

    /**
     * 예약 수정
     */
    async updateEvent(event_id : string, status_code : hodu_doc_enum.appointment_status_code) : Promise<void> {
        
        // 이벤트
        let lo_event !: t_event;

        // event_id로 먼저 조회
        
        let calendar_id : string = this.calendar_id;
        let scope       : string = OWNER_TYPE.GROUP;
        let scope_id    : number = this.scope_group_id;

        await this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                lo_event = JSON.parse(JSON.stringify(response.data.data.event_data));
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })

        // 조회 결과 없으면 존재하지 않는 예약 처리
        if( lo_event == null || lo_event.event_data.appointment == null ) {
            this.hodu_show_dialog('cancel', '존재하지 않는 예약 입니다', ['확인'], [
                () => { this.getAppointments(); }
            ])
            return;
        }

        // 코드 세팅
        lo_event.event_data.appointment.appointment_status = status_code;

        // 컬러 세팅
        switch(status_code) {
            case hodu_doc_enum.appointment_status_code.RECEIPT:
                lo_event.event_data.color = hodu_color.appointment_receipt;
                break;

            case hodu_doc_enum.appointment_status_code.NOSHOW:
                lo_event.event_data.color = hodu_color.appointment_noshow;
                break;
        }

        // 수정 API
        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.PUT, lo_event.event_data)
            .then((response) => {
                console.log(response);
                this.getAppointments();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 스테이터스에 따른 텍스트 반환
     */
    getStatusClassOrText(appointment : hodu_doc_object.appointment_info, is_get_class : boolean = false) : string {

        switch( appointment.status ) {
            case hodu_doc_enum.appointment_status_code.REQUEST:   return is_get_class ? "ing"       : "요청";
            case hodu_doc_enum.appointment_status_code.DECLINE:   return is_get_class ? "reject"    : "거절";
            case hodu_doc_enum.appointment_status_code.CONFIRM:   return is_get_class ? "confirmed" : "확정";
            case hodu_doc_enum.appointment_status_code.RECEIPT:   return is_get_class ? "made"      : "접수";
            case hodu_doc_enum.appointment_status_code.DIAGNOSIS: return is_get_class ? "seen"      : "진료";
            case hodu_doc_enum.appointment_status_code.NOSHOW:    return is_get_class ? "noshow"    : "노쇼";
            default: return "";
        }
        
    }

    /**
     * 조회 기준 변경 (일별, 주별)
     */
    changeSelectCriteria(value : hodu_doc_enum.appointment_select_criteria) : void {
        if ( this.appointment_select_criteria == value ) { return; }

        this.appointment_select_criteria = value;
        
        if( this.appointment_select_criteria != hodu_doc_enum.appointment_select_criteria.DIRECT ) {
            this.end_date = moment(this.start_date).set("hour", 23)
                                                   .set("minute", 59)
                                                   .set("second", 59)
                                                   .set("millisecond", 999).toDate();
        }
        
        // 주별일때는 7일 더한 값으로 변경
        if( this.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
            this.end_date = moment(this.end_date).set("date", this.start_date.getDate() + 7).toDate();
        }

        this.date_term = this.getDateDiff(this.start_date, this.end_date);

        // 조회
        this.getAppointments();
    }

    /**
     * 시작일이 오늘인 예약 리스트 조회
     */
    getTodayAppointments() : void {
        this.start_date = moment().set("hour", 0)
                                  .set("minute", 0)
                                  .set("second", 0)
                                  .set("millisecond", 0).toDate();

        this.end_date = moment(this.start_date).set("hour", 23)
                                               .set("minute", 59)
                                               .set("second", 59)
                                               .set("millisecond", 999).toDate();

        // 주별이라면 종료일을 7일 후로 설정
        if( this.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
            this.end_date = moment(this.end_date).set("date", this.start_date.getDate() + 7).toDate();
        }

        this.date_term = this.getDateDiff(this.start_date, this.end_date);

        this.getAppointments();
    }

    /**
     * 이전, 다음 구간의 예약 리스트 조회
     */
    getAppointmentsByOffset(is_next : boolean) : void {

        this.start_date = moment(this.start_date).set("date", this.start_date.getDate() + (is_next ? (this.date_term == 0 ?  1 :  this.date_term) 
                                                                                                   : (this.date_term == 0 ? -1 : -this.date_term) ))
                                                 .set("hour", 0)
                                                 .set("minute", 0)
                                                 .set("second", 0)
                                                 .set("millisecond", 0).toDate();

        // 직접 선택이라면 정해진 간격대로 설정한다
        if( this.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.DIRECT ) {
            this.end_date = moment(this.start_date).set("date", this.start_date.getDate() + this.date_term)
                                                   .set("hour", 23)
                                                   .set("minute", 59)
                                                   .set("second", 59)
                                                   .set("millisecond", 999).toDate();
        }

        // 직접 선택이 아니라면 오늘 시점으로 간격을 맞춘다
        if( this.appointment_select_criteria != hodu_doc_enum.appointment_select_criteria.DIRECT )  {
            this.end_date = moment(this.start_date).set("hour", 23)
                                                   .set("minute", 59)
                                                   .set("second", 59)
                                                   .set("millisecond", 999).toDate();


            // 주별이라면 종료일을 7일 후로 설정
            if( this.appointment_select_criteria == hodu_doc_enum.appointment_select_criteria.WEEKLY ) {
                this.end_date = moment(this.end_date).set("date", this.start_date.getDate() + 7).toDate();
            }
        }

        this.getAppointments();
    }

    /**
     * 해당 일자의 예약 리스트를 조회한다
     */
    getDailyAppointments(date : Date) : void {
        this.start_date = moment(date).set("hour", 0)
                                      .set("minute", 0)
                                      .set("second", 0)
                                      .set("millisecond", 0).toDate();

        this.end_date = moment(this.start_date).set("hour", 23)
                                               .set("minute", 59)
                                               .set("second", 59)
                                               .set("millisecond", 999).toDate();

        this.appointment_select_criteria = hodu_doc_enum.appointment_select_criteria.DAILY;
        this.getAppointments();
    }

    /**
     * 검색창 열기 / 닫기
     */
    searchOnOff() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_word = "";
            this.getAppointments();
        }

        // 검색 창 열릴때 검색 창에 포커스 가도록
        else {
            this.$nextTick(() => { $('#appointment_search_text').focus(); });
        }
    }

    /**
     * 예약 검색 keydown
     */
    eventKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.getAppointments();
    }

    /**
     * 예약 등록으로 이동
     */
    moveCreate() : void {
        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/create`);
    }

    /**
     * 예약 상세 화면으로 이동
     */
    moveDetail(event_id : string) : void {
        if( !this.doSetAppointmentDetailInfo ) { return; }

        this.doSetAppointmentDetailInfo({
            event : null,
            is_patient : false,
        });
        
        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event_id}`);
    }

    /**
     * 예약 승인 모달 띄우기
     */
    showApplyModal(event_id : string) : void {
        if( this.doSetAppointmentApplyModalInfo ) { 
            this.doSetAppointmentApplyModalInfo({ 
                show_modal : true,
                event_id  : event_id,
                apply_callback : this.getAppointments 
            }); 
        }
    }

    /**
     * 월별 예약 건수 리스트 ON / OFF
     */
    previewOnOff() : void {
        this.preview_close = !this.preview_close;
        setTimeout(() => { this.handleResize(); }, 200);
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : hodu_doc_enum.appointment_sort_type) : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }

        // console.log(`[${this.sort_type}, ${this.sort_dir}]`);
    }

    /**
     * 상태값 필터창 ON / OFF
     */
    statusFilterMenuOnOff(event : Event) : void {
        event.stopPropagation();
        this.is_status_filter_open     = !this.is_status_filter_open;
        this.is_department_filter_open = false;
        this.is_doctor_filter_open     = false;
    }

    /**
     * 상태값 필터 전체 체크
     */
    statusFilterAll(event : Event) : void {
        // event.stopPropagation();

        // 전부 필터에 존재하지 않는다면
        if( this.status_filter.length < 1 ) {
            this.status_filter.splice(0 ,this.status_filter.length);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.REQUEST);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.DECLINE);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.CONFIRM);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.RECEIPT);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.DIAGNOSIS);
            this.status_filter.push(hodu_doc_enum.appointment_status_code.NOSHOW);
            return;
        }

        // 하나라도 필터에 존재한다면
        this.status_filter.splice(0 ,this.status_filter.length);
    }

    /**
     * 상태값 필터에 추가 / 제거
     */
    statusFilterOnOff(event : Event, value : hodu_doc_enum.appointment_status_code) {
        // event.stopPropagation();
        
        if ( this.status_filter.indexOf(value) < 0 ) { 
            this.status_filter.push(value);
            return;
        }

        this.status_filter.splice(this.status_filter.indexOf(value), 1);
    }

    /**
     * 진료과 필터창 ON / OFF
     */
    departmentFilterMenuOnOff(event : Event) : void {
        event.stopPropagation();
        this.is_status_filter_open     = false;
        this.is_department_filter_open = !this.is_department_filter_open;
        this.is_doctor_filter_open     = false;
    }

    /**
     * 진료과 필터 전체 체크
     */
    departmentFilterAll(event : Event) : void {
        // event.stopPropagation();

        // 전부 필터에 존재하지 않는다면
        if( this.department_filter.length < 1 ) {
            this.department_filter.splice(0, this.department_filter.length);
            for( const department of this.computedDoctorExistDepartment ) {
                this.department_filter.push(department.department_code);
            }
            return;
        }

        // 하나라도 필터에 존재한다면
        this.department_filter.splice(0 ,this.department_filter.length);
    }

    /**
     * 진료과 필터에 추가 / 제거
     */
    departmentFilterOnOff(event : Event, value : string) {
        // event.stopPropagation();
        
        if ( this.department_filter.indexOf(value) < 0 ) { 
            this.department_filter.push(value);
            return;
        }

        this.department_filter.splice(this.department_filter.indexOf(value), 1);
    }

    /**
     * 의사 필터창 ON / OFF
     */
    doctorFilterMenuOnOff(event : Event) : void {
        event.stopPropagation();
        this.is_status_filter_open     = false;
        this.is_department_filter_open = false;
        this.is_doctor_filter_open     = !this.is_doctor_filter_open;
    }

    /**
     * 의사 필터 전체 체크
     */
    doctorFilterAll(event : Event) : void {
        // event.stopPropagation();

        // 전부 필터에 존재하지 않는다면
        if( this.doctor_filter.length < 1 ) {
            this.doctor_filter.splice(0, this.doctor_filter.length);
            for( const doctor of this.computedNotFilteredDoctor ) {
                this.doctor_filter.push(`${doctor.department_code}___${doctor.doctor_code}`);
            }
            return;
        }

        // 하나라도 필터에 존재한다면
        this.doctor_filter.splice(0 ,this.doctor_filter.length);
    }

    /**
     * 의사 필터에 추가 / 제거
     */
    doctorFilterOnOff(event : Event, value : string) {
        // event.stopPropagation();
        
        if ( this.doctor_filter.indexOf(value) < 0 ) { 
            this.doctor_filter.push(value);
            return;
        }

        this.doctor_filter.splice(this.doctor_filter.indexOf(value), 1);
    }

    /**
     * 예약 리스트 아이템 클릭 했을때 status에 따라서 다른 일을 함
     */
    clickAppointment(appointment : hodu_doc_object.appointment_info) : void {

        switch(appointment.status) {

            /**
             * 요청, 거절
             */
            case hodu_doc_enum.appointment_status_code.REQUEST:
            case hodu_doc_enum.appointment_status_code.DECLINE:
                this.showApplyModal(appointment.event_id);
                break;

            /**
             * 확정
             */
            case hodu_doc_enum.appointment_status_code.CONFIRM:
                // 노쇼, 접수로 변경 할 수 있는 모달
                this.hodu_show_dialog('alert', '예약 상태를 변경 하시겠습니까?', ['취소', '노쇼', '접수'], [
                    () => {},
                    () => { this.updateEvent(appointment.event_id ,hodu_doc_enum.appointment_status_code.NOSHOW); },
                    () => { this.updateEvent(appointment.event_id ,hodu_doc_enum.appointment_status_code.RECEIPT); }
                ]);
                break;
            /**
             * 접수, 진료, 노쇼
             */
            case hodu_doc_enum.appointment_status_code.RECEIPT:
            case hodu_doc_enum.appointment_status_code.DIAGNOSIS:
            case hodu_doc_enum.appointment_status_code.NOSHOW:
                this.moveDetail(appointment.event_id);
                break;

        }

    }

    /**
     * 필터 영역 외에 다른 곳을 누른다면 닫는다 
     */
    // filterOff(event : Event) : void {
    //     this.is_status_filter_open     = false;
    //     this.is_department_filter_open = false;
    //     this.is_doctor_filter_open     = false;
    // }

    /**
     * 예약(일정) 삭제 권한 반환
     */
    getAppointmentDeletePermission() : boolean {
        return this.is_group_permmision(this.scope_group_id, "event", "delete");
    }

    /**
     * 예약 삭제
     */
    deleteEvent(event_id : string) : void {

        this.hodu_show_dialog("cancel", "예약을 삭제하시겠습니까?", ["아니오", "예"], [
            () => {},
            () => {
                const calendar_id : string = this.calendar_id;
                const scope       : string = OWNER_TYPE.GROUP;
                const scope_id    : number = this.scope_group_id;
                
                const url : string = `api/v1/calendars/${ calendar_id }/events/${ scope }/${ scope_id }/${ event_id }?push_yn=false`;

                this.hodu_api_call(url, API_METHOD.DELETE)
                    .then((response) => { 
                        this.getAppointments();
                    })
                    .catch((e) => { 
                        this.hodu_error_process(e, true, false); 
                    });
            },
        ]);
        
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // 이벤트 버스 리사이즈
        this.event_bus.$emit('previewResize');
        
        // @ts-ignore
        $('#appointment_list_scroll').mCustomScrollbar('destroy');
        this.setScroll();
    }

}
