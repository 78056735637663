
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class ResidentMoveIntoRequestTimeSetting extends Mixins(VueHoduCommon) {

    times : any[] = [];
    original_times : any[] = [];

    mounted() : void {
        this.getMoveIntoTimes();
    }

    async getMoveIntoTimes() {
        try {
            const response = await this.hodu_api_call(`api/v1/home/move_into/time?group_id=${this.scope_group_id}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.times ) {
                throw new Error("입주 예약 시간 조회 중 오류 발생");
            }

            this.times.splice(0, this.times.length);
            this.times = this.times.concat(response.data.data.times);

            this.original_times = JSON.parse(JSON.stringify(this.times));

        } catch(e) {
            this.hodu_show_dialog("cancel", "입주 예약 시간 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 저장
     */
    async save() : Promise<void> {

        const valid_list : any[] = [];
        for( const time of this.times ) {
            valid_list.push({
                "index" : this.times.indexOf(time),
                "start" : Number(time.time_start),
                "end" : Number(time.time_end)
            });
        }
        for( const time of this.times ) {
            const index = this.times.indexOf(time);
            const start = Number(time.time_start);
            const end = Number(time.time_end);

            const valid_check = valid_list.filter(valid => valid.index != index &&
                                                           ((valid.start <= start && start <= valid.end) ||
                                                           (valid.start <= end && end <= valid.end)));

            if( valid_check.length > 0 ) {
                this.hodu_show_dialog("alert", `${ index + 1 }회차 시간이 다른 회차의 시간과 겹치는 구간이 존재합니다`, ['확인']);
                return;
            }
        }

        this.hodu_show_dialog("alert", "설정 변경시 현재 예약된 입주 예약이 전부 삭제 됩니다\n정말로 설정을 변경하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {

                try {
                    
                    const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time`, API_METHOD.POST, {
                        group_id : this.scope_group_id,
                        times : this.times,
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("입주 예약 라인 설정 중 오류 발생");
                    }

                    this.hodu_show_dialog("success", '입주 예약 시간 설정 완료', ['확인'], [() => {
                        this.getMoveIntoTimes();
                    }]);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "입주 예약 시간 설정 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ]);
    }

    /**
     * 입주 예약 시간 추가
     */
    async add() {

        this.times.push({
            "time_id" : "",
            "time_seq" : this.times.length + 1,
            "group_id" : this.scope_group_id,
            "time_start" : "0000",
            "time_end" : "2359",
        });
        // try {

        //     const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time`, API_METHOD.POST, {
        //         "group_id" : this.scope_group_id,
        //         "time_start" : "0000",
        //         "time_end" : "2359",
        //     });

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("입주 예약 시간 추가 중 오류 발생");
        //     }

        //     this.getMoveIntoTimes();

        // } catch(e) {
        //     this.hodu_show_dialog("cancel", "입주 예약 시간 추가 중 오류 발생", ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // }
    }

    /**
     * 시작시간 변경
     */
    async startTimeChange(event, time) {

        const value = Number(event.target.value);
        
        const new_time = this.getTime(value, '');
        const new_end_time = this.getTime(value + 30, '');

        time.time_start = new_time;
        time.time_end = new_end_time;
        this.$forceUpdate();

        // try {

        //     // 해당 time_id 로 등록된 예약(오늘을 포함한 이후 예약)이 있다면 취소된다는 메시지가 떠야함
        //     // TODO 유효성 체크

        //     const value = Number(event.target.value);

        //     const new_time = this.getTime(value, '');
        //     const new_end_time = this.getTime(value + 30, '');

        //     console.log(`${new_time} ${new_end_time}`);

        //     const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time/${time.time_id}`, API_METHOD.PUT, {
        //         "group_id" : this.scope_group_id,
        //         "time_start" : new_time,
        //         "time_end" : new_end_time,
        //     });

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("입주 예약 시간 수정 중 오류 발생");
        //     }

        // } catch(e) {
        //     this.hodu_show_dialog("cancel", "입주 예약 시간 수정 중 오류 발생", ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // } finally {
        //     this.getMoveIntoTimes();
        // }
    }

    /**
     * 종료시간 변경
     */
    async endTimeChange(event, time) {

        const value = Number(event.target.value);
        const new_time = this.getTime(value, '');
        time.time_end = new_time;

        if( Number(time.time_start) >= Number(time.time_end) ) {
            const new_start_time = this.getTime(value - 30, '');
            time.time_start = new_start_time;
        }

        this.$forceUpdate();

        // try {

        //     // 해당 time_id 로 등록된 예약(오늘을 포함한 이후 예약)이 있다면 취소된다는 메시지가 떠야함
        //     // TODO 유효성 체크

        //     const value = Number(event.target.value);
        //     const new_time = this.getTime(value, '');

        //     const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time/${time.time_id}`, API_METHOD.PUT, {
        //         "group_id" : this.scope_group_id,
        //         "time_end" : new_time
        //     });

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("입주 예약 시간 수정 중 오류 발생");
        //     }

        // } catch(e) {
        //     this.hodu_show_dialog("cancel", "입주 예약 시간 수정 중 오류 발생", ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // } finally {
        //     this.getMoveIntoTimes();
        // }
    }

    /**
     * 예약 삭제
     */
    async deleteMoveIntoTime(time) : Promise<void> {

        this.times.splice(this.times.indexOf(time), 1);
        // try {

        //     const response = await this.hodu_api_call(`socket_api/v1/home/move_into/time/${time.time_id}`, API_METHOD.DELETE, {
        //         "group_id" : this.scope_group_id
        //     });

        //     if( !response || !this.isHttpStatusSuccess(response.status) ) {
        //         throw new Error("입주 예약 시간 삭제 중 오류 발생");
        //     }

        // } catch(e) {
        //     this.hodu_show_dialog("cancel", "입주 예약 시간 삭제 중 오류 발생", ['확인']);
        //     this.hodu_error_process(e, false, false, true);
        // } finally {
        //     this.getMoveIntoTimes();
        // }
    }

    /**
     * 시간값 계산
     */
    getTime(time : number, fix : string) {

        if( time >= 1440 ) {
            return `23${fix}59`;
        }

        const hour = Math.floor(time / 60);
        const min = time % 60;

        return `${`0${hour}`.slice(-2)}${fix}${`0${min}`.slice(-2)}`;
    }

    /**
     * 저장 활성화 여부 (하나라도 달라진게 있는 경우에만)
     */
    isSaveable() : boolean {
        return JSON.stringify(this.times) != JSON.stringify(this.original_times);
    }

}
