
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupBoardReadInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {

    }
}) export default class GroupBoardRead extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_board_read_info !: GroupBoardReadInfo

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupBoardReadInfo ?: (params : GroupBoardReadInfo) => void;

    user_list : any[] = [];
    unread_user_list : any[] = [];

    tab : string = "READ";

    mounted() : void {
        this.getNotificationRead();
    }

    /**
     * 공지사항 읽은 사람 조회
     */
    getNotificationRead() : void {
        const vue = this;

        this.hodu_api_call(`api/v1/groups/${this.group_board_read_info.group_id}/board/read/${this.group_board_read_info.board_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                
                // 읽은 사람
                vue.user_list.splice(0, vue.user_list.length);
                vue.user_list = vue.user_list.concat(response.data.data.board_read_user_list);

                // 안 읽은 사람
                vue.unread_user_list.splice(0, vue.unread_user_list.length);
                vue.unread_user_list = vue.unread_user_list.concat(response.data.data.board_unread_user_list);
            })
            .catch((e) => {
                this.doSetGroupBoardReadInfo?.({
                    show_modal : false,
                    group_id : 0,
                    board_id : ''
                });

                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 역할 받기
     */
    getRole(user : any) : string {
        if( user.team_role && user.team_role.length > 0 ) {
            switch( user.team_role[0] ) {
                case 'ADMIN':
                    return '최종관리자';

                case 'TEAM_MANAGER':
                    return '팀장';

                case 'TEAM_USER':
                    return '팀원';
            }
        }

        if( user.group_role && user.group_role.length > 0 ) {
            switch( user.group_role[0] ) {
                case 'ADMIN':
                    return '최종관리자';

                case 'GROUP_MANAGER':
                    return '그룹 관리자';

                case 'GROUP_SUB_MANAGER':
                    return '그룹 부관리자';
                    
                case 'GROUP_USER':
                    return '그룹원'
            }
        }

        return '';
    }

    /**
     * 확인 버튼 클릭
     */
    clickCloseButton() : void {
        this.doSetGroupBoardReadInfo?.({
            show_modal : false,
            group_id : 0,
            board_id : ""
        });
    }

}

