
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object } from '@/model/hodudoc';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalTimeSetting extends Mixins(VueHoduCommon) {

    /**
     * 텍스트 목록
     */
    get computedSettingTextList() : any[] {

        const text_list : any[] = [];

        if( this.hospital_setting_info == null ) { return []; }

        for( const setting of this.hospital_setting_info ) {
            
            const exist = text_list.filter(item => new Date(item.start_date).getTime() == new Date(setting.start_date).getTime());
            
            if( exist.length > 0 ) { continue; }

            text_list.push({
                start_date : setting.start_date,
                end_date : setting.end_date,
                text : `${this.hodu_date_to_format_string(setting.start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(setting.start_date, '요일')} ~ ${this.hodu_date_to_format_string(setting.end_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(setting.end_date, '요일')}`
            })
        }

        // 시간순 정렬
        text_list.sort((item1, item2) : number => {

            const start1 = new Date(item1.start_date);
            const start2 = new Date(item2.start_date);

            if( start1.getTime() > start2.getTime() ) {
                return -1;
            } 
            
            else if ( start1.getTime() < start2.getTime() ) {
                return 1;
            }
            
            return 0;
        });

        return text_list;
    }

    /**
     * 5 이상에서 60에 딱 나눠 떨어지는 5의 배수를 반환
     */
    // get computedValidMinutes() : number[] {
    //     const valid_min : number[] = []; 

    //     for( let i = 5; i <= 60; i+=5 ) {
    //         if( 60 % i == 0 ) { valid_min.push(i); }
    //     }

    //     return valid_min;
    // }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHospitalTimeSettingModalInfo ?: (params : hodu_doc_modal_info.HospitalTimeSettingModalInfo) => void;

    @Prop() event_bus !: Vue;

    selected_time_setting : hodu_doc_object.hospital_setting_info[] = [];
    selected_term : number = 15;
    selected_term_by_people : number = 10;

    selected_start_date : Date = new Date();
    selected_end_date : Date = new Date();

    is_first_create : boolean = false;
    is_updateable : boolean = false;
    
    async mounted() : Promise<void> {
        if( this.event_bus != null ) { this.event_bus.$on('timeSettingResize', this.handleResize); }

        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        this.setScroll();

        // 현재 적용되고 있는 세팅이 없다면 관리자 문의 요청 (반드시 최소 하나의 진료시간 설정이 존재해야한다)
        if( this.hospital_setting_info == null || this.hospital_setting_info.length < 7 ) {    
            this.hodu_show_dialog('cancel', '진료 시간 정보가 없습니다 관리자에게 문의 해주세요', ['확인'], [() => { this.movePrevPage(); }]);
            return;
        }

        const current_date : Date = new Date();
        this.selected_time_setting = this.selected_time_setting.concat(this.hospital_setting_info.filter(
            item => new Date(item.start_date).getTime() <= current_date.getTime() && 
                    new Date(item.end_date).getTime()   >= current_date.getTime()
        ));

        this.selected_time_setting.sort((item1, item2) : number => {
            
            if( Number(item1.day_of_week) < Number(item2.day_of_week) ) {
                return -1;
            }
            
            else if ( Number(item1.day_of_week) > Number(item2.day_of_week) ) {
                return 1;
            }

            return 0;
        });

        // 예약텀 세팅 데이터 받아오기
        if( this.selected_time_setting.length > 0 ) {
            const setting : hodu_doc_object.hospital_setting_info = this.selected_time_setting[0];
            this.selected_term = setting.reservation_term;
            this.selected_term_by_people = setting.reservation_term_by_people;
            this.selected_start_date = new Date(setting.start_date);
            this.selected_end_date = new Date(setting.end_date);

            // 선택된 세팅에 따라서 수정 가능할지 아닐지 정함 (end_date가 현재보다 옛날이라면 수정 불가)
            this.is_updateable = this.selected_end_date.getTime() > new Date().getTime();
        }
        
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();

        // @ts-ignore
        $('#hospital_time_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0)
        });
    }

    /**
     * 조회 할 근무표 변경
     */
    selectTimeSetting(start_time_millis : number) : void {

        if( this.hospital_setting_info == null ) { return;}

        this.selected_time_setting.splice(0, this.selected_time_setting.length);
        this.selected_time_setting = this.selected_time_setting.concat(this.hospital_setting_info.filter(
            item => new Date(item.start_date).getTime() == start_time_millis
        ));

        this.selected_time_setting.sort((item1, item2) : number => {
            
            if( Number(item1.day_of_week) < Number(item2.day_of_week) ) {
                return -1;
            }
            
            else if ( Number(item1.day_of_week) > Number(item2.day_of_week) ) {
                return 1;
            }

            return 0;
        });

        // 예약텀 세팅 데이터 받아오기
        if( this.selected_time_setting.length > 0 ) {
            const setting : hodu_doc_object.hospital_setting_info = this.selected_time_setting[0];
            this.selected_term = setting.reservation_term;
            this.selected_term_by_people = setting.reservation_term_by_people;
            this.selected_start_date = new Date(setting.start_date);
            this.selected_end_date = new Date(setting.end_date);

            // 선택된 세팅에 따라서 수정 가능할지 아닐지 정함 (end_date가 현재보다 옛날이라면 수정 불가)
            this.is_updateable = this.selected_end_date.getTime() > new Date().getTime();
        }

    }
    
    /**
     * 세팅 선택 여부
     */
    isSelectedSetting(setting : any) : boolean {
        
        const current_date : Date = new Date();

        return new Date(setting.start_date).getTime() <= current_date.getTime() && new Date(setting.end_date).getTime() >= current_date.getTime();
    }

    /**
     * 옵션 태그 오전 시간 문자열 반환
     */
    getTimeOptionAmText(n : number, is_start :boolean) : string {
        const target_date : Date = moment().set('hour', 7).set('minute', (n - 1) * 30 + ( is_start ? 0 : 30 )).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }

    /**
     * 옵션 태그 오후 시간 문자열 반환
     */
    getTimeOptionPmText(n : number, is_start :boolean) : string {
        const target_date : Date = moment().set('hour', 12).set('minute', (n - 1) * 30 + ( is_start ? 0 : 30 )).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }

    /**
     * 선택된 시간 여부 반환
     */
    isSelectedTime(HHmm : string | null, n : number, default_hour : number, default_min : number) : boolean {

        const target_string : string = moment().set('hour', default_hour).set('minute', (n - 1) * 30 + default_min).format('HHmm');

        // console.log(`${target_string}_${HHmm}`);

        return HHmm == target_string;
    }

    /**
     * 병원 근무시간 추가 모달 띄우기
     */
    showHospitalTimeSettingModal(is_create : boolean) : void {
        if( this.doSetHospitalTimeSettingModalInfo == null || this.selected_time_setting.length < 1 ) { return; }

        this.doSetHospitalTimeSettingModalInfo({
            "show_modal" : true,
            "start_date" : new Date(this.selected_time_setting[0].start_date),
            "is_create"  : is_create 
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#hospital_time_setting_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
