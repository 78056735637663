
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class ResidentMoveIntoRequestLineSetting extends Mixins(VueHoduCommon) {

    /**
     * 라인
     */
    get computedLines() : any[] {

        let lines : any[] = [];

        if( this.selected_building != null && this.selected_building.length > 0 ) {
            const filter = this.buildings.filter(building => building.building == this.selected_building);
            if( filter.length > 0 ) {
                lines.splice(0, lines.length);
                lines = lines.concat(filter[0].lines);
            }
        }

        return lines;
    }

    buildings : any[] = [];
    selected_building : string = "";

    original_lines : any[] = [];
    copy_lines : any[] = [];

    mounted() : void {
        this.getBuildings();
    }

    /**
     * 동 조회
     */
    async getBuildings() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/building`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.buildings ) {
                throw new Error("동 조회 중 오류 발생");
            }

            this.buildings.splice(0, this.buildings.length);
            this.buildings = this.buildings.concat(response.data.data.buildings);

            if( this.selected_building.length < 1 && this.buildings.length > 0 ) {
                this.selected_building = this.buildings[0].building;
            }

            this.$nextTick(() => {
                this.original_lines = JSON.parse(JSON.stringify(this.computedLines));
            });

        } catch(e) {
            this.hodu_show_dialog("cancel", "동 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 라인 복사
     */
    copyLines() : void {
        this.copy_lines.splice(0, this.copy_lines.length);
        this.copy_lines = this.copy_lines.concat(this.computedLines);
    }

    /**
     * 라인 붙여넣기
     */
    pasteLines() : void {
        this.computedLines.splice(0, this.computedLines.length);
        for( const line of this.copy_lines ) this.computedLines.push(line);
        this.$forceUpdate();
    }

    /**
     * 라인 삭제
     */
    removeLine(computedLines, line) : void {
        computedLines.splice(computedLines.indexOf(line), 1);
        this.$forceUpdate();
    }

    /**
     * 라인 추가
     */
    add() : void {
        this.computedLines.push({
            building : this.selected_building,
            group_id : this.scope_group_id,
            line_data : {
                start_unit : "",
                end_unit : "",
                name :"",
            },
            line_id :"",
            line_seq : this.computedLines.length + 1
        });
        this.$forceUpdate();
    }

    /**
     * 저장 활성화 여부 (하나라도 달라진게 있는 경우에만)
     */
    isSaveable() : boolean {
        return JSON.stringify(this.computedLines) != JSON.stringify(this.original_lines);
    }

    /**
     * 저장
     */
    async save() : Promise<void> {
        
        const valid_list : any[] = [];
        for( const line of this.computedLines ) {
            valid_list.push({
                "index" : this.computedLines.indexOf(line),
                "start" : Number(line.line_data.start_unit),
                "end" : Number(line.line_data.end_unit)
            });
        }
        for( const line of this.computedLines ) {
            const index = this.computedLines.indexOf(line);
            const start = Number(line.line_data.start_unit);
            const end = Number(line.line_data.end_unit);

            const valid_check = valid_list.filter(valid => valid.index != index &&
                                                           ((valid.start <= start && start <= valid.end) ||
                                                           (valid.start <= end && end <= valid.end)));

            if( line.line_data.name == null || line.line_data.name.trim().length < 1 ) {
                this.hodu_show_dialog("alert", `${ index + 1 }번째 라인의 이름을 입력해주세요`, ['확인']);
                return;
            }

            if( valid_check.length > 0 ) {
                this.hodu_show_dialog("alert", `${ index + 1 }번째 라인의 호수가 다른 라인의 호수와 겹치는 구간이 존재합니다`, ['확인']);
                return;
            }
        }

        this.hodu_show_dialog("alert", "설정 변경시 현재 예약된 입주 예약이 전부 삭제 됩니다\n정말로 설정을 변경하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    const response = await this.hodu_api_call(`socket_api/v1/home/move_into/line`, API_METHOD.POST, {
                        group_id : this.scope_group_id,
                        building : this.selected_building,
                        lines : this.computedLines,
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("입주 예약 라인 설정 중 오류 발생");
                    }

                    this.hodu_show_dialog("success", '입주 예약 라인 설정 완료', ['확인'], [() => {
                        this.getBuildings();
                    }]);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "입주 예약 라인 설정 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                } 
            }
        ]);
    }

    @Watch("selected_building")
    changeBuilding() {
        this.$nextTick(() => {
            this.original_lines = JSON.parse(JSON.stringify(this.computedLines));
        });
    }

}
