
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ExampleDepartments extends Mixins(VueHoduCommon) {

    @Prop() level       !: number;
    @Prop() departments !: any; 

    mounted() : void {
        
    }

}
