
export namespace hodu_color {

    export const hodu_dc_0 : string = "#FF6363";
    export const hodu_dc_1 : string = "#FFA70E";
    export const hodu_dc_2 : string = "#FFC72F";
    export const hodu_dc_3 : string = "#FF198B";
    export const hodu_dc_4 : string = "#00B2C7";
    export const hodu_dc_5 : string = "#13D08B";
    export const hodu_dc_6 : string = "#4DBAFF";
    export const hodu_dc_7 : string = "#477FFF";
    export const hodu_dc_8 : string = "#6854FF";
    export const hodu_dc_9 : string = "#35405A";

    export const hodu_lc_0 : string = "#B9A494";
    export const hodu_lc_1 : string = "#E67D9B";
    export const hodu_lc_2 : string = "#FF9898";
    export const hodu_lc_3 : string = "#AB8DD6";
    export const hodu_lc_4 : string = "#F3AC77";
    export const hodu_lc_5 : string = "#E3D37A";
    export const hodu_lc_6 : string = "#8DD6A0";
    export const hodu_lc_7 : string = "#7197ED";
    export const hodu_lc_8 : string = "#8DAED6";
    export const hodu_lc_9 : string = "#6F7A93";
    
    export const hodu_light_blue_gray : string = "#F1F3F5";
    
    // HODU D APPOINTMENT STATUS COLOR
    export const appointment_request   : string = "#F1F3F5";
    export const appointment_decline   : string = "#FFF0F0";
    export const appointment_confirm   : string = "#13D08B";
    export const appointment_receipt   : string = "#FFC72F";
    export const appointment_diagnosis : string = '#477FFF';
    export const appointment_noshow    : string = '#FF6363';

    // CHATTING BACKGROUND COLOR
    export const chat_color_0 : string = "#FFE0E0";
    export const chat_color_1 : string = "#FFEDCF";
    export const chat_color_2 : string = "#FFF4D5";
    export const chat_color_3 : string = "#FFD1E8";
    export const chat_color_4 : string = "#CCF0F4";
    export const chat_color_5 : string = "#D0F6E8";
    export const chat_color_6 : string = "#DBF1FF";
    export const chat_color_7 : string = "#DAE5FF";
    export const chat_color_8 : string = "#E1DDFF";
    export const chat_color_9 : string = "#D7D9DE";

    export const approval_cancel_color  : string = "#B8BBCE"; 
    export const approval_ing_color     : string = "#13D08B";
    export const approval_reject_color  : string = "#FF6363";
    export const approval_confirm_color : string = "#477FFF";
    export const approval_temp_color    : string = "#FFA70E";
}