
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, EVENT_SUB_TYPE, CRUD_TYPE, API_METHOD, SHARE_OPTION, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const WorkInfo  = namespace('WorkInfo');
const ModalInfo = namespace('ModalInfo');

import { WorkSearchInfo } from '@/store/modules/WorkInfo';

import { t_event } from '@/model/event';
import { t_work_status } from '@/model/osm';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class WorkList extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowWorkStatus        ?: any;
    @ModalInfo.Action doSetShowWorkDefaultStatus ?: any;

    /**
     * @WorkInfo.State
     */
    @WorkInfo.State work_search_info !: WorkSearchInfo;

    /**
     * @WorkInfo.Action
     */
    @WorkInfo.Action doSetWorkSearchInfo ?: any;
    @WorkInfo.Action doSetWorkStatusInfo ?: any;

    work_list : any[] = [];
    work_original_list : any[] = [];

    work_sort_target : string = "TIME";
    work_sort_direction : SORT_TYPE = SORT_TYPE.DESC;

    start_date : Date = new Date();
    end_date : Date = new Date();
    date_term : number = 7;

    startDateString : string = "";
    endDateString   : string = "";

    work_search_query      : string = "";
    work_search_query_temp : string = "";

    search_work_status        : string = "ALL";
    search_work_template_type : string = "ALL";

    grp3_text : string = "전체";
    grp1_text : string = "유형 전체";

    search_on : boolean = false;
    grp3_on   : boolean = false;
    grp1_on   : boolean = false;

    timeout_ms = 500; // 새로고침시 권한 조회가 너무 느린경우 충돌을 일으킴 (이것은 임시방편이고 조회전 설정을 스토어에 등록해놓는것이 좋아보임)

    mounted() : void {
        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                
                inst.input.val(vue.createWorkDateFormat(dateText));
                
                // 시작일 설정시 date_term 유지해서 end일자도 늘림
                if($(this).attr('id')=='work_fr_ymd'){
                    vue.start_date = new Date(dateText);
                    vue.start_date.setHours(0);
                    vue.start_date.setMinutes(0);
                    vue.start_date.setSeconds(0);
                    vue.start_date.setMilliseconds(0);

                    // 사장님 요청으로 간격유지 없앰 (2020-01-22 LSJ)
                    // vue.end_date = new Date(vue.start_date);
                    // vue.end_date.setDate(vue.end_date.getDate() + vue.date_term);
                    // vue.end_date.setHours(23);
                    // vue.end_date.setMinutes(59);
                    // vue.end_date.setSeconds(59);
                    // vue.end_date.setMilliseconds(999);

                    // 시작일보다 종료일이 빠른 경우 종료일을 시작일로 변경한다
                    if( new Date(dateText).getTime() > vue.end_date.getTime() ) {
                        vue.end_date = new Date(dateText);
                        vue.end_date.setHours(0);
                        vue.end_date.setMinutes(0);
                        vue.end_date.setSeconds(0);
                        vue.end_date.setMilliseconds(0);
                    }
                } 
                
                // 종료일 설정시 end일자 변경하고 date_term을 구해 등록
                else {
                    // 종료일자가 시작일보다 빠른 경우 시작일을 종료일로 변경한다
                    if( new Date(dateText).getTime() < vue.start_date.getTime() ) {
                        vue.start_date = new Date(dateText);
                        vue.start_date.setHours(0);
                        vue.start_date.setMinutes(0);
                        vue.start_date.setSeconds(0);
                        vue.start_date.setMilliseconds(0);
                    }

                    vue.end_date = new Date(dateText);
                    vue.end_date.setHours(23);
                    vue.end_date.setMinutes(59);
                    vue.end_date.setSeconds(59);
                    vue.end_date.setMilliseconds(999);

                    vue.date_term = vue.getDateDiff(vue.start_date, vue.end_date);
                }
                
                vue.work_search_info.search_start_date = vue.start_date;
                vue.work_search_info.search_end_date   = vue.end_date;
                vue.doSetWorkSearchInfo(vue.work_search_info);

                vue.startEndDateStringMake();
                vue.getWorkList();

            },
        };

        // @ts-ignore
        $('#work_fr_ymd').datepicker(option);

        // @ts-ignore
        $('#work_to_ymd').datepicker(option);

        // 시작일 종료일 설정 (저번달 1일 ~ 다음달 마지막일)
        this.start_date = moment().add('month', -1).set('date', 1).toDate();
        this.end_date = moment().add('month', 2).set('date', 0).toDate();     
        this.date_term = this.getDateDiff(this.start_date, this.end_date);
        
        this.work_search_query = this.work_search_info.search_query ? this.work_search_info.search_query : "";
        if( this.work_search_query.length > 0 ) {
            this.search_on = true;
            this.work_search_query_temp = this.work_search_query;
        }

        if( this.work_search_info.search_start_date ) {
            this.start_date = new Date(this.work_search_info.search_start_date);
            this.date_term = this.getDateDiff(this.start_date, vue.end_date);
        }

        if( this.work_search_info.search_end_date ) {
            this.end_date = new Date(this.work_search_info.search_end_date);
            this.date_term = this.getDateDiff(this.start_date, vue.end_date);
        }

        if( this.work_search_info.search_template_type ) {
            this.search_work_template_type = this.work_search_info.search_template_type;
        }

        if( this.work_search_info.search_work_status ) {
            this.search_work_status = this.work_search_info.search_work_status;
        }

        this.setScroll();
        this.startEndDateStringMake();
        this.getWorkList();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height      : number | undefined = $('.title_box').outerHeight();
        const setting_bg_height : number | undefined = $('.settingBg').outerHeight();
        const header_height     : number | undefined = $('.sortHeader').outerHeight();
        const final_hegiht      : number             = window.innerHeight - ( title_height ? title_height : 0 ) - ( setting_bg_height ? setting_bg_height : 0 ) 
                                                                          - ( header_height ? header_height : 0 );
        // @ts-ignore
        $('#work_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : final_hegiht
        });

    }
 
    /**
     * 업무 리스트 조회
     */
    getWorkList() : void {

        setTimeout(async() => {
            // 업무 읽기 권한이 없는 경우
            if( this.getWorkReadPermission() == false ) {
                return;
            }
        
            const vue = this;

            let url : string = `api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope == 'GROUP' ? this.scope_group_id : this.scope_team_id }`;
            
            const start : string = moment(this.start_date).utc().format();
            const end : string = moment(this.end_date).utc().format();

            let query : string = `?start=${start}&end=${end}&mycal=false&syscal=false&shared=false&work_template_type=${this.search_work_template_type}&work_status=${this.search_work_status}&search=${this.work_search_query}`;

            // 그룹 업무 리스트 일때
            if( this.scope == OWNER_TYPE.GROUP ) {
                query += `&groups=${ this.scope_group_id }`;
                
                // 가입한 팀 추가
                for( const team_role of this.user_team_role ) {
                    if( team_role.group_id == this.scope_group_id ) {
                        query += `&teams=${ team_role.team_id }`;
                    }   
                }
            } 

            // 팀 업무 리스트 일때
            else {
                query += `&teams=${ this.scope_team_id }`;
            }

            let groups : number[] = [];
            let teams : number[] = [];

            if( this.user_group_role != null && this.user_group_role.length > 0 ) {
                for( const group_role of this.user_group_role ) {
                    if( group_role.group_id == null || group_role.group_id < 1 ) {
                        continue
                    }
                    groups.push(group_role.group_id);
                }
            }

            if( this.user_team_role != null && this.user_team_role.length > 0 ) {
                for( const team_role of this.user_team_role ) {
                    if( team_role.team_id == null || team_role.team_id < 1 ) {
                        continue
                    }
                    teams.push(team_role.team_id);
                }
            }

            this.hodu_api_call(`${url}${query}`, API_METHOD.GET)
                .then((response) => {
                    console.log(response);

                    const temp_works : any[] = [];
                    const response_works = response.data.data.work_list;

                    for( const work of response_works ) {

                        if ( work == null || work.event == null || work.event.event_data == null ) {
                            temp_works.push(work);
                            continue;
                        }

                        const event = work.event;
                        const event_data = event.event_data;

                        // 숨김 처리 아니라면 추가
                        if( (event_data.is_private ?? false) == false ) {
                            temp_works.push(work);
                            continue;
                        }

                        // 본인 작성이면 숨김상태여도 그대로 추가
                        if( event_data.event_owner_id == this.user_id ) {
                            temp_works.push(work);
                            continue;
                        }

                        // 권한 구하기
                        let is_permitted : boolean = false;
                        if( event.team_id > 0 ) {
                            if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                                is_permitted = this.is_team_permmision(event.team_id, "event", "private_read");
                            }
                            else if( this.isMeetingLog(event.event_sub_type) ) {
                                is_permitted = this.is_team_permmision(event.team_id, "meetinglog", "private_read");
                            }
                            else if( this.isReport(event.event_sub_type) ) {
                                is_permitted = this.is_team_permmision(event.team_id, "report", "private_read");
                            }
                            else if( this.isWork(event.event_sub_type) ) {
                                is_permitted = this.is_team_permmision(event.team_id, "work", "private_read");
                            }
                        }
                        else if( event.group_id > 0 ) {
                            if(  this.isEvent(event.event_sub_type) || this.isCard(event.event_sub_type) ) {
                                is_permitted = this.is_group_permmision(event.group_id, "event", "private_read");
                            }
                            else if( this.isMeetingLog(event.event_sub_type) ) {
                                is_permitted = this.is_group_permmision(event.group_id, "meetinglog", "private_read");
                            }
                            else if( this.isReport(event.event_sub_type) ) {
                                is_permitted = this.is_group_permmision(event.group_id, "report", "private_read");
                            }
                            else if( this.isWork(event.event_sub_type) ) {
                                is_permitted = this.is_group_permmision(event.group_id, "work", "private_read");
                            }
                        }

                        if( is_permitted == true ) {
                            temp_works.push(work);
                            continue;
                        }

                        // 프로젝트의 경우 담당자면 볼 수 있음
                        if( event.event_sub_type, "WORK" && event_data.work != null ) {

                            if( event_data.work?.assignment_type == "ALL" ) {
                                temp_works.push(work);
                                continue;
                            }

                            const assign_user_ids = event_data.work!.assign_user_ids;
                            if( assign_user_ids != null && assign_user_ids.indexOf(this.user_id) > -1 ) {
                                temp_works.push(work);
                                continue;
                            }

                        }
                        
                    }

                    vue.work_list.splice(0, vue.work_list.length);
                    vue.work_list = vue.work_list.concat(temp_works);
                    vue.work_original_list = JSON.parse(JSON.stringify(vue.work_list));

                    vue.workListSort();

                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });

            this.timeout_ms = 1;

        }, this.timeout_ms);
    }

    /**
     * 시작, 종료일 텍스트 생성
     */
    startEndDateStringMake() : void {
        this.startDateString = this.createWorkDateFormat(this.start_date);
        this.endDateString   = this.createWorkDateFormat(this.end_date);
    }

    /**
     * 업무 날짜 형식에 맞게 만들어서 반환
     */
    createWorkDateFormat(request_date : Date) : string {
        if( !(request_date instanceof Date) ) {
            request_date = new Date(moment(request_date).format());
        }

        const year  : string  = `${request_date.getFullYear()}`;
        const month : string = `0${request_date.getMonth() + 1}`.slice(-2);
        const date  : string  = `0${request_date.getDate()}`.slice(-2);

        return `${year}.${month}.${date} ${this.getDayOfWeek(request_date)}`;
    }

    /**
     * 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * 업무의 상태값 텍스트로 반환
     */
    returnTextByWorkStatus(work : any) : string {
        // 단순히 플래그값에 따라서 반환
        switch( work.event.event_data.work.work_status_code ) {
            case "WAIT":
                return '대기';

            case "START":
                return '진행';

            case "END":
                return '완료';

            case "CANCEL":
                return '중단';

            default:
                return '';
        }
    }

    /**
     * 업무자의 상태값 텍스트로 반환
     */
    returnTextByWorkerStatus(status : t_work_status) : string {
        // is_end라면 완료
        if( status.work_status_info.is_end == true ) {
            return '완료';
        }

        // 하나라도 진행된게 있다면 시작
        for( let progress of status.work_status_info.progress ) {
            if( progress.status != 'WAIT' ) {
                return '진행중';
            }
        }
        // 하나도 진행된게 없다면 시작전
        return '시작전';
    }

    /**
     * 업무 배정자 리스트 펼치기 / 접기
     */
    workAssignmentOnOff(e, index : number, work : any) : void {

        e.preventDefault();
        e.stopPropagation();

        // 업무 읽기 권한이 없는 경우
        if( this.getWorkReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        if( work.work_status_info.length < 1 ) {
            return;
        }

        // 기존 리스트 전부 false
        const work_list_length : number = this.work_list.length;
        for( let i = 0; i < work_list_length; i++ ) {
            if( index == i ) {
                continue;
            }

            const work_obj : any = JSON.parse(JSON.stringify(this.work_list[i]));
            work_obj.listOn = false;
            this.work_list.splice(i, 1, work_obj);
        }

        // 클릭한 리스트 ON / OFF
        work.listOn = work.listOn == null ? true : !work.listOn;
        this.work_list.splice(index, 1, work);

        for( let i = 0; i < this.work_original_list.length; i++ ) {
            if( work.event.event_id == this.work_original_list[i].event.event_id ) {
                const original_work : any = JSON.parse(JSON.stringify(this.work_original_list[i]));
                original_work.listOn = work.listOn;
                this.work_original_list.splice(i, 1, original_work);
            }
        }
    }

    /**
     * 업무 생성
     */
    addWork() : void {
        if( this.getWorkCreatePermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 작성 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        const target_date : Date = new Date();
        const start_date : Date = new Date(target_date.getTime());
        const end_date   : Date = new Date(target_date.getTime());

        // 시작 시간이 '정시' 거나 '23시' 일 경우는 그대로 사용한다 
        if( target_date.getMinutes() != 0 && target_date.getHours() != 23 ){
           start_date.setHours(target_date.getHours() + 1);
        }
        
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        // 시작시간이 23시라면 23시 50분 고정, 아니라면 시작시간 + 1시간에 0분
        if( start_date.getHours() == 23 ){
            end_date.setHours(23);
            end_date.setMinutes(50);
        } else {
            end_date.setHours(start_date.getHours() + 1);
            end_date.setMinutes(0);
        }

        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        // event 기본 값 설정
        const event : t_event = {
            "audit_created": new Date(),
            "audit_delete_flag": false,
            "audit_deleted": null,
            "audit_modified": new Date(),
            "audit_user_id": this.user_id,
            "calendar_id": this.calendar_id,
            "event_data": {
                "alarm": [],
                "attachment": {
                    "files": [],
                    "imgs": []
                },
                "attend": false,
                "color": "#477FFF",
                "event_owner_group_id": this.scope_team_id > 0 ? 0 : this.scope_group_id,
                "event_owner_group_name": this.scope_team_id > 0 ? "" : this.scope_group_team_option.group_team_name,
                "event_owner_id": this.user_id,
                "event_owner_name": this.user_name,
                "event_owner_team_id": this.scope_team_id,
                "event_owner_team_name": this.scope_team_id > 0 ? this.scope_group_team_option.group_team_name : "",
                "event_push_yn": true,
                "event_sub_type": EVENT_SUB_TYPE.WORK,
                "location": [],
                "memo": "",
                "note": "",
                "percent_complete": 0,
                "priority": 0,
                "schedule_date": {
                    "end": end_date,
                    "isAllDay": false,
                    "isContinuos": false,
                    "isIgnore": false,
                    "lunar_yn": false,
                    "recurrence_end": end_date,
                    "start":  start_date
                },
                "contacts" : [{
                    "name" : "",
                    "tel" : ""
                }],
                "status": "",
                "title": "",
                "uid": "",
                "version": "1",
                "vote": [],
                "work": {
                    "template_id"         : "", 
                    "template_type"       : "CHECK", 
                    "work_status"         : "대기",
                    "work_status_code"    : "WAIT",
                    "assignment_type"     : "ALL", 
                    "work_status_visible" : true,
                    "audit_modified"      : new Date()
                },
                "is_private" : false,
            },
            "event_id": "",
            "event_type": this.scope,
            "event_sub_type": EVENT_SUB_TYPE.WORK,
            "user_id": 0,
            "team_id": this.scope == OWNER_TYPE.TEAM ? this.scope_team_id : 0,
            "group_id": this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : 0,
            "subscribe_users": [],
            "subscribe_groups": [],
            "subscribe_teams": [],
            "duration" : "",
        }

        // EventInfo에 이벤트 등록
        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.CREATE);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        this.hodu_router_push('/event');
    }

    /**
     * 업무 현황
     */
    showWorkStatus(e, work : any) : void {

        e.preventDefault();
        e.stopPropagation();

        // 업무 읽기 권한이 없는 경우
        if( this.getWorkReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }
        
        // 업무현황이 보이기 여부가 false인데 작성자가 아니라면 진입불가
        if( work.event.event_data.work.work_status_visible == false && work.event.event_data.event_owner_id != this.user_id ) {
            return;
        }

        // 미정업무인데 아무도 시작 안한건 진입불가
        else if( work.event.event_data.work.assignment_type == 'UNDEF' && work.work_status_info.length < 1 ) {
            return;
        }

        // 기본업무 현황 MODAL
        else if( work.event.event_data.work.template_type == 'DEFAULT' ) {
            this.doSetWorkStatusInfo({
                calendar_id : work.event.calendar_id,
                event_id : work.event.event_id,
                scope : work.event.event_type,
                scope_id : work.event.event_type == OWNER_TYPE.GROUP ? work.event.group_id : work.event.team_id
            });
            this.doSetShowWorkDefaultStatus(true);
        }

        // 단계, 체크식 업무 현황 MODAL
        else {
            this.doSetWorkStatusInfo({
                calendar_id : work.event.calendar_id,
                event_id : work.event.event_id,
                scope : work.event.event_type,
                scope_id : work.event.event_type == OWNER_TYPE.GROUP ? work.event.group_id : work.event.team_id
            });
            this.doSetShowWorkStatus(true);
        }
        
    }

    /**
     * grp3 (work_status) 클릭
     */
    grp3Click() : void {
        if( this.getWorkReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.grp3_on = !this.grp3_on;
        this.grp1_on = false;
    }

    /**
     * 검색할 업무상태 선택
     */
    selectWorkStatus(work_status : string) : void {
        if( this.getWorkReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.search_work_status = work_status;
        this.work_search_info.search_work_status = this.search_work_status;
        this.doSetWorkSearchInfo(this.work_search_info);
        this.grp3_on = false;

        switch(work_status) {
            case "ALL":
                this.grp3_text = "전체";
                break;

            case "WAIT":
                this.grp3_text = "대기";
                break;

            case "START":
                this.grp3_text = "진행";
                break;

            case "END":
                this.grp3_text = "완료";
                break;

            case "CANCEL":
                this.grp3_text = "중단";
                break;
        }

        this.getWorkList();
    }

    /**
     * grp1 (template) 클릭
     */
    grp1Click() : void {
        if( this.getWorkReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.grp1_on = !this.grp1_on;
        this.grp3_on = false;
    }

    /**
     * 검색할 업무 템플릿 타입 선택
     */
    selectWorkTemplateType(template_type : string) : void {
        if( this.getWorkReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.search_work_template_type = template_type;
        this.work_search_info.search_template_type = this.search_work_template_type;
        this.doSetWorkSearchInfo(this.work_search_info);
        this.grp1_on = false;

        switch(template_type) {
            case "ALL":
                this.grp1_text = "유형 전체";
                break;

            case "DEFAULT":
                this.grp1_text = "기본";
                break;

            case "FLOW":
                this.grp1_text = "단계식";
                break;

            case "CHECK":
                this.grp1_text = "체크식";
                break;
        }

        this.getWorkList();
    }

    /**
     * 업무 검색 ON / OFF
     */
    searchOnOff(flag : boolean) : void {
        if( this.getWorkReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.search_on = flag;

        if( flag == false ) {
            this.work_search_query = "";
            this.work_search_query_temp = "";
            this.getWorkList();
        }
        else {
            this.$nextTick(() => $('#project_search').focus());
        }
    }

    /**
     * 업무 검색 keydown
     */
    searchKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.searchWork();
    }

    /**
     * 업무 검색
     */
    searchWork() : void {
        this.work_search_query = this.work_search_query_temp.trim();

        const serach_info = JSON.parse(JSON.stringify(this.work_search_info));
        serach_info.search_query = this.work_search_query;
        this.doSetWorkSearchInfo(serach_info);

        this.getWorkList();
    }

    /**
     * 업무 리스트 단건 클릭 (업무 조회로 이동)
     */
    selectWork(e, event : t_event) : void {

        e.preventDefault();
        e.stopPropagation();

        if( this.getWorkReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.READ);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 일정 등록 페이지로 이동 
        this.hodu_router_push(`/event/${ event.event_id }`);
    }

    /**
     * 업무 읽기 권한 반환
     */
    getWorkReadPermission() : boolean {
        
        // 그룹 권한 체크
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "work", "read");
        } 
        
        // 팀 권한 체크
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "work", "read");
        } 
        
        // 개인은 불가능
        else {
            return false;
        }
    }

    /**
     * 업무 생성 권한 반환
     */
    getWorkCreatePermission() : boolean {
        
        // 그룹 권한 체크
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "work", "create");
        } 
        
        // 팀 권한 체크
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "work", "create");
        } 
        
        // 개인은 불가능
        else {
            return false;
        }
    }

    /**
     * 왼쪽 화살표 클릭 - 현재 설정된 date_term 만큼 start와 end를 이전으로 설정해서 조회
     */
    getPreviousWork() : void {
        this.start_date.setDate(this.start_date.getDate() - this.date_term - 1);
        this.end_date.setDate(this.end_date.getDate() - this.date_term - 1);

        this.work_search_info.search_start_date = this.start_date;
        this.work_search_info.search_end_date   = this.end_date;

        this.doSetWorkSearchInfo(this.work_search_info);

        this.startEndDateStringMake();
        this.getWorkList();
    }

    /**
     * 오른쪽 화살표 클릭 - 현재 설정된 date_term 만큼 start와 end를 이후로 설정해서 조회
     */
    getNextWork() : void {
        this.start_date.setDate(this.start_date.getDate() + this.date_term + 1);
        this.end_date.setDate(this.end_date.getDate() + this.date_term + 1);

        this.work_search_info.search_start_date = this.start_date;
        this.work_search_info.search_end_date   = this.end_date;

        this.doSetWorkSearchInfo(this.work_search_info);

        this.startEndDateStringMake();
        this.getWorkList();
    }

    /**
     * 오늘 업무 리스트를 조회
     */
    getTodayWork() : void {
        this.start_date = new Date();
        this.start_date.setHours(0);
        this.start_date.setMinutes(0);
        this.start_date.setSeconds(0);
        this.start_date.setMilliseconds(0);

        this.end_date = new Date();
        this.end_date.setHours(23);
        this.end_date.setMinutes(59);
        this.end_date.setSeconds(59);
        this.end_date.setMilliseconds(999);

        this.work_search_info.search_start_date = this.start_date;
        this.work_search_info.search_end_date   = this.end_date;
        this.date_term = 0;

        this.doSetWorkSearchInfo(this.work_search_info);

        this.startEndDateStringMake();
        this.getWorkList();
    }

    /**
     * 정렬
     */
    sort(sort_type : string) {
        if(this.work_sort_target != sort_type) {
            this.work_sort_target = sort_type;
            this.work_sort_direction = SORT_TYPE.ASC;
            this.workListSort();
            return;
        }

        this.work_sort_direction = (this.work_sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        this.workListSort();
    }
    
    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.work_list.splice(0, this.work_list.length);
        this.work_list = this.work_list.concat(JSON.parse(JSON.stringify(this.work_original_list)));
    }

    /**
     * 업무 리스트 정렬
     */
    workListSort() : void {
        // 업무자 기준 정렬일때
        if( this.work_sort_target == "WORKER" ) {
            const work_count : number = this.work_list.length;
            for( let i = 0; i < work_count; i++ ) {
                const work : any = this.work_list[i];
                if( work.work_status_info == null || work.work_status_info.length < 2 ) { continue; }

                work.work_status_info.sort((o1 : any, o2 : any) : number => {
                    if( this.work_sort_direction == SORT_TYPE.ASC ) {
                        if( o1.user_name  > o2.user_name ) { return  1; } 
                        if( o1.user_name  < o2.user_name ) { return -1; } 
                        if( o1.user_name == o2.user_name ) { return  0; }
                    }

                    if( this.work_sort_direction == SORT_TYPE.DESC ) {
                        if( o1.user_name  > o2.user_name ) { return -1; } 
                        if( o1.user_name  < o2.user_name ) { return  1; } 
                        if( o1.user_name == o2.user_name ) { return  0; }
                    }
                    
                    return 0;
                });
            }
        }

        this.work_list.sort((o1 : any, o2 : any) : number => {
            
            // 기간 오름차순
            if( this.work_sort_target == "TIME" && this.work_sort_direction == SORT_TYPE.ASC ) {
                if( new Date(moment(o1.event.event_data.schedule_date.start).format()).getTime()  > new Date(moment(o2.event.event_data.schedule_date.start).format()).getTime() ) { return  1; } 
                else if( new Date(moment(o1.event.event_data.schedule_date.start).format()).getTime()  < new Date(moment(o2.event.event_data.schedule_date.start).format()).getTime() ) { return -1; } 
                else if( new Date(moment(o1.event.event_data.schedule_date.start).format()).getTime() == new Date(moment(o2.event.event_data.schedule_date.start).format()).getTime() ) { return  0; }
            }

            // 기간 내림차순
            else if( this.work_sort_target == "TIME" && this.work_sort_direction == SORT_TYPE.DESC ) {
                if( new Date(moment(o1.event.event_data.schedule_date.start).format()).getTime()  > new Date(moment(o2.event.event_data.schedule_date.start).format()).getTime() ) { return -1; } 
                else if( new Date(moment(o1.event.event_data.schedule_date.start).format()).getTime()  < new Date(moment(o2.event.event_data.schedule_date.start).format()).getTime() ) { return  1; } 
                else if( new Date(moment(o1.event.event_data.schedule_date.start).format()).getTime() == new Date(moment(o2.event.event_data.schedule_date.start).format()).getTime() ) { return  0; }
            }

            // 그룹 팀 이름 정렬
            else if( this.work_sort_target == 'GROUP_TEAM' ) {
                const o1_group_team_name = o1.team_name && o1.team_name.length > 0 ? o1.team_name : o1.group_name;
                const o2_group_team_name = o2.team_name && o2.team_name.length > 0 ? o2.team_name : o2.group_name;

                if( o1_group_team_name > o2_group_team_name ) { return this.work_sort_direction == SORT_TYPE.ASC ? 1 : -1; } 
                else if( o1_group_team_name  < o2_group_team_name ) { return this.work_sort_direction == SORT_TYPE.ASC ? -1 : 1; } 
                else if( o1_group_team_name == o2_group_team_name ) { return 0; }
            }

            // 업무 제목 오름차순
            else if( this.work_sort_target == "TITLE" && this.work_sort_direction == SORT_TYPE.ASC ) {
                if( o1.event.event_data.title  > o2.event.event_data.title ) { return  1; } 
                else if( o1.event.event_data.title  < o2.event.event_data.title ) { return -1; } 
                else if( o1.event.event_data.title == o2.event.event_data.title ) { return  0; }
            }

            // 업무 제목 내림차순
            if( this.work_sort_target == "TITLE" && this.work_sort_direction == SORT_TYPE.DESC ) {
                if( o1.event.event_data.title  > o2.event.event_data.title ) { return -1; } 
                else if( o1.event.event_data.title  < o2.event.event_data.title ) { return  1; } 
                else if( o1.event.event_data.title == o2.event.event_data.title ) { return  0; }
            }

            // 미정 or 첫번째 업무자명 오름차순
            if( this.work_sort_target == "WORKER" && this.work_sort_direction == SORT_TYPE.ASC ) {
                if( (o1.work_status_info == null || o1.work_status_info.length < 1) && (o2.work_status_info == null || o2.work_status_info.length < 1) ) { return  0; }
                else if( (o1.work_status_info == null || o1.work_status_info.length < 1) && (o2.work_status_info != null && o2.work_status_info.length > 0) ) { return -1; }
                else if( (o1.work_status_info != null && o1.work_status_info.length > 0) && (o2.work_status_info == null || o2.work_status_info.length < 1) ) { return  1; }
                
                else if( o1.work_status_info[0].user_name  > o2.work_status_info[0].user_name ) { return  1; } 
                else if( o1.work_status_info[0].user_name  < o2.work_status_info[0].user_name ) { return -1; } 
                else if( o1.work_status_info[0].user_name == o2.work_status_info[0].user_name ) { return  0; }
            }

            // 미정 or 첫번째 업무자명 내림차순
            if( this.work_sort_target == "WORKER" && this.work_sort_direction == SORT_TYPE.DESC ) {
                if( (o1.work_status_info == null || o1.work_status_info.length < 1) && (o2.work_status_info == null || o2.work_status_info.length < 1) ) { return  0; }
                else if( (o1.work_status_info == null || o1.work_status_info.length < 1) && (o2.work_status_info != null && o2.work_status_info.length > 0) ) { return  1; }
                else if( (o1.work_status_info != null && o1.work_status_info.length > 0) && (o2.work_status_info == null || o2.work_status_info.length < 1) ) { return -1; }

                else if( o1.work_status_info[0].user_name  > o2.work_status_info[0].user_name ) { return -1; } 
                else if( o1.work_status_info[0].user_name  < o2.work_status_info[0].user_name ) { return  1; } 
                else if( o1.work_status_info[0].user_name == o2.work_status_info[0].user_name ) { return  0; }
            }

            // 업무명 오름차순
            if( this.work_sort_target == "WORK" && this.work_sort_direction == SORT_TYPE.ASC ) {
                if( (o1.work_template_info == null || o1.work_template_info.template_info == null) && (o2.work_template_info == null || o2.work_template_info.template_info == null) ) { return  0; }
                else if( (o1.work_template_info == null || o1.work_template_info.template_info == null) && (o2.work_template_info != null && o2.work_template_info.template_info != null) ) { return -1; }
                else if( (o1.work_template_info != null && o1.work_template_info.template_info != null) && (o2.work_template_info == null || o2.work_template_info.template_info == null) ) { return  1; }

                else if( o1.work_template_info.template_info.name  > o2.work_template_info.template_info.name ) { return  1; } 
                else if( o1.work_template_info.template_info.name  < o2.work_template_info.template_info.name ) { return -1; } 
                else if( o1.work_template_info.template_info.name == o2.work_template_info.template_info.name ) { return  0; }
            }

            // 업무명 내림차순
            if( this.work_sort_target == "WORK" && this.work_sort_direction == SORT_TYPE.DESC ) {
                if( (o1.work_template_info == null || o1.work_template_info.template_info == null) && (o2.work_template_info == null || o2.work_template_info.template_info == null) ) { return  0; }
                else if( (o1.work_template_info == null || o1.work_template_info.template_info == null) && (o2.work_template_info != null && o2.work_template_info.template_info != null) ) { return  1; }
                else if( (o1.work_template_info != null && o1.work_template_info.template_info != null) && (o2.work_template_info == null || o2.work_template_info.template_info == null) ) { return -1; }

                else if( o1.work_template_info.template_info.name  > o2.work_template_info.template_info.name ) { return -1; } 
                else if( o1.work_template_info.template_info.name  < o2.work_template_info.template_info.name ) { return  1; } 
                else if( o1.work_template_info.template_info.name == o2.work_template_info.template_info.name ) { return  0; }
            }

            return 0;
        })
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#work_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
