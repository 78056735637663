
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { hodu_doc_table, hodu_doc_object } from '../../model/hodudoc';
import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalHolidaySetting extends Mixins(VueHoduCommon) {
    
    /**
     * 선택한 년도에 따른 오름차순 휴가 리스트
     */
    get computedHolidays() : any[] {
        if ( this.legal_holidays.length < 1 ) { return [] }
        let filtered_list : hodu_doc_object.public_holiday_info[] = JSON.parse(JSON.stringify(this.legal_holidays))
        
        for (let i=0; i<filtered_list.length; i++) {
            let holiday = filtered_list[i]
            if( this.computedWorkingHolidays.includes(holiday.solar_ymd) ) {
                filtered_list[i].is_work_day = false
                continue;
            }
            filtered_list[i].is_work_day = true
        }

        return this.sortHolidays(filtered_list);
    }

    /**
     * 일하는 공휴일의 날짜 string 리스트  ex. ["20200101", "20211231"]
     */
    get computedWorkingHolidays() : string[] {
        let workingHolidayDates : string[] = []
        for ( const workingholiday of this.hospital_working_holidays) {
            let start_date = moment(new Date(workingholiday.start_date)).format("YYYYMMDD")
            if ( start_date != null ) {
                workingHolidayDates.push(start_date)
            }
        }
        return workingHolidayDates
    }

    @Prop() event_bus !: Vue;
    
    year_list                   : number[] = []
    selected_year               : number = new Date().getFullYear()
    is_year_list_open           : boolean = false;
    legal_holidays              : hodu_doc_object.public_holiday_info[] = [];
    hospital_working_holidays   : any[] = [];
    holiday_sort_target         : string = "DATE";
    holiday_sort_direction      : string = "ASC";

    async mounted() : Promise<void> {
        if( this.event_bus != null ) { this.event_bus.$on('holidaySettingResize', this.handleResize); }
        this.setScroll();
        this.getLegalHolidays(new Date().getFullYear())

        const curr_year = new Date().getFullYear()
        for(let i=curr_year; i<=2050; i++) {
            this.year_list.push(i)
        }
    }

    async getLegalHolidays(year: number) : Promise<void> {
        let start_date = moment(new Date()).set("years", year).set("months", 0).set("dates",1).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate();
        let end_date   = moment(start_date).set("months", 11).set("dates",31).toDate();
        
        await this.getHolidays(this.selected_year)
        await this.getHospitalDayOffs()
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hospital_holiday_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });

        // @ts-ignore
        $('#year_dropdown').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 50,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    sortHolidays(list : hodu_doc_object.public_holiday_info[]) : hodu_doc_object.public_holiday_info[] {
        return list.sort((holiday1, holiday2) : number => {
            // 날짜 오름차순
            if( this.holiday_sort_target == "DATE" && this.holiday_sort_direction == "ASC" ) {
                if( holiday1.solar_ymd  > holiday2.solar_ymd ) { return  1; } 
                if( holiday1.solar_ymd  < holiday2.solar_ymd ) { return -1; } 
                if( holiday1.solar_ymd == holiday2.solar_ymd ) { return  0; }
            }

            // 날짜 내림차순
            if( this.holiday_sort_target == "DATE" && this.holiday_sort_direction == "DESC" ) {
                if( holiday1.solar_ymd  > holiday2.solar_ymd ) { return -1; }
                if( holiday1.solar_ymd  < holiday2.solar_ymd ) { return  1; }
                if( holiday1.solar_ymd == holiday2.solar_ymd ) { return  0; }
            }

            // 휴일명 오름차순
            if( this.holiday_sort_target == "NAME" && this.holiday_sort_direction == "ASC" ) {
                if( holiday1.holi_name  > holiday2.holi_name ) { return  1; }
                if( holiday1.holi_name  < holiday2.holi_name ) { return -1; }
                if( holiday1.holi_name == holiday2.holi_name ) { return  0; }
            }

            // 휴일명 내림차순
            if( this.holiday_sort_target == "NAME" && this.holiday_sort_direction == "DESC" ) {
                if( holiday1.holi_name  > holiday2.holi_name ) { return -1; }
                if( holiday1.holi_name  < holiday2.holi_name ) { return  1; }
                if( holiday1.holi_name == holiday2.holi_name ) { return  0; }
            }

            // 휴무 여부 오름차순
            if( this.holiday_sort_target == "OFFYN" && this.holiday_sort_direction == "ASC" ) {
                if( holiday1.is_work_day == true && holiday2.is_work_day == false ) { return  1; }
                if( holiday1.is_work_day == false && holiday2.is_work_day == true ) { return  -1; }
                if( holiday1.is_work_day == holiday2.is_work_day ) { return  0; }
            }

            // 휴무 여부 내림차순
            if( this.holiday_sort_target == "OFFYN" && this.holiday_sort_direction == "DESC" ) {
                if( holiday1.is_work_day == true && holiday2.is_work_day == false ) { return  -1; }
                if( holiday1.is_work_day == false && holiday2.is_work_day == true ) { return  1; }
                if( holiday1.is_work_day == holiday2.is_work_day ) { return  0; }
            }

            return 0;
        });
    }

    sortBy(target: string) {
        // 이미 DATE Sort 중이라면
        if( this.holiday_sort_target == target ) {
            // ASC -> DESC  순으로 변경 되어야 한다
            switch( this.holiday_sort_direction ) {
                case "ASC":
                    this.holiday_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.holiday_sort_direction = "ASC";
                    break; 
            }
        }
        
        // DATE Sort가 아니였다면
        else {
            this.holiday_sort_target = target;
            this.holiday_sort_direction = "ASC";
        }
    }

    getYearString(date_str : string) : string {
        return moment(date_str).format("YYYY")
    }

    toggleYearSelectMenu(event) : void {
        event.stopPropagation();
        this.is_year_list_open = !this.is_year_list_open;
    }

    changeHoliday(event, holiday : hodu_doc_object.public_holiday_info) : void {
        // if( event.target.value == 'no' ) {
        //     $(event.target).parent().parent().addClass("gray");
        //     $(".holidayUl").addClass("on");
        // } else {
        //     $(event.target).parent().parent().removeClass("gray");
        //     $(".holidayUl").removeClass("on");
        // }
        // $(event.target).blur();

        //! 병원 지정 휴일에 들어가있던 공휴일 휴무를 지운다
        if ( event.target.value == 'no' ) {
            for (const day_off of this.hospital_working_holidays) {
                let start_date = moment(day_off.start_date).format("YYYYMMDD")
                
                if ( start_date == holiday.solar_ymd && day_off.off_name == holiday.holi_name ) {
                    const delete_day_off = JSON.parse(JSON.stringify(day_off))
                    delete_day_off.is_public_holiday = event.target.value == 'no' ? false : true
                    this.deleteHolidayOff([delete_day_off.hospital_off_id])
                }
            }
        //! 해당 공휴일에 쉬기 위해 병원 지정 휴일에 공휴일 데이터를 넣어준다
        }else{
            let day_off : hodu_doc_table.t_hospital_day_offs = {
                biz_id : this.scope_group_team_option.biz_id,
                start_date          : moment(holiday.solar_ymd).set("hours", 0).set("minutes", 0).set("seconds", 0).toDate(),
                end_date            : moment(holiday.solar_ymd).set("hours", 23).set("minutes", 59).set("seconds", 59).toDate(),
                off_name            : holiday.holi_name,
                off_reason          : holiday.holi_name,
                is_public_holiday   : true,
                audit_created       : new Date(),
                audit_created_id    : this.user_id,
                hospital_off_id     : "",
            }
            this.createHolidayOff(day_off)
        }
    }

    changeYear(year : number) : void {
        this.selected_year = year;
        this.getLegalHolidays(this.selected_year);
        this.is_year_list_open = false;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#hospital_holiday_setting_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

    getDateString(date_str: string) : string {
        const month = moment(date_str).get("months") + 1
        const dates = moment(date_str).get("dates")
        return `${month}월 ${dates}일`
    }
    
    /**
     * 공휴일 조회
     */
    async getHolidays(year: number) : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/holiday?year=${year}`, API_METHOD.GET )
            .then((response) => {
                let jsonData : hodu_doc_object.public_holiday_info[] = JSON.parse(JSON.stringify(response.data.data))
                this.legal_holidays.splice(0, this.legal_holidays.length)
                this.legal_holidays = this.legal_holidays.concat(jsonData)
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 조회
     */
    async getHospitalDayOffs() : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/workingholiday?year=0`, API_METHOD.GET )
            .then((response) => {
                let jsonData : any[] = JSON.parse(JSON.stringify(response.data.data))
                this.hospital_working_holidays.splice(0, this.hospital_working_holidays.length)
                this.hospital_working_holidays = this.hospital_working_holidays.concat(jsonData)
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 해당 공휴일에 휴무 여부 설정 (병원 지정 휴일 수정 api 입니다.)
     */
    async createHolidayOff(dayoff: hodu_doc_table.t_hospital_day_offs) : Promise<void> {
        let body = {
            biz_id              : this.scope_group_team_option.biz_id,
            off_name            : dayoff.off_name,
            off_reason          : dayoff.off_reason,
            start_date          : new Date(dayoff.start_date),
            end_date            : new Date(dayoff.end_date),
            is_public_holiday   : dayoff.is_public_holiday
        }
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.POST, body)
            .then((response) => {
                let jsonData = JSON.parse(JSON.stringify(response.data.data));
                // let day_off = jsonData as hodu_doc_table.t_hospital_doctor_day_offs

                // if ( day_off.off_name != null && day_off.off_name.length > 0 ) {
                    // this.getHospitalDayOffs();
                    this.getLegalHolidays(this.selected_year);
                // }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 삭제 요청
     */
    async deleteHolidayOff(off_ids : string[]) : Promise<void> {
        let body = {
            hospital_off_ids : off_ids,
            delete_db        : true
        }
        console.log(body.hospital_off_ids);
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.DELETE, body)
            .then((response) => {
                this.getLegalHolidays(this.selected_year);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

}
