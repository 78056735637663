
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, CRUD_TYPE, EVENT_SUB_TYPE, SHARE_OPTION, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonSelectFriendAndGroupTeamModalInfo } from '@/store/modules/ModalInfo';
import { GroupTeamInfo } from '@/store/modules/GroupInfo';
import { hodu_color } from '@/common/color';

@Component({
    components: {
        
    },
})
export default class CommonSelectFriendAndGroupTeamModal extends Mixins(VueHoduCommon) {

    SHARE_OPTION : any = SHARE_OPTION;

    /**
     * 친구 정보
     */
    get computedFriends() : any[] {

        let computed_friends : any[]  = JSON.parse(JSON.stringify(this.friends.filter(friend => friend.is_hide == false && friend.is_block == false)));
        
        if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
            computed_friends = computed_friends.filter(friend => (friend.user_email != null && friend.user_email.length > 0 ) );
        }
    
        if( this.friend_search_query.trim().length > 0 ) {
            computed_friends = computed_friends.filter(friend => this.hodu_string_includes(this.getFriendName(friend), this.friend_search_query) ||
                                                                 (friend.user_phone_number != null && this.hodu_string_includes(friend.user_phone_number, this.friend_search_query)) ||
                                                                 (friend.user_email != null && this.hodu_string_includes(friend.user_email, this.friend_search_query)) );
        }

        return computed_friends.sort((o1, o2) : number => {

            let o1_name = this.getFriendName(o1);
            let o2_name = this.getFriendName(o2);

            if( this.friend_sort_type == 'NAME' ) {

                if( o1_name < o2_name ) return this.friend_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_name > o2_name ) return this.friend_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                return 0;

            }

            return 0;
        });
    }

    /**
     * 그룹 정보
     */
    get computedGroupInfo() : any[] {

        let computed_group_info = JSON.parse(JSON.stringify(this.group_info_list));

        // 미인증시 호두홈, 호두키즈는 보이지 않는다
        if( this.auth_info == null || this.auth_info.auth_check == false ) {
            computed_group_info = computed_group_info.filter(
                group => group.biz_type != 'BIZH' && group.group_info.project_type != 'kids' 
            );
        }

        // 제외 해야하는 그룹&팀 제외
        if( this.calendar_scope_group_id > -1 && this.calendar_scope_team_id > -1 ) {
            if( this.calendar_scope_team_id < 1 ) {
                computed_group_info = computed_group_info.filter(group => group.group_id != this.calendar_scope_group_id);
            }
            else {
                for( const group of computed_group_info ) {
                    if( group.group_id != this.calendar_scope_group_id ) continue;
                    const group_copy = JSON.parse(JSON.stringify(group));
                    group_copy.teams = group_copy.teams.filter(team => team.team_id != this.calendar_scope_team_id);
                    computed_group_info.splice(computed_group_info.indexOf(group), 1, group_copy);
                    break;
                }
            }
        }

        if( this.group_team_search_query.trim().length > 0 ) {

            let search_list : any[] = [];

            const group_count : number = computed_group_info.length;
            for( let i = 0; i < group_count; i++ ) {
                const group_obj : GroupTeamInfo = JSON.parse(JSON.stringify(computed_group_info[i]));
                
                // 그룹 이름이 매칭 된다면 push
                if( this.hodu_string_includes(group_obj.group_name, this.group_team_search_query) ) {
                    
                    // 팀 중에도 매칭되는게 있다면 펼쳐서 보여준다
                    if( group_obj.teams.filter(team => this.hodu_string_includes(team.team_name, this.group_team_search_query)).length > 0 ) {
                        group_obj.shareListOn = true;
                    }

                    search_list.push(group_obj);
                    continue;
                }

                group_obj.teams = group_obj.teams.filter(team => team.team_name.indexOf(this.group_team_search_query) > - 1);
                
                // 팀 이름이 하나라도 매칭 된다면 push, 팀 이름만 매칭되는 경우는 리스트를 펼쳐준다
                if( group_obj.teams.length > 0 ) {
                    group_obj.shareListOn = true;
                    search_list.push(group_obj);
                }
                
            }

            computed_group_info = JSON.parse(JSON.stringify(search_list));
        }

        if( this.group_team_sort_type != null ) {

            // 팀 정렬
            for( const group of computed_group_info ) {
                if( group.teams != null && group.teams.length > 0 ) {
                    group.teams.sort((o1, o2) : number => {
                        const o1_team_name = o1.team_name;
                        const o2_team_name = o2.team_name;

                        const o1_user_count = o1.user_count;
                        const o2_user_count = o2.user_count;

                        if( this.group_team_sort_type == "NAME" ) {
                            if( o1_team_name < o2_team_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                            else if( o1_team_name > o2_team_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                            return 0;
                        }

                        if( this.group_team_sort_type == "COUNT" ) {
                            if( o1_user_count < o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                            else if( o1_user_count > o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                            return 0;
                        }

                        return 0;
                    });
                }
            }

            // 그룹 정렬
            computed_group_info.sort((o1, o2) : number => {

                const o1_group_name = o1.group_name;
                const o2_group_name = o2.group_name;

                const o1_user_count = o1.user_count;
                const o2_user_count = o2.user_count;

                if( this.group_team_sort_type == "NAME" ) {
                    if( o1_group_name < o2_group_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                    else if( o1_group_name > o2_group_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                    return 0;
                }

                if( this.group_team_sort_type == "COUNT" ) {
                    if( o1_user_count < o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                    else if( o1_user_count > o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                    return 0;
                }

                return 0;
            });

        }

        return computed_group_info;
    }

    /**
     * 선택 된 그룹&팀의 멤버 정보
     */
    get computedGroupTeamMember() : any[] {

        let computed_group_team_member : any[] = JSON.parse(JSON.stringify(this.group_team_members));

        if( this.group_team_member_search_query.trim().length > 0 ) {
            computed_group_team_member = computed_group_team_member.filter(member => member.user_info.user_name.toUpperCase().indexOf(this.group_team_member_search_query.trim().toUpperCase()) > -1)
        }

        // 같은 그룹내 멤버중 직급이 높은 순서대로 + 같은 직급의 가나다순
        return computed_group_team_member.sort((o1, o2) : number => {

            const o1_role_level = o1.role_level;
            const o2_role_level = o2.role_level;
            const o1_user_name  = o1.user_info.user_name;
            const o2_user_name  = o2.user_info.user_name;

            if( this.member_sort_type == 'NAME' ) {
                if( o1_user_name < o2_user_name ) return this.member_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_user_name > o2_user_name ) return this.member_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_role_level < o2_role_level ) return -1;
                else if( o1_role_level > o2_role_level ) return 1;
                return 0;
            }

            if( this.member_sort_type == 'ROLE' ) {
                if( o1_role_level < o2_role_level ) return this.member_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                else if( o1_role_level > o2_role_level ) return this.member_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_user_name < o2_user_name ) return -1;
                else if( o1_user_name > o2_user_name ) return 1;
                return 0;
            }

            return 0;
        });
    }

    /**
     * 이메일이 있는 멤버가 존재하는 그룹&팀 
     */
    get computedEmailGroupInfo() : any[] {

        let computed_email_group_info : any[] = JSON.parse(JSON.stringify(this.email_group_team_list));

        // 미인증시 호두홈, 호두키즈는 보이지 않는다
        if( this.auth_info == null || this.auth_info.auth_check == false ) {
            computed_email_group_info = computed_email_group_info.filter(
                group => group.group_type != 'BIZH' && group.group_info.project_type != 'kids' 
            );
        }

        // 제외 해야하는 그룹&팀 제외
        if( this.calendar_scope_group_id > -1 && this.calendar_scope_team_id > -1 ) {
            if( this.calendar_scope_team_id < 1 ) {
                computed_email_group_info = computed_email_group_info.filter(group => group.group_id != this.calendar_scope_group_id);
            }
            else {
                for( const group of computed_email_group_info ) {
                    if( group.group_id != this.calendar_scope_group_id ) continue;
                    const group_copy = JSON.parse(JSON.stringify(group));
                    group_copy.teams = group_copy.teams.filter(team => team.team_id != this.calendar_scope_team_id);
                    computed_email_group_info.splice(computed_email_group_info.indexOf(group), 1, group_copy);
                    break;
                }
            }
        }

        if( this.group_team_search_query.trim().length > 0 ) {

            let search_list : any[] = [];

            const group_count : number = computed_email_group_info.length;
            for( let i = 0; i < group_count; i++ ) {
                const group_obj : any = JSON.parse(JSON.stringify(computed_email_group_info[i]));
                
                // 그룹 이름이 매칭 된다면 push
                if( this.hodu_string_includes(group_obj.group_info.group_name, this.group_team_search_query) ) {
                    
                    // 팀 중에도 매칭되는게 있다면 펼쳐서 보여준다
                    if( group_obj.teams.filter(team => this.hodu_string_includes(team.team_name, this.group_team_search_query)).length > 0 ) {
                        group_obj.shareListOn = true;
                    }

                    search_list.push(group_obj);
                    continue;
                }

                group_obj.teams = group_obj.teams.filter(team => this.hodu_string_includes(team.team_info.team_name, this.group_team_search_query));
                
                // 팀 이름이 하나라도 매칭 된다면 push, 팀 이름만 매칭되는 경우는 리스트를 펼쳐준다
                if( group_obj.teams.length > 0 ) {
                    group_obj.shareListOn = true;
                    search_list.push(group_obj);
                }
                
            }

            computed_email_group_info = JSON.parse(JSON.stringify(search_list));
        }

        for( const group of computed_email_group_info ) {
            group.user_count = group.group_member_count;
            for( const team of group.teams) team.user_count = team.team_member_count;

            group.teams.sort((o1, o2) : number => {

                const o1_team_id = o1.team_id;
                const o2_team_id = o2.team_id;

                const o1_team_name = o1.team_info.team_name;
                const o2_team_name = o2.team_info.team_name;

                const o1_user_count = o1.user_count;
                const o2_user_count = o2.user_count;

                if( this.group_team_sort_type == null ) {
                    if( o1_team_id < o2_team_id ) return -1;
                    else if( o1_team_id > o2_team_id ) return 1;
                    return 0;
                }

                if( this.group_team_sort_type == "NAME" ) {
                    if( o1_team_name < o2_team_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                    else if( o1_team_name > o2_team_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                    return 0;
                }

                if( this.group_team_sort_type == "COUNT" ) {
                    if( o1_user_count < o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                    else if( o1_user_count > o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                    return 0;
                }

                return 0;
            });
        }

        computed_email_group_info.sort((o1, o2) : number => {
            const o1_group_id = o1.group_id;
            const o2_group_id = o2.group_id;

            const o1_group_name = o1.group_info.group_name;
            const o2_group_name = o2.group_info.group_name;

            const o1_user_count = o1.user_count;
            const o2_user_count = o2.user_count;

            if( this.group_team_sort_type == null ) {
                if( o1_group_id < o2_group_id ) return -1;
                else if( o1_group_id > o2_group_id ) return 1;
                return 0;
            }

            if( this.group_team_sort_type == "NAME" ) {
                if( o1_group_name < o2_group_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_group_name > o2_group_name ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                return 0;
            }

            if( this.group_team_sort_type == "COUNT" ) {
                if( o1_user_count < o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_user_count > o2_user_count ) return this.group_team_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                return 0;
            }

            return 0;
        });

        return computed_email_group_info;
    }

    /**
     * 이메일이 있는 그룹&팀 멤버
     */
    get computedEmailGroupTeamMember() : any[] {

        let computed_email_group_team_member : any[] = JSON.parse(JSON.stringify(this.email_group_team_members));

        if( this.group_team_member_search_query.trim().length > 0 ) {
            computed_email_group_team_member = computed_email_group_team_member.filter(member => this.hodu_string_includes(member.user_name, this.group_team_member_search_query) ||
                                                                                                 this.hodu_string_includes(member.user_email, this.group_team_member_search_query));
        }

        return computed_email_group_team_member.sort((o1, o2) : number => {
            const o1_user_name  = o1.user_name;
            const o2_user_name  = o2.user_name;

            if( this.member_sort_type == 'NAME' ) {
                if( o1_user_name < o2_user_name ) return this.member_sort_direction == SORT_TYPE.ASC ? -1 : 1;
                else if( o1_user_name > o2_user_name ) return this.member_sort_direction == SORT_TYPE.ASC ? 1 : -1;
                return 0;
            }

            return 0;
        });
    }

    /**
     * @ModalInfo.state
     */
    @ModalInfo.State common_select_friend_and_group_team_modal_info !: CommonSelectFriendAndGroupTeamModalInfo;

    share_option : SHARE_OPTION = SHARE_OPTION.SHARE;// 일정 공유 타입
    event_sub_type : EVENT_SUB_TYPE = EVENT_SUB_TYPE.SCHEDULE;
    event_crud_type : CRUD_TYPE = CRUD_TYPE.CREATE;
    event_calendar_id : string = "";
    event_scope : string = "";
    event_scope_id : number = 0;
    event_id : string = "";

    calendar_scope_group_id : number = -1;
    calendar_scope_team_id : number = -1;

    tab : string = "FRIEND"; // FRIEND, GROUP_AND_TEAM
    
    selected_user_ids        : number[] = [];
    selected_group_ids       : number[] = [];
    selected_team_ids        : number[] = [];
    selected_friend_user_ids : number[] = [];
    group_user_selected_key  : string[] = [];
    team_user_selected_key   : string[] = [];

    group_team_members : any[] = [];
    email_group_team_list : any[] = [];
    email_group_team_members : any[] = [];

    selected_group_team : GroupTeamInfo | any = null; // 멤버 리스트를 보기 위해 선택된 그룹, 팀
    view_search_friend : boolean = true; // 친구 검색 중
    view_search_group_team : boolean = false; // 그룹&팀 검색 중
    view_search_member : boolean = false; // 그룹&팀의 멤버 검색 중

    friend_search_query : string = "";
    group_team_search_query : string = "";
    group_team_member_search_query : string = "";

    friend_sort_type        : string = "NAME";
    friend_sort_direction     : SORT_TYPE = SORT_TYPE.ASC;
    group_team_sort_type    : string | null = null; // NAME, COUNT 
    group_team_sort_direction : SORT_TYPE = SORT_TYPE.ASC;
    member_sort_type        : string = "ROLE";      // NAME, ROLE
    member_sort_direction     : SORT_TYPE = SORT_TYPE.DESC;

    async mounted() : Promise<void> {
        // 탭 메뉴
        // $('.tab input').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.mo_content').hide();
        // 	$('.mo_content').eq(borderIndex).show();
        // 	$(this).addClass('on').siblings().removeClass();

        // 	return false;
        // });

        this.tab = this.common_select_friend_and_group_team_modal_info.use_group_select_only == true ? 'GROUP_AND_TEAM' : 'FRIEND'; 
        this.share_option = this.common_select_friend_and_group_team_modal_info?.share_option ?? SHARE_OPTION.SHARE;
        this.event_sub_type = this.common_select_friend_and_group_team_modal_info?.event_sub_type ?? EVENT_SUB_TYPE.SCHEDULE;
        this.event_crud_type = this.common_select_friend_and_group_team_modal_info?.event_crud_type ?? CRUD_TYPE.CREATE;
        this.event_calendar_id = this.common_select_friend_and_group_team_modal_info?.event_calendar_id ?? "";
        this.event_scope = this.common_select_friend_and_group_team_modal_info?.event_scope ?? "";
        this.event_scope_id = this.common_select_friend_and_group_team_modal_info?.event_scope_id ?? 0;
        this.event_id = this.common_select_friend_and_group_team_modal_info?.event_id ?? "";

        this.calendar_scope_group_id = this.common_select_friend_and_group_team_modal_info?.calendar_scope_group_id ?? -1;
        this.calendar_scope_team_id = this.common_select_friend_and_group_team_modal_info?.calendar_scope_team_id ?? -1;
        
        this.selected_user_ids        = this.selected_user_ids.concat(this.common_select_friend_and_group_team_modal_info?.selected_user_ids ?? [])
        this.selected_group_ids       = this.selected_group_ids.concat(this.common_select_friend_and_group_team_modal_info?.selected_group_ids ?? []);
        this.selected_team_ids        = this.selected_team_ids.concat(this.common_select_friend_and_group_team_modal_info.selected_team_ids ?? []);
        this.selected_friend_user_ids = this.selected_friend_user_ids.concat(this.common_select_friend_and_group_team_modal_info?.selected_friend_user_ids ?? []);
        this.group_user_selected_key  = this.group_user_selected_key.concat(this.common_select_friend_and_group_team_modal_info.group_user_selected_key ?? []);
        this.team_user_selected_key   = this.team_user_selected_key.concat(this.common_select_friend_and_group_team_modal_info.team_user_selected_key ?? []);

        if( this.common_select_friend_and_group_team_modal_info.use_need_user_email ) {
            this.member_sort_type = "NAME";
            this.member_sort_direction = SORT_TYPE.ASC;
        }
        else {
            this.member_sort_type = "ROLE";
            this.member_sort_direction = SORT_TYPE.DESC;
        }

        const promise_function = [this.get_group_role_service(), this.getFriends()];
        if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
            promise_function.push(this.getTargetEventShareByEmail());
        }

        this.hodu_show_indicator();
        await Promise.all(promise_function);
        await this.calculateGroupTeamShareChecked();
        this.hodu_hide_indicator();

        this.$nextTick(() => $('#searchTotalTxt').focus());
    }

    /**
     * 이메일 공유 대상 조회
     */
    async getTargetEventShareByEmail() : Promise<void> {

        const url = `api/v1/calendars/${this.event_calendar_id}/events/${this.event_scope}/${this.event_scope_id}/${this.event_id}/share/email`;

        try {
            const response = await this.hodu_api_call(url, API_METHOD.GET, null, false);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.target ) {
                throw new Error("이메일 공유 대상 조회 실패");
            }

            if( this.auth_info == null || this.auth_info.auth_check == false ) {
                response.data.data.target = response.data.data.target.filter(
                    group => group.group_type != 'BIZH' && group.group_info.project_type != 'kids' 
                );
            }

            this.email_group_team_list.splice(0, this.email_group_team_list.length);
            this.email_group_team_list = this.email_group_team_list.concat(response.data.data.target);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 공유 옵션 변경
     */
    shaerOptionChange(share_option : SHARE_OPTION) : void {
        if( this.share_option == share_option ) return;

        this.share_option = share_option;

        // 선택 정보 초기화
        this.reset();
    } 

    /**
     * 탭 변경
     */
    tabChange(tab : string) {
        
        if( this.tab == tab ) return;
        
        this.tab = tab;

        this.view_search_friend = (tab == 'FRIEND');
        this.view_search_group_team = (tab == 'GROUP_AND_TEAM');
        this.view_search_member = false;

        this.friend_search_query = "";
        this.group_team_search_query = "";
        this.group_team_member_search_query = "";
        
        for( const group of this.group_info_list ) {
            group.shareListOn = false;
            this.group_info_list.splice(this.group_info_list.indexOf(group), 1, group);
        }

        this.$nextTick(() => $('#searchTotalTxt').focus());
    }

    // search() : void {
    //     // 그룹, 팀 리스트 보는 중 일때
    //     if( this.selected_group_team == null ) {
    //         if( this.tab == 'FRIEND' ) {
    //             this.view_search_friend = !this.view_search_friend;
    //             this.view_search_group_team = this.view_search_friend;
    //             this.view_search_member = this.view_search_friend;
    //         }
    //         else {
    //             this.view_search_group_team = !this.view_search_group_team;
    //             this.view_search_friend = this.view_search_group_team;
    //             this.view_search_member = this.view_search_group_team;
    //         }
    //     }

    //     // 멤버 리스트 보는 중 일때
    //     else {
    //         this.view_search_member = !this.view_search_member;
    //         this.view_search_group_team = this.view_search_member;
    //         this.view_search_friend = this.view_search_member;
    //     }

    //     // 그룹, 팀 검색을 끄는 경우
    //     if( this.view_search_group_team == false ) {
    //         if( this.tab == 'FRIEND' ) {
    //             this.friend_search_query = '';
    //         }
    //         else {
    //             this.group_team_search_query = '';
    //         }
    //     }

    //     // 멤버 검색을 끄는 경우
    //     if( this.view_search_member == false ) {
    //         this.group_team_member_search_query = '';
    //     }
    // }

    /**
     * 해당 그룹, 팀의 선택 된 유저 수 반환
     */
    getGroupTeamSelectedUserCount(group_and_team : GroupTeamInfo) : number {
        let count = 0;

        if( group_and_team.row_type == 'GROUP' ) {

            if( group_and_team.shareChecked == true && this.selected_group_ids.indexOf(group_and_team.group_id) > -1 ) {
                if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
                    count = group_and_team.user_count;
                }
                else {
                    count = (group_and_team.user_count - 1);
                }
            }
            else {
                const target = this.group_user_selected_key.filter(key => key.includes(`${group_and_team.group_id}-`));
                count = target.length;
            }  

        }

        else if( group_and_team.row_type == 'TEAM' ) {
            
            if( group_and_team.shareChecked == true && this.selected_team_ids.indexOf(group_and_team.team_id) > -1 ) {
                if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
                    count = group_and_team.user_count;
                }
                else {
                    count = (group_and_team.user_count - 1);
                }
            }
            else {
                const target = this.team_user_selected_key.filter(key => key.includes(`${group_and_team.team_id}-`));
                count = target.length;
            }

        }

        return count;
    }

    /**
     * 그룹&팀 멤버 보기
     */
    async showGroupTeamMember(group_team : GroupTeamInfo) : Promise<void> {

        if( this.share_option == SHARE_OPTION.MOVE && group_team.shareChecked == true ) {
            
            if( group_team.row_type == "GROUP" && this.selected_group_ids.indexOf(group_team.group_id) > -1 ) {
                return;
            }

            if( group_team.row_type == "TEAM" && this.selected_team_ids.indexOf(group_team.team_id) > -1 ) {
                return;
            }

        }

        this.selected_group_team = group_team;

        try {
            const target = this.selected_group_team.row_type;
            let group_id = this.selected_group_team.group_id ? this.selected_group_team.group_id : 0;
            let team_id = this.selected_group_team.team_id ? this.selected_group_team.team_id : 0;

            const response = await this.hodu_api_call(`api/v1/${ target == "GROUP" ? 'groups' : 'teams' }/${ target == "GROUP" ? 'getGroupMember' : 'getTeamMember' }/${group_id}${ team_id > 0 ? `/${team_id}` : ''}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list ) {
                throw new Error("멤버 조회 중 오류 발생");
            }

            // 선택 되어야하는 멤버
            if( target == "GROUP" && this.selected_group_ids.indexOf(group_id) > -1 ) {
                for( const member of response.data.data.list ) member.checked = true;
            } 
            else if( target == "GROUP" && this.group_user_selected_key.filter(key => key.includes(`${group_id}-`)).length > 0 ) {
                for( const member of response.data.data.list ) {
                    if( this.group_user_selected_key.filter(key => key.includes(`${group_id}-${member.user_id}`)).length > 0 ) member.checked = true;
                }
            }
            else if( target == "TEAM" && this.selected_team_ids.indexOf(team_id) > -1 ) {
                for( const member of response.data.data.list ) member.checked = true;
            } 
            else if( target == "TEAM" && this.team_user_selected_key.filter(key => key.includes(`${team_id}-`)).length > 0 ) {
                for( const member of response.data.data.list ) {
                    if( this.team_user_selected_key.filter(key => key.includes(`${team_id}-${member.user_id}`)).length > 0 ) member.checked = true;
                }
            }
            
            this.group_team_members.splice(0, this.group_team_members.length);
            this.group_team_members = this.group_team_members.concat(response.data.data.list.filter(member => member.user_id != this.user_id));

            if( (this.selected_group_team.user_count - 1) != this.group_team_members.length ) {
                this.selected_group_team.user_count = this.group_team_members.length + 1;
            } 

            this.view_search_friend = false;
            this.view_search_group_team = false;
            this.view_search_member = true;

        } catch(e) {
            alert("멤버 조회 중 오류 발생")
            this.hodu_error_process(e, false, false, true);
            this.selected_group_team = null;
        }
    }

    /**
     * 이메일 필수 그룹&팀 멤버 보기 
     */
    showEmailGroupTeamMember(group_team : any) : void {
        this.selected_group_team = group_team;

        this.email_group_team_members.splice(0, this.email_group_team_members.length);

        let members : any[] = [];

        if( group_team.row_type == 'GROUP' ) {
            members = this.email_group_team_members.concat(group_team.group_member);
        }
        else {
            members = this.email_group_team_members.concat(group_team.team_member);
        }

        const row_type = group_team.row_type;
        const group_id = group_team.group_id ?? 0;
        const team_id = group_team.team_id ?? 0;

        // 선택 되어야하는 멤버
        if( row_type == "GROUP" && this.selected_group_ids.indexOf(group_id) > -1 ) {
            for( const member of members ) member.checked = true;
        } 
        else if( row_type == "GROUP" && this.group_user_selected_key.filter(key => key.includes(`${group_id}-`)).length > 0 ) {
            for( const member of members ) {
                if( this.group_user_selected_key.filter(key => key.includes(`${group_id}-${member.user_id}`)).length > 0 ) member.checked = true;
                else member.checked = false;
            }
        }
        else if( row_type == "TEAM" && this.selected_team_ids.indexOf(team_id) > -1 ) {
            for( const member of members ) member.checked = true;
        } 
        else if( row_type == "TEAM" && this.team_user_selected_key.filter(key => key.includes(`${team_id}-`)).length > 0 ) {
            for( const member of members ) {
                if( this.team_user_selected_key.filter(key => key.includes(`${team_id}-${member.user_id}`)).length > 0 ) member.checked = true;
                else member.checked = false;
            }
        }
        else {
            for( const member of members ) member.checked = false;
        }

        this.email_group_team_members = this.email_group_team_members.concat(members);

        this.view_search_friend = false;
        this.view_search_group_team = false;
        this.view_search_member = true;
    }

    /**
     * 팀 리스트 펼치기 / 접기 
     */
    async showTeamList(group_team_info : GroupTeamInfo) : Promise<void> {
        group_team_info.shareListOn = group_team_info.shareListOn == null || group_team_info.shareListOn == false ? true : false;

        let group_team : GroupTeamInfo | null = null;

        for( const group of this.group_info_list ) {
            if( group.group_id != group_team_info.group_id ) continue;
            group_team = group;
            break;
        }

        if( group_team != null ) {
            group_team.shareListOn = group_team_info.shareListOn;
        }

        this.$forceUpdate();
    }

    /**
     * 친구 체크
     */
    checkFriend(event, friend_user_id : number) : void {
        const checked : boolean = event.target.checked;

        if( this.share_option == SHARE_OPTION.MOVE ) {
            this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
            this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
            this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
            this.selected_user_ids.splice(0, this.selected_user_ids.length);
            this.selected_group_ids.splice(0, this.selected_group_ids.length);
            this.selected_team_ids.splice(0, this.selected_team_ids.length);

            if( checked == true ) {
                this.selected_friend_user_ids.push(friend_user_id);
                this.calculateUserIds();
                this.calculateGroupTeamShareChecked();
            }
            return;
        }

        if( checked == true && this.selected_friend_user_ids.indexOf(friend_user_id) < 0 ) {
            this.selected_friend_user_ids.push(friend_user_id);
        }
        else if( checked == false && this.selected_friend_user_ids.indexOf(friend_user_id) > -1 ) {
            this.selected_friend_user_ids.splice(this.selected_friend_user_ids.indexOf(friend_user_id), 1);
        }

        this.calculateUserIds();
    }

    /**
     * 친구 전체 체크
     */
    friendAllCheck() : void {
        let checked = this.friends.length == this.selected_friend_user_ids.length;

        this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
        if( checked == false ) {
            for( const friend of this.friends ) this.selected_friend_user_ids.push(friend.friend_user_id);
        }

        this.calculateUserIds();
    }

    /**
     * 멤버 전체 체크가 되어 있는지 여부
     */
    isMemberAllChecked() : boolean {
        if( this.selected_group_team == null ) return false;
        console.log(this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length);
        return this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length;
    }

    /**
     * 이메일이 있는 멤버 전체 체크가 되어 있는지 여부
     */
    isEmailMemberAllChecked() : boolean {
        if( this.selected_group_team == null ) return false;
        console.log(this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length);
        return this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length;
    }

    /**
     * 그룹&팀 체크
     */
    checkGroupAndTeam(event, group_and_team : GroupTeamInfo) : void {
        let checked : boolean = event.target.checked;

        let group_id = group_and_team.group_id ?? 0;
        let team_id = group_and_team.team_id ?? 0;
        let row_type = group_and_team.row_type;
        
        if( this.share_option == SHARE_OPTION.MOVE ) {

            this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
            this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
            this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
            this.selected_user_ids.splice(0, this.selected_user_ids.length);
            this.selected_group_ids.splice(0, this.selected_group_ids.length);
            this.selected_team_ids.splice(0, this.selected_team_ids.length);

            if( row_type == 'GROUP' && checked == true ) {
                this.selected_group_ids.push(group_id);
            }
            else if( row_type == 'TEAM' && checked == true ) {
                this.selected_team_ids.push(team_id);
            }

            this.calculateGroupTeamShareChecked();
            return;
        }

        // group_member_key 에서 제거
        if( row_type == 'GROUP' ) {
            this.group_user_selected_key = this.group_user_selected_key.filter(key => !key.includes(`${group_id}-`));
        }
        // team_member_key 에서 제거
        else {
            this.team_user_selected_key = this.team_user_selected_key.filter(key => !key.includes(`${team_id}-`));
        }
        
        if( checked == true ) {
            // group_ids에 추가
            if( row_type == 'GROUP' && this.selected_group_ids.indexOf(group_id) < 0 ) {
                this.selected_group_ids.push(group_id);
            }
            // team_ids에 추가
            else if( row_type == 'TEAM' && this.selected_team_ids.indexOf(team_id) < 0 ) {
                this.selected_team_ids.push(team_id);
            }
        }
        else if( checked == false ) {
            // group_ids에서 제거
            if( row_type == 'GROUP' && this.selected_group_ids.indexOf(group_id) > -1 ) {
                this.selected_group_ids.splice(this.selected_group_ids.indexOf(group_id), 1);
            }
            // team_ids에서 제거
            else if( row_type == 'TEAM' && this.selected_team_ids.indexOf(team_id) > -1 ) {
                this.selected_team_ids.splice(this.selected_team_ids.indexOf(team_id), 1);
            }
        }
    }

    /**
     * 멤버 전체 체크
     */
    memberAllCheck() : void {
        if( this.selected_group_team == null ) return;

        let checked : boolean = this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length;

        for( const member of this.group_team_members ) {
            const new_member = JSON.parse(JSON.stringify(member));
            new_member.checked = !checked;
            this.group_team_members.splice(this.group_team_members.indexOf(member), 1, new_member);
        }
    }

    /**
     * 이메일이 있는 멤버 전체 체크
     */
    emailMemberAllCheck() : void {
        if( this.selected_group_team == null ) return;

        let checked : boolean = this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length;

        for( const member of this.email_group_team_members ) {
            const new_member = JSON.parse(JSON.stringify(member));
            new_member.checked = !checked;
            this.email_group_team_members.splice(this.email_group_team_members.indexOf(member), 1, new_member);
        }
    }

    /**
     * 그룹&팀 멤버 체크
     */
    checkMember(event, member : any) : void {
        const checked : boolean = event.target.checked;

        console.log(checked);
        
        const new_member = JSON.parse(JSON.stringify(member));
        new_member.checked = checked;

        for( const original_member of this.group_team_members ) {
            if( original_member.user_id != member.user_id ) {
                if( this.share_option == SHARE_OPTION.MOVE ) {
                    const original_member_copy = JSON.parse(JSON.stringify(original_member));
                    original_member_copy.checked = false;
                    this.group_team_members.splice(this.group_team_members.indexOf(original_member), 1, original_member_copy);
                }
                continue;
            }
            this.group_team_members.splice(this.group_team_members.indexOf(original_member), 1, new_member);
        }
    }

    /**
     * 이메일이 있는 그룹&팀 멤버 체크
     */
    checkEmailMember(event, member : any) : void {
        const checked : boolean = event.target.checked;

        console.log(checked);
        
        const new_member = JSON.parse(JSON.stringify(member));
        new_member.checked = checked;

        for( const original_member of this.email_group_team_members ) {
            if( original_member.user_id != member.user_id ) continue;
            this.email_group_team_members.splice(this.email_group_team_members.indexOf(original_member), 1, new_member);
        }
    }

    /**
     * user_ids 계산
     */
    calculateUserIds() : void {
        this.selected_user_ids.splice(0, this.selected_user_ids.length);

        const map : Map<number, number> = new Map();
        
        for( const friend_user_id of this.selected_friend_user_ids ) {
            map.set(friend_user_id, friend_user_id);
        }

        for( const group_user_key of this.group_user_selected_key ) {
            const key_split : string[] = group_user_key.split('-');
            if( key_split.length != 2 ) continue;
            const user_id = Number(key_split[1]);
            if( isNaN(user_id) ) continue;
            map.set(user_id, user_id);
        }

        for( const group_user_key of this.team_user_selected_key ) {
            const key_split : string[] = group_user_key.split('-');
            if( key_split.length != 2 ) continue;
            const user_id = Number(key_split[1]);
            if( isNaN(user_id) ) continue;
            map.set(user_id, user_id);
        }

        for( const user_id of map.keys() ) {
            this.selected_user_ids.push(user_id);
        }

    }

    /**
     * 그룹&팀 shareChecked 계산
     */
    async calculateGroupTeamShareChecked() : Promise<void> {
        for( const group of this.group_info_list ) {

            const group_copy = JSON.parse(JSON.stringify(group));
            const group_id = group_copy.group_id;

            for( const team of group_copy.teams ) { 

                const team_copy = JSON.parse(JSON.stringify(team));

                const team_id = team_copy.team_id;
                team_copy.shareChecked = this.team_user_selected_key.filter(key => key.includes(`${team_id}-`)).length > 0 || 
                                         this.selected_team_ids.indexOf(team_id) > -1;
                group_copy.teams.splice(group_copy.teams.indexOf(team), 1, team_copy);
            }

            group_copy.shareChecked = this.group_user_selected_key.filter(key => key.includes(`${group_id}-`)).length > 0 ||
                                      this.selected_group_ids.indexOf(group_id) > -1;

            this.group_info_list.splice(this.group_info_list.indexOf(group), 1, group_copy);
        }
    }

    /**
     * 친구 정렬
     */
    friendSort(sort_type : string) {
        if( this.friend_sort_type == sort_type ) {
            this.friend_sort_direction = this.friend_sort_direction == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
            return;
        }

        this.friend_sort_type = sort_type;
        this.friend_sort_direction = SORT_TYPE.ASC;
    }

    /**
     * 그룹&팀 정렬
     */
    groupTeamSort(sort_type : string) {

        if( this.group_team_sort_type == sort_type ) {

            if( this.group_team_sort_direction == SORT_TYPE.ASC ) {
                this.group_team_sort_direction = SORT_TYPE.DESC;
            }
            else {
                this.group_team_sort_type = null;
            }
            
            return;
        }

        this.group_team_sort_type = sort_type;
        this.group_team_sort_direction = SORT_TYPE.ASC;
    }

    /**
     * 그룹&팀 멤버 정렬
     */
    memberSort(sort_type : string) {
        if( this.member_sort_type == sort_type ) {
            this.member_sort_direction = this.member_sort_direction == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
            return;
        }

        this.member_sort_type = sort_type;
        this.member_sort_direction = SORT_TYPE.ASC;
    }

    /**
     * 친구 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/im_photoB.gif');
        jquery_taget.parent().find('p.fImg, p.fPic').css('background-image', `url(${replace_image})`);
    }

    /**
     * 그룹, 팀 이미지 에러
     */
    groupTeamImageError(event, group : GroupTeamInfo) : void {
        const jquery_taget = $(event.target);

        let target_class = "imgDc7";

        switch( this.hodu_hex_color_process(group.color) ) {
            case hodu_color.hodu_dc_0: target_class = 'imgDc0'; break;
            case hodu_color.hodu_dc_1: target_class = 'imgDc1'; break;
            case hodu_color.hodu_dc_2: target_class = 'imgDc2'; break;
            case hodu_color.hodu_dc_3: target_class = 'imgDc3'; break;
            case hodu_color.hodu_dc_4: target_class = 'imgDc4'; break;
            case hodu_color.hodu_dc_5: target_class = 'imgDc5'; break;
            case hodu_color.hodu_dc_6: target_class = 'imgDc6'; break;
            case hodu_color.hodu_dc_7: target_class = 'imgDc7'; break;
            case hodu_color.hodu_dc_8: target_class = 'imgDc8'; break;
            case hodu_color.hodu_dc_9: target_class = 'imgDc9'; break;
            case hodu_color.hodu_lc_0: target_class = 'imgLc0'; break;
            case hodu_color.hodu_lc_1: target_class = 'imgLc1'; break;
            case hodu_color.hodu_lc_2: target_class = 'imgLc2'; break;
            case hodu_color.hodu_lc_3: target_class = 'imgLc3'; break;
            case hodu_color.hodu_lc_4: target_class = 'imgLc4'; break;
            case hodu_color.hodu_lc_5: target_class = 'imgLc5'; break;
            case hodu_color.hodu_lc_6: target_class = 'imgLc6'; break;
            case hodu_color.hodu_lc_7: target_class = 'imgLc7'; break;
            case hodu_color.hodu_lc_8: target_class = 'imgLc8'; break;
            case hodu_color.hodu_lc_9: target_class = 'imgLc9'; break;
        }

        jquery_taget.parent().find('p.img').addClass(target_class);
    }

    /**
     * 선택 정보 초기화
     */
    reset() {
        for( const group of this.group_info_list ) {
            
            const group_copy = JSON.parse(JSON.stringify(group));

            group_copy.shareChecked = false;

            for( const team of group_copy.teams ) {
                team.shareChecked = false;
            }

            this.group_info_list.splice(this.group_info_list.indexOf(group), 1, group_copy);
        }
        
        for( const member of this.group_team_members ) {
            const member_copy = JSON.parse(JSON.stringify(member));
            member_copy.checked = false;
            this.group_team_members.splice(this.group_team_members.indexOf(member), 1, member_copy);
        }

        for( const friend of this.friends ) {
            const friend_copy = JSON.parse(JSON.stringify(friend));
            friend_copy.checked = false;
            this.friends.splice(this.friends.indexOf(friend), 1, friend_copy);
        }

        this.selected_user_ids.splice(0, this.selected_user_ids.length);
        this.selected_group_ids.splice(0, this.selected_group_ids.length);
        this.selected_team_ids.splice(0, this.selected_team_ids.length);
        this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
        this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
        this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
    }

    /**
     * 저장
     */
    save() : void {

        // 일정 이메일 공유 일때
        if( this.common_select_friend_and_group_team_modal_info.use_need_user_email == true ) {
            
            // 그룹 내부를 보고 있을때
            if( this.selected_group_team != null ) {

                let selected_count : number = 0;
                
                let row_type = this.selected_group_team.row_type;
                let group_id = this.selected_group_team.group_id ?? 0;
                let team_id = this.selected_group_team.team_id ?? 0;

                if( row_type == 'GROUP' ) {

                    this.selected_group_ids = this.selected_group_ids.filter(selected_group_id => selected_group_id != group_id);
                    this.group_user_selected_key = this.group_user_selected_key.filter(key => !key.includes(`${group_id}-`));

                    // 전체 선택 됨
                    if( this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length ) {
                        selected_count = this.email_group_team_members.length;
                        this.selected_group_ids.push(group_id);
                    }
                    // 전부 선택 되지는 않음
                    else {
                        for( const member of this.email_group_team_members ) {
                            if( member.checked == true ) {
                                selected_count++;
                                this.group_user_selected_key.push(`${group_id}-${member.user_id}`);
                            }
                        }
                    }
                }
                else if( row_type == 'TEAM' ) {

                    this.selected_team_ids = this.selected_team_ids.filter(selected_team_id => selected_team_id != team_id);
                    this.team_user_selected_key = this.team_user_selected_key.filter(key => !key.includes(`${team_id}-`));

                    // 전체 선택 됨
                    if( this.email_group_team_members.length == this.email_group_team_members.filter(member => member.checked == true).length ) {
                        selected_count = this.email_group_team_members.length;
                        this.selected_team_ids.push(team_id);
                    }
                    // 전부 선택되지는 않음
                    else {
                        for( const member of this.email_group_team_members ) {
                            if( member.checked == true ) {
                                selected_count++;
                                this.team_user_selected_key.push(`${team_id}-${member.user_id}`);
                            }
                        }
                    }

                }

                // 선택된 멤버가 하나라도 있으면 해당 그룹은 체크 표시가 되어 있어야함
                this.selected_group_team.shareChecked = (selected_count > 0);

                // selected_user_ids 계산
                this.calculateUserIds();
                this.calculateGroupTeamShareChecked();

                this.selected_group_team = null;

                this.view_search_friend = (this.tab == 'FRIEND');
                this.view_search_group_team = (this.tab == 'GROUP_AND_TEAM');
                this.view_search_member = false;

                this.group_team_member_search_query = "";
                return;
            }

            const start_date = this.common_select_friend_and_group_team_modal_info.event_start ?? new Date();
            const end_date = this.common_select_friend_and_group_team_modal_info.event_end ?? new Date();

            let is_all_day = false;
            if( end_date.getFullYear() == start_date.getFullYear() &&
                end_date.getMonth()    == start_date.getMonth()    &&
                end_date.getDate()     == start_date.getDate() ) {
                
                let event_time = end_date.getTime() - start_date.getTime();

                // 23시간 59분 차이가 난다면 
                let all_day_time = (23 * 60 * 60 * 1000) + (59 * 60 * 1000);

                if( event_time >= all_day_time ) is_all_day = true;
            }

            const start = encodeURIComponent(`${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date, '요일')}${ !is_all_day ? ` ${this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(start_date, 'hh:mm a'))}` : '' }`);
            const end = encodeURIComponent(`${this.hodu_date_to_format_string(end_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(end_date, '요일')}${ !is_all_day ? ` ${this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(end_date, 'hh:mm a'))}` : '' }`);
            const start_timestamp = start_date.getTime();
            const end_timestamp = end_date.getTime();

            const title = this.common_select_friend_and_group_team_modal_info.event_title ?? "";

            let user_info_array : any[] = [];
            let user_info_map : Map<number, any> = new Map();

            // { "email" : "email@gmail.com", "name" : "이름" }
            // friend_user_ids, group_ids, team_ids, group_user_key, team_user_key 다 찾아서 하나씩 하나씩 넣어야함...
            for( const group_id of this.selected_group_ids ) {
                const target_group = this.email_group_team_list.filter(group => group.group_id == group_id);
                if( target_group.length < 1 ) continue;

                for( const member of target_group[0].group_member ) {
                    user_info_map.set(member.user_id, {
                        "name" : member.user_name,
                        "email" : member.user_email
                    });
                }
            }

            for( const team_id of this.selected_team_ids ) {
                const target_group = this.email_group_team_list.filter(group => group.teams.filter(team => team.team_id == team_id).length > 0);
                
                if( target_group.length < 1 ) continue;

                const team = target_group[0].teams.filter(team => team.team_id == team_id)[0];

                for( const member of team.team_member ) {
                    user_info_map.set(member.user_id, {
                        "name" : member.user_name,
                        "email" : member.user_email
                    });
                }
            }

            for( const group_user_key of this.group_user_selected_key ) {

                const key_split = group_user_key.split('-');

                if( key_split.length < 2 || isNaN(Number(key_split[0])) || isNaN(Number(key_split[1])) ) continue;

                const group_id = Number(key_split[0]);
                const user_id = Number(key_split[1]);

                const target_group = this.email_group_team_list.filter(group => group.group_id == group_id);
                if( target_group.length < 1 ) continue;

                for( const member of target_group[0].group_member ) {
                    
                    if( member.user_id != user_id ) continue;
                    
                    user_info_map.set(member.user_id, {
                        "name" : member.user_name,
                        "email" : member.user_email
                    });

                    break;
                }
            }

            for( const team_user_key of this.team_user_selected_key ) {

                const key_split = team_user_key.split('-');

                if( key_split.length < 2 || isNaN(Number(key_split[0])) || isNaN(Number(key_split[1])) ) continue;

                const team_id = Number(key_split[0]);
                const user_id = Number(key_split[1]);

                const target_group = this.email_group_team_list.filter(group => group.teams.filter(team => team.team_id == team_id).length > 0);
                
                if( target_group.length < 1 ) continue;

                const team = target_group[0].teams.filter(team => team.team_id == team_id)[0];

                for( const member of team.team_member ) {

                    if( member.user_id != user_id ) continue;

                    user_info_map.set(member.user_id, {
                        "name" : member.user_name,
                        "email" : member.user_email
                    });

                    break;
                }
            }

            // 친구를 마지막에 돌린다 (똑같은 user_id 가 중첩 될 때 친구 정보에 맞춰서 보여주기 위함)
            for( const friend_user_id of this.selected_friend_user_ids ) {
                const target_friend = this.friends.filter(friend => friend.friend_user_id == friend_user_id);
                if( target_friend.length < 1 ) continue;
                
                user_info_map.set(target_friend[0].friend_user_id, {
                    "name" : this.getFriendName(target_friend[0]),
                    "email" : target_friend[0].user_email
                });
            }

            for( const key of user_info_map.keys() ) {
                user_info_array.push(user_info_map.get(key));
            }

            let user_info_query = encodeURIComponent(JSON.stringify(user_info_array));
            let query : string = `event_id=${this.common_select_friend_and_group_team_modal_info.event_id}&scope=${this.common_select_friend_and_group_team_modal_info.event_scope}&scope_id=${this.common_select_friend_and_group_team_modal_info.event_scope_id}&start=${start}&start_timestamp=${start_timestamp}&end=${end}&end_timestamp=${end_timestamp}&title=${title}&sender_email=${this.user_email}&user_info=${user_info_query}`;
            window.open(`/mail_share.html?&${query}`, "hodu_email_share", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);

            this.close();
            return;
        }

        // 그룹, 팀 멤버를 보고 있다면 그룹 팀 내부에서 선택 된 정보 저장후 탭에서만 나가기
        if( this.selected_group_team != null ) {

            let selected_count : number = 0;

            let row_type = this.selected_group_team.row_type;
            let group_id = this.selected_group_team.group_id;
            let team_id = this.selected_group_team.team_id;

            if( this.share_option == SHARE_OPTION.MOVE ) {
                this.selected_friend_user_ids.splice(0, this.selected_friend_user_ids.length);
                this.group_user_selected_key.splice(0, this.group_user_selected_key.length);
                this.team_user_selected_key.splice(0, this.team_user_selected_key.length);
                this.selected_user_ids.splice(0, this.selected_user_ids.length);
                this.selected_group_ids.splice(0, this.selected_group_ids.length);
                this.selected_team_ids.splice(0, this.selected_team_ids.length);
            }

            if( row_type == 'GROUP' ) {

                this.selected_group_ids = this.selected_group_ids.filter(selected_group_id => selected_group_id != group_id);
                this.group_user_selected_key = this.group_user_selected_key.filter(key => !key.includes(`${group_id}-`));

                // 유저만 선택하는 모드 아님 + 전체 선택 됨
                if( this.common_select_friend_and_group_team_modal_info.use_user_select_only != true && this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length ) {
                    selected_count = this.group_team_members.length;
                    this.selected_group_ids.push(group_id);
                }
                // 전부 선택 되지는 않음
                else {
                    for( const member of this.group_team_members ) {
                        if( member.checked == true ) {
                            selected_count++;
                            this.group_user_selected_key.push(`${group_id}-${member.user_id}`);
                        }
                    }
                }
            }
            else if( row_type == 'TEAM' ) {

                this.selected_team_ids = this.selected_team_ids.filter(selected_team_id => selected_team_id != team_id);
                this.team_user_selected_key = this.team_user_selected_key.filter(key => !key.includes(`${team_id}-`));

                // 유저만 선택하는 모드 아님 + 전체 선택 됨
                if( this.common_select_friend_and_group_team_modal_info.use_user_select_only != true && this.group_team_members.length == this.group_team_members.filter(member => member.checked == true).length ) {
                    selected_count = this.group_team_members.length;
                    this.selected_team_ids.push(team_id);
                }
                // 전부 선택되지는 않음
                else {
                    for( const member of this.group_team_members ) {
                        if( member.checked == true ) {
                            selected_count++;
                            this.team_user_selected_key.push(`${team_id}-${member.user_id}`);
                        }
                    }
                }

            }

            // 선택된 멤버가 하나라도 있으면 해당 그룹은 체크 표시가 되어 있어야함
            this.selected_group_team.shareChecked = (selected_count > 0);

            // selected_user_ids 계산
            this.calculateUserIds();

            this.calculateGroupTeamShareChecked();

            this.selected_group_team = null;
            this.group_team_members.splice(0, this.group_team_members.length);

            this.view_search_friend = (this.tab == 'FRIEND');
            this.view_search_group_team = (this.tab == 'GROUP_AND_TEAM');
            this.view_search_member = false;

            this.group_team_member_search_query = "";
            return;
        }

        // 저장 처리
        this.common_select_friend_and_group_team_modal_info.callback?.({
            "share_option" : this.share_option,
            "selected_user_ids" : this.selected_user_ids,
            "selected_group_ids" : this.selected_group_ids,
            "selected_team_ids" : this.selected_team_ids,
            "selected_friend_user_ids" : this.selected_friend_user_ids,
            "group_user_selected_key" : this.group_user_selected_key,
            "team_user_selected_key" : this.team_user_selected_key,
        });
        this.close();

    }

    /**
     * 닫기
     */
    close() : void {

        // 그룹, 팀 멤버를 보고 있다면 탭에서만 나가기
        if( this.selected_group_team != null ) {
            this.selected_group_team = null;
            this.group_team_members.splice(0, this.group_team_members.length);

            this.view_search_friend = (this.tab == 'FRIEND');
            this.view_search_group_team = (this.tab == 'GROUP_AND_TEAM');
            this.view_search_member = false;

            this.group_team_member_search_query = "";
            return;
        }

        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : false
        })
    }

}
