import { render, staticRenderFns } from "./LeftGroupFileNew.vue?vue&type=template&id=68852b72&scoped=true&"
import script from "./LeftGroupFileNew.vue?vue&type=script&lang=ts&"
export * from "./LeftGroupFileNew.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68852b72",
  null
  
)

export default component.exports