
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');

@Component({

})
export default class LeftGroupFileNew extends Mixins(VueHoduCommon) {
      
    /**
     * @GroupInfo.Action
     */
    @GroupInfo.Action doSelectedDirUuid ?: (params : string) => void;

    mounted() : void {

    }

    /**
     * 파일보관함 리스트로 이동
     */
    goGroupFileList() : void {        
        this.doGroupId(this.scope_group_id);
        this.doTeamId(this.scope_team_id);
        this.doGroupTeamOption(JSON.parse(JSON.stringify(this.scope_group_team_option)));
        this.doSelectedDirUuid?.("");

        if( this.scope_group_id > 0 ) {
            this.hodu_router_push(`/group/filebox?${new Date().getTime()}`);
        }
        else {
            this.hodu_router_push(`/filebox?${new Date().getTime()}`);
        }
    }
}
