
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import TitleBox from '@/components/ui/TitleBox.vue';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        TitleBox, ResizeObserver
    },
})
export default class ResidentData extends Mixins(VueHoduCommon) {

    resident_datas : any[] = [];

    mounted() : void {
        this.getResidentDatas();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height : number | undefined = $('#resident_data .title_box').outerHeight();
        const sort_header_height : number | undefined = $('#resident_data .sortHeader').outerHeight();

        // @ts-ignore
        $('#resident_data_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ) - ( sort_header_height == null ? 0 : sort_header_height )
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#resident_data_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

    /**
     * 입주민 데이터 조회
     */
    async getResidentDatas() : Promise<void> {

        try {
            
            const response = await this.hodu_api_call(`api/v1/home/resident_data`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.resident_datas ) {
                throw new Error("입주민 데이터 조회 중 오류 발생");
            }

            this.resident_datas.splice(0, this.resident_datas.length); 
            this.resident_datas = this.resident_datas.concat(response.data.data.resident_datas);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

}
