import { render, staticRenderFns } from "./GroupTeamOtherOutModal.vue?vue&type=template&id=422dd070&scoped=true&"
import script from "./GroupTeamOtherOutModal.vue?vue&type=script&lang=ts&"
export * from "./GroupTeamOtherOutModal.vue?vue&type=script&lang=ts&"
import style0 from "./GroupTeamOtherOutModal.vue?vue&type=style&index=0&id=422dd070&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "422dd070",
  null
  
)

export default component.exports