import { render, staticRenderFns } from "./KidsTeacher.vue?vue&type=template&id=2a0c99a5&scoped=true&"
import script from "./KidsTeacher.vue?vue&type=script&lang=ts&"
export * from "./KidsTeacher.vue?vue&type=script&lang=ts&"
import style0 from "./KidsTeacher.vue?vue&type=style&index=0&id=2a0c99a5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0c99a5",
  null
  
)

export default component.exports