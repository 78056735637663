
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, {API_METHOD} from '@/mixin/VueHoduCommon';
import { approval_modal_interface, approval_interface } from '@/model/approval';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ApprovalFormDownload extends Mixins(VueHoduCommon) {

/**
     * 부서 정보
     */
    get computedTemplates() : any[] {
        let templates = JSON.parse(JSON.stringify(this.templates));

        var template_files : any[] = []

        for ( const template of templates ) {
            if ( template.is_selected ) {
                for ( const file of template.files ) {
                    file.template_class_name = template.template_class_name
                }
                template_files = template_files.concat(template.files)
            }
        }

        return template_files;
    }

    is_open_tab : boolean = true;
    
    templates : approval_interface.ApprovalTemplateBundle[] = []

    async mounted() : Promise<void> {
        await this.getApprovalTemplateInfo();
        this.setScroll();
    }

    async getApprovalTemplateInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class?include_templates=${true}`, API_METHOD.GET, null, false);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }

            this.templates = response.data.data.approval_templates.filter(template => template.template_state == '0' || template.template_state == '10');
            for (const template of this.templates) {
                template.is_selected = true
                template.is_fold = true
            }

            this.templates.sort((o1, o2) : number => {
                if( o1.template_state > o2.template_state ) return 1;
                else if( o1.template_state < o2.template_state ) return -1;
                else if( o1.template_class_id > o2.template_class_id ) return 1;
                else if( o1.template_class_id < o2.template_class_id ) return -1;
                return 0;
            });

        } catch(e) {
            throw e;
        }
    }

    downloadForm() : void {

    }

    selectClass(event, index:number) {
        var preClass = JSON.parse(JSON.stringify(this.templates[index]))
        preClass.is_selected = event.target.checked as boolean
        this.templates.splice(index, 1, preClass)
    }
    
    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight  : number | undefined = $('.title_box').outerHeight();
        const filterHeight : number | undefined = $('.filter_tap').outerHeight();

        // @ts-ignore
        $('#form_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (filterHeight ? filterHeight : 0)
        });
    }

}
