
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';
import { approval_enum } from '@/model/approval';

// TODO 사용하면 vacation_selected 대응 해야함
@Component({
    components: {
        
    },
})
export default class OrganizationVacationDetailModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_vacation_detail_modal_info  !: organization_modal_interface.OrganizationVacationDetailModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationVacationDetailModalInfo ?: (params : organization_modal_interface.OrganizationVacationDetailModalInfo) => void;

    employees : any[] = [];

    user_employee_info : any = null;

    approval : any = null;

    async mounted() : Promise<void> {
        await Promise.all([this.getOrganizationEmp(), this.getSelectedApprovalInfo()]);

        if( this.approval == null ) {
            this.close();
            return;
        }

        const target = this.employees.filter(employee => employee.user_id == this.approval.approver[0].user_id);

        if( target.length < 1 ) {
            this.close();
            return;
        }

        this.user_employee_info = target[0];
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 기안서 조회
     */
    async getSelectedApprovalInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.organization_vacation_detail_modal_info.approval_uid}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("휴가원 조회 중 오류 발생");
            }

            const approval_info = response.data.data.approval_info;

            this.approval = JSON.parse(JSON.stringify(approval_info));

        } catch(e) {
            alert("휴가원 조회 중 오류 발생")
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 상태에 따른 텍스트 반환
     */
    getStatusText(approval : any) : string {
        switch(approval.approval_state) {
            case approval_enum.APPROVAL_STATE.ING:
                return "승인 대기중";
            case approval_enum.APPROVAL_STATE.CANCEL:
                return "취소됨";
            case approval_enum.APPROVAL_STATE.COMPLETE:
                return "승인 됨";
            case approval_enum.APPROVAL_STATE.REJECT:
                return "반려 됨";
        }

        return "";
    }

    /**
     * 휴가 날짜 텍스트 반환
     */
    getDateText(approval : any) : string {

        const vacation_start = approval.contents.vacation_start;
        const vacation_end = approval.contents.vacation_end;

        if( vacation_start == vacation_end ) {
            const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
            return `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)}`;
        }

        const target_start = [vacation_start.substring(0,4), vacation_start.substring(4,6), vacation_start.substring(6,8)].join('-');
        const target_end = [vacation_end.substring(0,4), vacation_end.substring(4,6), vacation_end.substring(6,8)].join('-');

        return `${this.hodu_date_to_format_string(target_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_start)} ~ ${this.hodu_date_to_format_string(target_end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(target_end)}`;
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 사용자 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('p.img').css('background-image', require('@/assets/images/contents/ic_approval_user_on.png'))
    }

    /**
     * 체크 비활성화 여부
     */
    isDisabled() : boolean {
        if( this.approval == null ) return true;
        const target = this.approval.approver.filter(item => item.user_id == this.user_id && item.state == approval_enum.APPROVAL_APPROVER_STATE.ING);
        return target.length < 1;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationVacationDetailModalInfo?.({
            show_modal : false,
            approval_uid : ""
        });
    }

}
