
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const HoduDocInfo = namespace('HoduDocInfo');

import HospitalBasicSetting   from '@/components/hodu_d/HospitalBasicSetting.vue';
import HospitalTimeSetting    from '@/components/hodu_d/HospitalTimeSetting.vue';
import HospitalHolidaySetting from '@/components/hodu_d/HospitalHolidaySetting.vue';
import HospitalOffSetting     from '@/components/hodu_d/HospitalOffSetting.vue';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HospitalBasicSetting, HospitalTimeSetting, HospitalHolidaySetting, HospitalOffSetting, ResizeObserver
    },
}) 
export default class HospitalSetting extends Mixins(VueHoduCommon) {

    /**
     * @HoduDocInfo.State
     */
    @HoduDocInfo.State hospital_setting_tab !: "BASIC" | "TIME" | "HOLIDAY" | "OFF";

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetHospitalSettingTab ?: (parms : "BASIC" | "TIME" | "HOLIDAY" | "OFF") => void;

    event_bus : Vue = new Vue();

    mounted() : void {
        // $(".mainMenu a").click(function(){
        //     var getIndex = $(this).index();
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");
        //     if ( getIndex == 0 ) { 
        //         $(".mainMenu a.pubHolidayLink").removeClass("linkLeft");
        //         $(".mainMenu a.setTimeLink").removeClass("linkLeft");

        //         // 탭
        //         $(".hospital_setting").removeClass("offDay");
        //         $(".hospital_setting").removeClass("pubHoliday");
        //         $(".hospital_setting").removeClass("setTime");
        //         $(".hospital_setting").addClass("basic");

        //     } else if ( getIndex == 1 ) {
        //         $(".mainMenu a.pubHolidayLink").removeClass("linkLeft");
        //         $(".mainMenu a.setTimeLink").removeClass("linkLeft");

        //         // 탭
        //         $(".hospital_setting").removeClass("offDay");
        //         $(".hospital_setting").removeClass("pubHoliday");
        //         $(".hospital_setting").removeClass("basic");
        //         $(".hospital_setting").addClass("setTime");

        //     } else if ( getIndex == 2 ) {
        //         $(".mainMenu a.pubHolidayLink").removeClass("linkLeft");
        //         $(".mainMenu a.setTimeLink").addClass("linkLeft");

        //         // 탭
        //         $(".hospital_setting").removeClass("offDay");
        //         $(".hospital_setting").removeClass("setTime");
        //         $(".hospital_setting").removeClass("basic");
        //         $(".hospital_setting").addClass("pubHoliday");

        //     } else {
        //         $(".mainMenu a.setTimeLink").addClass("linkLeft");
        //         $(".mainMenu a.pubHolidayLink").addClass("linkLeft");

        //         // 탭
        //         $(".hospital_setting").removeClass("pubHoliday");
        //         $(".hospital_setting").removeClass("setTime");
        //         $(".hospital_setting").removeClass("basic");
        //         $(".hospital_setting").addClass("offDay");
        //     }
        // });
    }

    setHospitalSettingTab(tab : "BASIC" | "TIME" | "HOLIDAY" | "OFF") : void {
        if( this.doSetHospitalSettingTab ) { this.doSetHospitalSettingTab(tab); }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.event_bus.$emit('basicSettingResize');
        this.event_bus.$emit('timeSettingResize');
        this.event_bus.$emit('holidaySettingResize');
        this.event_bus.$emit('offSettingResize');
    }

    /**
     * 병원 지정 휴일 멀티 삭제
     */
    deleteDayOffs() : void {
        this.event_bus.$emit('deleteDayOffs');
    }

}
