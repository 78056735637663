import { render, staticRenderFns } from "./DeleteData.vue?vue&type=template&id=f3a6f762&scoped=true&"
import script from "./DeleteData.vue?vue&type=script&lang=ts&"
export * from "./DeleteData.vue?vue&type=script&lang=ts&"
import style0 from "./DeleteData.vue?vue&type=style&index=0&id=f3a6f762&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3a6f762",
  null
  
)

export default component.exports