
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { t_event_file } from '@/model/event';
import { organization_modal_interface } from '@/model/organization';

@Component({
    components: {
        
    },
})
export default class OrganizationEmpPersonalRecordDetail extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordPrintModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordPrintModalInfo) => void;

    target_user_id : number = 0;
    record : any = null;
    default_user_image : string = require('@/assets/images/contents/user_photo_fff.png');
    create_and_update_permission : boolean = false;

    beforeMount() {
        this.target_user_id = this.$route.params.user_id ? Number(this.$route.params.user_id) : 0;
    }

    mounted() : void {

        if( isNaN(this.target_user_id) || this.target_user_id < 1 ) {
            this.movePrevPage();
            return;
        }

        this.getPersonalRecord();
    }

    async getPersonalRecord() {

        try {

            this.create_and_update_permission = this.get_group_role(this.scope_group_id) == 'ADMIN';

            if( this.create_and_update_permission == false ) {

                const permission_response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/personal_record/permission`, API_METHOD.GET);

                console.log(permission_response);

                if( permission_response == null || !this.isHttpStatusSuccess(permission_response.status) || !permission_response.data || !permission_response.data.data ) {
                    throw new Error("인사정보 등록 및 수정 권한 조회중 오류발생");
                }

                const permission = permission_response.data.data.permission;

                if( permission != null && permission.length > 0 ) {
                    for( const perm of permission ) {
                        if( perm.user_id == this.user_id ) {
                            this.create_and_update_permission = true;
                            break;
                        } 
                    }
                }
            }

            const group_id = this.scope_group_id;
            const user_id = this.target_user_id;

            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}`, API_METHOD.GET);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.record ) {
                throw new Error("인사정보 조회중 오류발생");
            }

            this.record = JSON.parse(JSON.stringify(response.data.data.record));

        } catch(e) {
            this.hodu_error_process(e, false, false ,true);
        }

    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
     yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    getAdditionalInfoTitle(info) {

        switch( info.type ) {
            case "education":
                return "학력";
            case "career":
                return "경력";
            case "license_and_awards":
                return "자격증 / 수상";
            case "training_courses":
                return "교육이수";
            case "etc":
                return info.title;
        }

        return "";
    }

    getEducationStatusText(status) {
        switch( status ) {
            case "graduate":
                return "졸업";
            case "attending":
                return "졸업예정";
            case "take_off_school":
                return "휴학";
            case "dropout":
                return "중퇴";
        }

        return "";
    }

    getTotalCareer(info) {

        let total_career : string = "";

        let month_count : number = 0;
        for( let history of info.history ) {

            let start :string = history.start;
            let end : string = history.end;

            if( history.is_currently_employed == true ) {
                end = this.hodu_date_to_format_string(new Date(), 'YYYY.MM');
            }

            let start_split : string[] = start.split(".");
            let end_split : string[] = end.split(".");

            let start_month_count : number = 0;
            let end_month_count : number = 0;

            for( let i = 0; i < start_split.length; i++ ) {
                let start_str : string = start_split[i];

                let count : number = Number(start_str);
                if( i == 0 ) count *= 12;

                start_month_count += count;
            }

            for( let i = 0; i < end_split.length; i++ ) {
                let end_str : string = end_split[i];

                let count : number = Number(end_str);
                if( i == 0 ) count *= 12;

                end_month_count += count;
            }

            month_count += (end_month_count - start_month_count);
        }
            
        // 년단위로 딱 떨어지는 경우
        if( month_count > 0 && month_count % 12 == 0 ) {
            total_career = `총 ${Math.floor(month_count / 12)} 년`;
        }
        // 년단위 이하
        else if( month_count < 12 ) {
            total_career = `총 ${Math.floor(month_count)} 개월`;
        }
        // n년 m개월
        else {
            total_career = `총 ${Math.floor(month_count / 12)}년 ${Math.floor(month_count % 12)} 개월`;
        }

        return total_career;
    }

    /**
     * 파일 다운로드
     */
     download(file : t_event_file) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    userImageError(event) : void {
        $(event.target).parent().find('p.img_bring').css('background-image', require('@/assets/images/contents/user_photo_fff.png'))
    }

    updaeteRecord() {
        if( this.record == null ) return;
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/organization/personal_record/create/${this.record.user_id}`);
    }

    deleteRecord() { 
        if( this.record == null ) return;
        this.hodu_show_dialog("cancel", `인사정보를 삭제하시겠습니까?`, ["아니오", "예"], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/personal_record/${this.record.user_id}`, API_METHOD.DELETE);

                    if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("인사 정보 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "인사정보 삭제 중 오류 발생", ['확인']);
                }
            }
        ]);
    }

    print() {
        this.doSetOrganizationEmpPersonalRecordPrintModalInfo?.({
            show_modal : true,
            group_id : this.scope_group_id,
            user_id : this.target_user_id,
            is_quitter : false,
        });
    }

}
