
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

@Component({
    components: {
        
    },
})
export default class OrganizationEmpPersonalRecordDetailModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_emp_personal_record_detail_modal_info !: organization_modal_interface.OrganizationEmpPersonalRecordDetailModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordDetailModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordDetailModalInfo) => void;

    record : any = null;
    default_user_image : string = require('@/assets/images/contents/ic_approval_user_on.png');

    mounted() : void {

        if( this.organization_emp_personal_record_detail_modal_info.group_id < 1 || this.organization_emp_personal_record_detail_modal_info.user_id < 1 ) {
            this.close();
        }
        

        this.getPersonalRecord();
    }

    async getPersonalRecord() {

        try {

            const group_id = this.organization_emp_personal_record_detail_modal_info.group_id;
            const user_id = this.organization_emp_personal_record_detail_modal_info.user_id;

            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}?is_not_allow=true`, API_METHOD.GET);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.record ) {
                throw new Error("인사정보 조회중 오류발생");
            }

            this.record = JSON.parse(JSON.stringify(response.data.data.record));

        } catch(e) {
            this.close();
        }

    }

    userImageError(event) : void {
        $(event.target).parent().find('p.hr_modal_img').css('background-image', require('@/assets/images/contents/ic_approval_user_on.png'))
    }

    close() : void {
        this.doSetOrganizationEmpPersonalRecordDetailModalInfo?.({
            show_modal : false,
            group_id : 0,
            user_id : 0
        });
    }

}
