
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class Announcement extends Mixins(VueHoduCommon) {

    noti_uid : string = "";
    tts : any = null;
    audio = new Audio();

    scroll_height : number = 0;

    update_interval : number | undefined;

    target_noti_uid = "";
    goal_repeat_count = 0;
    current_repeat_count = 0;

    mounted() : void {

        this.noti_uid = this.$route.params.uid;

        if( this.noti_uid == null || this.noti_uid.length < 1 ) {
            this.hodu_show_dialog("cancel", "안내방송 조회 중 오류 발생 잠시 후 다시 시도 해주세요", ['확인'], [() => { this.movePrevPage() }]);
            return;
        }

        $(".when_time > select option").prop('disabled',true);

        this.getTTSList();

        // 5분 단위인지 체크후 UI 강제 업데이트
        this.update_interval = window.setInterval(() => {
            
            const target_date = new Date();
            
            if( target_date.getMinutes() % 5 == 0 && target_date.getSeconds() == 0 ) {
                this.$forceUpdate();
            } 
            
        }, 1000);
    }

    beforeDestroy() : void {
        this.audio.pause();
        if( this.update_interval ) { clearInterval(this.update_interval); }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {

            const titleHeight  : number | undefined = $('#announcement .title_box').outerHeight();
            const optionHeight : number | undefined = $('#announcement .annOption').outerHeight();
            const contentTitleHeight : number | undefined = $('#announcement #ann_title').outerHeight();

            console.log(titleHeight);
            console.log(optionHeight);
            console.log(contentTitleHeight);

            this.scroll_height = window.innerHeight - (titleHeight ? titleHeight : 0)
                                                   - (optionHeight ? 2 * optionHeight : 0)
                                                   - (contentTitleHeight ? contentTitleHeight : 0)

            // @ts-ignore
            // $('#modal_ann_contents').mCustomScrollbar({
            //     axis : 'y',
            //     scrollbarPosition : 'outside',
            //     mouseWheelPixels : 100,
            //     scrollInertia : 60,
            //     autoDraggerLength : false,
            //     setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
            //                                 - (optionHeight ? optionHeight : 0)
            //                                 - (optionHeight ? optionHeight : 0)
            //                                 - (contentTitleHeight ? contentTitleHeight : 0)
            // });

        });
    }

    /**
     * 안내방송 리스트 조회
     */
    async getTTSList() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}?page=0&size=0&noti_uid=${this.noti_uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.list || response.data.data.list.length < 1 ) {
                throw new Error("안내방송 조회 중 오류 발생");
            }

            const first_init = (this.tts == null);

            this.tts = JSON.parse(JSON.stringify(response.data.data.list[0]));
            this.tts.noti_data.body = this.tts.noti_data.body.split('\n').join("<br>");

            if( first_init ) {
                this.setScroll();
            }

        } catch(e) {
            this.hodu_show_dialog("cancel", "안내방송 조회 중 오류 발생", ["확인"], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 미리 듣기
     */
    async setPreTTS() : Promise<void> {
        this.target_noti_uid = "";
        this.goal_repeat_count = 0;
        this.current_repeat_count = 0;

        this.audio.pause();
        this.audio.src = `app_images/tts/${this.noti_uid}.mp3`;
        this.audio.play();
    }

    /**
     * 안내방송 삭제
     */
    async deleteTTS() : Promise<void> {

        if( this.tts == null ) { 
            return;
        }

        this.hodu_show_dialog('cancel', "정말로 안내방송을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    const response = await this.hodu_api_call(`api/v1/home/tts?noti_uid=${this.noti_uid}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("안내방송 삭제 중 오류 발생");
                    }

                    this.movePrevPage();

                } catch(e) {
                    this.hodu_show_dialog('cancel', '안내방송 삭제 중 오류 발생', ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ]);

    }

    /**
     * 방송
     */
    async play() : Promise<void> {
        try {

            if( this.tts == null ) {
                return;
            }

            this.audio.pause();

            // 반복 횟수만큼 재생
            this.target_noti_uid = this.tts.noti_uid;
            this.goal_repeat_count = this.tts.noti_info.tts.repeat_count;
            this.current_repeat_count = 0;

            this.current_repeat_count++;
            this.audio.src = `app_images/tts/${this.tts.noti_uid}.mp3`;
            this.audio.onended = () => {
                if( this.target_noti_uid != this.tts.noti_uid || this.goal_repeat_count <= this.current_repeat_count ) {
                    return;
                }

                if( this.goal_repeat_count != this.current_repeat_count ) {
                    this.current_repeat_count++;
                    setTimeout(() => { this.audio.play(); }, 1500); 
                }
            }
            this.audio.play();

            // 방송 완료로 변경
            this.tts.noti_info.tts.complete_yn = 'Y';
            this.tts.noti_data.body = this.tts.noti_data.body.split('<br>').join("\n");
            const response = await this.hodu_api_call(`api/v1/home/tts/${this.scope_group_id}?noti_uid=${this.tts.noti_uid}&mode=status`, API_METHOD.PUT, {
                "notification" : this.tts
            });

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("안내방송 방송 완료로 수정 중 오류 발생");
            }

            await this.getTTSList();

        } catch(e) {
            this.hodu_show_dialog('cancel', '방송 시작 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 남은 시간 반환
     */
    getRemainTime(time : Date | string) : string {

        if( this.tts == null ) {
            return '';
        }

        time = time instanceof Date ? time : new Date(time);

        const expected_time = time.getTime();
        const current_time = new Date().getTime();

        const offset = expected_time - current_time;

        if( offset < 1 ) {
            return "시간만료";
        }

        const offset_sec = offset / 1000;

        // 일 단위 필요하면 사용
        // const offset_day = offset_sec / 24 / 3600;
        
        const offset_hour = offset_sec / 3600;
        const offset_min = offset_sec / 60;

        // 일 단위 필요하면 사용
        // if( offset_day >= 1 ) {
        //     return `${offset_day.toFixed(0)}일`;
        // }

        if( offset_hour >= 1 ) {
            return `${offset_hour.toFixed(0)}시간`;
        }

        if( offset_min >= 1 ) {
            return `${offset_min.toFixed(0)}분`;
        }

        return `${offset_sec.toFixed(0)}초`;
    }

    moveModify() : void {
        if( this.tts == null ) return;
        this.hodu_router_push(`/home/announcement/modify/${this.tts.noti_uid}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        // $('#modal_ann_contents').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
