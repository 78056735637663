
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';

const EtcInfo   = namespace('EtcInfo');
const GroupInfo = namespace('GroupInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({

}) 
export default class MessageTemplateAdd extends Mixins(VueHoduCommon) {
    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;

    /**
     * EtcInfo.Action
     */
    @EtcInfo.Action doMessageUid ?: any;
    
    /**
     * EtcInfo.State
     */
    @EtcInfo.State message_uid !: string;

    is_created : boolean = true;
    text_count : number  = 0;
    text_max   : number  = 2000;

    message_data : any = {
        uid : "",
        scope: "GROUP",
        scope_id: 0,
        title: "",
        message_type: "",
        message_info: {
            title: "",
            body: ""
        },
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: this.user_id
    };

    mounted() : void {
        // 업무 설정 > 업무 형태 선택
		$(".content .sec2 input").click(function(){
			$(this).siblings(".dropdown").toggleClass("on");
        });
        
		// 업무 설정 > 단계 선택
		$(".content .sec3 input").click(function(){
			$(this).siblings(".dropdown").toggleClass("on");
        });
        
		// 업무 색상 선택
		$(".content .second .clr input").click(function(){
			$(this).parent().siblings(".pickColour").toggleClass("on");
        });

        if ( this.message_uid ) {
            this.is_created = false;
            this.getMessageTemplate();
        }
    }

    /**
     * 키 입력
     */
    changeKey() : void {
        let str     = this.message_data.message_info.body;
        let strLen  = str.length;
        let rbyte   = 0;
        let rlen    = 0;
        let oneChar = "";
        let str2    = "";

        for ( let i = 0; i < strLen; i++ ) {
            oneChar = str.charAt(i);

            if ( escape(oneChar).length > 4 ) {
                rbyte += 2;
            }
            else {
                rbyte++;
            }

            if ( rbyte <= this.text_max ) {
                rlen = i+1;
            }
        }

        if ( rbyte > this.text_max ) {
            this.hodu_show_dialog("alert", "한글 " + (this.text_max / 2) + "자 / 영문 " + this.text_max + "자를 초과 입력할 수 없습니다.", ['확인']);
            
            str2 = str.substr(0, rlen);

            this.message_data.message_info.body = str2;
        }
        else {
            this.text_count = rbyte;
        }
    }

    /**
     * 메시지 템플릿 조회
     */
    getMessageTemplate() : void {
        this.hodu_api_call(`api/v1/message_template/GROUP/${this.group_id}/${this.message_uid}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.message_data = response.data.data.data;
                this.changeKey();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }    

    /**
     * 저장
     */
    async message_save() : Promise<void> {
        if ( this.message_data.title.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "제목을 입력해주세요", ['확인']);
            return;
        }

        if ( this.message_data.title.length > 15 ) {
            this.hodu_show_dialog("alert", "제목은 15자 미만입니다", ['확인']);
            return;
        }

        if ( this.message_data.message_info.body.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "메세지를 입력해주세요", ['확인']);
            return;
        }

        if ( this.is_created ) {
            this.message_data.scope_id = this.group_id;

            delete this.message_data.uid;
            delete this.message_data.audit_created;
            delete this.message_data.audit_modified;
            delete this.message_data.audit_deleted;

            await this.hodu_api_call(`api/v1/message_template`, API_METHOD.POST, {message: this.message_data})
                .then(async(response) => {
                    this.movePrevPage();
                    return;
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });        
        }
        else {
            await this.hodu_api_call(`api/v1/message_template`, API_METHOD.PUT, {message: this.message_data})
                .then(async(response) => {
                    this.movePrevPage();
                    return;
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });               
        }
    }

    /**
     * 
     */
    addTemp(value) : void {
        this.message_data.message_info.body += value;
        $('#msgOpInContents').focus();
    }
}
