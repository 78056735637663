
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupTeamInfo } from '@/store/modules/GroupInfo';

const GroupInfo = namespace('GroupInfo');

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {
        ResizeObserver
    }
}) 
export default class MessageSend extends Mixins(VueHoduCommon) {
    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;

    is_checkedAll            : boolean = false;
    selected_group_id        : number = 0;
    selected_team_id         : number = 0;
    selected_group_team_name : string = "";
    group_team_list          : any    = [];
    members                  : any    = [];
    message_template         : any    = [];
    message_content          : string = "";
    text_count               : number = 0;
    text_max                 : number = 2000;
    user_profile_no_image             = require("@/assets/images/contents/im_photoB.gif");

    async mounted() : Promise<void> {
        const vue = this;

        // 메세지 문구 클릭시 화살표 방향 변환
        $("#sendMmsMasageTxt").click(function(){
            $(this).toggleClass("on")

        });

        // 검색 버튼 클릭시 searchDiv 띄우기
        $("#section_ce .schBtn").click(function(){
            $("#section_ce .searchDiv").addClass("on");
            vue.$nextTick(() => $('#group_member_search').focus());
        });

        // 검색 닫기 버튼 클릭시 searchDiv 없애기
        $("#section_ce .searchDiv .closeBtn").click(function(){
            $("#section_ce .searchDiv").removeClass("on");
            $("#group_member_search").val("");
            $("#group_member_search").trigger('keyup');
        });

        // 발송 (전체초기화)
        $("#showPhone").click(function(){
			$(this).addClass("on");
            $("#draggable").addClass("on");
            $('#sendMmsMassageScroll').addClass('dpnone');
            $('#optionsDiv').removeClass('on');
            vue.message_content = "";
            vue.text_count = 0;

            vue.$nextTick(() => $('#sendMmsMasageContent').focus());
        });

        // 메세지 문구를 선택하세요
        $("#sendMmsMasageTxt").click(function() {
            if ( vue.message_template.length < 1 ) {
                return;
            }
            
            $('#sendMmsMassageScroll').toggleClass('dpnone');
        });

        // 그룹&팀 선택
        $("#grpStepTxt").click(function() {
            $('#groupSelectDiv').toggleClass('dpnone');
        });

        // 필터검색
        $("#group_member_search").keyup(function(event) {
            let value = $("#group_member_search").val();
            
            if ( value == "" ) {  
                $("#sendMmsPageUserList li").show();
            }
            else {
                $("#sendMmsPageUserList li").hide();
                let temp = $("#sendMmsPageUserList li .name .sentTo:contains('"+value+"')");
                $(temp).parent().parent().show();
            }
        });        

        this.setScroll();
        await this.getGroupTeam();
        await this.getMessageTemplate();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();

        // @ts-ignore
        $('#sendMmsPageDiv').mCustomScrollbar({
            axis : 'y',
            setHeight : window.innerHeight - ( titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight ),
            scrollbarPosition : 'outside',
        });

        // @ts-ignore
        $(".content").mCustomScrollbar({
			mouseWheelPixels: 500
        });
    }    

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#sendMmsPageDiv').mCustomScrollbar('destroy');

        // @ts-ignore
        $(".content").mCustomScrollbar('destroy');

    	this.setScroll();
    }

   /**
    * 그룹 팀 조회
    */
   async getGroupTeam() : Promise<void> {
       this.group_team_list = [];
       this.members = [];

       const vue = this;

       await this.hodu_api_call(`api/v1/groupsweb/getGroupTeamList/${this.group_id}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                if ( !response.data.data.group ) {
                    this.hodu_show_dialog("alert", "그룹 조회중 오류가 발생하였습니다[GroupTeamList]", ['확인']);
                    return;
                }

                this.group_team_list.push({group_id: response.data.data.group.group_id, team_id: 0, name: response.data.data.group.group_info.group_name});

                $.each(response.data.data.team_list, function(key, data) {
                    vue.group_team_list.push({group_id: data.group_id, team_id: data.team_id, name: data.team_info.team_name});
                });
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

       await this.changeGroupTeam(this.group_id, this.team_id);
   }

    /**
     * 메시지 템플릿 조회
     */
    async getMessageTemplate() : Promise<void> {
        this.message_template = [];

        await this.hodu_api_call(`api/v1/message_template/GROUP/${this.group_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.message_template = response.data.data.list;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        // @ts-ignore
        $("#sendMmsMasageList").mCustomScrollbar({
            axis : 'y',
            setHeight : 376,
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 메세지 팝업창 닫기
     */
    closeMessagePop() : void {
        $("#showPhone").removeClass("on");
		$("#draggable").removeClass("on");
    }

    /**
     * 메세지 발송
     */
    async sendMessagePop() : Promise<void> {
        if ( this.message_content.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "메세지 문구를 입력해주세요", ['확인']);
            return;
        }

        let send_users : number[] = [];
        let for_count  : number   = this.members.length;

        for ( let i = 0; i < for_count; i++ ) {
            if ( this.members[i].checked ) {
                send_users.push(this.members[i].user_id);
                /*
                send_users.push({
                    user_id: this.members[i].user_id
                    //user_name: this.members[i].user_info.user_name,
                    //user_phone_number: this.members[i].user_info.user_phone_number,
                    //user_email: this.members[i].user_info.user_email
                });
                */
            }
        }

        if ( send_users.length == 0 ) {
            this.hodu_show_dialog("alert", "메세지 발송 할 그룹원을 선택해주세요", ['확인']);
            return;
        }

        let req_data = {
            users: send_users,
            group_id: this.selected_group_id,
            team_id: this.selected_team_id,
            group_team_name: this.selected_group_team_name,
            message_content: this.message_content
        }
        
        const scope = this.scope;
        const scope_id = this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id;

        await this.hodu_api_call(`api/v1/message_template/${scope}/${scope_id}/send`, API_METHOD.POST, req_data)
            .then(async(response) => {
                this.hodu_show_dialog("success", "성공적으로 메세지 발송하였습니다", ['확인']);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 메세지 선택
     */
    messageContentChange(str) : void {
        $('#sendMmsMassageScroll').addClass('dpnone');
        this.message_content = str;
        this.changeKey();
    }

    /**
     * 메세지 초기화
     */
    resetMessageContent() : void {
        this.message_content = "";
        this.changeKey();
    }

    /**
     * 키 입력
     */
    changeKey() : void {
        let str     = this.message_content;
        let strLen  = str.length;
        let rbyte   = 0;
        let rlen    = 0;
        let oneChar = "";
        let str2    = "";

        for ( let i = 0; i < strLen; i++ ) {
            oneChar = str.charAt(i);

            if ( escape(oneChar).length > 4 ) {
                rbyte += 2;
            }
            else {
                rbyte++;
            }

            if ( rbyte <= this.text_max ) {
                rlen = i+1;
            }
        }

        if ( rbyte > this.text_max ) {
            this.hodu_show_dialog("alert", "한글 " + (this.text_max / 2) + "자 / 영문 " + this.text_max + "자를 초과 입력할 수 없습니다.", ['확인']);
            
            str2 = str.substr(0, rlen);

            this.message_content = str2;
        }
        else {
            this.text_count = rbyte;
        }
    }

    /**
     * 옵션 클릭
     */
    messageOption() : void {
        $('#optionsDiv').toggleClass('on');
    }

    /**
     * 메세지 옵션 선택
     */
    messageContentAddOption(str) : void {
        this.message_content += str + " ";
        $('#sendMmsMasageContent').focus();
        this.changeKey();
    }

    /**
     * 그룹&팀 변경
     */
    async changeGroupTeam(pi_group_id, pi_team_id) : Promise<void> {
        this.selected_group_id = 0;
        this.selected_team_id  = 0;
        this.selected_group_team_name = "그룹&팀을 선택하세요";

        for ( let i = 0; i < this.group_team_list.length; i++ ) {
            if ( this.group_team_list[i].group_id == pi_group_id && this.group_team_list[i].team_id == pi_team_id ) {
                this.selected_group_id        = pi_group_id;
                this.selected_team_id         = pi_team_id;
                this.selected_group_team_name = this.group_team_list[i].name;
                break;
            }
        }

        $('#groupSelectDiv').addClass('dpnone');

        // 멤버조회
        await this.hodu_api_call(`api/v1/groupsweb/getGroupTeamMember/${this.selected_group_id}/${this.selected_team_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.members = [];
                
                let user_length = response.data.data.list.length;

                for ( let i = 0; i < user_length; i++ ) {
                    if ( response.data.data.list[i].user_id != this.user_id ) {
                        response.data.data.list[i].checked = false;
                        this.members.push(response.data.data.list[i]);
                    }
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        this.is_checkedAll = false;
    }

    /**
     * 전체선택, 전체해제
     */
    async selectAllCheckBox() : Promise<void> {
        this.is_checkedAll = !this.is_checkedAll;
        
        for ( let i = 0; i < this.members.length; i++ ) {
            this.members[i].checked = this.is_checkedAll;
        }
    }

    /**
     * 개별 체크박스 선택,해제
     */
    rowSelectCheckBox(pi_index) : void {
        this.members[pi_index].checked = !this.members[pi_index].checked;
        this.is_checkedAll = this.members.filter(member => member.checked == true).length == this.members.length;

        /*
        if ( this.members[pi_index].checked ) {
            let members_length = this.members.length;

            for ( let i = 0; i < members_length; i++ ) {
                if ( !this.members[i].checked ) {
                    this.is_checkedAll = false;
                    return;
                }
            }

            this.is_checkedAll = true;
        }
        else {
            this.is_checkedAll = false;
        }
        */
    }

    /**
     * 스크롤 top 이동
     */
    memberListScrollTop() : void {
        // @ts-ignore
        $('#sendMmsPageDiv').mCustomScrollbar("scrollTo", "top", { scrollInertia : 0 });
    }
}

