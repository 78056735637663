
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import moment from 'moment';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        
    },
})
export default class AttendanceTotalListYear extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    get computedYears() {

        let years : any[] = [];

        const current_year = new Date().getFullYear();

        for( let i = (current_year - 5); i <= (current_year + 5); i++ ) {
            years.push(i);
        }

        return years;
    }

   /**
     * @AttendanceInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @AttendanceInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트

    year_emp_attend_info : any[] = [];
    departments : any[] = [];

    selected_year : string = '';
    selected_dept_id : number = -1;

    header_width : number = 0;
    resize_function_submit : boolean = false;

    async mounted() {

        $(".teamLeft").click(function(){
            $(".dropdown").toggleClass("on");
        });

        this.selected_year = moment(this.selected_date).format('YYYY');

        await this.getTotalData();

        this.setScroll();

        // $(document).ready(function(){
        //     setYeareBox();
        // });
        // // select box 연도
        // function setYeareBox(){
        //     let dt = new Date();
        //     let com_year = dt.getFullYear();
        //     // 현재 년도 자동 선택
        //     $("#yearSel").append("<option value='" + com_year + "' selected>" + com_year + "년</option>");
        //      // 올해 기준으로 -10년부터 +0년을 보여준다.
        //     for(var y = (com_year-10); y <= (com_year+0); y++){
        //         $("#yearSel").append("<option value='" + y + "'>" + y + "년</option>");
        //     }
        // }

        // var $divs = $('#attendance_list_year_left_scroll, #totalCon_year_left_scroll');
        // var sync = function(e){
        //     var $other = $divs.not(this).off('scroll'), other = $other.get(0);
        //     var percentage = this.scrollTop / (this.scrollHeight - this.offsetHeight);
        //     other.scrollTop = percentage * (other.scrollHeight - other.offsetHeight);
        //     setTimeout( function(){ $other.on('scroll', sync ); },10);
        // }
        // $divs.on( 'scroll', sync);
    }

    /**
     * 스크롤 설정
     */
     setScroll() : void {

        this.$nextTick(() => {
            
            const title_height = $('#attendance .title_box').outerHeight();
            const schedule_box_height = $('#attendance_total_list_year .schedule_box').outerHeight();
            const sort_header_height = $('#attendance_total_list_year .sortHeader').outerHeight();

            console.log(`${title_height}px ${schedule_box_height}px ${sort_header_height}px`)

            const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                    - (schedule_box_height ? schedule_box_height : 0)
                                                    - (sort_header_height ? sort_header_height : 0) - 10;

            // @ts-ignore
            $('#attendance_list_year_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_year_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#totalCon_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#attendance_list_year_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            // @ts-ignore
            $('#totalCon_year_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#totalCon_year_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#attendance_list_year_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                        
                    }
                }
            });

            // @ts-ignore
            $('#attendance_list_year_right_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_year_right_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });

                        // @ts-ignore
                        $('#totalCon_year_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            
            this.event_bus.$on('totalListYearResize', this.handleResize);
        });

     }

    /**
     * attend data 가져오기
     */
    @Watch('selected_dept_id')
    @Watch('selected_year')
    async getTotalData() : Promise<void> {
        try {
            await this.hodu_show_indicator();

            // @ts-ignore
            // $('#attendance_list_month_right_scroll').mCustomScrollbar('destroy');

            // let month_start = moment(`${this.date_string.substring(0,4)}.01.01`).toDate();
            // let month_end   = moment(month_start).add('year', 1).set('date', 0).toDate();

            // await Promise.all([this.getLunar(month_start, month_end), this.getHolidays(month_start, month_end), this.getOrganization()]);
            
            await this.getOrganization()
            await this.getEmpAttendDataYearInfo();

            // this.$nextTick(() => {
                
            //     this.setScroll();

            //     if( this.date_string == this.hodu_date_to_format_string(new Date(), 'YYYY.MM') ) {
            //         const last_date = new Date();
            //         last_date.setMonth(last_date.getMonth() + 1);
            //         last_date.setDate(0);
            //         const width = document.getElementById('month_right_scroll_ul')?.offsetWidth ?? 0;
            //         $('#month_right_scroll').scrollLeft((width * (new Date().getDate() / last_date.getDate())) - 60);
            //     }
            //     else {
            //         $('#month_right_scroll').scrollLeft(0);
            //     }
            // });            

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 이벤트(월 달력 일별 근태현황) 조회
     */
    async getEmpAttendDataYearInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/data/year?year=${this.selected_year}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.year_emp_attend_info ) {
                throw new Error("연별 통합 리스트 근태현황 조회 실패");
            }

            if( this.selected_dept_id >= 0 ) {
                response.data.data.year_emp_attend_info = response.data.data.year_emp_attend_info.filter(emp => emp.dept_id == this.selected_dept_id);
            }
            
            this.year_emp_attend_info.splice(0, this.year_emp_attend_info.length);
            this.year_emp_attend_info = this.year_emp_attend_info.concat(response.data.data.year_emp_attend_info);

            // 근태현황 시작일 관련 처리
            for( const emp of this.year_emp_attend_info ) {

                if( emp.late != null ) {
                    for( const key in emp.late ) {
                        // 근태현황 시작일이 적용 안됐다면 전부 제외
                        if( this.getAttendanceStart() == '' ) {
                            delete(emp.late[key]);
                            continue;
                        }

                        // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                        if( Number(this.getAttendanceStart()) > Number(key) ) {
                            delete(emp.late[key]);
                            continue;
                        }
                    }
                }

                if( emp.awol != null ) {
                    for( const key in emp.awol ) {
                        // 근태현황 시작일이 적용 안됐다면 전부 제외
                        if( this.getAttendanceStart() == '' ) {
                            delete(emp.awol[key]);
                            continue;
                        }

                        // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                        if( Number(this.getAttendanceStart()) > Number(key) ) {
                            delete(emp.awol[key]);
                            continue;
                        }
                    }
                    
                }

                if( emp.vacation != null ) {
                    for( const key in emp.vacation ) {
                        // 근태현황 시작일이 적용 안됐다면 전부 제외
                        if( this.getAttendanceStart() == '' ) {
                            delete(emp.vacation[key]);
                            continue;
                        }

                        // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                        if( Number(this.getAttendanceStart()) > Number(key) ) {
                            delete(emp.vacation[key]);
                            continue;
                        }
                    }
                }

                if( emp.business_trip != null ) {
                    for( const key in emp.business_trip ) {
                        // 근태현황 시작일이 적용 안됐다면 전부 제외
                        if( this.getAttendanceStart() == '' ) {
                            delete(emp.business_trip[key]);
                            continue;
                        }

                        // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                        if( Number(this.getAttendanceStart()) > Number(key) ) {
                            delete(emp.business_trip[key]);
                            continue;
                        }
                    }
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getAttendanceStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    getDeptName(dept_id : number) : string {

        if( dept_id == null || dept_id < 0 ) {
            return '전체 부서';
        }

        const filter = this.departments.filter(dept => dept.dept_id == dept_id);
        
        if( filter.length < 1 ) {
            return '';
        }

        return filter[0].dept_name;
    }

    async excelDownload() {
        
        if( this.year_emp_attend_info.length < 1 ) {
            return;
        }

        try {

            const months : any[] = [];
            const employees : any[] = [];

            for( let i = 1; i <= 12; i++ ) {
                months.push({
                    "month_string": `${i}월`,
                    "month_string2": `0${i}`.slice(-2),
                    "month": i
                });
            }

            for( const emp of this.year_emp_attend_info ) {

                const employee : any = {
                    "obj" : emp,
                    "user_name" : emp.user_name,
                    "pos_name" : emp.pos_name,
                    "dept_name" : emp.dept_name,
                    "total": {
                        "vacation": (emp.use_annual_count == null || emp.use_annual_count == '') ? this.getTotalVacationCount(emp) : emp.use_annual_count,
                        "late": this.getTotalLateCount(emp),
                        "business_trip": this.getTotalBusinessTripCount(emp),
                        "awol": this.getTotalAwolCount(emp)          
                    },
                    "attend": {}
                };

                for( const month of months ) {
                    
                    employee.attend[String(month.month_string2)] = {
                        "vacation": this.getVacationCount(emp, month.month_string2),
                        "late": this.getLateCount(emp, month.month_string2),
                        "business_trip": this.getBusinessTripCount(emp, month.month_string2),
                        "awol": this.getAwolCount(emp, month.month_string2)  
                    }

                }

                employees.push(employee);
            }

            const body = {
                "name" : encodeURIComponent(`${this.selected_year}_호두_연별_통합_리스트.xlsx`),
                "sheet_name" : this.selected_year,
                "months" : months, 
                "emp" : employees
            }

            console.log(body);

            const excel_response = await this.$http({
                url : "api/v1/excel/attend/total_list_year",
                method : 'POST',
                responseType: 'blob',
                headers : {
                    "Cache-Control" : "no_cache"
                },
                data : body
            });

            console.log(excel_response);

            const blob = new Blob([excel_response.data]);

            // @ts-ignore
            if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, decodeURIComponent(body.name)); // for IE
                return;
            }

            await this.hodu_download_blob(blob, decodeURIComponent(body.name));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }

        
    }

    /**
     * count 지각
     */
    getLateCount(employee, month) {

        if( employee.late == null ) {
            return 0;
        }

        const keys = Object.keys(employee.late);
        const month_keys = keys.filter(key => new RegExp(`${this.selected_year}${month}`).test(key) &&
                                              new RegExp(/[0-9]{8}/ig).test(key));

        let count = 0;

        for( const month_key of month_keys ) {
            const obj_array = employee.late[month_key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 결근
     */
    getAwolCount(employee, month) {

        if( employee.awol == null ) {
            return 0;
        }

        const keys = Object.keys(employee.awol);
        const month_keys = keys.filter(key => new RegExp(`${this.selected_year}${month}`).test(key) &&
                                              new RegExp(/[0-9]{8}/ig).test(key));

        let count = 0;

        for( const month_key of month_keys ) {
            const obj_array = employee.awol[month_key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 연차
     */
    getVacationCount(employee, month) {

        if( employee.vacation == null ) {
            return 0;
        }

        const keys = Object.keys(employee.vacation);
        const month_keys = keys.filter(key => new RegExp(`${this.selected_year}${month}`).test(key) &&
                                              new RegExp(/[0-9]{8}/ig).test(key));
        
        console.log(month_keys);

        let count = 0;

        for( const month_key of month_keys ) {
            const obj_array = employee.vacation[month_key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 출장
     */
    getBusinessTripCount(employee, month) {

        if( employee.business_trip == null ) {
            return 0;
        }

        const keys = Object.keys(employee.business_trip);
        const month_keys = keys.filter(key => new RegExp(`${this.selected_year}${month}`).test(key) &&
                                              new RegExp(/[0-9]{8}/ig).test(key));
        
        console.log(month_keys);

        let count = 0;

        for( const month_key of month_keys ) {
            const obj_array = employee.business_trip[month_key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 지각
     */
    getTotalLateCount(employee) {

        if( employee.late == null ) {
            return 0;
        }

        const keys = Object.keys(employee.late);

        let count = 0;

        for( const key of keys ) {
            const obj_array = employee.late[key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 결근
     */
    getTotalAwolCount(employee) {

        if( employee.awol == null ) {
            return 0;
        }

        const keys = Object.keys(employee.awol);

        let count = 0;

        for( const key of keys ) {
            const obj_array = employee.awol[key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 연차
     */
    getTotalVacationCount(employee) {

        if( employee.vacation == null ) {
            return 0;
        }

        const keys = Object.keys(employee.vacation);

        let count = 0;

        for( const key of keys ) {
            const obj_array = employee.vacation[key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    /**
     * count 출장
     */
    getTotalBusinessTripCount(employee) {

        if( employee.business_trip == null ) {
            return 0;
        }

        const keys = Object.keys(employee.business_trip);

        let count = 0;

        for( const key of keys ) {
            const obj_array = employee.business_trip[key];

            if( obj_array == null ) continue;

            for( const obj of obj_array ) {
                count += obj.daycount;
            }
        }

        return count;
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_taget.parent().find('p.img').css('background-image', `url(${replace_image})`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#attendance_list_year_left_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#totalCon_year_left_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#attendance_list_year_right_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

}
