
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

import moment from 'moment';

import { namespace } from 'vuex-class';
const EtcInfo    = namespace('EtcInfo');
const dateFormat = require('dateformat');

@Component({
    components: {
        ResizeObserver
    },
})
export default class HoduNoticeList extends Mixins(VueHoduCommon) {
    notice_data          : any     = [];
    original_notice_data : any     = [];
    admin_flag           : boolean = false;

    notice_sort_target : string | null = null;
    notice_sort_direction : string = "ASC";

    @EtcInfo.Action doHoduNotiUid ?: any;

    async mounted() : Promise<void> {
        const vue = this;
        
        this.setScroll();

        this.admin_flag = await this.getAdminCheck();

        // 공지사항 조회
        this.getNotice();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();
        const scrollHeight     : number | undefined = window.innerHeight - ( titleHeight ? titleHeight : 0 ) - ( settingBgHeight ? settingBgHeight : 0 ) - ( sortHeaderHeight ? sortHeaderHeight : 0 );

        // @ts-ignore
        $('#group_notice_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : scrollHeight,
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 그룹 공지사항 조회
     */
    async getNotice() : Promise<void> {
        this.notice_data = [];

        await this.hodu_api_call(`api/v1/notification/notice/0/0/HODU?page=0&size=0&current_date=${moment(new Date()).utc().format()}`, API_METHOD.GET, null)
            .then(async(response) => {
                console.log(response);
                this.notice_data = response.data.data.list;

                $.each(this.notice_data, function(key, data) {
                    data.noti_info.start_date = dateFormat(data.noti_info.start_date, 'yyyy.mm.dd');
                });

                this.original_notice_data = JSON.parse(JSON.stringify(this.notice_data));
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 공지사항 상세보기
     */
    async detailNotice(po_data) : Promise<void> {
        const vue = this;

        // 공지사항 읽음여부
        if ( !po_data.read_flag ) {
            await this.hodu_api_call(`api/v1/notification/insertRead/${po_data.noti_uid}`, API_METHOD.PUT, {}, false)
                .then(async(response) => {
                    vue.goNoticeDetail(po_data);
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }
        else {
            vue.goNoticeDetail(po_data);
        }
    }    

    async goNoticeDetail(po_data) : Promise<void> {
        this.doHoduNotiUid(po_data.noti_uid);
        this.hodu_router_push(`/hodu/${new Date().getTime()}/notice_detail`);
    }

    ckeditor4() : void {
        this.doHoduNotiUid("");
        this.hodu_router_push(`/hodu/${new Date().getTime()}/notice_create`);
    }

    /**
     * 제목 기준 정렬
     */
    subjectSort() : void {
        // 이미 SUBJECT Sort 중이라면
        if( this.notice_sort_target == "SUBJECT" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // SUBJECT Sort가 아니였다면
        else {
            this.notice_sort_target = "SUBJECT";
            this.notice_sort_direction = "ASC";
        }

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * 작성일 기준 정렬
     */
    timeSort() : void {
        // 이미 TIME Sort 중이라면
        if( this.notice_sort_target == "TIME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // TIME Sort가 아니였다면
        else {
            this.notice_sort_target = "TIME";
            this.notice_sort_direction = "ASC";
        }

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.notice_data = JSON.parse(JSON.stringify(this.original_notice_data));
    }

    /**
     * 공지사항 정렬
     */
    noticeListSort() : void {
        this.notice_data.sort((o1 : any, o2 : any) : number => {

            // 제목 오름차순
            if( this.notice_sort_target == "SUBJECT" && this.notice_sort_direction == "ASC" ) {
                if( o1.noti_info.title  > o2.noti_info.title ) { return  1; } 
                if( o1.noti_info.title  < o2.noti_info.title ) { return -1; } 
                if( o1.noti_info.title == o2.noti_info.title ) { return  0; }
            }

            // 제목 내림차순
            if( this.notice_sort_target == "SUBJECT" && this.notice_sort_direction == "DESC" ) {
                if( o1.noti_info.title  > o2.noti_info.title ) { return -1; } 
                if( o1.noti_info.title  < o2.noti_info.title ) { return  1; } 
                if( o1.noti_info.title == o2.noti_info.title ) { return  0; }
            }

            // 작성일 오름차순
            if( this.notice_sort_target == "TIME" && this.notice_sort_direction == "ASC" ) {
                if( new Date(o1.noti_info.start_date).getTime()  > new Date(o2.noti_info.start_date).getTime() ) { return  1; } 
                if( new Date(o1.noti_info.start_date).getTime()  < new Date(o2.noti_info.start_date).getTime() ) { return -1; } 
                if( new Date(o1.noti_info.start_date).getTime() == new Date(o2.noti_info.start_date).getTime() ) { return  0; }
            }

            // 작성일 내림차순
            if( this.notice_sort_target == "TIME" && this.notice_sort_direction == "DESC" ) {
                if( new Date(o1.noti_info.start_date).getTime()  > new Date(o2.noti_info.start_date).getTime() ) { return -1; } 
                if( new Date(o1.noti_info.start_date).getTime()  < new Date(o2.noti_info.start_date).getTime() ) { return  1; } 
                if( new Date(o1.noti_info.start_date).getTime() == new Date(o2.noti_info.start_date).getTime() ) { return  0; }
            }

            return 0;
        })
    }
    
    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#group_notice_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }
}
