import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

export async function chat_notification_process(vue : VueHoduCommon, data : any) {
    
    if( data.noti_type != 'CHANNEL' ) return;

    const noti_sub_type = data.noti_sub_type;

    switch( noti_sub_type ) {

        /**
         * 채팅 메시지
         */
        case "MSG":
            vue.hodu_router_push(`/chatting/${new Date().getTime()}`, () => join_channel(vue, data));
            break;
    }

}

/**
 * 채팅방(채널) 접속
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 */
async function join_channel(vue : VueHoduCommon, data : any) : Promise<void> {

    try {
                
        const response = await vue.hodu_api_call(`api/v1/chat/channel_info/${data.channel_uid}/join`, API_METHOD.GET);

        if( !response || !vue.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.channel ) {
            throw new Error("채팅방 참여 실패");
        }

        // 채팅 부모 컴포넌트 찾기
        const channel_component = vue.$route.matched[0].instances['default'];

        channel_component['connected_channel'] = response.data.data.channel;

        if( channel_component['connected_channel'] != null && channel_component['connected_channel'] != null ) {
            for( const user of channel_component['connected_channel'].users ) {
                if( Number(user.user_id) != channel_component['user_id'] ) continue;
                channel_component['is_chatting_room_manager'] = Number(user.user_id) == channel_component['user_id'] && user.user_role == 'OWNER';
                break;
            }
        }

        if( channel_component['connected_channel'] == null || channel_component['connected_channel'].channel_url == null || channel_component['connected_channel'].channel_url.length < 1 ) {
            throw new Error("채팅방 참여 실패");
        }

        channel_component['openWebSocket'](response.data.data.channel.channel_url);

    } catch(e) {
        vue.hodu_error_process(e, true, false);
    }

}