
import { Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD, GROUP_TYPE, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

import { GroupAddFileModalNewInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';
import { hodu_color } from '@/common/color';
import { hodu_cookie } from '@/lib/HoduCookie';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class GroupFileBox extends Mixins(VueHoduCommon) {

    /**
     * 내 파일함 & 그룹 파일함 반환
     */
    get computedMyDirList() : any[] {

        // if( this.scope == OWNER_TYPE.PERSONAL ) {
        //     // 개인 파일함
        //     return [];
        // }

        let target_list : any[] = JSON.parse(JSON.stringify(this.group_dir_list));

        target_list = target_list.sort((item, item2) : number => {

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.dir_name == item2.dir_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.dir_name > item2.dir_name ? 1 : -1;
                }

                return item.dir_name < item2.dir_name ? 1 : -1;
            }

            // 유저 이름 기준 정렬
            if( this.sort_type == 'USER_NAME' ) {
                
                // 유저 이름이 같다면
                if( item.user_name == item2.user_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.user_name > item2.user_name ? 1 : -1;
                }

                return item.user_name < item2.user_name ? 1 : -1;
            }

            // 파일수 기준 정렬
            if( this.sort_type == 'FILE_COUNT' ) {
                
                // 파일수가 같다면
                if( item.files.length == item2.files.length ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.files.length > item2.files.length ? 1 : -1;
                }

                return item.files.length < item2.files.length ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {
                
                // 날짜가 같다면
                if( new Date(item.audit_modified).getTime() == new Date(item2.audit_modified).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item.audit_modified).getTime() > new Date(item2.audit_modified).getTime() ? 1 : -1;
                }

                return new Date(item.audit_modified).getTime() < new Date(item2.audit_modified).getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;

    }

    /**
     * 내 파일함 & 그룹 파일함 반환
     */
    get computedHoduCList() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.hodu_c_list));

        target_list = target_list.sort((item, item2) : number => {

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.group_name == item2.group_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.group_name > item2.group_name ? 1 : -1;
                }

                return item.group_name < item2.group_name ? 1 : -1;
            }

            // 파일수 기준 정렬
            if( this.sort_type == 'FILE_COUNT' ) {
                
                // 파일수가 같다면
                if( this.getFileCount(item) == this.getFileCount(item2) ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return this.getFileCount(item) > this.getFileCount(item2) ? 1 : -1;
                }

                return this.getFileCount(item) < this.getFileCount(item2) ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {

                let item_1_date : Date | null = null;
                let item_2_date : Date | null = null;

                for( const dir of item.dir_list ) {
                    if( item_1_date == null ) { 
                        item_1_date = new Date(dir.audit_modified); 
                        continue;
                    }
                    if( item_1_date.getTime() < new Date(dir.audit_modified).getTime() ) { item_1_date = new Date(dir.audit_modified); }
                }

                for( const dir of item2.dir_list ) {
                    if( item_2_date == null ) { 
                        item_2_date = new Date(dir.audit_modified); 
                        continue;
                    }
                    if( item_2_date.getTime() < new Date(dir.audit_modified).getTime() ) { item_2_date = new Date(dir.audit_modified); }
                }

                if( item_1_date == null || item_2_date == null ) { return 0; }
                
                // 날짜가 같다면
                if( item_1_date.getTime() == item_2_date.getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item_1_date.getTime() > item_2_date.getTime() ? 1 : -1;
                }

                return item_1_date.getTime() < item_2_date.getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    /**
     * 내 파일함 & 그룹 파일함 반환
     */
    get computedHoduDList() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.hodu_d_list));

        target_list = target_list.sort((item, item2) : number => {

            // 디렉토리 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {
                
                // 디렉토리 이름이 같다면
                if( item.group_name == item2.group_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.group_name > item2.group_name ? 1 : -1;
                }

                return item.group_name < item2.group_name ? 1 : -1;
            }

            // 파일수 기준 정렬
            if( this.sort_type == 'FILE_COUNT' ) {
                
                // 파일수가 같다면
                if( item.file_list.length == item2.file_list.length ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item.file_list.length > item2.file_list.length ? 1 : -1;
                }

                return item.file_list.length < item2.file_list.length ? 1 : -1;
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {

                let item_1_date : Date | null = null;
                let item_2_date : Date | null = null;

                for( const file of item.file_list ) {
                    if( item_1_date == null ) { 
                        item_1_date = new Date(file.audit_created); 
                        continue;
                    }
                    if( item_1_date.getTime() < new Date(file.audit_created).getTime() ) { item_1_date = new Date(file.audit_created); }
                }

                for( const file of item2.file_list ) {
                    if( item_2_date == null ) { 
                        item_2_date = new Date(file.audit_created); 
                        continue;
                    }
                    if( item_2_date.getTime() < new Date(file.audit_created).getTime() ) { item_2_date = new Date(file.audit_created); }
                }

                if( item_1_date == null || item_2_date == null ) { return 0; }
                
                // 날짜가 같다면
                if( item_1_date.getTime() == item_2_date.getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item_1_date.getTime() > item_2_date.getTime() ? 1 : -1;
                }

                return item_1_date.getTime() < item_2_date.getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    /**
     * 검색 된 파일을 가진 디렉토리(파일리스트를 가짐)들을 반환
     */
    get computedSearchFileList() : any[] {

        if( this.file_search_query == null || this.file_search_query.length < 1 ) {
            return [];
        }

        let list : any[] = JSON.parse(JSON.stringify(this.search_files));

        for( const obj of list ) {
            obj.is_open = this.close_uuids.indexOf(obj.dir_uuid) < 0;
        }

        return list;
    }

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowGroupAddFileBox      ?: any;   
    @ModalInfo.Action doSetGroupAddFileModalInfo    ?: any;
    @ModalInfo.Action doSetGroupAddFileModalNewInfo ?: (params : GroupAddFileModalNewInfo) => void;  

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    group_dir_list : any[] = [];
    hodu_c_list : any[] = [];
    hodu_d_list : any[] = [];

    open_biz_id : string = "";
    
    file_search_query : string = "";

    is_right_div_open : boolean = true;
    is_card_view : boolean = true;

    close_uuids : string[] = [];

    file_box_tab : 'ALL' | 'PREMIUM' | 'HODUDOC' = 'ALL';
    
    // 정렬
    sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE' = 'DIR_NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    search_files : any[] = [];

    mounted() : void {

        const group_file_box_is_card_view = hodu_cookie.get('group_file_box_is_card_view');
        if( group_file_box_is_card_view != null ) {
            this.is_card_view = group_file_box_is_card_view === 'true';
        }

        const group_file_box_is_right_div_open = hodu_cookie.get('group_file_box_is_right_div_open');
        if( group_file_box_is_right_div_open != null ) {
            this.is_right_div_open = group_file_box_is_right_div_open === 'true';
        }

        // //파일 옵션 메뉴 toggle
        // $('.fileMenu').click(function(){ 
        //     $(this).siblings('ul').toggle();         
        // })

        // // 폴더 메뉴 toggle
        // $('.folder_menuFile > .folderDiv > button').click(function(){
        //     $(this).siblings('.folder_menuFile > .folderDiv > ul').toggle();            
        // })

        // // 서브폴더 열기
        // $(".subFolder_more").click(function(){
        //     $(this).parent().toggleClass("on");
        // });

        // // 왼쪽 메뉴 click
        // $(".leftMainMenu > li").click(function(){
        // 	$(this).addClass('active').siblings().removeClass();
        // })

        // // 왼쪽 메뉴 dropdown
        // $(".leftMainMenu > li .mainMore").click(function(){
        // 	$(".leftMainMenu > li.active .leftSubMenu").toggleClass('open');
        // })

        // 오른쪽 찾기버튼 클릭시 검색창 열기
        // $(".closeRight").click(function(){
        //     $(".section_ce_fix").toggleClass("rightDivClosed");
        //     $(this).toggleClass("on");

        // });

        // 리스트로 보이기
        // $(".btns .dpTwo").click(function(){
        //     $(".section_ce_fix").addClass("dp2");
        //     $(".section_ce_fix").removeClass("dp1");
        //     $(".btns .dpOne").show();
        //     $(this).hide();

        // });

        // 박스형으로 보이기
        // $(".btns .dpOne").click(function(){
        //     $(".section_ce_fix").removeClass("dp2");
        //     $(".section_ce_fix").addClass("dp1");
        //     $(".btns .dpTwo").show();
        //     $(this).hide();

        // });

        // 서브폴더 열기
        // $(".seeSubFolder").click(function(){
        //     $(this).parent().addClass("on");
        // });

        // 서브폴더 닫기
        // $(".closeSub").click(function(){
        //     $(this).parent().parent().parent().removeClass("on");

        // });

        // 오른쪽 검색 폴더 닫고 열기
        // $(".closeAndOpen").click(function(){
        //     $(this).parent().parent().toggleClass("on");

        // });

        // 개인 달력
        if( this.scope == OWNER_TYPE.PERSONAL ) { this.getFileBoxDirAndFile(); }

        // 그룹 달력
        else { this.getGroupFiles(); }


        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {
            const title_box_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const search_div_height : number | undefined = $('.mainRightContent .schDiv').outerHeight();
            const route_bar_height : number | undefined = $('#route_bar').outerHeight();

            // @ts-ignore
            $('.mainLeftDivScroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (route_bar_height ? route_bar_height : 0),
            });

            // @ts-ignore
            $('#file_search_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (search_div_height ? search_div_height : 0)
                                            - (route_bar_height ? route_bar_height : 0),
            });
        })

        
    }

    /**
     * 그룹 파일함 디렉토리 조회
     */
    async getGroupFiles() : Promise<void> {
        
        await this.hodu_api_call(`api/v1/groupsweb/getGroupFiles/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.GET)
            .then(async(response) => {
                this.group_dir_list.splice(0, this.group_dir_list.length)
                this.group_dir_list = this.group_dir_list.concat(response.data.data.files);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 유저와 관련된 파일함 전체 조회
     */
    async getFileBoxDirAndFile() : Promise<void> {

        await this.hodu_api_call(`api/v1/user/me/file`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.hodu_c_list.splice(0, this.hodu_c_list.length);
                this.hodu_c_list = this.hodu_c_list.concat(response.data.data.hodu_c_list);

                this.hodu_d_list.splice(0, this.hodu_d_list.length);
                this.hodu_d_list = this.hodu_d_list.concat(response.data.data.hodu_d_list);

            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });

    }

    /**
     * 파일박스 탭 변경
     */
    changeFileBoxTab(value : 'ALL' | 'PREMIUM' | 'HODUDOC') : void {
        this.file_box_tab = value;
        this.is_card_view = !this.is_card_view;
        this.$nextTick(() => { this.is_card_view = !this.is_card_view; });
    }

    /**
     * 카드 뷰 모드 ON / OFF
     */
    cardViewModeOnOff() : void {
        this.is_card_view = !this.is_card_view;

        hodu_cookie.set('group_file_box_is_card_view', String(this.is_card_view));
        
        if( this.scope == OWNER_TYPE.PERSONAL && this.open_biz_id != null && this.open_biz_id.length > 0 ) {
            
            const scroll_container = $(`#${this.open_biz_id}_scroll`);

            if( this.is_card_view == true ) {

                this.$nextTick(() => {
                
                    const li_height : number | undefined = $(`#${this.open_biz_id}_li`).innerHeight();
                    const li_title_height : number | undefined = $(`#${this.open_biz_id}_li .subFoldertitleDiv`).innerHeight();

                    // @ts-ignore
                    scroll_container.mCustomScrollbar({
                        axis : 'y',
                        scrollbarPosition : 'outside',
                        mouseWheelPixels : 100,
                        scrollInertia : 60,
                        autoDraggerLength : false,
                        setHeight : (li_height ? li_height : 0) - (li_title_height ? li_title_height : 0)
                    });

                });

            }

            else {
                // @ts-ignore
                scroll_container.mCustomScrollbar('destroy');
            }
            
        }

        this.$nextTick(() => this.handleResize());
    }

    /**
     * 오른쪽 div 닫기 / 열기
     */
    rightDivOnOff() : void {
        this.is_right_div_open = !this.is_right_div_open; 
        hodu_cookie.set('group_file_box_is_right_div_open', String(this.is_right_div_open));
    }

    /**
     * 검색쪽 디렉토리 닫기 / 열기
     */
    searchDirOpenOnOff(dir_obj : any) : void {

        if( this.close_uuids.indexOf(dir_obj.dir_uuid) < 0 ) {
            this.close_uuids.push(dir_obj.dir_uuid);
        }

        else {
            this.close_uuids.splice(this.close_uuids.indexOf(dir_obj.dir_uuid), 1);
        }

    }

    /**
     * 배경화면 반환
     */
    getBackgroundImage(file : any) : string {
        if( new RegExp(escape('image')).test(escape(file.file_type)) == false ) {
            return 'none';
        }

        return file.file_path.indexOf('patient_file') > -1 ? `url(app_images/${file.file_path})` : `url(app_images/group_file/${file.file_path})`;
    }

    /**
     * 호두 C - 그룹 파일 개수 반환
     */
    getFileCount(group : any) : number {
        let file_count : number = 0;
        for( const dir of group.dir_list ) { file_count += dir.path_files.length; }
        return file_count;
    }

    /**
     * 호두 C & D - 마지막 업데이트 된 날짜
     */
    getLastUpdate(group : any) : string {

        let target_date : Date | null = null;
        for( const dir of (group.dir_list ? group.dir_list : group.file_list) ) {
            
            // 첫 데이터
            if( target_date == null ) {
                target_date = new Date(dir.audit_modified);
                continue;
            }

            // 비교
            if( target_date.getTime() < new Date(dir.audit_modified).getTime() ) {
                target_date = new Date(dir.audit_modified);
            }
        }

        // 리스트가 없다면
        if( target_date == null ) { return ''; }

        return `${this.hodu_date_to_format_string(target_date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(target_date)}`;
    }

    /**
     * 그룹 파일함 폴더 열기
     */
    openGroupFilxBox(biz_id : string) : void {
        this.open_biz_id = biz_id;

        if( this.is_card_view == false ) { return; } 

        this.$nextTick(() => {
            
            const li_height : number | undefined = $(`#${biz_id}_li`).innerHeight();
            const li_title_height : number | undefined = $(`#${biz_id}_li .subFoldertitleDiv`).innerHeight();

            // @ts-ignore
            $(`#${biz_id}_scroll`).mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : (li_height ? li_height : 0) - (li_title_height ? li_title_height : 0)
            });

        });
    }

    /**
     * 그룹 파일함 폴더 닫기
     */
    closeFileBox() : void {

        if( this.is_card_view == true ) { 
            // @ts-ignore
            $(`#${this.open_biz_id}_scroll`).mCustomScrollbar('destroy');
        }

        this.open_biz_id = "";
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }

        // console.log(`[${this.sort_type}, ${this.sort_dir}]`);
    }

    /**
     * 파일함 추가 모달
     */
    showGroupAddFileBox() : void {

        this.doSetGroupAddFileModalNewInfo?.({
            show_modal : true,
            is_create: true,
            dir_uuid: "",
            group_id: this.scope_group_id,
            // dir_name: "",
            // dir_color: hodu_color.hodu_dc_2,
            // dir_icon: 0,
            create_function: this.scope == OWNER_TYPE.PERSONAL ? () => {} : this.getGroupFiles
        });
    }

    /**
     * 디렉토리 수정
     */
    modifyDir(dir) : void {

        this.doSetGroupAddFileModalNewInfo?.({
            show_modal : true,
            is_create: false,
            dir_uuid: dir.dir_uuid,
            group_id: dir.group_id,
            // dir_name: dir.dir_name,
            // dir_color: dir.dir_color,
            // dir_icon: dir.dir_icon,
            update_function: (is_delete : boolean, dir_uuid : string) => {
                this.getGroupFiles();
            }
        });

    }

    /**
     * 파일함 디렉토리로 이동
     */
    async moveFileDir(dir : any, group ?: any) : Promise<void> {
        
        let uuid : string = "";

        if( group != null && this.computedHoduDList.indexOf(group) > -1 ) {
            uuid = group.biz_id;
        }
        else {
            uuid = dir.dir_uuid;
        }

        if( dir.has_password == true ) {
            this.password_input(dir, group);
            return;
        }
        
        // 개인 달력인 경우 그룹 달력으로 이동 시킨 후 이동한다
        if( this.scope == OWNER_TYPE.PERSONAL && group.group_type == GROUP_TYPE.BIZC ) {

            // 달력으로 먼저 이동
            this.hodu_router_push(`/?${new Date().getTime()}`, async() => {

                if( $('#bt_calContr').hasClass('on') == false ) {
                    $('.ui-datepicker-calendar-wrap').hide();
                    $('#bt_calContr').addClass('on');
                }

                this.doSetGroupMenuClose(true);
                this.doSetMessageMenuClose(true);
                this.doSetWorkMenuClose(true);
                this.doSetCommunityMenuClose(true);
                this.doSetResidentMenuClose(true);

                this.doGroupId(group.group_id);
                this.doTeamId(0);

                this.doSetCalendarId(`group-${this.group_id}`);
                this.doSetScope(OWNER_TYPE.GROUP);
                this.doSetScopeGroupId(this.group_id);
                this.doSetScopeTeamId(0);
                this.doSetScopeGroupTeamOption({
                    group_team_name     : group.group_name,
                    group_team_color    : group.group_color,
                    group_team_image    : "",
                    group_team_descript : "",
                    biz_id              : group.biz_id,
                    biz_type            : GROUP_TYPE.BIZC,
                });

                await this.hodu_loading_timer_exit();
                await this.hodu_loading();

                setTimeout(() => {
                    // @ts-ignore
                    $('.left_inbox02').mCustomScrollbar('destroy');
                    window['left_inbox02_mCustomScrollbar']();
                }, 100);

                if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }

                this.hodu_router_push(`/group/${new Date().getTime()}/file/${uuid}`);

            });

            return;
        }

        // 호두 D 
        if( this.scope == OWNER_TYPE.PERSONAL && group.group_type == GROUP_TYPE.BIZD ) {
            this.hodu_router_push(`/hospital/file/${group.biz_id}`);
            return;
        }

        this.hodu_router_push(`/group/${new Date().getTime()}/file/${uuid}`);
    }

    /**
     * 검색된 파일 또는 디렉토리를 누르면 이동
     */
    moveSearchFileDir(dir_obj : any) : void {

        if( dir_obj.files == null || dir_obj.files.length < 1 ) { return; }

        if( dir_obj.has_password == true ) {
            this.password_input(dir_obj);
            return;
        }

        // 개인 달력인 경우 && 호두 C 디렉토리인 경우 그룹 달력으로 이동 시킨 후 이동한다
        if( this.scope == OWNER_TYPE.PERSONAL && dir_obj.group_type == GROUP_TYPE.BIZC ) {

            // 달력으로 먼저 이동
            this.hodu_router_push(`/?${new Date().getTime()}`, async() => {

                if( $('#bt_calContr').hasClass('on') == false ) {
                    $('#container').find('#left_area').find('.left_inbox').addClass('on');
                    $('.ui-datepicker-calendar-wrap').hide();
                    $('#bt_calContr').addClass('on');

                    const left_area_height : number | undefined = $('#left_area').outerHeight();
                    const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

                    const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
                    const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

                    let heightSize : number = left_area_height_final - left_inbox_height_final;
                    $('.left_inbox02').height(heightSize+"px");
                }

                this.doSetGroupMenuClose(true);
                this.doSetMessageMenuClose(true);
                this.doSetWorkMenuClose(true);
                this.doSetCommunityMenuClose(true);
                this.doSetResidentMenuClose(true);

                this.doGroupId(dir_obj.files[0].group_id);
                this.doTeamId(0);

                this.doSetCalendarId(`group-${this.group_id}`);
                this.doSetScope(OWNER_TYPE.GROUP);
                this.doSetScopeGroupId(this.group_id);
                this.doSetScopeTeamId(0);
                this.doSetScopeGroupTeamOption({
                    group_team_name     : dir_obj.group_name,
                    group_team_color    : dir_obj.group_color,
                    group_team_image    : "",
                    group_team_descript : "",
                    biz_id              : dir_obj.files[0].biz_id,
                    biz_type            : GROUP_TYPE.BIZC,
                });

                await this.hodu_loading_timer_exit();
                await this.hodu_loading();

                setTimeout(() => {
                    // @ts-ignore
                    $('.left_inbox02').mCustomScrollbar('destroy');
                    window['left_inbox02_mCustomScrollbar']();
                }, 100);

                if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }

                this.hodu_router_push(`/group/${new Date().getTime()}/file/${dir_obj.dir_uuid}`);

            });

            return;
        }

        // HODU D인 경우
        if( dir_obj.group_type == GROUP_TYPE.BIZD ) {
            this.hodu_router_push(`/hospital/file/${dir_obj.files[0].biz_id}`);
            return;
        }

        this.hodu_router_push(`/group/${new Date().getTime()}/file/${dir_obj.dir_uuid}`);
    }

    /**
     * 디렉토리 비밀번호 입력
     */
    password_input(dir : any, group ?: any) {

        let uuid : string = "";

        if( group != null && this.computedHoduDList.indexOf(group) > -1 ) {
            uuid = group.biz_id;
        }
        else {
            uuid = dir.dir_uuid;
        }

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }
                    
                    // 개인 달력인 경우 그룹 달력으로 이동 시킨 후 이동한다
                    if( this.scope == OWNER_TYPE.PERSONAL && ((group != null && group.group_type == GROUP_TYPE.BIZC) || (group == null && dir.group_type == GROUP_TYPE.BIZC)) ) {

                        // 달력으로 먼저 이동
                        this.hodu_router_push(`/?${new Date().getTime()}`, async() => {

                            if( $('#bt_calContr').hasClass('on') == false ) {
                                $('.ui-datepicker-calendar-wrap').hide();
                                $('#bt_calContr').addClass('on');
                            }

                            this.doSetGroupMenuClose(true);
                            this.doSetMessageMenuClose(true);
                            this.doSetWorkMenuClose(true);
                            this.doSetCommunityMenuClose(true);
                            this.doSetResidentMenuClose(true);

                            this.doGroupId(group != null ? group.group_id : dir.group_id);
                            this.doTeamId(0);

                            this.doSetCalendarId(`group-${this.group_id}`);
                            this.doSetScope(OWNER_TYPE.GROUP);
                            this.doSetScopeGroupId(this.group_id);
                            this.doSetScopeTeamId(0);
                            this.doSetScopeGroupTeamOption({
                                group_team_name     : group != null ? group.group_name : dir.group_name,
                                group_team_color    : group != null ? group.group_color : dir.group_color,
                                group_team_image    : "",
                                group_team_descript : "",
                                biz_id              : group != null ? group.biz_id : dir.biz_id,
                                biz_type            : GROUP_TYPE.BIZC,
                            });

                            await this.hodu_loading_timer_exit();
                            await this.hodu_loading();

                            setTimeout(() => {
                                // @ts-ignore
                                $('.left_inbox02').mCustomScrollbar('destroy');
                                window['left_inbox02_mCustomScrollbar']();
                            }, 100);

                            if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }

                            this.hodu_router_push(`/group/${new Date().getTime()}/file/${uuid}`);

                        });

                        return;
                    }

                    // 호두 D 
                    if( this.scope == OWNER_TYPE.PERSONAL && ((group != null && group.group_type == GROUP_TYPE.BIZD) || (group == null && dir.group_type == GROUP_TYPE.BIZD)) ) {
                        this.hodu_router_push(`/hospital/file/${group != null ? group.biz_id : dir.biz_id}`);
                        return;
                    }

                    this.hodu_router_push(`/group/${new Date().getTime()}/file/${uuid}`);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });
    }

    menu_open(target_dir) {
        for( const dir of this.computedMyDirList ) {
            if( dir.dir_uuid == target_dir.dir_uuid ) continue;
            dir.open_menu = false;
        }

        if( target_dir.open_menu == null ) {
            target_dir.open_menu = true;
        }
        else {
            target_dir.open_menu = !target_dir.open_menu;
        }
        this.$forceUpdate();
    }
    
    delete_password(dir) {

        const uuid = dir.dir_uuid;
        
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }

                    const delete_response = await this.hodu_api_call(`api/v1/groups/${dir.group_id}/file/${uuid}/password`, API_METHOD.DELETE);

                    console.log(delete_response);

                    if( !delete_response || !this.isHttpStatusSuccess(delete_response.status) ) {
                        throw new Error("파일함 비밀번호 삭제 중 오류 발생");
                    }
                    
                    this.getGroupFiles();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });

    }

    /**
     * 파일보관함 리스트로 이동
     */
     goGroupFileList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        
        this.doGroupId(this.scope_group_id);
        this.doTeamId(this.scope_team_id);
        this.doGroupTeamOption(JSON.parse(JSON.stringify(this.scope_group_team_option)));
        this.hodu_router_push(`/group/${new Date().getTime()}/file`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        // @ts-ignore
        $('.mainLeftDivScroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#file_search_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

    /**
     * 파일 검색 쿼리 변경 감지
     */
    @Debounce(250)
    @Watch('file_search_query')
    async watchFileSearchQuery() : Promise<void> {
        this.close_uuids.splice(0, this.close_uuids.length);

        if( this.file_search_query.length < 1 ) {
            this.search_files.splice(0, this.search_files.length);
            return;
        }

        try {

            // 전체 검색
            if( this.isPersonalScope(this.scope) ) {

                const response = await this.hodu_api_call(`api/v1/file?search_query=${this.file_search_query}`, API_METHOD.GET, null, false);

                console.log(response);
                
                if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.groups ) {
                    throw new Error("검색 중 오류 발생");
                }
    
                this.search_files.splice(0, this.search_files.length);
                this.search_files = this.search_files.concat(response.data.data.groups);
            }
            
            // 그룹 검색
            else {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/file?search_query=${this.file_search_query}`, API_METHOD.GET, null, false);

                console.log(response);
                
                if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.files ) {
                    throw new Error("검색 중 오류 발생");
                }
            
                this.search_files.splice(0, this.search_files.length);
                this.search_files = this.search_files.concat(response.data.data.files);
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

}   
