
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupModalInfo, GroupListConfirmModalData, ImageCropModalInfo } from '@/store/modules/ModalInfo';
import { t_event_file } from '@/model/event';

const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');

@Component({

})
export default class GroupTeamCreate extends Mixins(VueHoduCommon) {
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;

    /**
     * 변수 선언부
     */
    team_data : any = {
        team_id: 0,
        group_id: 0,
        biz_id: "",
        team_type: "",
        create_user_id: 0,
        team_info: {
            team_name: "",
            description: "",
            image: "",
            team_color: "#477FFF"
        },
        team_policy: {
            is_public: false,
            is_appr_required: false
        },
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: 0
    }

    is_created    : boolean = true;
    is_deleteFlag : boolean = false;
    image_drag    : boolean = false;
    base64url     : string  = "";
    image_files   : any     = [];
    biz_id        : string  = "";
    team_no_image = null;

    color_pick : boolean = false;

    async beforeMount() {
        if ( !this.group_id || this.group_id < 1 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() {

        this.biz_id = this.group_team_option.biz_id;

        if ( this.group_id > 0 && this.team_id > 0 ) {
            this.is_created = false;

            // 삭제 퍼미션 체크
            this.is_deleteFlag = this.is_group_permmision(this.group_id, "team", "modify");

            if ( !this.is_deleteFlag ) {
                this.is_deleteFlag = this.is_team_permmision(this.team_id, "team_info", "delete");
            }

            await this.getGroupTeam();
        }
        else {
            this.team_no_image = require("@/assets/images/contents/img"+this.group_color_to_class_name(this.team_data.team_info.team_color)+".png");
        }

        // HODU H는 기본이 승인 받도록
        if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
            this.team_data.team_policy.is_appr_required = true;
        }
    }

    /**
     * 팀 단건 조회
    */
    async getGroupTeam() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getDetail/${this.group_id}/${this.team_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                if ( !response.data.data.data ) {
                    this.hodu_show_dialog("cancel", '팀 조회중 오류가 발생하였습니다.', ['확인']);
                    vue.movePrevPage();
                    return;
                }

                let data = response.data.data.data;
                this.team_data.team_info.team_name          = data.team_info.team_name;
                this.team_data.team_info.team_color         = data.team_info.team_color;
                this.team_data.team_info.image              = data.team_info.image;
                this.team_data.team_info.description        = data.team_info.description;
                this.team_data.team_policy.is_appr_required = data.team_policy.is_appr_required;

                // 호두 H 일때는 주차 가능 수를 받아야 한다
                if( vue.isHoduH(vue.scope_group_team_option.biz_type ? vue.scope_group_team_option.biz_type : '') ) {
                    this.team_data.team_info.max_parking_number = data.team_info.max_parking_number;
                }

                if ( this.team_data.team_info.team_color.length == 9 ) {
                    this.team_data.team_info.team_color = "#" + this.team_data.team_info.team_color.substring(3, this.team_data.team_info.team_color.length);
                }

                if ( this.team_data.team_info.image == "" ) {
                    this.noTeamImage();
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 승인 변경
     */
    setApproveChange(any) : void {
        this.team_data.team_policy.is_appr_required = any.target.checked;
    }

    /**
     * 팀 삭제
     */
    async teamDelete() : Promise<void> {
        this.hodu_show_dialog("alert", "팀을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.teamDeleteService();
            },
        ]);
    }    

    async teamDeleteService() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/teams/updateTeamDelete/${this.team_id}/${this.group_id}`, API_METHOD.DELETE, null)
            .then(async(response) => {
                await this.get_group_role_service();
                this.doTeamId(0);
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });        
    }

    /**
     * 팀 생성
     */
    async saveValidator() : Promise<void> {
        if ( this.team_data.team_info.team_name.length < 2 || this.team_data.team_info.team_name.length > 20 ) {
            this.hodu_show_dialog("cancel", "팀명은 최소 2글자 부터 최대 20글자 입니다.", ['확인']);
            return;
        }

        // 팀 소개글 제한 없음
        // if ( this.team_data.team_info.description.length > 20 ) {
        //     this.hodu_show_dialog("cancel", "팀 소개글은 최대 20글자 입니다.", ['확인']);
        //     return;
        // }

        this.hodu_show_dialog("alert", "팀 " + (this.team_id == 0 ? "생성" : "수정") + " 하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.teamFileSave();
            },
        ]);
    }

    /**
     * 파일 업로드
     */
    async teamFileSave() : Promise<void> {
        const vue = this;

        if ( this.base64url && this.image_files.length > 0 ) {
            const form_data : FormData = new FormData();

            // FormData 내용 생성
            const image_files_count : number = this.image_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.image_files[i].file);
            }

            await this.hodu_temp_upload(form_data)
                .then(async(response) => {
                    //const temp_files : t_event_file[] = response.data.data.temp_files;
                    this.team_id == 0 ? await this.teamCreateSave(response.data.data.temp_files) : await this.teamModifySave(response.data.data.temp_files);
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });      
        }
        else {
            this.team_id == 0 ? await this.teamCreateSave([]) : await this.teamModifySave([]);
        }
    }

    /**
     * 팀 생성
     */
    async teamCreateSave(po_files : t_event_file[]) : Promise<void> {
        const vue = this;

        this.team_data.group_id              = this.group_id;
        this.team_data.biz_id                = this.biz_id;
        this.team_data.team_type             = "";
        this.team_data.team_policy.is_public = true;

        // HODU H는 biz_id를 같이 보내준다
        if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
            this.team_data.team_info.hodu_h_biz_id = this.scope_group_team_option.biz_id;
        }

        let param_data = {team: this.team_data}

        console.log(JSON.stringify(this.team_data))

        if ( null != po_files && po_files.length > 0 ) {
            param_data["team_file"] = po_files[0];
        }

        await this.hodu_api_call(`api/v1/teams`, API_METHOD.POST, param_data)
            .then(async(response) => {
                await this.get_group_role_service();
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 팀 수정
     */
    async teamModifySave(po_files : t_event_file[]) : Promise<void> {
        const vue = this;

        let param_data = {team: this.team_data}

        if ( null != po_files && po_files.length > 0 ) {
            param_data["team_file"] = po_files[0];
        }

        await this.hodu_api_call(`api/v1/teams/${this.team_id}/${this.group_id}`, API_METHOD.PUT, param_data)
            .then(async(response) => {

                if( this.team_id == this.scope_team_id ) {

                    await this.get_group_role_service();
                    const groups = this.all_group_data.filter(team => team.group_id == this.scope_group_id);
                    if( groups.length > 0 ) {

                        const group = groups[0];

                        const teams = group.teams.filter(team => team.team_id == this.scope_team_id);

                        if( teams.length > 0 ) {

                            const team = teams[0];

                            this.doSetScopeGroupTeamOption({
                                group_team_name : team.team_name,
                                group_team_color : team.color,
                                group_team_image : `${this.make_team_profile_image_url(this.scope_team_id)}?t=${new Date().getTime()}`,
                                group_team_descript : this.team_data.team_info.description,
                                biz_id : team.biz_id,
                                biz_type : team.biz_type
                            });
                        }
                    }
                }

                await vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 색상선택
     */
    colorChange(color) : void {
        this.color_pick = false;
        this.team_data.team_info.team_color = color;
        this.noTeamImage();
    }

    /**
     * 색상 선택 boolean
     */
    colorPick() : void {
        this.color_pick = !this.color_pick;
    }

    /**
     * 이미지 삭제
     */
    imageDelete() : void {
        if ( this.base64url ) {
            this.base64url = "";
            this.image_files = [];
        }

        if ( this.team_data.team_info.image ) {
            this.team_data.team_info.image = "";
        }

        this.noTeamImage();
    }

    /**
     * 이미지 변경 (업로드 안되어있을때)
    */
    noTeamImage() : void {
        this.team_no_image = require("@/assets/images/contents/img"+this.group_color_to_class_name(this.team_data.team_info.team_color)+".png");
    }

    /**
     * 이미지 드래그해서 이미지 영역에 올려놨을때
     */
    imageDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.image_drag = true;

    }

    /**
     * 이미지 드래그해서 이미지 영역에서 벗어났을때
     */
    imageDragLeave() : void {
        this.image_drag = false;
    }

    /**
     * 이미지를 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    imageDrop(event) : void {
        this.image_drag = false;
        this.addImage(event.dataTransfer.files);
    }

    /**
     * 팀 이미지 추가
     */
    addImageNormal(event) : void {
        const file : File[] = event.target.files;
        this.addImage(file);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(file : File[]) : Promise<void> {
        const vue = this;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(file[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#cdImg').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                if( this.doSetImageCropModalInfo == null ) { return; }

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (4/3), // 4:3
                    image_url : url,
                    cancel_function : async() => {
                        $('#cdImg').val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        
                        this.base64url = cropped_url;

                        const resize_blob : Blob = await vue.hodu_image_resize(this.base64url);

                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = file[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, file[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = file[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        // 업로드용 파일 객체 담기
                        vue.image_files = [];
                        vue.image_files.push({
                            file : resize_file
                        });

                        $('#cdImg').val("");

                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }
}
