

/**
 * Interface
 */
export namespace organization_interface {

}

/**
 * Modal Store Interface
 */
export namespace organization_modal_interface {

    /**
     * 조직도 부서 관리 모달
     */
    export interface OrganizationModalInfo {
        show_modal : boolean;
        is_select_only ?: boolean;
        callback ?: Function;
    }

    /**
     * 조직도 부서 관리 모달2
     */
    export interface OrganizationModal2Info {
        show_modal : boolean;
        dept_id : number;
        callback ?: Function;
    }

    /**
     * 조직도 휴가 타입 추가 모달
     */
    export interface OrganizationVacationTypeModalInfo {
        show_modal : boolean;
        is_create : boolean;
        vacation_type_id ?: number;
        title ?: string;
        day ?: number;
        pay ?: boolean;
        callback ?: Function;
    }

    /**
     * 조직도 근무 타입 상세 모달
     */
    export interface OrganizationWorkTypeDetailModalInfo {
        show_modal : boolean;
        work_type_id : number;
        callback ?: Function;
    }

    /**
     * 조직도 근무 타입 추가 모달
     */
    export interface OrganizationWorkTypeAddModalInfo {
        show_modal : boolean;
        is_create : boolean;
        work_type_id : number;
        callback ?: Function;
    }

    /**
     * 조직도 연차 설정 모달
     */
    export interface OrganizationOffModalInfo {
        show_modal : boolean;
        // is_create : boolean;
        user_id : number;
        // user_ids : number[];
        // is_only_setting_term : boolean;
        callback ?: Function;
    }

    /**
     * 조직도 지정휴무일 추가 모달
     */
    export interface OrganizationOffAddModalInfo {
        show_modal : boolean;
        is_create : boolean;
        off_id : number;
        callback ?: Function;
    }

    /**
     * 휴가 현황 - 휴가 추가 모달
     */
    export interface OrganizationVacationAddModlInfo {
        show_modal : boolean;
        callback ?: Function;
    }

    /**
     * 휴가 현황 - 휴가 상세 모달
     */
    export interface OrganizationVacationDetailModalInfo {
        show_modal : boolean;
        approval_uid : string;
        callback ?: Function;
    }

    /**
     * 휴가 현황 - 내 휴가 상세 모달
     */
    export interface OrganizationMyVacationDetailModalInfo {
        show_modal : boolean;
        approval_uid : string;
        callback ?: Function;
    }

    /**
     * 직원 결재 설정 사인 이미지 추가/수정 모달
     */
    export interface OrganizationSignModalInfo {
        show_modal : boolean;
        group_id ?: number;
        user_id ?: number;
        callback ?: Function;
    }

    export interface OrganizationEmpPersonalRecordDetailModalInfo {
        show_modal : boolean;
        group_id : number;
        user_id : number;
    }

    export interface OrganizationEmpPersonalRecordImportModalInfo {
        show_modal : boolean;
        callback ?: Function;
    }

    export interface OrganizationEmpPersonalRecordPrintModalInfo {
        show_modal : boolean;
        group_id : number;
        user_id : number;
        is_quitter : boolean;
    }

    export interface OrganizationDefaultEmpOffModalInfo {
        show_modal : boolean;
        group_id : number;
        callback ?: Function;
    }

}

/**
 * Enum
 */
export namespace organization_enum {

    /**
     * 직원 관리 정렬 타입
     */
    export enum ORGANIZATION_EMPLOYEE_SORT_TYPE {
        NAME         = "NAME",         // 이름
        DEPARTMENT   = "DEPARTMENT",   // 부서
        POSITION     = "POSITION",     // 직급
        APPROVER     = "APPROVER",     // 결재자
        EMAIL        = "EMAIL",        // 이메일
        ANNUAL_DATE  = "ANNUAL_DATE",  // 연차기간
        ANNUAL_COUNT = "ANNUAL_COUNT", // 연차수
        ENTERED_DATE = "ENTERED_DATE", // 입사일
        RESIGN_DATE  = "RESIGN_DATE",  // 퇴사일  
    }

    /**
     * 직원 관리 탭
     */
    export enum ORGANIZATION_SETTING_TAB {
        RANK = "RANK",
        QUITTER = "QUITTER",
        APPROVAL = "APPROVAL",
        EMP_WORK_TYPE = "EMP_WORK_TYPE",
        EMP_OFF = "EMP_OFF",
        ENTERED_DATE = "ENTERED_DATE",
        DEPARTMENT = "DEPARTMENT",
        POSITION = "POSITION",
        WORK_TYPE = "WORK_TYPE",
        VACATION_TYPE = "VACATION_TYPE",
        HOLIDAY = "HOLIDAY",
        OFF = "OFF", 
    }

    /**
     * 휴가 현황 탭
     */
    export enum ORGANIZATION_VACATION_TAB {
        ADMIN = "ADMIN",
        USER = "USER"
    }

    /**
     * 휴가 현황 정렬 타입
     */
    export enum ORGANIZATION_VACATION_SORT_TYPE {
        REQUEST = "REQUEST", // 요청일
        VACATION = "VACATION", // 휴가날짜
        DAYCOUNT = "DAYCOUNT" // 휴가 수
    }

    /**
     * 법정휴무일 정렬 타입
     */
    export enum ORGANIZATION_HOLIDAY_SORT_TYPE {
        DATE = "DATE",
        NAME = "NAME",
    }

    /**
     * 지정휴무일 정렬 타입
     */
    export enum ORGANIZATION_OFF_SORT_TYPE {
        DATE = "DATE",
        COUNT = "COUNT",
    }

}