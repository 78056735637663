import {GetterTree, MutationTree , ActionTree} from "vuex";

export const namespaced = true;

import moment from "moment";
import { CALENDAR_TYPE } from '@/mixin/VueHoduCommon';

export interface CalendarInfoState {
    calendar_id             : string;
    scope                   : string;
    scope_group_id          : number;
    scope_team_id           : number;
    scope_group_team_option : any;

    start_date              : string; // 마지막으로 보고 있던 달력의 월 시작 날짜
    calendar_view_type      : string; // 달력 타입 ( 월 : dayGridMonth, 주 : timeGridWeek, 일 : timeGridDay )
    selected_date           : Date;   // 선택된 날짜 (NavLinksDay or dateClick)

    // 일정 검색 설정
    schedule_search_config  : ScheduleSearchConfig;

    // 일정 시간 표시 타입
    schedule_time_type      : string; // NONE, START, END

    calendar_height         : number;
    event_limit             : number;

    // LeftDateClick 클릭 반응용
    go_to_date              : Date;

    is_event_filter_search  : boolean;
    event_search_query      : string;
    event_search_option     : EventSearchOption;
}

export interface EventSearchOption {
    search_event_type  : string; // ALL, PERSONAL, GROUP, TEAM, CARD, WORK
    start_date        ?: Date;
    end_date          ?: Date;
    event_title       ?: string;
    event_color        : string[];
    event_owner_name  ?: string;
    location_name     ?: string;
    has_photo          : boolean;
    has_file           : boolean;
    has_chat           : boolean;
    has_attend         : boolean;
    has_vote           : boolean;
    is_work_wait       : boolean;  
    is_work_start      : boolean;  
    is_work_end        : boolean;  
}

export const state: CalendarInfoState = {
    calendar_id    : "",
    scope          : "",
    scope_group_id : 0,
    scope_team_id  : 0,
    scope_group_team_option : {
        group_team_name : "",
        group_team_color : "#FFFFFF",
        group_team_image : "",
        group_team_descript : "",
        biz_id : ""
    },

    start_date : moment(new Date()).format("YYYY-MM-DD").toString(),
    calendar_view_type : CALENDAR_TYPE.DAY_GRID_MONTH,
    selected_date : new Date(),

    schedule_search_config : {
        past_schedule            : true,
        personal_schedule        : true,
        my_schedule              : true,
        shared_schedule          : true,
        group_schedule           : true,
        hodu_c_schedule          : true,
        hodu_d_schedule          : true,
        group_appointment        : true,
        system_calendar          : true,
        group_filter             : [],
        team_filter              : [],
        group_and_team_filter    : [],
        hodu_d_filter            : [],
        group_appointment_filter : [],  
        update_tag               : 0
    },

    schedule_time_type : "NONE",

    calendar_height : 0,
    event_limit : 6,
    go_to_date : new Date(),
    is_event_filter_search : false,
    event_search_query : "",
    event_search_option : {
        search_event_type : "ALL",
        event_color : [],
        has_photo : false,
        has_file : false,
        has_chat : false,
        has_attend : false,
        has_vote : false,
        is_work_wait : false,
        is_work_start : false,
        is_work_end : false
    },
};

export interface ScheduleSearchConfig {
    past_schedule            : boolean;
    personal_schedule        : boolean;
    my_schedule              : boolean;
    shared_schedule          : boolean;
    group_schedule           : boolean;
    hodu_c_schedule          : boolean;
    hodu_d_schedule          : boolean;
    group_appointment        : boolean;
    system_calendar          : boolean; 
    group_filter             : number[];
    team_filter              : number[];
    group_and_team_filter    : GroupAndTeamFilter[];
    hodu_d_filter            : string[];
    group_appointment_filter : string[];
    update_tag               : number;
}

export interface GroupAndTeamFilter {
    biz_id   : string,
    scope    : string,
    scope_id : number,
    group_id : number | null,
    team_ids : number[]
}

// getters
export const getters: GetterTree<CalendarInfoState, any> = {

    getCalendarId : (state) => {
        return state.calendar_id;
    },

    getScope : (state) => {
        return state.scope;
    },

    getScopeGroupId : (state) => {
        return state.scope_group_id;
    },

    getScopeTeamId : (state) => {
        return state.scope_team_id;
    },

    getScopeGroupTeamOption : (state) => {
        return state.scope_group_team_option;
    },

    getStartDate : (state) => {
        return state.start_date;
    },

    getCalendarViewType : (state) => {
        return state.calendar_view_type;
    },

    getSelectedDate : (state) => {
        return state.selected_date;
    },

    getScheduleSearchConfig : (state) => {
        return state.schedule_search_config;
    },

    getScheduleTimeType : (state) => {
        return state.schedule_time_type;
    },

    getCalendarHeight : (state) => {
        return state.calendar_height;
    },

    getEventLimit : (state) => {
        return state.event_limit;
    },

    getGoToDate : (state) => {
        return state.go_to_date;
    },

    isEventFilterSearch : (state) => {
        return state.is_event_filter_search
    },

    getEventSearchQuery : (state) => {
        return state.event_search_query;
    },

    getEventSearchOption : (state) => {
        return state.event_search_option;
    }
};

// action
export const actions: ActionTree<CalendarInfoState, CalendarInfoState> = {
    doSetCalendarInfo: ({commit, state}, payload : CalendarInfoState) => {
        console.log(`mutations.doSetCalendarInfo ${JSON.stringify(payload)}`);
        
        state.calendar_id        = payload.calendar_id        != null ? payload.calendar_id        : state.calendar_id;
        state.scope              = payload.scope              != null ? payload.scope              : state.scope;
        state.scope_group_id     = payload.scope_group_id     != null ? payload.scope_group_id     : state.scope_group_id;
        state.scope_team_id      = payload.scope_team_id      != null ? payload.scope_team_id      : state.scope_team_id;

        state.scope_group_team_option.group_team_name     = payload.scope_group_team_option.group_team_name     != null ? payload.scope_group_team_option.group_team_name
                                                                                                                        : state.scope_group_team_option.group_team_name;

        state.scope_group_team_option.group_team_color    = payload.scope_group_team_option.group_team_color    != null ? payload.scope_group_team_option.group_team_color
                                                                                                                        : state.scope_group_team_option.group_team_color;

        state.scope_group_team_option.group_team_image    = payload.scope_group_team_option.group_team_image    != null ? payload.scope_group_team_option.group_team_image
                                                                                                                        : state.scope_group_team_option.group_team_image;

        state.scope_group_team_option.group_team_descript = payload.scope_group_team_option.group_team_descript != null ? payload.scope_group_team_option.group_team_descript
                                                                                                                        : state.scope_group_team_option.group_team_descript;

        state.scope_group_team_option.biz_id              = payload.scope_group_team_option.biz_id              != null ? payload.scope_group_team_option.biz_id
                                                                                                                        : state.scope_group_team_option.biz_id;

        state.scope_group_team_option.biz_type            = payload.scope_group_team_option.biz_type            != null ? payload.scope_group_team_option.biz_type
                                                                                                                        : state.scope_group_team_option.biz_type;

        state.scope_group_team_option.is_domain_service   = payload.scope_group_team_option.is_domain_service   != null ? payload.scope_group_team_option.is_domain_service
                                                                                                                        : state.scope_group_team_option.is_domain_service;                                                                                                                    

        state.start_date         = payload.start_date         != null ? payload.start_date         : state.start_date;
        state.calendar_view_type = payload.calendar_view_type != null ? payload.calendar_view_type : state.calendar_view_type;
        state.selected_date      = payload.selected_date      != null ? payload.selected_date      : state.selected_date;
        state.calendar_height    = payload.calendar_height    != null ? payload.calendar_height    : state.calendar_height;
        state.event_limit        = payload.event_limit        != null ? payload.event_limit        : state.event_limit;

        // schedule_search_config
        state.schedule_search_config.past_schedule     = payload.schedule_search_config.past_schedule != null
                                                         ? payload.schedule_search_config.past_schedule
                                                         : state.schedule_search_config.past_schedule;

        state.schedule_search_config.personal_schedule = payload.schedule_search_config.personal_schedule != null
                                                         ? payload.schedule_search_config.personal_schedule
                                                         : state.schedule_search_config.personal_schedule;

        state.schedule_search_config.my_schedule       = payload.schedule_search_config.my_schedule != null
                                                         ? payload.schedule_search_config.my_schedule
                                                         : state.schedule_search_config.my_schedule;

        state.schedule_search_config.shared_schedule   = payload.schedule_search_config.shared_schedule != null
                                                         ? payload.schedule_search_config.shared_schedule
                                                         : state.schedule_search_config.shared_schedule;

        state.schedule_search_config.group_schedule    = payload.schedule_search_config.group_schedule != null
                                                         ? payload.schedule_search_config.group_schedule
                                                         : state.schedule_search_config.group_schedule;

        state.schedule_search_config.hodu_c_schedule   = payload.schedule_search_config.hodu_c_schedule != null
                                                         ? payload.schedule_search_config.hodu_c_schedule
                                                         : state.schedule_search_config.hodu_c_schedule;

        state.schedule_search_config.hodu_d_schedule   = payload.schedule_search_config.hodu_d_schedule != null
                                                         ? payload.schedule_search_config.hodu_d_schedule
                                                         : state.schedule_search_config.hodu_d_schedule;

        state.schedule_search_config.group_appointment = payload.schedule_search_config.group_appointment != null
                                                         ? payload.schedule_search_config.group_appointment
                                                         : state.schedule_search_config.group_appointment;

        state.schedule_search_config.system_calendar   = payload.schedule_search_config.system_calendar != null
                                                         ? payload.schedule_search_config.system_calendar
                                                         : state.schedule_search_config.system_calendar;

        // GroupFilter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.group_filter.splice(0, state.schedule_search_config.group_filter.length);
        if( payload.schedule_search_config.group_filter != null ){
            const payload_size : number = payload.schedule_search_config.group_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.group_filter.push(payload.schedule_search_config.group_filter[i]);
            }
        }

        // TeamFilter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.team_filter.splice(0, state.schedule_search_config.team_filter.length);
        if( payload.schedule_search_config.team_filter != null ){
            const payload_size : number = payload.schedule_search_config.team_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.team_filter.push(payload.schedule_search_config.team_filter[i]);
            }
        }

        // group_and_team_filter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.group_and_team_filter.splice(0, state.schedule_search_config.group_and_team_filter.length);
        if( payload.schedule_search_config.group_and_team_filter != null ) {
            const payload_size : number = payload.schedule_search_config.group_and_team_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.group_and_team_filter.push(payload.schedule_search_config.group_and_team_filter[i]);
            }
        }

        // hodu_d_filter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.hodu_d_filter.splice(0, state.schedule_search_config.hodu_d_filter.length);
        if( payload.schedule_search_config.hodu_d_filter != null ) {
            const payload_size : number = payload.schedule_search_config.hodu_d_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.hodu_d_filter.push(payload.schedule_search_config.hodu_d_filter[i]);
            }
        }

        // group_appointment_filter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.group_appointment_filter.splice(0, state.schedule_search_config.group_appointment_filter.length);
        if( payload.schedule_search_config.group_appointment_filter != null ) {
            const payload_size : number = payload.schedule_search_config.group_appointment_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.group_appointment_filter.push(payload.schedule_search_config.group_appointment_filter[i]);
            }
        }

        state.schedule_search_config.update_tag = payload.schedule_search_config.update_tag != null
                                                  ? payload.schedule_search_config.update_tag
                                                  : state.schedule_search_config.update_tag;

        state.schedule_time_type = payload.schedule_time_type;

        state.is_event_filter_search = payload.is_event_filter_search;
        
        state.event_search_query = payload.event_search_query;

        state.event_search_option.search_event_type = payload.event_search_option.search_event_type;
        state.event_search_option.start_date        = payload.event_search_option.start_date;
        state.event_search_option.end_date          = payload.event_search_option.end_date;
        state.event_search_option.event_title       = payload.event_search_option.event_title;
        state.event_search_option.event_color.splice(0, state.event_search_option.event_color.length);
        const color_length : number = payload.event_search_option.event_color.length;
        for( let i = 0; i < color_length; i++ ) {
            state.event_search_option.event_color.push(payload.event_search_option.event_color[i]);
        }
        state.event_search_option.event_owner_name  = payload.event_search_option.event_owner_name;
        state.event_search_option.location_name     = payload.event_search_option.location_name;
        state.event_search_option.has_photo         = payload.event_search_option.has_photo;
        state.event_search_option.has_file          = payload.event_search_option.has_file;
        state.event_search_option.has_chat          = payload.event_search_option.has_chat;
        state.event_search_option.has_attend        = payload.event_search_option.has_attend;
        state.event_search_option.has_vote          = payload.event_search_option.has_vote;
        state.event_search_option.is_work_wait      = payload.event_search_option.is_work_wait;
        state.event_search_option.is_work_start     = payload.event_search_option.is_work_start;
        state.event_search_option.is_work_end       = payload.event_search_option.is_work_end;
    },
    
    doSetScheduleSearchConfig: ({commit, state}, payload : ScheduleSearchConfig) => {
        console.log(`actions.doSetScheduleSearchConfig ${payload}`);

        // schedule_search_config
        state.schedule_search_config.past_schedule     = payload.past_schedule != null
                                                         ? payload.past_schedule
                                                         : state.schedule_search_config.past_schedule;

        state.schedule_search_config.personal_schedule = payload.personal_schedule != null
                                                         ? payload.personal_schedule
                                                         : state.schedule_search_config.personal_schedule;

        state.schedule_search_config.my_schedule       = payload.my_schedule != null
                                                         ? payload.my_schedule
                                                         : state.schedule_search_config.my_schedule;

        state.schedule_search_config.shared_schedule   = payload.shared_schedule != null
                                                         ? payload.shared_schedule
                                                         : state.schedule_search_config.shared_schedule;

        state.schedule_search_config.group_schedule    = payload.group_schedule != null
                                                         ? payload.group_schedule
                                                         : state.schedule_search_config.group_schedule;

        state.schedule_search_config.hodu_c_schedule   = payload.hodu_c_schedule != null
                                                         ? payload.hodu_c_schedule
                                                         : state.schedule_search_config.hodu_c_schedule;

        state.schedule_search_config.hodu_d_schedule   = payload.hodu_d_schedule != null
                                                         ? payload.hodu_d_schedule
                                                         : state.schedule_search_config.hodu_d_schedule;

        state.schedule_search_config.group_appointment = payload.group_appointment != null
                                                         ? payload.group_appointment
                                                         : state.schedule_search_config.group_appointment;

        state.schedule_search_config.system_calendar   = payload.system_calendar != null
                                                         ? payload.system_calendar
                                                         : state.schedule_search_config.system_calendar;

        // GroupFilter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.group_filter.splice(0, state.schedule_search_config.group_filter.length);
        if( payload.group_filter != null ){
            const payload_size : number = payload.group_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.group_filter.push(payload.group_filter[i]);
            }
        }

        // TeamFilter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.team_filter.splice(0, state.schedule_search_config.team_filter.length);
        if( payload.team_filter != null ){
            const payload_size : number = payload.team_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.team_filter.push(payload.team_filter[i]);
            }
        }

        // group_and_team_filter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.group_and_team_filter.splice(0, state.schedule_search_config.group_and_team_filter.length);
        if( payload.group_and_team_filter != null ) {
            const payload_size : number = payload.group_and_team_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.group_and_team_filter.push(payload.group_and_team_filter[i]);
            }
        }

        // hodu_d_filter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.hodu_d_filter.splice(0, state.schedule_search_config.hodu_d_filter.length);
        if( payload.hodu_d_filter != null ) {
            const payload_size : number = payload.hodu_d_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.hodu_d_filter.push(payload.hodu_d_filter[i]);
            }
        }

        // group_appointment_filter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.group_appointment_filter.splice(0, state.schedule_search_config.group_appointment_filter.length);
        if( payload.group_appointment_filter != null ) {
            const payload_size : number = payload.group_appointment_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.group_appointment_filter.push(payload.group_appointment_filter[i]);
            }
        }

        state.schedule_search_config.update_tag = payload.update_tag != null
                                                  ? payload.update_tag
                                                  : state.schedule_search_config.update_tag;

        // commit("setScheduleSearchConfig", schedule_search_config);
    },

    doSetScheduleTimeType: ({commit, state}, schedule_time_type : string) => {
        console.log(`actions.doSetScheduleTimeType ${schedule_time_type}`);
        state.schedule_time_type = schedule_time_type;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    doSelectScheduleList: ({commit, state}, schedule_search_config : ScheduleSearchConfig) => {
        console.log("actions.doSelectScheduleList");
        commit("selectScheduleList", schedule_search_config);
    },

    doPickingLeftDatePicker: ({commit, state}, picking_date : Date) => {
        console.log("actions.doPickingLeftDatePicker");
        commit("pickingLeftDatePicker", picking_date);
    },

    doSetCalendarId : ({commit, state}, calendar_id : string) => {
        console.log("actions.doSetCalendarId");
        commit("setCalendarId", calendar_id);
    },

    doSetScope : ({commit, state}, scope : string) => {
        console.log("actions.doSetScope");
        commit("setScope", scope);
    },

    doSetScopeGroupId : ({commit, state}, scope_group_id : number) => {
        console.log("actions.doSetScopeGroupId");
        commit("setScopeGroupId", scope_group_id);
    },

    doSetScopeTeamId : ({commit, state}, scope_team_id : number) => {
        console.log("actions.doSetScopeTeamId");
        commit("setScopeTeamId", scope_team_id);
    },

    doSetScopeGroupTeamOption : ({commit, state}, scope_group_team_option : any) => {
        console.log("actions.doSetScopeGroupTeamOption");
        commit("setScopeGroupTeamOption", scope_group_team_option);
    },

    doSetStartDate: ({commit, state}, start_date : string) => {
        console.log("actions.doSetStartDate");
        commit("setStartDate", start_date);
    },

    doSetCalendarViewType: ({commit, state}, calendar_view_type : string) => {
        console.log("actions.doSetCalendarViewType");
        commit("setCalendarViewType", calendar_view_type);
    },

    doSetSelectedDate: ({commit, state}, seleceted_date : Date) => {
        console.log("actions.doSetSelectedDate");
        commit("setSelectedDate", seleceted_date);
    },

    doSetCalendarHeight: ({commit, state}, calendar_height : number) => {
        console.log("actions.doSetCalendarHeight");
        commit("setCalendarHeight", calendar_height);
    },

    doSetEventLimit: ({commit, state}, event_limit : number) => {
        console.log("actions.doSetEventLimit");
        commit("setEventLimit", event_limit);
    },

    doSetIsEventFilterSearch: ({commit, state}, is_event_filter_search : boolean) => {
        console.log("actions.doSetIsEventFilterSearch");
        commit("setIsEventFilterSearch", is_event_filter_search);
    },

    doSetEventSearchQuery: ({commit, state}, event_search_query : string) => {
        console.log("actions.doSetEventSearchQuery");
        commit("setEventSearchQuery", event_search_query);
    },

    doSetEventSearchOption: ({commit, state}, event_search_option : EventSearchOption) => {
        console.log("actions.doSetEventSearchOption");
        commit("setEventSearchOption", event_search_option);
    },

};

// mutation
export const mutations: MutationTree<CalendarInfoState> = {
    
    setScheduleSearchConfig: (state, payload : ScheduleSearchConfig) => {
        console.log(`mutations.setScheduleSearchConfig ${JSON.stringify(payload)}`);

        // schedule_search_config
        state.schedule_search_config.past_schedule     = payload.past_schedule != null
                                                         ? payload.past_schedule
                                                         : state.schedule_search_config.past_schedule;

        state.schedule_search_config.personal_schedule = payload.personal_schedule != null
                                                         ? payload.personal_schedule
                                                         : state.schedule_search_config.personal_schedule;

        state.schedule_search_config.my_schedule       = payload.my_schedule != null
                                                         ? payload.my_schedule
                                                         : state.schedule_search_config.my_schedule;

        state.schedule_search_config.shared_schedule   = payload.shared_schedule != null
                                                         ? payload.shared_schedule
                                                         : state.schedule_search_config.shared_schedule;

        state.schedule_search_config.group_schedule    = payload.group_schedule != null
                                                         ? payload.group_schedule
                                                         : state.schedule_search_config.group_schedule;

        state.schedule_search_config.hodu_c_schedule   = payload.hodu_c_schedule != null
                                                         ? payload.hodu_c_schedule
                                                         : state.schedule_search_config.hodu_c_schedule;

        state.schedule_search_config.hodu_d_schedule   = payload.hodu_d_schedule != null
                                                         ? payload.hodu_d_schedule
                                                         : state.schedule_search_config.hodu_d_schedule;

        state.schedule_search_config.group_appointment = payload.group_appointment != null
                                                         ? payload.group_appointment
                                                         : state.schedule_search_config.group_appointment;

        state.schedule_search_config.system_calendar   = payload.system_calendar != null
                                                         ? payload.system_calendar
                                                         : state.schedule_search_config.system_calendar;

        // GroupFilter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.group_filter.splice(0, state.schedule_search_config.group_filter.length);
        if( payload.group_filter != null ){
            const payload_size : number = payload.group_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.group_filter.push(payload.group_filter[i]);
            }
        }

        // TeamFilter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.team_filter.splice(0, state.schedule_search_config.team_filter.length);
        if( payload.team_filter != null ){
            const payload_size : number = payload.team_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.team_filter.push(payload.team_filter[i]);
            }
        }

        // group_and_team_filter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.group_and_team_filter.splice(0, state.schedule_search_config.group_and_team_filter.length);
        if( payload.group_and_team_filter != null ) {
            const payload_size : number = payload.group_and_team_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.group_and_team_filter.push(payload.group_and_team_filter[i]);
            }
        }

        // hodu_d_filter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.hodu_d_filter.splice(0, state.schedule_search_config.hodu_d_filter.length);
        if( payload.hodu_d_filter != null ) {
            const payload_size : number = payload.hodu_d_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.hodu_d_filter.push(payload.hodu_d_filter[i]);
            }
        }

        // group_appointment_filter 원본 배열을 수정해야하므로 대입이 아니라 splice & push 사용
        state.schedule_search_config.group_appointment_filter.splice(0, state.schedule_search_config.group_appointment_filter.length);
        if( payload.group_appointment_filter != null ) {
            const payload_size : number = payload.group_appointment_filter.length;
            for( let i = 0; i < payload_size; i++ ){
                state.schedule_search_config.group_appointment_filter.push(payload.group_appointment_filter[i]);
            }
        }

        state.schedule_search_config.update_tag = payload.update_tag != null
                                                  ? payload.update_tag
                                                  : state.schedule_search_config.update_tag;
    },

    selectScheduleList: (state, payload : CalendarInfoState) => {
        console.log(`mutations.selectScheduleList ${JSON.stringify(payload)}`);

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    pickingLeftDatePicker: (state, payload : Date) => {
        console.log(`mutations.pickingLeftDatePicker ${JSON.stringify(payload)}`);
        state.go_to_date = payload;
    },

    setCalendarId : (state, payload : string) => {
        console.log(`mutations.setCalendarId ${JSON.stringify(payload)}`);
        state.calendar_id = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setScope : (state, payload : string) => {
        console.log(`mutations.setScope ${JSON.stringify(payload)}`);
        state.scope = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setScopeGroupId : (state, payload : number) => {
        console.log(`mutations.setScopeGroupId ${JSON.stringify(payload)}`);
        state.scope_group_id = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setScopeTeamId : (state, payload : number) => {
        console.log(`mutations.setScopeTeamId ${JSON.stringify(payload)}`);
        state.scope_team_id = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setScopeGroupTeamOption : (state, payload : any) => {
        console.log(`mutations.setScopeGroupTeamOption ${JSON.stringify(payload)}`);
        state.scope_group_team_option.group_team_name     = payload.group_team_name;
        state.scope_group_team_option.group_team_color    = payload.group_team_color;
        state.scope_group_team_option.group_team_image    = payload.group_team_image;
        state.scope_group_team_option.group_team_descript = payload.group_team_descript;
        state.scope_group_team_option.biz_id              = payload.biz_id;
        state.scope_group_team_option.biz_type            = payload.biz_type;
        state.scope_group_team_option.biz_type            = payload.biz_type;
        
        
        // 빈 값이 들어오는 경우 계속 유지되어야 하는 값
        if( payload.login_mode != null ) state.scope_group_team_option.login_mode = payload.login_mode;
        if( payload.is_domain_service != null ) state.scope_group_team_option.is_domain_service = payload.is_domain_service;  

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setStartDate: (state, payload : string) => {
        console.log(`mutations.setStartDate ${JSON.stringify(payload)}`);
        state.start_date = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setCalendarViewType: (state, payload : string) => {
        console.log(`mutations.setCalendarViewType ${JSON.stringify(payload)}`);
        state.calendar_view_type = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setSelectedDate: (state, payload : Date) => {
        console.log(`mutations.setSelectedDate ${JSON.stringify(payload)}`);
        state.selected_date = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setCalendarHeight: (state, payload : number) => {
        console.log(`mutations.setCalendarHeight ${JSON.stringify(payload)}`);
        state.calendar_height = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setEventLimit: (state, payload : number) => {
        console.log(`mutations.setEventLimit ${JSON.stringify(payload)}`);
        state.event_limit = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setIsEventFilterSearch: (state, payload : boolean) => {
        console.log(`mutations.setEventSearchQuery ${JSON.stringify(payload)}`);
        state.is_event_filter_search = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setEventSearchQuery: (state, payload : string) => {
        console.log(`mutations.setEventSearchQuery ${JSON.stringify(payload)}`);
        state.event_search_query = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    },

    setEventSearchOption: (state, payload : EventSearchOption ) => {
        state.event_search_option.search_event_type = payload.search_event_type;
        state.event_search_option.start_date        = payload.start_date;
        state.event_search_option.end_date          = payload.end_date;
        state.event_search_option.event_title       = payload.event_title;
        state.event_search_option.event_color.splice(0, state.event_search_option.event_color.length);
        const color_length : number = payload.event_color.length;
        for( let i = 0; i < color_length; i++ ) {
            state.event_search_option.event_color.push(payload.event_color[i]);
        }
        state.event_search_option.event_owner_name  = payload.event_owner_name;
        state.event_search_option.location_name     = payload.location_name;
        state.event_search_option.has_photo         = payload.has_photo;
        state.event_search_option.has_file          = payload.has_file;
        state.event_search_option.has_chat          = payload.has_chat;
        state.event_search_option.has_attend        = payload.has_attend;
        state.event_search_option.has_vote          = payload.has_vote;
        state.event_search_option.is_work_wait      = payload.is_work_wait;
        state.event_search_option.is_work_start     = payload.is_work_start;
        state.event_search_option.is_work_end       = payload.is_work_end;

        if( sessionStorage != null ) { sessionStorage.setItem("calendar_info", JSON.stringify(state)); }
    }

};