
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import SearchBar from '@/components/ui/SearchBar.vue';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

import { GroupBoardReadInfo } from '@/store/modules/ModalInfo';
import { GroupNoticeSearchInfo } from '@/store/modules/GroupInfo';

import { ResizeObserver } from 'vue-resize';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

@Component({
    components: {
        SearchBar, ResizeObserver
    },
})
export default class GroupBoard extends Mixins(VueHoduCommon) {
    
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_board_read_info !: GroupBoardReadInfo

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupBoardReadInfo ?: (params : GroupBoardReadInfo) => void;
    
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_notice_search_info !: GroupNoticeSearchInfo;

    /**
     * GroupInfo.Action
     */
    @GroupInfo.Action doSetGroupNoticeSearchInfo ?: (params : GroupNoticeSearchInfo) => void;
    
    posts : any[] = [];
    
    is_page_end = false;

    is_search_open : boolean = false;
    search_query : string = "";
    search_query_temp : string = "";
    
    async beforeMount() : Promise<void> {
        this.search_query = this.group_notice_search_info.search_query ? this.group_notice_search_info.search_query : "";
        if ( this.search_query.length > 0 ) {
            this.is_search_open = true;
            this.search_query_temp = this.search_query;
        }
    }

    mounted() : void {
        this.setScroll();
        this.getGroupBoards();
    }

    setScroll() {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        const scrollHeight      : number | undefined = window.innerHeight - ( titleHeight ? titleHeight : 0 ) - ( settingBgHeight ? settingBgHeight : 0 ) - ( sortHeaderHeight ? sortHeaderHeight : 0 );

        // @ts-ignore
        $('#group_board_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : scrollHeight,
            scrollbarPosition : 'outside',
            callbacks:{
                whileScrolling: this.whileScrolling
            }
        });
    }

    /**
     * 알림 스크롤 리스너 - 페이징 처리
     */
     @Throttle(500)
    async whileScrolling() : Promise<void> {
        
        // 페이징 끝났다면 빠져나감
        if( this.is_page_end == true ) {
            return;
        }

        try {
            const noti_ul_scroll_height : number | undefined = $('#group_board_scroll').height();
            const container_height      : number | undefined = $('#group_board_scroll .mCSB_container').height();
            let container_top : number = 0;
            let container_top_string : string = "";

            try {
                container_top_string = $('#group_board_scroll .mCSB_container').css('top')

                if(container_top_string) {
                    container_top_string = container_top_string.replace('px', '');
                    container_top = Math.abs(Number(container_top_string));
                } else {
                    container_top = 0;
                }
            } catch(e) {
                container_top = 0;
            }
        
            if( (container_height ? container_height : 0) - (noti_ul_scroll_height ? noti_ul_scroll_height : 0) - container_top <= 250 ) {
                this.getGroupBoards(this.posts[this.posts.length - 1].board_id);
            }

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }

    }
    
    async getGroupBoards(last_board_id ?: string | null | undefined) : Promise<void> {
        
        try {

            let query = "block_count=20";

            if( last_board_id != null && last_board_id.length > 0 ) {
                query += `&board_id=${last_board_id}`
            }

            if( this.scope_team_id > 0 ) {
                query += `&team_id=${this.scope_team_id}`;
            }

            if( this.search_query != null && this.search_query.length > 0 ) {
                query += `&search_query=${this.search_query}`;
            }
            
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/board?${query}`, API_METHOD.GET);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || ! response.data.data || !response.data.data.posts ) {
                throw new Error("그룹 게시판 조회 중 오류 발생");
            }
            
            if( last_board_id == null ) {
                this.posts.splice(0, this.posts.length);
            }
            this.posts = this.posts.concat(response.data.data.posts);

            this.is_page_end = response.data.data.is_page_end;
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "그룹 게시판 조회 중 오류 발생", ['확인']);
        }
        
    }

    /**
     * 그룹 게시글 읽음 현황 모달 보이기
     */
    showGroupBoardReadModal(board) : void {
        this.doSetGroupBoardReadInfo?.({
            show_modal : true,
            group_id : board.group_id,
            board_id : board.board_id
        });
    }

    /**
     * 검색 ON / OFF
     */
    searchOnOff(flag : boolean) : void {

        this.is_search_open = flag;

        if( flag == false ) {
            this.search_query = "";
            this.search_query_temp = "";

            const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
            search_info.search_query = "";
            this.doSetGroupNoticeSearchInfo?.(search_info);

            this.getGroupBoards();
        }
    }

    /**
     * 검색 keydown
     */
    searchKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }
        
        this.search();
    }

    /**
     * 검색
     */
    search() : void {
        this.search_query = this.search_query_temp.trim();

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.search_query = this.search_query;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        this.getGroupBoards();
    }

    moveCreate() : void {
        this.hodu_router_push(`/group/${new Date().getTime()}/board/create`);
    }
    
    moveDetail(board_id : string) : void {
        this.hodu_router_push(`/group/${new Date().getTime()}/board/${board_id}`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#group_board_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }
    
}
