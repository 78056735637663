
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { GROUP_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';

@Component({

})
export default class BizCreate extends Mixins(VueHoduCommon) {
    admin_user_id       : string  = "";
    admin_create_biz_id : string  = "";
    check_biz_id        : string  = "";
    b_biz_id_flag       : boolean = false;

    mounted() : void {

    }

    /*
    biz_check() : void {
        if ( this.admin_create_biz_id.trim().length < 2 ) {
            this.hodu_show_dialog("cancel", "사업장코드 를 입력해주세요 최소 2자이상", ['확인']);
            return;
        }

        this.hodu_api_call(`api/v1/admin/biz/biz_admin/getBizIdCheck?biz_id=${this.admin_create_biz_id}`, 'get', null)
            .then(async(response) => {
                if ( response.data.data.count > 0 ) {
                    this.check_biz_id  = "";
                    this.b_biz_id_flag = false;
                    this.hodu_show_dialog("cancel", "사용하실 수 없는 사업장코드 입니다 (중복)", ['확인']);
                    return;
                }
                else {
                    this.check_biz_id  = this.admin_create_biz_id;
                    this.b_biz_id_flag = true;
                    this.hodu_show_dialog("success", "사용가능 합니다", ['확인']);
                    return;
                }
            })
            .catch(async(e) => {
                if ( (e.response.status) && e.response.status == 403 ) {
                    this.movePrevPage();
                    return;
                }
            });        
    }
    */

    biz_create_save() : void {
        let is_cert = $('#is_cert').val();
        let ls_biz_nm = $("#biz_nm").val();

        /*
        if ( !this.b_biz_id_flag ) {
            this.hodu_show_dialog("cancel", "사업장코드 중복체크 검사를 해주세요", ['확인']);
            return;
        }

        if ( this.admin_create_biz_id.trim().length < 2 ) {
            this.hodu_show_dialog("cancel", "사업장코드 를 입력해주세요 최소 2자이상", ['확인']);
            return;
        }

        if ( this.check_biz_id != this.admin_create_biz_id ) {
            this.hodu_show_dialog("cancel", "사업장코드 중복체크 검사를 해주세요", ['확인']);
            return;
        }
        */

        if ( ls_biz_nm == "" ) {
            this.hodu_show_dialog("cancel", "회사명을 입력해주세요", ['확인']);
            return;
        }

        if ( !this.admin_user_id ) {
            this.hodu_show_dialog("cancel", "관리자 아이디를 입력해주세요", ['확인']);
            return;
        }

        var regexp = /^[0-9]*$/

        if ( !regexp.test(this.admin_user_id) ) {
            this.hodu_show_dialog("cancel", "관리자아이디는 숫자만 입력하실수 있습니다", ['확인']);
            return;
        }

        let insert_data = {
            //biz_id: this.admin_create_biz_id,
            biz_nm: ls_biz_nm,
            group_id: 0,
            group_type: GROUP_TYPE.BIZC,
            is_cert: is_cert,
            biz_master_id: this.admin_user_id
        }

        this.hodu_api_call(`api/v1/admin/biz/biz_admin`, API_METHOD.POST, insert_data)
            .then(async(response) => {
                this.admin_create_biz_id = "";
                this.check_biz_id        = "";
                this.b_biz_id_flag       = false;

                //$('#biz_id').val("");
                $('#biz_nm').val("");

                this.hodu_show_dialog("success", "등록되었습니다", ['확인']);
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            }); 
    }
}
