
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { hodu_doc_object } from '@/model/hodudoc';

import AppointmentAvailableListLeft from '@/components/hodu_d/AppointmentAvailableListLeft.vue';
import AppointmentAvailableListRight from '@/components/hodu_d/AppointmentAvailableListRight.vue';

import { ResizeObserver } from 'vue-resize';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        AppointmentAvailableListLeft, AppointmentAvailableListRight, ResizeObserver
    },
}) 
export default class AppointmentCreate extends Mixins(VueHoduCommon) {

    event_bus : Vue = new Vue();

    is_open_right_list : boolean = true;

    max_date            : Date = new Date();
    selected_date       : Date = new Date();
    selected_department : hodu_doc_object.department_info | null = null;
    selected_doctor     : hodu_doc_object.doctor_info | null = null;

    /**
     * 의사가 한 명이라도 존재하는 진료과만 반환
     */
    get computedDoctorExistDepartment() : hodu_doc_object.department_info[] {

        let doctor_exist_department : hodu_doc_object.department_info[] = [];

        if( this.department_info != null && this.doctor_info != null ) {

            for( const department of this.department_info ) {

                if( this.doctor_info.filter(item => item.department_code == department.department_code).length > 0 ) {
                    doctor_exist_department.push(department);
                }

            }

        }

        return doctor_exist_department;
    }

    /**
     * 선택된 진료과의 의사만 반환
     */
    get computedDepartmentDoctor() : hodu_doc_object.doctor_info[] {

        let department_doctor : hodu_doc_object.doctor_info[] = [];

        if( this.selected_department != null && this.doctor_info != null ) {
            const department_doctor_temp = this.doctor_info.filter(item => this.selected_department ? item.department_code == this.selected_department.department_code : false);
            department_doctor = department_doctor.concat(department_doctor_temp);
        }

        return department_doctor;
    }

    beforeMount() : void {
        // 1달 후의 토요일까지
        this.max_date = new Date(this.selected_date);
        this.max_date.setMonth(this.max_date.getMonth() + 1);
        this.max_date.setDate(this.max_date.getDate() + 6 - this.max_date.getDay());
    }

    async mounted() : Promise<void> {

        // 진료과 조회 && 의사 조회 && 병원 시간표 조회
        // console.log("Before call get_hodu_d_info");
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);
        // console.log("After call get_hodu_d_info");

        // 진료과가 없는 경우
        if( this.department_info == null || this.department_info.length < 1 || this.computedDoctorExistDepartment.length < 1 ) {
            this.hodu_show_dialog("cancel", "진료과를 등록해주세요", ["확인"], [() => {
                this.movePrevPage();
            }]);
            return;
        }
        
        // 가장 첫번째 진료과 선택 나중에 마지막에 선택한 진료과가 될 수 있음
        this.selected_department = this.computedDoctorExistDepartment[0];

        // 의사가 없는 경우
        if( this.doctor_info == null || this.doctor_info.length < 1 ) {
            this.hodu_show_dialog("cancel", "의사를 등록해주세요", ["확인"], [() => {
                this.movePrevPage();
            }]);
            return;
        }
        
        // 가장 첫번째 진료과 선택 나중에 마지막에 선택한 의사가 될 수 있음
        this.selected_doctor = this.computedDepartmentDoctor[0];

        const vue = this;
        
        const datepicker_option = {
            inline: false,
            minDate : new Date(),
            maxDate : this.max_date,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText);
                inst.input.val(mnt.format('YYYY.MM'));
                vue.selected_date = mnt.toDate();
            },
        };

        // @ts-ignore
        $('#available_fr_ymd').datepicker(datepicker_option);

        this.$nextTick(() => { this.event_bus.$emit('defaultDataInit'); });
    }

    /**
     * 오른쪽 리스트 닫기/열기
     */
    rightOnOff() : void {
        this.is_open_right_list = !this.is_open_right_list;
        this.event_bus.$emit('rerenderHooper');
    }

    /**
     * 진료과 변경
     */
    changeDepartment(value : string) : void {

        const department_list : hodu_doc_object.department_info[] = this.computedDoctorExistDepartment.filter(item => item.department_code == value);

        if( department_list.length < 1 ) { return; }

        this.selected_department = JSON.parse(JSON.stringify(department_list[0]))

        const doctor_list : hodu_doc_object.doctor_info[] = this.computedDepartmentDoctor.filter(item => this.selected_department ? item.department_code == this.selected_department.department_code : false);

        if( doctor_list.length < 1 ) { return; }

        this.selected_doctor = JSON.parse(JSON.stringify(doctor_list[0]));
    }

    /**
     * 선택된 진료과 여부
     */
    isSelectedDepartment(department_code : string) : boolean {
        if( !this.selected_department ) { return false; }

        return this.selected_department.department_code == department_code;
    }

    /**
     * 의사 변경
     */
    changeDoctor(value : string) : void {
        const doctor_list : hodu_doc_object.doctor_info[] = this.computedDepartmentDoctor.filter(item => item.doctor_code == value);

        if( doctor_list.length < 1 ) { return; }

        this.selected_doctor = JSON.parse(JSON.stringify(doctor_list[0]));
    }

    /**
     * 선택된 의사 여부
     */
    isSelectedDoctor(doctor_code : string) : boolean {
        if( !this.selected_doctor ) { return false; }

        return doctor_code == this.selected_doctor.doctor_code;
    }

    /**
     * 날짜 선택
     */
    selectDate(date : Date) : void {
        this.selected_date = date;
    }

    /**
     * 일 단위 offset 값 만큼 날짜 선택
     */
    selectDateByOffset(date_offset : number) : void {
        
        // 이전 주로 이동할 때 이미 왼쪽 끝이라면 무시 
        if( date_offset < 0 && this.isLeftEnd() ) { return; }

        // 다음 주로 이동할 때 이미 오른쪽 끝이라면 무시
        if( date_offset > 0 && this.isRightEnd() ) { return; }

        // 날짜 이동
        let move_date : Date = moment(this.selected_date).add("d", date_offset).toDate();
        if( move_date.getTime() < new Date().getTime() ) { move_date = new Date(); }
        if( move_date.getTime() > this.max_date.getTime() ) { move_date = this.max_date; }
        this.selected_date = move_date;
    }

    /**
     * 오늘 날짜로 이동
     */
    getTodayAvailableList() : void {
        this.selected_date = new Date();
    }

    /**
     * 왼쪽 끝 주간 여부
     */
    isLeftEnd() : boolean {
        const current_diff : number = this.getDateDiff(moment().set("date", new Date().getDate() - new Date().getDay()).toDate(), this.selected_date);
        const current_index : number = Math.floor(current_diff / 7);

        return current_index < 1;

    }

    /**
     * 오른쪽 끝 주간 여부
     */
    isRightEnd() : boolean {
        
        const current_diff : number = this.getDateDiff(moment().set("date", new Date().getDate() - new Date().getDay() - 1).toDate(), this.selected_date);
        const current_index : number = Math.ceil(current_diff / 7);
        const date_period : number = this.getDateDiff(moment().set("date", new Date().getDate() - new Date().getDay()).toDate(), this.max_date);
        const total_week : number = Math.ceil(date_period / 7);

        return current_index == total_week;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.event_bus.$emit('leftAvailableListResize');
        this.event_bus.$emit('rightAvailableListResize');
    }

    /**
     * 왼쪽 메뉴 접기 / 펼치기 변할때 hooper 다시 렌더링 
     */
    @Watch('left_control_box_flag')
    changeLeftControlBoxFlag() : void {
        this.event_bus.$emit('rerenderHooper');
    }

}
