
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

@Component({

}) export default class EventImportModal extends Mixins(VueHoduCommon) {

    mounted() : void {

    }

}
