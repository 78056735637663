
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, CRUD_TYPE, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_color } from '@/common/color';

import ChattingLeft  from '@/components/chatting/ChattingLeft.vue'
import ChattingRight from '@/components/chatting/ChattingRight.vue'

import { chat_interface, chat_enum, chat_modal_interface } from '@/model/chat';

import { hodu_web_socket } from '@/lib/HoduWebSocket';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ChattingLeft, ChattingRight,
        ResizeObserver
    },
})
export default class ChattingList extends Mixins(VueHoduCommon) {
    
    FORCE_CLOSE : number = 1000;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetChattingRoomCreateModalInfo ?: (params : chat_modal_interface.ChattingRoomCreateModalInfo) => void;
    @ModalInfo.Action doSetChattingRoomInfoModalInfo   ?: (params : chat_modal_interface.ChattingRoomInfoModalInfo) => void;

    event_bus : Vue = new Vue();

    channels : chat_interface.I_CHANNEL_INFO[] = [];
    connected_channel : chat_interface.I_CHANNEL_INFO | null = null;
    chat_list : chat_interface.I_CHAT_MESSAGE[] = [];
    users_online : Object = {};

    is_right_div_closed : boolean = false;
    is_chatting_room_manager : boolean = false;
    is_send_my_message : boolean = false;

    channel_interval ?: number

    first_msg_list : boolean = false; 
    is_closed_not_forcely : boolean = false;
    
    async mounted() : Promise<void> {

        const vue = this;
        
        // // 채팅 내용으로 검색
        // $(".schBtn").click(function(){
        //     $(this).parent().parent().find(".searchDiv").addClass("on");
        // });

        // // 채팅 내용으로 검색 닫기
        // $(".leftDiv .closeBtn").click(function(){
        //     $(".leftDiv .searchDiv").removeClass("on");
        // });

        // // 수정버튼 클릭 시
        // $(".leftDiv .btns .input_btn.edit").click(function(){
        //     $(".section_ce_fix").addClass("edit");
        // });

        // // 수정버튼 클릭 후 수정모드 닫기
        // $(".leftDiv .btns .input_btn.cancel").click(function(){
        //     $(".section_ce_fix").removeClass("edit");
        // });


        // // 친구와 그룹 열고 닫기
        // $(".mag_btn").click(function(){
        //     $(this).toggleClass("on");
        //     $(".section_ce_fix").toggleClass("rightDivClosed");

        // });

        // // .rightDiv .whenClosed 클릭시 친구 그룹 다시 열기
        // $(".rightDiv .whenClosed").click(function(){
        //     $(".mag_btn").removeClass("on");
        //     $(".section_ce_fix").removeClass("rightDivClosed");

        // });

        // // .chatroomBtns 친구, 사진, 파일 버튼
        // $(".chatroomBtns input").click(function(){

        //     var getIndex3 = $(this).index();

        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");

        //     $(".rightDiv").removeClass("mate");
        //     $(".rightDiv").removeClass("img");
        //     $(".rightDiv").removeClass("file");

        //     if ( getIndex3 == 0 ) {
        //         $(".rightDiv").addClass("mate");
        //     } else if ( getIndex3 == 1 ) {
        //         $(".rightDiv").addClass("img");
        //     } else {
        //         $(".rightDiv").addClass("file");
        //     }
        // });



        // // .goChatroom 채팅룸 가기 클릭 시
        // $(".goChatroom").click(function(){

        //     // 인덱스 찾기
        //     var getIndex = $(this).parent().index();

        //     $(".section_ce_fix").removeClass("rm");
        //     $(".section_ce_fix").removeClass("oneCt");

        //     // 방장 일 시 .section_ce_fix에 rm 클래스 추가
        //     if( getIndex == 3 ) {
        //         $(".section_ce_fix").addClass("rm");
        //     } else if ( getIndex == 5 ) {
        //         $(".section_ce_fix").addClass("oneCt");
        //     } else {
        //         $(".section_ce_fix").removeClass("rm");

        //     }

        //     $(".section_ce_fix").addClass("leftDivClosed");

        //     $(this).parent().siblings().removeClass("on");

        //     // 선택된 .chatroomUl li에 on넣기
        //     $(this).parent().addClass("on");
            
        // });

        // .chatroomMenu 클릭 시 채팅룸 닫고 채팅ul 다시 열기
        // $(".chatroomMenu").click(function(){
        //     $(".section_ce_fix").removeClass("leftDivClosed");
        //     // .chatroomUl li에 on 빼기
        //     $(".chatroomUl li").removeClass("on");
        // });


        // .mainPanel .addExtra 클릭 시 chatroomPanel 넓히기
        // $(".mainPanel .addExtra").click(function(){
        //     $(".chatroomPanel").toggleClass("on");
        //     $(this).toggleClass("on");
        // });

        // changeChatroomBgBtn 클릭시 chatroomBgDiv 열기 (배경색 바꾸기)
        // $(".changeChatroomBgBtn").click(function(){
        //     $(".chatroomBgDiv").addClass("on");

        // });

        // .addEmojis 클릭 시
        // $(".addEmojis").click(function(){
        //     $(this).toggleClass("on");

        // });

        // chatroomBgDiv a 클릭시
        // $(".chatroomBgDiv a").click(function(){
        //     var getIndexClr = $(this).index();
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");

        //     // 색 변경
        //     for( let i = 0; i < 11; i++ ) {
        //         if( $('.chatroom').hasClass(`bgc${i}`) ) {
        //             $('.chatroom').removeClass(`bgc${i}`)
        //             break;
        //         }
        //     }
        //     // 폴더 사라짐 방지
        //     $('.chatroom').addClass(`bgc${getIndexClr}`);
        // });

        // // .chatroomBgDiv .closeBtn 닫기 버튼
        // $(".chatroomBgDiv .closeBtn").click(function(){
        //     $(".chatroomBgDiv").removeClass("on");

        // });

        // // .rightDiv 속 .magBtn 클릭 시 친구 또는 그룹 ul보이기
        // $(".rightDiv .btns input").click(function(){

        //     // 인덱스 찾기
        //     var getIndex = $(this).index();

        //     // 버튼에 온 넣고 빼기
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");

        //     // 첫번째일때 친구 리스트 보이기
        //     if ( getIndex == 0 ) {

        //         $(".magUl").removeClass("grp");
        //         $(".magUl").addClass("mate");

        //     } else {

        //         // 그룹일때 그룹 보이기
        //         $(".magUl").removeClass("mate");
        //         $(".magUl").addClass("grp");
                
        //     }

        // });

        // // 그룹 > 멤버보기 클릭 시 (채팅룸 만들기 없애기) 
        // $(".grpUl > li .seeMembers").click(function(){
        //     var getIndex1 = $(this).parent().parent().parent().index();

        //     $(this).toggleClass("on");

        //     $(".grpUl > li").eq(getIndex1).find(">div").toggleClass("on");
        //     $(".grpUl > li").eq(getIndex1).toggleClass("on");
        // });

        // // 팀 보기
        // $(".grpList .oac").click(function(){
        //     $(".teamList").addClass("teamOn");
        // });

        // // 팀 멤버보기
        // $(".teamList .grpUl .oac").click(function(){
        //     $(this).toggleClass("on");
        //     $(this).parent().siblings().toggleClass("on");
        //     $(this).parent().parent().toggleClass("on");
        // });

        // // 팀 빠져나가기
        // $(".teamList .prev").click(function(){
        //     $(".teamList").removeClass("teamOn");
        // });

        // // rightDiv 수정 클릭 시
        // $(".rightDiv .editBtn").click(function(){
        //     $(this).parent().parent().addClass("editMode");
        //     $(".rightDiv").addClass("editMode");

        // });
        // $(".rightDiv .cancelBtn").click(function(){
        //     $(this).parent().parent().removeClass("editMode");
        //     $(".rightDiv").removeClass("editMode");

        // });

        // // .ctMenu
        // $(".ctMenu").click(function(){
        //     $(this).toggleClass("on");
        //     $(".rightSettingDiv").toggleClass("on");
        // });

        // // .addBtn
        // $(".addBtn").click(function(){
        //     $("#chatting_room_invite_confirm_modal").removeClass("ps");
        // });


        // $(".section_ce_fix .btns2 .edit").click(function(){
        //     $(this).parent().addClass("editMode");
        //     $(".chatroom").addClass("editMode");

        // });


        // $(".section_ce_fix .btns2 .cancel").click(function(){
        //     $(this).parent().removeClass("editMode");
        //     $(".chatroom").removeClass("editMode");

        // });

        await this.myChannelList()
        this.channel_interval = window.setInterval(() => {
            if( this.connected_channel != null ) return;
            window['myNewMessage']?.();
            this.myChannelList();
        }, 30000);
    }

    /**
     * 웹소켓 닫기
     */
    beforeDestroy() : void {
        this.closeWebSocket();
        clearInterval(this.channel_interval);
    }

    /**
     * 채널 리스트 가져오기
     */
    async myChannelList() : Promise<void> {

        try {

            // 친구 정보 조회
            await this.getFriends(false);

            const blocked_friends = this.friends.filter(friend => friend.is_block == true);
            const blocked_friends_user_ids = Array.from(blocked_friends, x => x.friend_user_id);

            const channel_response = await this.hodu_api_call('api/v1/chat/channel_info/my', API_METHOD.GET, null, false);

            console.log(channel_response);
            this.channels.splice(0, this.channels.length);
            this.channels = this.channels.concat(channel_response.data.data.channels);

            // 차단된 친구와 1:1 채팅방인경우 그냥 숨긴다
            if( blocked_friends.length > 0 ) {
                console.log(JSON.stringify(blocked_friends));
                this.channels = this.channels.filter(channel => 
                    (channel.users == null || (channel.users != null && ( channel.users.length != 2 || ( channel.users.length == 2 &&
                        channel.users.filter(user => user.user_id != String(this.user_id) && blocked_friends_user_ids.indexOf(Number(user.user_id)) == -1).length > 0
                    ))))
                );
            }

            this.channels.sort((o1, o2) : number => {

                if( o1.last_msg == null && o2.last_msg == null ) {
                    return 0;
                }

                if( o1.last_msg == null && o2.last_msg != null ) {
                    return 1;
                }

                if( o1.last_msg != null && o2.last_msg == null ) {
                    return -1;
                }

                if( o1.last_msg != null && o2.last_msg != null ) {
                    const o1_date : Date = new Date(o1.last_msg['send_date']);
                    const o2_date : Date = new Date(o2.last_msg['send_date']);

                    if( o1_date.getTime() == o2_date.getTime() ) return 0;
                    if( o1_date.getTime() > o2_date.getTime() ) return -1;
                    if( o1_date.getTime() < o2_date.getTime() ) return 1;
                }

                return 0;
            });

        } catch(e) {
            this.hodu_error_process(e, true, false);
        }
    }

    /**
     * 채널 참여
     */
    async join(channel : chat_interface.I_CHANNEL_INFO) : Promise<void> {

        try {
            hodu_web_socket.close();

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${channel.channel_uid}/join`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.channel ) {
                throw new Error("채팅방 참여 실패");
            }

            this.connected_channel = response.data.data.channel;
            
            if( this.connected_channel != null && this.connected_channel.users != null ) {

                for( const user of this.connected_channel.users ) {
                    if( Number(user.user_id) != this.user_id ) continue;
                    this.is_chatting_room_manager = Number(user.user_id) == this.user_id && user.user_role == 'OWNER';
                    break;
                }

            }

            if( this.connected_channel == null || this.connected_channel.channel_url == null || this.connected_channel.channel_url.length < 1 ) {
                throw new Error("채팅방 참여 실패");
            }

            this.openWebSocket(response.data.data.channel.channel_url);

        } catch(e) {
            this.hodu_show_dialog('cancel', "채팅방 참여 실패", ['확인']);
        }
        
    }

    /**
     * 웹 소켓 열기
     */
    openWebSocket(url : string, is_retry : boolean = false) : void {
        console.log(`TRY CONNECT WEB SOCKET : ${url}`);

        const device_uid = sessionStorage.getItem("device_uid");
        const session_token = sessionStorage.getItem("session_token");

        if( device_uid == null || session_token == null ) { return; }

        // 재시도라면 원래 URL 그대로 사용
        if( is_retry == false ) {
 
            url = url.replace(/https:/ig, 'wss:').replace(/http:/ig, 'ws:');

            // https 환경에서는 wss 사용해야함
            if( new RegExp("https:").test(location.protocol) == true ) {
                const url_class : URL = new URL(url);    
                let port_number : number = Number(url_class.port);
                
                port_number += 10;

                url_class.port = String(port_number);

                url = url_class.toString().replace(/ws:/ig, "wss:");
                url = url.replace("app.hoduware.com", "chat.hodu.app");
            }

        }

        try {
            hodu_web_socket.connect(`${url}?device-uid=${device_uid}&authorization=${session_token}`);
        } catch(e) {
            this.hodu_show_dialog('cancel', "채팅방 접속 실패", ['확인'], [() => { 
                this.connected_channel = null;
                this.is_chatting_room_manager = false; 
            }]);
            return;
        }

        // 웹소켓 콜백 등록
        hodu_web_socket.onopen(this.webSocketOnOpen);

        hodu_web_socket.onerror((event : Event) => {
            console.log("WEB SOCKET ERROR");
            hodu_web_socket.close();
        });

        hodu_web_socket.onclose((event : CloseEvent) => {
            console.log(`CLOSE WEB SOCKET : ${hodu_web_socket.getWebSocket()?.url}`);

            this.users_online = JSON.parse(JSON.stringify({}));

            // 채널 접속 중에 끊겼다면 재시도
            if( this.connected_channel != null && event.code != this.FORCE_CLOSE ) {
                this.is_closed_not_forcely = true;
                this.openWebSocket(url, true);
            } else {
                this.chat_list.splice(0, this.chat_list.length);
            }
        });

    }

    /**
     * 웹소켓 onopen 콜백
     */
    webSocketOnOpen(event : Event) : void {
        console.log(`OPEN WEB SOCKET : ${hodu_web_socket.getWebSocket()?.url}`);
        hodu_web_socket.onmessage(this.webSocketOnMessage);
    }

    /**
     * 웹소켓 onmessage 콜백
     */
    webSocketOnMessage({data}) : void {

        // 데이터 수신
        data = JSON.parse(data);
        console.log(data);

        switch( data.sub_type ) {
            /**
             * 메세지 리스트
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_LIST:
                
                // 끊겼다가 들어왔다면
                if( this.is_closed_not_forcely == true ) {
                    this.is_closed_not_forcely = false;
                    
                    // 데이터가 완전히 비었다면 다 삭제 
                    if( data.data.length < 1 ) {
                        this.chat_list.splice(0, this.chat_list.length);
                        return;
                    }

                    // 기존 채팅 리스트가 비어있었다면 전부 추가
                    if( this.chat_list.length < 1 ) {
                        this.chat_list = this.chat_list.concat(data.data);
                        this.read(data.data[0].idx); 
                        return;
                    }

                    for( const chat of data.data ) {
                        if( chat.idx > (this.chat_list[0].idx ?? 0) ) {
                            this.chat_list.unshift(chat);
                        }
                    }
                    this.read(data.data[0].idx); 
                    return;
                }

                this.chat_list = this.chat_list.concat(data.data);
                
                if( data.data.length < 50 ) {
                    this.event_bus.$emit('setPageEnd', true);
                }

                this.readMaxIdx();
                console.log("Chatting:webSocketOnMessage:MSG_LIST");
                
                if( this.first_msg_list == false ) {
                    this.first_msg_list = true;
                    this.$nextTick(() => { 
                        console.log("Chatting:webSocketOnMessage:MSG_LIST:scrollInit");
                        this.event_bus.$emit('scrollInit'); 
                    });
                }
                break;

            /**
             * 메세지
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG:
                this.chat_list.push(data);
                this.chat_list.sort((o1, o2) : number => {
                    
                    if( o1.send_date! > o2.send_date! ) {
                        return -1;
                    }
                    
                    if( o1.send_date! < o2.send_date! ) {
                        return 1;
                    }

                    return 0;
                })
                this.read(data.idx);

                /* eslint no-case-declarations: "off"*/
                let is_bottom : boolean = false;
                const scroll_container = document.getElementById('chatroom_scroll')
                if( scroll_container != null ) {
                    const height = $(scroll_container).innerHeight() 
                    const scrollY = $(scroll_container).scrollTop();
                    const scrollHeight = scroll_container.scrollHeight;

                    if( height != null && scrollY != null ) {
                        is_bottom = (height + scrollY) == scrollHeight;
                    }
                    
                }
                

                if( (data.user_id == this.user_id && this.is_send_my_message == true) || is_bottom ) {
                    this.event_bus.$emit('scrollBottom');
                    this.is_send_my_message = false;
                }

                // 이미지 또는 파일인 경우 이미지 리스트, 파일 리스트에 추가시킨다
                if( data.data != null && data.data.files != null && data.data.files.length > 0 ) {
                    this.event_bus.$emit('imageOrFileAdd', data.data.files);
                }
                break;

            /**
             * 메세지 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_DEL:
                if( this.chat_list.filter(chat => chat.idx == data.data).length < 1 ) { return; }

                this.chat_list.splice(this.chat_list.indexOf(this.chat_list.filter(chat => chat.idx == data.data)[0]), 1);
                break;

            /**
             * 메세지 전체 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_DEL_ALL:
                this.chat_list.splice(0, this.chat_list.length);
                break;

            /**
             * 이미지 전체 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_DEL_ALL_IMAGE:
                this.event_bus.$emit('imageOrFileDelete', 'IMAGE');
                break;

            /**
             * 파일 전체 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.MSG_DEL_ALL_FILE:
                this.event_bus.$emit('imageOrFileDelete', 'FILE');
                break;

            /**
             * 메시지 읽음여부
             */
            case chat_enum.MSG_CHANNEL_TYPE.READ:
                if( this.connected_channel == null ) { return; }

                if( this.connected_channel.users_read == null ) {
                    this.connected_channel.users_read = {};
                }

                this.connected_channel.users_read[`${data.user_id}`] = data.data;
                this.connected_channel.users_read = JSON.parse(JSON.stringify(this.connected_channel.users_read));
                window['myNewMessage']?.();
                break;

            /**
             * 멤버 온라인
             */
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_ONLINE:
                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }
                
                this.users_online[`${data.user_id}`] = String(data.send_date);
                this.users_online = JSON.parse(JSON.stringify(this.users_online));
                break;

            /**
             * 멤버 오프라인
             */
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_OFFLINE:
                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }

                delete(this.users_online[`${data.user_id}`]);
                this.users_online = JSON.parse(JSON.stringify(this.users_online));
                break;

            /**
             * 멤버 가입 && 멤버 역할 변경
             */
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_JOIN:
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_MODIFY:
                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }

                if ( this.connected_channel.users.filter(user => user.user_id == data.data.user_id)[0] == null ) {
                    this.connected_channel?.users?.push(data.data);
                    return;
                }

                this.connected_channel.users.splice(
                    this.connected_channel.users.indexOf(this.connected_channel.users.filter(user => user.user_id == data.data.user_id)[0]),
                    1,
                    data.data
                );

                if( this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id)[0] != null ) {
                    this.is_chatting_room_manager = ("OWNER" == this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id)[0].user_role);
                }

                break;

            /**
             * 멤버 강퇴 && 멤버 나감
             */
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_KICK:
            case chat_enum.MSG_CHANNEL_TYPE.MEMBER_LEAVE:
                if( this.connected_channel == null || this.connected_channel.users == null ) { return; }
                
                if( data.data == this.user_id ) {
                    if( data.sub_type == chat_enum.MSG_CHANNEL_TYPE.MEMBER_KICK ) {
                        this.hodu_show_dialog("cancel", "채팅방에서 강제 퇴장 처리 되었습니다", ["확인"]);
                    }
                    this.movePrevPageAndChattingRoomExit();
                }

                for( let i = 0; i < this.connected_channel.users.length; i++ ) {
                    if( this.connected_channel.users[i].user_id == data.data ) {
                        this.connected_channel.users.splice(i, 1);
                        delete(this.connected_channel.users_read![`${data.data}`]);
                        break;
                    }
                }
                break;

            /**
             * 채팅방 수정
             */
            case chat_enum.MSG_CHANNEL_TYPE.MODIFY:
                this.connected_channel = JSON.parse(JSON.stringify(data.data));
                break;

            /**
             * 채팅방 삭제
             */
            case chat_enum.MSG_CHANNEL_TYPE.DESTROY:
                this.hodu_show_dialog("cancel", "채팅방이 삭제 되었습니다", ["확인"]);
                this.movePrevPageAndChattingRoomExit();
                break;
        }

    }

    /**
     * 웹 소켓 닫기
     */
    closeWebSocket() : void {
        hodu_web_socket.close(this.FORCE_CLOSE);
    }

    /**
     * 웹 소켓에 데이터 송신
     */
    send(data : string) : void {
        console.log(data);
        hodu_web_socket.send(data);
    }

    /**
     * 읽기 처리
     */
    read(idx: number) {

        this.send(JSON.stringify({
            type : 'CHANNEL',
            sub_type : chat_enum.MSG_CHANNEL_TYPE.READ,
            data : idx
        }));

    }

    /**
     * 마지막 index 읽기처리
     */
    readMaxIdx() {
        this.read(this.connected_channel?.msg_idx ?? 0);
    }

    /**
     * 채팅방 초대
     */
    invite() { 
        
        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : "채팅방 초대",
            use_user_select_only : true,
            callback : async(selected_info) => {
                console.log(selected_info);

                try {
                    const user_infos : any[] = [];
                    for( const user_id of selected_info.selected_user_ids ) {
                        user_infos.push({
                            user_id: String(user_id), 
                            user_name: ""
                        });
                    }

                    if( this.connected_channel != null && this.connected_channel.users != null && this.connected_channel.users.length == 1 ) {

                        // 차단한 상대와의 1:1채팅은 불가능
                        await this.getFriends(false);

                        const blocked_friends = this.friends.filter(friend => friend.is_block == true);
                        const blocked_friends_user_ids = Array.from(blocked_friends, x => x.friend_user_id);

                        if( selected_info.selected_user_ids.length == 1 && blocked_friends_user_ids.indexOf(selected_info.selected_user_ids[0]) > -1 ) {
                            this.hodu_show_dialog("cancel", "차단한 상대와 1:1 채팅은 불가능합니다", ['확인']);
                            return;
                        }

                    }
                    
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/invite`, API_METHOD.POST, user_infos);

                    console.log(response);
                
                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data ) {
                        throw new Error("채팅방 초대 실패");
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 초대 중 오류 발생", ['확인']);
                }
            }
        });
        
    }

    /**
     * 채팅방으로 이동
     */
    moveChattingRoom(room_id : string) : void {
        this.hodu_router_push(`/chatting/${new Date().getTime()}/${room_id}`)
    }

    /**
     * 임시 페이지로 이동
     */
    moveChattingTempPage() : void {
        this.hodu_router_push(`/chatting/${new Date().getTime()}/temp-page`);
    }

    /**
     * 모달 열기
     */
    async showChattingRoomCreateModal() : Promise<void> {
        
        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : "채팅방 생성",
            use_user_select_only : true,
            callback : async(selected_info) => {
                console.log(selected_info);

                try {

                    // 차단한 상대와의 1:1채팅은 불가능
                    await this.getFriends(false);

                    const blocked_friends = this.friends.filter(friend => friend.is_block == true);
                    const blocked_friends_user_ids = Array.from(blocked_friends, x => x.friend_user_id);

                    if( selected_info.selected_user_ids.length == 1 && blocked_friends_user_ids.indexOf(selected_info.selected_user_ids[0]) > -1 ) {
                        this.hodu_show_dialog("cancel", "차단한 상대와 1:1 채팅은 불가능합니다", ['확인']);
                        return;
                    }

                    const user_infos : any[] = [];
                    for( const user_id of selected_info.selected_user_ids ) {
                        user_infos.push({
                            user_id: String(user_id), 
                            user_name: ""
                        });
                    }

                    const post_data : chat_interface.I_CHANNEL_CREATE_INFO = {
                        channel_info : {
                            type : 'CHAT',                 
                            sub_type : OWNER_TYPE.PERSONAL,
                            ref_id : '',
                            is_private : true,
                            info: { "title": '' }
                        },
                        invite_user_infos : user_infos ? user_infos : []
                    }
                    
                    const response = await this.hodu_api_call(`api/v1/chat/channel_info/`, API_METHOD.POST, post_data);

                    console.log(response);
                
                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.channel ) {
                        throw new Error("채팅방 등록 실패");
                    }

                    const channel = response.data.data.channel;

                    this.myChannelList();
                    this.connected_channel = channel;

                    console.log(channel);

                    // 해당 방의 관리자인지 구해야함
                    this.is_chatting_room_manager = false;
                    for( const user of channel.users ) {
                        if( user.user_id == this.user_id ) {
                            this.is_chatting_room_manager = (user.user_role == 'OWNER');
                            break;
                        }
                    }

                    this.openWebSocket(channel.channel_url);

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "채팅방 등록 중 오류 발생", ['확인']);
                }
            }
        });
    }

    /**
     * 채팅방 설정 모달
     */
    showChattingRoomInfoModal() : void {
        if( this.doSetChattingRoomInfoModalInfo == null ) { return; }

        this.doSetChattingRoomInfoModalInfo({
            show_modal : true
        });
    }

    /**
     * 뒤로 가기
     */
    movePrevPageAndChattingRoomExit() : void {

        if( this.connected_channel == null ) {
            this.movePrevPage();
            return;
        }

        this.connected_channel = null;
        this.is_chatting_room_manager = false;
        this.is_right_div_closed = false;
        this.first_msg_list = false;
        this.closeWebSocket();
        this.myChannelList();
    }

    /**
     * 리사이즈 감지 
     */
    handleResize() : void {
        this.event_bus.$emit('handleLeftResize');
        this.event_bus.$emit('handleRightResize');
    }

}
