import { organization_enum } from '@/model/organization';
import { ActionTree, MutationTree, GetterTree } from 'vuex';

export const namespaced = true;

export interface OrganizationInfoState {
    selected_tab : organization_enum.ORGANIZATION_SETTING_TAB;
    selected_vacation_tab : organization_enum.ORGANIZATION_VACATION_TAB;
}

export const state : OrganizationInfoState = {
    selected_tab : organization_enum.ORGANIZATION_SETTING_TAB.RANK,
    selected_vacation_tab : organization_enum.ORGANIZATION_VACATION_TAB.ADMIN,
};

// getters
export const getters: GetterTree<OrganizationInfoState, any> = {

    getSelectedTab : (state) => {
        return state.selected_tab;
    },

    getSelectedVacationTab : (state) => {
        return state.selected_vacation_tab;
    },

}

// action
export const actions: ActionTree<OrganizationInfoState, OrganizationInfoState> = {

    doSetOrganizationSelectedTab: ({state, commit}, selected_tab : organization_enum.ORGANIZATION_SETTING_TAB) => {
        console.log(`actions.doSetOrganizationSelectedTab ${JSON.stringify(selected_tab)}`);
        state.selected_tab = selected_tab;

        if( sessionStorage != null ) {
            sessionStorage.setItem("organization_setting_tab_info", JSON.stringify({
                selected_tab : state.selected_tab
            }));
        }
    },

    doSetOrganizationSelectedVacationTab: ({state, commit}, selected_vacation_tab : organization_enum.ORGANIZATION_VACATION_TAB) => {
        console.log(`actions.doSetOrganizationSelectedVacationTab ${JSON.stringify(selected_vacation_tab)}`);
        state.selected_vacation_tab = selected_vacation_tab;

        if( sessionStorage != null ) {
            sessionStorage.setItem("organization_vacation_tab_info", JSON.stringify({
                selected_vacation_tab : state.selected_vacation_tab
            }));
        }
    },

}