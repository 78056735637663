
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, EVENT_SUB_TYPE, CRUD_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import { ScheduleSearchConfig } from '@/store/modules/CalendarInfo';
const CalendarInfo = namespace('CalendarInfo');
const EventInfo    = namespace('EventInfo');
const ModalInfo    = namespace('ModalInfo');

import { t_event } from '@/model/event';
import { local_storage_info, LocalStorageInfo, hodu_local_storage } from '@/lib/HoduLocalStorage';

@Component({

}) export default class EventShortCreate extends Mixins(VueHoduCommon) {

    /**
     * @CalendarInfo.State
     */
    @CalendarInfo.State selected_date          !: Date;

    /**
     * @EventInfo.State
     */
    @EventInfo.State event !: t_event;

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetIsMovedByShortCreate ?: any;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventShortCreate ?: any;

    date_term : number = 0;
    group_team_list : any = [];
    colorToneMode : string = "dc";
    group_color : string = "transparent";

    start_date  : string = "";
    start_time  : string = "";
    start_hour  : string = "";
    start_min   : string = "";
    start_am_pm : string = "";

    end_date  : string = "";
    end_time  : string = "";
    end_hour  : string = "";
    end_min   : string = "";
    end_am_pm : string = "";

    group_select_open : boolean = false;
    color_select_open : boolean = false;
    time_from_open : boolean = false;
    time_to_open : boolean = false;

    async mounted() : Promise<void> {
        const vue = this;
        this.makeDateString();
        await this.get_group_role_service();

        const group_outer_height : number | undefined = $('#s_group').outerHeight();
        const group_outer_height_final : number = group_outer_height == null ? 0 : group_outer_height; 

        // mCustomScrollbar
        let scrollerOption = {
            axis : 'y',
            setHeight : 282,
            scrollbarPosition : 'outside',
            mouseWheelPixels:200,
        };

        // @ts-ignore
        $('#schedule_simple_group_div').mCustomScrollbar(scrollerOption);

        // 오토 포커스
        $('#simple_title').focus();
        
        // owner 데이터 삽입
        this.event.calendar_id                 = this.calendar_id;
        this.event.event_data.event_owner_id   = this.user_id;
        this.event.event_data.event_owner_name = this.user_name;

        if( this.scope_group_team_option.group_team_color != null && this.scope_group_team_option.group_team_color.length > 0 && this.scope_group_team_option.group_team_color != '#FFFFFF' ) {
            this.group_color = this.scope_group_team_option.group_team_color;
        }

        // date_picker
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');

                if( id == null ){
                    return;
                }
                
                const select_date : Date = new Date(dateText); 
                inst.input.val(`${dateText} ${vue.getDayOfWeek(select_date)}`);

                // 시작일 변경
                if( id == 's_FrYmd'){
                    
                    // start 원래의 시간, 분 유지
                    select_date.setHours(vue.event.event_data.schedule_date.start instanceof Date 
                                       ? vue.event.event_data.schedule_date.start.getHours() 
                                       : new Date(vue.event.event_data.schedule_date.start).getHours());

                    select_date.setMinutes(vue.event.event_data.schedule_date.start instanceof Date 
                                         ? vue.event.event_data.schedule_date.start.getMinutes() 
                                         : new Date(vue.event.event_data.schedule_date.start).getMinutes()); 

                    // date 적용
                    vue.event.event_data.schedule_date.start = select_date;

                    // date_term 유지 준비 
                    const term_date : Date = new Date(select_date.getTime());

                    // end 원래의 시간, 분 유지
                    term_date.setHours(vue.event.event_data.schedule_date.end instanceof Date 
                                       ? vue.event.event_data.schedule_date.end.getHours() 
                                       : new Date(vue.event.event_data.schedule_date.end).getHours());

                    term_date.setMinutes(vue.event.event_data.schedule_date.end instanceof Date 
                                         ? vue.event.event_data.schedule_date.end.getMinutes() 
                                         : new Date(vue.event.event_data.schedule_date.end).getMinutes()); 

                    // date_term 유지
                    term_date.setDate(term_date.getDate() + vue.date_term);

                    // 종료일이 시작일 보다 느릴때 시작일의 +1일을 해준다 ex) 시작일이 오후 11:50, 종료일이 다음날 오전 12:00 일때 시작 년월일을 바꾸는 경우
                    if( term_date.getTime() < select_date.getTime() ) {
                        term_date.setDate(select_date.getDate() + 1);
                    }

                    vue.event.event_data.schedule_date.end            = term_date;
                    vue.event.event_data.schedule_date.recurrence_end = term_date;

                // 종료일 변경
                } else {

                    // end 원래의 시간, 분 유지
                    select_date.setHours(vue.event.event_data.schedule_date.end instanceof Date 
                                       ? vue.event.event_data.schedule_date.end.getHours() 
                                       : new Date(vue.event.event_data.schedule_date.end).getHours());

                    select_date.setMinutes(vue.event.event_data.schedule_date.end instanceof Date 
                                         ? vue.event.event_data.schedule_date.end.getMinutes() 
                                         : new Date(vue.event.event_data.schedule_date.end).getMinutes()); 

                    // 시작일과 종료일이 완벽히 같은 시간이라면 => 종료일의 시간을 + 10분 함
                    if( new Date(vue.event.event_data.schedule_date.start).getTime() == select_date.getTime() ) {
                        select_date.setMinutes(select_date.getMinutes() + 10);
                    
                    // 종료일이 더 옛날 날짜라면 => 시작일은 종료일로 변경 시작일 시간의 + 1시간
                    } else if( new Date(vue.event.event_data.schedule_date.start).getTime() > select_date.getTime() ){
                        const new_start : Date = new Date(select_date.getTime());
                        new_start.setHours(new Date(vue.event.event_data.schedule_date.start).getHours());
                        new_start.setMinutes(new Date(vue.event.event_data.schedule_date.start).getMinutes());
                        vue.event.event_data.schedule_date.start = new_start;

                        select_date.setHours(new Date(vue.event.event_data.schedule_date.start).getHours() + 1);
                        select_date.setMinutes(new Date(vue.event.event_data.schedule_date.start).getMinutes());
                    }

                    // date 적용
                    vue.event.event_data.schedule_date.end            = select_date;
                    vue.event.event_data.schedule_date.recurrence_end = select_date;

                    // date-term 변경
                    vue.date_term = vue.getDateDiff(vue.event.event_data.schedule_date.start, vue.event.event_data.schedule_date.end);

                    // date_term이 0보다 클때 연속일정 아니라면 일반 일정
                    vue.event.event_data.schedule_date.isContinuos = ( vue.date_term > 0 );
                }

                vue.makeDateString();

            },
        };

        // @ts-ignore
        $('#s_FrYmd').datepicker(option);

        // @ts-ignore
        $('#s_ToYmd').datepicker(option);

        // 그룹 정보 가공
        await this.getGroupInformation();
    }

    /**
     * 날짜 텍스트 만들기
     */
    makeDateString() : void {
        const start : Date = this.event.event_data.schedule_date.start instanceof Date 
                             ? this.event.event_data.schedule_date.start 
                             : new Date(this.event.event_data.schedule_date.start);

        this.start_date  = `${start.getFullYear()}.${`0${start.getMonth() + 1}`.slice(-2)}.${`0${start.getDate()}`.slice(-2)} ${this.getDayOfWeek(start)}`;
        this.start_hour  = `0${start.getHours() <= 12 ? ( start.getHours() == 0 ? 12 : start.getHours() ) : start.getHours() - 12}`.slice(-2);
        this.start_min   = `0${start.getMinutes()}`.slice(-2);
        this.start_am_pm = start.getHours() < 12 ? "AM" : "PM";
        const string_start_am_pm : string = start.getHours() < 12 ? "오전" : "오후";
        this.start_time  = `${string_start_am_pm} ${this.start_hour}:${this.start_min}`;

        const end : Date = this.event.event_data.schedule_date.end instanceof Date 
                           ? this.event.event_data.schedule_date.end 
                           : new Date(this.event.event_data.schedule_date.end);

        this.end_date  = `${end.getFullYear()}.${`0${end.getMonth() + 1}`.slice(-2)}.${`0${end.getDate()}`.slice(-2)} ${this.getDayOfWeek(end)}`;
        this.end_hour  = `0${end.getHours() <= 12 ? ( end.getHours() == 0 ? 12 : end.getHours() ) : end.getHours() - 12}`.slice(-2);
        this.end_min   = `0${end.getMinutes()}`.slice(-2);
        this.end_am_pm = end.getHours() < 12 ? "AM" : "PM";
        const string_end_am_pm : string = end.getHours() < 12 ? "오전" : "오후";
        this.end_time  = `${string_end_am_pm} ${this.end_hour}:${this.end_min}`;
    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * 그룹 정보 가공
     */
    async getGroupInformation() : Promise<void> {
        
        // 개인 캘린더일때만 개인일정을 넣는다
        if( this.scope == OWNER_TYPE.PERSONAL ) {
            this.group_team_list.push({
                "target" : OWNER_TYPE.PERSONAL,
                "target_id" : this.user_id,
                "target_name" : "개인일정",
                "target_color" : "transparent",
                "max_image_count" : 4
            });
        }

        // group_info가 없으면 수행 할 필요 없음
        if( this.group_info_list == null ){
            return;
        }
        
        // 가공 시작
        const group_info_list_length : number = this.group_info_list.length;
        const user_group_role_length : number = this.user_group_role.length;
        for( let i = 0; i < group_info_list_length; i++ ) {

            const group_info = this.group_info_list[i];

            // 인증 안된 상태라면 호두홈, 호두키즈 일정 생성 불가
            if( (this.auth_info == null || this.auth_info.auth_check == false) && (group_info.biz_type == 'BIZH' || group_info.group_info.project_type == 'kids') ) {
                continue;
            }
            
            // group_role 가져와서 그룹 권한 체크 후 데이터 쌓기
            for( let k = 0; k < user_group_role_length; k++) {
                if( group_info.group_id == this.user_group_role[k].group_id ){
                    if( this.user_group_role[k].group_permmision.event.create == false ){
                        break;
                    }

                    // 그룹&팀 달력인데 BIZ_ID가 같지않다면 제외시킨다
                    if( this.scope != OWNER_TYPE.PERSONAL && group_info.biz_id != this.scope_group_team_option.biz_id ) {
                        break;
                    }

                    // 그룹 가공 정보 추가
                    this.group_team_list.push({
                        "target" : "GROUP",
                        "target_id" : group_info.group_id,
                        "target_name" : group_info.group_name,
                        "target_color" : group_info.color
                    });
                    
                }
            }

            if( group_info.teams == null || group_info.teams.length < 1 ){
                continue;
            }
            
            const teams_length : number = group_info.teams.length
            const user_team_role_length : number = this.user_team_role.length;
            for( let j = 0; j < teams_length; j++ ){

                const team_info = group_info.teams[j];

                for( let k = 0; k < user_team_role_length; k++) {
                    
                    const team_role = this.user_team_role[k];

                    if( team_info.team_id == team_role.team_id ){
                        
                        if( team_role.team_permmision.event.create == false ){
                            break;
                        }

                        // 그룹&팀 달력인데 BIZ_ID가 같지않다면 제외시킨다
                        if( this.scope != OWNER_TYPE.PERSONAL && team_info.biz_id != this.scope_group_team_option.biz_id ) {
                            break;
                        }

                        // 팀 가공 정보 추가
                        this.group_team_list.push({
                            "target" : "TEAM",
                            "target_id" : team_info.team_id,
                            "target_name" : team_info.team_name,
                            "target_color" : team_info.color
                        });
                    
                    }
                }
            }
        }

        // 개인달력에서 일정 생성 하는 것이 아니라면 나간다
        if( this.scope != OWNER_TYPE.PERSONAL || this.event.event_sub_type != EVENT_SUB_TYPE.SCHEDULE ) {
            return;
        }

        // 로컬 데이터 받아오기
        const local_data : string | null = hodu_local_storage.getItem(`${this.user_id}`);
        if( local_data != null ) {
            const temp_local_data : LocalStorageInfo = JSON.parse(local_data);
            local_storage_info.last_event_created_scope = temp_local_data.last_event_created_scope;
        }

        // 이전에 선택한 그룹&팀이 없다면 나간다
        if( local_storage_info == null || local_storage_info.last_event_created_scope == null || local_storage_info.last_event_created_scope.scope == null || 
            local_storage_info.last_event_created_scope.scope.length < 1 || local_storage_info.last_event_created_scope.scope_id == null || local_storage_info.last_event_created_scope.scope_id < 1 ) {
            return;
        }

        // 마지막에 선택한 그룹&팀 정보 찾기
        const group_team_count : number = this.group_team_list.length;
        for( let i = 0; i < group_team_count; i++ ){
            switch( local_storage_info.last_event_created_scope.scope ) {
                case "GROUP":
                    if( this.group_team_list[i].target != "GROUP" || local_storage_info.last_event_created_scope.scope_id != this.group_team_list[i].target_id ){
                        continue;
                    }

                    this.event.event_type = local_storage_info.last_event_created_scope.scope;
                    this.event.calendar_id = `group-${this.group_team_list[i].target_id}`;
                    this.event.user_id = 0;
                    this.event.group_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.event_data.event_owner_group_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.team_id = 0;
                    this.event.event_data.event_owner_team_id = 0;
                    this.event.event_data.event_owner_group_name = this.group_team_list[i].target_name;

                    this.group_color = this.group_team_list[i].target_color;
                    break;

                case "TEAM":
                    if( this.group_team_list[i].target != "TEAM" || local_storage_info.last_event_created_scope.scope_id != this.group_team_list[i].target_id ){
                        continue;
                    }

                    this.event.event_type = local_storage_info.last_event_created_scope.scope;
                    this.event.calendar_id = `team-${this.group_team_list[i].target_id}`;
                    this.event.user_id = 0;
                    this.event.group_id = 0;
                    this.event.event_data.event_owner_group_id = 0;
                    this.event.team_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.event_data.event_owner_team_id = local_storage_info.last_event_created_scope.scope_id;
                    this.event.event_data.event_owner_team_name = this.group_team_list[i].target_name;

                    this.group_color = this.group_team_list[i].target_color;
                    break;

                default:
                    this.group_color = 'transparent';
                    break;
            }
        }
    }

    /**
     * 추가 정보 영역이 열린 상태에서 반투명한 영역 클릭
     */
    clickAddBg() : void {
        this.color_select_open = false;
        this.group_select_open = false;
        this.time_from_open    = false;
        this.time_to_open      = false;
    }

    /**
     * 그룹 선택창 열기
     */
    groupSelectListOpen() : void {
        this.group_select_open = true;
    }

    /**
     * 그룹, 팀 선택
     */
    group_team_select(group_team : any) : void {
        
        this.event.event_type = group_team.target;
        this.group_color      = group_team.target_color;
        
        switch(group_team.target) {
            case OWNER_TYPE.PERSONAL:
                this.event.calendar_id                       = `personal-${this.user_id}`;
                this.event.user_id                           = this.user_id;
                this.event.group_id                          = 0;
                this.event.team_id                           = 0;
                this.event.event_data.event_owner_group_id   = 0;
                this.event.event_data.event_owner_group_name = "";
                this.event.event_data.event_owner_team_id    = 0;
                this.event.event_data.event_owner_team_name  = "";
                break;

            case OWNER_TYPE.GROUP:
                this.event.calendar_id                       = `group-${group_team.target_id}`;
                this.event.user_id                           = 0;
                this.event.group_id                          = group_team.target_id;
                this.event.team_id                           = 0;
                this.event.event_data.event_owner_group_id   = group_team.target_id;
                this.event.event_data.event_owner_group_name = group_team.target_name;
                this.event.event_data.event_owner_team_id    = 0;
                this.event.event_data.event_owner_team_name  = "";
                break;

            case OWNER_TYPE.TEAM:
                this.event.calendar_id                       = `team-${group_team.target_id}`;
                this.event.user_id                           = 0;
                this.event.group_id                          = 0;
                this.event.team_id                           = group_team.target_id;
                this.event.event_data.event_owner_group_id   = 0;
                this.event.event_data.event_owner_group_name = "";
                this.event.event_data.event_owner_team_id    = group_team.target_id;
                this.event.event_data.event_owner_team_name  = group_team.target_name;
                break;
        }

        this.group_select_open = false;
    }

    /**
     * 일정 색상 변경 창 열기
     */
    colorSelectListOpen() : void {
        this.color_select_open = true;
    }

    /**
     * 일정 색상 변경
     */
    changeColor(e : Event, color : string, colorMode : string) : void {
        this.event.event_data.color = color;
        this.colorToneMode          = colorMode;
        this.color_select_open      = false;
    }

    /**
     * 시작 시간 변경 창 열기
     */
    timeFromListOpen() : void {
        this.time_from_open = true;
    }

    /**
     * 종료 시간 변경 창 열기
     */
    timeToListOpen() : void {
        this.time_to_open = true;
    }

    /**
     * 일정 간편 작성 닫기
     */
    eventShortCreateClose() : void {
        this.doSetShowEventShortCreate(false);
    }

    /**
     * 일정 간편 작성 저장
     */
    async eventShortCreateSave() : Promise<void> {

        if( await this.checkvalid() == false){
            return;
        }

        // recurrence_end를 end와 동일하게 맞춘다
        this.event.event_data.schedule_date.recurrence_end = new Date(this.event.event_data.schedule_date.end);

        // date_term이 1일 이상 차이날때 연속일정으로 만든다
        this.event.event_data.schedule_date.isContinuos = ( this.date_term > 0 );
        
        // 종료일이 0시 0분일때 23:59:59로 세팅
        if( new Date(this.event.event_data.schedule_date.end).getHours() == 0 && new Date(this.event.event_data.schedule_date.end).getMinutes() == 0 ) {
            const end : Date = new Date(this.event.event_data.schedule_date.end);
            end.setDate(end.getDate() - 1);
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);

            this.event.event_data.schedule_date.end = end;
        }

        // 투표 & 참석 세팅
        this.event.event_data.vote   = [];
        this.event.event_data.attend = false;

        // 노트 & 메모 세팅(trim)
        this.event.event_data.note = this.event.event_data.note == null ? "" : this.event.event_data.note.trim();
        this.event.event_data.memo = "";

        // owner_id 구하기
        const owner_id : number | undefined = this.event.event_type == OWNER_TYPE.PERSONAL 
                                              ? this.event.event_data.event_owner_id
                                              : this.event.event_type == OWNER_TYPE.GROUP
                                                ? this.event.group_id
                                                : this.event.team_id;

        this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${this.event.event_type}/${owner_id}`, API_METHOD.POST, this.event.event_data)
            .then((response) => {
                // console.log(JSON.stringify(response));

                // 개인 캘린더에서 일반 일정 작성일때 마지막에 생성한 그룹&팀 기억
                if( this.scope == OWNER_TYPE.PERSONAL && this.event.event_sub_type == EVENT_SUB_TYPE.SCHEDULE ) {
                    local_storage_info.last_event_created_scope = {
                        "scope" : this.event.event_type,
                        "scope_id" : owner_id
                    }
                    hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info)); 
                }

                this.doSetShowEventShortCreate(false); 
                this.schedule_search_config.update_tag = new Date().getTime(); // update_tag 최신화로 재조회

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 일정 상세 작성 페이지로 이동
     */
    eventDetailCreate() : void {
        this.doSetShowEventShortCreate(false);
        this.doSetEvent(this.event);
        this.doSetEventCrudType(CRUD_TYPE.CREATE);
        this.doSetIsMovedByShortCreate(true);
        this.hodu_router_push('/event');
    }

    async checkvalid() : Promise<boolean> {
        
        if( this.event.event_data.title.trim().length < 2 ){
            alert("일정 제목을 입력 해 주세요\n(최소 2자 이상 입력)");
            $('#simple_schedule_crate .title').addClass('wrong');
            return false;
        }

        if( this.event.event_data.title.trim().length < 2 ){
            alert("일정 제목은 100자 까지 입력이 가능합니다");
            $('#simple_schedule_crate .title').addClass('wrong');
            return false;
        }

        // 통과
        return true;
    }

    /**
     * 시작일 시간 변경
     */
    selectStartTimeHour(event) : void {
        const startHours : number = this.start_am_pm == "AM" && Number(event.target.value) == 12 
                                    ? 0 
                                    : (this.start_am_pm == "AM" && Number(event.target.value) != 12) || (this.start_am_pm == "PM" && Number(event.target.value) == 12)
                                    ? Number(event.target.value)
                                    : Number(event.target.value) + 12;

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        start.setHours(startHours)
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        // 23시 일때
        if( start.getHours() == 23 ){
            
            // 50분인 경우
            if( start.getMinutes() == 50 ){
               end.setTime(start.getTime());
               end.setMinutes(start.getMinutes() + 10);

            // 0~40분 인 경우
            } else {
               end.setTime(start.getTime());
               end.setMinutes(50);
            }
           
        // 23시가 아니라면 + 1시간
        } else {
            end.setTime(start.getTime());
            end.setHours(start.getHours() + 1);
            end.setMinutes(start.getMinutes());
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        if( this.date_term < 1 ) { this.event.event_data.schedule_date.end = end; }

        this.makeDateString();
    }

    /**
     * 시작일 분 변경
     */
    selectStartTimeMin(event) : void {
        const start : Date = new Date(this.event.event_data.schedule_date.start);
        start.setMinutes(Number(event.target.value))
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        // 23시 일때
        if( start.getHours() == 23 ){
            
            // 50분인 경우
            if( start.getMinutes() == 50 ){
               end.setTime(start.getTime());
               end.setMinutes(start.getMinutes() + 10);

            // 0~40분 인 경우
            } else {
               end.setTime(start.getTime());
               end.setMinutes(50);
            }
           
        // 23시가 아니라면 + 1시간
        } else {
            end.setTime(start.getTime());
            end.setHours(start.getHours() + 1);
            end.setMinutes(start.getMinutes());
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        if( this.date_term < 1 ) { this.event.event_data.schedule_date.end = end; }

        this.makeDateString();
    }

    /**
     * 시작일 오전 오후 변경
     */
    selectStartTimeAmPm(event) : void {

        // 이미 같은 값이라면 return
        if( this.start_am_pm == event.target.value ){
            return;
        }

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        switch( event.target.value ){
            case "AM":
                start.setHours(start.getHours() - 12);
                break;
            
            case "PM":
                start.setHours(start.getHours() + 12);
                break;
        }
        
        // 23시 일때
        if( start.getHours() == 23 ){
            
            // 50분인 경우
            if( start.getMinutes() == 50 ){
               end.setTime(start.getTime());
               end.setMinutes(start.getMinutes() + 10);

            // 0~40분 인 경우
            } else {
               end.setTime(start.getTime());
               end.setMinutes(50);
            }
           
        // 23시가 아니라면 + 1시간
        } else {
            end.setTime(start.getTime());
            end.setHours(start.getHours() + 1);
            end.setMinutes(start.getMinutes());
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        if( this.date_term < 1 ) { this.event.event_data.schedule_date.end = end; }

        this.makeDateString();
    }

    /**
     * 종료일 시간 변경
     */
    selectEndTimeHour(event) : void {
        const endHours : number = this.end_am_pm == "AM" && Number(event.target.value) == 12 
                                  ? 0 
                                  : (this.end_am_pm == "AM" && Number(event.target.value) != 12) || (this.end_am_pm == "PM" && Number(event.target.value) == 12)
                                    ? Number(event.target.value)
                                    : Number(event.target.value) + 12;

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 
        end.setHours(endHours);
        
        // 완벽히 같은 시간은 10분을 더해준다
        if( start.getTime() == end.getTime() ) {
            end.setMinutes(end.getMinutes() + 10);

        // 종료일이 시작일보다 작다면 alert
        } else if ( start.getTime() > end.getTime() ) {
            alert("일정 종료시간을 확인하세요");
            return;
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        this.event.event_data.schedule_date.end   = end;

        this.makeDateString();
    }

    /**
     * 종료일 분 변경
     */
    selectEndTimeMin(event) : void {
        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 
        end.setMinutes(Number(event.target.value));

        // 완벽히 같은 시간은 10분을 더해준다
        if( start.getTime() == end.getTime() ) {
            end.setMinutes(end.getMinutes() + 10);

        // 종료일이 시작일보다 작다면 alert
        } else if ( start.getTime() > end.getTime() ) {
            alert("일정 종료시간을 확인하세요");
            return;
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        this.event.event_data.schedule_date.end   = end;

        this.makeDateString();
    }

    /**
     * 종료일 오전 오후 변경
     */
    selectEndTimeAmPm(event) : void {

        // 이미 같은 값이라면 return
        if( this.end_am_pm == event.target.value ){
            return;
        }

        const start : Date = new Date(this.event.event_data.schedule_date.start);
        const end   : Date = new Date(this.event.event_data.schedule_date.end); 

        switch( event.target.value ){
            case "AM":
                end.setHours(end.getHours() - 12);
                break;
            
            case "PM":
                end.setHours(end.getHours() + 12);
                break;
        }

        // 완벽히 같은 시간은 10분을 더해준다
        if( start.getTime() == end.getTime() ) {
            end.setMinutes(end.getMinutes() + 10);

        // 종료일이 시작일보다 작다면 alert
        } else if ( start.getTime() > end.getTime() ) {
            alert("일정 종료시간을 확인하세요");
            return;
        }

        // 날짜 세팅
        this.event.event_data.schedule_date.start = start;
        this.event.event_data.schedule_date.end   = end;

        this.makeDateString();
    }

    /**
     * 제목 변경시 마다 wrong 있을시에 삭제
     */
    @Watch('event.event_data.title')
    watchTitle() : void {
        if( $('#simple_schedule_crate .title').hasClass('wrong') == true ){
            $('#simple_schedule_crate .title').removeClass('wrong');
        }
    }

}

