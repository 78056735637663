import Vue from "vue";
import VueI18n from 'vue-i18n';

import messages from './messages';

Vue.use(VueI18n);

let locale = "ko";

try {
    let type = navigator.appName;
    let country_code = 'ko-KR';
    if (type == 'Netscape') {
        country_code = navigator.language;
    } else {
        country_code = navigator['userLanguage'];
    }
    
    if( country_code == 'ko-KR' ) {
        locale = 'ko';
    }
    else {
        locale = "en";
    }

} catch(e){
    locale = 'ko';
}

const i18n = new VueI18n({
    locale: locale,
    messages : messages
});

export default i18n;