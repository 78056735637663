
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import moment from 'moment';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_modal_info, hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class DoctorTimeSettingModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDoctorTimeSettingModalInfo ?: (params : hodu_doc_modal_info.DoctorTimeSettingModalInfo) => void;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State doctor_time_setting_modal_info !: hodu_doc_modal_info.DoctorTimeSettingModalInfo;

    picker_start_date : Date = moment(new Date()).set("hours", 15).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate();
    picker_end_date   : Date = new Date("2050-12-31 14:59:59Z");

    mounted() : void {
        const vue = this;

        // $(".checkDiv a").click(function(){
        //     var getIndex = $(this).index();
        //     $(this).toggleClass("on");
        //     if (getIndex == 2) {
        //             $(this).siblings().removeClass("on");
        //     } else {
                
                
        //     }
        // });

        // jquery-ui datepicker 옵션
        const datepicker_option = {
            inline: false,
            minDate : new Date(),
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText);
                inst.input.val(mnt.format('YYYY.MM.DD'));
                
                // 시작일 변경
                if( id == 'doctor_create_start_date' ) {
                    vue.picker_start_date = mnt.toDate();
                } 

                // 종료일 변경
                else if( id == 'doctor_create_end_date' ) {
                    vue.picker_end_date = mnt.toDate();
                }

            },
        }
        // @ts-ignore
        $('#doctor_create_start_date').datepicker(datepicker_option);

        // @ts-ignore
        $('#doctor_create_end_date').datepicker(datepicker_option);
    }

    // getPickerStartEndDate(isStart: boolean): string {
    //     if ( this.doctor_time_setting_modal_info == null ) {
    //         return '';
    //     }

    //     if (isStart) {
    //         if ( this.doctor_time_setting_modal_info.is_create_work_time ) {
    //             this.getYMDstr(this.picker_start_date)
    //         }else{
    //             this.getYMDstr(this.doctor_time_setting_modal_info.end_date)
    //         }

    //     }

        
    //      : ''
    // }

    getYMDstr(po_date: Date) : string {
        return moment(po_date).format("YYYY.MM.DD")
    }

    onClickTime(dowIndex: string | number, change_work_code : string) : void {
        if (this.doctor_time_setting_modal_info.timetable == null) { return; }
        const index = Number(dowIndex);
        let origin_work_code : string = this.doctor_time_setting_modal_info.timetable.list[index].work_code;

        if (change_work_code == "OFF") {
            this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.OFF
            return;
        }
        
        if (origin_work_code == hodu_doc_enum.doctor_work_code.AM) {
            switch (change_work_code) {
                case "PM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.FULL_TIME
                    break;
                case "AM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.OFF
                    break;

            }
            return;
        }

        if (origin_work_code == hodu_doc_enum.doctor_work_code.PM) {
            switch (change_work_code) {
                case "PM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.OFF
                    break;
                case "AM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.FULL_TIME
                    break;

            }
            return;
        }

        if (origin_work_code == hodu_doc_enum.doctor_work_code.FULL_TIME) {
            switch (change_work_code) {
                case "PM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.AM
                    break;
                case "AM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.PM
                    break;

            }
            return;
        }

        if (origin_work_code == hodu_doc_enum.doctor_work_code.OFF) {
            switch (change_work_code) {
                case "PM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.PM
                    break;
                case "AM" :
                    this.doctor_time_setting_modal_info.timetable.list[index].work_code = hodu_doc_enum.doctor_work_code.AM
                    break;

            }
            return;
        }
    }

    /**
     * 표시될 영역이 해당 요일에 병원 스케줄의 오전/오후/종일/휴무 중 무엇인지 반환
     */
    isOn(dowIndex : number, div: string) : string {
        let   lb_value : string = "off";
        if ( this.doctor_time_setting_modal_info.timetable == null ) { return "" }
        const workCode : hodu_doc_enum.doctor_work_code = this.doctor_time_setting_modal_info.timetable.list[dowIndex].work_code;
        
        switch (div) {
            case "AM" :
                if ( workCode == hodu_doc_enum.doctor_work_code.FULL_TIME ) {
                    lb_value = "morn";
                } else if (workCode == hodu_doc_enum.doctor_work_code.AM ) {
                    lb_value = "on morn";
                } else {
                    lb_value = "off";
                }
                break;
            case "PM" :
                if ( workCode == hodu_doc_enum.doctor_work_code.FULL_TIME ) {
                    lb_value = "night";
                } else if ( workCode == hodu_doc_enum.doctor_work_code.PM ) {
                    lb_value = "on night";
                } else {
                    lb_value = "off";
                }
                break;
            case "OFF" :
                if ( workCode == hodu_doc_enum.doctor_work_code.OFF ) {
                    lb_value = "on off";
                } else {
                    lb_value = "off";
                }
                break;
        }
        
        return lb_value;
    }

    getDayNameChild(value: string | number) : string {
        return this.getDayName(Number(value));
    }

    saveTimetable() : void {
        if (this.doctor_time_setting_modal_info.timetable == null) { return; }
        
        if (this.doctor_time_setting_modal_info.is_create_work_time) {
            let temp_doctor_info : hodu_doc_object.doctor_info = {
                hospital_name       : "",
                department_code     : "",
                department_name     : "",
                doctor_code         : "",
                doctor_name         : "",
                doctor_detail       : {
                    color           : "",
                    position        : "",
                    license         : "",
                    career          : [],
                    profile_image   : ""
                },
                start_date          : moment(this.picker_start_date).set("hours",0).set("minutes",0).set("seconds",0).set("milliseconds",0).toDate(),
                end_date            : moment(this.picker_end_date).set("hours",14).set("minutes",59).set("seconds",59).set("milliseconds",0).toDate(),
                is_current_schedule : false,
                timetable_id        : "",
                monday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
                tuesday             : hodu_doc_enum.doctor_work_code.FULL_TIME,
                wednesday           : hodu_doc_enum.doctor_work_code.FULL_TIME,
                thursday            : hodu_doc_enum.doctor_work_code.FULL_TIME,
                friday              : hodu_doc_enum.doctor_work_code.FULL_TIME,
                saturday            : hodu_doc_enum.doctor_work_code.OFF,
                sunday              : hodu_doc_enum.doctor_work_code.OFF
            }
            window["createTimetable"](this.doctor_time_setting_modal_info.timetable.list, temp_doctor_info)
            
        }else{
            //! 의사 조회한 뒤 근무표 수정할 때
            if ( this.doctor_time_setting_modal_info.timetable.timetable_id.length > 0 ) {
                window["updateTimetable"](this.doctor_time_setting_modal_info.timetable.list, this.doctor_time_setting_modal_info.timetable.timetable_id)
            }
            //! 의사 생성 화면일 때 
            else {
                window["createTimetable"](this.doctor_time_setting_modal_info.timetable.list)
            }
        }
        this.close();
    }

    deleteTimetable() : void {

    }

    close() : void {
        if( this.doSetDoctorTimeSettingModalInfo ) {
            this.doSetDoctorTimeSettingModalInfo({
                "show_modal"            : false,
                "is_create_work_time"   : false,
                "start_date"            : new Date(),
                "end_date"              : new Date(),
                "timetable"             : null
            });
        }
    }
}
