
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HomeVisitantInfoModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_visitant_info_modal_info !: hodu_home_modal_info.HomeVisitantInfoModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeVisitantInfoModalInfo ?: (params : hodu_home_modal_info.HomeVisitantInfoModalInfo) => void;

    mounted() : void {
        
    }

    /**
     * 시간 텍스트 반환
     */
    getTimeText(car : any) : string {

        const start_date = new Date(new Date(car.start_date));
        const end_date = new Date(car.end_date);
        
        const start = `${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;

        // 포맷 (같은 년월은 표시하지 않는다)
        let end_format = 'YYYY.MM.DD';
        if( start_date.getFullYear() == end_date.getFullYear() ) {
            end_format = 'MM.DD';

            if( start_date.getMonth() == end_date.getMonth() ) {
                end_format = 'DD';

                if( start_date.getDate() == end_date.getDate() ) {
                    end_format = '';
                }
            }
        }

        const end   = `${this.hodu_date_to_format_string(end_date, end_format)} ${this.getDayOfWeekByDate(end_date)}`;

        // return `${start}${end_format == '' ? '' : ` ~ ${end}`}`;
        return start;
    }
    
    closeModal() : void {
        if( this.doSetHomeVisitantInfoModalInfo == null ) { return; }
        
        this.doSetHomeVisitantInfoModalInfo({
            show_modal : false
        })
    }

}
