
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_doc_object, hodu_doc_modal_info } from '@/model/hodudoc';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class AppointmentAvailableListRight extends Mixins(VueHoduCommon) {

    /**
     * 의사가 한 명이라도 존재하는 진료과만 반환
     */
    get computedDoctorExistDepartment() : hodu_doc_object.department_info[] {

        let doctor_exist_department : hodu_doc_object.department_info[] = [];

        if( this.department_info != null && this.doctor_info != null ) {

            for( const department of this.department_info ) {

                if( this.doctor_info.filter(item => item.department_code == department.department_code).length > 0 ) {
                    doctor_exist_department.push(department);
                }

            }

        }

        return doctor_exist_department;
    }

    /**
     * 선택된 진료과의 의사만 반환
     */
    get computedDepartmentDoctor() : hodu_doc_object.doctor_info[] {

        let department_doctor : hodu_doc_object.doctor_info[] = [];

        if( this.selected_department != null && this.doctor_info != null ) {
            const department_doctor_temp = this.doctor_info.filter(item => this.selected_department ? item.department_code == this.selected_department.department_code : false);
            department_doctor = department_doctor.concat(department_doctor_temp);
        }

        return department_doctor;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAppointmentCreateModalInfo ?: (params : hodu_doc_modal_info.AppointmentCreateModalInfo) => void;

    @Prop() event_bus !: Vue;
    @Prop() max_date !: Date;

    doctor_list_div_style : string = "";
    selected_department : hodu_doc_object.department_info | null = null;
    selected_doctor : hodu_doc_object.doctor_info | null = null;
    selected_date : Date = new Date();
    current_page : number = 0;
    is_page_end : boolean = false;
    is_scroll : boolean = false;

    appointment_avaiable_promise_list : Promise<void>[] = [];
    available_list_temp : hodu_doc_object.appointment_available_time_info_list[] = [];
    available_list      : hodu_doc_object.appointment_available_time_info_list[] = [];
    
    mounted() : void {
        if( this.event_bus != null ) { 
            this.event_bus.$on('rightAvailableListResize', this.handleResize);
            this.event_bus.$on('defaultDataInit', this.defaultDataInit); 
        }

        // 재조회용
        window['rightSelectAvailableList'] = this.rightSelectAvailableList;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        if( this.doctor_info == null ) { return; }
        const filtered_doctor_list : hodu_doc_object.doctor_info[] = this.doctor_info.filter(item => item.department_code == (this.selected_department ? this.selected_department.department_code : ''));
        const doctor_div_with : number = (filtered_doctor_list.length * 270) + (filtered_doctor_list.length - 1); // 의사 + 구분선 (271d - 1)로 식 변경 가능하지만 이해하기 쉽게 내버려둠
        this.doctor_list_div_style = `width : ${doctor_div_with}px !important;`;

        // @ts-ignore
        $('#doctor_list_scroll').mCustomScrollbar({
            axis : 'x',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
        });

        this.$nextTick(() => {
            const title_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
            const doc_dets_height : number | undefined = $('.docDets').outerHeight();
            const simple_ul_descript_height : number | undefined = $('.simpleUlDescript').outerHeight();

            // @ts-ignore
            $('#available_list_right_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_height ? title_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (doc_dets_height ? doc_dets_height : 0)
                                            - (simple_ul_descript_height ? simple_ul_descript_height : 0),
                callbacks : {
                    whileScrolling: () => {
                        const top_px : number = Math.abs(Number($('#available_list_right_scroll').find('.mCSB_container').css('top').replace(/px/ig, '')));
                        this.is_scroll = top_px > 0; 
                    }
                }
            });
        });
    }

    /**
     * 해당 날짜의 예약 가능, 불가능 여부 정보 조회
     */
    async getAvailableAppointmentTime(date : Date) : Promise<void> {

        if( this.selected_department == null || this.selected_doctor == null ) { return; } 

        date = date instanceof Date ? date : new Date(date);

        const start = moment(date).format('YYYY-MM-DD');              // 조회일
        const department_code = this.selected_doctor.department_code; // 진료과 코드
        const doctor_code = this.selected_doctor.doctor_code;         // 의사 코드

        if( doctor_code == null || doctor_code.length < 1 ) { return; }

        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/availability?start=${start}&department_code=${department_code}&doctor_code=${doctor_code}`, API_METHOD.GET)
            .then((response) => {
                const available_list : hodu_doc_object.appointment_available_time_info[] = response.data.data;
                const hour_list : number[] = [];

                for( const available of available_list ) {
                    if( hour_list.indexOf(new Date(available.original_timetable).getHours()) > -1 ) { continue; }
                    hour_list.push(new Date(available.original_timetable).getHours());
                }

                this.available_list_temp.push({
                    "date" : date,
                    "available_time_info" : available_list,
                    "hour_list" : hour_list 
                });
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false); 
            });
    }

    /**
     * 기본 정보 init
     */
    async defaultDataInit() : Promise<void> {
        if( this.computedDoctorExistDepartment.length > 0 ) { this.selected_department = this.computedDoctorExistDepartment[0]; }

        // 예약 가능 시간 API 조회 병렬 처리
        this.selectAvailableList();

        this.$nextTick(() => { this.setScroll(); });
    }

    /**
     * 해당 의사의 available_list 최초 조회
     */
    selectAvailableList(page : number = 0, is_first_doctor : boolean = true) : void {
        if( is_first_doctor == true && this.computedDepartmentDoctor.length > 0 ) { this.selected_doctor = this.computedDepartmentDoctor[0]; }
    
        this.appointment_avaiable_promise_list.splice(0, this.appointment_avaiable_promise_list.length);
        this.available_list_temp.splice(0, this.available_list_temp.length);
        
        // 첫 페이지를 조회하는 경우
        if( page == 0 ) {
            this.current_page = 0; 
            this.is_page_end = false;
            this.available_list.splice(0, this.available_list.length); 
        }

        const selected_date_month_max_date : number = moment(this.selected_date).add('month', 1).set('date', 0).toDate().getDate();

        for( let i = (7 * page); i < (7 * page) + 7; i++ ) {
            const target_date = moment(this.selected_date).set('date', this.selected_date.getDate() + i).toDate();

            // max_date 와 같거나 이후의 날짜인 경우, 해당 월 보다 큰 경우 제외하고, 해당 달의 마지막 날이라면  break;
            if( ( this.max_date.getFullYear()   == target_date.getFullYear() && 
                  this.max_date.getMonth()      == target_date.getMonth()    &&
                  this.max_date.getDate()        < target_date.getDate()  )  ||
                  this.selected_date.getMonth() != target_date.getMonth() ) {
                
                this.is_page_end = true;
                break;
            }

            // 해당 달의 마지막 날짜까지 갔다면 page_end
            if( selected_date_month_max_date == target_date.getDate() ) { this.is_page_end = true; }

            this.appointment_avaiable_promise_list.push(this.getAvailableAppointmentTime(target_date));
        }

        Promise.all(this.appointment_avaiable_promise_list)
            .then(async() => {

                // 날짜 순서대로 sort
                await this.available_list_temp.sort((item1, item2) : number => {
                    
                    if( item1.date.getTime() < item2.date.getTime() ) {
                        return -1;
                    }

                    else if( item1.date.getTime() > item2.date.getTime() ) {
                        return 1;
                    }

                    return 0;
                });
                
                // 리스트에 합치고 temp, promise 리스트는 전부 제거
                this.available_list = this.available_list.concat(JSON.parse(JSON.stringify(this.available_list_temp)));
                this.appointment_avaiable_promise_list.splice(0, this.appointment_avaiable_promise_list.length);
                this.available_list_temp.splice(0, this.available_list_temp.length);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 몇분당 몇명까지 진료가능 한지 텍스트 반환 (오늘 기준으로 밖에 못가져온다)
     */
    getHospitalSettingString() : string {

        if( this.hospital_setting_info == null ) { return ''; }

        const day_of_week_string : string = String(new Date().getDay());

        // day_of_week의 타입은 number지만 string으로 데이터가 넘어와서 이렇게 사용
        const settings : hodu_doc_object.hospital_setting_info[] = this.hospital_setting_info.filter(item => String(item.day_of_week)    == day_of_week_string   &&
                                                                                                    new Date(item.start_date).getTime() <= new Date().getTime() &&
                                                                                                    new Date(item.end_date).getTime()   >= new Date().getTime() )

        if( settings.length < 1 ) { return ''; }

        const setting : hodu_doc_object.hospital_setting_info = settings[0];

        return `${setting.reservation_term}분 / ${setting.reservation_term_by_people}명 가능 (오늘 기준)`;
    }

    /**
     * 의사 사진 URL 반환
     */
    getDoctorBackgroundImage(doc : hodu_doc_object.doctor_info) : string {

        if( doc.doctor_detail.profile_image == null || doc.doctor_detail.profile_image.length < 1 ) {
            return `url(${require("@/assets/images/contents/im_photoB.gif")})`;
        }

        return `url('app_images/${doc.doctor_detail.profile_image}')`;

    }

    /**
     * 진료과 변경
     */
    changeDepartment(value : string) : void {

        for ( const department of this.computedDoctorExistDepartment ) {
            if( department.department_code == value ) { 
                this.selected_department = department;
                this.current_page = 0;
                this.selectAvailableList();
                break;
            } 
        }
    }

    /**
     * 의사 변경
     */
    selectDoctor(doctor : hodu_doc_object.doctor_info) : void {

        // alert(JSON.stringify(doctor));

        this.selected_doctor = JSON.parse(JSON.stringify(doctor));
        this.current_page = 0;
        this.selectAvailableList(this.current_page, false);
    }

    /**
     * 예약 등록 모달
     */
    showAppointmentCreateModal(available : hodu_doc_object.appointment_available_time_info) : void {
        if( available.available == false ) { return; }

        if( this.doSetAppointmentCreateModalInfo ) { 

            this.doSetAppointmentCreateModalInfo({ 
                "show_modal" : true,
                "is_create_one_patient" : false,
                "department_code"       : available.department_code,
                "doctor_code"           : available.doctor_code,
                "original_timetable"    : new Date(available.original_timetable), 
            }); 
        } 
    }

    /**
     * 재 조회
     */
    rightSelectAvailableList() : void {
        for( let i = 0; i <= this.current_page; i++ ) {
            this.selectAvailableList(i, false);
        }
    }

    /**
     * 왼쪽 끝 주간 여부
     */
    isLeftEnd() : boolean {
        return moment(this.selected_date).month() == moment().month();
    }

    /**
     * 오른쪽 끝 주간 여부
     */
    isRightEnd() : boolean {
        return moment(this.selected_date).month() == moment(this.max_date).month();
    }

    /**
     * 날짜 선택
     */
    selectDate(date : Date) : void {
        this.selected_date = date;
        this.current_page = 0;
        this.selectAvailableList(this.current_page, false);
    }

    /**
     * 일 단위 offset 값 만큼 날짜 선택
     */
    selectDateByOffset(date_offset : number) : void {
        
        // 이전 달로 이동할 때 이미 왼쪽 끝이라면 무시 
        if( date_offset < 0 && this.isLeftEnd() ) { return; }

        // 다음 달로 이동할 때 이미 오른쪽 끝이라면 무시
        if( date_offset > 0 && this.isRightEnd() ) { return; }

        // 날짜 이동
        let move_date : Date = moment(this.selected_date).add("month", date_offset)
                                                         .set("date", 1)
                                                         .set("hour", 23)
                                                         .set("minute", 59)
                                                         .set("second", 59)
                                                         .set("millisecond", 999)
                                                         .toDate();

        if( move_date.getTime() < new Date().getTime() ) { move_date = new Date(); }
        
        this.selected_date = move_date;
        this.current_page = 0;
        this.selectAvailableList(this.current_page, false);
    }
    
    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#doctor_list_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#available_list_right_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
