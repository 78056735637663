import {GetterTree, MutationTree , ActionTree} from "vuex";

export const namespaced = true;

import { hodu_doc_object } from "@/model/hodudoc"
import { t_event } from '@/model/event';

/**
 * state interface
 */
export interface HoduDocInfoState {
    hospital_info         : hodu_doc_object.hospital_info | null;
    hospital_setting_info : hodu_doc_object.hospital_setting_info[] | null;
    department_info       : hodu_doc_object.department_info[] | null;
    doctor_info           : hodu_doc_object.doctor_info[] | null;
    patient_info          : hodu_doc_object.patient_info[] | null;

    appointment_detail_info : AppointmentDetailInfo; 
    
    doctor_and_department_tab : "DOCTOR" | "DEPARTMENT";
    patient_tab               : "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX";
    hospital_setting_tab      : "BASIC" | "TIME" | "HOLIDAY" | "OFF";
}

export interface AppointmentDetailInfo {
    event : t_event | null;
    is_patient : boolean;
}

/**
 * state
 */
export const state: HoduDocInfoState = {
    hospital_info : null,        
    hospital_setting_info : null,
    department_info : null,      
    doctor_info : null,          
    patient_info : null,

    appointment_detail_info : {
        event : null,
        is_patient : false,
    },
    
    doctor_and_department_tab : "DOCTOR",
    patient_tab : "LIST",
    hospital_setting_tab : "BASIC",
}

/**
 * getters
 */
export const getters: GetterTree<HoduDocInfoState, any> = {
    
    getHospitalInfo: (state) => {
        return state.hospital_info;
    },

    getHospitalSettingInfo: (state) => {
        return state.hospital_setting_info;
    },

    getDepartmentInfo: (state) => {
        return state.department_info;
    },

    getDoctorInfo: (state) => {
        return state.doctor_info;
    },

    getPatientInfo: (state) => {
        return state.patient_info;
    },

    getAppointmentDetailInfo: (state) => {
        return state.appointment_detail_info;
    },

    getDoctorAndDepartmentTab: (state) => {
        return state.doctor_and_department_tab;
    },

    getPatientTab: (state) => {
        return state.patient_tab;
    },

    getHospitalSettingTab: (state) => {
        return state.hospital_setting_tab;
    },
}

/**
 * actions
 */
export const actions: ActionTree<HoduDocInfoState, HoduDocInfoState> = {
    
    doSetHoduDocInfo: ({commit, state}, payload : HoduDocInfoState) => {
        console.log(`mutations.doSetHoduDocInfo ${JSON.stringify(payload)}`);
        
        setHospitalInfo(payload.hospital_info);
        setHospitalSettingInfo(payload.hospital_setting_info);
        setDepartmentInfo(payload.department_info);
        setDoctorInfo(payload.doctor_info);
        setPatientInfo(payload.patient_info);

        state.doctor_and_department_tab = payload.doctor_and_department_tab;
        state.patient_tab               = payload.patient_tab;
        state.hospital_setting_tab      = payload.hospital_setting_tab;

        state.appointment_detail_info.event = payload.appointment_detail_info.event;
        state.appointment_detail_info.is_patient = payload.appointment_detail_info.is_patient;

        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    },

    doSetHospitalInfo: ({commit, state}, hospital_info : hodu_doc_object.hospital_info) => {
        console.log("actions.doSetHospitalInfo");
        setHospitalInfo(hospital_info);
        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }

        // commit("setHospitalInfo", hospital_info);
    },

    doSetHospitalSettingInfo: ({commit, state}, hospital_setting_info : hodu_doc_object.hospital_setting_info[]) => {
        console.log("actions.doSetHospitalSettingInfo");
        setHospitalSettingInfo(hospital_setting_info);
        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }

        // commit("setHospitalSettingInfo", hospital_setting_info);
    },

    doSetDepartmentInfo: ({commit, state}, department_info : hodu_doc_object.department_info[]) => {
        console.log("actions.doSetDepartmentInfo");
        setDepartmentInfo(department_info);
        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }

        // commit("setDepartmentInfo", department_info);
    },

    doSetDoctorInfo: ({commit, state}, doctor_info : hodu_doc_object.doctor_info[]) => {
        console.log("actions.doSetDoctorInfo");
        setDoctorInfo(doctor_info);
        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }

        // commit("setDoctorInfo", doctor_info);
    },

    doSetPatientInfo: ({commit, state}, patient_info : hodu_doc_object.patient_info[]) => {
        console.log("actions.doSetPatientInfo");
        setPatientInfo(patient_info);
        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
        
        // commit("setPatientInfo", patient_info);
    },

    doSetAppointmentDetailInfo: ({commit, state}, appointment_detail_info : AppointmentDetailInfo) => {
        console.log("actions.doSetAppointmentDetailInfo");
        commit("setAppointmentDetailInfo", appointment_detail_info);
    },

    doSetDoctorAndDepartmentTab: ({commit, state}, doctor_and_department_tab : "DOCTOR" | "DEPARTMENT") => {
        console.log("actions.doSetDoctorAndDepartmentTab");
        commit("setDoctorAndDepartmentTab", doctor_and_department_tab);
    },

    doSetPatientTab: ({commit, state}, patient_tab : "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX") => {
        console.log("actions.doSetPatientTab");
        commit("setPatientTab", patient_tab);
    },

    doSetHospitalSettingTab: ({commit, state}, hospital_setting_tab : "BASIC" | "TIME" | "HOLIDAY" | "OFF") => {
        console.log("actions.doSetHospitalSettingTab");
        commit("setHospitalSettingTab", hospital_setting_tab);
    },

}

/**
 * mutations
 */
export const mutations: MutationTree<HoduDocInfoState> = {

    // setHospitalInfo: (state, payload : hodu_doc_object.hospital_info) => {
    //     console.log(`mutations.setHospitalInfo ${JSON.stringify(payload)}`);

    //     setHospitalInfo(payload);

    //     if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    // },

    // setHospitalSettingInfo: (state, payload : hodu_doc_object.hospital_setting_info[]) => {
    //     console.log(`mutations.setHospitalSettingInfo ${JSON.stringify(payload)}`);

    //     setHospitalSettingInfo(payload);

    //     if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    // },

    // setDepartmentInfo: (state, payload : hodu_doc_object.department_info[]) => {
    //     console.log(`mutations.setDepartmentInfo ${JSON.stringify(payload)}`);

    //     setDepartmentInfo(payload);

    //     if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    // },

    // setDoctorInfo: (state, payload : hodu_doc_object.doctor_info[]) => {
    //     console.log(`mutations.setDoctorInfo ${JSON.stringify(payload)}`);

    //     setDoctorInfo(payload);

    //     if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    // },

    // setPatientInfo: (state, payload : hodu_doc_object.patient_info[]) => {
    //     console.log(`mutations.setPatientInfo ${JSON.stringify(payload)}`);

    //     setPatientInfo(payload);

    //     if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    // },

    setAppointmentDetailInfo: (state, payload : AppointmentDetailInfo) => {
        console.log(`mutations.setDoctorAndDepartmentTab ${JSON.stringify(payload)}`);

        state.appointment_detail_info.event      = JSON.parse(JSON.stringify(payload.event));
        state.appointment_detail_info.is_patient = payload.is_patient;

        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    },

    setDoctorAndDepartmentTab: (state, payload : "DOCTOR" | "DEPARTMENT") => {
        console.log(`mutations.setDoctorAndDepartmentTab ${JSON.stringify(payload)}`);

        state.doctor_and_department_tab = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    },

    setPatientTab: (state, payload : "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX") => {
        console.log(`mutations.setPatientTab ${JSON.stringify(payload)}`);

        state.patient_tab = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    },

    setHospitalSettingTab: (state, payload : "BASIC" | "TIME" | "HOLIDAY" | "OFF") => {
        console.log(`mutations.setHospitalSettingTab ${JSON.stringify(payload)}`);

        state.hospital_setting_tab = payload;

        if( sessionStorage != null ) { sessionStorage.setItem("hodu_doc_info", JSON.stringify(state)); }
    },

}

/**
 * 병원 정보 세팅
 * @param payload - hospital_info
 */
function setHospitalInfo(payload : hodu_doc_object.hospital_info | null) : void {
    
    // 둘 중 하나라도 비어있다면 오브젝트 째로 덮어 씌운다
    if( !state.hospital_info || !payload ) {
        state.hospital_info = payload;
        return;
    }

    state.hospital_info.biz_id   = payload.biz_id;
    state.hospital_info.group_id = payload.group_id;

    // group_ids
    state.hospital_info.group_ids.splice(0, state.hospital_info.group_ids.length);
    for( const group_id of payload.group_ids ) { state.hospital_info.group_ids.push(group_id); }

    // company_info
    state.hospital_info.company_info.name = payload.company_info.name;

    // contacts 둘 다 있을 때
    if( state.hospital_info.company_info.contacts && payload.company_info.contacts ) {
        state.hospital_info.company_info.contacts.name = payload.company_info.contacts.name;
        state.hospital_info.company_info.contacts.tel  = payload.company_info.contacts.tel;
    }

    // contacts 둘 중 하나라도 없을 시
    else { state.hospital_info.company_info.contacts = payload.company_info.contacts; }

    // departments 둘 다 있을 때
    if( state.hospital_info.company_info.departments && payload.company_info.departments ) {
        state.hospital_info.company_info.departments.splice(0, state.hospital_info.company_info.departments.length);
        for( const department of payload.company_info.departments ) {
            state.hospital_info.company_info.departments.push(department);
        }
    }

    // departments 둘 중 하나라도 없을 시
    else { state.hospital_info.company_info.departments = payload.company_info.departments; }

    // location 둘 다 있을 때
    if( state.hospital_info.company_info.location && payload.company_info.location ) {
        state.hospital_info.company_info.location.place         = payload.company_info.location.place;
        state.hospital_info.company_info.location.tel           = payload.company_info.location.tel;
        state.hospital_info.company_info.location.address       = payload.company_info.location.address;
        state.hospital_info.company_info.location.address_old   = payload.company_info.location.address_old;
        state.hospital_info.company_info.location.post_code     = payload.company_info.location.post_code;
        
        // geo 둘 다 있을 때
        if( state.hospital_info.company_info.location.geo && payload.company_info.location.geo ) {
            state.hospital_info.company_info.location.geo.latitude  = payload.company_info.location.geo.latitude;
            state.hospital_info.company_info.location.geo.longitude = payload.company_info.location.geo.longitude;
        }

        // geo 둘 중 하나라도 없을 시
        else { state.hospital_info.company_info.location.geo = payload.company_info.location.geo; }
        
    }
    
    else { state.hospital_info.company_info.location = payload.company_info.location; }

    // 이미지 둘 다 있을때
    if( state.hospital_info.company_info.images != null && payload.company_info.images != null ) {
        state.hospital_info.company_info.images.splice(0, state.hospital_info.company_info.images.length);
        for( const image of payload.company_info.images ) { state.hospital_info.company_info.images.push(image); }
    } 

    else {
        state.hospital_info.company_info.images = payload.company_info.images;
    }

    state.hospital_info.company_info.descript = payload.company_info.descript;
    state.hospital_info.company_info.web_url = payload.company_info.web_url;
    state.hospital_info.company_info.auto_apply_appointment = payload.company_info.auto_apply_appointment;

    // message_template 둘 다 있을 때
    if( state.hospital_info.company_info.message_template && payload.company_info.message_template ) {
        state.hospital_info.company_info.message_template.splice(0, state.hospital_info.company_info.message_template.length);
        for( const message_template of payload.company_info.message_template ) {
            state.hospital_info.company_info.message_template.push(message_template);
        }
    }

    // message_template 둘 중 하나라도 없을 시
    else { state.hospital_info.company_info.message_template = payload.company_info.message_template }

    state.hospital_info.expire_date       = payload.expire_date;
    state.hospital_info.biz_type          = payload.biz_type;
    state.hospital_info.is_cert           = payload.is_cert;
    state.hospital_info.meta              = payload.meta;
    state.hospital_info.audit_created     = payload.audit_created;
    state.hospital_info.audit_modified    = payload.audit_modified;
    state.hospital_info.audit_deleted     = payload.audit_deleted;
    state.hospital_info.audit_delete_flag = payload.audit_delete_flag;
    state.hospital_info.audit_user_id     = payload.audit_user_id;
}

/**
 * 병원 세팅 정보 세팅
 * @param payload - hospital_setting_info
 */
function setHospitalSettingInfo(payload : hodu_doc_object.hospital_setting_info[] | null) : void {
    
    // 둘 중 하나라도 비어있다면 오브젝트 째로 덮어 씌운다
    if( !state.hospital_setting_info || !payload ) {
        state.hospital_setting_info = payload;
        return;
    }

    state.hospital_setting_info.splice(0, state.hospital_setting_info.length);
    for( const setting_info of payload ) { state.hospital_setting_info.push(setting_info); }

    // state.hospital_setting_info.is_current_schedule        = payload.is_current_schedule;
    // state.hospital_setting_info.from_to                    = payload.from_to;
    // state.hospital_setting_info.day_of_week                = payload.day_of_week;
    // state.hospital_setting_info.start_am_time              = payload.start_am_time;
    // state.hospital_setting_info.end_am_time                = payload.end_am_time;
    // state.hospital_setting_info.start_pm_time              = payload.start_pm_time;
    // state.hospital_setting_info.end_pm_time                = payload.end_pm_time;
    // state.hospital_setting_info.summary                    = payload.summary;
    // state.hospital_setting_info.start_date                 = payload.start_date;
    // state.hospital_setting_info.end_date                   = payload.end_date;
    // state.hospital_setting_info.reservation_term           = payload.reservation_term;
    // state.hospital_setting_info.reservation_term_by_people = payload.reservation_term_by_people;

}

/**
 * 진료과 정보 세팅
 * @param payload - department_info
 */
function setDepartmentInfo(payload : hodu_doc_object.department_info[] | null) : void {
    
    // 둘 중 하나라도 비어있다면 오브젝트 째로 덮어 씌운다
    if( !state.department_info || !payload ) {
        state.department_info = payload;
        return;
    }

    state.department_info.splice(0, state.department_info.length);
    for( const department of payload ) { state.department_info.push(department); }

    // state.department_info.department_code = payload.department_code;
    // state.department_info.department_name = payload.department_name;

}

/**
 * 의사 정보 세팅
 * @param payload - doctor_info
 */
function setDoctorInfo(payload : hodu_doc_object.doctor_info[] | null) : void {
    
    // 둘 중 하나라도 비어있다면 오브젝트 째로 덮어 씌운다
    if( !state.doctor_info || !payload ) {
        state.doctor_info = payload;
        return;
    }

    state.doctor_info.splice(0, state.doctor_info.length);
    for( const doctor of payload ) { state.doctor_info.push(doctor); }

    // state.doctor_info.hospital_name   = payload.hospital_name;  
    // state.doctor_info.department_code = payload.department_code;
    // state.doctor_info.department_name = payload.department_name;
    // state.doctor_info.doctor_code     = payload.doctor_code;    
    // state.doctor_info.doctor_name     = payload.doctor_name;    
    // state.doctor_info.start_date      = payload.start_date;     
    // state.doctor_info.end_date        = payload.end_date;       
    // state.doctor_info.sunday          = payload.sunday;         
    // state.doctor_info.monday          = payload.monday;         
    // state.doctor_info.tuesday         = payload.tuesday;        
    // state.doctor_info.wednesday       = payload.wednesday;      
    // state.doctor_info.thursday        = payload.thursday;       
    // state.doctor_info.friday          = payload.friday;         
    // state.doctor_info.saturday        = payload.saturday;       
}

/**
 * 환자 정보 세팅
 * @param payload - patient_info
 */
function setPatientInfo(payload : hodu_doc_object.patient_info[] | null) : void {
    
    // 둘 중 하나라도 비어있다면 오브젝트 째로 덮어 씌운다
    if( !state.patient_info || !payload ) {
        state.patient_info = payload;
        return;
    }
    
    state.patient_info.splice(0, state.patient_info.length);
    for( const patient of payload ) { state.patient_info.push(patient); }

    // state.patient_info.user_name         = payload.user_name;
    // state.patient_info.user_phone_number = payload.user_phone_number;
    // state.patient_info.user_email        = payload.user_email;
    // state.patient_info.nok_count         = payload.nok_count;
    
}

