import {GetterTree, MutationTree , ActionTree} from "vuex";

export const namespaced = true;

export interface PopupInfoState {
    show_popup : boolean;
}

export const state : PopupInfoState = {
    show_popup : false,
}

/**
 * getters
 */
export const getters: GetterTree<PopupInfoState, any> = {

    isShowPopup: (state) => {
        return state.show_popup;
    },

}

/**
 * actions
 */
export const actions: ActionTree<PopupInfoState, PopupInfoState> = {

    doSetShowPopup: ({commit, state}, show_popup : boolean) => {
        state.show_popup = show_popup;
    },
    
}