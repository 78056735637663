
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";
import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');

@Component({
   components: {

   }
}) export default class GroupApprove extends Mixins(VueHoduCommon) {
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id          !: number;
    @GroupInfo.State team_id           !: number;

    /**
     * 변수 선언부
     */
    list : any = [];
    original_list : any = [];
    is_allChack : boolean = false;

    approve_sort_target : string | null = null;
    approve_sort_direction : string = "ASC";

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() : Promise<void> {
        /* 화살표 클릭시 팀 보여주기 */
        $(".selectTitle").click(function(){
            $(this).siblings().toggleClass("on");
        });

        // 멤버 추가 버튼클릭시
		$("#inviteMate").click(function(){
			$(this).toggleClass("on");
			$(".inviteMateDiv").toggleClass("on");
        });
        
		// 멤버추가창 전송버튼클릭시
		$("#modalMemInvateBtn").click(function(){
			$(".inviteMateDiv").addClass("sent");	
        });
        
		// 멤버추가창 인풋에 wrong 빼기
		$(".inviteMateDiv input").click(function(){
			$(this).removeClass("wrong");
        });
        
		// 멤버추가창 닫기버튼
		$(".inviteMateDiv .btns .close").click(function(){
			$(".inviteMateDiv").removeClass("sent");
			$(".inviteMateDiv").removeClass("on");
			$("#inviteMate").removeClass("on");
        });
        
		// 멤버추가창 다른 멤버 초대
		$(".inviteMateDiv .btns .inviteMore").click(function(){
			$(".inviteMateDiv").removeClass("sent");
        });
        
        this.getApprov();
    }

    /**
     * 조회
     */
    async getApprov() : Promise<void> {
        this.list = [];
        this.is_allChack = false;

        await this.hodu_api_call(`api/v1/groupsweb/getApprov/${this.group_id}/${this.team_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.list = response.data.data.list;
                this.original_list = JSON.parse(JSON.stringify(this.list));
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }    

    /**
     * 전체선택 
     */
    checkAll(event) : void {
        this.is_allChack = event.target.checked;

        for (let i=0; i<this.list.length; i++) {
            this.list[i].checked = this.is_allChack;
        }
    }

    /**
     * 개별선택
     */
    checkRow(event, index) : void {
        this.list[index].checked = event.target.checked;

        if ( event.target.checked ) {
            this.is_allChack = true;

            for (let i=0; i<this.list.length; i++) {
                if ( !this.list[i].checked ) {
                    this.is_allChack = false;
                    break;
                }
            }
        }
        else {
            this.is_allChack = false;
        }
    }

    /**
     * 승인 거절
     */
    groupApprovSave(state) : void {
        let appr_group_team_name = "";
        let arr_approve : any = [];

        for (let i=0; i<this.list.length; i++) {
            let lo_data = this.list[i];

            if ( !this.list[i].checked ) {
                continue;
            }

            if ( !appr_group_team_name ) {
                appr_group_team_name = (this.team_id == 0 ? lo_data.info.group_name : lo_data.info.team_name);
            }

            let dic = {
                group_id        : lo_data.group_id,
                team_id         : (this.team_id == 0 ? 0 : lo_data.team_id),
                group_team_name : appr_group_team_name,
                user_id         : lo_data.user_id,
                appr_state      : state
            }

            arr_approve.push(dic);
        }

        if ( arr_approve.length < 1 ) {
            this.hodu_show_dialog("alert", "승인 또는 거절 할 사용자를 선택해주세요", ['확인']);
            return;
        }

        this.hodu_show_dialog("alert", `${state == 'A' ? '승인' : '거절'} 하시겠습니까?`, ['아니오', '예'], [
            () => {},
            () => {
                this.groupApprovSaveService(arr_approve);
            },
        ]);
    }

    groupApprovSaveService(arr) : void {
        if ( arr.length < 1 ) {
            return;
        }

        this.hodu_api_call(`api/v1/groups/updateApprov`, API_METHOD.PUT, {arrO_list: arr})
            .then(async(response) => {
                this.getApprov();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });        
    }

    /**
     * 이름 기준 정렬
     */
    sortName() : void {

        // 이미 NAME Sort 중이라면
        if( this.approve_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.approve_sort_direction ) {
                case "ASC":
                    this.approve_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.approve_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.approve_sort_target = "NAME";
            this.approve_sort_direction = "ASC";
        }

        if( this.approve_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * 연락처 기준 정렬
     */
    sortTel() : void {

        // 이미 TEL Sort 중이라면
        if( this.approve_sort_target == "TEL" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.approve_sort_direction ) {
                case "ASC":
                    this.approve_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.approve_sort_target = null;
                    break; 
            }
        }
        
        // TEL Sort가 아니였다면
        else {
            this.approve_sort_target = "TEL";
            this.approve_sort_direction = "ASC";
        }

        if( this.approve_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * 이메일 기준 정렬
     */
    sortEmail() : void {

        // 이미 EMAIL Sort 중이라면
        if( this.approve_sort_target == "EMAIL" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.approve_sort_direction ) {
                case "ASC":
                    this.approve_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.approve_sort_target = null;
                    break; 
            }
        }
        
        // EMAIL Sort가 아니였다면
        else {
            this.approve_sort_target = "EMAIL";
            this.approve_sort_direction = "ASC";
        }

        if( this.approve_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * 요청일 기준 정렬
     */
    sortRequestDate() : void {

        // 이미 REQUEST Sort 중이라면
        if( this.approve_sort_target == "REQUEST" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.approve_sort_direction ) {
                case "ASC":
                    this.approve_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.approve_sort_target = null;
                    break; 
            }
        }
        
        // REQUEST Sort가 아니였다면
        else {
            this.approve_sort_target = "REQUEST";
            this.approve_sort_direction = "ASC";
        }

        if( this.approve_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.list = JSON.parse(JSON.stringify(this.original_list));
    }

    /**
     * approve_list 정렬
     */
    listSort() : void {
        this.list.sort((o1, o2) : number => {
            // 이름 오름차순
            if( this.approve_sort_target == "NAME" && this.approve_sort_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 이름 내림차순
            if( this.approve_sort_target == "NAME" && this.approve_sort_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 전화번호 오름차순
            if( this.approve_sort_target == "TEL" && this.approve_sort_direction == "ASC" ) {
                if( o1.user_phone_number  > o2.user_phone_number ) { return  1; } 
                if( o1.user_phone_number  < o2.user_phone_number ) { return -1; } 
                if( o1.user_phone_number == o2.user_phone_number ) { return  0; }
            }

            // 전화번호 내림차순
            if( this.approve_sort_target == "TEL" && this.approve_sort_direction == "DESC" ) {
                if( o1.user_phone_number  > o2.user_phone_number ) { return -1; } 
                if( o1.user_phone_number  < o2.user_phone_number ) { return  1; } 
                if( o1.user_phone_number == o2.user_phone_number ) { return  0; }
            }

            // 이메일 오름차순
            if( this.approve_sort_target == "EMAIL" && this.approve_sort_direction == "ASC" ) {
                if( o1.user_email  > o2.user_email ) { return  1; } 
                if( o1.user_email  < o2.user_email ) { return -1; } 
                if( o1.user_email == o2.user_email ) { return  0; }
            }

            // 이메일 내림차순
            if( this.approve_sort_target == "EMAIL" && this.approve_sort_direction == "DESC" ) {
                if( o1.user_email  > o2.user_email ) { return -1; } 
                if( o1.user_email  < o2.user_email ) { return  1; } 
                if( o1.user_email == o2.user_email ) { return  0; }
            }

            // 요청일 오름차순
            if( this.approve_sort_target == "REQUEST" && this.approve_sort_direction == "ASC" ) {
                if( new Date(o1.audit_created).getTime()  > new Date(o2.audit_created).getTime() ) { return  1; } 
                if( new Date(o1.audit_created).getTime()  < new Date(o2.audit_created).getTime() ) { return -1; } 
                if( new Date(o1.audit_created).getTime() == new Date(o2.audit_created).getTime() ) { return  0; }
            }

            // 요청일 내림차순
            if( this.approve_sort_target == "REQUEST" && this.approve_sort_direction == "DESC" ) {
                if( new Date(o1.audit_created).getTime()  > new Date(o2.audit_created).getTime() ) { return -1; } 
                if( new Date(o1.audit_created).getTime()  < new Date(o2.audit_created).getTime() ) { return  1; } 
                if( new Date(o1.audit_created).getTime() == new Date(o2.audit_created).getTime() ) { return  0; }
            }

            return 0;
        });
    }

}
