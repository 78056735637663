
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { GROUP_TYPE, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const AttendanceInfo = namespace('AttendanceInfo');

const dateFormat = require('dateformat');

@Component({

}) 
export default class LeftDatePicker extends Mixins(VueHoduCommon) {
    
    /**
     * @CalendarInfo.Action
     */
    @CalendarInfo.Action doPickingLeftDatePicker ?: any;

    /**
     * @AttendanceInfo.Action
     */
    @AttendanceInfo.Action doSetGoToDate ?: any;

    mounted() {
        const self = this;

        // @ts-ignore
        $('#datepicker').datepicker({
            showOtherMonths : true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix: '.',
            showMonthAfterYear: true,
            onSelect: this.onSelectDatePicker,
            onChangeMonthYear: this.onChangeMonthYear,
        });
        
    }

    onSelectDatePicker(dateString : string , data : any) : void {
        // 다른 달일 경우 그냥 datePicker만 이동
        if( new Date(dateString).getMonth() != data.drawMonth ) {
            
            // 근태현황 달력은 그냥 넘어가도록 설정
            if( this.$route.matched[0].name == "Attendance" ) {
                this.doSetGoToDate(new Date(dateString));
                this.calendarGotoDateCss(dateFormat(new Date(dateString), 'yyyy-mm-dd'));
                return;
            }
            
            // @ts-ignore
            $('#datepicker').datepicker('setDate', new Date(dateString));
            return;
        }
        
        // 여기서 현재 보고 있는 페이지가 BIZH + 부관리자 라면 방문차량으로 이동 
        if ( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZH && this.is_hodu_home_sub_manager() ) {
            this.hodu_router_push(`/home/visitant/${new Date(dateString).getTime()}`);
        } 
        
        // 근태 현황 달력이라면
        else if( this.$route.matched[0].name == "Attendance" ) {
            this.doSetGoToDate(new Date(dateString));
            this.calendarGotoDateCss(dateFormat(new Date(dateString), 'yyyy-mm-dd'));
            return;
        }
        
        else {
            this.hodu_router_push('/');
        }

        setTimeout(() => {
            this.doPickingLeftDatePicker(new Date(dateString));
            this.calendarGotoDateCss(dateFormat(new Date(dateString), 'yyyy-mm-dd'));
        }, 1);
        
    }

    onChangeMonthYear(year: number, month: number, inst: any) : void {
        if($('#bt_calContr').hasClass('on')) {		// 데이트픽커가 닫혀있다면
            this.onClickDatePickerOnOff();
        }
    }

    onClickDatePickerOnOff() : void {
        $('#container').find('#left_area').find('.left_inbox').toggleClass('on');
        if($('#bt_calContr').hasClass('on')) {
            $('.ui-datepicker-calendar-wrap').show();
        } else  {
            $('.ui-datepicker-calendar-wrap').hide();
        }
        $('#bt_calContr').toggleClass('on');

        // left_inbox02 Resize
        setTimeout(() => this.leftResizeScroll(), 100);
    }

    calendarGotoDateCss(date : string) : void {
        this.$nextTick(() => {
            $('#calendar').find('.fc-day-number').removeClass('on_orange');
            $('#calendar').find('.fc-day-number').removeClass('on_select');
            
            if(dateFormat(new Date(), 'yyyy-mm-dd') == date) {
                $('#calendar').find(`td.fc-day-top[data-date="${date}"]`).find('.fc-day-number').addClass('on_orange');
            }else {
                $('#calendar').find(`td.fc-day-top[data-date="${date}"]`).find('.fc-day-number').addClass('on_select');
            }
        });
    }

    leftResizeScroll() : void {
        // const left_area_height : number | undefined = $('#left_area').outerHeight();
        // const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

        // const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
        // const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

        // let heightSize : number = left_area_height_final - left_inbox_height_final;
        // $('.left_inbox02').height(heightSize+"px");

        setTimeout(() => {
            // @ts-ignore
            $('.left_inbox02').mCustomScrollbar('destroy');
            if( window['left_inbox02_mCustomScrollbar'] ) window['left_inbox02_mCustomScrollbar']();
        }, 100);
    }

}

