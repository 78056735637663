
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');

const dateFormat = require('dateformat');

import { t_event_file } from '@/model/event';
import { t_notification } from '@/model/osm';

declare var CKEDITOR: any;

import { ResizeObserver } from 'vue-resize';
import { files } from 'jszip';

@Component({
    components : {
        ResizeObserver
    }
})
export default class GroupNoticeCreateEditor extends Mixins(VueHoduCommon) {
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * 변수 선언부
     */
    noti_uid   : string  = "";
    is_created : boolean = true;
    is_shared  : boolean = false;

    init_cke : boolean = false;
    file_drag : boolean = false;

    files : any[] = [];
    all_files : any[] = [];

    o_notice : t_notification = {
        noti_uid : "",
        noti_type : "",
        noti_sub_type: "",
        group_id: 0,
        team_id: 0,
        team_ids: [],
        group_ids: [],
        user_ids: [],
        noti_info: {
            title: "",
            start_date: null,
            end_date: null,
            calendar_id: "",
            event_id: "",
            event_sub_type: "",
            shared: "",
            scope: "",
            scope_id: 0
        },
        noti_data: {
            body: "",
            body_type: null
        },
        meta: "",
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: 0
    };

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() : Promise<void> {
        const vue = this;
        
        // 공지사항 수정일때 사용함..
        if ( this.group_team_option.noti_uid ) {
            this.noti_uid   = this.group_team_option.noti_uid;
            this.is_created = false;
        }
        else {
            this.noti_uid   = "";
            this.is_created = true;
            this.o_notice.noti_info.start_date = new Date();
            if( this.team_id < 1 ) { this.o_notice.noti_info.shared = 'T'; }
            this.setNoticeStartYmdDisplay();
        }

        // date_picker
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                vue.o_notice.noti_info.start_date = new Date(dateText)
                vue.setNoticeStartYmdDisplay();
            },
        };

        // @ts-ignore
        $('#noti_datepicker').datepicker(option);        

        // CKEDITOR
        await CKEDITOR.on( 'instanceReady', function( e ) {
            let editor = e.editor,body = CKEDITOR.document.getBody();
            editor.on( 'focus', function() {
                // texArea에 포커스가 있을때 datePicker none 처리
                $('#ui-datepicker-div').css("display", "none");
            });

            vue.init_cke = true

            // content 높이 설정
            vue.$nextTick(async() => {
                const title_height = $('.title_box').outerHeight();
                const grp_height = $('.content .grp').outerHeight();
                const noti_title_height = $('#noti_title').outerHeight();
                const cke_top_height = $('#cke_modal_noti_contents .cke_top').outerHeight();
                const cke_bottom_height = $('#cke_modal_noti_contents .cke_bottom').outerHeight();

                let height = window.innerHeight - (title_height ? title_height : 0)
                                                - (grp_height ? grp_height : 0)
                                                - (noti_title_height ? noti_title_height : 0)
                                                - (cke_top_height ? cke_top_height : 0)
                                                - (cke_bottom_height ? cke_bottom_height : 0)
                                                - 205;

                $("#cke_modal_noti_contents .cke_contents").attr('style', 'height : ' + height + 'px !important');

                // @ts-ignore
                $('#file_scroll').mCustomScrollbar('destroy');

                // @ts-ignore
                $('#file_scroll').mCustomScrollbar({
                    axis : 'y',
                    scrollbarPosition : 'outside',
                    mouseWheelPixels : 120,
                    scrollInertia : 60,
                    autoDraggerLength : false,
                    setHeight : 145
                });
            });
        });

        // 공지 상세
        this.getNoticeDetatil();
    }

    /**
     * 공지 시작일 표시
     */
    setNoticeStartYmdDisplay() : void {
        $('#noti_datepicker').val(dateFormat(this.o_notice.noti_info.start_date, "yyyy.mm.dd"));
    }

    /**
     * 공지사항 상세화면 조회
     */
    async getNoticeDetatil() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getNoticeDetail/${this.group_id}/${this.team_id}?uid=${this.noti_uid}`, API_METHOD.GET, {}, false)
            .then(async(response) => {
                if ( this.team_id > 0 ) {
                    this.is_shared = true;
                }
                else {
                    this.is_shared = (response.data.data.team_count.team_count > 0 ? true : false);
                }

                if ( this.is_created == false ) {
                    // 데이터가 없을경우
                    if ( !response.data.data.noti_data ) {
                        if ( vue.group_team_option.noti_uid ) {
                            delete vue.group_team_option.noti_uid;
                        }

                        vue.movePrevPage();
                        return;
                    }
                    else {
                        this.o_notice = response.data.data.noti_data;
                        
                        if( this.o_notice.noti_info.files ) {
                            this.files = this.files.concat(this.o_notice.noti_info.files);
                        }

                        this.setCkeditor();
                        this.setNoticeStartYmdDisplay();
                    }
                }
                else {
                    this.setCkeditor();
                }

                return;
            })
            .catch(async(e) => {

                if ( vue.group_team_option.noti_uid ) {
                    delete vue.group_team_option.noti_uid;
                }

                this.hodu_error_process(e, true, true);
            });        
    }
    
    /**
     * CKEditor 세팅
     */
    async setCkeditor() : Promise<void> {
        let ckeditorOption : any = {
            uploadUrl: "api/v1/upload/noticeUpload?type=image",  // 이게 드래그 드롭을 위한 URL
            filebrowserUploadUrl: "api/v1/upload/noticeUpload?type=file",  // 파일업로드를 위한 URL
            filebrowserImageUploadUrl : "api/v1/upload/noticeUpload?type=image",//"/hoduweb/upload/ckeditorImg.do",
            filebrowserUploadMethod : 'xhr',
            fileTools_requestHeaders: {
                'X-Requested-With': 'XMLHttpRequest',
                'device-uid': sessionStorage.getItem("device_uid"),
                'authorization': sessionStorage.getItem("session_token"),
            }
        };

        ckeditorOption.resize_maxWidth = 652;

        await CKEDITOR.replace('modal_noti_contents', ckeditorOption);
        CKEDITOR.instances.modal_noti_contents.setData(this.o_notice.noti_data.body);
        //CKEDITOR.instances.modal_noti_contents.updateElement();
    }

    /**
     * 저장
     */
    async actionSave() : Promise<void> {
        const vue = this;

        let content_body = CKEDITOR.instances.modal_noti_contents.getData();

        if ( this.o_notice.noti_info.title.trim().length < 1 ) {
            alert('제목을 입력하세요');
            return;
        }

        if ( content_body.trim().length < 1 ) {
            alert('내용을 입력하세요');
            return;
        }

        if( this.all_files.length > 0 ) {
            const form_data : FormData = new FormData();
        
            // FormData 내용 생성
            const image_files_count : number = this.all_files.length;
            for( let i = 0; i < image_files_count; i++ ) {
                form_data.append('file', this.all_files[i].file);
            }

            try {
                const response = await this.hodu_temp_upload(form_data);
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("이미지 임시 업로드 실패");
                }

                const temp_files : t_event_file[] = response.data.data.temp_files;

                // 임시파일 데이터로 객체 대체하기
                const all_files_count : number = this.all_files.length;
                for( let i = 0; i < all_files_count; i++ ) {
                    vue.files.splice(this.all_files[i].index, 1, temp_files[i]);    
                }

            } catch(e) {
                this.hodu_error_process(e, true, false);
                return;
            }
        }


        // 등록된 이미지 찾기
        let HTML = content_body;
        //let re = /<img src="(.*?)"/gi;
        let re = /src="(.*?)"/gi;
        let result = HTML.match(re);
        let replace_images : string[] = [];

        if ( result && result.length > 0 ) {
            for ( let i = 0; i < result.length; i++ ) {
                let img = result[i].replace('src="', '').replace('"', '');

                if ( Number(img.indexOf("/app_images/temp_uploads/")) > -1 ) {
                    replace_images.push(img);
                }
            }
        }

        this.o_notice.noti_data.body = content_body;

        if ( this.is_created ) {
            this.o_notice.noti_type = "GROUP_NOTICE";
            this.o_notice.noti_data.body_type = "HTML";
            this.o_notice.noti_info.end_date = new Date("2050.12.31");

            if ( this.team_id == 0 ) {
                this.o_notice.group_id = this.group_id;
            }
            else {
                this.o_notice.group_id = this.group_id;
                this.o_notice.team_id  = this.team_id;
                this.o_notice.team_ids = [this.team_id];
            }
        }

        if ( this.team_id > 0 ) {
            if ( this.o_notice.noti_info.shared == "G" ) {
                this.o_notice.group_ids = [this.group_id];
            }
            else {
                this.o_notice.group_ids = [];
            }
        }
        else {
            this.o_notice.group_ids = [this.group_id]
        }

        let req_data : any = {
            notification: this.o_notice,
            web_notice: "Y",
            replace_images: replace_images
        }

        if( this.files.length > 0 ) {
            req_data.files = this.files;
        }

        await this.hodu_api_call(`api/v1/notification/notice/${this.team_id == 0 ? 'GROUP' : 'TEAM'}`, this.is_created ? API_METHOD.POST : API_METHOD.PUT, req_data)
            .then(async(response) => {
                if ( this.is_created ) {
                    if ( vue.group_team_option.noti_uid ) {
                        delete vue.group_team_option.noti_uid;
                    }
                }
                
                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 공유 변경
     */
    sharedChange(any) : void {
        let ls_shared = this.o_notice.noti_info.shared;

        if ( ls_shared == "" ) {
            this.o_notice.noti_info.shared = (this.team_id == 0 ? "T" : "G");
        }
        else {
            this.o_notice.noti_info.shared = "";
        }
    }

    /**
     * 파일추가 버튼
     */
    addFileButton() : void {
        $('#cdFile').trigger('click');
    }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        this.file_drag = false;
        this.addFile(event.dataTransfer.files);
    }

    /**
     * 파일 추가 - +버튼 눌러서 추가
     */
    addFileNormal(event) : void {
        const vue = this;
        const files : File[] = event.target.files;
        this.addFile(files);
    }

    /**
     * 파일 객체 추가
     */
    addFile(files : File[]) : void {
        let file_count : number = files.length;
        const event_files : t_event_file[] = [];

        if( this.files.length >= 5 ) {
            alert(`파일 최대 개수는 5개 입니다`);
            $('#cdFile').val("");
            return;
        }

        // 파일 여유 공간이 있지만 선택한 파일 개수 + 기존에 있던 파일 개수가 최대치를 넘은 경우
        if( this.files.length + file_count > 5 ) {
            if( files instanceof FileList ) {
                files = Array.prototype.slice.call(files, 0, (5 - this.files.length));
            }

            else {
                alert(`파일 최대 개수는 5개 입니다`);
                $('#cdFile').val("");
                return;
            }

            file_count = files.length;
        }

        let max_size : number = this.is_premium_group("GROUP", this.group_id) ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
        let max_size_text : string = this.is_premium_group("GROUP", this.group_id) ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

        // 파일 용랑 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > max_size ) {
                alert(`${max_size_text} 이하의 파일만 업로드 가능 합니다`);
                $('#cdFile').val("");
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#cdFile').val("");
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#cdFile').val("");
                return;
            }
            
            event_files.push({
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: "",                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            })

            // 업로드용 파일 객체 담기
            this.all_files.push({
                index : ( this.files.length + i ), 
                file : files[i]
            });
        }

        // 배열 합치기
        this.files = this.files.concat(event_files);

        // 파일 input 비우기
        $('#cdFile').val("");
    }

    /**
     * 파일 삭제
     */
    deleteFile(file : t_event_file) : void {

        const file_index : number = this.files.indexOf(file);

        if( file_index == -1 ){
            return;
        }

        this.files.splice(file_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const files_count : number = this.all_files.length;
        for( let i = (files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.all_files[i].index > file_index ) {
                this.all_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.all_files[i].index == file_index ) {
                this.all_files.splice(i, 1);
                break;
            }
        }
    }

    /**
     * 파일 전체 삭제
     */
    allFileDelete() : void {
        this.files.splice(0, this.files.length);
        this.all_files.splice(0, this.all_files.length);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        if( !this.init_cke ) return;

        // content 높이 설정
        this.$nextTick(async() => {
            const title_height = $('.title_box').outerHeight();
            const grp_height = $('.content .grp').outerHeight();
            const noti_title_height = $('#noti_title').outerHeight();
            const cke_top_height = $('#cke_modal_noti_contents .cke_top').outerHeight();
            const cke_bottom_height = $('#cke_modal_noti_contents .cke_bottom').outerHeight();

            let height = window.innerHeight - (title_height ? title_height : 0)
                                            - (grp_height ? grp_height : 0)
                                            - (noti_title_height ? noti_title_height : 0)
                                            - (cke_top_height ? cke_top_height : 0)
                                            - (cke_bottom_height ? cke_bottom_height : 0)
                                            - 205;

            $("#cke_modal_noti_contents .cke_contents").attr('style', 'height : ' + height + 'px !important');

            // @ts-ignore
            $('#file_scroll').mCustomScrollbar('destroy');

            // @ts-ignore
            $('#file_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : 145
            });
        });
    }
    
}
