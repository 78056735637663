
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class KidsDetail extends Mixins(VueHoduCommon) {

    mounted() : void {
        $("#kid_name").off("click").on("click", function() {
            if ($("#kid_name").hasClass("on")) { 
                $("#kid_name").removeClass("on");

            } else { 
                $("#kid_name").addClass("on");

            }
        });
    }

}
