
import { Vue, Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';
import moment from 'moment';
import { organization_enum } from '@/model/organization';

import { directive as onClickaway } from 'vue-clickaway';

@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        
    },
})
export default class OrganizationHolidaySetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;

    @Prop() event_bus !: Vue;

    get computedHoliday() : any[] {
        let holidays : any[] = []; 

        for( const holiday of this.holidays ) {
            const not_holiday = this.not_holidays.filter(item => item.holiday == holiday.solar_ymd.replace(/-/ig, ""));
            const is_rest : boolean = not_holiday.length < 1;

            holidays.push({
                "ymd" : holiday.solar_ymd,
                "holi_name" : holiday.holi_name,
                "is_rest" : is_rest
            });
        }
        
        holidays.sort((o1, o2) : number => {

            if( this.sort_type == organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE.DATE ) {
                const o1_yyyymmdd = o1.ymd.replace(/-/ig, "");
                const o2_yyyymmdd = o2.ymd.replace(/-/ig, "");

                if( o1_yyyymmdd > o2_yyyymmdd ) return this.sort_dir == SORT_TYPE.ASC ?  1 : -1;
                if( o1_yyyymmdd < o2_yyyymmdd ) return this.sort_dir == SORT_TYPE.ASC ? -1 :  1;
            }

            else if( this.sort_type == organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE.NAME ) {
                const o1_name = o1.holi_name;
                const o2_name = o2.holi_name;

                if( o1_name > o2_name ) return this.sort_dir == SORT_TYPE.ASC ?  1 : -1;
                if( o1_name < o2_name ) return this.sort_dir == SORT_TYPE.ASC ? -1 :  1;
            }

            return 0;
        });

        return holidays;
    }

    selected_year : string = "";

    is_year_open : boolean = false;

    holidays : any[] = []; // 공휴일
    not_holidays : any[] = []; // 공휴일 쉬지 않는 날

    sort_type : organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE = organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE.DATE;
    sort_dir : SORT_TYPE = SORT_TYPE.ASC;

    mounted() : void {
        this.event_bus?.$on("OrganizationHolidaySettingResize", this.handleResize);

        this.selected_year = moment().format('YYYY');
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            // 조직도 스크롤
            const title_box_height = $('#organization_setting .title_box').outerHeight();
            const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            const sort_header_height = $('#organization_holiday_setting .sortHeader').outerHeight();

            // @ts-ignore
            $('#organization_holiday_setting_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (title_box2_height ? title_box2_height : 0)
                                            - (sort_header_height ? sort_header_height : 0)
            });

            // @ts-ignore
            $('#year_dropdown').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
            });
        });
    }

    /**
     * 법정 휴무일 조회
     */
    @Watch('selected_year')
    async getLegalHolidays() : Promise<void> {
        try {
            let start_date = moment(new Date()).set("years", Number(this.selected_year)).set("months", 0).set("dates",1).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toDate();
            let end_date   = moment(start_date).set("months", 11).set("dates",31).toDate();

            await this.getOrganizationNotHoliday();
            await this.getHolidays(start_date, end_date);

        } catch(e) {
            this.hodu_show_dialog('cancel', '법정 휴무일 조회 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {
        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        this.holidays.splice(0, this.holidays.length);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {

            this.holidays.push(holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                holi_day_before.holi_name = holi_day.holi_name + " 연휴";

                this.holidays.push(holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                holi_day_after.holi_name = holi_day.holi_name + " 연휴";

                this.holidays.push(holi_day_after);
            }
        }

    }

    /**
     * 쉬지 않는 날 조회
     */
    async getOrganizationNotHoliday() : Promise<void> {
        
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/holiday`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.not_holiday ) {
                throw new Error("법정 휴무일 조회 중 오류 발생");
            }

            this.not_holidays.splice(0, this.not_holidays.length);
            this.not_holidays = this.not_holidays.concat(response.data.data.not_holiday);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 법정 휴무일 쉬지 않는 날 등록
     */
    async insertOrganizationNotHoliday(holiday_yyyymmdd : string) : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/holiday`, API_METHOD.POST, { holiday : holiday_yyyymmdd });

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("법정 휴무일 쉬지 않는 날 등록 중 오류 발생");
            }

            this.getLegalHolidays();

        } catch(e) {
            this.hodu_show_dialog('cancel', '법정 휴무일 쉬지 않는 날 등록 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 법정 휴무일 쉬지 않는 날 등록
     */
    async deleteOrganizationNotHoliday(holiday_yyyymmdd : string) : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/holiday/${holiday_yyyymmdd}`, API_METHOD.DELETE);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("법정 휴무일 쉬지 않는 날 삭제 중 오류 발생");
            }

            this.getLegalHolidays();

        } catch(e) {
            this.hodu_show_dialog('cancel', '법정 휴무일 쉬지 않는 날 삭제 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 쉬는날, 쉬지않는날 변경
     */
    async changeNotHoliday(event, holiday) : Promise<void> {

        const value = event.target.value;

        const holiday_yyyymmdd = holiday.ymd.replace(/-/ig, '');

        if( value == 'yes' ) {
            this.deleteOrganizationNotHoliday(holiday_yyyymmdd);
        }

        // insert
        else if( value == 'no' ) {
            this.insertOrganizationNotHoliday(holiday_yyyymmdd);
        }
    }

    /**
     * 정렬
     */
    sort(sort_type : organization_enum.ORGANIZATION_HOLIDAY_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_dir = SORT_TYPE.ASC;
            return;
        }

        this.sort_dir = (this.sort_dir == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 드롭다운 바깥쪽 클릭
     */
    dropdownOutsideClick() : void {
        this.is_year_open = false;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_holiday_setting_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#year_dropdown').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
