import { t_company_info } from './osm';
import { t_event } from './event';

/**
 * 호두닥 테이블 인터페이스를 담는 네임스페이스
 */
export namespace hodu_doc_table {

    /**
     * 병원 시간표
     */
    export interface t_hospital_timetable {
        biz_id                     : string;                           // 프리미엄 아이디
        start_date                 : Date | string;                    // 적용 시작일
        end_date                   : Date | string;                    // 적용 종료일
        reservation_term           : number;                           // 예약기간 : 기본 15분
        reservation_term_by_people : number;                           // 예약기간내 진료가능 환자수
        work_info                  : t_hospital_timetable_work_info[]; // 요일별 근무 시간 : (day_seq 0: SUNDAY, 6: SATURDAY)
        audit_created              : Date | string;                    // 생성일
        audit_created_id           : number;                           // 데이터 생성 사용자 ID 
        audit_modified             : Date | string;                    // 최종수정일
        audit_modified_id          : number;                           // 데이터 최종 수정 사용자 ID
        audit_delete_flag          : boolean;                          // 삭제여부
    }

    /**
     * 병원 시간표 요일별 근무 시간
     */
    export interface t_hospital_timetable_work_info {
        day_of_week   : number;        // 요일 (0 : 일요일 ~ 6 : 토요일)
        start_am_time : string | null; // 오전 근무 시작 시간 (hhmm) ex) 0900
        end_am_time   : string | null; // 오전 근무 종료 시간 (hhmm) ex) 1200
        start_pm_time : string | null; // 오후 근무 시작 시간 (hhmm) ex) 1300
        end_pm_time   : string | null; // 오후 근무 종료 시간 (hhmm) ex) 1800
    }

    /**
     * 병원 휴일
     */
    export interface t_hospital_day_offs {
        biz_id            : string;        // 프리미엄 아이디
        start_date        : Date | string; // 적용 시작일
        end_date          : Date | string; // 적용 종료일
        off_name          : string;        // 휴일 이름
        off_reason        : string;        // 휴일 내용
        audit_created     : Date | string; // 생성일
        audit_created_id  : number;        // 데이터 생성 사용자 ID
        is_public_holiday : boolean;       // 공휴일 여부
        hospital_off_id   : string;        // 병원 지정 휴일 uuid
    }

    /**
     * 병원 진료과
     */
    export interface t_hospital_departments {
        biz_id            : string;        // 프리미엄 아이디
        department_code   : string;        // 진료과 코드
        department_name   : string;        // 진료과 이름
        audit_created     : Date | string; // 생성일
        audit_created_id  : number;        // 데이터 생성 사용자 ID
        audit_modified    : Date | string; // 최종수정일
        audit_modified_id : number;        // 데이터 최종 수정 사용자 ID
        audit_delete_flag : boolean;       // 삭제여부     
    }

    /**
     * 의사
     */
    export interface t_hospital_doctors {
        biz_id            : string;        // 프리미엄 아이디
        department_code   : string;        // 진료과 코드
        doctor_code       : string;        // 의사 코드
        doctor_seq        : number;        // 달력 필터링시 팀 아이디를 대신하기 위해 생성 : 의사 코드 시퀀스
        doctor_name       : string;        // 의사 이름
        audit_created     : Date | string; // 생성일
        audit_created_id  : number;        // 데이터 생성 사용자 ID
        audit_modified    : Date | string; // 최종수정일
        audit_modified_id : number;        // 데이터 최종 수정 사용자 ID
        audit_delete_flag : boolean;       // 삭제여부
    }

    /**
     * 의사 시간표
     */
    export interface t_hospital_doctors_timetable {
        biz_id            : string;        // 프리미엄 아이디
        department_code   : string;        // 진료과 코드 
        doctor_code       : string;        // 의사 코드
        start_date        : Date | string; // 적용 시작일
        end_date          : Date | string; // 적용 종료일
        work_day_info     : any;           // 근무시간표
        audit_created     : Date | string; // 생성일
        audit_created_id  : number;        // 데이터 생성 사용자 ID
        audit_modified    : Date | string; // 최종수정일
        audit_modified_id : number;        // 데이터 최종 수정 사용자 ID
        doctor_seq        : number;        // 삭제여부
    }

    /**
     * 의사 시간표 요일별 근무 시간
     */
    export interface t_hospital_doctors_timetable_work_day_info {
        work_code   : hodu_doc_enum.doctor_work_code; // 의사 근무 시간 타입 ( OFF, FULL_TIME, AM, PM )
        day_of_week : number;                         // 요일 (0 : 일요일 ~ 6 : 토요일)
    }

    /**
     * 의사 휴가
     */
    export interface t_hospital_doctor_day_offs {
        doctor_off_id     : string;        // 휴가 uuid
        biz_id            : string;        // 프리미엄 아이디
        department_code   : string;        // 진료과 코드
        doctor_code       : string;        // 의사 코드
        off_name          : string;        // 휴가 이름
        off_reason        : string;        // 휴가 내용
        start_date        : Date | string; // 시작일자
        end_date          : Date | string; // 종료일자
        audit_created     : Date | string; // 생성일
        audit_created_id  : number;        // 데이터 생성 사용자 ID
        audit_deleted     : Date | string; // 삭제일
        audit_deleted_id  : number;        // 데이터 삭제 사용자 ID
        audit_delete_flag : boolean;       // 삭제여부
    }
    
    /**
     * 환자 파일함
     */
    export interface t_hospital_patient_file {
        file_uuid          : string;        // 파일 UUID    
        event_id           : string | null; // 예약에서 등록 된 경우 해당 예약의 event_id
        biz_id             : string;        // 프리미엄 아이디
        user_id            : number;        // 대상 유저 ID
        group_id           : number;        // 그룹 ID
        file_name          : string;        // 파일명 
        file_size          : number;        // 파일 용량 Byte 
        file_path          : string;        // 파일 경로
        audit_created     ?: Date | null;   // 생성일
        audit_modified    ?: Date | null;   // 수정일
        audit_deleted     ?: Date | null;   // 삭제일
        audit_delete_flag  : boolean;       // 삭제여부
        file_type          : string;        // 파일의 mimeType 
        original_file_name : string;        // 파일 원본명
        
        // 테이블에는 없음
        checked : boolean; // 파일 선택 여부
    }
}

/**
 * 호두닥 오브젝트 인터페이스를 담는 네임스페이스 (주로 API 조회 결과로 나온 내용들)
 */
export namespace hodu_doc_object {

    export interface my_hospital_info {
        appointments : t_event[];
        biz_id       : string;
        departments  : department_info[];
        doctors      : doctor_info[];
        files        : hodu_doc_table.t_hospital_patient_file[];
        group_color  : string;
        group_id     : number;
        group_name   : string;
        hospital_info : any;
        time_tables  : any[]
    }

    /**
     * 병원 기본 정보 (API 조회 결과)
     */
    export interface hospital_info {
        biz_id             : string;         // 프리미엄 아이디
        group_id           : number;         // 그룹 아이디
        group_ids          : number[];       // 최상위 포함 모든 하위 그룹 ID 
        company_info       : t_company_info; // 회사 정보
        expire_date        : Date | string;  // 유효기간 
        biz_type           : string;         // 프리미엄 타입
        is_cert            : boolean;        // 인증여부
        meta              ?: any;            // 메타데이터
        audit_created      : Date | string;  // 생성일
        audit_modified     : Date | string;  // 최종수정일
        audit_deleted      : Date | string;  // 삭제일
        audit_delete_flag  : boolean;        // 삭제여부
        audit_user_id      : number;         // 데이터 최종 수정 사용자 ID
    }

    /**
     * 병원 세팅 정보 (API 조회 결과)
     */
    export interface hospital_setting_info {
        is_current_schedule        : boolean;       // 현재 적용되는 세팅인지 여부
        from_to                    : string;        // ~ 부터 ~ 까지 날짜(YYYY-MM-DD ~ YYYY-MM-DD) 텍스트
        day_of_week                : number;        // 요일 (0 : 일요일 ~ 6 : 토요일)
        start_am_time              : string | null; // 오전 근무 시작 시간 (hhmm) ex) 0900
        end_am_time                : string | null; // 오전 근무 종료 시간 (hhmm) ex) 1200
        start_pm_time              : string | null; // 오후 근무 시작 시간 (hhmm) ex) 1300
        end_pm_time                : string | null; // 오후 근무 종료 시간 (hhmm) ex) 1800
        summary                    : string;        // 오전, 오후 근무시간 텍스트 설명
        start_date                 : Date | string; // 적용 시작일
        end_date                   : Date | string; // 적용 종료일
        reservation_term           : number;        // 예약기간 : 기본 15분
        reservation_term_by_people : number;        // 예약기간내 진료가능 환자수
    }

    /**
     * 진료과 정보 (API 조회 결과)
     */
    export interface department_info {
        department_code : string; // 진료과 코드
        department_name : string; // 진료과 이름
    }

    /**
     * 의사 정보 (API 조회 결과)
     */
    export interface doctor_info {
        hospital_name       : string;                         // 병원 이름
        department_code     : string;                         // 진료과 코드
        department_name     : string;                         // 진료과 이름
        doctor_code         : string;                         // 의사 코드
        doctor_name         : string;                         // 의사 이름
        doctor_detail       : hodu_doc_object.doctor_detail;  // 의사 상세정보
        start_date          : Date | string;                  // 계약 시작일
        end_date            : Date | string;                  // 계약 종료일
        is_current_schedule : boolean;                        // 현재 적용된 근무시간 여부
        timetable_id        : string;                         // 근무표 uuid
        sunday              : hodu_doc_enum.doctor_work_code; // 일요일 의사 근무 시간 타입 ( OFF, FULL_TIME, AM, PM )
        monday              : hodu_doc_enum.doctor_work_code; // 월요일 의사 근무 시간 타입 ( OFF, FULL_TIME, AM, PM )
        tuesday             : hodu_doc_enum.doctor_work_code; // 화요일 의사 근무 시간 타입 ( OFF, FULL_TIME, AM, PM )
        wednesday           : hodu_doc_enum.doctor_work_code; // 수요일 의사 근무 시간 타입 ( OFF, FULL_TIME, AM, PM )
        thursday            : hodu_doc_enum.doctor_work_code; // 목요일 의사 근무 시간 타입 ( OFF, FULL_TIME, AM, PM )
        friday              : hodu_doc_enum.doctor_work_code; // 금요일 의사 근무 시간 타입 ( OFF, FULL_TIME, AM, PM )
        saturday            : hodu_doc_enum.doctor_work_code; // 토요일 의사 근무 시간 타입 ( OFF, FULL_TIME, AM, PM )
    }

    /**
     * 의사 상세정보
     */
    export interface doctor_detail {
        color         : string;      // 색상
        position      : string;      // 직급 {DIR : 원장(director), DPT : 부원장(deputy director), EMP : 직원(employee)}
        license       : string;      // 자격 {MEDIC : 대학원생,레지던트?, SPCLT : 전문의}
        career        : string[];    // 학력 및 경력사항
        profile_image : string;      // 프로필 이미지
    }

    /**
     * 환자 정보 (API 조회 결과)
     */
    export interface patient_info {
        user_id           : number; // 환자 ID  
        user_name         : string; // 환자 이름
        user_phone_number : string; // 환자 전화번호
        user_email        : string; // 환자 이메일
        nok_count         : number; // 보호자 수?????
        file_count        : number; // 파일 수
        
        // API 조회 결과 아님
        checked : boolean; // 환자 선택 여부  
    }

    /**
     * 예약 정보 (API 조회 결과)
     */
    export interface appointment_info {
        event_id            : string;                                // event_uuid   
        status              : hodu_doc_enum.appointment_status_code; // 상태값
        is_past_appointment : boolean;                               // 과거 예약 여부
        mmdd                : string;                                // 날짜 mmdd 포맷 텍스트
        ampmhh12mi          : string;                                // 날짜 a hh:mm 포맷 텍스트
        start_date          : Date | string;                         // 시작일
        end_date            : Date | string;                         // 종료일
        doctor_code         : string;                                // 의사 코드
        doctor_name         : string;                                // 의사 이름
        department_code     : string;                                // 진료과 코드
        department_name     : string;                                // 진료과 이름
        patient_name        : string;                                // 환자 이름
        nok_name            : string;                                // 보호자 이름
        appoint_user_id     : number;                                // 예약 생성 사용자 ID
        appoint_user        : string;                                // 예약 생성 사용자 이름
        user_phone_number   : string;                                // 전화번호
        total_count         : number;                                // 총 예약 수 (페이징에 사용됨?)
    }

    /**
     * 예약 기간별 리스트에서 일일 정보
     */
    export interface appointment_daily_info {
        cnt         : number;        // 예약 건수     
        day_of_week : number;        // 요일
        holi_name   : string;        // 휴일명  
        orig_date   : Date | string; // 원본 날짜 UTC
        is_work_day : boolean;       // 휴일 여부
        ymd         : string;        // YMD 문자열  
        year        : number;        // 년   
        month       : number;        // 월
        day         : number;        // 일 

    }

    /**
     * 예약 시간 정보 (API 조회 결과)
     */
    export interface appointment_available_time_info {
        biz_id                     : string;                         // 프리미엄 아이디
        department_code            : string;                         // 진료과 코드
        department_name            : string;                         // 진료과 이름
        doctor_code                : string;                         // 의사 코드
        doctor_name                : string;                         // 의사 이름 
        available                  : boolean;                        // 예약 가능 여부
        reservation_term           : number;                         // 예약기간 : 기본 15분
        reservation_term_by_people : number;                         // 예약기간내 진료가능 환자수
        coalesce                   : number;                         // 해당 시각에 예약한 환자 수
        am                         : boolean;                        // 오전 
        pm                         : boolean;                        // 오후 
        original_timetable         : Date | string;                  // 시간
        today_timetable            : hodu_doc_enum.doctor_work_code; // 의사 근무 시간 타입 ( OFF, FULL_TIME, AM, PM )
        start_am_time              : string;                         // 오전 근무 시작 시간 (hhmm) ex) 0900
        end_am_time                : string;                         // 오전 근무 종료 시간 (hhmm) ex) 1200
        start_pm_time              : string;                         // 오후 근무 시작 시간 (hhmm) ex) 1300
        end_pm_time                : string;                         // 오후 근무 종료 시간 (hhmm) ex) 1800
        is_hospital_day_off        : boolean;                        // 병원 휴일 여부
        is_doctors_day_off         : boolean;                        // 의사 휴일 여부 
    }

    /**
     * 예약 시간 정보 리스트 (예약 등록 오른쪽 컴포넌트에서 사용)
     */
    export interface appointment_available_time_info_list {
        date : Date;
        available_time_info : appointment_available_time_info[];
        hour_list : number[];
    }

    /**
     * 시간 예약 정보
     */
    export interface appointment_by_time {
        event_id           : string;                                // 이벤트 ID
        appointment_status : hodu_doc_enum.appointment_status_code; // 예약 상태
        birth_day          : string | null;                         // 생년월일
        biz_id             : string;                                // 프리미엄 아이디
        department_code    : string;                                // 진료과 코드
        doctor_code        : string;                                // 의사 코드
        nok_phone_number   : string | null;                         // 보호자 전화번호
        start              : Date | string;                         // 예약 시간
        symptoms           : string;                                // 증상
        user_name          : string;                                // 이름
    }

    /**
     * 시간별 예약 리스트
     */
    export interface appointment_by_time_list {
        date_key     : string;                // key HH:mm 포맷 (ex : 15:15, 16:30, 18:00 등)                    
        appointments : appointment_by_time[]; // 예약 리스트
    }

    /**
     * 공휴일 정보 (API 조회 결과)
     */
    export interface public_holiday_info {
        solar_ymd   : string;  // 양력 YYYYMMDD
        day_of_week : number;  // 요일 (0 : 일요일 ~ 6 : 토요일)
        holi_name   : string;  // 휴일 이름
        is_work_day : boolean; // 병원 업무 실시 여부
    }

    // 병원 지정 휴일은 hodu_doc_table.t_hospital_day_offs과 동일

    /**
     * 공휴일 정보 (API 조회 결과)
     */
    export interface doctor_timetable_history {
        timetable_id : string;              // uuid
        date_text    : string;              // "2020.02.15~2020.05.05"
        list         : doctor_timetable[];
    }

    export interface doctor_timetable {
        day_of_week : number;                           // 0: 일요일 1: 월요일 ~ 6: 토요일
        work_code   : hodu_doc_enum.doctor_work_code;   // "FULL_TIME", "AM", "PM", "OFF"
        am_text     : string;                           // "오전 09:00~11:00"  
        pm_text     : string;                           // "오후 13:00~20:00"
    }
}

/**
 * 호두닥 Modal에서 사용 될 Info 들
 */
export namespace hodu_doc_modal_info {

    /**
     * 예약 생성 Modal
     */
    export interface AppointmentCreateModalInfo {
        show_modal             : boolean;
        is_create_one_patient  : boolean;
        department_code       ?: string;
        doctor_code           ?: string;
        original_timetable    ?: Date;
        patient_info          ?: hodu_doc_object.patient_info;
    }

    /**
     * 예약 승인 Modal
     */
    export interface AppointmentApplyModalInfo {
        show_modal      : boolean;
        event_id        : string;
        apply_callback ?: Function;
    }

    /**
     * 달력 예약 리스트 Modal
     */
    export interface AppointmentListModalInfo {
        show_modal : boolean;
        events : t_event[];
    }
    
    /**
     * 진료과 Modal
     */
    export interface DepartmentSettingModalInfo {
        show_modal      : boolean;
        is_create       : boolean | null;
        department      : hodu_doc_object.department_info | null;
    }

    /**
     * 의사 근무시간 Modal
     */
    export interface DoctorTimeSettingModalInfo {
        show_modal          : boolean;
        is_create_work_time : boolean; // 의사 관리에서 근무기간을 추가 하는 경우 true 그 외는 false 
        start_date          : Date;
        end_date            : Date;
        timetable           : hodu_doc_object.doctor_timetable_history | null ;
    }

    /**
     * 의사 휴가 등록/수정 Modal
     */
    export interface DoctorOffSettingModalInfo {
        show_modal : boolean;
        is_create  : boolean; // 휴가 등록/수정 여부
    }

    export interface DayOffSettingModalInfo {
        show_modal : boolean;
        is_create  : boolean;
        is_doctor  : boolean;
        day_off    : hodu_doc_table.t_hospital_doctor_day_offs | hodu_doc_table.t_hospital_day_offs | null;
    }

    /**
     * 환자 메세지 발송 Modal
     */
    export interface PatientSendMessageModalInfo {
        show_modal : boolean;
        selected_patients : hodu_doc_object.patient_info[];
    }

    /**
     * 병원 진료시간 추가 Modal
     */
    export interface HospitalTimeSettingModalInfo {
        show_modal : boolean;
        start_date : Date;
        is_create  : boolean;
    }
}

/**
 * 호두닥 enum을 담는 네임스페이스
 */
export namespace hodu_doc_enum {
    
    /**
     * 예약 현황 조회 기준 ENUM 
     */
    export enum appointment_select_criteria {
        DIRECT = 'DIRECT', // 직접 입력 (기간 제약 없음)
        DAILY  = "DAILY",  // 일별
        WEEKLY = "WEEKLY", // 주별 
    }

    /**
     * 예약 상태값 work_code ENUM
     */
    export enum appointment_status_code {
        REQUEST   = "REQUEST",   // 요청 - 환자의 예약이 요쳥 된 상태
        DECLINE   = "DECLINE",   // 거절 - 환자의 예약이 거절 된 상태
        CONFIRM   = "CONFIRM",   // 확정 - 환자의 예약이 확정 된 상태
        RECEIPT   = "RECEIPT",   // 접수 - 환자의 예약이 확정 된 이후 환자가 예정된 시간에 나와 접수 한 상태
        DIAGNOSIS = "DIAGNOSIS", // 진료 - 접수 한 환자의 진료가 완료 된 상태
        NOSHOW    = "NOSHOW",    // 노쇼 - 환자의 예약이 확정 된 이후 환자가 예정된 시간에 오지 않은 경우
    }

    /**
     * 예약 리스트 정렬 타입
     */
    export enum appointment_sort_type {
        TIME       = "TIME",
        DEPARTMENT = "DEPARTMENT",
        DOCTOR     = "DOCTOR",
        PATIENT    = "PATIENT",
    }

    /**
     * 의사 시간표 요일별 근무 시간 work_code ENUM
     */
    export enum doctor_work_code {
        OFF        = "OFF",
        FULL_TIME  = "FULL_TIME",
        AM         = "AM",
        PM         = "PM" 
    }

}