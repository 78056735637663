
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { t_event } from '@/model/event';

@Component({

}) export default class EventRepeatModify extends Mixins(VueHoduCommon) {
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventRepeatModify ?: any;

    /**
     * EventInfo.State
     */
    @EventInfo.State event                 ?: t_event;
    @EventInfo.State is_first_repeat_event ?: boolean;
    @EventInfo.State repeat_modify_action  ?: string;

    repeat_text : any[] = [];
    
    mounted() {
        // 반복 일정의 첫 일정이 아니라면 이후 일정 삭제&수정이 있다
        if( this.is_first_repeat_event == false ) {
            this.repeat_text.push({ 
                text : `이후 일정 모두 ${this.repeat_modify_action}`, 
                code : 'F'
            });
        }

        // 전체 반복 일정 삭제&수정
        this.repeat_text.push({ 
            text : `전체 반복 일정 ${this.repeat_modify_action}`,
            code : 'A' 
        });

        // 이 일정만 삭제&수정
        this.repeat_text.push({
            text : `이 일정만 ${this.repeat_modify_action}`,
            code : 'E'
        });
    }

    /**
     * 반복 수정/삭제 옵션 모달 닫기
     */
    repeatModifyModalClose() : void {
        this.doSetShowEventRepeatModify(false);
    }

    /**
     * repeat modify 선택
     */
    async repeatModify(code : string) : Promise<void> {

        // 삭제인 경우 Event.vue에서 등록한 window 함수 repeatDelete 실행
        if( this.repeat_modify_action == '삭제' ) {
            await window['repeatDelete'](code);
        }

        // 수정인 경우 Event.vue에서 등록한 window 함수 repeatModify 실행
        else {
            await window['repeatModify'](code);
        }
        
        this.doSetShowEventRepeatModify(false);
    }
}
