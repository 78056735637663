import {GetterTree, MutationTree , ActionTree} from "vuex";

export const namespaced = true;

export interface GroupTeamInfo {
    level                   : number;           // 그룹 레벨 ( 현재 1은 그룹, 2는 팀 )
    row_type                : string;           // GROUP : 그룹, TEAM : 팀
    group_id                : number;           // 해당 그룹 아이디 or 소속된 그룹 아이디
    team_id                 : number;           // 해당 팀 아이디 (그룹이면 0)
    biz_id                  : string;           // 비즈 아이디
    biz_type               ?: string;           // 비즈 타입 : 일반 그룹(null), 호두C (BIZC), 호두 D(BIZD) 
    group_name              : string;           // 해당 그룹 이름 or 소속된 그룹 이름 
    team_name               : string;           // 해당 팀 이름
    color                   : string;           // 색상
    team_count              : number;           // 팀 개수
    group_features          : any[];            // 그룹 기능
    group_info              : any;              // 그룹 정보
    limit_event_attachment  : number;           // 첨부파일 허용 개수
    limit_event_image_count : number;           // 이미지 첨부 허용 개수
    user_count              : number;           // 그룹or팀 소속된 유저 수
    checked                 : boolean;          // 필터 체크 여부 ( true : 필터에 없음, false : 필터에 존재함 )
    shareChecked            : boolean;          // 일정 공유에서 리스트 체크 여부
    listOn                  : boolean;          // 왼쪽 그룹 필터 리스트 펼침 여부 ( true : 리스트 펼쳐짐, false : 리스트 접힘 )
    shareListOn             : boolean;          // 일정 공유에서 리스트 펼침 여부 ( true : 리스트 펼쳐짐, false : 리스트 접힘 )
    teams                   : GroupTeamInfo[];  // 해당 그룹의 팀 리스트 ( 팀에서는 존재하지 않음 )
    css_style              ?: string;           // 체크 컬러 때문에 집어넣는 css style 텍스트
    department_code        ?: string;           // 진료과 코드
    doctor_code            ?: string;           // 의사 코드 
    role                    : string[];         // 권한
}

export interface GroupNoticeSearchInfo {
    notice_sort_target : string | null;
    notice_sort_direction : string;
    search_query : string;
}

export interface GroupInfoState {
    group_info_list : GroupTeamInfo[];
    all_group_data_temp : GroupTeamInfo[];
    all_group_data : GroupTeamInfo[];
    normal_group_data : GroupTeamInfo[];
    hodu_c_group_data : GroupTeamInfo[];
    hodu_d_group_data : GroupTeamInfo[];
    group_id : number;
    team_id : number;
    list_type : number;
    group_statistics_start_date : string;
    group_team_option : any;
    group_notice_search_info : GroupNoticeSearchInfo;
    selected_dir_uuid : string; // 선택된 파일함 폴더 아이디
}

export const state: GroupInfoState = {
    group_info_list : [],
    all_group_data_temp : [],
    all_group_data : [],
    normal_group_data : [],
    hodu_c_group_data : [],
    hodu_d_group_data : [],
    group_id : 0,
    team_id : 0,
    list_type : 1,
    group_statistics_start_date : "",
    group_team_option : null,
    group_notice_search_info : {
        notice_sort_direction : "ASC",
        notice_sort_target : null,
        search_query : "",
    },
    selected_dir_uuid : "",
}

// getters
export const getters: GetterTree<GroupInfoState, any> = {
    getGroupInfoList : (state) => {
        return state.group_info_list;
    },
    getAllGroupDataTemp : (state) => {
        return state.all_group_data_temp;
    },
    getAllGroupData : (state) => {
        return state.all_group_data;
    },
    getNormalGroupData : (state) => {
        return state.normal_group_data;
    },
    getHoduCGroupData : (state) => {
        return state.hodu_c_group_data;
    },
    getHoduDGroupData : (state) => {
        return state.hodu_d_group_data;
    },
    getGroupId : (state) => {
        return state.group_id;
    },
    getTeamId : (state) => {
        return state.team_id;
    },
    getListType : (state) => {
        return state.list_type;
    },
    getGroupStatisticsStartDate : (state) => {
        return state.group_statistics_start_date;
    },
    getGroupTeamOption : (state) => {
        return state.group_team_option;
    },
    getGroupNoticeSearchInfo : (state) => {
        return state.group_notice_search_info;
    },
    getSelectedDirUuid : (state) => {
        return state.selected_dir_uuid;
    },
}

// action
export const actions: ActionTree<GroupInfoState, GroupInfoState> = {

    doReplaceGroupInfoState: ({commit, state}, group_info_state : GroupInfoState) => {
        console.log(`actions.doReplaceGroupInfoState ${JSON.stringify(group_info_state)}`);
        
        // 원본배열 유지를 위해 splice & concat
        state.group_info_list.splice(0, state.group_info_list.length);
        state.group_info_list = state.group_info_list.concat(group_info_state.group_info_list);

        state.group_id                    = group_info_state.group_id;
        state.team_id                     = group_info_state.team_id;
        state.group_statistics_start_date = group_info_state.group_statistics_start_date;
        state.group_team_option           = group_info_state.group_team_option;
        state.list_type                   = group_info_state.list_type;
        state.group_notice_search_info    = JSON.parse(JSON.stringify(group_info_state.group_notice_search_info));

        if( sessionStorage != null ){
            sessionStorage.setItem("group_info", JSON.stringify(state));
        }
    },
    doReplaceGroupInfoList: ({commit, state}, group_info_list : GroupTeamInfo[]) => {
        console.log("actions.doReplaceGroupInfoList");

        // 원본배열 유지를 위해 splice & concat
        state.group_info_list.splice(0, state.group_info_list.length);
        state.group_info_list = state.group_info_list.concat(group_info_list);

        if( sessionStorage != null ){
            sessionStorage.setItem("group_info", JSON.stringify(state));
        }

        // commit("replaceGroupInfoList", group_info_list);
    },
    doSetAllGroupDataTemp: ({commit, state}, all_group_data_temp : GroupTeamInfo[]) => {
        console.log("actions.doSetAllGroupDataTemp");
        state.all_group_data_temp.splice(0, state.all_group_data_temp.length);
        state.all_group_data_temp = state.all_group_data_temp.concat(all_group_data_temp);
        // commit("setAllGroupDataTemp", all_group_data_temp);
    },
    doSetNormalGroupData: ({commit, state}, normal_group_data : GroupTeamInfo[]) => {
        console.log("actions.doSetNormalGroupData");
        state.normal_group_data.splice(0, state.normal_group_data.length);
        state.normal_group_data = state.normal_group_data.concat(normal_group_data);
        // commit("setNormalGroupData", normal_group_data);
    },
    doSetHoduCGroupData: ({commit, state}, hodu_c_group_data : GroupTeamInfo[]) => {
        console.log("actions.doSetHoduCGroupData");
        state.hodu_c_group_data.splice(0, state.hodu_c_group_data.length);
        state.hodu_c_group_data = state.hodu_c_group_data.concat(hodu_c_group_data);
        // commit("setHoduCGroupData", hodu_c_group_data);
    },
    doSetHoduDGroupData: ({commit, state}, hodu_d_group_data : GroupTeamInfo[]) => {
        console.log("actions.doSetHoduDGroupData");
        state.hodu_d_group_data.splice(0, state.hodu_d_group_data.length);
        state.hodu_d_group_data = state.hodu_d_group_data.concat(hodu_d_group_data);
        // commit("setHoduDGroupData", hodu_d_group_data);
    },
    doGroupId: ({commit, state}, group_id : number) => {
        console.log("actions.doReplaceGroupId");
        commit("replaceGroupId", group_id);
    },
    doTeamId: ({commit, state}, team_id : number) => {
        console.log("actions.doReplaceTeamId");
        commit("replaceTeamId", team_id);
    },
    doGroupStatisticsStartDate: ({commit, state}, group_statistics_start_date : string) => {
        console.log("actions.doGroupStatisticsStartDate");
        commit("replaceGroupStatisticsStartDate", group_statistics_start_date);
    },
    doGroupTeamOption: ({commit, state}, group_team_option : any) => {
        console.log("actions.doGroupTeamOption");
        commit("replaceGroupTeamOption", group_team_option);
    },
    doGroupListType: ({commit, state}, list_type : number) => {
        console.log("actions.doGroupListType");
        commit("replaceGroupListType", list_type);
    },
    doSetGroupNoticeSearchInfo: ({commit, state}, group_notice_search_info : GroupNoticeSearchInfo) => {

        console.log(`actions.doSetGroupNoticeSearchInfo ${JSON.stringify(group_notice_search_info)}`);

        state.group_notice_search_info = JSON.parse(JSON.stringify(group_notice_search_info));

        if( sessionStorage != null ){
            sessionStorage.setItem("group_info", JSON.stringify(state));
        }
    },
    doSelectedDirUuid: ({commit, state}, selected_dir_uuid : string) => {
        console.log(`actions.doSelectedDirUuid ${JSON.stringify(selected_dir_uuid)}`);

        state.selected_dir_uuid = selected_dir_uuid;

        if( sessionStorage != null ){
            sessionStorage.setItem("selected_dir_uuid", selected_dir_uuid);
        }
    },
}

// mutation
export const mutations: MutationTree<GroupInfoState> = {
    // replaceGroupInfoList: (state, payload : GroupInfoState) => {
    //     console.log(`mutations.replaceGroupInfoList ${JSON.stringify(payload)}`);

    //     // 원본배열 유지를 위해 splice & concat
    //     state.group_info_list.splice(0, state.group_info_list.length);
    //     state.group_info_list = state.group_info_list.concat(payload.group_info_list);

    //     if( sessionStorage != null ){
    //         sessionStorage.setItem("group_info", JSON.stringify(state));
    //     }
    // },
    // setAllGroupDataTemp: (state, payload : GroupTeamInfo[]) => {
    //     console.log(`mutations.setAllGroupDataTemp ${JSON.stringify(payload)}`);

    //     state.all_group_data_temp.splice(0, state.all_group_data_temp.length);
    //     state.all_group_data_temp = state.all_group_data_temp.concat(payload);
    // },
    // setNormalGroupData: (state, payload : GroupTeamInfo[]) => {
    //     console.log(`mutations.setNormalGroupData ${JSON.stringify(payload)}`);

    //     state.normal_group_data.splice(0, state.normal_group_data.length);
    //     state.normal_group_data = state.normal_group_data.concat(payload);
    // },
    // setHoduCGroupData: (state, payload : GroupTeamInfo[]) => {
    //     console.log(`mutations.setHoduCGroupData ${JSON.stringify(payload)}`);

    //     state.hodu_c_group_data.splice(0, state.hodu_c_group_data.length);
    //     state.hodu_c_group_data = state.hodu_c_group_data.concat(payload);
    // },
    // setHoduDGroupData: (state, payload : GroupTeamInfo[]) => {
    //     console.log(`mutations.setHoduDGroupData ${JSON.stringify(payload)}`);

    //     state.hodu_d_group_data.splice(0, state.hodu_d_group_data.length);
    //     state.hodu_d_group_data = state.hodu_d_group_data.concat(payload);
    // },
    replaceGroupId: (state, payload : number) => {
        console.log(`mutations.replaceGroupId ${JSON.stringify(payload)}`);

        state.group_id = payload;

        if( sessionStorage != null ){
            sessionStorage.setItem("group_info", JSON.stringify(state));
        }
    },
    replaceTeamId: (state, payload : number) => {
        console.log(`mutations.replaceTeamId ${JSON.stringify(payload)}`);

        state.team_id = payload;

        if( sessionStorage != null ){
            sessionStorage.setItem("group_info", JSON.stringify(state));
        }
    },
    replaceGroupStatisticsStartDate: (state, payload : string) => {
        console.log(`mutations.replaceGroupStatisticsStartDate ${JSON.stringify(payload)}`);

        state.group_statistics_start_date = payload;

        if( sessionStorage != null ){
            sessionStorage.setItem("group_info", JSON.stringify(state));
        }
    },
    replaceGroupTeamOption: (state, payload : any) => {
        console.log(`mutations.replaceGroupTeamOption ${JSON.stringify(payload)}`);

        state.group_team_option = payload;

        if( sessionStorage != null ){
            sessionStorage.setItem("group_info", JSON.stringify(state));
        }
    },
    replaceGroupListType: (state, payload : number) => {
        console.log(`mutations.replaceGroupListType ${JSON.stringify(payload)}`);

        state.list_type = payload;

        if( sessionStorage != null ){
            sessionStorage.setItem("group_info", JSON.stringify(state));
        }
    },
}