
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';

import { organization_enum } from '@/model/organization';

import { directive as onClickaway } from 'vue-clickaway';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        OrganizationRecursion
    },
})
export default class OrganizationEmpWorkTypeSetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;
    
    @Prop() event_bus !: Vue;

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        employees = employees.filter(employee => this.filter_work_type_id.indexOf(employee.work_type_id) == -1);

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            if( this.sort_type == organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME ) {

                if( o1_name == o2_name ) return 0;
                else if( o1_name > o2_name ) return this.sort_direction == SORT_TYPE.ASC ?  1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == SORT_TYPE.ASC ? -1 :  1;
            }

            return 0;
        });

        // 검색
        if( this.search_query != null && this.search_query.length > 0 ) {
            employees = employees.filter(item => // 이름   
                                                 this.hodu_string_includes(item.user_name, this.search_query) ||

                                                 // 근무 타입
                                                 this.hodu_string_includes(this.getWorkTypename(item.work_type_id), this.search_query) );
        }

        return employees;
    }

    /**
     * work_type.contents 같은 시간끼리 뭉치기
     */
    get computedWorkType() {
        return (work_type : any) => {
            
            const list : any[] = [];

            for( const content of work_type.contents ) {

                const am_from          = content.am_from;
                const am_to            = content.am_to;
                const pm_from          = content.pm_from;
                const pm_to            = content.pm_to;
                const is_telecommuting = content.is_telecommuting;

                const targets = list.filter(item => item.am_from          == am_from &&
                                                    item.am_to            == am_to   &&
                                                    item.pm_from          == pm_from &&
                                                    item.pm_to            == pm_to   && 
                                                    item.is_telecommuting == is_telecommuting);

                if( targets.length < 1 ) {
                    list.push({
                        text_array       : [this.getDayName(content.week)],
                        text             : "",
                        am_from          : am_from,
                        am_to            : am_to,
                        pm_from          : pm_from,
                        pm_to            : pm_to,
                        is_telecommuting : is_telecommuting,  
                    });
                    continue;
                }

                targets[0].text_array.push(this.getDayName(content.week));
            }

            for( const item of list ) {
                // 평일, 주말 여부
                let is_week_day = false;
                let is_week_end = false;

                if( item.text_array.indexOf('월') > -1 && item.text_array.indexOf('화') > -1 && item.text_array.indexOf('수') > -1 && item.text_array.indexOf('목') > -1 && item.text_array.indexOf('금') > -1 ) {
                    is_week_day = true;
                }

                if( item.text_array.indexOf('토') > -1 && item.text_array.indexOf('일') > -1 ) {
                    is_week_end = true;
                }

                if( is_week_day == true && is_week_end == true ) {
                    item.text = "평일, 주말";
                    continue;
                }

                else if( is_week_day == true && is_week_end == false ) {
                    item.text = "평일";
                }

                else if( is_week_day == false && is_week_end == true ) {
                    item.text = "주말";
                }

                for( const text of item.text_array ) {
                    if( is_week_day == true && (text == "월" || text == "화" || text == "수" || text == "목" || text == "금") ) continue;
                    if( is_week_end == true && (text == "토" || text == "일" ) ) continue;

                    if( item.text.length > 0 ) item.text += ", ";
                    item.text += text;
                }
            }

            return list;
        };
    }

    departments : any[] = [];
    selected_department : any = { dept_id : -1 };

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];
    work_types : any[] = [];
    search_query : string = "";

    sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE = organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE.NAME;
    sort_direction : SORT_TYPE = SORT_TYPE.ASC;

    department_scroll_height : number = 0;

    is_close_organization : boolean = false;

    work_type_filter_open : boolean = false;
    filter_work_type_id : number[] = [];
    
    async mounted() : Promise<void> {
        this.event_bus?.$on("OrganizationEmpWorkTypeSettingResize", this.handleResize);

        await this.getTotalOrganizationData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            // 조직도 스크롤
            const title_box_height = $('#organization_setting .title_box').outerHeight();
            const title_box2_height = $('#organization_setting .title_box2').outerHeight();
            const all_height = $('#organization_setting .approval_content .wrapper .chk.all').outerHeight();
            const content_top_height = $('#organization_setting .approval_content .content_top').outerHeight();

            this.department_scroll_height = window.innerHeight - (title_box_height ? title_box_height : 0)
                                                            - (title_box2_height ? title_box2_height : 0)
                                                            - (content_top_height ? content_top_height : 0)
                                                            - (all_height ? all_height : 0);


            // 부서원 리스트 스크롤
            const fixed_div_height = $('#organization_setting .approval_content .section_scroll .fixedDiv').outerHeight();

            // @ts-ignore
            $('#organization_emp_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (title_box2_height ? title_box2_height : 0)
                                            - (content_top_height ? content_top_height : 0)
                                            - (fixed_div_height ? fixed_div_height : 0)
            });

            // @ts-ignore
            $('#work_type_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (title_box2_height ? title_box2_height : 0)
                                            - (content_top_height ? content_top_height : 0)
            });

        });
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();
            await this.getOrganizationWorktype();
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 근무 타입 조회
     */
    async getOrganizationWorktype() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_type_info ) {
                throw new Error("조직도 근무 타입 조회 중 오류 발생");
            }

            this.work_types.splice(0, this.work_types.length);
            this.work_types = this.work_types.concat(response.data.data.work_type_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 직원 직급 변경
     */
    async changeEmployeeWorkType(event, employee : any) : Promise<void> {
        try {
            const work_type_id = event.target.value;

            // API 실행
            const data = JSON.parse(JSON.stringify(employee));
            data.work_type_id = work_type_id;

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.POST, data);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 직원 근무 변경 중 오류 발생");
            }

            this.getTotalOrganizationData();

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직원 근무 변경 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 드롭다운 바깥쪽 클릭
     */
    dropdownOutsideClick() : void {
        this.work_type_filter_open = false;
    }

    /**
     * 근무타입 필터 변경
     */
    filterChange(event, work_type_id) : void {
        const checked = event.target.checked;

        if( checked == true && this.filter_work_type_id.indexOf(work_type_id) > -1 ) {
            this.filter_work_type_id.splice(this.filter_work_type_id.indexOf(work_type_id), 1);
        }
        else if( checked == false && this.filter_work_type_id.indexOf(work_type_id) == -1 ) {
            this.filter_work_type_id.push(work_type_id);
        }
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 부서 이름 반환
     */
    getDepartmentName(dept_id : number) : string {
        try {
            const target = this.departments.filter(item => item.dept_id == dept_id);
            if( target.length < 1 ) return "-";
            return target[0].dept_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 근무 타입 이름
     */
    getWorkTypename(work_type_id) : string {
        try {
            const target = this.work_types.filter(item => item.work_type_id == work_type_id);
            if( target.length < 1 ) return "-";
            return target[0].work_type_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 정렬
     */
    sort(sort_type : organization_enum.ORGANIZATION_EMPLOYEE_SORT_TYPE) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user_on.png');
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_emp_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#work_type_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
