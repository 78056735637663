
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, CRUD_TYPE, EVENT_SUB_TYPE, FILE_SIZE, OWNER_TYPE, SHARE_OPTION } from '@/mixin/VueHoduCommon';

import { t_event, t_event_file } from '@/model/event';
import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';

import { namespace } from 'vuex-class';
const GalleryInfo = namespace('GalleryInfo');
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');
const HoduDocInfo = namespace('HoduDocInfo');

import { RRule, RRuleSet, rrulestr } from 'rrule'

import JSZip from 'jszip';
import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class EventGallery extends Mixins(VueHoduCommon) {

    /**
     * 일정 리스트
     */
    get computedEvents() : any[] {

        let events = JSON.parse(JSON.stringify(this.events));

        // 정렬
        return events.sort((o1, o2) => {
            const o1_start = new Date(o1.event_data.schedule_date.start);
            const o2_start = new Date(o2.event_data.schedule_date.start);

            if( o1_start.getTime() < o2_start.getTime() ) {
                return 1;
            }
            else if( o1_start.getTime() > o2_start.getTime() ) {
                return -1;
            }

            return 0;
        });
    }

    /**
     * 파일 리스트
     */
    get computedFiles() : any[] {

        let files = JSON.parse(JSON.stringify(this.files));

        if( this.search_query.trim().length > 0 ) {
           files = files.filter(file => this.hodu_string_includes(file.name, this.search_query) == true); 
        }

        return files;
    }

    // tab : string = ""; // imgs, files TODO vuex
    // files : any[] = []; // TODO vuex

    // search_query : string = ""; // TODO vuex 탭 변경시 초기화
    // date : Date = new Date(); // TODO vuex 탭 변경시 초기화

    /**
     * @GalleryInfo.State
     */
    @GalleryInfo.State tab !: string;
    @GalleryInfo.State events !: any[];
    @GalleryInfo.State files !: any[];
    @GalleryInfo.State search_query !: string;
    @GalleryInfo.State date !: Date;

    /**
     * @GalleryInfo.Action
     */
    @GalleryInfo.Action doSetTab ?: (tab : string) => void;
    @GalleryInfo.Action doSetEvents ?: (events : any[]) => void;
    @GalleryInfo.Action doSetFiles ?: (files : any[]) => void;
    @GalleryInfo.Action doSetSearchQuery ?: (search_query : string) => void;
    @GalleryInfo.Action doSetDate ?: (date : Date) => void;

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetIsFirstRepeatEvent   ?: any; // 조회하는 반복일정이 해당 반복일정의 첫 일정이였다면 true 아니라면 false
    @EventInfo.Action doSetEventOriginalDate    ?: any; // 반복일정 조회시 해당 일정의 원본 date들을 설정
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventImagePreview    ?: any;

    mounted() : void {

        //메뉴 탭
        // $('.titleTab_div h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.galleryConDiv > .galleryDiv').hide();
        // 	$('.galleryConDiv > .galleryDiv').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });

        // //파일 옵션 메뉴 toggle
        // $('.fileMenu').click(function(){ 
        //     $(this).siblings('ul').toggle();         
        // });

        // this.getEventFiles();
        // this.tabChange('imgs');

        if( this.tab == "imgs" ) {
            this.getEvents();
        }

        else if( this.tab == 'files' ) {
            this.getEventFiles();
        }

        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        let target : string = "";

        let title_height = $('#event_gallery .title_box').height();
        let title_tab_div_height = $('#event_gallery .titleTab_div').height();
        let gallery_top = $('#event_gallery .galleryConTop').height();
        let sort_header_height = $('#event_gallery .sortHeader').height();

        let scroll_height : number = 0;         

        if( this.tab == 'imgs' ) {
            target = '#event_img_list';

            scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                               - (title_tab_div_height ? title_tab_div_height : 0)     
                                               - (gallery_top ? gallery_top : 0);
        }

        else if( this.tab == 'files' ) {
            target = '#event_file_list';
            
            scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                               - (title_tab_div_height ? title_tab_div_height : 0)     
                                               - (gallery_top ? gallery_top : 0)
                                               - (sort_header_height ? sort_header_height : 0);
        }

        $(target).css('height', `${scroll_height}px`);
        // // @ts-ignore;
        // $(target).mCustomScrollbar({
        //     axis : 'y',
        //     scrollbarPosition : 'outside',
        //     mouseWheelPixels : 100,
        //     scrollInertia : 60,
        //     autoDraggerLength : false,
        //     setHeight : scroll_height,
        // });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // // @ts-ignore
        // $('#event_img_list').mCustomScrollbar('destroy');

        // // @ts-ignore
        // $('#event_file_list').mCustomScrollbar('destroy');

        this.setScroll();
    }

    /**
     * 일정 조회
     */
    async getEvents() : Promise<void> {

        try {
            await this.hodu_show_indicator();

            let start = moment(this.date).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
            let end = moment(this.date).add('month', 1).set('date', 1).add('day', -1).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

            const start_query : string = moment(start).utc().format();
            const end_query : string = moment(end).utc().format();

            let owner_type  : string = this.scope;
            let owner_id    : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id :
                                       this.scope == OWNER_TYPE.GROUP    ? this.scope_group_id : this.scope_team_id; 

            let calendar_id : string = this.calendar_id;

            let query : string = `?start=${start_query}&end=${end_query}&mycal=${ this.scope != OWNER_TYPE.PERSONAL ? 'false' : 'true' }&syscal=${ this.scope != OWNER_TYPE.PERSONAL ? 'false' : 'true' }&shared=${ this.scope != OWNER_TYPE.PERSONAL ? 'false' : 'true' }`;

            // groups 쿼리 생성
            if( this.user_group_role != null && this.user_group_role.length > 0 ){
                const role_size : number = this.user_group_role.length;
                for( let i = 0; i < role_size; i++ ) {
                    if ( this.user_group_role[i].group_id == null || this.user_group_role[i].group_id < 1 ) {
                        continue
                    }
                    // PERSONAL이 아니라면 해당 그룹의 정보만 추가한다
                    if( this.scope != OWNER_TYPE.PERSONAL ) {
                        if( this.scope_group_id > 0 && this.scope_group_id != Number(this.user_group_role[i].group_id) ) {
                            continue;
                        }

                        let biz_id : string | null = null;
                        let hodu_c_group_length : number = this.hodu_c_group_data.length;
                        for( let j = 0; j < hodu_c_group_length; j++ ) {
                            if( this.hodu_c_group_data[j].group_id == Number(this.user_group_role[i].group_id) ) {
                                biz_id = this.hodu_c_group_data[j].biz_id;
                                break;
                            }
                        }

                        // 판독한 그룹이 biz그룹이 아니라면 || 현재 보고 있는 달력의 비즈 그룹이 아니라면 continue;
                        if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                            continue;
                        }
                    }

                    query += `&groups=${Number(this.user_group_role[i].group_id)}`;
                }
            }

            // teams 쿼리 생성
            if( this.user_team_role != null && this.user_team_role.length > 0 ){
                const role_size : number = this.user_team_role.length;
                for( let i = 0; i < role_size; i++ ){
                    if( this.user_team_role[i].team_id == null || this.user_team_role[i].team_id < 1 ){
                        continue
                    }

                    // PERSONAL이 아니라면 해당 팀의 정보만 추가한다
                    if( this.scope != OWNER_TYPE.PERSONAL ) {
                        if( this.scope_team_id > 0 && this.scope_team_id != Number(this.user_team_role[i].team_id) ) {
                            continue;
                        }

                        let biz_id : string | null = null;
                        let hodu_c_group_length : number = this.hodu_c_group_data.length;
                        for( let j = 0; j < hodu_c_group_length; j++ ) {
                            const team_length : number = this.hodu_c_group_data[j].teams.length;
                            for( let k = 0; k < team_length; k++ ) {
                                if( this.hodu_c_group_data[j].teams[k].team_id == Number(this.user_team_role[i].team_id) ) {
                                    biz_id = this.hodu_c_group_data[j].biz_id;
                                    break;
                                }
                            }

                            // 비즈 아이디를 찾았다면 break;
                            if( biz_id != null ) {
                                break;
                            }
                        }

                        // 판독한 팀이 biz팀이 아니라면 || 현재 보고 있는 달력의 비즈 팀이 아니라면 continue;
                        if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                            continue;
                        }
                    }

                    query += `&teams=${Number(this.user_team_role[i].team_id)}`;
                }
            }

            const response = await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}${query}`, API_METHOD.GET, undefined, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.events ) {
                throw new Error("일정 파일 목록 조회 중 오류 발생");
            }

            const temp_events : any[] = [];

            const events = response.data.data.events.filter(event => (event.event_data.attachment?.imgs?.length ?? 0) > 0);

            console.log(events);

            for( const event of events ) {

                // 가공
                const is_lunar = event.event_data.schedule_date.lunar_yn;
                const is_repeat = event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.rrule.length > 0;

                // 음력 반복 일정
                if( is_lunar && is_repeat ) {
                    
                    let lunar_start : string = event.event_data.schedule_date.lunar_start.replace(/-/ig, '').substring(0, 8);

                    // 평달 반복인지 윤달 반복인지 체크
                    const intercalation_obj : any = await this.hodu_is_intercalation(moment(event.event_data.schedule_date.start).format('YYYYMMDD'));
                    
                    // 윤달인 경우, 현재 양력 연도에 해당 반복 일정이 없다면 넘어간다 
                    if( intercalation_obj.is_intercalation ) {
                        let is_search_target_year : boolean = false;
                        for( const next_date of intercalation_obj.next_date_obj ) {
                            if( next_date.solar_ymd.substr(0, 4) == `${this.date.getFullYear()}` ) {
                                is_search_target_year = true;
                                break;
                            }
                        }

                        if( is_search_target_year == false ) {
                            continue;
                        }
                    }

                    const lunar_list = await this.hodu_lunar_to_solars(lunar_start, intercalation_obj.is_intercalation);

                    console.log(lunar_list);

                    let target_lunar : any = null;
                    for( const lunar of lunar_list ) {
                        if( this.hodu_string_includes(lunar.solar_ymd, `${this.date.getFullYear()}-${`0${this.date.getMonth() + 1}`.slice(-2)}`) == true ) {
                            target_lunar = lunar;
                            break;
                        }
                    }

                    if( target_lunar != null ) {
                        const new_event = JSON.parse(JSON.stringify(event));
                        new_event.original_start = new_event.event_data.schedule_date.start;
                        new_event.original_end = new_event.event_data.schedule_date.end;

                        const target_date = new Date(target_lunar.solar_ymd);

                        const new_start = moment(new_event.event_data.schedule_date.start).set('year', target_date.getFullYear()).set('month', target_date.getMonth()).set('date', target_date.getDate());
                        const new_end = moment(new_event.event_data.schedule_date.end).set('year', target_date.getFullYear()).set('month', target_date.getMonth()).set('date', target_date.getDate());

                        new_event.event_data.schedule_date.start = new_start.toDate();
                        new_event.event_data.schedule_date.end = new_end.toDate();

                        temp_events.push(new_event);
                    }

                    continue;
                }

                // 반복 일정
                else if( !is_lunar && is_repeat ) {
                    const dtstart = moment(event.event_data.schedule_date.start).utc().format("YYYYMMDDTHHmmss") + 'Z';
                    const until = moment(event.event_data.schedule_date.recurrence_end).utc().format("YYYYMMDDTHHmmss") + 'Z';

                    const rrule : RRule | RRuleSet = rrulestr(`DTSTART:${dtstart}\nRRULE:${event.event_data.schedule_date.rrule};UNTIL=${until}`);

                    if( event.event_data.schedule_date.exdate == null ) event.event_data.schedule_date.exdate = [];
                    const exdate_list : Date[] = event.event_data.schedule_date.exdate;
                    exdate_list.sort();

                    const recurrence_id : Date | null = event.event_data.schedule_date.recurrence_id ? new Date(event.event_data.schedule_date.recurrence_id) : null;

                    const repeat_dates = rrule.all();

                    // 반복이면서 잘려나온 일정은 무시한다 (이 일정만 삭제된것이거나 이후 일정 삭제된것임)
                    if( recurrence_id != null && event.event_data.schedule_date.rrule != null ) {
                        continue;
                    }

                    for( const repeat_date of repeat_dates ) {
                        
                        const repeat_yyyymmdd = moment(repeat_date).format('YYYYMMDD');
                        
                        if( recurrence_id != null ) {
                            const recurrence_id_yyyymmdd = moment(recurrence_id).format('YYYYMMDD');
                            if( repeat_yyyymmdd == recurrence_id_yyyymmdd ) continue;
                        }

                        let find_exdate = false;

                        for( const exdate of exdate_list ) {
                            const exdate_yyyymmdd = moment(exdate).format('YYYYMMDD');

                            if( repeat_yyyymmdd == exdate_yyyymmdd ) {
                                find_exdate = true;
                                break;
                            }
                        }

                        if( find_exdate ) continue;
                        
                        const target_date = moment(repeat_date).toDate();
                        if( !(start.getTime() <= target_date.getTime() && target_date.getTime() <= end.getTime()) ) {
                            continue;
                        }

                        const new_event = JSON.parse(JSON.stringify(event));
                        new_event.original_start = new_event.event_data.schedule_date.start;
                        new_event.original_end = new_event.event_data.schedule_date.end;
                        
                        const new_start = moment(new_event.event_data.schedule_date.start).set('year', repeat_date.getFullYear()).set('month', repeat_date.getMonth()).set('date', repeat_date.getDate());
                        const new_end = moment(new_event.event_data.schedule_date.end).set('year', repeat_date.getFullYear()).set('month', repeat_date.getMonth()).set('date', repeat_date.getDate());

                        new_event.event_data.schedule_date.start = new_start.toDate();
                        new_event.event_data.schedule_date.end = new_end.toDate();
                        
                        temp_events.push(new_event);
                    }

                    continue;
                }
                
                // 일반 일정
                temp_events.push(event);
            }

            this.doSetEvents?.(temp_events);

            this.$nextTick(() => {
                this.datepickerInit();
                this.handleResize();
            });

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "일정 사진 목록 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
        
    }

    /**
     * 일정 파일 조회
     */
    async getEventFiles() : Promise<void> {

        try {
            await this.hodu_show_indicator();

            let owner_type  : string = this.scope;
            let owner_id    : number = this.scope == OWNER_TYPE.PERSONAL ? this.user_id :
                                       this.scope == OWNER_TYPE.GROUP    ? this.scope_group_id : this.scope_team_id; 

            let calendar_id : string = this.calendar_id;

            let query : string = `?timezone=${-new Date().getTimezoneOffset()}`;

            if( this.user_group_role != null && this.user_group_role.length > 0 ) {

                for( const group_role of this.user_group_role ) {

                    if ( group_role.group_id == null || group_role.group_id < 1 ) {
                        continue
                    }

                    // PERSONAL이 아니라면 해당 그룹의 정보만 추가한다
                    if( this.scope != OWNER_TYPE.PERSONAL ) {
                        if( this.scope_group_id > 0 && this.scope_group_id != Number(group_role.group_id) ) {
                            continue;
                        }

                        let biz_id : string | null = null;
                        for( let group_data of this.hodu_c_group_data ) {
                            if( group_data.group_id == Number(group_role.group_id) ) {
                                biz_id = group_data.biz_id;
                                break;
                            }
                        }

                        // 판독한 그룹이 프리미엄 그룹이 아니라면 || 현재 보고 있는 달력의 프리미엄 그룹이 아니라면 continue;
                        if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                            continue;
                        }
                    }

                    query += `&groups=${Number(group_role.group_id)}`;
                }

            }

            if( this.user_team_role != null && this.user_team_role.length > 0 ) {

                for( const team_role of this.user_team_role ) {

                    if( team_role.team_id == null || team_role.team_id < 1 ) {
                        continue
                    }

                    // PERSONAL이 아니라면 해당 팀의 정보만 추가한다
                    if( this.scope != OWNER_TYPE.PERSONAL ) {
                        if( this.scope_team_id > 0 && this.scope_team_id != Number(team_role.team_id) ) {
                            continue;
                        }

                        let biz_id : string | null = null;
                        for( const group_data of this.hodu_c_group_data ) {
                            
                            for( const team of group_data.teams ) {
                                if( team.team_id == Number(team_role.team_id) ) {
                                    biz_id = group_data.biz_id;
                                    break;
                                }
                            }

                            // 비즈 아이디를 찾았다면 break;
                            if( biz_id != null ) {
                                break;
                            }

                        }

                        // 판독한 팀이 biz팀이 아니라면 || 현재 보고 있는 달력의 비즈 팀이 아니라면 continue;
                        if( biz_id == null || biz_id != this.scope_group_team_option.biz_id ) {
                            continue;
                        }
                    }

                    query += `&teams=${Number(team_role.team_id)}`;
                }
                
            }

            if( this.hodu_d_group_data.length > 0 && this.scope == OWNER_TYPE.PERSONAL ) {
                for( const hodu_d_group of this.hodu_d_group_data ) {
                    for( const doctor of hodu_d_group.teams ) {
                        const doctor_key : string = `${doctor.biz_id}___${doctor.department_code}___${doctor.doctor_code}`; 
                        query += `&doctors=${doctor_key}`;
                    }
                }
            }

            const response = await this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}/file${query}`, API_METHOD.GET, undefined, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.files ) {
                throw new Error("일정 파일 목록 조회 중 오류 발생");
            }

            this.doSetFiles?.(response.data.data.files);
 
            this.$nextTick(() => this.handleResize());

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "일정 파일 목록 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }

    }

    /**
     * 탭 변경
     */
    tabChange(tab : string) {
        
        this.doSetTab?.(tab);

        if( tab == "imgs" ) {
            this.getEvents();
        }

        if( tab == 'files' ) {
            this.getEventFiles();
            this.doSetDate?.(new Date());
        }

    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    selected_date.setDate(1);

                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(selected_date)}`);
                    this.doSetDate?.(selected_date);
                    this.getEvents();
                },
            }

            // @ts-ignore
            $('#gallery_month').datepicker(datepicker_option);
        });
    }

    /**
     * 날짜 변경
     */
    moveMonth(offset : number) {
        const new_date = new Date(this.date);
        new_date.setMonth(new_date.getMonth() + offset);
        new_date.setDate(1);

        this.doSetDate?.(new_date);
        this.getEvents();
    }

    /**
     * BYTE TO SIZE
     */
    byteToSize(byte, decimals = 2) : string {
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];        
    }

    /**
     * 메뉴 펼치기 접기
     */
    menuOnOff(list : any[], obj : any) {
        for( const ob of list ) {
            if( ob == obj ) {
                obj.on = obj.on == null || obj.on == false ? true : false;
                continue;
            }
            ob.on = false;
        }
        this.$forceUpdate();
    }

    /**
     * 파일에서 일정으로 이동시 사용
     */
    async getEventForMove(file) : Promise<void> {

        try {
            const user_id  : number = file.user_id  == null ? 0 : file.user_id;
            const group_id : number = file.group_id == null ? 0 : file.group_id;
            const team_id  : number = file.team_id  == null ? 0 : file.team_id;

            const scope    : string = user_id > 0 ? OWNER_TYPE.PERSONAL 
                                                : group_id > 0 ? OWNER_TYPE.GROUP : OWNER_TYPE.TEAM;

            const scope_id : number = user_id > 0 ? user_id 
                                                : group_id > 0 ? group_id : team_id;

            switch( file.event_sub_type ) {
                case EVENT_SUB_TYPE.SCHEDULE:
                case EVENT_SUB_TYPE.CARD:
                case EVENT_SUB_TYPE.REPORT:
                case EVENT_SUB_TYPE.MEETINGLOG:
                case EVENT_SUB_TYPE.APPOINTMENT:
                    this.selectScheduleAndCard(file.event_id, scope, scope_id);
                    break;

                case EVENT_SUB_TYPE.WORK:
                    this.selectWork(file.event_id, scope, scope_id);
                    break;
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        // const eventAtomic : t_event = arg.event.extendedProps.eventAtomic

        // if( eventAtomic.event_data.approval && eventAtomic.event_data.approval.approval_uid && eventAtomic.event_data.approval.approval_uid.length > 0 ) {
            
        //     const approver : number[] = Array.from(eventAtomic.event_data.approval.approver ? eventAtomic.event_data.approval.approver : [], x => x.user_id);
        //     const receiver : number[] = Array.from(eventAtomic.event_data.approval.receive_reference ? eventAtomic.event_data.approval.receive_reference : [], x => x.user_id);
            
        //     // 작성자, 결재자, 수신참조만 기안서 화면으로 이동
        //     if( eventAtomic.event_data.event_owner_id == this.user_id || approver.indexOf(this.user_id) > -1 || receiver.indexOf(this.user_id) > -1 ) {
        //         this.hodu_router_push(`/GROUP/${eventAtomic.group_id}/approval/${eventAtomic.event_data.approval.approval_uid}`);
        //     }

        //     return;
        // }

        // // 반복 일정의 날짜 데이터 설정
        // if( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.lunar_yn == false ) {
        //     // 해당 반복일정의 첫 일정인지 구하기
        //     const dtStart : Date = new Date(moment(eventAtomic.event_data.schedule_date.start).format());
            
        //     // DTSTART랑 UNTIL이 이미 들어있다면 제거후 재등록
        //     let rrule_string : string = eventAtomic.event_data.schedule_date.rrule;
        //     if( rrule_string.indexOf(';UNTIL') > -1 ) {
        //         rrule_string = rrule_string.substring(0, rrule_string.indexOf(';UNTIL'));
        //     }
        //     if( rrule_string.indexOf('FREQ') > - 1 ) {
        //         rrule_string = rrule_string.substring(rrule_string.indexOf('FREQ'));
        //     }
        //     eventAtomic.event_data.schedule_date.rrule = rrule_string;

        //     const rrule : RRule | RRuleSet = rrulestr(`DTSTART:${this.formatDateForRruleDTSTARTByUTC(dtStart)}\nRRULE:${eventAtomic.event_data.schedule_date.rrule};UNTIL=${this.formatDateForRruleUNTILByUTC(new Date(moment(eventAtomic.event_data.schedule_date.recurrence_end).format()))}`);

        //     // 해당 반복일정 조각이 반복일정의 첫 조각인지 판단
        //     this.doSetIsFirstRepeatEvent( arg.event.extendedProps.isFirstEvent && ( rrule.all()[0].getTime() == new Date(moment(arg.event.start).format()).getTime() ) );

        //     // 일정의 원래 날짜 store EventInfo에 등록
        //     this.doSetEventOriginalDate({
        //         original_start : new Date(moment(eventAtomic.event_data.schedule_date.start).format()),
        //         original_end : new Date(moment(eventAtomic.event_data.schedule_date.end).format())
        //     });

        // }
        
        // // 음력 반복 일정
        // else if ( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.lunar_yn == true ) {
            
        //     // 일정의 원래 날짜 store EventInfo에 등록
        //     this.doSetEventOriginalDate({
        //         original_start : new Date(moment(eventAtomic.event_data.schedule_date.start).format()),
        //         original_end : new Date(moment(eventAtomic.event_data.schedule_date.end).format())
        //     });
            
        // }

        // const start_date : Date = new Date(moment(arg.event.start).format());
        // start_date.setHours(new Date(moment(eventAtomic.event_data.schedule_date.start).format()).getHours());
        // start_date.setMinutes(new Date(moment(eventAtomic.event_data.schedule_date.start).format()).getMinutes());
        // eventAtomic.event_data.schedule_date.start = start_date; // ATOMIC START
        
        // const end_date : Date = new Date(moment(arg.event.end ? arg.event.end : arg.event.start).format());
        // if( arg.event.allDay == true && (eventAtomic.event_data.schedule_date.isAllDay == true || eventAtomic.event_data.schedule_date.isContinuos == true) ) {
        //     end_date.setSeconds(-1);

        //     // 반복일정 왠지모르지만 종일이면 종료일 1일이 더 추가되는 문제 있음
        //     // if( eventAtomic.event_data.schedule_date.rrule != null && eventAtomic.event_data.schedule_date.rrule.length > 0 ) {
        //     //     end_date.setDate(end_date.getDate() - 1);
        //     // }
            
        // }
        // end_date.setHours(new Date(moment(eventAtomic.event_data.schedule_date.end).format()).getHours());
        // end_date.setMinutes(new Date(moment(eventAtomic.event_data.schedule_date.end).format()).getMinutes());
        // eventAtomic.event_data.schedule_date.end = end_date;   // ATOMIC END

        // // EventInfo에 이벤트 등록
        // this.doSetEvent(eventAtomic);
        // this.doSetEventCrudType(CRUD_TYPE.READ);
        // this.doSetEventShareInfo({
        //     share_option : SHARE_OPTION.SHARE,
        //     user_ids : [],
        //     group_ids : [],
        //     team_ids : [],
        //     group_user_ids : [],
        //     team_user_ids : []
        // });

        // // 일정 등록 페이지로 이동 
        // this.hodu_router_push(`/event/${ eventAtomic.event_id }`);
    }

    /**
     * 일정 조회
     */
    async selectScheduleAndCard(event_id : string, scope : string, scope_id : number) : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${`${scope}-${scope_id}`.toLowerCase()}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                const response_data : any = response.data.data;
                const event : t_event = response_data.event_data;

                // 병원 예약인 경우
                if( event.event_sub_type == EVENT_SUB_TYPE.APPOINTMENT ) {
                    this.doSetAppointmentDetailInfo?.({
                        event : event,
                        is_patient : true,
                    })
                    this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event.event_id}`);
                    return;
                }

                // 노트가 아예 없다면 빈값이라도 넣어주도록 처리 undefined 라서 노트 입력이 아예 안됨
                event.event_data.note = event.event_data.note ? event.event_data.note : "";

                this.doSetEvent(event);
                this.doSetEventCrudType(CRUD_TYPE.READ);
                this.doSetEventShareInfo({
                    share_option : SHARE_OPTION.SHARE,
                    user_ids : [],
                    group_ids : [],
                    team_ids : [],
                    group_user_ids : [],
                    team_user_ids : []
                });

                this.hodu_router_push(`/event/${event_id}`);
                
            }).catch(async(e) => {
                this.hodu_error_process(e, true, true);
            });
    }

    /**
     * 업무 조회
     */
    async selectWork(event_id : string, scope : string, scope_id : number) : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/calendars/${`${scope}-${scope_id}`.toLowerCase()}/works/${scope}/${scope_id}/${event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                const response_data : any = response.data.data;

                // 노트가 아예 없다면 빈값이라도 넣어주도록 처리 undefined 라서 노트 입력이 아예 안됨
                response_data.event_data.event_data.note = response_data.event_data.event_data.note ? response_data.event_data.event_data.note : "";

                this.doSetEvent(response_data.event_data);
                this.doSetEventCrudType(CRUD_TYPE.READ);
                this.doSetEventShareInfo({
                    share_option : SHARE_OPTION.SHARE,
                    user_ids : [],
                    group_ids : [],
                    team_ids : [],
                    group_user_ids : [],
                    team_user_ids : []
                });

                this.hodu_router_push(`/event/${event_id}`);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });
    }
    
    /**
     * 파일 다운로드
     */
    fileDownload(file : any) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 파일 전체 다운로드
     */
    async fileAllDownload(imgs) : Promise<void> {
        
        const selected_files : t_event_file[] = imgs;

        if( selected_files.length < 1 ) {
            this.hodu_show_dialog('alert', '다운로드 할 파일을 선택해주세요', ["확인"], [() => {}]);
            return;
        }

        // 파일 1개라면
        if( selected_files.length == 1 ) {
            this.fileDownload(selected_files[0]);
            return;
        }

        // 파일 여러개는 zip 파일로 받는다

        await this.hodu_show_indicator();

        const zip = new JSZip();

        const file_promise : Promise<void>[] = [];

        for( const file of selected_files ) {

            // 파일 다운로드 Promise
            const promise = this.hodu_download(`/app_images/${file.url}`, "", true)
                .then((response) => {
                    console.log(response);

                    // 다운로드한 파일은 zip 파일 구성에 추가
                    zip.file(file.name, new Blob([response.data]));
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });

            // 병렬처리용 Promise 배열에 넣기
            file_promise.push(promise);
        }

        Promise.all(file_promise)
            .then(async() => {

                // zip 파일 압축
                await zip.generateAsync({type:"blob"}).then((content) => {
                    
                    // 압축 완료시 나오는 blob으로 다운로드
                    try {
                        this.hodu_download_blob(content, `hodu_download_${new Date().getTime()}.zip`);
                    } catch(e) {
                        this.hodu_error_process(e, true, false);
                    }

                });
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            }).finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 일정으로 이동
     */
    goEvent(event) {

        // 반복 일정의 날짜 데이터 설정
        if( event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.lunar_yn == false ) {
            // 해당 반복일정의 첫 일정인지 구하기
            const dtStart : Date = new Date(moment(event.event_data.schedule_date.start).format());
            
            // DTSTART랑 UNTIL이 이미 들어있다면 제거후 재등록
            let rrule_string : string = event.event_data.schedule_date.rrule;
            if( rrule_string.indexOf(';UNTIL') > -1 ) {
                rrule_string = rrule_string.substring(0, rrule_string.indexOf(';UNTIL'));
            }
            if( rrule_string.indexOf('FREQ') > - 1 ) {
                rrule_string = rrule_string.substring(rrule_string.indexOf('FREQ'));
            }
            event.event_data.schedule_date.rrule = rrule_string;

            const dtstart = moment(event.event_data.schedule_date.start).utc().format("YYYYMMDDTHHmmss") + 'Z';
            const until = moment(event.event_data.schedule_date.recurrence_end).utc().format("YYYYMMDDTHHmmss") + 'Z';

            rrule_string = `DTSTART:${dtstart}\nRRULE:${event.event_data.schedule_date.rrule};UNTIL=${until}`;

            const rrule : RRule | RRuleSet = rrulestr(rrule_string);

            // 해당 반복일정 조각이 반복일정의 첫 조각인지 판단
            this.doSetIsFirstRepeatEvent((rrule.all()[0].getTime() == new Date(moment(event.event_data.schedule_date.start).format()).getTime()));

            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : new Date(moment(event.original_start).format()),
                original_end : new Date(moment(event.original_end).format())
            });
        }
        
        // 음력 반복 일정
        else if ( event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.lunar_yn == true ) {
            
            // 일정의 원래 날짜 store EventInfo에 등록
            this.doSetEventOriginalDate({
                original_start : new Date(moment(event.original_start).format()),
                original_end : new Date(moment(event.original_end).format())
            });
            
        }

        // EventInfo에 이벤트 등록
        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.READ);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        // 일정 등록 페이지로 이동 
        this.hodu_router_push(`/event/${event.event_id}`);
    }

    /**
     * 이미지 미리보기
     */
    imagePreviewOpen(event, image : t_event_file) : void {

        // 객체에 첨부 객체 or 이미지객체가 없다면 무시
        if( event.event_data.attachment == null || event.event_data.attachment.imgs == null ) {
            return;
        }

        const image_files : t_event_file[] = event.event_data.attachment.imgs;
        const image_index : number         = event.event_data.attachment.imgs.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : image_files
        });
        this.doSetShowEventImagePreview(true);
    }

}
