
import { ActionTree, MutationTree, GetterTree } from 'vuex';

export const namespaced = true;

import { attendance_enum } from '@/model/attendance';
import moment from 'moment';

export interface AttendanceInfoState {
    calendar_view_type : attendance_enum.ATTENDANCE_CALENDAR_TYPE; // 달력 타입
    start_date         : string;                                   // 마지막으로 보고 있던 달력의 월 시작 날짜
    calendar_height    : number;
    event_limit        : number;
    selected_date      : Date;
    go_to_date         : Date;
}

export const state: AttendanceInfoState = {
    calendar_view_type : attendance_enum.ATTENDANCE_CALENDAR_TYPE.DAYGRID_MONTH,
    start_date : moment(new Date()).format("YYYY-MM-DD").toString(),
    calendar_height : 0,
    event_limit : 6,
    selected_date : new Date(),
    go_to_date : new Date(),
}

// getters
export const getters: GetterTree<AttendanceInfoState, any> = {

    getCalendarViewType : (state) => {
        return state.calendar_view_type;
    },

    getStartDate : (state) => {
        return state.start_date;
    },

    getCalendarHeight : (state) => {
        return state.calendar_height;
    },

    getEventLimit : (state) => {
        return state.event_limit;
    },

    getSelectedDate : (state) => {
        return state.selected_date;
    },

    getGoToDate : (state) => {
        return state.go_to_date;
    },

}

// action
export const actions: ActionTree<AttendanceInfoState, AttendanceInfoState> = {
    
    doSetAttendanceInfo: ({commit, state}, attendance_info : AttendanceInfoState) => {
        console.log(`actions.doSetAttendanceInfo ${JSON.stringify(attendance_info)}`);

        state.calendar_view_type = attendance_info.calendar_view_type;
        state.start_date = attendance_info.start_date;
        state.calendar_height = attendance_info.calendar_height;
        state.event_limit = attendance_info.event_limit;
        state.selected_date = attendance_info.selected_date;
        state.go_to_date = attendance_info.go_to_date;

        if( sessionStorage != null ) { sessionStorage.setItem("attendance_info", JSON.stringify(state)); }
    },

    doSetCalendarViewType: ({commit, state}, calendar_view_type : attendance_enum.ATTENDANCE_CALENDAR_TYPE) => {
        console.log(`actions.doSetCalendarViewType ${JSON.stringify(calendar_view_type)}`);
        state.calendar_view_type = calendar_view_type;
        if( sessionStorage != null ) { sessionStorage.setItem("attendance_info", JSON.stringify(state)); }
    },

    doSetAttendanceStartDate: ({commit, state}, start_date : string) => {
        console.log(`actions.doSetAttendanceStartDate ${JSON.stringify(start_date)}`);
        state.start_date = start_date;
        if( sessionStorage != null ) { sessionStorage.setItem("attendance_info", JSON.stringify(state)); }
    },

    doSetCalendarHeight: ({commit, state}, calendar_height : number) => {
        console.log(`actions.doSetCalendarHeight ${JSON.stringify(calendar_height)}`);
        state.calendar_height = calendar_height;
        if( sessionStorage != null ) { sessionStorage.setItem("attendance_info", JSON.stringify(state)); }
    },

    doSetEventLimit: ({commit, state}, event_limit : number) => {
        console.log(`actions.doSetEventLimit ${JSON.stringify(event_limit)}`);
        state.event_limit = event_limit;
        if( sessionStorage != null ) { sessionStorage.setItem("attendance_info", JSON.stringify(state)); }
    },

    doSetSelectedDate: ({commit, state}, selected_date : Date) => {
        console.log(`actions.doSetSelectedDate ${JSON.stringify(selected_date)}`);
        state.selected_date = selected_date;
        if( sessionStorage != null ) { sessionStorage.setItem("attendance_info", JSON.stringify(state)); }
    },

    doSetGoToDate: ({commit, state}, go_to_date : Date) => {
        console.log(`actions.doSetGoToDate ${JSON.stringify(go_to_date)}`);
        state.go_to_date = go_to_date;
        if( sessionStorage != null ) { sessionStorage.setItem("attendance_info", JSON.stringify(state)); }
    },

}