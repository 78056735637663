
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import { attendance_modal_interface } from '@/model/attendance';
import moment from 'moment';
import { approval_enum } from '@/model/approval';
const ModalInfo = namespace('ModalInfo');

@Component({
    components: {
        
    },
})
export default class AttendanceRequestModal extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State attendance_request_modal_info !: attendance_modal_interface.AttendanceRequestModalInfo

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetAttendanceRequestModalInfo ?: (params : attendance_modal_interface.AttendanceRequestModalInfo) => void;

    attend_info : any[] = [];
    vacation_types : any[] = [];
    work_types : any[] = [];

    date = new Date();

    my_employee_info : any = null;
    my_position_info : any = null;
    my_department_info : any = null;

    is_start_update_mode = false;
    is_end_update_mode = false;

    attend : any = null;
    copy_attend : any = null;

    vacations : any[] = [];
    businesstrip : any[] = [];

    delete_vacation : any[] = [];
    delete_businesstrip : any[] = [];

    attend_state = "20";
    
    start_am_pm = 'AM';
    start_hour = "09";
    start_min = "00";

    end_am_pm = "PM";
    end_hour = "06";
    end_min = "00";

    mounted() : void {

        // $(document).ready(function(){
            
            // $(".statusLi .descript").click(function(){
            //     $(this).parent().find(".dropdown").toggleClass("on");

            //     return false;
            // });

        // });
        
        const yyyymmdd = this.attendance_request_modal_info.yyyymmdd;
        this.date = new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));

        this.getTotalOrganizationData();
    }

    /**
     * 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getOrganizationAttend();
            await this.getOrganizationEmp();
            await Promise.all([this.getOrganizationPosition(), this.getOrganization(), this.getOrganizationVacationTypeInfo()]);

            // if( !this.my_employee_info || !this.my_position_info || !this.my_department_info ) {
            //     throw new Error("근태 상태 조회 중 오류 발생");   
            // }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("근태 상태 조회 중 오류 발생");
            this.close();
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            for( const department of response.data.data.dept_info ) {
                if( this.my_employee_info.dept_id == department.dept_id ) {
                    this.my_department_info = department;
                    break;
                }
            }

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            for( const pos of response.data.data.pos_info ) {
                if( this.my_employee_info.pos_id == pos.pos_id ) {
                    this.my_position_info = pos;
                    break;
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            for( const employee of response.data.data.emp_info ) {
                if( employee.user_id == this.attendance_request_modal_info.user_id ) {
                    this.my_employee_info = employee;
                    break;
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 휴가타입 조회
     */
    async getOrganizationVacationTypeInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.vacation_type_info ) {
                throw new Error("휴가타입 조회 중 오류 발생")
            }

            this.vacation_types.splice(0, this.vacation_types.length);
            this.vacation_types = this.vacation_types.concat(response.data.data.vacation_type_info);
            this.vacation_types.sort((o1, o2) : number => {
                if( o1.vacation_type_id > o2.vacation_type_id ) return 1;
                else return -1;
            });

        } catch(e) {
            throw e
        }
    }

    /**
     * 근태 상태 조회
     */
    async getOrganizationAttend() : Promise<void> {
        
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/${this.attendance_request_modal_info.yyyymmdd}?user_id=${this.attendance_request_modal_info.user_id}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_info ) {
                throw new Error("근태 상태 조회 중 오류 발생");
            }

            this.attend_info.splice(0, this.attend_info.length);
            this.attend_info = this.attend_info.concat(response.data.data.attend_info);

            this.attend = null;
            this.vacations.splice(0, this.vacations.length);
            this.businesstrip.splice(0, this.businesstrip.length);

            for( const attend of this.attend_info ) {
                if( attend.attend_type == 10 ) {
                    this.attend = attend;

                    if( this.attend.attend_state == null ) this.attend.attend_state = '20';

                    this.attend_state = attend.attend_state;
                    
                    if( this.isNormal() == true ) {
                        this.attend_state = "10";
                    }
                    
                    if( attend.start_time != null ) {
                        let start_hour = attend.start_time.substring(0,2);
                        const start_min = attend.start_time.substring(2,4);
                        const start_am_pm = Number(start_hour >= 12) ? 'PM' : 'AM';

                        if( Number(start_hour) >= 13 ) start_hour = `${(Number(start_hour) - 12)}`;
                        if( Number(start_hour) == 0  ) start_hour = `12`;

                        this.start_am_pm = start_am_pm;
                        this.start_hour  = `0${String(start_hour)}`.slice(-2);
                        this.start_min   = start_min;
                    }

                    if( attend.end_time != null ) {
                        let end_hour = attend.end_time.substring(0,2);
                        const end_min = attend.end_time.substring(2,4);
                        const end_am_pm = Number(end_hour >= 12) ? 'PM' : 'AM';

                        if( Number(end_hour) >= 13 ) end_hour = `${(Number(end_hour) - 12)}`;
                        if( Number(end_hour) == 0  ) end_hour = `12`;

                        this.end_am_pm = end_am_pm;
                        this.end_hour  = `0${String(end_hour)}`.slice(-2);
                        this.end_min   = end_min;
                    }

                }
                else if( attend.attend_type == '20' ) {
                    this.vacations.push(attend);
                }
                else if( attend.attend_type == '30' ) {
                    this.businesstrip.push(attend);
                }
            }

            this.copy_attend = this.attend ? JSON.parse(JSON.stringify(this.attend)) : null;

            // 출근 기록이 없다면
            if( this.attend == null ) {
                if( this.isNormal() ) {
                    this.attend_state = '10';
                }
                else {
                    this.attend_state = '20';
                }
            }

        } catch(e) {
            throw e;
        }

    }

    /**
     * 시간 텍스트 가져오기
     */
    getTimeText(time_text : string) : string {
        if( time_text == null || time_text.length < 6 ) return '';

        const yyyymmdd = this.attendance_request_modal_info.yyyymmdd;

        const selected_date = new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));

        const time = [time_text.substring(0,2), time_text.substring(2,4), time_text.substring(4,6)].join(':');
        const target_date = moment(moment(selected_date).format('YYYY-MM-DD') + ` ${time}`).toDate();
        return this.amPmStringToLocaleAmPmString(this.hodu_date_to_format_string(target_date, 'hh:mm a'));
    }

    /**
     * 휴가 이름 반환
     */
    getVacationName(type_id : number) : string {

        try {
            const target = this.vacation_types.filter(vacation => vacation.vacation_type_id == type_id);
            if( target.length > 0 ) return target[0].vacation_type_name;
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * 출장 타입 이름 반환
     */
    getBusinessTripName(type_id : number) : string {
        if( type_id == 1 ) return "종일";
        else if( type_id == 2 ) return "오전출장";
        else if( type_id == 3 ) return "오후출장";
        return '';
    }

    /**
     * 하루 중 얼마나 근무하는가를 반환
     */
    getWorkCnt() : number {

        let attend_daycount = this.attend ? Number(this.attend.daycount) : 1; // TODO work_type에 따라서 값이 바뀌어야함
        let vacation_daycount = 0;
        let businesstrip_daycount = 0;

        for( const vacation of this.vacations ) vacation_daycount += Number(vacation.daycount);
        for( const bt of this.businesstrip ) businesstrip_daycount += Number(bt.daycount);

        return (attend_daycount - (vacation_daycount + businesstrip_daycount) ) <= 0 ? 0 : (attend_daycount - (vacation_daycount + businesstrip_daycount));
    }

    /**
     * '정상' 상태인지 판단
     */
    isNormal() : boolean {

        // 오늘 날짜면 
        const current_date_string = this.attendance_request_modal_info.yyyymmdd;
        const today_date_string = moment().format('YYYYMMDD');

        // 미래 날짜는 무조건 false
        if( Number(current_date_string) > Number(today_date_string) ) {
            return false;
        }

        // 해당 일자의 work_cnt == 0 일때 (휴가 or 근무하는날 아님?) 무조건 정상
        let attend_daycount = this.attend ? Number(this.attend.daycount) : 1; // TODO work_type에 따라서 값이 바뀌어야함
        let vacation_daycount = 0;
        let businesstrip_daycount = 0;

        for( const vacation of this.vacations ) vacation_daycount += Number(vacation.daycount);
        for( const bt of this.businesstrip ) businesstrip_daycount += Number(bt.daycount);

        if( (attend_daycount - (vacation_daycount + businesstrip_daycount) ) <= 0 ) {
            return true;
        }

        // 오늘 날짜는 출근 시각만 찍혔어도 정상 취급
        // if( current_date_string == today_date_string && this.attend.attend_state == '20' ) {
        //     return true;
        // }

        if( this.attend == null ) {
            return false;
        }

        // 그 외 정상 상태인 경우 true, 아니라면 false
        return this.attend.attend_state == '10';
    }

    /**
     * start_time 수정 취소
     */
    cancelUpdateStartTime() : void {

        this.is_start_update_mode = false;

        // 수정 이전으로 변경
        if( this.attend.start_time != null ) {
            let start_hour = this.attend.start_time.substring(0,2);
            const start_min = this.attend.start_time.substring(2,4);
            const start_am_pm = Number(start_hour >= 12) ? 'PM' : 'AM';

            if( Number(start_hour) >= 13 ) start_hour = `${(Number(start_hour) - 12)}`;
            if( Number(start_hour) == 0  ) start_hour = `12`;

            this.start_am_pm = start_am_pm;
            this.start_hour  = start_hour;
            this.start_min   = start_min;
        }
        else {
            this.start_am_pm = "AM";
            this.start_hour  = "09";
            this.start_min   = "00";
        }
    }

    /**
     * end_time 수정 취소
     */
    cancelUpdateEndTime() : void {

        this.is_end_update_mode = false;

        // 수정 이전으로 변경
        if( this.attend.end_time != null ) {
            let end_hour = this.attend.end_time.substring(0,2);
            const end_min = this.attend.end_time.substring(2,4);
            const end_am_pm = Number(end_hour >= 12) ? 'PM' : 'AM';

            if( Number(end_hour) >= 13 ) end_hour = `${(Number(end_hour) - 12)}`;
            if( Number(end_hour) == 0  ) end_hour = `12`;

            this.end_am_pm = end_am_pm;
            this.end_hour  = end_hour;
            this.end_min   = end_min;
        }
        else {
            this.end_am_pm = "PM";
            this.end_hour  = "06";
            this.end_min   = "00";
        }

    }

    /**
     * 근무 안하는날 텍스트 반환 
     */
    getOffText() : string {

        if( this.vacations.length > 0 && this.businesstrip.length > 0 ) {
            return '휴가·출장';
        }

        if( this.vacations.length > 0 ) {
            return '휴가';
        }

        if( this.businesstrip.length > 0 ) {
            return '출장';
        }

        return '휴일';
    }

    /**
     * 휴가 삭제
     */
    deleteVacation(vacation) : void {
        this.vacations.splice(this.vacations.indexOf(vacation), 1);
        this.delete_vacation.push(vacation);
    }

    /**
     * 출장 삭제
     */
    deleteBusinessTrip(businesstrip) : void {
        this.businesstrip.splice(this.businesstrip.indexOf(businesstrip), 1);
        this.delete_businesstrip.push(businesstrip);
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_taget.parent().find('p.img').css('background-image', `url(${replace_image})`);
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetAttendanceRequestModalInfo?.({ show_modal : false, user_id : 0, yyyymmdd : '' });
    }

    /**
     * 취소 처리 함수 (병렬처리용)
     */
    async cancelApproval(approval_uid : string) : Promise<void> {
        try {
            //! 1. 기안서 조회
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${approval_uid}`, API_METHOD.GET);
        
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("기안서 조회 중 오류 발생");
            }

            const approval = response.data.data.approval_info;

            //! 2. 기안서 취소
            approval.approval_state = approval_enum.APPROVAL_STATE.CANCEL;
            
            const response_update = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${approval_uid}?is_not_send_push=true`, API_METHOD.PUT, approval);

            console.log(response_update);

            if( !response_update || !this.isHttpStatusSuccess(response_update.status) ) {
                throw new Error("기안서 취소 중 오류 발생");
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 저장
     */
    async save() : Promise<void> {

        const attend : any = {
            group_id : this.scope_group_id,
            approval_uid : null,
            attend_state : this.attend ? this.attend.attend_state : "20",
            attend_type : 10,
            date_string : this.attendance_request_modal_info.yyyymmdd,

            // TODO 클라이언트에서 daycount 넣을때 현재 work_type에 맞도록 들어가야함
            daycount : this.attend ? this.attend.daycount : 1,

            start_time : this.attend ? this.attend.start_time : null,
            end_time : this.attend ? this.attend.end_time : null,
            type_id : this.attend ? this.attend.end_time : this.my_employee_info.work_type_id,
            user_id : this.my_employee_info.user_id,
        }

        attend.attend_state = this.attend_state;

        // 출근시간 수정 중
        if( this.is_start_update_mode == true ) {
            let start_hour = Number(this.start_hour);

            if( this.start_am_pm == 'PM' && start_hour < 12 ) {
                start_hour = start_hour + 12;
            }

            attend.start_time = `${`0${start_hour}`.slice(-2)}${this.start_min}00`;
        }

        // 퇴근시간 수정 중
        if( this.is_end_update_mode == true ) {
            let end_hour = Number(this.end_hour);

            if( this.end_am_pm == 'PM' && end_hour < 12 ) {
                end_hour = end_hour + 12;
            }

            attend.end_time = `${`0${end_hour}`.slice(-2)}${this.end_min}00`;
        }
        
        // (출근시간 미입력, 퇴근시간 입력)
        if( attend.start_time == null && attend.end_time != null ) {
            alert("출근시간을 입력해주세요");
            return;
        }

        // (출근시간이 퇴근시간 이후)
        if( attend.start_time && attend.end_time && Number(attend.start_time) > Number(attend.end_time) ) {
            alert("출근시간은 퇴근시간 이전 시간으로 입력되어야 합니다");
            return;
        }

        // deleted_vacation (휴가 취소) 
        const approval_promise : Promise<any>[] = [];
        for( const target of this.delete_vacation ) {
            approval_promise.push(this.cancelApproval(target.approval_uid));
        }

        // deleted_businesstrip (출장 취소)
        for( const target of this.delete_businesstrip ) {
            approval_promise.push(this.cancelApproval(target.approval_uid));
        }

        if( approval_promise.length > 0 ) {
            try {
                await this.hodu_show_indicator();
                await Promise.all(approval_promise);

                // 원래부터 attend 정보가 비어있는 null 이였다면
                if( this.attend == null && this.copy_attend == null ) {
                    this.attendance_request_modal_info.callback?.();
                    this.close();
                    return;
                }
                
            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                alert("근태 정보 입력 중 오류 발생");
                await this.hodu_hide_indicator();
                return;
            } 
        }

        // insert attend_type 10
        if( this.attend == null ) {

            // 정상, 지각인데 시간정보가 안들어가는 경우
            if( (attend.attend_state == '10' || attend.attend_state == '30') && attend.start_time == null ) {
                alert("출근 시간 없이 새로운 출근 데이터를 추가 할 수 없습니다");
                return;
            }

            try {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/${this.attendance_request_modal_info.yyyymmdd}`, API_METHOD.POST, attend);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("근태 정보 입력 중 오류 발생");
                }

                this.attendance_request_modal_info.callback?.();
                this.close();

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                alert("근태 정보 입력 중 오류 발생");
            }

        }
        
        // update attend_type 10
        else {

            // 기존 데이터 삭제
            if( attend.start_time == null ) {

                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/${this.attendance_request_modal_info.user_id}/${this.attendance_request_modal_info.yyyymmdd}`, API_METHOD.DELETE);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("근태현황 출·퇴근 현황 삭제 중 오류 발생");
                    }

                    this.attendance_request_modal_info.callback?.();
                    this.close();
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    alert("근태현황 출·퇴근 현황 삭제 중 오류 발생");
                }

            }

            else {

                try {
                    // 기존 데이터 수정
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/${this.attendance_request_modal_info.yyyymmdd}`, API_METHOD.PUT, attend);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("근태 정보 입력 중 오류 발생");
                    }

                    this.attendance_request_modal_info.callback?.();
                    this.close();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    alert("근태 정보 입력 중 오류 발생");
                }

            }

        }
    }

}
