
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const EventInfo = namespace('EventInfo');
const GroupInfo = namespace('GroupInfo');

import { EventWorkAssignmentInfo } from '@/store/modules/ModalInfo';

import { t_event } from '@/model/event';

@Component({

})
export default class EventWorkAssignment extends Mixins(VueHoduCommon) {

    /**
     * ModalInfo.State
     */
    @ModalInfo.State event_work_assignment_info !: EventWorkAssignmentInfo;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEventWorkAssignmentInfo ?: any;

    /**
     * @EventInfo.State
     */
    @EventInfo.State  event !: t_event;

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    get targetUserList() : any[] {
        if( this.user_search_query.length < 1 ) {
            return this.user_list;
        }

        return this.user_list.filter(member => member.user_info.user_name.indexOf(this.user_search_query) > -1);
    } 

    user_list : any[] = [];
    
    user_search_query : string = "";

    member_checked_count : number = 0;
    member_check_all : boolean = false;

    user_search_flag : boolean = false;

    mounted() : void {
        this.getMemebers();
    }

    /**
     * 그룹 or 팀 멤버 리스트 가져오기
     */
    getMemebers() : void {
        const vue = this;
        this.user_list.splice(0, this.user_list.length);

        let target_scope = (this.event.is_temp != true ? this.scope : ((this.event.scope_team_id ?? 0) > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP));

        let request_scope = (target_scope == OWNER_TYPE.GROUP) ? 'groups' : 'teams';
        let request_api = (target_scope == OWNER_TYPE.GROUP) ? 'getGroupMember' : 'getTeamMember';
        let request_group_id = (this.event.is_temp == true ? this.event.scope_group_id : this.scope_group_id);
        let request_team_id = (this.event.is_temp == true ? (this.event.scope_team_id ?? 0) : this.scope_team_id) > 0 ? `/${ (this.event.is_temp == true ? this.event.scope_team_id : this.scope_team_id) }` : '';

        if( this.event_work_assignment_info.is_group_member_select == true ) {
            request_scope = 'groups';
            request_api = 'getGroupMember';
            request_group_id = this.group_id;
            request_team_id = '';
        }

        // api/v1/groups/getGroupMember/{group_id} 또는 api/v1/teams/getTeamMember/{group_id}/{team_id}
        this.hodu_api_call(`api/v1/${request_scope}/${request_api}/${request_group_id}${request_team_id}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                vue.user_list = vue.user_list.concat(response.data.data.list);

                console.log(vue.event_work_assignment_info.selected_user_ids);
                const user_list_length : number = vue.user_list.length;
                for( let i = 0; i < user_list_length; i++ ) {
                    const user_obj : any = vue.user_list[i];
                    user_obj.checked = vue.event_work_assignment_info.selected_user_ids.indexOf(user_obj.user_id) > -1
                    vue.user_list.splice(i, 1, user_obj);

                    if( user_obj.checked == true ) {
                        vue.member_checked_count++
                    }
                }

                if( vue.member_checked_count == vue.user_list.length ) {
                    this.member_check_all = true;
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 멤버 전체 선택
     */
    memberAllSelect() : void {
        const user_list_count : number = this.user_list.length;
        for( let i = 0; i < user_list_count; i++ ) {
            const member_obj : any = JSON.parse(JSON.stringify(this.user_list[i]));
            member_obj.checked = ( this.member_check_all == true ); // 전체선택값
            this.user_list.splice(i, 1, member_obj);
        }

        if( this.member_check_all == false ) {
            this.member_checked_count = 0;
        }
    }

    /**
     * 그룹, 팀 멤버 클릭시 count 변경 및 전체 선택 체크 변경
     */
    memberClick(event, user) : void {

        // 하나만 선택가능이면 나머지 체크 풀기
        // if( event.target.checked == true && this.event_work_assignment_info.is_select_one == true ) {

        //     for( const user of this.targetUserList ) {
        //         user.checked = false;
        //     }
        //     user.checked = true;
        //     this.$forceUpdate();
        // }

        let checked_count = 0;
        for( const user of this.targetUserList ) {
            if( user.checked == true ) checked_count++;
        }

        if( event.target.checked == true && this.event_work_assignment_info.select_max_count != null && this.event_work_assignment_info.select_max_count < checked_count ) {
            user.checked = false;
            $(event.target).prop("checked", false);
            this.$forceUpdate();
        }

        this.member_checked_count = this.getMemberCheckedCount();
        this.member_check_all     = this.member_checked_count == this.user_list.length;
    }

    /**
     * 그룹, 팀 멤버가 체크된게 몇명인지 반환
     */
    getMemberCheckedCount() : number {
        try {
            let checked_memeber_count : number = 0;
                
            const user_list_count : number = this.user_list.length;
            for( let i = 0; i < user_list_count; i++ ) {
                if( this.user_list[i].checked == true ) {
                    checked_memeber_count++;
                }
            }

            return checked_memeber_count;

        } catch(e) {
            this.hodu_error_process(e, false, false);
            return 0;
        }

    }

    /**
     * 검색 버튼 toggle
     */
    searchButtonClick() : void {
        
        this.user_search_flag = !this.user_search_flag;

        // 검색을 끄는 경우
        if( this.user_search_flag == false ) {
            this.user_search_query = '';
        }

    }

    /**
     * 취소 버튼
     */
    clickCancelButton() : void {
        this.doSetEventWorkAssignmentInfo({
            show_event_work_assignment : false,
            selected_user_ids          : [],
            confirm_function           : () => {}
        })
    }

    /**
     * 배정 버튼
     */
    clickAssignButton() : void {
        if( this.event_work_assignment_info.confirm_function == null ) {
            return;
        }

        const user_ids : number[] = [];

        // check 되어있는 아이디 체크 및 push
        const user_list_length : number = this.user_list.length;
        for( let i = 0; i < user_list_length; i++ ) {
            if( this.user_list[i].checked == true ) {
                user_ids.push(this.user_list[i].user_id);
            }
        }

        if( this.event_work_assignment_info.is_group_member_select != true ) {
            if( user_ids.length < 1 ) { 
                alert("최소 1명 이상 선택 해야 합니다"); 
                return;
            }
        }

        // length가 리스트의 length와 같다면 assignment_type == 'ALL', 1명이라도 배정됐다면 'ASSIGN', 아무도 배정하지 않았다면 'UNDEF'  
        const assignment_type : string = user_ids.length == this.user_list.length ? 'ALL' : 'ASSIGN';

        this.event_work_assignment_info.confirm_function(user_ids, assignment_type, this.user_list.length);
    }
}
