
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupNoticeReadInfo, LoginHospitalModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {

    }
}) export default class LoginHospitalSelectModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State login_hospital_select_modal_info !: LoginHospitalModalInfo

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetLoginHospitalModalInfo ?: (param : LoginHospitalModalInfo) => void;

    default_hospital_image : string = require("@/assets/images/contents/imgDc7.png");

    biz_type = 'BIZD';

    mounted() : void {
        if( this.login_hospital_select_modal_info.hospital_list.length > 0 ) {
            this.biz_type = this.login_hospital_select_modal_info.hospital_list[0].biz_type;
        }
    }

    getText() : string {
        switch(this.biz_type) {
            case "BIZD": return '병원';
            case "BIZH": return '아파트';
            case "KIDS": return '호두키즈';
            default: return '';
        }
    }

    /**
     * 선택한 병원으로 이동
     */
    selectHospital(biz_id : string) {
        if ( this.login_hospital_select_modal_info.callback == null ) { 
            return;
        }
        this.login_hospital_select_modal_info.callback(biz_id)
        this.login_hospital_select_modal_info.show_modal = false
    }

    /**
     * 확인 버튼 클릭
     */
    clickCloseButton() : void {
        if ( this.doSetLoginHospitalModalInfo ) {
            this.doSetLoginHospitalModalInfo({
                show_modal    : false,
                hospital_list : []
            })
        }
    }

}

