
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupSurveyChoiceListModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class GroupSurveyChoiceListModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_survey_choice_list_modal_info !: GroupSurveyChoiceListModalInfo;

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupSurveyChoiceListModalInfo ?: (params : GroupSurveyChoiceListModalInfo) => void;

    mounted() : void {

    }

    close() : void {
        this.doSetGroupSurveyChoiceListModalInfo?.({ show_modal : false, data : null });
    }

}
