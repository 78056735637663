
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class GroupNotice extends Mixins(VueHoduCommon) {
    /**
     * Prop 변수 선언부
     */
    @Prop() private group_id !: number;
    @Prop() private team_id  !: number;

    /**
     * 변수 선언부
     */
    notice_type : string = "";
    notice_data : any = [];
    notice_slide_number : number = 0;

    async mounted() {
        this.getNotice();
    }

    /**
     * 그룹 공지사항 조회
     */
    async getNotice() : Promise<void> {
        this.notice_data = [];
        this.notice_type = (this.team_id == 0 ? "GROUP" : "TEAM");

        await this.hodu_api_call(`api/v1/notification/notice/${this.group_id}/${this.team_id}/${this.notice_type}?page=0&size=10&current_date=${moment(new Date()).utc().format()}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                this.notice_data = response.data.data.list;
                this.notice_slide_number = 0;
            })
            .catch(async(e) => {

            });
    }

    /**
     * 공지사항 페이징 처리
     */
    notice_paging(stat) : void {
        stat == "next" ? this.notice_slide_number ++ : this.notice_slide_number --;
    }

    /**
     * 공지사항 상세보기
     */
    async detailNotice(po_data) : Promise<void> {
        const vue = this;

        // 공지사항 읽음여부
        if ( !po_data.read_flag ) {
            await this.hodu_api_call(`api/v1/notification/insertRead/${po_data.noti_uid}`, API_METHOD.PUT, {}, false)
                .then(async(response) => {
                    vue.$emit('increment', po_data.noti_uid);
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }
        else {
            this.$emit('increment', po_data.noti_uid);
        }
    }
}
