
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';

const EtcInfo   = namespace('EtcInfo');
const GroupInfo = namespace('GroupInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({

}) 
export default class MessageTemplate extends Mixins(VueHoduCommon) {
    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;

    /**
     * EtcInfo.Action
     */
    @EtcInfo.Action doMessageUid ?: any;

    list : any = [];

    async mounted() : Promise<void> {
        if ( !this.group_id || this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
        
        this.doMessageUid("");
        this.getMessageTemplate();
    }
    
    goMessageTemplateAdd() : void {
        this.doMessageUid("");
        this.hodu_router_push(`/message/${new Date().getTime()}/template_add`);
    }

    /**
     * 메시지 템플릿 조회
     */
    getMessageTemplate() : void {
        this.list = [];

        this.hodu_api_call(`api/v1/message_template/GROUP/${this.group_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.list = response.data.data.list;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 메시지 템플릿 삭제
     */
    deleteMessage(ps_uid) : void {
        this.hodu_show_dialog("alert", "메세지를 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.hodu_api_call(`api/v1/message_template/${ps_uid}`, API_METHOD.DELETE, null)
                    .then(async(response) => {
                        this.getMessageTemplate();
                    })
                    .catch(async(e) => {
                        this.hodu_error_process(e, true, false);
                    });
            },
        ]);
    }

    /**
     * 메세지 수정
     */
    messageUpdate(ps_uid, pi_user_id) : void {
        if ( this.user_id != pi_user_id )  {
            return;
        }

        this.doMessageUid(ps_uid);
        this.hodu_router_push(`/message/${new Date().getTime()}/template_add`);
    }
}

