import {GetterTree, MutationTree , ActionTree} from "vuex";

export const namespaced = true;

export interface LoginInfoState {
    isLogin: boolean;
    device_uid: string;
    session_token: string | null;
    
    // 유저 정보
    user_id           ?: number  | null;
    user_type         ?: string  | null;
    user_name         ?: string  | null;
    user_email        ?: string  | null;
    user_phone_number ?: string  | null;
    country_code      ?: string  | null;
    is_temp_password  ?: boolean | null;
    user_preference   ?: any;
    user_group_role   ?: any;
    user_team_role    ?: any;
    template_map      ?: any;
    auth_info         ?: any;
    friends           ?: any[];

    // 이미지 캐시 관련
    image_time         : number;

    // 달력 로딩 관련
    loading_bouncy : boolean;
    loading_end_1  : boolean;
    loading_end_2  : boolean;
    loading_end_3  : boolean;
}

export const state: LoginInfoState = {
    isLogin: false,
    device_uid : "0000-0000-00000000",
    session_token: null,

    // 유저 정보
    user_id           : 0,
    user_type         : "",
    user_name         : "",
    user_email        : "",
    user_phone_number : "",
    country_code      : "",
    is_temp_password  : false,
    user_preference   : null,
    user_group_role   : null,
    user_team_role    : null,
    template_map      : null,
    auth_info         : {
        auth_check: false, 
        auth_check_date: null, 
        auth_delay_date: null, 
        auth_check_later: false
    },

    friends : [],

    // 이미지 캐시 관련
    image_time : new Date().getTime(),

    // 로딩 관련
    loading_bouncy : false,
    loading_end_1  : true,
    loading_end_2  : true,
    loading_end_3  : true
};


// getters
export const getters: GetterTree<LoginInfoState, any> = {

    isLogin: (state) => {
        return state.isLogin;
    },

    getUserId: (state) => {
        return state.user_id;
    },

    getUserType: (state) => {
        return state.user_type;
    },

    getUserName: (state) => {
        return state.user_name;
    },

    getUserEmail: (state) => {
        return state.user_email;
    },

    getUserPhoneNumber: (state) => {
        return state.user_phone_number;
    },

    getCountryCode: (state) => {
        return state.country_code;
    },

    isTempPassword: (state) => {
        return state.is_temp_password;
    },

    getUserPreference: (state) => {
        return state.user_preference;
    },

    getUserGroupRole : (state) => {
        return state.user_group_role;
    },

    getUserTeamRole : (state) => {
        return state.user_team_role;
    },

    getTemplateMap : (state) => {
        return state.template_map;
    },

    getAuthInfo : (state) => {
        return state.auth_info;
    },

    getFriends : (state) => {
        return state.friends;
    },

    getImageTime : (state) => {
        return state.image_time;
    },

    getLoadingBouncy : (state) => {
        return state.loading_bouncy;
    },

    getLoadingEnd1 :(state) => {
        return state.loading_end_1;
    },

    getLoadingEnd2 :(state) => {
        return state.loading_end_2;
    },

    getLoadingEnd3 :(state) => {
        return state.loading_end_3;
    }
};

export const actions: ActionTree<LoginInfoState, LoginInfoState> = {

    doLogin: ({commit, state}, user_info : LoginInfoState) => {
        console.log(`actions.doLogin ${JSON.stringify(user_info)}`);

        state.isLogin           = user_info.isLogin;
        state.user_id           = user_info.user_id;
        state.user_type         = user_info.user_type;
        state.user_email        = user_info.user_email;
        state.user_name         = user_info.user_name;
        state.user_phone_number = user_info.user_phone_number;
        state.country_code      = user_info.country_code;
        state.is_temp_password  = user_info.is_temp_password;
        state.user_preference   = user_info.user_preference;
        state.user_group_role   = user_info.user_group_role;
        state.user_team_role    = user_info.user_team_role;
        state.template_map      = user_info.template_map ? JSON.parse(JSON.stringify(user_info.template_map)) : null;
        state.auth_info         = user_info.auth_info ? JSON.parse(JSON.stringify(user_info.auth_info)) : null;

        // 세션에 login_info 추가
        if( sessionStorage != null ){
            sessionStorage.setItem("login_info", JSON.stringify(state));
        }
        // commit("login",user_info);
    },

    doSetUserId: ({commit, state}, user_id : number) => {
        console.log(`actions.doSetUserId ${user_id}`);
        state.user_id = user_id;

        // 세션에 login_info 추가
        if( sessionStorage != null ){
            sessionStorage.setItem("login_info", JSON.stringify(state));
        }
    },

    doSetUserPreference: ({commit, state}, user_preference : any) => {
        console.log("actions.doSetUserNmae");
        
        if( state.user_preference == null ) {
            state.user_preference = user_preference; 
        }
        else {
            state.user_preference.splice(0, state.user_preference.length);
            state.user_preference = state.user_preference.concat(user_preference);
        }
    },

    doSetUserName: ({commit, state}, user_name : string) => {
        console.log("actions.doSetUserNmae");
        commit("setUserName", user_name);
    },

    doSetUserPhoneNumber: ({commit, state}, user_phone_number : string) => {
        console.log("actions.doSetUserPhoneNumber");
        commit("setUserPhoneNumber", user_phone_number);
    },

    doSetCountryCode: ({commit, state}, country_code : string) => {
        console.log(`actions.doSetCountryCode ${country_code}`);
        
        state.country_code = country_code;
        
        // 세션에 login_info 추가
        if( sessionStorage != null ){
            sessionStorage.setItem("login_info", JSON.stringify(state));
        }
    },

    doSetGroupRole: ({commit, state}, user_group_role : any) => {
        console.log(`actions.doSetGroupRole ${user_group_role}`);

        state.user_group_role = user_group_role;

        // 세션에 login_info 추가
        if( sessionStorage != null ){
            sessionStorage.setItem("login_info", JSON.stringify(state));
        }

        // commit("setGroupRole", user_group_role);
    },

    doSetTeamRole: ({commit, state}, user_team_role : any) => {
        console.log(`actions.doSetTeamRole ${user_team_role}`);

        state.user_team_role = user_team_role;

        // 세션에 login_info 추가
        if( sessionStorage != null ){
            sessionStorage.setItem("login_info", JSON.stringify(state));
        }

        // commit("setTeamRole", user_team_role);
    },

    doSetAuthInfo: ({commit, state}, auth_info : any) => {
        console.log(`actions.doSetAuthInfo ${JSON.stringify(auth_info)}`);

        state.auth_info = auth_info ? JSON.parse(JSON.stringify(auth_info)) : null;

        // 세션에 login_info 추가
        if( sessionStorage != null ){
            sessionStorage.setItem("login_info", JSON.stringify(state));
        }
    },

    doSetFriends: ({commit, state}, friends : any) => {
        console.log(`actions.doSetFriends ${JSON.stringify(friends)}`);

        if( state.friends == null || friends == null ) {
            state.friends = friends;
        }
        else {
            state.friends.splice(0, state.friends.length);
            state.friends = state.friends.concat(friends);
        }
    },

    doSetImageTime: ({commit, state}, image_time : any) => {
        console.log("actions.doSetImageTime");
        commit("setImageTime", image_time);
    },

    doSetLoadingBouncy: ({commit, state}, loading_bouncy : boolean) => {
        console.log("actions.doSetLoadingBouncy");
        commit("setLoadingBouncy", loading_bouncy);
    },

    doSetLoadingEnd1: ({commit, state}, loading_end_1 : boolean) => {
        console.log("actions.doSetLoadingEnd1");
        commit("setLoadingEnd1", loading_end_1);
    },

    doSetLoadingEnd2: ({commit, state}, loading_end_2 : boolean) => {
        console.log("actions.doSetLoadingEnd2");
        commit("setLoadingEnd2", loading_end_2);
    },

    doSetLoadingEnd3: ({commit, state}, loading_end_3 : boolean) => {
        console.log("actions.doSetLoadingEnd3");
        commit("setLoadingEnd3", loading_end_3);
    },

};

export const mutations: MutationTree<LoginInfoState> = {

    setUserName: (state, payload : string) => {
        console.log(`mutations.setUserName ${JSON.stringify(payload)}`);
        state.user_name = payload;

        // 세션에 login_info 추가
        if( sessionStorage != null ){
            sessionStorage.setItem("login_info", JSON.stringify(state));
        }
    },

    setUserPhoneNumber: (state, payload : string) => {
        console.log(`mutations.setUserName ${JSON.stringify(payload)}`);
        state.user_phone_number = payload;

        // 세션에 login_info 추가
        if( sessionStorage != null ){
            sessionStorage.setItem("login_info", JSON.stringify(state));
        }
    },

    // setGroupRole: (state, payload : any) => {
    //     console.log(`mutations.setGroupRole ${JSON.stringify(payload)}`);
    //     state.user_group_role = payload;

    //     // 세션에 login_info 추가
    //     if( sessionStorage != null ){
    //         sessionStorage.setItem("login_info", JSON.stringify(state));
    //     }
    // },

    // setTeamRole: (state, payload :  any) => {
    //     console.log(`mutations.setTeamRole ${JSON.stringify(payload)}`);
    //     state.user_team_role = payload;

    //     // 세션에 login_info 추가
    //     if( sessionStorage != null ){
    //         sessionStorage.setItem("login_info", JSON.stringify(state));
    //     }
    // },

    setImageTime: (state, payload : number) => {
        console.log(`mutations.setImageTime ${JSON.stringify(payload)}`);
        state.image_time = payload;
    },

    setLoadingBouncy: (state, payload : boolean) => {
        console.log(`mutations.setLoadingBouncy ${JSON.stringify(payload)}`);
        state.loading_bouncy = payload;
    },

    setLoadingEnd1: (state, payload : boolean) => {
        console.log(`mutations.setLoadingEnd1 ${JSON.stringify(payload)}`);
        state.loading_end_1 = payload;
    },

    setLoadingEnd2: (state, payload : boolean) => {
        console.log(`mutations.setLoadingEnd2 ${JSON.stringify(payload)}`);
        state.loading_end_2 = payload;
    },

    setLoadingEnd3: (state, payload : boolean) => {
        console.log(`mutations.setLoadingEnd3 ${JSON.stringify(payload)}`);
        state.loading_end_3 = payload;
    },

};
