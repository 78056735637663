
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const CalendarInfo = namespace('CalendarInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
}) export default class VisitantList extends Mixins(VueHoduCommon) {
    
    /**
     * 선택된 동의 리스트만 반환 
     */
    get computedCarListByTargetBuilding() : any[] {
        let final_list : any[] = JSON.parse(JSON.stringify(this.car_list));

        final_list = final_list.filter(item => new RegExp(escape(this.search_word)).test(escape(item.car_info.car_number)) == true);

        if( this.target_building == 'TARGET_ALL') {
            return final_list;
        }

        final_list = final_list.filter(item => item.resident_info.building == this.target_building);
        
        return final_list;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeVisitantCreateModalInfo ?: (params : hodu_home_modal_info.HomeVisitantCreateModalInfo) => void;
    @ModalInfo.Action doSetHomeVisitantInfoModalInfo ?: (params : hodu_home_modal_info.HomeVisitantInfoModalInfo) => void;
    
    /**
     * @CalendarInfo.State
     */
    @CalendarInfo.State go_to_date !: Date;              

    target_building : string = "TARGET_ALL";

    apartment_building_list : any[] = [];
    car_list : any[] = [];

    start_date : Date = new Date();
    end_date : Date = new Date();

    is_search_open : boolean = false;
    search_word    : string = "";
    get_visitant_interval : number | undefined;

    is_first_api_call : boolean = false;
    new_car_id_list : string[] = [];
    new_audio : HTMLAudioElement = new Audio(require('@/assets/audio/bell.mp3'));

    buildings : any[] = [];

    mounted() : void {
        const vue = this;
        
        $(".hoduhomeUl li a").click(function(){
            $(".modalDiv.hoduhome1").addClass("on");
            return false;
        });

        this.clickToday();

        // 5초마다 예약 재조회
        this.get_visitant_interval = window.setInterval(async() => {
            this.getBuildings();
            this.getHoduHomeInfo();
            this.getCarList();
        }, 5000);

        const datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
                inst.input.val(mnt.format('YYYY.MM.DD') + ' ' + vue.getDayOfWeekByDate(mnt.toDate()));
                
                // 시작일 변경
                if( id == 'visitant_fr_ymd' ) {
                    vue.start_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.end_date = moment(vue.start_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
                    }
                } 

                // 종료일 변경
                else if( id == 'visitant_to_ymd' ) {
                    vue.end_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.start_date = moment(vue.end_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                    }
                }

                vue.end_date = moment(vue.end_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

                vue.getBuildings();
                vue.getHoduHomeInfo();
                vue.getCarList();
            },
        }

        // @ts-ignore
        $('#visitant_fr_ymd').datepicker(datepicker_option);

        // @ts-ignore
        $('#visitant_to_ymd').datepicker(datepicker_option);

        window['getBuildings'] = this.getBuildings();
        window['getHoduHomeInfo'] = this.getHoduHomeInfo;
        window['getCarList'] = this.getCarList;

        this.setScroll();
    }

    /**
     * 종료 전 interval 제거
     */
    beforeDestroy() : void {
        if( this.get_visitant_interval ) { clearInterval(this.get_visitant_interval); }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hoduhome_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });
    }

    /**
     * 동 조회
     */
    async getBuildings() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/building`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.buildings ) {
                throw new Error("동 조회 중 오류 발생");
            }

            this.buildings.splice(0, this.buildings.length);
            this.buildings = this.buildings.concat(response.data.data.buildings);

        } catch(e) {
            this.hodu_show_dialog("cancel", "동 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 호두홈 정보 조회
     */
    async getHoduHomeInfo() : Promise<void> {
        
        await this.hodu_api_call(`api/v1/home/info/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null, false)
            .then((response) => {
                console.log(response);
                const home_info : any = response.data.data.home_info; 

                this.apartment_building_list.splice(0, this.apartment_building_list.length);

                if( home_info.building_info != null ) {
                    this.apartment_building_list = this.apartment_building_list.concat(home_info.building_info);
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })
    }

    /**
     * 차량 조회(방문차량)
     */
    getCarList() : void {

        const start = moment(this.start_date).utc().format(); 
        const end   = moment(this.end_date).utc().format();
 
        this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}?is_resident=false&start_date=${start}&end_date=${end}&is_web=true`, API_METHOD.GET, null, false)
            .then((response) => {
                console.log(response);

                this.car_list.splice(0, this.car_list.length);
                this.car_list = this.car_list.concat(response.data.data.car_list);

                let is_audio_play : boolean = false;
                for( const new_car of this.car_list.filter(item => item.car_info.is_new == true) ) {
                    if( this.new_car_id_list.indexOf(new_car.car_id) > -1 ) { continue; }

                    this.new_car_id_list.push(new_car.car_id);
                    is_audio_play = true;
                }

                // 알람 간격 정해서 넣을것
                if( this.is_first_api_call == true && is_audio_play == true ) {
                    this.new_audio.play();
                }

                if( this.is_first_api_call == false ) { this.is_first_api_call = true; }
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })
    }

    /**
     * 업데이트
     */
    async updateCarInfo(car : any) : Promise<void> {

        await this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${car.car_id}`, API_METHOD.PUT, car)
            .then((response) => {
                console.log(response);

                this.new_car_id_list.splice(this.new_car_id_list.indexOf(car.car_id), 1);
                this.getHoduHomeInfo();
                this.getCarList();
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });

    }

    /**
     * 검색창 열기 / 닫기
     */
    searchOnOff() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_word = "";
        }

        // 검색 창 열릴때 검색 창에 포커스 가도록
        else {
            this.$nextTick(() => { $('#visitant_search_text').focus(); });
        }
    }

    /**
     * 조회 대상 동을 변경
     */
    changeTargetBuilding(value : string) : void {
        this.target_building = value;
    }

    /**
     * 모두 승인
     */
    applyAllVisitantList() : void {
        
        const new_list = this.car_list.filter(item => item.car_info.is_new == true);

        if( new_list.length < 1 ) {
            this.hodu_show_dialog('alert', '해당 기간에는 신규 방문차량이 존재하지 않습니다', ['확인'])
            return;
        }

        this.hodu_show_dialog('alert', '신규 방문차량을 모두 승인 처리 하시겠습니까?', ['아니오', '예'], [
            () => {},
            () => {

                const promise_list : Promise<void>[] = [];
                for( const car of new_list ) {
                    promise_list.push(this.applyVisitantCar(car));
                }

                Promise.all(promise_list)
                    .then(() => {
                        this.new_car_id_list.splice(0, this.new_car_id_list.length);
                        this.getBuildings();
                        this.getHoduHomeInfo();
                        this.getCarList();
                        this.hodu_show_dialog('success', '신규 방문차량 모두 승인 완료', ['확인']);
                    })
                    .catch((e) => {
                        this.hodu_show_dialog('cancel', '신규 방문차량 모두 승인 중 오류 발생', ['확인']);
                    })
                
            }
        ])

    }

    /**
     * 차량 승인
     */
    async applyVisitantCar(car : any) : Promise<void> {
        // 새로운게 아니라면 날짜 지난거 반응 X
        if( car.car_info.is_new == false && new Date(car.end_date).getTime() < new Date().getTime() ) { return; }

        // 이미 승인된거는 반응 X
        if( car.car_info.is_new == false && car.car_info.is_apply == true ) { return; }

        car.car_info.is_new = false;
        car.car_info.is_apply = true;

        await this.updateCarInfo(car);
    }

    /**
     * 차량 거절
     */
    rejectVisitantCar(car : any) : void {
        // 새로운게 아니라면 날짜 지난거 반응 X
        if( car.car_info.is_new == false && new Date(car.end_date).getTime() < new Date().getTime() ) { return; }

        // 이미 거절된거는 반응 X
        if( car.car_info.is_new == false && car.car_info.is_apply == false ) { return; }

        car.car_info.is_new = false;
        car.car_info.is_apply = false;

        this.updateCarInfo(car);
    }

    /**
     * 오늘 클릭
     */
    clickToday() : void {
        this.start_date = moment().set('hour', 0)
                                  .set('minute', 0)
                                  .set('second', 0)
                                  .set('millisecond', 0).toDate();

        this.end_date = moment(this.start_date).set('hour', 23)
                                               .set('minute', 59)
                                               .set('second', 59)
                                               .set('millisecond', 999).toDate();

        this.getBuildings();
        this.getHoduHomeInfo();
        this.getCarList();
    }

    /**
     * 시간 텍스트 반환
     */
    getTimeText(car : any) : string {

        const start_date = new Date(new Date(car.start_date));
        const end_date = new Date(car.end_date);
        
        const start = `${this.hodu_date_to_format_string(start_date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;

        // 포맷 (같은 년월은 표시하지 않는다)
        let end_format = 'YY.MM.DD';
        if( start_date.getFullYear() == end_date.getFullYear() ) {
            end_format = 'MM.DD';

            if( start_date.getMonth() == end_date.getMonth() ) {
                end_format = 'DD';

                if( start_date.getDate() == end_date.getDate() ) {
                    end_format = '';
                }
            }
        }

        const end   = `${this.hodu_date_to_format_string(end_date, end_format)} ${this.getDayOfWeekByDate(end_date)}`;
        // const term = `(${this.getDateDiff(start_date, end_date) + 1}일)`
        const term = `(방문기간 ${this.getDateDiff(start_date, end_date) + 1}일)`

        // return `${start}${end_format == '' ? ' ' : ` ~ ${end} `}${term}`;
        return `${start} ${term}`;
    }

    /**
     * 방문 차량 생성 모달
     */
    showVisitantCreateModal() : void {
        if( this.doSetHomeVisitantCreateModalInfo == null ) { return; }

        this.doSetHomeVisitantCreateModalInfo({
            show_modal : true
        });
    }

    /**
     * 방문 차량 정보 모달
     */
    showVisitantInfoModal(car : any) : void {
        if( this.doSetHomeVisitantInfoModalInfo == null ) { return; }
        
        const send_car = JSON.parse(JSON.stringify(car));
        // send_car.resident_info.building = this.apartment_building_list.filter(item => item.team_id == car.resident_info.building)[0].team_info.team_name;

        this.doSetHomeVisitantInfoModalInfo({
            show_modal : true,
            car : send_car,
        });
    }

    /**
     * 왼쪽 inline datepicker 날짜 변경 감지
     */
    @Watch('go_to_date')
    onGoToDateChange() : void {
        this.start_date = moment(this.go_to_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
        this.end_date = moment(this.go_to_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

        this.getBuildings();
        this.getHoduHomeInfo();
        this.getCarList();
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#hoduhome_list_scroll").mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
