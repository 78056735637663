
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { GROUP_TYPE, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { local_storage_info } from '@/lib/HoduLocalStorage';

import { GroupTeamInfo } from '@/store/modules/GroupInfo';
import { GroupAndTeamFilter } from '@/store/modules/CalendarInfo';
import { hodu_doc_object } from '@/model/hodudoc';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class RightFilter extends Mixins(VueHoduCommon) {

    /**
     * 그룹&팀 달력일때 가져올 리스트
     */
    get getGroupAndTeam() : GroupTeamInfo[] {
        if( this.scope == OWNER_TYPE.PERSONAL ){
            return [];
        }

        const groupAndTeam : GroupTeamInfo[] = [];
        const hodu_c_group_data_length : number = this.hodu_c_group_data.length;
        for( let i = 0; i < hodu_c_group_data_length; i++ ) {
            const group_info : GroupTeamInfo = JSON.parse(JSON.stringify(this.hodu_c_group_data[i]));
            if( group_info.group_id != this.scope_group_id ) {
                continue;
            }

            let group_and_team_filter : GroupAndTeamFilter | null = null;
            const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
            for( let j = 0; j < filter_length; j++ ) {
                if( this.scope_group_team_option.biz_id == this.schedule_search_config.group_and_team_filter[j].biz_id &&
                    this.scope == this.schedule_search_config.group_and_team_filter[j].scope &&
                    (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) == this.schedule_search_config.group_and_team_filter[j].scope_id ) {
                    group_and_team_filter = this.schedule_search_config.group_and_team_filter[j];
                    break;
                }
            }

            const team_length : number = group_info.teams.length;
            for( let j = team_length - 1; j >= 0; j-- ) {
                const team_info : GroupTeamInfo = JSON.parse(JSON.stringify(group_info.teams[j]));
                
                if( this.scope_team_id > 0 && team_info.team_id != this.scope_team_id ) {
                    group_info.teams.splice(j, 1);
                    continue;
                }

                // team_info 체크 정보 가공
                team_info.checked = true;
                if( group_and_team_filter != null && group_and_team_filter.team_ids != null && group_and_team_filter.team_ids.indexOf(team_info.team_id) > -1 ) {
                    team_info.checked = false;
                }

                group_info.teams.splice(j, 1, team_info);
            }

            // group_info 체크 정보 가공
            group_info.listOn  = true;
            group_info.checked = true;
            if( group_and_team_filter != null && group_and_team_filter.group_id != null && group_and_team_filter.group_id == group_info.group_id ) {
                group_info.checked = false;
            }
            groupAndTeam.push(group_info);
        }
        return groupAndTeam;
    }

    /**
     * 호두 D 정보로 필터 제작
     */
    get computedHoduDDepartmentAndDoctor() : GroupTeamInfo[] {

        if( this.recomputed ) { return []; } 

        let department_and_doctor : GroupTeamInfo[] = [];

        // 진료과, 의사 정보가 있다면 필터로 제작한다 (department : 그룹, doctor : 팀)
        if( this.department_info != null && this.doctor_info != null ) {

            for( const department of this.department_info ) {
                
                // 해당 진료과인 의사만 
                const doctors : hodu_doc_object.doctor_info[] = this.doctor_info.filter(item => item.department_code == department.department_code);
                
                // 해당 진료과에 의사가 없으면 건너뜀
                if( doctors.length < 1 ) { continue; }
                
                const teams : GroupTeamInfo[] = [];

                for( const doctor of doctors) {
                    teams.push({
                        level : 2,
                        row_type : "DOCTOR",
                        group_id : this.scope_group_id,
                        team_id : 0,
                        biz_id : this.scope_group_team_option.biz_id,
                        biz_type : this.scope_group_team_option.biz_type,
                        group_name : doctor.department_name,
                        team_name : doctor.doctor_name,
                        color : doctor.doctor_detail.color,
                        team_count : 0,
                        group_features : [],
                        group_info : {},
                        limit_event_attachment : 0,
                        limit_event_image_count : 0,
                        user_count : 0,
                        checked : this.schedule_search_config.group_appointment_filter.indexOf(`${this.scope_group_team_option.biz_id}___${doctor.department_code}___${doctor.doctor_code}`) < 0, // 필터에 없다면 체크
                        shareChecked : false,
                        listOn : false,
                        shareListOn : false,
                        teams : [],
                        department_code : doctor.department_code,
                        doctor_code : doctor.doctor_code,
                        css_style : this.schedule_search_config.group_appointment_filter.indexOf(`${this.scope_group_team_option.biz_id}___${doctor.department_code}___${doctor.doctor_code}`) < 0 
                                    ? `background-color : ${doctor.doctor_detail.color}; border-color : ${doctor.doctor_detail.color};`
                                    : '',
                        role : []
                    })
                }

                const is_list_on : boolean | undefined = this.appointment_list_on_map.get(department.department_code);

                // 진료과 추가 
                department_and_doctor.push({
                    level : 1,
                    row_type : "DEPARTMENT",
                    group_id : this.scope_group_id,
                    team_id : 0,
                    biz_id : this.scope_group_team_option.biz_id,
                    biz_type : this.scope_group_team_option.biz_type,
                    group_name : department.department_name,
                    team_name : '',
                    color : this.scope_group_team_option.group_team_color,
                    team_count : teams.length,
                    group_features : [],
                    group_info : {},
                    limit_event_attachment : 0,
                    limit_event_image_count : 0,
                    user_count : 0,
                    checked : teams.filter(item => item.checked == true).length > 0, // 하나라도 체크 된게 있다면 true
                    shareChecked : false,
                    listOn : is_list_on ? is_list_on : false,
                    shareListOn : false,
                    teams : teams,
                    department_code : department.department_code,
                    css_style : teams.filter(item => item.checked == true).length > 0 
                                ? `background-color : ${this.scope_group_team_option.group_team_color}; border-color : ${this.scope_group_team_option.group_team_color};`
                                : '',
                    role: []
                });

            }

        }

        return department_and_doctor;
    }

    past_color_style : string = "";
    basic_color_style : string = "";
    report_color_style : string = "";
    meetinglog_color_style : string = "";
    project_color_style : string = "";
    vacation_color_style : string = "";
    businesstrip_color_style : string = "";
    recomputed : boolean = false;

    appointment_list_on_map : Map<string, boolean> = new Map();

    personal_list_on          : boolean = false;
    group_list_on             : boolean = false;
    hodu_c_list_on            : boolean = false;
    hodu_d_list_on            : boolean = false; 
    group_and_team_list_on    : boolean = false;
    group_appointment_list_on : boolean = false;
    system_list_on            : boolean = false;  
    
    async mounted() : Promise<void> {
        /* 필터 메뉴 on / off */
        // $(".calFil_drop, .calFil_secChild").each(function(){
        //     $(".calFil_drop, .calFil_secChild").off("click").on("click", function() {
        //         if ($(".calFil_drop, .calFil_secChild").hasClass("on")) { 

        //             $(".calFil_drop, .calFil_secChild").removeClass("on");
        //         }
        //         else {
        //             $(".calFil_drop, .calFil_secChild").addClass("on");
        //         }
        //     });
        // });
        $(".calFil_system_oen").off("click").on("click", function() {
            if ($(".calFil_system_oen").hasClass("on")) { 

                $(".calFil_system_oen").removeClass("on");
            }
            else {
                $(".calFil_system_oen").addClass("on");
            }
        });

        // 필터 정보가 없다면 기본 정보 세팅
        // if( local_storage_info.filter_info == null ) {
        //     local_storage_info.filter_info = {
        //         basic : true,
        //         work : true,
        //         report : true,
        //         meetinglog : true,
        //         vacation : true,
        //         businesstrip : true,
        //     }
            // hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info));
        // }

        // get filter info
        await this.getFilterInfo();

        // 필터 체크 색상 재적용하는 함수 등록
        window["setFilterCheckColor"] = this.setFilterCheckColor;

        // 선적용
        this.$nextTick(() => this.setFilterCheckColor());
        
        await this.get_group_role_service();

        if( this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD ) {
            await this.get_hodu_d_info(this.scope_group_team_option.biz_id);
        }  
        
        // 싱크후 재적용
        this.$nextTick(() => this.setFilterCheckColor());

        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const calfil_titlebox_height = $('.calfil_titlebox').outerHeight();
        const scroll_height = window.innerHeight - (calfil_titlebox_height ? calfil_titlebox_height : 0);

        // @ts-ignore
        $('#right_filter_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height
        });

    }

    /**
     * 필터 정보 가져오기
     */
    async getFilterInfo() : Promise<void> {
        try {
            const filter_info = await this.get_user_preference('filter', true);
            const preference = filter_info.preference;
            const web_filter_info = preference['WEB'];
        
            const event_sub_type_filter_info = web_filter_info.filter_info;
            const schedule_search_config = web_filter_info.schedule_search_config;

            if( event_sub_type_filter_info != null ) {
                local_storage_info.filter_info = JSON.parse(JSON.stringify(event_sub_type_filter_info));
            }
            else {
                local_storage_info.filter_info = {
                    basic : true,
                    work : true,
                    report : true,
                    meetinglog : true,
                    vacation : true,
                    businesstrip : true,
                }
            }

            if( schedule_search_config != null ) {
                if( schedule_search_config.system_calendar == null ) {
                    schedule_search_config.system_calendar = true;
                }
                this.doSetScheduleSearchConfig(schedule_search_config);
            }
            else {
                this.doSetScheduleSearchConfig({
                    past_schedule : true,
                    personal_schedule : true,
                    my_schedule : true,
                    shared_schedule : true,
                    group_schedule : true,
                    hodu_c_schedule : true,
                    hodu_d_schedule : true,
                    group_appointment : true,
                    system_calendar : true,
                    group_filter : [],
                    team_filter : [],
                    group_and_team_filter : [],
                    hodu_d_filter : [],
                    group_appointment_filter : [],       
                    update_tag : 0,
                });
            }
            

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            local_storage_info.filter_info = {
                basic : true,
                work : true,
                report : true,
                meetinglog : true,
                vacation : true,
                businesstrip : true,
            }
            this.doSetScheduleSearchConfig({
                past_schedule : true,
                personal_schedule : true,
                my_schedule : true,
                shared_schedule : true,
                group_schedule : true,
                hodu_c_schedule : true,
                hodu_d_schedule : true,
                group_appointment : true,
                system_calendar : true,
                group_filter : [],
                team_filter : [],
                group_and_team_filter : [],
                hodu_d_filter : [],
                group_appointment_filter : [],       
                update_tag : 0,
            });
        }
        this.$forceUpdate();
    }

    past_schedule_click(event){
        event.stopPropagation();
        this.schedule_search_config.past_schedule = !this.schedule_search_config.past_schedule;
        this.filterToDB();
    }

    personal_schedule_click(event){
        this.schedule_search_config.my_schedule     = this.schedule_search_config.personal_schedule;
        this.schedule_search_config.shared_schedule = this.schedule_search_config.personal_schedule;
        this.filterToDB();
    }

    my_schedule_check_click(event){
        event.stopPropagation();
        this.schedule_search_config.my_schedule = !this.schedule_search_config.my_schedule;
        this.filterToDB();
    }

    shared_schedule_check_click(event){
        event.stopPropagation();
        this.schedule_search_config.shared_schedule = !this.schedule_search_config.shared_schedule;
        this.filterToDB();
    }

    system_calendar_check_click(event){
        event.stopPropagation();
        this.schedule_search_config.system_calendar = !this.schedule_search_config.system_calendar;
        this.filterToDB();
    }

    /**
     * 일반 그룹&팀 전부 ON/OFF
     */
    group_schedule_click(event){

        const normal_group_size : number = this.normal_group_data.length;
        for( let i = 0; i < normal_group_size; i++ ){
            
            this.normal_group_data[i].checked = event.target.checked;

            // 그룹 필터에 추가or제거
            if( !this.normal_group_data[i].checked ){
                if( this.schedule_search_config.group_filter.indexOf(Number(this.normal_group_data[i].group_id)) == -1 ){
                    this.schedule_search_config.group_filter.push(Number(this.normal_group_data[i].group_id));
                }
            } else {
                const group_filter_index : number = this.schedule_search_config.group_filter.indexOf(this.normal_group_data[i].group_id);

                if( group_filter_index != -1 ){
                    this.schedule_search_config.group_filter.splice(group_filter_index, 1);
                }
            }

            if( this.normal_group_data[i].teams != null && this.normal_group_data[i].teams.length > 0 ){
                const hodu_c_team_size : number = this.normal_group_data[i].teams.length;
                for( let j = 0; j < hodu_c_team_size; j++ ){
                    this.normal_group_data[i].teams[j].checked = this.schedule_search_config.group_schedule;

                    // 팀 필터에 추가or제거
                    if( !this.normal_group_data[i].teams[j].checked ){
                        if( this.schedule_search_config.team_filter.indexOf(Number(this.normal_group_data[i].teams[j].team_id)) == -1 ){
                            this.schedule_search_config.team_filter.push(Number(this.normal_group_data[i].teams[j].team_id));
                        }
                    } else {
                        const team_filter_index : number = this.schedule_search_config.team_filter.indexOf(this.normal_group_data[i].teams[j].team_id);

                        if( team_filter_index != -1 ){
                            this.schedule_search_config.team_filter.splice(team_filter_index, 1);
                        }
                    }
                }
            }
        }

        this.filterToDB();
    }

    /**
     * 호두C 그룹&팀 전부 ON/OFF
     */
    hodu_c_schedule_click(event){
        
        const hodu_c_group_size : number = this.hodu_c_group_data.length;
        for( let i = 0; i < hodu_c_group_size; i++ ){
            
            this.hodu_c_group_data[i].checked = event.target.checked;

            // 그룹 필터에 추가or제거
            if( !this.hodu_c_group_data[i].checked ){
                if( this.schedule_search_config.group_filter.indexOf(Number(this.hodu_c_group_data[i].group_id)) == -1 ){
                    this.schedule_search_config.group_filter.push(Number(this.hodu_c_group_data[i].group_id));
                }
            } else {
                const group_filter_index : number = this.schedule_search_config.group_filter.indexOf(this.hodu_c_group_data[i].group_id);

                if( group_filter_index != -1 ){
                    this.schedule_search_config.group_filter.splice(group_filter_index, 1);
                }
            }

            if( this.hodu_c_group_data[i].teams != null && this.hodu_c_group_data[i].teams.length > 0 ){
                const hodu_c_team_size : number = this.hodu_c_group_data[i].teams.length;
                for( let j = 0; j < hodu_c_team_size; j++ ){
                    this.hodu_c_group_data[i].teams[j].checked = this.schedule_search_config.hodu_c_schedule;

                    // 팀 필터에 추가or제거
                    if( !this.hodu_c_group_data[i].teams[j].checked ){
                        if( this.schedule_search_config.team_filter.indexOf(Number(this.hodu_c_group_data[i].teams[j].team_id)) == -1 ){
                            this.schedule_search_config.team_filter.push(Number(this.hodu_c_group_data[i].teams[j].team_id));
                        }
                    } else {
                        const team_filter_index : number = this.schedule_search_config.team_filter.indexOf(this.hodu_c_group_data[i].teams[j].team_id);

                        if( team_filter_index != -1 ){
                            this.schedule_search_config.team_filter.splice(team_filter_index, 1);
                        }
                    }
                }
            }
        }

        this.filterToDB();
    }

    /**
     * 호두 D 전체 ON / OFF
     */
    hodu_d_schedule_click(event) : void {
        const hodu_d_group_size : number = this.hodu_d_group_data.length;
        for( let i = 0; i < hodu_d_group_size; i++ ){
            
            this.hodu_d_group_data[i].checked = event.target.checked;
            
            for( let j = 0; j < this.hodu_d_group_data[i].teams.length; j++ ) {
                this.hodu_d_group_data[i].teams[j].checked = event.target.checked;
                const doctor_key = `${this.hodu_d_group_data[i].teams[j].biz_id}___${this.hodu_d_group_data[i].teams[j].department_code}___${this.hodu_d_group_data[i].teams[j].doctor_code}`;
                const doctor_index = this.schedule_search_config.hodu_d_filter.indexOf(doctor_key);

                // 체크 해제 상태가 되었을때 필터에 추가
                if( !this.hodu_d_group_data[i].checked ){
                    if( doctor_index < 0 ) { this.schedule_search_config.hodu_d_filter.push(doctor_key); }
                } 
                
                // 체크 상태가 되었을때 필터에서 제외
                else {
                    if( doctor_index > -1 ) { this.schedule_search_config.hodu_d_filter.splice(doctor_index, 1); }
                }
            }

        }

        this.filterToDB();
    }

    /**
     * on -> 모든 팀 활성화, off -> 모든 팀 비활성화
     */
    @Debounce(1)
    group_filter_click(event, target_list : any, group_obj : any, type : string, group_index : number){
        

        // console.log("=============================group_filter_click=============================")
        // console.log(target_list);
        // console.log(group_obj);
        // console.log(type);
        // console.log(group_index);
        // console.log("============================================================================")

        if( group_index == -1 ) {
            return;
        }
    
        // 그룹 checked 변경
        group_obj.checked = !group_obj.checked;

        // 팀 전체 checked 그룹과 똑같도록 변경
        if( group_obj.teams != null && group_obj.teams.length > 0 ){
            const team_size : number = group_obj.teams.length;
            for( let i = 0; i < team_size; i++ ){
                const team_obj : any = group_obj.teams[i];
                team_obj.checked = group_obj.checked;
                group_obj.teams.splice(i, 1, team_obj);
            }
        }

        switch ( type ) {
            case "NORMAL":
                this.normal_group_data.splice(group_index, 1, group_obj);
                break;

            case "HODU_C":
                this.hodu_c_group_data.splice(group_index, 1, group_obj);
                break;

            case "HODU_D":
                this.hodu_d_group_data.splice(group_index, 1, group_obj);
                break;
        }

        // 호두닥 필터
        if( type == "HODU_D" ) {
            
            const doctor_count : number = group_obj.teams.length;
            for( let i = 0; i < doctor_count; i++ ) {
                const doctor_key = `${group_obj.teams[i].biz_id}___${group_obj.teams[i].department_code}___${group_obj.teams[i].doctor_code}`;
                const doctor_index = this.schedule_search_config.hodu_d_filter.indexOf(doctor_key);
                
                // 체크 해제 상태가 되었을때 필터에 추가
                if( !group_obj.checked ) { 
                    if( doctor_index < 0 ) { this.schedule_search_config.hodu_d_filter.push(doctor_key); }
                }

                // 체크 상태가 되었을때 필터에서 제외
                else { 
                    if( doctor_index > -1 ) { this.schedule_search_config.hodu_d_filter.splice(doctor_index, 1); }
                }
            }

            let is_unchecked_all : boolean = true;
            for( const group_data of this.hodu_d_group_data ) {
                if( group_data.checked == true ) { 
                    is_unchecked_all = false;
                    break;    
                }
            }

            this.schedule_search_config.hodu_d_schedule = !is_unchecked_all;

        }

        // 일반 필터
        else {
            
            // console.log(group_obj.checked);

            // 그룹, 팀 아이디 필터에 추가or제거
            if( !group_obj.checked ){
                if( this.schedule_search_config.group_filter.indexOf(Number(group_obj.group_id)) == -1 ){
                    this.schedule_search_config.group_filter.push(Number(group_obj.group_id));
                }
            } else {
                const group_filter_index : number = this.schedule_search_config.group_filter.indexOf(group_obj.group_id);

                if( group_filter_index != -1 ){
                    this.schedule_search_config.group_filter.splice(group_filter_index, 1);
                }
            }

            // console.log("group_filter=" + JSON.stringify(this.schedule_search_config.group_filter));

            if( group_obj.teams != null && group_obj.teams.length > 0 ){
                const team_size : number = group_obj.teams.length;
                for( let i = 0; i < team_size; i++ ){
                    const team_obj : any = group_obj.teams[i];
                    
                    if( !team_obj.checked ){
                        if( this.schedule_search_config.team_filter.indexOf(Number(team_obj.team_id)) == -1 ){
                            this.schedule_search_config.team_filter.push(Number(team_obj.team_id));
                        }
                    } else {
                        const team_filter_index : number = this.schedule_search_config.team_filter.indexOf(team_obj.team_id);

                        if( team_filter_index != -1 ){
                            this.schedule_search_config.team_filter.splice(team_filter_index, 1);
                        }
                    }
                }
            }
        }

        this.filterToDB();
    }

    /**
     * 해당 팀만 on / off
     */
    @Debounce(1)
    team_filter_click(target_list : any, group_obj : any, team_obj : any, type : string){
        const group_index : number = target_list.indexOf(group_obj);
        const team_index  : number = group_obj.teams.indexOf(team_obj);

        if( group_index == -1 || team_index == -1 ) {
            return;
        } 
    
        team_obj.checked = !team_obj.checked;

        // 호두닥 필터
        if( type == 'HODU_D' ) {
            const doctor_key = `${team_obj.biz_id}___${team_obj.department_code}___${team_obj.doctor_code}`;
            const doctor_index = this.schedule_search_config.hodu_d_filter.indexOf(doctor_key);

            // 체크 해제 상태가 되었을때 필터에 추가
            if( !team_obj.checked ) { 
                if( doctor_index < 0 ) { this.schedule_search_config.hodu_d_filter.push(doctor_key); }
            }

            // 체크 상태가 되었을때 필터에서 제외
            else { 
                if( doctor_index > -1 ) { this.schedule_search_config.hodu_d_filter.splice(doctor_index, 1); }
            }

        }

        // 일반 필터
        else {
            // 팀 아이디 필터에 추가or제거
            if( !team_obj.checked ){
                if( this.schedule_search_config.team_filter.indexOf(Number(team_obj.team_id)) == -1 ){
                    this.schedule_search_config.team_filter.push(Number(team_obj.team_id));
                }
            } else {
                const team_filter_index : number = this.schedule_search_config.team_filter.indexOf(team_obj.team_id);

                if( team_filter_index != -1 ){
                    this.schedule_search_config.team_filter.splice(team_filter_index, 1);
                }
            }
        }

        group_obj.teams.splice(team_index, 1, team_obj);

        switch ( type ) {
            case "NORMAL":
                this.normal_group_data.splice(group_index, 1, group_obj);
                break;

            case "HODU_C":
                this.hodu_c_group_data.splice(group_index, 1, group_obj);
                break;

            case "HODU_D":
                group_obj.checked = this.schedule_search_config.hodu_d_filter.filter(key => new RegExp(group_obj.biz_id).test(key) == true).length < group_obj.teams.length;
                this.hodu_d_group_data.splice(group_index, 1, group_obj);
                break;
        }

        if( type == "HODU_D" ) {
            let is_unchecked_all : boolean = true;
                for( const group_data of this.hodu_d_group_data ) {
                    if( group_data.checked == true ) { 
                        is_unchecked_all = false;
                        break;    
                    }
                }

            this.schedule_search_config.hodu_d_schedule = !is_unchecked_all;
        }

        this.filterToDB();
    }

    /**
     * 깊이 1 그룹메뉴 ON / OFF
     */
    depth01_onOff(type : string) : void {
        switch (type) {
            case OWNER_TYPE.PERSONAL:
                this.personal_list_on = !this.personal_list_on;
                break;

            case "GROUP":
                this.group_list_on = !this.group_list_on;
                break;

            case "HODU_C":
                this.hodu_c_list_on = !this.hodu_c_list_on;
                break;

            case "HODU_D":
                this.hodu_d_list_on = !this.hodu_d_list_on;
                break;

            case "GROUP_AND_TEAM":
                this.group_and_team_list_on = !this.group_and_team_list_on;
                break;

            case "APPOINTMENT":
                this.group_appointment_list_on = !this.group_appointment_list_on;
                break;
            
            case "SYSTEM":
                this.system_list_on = !this.system_list_on;
                break;
        }
    }

    /**
     * 깊이 2 그룹메뉴 ON / OFF
     */
    depth02_onOff(event, teams_length : number, group_data : any, type : string, group_index : number) : void {
        
        /**
         * 하위 내용이 없으면 클릭
         */
        if( teams_length < 1 ){
            switch( type ) {
                case "GROUP":
                    this.group_filter_click(event, this.normal_group_data, group_data, type, group_index);
                    break;

                case "HODU_C":
                    this.group_filter_click(event, this.hodu_c_group_data, group_data, type, group_index);
                    break;

                case "HODU_D":
                    this.group_filter_click(event, this.hodu_d_group_data, group_data, type, group_index);
                    break;
            }
            
            return;
        }

        /**
         * 하위 내용이 있으면 리스트 펼치기
         */
        let data_index : number = -1;
        let is_list_on : boolean | undefined;
        switch (type) {
            case "GROUP":
                data_index = this.normal_group_data.indexOf(group_data);

                // index out 방지
                if( data_index == -1 ){
                    return;
                }
                group_data.listOn = !group_data.listOn
                this.normal_group_data.splice(data_index, 1, group_data);
                break;

            case "HODU_C":
                data_index = this.hodu_c_group_data.indexOf(group_data);

                // index out 방지
                if( data_index == -1 ){
                    return;
                }
                group_data.listOn = !group_data.listOn
                this.hodu_c_group_data.splice(data_index, 1, group_data);
                break;       
                
            case "HODU_D":
                data_index = this.hodu_d_group_data.indexOf(group_data);

                // index out 방지
                if( data_index == -1 ){
                    return;
                }
                group_data.listOn = !group_data.listOn
                this.hodu_d_group_data.splice(data_index, 1, group_data);
                break;
        }

    }

    /**
     * 그룹or팀 진입 상태에서 그룹 필터 p태그 클릭
     */
    bizGroupFilterPClick(event : any) : void {
        const biz_group_check_label : any = $(event.target).find('.biz_group_check_label');
        biz_group_check_label.trigger('click');
    }

    /**
     * 그룹or팀 진입 상태에서 그룹 필터 이름 클릭
     */
    bizGroupFilterNameClick(event : any) : void {
        const biz_group_check_label : any = $(event.target).parent().find('.biz_group_check_label');
        biz_group_check_label.trigger('click');
    }

    /**
     * 그룹or팀 진입 상태에서 그룹 필터 ON / OFF
     */
    bizGroupFilterOnOff(checked : boolean, group_id : number) : void {

        // console.log(checked);
        
        try {
            // 필터 정보 찾기
            let filter : GroupAndTeamFilter | null = null;
            let filter_index : number = -1;
            const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
            for( let i = 0; i < filter_length; i++ ) {
                if( this.scope_group_team_option.biz_id == this.schedule_search_config.group_and_team_filter[i].biz_id &&
                    this.scope == this.schedule_search_config.group_and_team_filter[i].scope &&
                    (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) == this.schedule_search_config.group_and_team_filter[i].scope_id ) {
                    filter = JSON.parse(JSON.stringify(this.schedule_search_config.group_and_team_filter[i]));
                    filter_index = i;
                    break;
                }
            }

            // 필터가 존재하지 않는 경우
            if( filter == null ) {
                filter = {
                    biz_id : this.scope_group_team_option.biz_id,
                    scope : this.scope,
                    scope_id : (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id),
                    group_id : null,
                    team_ids : []
                };
            }

            // checked가 false라면 필터에 추가!
            if( checked == false ) {
                filter.group_id = group_id;

                // 기존에 존재했다면 splice
                if( filter_index > -1 ) {
                    this.schedule_search_config.group_and_team_filter.splice(filter_index, 1, filter);
                }

                // 기존에 없었다면 push
                else {
                    this.schedule_search_config.group_and_team_filter.push(filter);
                }
            }

            // checked가 true라면 필터에서 제거!
            else {
                // 기존에 없다면 나간다
                if( filter_index == -1 ) {
                    return;
                }

                // team_ids가 남아있으면 splice(index, 1, object)
                if( filter.team_ids.length > 0 ) {
                    this.schedule_search_config.group_and_team_filter.splice(filter_index, 1, filter);
                }

                // team_ids도 없다면 splice(index, 1);
                else {
                    this.schedule_search_config.group_and_team_filter.splice(filter_index, 1);
                }
            }

        } catch(e) {
            console.log(e);
        }

        this.filterToDB();
    }
    
    /**
     * 그룹or팀 진입 상태에서 팀 필터 이름 클릭
     */
    bizTeamFilterNameClick(event, team_data) : void {
        this.bizTeamFilterOnOff(!team_data.checked, team_data.team_id);
    }

    /**
     * 그룹or팀 진입 상태에서 팀 필터 ON / OFF
     */
    bizTeamFilterOnOff(checked : boolean, team_id : number) : void {

        // 필터 정보 찾기
        let filter : GroupAndTeamFilter | null = null;
        let filter_index : number = -1;
        const filter_length : number = this.schedule_search_config.group_and_team_filter.length;
        for( let i = 0; i < filter_length; i++ ) {
            if( this.scope_group_team_option.biz_id == this.schedule_search_config.group_and_team_filter[i].biz_id &&
                this.scope == this.schedule_search_config.group_and_team_filter[i].scope &&
                (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id) == this.schedule_search_config.group_and_team_filter[i].scope_id ) {
                filter = JSON.parse(JSON.stringify(this.schedule_search_config.group_and_team_filter[i]));
                filter_index = i;
                break;
            }
        }

        // 필터가 존재하지 않는 경우
        if( filter == null ) {
            filter = {
                biz_id : this.scope_group_team_option.biz_id,
                scope : this.scope,
                scope_id : (this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id),
                group_id : null,
                team_ids : []
            };
        }
        
        // checked가 false라면 필터에 추가!
        if( checked == false ) {
            // 이미 들어있는 경우는 나간다
            if( filter.team_ids.indexOf(team_id) > -1 ) {
                return;
            }

            filter.team_ids.push(team_id);

            // 기존에 존재했다면 splice
            if( filter_index > -1 ) {
                this.schedule_search_config.group_and_team_filter.splice(filter_index, 1, filter);
            }

            // 기존에 없었다면 push
            else {
                this.schedule_search_config.group_and_team_filter.push(filter);
            }
        }

        // checked가 true라면 필터에서 제거!
        else {
            // 기존에 없다면 || team_id가 들어있지 않은 경우는 나간다
            if( filter_index == -1 || filter.team_ids.indexOf(team_id) == -1) {
                return;
            }

            filter.team_ids.splice(filter.team_ids.indexOf(team_id), 1);

            // group_id, team_ids 둘중 하나라도 남아있다면 splice(index, 1, object)
            if( filter.team_ids.length > 0 || filter.group_id != null ) {
                this.schedule_search_config.group_and_team_filter.splice(filter_index, 1, filter);
            }

            // 둘중 하나라도 안남아있다면 splcie(index, 1);
            else {
                this.schedule_search_config.group_and_team_filter.splice(filter_index, 1);
            }
        }

        this.filterToDB();
    }

    /**
     * 필터 데이터를 로컬로 이동시킨다
     */
    // filterToLocal() : void {
    //     local_storage_info.schedule_search_config = this.schedule_search_config;
    //     hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info));

    //     // 로컬에 저장하는 과정에서 체크 컬러 다시 구하기 실행
    //     this.setFilterCheckColor();
    // }

    /**
     * 필터 정보 DB에 저장
     */
    async filterToDB() : Promise<void> {

        try {
            const filter_preference = await this.get_user_preference('filter', true);

            // console.log(`==============[filter_preference]==============`);
            // console.log(filter_preference);

            if( filter_preference == null ) {
                await this.set_user_preference('filter', { 'WEB' : { "schedule_search_config" : this.schedule_search_config, "filter_info" : local_storage_info.filter_info } }, true);
                return;
            }

            const preference = filter_preference.preference;
            
            // console.log(`==============[preference]==============`);
            // console.log(preference);
            
            if( preference == null ) {
                await this.set_user_preference('filter', { 'WEB' : { "schedule_search_config" : this.schedule_search_config, "filter_info" : local_storage_info.filter_info } }, true);
            }

            const web_filter_info = preference['WEB'];
            
            if( web_filter_info == null ) {
                await this.set_user_preference('filter', Object.assign(preference, { 
                    'WEB' : { 
                        "schedule_search_config" : this.schedule_search_config, 
                        "filter_info" : local_storage_info.filter_info 
                    }
                }), true);
                return;
            }

            // console.log(`==============[local_storage_info]==============`);
            // console.log(local_storage_info);

            await this.set_user_preference('filter', 
                Object.assign(preference, 
                    { 
                        "WEB" : Object.assign(web_filter_info, { 
                            "schedule_search_config" : this.schedule_search_config, 
                            "filter_info" : local_storage_info.filter_info 
                        })
                    }
                )
            , true);

            // 로컬에 저장하는 과정에서 체크 컬러 다시 구하기 실행
            await this.setFilterCheckColor();

            this.recomputed = true;
            this.$nextTick(() => { this.recomputed = false; });
            this.$forceUpdate(); 

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 체크 컬러를 재적용 시킨다
     */
    async setFilterCheckColor() : Promise<void> {

        const biz_check_color : string = this.scope == OWNER_TYPE.PERSONAL ? "#477FFF" : this.scope_group_team_option.group_team_color;

        // 지난 일정 - 체크 된 경우 (개인달력 or 프리미엄달력)
        if( this.schedule_search_config.past_schedule == true ) { 
            this.past_color_style = `background-color : ${biz_check_color}`;
        } 
    
        // 지난 일정 - 체크 안 된 경우
        else {
            this.past_color_style = "";
        }

        this.basic_color_style        = this.isEventSubTypeFilterChecked('basic')        == true ? `background-color : ${biz_check_color}` : "";
        this.report_color_style       = this.isEventSubTypeFilterChecked('report')       == true ? `background-color : ${biz_check_color}` : "";
        this.meetinglog_color_style   = this.isEventSubTypeFilterChecked('meetinglog')   == true ? `background-color : ${biz_check_color}` : "";
        this.project_color_style      = this.isEventSubTypeFilterChecked('work')         == true ? `background-color : ${biz_check_color}` : "";
        this.vacation_color_style     = this.isEventSubTypeFilterChecked('vacation')     == true ? `background-color : ${biz_check_color}` : "";
        this.businesstrip_color_style = this.isEventSubTypeFilterChecked('businesstrip') == true ? `background-color : ${biz_check_color}` : "";

        // 일반 그룹, 팀 색상 적용
        const normal_group_length : number = this.normal_group_data.length;
        for( let i = 0; i < normal_group_length; i++ ) {
            if( this.normal_group_data[i].checked == true ) {
                this.normal_group_data[i].css_style = `background-color : ${this.hodu_hex_color_process(this.normal_group_data[i].color)}`;
            }

            else {
                this.normal_group_data[i].css_style = "";
            }

            const normal_team_length : number = this.normal_group_data[i].teams.length;
            for( let j = 0; j < normal_team_length; j++ ) {
                if( this.normal_group_data[i].teams[j].checked == true ) {
                    this.normal_group_data[i].teams[j].css_style = `background-color : ${this.hodu_hex_color_process(this.normal_group_data[i].teams[j].color)}`;
                }

                else {
                    this.normal_group_data[i].teams[j].css_style = "";
                }
            }
        }

        // 비즈 그룹, 팀 색상 적용
        const hodu_c_group_length : number = this.hodu_c_group_data.length;
        for( let i = 0; i < hodu_c_group_length; i++ ) {
            if( this.hodu_c_group_data[i].checked == true ) {
                this.hodu_c_group_data[i].css_style = `background-color : ${this.hodu_hex_color_process(this.hodu_c_group_data[i].color)}`;
            }

            else {
                this.hodu_c_group_data[i].css_style = "";
            }

            const hodu_c_team_length : number = this.hodu_c_group_data[i].teams.length;
            for( let j = 0; j < hodu_c_team_length; j++ ) {
                if( this.hodu_c_group_data[i].teams[j].checked == true ) {
                    this.hodu_c_group_data[i].teams[j].css_style = `background-color : ${this.hodu_hex_color_process(this.hodu_c_group_data[i].teams[j].color)}`;
                }

                else {
                    this.hodu_c_group_data[i].teams[j].css_style = "";
                }
            }
        }

        // 그룹, 팀 달력 색상 적용
        const computed_group_length : number = this.getGroupAndTeam.length;
        for( let i = 0; i < computed_group_length; i++ ) {
            if( this.getGroupAndTeam[i].checked == true ) {
                this.getGroupAndTeam[i].css_style = `background-color : ${this.hodu_hex_color_process(this.getGroupAndTeam[i].color)}`;
            }

            else {
                this.getGroupAndTeam[i].css_style = "";
            }

            const computed_team_length : number = this.getGroupAndTeam[i].teams.length;
            for( let j = 0; j < computed_team_length; j++ ) {
                if( this.getGroupAndTeam[i].teams[j].checked == true ) {
                    this.getGroupAndTeam[i].teams[j].css_style = `background-color : ${this.hodu_hex_color_process(this.getGroupAndTeam[i].teams[j].color)}`;
                }

                else {
                    this.getGroupAndTeam[i].teams[j].css_style = "";
                }
            }
        }
    }

    /**
     * 그룹에서 예약 전체 ON / OFF
     */
    changeGroupAppointment(event) : void {
        const is_checked : boolean = event.target.checked;

        this.schedule_search_config.group_appointment_filter.splice(0, this.schedule_search_config.group_appointment_filter.length);
        
        if( is_checked == true ) {
            this.filterToDB();
            this.recomputed = true;
            this.$nextTick(() => { this.recomputed = false; }); 
            return;
        }

        for ( const department of this.computedHoduDDepartmentAndDoctor ) {
            for( const doctor of department.teams ) {
                const key = `${doctor.biz_id}___${doctor.department_code}___${doctor.doctor_code}`;
                this.schedule_search_config.group_appointment_filter.push(key);
            }
        }

        this.filterToDB();
    }

    /**
     * 호두 D 그룹 달력에서 병원 예약 - 진료과의 의사 리스트 ON / OFF
     */
    departmentListOnOff(event, department : GroupTeamInfo) : void {

        event.preventDefault();
        event.stopPropagation();

        if( department.department_code == null ) { return; }

        const is_checked : boolean | undefined = this.appointment_list_on_map.get(department.department_code);
        if( is_checked == null ) {
            this.appointment_list_on_map.set(department.department_code, true);
            this.recomputed = true;
            this.$nextTick(() => { this.recomputed = false; }); 
            return;
        }

        this.appointment_list_on_map.set(department.department_code, !is_checked);
        this.recomputed = true;
        this.$nextTick(() => { this.recomputed = false; }); 
        
    }

    /**
     * 호두 D 그룹 달력에서 해당 진료과의 의사 필터 전체 ON / OFF
     */
    departmentOnOff(event, is_checked : boolean, department : GroupTeamInfo) : void {

        event.preventDefault();
        event.stopPropagation();

        // 해당 진료과의 필터 전부 제거
        this.schedule_search_config.group_appointment_filter = this.schedule_search_config.group_appointment_filter.filter(item => new RegExp(`___${department.department_code}___`).test(item) == false);
        
        // 체크 해제라면 그대로 종료
        if( is_checked == true ) {
            this.schedule_search_config.group_appointment = true; 
            this.filterToDB();
            return; 
        }

        // 체크라면 해당 진료과의 모두 집어넣기
        for(const doctor of department.teams) {
            const key = `${doctor.biz_id}___${doctor.department_code}___${doctor.doctor_code}`;
            this.schedule_search_config.group_appointment_filter.push(key);
        }

        let total_count = 0;
        for( const department of this.computedHoduDDepartmentAndDoctor ) {
            total_count += department.team_count
        }

        // 필터 여부
        // alert(`[${total_count}, ${this.schedule_search_config.group_appointment_filter.length}]`);
        this.schedule_search_config.group_appointment = (total_count != this.schedule_search_config.group_appointment_filter.length);

        this.filterToDB();
    }

    /**
     * 호두 D 그룹 달력에서 의사 필터 ON / OFF
     */
    doctorOnOff(is_checked : boolean, doctor : GroupTeamInfo) : void {

        const key = `${doctor.biz_id}___${doctor.department_code}___${doctor.doctor_code}`;
        const index : number = this.schedule_search_config.group_appointment_filter.indexOf(key);

        if( is_checked == false ) {
            if( index < 0 ) { this.schedule_search_config.group_appointment_filter.push(key); }
        }

        else {
            if( index > -1 ) { this.schedule_search_config.group_appointment_filter.splice(index, 1); }
        }

        let total_count = 0;
        for( const department of this.computedHoduDDepartmentAndDoctor ) {
            total_count += department.team_count
        }

        // 필터 여부
        // alert(`[${total_count}, ${this.schedule_search_config.group_appointment_filter.length}]`);
        this.schedule_search_config.group_appointment = (total_count != this.schedule_search_config.group_appointment_filter.length);

        this.filterToDB();
    }

    /**
     * 일정 종류 필터 체크(이름 눌렀을때)
     */
    eventSubTypeFilterCheck(event : any, type : string) {

        event.preventDefault();
        event.stopPropagation();

        const checkbox = $(event.target).parent().find("input:checkbox");

        if( checkbox.length < 1 ) return;

        const checkbox_element = checkbox.get(0) as HTMLInputElement;

        checkbox_element.checked = !checkbox_element.checked;
        this.eventSubTypeFilterChange(checkbox_element, type);
    }

    /**
     * 일정 종류 필터 변경
     */
    eventSubTypeFilterChange(el : any, type : string) : void {
        const checked = el.checked;
        local_storage_info.filter_info[type] = checked;
        // hodu_local_storage.setItem(`${this.user_id}`, JSON.stringify(local_storage_info));
        this.filterToDB();
        window['getEvents']?.();
        this.setFilterCheckColor();
    }

    /**
     * 일정 종류 필터 체크 여부
     */
    isEventSubTypeFilterChecked(type : string) : boolean {
        return local_storage_info.filter_info[type] != null ? local_storage_info.filter_info[type] : true;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#right_filter_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
