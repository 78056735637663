
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { CRUD_TYPE, EVENT_SUB_TYPE, OWNER_TYPE, SHARE_OPTION } from '@/mixin/VueHoduCommon';

import FloatAdd from '@/components/ui/FloatAdd.vue';
import TitleBox from '@/components/ui/TitleBox.vue';
import SearchBar from '@/components/ui/SearchBar.vue';

import ReportListComp from '@/components/work/ReportListComp.vue';

import { namespace } from 'vuex-class';
const WorkInfo = namespace('WorkInfo');

import { t_event } from '@/model/event';
import { WorkSearchInfo } from '@/store/modules/WorkInfo';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        FloatAdd, TitleBox, SearchBar,
        ReportListComp,
        ResizeObserver
    },
})
export default class ReportList extends Mixins(VueHoduCommon) {
    /**
     * @WorkInfo.State
     */
    @WorkInfo.State report_search_info !: WorkSearchInfo;

    /**
     * @WorkInfo.Action
     */
    @WorkInfo.Action doSetReportSearchInfo ?: (params : WorkSearchInfo) => void;

    event_bus : Vue = new Vue();

    is_search_open : boolean = false;
    search_query : string = "";
    search_query_temp : string = "";

    mounted() : void {
        this.search_query = this.report_search_info.search_query ? this.report_search_info.search_query : "";
        if ( this.search_query.length > 0 ) {
            this.is_search_open = true;
            this.search_query_temp = this.search_query;
        }
    }

    /**
     * 검색 ON / OFF
     */
    searchOnOff(flag : boolean) : void {
        
        if( this.getReportReadPermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 일지 읽기 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        this.is_search_open = flag;

        if( flag == false ) {
            this.search_query = "";
            this.search_query_temp = "";

            const search_info : WorkSearchInfo = JSON.parse(JSON.stringify(this.report_search_info));
            search_info.search_query = "";
            this.doSetReportSearchInfo?.(search_info);
        }
    }

    /**
     * 검색 keydown
     */
    searchKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.search();
    }

    /**
     * 검색
     */
    search() : void {
        this.search_query = this.search_query_temp.trim();

        const search_info : WorkSearchInfo = JSON.parse(JSON.stringify(this.report_search_info));
        search_info.search_query = this.search_query;
        this.doSetReportSearchInfo?.(search_info);
    }

    /**
     * 업무일지 생성
     */
    addReport() : void {

        if( this.getReportCreatePermission() == false ) {
            this.hodu_show_dialog('cancel', '업무 일지 작성 권한이 없습니다 관리자에게 문의하세요', ['확인']);
            return;
        }

        const target_date : Date = new Date();
        const start_date : Date = new Date(target_date.getTime());
        const end_date   : Date = new Date(target_date.getTime());

        // 시작 시간이 '정시' 거나 '23시' 일 경우는 그대로 사용한다 
        if( target_date.getMinutes() != 0 && target_date.getHours() != 23 ){
           start_date.setHours(target_date.getHours() + 1);
        }
        
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        // 시작시간이 23시라면 23시 50분 고정, 아니라면 시작시간 + 1시간에 0분
        if( start_date.getHours() == 23 ){
            end_date.setHours(23);
            end_date.setMinutes(50);
        } else {
            end_date.setHours(start_date.getHours() + 1);
            end_date.setMinutes(0);
        }

        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        // event 기본 값 설정
        const event : t_event = {
            "audit_created": new Date(),
            "audit_delete_flag": false,
            "audit_deleted": null,
            "audit_modified": new Date(),
            "audit_user_id": this.user_id,
            "calendar_id": this.calendar_id,
            "event_data": {
                "alarm": [],
                "attachment": {
                    "files": [],
                    "imgs": []
                },
                "attend": false,
                "color": "#477FFF",
                "event_owner_group_id": this.scope_team_id > 0 ? 0 : this.scope_group_id,
                "event_owner_group_name": this.scope_team_id > 0 ? "" : this.scope_group_team_option.group_team_name,
                "event_owner_id": this.user_id,
                "event_owner_name": this.user_name,
                "event_owner_team_id": this.scope_team_id,
                "event_owner_team_name": this.scope_team_id > 0 ? this.scope_group_team_option.group_team_name : "",
                "event_push_yn": true,
                "event_sub_type": EVENT_SUB_TYPE.REPORT,
                "location": [],
                "memo": "",
                "note": "",
                "percent_complete": 0,
                "priority": 0,
                "schedule_date": {
                    "end": end_date,
                    "isAllDay": false,
                    "isContinuos": false,
                    "isIgnore": false,
                    "lunar_yn": false,
                    "recurrence_end": end_date,
                    "start":  start_date
                },
                "contacts" : [{
                    "name" : "",
                    "tel" : ""
                }],
                "status": "",
                "title": "",
                "uid": "",
                "version": "1",
                "vote": [],
                "remark" : "",
                "is_private" : false,
            },
            "event_id": "",
            "event_type": this.scope,
            "event_sub_type": EVENT_SUB_TYPE.REPORT,
            "user_id": 0,
            "team_id": this.scope == OWNER_TYPE.TEAM ? this.scope_team_id : 0,
            "group_id": this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : 0,
            "subscribe_users": [],
            "subscribe_groups": [],
            "subscribe_teams": [],
            "duration" : "",
        }

        // EventInfo에 이벤트 등록
        this.doSetEvent(event);
        this.doSetEventCrudType(CRUD_TYPE.CREATE);
        this.doSetEventShareInfo({
            share_option : SHARE_OPTION.SHARE,
            user_ids : [],
            group_ids : [],
            team_ids : [],
            group_user_ids : [],
            team_user_ids : []
        });

        this.hodu_router_push('/event');
    }

    /**
     * 업무 읽기 권한 반환
     */
    getReportReadPermission() : boolean {
        
        // 그룹 권한 체크
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "report", "read");
        } 
        
        // 팀 권한 체크
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "report", "read");
        } 
        
        // 개인은 불가능
        else {
            return false;
        }
    }

    /**
     * 업무 생성 권한 체크
     */
    getReportCreatePermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "report", "create");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "report", "create");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.event_bus.$emit('handleResize');
    }

}
