
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import moment from 'moment';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class AttendanceListWeek extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 1주일 계산
     */
    get computedWeek() : any[] {

        let week : any[] = [];

        for( let i = 0; i <= 6; i++ ) {
            
            const target = moment(this.start).add('day', i);
            
            week.push({
                date : target.toDate(),
                day : this.getDayOfWeekByDate(target.toDate())
            });
        }

        return week;
    }

    /**
     * attend 데이터 반환
     */
    get computedAttendInfo() : any {
        return (user_id) => {

            const target = this.attend_emp_week_info.filter(info => info.user_id == user_id);

            if( target == null ) {
                return [];
            }

            const info = target[0];

            
        };
    }

    /**
     * @CalendarInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @CalendarInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트

    start : Date = new Date();
    end : Date = new Date();

    start_text : string = "";
    end_text : string = "";

    attend_emp_week_info : any[] = [];

    lunar_date : Map<string, string> = new Map(); // 음력 Map
    holidays   : Map<string, Object> = new Map(); // 공휴일 Map

    header_width : number = 0;

    resize_function_submit : boolean = false;

    async mounted() : Promise<void> {
        const moment_start_date = moment(this.selected_date);
        const start_date = moment_start_date.toDate();
        
        this.start = moment_start_date.set('date', moment_start_date.get('date') - start_date.getDay()).toDate();
        this.end   = moment_start_date.set('date', moment_start_date.get('date') + 6).toDate();

        this.makeDateText();
        this.datepickerInit();
        
        // 조회
        await this.getTotalData();

        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    @Throttle(100)
    async setScroll() : Promise<void> {
        this.$nextTick(() => {
            const title_height = $('#attendance .title_box').outerHeight();
            const box_height = $('#attendance_list_week .schedule_box').outerHeight();
            const head_height = 60;

            const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                        - (box_height ? box_height : 0)
                                                        - (head_height ? head_height : 0);

            // @ts-ignore
            $('#attendance_list_week_left_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_week_left_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_week_right_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            const left_div_width : number | undefined = $('#attendance_list_week .leftDiv').outerWidth();
            const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
            const right_area_controller_width : number | undefined = !this.right_control_box_flag ? $('#right_area_controller').outerWidth() : 0;

            const scroll_width : number = window.innerWidth - (left_div_width ? left_div_width : 0)
                                                            - (left_area_width ? left_area_width : 0) 
                                                            - (right_area_controller_width ? right_area_controller_width : 0);

            this.header_width = scroll_width;

            // @ts-ignore
            $('#attendance_list_week_right_scroll').mCustomScrollbar({
                axis : 'yx',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 0,
                autoDraggerLength : false,
                setHeight : scroll_height,
                setWidth : scroll_width,
                callbacks : {
                    whileScrolling: () => {
                        const scroll_top = $('#attendance_list_week_right_scroll').find('.mCSB_container').css('top');

                        // @ts-ignore
                        $('#attendance_list_week_left_scroll').mCustomScrollbar("scrollTo", scroll_top, { callbacks:false });
                    }
                }
            });

            if( this.resize_function_submit == false ) { 
                this.event_bus.$on('listWeekResize', this.handleResize);
                this.resize_function_submit = true;
            }
        });
    }

    /**
     * 전체 데이터 조회
     */
    async getTotalData() : Promise<void> {
        try {
            await this.hodu_show_indicator();

            // @ts-ignore
            $('#attendance_list_week_right_scroll').mCustomScrollbar('destroy');

            await Promise.all([this.getLunar(this.start, this.end), this.getHolidays(this.start, this.end)]);
            await this.getEmpAttendDataWeekInfo();

            this.$nextTick(() => {
                this.setScroll();
            });

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 주별 데이터 조회
     */
    async getEmpAttendDataWeekInfo() : Promise<void> {

        try {
            const month = moment(this.end).format('YYYYMM');

            let week = 0;

            const month_moment = moment(`${month.substring(0,4)}-${month.substring(4,6)}-01`);

            const month_start_moment = moment(month_moment.toDate()).add('day', -month_moment.toDate().getDay());
            const month_end_moment = moment(month_moment.toDate()).add('day', 6 - month_moment.toDate().getDay());
            
            let repeat_flag = true;

            while( repeat_flag ) {
                week++;

                if( moment(this.start).format('YYYYMMDD') == month_start_moment.format('YYYYMMDD') && moment(this.end).format('YYYYMMDD') == month_end_moment.format('YYYYMMDD') ) {
                    repeat_flag = false;
                }

                month_start_moment.add('day', 7);
                month_end_moment.add('day', 7);
            }

            // const today_date_string = moment(this.end).format('YYYYMMDD');
            const today_date_string = moment().format('YYYYMMDD');

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/data/week?month=${month}&week=${week}&today_date_string=${today_date_string}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_month_info ) {
                throw new Error("주별 근태현황 데이터 조회 중 오류 발생");
            }

            this.attend_emp_week_info.splice(0, this.attend_emp_week_info.length);
            this.attend_emp_week_info = this.attend_emp_week_info.concat(response.data.data.attend_emp_month_info);

            for( const week_info of this.attend_emp_week_info ) {
                for( const key in week_info.jsonb ) {

                    // 근태현황 시작일이 적용 안됐다면 전부 제외
                    if( this.getAttendanceStart() == '' ) {
                        delete(week_info.jsonb[key]);
                        continue;
                    }

                    // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                    if( Number(this.getAttendanceStart()) > Number(key) ) {
                        delete(week_info.jsonb[key]);
                        continue;
                    }
                }
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "주별 근태현황 데이터 조회 중 오류 발생", ["확인"]);
        }

    }

    /**
     * 음력 구하기
     */
    async getLunar(start : Date, end : Date) : Promise<void> {
        start = start instanceof Date ? start : new Date(moment(start).format());
        end   = end   instanceof Date ? end   : new Date(moment(end).format());

        const lunar_objs : any[] = await this.hodu_solars_to_lunars(start, end);
        
        for( let lunar_obj of lunar_objs ) {
            this.lunar_date.set(lunar_obj.solar_ymd, lunar_obj.lunar_ymd);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {
        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            this.holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

    }

    /**
     * date 텍스트 만들기
     */
    makeDateText() : void {
        this.start_text = `${this.hodu_date_to_format_string(this.start, "YYYY.MM.DD")}`;
        this.end_text   = `${this.hodu_date_to_format_string(this.end, "YYYY.MM.DD")}`; 
    }

    /**
     * datepicker 초기화
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                                        
                    if( inst.id == 'attendance_list_week_start' ) {
                        this.start = moment(selected_date).add('day', -selected_date.getDay()).toDate();
                        this.end   = moment(this.start).add('day', 6).toDate();
                        inst.input.val(`${this.hodu_date_to_format_string(this.start, "YYYY.MM.DD")}`);
                    }

                    else if( inst.id == 'attendance_list_week_end' ) {
                        this.end   = moment(selected_date).add('day', 6 - selected_date.getDay()).toDate();
                        this.start = moment(this.end).add('day', -6).toDate();
                        inst.input.val(`${this.hodu_date_to_format_string(this.end, "YYYY.MM.DD")}`);
                    }

                    this.makeDateText();
                    this.doSetSelectedDate?.(this.start);

                    // 조회
                    this.getTotalData();
                },
            }

            // @ts-ignore
            $('#attendance_list_week_start').datepicker(datepicker_option);

            // @ts-ignore
            $('#attendance_list_week_end').datepicker(datepicker_option);
        });
    }

    /**
     * 이전 날짜
     */
    prev() : void {
        this.start = moment(this.start).add("day", -7).toDate();
        this.end = moment(this.end).add("day", -7).toDate();

        this.makeDateText();
        this.doSetSelectedDate?.(this.start);

        // 조회
        this.getTotalData();
    }

    /**
     * 다음 날짜
     */
    next() : void {
        this.start = moment(this.start).add("day", 7).toDate();
        this.end = moment(this.end).add("day", 7).toDate();

        this.makeDateText();
        this.doSetSelectedDate?.(this.start);

        // 조회
        this.getTotalData();
    }

    /**
     * 오늘
     */
    today() : void {
        this.start = moment().add('day', -moment().toDate().getDay()).toDate();
        this.end   = moment(this.start).add('day', 6).toDate();

        this.makeDateText();
        this.doSetSelectedDate?.(new Date());

        // 조회
        this.getTotalData();
    }

    /**
     * 이벤트 개수
     */
    getEventCount(events, date : Date) : number {

        if( events == null || events.length < 1 ) return 0;
        
        const current_yyyymmdd = moment().format('YYYYMMDD');
        const day_yyyymmdd = moment(date).format('YYYYMMDD');
        if( events.length == 1 && events[0].attend_type == null && Number(current_yyyymmdd) < Number(day_yyyymmdd) ) {
            return 0;
        }

        let count = 0;
        let vacation_daycount = 0;
        let businesstrip_daycount = 0;
        let is_exist_state_10 = false;

        for( const event of events ) {
            if( event.attend_type == 20 ) {
                count++;
                vacation_daycount += event.daycount;
            }
            else if( event.attend_type == 30 ) {
                count++;
                businesstrip_daycount += event.daycount;
            }
            else if( event.attend_type == null || event.attend_type == 10 ) {
                count += 2;
                is_exist_state_10 = true;
            }
        }

        // 휴가 합이 1일이 안되는데 출근 정보가 존재하지 않는다면(미체크) count + 2 TODO work_type 조회해서 해당 타입에 맞게 바뀌어야함
        if( is_exist_state_10 == false && (vacation_daycount + businesstrip_daycount) < 1 ) {
            return count + 2;
        }
        
        // 휴가가 1일 이상으로 신청됐다면 무조건 휴가 TODO work_type 조회해서 해당 타입에 맞게 바뀌어야함
        if( (vacation_daycount + businesstrip_daycount) >= 1 ) {
            return 1;
        }

        return count;
    }

    /**
     * 결근 여부
     */
    isAwol(events) : boolean {
        if( events == null || events.length < 1 ) return false;

        for( const event of events ) {
            if( event.attend_type == 10 ) {
                return event.attend_state == '40';
            }
        }

        return false;
    }

    /**
     * 휴가 제목 리스트 반환
     */
    getVacationTitle(events) : string {
        let vacation_name_list = "";
        for( const event of events ) {
            if( vacation_name_list.length > 0 ) vacation_name_list += ", "; 
            vacation_name_list += event.vacation_type_name;
        }
        return vacation_name_list;
    }

    /**
     * 출근 시간
     */
    getStartTimeText(events : any[]) : string {
        let start_time = null;

        for( const attend_info of events ) {
            // 출 퇴근 정보
            if( attend_info.attend_type == 10 ) {
                start_time = attend_info.start_time;
            }

            // 휴가 정보
            else if( attend_info.attend_type == 20 ) {
                
            }
        }

        return start_time != null ? this.processTimeText(start_time) : ''
    }

    /**
     * 퇴근 시간
     */
    getEndTimeText(events : any[], date : Date) : string {
        let end_time = null;
        const today_string = moment().format('YYYYMMDD');

        for( const attend_info of events ) {
            // 출 퇴근 정보
            if( attend_info.attend_type == 10 ) {
                end_time = attend_info.end_time;
            }
            // 휴가 정보
            else if( attend_info.attend_type == 20 ) {
                
            }
        }

        return end_time != null ? this.processTimeText(end_time) : (
            moment(date).format('YYYYMMDD') == today_string ? (this.getStartTimeText(events) == '' ? '' : '근무중') : ''
        );
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getAttendanceStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * 근무 안하는날 텍스트 반환 
     */
    getOffText(attend) : string {

        if( attend == null ) return "휴일";

        const vacation = attend.filter(item => item.attend_type == '20');
        const businesstrip = attend.filter(item => item.attend_type == '30');

        if( vacation.length > 0 && businesstrip.length > 0 ) {
            return '휴가·출장';
        }

        if( vacation.length > 0 ) {
            return '휴가';
        }

        if( businesstrip.length > 0 ) {
            return '출장';
        }

        return '휴일';
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }
    
    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_taget.parent().find('p.img').css('background-image', `url(${replace_image})`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#attendance_list_week_left_scroll').mCustomScrollbar('destroy');

        // @ts-ignore
        $('#attendance_list_week_right_scroll').mCustomScrollbar('destroy');
            
        this.setScroll();
    }

}
