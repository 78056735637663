
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';
import { EquipmentImportModalInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class EquipmentList extends Mixins(VueHoduCommon) {

    /**
     * computed 장비 리스트
     */
    get computedEquipments() : any[] {
        let list = JSON.parse(JSON.stringify(this.equipments));

        const search_query = this.search_query.toUpperCase().trim();

        if( search_query.length > 0 ) {
            list = list.filter(equipment => this.hodu_string_includes(equipment.equipment_data.name, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.code, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.location, search_query) || 
                                            this.hodu_string_includes(equipment.equipment_data.manager, search_query) || 
                                            this.hodu_string_includes(equipment.equipment_data.desc, search_query) || 
                                            this.hodu_string_includes(equipment.equipment_data.major_category, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.middle_category, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.minor_category, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.manufacturer, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.manufacturer_tel, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.vendor, search_query) ||
                                            this.hodu_string_includes(equipment.equipment_data.vendor_tel, search_query));
        }

        return list;
    }

    /**
     * computed 장비 관리 이력 리스트
     */
    get computedManagements() : any[] {
        let list = JSON.parse(JSON.stringify(this.managements));

        const search_query = this.search_query.toUpperCase().trim();

        if( search_query.length > 0 ) {
            list = list.filter(management => this.hodu_string_includes(management.equipment.equipment_data.name, search_query) ||
                                             this.hodu_string_includes(management.management_data.repair_items, search_query) || 
                                             this.hodu_string_includes(management.management_data.repairer, search_query) || 
                                             this.hodu_string_includes(management.management_data.repair_tel, search_query) ||
                                             this.hodu_string_includes(management.management_data.memo, search_query));
        }

        return list;
    }

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEquipmentManagementDetailModalInfo ?: (params : hodu_home_modal_info.EquipmentManagementDetailModalInfo) => void;
    @ModalInfo.Action doSetEquipmentImportModalInfo ?: (params : EquipmentImportModalInfo) => void;

    tab : string = "EQUIPMENT";
    equipments : any[] = [];
    managements : any[] = [];

    is_search_div_open : boolean = false;
    search_query : string = "";
    
    mounted() : void {
        
        if( this.tab == 'EQUIPMENT' ) {
            this.getHomeEquipments();
        }
        else {
            this.getHomeEquipmentManagements();
        }

        this.setScroll();
        //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.fixedDiv_con > ul').hide();
        // 	$('.fixedDiv_con > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });
        
        window['equipment_reload'] = this.getHomeEquipments;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const title_div_height = $('.titleDiv').outerHeight();
        const sort_header_height = $('.sortHeader').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                 - (title_div_height ? title_div_height : 0)
                                                 - (sort_header_height ? sort_header_height : 0);

        if( this.tab == 'EQUIPMENT' ) {

            // @ts-ignore
            $('#equipment_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : scroll_height,
            });

        }
        else {

            // @ts-ignore
            $('#equipment_management_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : scroll_height,
            });

        }
        
    }

    /**
     * 장비 목록 조회
     */
    async getHomeEquipments() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment`, API_METHOD.GET);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.equipments ) {
                throw new Error("장비 리스트 조회 중 오류 발생");
            }

            this.equipments.splice(0, this.equipments.length);
            this.equipments = this.equipments.concat(response.data.data.equipments);

        } catch(e) {
            this.hodu_show_dialog('cancel', "장비 리스트 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 장비 이력 리스트 조회
     */
    async getHomeEquipmentManagements() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/management`, API_METHOD.GET);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.managements ) {
                throw new Error("장비 리스트 조회 중 오류 발생");
            }

            this.managements.splice(0, this.managements.length);
            this.managements = this.managements.concat(response.data.data.managements);

        } catch(e) {
            this.hodu_show_dialog('cancel', "장비 리스트 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }
    

    /**
     * 검색창 열기
     */
    openSearch() : void {
        this.is_search_div_open = true;
        this.$nextTick(() => $('#equipment_search').focus());
    }   

    /**
     * 검색창 닫기
     */
    closeSearch() : void {
        this.is_search_div_open = false;
        this.search_query = "";
    }

    /**
     * 탭 변경
     */
    tabChange(tab) : void {

        this.is_search_div_open = false;
        this.search_query = "";
        
        this.tab = tab;
        
        if( this.tab == 'EQUIPMENT' ) {
            // @ts-ignore
            $('#equipment_management_scroll').mCustomScrollbar("destroy");
            this.getHomeEquipments();
        }
        else {
            // @ts-ignore
            $('#equipment_scroll').mCustomScrollbar("destroy");
            this.getHomeEquipmentManagements();
        }

        this.$nextTick(() => this.setScroll());
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 장비 생성으로 이동
     */
    moveEquipmentCreate() : void {
        this.hodu_router_push(`/home/equipment/create`);
    }

    /**
     * 상세조회로 이동
     */
    moveEquipmentDetail(uid) : void {
        this.hodu_router_push(`/home/equipment/${uid}`);
    }

    /**
     * 장비 관리 이력 모달
     */
    showEquipmentManagementDetail(uid, seq) : void {
        this.doSetEquipmentManagementDetailModalInfo?.({ 
            show_modal : true, 
            uid : uid, 
            seq : seq,
            callback : this.getHomeEquipmentManagements 
        });
    }

    importEquipment() : void {
        this.doSetEquipmentImportModalInfo?.({
            show_modal : true,
            group_id : this.scope_group_id
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        if( this.tab == 'EQUIPMENT' ) {
            // @ts-ignore
            $('#equipment_scroll').mCustomScrollbar("destroy");
        }
        else {
            // @ts-ignore
            $('#equipment_management_scroll').mCustomScrollbar("destroy");
        }

        this.$nextTick(() => this.setScroll());
    }

}
