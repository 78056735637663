
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { t_event_file } from '@/model/event';
import { organization_modal_interface } from '@/model/organization';

import * as crypto from "crypto-js";

@Component({
    components: {
        
    },
})
export default class OrganizationEmpPersonalRecordPrintModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_emp_personal_record_print_modal_info !: organization_modal_interface.OrganizationEmpPersonalRecordPrintModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationEmpPersonalRecordPrintModalInfo ?: (params : organization_modal_interface.OrganizationEmpPersonalRecordPrintModalInfo) => void;

    name : string = "";
    address : string = "";
    dept_name : string = "";
    pos_name : string = "";
    period : string = "";

    social_security_number_left : string = "";
    social_security_number_right : string = "";
    usage : string = "";
    submit_to : string = "";

    company_name : string = "";
    company_address : string = "";
    company_ceo : string = "";
    stamp_image : string = "";

    quitter : any = null;

    mounted() : void {

        $(document).ready(function() {
            $('.addressTex').on( 'keyup', function (e){
                $(this).css('height', 'auto');
                $(this).height(this.scrollHeight);
            });
            $('.addressTex').keyup();
        });

        this.getTotalData();
    }

    async getTotalData() {
        try {
            await this.getGroup();

            if( this.organization_emp_personal_record_print_modal_info.is_quitter == false ) {
                await this.getPersonalRecord();
            }
            else {
                await this.getQuitter();
            }

        } catch(e) {
            this.close();
        }
    }

    async getGroup() {

        try {
            const group_id = this.organization_emp_personal_record_print_modal_info.group_id;

            const response = await this.hodu_api_call(`api/v1/groupsweb/getDetail/${group_id}/0`, API_METHOD.GET, null);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.data ) {
                throw new Error("그룹 정보 조회중 오류발생");
            }

            const data = response.data.data.data;

            this.company_name = (data.group_info.company_name ?? "");
            this.company_address = (data.group_info.company_address ?? "");
            this.company_ceo = (data.group_info.company_ceo ?? "");
            this.stamp_image = (data.group_info.stamp_image ?? "");

        } catch(e) {
            alert("그룹 정보 조회 중 오류 발생");
            throw e;
        }
    }

    async getPersonalRecord() {

        try {

            const group_id = this.organization_emp_personal_record_print_modal_info.group_id;
            const user_id = this.organization_emp_personal_record_print_modal_info.user_id;

            let create_and_update_permission = this.get_group_role(group_id) == 'ADMIN';

            if( create_and_update_permission == false ) {

                const permission_response = await this.hodu_api_call(`api/v1/groups/${group_id}/personal_record/permission`, API_METHOD.GET);

                console.log(permission_response);

                if( permission_response == null || !this.isHttpStatusSuccess(permission_response.status) || !permission_response.data || !permission_response.data.data ) {
                    throw new Error("인사정보 등록 및 수정 권한 조회중 오류발생");
                }

                const permission = permission_response.data.data.permission;

                if( permission != null && permission.length > 0 ) {
                    for( const perm of permission ) {
                        if( perm.user_id == this.user_id ) {
                            create_and_update_permission = true;
                            break;
                        } 
                    }
                }
            }

            if( create_and_update_permission == false ) {
                alert("권한 없음");
                this.close();
                return ;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}`, API_METHOD.GET);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.record ) {
                throw new Error("인사정보 조회중 오류발생");
            }

            const record = JSON.parse(JSON.stringify(response.data.data.record));

            this.dept_name = record.dept_name;
            this.pos_name = record.pos_name;
            this.name = record.personal_record_data.name;
            this.address = record.personal_record_data.address;

            // 재직 기간 계산
            let month_count : number = 0;

            let start : string = `${record.start_date.substring(0, 4)}.${record.start_date.substring(4, 6)}`;
            let end : string = this.hodu_date_to_format_string(new Date(), 'YYYY.MM');

            let start_split : string[] = start.split(".");
            let end_split : string[] = end.split(".");

            let start_month_count : number = 0;
            let end_month_count : number = 0;

            for( let i = 0; i < start_split.length; i++ ) {
                let start_str : string = start_split[i];

                let count : number = Number(start_str);
                if( i == 0 ) count *= 12;

                start_month_count += count;
            }

            for( let i = 0; i < end_split.length; i++ ) {
                let end_str : string = end_split[i];

                let count : number = Number(end_str);
                if( i == 0 ) count *= 12;

                end_month_count += count;
            }

            month_count += (end_month_count - start_month_count);

            let career : string = "";

            // 년단위로 딱 떨어지는 경우
            if( month_count > 0 && month_count % 12 == 0 ) {
                career = `${Math.floor(month_count / 12)}년`;
            }
            // 년단위 이하
            else if( month_count < 12 ) {
                career = `${Math.floor(month_count)}개월`;
            }
            // n년 m개월
            else {
                career = `${Math.floor(month_count / 12)}년 ${Math.floor(month_count % 12)}개월`;
            }

            let start_date = new Date(`${record.start_date.substring(0, 4)}-${record.start_date.substring(4, 6)}-${record.start_date.substring(6, 8)}`);
            let end_date = new Date();

            this.period = `${start_date.getFullYear()}년 ${`0${start_date.getMonth() + 1}`.slice(-2)}월 ${`0${start_date.getDate()}`.slice(-2)}일 부터 ${end_date.getFullYear()}년 ${`0${end_date.getMonth() + 1}`.slice(-2)}월 ${`0${end_date.getDate()}`.slice(-2)}일 현재 (${career})`;


        } catch(e) {
            alert("인사 정보 조회 중 오류 발생");
            throw e; 
        }

    }

    async getQuitter() {

        try {
            const group_id = this.organization_emp_personal_record_print_modal_info.group_id;
            const user_id = this.organization_emp_personal_record_print_modal_info.user_id;

            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/quitter/${user_id}`, API_METHOD.GET);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.quitter ) {
                throw new Error("퇴사자 조회 중 오류 발생");
            }

            console.log(response.data.data.quitter);
            
            this.quitter = JSON.parse(JSON.stringify(response.data.data.quitter));

            this.dept_name = this.quitter.quitter_data.dept_name;
            this.pos_name = this.quitter.quitter_data.pos_name;
            this.name = this.quitter.quitter_data.name;
            this.address = this.quitter.quitter_data.address;

            // 재직 기간 계산
            let month_count : number = 0;

            let start : string = `${this.quitter.quitter_data.start_date.substring(0, 4)}.${this.quitter.quitter_data.start_date.substring(4, 6)}`;
            let end : string = `${this.quitter.quitter_data.end_date.substring(0, 4)}.${this.quitter.quitter_data.end_date.substring(4, 6)}`;

            let start_split : string[] = start.split(".");
            let end_split : string[] = end.split(".");

            let start_month_count : number = 0;
            let end_month_count : number = 0;

            for( let i = 0; i < start_split.length; i++ ) {
                let start_str : string = start_split[i];

                let count : number = Number(start_str);
                if( i == 0 ) count *= 12;

                start_month_count += count;
            }

            for( let i = 0; i < end_split.length; i++ ) {
                let end_str : string = end_split[i];

                let count : number = Number(end_str);
                if( i == 0 ) count *= 12;

                end_month_count += count;
            }

            month_count += (end_month_count - start_month_count);

            let career : string = "";

            // 년단위로 딱 떨어지는 경우
            if( month_count > 0 && month_count % 12 == 0 ) {
                career = `${Math.floor(month_count / 12)}년`;
            }
            // 년단위 이하
            else if( month_count < 12 ) {
                career = `${Math.floor(month_count)}개월`;
            }
            // n년 m개월
            else {
                career = `${Math.floor(month_count / 12)}년 ${Math.floor(month_count % 12)}개월`;
            }

            let start_date = new Date(`${this.quitter.quitter_data.start_date.substring(0, 4)}-${this.quitter.quitter_data.start_date.substring(4, 6)}-${this.quitter.quitter_data.start_date.substring(6, 8)}`);
            let end_date = new Date(`${this.quitter.quitter_data.end_date.substring(0, 4)}-${this.quitter.quitter_data.end_date.substring(4, 6)}-${this.quitter.quitter_data.end_date.substring(6, 8)}`);

            this.period = `${start_date.getFullYear()}년 ${`0${start_date.getMonth() + 1}`.slice(-2)}월 ${`0${start_date.getDate()}`.slice(-2)}일 부터 ${end_date.getFullYear()}년 ${`0${end_date.getMonth() + 1}`.slice(-2)}월 ${`0${end_date.getDate()}`.slice(-2)}일 (${career})`;

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("퇴사자 조회 중 오류 발생");
        }
    }

    async print() {

        // 필수데이터 체크
        if( this.name == null || this.name.length < 1 ) {
            alert("성명을 입력해주세요")
            return;
        }

        if( this.social_security_number_left == null || this.social_security_number_left.length < 1 || 
            this.social_security_number_right == null || this.social_security_number_right.length < 1 ) {
            alert("주민등록번호를 입력해주세요");
            return;
        }

        if( this.social_security_number_left.length != 6 || this.social_security_number_right.length != 7 || 
            !new RegExp(/[0-9]{6}/ig).test(this.social_security_number_left) || !new RegExp(/[0-9]{7}/ig).test(this.social_security_number_right) ) {
            alert("주민등록번호 형식을 확인해주세요");
            return;
        }

        if( this.address == null || this.address.length < 1 ) {
            alert("현주소를 입력해주세요")
            return;
        }

        if( this.dept_name == null || this.dept_name.length < 1 ) {
            alert("부서를 입력해주세요")
            return;
        }

        if( this.pos_name == null || this.pos_name.length < 1 ) {
            alert("직급을 입력해주세요")
            return;
        }

        if( this.usage == null || this.usage.length < 1 ) {
            alert("용도를 입력해주세요")
            return;
        }

        if( this.submit_to == null || this.submit_to.length < 1 ) {
            alert("제출처를 입력해주세요")
            return;
        }

        if( this.company_name == null || this.company_name.length < 1 ) {
            alert("회사명을 입력해주세요")
            return;
        }

        if( this.company_ceo == null || this.company_ceo.length < 1 ) {
            alert("대표이사를 입력해주세요")
            return;
        }

        if( this.company_address == null || this.company_address.length < 1 ) {
            alert("회사주소를 입력해주세요")
            return;
        }

        const obj = {
            "name" : this.name,
            "address" : this.address,
            "dept_name" : this.dept_name,
            "pos_name" : this.pos_name,
            "period" : this.period,
            "social_security_number" : `${this.social_security_number_left}-${this.social_security_number_right}`,
            "usage" : this.usage,
            "submit_to" : this.submit_to,
            "company_name" : this.company_name,
            "company_address" : this.company_address,
            "company_ceo" : this.company_ceo,
            "stamp_image" : this.stamp_image
        };

        const secret_key = "hodu_aes_secret_key";
        const cipher = crypto.AES.encrypt(JSON.stringify(obj), secret_key);
        const cipher_text = cipher.toString();

        let query = `user_id=${this.organization_emp_personal_record_print_modal_info.user_id}&group_id=${this.organization_emp_personal_record_print_modal_info.group_id}&print_data=${encodeURIComponent(cipher_text)}`;
        query += `&is_quitter=${this.organization_emp_personal_record_print_modal_info.is_quitter}`

        const timezone = -(new Date().getTimezoneOffset());
        query += `&timezone=${timezone ? timezone : 540}`;

        window.open(`/personal_record_print.html?${query}`, "personal_record_print", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);

        if( this.organization_emp_personal_record_print_modal_info.is_quitter == true ) {

            this.quitter.quitter_data.dept_name = this.dept_name.trim();
            this.quitter.quitter_data.pos_name = this.pos_name.trim();
            this.quitter.quitter_data.name = this.name.trim();
            this.quitter.quitter_data.address = this.address.trim();

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/quitter/${this.quitter.user_id}`, API_METHOD.PUT, this.quitter.quitter_data);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("퇴사자 정보 수정 중 오류 발생");
            }

        }

        
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationEmpPersonalRecordPrintModalInfo?.({
            show_modal : false,
            group_id : 0,
            user_id : 0,
            is_quitter : false,
        });
    }

}
