
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';
import moment from 'moment';
import { namespace } from 'vuex-class';
const ModalInfo  = namespace('ModalInfo');
const GroupInfo  = namespace('GroupInfo');
const dateFormat = require('dateformat');
const EtcInfo    = namespace('EtcInfo');

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {
        ResizeObserver
    }
}) 
export default class MessageStatus extends Mixins(VueHoduCommon) {
	/**
     * @ModalInfo.Action
     */
	@ModalInfo.Action doSetMessageSendStatus ?: any;

	/**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
	@GroupInfo.State team_id !: number;

	/**
     * EtcInfo.Action
     */
    @EtcInfo.Action doMessageInfo ?: any;
	
    list          : any    = [];
    original_list : any    = [];
	start_date    : string = "";
	end_date      : string = "";
    is_checkedAll : boolean = false;
    
    message_status_target : string | null = null;
    message_status_direction : string = "ASC";

    async mounted() : Promise<void> {
		this.doMessageInfo({});

		const vue = this;

		// 검색 버튼 클릭시 searchDiv 띄우기
        $("#section_ce .schBtn").click(function(){
            $("#section_ce .searchDiv").addClass("on");
			vue.$nextTick(() => $('#group_member_search').focus());
        });

        // 검색 닫기 버튼 클릭시 searchDiv 없애기
        $("#section_ce .searchDiv .closeBtn").click(function(){
            $("#section_ce .searchDiv").removeClass("on");
            $("#group_member_search").val("");
            $("#group_member_search").trigger('keyup');
		});

		if ( this.start_date == "" && this.end_date == "" ) {
            this.start_date = dateFormat(new Date(), 'yyyy-mm-dd');
			this.end_date   = this.getDayTerm(moment(this.start_date).format(), 7, "yyyy-mm-dd");
		}

		// date_picker
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                inst.input.val(`${moment(dateText).format('YYYY.MM.DD')} ${vue.getYoil(dateText)}요일`);

				if ( inst.id == "msg_status_fr_ymd" ) {
					vue.start_date = dateText;
				}
				else if ( inst.id == "msg_status_to_ymd" ) { 
					vue.end_date = dateText;
				}

				if ( vue.start_date >= vue.end_date ) {
					vue.hodu_show_dialog("alert", "종료일자는 시작일자 보다 작거나 같을 수 없습니다", ['확인'], [
						() => {
							if ( inst.id == "msg_status_fr_ymd" ) vue.start_date = inst.lastVal.toString().substring(0,10);
							if ( inst.id == "msg_status_to_ymd" ) vue.end_date = inst.lastVal.toString().substring(0,10);
						},
					]);
					
					return;
				}
				else {
					vue.getMessageStatus();
				}
            },
		};
		
		// @ts-ignore
		$('#msg_status_fr_ymd').datepicker(option);
		
		// @ts-ignore
		$('#msg_status_to_ymd').datepicker(option);

        // 필터검색
        $("#group_member_search").keyup(function(event) {
            let value = $("#group_member_search").val();
            
            if ( value == "" ) {  
                $("#messageStatusUserList li").show();
            }
            else {
                $("#messageStatusUserList li").hide();
                let temp = $("#messageStatusUserList li .name:contains('"+value+"')");
                $(temp).parent().show();
            }
        });        		

		this.getMessageStatus();
		this.setScroll();
	}

	/**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleBoxOuterHeight : number | undefined = $('.title_box').outerHeight();
		const settingBgOuterHeight : number | undefined = $('.settingBg').outerHeight();
		const sortHeaderOuterHeight : number | undefined = $('.msgHeader.sortHeader').outerHeight();

        // @ts-ignore
        $('#message_status_scroll').mCustomScrollbar({
            axis : 'y',
			scrollbarPosition : 'outside',
			mouseWheelPixels : 100,
			scrollInertia : 60,
			autoDraggerLength : false,
			setHeight : window.innerHeight - ( titleBoxOuterHeight == null ? 0 : titleBoxOuterHeight )
										   - ( settingBgOuterHeight == null ? 0 : settingBgOuterHeight )
										   - ( sortHeaderOuterHeight == null ? 0 : sortHeaderOuterHeight ),	
        });
    }

	/**
	 * 리스트 조회
	 */
	async getMessageStatus() : Promise<void> {
		this.list = [];

		let scope : string = (this.team_id > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP);

		const start = moment(this.start_date).set('hour', 0)
										     .set('minute', 0)
										     .set('second', 0)
										     .set('millisecond', 0).utc().format();
													
		const end = moment(this.end_date).set('hour', 23)
										 .set('minute', 59)
										 .set('second', 59)
										 .set('millisecond', 999).utc().format();

		await this.hodu_api_call(`api/v1/message_template/getMessageStatus/${scope}/${this.group_id}/${this.team_id}?start_date=${start}&end_date=${end}`, API_METHOD.GET, null, false)
            .then(async(response) => {
				this.list = response.data.data.list;

				let list_length = this.list.length;

				for ( let i = 0; i < list_length; i++ ) {
					this.list[i].checked = false;
					
					this.list[i].message_start_date = moment(this.list[i].audit_created).format('YYYY.MM.DD');
					this.list[i].message_start_time = moment(this.list[i].audit_created).format('hh:mm A');
                }

                this.original_list = JSON.parse(JSON.stringify(this.list));
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
			});
			
		this.is_checkedAll = false;
	}
	
	/**
	 * 메세지 전송 상태 모달 열기
	 */
	showMessageSendStatus(po_data) : void {
		this.doMessageInfo(po_data);
		window["messageSendStatusPopClose"] = this.messageSendStatusPopClose;
		this.doSetMessageSendStatus(true);
	}

	/**
	 * 메세지 상세보기 닫기
	 */
	messageSendStatusPopClose() : void { 
		this.doMessageInfo({});
		this.doSetMessageSendStatus(false);
	}

	/**
	 * 메세지 전송여부 결과 리턴
	 */
	messageStatusResult(po_data) : boolean {
		let data_length = po_data.push_result.length;

		if ( po_data.data_length < 1 ) {
			return false;
		}

		let result_value = false;

		for ( let i = 0; i < data_length; i++ ) {
			let lo_data = po_data.push_result[i];

			if ( lo_data.status == "FEED_OK" ) {
				result_value = true;
				break;
			}
		}

		return result_value;
	}	
	
	/**
	 * 날짜 변환 후 보여주기
	 */
	displayDate(po_date, ps_format) : string {
		return dateFormat(moment(po_date).format(), ps_format);
	}

    /**
     * 요일 구하기
     */
    getYoil(po_date) : string {
        var week = ['일', '월', '화', '수', '목', '금', '토'];
        var dayOfWeek = week[new Date(moment(po_date).format()).getDay()];
        return dayOfWeek;
	}

    /**
     * Day Term 구하기
     */
    getDayTerm(date, term, format) : string {        
        return dateFormat(moment(date).add(term,'day'), format);
	}	

	/**
     * 전체선택, 전체해제
     */
    async selectAllCheckBox() : Promise<void> {
        this.is_checkedAll = !this.is_checkedAll;
        
        for ( let i = 0; i < this.list.length; i++ ) {
            this.list[i].checked = this.is_checkedAll;
        }
	}
	
    /**
     * 개별 체크박스 선택,해제
     */
    rowSelectCheckBox(pi_index) : void {
		this.list[pi_index].checked = !this.list[pi_index].checked;
		this.is_checkedAll = this.list.filter(item => item.checked == true).length == this.list.length;
	}	
    
    /**
     * 시간 기준 정렬
     */
    timeSort() : void {
        // 이미 TIME Sort 중이라면
        if( this.message_status_target == "TIME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.message_status_direction ) {
                case "ASC":
                    this.message_status_direction = "DESC";
                    break;
            
                case "DESC":
                    this.message_status_target = null;
                    break; 
            }
        }
        
        // TIME Sort가 아니였다면
        else {
            this.message_status_target = "TIME";
            this.message_status_direction = "ASC";
        }

        if( this.message_status_target == null ) { this.setListToOriginalList(); }
        else { this.messageListSort(); }
    }

    /**
     * 수신자명 기준 정렬
     */
    userNameSort() : void {
        // 이미 USER_NAME Sort 중이라면
        if( this.message_status_target == "USER_NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.message_status_direction ) {
                case "ASC":
                    this.message_status_direction = "DESC";
                    break;
            
                case "DESC":
                    this.message_status_target = null;
                    break; 
            }
        }
        
        // USER_NAME Sort가 아니였다면
        else {
            this.message_status_target = "USER_NAME";
            this.message_status_direction = "ASC";
        }

        if( this.message_status_target == null ) { this.setListToOriginalList(); }
        else { this.messageListSort(); }
    }

    /**
     * 그룹&팀 이름 기준 정렬
     */
    groupNameSort() : void {
        // 이미 GROUP_TEAM Sort 중이라면
        if( this.message_status_target == "GROUP_TEAM" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.message_status_direction ) {
                case "ASC":
                    this.message_status_direction = "DESC";
                    break;
            
                case "DESC":
                    this.message_status_target = null;
                    break; 
            }
        }
        
        // GROUP_TEAM Sort가 아니였다면
        else {
            this.message_status_target = "GROUP_TEAM";
            this.message_status_direction = "ASC";
        }

        if( this.message_status_target == null ) { this.setListToOriginalList(); }
        else { this.messageListSort(); }
    }

    /**
     * 내용 기준 정렬
     */
    contentSort() : void {
        // 이미 CONTENT Sort 중이라면
        if( this.message_status_target == "CONTENT" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.message_status_direction ) {
                case "ASC":
                    this.message_status_direction = "DESC";
                    break;
            
                case "DESC":
                    this.message_status_target = null;
                    break; 
            }
        }
        
        // CONTENT Sort가 아니였다면
        else {
            this.message_status_target = "CONTENT";
            this.message_status_direction = "ASC";
        }

        if( this.message_status_target == null ) { this.setListToOriginalList(); }
        else { this.messageListSort(); }
    }

    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.list = JSON.parse(JSON.stringify(this.original_list));
    }

    /**
     * 메세지 발송현황 정렬
     */
    messageListSort() : void {
        this.list.sort((o1 : any, o2 : any) : number => {
            // 발송시간 오름차순
            if( this.message_status_target == "TIME" && this.message_status_direction == "ASC" ) {
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime()  > new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return  1; } 
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime()  < new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return -1; } 
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime() == new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return  0; }
            }

            // 발송시간 내림차순
            if( this.message_status_target == "TIME" && this.message_status_direction == "DESC" ) {
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime()  > new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return -1; } 
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime()  < new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return  1; } 
                if( new Date(o1.message_start_date.replace(/\./, "-") + " " + o1.message_start_time).getTime() == new Date(o2.message_start_date.replace(/\./, "-") + " " + o2.message_start_time).getTime() ) { return  0; }
            }

            // 수신자 이름 오름차순
            if( this.message_status_target == "USER_NAME" && this.message_status_direction == "ASC" ) {
                if( o1.user_name  > o2.user_name ) { return  1; } 
                if( o1.user_name  < o2.user_name ) { return -1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 수신자 이름 내림차순
            if( this.message_status_target == "USER_NAME" && this.message_status_direction == "DESC" ) {
                if( o1.user_name  > o2.user_name ) { return -1; } 
                if( o1.user_name  < o2.user_name ) { return  1; } 
                if( o1.user_name == o2.user_name ) { return  0; }
            }

            // 그룹&팀 이름 오름차순
            if( this.message_status_target == "GROUP_TEAM" && this.message_status_direction == "ASC" ) {
                if( o1.group_team_name  > o2.group_team_name ) { return  1; } 
                if( o1.group_team_name  < o2.group_team_name ) { return -1; } 
                if( o1.group_team_name == o2.group_team_name ) { return  0; }
            }

            // 그룹&팀 이름 내림차순
            if( this.message_status_target == "GROUP_TEAM" && this.message_status_direction == "DESC" ) {
                if( o1.group_team_name  > o2.group_team_name ) { return -1; } 
                if( o1.group_team_name  < o2.group_team_name ) { return  1; } 
                if( o1.group_team_name == o2.group_team_name ) { return  0; }
            }

            // 내용 오름차순
            if( this.message_status_target == "CONTENT" && this.message_status_direction == "ASC" ) {
                if( o1.noti_data.body  > o2.noti_data.body ) { return  1; } 
                if( o1.noti_data.body  < o2.noti_data.body ) { return -1; } 
                if( o1.noti_data.body == o2.noti_data.body ) { return  0; }
            }

            // 내용 내림차순
            if( this.message_status_target == "CONTENT" && this.message_status_direction == "DESC" ) {
                if( o1.noti_data.body  > o2.noti_data.body ) { return -1; } 
                if( o1.noti_data.body  < o2.noti_data.body ) { return  1; } 
                if( o1.noti_data.body == o2.noti_data.body ) { return  0; }
            }

            return 0;
        });
	}
	
	/**
	 * 재전송
	 */
	async resend(data) : Promise<void> {
		this.resendApi([data]);	
	}
	
	/**
	 * 재전송
	 */
	async resendEvent() : Promise<void> {
		let re_send_users : any[]  = [];
		let for_count     : number = this.list.length;

		for ( let i = 0; i < for_count; i++ ) {
			if ( this.list[i].checked ) {
				re_send_users.push(this.list[i]);
			}
		}

		if ( re_send_users.length == 0 ) {
			this.hodu_show_dialog("alert", "메세지 재 발송 할 사용자를 선택해 주세요", ['확인']);
            return;
		}

		this.resendApi(re_send_users);
	}

	/**
	 * 재발송 API
	 */
	async resendApi(users) : Promise<void> {

		console.log(users);

		let send_user_groups : any[] = [];

		// 같은 noti_uid는 다시 묶어서 발송
		for( const user of users ) {
			
			const target = send_user_groups.filter(send_user_group => send_user_group.noti_uid == user.noti_uid); 

			if( target.length < 1 ) {
				send_user_groups.push({
					"noti_uid" : user.noti_uid,
					"group_id" : user.group_id,
					"team_id" : user.team_id,
					"group_team_name" : user.group_team_name,
					"noti_data" : user.noti_data,
					"users" : [user]
				});
				continue;
			}

			target[0].users.push(user);
		}

		console.log(send_user_groups);

		const promise_array : Promise<any>[] = [];
		for( const send_user_group of send_user_groups ) {

			let req_data = {
				users: Array.from(send_user_group.users as any[], x => Number(x.user_id)),
				group_id: send_user_group.group_id,
				team_id: send_user_group.team_id,
				group_team_name: send_user_group.group_team_name,
				message_content: send_user_group.noti_data.body
			}

			const scope = req_data.team_id > 0 ? OWNER_TYPE.TEAM : OWNER_TYPE.GROUP;
        	const scope_id = scope == OWNER_TYPE.GROUP ? req_data.group_id : req_data.team_id;
			
			const promise = this.hodu_api_call(`api/v1/message_template/${scope}/${scope_id}/send`, API_METHOD.POST, req_data);
			promise_array.push(promise);
		}

		await Promise.all(promise_array);

		this.hodu_show_dialog('success', "재전송 완료", ['확인']);
		this.getMessageStatus();

		// console.log(send_users); 

        // let for_count  : number   = this.members.length;

        // for ( let i = 0; i < for_count; i++ ) {
        //     if ( this.members[i].checked ) {
        //         send_users.push(this.members[i].user_id);
        //         /*
        //         send_users.push({
        //             user_id: this.members[i].user_id
        //             //user_name: this.members[i].user_info.user_name,
        //             //user_phone_number: this.members[i].user_info.user_phone_number,
        //             //user_email: this.members[i].user_info.user_email
        //         });
        //         */
        //     }
        // }

        // if ( send_users.length == 0 ) {
        //     this.hodu_show_dialog("alert", "메세지 발송 할 그룹원을 선택해주세요", ['확인']);
        //     return;
        // }

        // let req_data = {
        //     users: send_users,
        //     group_id: this.selected_group_id,
        //     team_id: this.selected_team_id,
        //     group_team_name: this.selected_group_team_name,
        //     message_content: this.message_content
        // }
        
        // const scope = this.scope;
        // const scope_id = this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id;

        // await this.hodu_api_call(`api/v1/message_template/${scope}/${scope_id}/send`, API_METHOD.POST, req_data)
        //     .then(async(response) => {
        //         this.hodu_show_dialog("success", "성공적으로 메세지 발송하였습니다", ['확인']);
        //     })
        //     .catch(async(e) => {
        //         this.hodu_error_process(e, true, false);
		// 	});

	}
	
	/**
     * 리사이즈 감지
     */
    handleResize() : void {
		// @ts-ignore
		$('#message_status_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
	}

}
