
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import ApprovalFormDownload from '@/components/approval/ApprovalFormDownload.vue';
import ApprovalFormSetting from '@/components/approval/ApprovalFormSetting.vue';

import { approval_enum, approval_interface } from '@/model/approval';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ApprovalFormDownload, ApprovalFormSetting
    },
})
export default class ApprovalFormBox extends Mixins(VueHoduCommon) {
    
    @Prop() event_bus !: Vue;
    @Prop() tab !: approval_enum.APPROVAL_BOX_TAB;
    @Prop() all_templates !: approval_interface.ApprovalTemplateBundle[];
    @Prop() templates !: approval_interface.ApprovalTemplateBundle[];
    @Prop() none_class_template !: any;
    @Prop() default_template !: any;
    @Prop() vacation_template !: any;
    @Prop() business_trip_template !: any;
    @Prop() holiday_work_template !: any;
    @Prop() cash_disbursement_voucher_template !: any;
    @Prop() free_template !: any;
    @Prop() receive_template !: any;

    mounted() : void {
        
    }

    /**
     * 양식 설정 조회
     */
    async getApprovalTemplateInfo() : Promise<void> {
        this.$emit('getApprovalTemplateInfo');
    }

    /**
     * 양식 다운로드
     */
    isFormDownload() : boolean {
        return this.tab == approval_enum.APPROVAL_BOX_TAB.FORM_DOWNLOAD;
    }

    /**
     * 양식 설정
     */
    isFormSetting() : boolean {
        return this.tab == approval_enum.APPROVAL_BOX_TAB.FORM_SETTING;
    }

}
