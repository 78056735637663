
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import moment from 'moment';

import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class ResidentCarList extends Mixins(VueHoduCommon) {

    /**
     * 선택된 동의 리스트만 반환 
     */
    get computedCarListByTargetBuilding() : any[] {
        let final_list : any[] = JSON.parse(JSON.stringify(this.car_list));

        final_list = final_list.filter(item => new RegExp(escape(this.search_word)).test(escape(item.car_info.car_number)) == true);

        if( this.target_building == 'TARGET_ALL') {
            return final_list;
        }

        final_list = final_list.filter(item => item.resident_info.building == this.target_building);
        
        return final_list;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeResidentCarRequestModalInfo ?: (params : hodu_home_modal_info.HomeResidentCarRequestModalInfo) => void;

    target_building : string = "TARGET_ALL";

    start_date : Date = new Date();
    end_date : Date = new Date();

    apartment_building_list : any[] = [];
    car_list : any[] = [];

    is_search_open : boolean = false;
    search_word    : string = "";

    buildings : any[] = [];

    mounted() : void {
        this.start_date = moment().add('month', -1).toDate(); 

        // $('.seeDets').click(function(){
        //     $('.bg4Modal').fadeIn(200); 
        //     $('.modal').show();
        //     return false;
        // });

        // $('.modal .btns input.close').click(function(){
        //     $('.bg4Modal').hide();
        //     $('.modal').hide()
        // });

        const vue = this;

        const datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
                inst.input.val(mnt.format('YYYY.MM.DD') + ' ' + vue.getDayOfWeekByDate(mnt.toDate()));
                
                // 시작일 변경
                if( id == 'resident_car_fr_ymd' ) {
                    vue.start_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.end_date = moment(vue.start_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
                    }
                } 

                // 종료일 변경
                else if( id == 'resident_car_to_ymd' ) {
                    vue.end_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.start_date = moment(vue.end_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                    }
                }

                vue.end_date = moment(vue.end_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

                Promise.all([vue.getBuildings(), vue.getHoduHomeInfo(), vue.getCarList()]);
            },
        };

        // @ts-ignore
        $('#resident_car_fr_ymd').datepicker(datepicker_option);

        // @ts-ignore
        $('#resident_car_to_ymd').datepicker(datepicker_option);

        Promise.all([this.getBuildings(), this.getHoduHomeInfo(), this.getCarList()]);
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hoduhome_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });
    }

    /**
     * 동 조회
     */
    async getBuildings() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/building`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.buildings ) {
                throw new Error("동 조회 중 오류 발생");
            }

            this.buildings.splice(0, this.buildings.length);
            this.buildings = this.buildings.concat(response.data.data.buildings);

        } catch(e) {
            this.hodu_show_dialog("cancel", "동 정보 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 호두홈 정보 조회
     */
    async getHoduHomeInfo() : Promise<void> {
        
        try {
            
            const response = await this.hodu_api_call(`api/v1/home/info/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null, false)

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.home_info ) {
                throw new Error("입주자 차량 조회 중 오류 발생");
            }

            const home_info : any = response.data.data.home_info;

            this.apartment_building_list.splice(0, this.apartment_building_list.length);
            if( home_info.building_info != null ) this.apartment_building_list = this.apartment_building_list.concat(home_info.building_info);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 입주자 차량 리스트 조회
     */
    async getCarList() : Promise<void> {

        try {

            const start = moment(this.start_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).utc().format(); 
            const end   = moment(this.end_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).utc().format();

            const response = await this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}?is_resident=true&start_date=${start}&end_date=${end}&is_web=true&include_deleted_car=true`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.car_list ) {
                throw new Error("입주자 차량 조회 중 오류 발생");
            }

            this.car_list.splice(0, this.car_list.length);
            this.car_list = this.car_list.concat(response.data.data.car_list.filter(car => car.car_info.state != 'TEMP'));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 검색창 열기 / 닫기
     */
    searchOnOff() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_word = "";
        }

        // 검색 창 열릴때 검색 창에 포커스 가도록
        else {
            this.$nextTick(() => { $('#resident_car_search_text').focus(); });
        }
    }

    /**
     * 조회 대상 동을 변경
     */
    changeTargetBuilding(value : string) : void {
        this.target_building = value;
    }

    /**
     * 오늘 클릭
     */
    clickToday() : void {
        this.start_date = moment().set('hour', 0)
                                  .set('minute', 0)
                                  .set('second', 0)
                                  .set('millisecond', 0).toDate();

        this.end_date = moment(this.start_date).set('hour', 23)
                                               .set('minute', 59)
                                               .set('second', 59)
                                               .set('millisecond', 999).toDate();

        this.getBuildings();
        this.getHoduHomeInfo();
        this.getCarList();
    }

    /**
     * 시간 텍스트 반환
     */
    getTimeText(car : any) : string {

        const start_date = new Date(new Date(car.start_date));
        const end_date = new Date(car.end_date);
        
        const start = `${this.hodu_date_to_format_string(start_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(start_date)}`;

        // 포맷 (같은 년월은 표시하지 않는다)
        // let end_format = 'YY.MM.DD';
        // if( start_date.getFullYear() == end_date.getFullYear() ) {
        //     end_format = 'MM.DD';

        //     if( start_date.getMonth() == end_date.getMonth() ) {
        //         end_format = 'DD';

        //         if( start_date.getDate() == end_date.getDate() ) {
        //             end_format = '';
        //         }
        //     }
        // }

        // const end   = `${this.hodu_date_to_format_string(end_date, end_format)} ${this.getDayOfWeekByDate(end_date)}`;
        // const term = `(${this.getDateDiff(start_date, end_date) + 1}일)`
        // const term = `(방문기간 ${this.getDateDiff(start_date, end_date) + 1}일)`

        // return `${start}${end_format == '' ? ' ' : ` ~ ${end} `}${term}`;
        return `${start}`;
    }

    /**
     * 호두홈 입주자 차량 승인 모달
     */
    showHomeResidentCarRequestModalInfo(car) : void {
        
        const copy_car = JSON.parse(JSON.stringify(car));
        
        // try {
        //     copy_car.resident_info.building = this.apartment_building_list.filter(item => item.team_id == copy_car.resident_info.building)[0].team_info.team_name;
        // } catch(e) {
        //     copy_car.resident_info.building = '-';
        // }

        this.doSetHomeResidentCarRequestModalInfo?.({
            show_modal : true,
            resident : { "resident_info" : copy_car.resident_info },
            car : copy_car,
            callback : () => { Promise.all([this.getBuildings(), this.getHoduHomeInfo(), this.getCarList()]); }
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
    	// @ts-ignore
        $("#hoduhome_list_scroll").mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
