
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { Hooper, Slide } from 'hooper';

@Component({
    components: {
        Hooper, Slide
    },
})
export default class EquipmentManagementDetailModal extends Mixins(VueHoduCommon) {
    
    // this.$refs.hooperSlide 사용
    $refs !: { hooperSlide : HTMLFormElement; }

    /**
     * ModalInfo.State
     */
    @ModalInfo.State equipment_management_detail_modal_info !: hodu_home_modal_info.EquipmentManagementDetailModalInfo; 

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetEquipmentManagementDetailModalInfo ?: (params : hodu_home_modal_info.EquipmentManagementDetailModalInfo) => void;
    
    equipment : any = null;
    management : any = null;

    view_index : number = 0;

    mounted() : void {
        Promise.all([this.getHomeEquipment(), this.getHomeEquipmentManagement()]);
    }

    /**
     * 장비 조회
     */
    async getHomeEquipment() : Promise<void> {

        try {

            const uid = this.equipment_management_detail_modal_info.uid;

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.equipment ) {
                throw new Error("장비 조회 중 오류 발생");
            }

            this.equipment = JSON.parse(JSON.stringify(response.data.data.equipment));

        } catch(e) {
            alert("장비 조회 중 오류 발생");
            this.equipment_management_detail_modal_info.callback?.();
            this.hodu_error_process(e, false, false, true);
            close();
        } 

    }

    /**
     * 장비 관리 이력 상세 조회
     */
    async getHomeEquipmentManagement() : Promise<void> {

        try {

            const uid = this.equipment_management_detail_modal_info.uid;
            const seq = this.equipment_management_detail_modal_info.seq; 

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${uid}/management/${seq}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.management ) {
                throw new Error("장비 관리 이력 조회 중 오류 발생");
            }

            this.management = JSON.parse(JSON.stringify(response.data.data.management));
            
        } catch(e) {
            alert("장비 관리 이력 조회 중 오류 발생");
            this.equipment_management_detail_modal_info.callback?.();
            this.hodu_error_process(e, false, false, true);
            this.close();
        } 

    }

    /**
     * 장비 관리 이력 삭제
     */
    async deleteHomeEquipmentManagement() : Promise<void> {
        try {

            const uid = this.equipment_management_detail_modal_info.uid;
            const seq = this.equipment_management_detail_modal_info.seq; 

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${uid}/management/${seq}`, API_METHOD.DELETE);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("장비 관리 이력 삭제 중 오류 발생");
            }

            this.equipment_management_detail_modal_info.callback?.();
            this.close();
            
        } catch(e) {
            alert("장비 관리 이력 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            Promise.all([this.getHomeEquipment(), this.getHomeEquipmentManagement()]);
        }
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 이미지 크게 보기
     */
    showImageBigger(image) : void {
        if( this.$refs.hooperSlide.isSliding == true ) return;
        window.open(`app_images/${image.url}`, '_blank');
    }

    /**
     * 현재 보고있는 인덱스로 세팅
     */
    updateViewIndex(payload : any) : void {
        this.view_index = payload.currentSlide;
    }

    /**
     * 해당 인덱스로 이동
     */
    slideTo(index : number) : void {
        this.view_index = index;
        this.$refs.hooperSlide.slideTo(index);
    }

    /**
     * 수정
     */
    modify() : void {
        const uid = this.equipment_management_detail_modal_info.uid;
        const seq = this.equipment_management_detail_modal_info.seq; 

        this.hodu_router_push(`/home/equipment/${uid}/management/modify/${seq}`);
        this.close();
    }

    /**
     * 삭제
     */
    remove() : void {
        if( confirm("정말 장비 관리 이력을 삭제 하시겠습니까?") == false ) return;
        this.deleteHomeEquipmentManagement();
    }

    /**
     * 닫기
     */
    close() : void {
        this.doSetEquipmentManagementDetailModalInfo?.({ show_modal : false, uid : "", seq : 0 });
    }

}
