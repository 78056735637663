
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupSurveyTemplateModalInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupSurvey extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupSurveyTemplateModalInfo ?: (params : GroupSurveyTemplateModalInfo) => void;

    mounted() : void {
        //toggleClass
        $("#exampleText").click(function(){
            $(".selectUl").toggleClass("on");
        });

        $(".faved_new").click(function(){
            $(this).toggleClass('on')
        })

        //siblings toggle
        $('.surveyMenu').click(function(event){
            event.stopPropagation(); 
            $(this).siblings('ul').toggle();   
        });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // // @ts-ignore
        // $('#group_member_scroll').mCustomScrollbar('destroy');
        
    	// this.setScroll();
    }

    viewTemplate() {
        this.doSetGroupSurveyTemplateModalInfo?.({ show_modal : true });
    }

    goSurveyCreate() {
        this.hodu_router_push(`/group/${new Date().getTime()}/survey/create`);
    }

    goSurveyDetail() {
        this.hodu_router_push(`/group/${new Date().getTime()}/survey/survey_id`);
    }

}
