
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const WorkInfo = namespace('WorkInfo');
const ApprovalInfo = namespace('ApprovalInfo');
const AttendanceInfo = namespace('AttendanceInfo');
const OrganizationInfo = namespace('OrganizationInfo');

import { approval_enum, approval_interface } from '@/model/approval';
import { organization_enum } from '@/model/organization';
import { attendance_enum } from '@/model/attendance';

import { WorkSearchInfo } from '@/store/modules/WorkInfo';
import moment from 'moment';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';

@Component({

})
export default class LeftWork extends Mixins(VueHoduCommon) {
    
    /**
     * @WorkInfo.Action
     */
    @WorkInfo.Action doSetWorkSearchInfo ?: (params : WorkSearchInfo) => void;
    @WorkInfo.Action doSetReportSearchInfo ?: (params : WorkSearchInfo) => void;
    @WorkInfo.Action doSetMeetinglogSearchInfo ?: (params : WorkSearchInfo) => void;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State show_work_pdf_modal !: boolean;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowWorkPdfModal ?: (params : boolean) => void;

    /** 
     * AttendanceInfo.Action 
     */ 
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarViewType    ?: (params : attendance_enum.ATTENDANCE_CALENDAR_TYPE) => void;

    /** 
     * ApprovalInfo.Action 
     */ 
    @ApprovalInfo.Action doSetSelectedTab         ?: (params : approval_enum.APPROVAL_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedDraftTab    ?: (params : approval_enum.APPROVAL_DRAFT_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedReceiveTab  ?: (params : approval_enum.APPROVAL_RECEIVE_BOX_TAB) => void;
    @ApprovalInfo.Action doSetFilter              ?: (params : approval_interface.ApprovalFilter) => void;
    @ApprovalInfo.Action doSetDraftFilter         ?: (params : approval_interface.ApprovalFilter) => void;
    @ApprovalInfo.Action doSetReceiveFilter       ?: (params : approval_interface.ApprovalFilter) => void;
    @ApprovalInfo.Action doSetIsFirstInitComplete ?: (params : boolean) => void;

    /**
     * @OrganizationInfo.Action
     */
    @OrganizationInfo.Action doSetOrganizationSelectedTab ?: (params : organization_enum.ORGANIZATION_SETTING_TAB) => void;
    @OrganizationInfo.Action doSetOrganizationSelectedVacationTab ?: (params : organization_enum.ORGANIZATION_VACATION_TAB) => void;

    mounted() : void {
        if( this.scope != null && this.scope != OWNER_TYPE.PERSONAL ) { this.doSetWorkMenuClose(true); }
    }

    /**
     * 업무 메뉴 ON / OFF
     */
    workMenuOnOff() : void {
        // if( this.left_control_box_flag == true ) {
        //     this.doSetWorkMenuClose(false);
        //     this.doSetLeftControlBoxFlag(false);
        //     return;
        // }

        this.doSetWorkMenuClose(!this.work_menu_close);
    }

    /**
     * 업무일지 리스트로 이동
     */
    goReportList() : void {
        this.doSetReportSearchInfo?.({});
        this.hodu_router_push(`/report?${new Date().getTime()}`);
    }

    /**
     * 회의록 리스트로 이동
     */
    goMeetingLogList() : void {
        this.doSetMeetinglogSearchInfo?.({});
        this.hodu_router_push(`/meetinglog?${new Date().getTime()}`);
    }

    /**
     * 업무 리스트로 이동
     */
    goWorkList() : void {
        this.doSetWorkSearchInfo?.({});
        this.hodu_router_push(`/work/${ new Date().getTime() }/list`);
    }

    /**
     * 업무 세팅으로 이동 (템플릿 설정)
     */
    goWorkSetting() : void {
        this.hodu_router_push(`/work/${ new Date().getTime() }/setting`);
    }

    /**
     * 업무 문서화 모달 열기
     */
    showWorkPdfModal() : void {
        // this.doSetShowWorkPdfModal?.(!this.show_work_pdf_modal);
        this.hodu_router_push(`/work/documentation?${new Date().getTime()}`);
    }

    /**
     * 업무 일지 읽기 권한 체크
     */
    getReportReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "report", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "report", "read");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 회의록 읽기 권한 체크
     */
    getMeetingLogReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "meetinglog", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "meetinglog", "read");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 업무 읽기 권한 체크
     */
    getWorkReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "work", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "work", "read");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 권한에 따라서 문서화 텍스트 반환
     */
    getDocumentationText() : string {

        if( this.getReportReadPermission() == true && this.getMeetingLogReadPermission() == true ) {
            return '업무일지, 회의록 문서화';
        }

        if( this.getReportReadPermission() == true ) {
            return '업무일지 문서화';
        }

        if( this.getMeetingLogReadPermission() == true ) {
            return '회의록 문서화';
        }

        return "";
    }

    /**
     * 전자결재
     */
    moveApproval() : void {
        
        const filter_end   = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        const filter_start = moment(filter_end).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).add('month', -1).toDate();

        const approval_filter_string = hodu_local_storage.getItem("approval_filter");

        console.log(approval_filter_string);

        if( approval_filter_string != null && approval_filter_string.length > 0 ) {
            const approval_filter = JSON.parse(approval_filter_string);
            const approval_filter_group = approval_filter[`${this.user_id}_${this.scope_group_id}`];

            console.log(approval_filter_group);
            
            if( approval_filter_group != null ) {
                const draft_filter = approval_filter_group.draft_filter;
                const receive_filter = approval_filter_group.receive_filter;

                draft_filter.start = filter_start;
                draft_filter.end = filter_end;
                draft_filter.search_query = "";

                receive_filter.start = filter_start;
                receive_filter.end = filter_end;
                receive_filter.search_query = "";

                this.doSetDraftFilter?.(JSON.parse(JSON.stringify(draft_filter)));
                this.doSetReceiveFilter?.(JSON.parse(JSON.stringify(receive_filter)));
            }
            else {
                this.setDefaultApprovalFilter();
            }
        }
        else {
            this.setDefaultApprovalFilter();
        }

        this.doSetSelectedTab?.(approval_enum.APPROVAL_BOX_TAB.DRAFT);
        this.doSetSelectedDraftTab?.(approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
        this.doSetSelectedReceiveTab?.(approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
        this.doSetIsFirstInitComplete?.(false);
        this.hodu_router_push(`/${this.scope}/${this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id}/approval?${new Date().getTime()}`);
    }

    setDefaultApprovalFilter() {

        const filter_end   = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        const filter_start = moment(filter_end).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).add('month', -1).toDate();
        
        const default_filter = {
            search_query : "",
            start : filter_start,
            end : filter_end,
            is_emergency : true,
            is_importance : true,
            is_normal : true,
            importance_filter : [10, 20, 30],
            departments : [],
            dept_ids : [],
            is_selecet_all_departments : true,
            is_approval : true,
            is_vacation : true,
            is_holiday_work : true,
            is_businesstrip : true,
            is_cash_disbursement_voucher : true,
            is_free : true,
        };

        this.doSetDraftFilter?.(default_filter);
        this.doSetReceiveFilter?.(default_filter);

        const approval_filter_string = hodu_local_storage.getItem("approval_filter");

        if( approval_filter_string != null && approval_filter_string.length < 1 ) {
            const approval_filter = JSON.parse(approval_filter_string);
            approval_filter[`${this.user_id}_${this.scope_group_id}`] = { "draft_filter" : default_filter, "receive_filter" : default_filter };
            hodu_local_storage.setItem("approval_filter", JSON.stringify(approval_filter));
        }
        else {
            const approval_filter = {};
            approval_filter[`${this.user_id}_${this.scope_group_id}`] = { "draft_filter" : default_filter, "receive_filter" : default_filter };
            hodu_local_storage.setItem("approval_filter", JSON.stringify(approval_filter));
        }
    }

    /**
     * 전자결재 설정
     */
    moveOrganizationSetting() : void {
        this.doSetOrganizationSelectedTab?.(organization_enum.ORGANIZATION_SETTING_TAB.RANK);
        this.hodu_router_push(`/${this.scope}/${this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id}/organization/setting?${new Date().getTime()}`);
    }

    /**
     * 근태 현황
     */
    moveAttendance() : void {
        this.doSetAttendanceStartDate(new Date());
        this.doSetCalendarViewType?.((this.get_group_role(this.scope_group_id) == 'GROUP_MANAGER' || this.get_group_role(this.scope_group_id) == 'ADMIN') ? attendance_enum.ATTENDANCE_CALENDAR_TYPE.DAYGRID_MONTH : attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST);
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/attendance?${new Date().getTime()}`);
    }

}
