
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const EventInfo = namespace('EventInfo');

import { EventHistoryModalInfo } from '@/store/modules/ModalInfo';
import { t_event } from '@/model/event';

@Component({
    components: {
        
    },
})
export default class EventHistoryModal extends Mixins(VueHoduCommon) {

    @EventInfo.State event!: t_event; // 일정 정보

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventHistoryModalInfo ?: (params : EventHistoryModalInfo) => void;
    
    histories : any[] = []

    mounted() : void {
        this.getHistories()
    }

    async getHistories() {

        const owner_type = this.event.team_id && this.event.team_id > 0 ? "TEAM" : this.event.group_id && this.event.group_id > 0 ? "GROUP" : "PERSONAL" ;
        const owner_id = owner_type == "PERSONAL" ? this.user_id : owner_type == "GROUP" ? this.event.group_id ?? 0 : this.event.team_id ?? 0
        
        try {
            const response = await this.hodu_api_call(`api/v1/calendars/${this.event.calendar_id}/events/${owner_type}/${owner_id}/${this.event.event_id}/log`, API_METHOD.GET, null, false);

            console.log(response);

            if( response.data.data.logs == null ) return;

            this.histories = this.histories.concat(JSON.parse(JSON.stringify(response.data.data.logs)));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    getDescription(type: string) : string {
        let desc = ""

        switch ( type ) {
            
            case "EVENT_CREATE" : 
                desc = "일정을 생성했습니다";
                break;

            case "EVENT_MODIFY" : 
                desc = "일정을 수정했습니다";
                break;

            case "EVENT_MOVE" :
                desc = "일정을 이동시켰습니다";
                break;

            case "EVENT_DELETE" :
                desc = "일정을 삭제했습니다";
                break;

            case "REPEAT_INSTANCE_UPDATE" :
                desc = "반복 일정을 이 일정만 수정 옵션으로 수정했습니다";
                break;

            case "REPEAT_AFTER_UPDATE" :
                desc = "반복 일정을 이후 일정 수정 옵션으로 수정했습니다";
                break;

            case "REPEAT_INSTANCE_DELETE" :
                desc = "반복 일정을 이 일정만 수정 옵션으로 삭제했습니다";
                break;

            case "REPEAT_AFTER_DELETE" :
                desc = "반복 일정을 이후 일정 수정 옵션으로 삭제했습니다";
                break;
                
            default:
                desc = "일정을 수정했습니다";
                break;
        }

        return desc
    }

    close() : void {
        this.doSetEventHistoryModalInfo?.({ show_modal : false, event_id : "" });
    }

}
