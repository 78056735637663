var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content",attrs:{"id":"organization_work_type_setting"}},[((_vm.get_group_role(_vm.scope_group_id) == 'GROUP_MANAGER' || _vm.get_group_role(_vm.scope_group_id) == 'ADMIN'))?_c('div',{staticClass:"bt_scheduleBox"},[_c('input',{staticClass:"bt_schedule",attrs:{"type":"button","id":"btnAddVacationType"},on:{"click":function($event){$event.preventDefault();return _vm.showOrganizationWorkTypeAddModalInfo(true)}}})]):_vm._e(),_c('div',{staticClass:"title_box3",class:{ notAdmin : (_vm.get_group_role(_vm.scope_group_id) != 'GROUP_MANAGER' && _vm.get_group_role(_vm.scope_group_id) != 'ADMIN') }},[_c('p',{staticClass:"desc"},[_vm._v("근태현황 시작일 : "),_c('span',{staticClass:"date",on:{"click":function($event){$event.preventDefault();return _vm.setAttedanceStart.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.getStart() == '' ? '근태현황 기능을 사용하려면 시작일을 설정해주세요' : _vm.hodu_date_to_format_string(_vm.yyyymmddToDate(_vm.getStart()), 'YYYY년 MM월 DD일')))])])]),_vm._m(0),_c('div',{staticClass:"scroll",attrs:{"id":"organization_work_type_scroll"}},[_c('div',{staticClass:"difTypeDiv"},[_c('ul',{staticClass:"difTypeUl"},_vm._l((_vm.computedWorkTypes),function(work_type,index){return _c('li',{key:work_type.work_type_id},[_c('p',{staticClass:"num"},[_vm._v(_vm._s(index + 1))]),_c('p',{staticClass:"type_title"},[_vm._v(_vm._s(work_type.work_type_name))]),_c('div',{staticClass:"days"},_vm._l((work_type.contents),function(day){return _c('p',{key:day.week,class:{ on : day.am_from || day.pm_from || day.is_telecommuting == true, off : day.is_telecommuting == false && !day.am_from && !day.pm_from }},[_c('span',{staticClass:"day"},[_vm._v(_vm._s(_vm.getDayName(day.week)))]),_c('span',{staticClass:"dot",class:{
                                home : day.is_telecommuting == true, 
                                morn : day.is_telecommuting == false && day.am_from && !day.pm_from, 
                                night : day.is_telecommuting == false && !day.am_from && day.pm_from, 
                                allday : day.is_telecommuting == false && day.am_from && day.pm_from 
                                }},[_vm._v("근무")])])}),0),_c('p',{staticClass:"peep"},[(work_type.employees.length > 0)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showOrganizationWorkTypeDetailModalInfo(work_type.work_type_id)}}},[_c('span',{staticClass:"img first",style:({ backgroundImage : `url(${_vm.getProfileImage(work_type.employees[0])})` })}),(work_type.employees.length > 1)?_c('span',{staticClass:"img last",style:({ backgroundImage : `url(${_vm.getProfileImage(work_type.employees[1])})` })},[(work_type.employees.length > 2)?_c('span',[_vm._v("+"+_vm._s(work_type.employees.length - 2))]):_vm._e()]):_vm._e(),_c('img',{staticClass:"dummy",attrs:{"src":_vm.getProfileImage(work_type.employees[0])},on:{"error":function($event){return _vm.userImageError($event)}}}),(work_type.employees.length > 1)?_c('img',{staticClass:"dummy last",attrs:{"src":_vm.getProfileImage(work_type.employees[1])},on:{"error":function($event){return _vm.userImageError($event)}}}):_vm._e()]):_vm._e()]),_c('a',{staticClass:"seeDets",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showOrganizationWorkTypeDetailModalInfo(work_type.work_type_id)}}},[_vm._v("자세히 보기")]),((_vm.get_group_role(_vm.scope_group_id) == 'GROUP_MANAGER' || _vm.get_group_role(_vm.scope_group_id) == 'ADMIN'))?_c('a',{staticClass:"editBtn",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showOrganizationWorkTypeAddModalInfo(false, work_type.work_type_id)}}},[_vm._v("수정")]):_vm._e()])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"sortHeader"},[_c('li',{staticClass:"num"},[_vm._v("No")]),_c('li',{staticClass:"type_title"},[_vm._v("타입명")]),_c('li',{staticClass:"days"},[_vm._v("요일 근무 여부")]),_c('li',{staticClass:"peep"},[_vm._v("사용 직원")])])
}]

export { render, staticRenderFns }