
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HomeContactCreateModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_contact_create_modal_info !: hodu_home_modal_info.HomeContactCreateModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeContactCreateModalInfo  ?: (params : hodu_home_modal_info.HomeContactCreateModalInfo) => void;

    is_create : boolean = true;

    key : string = "";
    name : string = "";
    tel : string = "";

    mounted() : void {
        this.is_create = this.home_contact_create_modal_info.contact == null;
        this.key = require("uuid/v4")();

        if( this.is_create == false ) {
            this.key = this.home_contact_create_modal_info.contact.key;
            this.name = this.home_contact_create_modal_info.contact.name;
            this.tel = this.home_contact_create_modal_info.contact.tel;
        }
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetHomeContactCreateModalInfo == null ) { return; }

        this.doSetHomeContactCreateModalInfo({
            show_modal : false
        });
    }

    /**
     * 추가 또는 수정
     */
    createOrUpdateContact() : void {

        if( this.name.trim().length < 1 ) {
            alert("이름을 입력 해주세요");
            $('.titleLi .descript input[type=text]').focus();
            return;
        }

        if( this.tel.trim().length < 1 ) {
            alert("번호를 입력 해주세요");
            $('.telLi .descript input[type=text]').focus();
            return;
        }

        // TODO 전화번호 valid 체크
        
        window[this.is_create ? 'createHomeContact' : 'updateHomeContact']({
            key : this.key,
            name : this.name.trim(),
            tel : this.tel.trim(),
        })

        this.closeModal();
    }

    /**
     * 삭제
     */
    deleteContact() : void {
        if( confirm("연락처를 삭제 하시겠습니까?") == false ) { return; }
        window['deleteHomeContact'](this.key);
        this.closeModal();
    }

    

}
