
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import moment from 'moment';
import { WorkStatusInfo } from '@/store/modules/WorkInfo';

const dateFormat = require('dateformat');
const WorkInfo = namespace('WorkInfo');
const ModalInfo = namespace('ModalInfo');

import { ResizeObserver } from 'vue-resize'

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {
        ResizeObserver
    }
})
export default class WorkStatus extends Mixins(VueHoduCommon) {
    /**
     * @WorkInfo.State
     */
    @WorkInfo.State   work_status_info !: WorkStatusInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowWorkStatus ?: any;

    work_color             : string    = "";
    work_status_title      : string    = "";
    work_template_contents : any[]     = [];
    no_work_start_users    : any       = [];
    total_work_user_count  : number    = 0;
    work_start_user_count  : number    = 0;
    replash_times          : string    = "";
    template_type          : string    = "";
    check_user_ids         : number[]  = [];    // 체크식 업무중 사용자 카운트
    see_chart              : boolean   = false; // 차트를 보는 중인지 여부
    work_status_list       : any[]     = [];    // 업무자 정보 리스트

    user_profile_no_image = require("@/assets/images/contents/im_photoB.gif");

    mounted() {
        // @ts-ignore
        // $(".workStatus .people").mCustomScrollbar();
		
		// 업무현황 > 시작전
		$(".workStatus .people2 .ul2 > li a").click(function(){
			$(this).parent().addClass("ing");	
		});
		
		// 업무현황 상단 업무명 드롭다운
		// $(".workStatus .titleBg a").click(function(){
		// 	// 버튼 화살표 위치변경
		// 	$(this).toggleClass("on");
		// 	// 드롭다우 보이기
		// 	$(this).parent().siblings(".dropdown").toggleClass("on");	
		// 	//드롭다운 뒤에 하얀 배경
		// 	$(".workStatus .bg").toggleClass("on");
			
        // });
        
		//  업무현황 상단 업무명 드롭다운 하얀 배경 클릭시 모든 on빼기
		// $(".workStatus .bg").click(function(){
		// 	$(this).removeClass("on");
		// 	$(".workStatus .titleBg a").removeClass("on");
		// 	$(".workStatus .titleBg .dropdown").removeClass("on");	
        // });
        
        this.setScroll();
        
        // 업무현황조회
        this.getWorkStatus();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height    : number | undefined = $('.left_titleBox').outerHeight();
        const title_bg_height : number | undefined = $('.titleBg').outerHeight();
        const final_height    : number = window.innerHeight - (title_height ? title_height : 0) - (title_bg_height ? title_bg_height : 0);

        // @ts-ignore
        $("#statusScroll").mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : final_height
        });
    }

    /**
     * 업무 현황 조회
     */
    async getWorkStatus() : Promise<void> {
        this.work_status_title      = "";
        this.work_template_contents = [];
        this.no_work_start_users    = [];
        this.total_work_user_count  = 0;
        this.work_start_user_count  = 0;
        this.check_user_ids         = [];

		await this.hodu_api_call(`api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/status/${this.work_status_info.event_id}`, API_METHOD.GET, null)
			.then(async(response) => {
                let lo_data = response.data.data.list[0];
                this.template_type = lo_data.template_type;

                this.work_status_list = lo_data.status_info;

                // JSON 만들기
                for ( let i = 0; i < lo_data.template_info.content.length; i++ ) {
                    let vo : any = lo_data.template_info.content[i];

                    this.work_template_contents.push({
                         color: await this.hodu_hex_color_process(vo.color)
                       , title: vo.title
                       , horizontal_percent : 0
                       , vertical_percent: 0
                       , status_info: []
                    })
                }

                // 체크식
                if ( this.template_type == "CHECK" ) { 
                    this.checkDisplay(lo_data);
                }
                // 단계식
                else {
                    this.flowDisplay(lo_data)
                }
			})
			.catch(async(e) => {
                this.hodu_error_process(e, false, false);
                this.doSetShowWorkStatus(false);
			});
    }
    
    /**
     * 체크식 데이터만들기
     */
    async checkDisplay(lo_data) : Promise<void> {
        let work_status_length = this.work_template_contents.length;

        // 업무를 시작한사람
        for ( let i = 0; i < lo_data.status_info.length; i++ ) {
            let vo       : any = lo_data.status_info[i];
            let progress : any = vo.work_status_info.progress;

            for ( let j = 0; j < work_status_length; j++ ) {
                if ( progress[j].status == "END" ) {
                    this.work_template_contents[j].status_info.push(vo);

                    if ( this.check_user_ids.indexOf(vo.user_id) < 0 ) { this.check_user_ids.push(vo.user_id) }
                }
            }
        }

        // 업무를 시작안한사람 찾기
        for ( let i = 0; i < lo_data.status_info.length; i++ ) {
            for ( let j = 0; j < work_status_length; j++ ) {
                let vo : any = lo_data.status_info[i];

                if ( this.check_user_ids.indexOf(vo.user_id) > -1 ) {
                    continue;
                }
                else {
                    if ( !this.no_work_start_users.find(u => u.user_id == vo.user_id) ) {
                        this.no_work_start_users.push(vo);
                        break;
                    }
                }
            }
        }

        for ( let i = 0; i < this.work_template_contents.length; i++ ) {
            if ( this.work_template_contents[i].status_info.length > 0 ) {
                this.work_start_user_count += this.work_template_contents[i].status_info.length;
            }
        }

        this.total_work_user_count = this.work_start_user_count;

        this.makePercent(lo_data.event_data.title, lo_data.event_data.color);
    }

    /**
     * 퍼센트만들기
     */
    makePercent(ps_title : string, ps_color : string) : void {
        this.work_status_title = ps_title;
        this.work_color        = ps_color;

        this.replash_times = this.amPmStringToLocaleAmPmString(moment(new Date()).format('YYYY.MM.DD hh:mm:ss A'));

        let total_count : number = 0;
        for(let contents of this.work_template_contents) {
            total_count += contents.status_info.length;
        }

        const contents_count : number = this.work_template_contents.length;

        // 아무도 업무 진행을 하지 않은 경우 
        if( total_count == 0 ) {
            for ( let i = 0; i < contents_count; i++ ) {
                const contents : any = JSON.parse(JSON.stringify(this.work_template_contents[i]));
                contents.horizontal_percent = (100 / (this.work_template_contents.length)).toFixed(10);
                contents.vertical_percent   = 3;
                this.work_template_contents.splice(i, 1, contents);
            }
        }

        // 업무를 진행한 사람이 있는 경우
        else {
            // 해당 번호에 없는지 체크
            let no_result_count : number = 0;
            for(let contents of this.work_template_contents) {
                if( contents.status_info.length == 0 ) {
                    no_result_count++;
                }
            }

            // 백분율 계산
            for ( let i = 0; i < contents_count; i++ ) {
                const contents : any = JSON.parse(JSON.stringify(this.work_template_contents[i]));
                contents.horizontal_percent = contents.status_info.length == 0 ? 3 : (contents.status_info.length / total_count * (100 - ( 3 * no_result_count ))).toFixed(10);
                contents.vertical_percent   = contents.status_info.length == 0 ? 3 : (contents.status_info.length / this.work_status_list.length * 100).toFixed(10);
                this.work_template_contents.splice(i, 1, contents);
            }
        }

        // 백분율 계산
        // let no_persent : number = 0;
        // let total_persent : number = 0;

        // 퍼센트가 0인것들에 대해서 미리만든다..
        // for ( let i = 0; i < this.work_template_contents.length; i++ ) {
        //     if ( this.work_template_contents[i].status_info.length == 0 ) {
        //         this.work_template_contents[i].percent = 3;
        //         no_persent += 3;
        //     }
        // }

        // 단계에 한명이라도 있는경우
        // for ( let i = 0; i < this.work_template_contents.length; i++ ) {
        //     if ( this.work_template_contents[i].status_info.length > 0 ) {

        //         let data_persent = Number(((this.work_template_contents[i].status_info.length / this.total_work_user_count * (100 - no_persent))).toFixed(1));

        //         total_persent += data_persent;

        //         if ( (total_persent + no_persent) > 100 ) {
        //             this.work_template_contents[i].percent =  100 - (total_persent - data_persent + no_persent);
        //         }
        //         else {
        //             this.work_template_contents[i].percent = data_persent;
        //         }
        //     }
        // }
    }

    /**
     * 단계식 데이터만들기
     */
    async flowDisplay(lo_data) : Promise<void> {
        for ( let i = 0; i < lo_data.status_info.length; i++ ) {
            let vo       : any     = lo_data.status_info[i];
            let is_end   : boolean = vo.work_status_info.is_end;
            let progress : any     = vo.work_status_info.progress;

            let work_status_length = this.work_template_contents.length;

                for ( let j = work_status_length - 1; j >= 0; j-- ) {
                    // 업무가 종료 되었을때
                    if ( is_end ) {
                        this.work_start_user_count ++;
                        this.work_template_contents[j].status_info.push(vo);
                        break;
                    }
                    else {
                        // 업무시작
                        if ( progress[j].status == "START" ) {
                            this.work_start_user_count ++;
                            this.work_template_contents[j].status_info.push(vo);
                            break;
                        }
                    }

                    // 업무를 시작 안한 사람들을 배열에 넣어야함.
                    if ( j == 0 ) {
                        this.no_work_start_users.push(vo);
                    }    
                }
        }

        this.total_work_user_count = this.work_start_user_count + this.no_work_start_users.length;

        /*
        this.total_work_user_count = this.work_start_user_count + this.no_work_start_users.length;
        this.work_status_title = lo_data.event_data.title;
        this.replash_times = moment(new Date()).format('YYYY.MM.DD HH:mm:ss A');

        // 백분율 계산
        let no_persent = 0;

        // 퍼센트가 0인것들에 대해서 미리만든다..
        for ( let i = 0; i < this.work_template_contents.length; i++ ) {
            if ( this.work_template_contents[i].status_info.length == 0 ) {
                this.work_template_contents[i].percent = 3;
                no_persent += 3;
            }
        }

        // 단계에 한명이라도 있는경우
        for ( let i = 0; i < this.work_template_contents.length; i++ ) {
            if ( this.work_template_contents[i].status_info.length > 0 ) {
                this.work_template_contents[i].percent = ((this.work_template_contents[i].status_info.length / this.total_work_user_count * (100 - no_persent))).toFixed(2);
            }
        }
        */

        this.makePercent(lo_data.event_data.title, lo_data.event_data.color);
    }

    /**
     * 차트 보기 ON / OFF
     */
    chartOnOff() : void {
        const vue = this;
        this.see_chart = !this.see_chart;

        // 첫번쨰 리스트에, 애니메이션 용
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(0).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[0] != null) {
                $(".chartBg").find(".barUl li").eq(0).find(".txt").css('height', `${vue.work_template_contents[0].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[0] != null ) {
                $(".chartBg").find(".barUl li").eq(0).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(0).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[0].color));
            }
        }, 200);
        // 두번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(1).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[1] != null) {
                $(".chartBg").find(".barUl li").eq(1).find(".txt").css('height', `${vue.work_template_contents[1].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[1] != null ) {
                $(".chartBg").find(".barUl li").eq(1).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(1).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[1].color));
            }
        }, 205);
        // 세번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(2).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[2] != null) {
                $(".chartBg").find(".barUl li").eq(2).find(".txt").css('height', `${vue.work_template_contents[2].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[2] != null ) {
                $(".chartBg").find(".barUl li").eq(2).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(2).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[2].color));
            }
        }, 210);
        // 네번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(3).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[3] != null) {
                $(".chartBg").find(".barUl li").eq(3).find(".txt").css('height', `${vue.work_template_contents[3].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[3] != null ) {
                $(".chartBg").find(".barUl li").eq(3).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(3).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[3].color));
            }
        }, 215);
        // 다섯번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(4).find(".txt").toggleClass("ani");
            if(vue.see_chart == true && vue.work_template_contents[4] != null) {
                $(".chartBg").find(".barUl li").eq(4).find(".txt").css('height', `${vue.work_template_contents[4].vertical_percent}%`);
            } else if( vue.see_chart == false && vue.work_template_contents[4] != null ) {
                $(".chartBg").find(".barUl li").eq(4).find(".txt").removeAttr("style");
                $(".chartBg").find(".barUl li").eq(4).find(".txt").css("background-color", vue.hodu_hex_color_process(vue.work_template_contents[4].color));
            }
        }, 220);

        setTimeout(function(){
            $(".chartBg").find(".barUl li").find(".txt").removeClass("ani");
            $(".chartBg").find(".barUl li").find(".txt").toggleClass("transit2");
        }, 300);
        
    }

    /**
     * 리스트 펼치기 접기
     */
    listOnOff(index : number) : void {
        const contents : any = this.work_template_contents[index];

        if( this.work_template_contents[index].listOn == null ) {
            contents.listOn = true;
        } else {
            contents.listOn = !contents.listOn;
        }

        this.work_template_contents.splice(index, 1, contents);
    }

    /**
     * 모달 닫기
     */
    modalClose() : void {
        this.doSetShowWorkStatus(false);
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#statusScroll").mCustomScrollbar('destroy');

        this.setScroll();
    }

}
