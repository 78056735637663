
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';
import { t_work_template } from '@/model/osm';

import { namespace } from 'vuex-class';
const WorkInfo = namespace('WorkInfo');

import { ResizeObserver } from 'vue-resize'

@Component({
    components: {
        ResizeObserver
    },
})
export default class WorkSetting extends Mixins(VueHoduCommon) {
    
    /**
     * @WorkInfo.Action
     */
    @WorkInfo.Action doSetWorkTemplateId ?: any;

    work_template_list : t_work_template[] = [];
    
    mounted() : void {
        this.setScroll();
        this.getWorkTepmplate();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height      : number | undefined = $('.title_box').outerHeight();
        const setting_bg_height : number | undefined = $('.settingBg').outerHeight();
        const final_height      : number             = window.innerHeight - ( title_height ? title_height : 0 ) - ( setting_bg_height ? setting_bg_height : 0 );
        
        // @ts-ignore
        $('#work_template_list_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : final_height,
            scrollbarPosition : 'outside'
        })
    }

    /**
     * 업무 템플릿 조회
     */
    getWorkTepmplate() : void {
        if( this.getWorkPermission('read') == false ) {
            return;
        }

        const vue = this;
        
        this.hodu_api_call(`api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                vue.work_template_list.splice(0, vue.work_template_list.length);
                vue.work_template_list = vue.work_template_list.concat(response.data.data.template_list);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 업무 템플릿 삭제창 뜨게 만들기
     */
    workTemplateDeleteModal(index : number, template : any) : void {
        if( this.getWorkPermission('delete') == false ) {
            return;
        }

        template.deleteDivOn = true;
        this.work_template_list.splice(index, 1, template);
    }

    /**
     * 업무 템플릿 삭제 취소
     */
    workTemplateDeleteCancel(index : number, template : any) : void {
        template.deleteDivOn = false;
        this.work_template_list.splice(index, 1, template);
    }

    /**
     * 업무 템플릿 삭제
     */
    workTemplateDelete(index : number, template_id : string) : void {
        if( this.getWorkPermission('delete') == false ) {
            return;
        }

        const vue = this;
        
        this.hodu_api_call(`api/v1/calendars/${ this.calendar_id }/works/${ this.scope }/${ this.scope_team_id > 0 ? this.scope_team_id : this.scope_group_id }/template/${ template_id }`, API_METHOD.DELETE)
            .then((response) => {
                console.log(response);
                vue.work_template_list.splice(index, 1);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 업무 템플릿 추가
     */
    moveWorkTemplateAdd() : void {
        if( this.getWorkPermission('create') == false ) {
            return;
        }

        this.doSetWorkTemplateId("");
        this.hodu_router_push(`/work/${ new Date().getTime() }/setting/template`);
    }

    /**
     * 템플릿 수정
     */
    modifyWorkTemplate(template_id : string) : void {
        if( this.getWorkPermission('modify') == false ) {
            return;
        }

        this.doSetWorkTemplateId(template_id);
        this.hodu_router_push(`/work/${ new Date().getTime() }/setting/template/${ template_id }`);
    }

    /**
     * 업무 권한 구하기
     * 업무 작성 권한(create) - 템플릿 작성 권한
     * 업무 읽기 권한(read)   - 템플릿 리스트 조회 권한
     * 업무 수정 권한(modify) - 템플릿 수정 권한
     * 업무 삭제 권한(delete) - 템플릿 삭제 권한
     */
    getWorkPermission(crud_type : string) : boolean {
        
        // 그룹
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, 'work', crud_type);
        } 
        
        // 팀
        else if ( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, 'work', crud_type);
        } 

        // 그 외의 경우는 불가능
        else {
            return false;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#work_template_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }
}

