import { render, staticRenderFns } from "./GroupFileboxMoveModal.vue?vue&type=template&id=7566b572&scoped=true&"
import script from "./GroupFileboxMoveModal.vue?vue&type=script&lang=ts&"
export * from "./GroupFileboxMoveModal.vue?vue&type=script&lang=ts&"
import style0 from "./GroupFileboxMoveModal.vue?vue&type=style&index=0&id=7566b572&prod&lang=css&"
import style1 from "./GroupFileboxMoveModal.vue?vue&type=style&index=1&id=7566b572&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7566b572",
  null
  
)

export default component.exports