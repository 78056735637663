
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class OrganizationWorkTypeDetailModal extends Mixins(VueHoduCommon) {

    get computedWorkType() : any {
        if( this.work_type == null ) return null;

        let work_type = JSON.parse(JSON.stringify(this.work_type));

        if( work_type.employees == null ) work_type.employees = []; 

        work_type.employees.splice(0, work_type.employees.length);
        work_type.employees = work_type.employees.concat(this.employees.filter(employee => employee.work_type_id == work_type.work_type_id));
        work_type.employees.sort((o1, o2) : number => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            // 같은 직급은 이름순
            if( o1_pos_seq == o2_pos_seq ) {
                if( o1_name > o2_name ) return 1;
                else if( o1_name < o2_name ) return -1;
                return 0
            }
            else if( o1_pos_seq > o2_pos_seq ) return  1;
            else if( o1_pos_seq < o2_pos_seq ) return -1;

            return 0;
        });

        return work_type;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_work_type_detail_modal_info !: organization_modal_interface.OrganizationWorkTypeDetailModalInfo;
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationWorkTypeDetailModalInfo ?: (params : organization_modal_interface.OrganizationWorkTypeDetailModalInfo) => void;

    work_type : any = null;
    employees : any[] = [];
    departments : any[] = [];
    position : any[] = [];

    is_visible_beacon_auto : boolean = false;
    
    async mounted() : Promise<void> {
        // $("#organization_work_type_detail_modal .rightContent .schDiv .titleDiv .schBtn").click(function(){
        //     $("#organization_work_type_detail_modal .rightContent .searchDiv").addClass("on");

        // });

        // $("#organization_work_type_detail_modal .rightContent .searchDiv .closeBtn").click(function(){
        //     $("#organization_work_type_detail_modal .rightContent .searchDiv").removeClass("on");
        // });
        
        console.log(this.organization_work_type_detail_modal_info);
        await this.getTotalOrganizationData();
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganization();
            await this.getOrganizationPosition();
            await this.getOrganizationWorktype();
            await this.getOrganizationEmp();
        } catch(e) {
            alert("조직도 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 근무 타입 조회
     */
    async getOrganizationWorktype() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype?work_type_id=${this.organization_work_type_detail_modal_info.work_type_id}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_type_info ) {
                throw new Error("조직도 근무 타입 조회 중 오류 발생");
            }

            this.work_type = JSON.parse(JSON.stringify(response.data.data.work_type_info));
            
            this.is_visible_beacon_auto = this.work_type.contents.filter(content => content.is_beacon_use == true).length > 0;

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);
        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 이름 반환
     * level 1 : 최상위 부서 이름 반환
     * level 2 : 최상위 부서 바로 아래의 부서 이름 하나만 반환
     * level n (n > 2) : level 2 / level 3 / level 4 ... 
     */
    getDepartmentName(dept_id : number) : string {
        try {
            let dept_name = "";
            const target = this.departments.filter(item => item.dept_id == dept_id)[0];

            let target_departments : any[] = [];
            for( const department of this.departments ) {
                if( target.dept_id_array.indexOf(department.dept_id) > -1 ) target_departments.push(department);
            }

            target_departments.sort((o1, o2) : number => {
                const o1_length = o1.dept_id_array.length;
                const o2_length = o2.dept_id_array.length;

                if( o1_length > o2_length ) return 1;
                else if( o1_length < o2_length ) return -1;
                return 0;
            });

            for( const department of target_departments ) {
                if( target_departments.length >= 2 && target_departments.indexOf(department) == 0 ) continue;

                if( dept_name.length > 0 ) dept_name += " / ";
                dept_name += department.dept_name;
            }
        
            return dept_name;
        } catch(e) {
            console.log(e);
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_taget = $(event.target);
        const replace_image = require('@/assets/images/contents/ic_approval_user_on.png');
        jquery_taget.parent().find('p.img').css('background-image', `url(${replace_image})`);
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetOrganizationWorkTypeDetailModalInfo?.({ show_modal : false, work_type_id : 0 });
    }

    /**
     * 근무타입 삭제
     */
    async del() : Promise<void> {
        if( confirm("정말로 근무타입을 삭제하시겠습니까?") == false ) return;
        
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype/${this.organization_work_type_detail_modal_info.work_type_id}`, API_METHOD.DELETE);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error('근무타입 삭제 중 오류 발생');
            }

            this.organization_work_type_detail_modal_info.callback?.();
            this.close();
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("근무타입 삭제 중 오류 발생");
        }
        
    }

}
