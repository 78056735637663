
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, getBrowserInfo, getOSInfo } from '@/mixin/VueHoduCommon';

declare let Kakao : any;
import '@/assets/js/kakao'

import { hodu_local_storage } from '@/lib/HoduLocalStorage';
import { hodu_cookie } from '@/lib/HoduCookie';

@Component({
    components: {
        
    },
})
export default class DeleteData extends Mixins(VueHoduCommon) {

    step : number = 1;

    id : string = ""; // 아이디 변수 (:value + @input)
    pw : string = ""; // 패스워드 변수 (:value + @input)
    password_login_mode : string = "email";

    login_type : string = "";

    delete_type : string = "";

    async mounted() {
        // $('.login_tap ul li').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.login_accnt > ul').hide();
        // 	$('.login_accnt > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // })

        if( !Kakao.isInitialized() ){ await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }

        this.appleLoginEventInit();
    }

    /**
     * 비밀번호 로그인 모드 변경
     */
    changePasswordLoginMode(mode : string) : void {
        this.password_login_mode = mode;
        this.id = "";
        this.pw = "";
    }

    /**
     * 로그인
     */
    async login() {

        const vue = this;
        const loginSuccessCallback = vue.loginSuccessCallback;
        const loginFailCallback    = vue.loginFailCallback;
        
        if( this.id == null || this.id.trim() == ""){
            alert(`${ this.password_login_mode == 'email' ? '이메일을' : '휴대폰번호를' } 입력 해주세요`);
            return;
        }

        // 이메일로그인 모드 && 개발 아이디가 아니라면 이메일 형식 체크
        if( this.password_login_mode == 'email' && !( this.id.trim() == "3" || this.id.trim() == "4" || this.id.trim() == "5" || this.id.trim() == "6" || this.id.trim() == "7" ) ) {
            // 이메일 형식 체크
            if(! /\S+@\S+\.\S+/.test(this.id.trim()) ){
                alert('이메일 형식을 확인해주세요');
                return;
            }
        }    

        if( this.pw == null || this.pw.trim() == ""){
            alert('패스워드를 입력 해주세요');
            return;
        }

        this.login_type = this.password_login_mode == 'email' ? 'EMAIL' : 'PHONE';
        await this.hodu_api_call(`/api/v1/auth/login/${this.password_login_mode == 'email' ? 'EMAIL' : 'PHONE'}`, API_METHOD.POST, {
            sns_id        : this.id.trim(),
            password      : this.pw.trim(),
            app_ver       : this.getVersion(),
            device_os     : "WEB",
            device_os_ver : await getBrowserInfo(),
            device_model  : await getOSInfo(),
            device_token  : ""
        })
        .then((response) => loginSuccessCallback(response))
        .catch((e)=> loginFailCallback(e));

    }

    async kakaoLogin() {
        
        const access_token = Kakao.Auth.getAccessToken();

        /**
         * access_token 있다면
         */
        if( access_token ) {
            this.login_type = 'KAKAOTALK';
            await this.hodu_api_call(`/api/v1/auth/login/KAKAOTALK`, API_METHOD.POST, {
                app_ver                : this.getVersion(),
                device_os              : "WEB",
                device_os_ver          : await getBrowserInfo(),
                device_model           : await getOSInfo(),
                kakaotalk_access_token : access_token,
                device_token           : ""
            }) 
            .then((response) => this.loginSuccessCallback(response))
            .catch((e) => this.kakaoLoginForm()); // 실패시 LoginForm
        }
        else {
            this.kakaoLoginForm();
        }
    }

    async kakaoLoginForm() {

        const vue = this;

        await Kakao.Auth.loginForm({
            success: async(authObj) => {
                vue.login_type = 'KAKAOTALK';
                await vue.hodu_api_call(`/api/v1/auth/login/KAKAOTALK`, API_METHOD.POST, {
                    app_ver                : vue.getVersion(),
                    device_os              : "WEB",
                    device_os_ver          : await getBrowserInfo(),
                    device_model           : await getOSInfo(),
                    kakaotalk_access_token : authObj.access_token,
                    device_token           : ""
                }) 
                .then((response) => vue.loginSuccessCallback(response))
                .catch((e) => vue.loginFailCallback(e));
            },
            fail: async(e) => vue.loginFailCallback(e)
        });

    }

    async googleLogin() {
        window['gapi'].load('auth2', async() => {
            const auth2 = window['gapi'].auth2.init({
                client_id: '48808503160-k10pbn6thi0ko0easfuid8e4lq4afta9.apps.googleusercontent.com',
            });

            console.log(auth2);

            auth2.grantOfflineAccess({ scope: 'profile email', prompt: 'select_account' }).then(async(result) => {
                console.log(result);
                const access_code = result.code;

                try {
                
                    this.login_type = 'GOOGLE';
                    await this.hodu_api_call(`/api/v1/auth/login/GOOGLE`, API_METHOD.POST, {
                        sns_id        : "",
                        app_ver       : this.getVersion(),
                        device_os     : "WEB",
                        device_os_ver : await getBrowserInfo(),
                        device_model  : await getOSInfo(),
                        device_token  : "",
                        google_code   : access_code
                    }) 
                    .then((response) => this.loginSuccessCallback(response))
                    .catch((e) => this.loginFailCallback(e)); // 실패시 LoginForm

                } catch(e) {
                    alert("구글 계정 로그인 중 오류 발생");
                    this.hodu_error_process(e, false, false, true);
                }

            });
        });
    }

    async appleLogin() {
        $('#appleid-signin').trigger('click');
    }

    /**
     * 애플 계정 로그인 관련 이벤트
     */
    appleLoginEventInit() {
        
        // meta 태그 redirect-uri 변경
        document.getElementById('appleid-signin-redirect-uri')?.setAttribute('content', `${location.protocol}//${location.hostname}/api/v1/apple`);

        document.addEventListener('AppleIDSignInOnSuccess', async(data) => {

            console.log(data);

            if( !data || !data['detail'] || !data['detail']['authorization'] ) {
                alert("애플 계정 로그인 중 오류 발생");
                return;
            }

            const code = data['detail']['authorization']['code'];
            const id_token = data['detail']['authorization']['id_token'];
            const state = data['detail']['authorization']['state'];

            try {

                this.login_type = 'APPLE';
                const response = await this.hodu_api_call('api/v1/auth/login/APPLE', API_METHOD.POST, { 
                    app_ver                : this.getVersion(),
                    device_os              : "WEB",
                    device_os_ver          : await getBrowserInfo(),
                    device_model           : await getOSInfo(),
                    sns_id                 : id_token,
                    apple_access_token     : code,
                    device_token           : "" 
                });

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("애플 계정 로그인 중 오류 발생");
                }
                
                console.log(response);
                this.loginSuccessCallback(response);

            } catch(e) {
                this.loginFailCallback(e);
            }

        });

        document.addEventListener('AppleIDSignInOnFailure', (error) => {
            console.log(error);

            if( error && (error as any).detail && (error as any).detail.error ) {

                if( (error as any).detail.error == "popup_closed_by_user" || (error as any).detail.error == "user_cancelled_authorize" ) {
                    return;
                } 

            }

            alert("애플 계정 로그인 중 오류 발생");
        });
    }

    async loginSuccessCallback(response) {
        
        sessionStorage.setItem("session_token", response.data.data.session_token);
        hodu_local_storage.setItem("session_token", response.data.data.session_token);
        hodu_local_storage.setItem("kakao_login_flag", String(this.login_type == "KAKAOTALK"));
        hodu_cookie.set("session_token", response.data.data.session_token);

        this.doLogin({
            "isLogin" : false, // 로그인 이후 다른 페이지 이동 하면 안됨
            "user_id" : response.data.data.user.user_id,
            "user_type" : this.login_type,
            "user_email" : response.data.data.user.user_email,
            "user_name" : response.data.data.user.user_name,
            "user_phone_number" : response.data.data.user.user_phone_number,
            "country_code" : response.data.data.user.country_code,
            "is_temp_password" : response.data.data.is_temp_password,
            "user_preference" : response.data.data.user_preference,
            "user_group_role" : response.data.data.group_role,
            "user_team_role" : response.data.data.team_role,
            "template_map" : response.data.data.template_map,
            "auth_info" : response.data.data.user.auth_info,
        });

        this.step = 2;
    }

    async loginFailCallback(e) {
        this.hodu_error_process(e, false, false, true);
        alert("로그인 실패\n잠시 후 다시 시도해주세요");
    }
 
    /**
     * 아이디에서 엔터시 로그인시도
     */
    idKeyDown(event) {
        if( event.keyCode != 13 ) {
            return;
        }

        this.login();
    }
    
    /**
     * 비밀번호에서 엔터시 로그인시도
     */
    pwKeyDown(event) {
        if( event.keyCode != 13 ) {
            return;
        }

        this.login();
    }

    requestDeleteData() {

        if( (this.delete_type?.length ?? 0) < 1 ) {
            alert("삭제할 데이터를 선택해주세요");
            return;
        }

        if( this.delete_type == 'all_delete' ) {
            if( confirm("정말 계정 및 모든 데이터를 삭제 하시겠습니까?") == false ) return;
            this.allDelete();
            return;
        }

        if( this.delete_type == 'event_delete' ) {
            if( confirm("정말 모든 일정 데이터를 삭제 하시겠습니까?") == false ) return;
            this.eventDelete();
            return;
        }

        if( this.delete_type == 'group_team_delete' ) {
            if( confirm("정말 모든 그룹&팀 데이터를 삭제 하시겠습니까?") == false ) return;
            this.groupTeamDelete();
            return;
        }

    }

    async allDelete() {
        try {
            const response = await this.hodu_api_call(`api/v1/delete_data/all_delete`, API_METHOD.POST);

            if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("계정 삭제 실패");
            }

            this.step = 3;

        } catch(e) {
            alert("계정 삭제 중 오류 발생");
        }
    }

    async eventDelete() {

        try {
            const response = await this.hodu_api_call(`api/v1/delete_data/event_delete`, API_METHOD.POST);

            if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("일정 삭제 실패");
            }

            this.step = 3;

        } catch(e) {
            alert("일정 삭제 중 오류 발생");
        }

    }

    async groupTeamDelete() {

        try {
            const response = await this.hodu_api_call(`api/v1/delete_data/group_team_delete`, API_METHOD.POST);

            if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("그룹&팀 데이터 삭제 실패");
            }

            this.step = 3;

        } catch(e) {
            alert("그룹&팀 데이터 삭제 중 오류 발생");
        }

    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_target = $(event.target);
        const defaultImage = require('@/assets/images/contents/ic_approval_user.png');
        jquery_target.parent().find('p.facePic').css("background-image", `url(${defaultImage})`);
    }
    
}
