
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';

import { organization_modal_interface } from '@/model/organization';

import moment from 'moment';

@Component({
    components: {
        HoduInlineDatePicker
    },
})
export default class OrganizationOffAddModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_off_add_modal_info !: organization_modal_interface.OrganizationOffAddModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationOffAddModalInfo ?: (params : organization_modal_interface.OrganizationOffAddModalInfo) => void;

    event_bus : Vue = new Vue();

    start : Date = new Date();
    end : Date = new Date();
    is_start_select : boolean = true;

    start_text : string = "";
    end_text : string = "";

    off_reason : string = "";

    off : any = null;

    mounted() : void {
        this.makeDateText();

        if( this.organization_off_add_modal_info.is_create == false ) {
            this.getOrganizationDayOff();
        }
    }

    /**
     * 휴무일 조회
     */
    async getOrganizationDayOff() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off/${this.organization_off_add_modal_info.off_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.day_off ) {
                throw new Error("지정 휴무일 조회 중 오류 발생");
            }

            this.off = JSON.parse(JSON.stringify(response.data.data.day_off));

            this.off_reason = this.off.off_reason;
            this.start = this.yyyymmddToDate(this.off.start_date);
            this.end = this.yyyymmddToDate(this.off.end_date);

            this.changeDate(this.start);
            this.makeDateText();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("지정 휴무일 조회 중 오류 발생");
            this.close();
        }
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 날짜 텍스트 생성
     */
    makeDateText() : void {
        this.start_text = `${this.hodu_date_to_format_string(this.start, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.start, '요일')}`;
        this.end_text = `${this.hodu_date_to_format_string(this.end, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(this.end, '요일')}`;
    }

    /**
     * 시작일, 종료일 달력 세팅 변경
     */
    set(is_start_select : boolean) : void {
        this.is_start_select = is_start_select;
        this.changeDate(this.is_start_select == true ? this.start : this.end);
    }

    /**
     * datepicker 선택 된 날짜 변경
     */
    changeDate(target_date : Date) : void {
        this.event_bus.$emit('changeDate', target_date);
    }

     /**
     * 달력 선택 됨
     */
    select(date_string : string , data : any) : void {
        
        if( this.is_start_select == true ) {
            this.start = new Date(date_string);
                        
            if( this.start.getTime() > this.end.getTime() ) {
                this.end = moment(this.start).toDate();
            }
        }
        else {
            this.end = new Date(date_string);

            if( this.start.getTime() > this.end.getTime() ) {
                this.start = moment(this.end).toDate();
            }
        }

        this.makeDateText();
        
    }

    /**
     * 달력 년, 월 변경 됨
     */
    change(year: number, month: number, inst: any) : void {
        // DO NOTHING
    }
    
    /**
     * 닫기
     */
    close() : void {
        this.doSetOrganizationOffAddModalInfo?.({
            show_modal : false,
            is_create : true,
            off_id : 0
        });
    }

    /**
     * 삭제
     */
    async deleteOff() : Promise<void> {
        if( confirm("정말로 해당 휴무일을 삭제 하시겠습니까?") == false ) return;

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off/${this.off.off_id}`, API_METHOD.DELETE);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("휴무일 삭제 중 오류 발생");
            }
            
            this.organization_off_add_modal_info.callback?.();
            this.close();

        } catch(e) {
            this.hodu_show_dialog('cancel', '휴무일 삭제 중 오류 발생', ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 저장
     */
    async save() : Promise<void> {
        
        if( this.off_reason.trim().length < 1 ) {
            alert("휴무 사유를 입력해주세요");
            return;
        }
        
        const obj = {
            off_name : '휴무일', // 나중에 적는 칸이 생기면 변수로 사용
            off_reason : this.off_reason.trim(),
            start_date : this.hodu_date_to_format_string(this.start, 'YYYYMMDD'),
            end_date : this.hodu_date_to_format_string(this.end, 'YYYYMMDD'),
        }

        // 생성
        if( this.organization_off_add_modal_info.is_create == true ) {
            try {
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off`, API_METHOD.POST, obj);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴무일 등록 중 오류 발생");
                }

                this.organization_off_add_modal_info.callback?.();
                this.close();

            } catch(e) {
                alert("휴무일 등록 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        }

        // 수정
        else {
            try {

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/off/${this.organization_off_add_modal_info.off_id}`, API_METHOD.PUT, obj);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴무일 수정 중 오류 발생");
                }

                this.organization_off_add_modal_info.callback?.();
                this.close();

            } catch(e) {
                alert("휴무일 수정 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        }

    }

}
