import { render, staticRenderFns } from "./DoctorAndDepartmentList.vue?vue&type=template&id=efdd8634&scoped=true&"
import script from "./DoctorAndDepartmentList.vue?vue&type=script&lang=ts&"
export * from "./DoctorAndDepartmentList.vue?vue&type=script&lang=ts&"
import style0 from "./DoctorAndDepartmentList.vue?vue&type=style&index=0&id=efdd8634&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efdd8634",
  null
  
)

export default component.exports