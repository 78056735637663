
/**
 * 필수 import
 */
import { Component, Vue, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

/**
 * 컴포넌트 import
 */
import ExampleUUID from '@/components/example/ExampleUUID.vue';
import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';
import ExampleDepartments from '@/components/example/ExampleDepartments.vue';

/**
 * store import
 */
import { namespace } from 'vuex-class';
const EtcInfo = namespace('EtcInfo');

/**
 * 선택적 import
 */
import moment from 'moment';

/**
 * router.ts 내용
 * Example이라는 이름으로 Example.vue를 localhost:8080/#/example 에 연결
 * 중간에 #이 들어가는 이유는 router.ts에서 mode: "hash" 로 설정 되어 있기 때문
 * 
 * router.beforeEach 내부에 /example 경로를 로그인을 안해도 들어 갈 수 있도록 설정 되어 있다
 * 설정이 안 된 페이지는 로그인이 안 되어 있다면 /login 으로 강제 이동
 */
// {
//     path: "/example",
//     name: "Example",
//     component: () => import("./pages/Example.vue"),
// },

// router.beforeEach((to : Route, from : Route, next : any) => {

//     if( to.path == '/login' || to.path == '/exception' || to.path == '/find/password' || to.path == '/example' ) {
//         next();
//         return;
//     }

//     if( store.state.LoginInfo.isLogin == false ) {
//         next('/login');
//         return;
//     }

//     next();

// });

@Component({
    /**
     * 컴포넌트 추가시 해당 항목에 import 된 것을 추가 해야함
     */
    components : {
        ExampleUUID, HoduInlineDatePicker, ExampleDepartments
    },
})
export default class Example extends Mixins(VueHoduCommon) {

    /**
     * 스토어 데이터는 페이지 이동 시에도 유지되는 데이터로 
     * index.ts에 설정되어 있다 (네임스페이스들은 modules 아래에 정의)
     * 새로고침시 유지되어야 하는 데이터들은 index.ts에서 세션이 있을때 다시 세팅 해준다 
     */

    /**
     * @EtcInfo.State - state 중 일부 변수를 가져오는 부분
     */
    @EtcInfo.State example_string !: string;

    /**
     * @EtcInfo.Action - Action 중 일부 함수를 가져오는 부분, 주로 세팅으로 이용 
     */
    @EtcInfo.Action doSetExampleString ?: (parms : string) => void;

    /**
     * 변수 선언 
     * 해당 부분에서 선언하는 변수는 const, let 등은 정의 하지 않음
     * !는 Nullable (null, undefined 허용) => type | null | undefined
     * ?는 Optional (undefined 허용)       => type | undefined
     */
    current_date       : Date = new Date();
    nullable_string   !: string;  
    get_time_interval ?: number;

    device_uid : string = "";
    session_token : string = "";

    table_data : number[][] = [
        [1, 2, 3, 4, 5],
        [6, 7, 8, 9, 10],
        [11, 12, 13, 14, 15],
        [16, 17, 18, 19, 20],
        [21, 22, 23, 24, 25],
    ];

    repeat_i : number = 26;

    uuid_logs : string[] = [];

    uuid_prefix : string = "";

    show_layout : boolean = false;
    example_string_temp = "";

    min_date !: Date;
    max_date !: Date;

    departments : any = {
        id : 0,
        id_array : [0],
        name : "최상단",
        departments : [
            {
                id : 1,
                id_array : [0, 1],
                name : "0-1",
                departments : [
                    {
                        id : 4,
                        id_array : [0, 1, 4],
                        name : "0-1-4",
                        departments : []
                    },
                    {
                        id : 5,
                        id_array : [0, 1, 5],
                        name : "0-1-5",
                        departments : [
                            {
                                id : 8,
                                id_array : [0, 1, 5, 8],
                                name : "0-1-5-8",
                                departments : []
                            },
                            {
                                id : 9,
                                id_array : [0, 1, 5, 9],
                                name : "0-1-5-9",
                                departments : []
                            }
                        ]
                    }
                ]
            },
            {
                id : 2,
                id_array : [0, 2],
                name : "0-2",
                departments : [
                    {
                        id : 6,
                        id_array : [0, 2, 6],
                        name : "0-2-6",
                        departments : []
                    },
                    {
                        id : 7,
                        id_array : [0, 2, 7],
                        name : "0-2-7",
                        departments : []
                    }
                ]
            },
            {
                id : 3,
                id_array : [0, 3],
                name : "0-3",
                departments : []
            }
        ]
    }

    popup_close_interval : number | undefined;
    popup_variable : string = "";

    /**
     * Life Cycle - beforeCreate
     * 참고 : https://kr.vuejs.org/v2/guide/instance.html#%EB%9D%BC%EC%9D%B4%ED%94%84%EC%82%AC%EC%9D%B4%ED%81%B4-%EB%8B%A4%EC%9D%B4%EC%96%B4%EA%B7%B8%EB%9E%A8
     */
    beforeCreate() : void {
        this.min_date = new Date();
        this.max_date = new Date(this.min_date);
        this.max_date.setMonth(this.max_date.getMonth() + 12);
    }

    /**
     * Life Cycle - created
     * 참고 : https://kr.vuejs.org/v2/guide/instance.html#%EB%9D%BC%EC%9D%B4%ED%94%84%EC%82%AC%EC%9D%B4%ED%81%B4-%EB%8B%A4%EC%9D%B4%EC%96%B4%EA%B7%B8%EB%9E%A8
     */
    created() : void {

    }

    /**
     * Life Cycle - beforeMount
     * 참고 : https://kr.vuejs.org/v2/guide/instance.html#%EB%9D%BC%EC%9D%B4%ED%94%84%EC%82%AC%EC%9D%B4%ED%81%B4-%EB%8B%A4%EC%9D%B4%EC%96%B4%EA%B7%B8%EB%9E%A8
     */
    beforeMount() : void {
        
    }

    /**
     * Life Cycle - mounted
     * 참고 : https://kr.vuejs.org/v2/guide/instance.html#%EB%9D%BC%EC%9D%B4%ED%94%84%EC%82%AC%EC%9D%B4%ED%81%B4-%EB%8B%A4%EC%9D%B4%EC%96%B4%EA%B7%B8%EB%9E%A8
     */
    mounted() : void {
        
        // 1초마다 현재 시간 갱신 반복
        this.get_time_interval = window.setInterval(async() => { this.current_date = new Date(); }, 1000);

        /**
         * temp에 example_string 저장
         */
        this.example_string_temp = this.example_string;

    }

    /**
     * Life Cycle - beforeUpdate
     * 참고 : https://kr.vuejs.org/v2/guide/instance.html#%EB%9D%BC%EC%9D%B4%ED%94%84%EC%82%AC%EC%9D%B4%ED%81%B4-%EB%8B%A4%EC%9D%B4%EC%96%B4%EA%B7%B8%EB%9E%A8
     */
    beforeUpdate() {
        
    }

    /**
     * Life Cycle - updated
     * 참고 : https://kr.vuejs.org/v2/guide/instance.html#%EB%9D%BC%EC%9D%B4%ED%94%84%EC%82%AC%EC%9D%B4%ED%81%B4-%EB%8B%A4%EC%9D%B4%EC%96%B4%EA%B7%B8%EB%9E%A8
     */
    updated() : void {
        
    }

    /**
     * Life Cycle - beforeDestroy
     * 참고 : https://kr.vuejs.org/v2/guide/instance.html#%EB%9D%BC%EC%9D%B4%ED%94%84%EC%82%AC%EC%9D%B4%ED%81%B4-%EB%8B%A4%EC%9D%B4%EC%96%B4%EA%B7%B8%EB%9E%A8
     */
    beforeDestroy() : void {
        
        // 반복 제거
        if( this.get_time_interval ) { clearInterval(this.get_time_interval); }
        if( this.popup_close_interval ) { clearInterval(this.popup_close_interval); }
    }

    /**
     * Life Cycle - destroyed
     * 참고 : https://kr.vuejs.org/v2/guide/instance.html#%EB%9D%BC%EC%9D%B4%ED%94%84%EC%82%AC%EC%9D%B4%ED%81%B4-%EB%8B%A4%EC%9D%B4%EC%96%B4%EA%B7%B8%EB%9E%A8
     */
    destroyed() : void {
        
    }

    /**
     * 함수 - 현재 시간 month 반환
     */
    getMonth() : number {
        if( !this.current_date ) { return 0; }
        return this.current_date.getMonth();
    }

    /**
     * 현재시간 alert
     */
    alertCurrentTime() : void {
        alert(`${moment(this.current_date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS")}Z`);
    }

    /**
     * 유저 정보 가져오기
     */
    getUserInfomation() : void {
        /**
         * 함수 부분에서 변수에 접근하는 방법 this.var_name
         */
        sessionStorage.setItem("device_uid", this.device_uid);
        sessionStorage.setItem("session_token", this.session_token);

        /**
         * API 호출
         * @param  {string}       url           - url
         * @param  {any}          method        - get, post, put, delete 등등
         * @param  {any}          body?         - body 데이터
         * @param  {boolean=true} showIndicator - 인디케이터 발생 여부
         */
        // async hodu_api_call(url : string, method : any, body ?: any, showIndicator: boolean = true) : Promise<any> {
        this.hodu_api_call('api/v1/user/me', API_METHOD.GET)
            .then((response) => {
                // 완료 부분
                alert(JSON.stringify(response));
            })
            .catch((e) => {
                /**
                 * 웹 try - catch 또는 API 통신 에러 처리
                 * @param  {any}           e                    - 에러
                 * @param  {boolean=true}  show_modal           - Modal로 에러 보여 줄 것 인지 여부        (false인 경우 alert) 
                 * @param  {boolean=false} is_back_page         - 에러시 이전 페이지로 이동 할 것 인지 여부 (false인 경우 유지)
                 * @param  {boolean=false} do_not_show_anything - 아무것도 보여주지 않을 것 인지 여부       (false인 경우 보여줌)
                 */
                this.hodu_error_process(e, true, false, false);
            })
    }

    /**
     * 반복 테이블 데이터 row 추가
     */
    addRow() : void {
        const row : number[] = [];
        for( let i = this.repeat_i; i < this.repeat_i + 5; i++ ) {
            row.push(i);
        }
        this.repeat_i += 5;

        this.table_data.push(row);
    }

    /**
     * 반복 테이블 데이터 마지막 row 제거
     */
    removeRow() : void {
        this.repeat_i -= 5;

        // remove로 제거하지 않고 splice로 제거한다 ()
        this.table_data.pop();
    }

    /**
     * UUID Prefix 체크
     */
    checkPrefix(event : any) : void {
        if( event.target.value.length > 8 ) { return; }

        // 숫자 + 영어 소문자가 아니라면  
        if( new RegExp(/[^A-Za-z0-9]/).test(event.target.value) ) {
            event.target.value = this.uuid_prefix;
            return;
        }

        this.uuid_prefix = event.target.value;
    } 

    /**
     * emit function
     */
    createLog(uuid : string) : void {
        this.uuid_logs.push(uuid);
    }

    /**
     * example string 저장
     */
    saveExampleString() : void {
        if( this.doSetExampleString ) { this.doSetExampleString(this.example_string_temp); } 
    }

    /**
     * datepicker 선택 emit 함수
     */
    datepickerSelectFunction(date_string : string , data : any) : void {
        alert(date_string);
    }   
    
    /**
     * datepicker 년, 월 변경 emit 함수
     */
    datepickerChangeFunction(year: number, month: number, inst: any) : void {
        alert(`${`0${year}`.slice(-2)}년 ${`0${month}`.slice(-2)}월`);
    }

    async excel() {
        
        try {

            const response = await this.$http({
                url : "api/v1/excel",
                method : 'POST',
                responseType: 'blob',
                headers : {
                    "Cache-Control" : "no_cache"
                },
                data : {
                    "data" : [
                        [],
                        [
                            {

                            },
                            { 
                                "value" : "A1",
                                "border" : {
                                    "top" : { "style" : "thin" },
                                    "left" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFDEEBF7" }, "bgColor" : { "argb" : "FFDEEBF7" } }
                            },
                            { 
                                "value" : "A2",
                                "border" : {
                                    "top" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFFCE5D6" }, "bgColor" : { "argb" : "FFFCE5D6" } }
                            },
                            { 
                                "value" : "A3",
                                "border" : {
                                    "top" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFDEEBF7" }, "bgColor" : { "argb" : "FFDEEBF7" } } 
                            },
                            { 
                                "value" : "A4",
                                "border" : {
                                    "top" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFFCE5D6" }, "bgColor" : { "argb" : "FFFCE5D6" } }
                            },
                            { 
                                "value" : "A5",
                                "border" : {
                                    "top" : { "style" : "thin" },
                                    "right" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFDEEBF7" }, "bgColor" : { "argb" : "FFDEEBF7" } } 
                            }
                        ],
                        [
                            {

                            },
                            { 
                                "value" : "B1",
                                "border" : {
                                    "bottom" : { "style" : "thin" },
                                    "left" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFDEEBF7" }, "bgColor" : { "argb" : "FFDEEBF7" } } 
                            },
                            { 
                                "value" : "B2",
                                "border" : {
                                    "bottom" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFFCE5D6" }, "bgColor" : { "argb" : "FFFCE5D6" } }
                            },
                            { 
                                "value" : "B3",
                                "border" : {
                                    "bottom" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFDEEBF7" }, "bgColor" : { "argb" : "FFDEEBF7" } }
                            },
                            { 
                                "value" : "B4",
                                "border" : {
                                    "bottom" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFFCE5D6" }, "bgColor" : { "argb" : "FFFCE5D6" } }
                            },
                            { 
                                "value" : "B5",
                                "border" : {
                                    "bottom" : { "style" : "thin" },
                                    "right" : { "style" : "thin" }
                                },
                                "fill" : { "type" : "pattern", "pattern" : "solid", "fgColor" : { "argb" : "FFDEEBF7" }, "bgColor" : { "argb" : "FFDEEBF7" } }
                            }
                        ]
                    ],
                    "name" : "hodu.xlsx"
                }
            });

            console.log(response);

            const blob = new Blob([response.data]);

            // @ts-ignore
            if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, "hodu.xlsx"); // for IE
                return;
            }

            await this.hodu_download_blob(blob, "hodu.xlsx");

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "엑셀 다운로드 중 오류 발생", ['확인']);
        }

    }

    /**
     * 팝업
     */
    popup() : void {
        // window 변수 지정
        window['popup_aaa'] = 'popup_aaa';
        window['popup_func'] = (popup_variable) => {
            console.log("popup_func activated");

            if( popup_variable != null && popup_variable.length > 0 ) this.popup_variable = popup_variable;
            console.log(this.popup_variable); 
        };      

        let popup = window.open('/popup_example.html', 'example', "toolbar=0, scrollbars=0, resizable=0, width=1024, height=768, left=0, top=0");

        console.log(popup);

        if ( popup == null ) return;

        this.popup_close_interval = window.setInterval(() => {

            if( popup?.closed ) {
                clearInterval(this.popup_close_interval);
                console.log("popup closed");
            }

        }, 500);
    }

    /**
     * Watch는 해당 변수의 데이터가 달라진 것을 감지해서 함수를 실행 시킨다 
     */
    @Watch('current_date')
    watchDate() : void {
        console.log(this.current_date);
    }

}
