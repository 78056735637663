
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import moment from 'moment';
import { ResizeObserver } from 'vue-resize';

import { namespace } from 'vuex-class';
const WorkInfo = namespace('WorkInfo');
const ModalInfo = namespace('ModalInfo');

import { WorkStatusInfo } from '@/store/modules/WorkInfo';

@Component({
    components: {
        ResizeObserver
    }
}) export default class WorkDefaultStatus extends Mixins(VueHoduCommon) {
    
    /**
     * @WorkInfo.State
     */
    @WorkInfo.State work_status_info !: WorkStatusInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowWorkDefaultStatus ?: any;

    /**
     * 변수 선언
     */
    work_data     : any     = null;  // 업무 데이터
    replash_times : string  = "";    // 최종적으로 업무 데이터가 로드된 시점의 시간 텍스트 (ex : 2019.12.20 11:22:54 AM) 
    see_chart     : boolean = false; // 차트 자세히 보기

    start_list_on : boolean = false;
    end_list_on   : boolean = false;

    get getWaitWorker() : any[] {
        if( !this.work_data ) {
            return [];
        }

        return this.work_data.status_info.filter(status => status.work_status_info.progress[0].status == 'WAIT');
    }

    get getStartWorker() : any[] {
        if( !this.work_data ) {
            return [];
        }

        return this.work_data.status_info.filter(status => status.work_status_info.progress[0].status == 'START');
    }
    
    get getEndWorker() : any[] {
        if( !this.work_data ) {
            return [];
        }

        return this.work_data.status_info.filter(status => status.work_status_info.progress[0].status == 'END');
    }

    mounted() {
        // 기본업무 조회
        this.getBasicStatus();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height    : number | undefined = $('.left_titleBox').outerHeight();
        const title_bg_height : number | undefined = $('.titleBg').outerHeight();
        const final_height    : number = window.innerHeight - (title_height ? title_height : 0) - (title_bg_height ? title_bg_height : 0);

        // @ts-ignore
        $("#statusScroll").mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : final_height
        });
    }

    /**
     * 기본업무 조회
     */
    async getBasicStatus() : Promise<void> {
        
        await this.hodu_api_call(`api/v1/calendars/${this.work_status_info.calendar_id}/works/${this.work_status_info.scope}/${this.work_status_info.scope_id}/status/${this.work_status_info.event_id}`, API_METHOD.GET)
			.then(async(response) => {
                console.log(response);
                this.work_data = response.data.data.list[0];
                this.replash_times = this.amPmStringToLocaleAmPmString(moment(new Date()).format('YYYY.MM.DD hh:mm:ss A'));
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
                this.doSetShowWorkDefaultStatus(false);
            })
    }

    /**
     * 차트 보기 ON / OFF
     */
    chartOnOff() : void {
        const vue = this;
        this.see_chart = !this.see_chart;

        // 첫번쨰 리스트에, 애니메이션 용
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(0).find(".txt").toggleClass("ani");
            if( vue.see_chart == true ) {
                $(".chartBg").find(".barUl li").eq(0).find(".txt").css('height', vue.getStartProgress(true));
            } else {
                $(".chartBg").find(".barUl li").eq(0).find(".txt").removeAttr("style");
            }
        }, 200);

        // 두번째 리스트에
        setTimeout(function(){
            $(".chartBg").find(".barUl li").eq(1).find(".txt").toggleClass("ani");
            if( vue.see_chart == true ) {
                $(".chartBg").find(".barUl li").eq(1).find(".txt").css('height', vue.getEndProgress(true));
            } else {
                $(".chartBg").find(".barUl li").eq(1).find(".txt").removeAttr("style");
            }
        }, 205);
        
        setTimeout(function(){
            $(".chartBg").find(".barUl li").find(".txt").removeClass("ani");
            $(".chartBg").find(".barUl li").find(".txt").toggleClass("transit2");
        }, 300);
        
    }

    /**
     * 업무를 시작 또는 완료한 사람 수 반환
     */
    getStartOrEndWorkStatusCount() : number {
        if( !this.work_data ) {
            return 0;
        }

        let start_or_end_count : number = 0;

        for( let status of this.work_data.status_info ) {
            if( status.work_status_info.progress[0].status != 'WAIT' ) {
                start_or_end_count++;
            }
        }

        return start_or_end_count;
    }

    /**
     * 업무 진행중인 사람 수 반환
     */
    getStartWorkStatusCount() : number {
        if( !this.work_data ) {
            return 0;
        }

        let start_count : number = 0;

        for( let status of this.work_data.status_info ) {
            if( status.work_status_info.progress[0].status == 'START' ) {
                start_count++;
            }
        }

        return start_count;
    }

    /**
     * 업무 완료한 사람 수 반환
     */
    getEndWorkStatusCount() : number {
        if( !this.work_data ) {
            return 0;
        }

        let end_count : number = 0;

        for( let status of this.work_data.status_info ) {
            if( status.work_status_info.progress[0].status == 'END' && status.work_status_info.is_end == true ) {
                end_count++;
            }
        }

        return end_count;
    }
    
    /**
     * 업무 진행중 progress 구하기
     */
    getStartProgress(is_vertical : boolean = false) : string {
        if( this.getStartOrEndWorkStatusCount() == 0 ) {
            return '50%';
        }

        // 세로 막대
        if( is_vertical == true ) {
            if( this.work_data == null || this.work_data.status_info.length < 1 ) {
                return '0%';
            }

            return `${ this.getStartWorkStatusCount() == 0 ? '3' : this.getStartWorkStatusCount() / this.work_data.status_info.length * 100 }%`;
        }

        // 완료가 0명인경우 3% 기본값 빼준다
        if( this.getEndWorkStatusCount() == 0 ) {
            return `${ ((this.getStartWorkStatusCount() / this.getStartOrEndWorkStatusCount() * 100) - 3).toFixed(10) }%`;
        }

        return `${ this.getStartWorkStatusCount() == 0 ? '3' : (this.getStartWorkStatusCount() / this.getStartOrEndWorkStatusCount() * 100).toFixed(10) }%`;
    }

    /**
     * 업무 완료 progress 구하기
     */
    getEndProgress(is_vertical : boolean = false) : string {
        if( this.getStartOrEndWorkStatusCount() == 0 ) {
            return '50%';
        }

        // 세로 막대
        if( is_vertical == true ) {
            if( this.work_data == null || this.work_data.status_info.length < 1 ) {
                return '0%';
            }

            return `${ this.getEndWorkStatusCount() == 0 ? '3' : this.getEndWorkStatusCount() / this.work_data.status_info.length * 100 }%`;
        }

        // 시작이 0명인경우 3% 기본값 빼준다
        if( this.getStartWorkStatusCount() == 0 ) {
            return `${ ((this.getEndWorkStatusCount() / this.getStartOrEndWorkStatusCount() * 100) - 3).toFixed(10) }%`;
        }

        return `${ this.getEndWorkStatusCount() == 0 ? '3' : (this.getEndWorkStatusCount() / this.getStartOrEndWorkStatusCount() * 100).toFixed(10) }%`;
    }

    /**
     * 진행중 리스트 접기 / 펼치기
     */
    startListOnOff() : void {
        this.start_list_on = !this.start_list_on;
    }

    /**
     * 완룐 리스트 접기 / 펼치기
     */
    endListOnOff() : void {
        this.end_list_on = !this.end_list_on;
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require("@/assets/images/contents/im_photoB.gif");
    }

    /**
     * 모달 닫기
     */
    modalClose() : void {
        this.doSetShowWorkDefaultStatus(false);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#statusScroll").mCustomScrollbar('destroy');

        this.setScroll();
    }

    
}

