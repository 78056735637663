/**
 * 웹소켓 클래스
 * create by LSJ 
 * 2020.08.03
 */
export class HoduWebSocket {

    private websocket : WebSocket | null = null;

    /**
     * 웹소켓 연결
     * @param url - 웹소켓 URL
     */
    connect(url : string) : void {
        try {
            this.websocket = new WebSocket(url);
        } catch(e) {
            throw e;
        }
    }

    /**
     * 웹소켓 닫기
     */
    close(code ?: number, reason ?: string) : void {
        try {
            this.websocket?.close(code, reason);
        } catch(e) {
            throw e;
        }
    }
    
    /**
     * 데이터 보내기
     * @param data - 데이터 (JSON 문자열)
     */
    send(data : string) : void {
        try {
            this.websocket?.send(data);
        } catch(e) {
            throw e;
        }
    }

    /**
     * 웹소켓이 열렸을때 수행할 콜백 함수 등록
     * @param callback - 콜백 함수
     */
    onopen(callback : any) : void {
        if( this.websocket == null ) { return; }
        this.websocket.onopen = callback;
    }

    /**
     * 메시지를 받았을때 수행할 함수 등록
     * @param callback - 콜백 함수
     */
    onmessage(callback : any) : void {
        if( this.websocket == null ) { return; }
        this.websocket.onmessage = callback;
    }

    /**
     * 웹소켓이 닫힐때 수행할 콜백 함수 등록
     * @param callback - 콜백 함수
     */
    onclose(callback : any) : void {
        if( this.websocket == null ) { return; }
        this.websocket.onclose = callback;
    }

    /**
     * 웹소켓 에러 발생시 수행할 콜백 함수 등록
     * @param callback - 콜백 함수
     */
    onerror(callback : any) : void {
        if( this.websocket == null ) { return; }
        this.websocket.onerror = callback;
    }

    /**
     * 웹소켓 반환
     */
    getWebSocket() : WebSocket | null {
        return this.websocket;
    }

}

export const hodu_web_socket : HoduWebSocket = new HoduWebSocket();