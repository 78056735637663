
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

import { hodu_color } from '@/common/color';

import { chat_interface, chat_enum, chat_modal_interface } from '@/model/chat';
import { t_event_file } from '@/model/event';

import moment from 'moment';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ChattingLeft extends Mixins(VueHoduCommon) {

    @Prop() event_bus         !: Vue;
    @Prop() channels          !: chat_interface.I_CHANNEL_INFO[];
    @Prop() connected_channel !: chat_interface.I_CHANNEL_INFO | null;
    @Prop() chat_list         !: chat_interface.I_CHAT_MESSAGE[];
    
    /**
     * 검색된 채널 리스트
     */
    get computedChannelList() : chat_interface.I_CHANNEL_INFO[] {
        return this.channels.filter(channel => (channel.info != null && channel.info.title != null && this.hodu_string_includes(channel.info.title, this.channel_search_query)) || 
                                               (channel.users != null && channel.users.filter(user => this.hodu_string_includes(user.user_name, this.channel_search_query)).length > 0));
    }

    /**
     * 일별 채팅 리스트
     */
    get computedDayChattingList() : chat_interface.I_CHAT_DAY_MESSAGE[] {

        let list : chat_interface.I_CHAT_DAY_MESSAGE[] = [];

        for( const chat of this.chat_list ) {
            const target_date : number = moment(chat.send_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate().getTime();
            const target_list = list.filter(item => item.date == target_date);
            
            if( chat.data != null && chat.data.msg != null ) {
                chat.data.msg = chat.data.msg.replace(/(<([^>]+)>)/ig, "");
            } 

            if( target_list.length < 1 ) {
                list.push({
                    date : target_date,
                    chat : [chat]
                });
                continue;
            }

            target_list[0].chat.push(chat);
            target_list[0].chat.sort((o1, o2) : number => {
                
                if( !o1.send_date || !o2.send_date ) { return 0; }

                if( o1.send_date < o2.send_date ) { return -1; }

                if( o1.send_date > o2.send_date ) { return 1; }

                return 0;
            })
        }

        list.sort((o1, o2) : number => {

            if( o1.date < o2.date ) { return -1; }

            if( o1.date > o2.date ) { return 1; }

            return 0;
        })

        console.log("ChattingLeft:computedDayChattingList");
        
        return list;
    }

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventImagePreview              ?: any;
    @ModalInfo.Action doSetChattingRoomInfoModalInfo          ?: (params : chat_modal_interface.ChattingRoomInfoModalInfo) => void;
    
    SCROLL_COUNT : number = 50;

    chatting_message : string = "";
    channel_search_query : string = "";
    
    is_search_div_open : boolean = false;
    is_first_scroll_event_end : boolean = false;
    is_page_end : boolean = false;
    is_extra_panel_open : boolean = false;
    is_chatting_room_background_change_open : boolean = false;
    isShift : boolean = false; // 쉬프트가 눌렸는지 여부
    isCtrl : boolean = false; // 컨트롤키 눌렸는지 여부

    clipboard_selection_start : number = 0;
    clipboard_selection_end : number = 0;

    mounted() : void {
        this.event_bus?.$on('handleLeftResize', this.handleResize);
        this.event_bus?.$on('scrollBottom', () => { 
            this.$nextTick(() => {
                const scroll_amount : number | undefined = $('.chatroom .scroll .chatroomGrp').outerHeight();
                const scroll_amount_number : number = scroll_amount ? scroll_amount : 0;
                if( scroll_amount_number < 1 ) return;
                $('.chatroom .scroll').scrollTop(scroll_amount_number);
            })
        });
        this.event_bus?.$on('setPageEnd', (is_end) => this.is_page_end = is_end);
        this.event_bus?.$on('scrollInit', this.setChattingRoomScroll);

        this.setScroll();

        // 스크롤 이벤트
        $('#chatroom_scroll').on("scroll", this.onChatRoomScroll);

        this.clipboardEventAdd();
    }

    /**
     * 클립보드 처리 
     */
    async clipboardEventAdd() : Promise<void> {
        const target = document.querySelector('textarea#chatting_textarea');

        target?.addEventListener('paste', (event) => {
            console.log(event);

            this.clipboard_selection_start = (target as HTMLTextAreaElement).selectionStart;
            this.clipboard_selection_end = (target as HTMLTextAreaElement).selectionEnd;

            if( !(event instanceof ClipboardEvent) ) {
                return;
            }

            const clipboardData = event.clipboardData;

            if( clipboardData == null ) {
                return;
            }

            const items = clipboardData.items;
            const files = clipboardData.files;

            const text = clipboardData.getData('Text');

            if( text != null && text.length > 0 ) {
                return;
            }

            // 클립보드 아이템
            if( items != null && items.length > 0 ) {

                event.preventDefault();

                const dialog_button_text : string[] = ['취소'];
                const dialog_button_callback : Function[] = [() => { 
                    (target as HTMLElement).focus(); 
                    (target as HTMLTextAreaElement).selectionStart = this.clipboard_selection_start;
                    (target as HTMLTextAreaElement).selectionEnd = this.clipboard_selection_end;
                }];

                for(const item of items) {

                    let target_item : string | File = "";
                    if( item.type.includes('text/plain') ) {
                        item.getAsString((data) => {
                            target_item = data;
                        });
                    }

                    if( item.type.includes('image') ) {
                        const file = item.getAsFile();
                        if( file ) target_item = file;
                    }

                    if( item.type.includes('text/plain') || item.type.includes('image') ) {
                        dialog_button_text.push(item.type.includes('text/plain') ? '텍스트' : '이미지');
                        dialog_button_callback.push(() => {
                            console.log(target_item);
                            this.clipboardProcess(target, target_item);
                        });
                    }

                }

                if( dialog_button_text.length == 1 ) return;
                if( dialog_button_text.length == 2 ) {

                    const item = items[0];

                    if( item.type.includes('text/plain') ) {
                        item.getAsString((data) => {
                            this.clipboardProcess(target, data);
                        });
                    }

                    if( item.type.includes('image') ) {
                        const file = item.getAsFile();
                        if( file ) this.clipboardProcess(target, file);
                    }

                    return;
                }
                    
                this.hodu_show_dialog("alert", "복사 유형을 선택해주세요", dialog_button_text, dialog_button_callback);
                return;
            }

            // 파일인 경우
            else if( files != null && files.length > 0 ) {

                const file : File | null = files.item(0);

                console.log(file);

                if( file == null ) return;

                this.addFileEvent([file]);
            }

        });
    }

    /**
     * 복사 유형 파악후 붙여넣기
     */
    clipboardProcess(target : Element, target_item : string | File) : void {
        console.log(target_item);

        (target as HTMLElement).focus();

        if( target_item instanceof File ) {
            this.addFileEvent([target_item]);
        }

        if( 'string' == typeof target_item ) {

            const original_value = (target as HTMLTextAreaElement).value;
            let value = "";
            
            const start_value = original_value.substring(0, this.clipboard_selection_start);
            const end_value = original_value.substring(this.clipboard_selection_end);

            console.log(start_value);
            console.log(end_value);

            value = start_value + (target_item as string) + end_value;
            (target as HTMLTextAreaElement).value = value;
            (target as HTMLTextAreaElement).selectionStart = this.clipboard_selection_start + (target_item as string).length;
            (target as HTMLTextAreaElement).selectionEnd = this.clipboard_selection_start + (target_item as string).length;
            this.chatting_message = value;
            this.isCtrl = false;
            this.isShift = false;
        }
    }

    /**
     * 스크롤 설정
     */
    @Watch('connected_channel')
    setScroll() : void {
        
        // if( this.connected_channel != null ) { return; }

        try {
            const main_title_height : number | undefined = $('.section_ce_fix .main-title').outerHeight();
            const sub_title_height : number | undefined = $('.section_ce_fix .useWhenClosed .sub-title').outerHeight();

            // @ts-ignore
            $('.chatroomDiv.scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 120,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - ( main_title_height == null ? 0 : main_title_height ) - ( sub_title_height == null ? 0 : sub_title_height )
            });

            console.log("ChattingLeft:setScroll");

            this.$nextTick(() => $('#chatting_textarea').focus());

        } catch(e) {
            alert(e);
        }
    }

    /**
     * 채팅방 스크롤 설정
     */
    @Watch('connected_channel')
    setChattingRoomScroll(watch_obj, is_resize : boolean = false) : void {

        if( this.connected_channel == null ) {
            this.is_first_scroll_event_end = false;
            this.is_page_end = false;
            return;
        }

        this.$nextTick(() => {

            const main_title_height : number | undefined = $('.section_ce_fix .main-title').outerHeight();
            const sub_title_height : number | undefined = $('.section_ce_fix .useWhenClosed .sub-title').outerHeight();
            const main_panel_height : number | undefined = $('.section_ce_fix .chatroom .chatroomPanel .mainPanel').outerHeight();

            $('.chatroom .scroll').height(window.innerHeight - ( main_title_height == null ? 0 : main_title_height ) 
                                                             - ( sub_title_height == null ? 0 : sub_title_height )
                                                             - ( main_panel_height == null ? 0 : main_panel_height ));

            
            // 리사이즈가 아니라면
            if( !is_resize ) {
                const scroll_amount : number | undefined = $('.chatroom .scroll .chatroomGrp').outerHeight();
                const scroll_amount_number : number = scroll_amount ? scroll_amount : 0;
                
                if( scroll_amount_number < 1 ) {
                    return;
                }
                
                $('.chatroom .scroll').scrollTop(scroll_amount_number);
                this.is_first_scroll_event_end = true;
                console.log("ChattingLeft:setChattingRoomScroll");
            }

        });
    }

    /**
     * 스크롤
     */
    @Throttle(100)
    onChatRoomScroll(event) : void {
        if( this.is_first_scroll_event_end == false || this.is_page_end == true || this.chat_list.length < 1 ) { return; }

        // console.log(`===============================================`);
        // console.log('onChatRoomScroll');
        // console.log($(event.target).scrollTop());
        // console.log(`===============================================`);

        const scroll_top : number | undefined = $(event.target).scrollTop();

        if( scroll_top == null || scroll_top > window.innerHeight ) { return; }

        // console.log("SCROLL ACTIVATED");
        
        const first_idx = this.chat_list[this.chat_list.length - 1].idx
        if( first_idx == null || first_idx < 2 ) {
            this.is_page_end = true;
        }

        this.send(JSON.stringify({
            type : 'CHANNEL',
            sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG_LIST,
            data : {
                offset : this.chat_list[this.chat_list.length - 1].idx,
                size : this.SCROLL_COUNT
            }
        }));
    }

    /**
     * 채널 나가면서 리셋해야할 내용 리셋
     */
    @Watch('connected_channel')
    reset() : void {
        if( this.connected_channel != null ) { return; }
        this.chatting_message = "";
        this.is_extra_panel_open = false;
        this.is_chatting_room_background_change_open = false; 
        // this.is_search_div_open = false;
        // this.channel_search_query = "";
    }

    /**
     * send
     */
    send(data : string) : void {
        this.$emit('send', data);
    }

    /**
     * 컬러에 따른 클래스 반환
     */
    getChattingColorClass() : string {

        if( this.connected_channel == null ) { return "bgc7"; }

        let target_color = "#DAE5FF";

        if( this.connected_channel?.info?.color ) {
            target_color = this.connected_channel.info.color;
        }

        if( this.connected_channel.users != null ) {
            const my_user_infos = this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id);

            if( my_user_infos.length > 0 && my_user_infos[0].user_info != null && my_user_infos[0].user_info['color'] != null ) {
                target_color = my_user_infos[0].user_info['color'].toUpperCase();
            }
        }

        switch( this.hodu_hex_color_process(target_color).toUpperCase() ) {
            case hodu_color.chat_color_0.toUpperCase(): return "bgc0";
            case hodu_color.chat_color_1.toUpperCase(): return "bgc1";
            case hodu_color.chat_color_2.toUpperCase(): return "bgc2";
            case hodu_color.chat_color_3.toUpperCase(): return "bgc3";
            case hodu_color.chat_color_4.toUpperCase(): return "bgc4";
            case hodu_color.chat_color_5.toUpperCase(): return "bgc5";
            case hodu_color.chat_color_6.toUpperCase(): return "bgc6";
            case hodu_color.chat_color_7.toUpperCase(): return "bgc7";
            case hodu_color.chat_color_8.toUpperCase(): return "bgc8";
            case hodu_color.chat_color_9.toUpperCase(): return "bgc9";
        }

        return `bgc7`;
    }

    /**
     * 선택된 색상 반환
     */
    isSelectedColor() : string {
        if( this.connected_channel == null || this.connected_channel.users == null ) { return "#DAE5FF"; }

        const my_user_infos = this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id);

        // 본인이 지정한 색상이 없는 경우 채팅방 색상 그대로
        if( my_user_infos.length < 1 || my_user_infos[0].user_info == null || my_user_infos[0].user_info['color'] == null ) {
            return (this.connected_channel.info == null || this.connected_channel.info.color == null ? "#DAE5FF" : this.connected_channel.info.color);
        }

        // 본인이 지정한 색상이 있는 경우
        return my_user_infos[0].user_info['color'];
    }

    /**
     * 색상 선택
     */
    async selectBackgroundColor(color : string) : Promise<void> {
        
        if( this.connected_channel == null || this.connected_channel.users == null ) { return; }

        try {

            const my_user_infos = this.connected_channel.users.filter(user => Number(user.user_id) == this.user_id);
            
            if( my_user_infos.length < 1 ) { return; }

            // 본인 유저 정보
            const post_data = JSON.parse(JSON.stringify(my_user_infos[0]));

            if( post_data.user_info == null ) {
                post_data.user_info = {};
            }

            post_data.user_info['color'] = color;

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel.channel_uid}/users/${this.user_id}`, API_METHOD.PUT, post_data);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.member ) {
                throw new Error("채팅방 배경색 변경 오류");
            }

            // 새로운 정보로 교체
            this.connected_channel.users.splice(this.connected_channel.users.indexOf(my_user_infos[0]), 1, response.data.data.member);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "채팅방 배경색 변경 중 오류 발생", ['확인']);
        }
    }

    /**
     * 채팅방 제목 반환 (유저가 정한 제목우선 없으면 처음 만들때 정해진 제목, 제목 자체가 없으면 유저목록)
     */
    getChattingRoomTitle(channel : chat_interface.I_CHANNEL_INFO) : string {

        if( channel.users != null && channel.users.length > 0 ) {
            const target_users = channel.users.filter(user => String(user.user_id) == String(this.user_id));
            if( target_users.length > 0 ) {
                const target_user = target_users[0];

                if( target_user.user_info != null && target_user.user_info['title'] != null ) {
                    return target_user.user_info['title'].trim();
                }
            }
        }

        else if( channel.info != null && channel.info.title != null ) {
            if( channel.info.title.trim().length > 0 ) return channel.info.title.trim();
        }
        
        return this.getUsersText(channel);
    }

    /**
     * 이미지, 텍스트에 나타낼 대상 유저 뽑아내기
     */
    getTargetUsers(channel : chat_interface.I_CHANNEL_INFO) : chat_interface.t_chat_channel_user[] {

        if( channel.users == null ) { return []; }

        if( channel.users.filter(user => user.leaved_flag == false).length < 2 ) { return channel.users.filter(user => user.leaved_flag == false); }

        return channel.users.filter(user => Number(user.user_id) != this.user_id && user.leaved_flag == false);
    }

    /**
     * 나가지 않은 멤버 수 반환
     */
    getMemberCountExceptLeavedMember(channel : chat_interface.I_CHANNEL_INFO) : number {
        if( channel == null || channel.users == null ) return 0;
        return channel.users.filter(user => user.leaved_flag == false).length;
    }

    /**
     * title에 나타낼 유저들 
     */
    getUsersText(channel : chat_interface.I_CHANNEL_INFO) : string {

        if( channel.users == null ) { return ""; }

        let title = "";

        for( const user of this.getTargetUsers(channel) ) {

            let user_name = user.user_name;

            if( isNaN(Number(user.user_id)) == false ) {
                const friend = this.getFriend(Number(user.user_id));

                if( friend != null ) {
                    user_name = this.getFriendName(friend);       
                }
            }
            
            title += `${user_name}${Number(user.user_id) == this.user_id ? ' (나)' : ''}, `;
        }

        return title.substr(0, title.length - 2);
    }

    /**
     * 파일 이름 리스트 반환
     */
    getFileNameList(files : t_event_file[]) : string {
        
        let last_msg : string = "메세지 없음";

        if( files == null || files.length < 1 ) return "메세지 없음";

        const file = files[0];
        
        const is_image = new RegExp("image").test(file.mimeType);

        // 사진 1장
        if( is_image == true && files.length == 1 ) {
            last_msg = `사진을 보냈습니다`;
        }

        // 사진 2장 이상
        if( is_image == true && files.length > 1 ) {
            last_msg = `사진 ${files.length}장을 보냈습니다`
        }

        // 파일 1개
        if( is_image == false && files.length == 1 ) {
            last_msg = `파일을 보냈습니다`;
        }

        // 파일 2개 이상
        if( is_image == false && files.length > 1 ) {
            last_msg = `파일 ${files.length}개를 보냈습니다`;
        }

        return last_msg.trim();
    }

    /**
     * 채팅방 첫번째 이미지 에러
     */
    firstImageError(event) : void {
        $(event.target).parent().find('p.first-img').addClass('no-img');
    }

    /**
     * 채팅방 두번째 이미지에러
     */
    secondImageError(event) : void {
        $(event.target).parent().find('p.second-img').addClass('no-img');
    }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        if( this.connected_channel == null ) return;
        
        event.dataTransfer.dropEffect = 'copy';
        // this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        // DO NOTHING, 나중에 효과 생길때 사용
        // this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        if( this.connected_channel == null ) return;

        // this.file_drag = false;
        this.addFileEvent(event.dataTransfer.files);
    }

    addFile(event) : void {
        if( this.connected_channel == null ) return;

        const files : File[] = event.target.files;
        this.addFileEvent(files);
    }

    /**
     * 파일 추가
     */
    async addFileEvent(files : File[]) : Promise<void> {
        const send_files : File[] = [];

        if( files.length < 1 ) { return; }

        if( files.length > 4 ) {
            this.hodu_show_dialog("alert", "파일은 한번에 최대 4개까지만 업로드 가능합니다", ['확인']);
            $('#addFile').val(""); 
            return;
        }

        await this.hodu_show_indicator();
        for( let file of files ) {

            // 파일 용량 체크
            if( file.size > this.DEFAULT_FILE_MAX_SIZE ) {
                await this.hodu_hide_indicator();
                this.hodu_show_dialog("alert", `${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`, ['확인']);
                $('#addFile').val("");
                return;
            }

            // 확장자가 없는 파일
            if( file.name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#addFile').val("");
                await this.hodu_hide_indicator();
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(file.name.substring(file.name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ file.name.substring(file.name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#addFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 이미지인지 파일인지 구분해서 이미지라면 리사이즈 한다
            if( new RegExp('image').test(file.type) == true ) {

                // 이미지 리사이즈
                try {
                    await this.fileReaderPromise(file)
                        .then(async(pe_fr : any) => {

                            if( pe_fr.target == null || pe_fr.target.result == null ){
                                return;
                            }
                            
                            let base64url : string = "";

                            if( pe_fr.target.result instanceof ArrayBuffer ){
                                const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                                const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                                base64url = decodeURIComponent(url);
                            } else {
                                base64url = pe_fr.target.result;
                            }

                            // 이미지 리사이즈
                            const blob : Blob = await this.hodu_image_resize(base64url);
                            
                            // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                            let resize_file : File = file;
                            try{
                                resize_file = await this.hodu_blob_to_file(blob, file.name);
                            }catch(e){
                                try {
                                    (blob as any).lastModifiedDate = new Date();
                                    (blob as any).name = file.name;
                                    resize_file = (blob as any);
                                } catch(e) {
                                    this.hodu_error_process(e, false, false, true);
                                }
                            }

                            send_files.push(resize_file);
                        });
                        
                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    await this.hodu_hide_indicator();
                    this.hodu_show_dialog("cancel", "파일 업로드 중 오류 발생", ['확인']);
                    return;
                }
            }

            else {
                send_files.push(file);
            }
        }
        await this.hodu_hide_indicator();

        // 채팅방 파일 업로드
        let event_files : t_event_file[] = [];

        try {
            const form_data = new FormData();
            
            for( const file of send_files ) { form_data.append("req_files", file); }

            const response = await this.hodu_api_call(`api/v1/chat/channel_info/${this.connected_channel?.channel_uid}/upload`, API_METHOD.POST, form_data);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || 
                !response.data.data || !response.data.data.files || response.data.data.files.length < 1 ) {
                throw new Error("파일 업로드 실패");
            }

            event_files = event_files.concat(response.data.data.files);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일 업로드 중 오류 발생", ['확인']);
            $('#addFile').val("");
            return;
        }

        // input에서 파일 값을 지운다
        $('#addFile').val("");

        // 일반 파일과 이미지가 겹치지 않도록 한다
        const img : t_event_file[] = event_files.filter(item => new RegExp('image').test(item.mimeType) == true);
        const file : t_event_file[] = event_files.filter(item => new RegExp('image').test(item.mimeType) == false);

        // 이미지 채팅 추가
        if( img.length > 0 ) {
            this.$emit("setIsSendMyMessage", true);
            this.send(JSON.stringify({
                type : "CHANNEL",
                sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG,
                data : {
                    msg : "",
                    files : img
                }
            }));
        }

        // 파일 채팅 추가
        if( file.length > 0 ) {
            this.$emit("setIsSendMyMessage", true);
            this.send(JSON.stringify({
                type : "CHANNEL",
                sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG,
                data : {
                    msg : "",
                    files : file
                }
            }));
        }
        
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File | Blob) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지 다이얼로그
     */
    imageOpen(files : t_event_file[], file : t_event_file) : void {
        const image_index : number = files.indexOf(file);

        if( image_index == -1 ){
            return;
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : files,
            "type" : "CHAT"
        });
        this.doSetShowEventImagePreview(true);
    }

    /**
     * 파일 다운로드
     */
    download(file : t_event_file) : void {
        this.hodu_download(`/chat_data/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 추가 패널 ON / OFF
     */
    extraPanelOnOff() : void {
        this.is_extra_panel_open = !this.is_extra_panel_open;
        this.is_chatting_room_background_change_open = false; 
    }

    /**
     * 채팅방으로 이동
     */
    openChattingRoom(channel : chat_interface.I_CHANNEL_INFO) : void {
        // 채널 JOIN & WebSocket 
        this.$emit('join', channel);
    }

    /**
     * 채팅방 리스트로 이동
     */
    moveChattingRoomList() : void {
        this.$emit('movePrevPageAndChattingRoomExit');
    }

    /**
     * shift + event 개행
     * enter         댓글 등록
     */
    keyUpTextArea(event) : void {
        if( event.keyCode == 16 ) { this.isShift = false; }
        if( event.keyCode == 17 ) { this.isCtrl = false; } 
    }

    /**
     * shift + event 개행
     * enter         댓글 등록
     */
    keyDownTextArea(event) : boolean {
        if( event.keyCode == 16 ) { this.isShift = true; }
        if( event.keyCode == 17 ) { this.isCtrl = true; } 

        if( event.keyCode == 13 ) {
            
            if( this.isCtrl == true ) {
                const val = this.chatting_message;
                const start = event.target.selectionStart;
                this.chatting_message = val.slice(0, start) + "\n" + val.slice(event.target.selectionEnd);
                
                this.$nextTick(() => {
                    event.target.selectionEnd = start + 1;
                    event.target.selectionStart = start + 1;
                });

                return true;
            }

            if( this.isShift == true ) {
                return true;
            }

            event.preventDefault();
            this.sendMessage();
            return false; 

        }

        return true;
    }

    /**
     * 메시지 보내기
     */
    sendMessage() : void {
        if( new RegExp(/(<([^>]+)>)/).test(this.chatting_message) == true ) {
            this.hodu_show_dialog('alert', '태그는 입력 할 수 없습니다\n예) <tag></tag>', ['확인']);
            return;
        }

        if( this.chatting_message.replace(/(<([^>]+)>)/ig, "").trim().length < 1 ) {
            return;
        }

        this.$emit("setIsSendMyMessage", true);

        this.send(JSON.stringify({
            type : 'CHANNEL',
            sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG,
            data : { msg : this.chatting_message.replace(/(<([^>]+)>)/ig, "").trim() }
        }));

        this.$nextTick(() => this.chatting_message = "");
    }

    /**
     * 채팅 삭제
     */
    deleteChatting(chat : chat_interface.I_CHAT_MESSAGE) : void {
        if( this.user_id != Number(chat.user_id) ) { return; }

        this.hodu_show_dialog('cancel', "채팅을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.send(JSON.stringify({
                    type : 'CHANNEL',
                    sub_type : chat_enum.MSG_CHANNEL_TYPE.MSG_DEL,
                    data : chat.idx
                }));
            }
        ]);
    }
    
    /**
     * 유저명 반환
     */
    getUserName(user_id : string) : string {
        
        if( isNaN(Number(user_id)) == false ) {
            const friend = this.getFriend(Number(user_id));

            if( friend != null ) {
                return this.getFriendName(friend);       
            }
        }

        if( this.connected_channel != null && this.connected_channel.users != null ) {
            
            for( const user of this.connected_channel.users ) {
                if( user_id == user.user_id ) { 
                    return user.user_name; 
                }
            }

        }

        return "알수없음";
    }

    /**
     * Date로 시간 text 만들기
     */
    getTimeTextByDate(reply_date : Date) : string {
        const hour : string = `0${reply_date.getHours() == 0 ? '12' : reply_date.getHours() > 12 ? reply_date.getHours() - 12 : reply_date.getHours() }`.slice(-2);
        const min  : string = `0${reply_date.getMinutes()}`.slice(-2);
        const amPm : string = `${reply_date.getHours() < 12 ? '오전' : '오후' }`;
        return `${amPm} ${hour}:${min}`;
    }

    /**
     * 채팅 안 읽은 수 반환
     */
    getReadCount(chat : chat_interface.I_CHAT_MESSAGE) : string {
        const idx = chat.idx;

        if( this.connected_channel == null || this.connected_channel.users == null ) { return ''; }

        const user_count = this.connected_channel.users.filter(user => user.leaved_flag == false).length;

        let unread_count = user_count;

        for( const key in this.connected_channel.users_read ) {
            if( Number(idx) <= this.connected_channel.users_read[`${key}`] ) { unread_count--; }
        }

        return unread_count > 0 ? `${unread_count}` : '';
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        $(event.target).parent().find('p.mPic').addClass('no-img');
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        $(event.target).parent().find('a').addClass('no-img');
    }

    /**
     * 채팅방 설정 모달
     */
    showChattingRoomInfoModal() : void {
        if( this.doSetChattingRoomInfoModalInfo == null ) { return; }

        this.doSetChattingRoomInfoModalInfo({
            show_modal : true
        });
    }

    /**
     * 검색창 열기
     */
    search() : void {
        this.is_search_div_open = true;
        this.$nextTick(() => $('#chatting_room_search').focus());
    }

    /**
     * 리사이즈 감지 
     */
    handleResize() : void {
        // @ts-ignore
        $('.chatroomDiv.scroll').mCustomScrollbar('destroy');
        this.$nextTick(() => this.setScroll());
        this.setChattingRoomScroll(null, true);
    }

}
