var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"titDepthBox grpCommunityTab",class:{ on : _vm.community_menu_close == true },attrs:{"id":"grpNoticeTab"}},[_c('span',{staticClass:"bar"}),_c('h3',{staticClass:"titleDep",on:{"click":function($event){$event.preventDefault();return _vm.communityMenuOnOff.apply(null, arguments)}}},[_vm._v("커뮤니티")]),_c('ul',{staticClass:"left_menu",attrs:{"id":"communityUl"}},[_c('li',[_c('a',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPersonalScope(_vm.scope) && _vm.isEnableGroupFeature(_vm.scope_group_id, 'notice')),expression:"!isPersonalScope(scope) && isEnableGroupFeature(scope_group_id, 'notice')"}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.moveGroupNotice.apply(null, arguments)}}},[_vm._v("공지사항")])]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.moveGroupBoard.apply(null, arguments)}}},[_vm._v("게시판")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:((_vm.isHoduC(_vm.scope_group_team_option ? _vm.scope_group_team_option.biz_type : '') || (_vm.isHoduH(_vm.scope_group_team_option ? _vm.scope_group_team_option.biz_type : '') && 
                    (_vm.get_group_role(_vm.scope_group_id) == 'GROUP_MANAGER' || _vm.get_group_role(_vm.scope_group_id) == 'ADMIN') ) ) && 
                    _vm.isEnableGroupFeature(_vm.scope_group_id, 'message') == true),expression:"(isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') || (isHoduH(scope_group_team_option ? scope_group_team_option.biz_type : '') && \n                    (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ) ) && \n                    isEnableGroupFeature(scope_group_id, 'message') == true"}]},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.moveMessageSend.apply(null, arguments)}}},[_vm._v("메세지 발송 하기")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:((_vm.isHoduC(_vm.scope_group_team_option ? _vm.scope_group_team_option.biz_type : '') || (_vm.isHoduH(_vm.scope_group_team_option ? _vm.scope_group_team_option.biz_type : '') && 
                    (_vm.get_group_role(_vm.scope_group_id) == 'GROUP_MANAGER' || _vm.get_group_role(_vm.scope_group_id) == 'ADMIN') ) ) && 
                    _vm.isEnableGroupFeature(_vm.scope_group_id, 'message') == true),expression:"(isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') || (isHoduH(scope_group_team_option ? scope_group_team_option.biz_type : '') && \n                    (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ) ) && \n                    isEnableGroupFeature(scope_group_id, 'message') == true"}]},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.moveMessageStatus.apply(null, arguments)}}},[_vm._v("메세지 발송 현황")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:((_vm.isHoduC(_vm.scope_group_team_option ? _vm.scope_group_team_option.biz_type : '') || (_vm.isHoduH(_vm.scope_group_team_option ? _vm.scope_group_team_option.biz_type : '') && 
                    (_vm.get_group_role(_vm.scope_group_id) == 'GROUP_MANAGER' || _vm.get_group_role(_vm.scope_group_id) == 'ADMIN') ) ) && 
                    _vm.isEnableGroupFeature(_vm.scope_group_id, 'message') == true),expression:"(isHoduC(scope_group_team_option ? scope_group_team_option.biz_type : '') || (isHoduH(scope_group_team_option ? scope_group_team_option.biz_type : '') && \n                    (get_group_role(scope_group_id) == 'GROUP_MANAGER' || get_group_role(scope_group_id) == 'ADMIN') ) ) && \n                    isEnableGroupFeature(scope_group_id, 'message') == true"}]},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.moveMessageTemplate.apply(null, arguments)}}},[_vm._v("메세지 문구 설정")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }