
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');
const HoduDocInfo = namespace('HoduDocInfo');

import { AppointmentDetailInfo } from '@/store/modules/HoduDocInfo';
import { hodu_doc_object, hodu_doc_enum } from '@/model/hodudoc';
import { t_event_hodu_doc, t_event, t_event_file } from '@/model/event';

declare var CKEDITOR: any;

import { ResizeObserver } from 'vue-resize';

import moment from 'moment';
import { hodu_color } from '@/common/color';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class Appointment extends Mixins(VueHoduCommon) {

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowEventImagePreview ?: any;

    /**
     * @HoduDocInfo.State
     */
    @HoduDocInfo.State appointment_detail_info !: AppointmentDetailInfo;

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetAppointmentDetailInfo ?: (parms : AppointmentDetailInfo) => void;
    // @HoduDocInfo.Action doSetPatientTab ?: (parms : "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX") => void;

    before_event : t_event | null = null;
    event_id : string = "";

    department : hodu_doc_object.department_info | null = null;
    doctor     : hodu_doc_object.doctor_info     | null = null;
    patient    : hodu_doc_object.patient_info    | null = null;

    events : t_event[] = [];
    image_or_files : any = [];

    file_content_height : number = 0;

    async mounted() : Promise<void> {
        
        this.event_id = this.$route.params.event_id;

        // 병원에서 들어온 경우, 환자가 들어온 경우 구분해서 본다
        let biz_id : string = this.scope_group_team_option.biz_id;
        if( this.appointment_detail_info.event != null && this.appointment_detail_info.event.event_data.appointment != null ) {
            biz_id = this.appointment_detail_info.event.event_data.appointment.biz_id;
        }
        
        await this.get_hodu_d_info(biz_id);
        await this.getEvent();

        if( this.appointment_detail_info.event == null || this.appointment_detail_info.event.event_data.appointment == null ) {
            this.hodu_show_dialog("cancel", "오류 발생\n잠시 후 다시 시도해주세요", ["확인"], [() => {}]);
            return;
        }

        const appointment     : t_event_hodu_doc = this.appointment_detail_info.event.event_data.appointment;
        const department_code : string           = appointment.department_code;
        const doctor_code     : string           = appointment.doctor_code;

        // 진료과 정보
        if( this.department_info == null || this.department_info.length < 1 || this.department_info.filter(item => item.department_code == department_code).length < 1 ) {
            this.hodu_show_dialog("cancel", "진료과 정보를 찾을 수 없습니다", ["확인"], [() => {}]);
            this.movePrevPage();
            return;
        }

        this.department = JSON.parse(JSON.stringify(this.department_info.filter(item => item.department_code == department_code)[0]));

        // 의사 정보
        if( this.doctor_info == null || this.doctor_info.length < 1 || this.doctor_info.filter(item => item.doctor_code == doctor_code).length < 1 ) {
            this.hodu_show_dialog("cancel", "의사 정보를 찾을 수 없습니다", ["확인"], [() => {}]);
            this.movePrevPage();
            return;
        }

        this.doctor = JSON.parse(JSON.stringify(this.doctor_info.filter(item => item.doctor_code == doctor_code)[0]));

        // 환자 정보
        if( this.patient_info == null || this.patient_info.length < 1 || this.patient_info.filter(item => item.user_id == (this.appointment_detail_info.event ? this.appointment_detail_info.event.event_data.event_owner_id : 0)).length < 1 ) {
            this.hodu_show_dialog("cancel", "환자 정보를 찾을 수 없습니다", ["확인"], [() => {}]);
            this.movePrevPage();
            return;
        }

        this.patient = this.patient_info.filter(item => item.user_id == (this.appointment_detail_info.event ? this.appointment_detail_info.event.event_data.event_owner_id : 0))[0];

        this.getEvent();
        this.getEvents();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {

            const title_box_height : number | undefined = $('.title_box').outerHeight();
            const doc_div_height : number | undefined = $('.DocDiv').outerHeight();
            const patient_info_div_height : number | undefined = $('#patient_info_div').outerHeight();
            
            const for_doc_div_height : number = window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (doc_div_height ? doc_div_height : 0);

            // @ts-ignore
            $('.forDocDiv').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : for_doc_div_height,
            });

            // @ts-ignore
            $('.thisPatientDiv').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                               - (patient_info_div_height ? patient_info_div_height : 0),
            });

        });
    }
    
    /**
     * 예약 조회
     */
    async getEvent() : Promise<void> {

        if( this.event_id == null ) { return; }

        let calendar_id : string = this.calendar_id;
        let scope       : string = OWNER_TYPE.GROUP;
        let scope_id    : number = this.scope_group_id;

        // 환자가 들어온 경우
        if( this.appointment_detail_info.event != null ) {
            calendar_id = this.appointment_detail_info.event.calendar_id ? this.appointment_detail_info.event.calendar_id : '';
            scope       = this.appointment_detail_info.event.event_type  ? this.appointment_detail_info.event.event_type  : '';
            scope_id    = this.appointment_detail_info.event.group_id    ? this.appointment_detail_info.event.group_id    : 0;
        }

        await this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${this.event_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);
            
                // 이벤트 데이터 세팅
                this.appointment_detail_info.event = JSON.parse(JSON.stringify(response.data.data.event_data));
                this.before_event                  = JSON.parse(JSON.stringify(response.data.data.event_data));

                if( this.doSetAppointmentDetailInfo ) { this.doSetAppointmentDetailInfo(this.appointment_detail_info); }

                // 환자용이 아니라면 CKEDITOR
                if( this.appointment_detail_info.is_patient == false && this.appointment_detail_info.event != null && 
                    this.appointment_detail_info.event.event_data.appointment != null && this.appointment_detail_info.event.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.NOSHOW ) {
                    await CKEDITOR.on( 'instanceReady', function( e ) {
                        let editor = e.editor,body = CKEDITOR.document.getBody();
                        editor.on( 'focus', function() {
                            // texArea에 포커스가 있을때 datePicker none 처리
                            $('#ui-datepicker-div').css("display", "none");
                        });
                     
                        // 높이 설정
                        // $("#cke_modal_noti_contents .cke_contents").attr('style', 'height : ' + 300 + 'px !important');
                    });

                    this.setCkeditor();
                }

            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            })
    }

    /**
     * 방문기록 조회
     */
    getEvents() : void {
        
        const start : string = moment('1970-01-01 00:00:00').utc().format();
        const end   : string = moment('2050-12-31 23:59:59').utc().format();

        const calendar_id : string = this.calendar_id;
        const owner_type  : string = this.scope;
        const owner_id    : number = this.scope_group_id;

        let query : string = `?start=${start}&end=${end}&mycal=false&syscal=false&shared=false`;

        // 환자용일때 (개인달력)
        if( this.appointment_detail_info.is_patient == true && this.appointment_detail_info.event != null && this.appointment_detail_info.event.event_data.appointment != null ) {
            const hopital_count : number = this.hodu_d_group_data.length;
            for( let i = 0; i < hopital_count; i++ ) {

                // 지금 보고 있는 병원이 아니라면 건너 뛴다
                if( this.hodu_d_group_data[i].biz_id != this.appointment_detail_info.event.event_data.appointment.biz_id ) { continue; }

                const doctor_count : number = this.hodu_d_group_data[i].teams.length;
                for( let j = 0; j < doctor_count; j++ ) {
                    const doctor_key : string = `${this.hodu_d_group_data[i].teams[j].biz_id}___${this.hodu_d_group_data[i].teams[j].department_code}___${this.hodu_d_group_data[i].teams[j].doctor_code}`; 
                    query += `&doctors=${doctor_key}`;
                }
            }
        }

        // 병원용일때 (그룹달력)
        if( this.appointment_detail_info.is_patient == false ) {
            query += `&biz_type=${this.scope_group_team_option.biz_type}&biz_id=${this.scope_group_team_option.biz_id}`;
        }
    
        this.hodu_api_call(`/api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}${query}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                this.events.splice(0, this.events.length);
                let temp_events : t_event[] = this.events.concat(response.data.data.events.filter(item => item.event_data 
                    ? item.event_data.event_owner_id == ( this.appointment_detail_info.event 
                        ? this.appointment_detail_info.event.event_data.event_owner_id 
                        : 0)
                    : false
                ));

                // 병원용에서는 요청, 거절, 확정 상태인 예약은 제외한다
                if( this.appointment_detail_info.is_patient == false ) {
                    
                    this.events = this.events.concat(temp_events.filter(item => item.event_data.appointment 
                        ? item.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.REQUEST &&
                          item.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.DECLINE &&
                          item.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.CONFIRM  
                        : false));
                        
                }

                // 예약한 사람은 전부 보이도록 한다
                else { this.events = this.events.concat(temp_events); }
                

                this.events.sort((item1, item2) : number => {
                    
                    if( new Date(item1.event_data.schedule_date.start).getTime() > new Date(item2.event_data.schedule_date.start).getTime() ) {
                        return -1;
                    } else if( new Date(item1.event_data.schedule_date.start).getTime() < new Date(item2.event_data.schedule_date.start).getTime() ) {
                        return 1;
                    }

                    return 0;
                })
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
            })
    }

    /**
     * 예약 업데이트
     */
    async updateEvent() : Promise<void> {

        const vue = this;

        if( this.appointment_detail_info.event == null || this.appointment_detail_info.event.event_data.appointment == null || 
            this.before_event                  == null || this.before_event.event_data.appointment                  == null ) { return; }
        
        let content_body = CKEDITOR.instances.noteForP.getData();   
        this.appointment_detail_info.event.event_data.appointment.prescription = content_body;

        if( this.appointment_detail_info.event.event_data.appointment.prescription == null || this.appointment_detail_info.event.event_data.appointment.prescription.length < 1 ) {
            this.hodu_show_dialog('alert', '환자 기록을 입력해주세요', ['확인'], [() => { CKEDITOR.instances.noteForP.focus(); }]);
            return;
        }

        /**
         * 이미지 파일이 있을때 => 이미지 임시파일 생성
         */
        const image_or_files_count : number = this.image_or_files.length;
        if( image_or_files_count > 0 ) {
            
            const form_data : FormData = new FormData();
            
            // FormData 내용 생성
            for( let i = 0; i < image_or_files_count; i++ ) {
                form_data.append('file', this.image_or_files[i].file);
            }

            await this.hodu_temp_upload(form_data)
                .then(async(response) => {
                    console.log(response);
                    const temp_files : t_event_file[] = response.data.data.temp_files;

                    if( vue.appointment_detail_info.event == null ) { return; }

                    // attachment null check
                    if( vue.appointment_detail_info.event.event_data.attachment == null ) {
                        vue.appointment_detail_info.event.event_data.attachment = {
                            imgs : [],
                            files : []
                        };
                    }

                    // imgs null check
                    if( vue.appointment_detail_info.event.event_data.attachment.files == null ) {
                        vue.appointment_detail_info.event.event_data.attachment.files = [];
                    }

                    // 임시파일 데이터로 객체 대체하기
                    const image_or_files_count : number = this.image_or_files.length;
                    for( let i = 0; i < image_or_files_count; i++ ) {
                        vue.appointment_detail_info.event.event_data.attachment.files.splice(this.image_or_files[i].index, 1, temp_files[i]);    
                    }

                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        const calendar_id : string = this.calendar_id;
        const scope       : string = OWNER_TYPE.GROUP;
        const scope_id    : number = this.scope_group_id;

        // before_event 와 after_event 비교해서 달라졌다면 진료 상태로 바꿈
        if( this.before_event.event_data.appointment.prescription != this.appointment_detail_info.event.event_data.appointment.prescription &&
            this.appointment_detail_info.event.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.DIAGNOSIS ) {
            this.appointment_detail_info.event.event_data.appointment.appointment_status = hodu_doc_enum.appointment_status_code.DIAGNOSIS;
            this.appointment_detail_info.event.event_data.color                          = hodu_color.appointment_diagnosis;
        }
        
        // 파일이 하나라도 추가되면 진료상태
        if( image_or_files_count > 0 && this.appointment_detail_info.event.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.DIAGNOSIS ) {
            this.appointment_detail_info.event.event_data.appointment.appointment_status = hodu_doc_enum.appointment_status_code.DIAGNOSIS;
            this.appointment_detail_info.event.event_data.color                          = hodu_color.appointment_diagnosis;
        }

        this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${this.appointment_detail_info.event.event_id}`, API_METHOD.PUT, this.appointment_detail_info.event.event_data)
                .then((response) => {
                    console.log(response);
                    this.$router.go(-1);
                })
                .catch((e) => {
                    this.hodu_error_process(e, false, false, true);
                });

    }

    /**
     * 예약 삭제
     */
    deleteEvent() : void {

        this.hodu_show_dialog("cancel", "예약을 삭제하시겠습니까?", ["아니오", "예"], [
            () => {},
            () => {

                if( this.appointment_detail_info.event == null ) { return }

                const calendar_id : string = this.calendar_id;
                const scope       : string = OWNER_TYPE.GROUP;
                const scope_id    : number = this.scope_group_id;
                
                const url : string = `api/v1/calendars/${ calendar_id }/events/${ scope }/${ scope_id }/${ this.appointment_detail_info.event.event_id }?push_yn=false`;

                this.hodu_api_call(url, API_METHOD.DELETE)
                    .then((response) => { 
                        this.$router.go(-1);
                    })
                    .catch((e) => { 
                        this.hodu_error_process(e, true, false); 
                    });
            },
        ]);
        
    }

    /**
     * 예약(일정) 삭제 권한 반환
     */
    getAppointmentDeletePermission() : boolean {
        
        if( this.appointment_detail_info.event == null || this.appointment_detail_info.event.event_data.appointment == null ) { return false; }

        // 환자는 예약 요청 중일때만 삭제 가능
        if( this.appointment_detail_info.is_patient == true ) {
            return this.appointment_detail_info.event.event_data.appointment.appointment_status == hodu_doc_enum.appointment_status_code.REQUEST;
        }

        return this.is_group_permmision(this.scope_group_id, "event", "delete");
    }

    /**
     * 예약(일정) 수정 권한 반환
     */
    getAppointmentUpdatePermission() : boolean {
        
        if( this.appointment_detail_info.event == null || this.appointment_detail_info.event.event_data.appointment == null ) { return false; }

        if( this.appointment_detail_info.is_patient == true ) { return false; }

        return this.appointment_detail_info.event.event_data.appointment.appointment_status != hodu_doc_enum.appointment_status_code.NOSHOW;
    }

    /**
     * 예약 상태에 따른 클래스 또는 텍스트를 반환
     */
    getAppointmentStatusClassOrText(appointment : t_event_hodu_doc | undefined, is_class : boolean = false) : string {

        if( appointment == null ) return '';

        switch(appointment.appointment_status) {
            case hodu_doc_enum.appointment_status_code.REQUEST  : return is_class ? "ing"       : "요청";
            case hodu_doc_enum.appointment_status_code.DECLINE  : return is_class ? "reject"    : "거절";
            case hodu_doc_enum.appointment_status_code.CONFIRM  : return is_class ? "confirmed" : "확정";
            case hodu_doc_enum.appointment_status_code.RECEIPT  : return is_class ? "made"      : "접수";
            case hodu_doc_enum.appointment_status_code.DIAGNOSIS: return is_class ? "seen"      : "진료";
            case hodu_doc_enum.appointment_status_code.NOSHOW   : return is_class ? "noshow"    : "노쇼";
            default: break;
        }

        return "";
    }

    /**
     * 거절사유 또는 메모 html 세팅
     */
    getPrescriptionHtml() : string {
        if( this.appointment_detail_info.event == null || this.appointment_detail_info.event.event_data.appointment == null ) { return ''; }

        const appointment : t_event_hodu_doc = this.appointment_detail_info.event.event_data.appointment;
        let html : string = appointment.prescription ? appointment.prescription.replace(/\n/ig, '<br>') : '';
        
        // 거절된 예약이라면 거절 사유
        if( appointment.appointment_status == hodu_doc_enum.appointment_status_code.DECLINE ) {
            html = appointment.appointment_decline_reason ? appointment.appointment_decline_reason.replace(/\n/ig, '<br>') : '';
        }

        // 환자용인데 비어있다면
        if( this.appointment_detail_info.is_patient == true && html.trim().length < 1 ) {
            html = '메모 없음';
        
            // 예약 요청중이라면 
            if( appointment.appointment_status == hodu_doc_enum.appointment_status_code.REQUEST ) {
                html = appointment.symptoms ? appointment.symptoms.replace(/\n/ig, '<br>') : '메모 없음';
            }
        }

        return html.trim();
    }

    /**
     * 파일 추가 - +버튼 눌러서 추가
     */
    async addImageAndFile(files : File[]) : Promise<void> {
        const vue = this;

        if( this.appointment_detail_info.event == null ) { return; }
        
        // 첨부파일 항목이 없다면 기본값 생성
        if( this.appointment_detail_info.event.event_data.attachment == null ){
            this.appointment_detail_info.event.event_data.attachment = {
                imgs : [],
                files : []
            }
        }

        // 파일 항목이 없다면 기본값 생성
        if( this.appointment_detail_info.event.event_data.attachment.files == null ){
            this.appointment_detail_info.event.event_data.attachment.files = [];
        }

        const file_count : number = files.length;
        const event_files : t_event_file[] = [];

        await this.hodu_show_indicator();

        // 파일 용량 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > this.DEFAULT_FILE_MAX_SIZE ) {
                alert(`${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`);
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#cdFile').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 이미지 파일이면 미리보기용 URL을 생성한다
            // let url : string = "";
            // if( new RegExp(escape('image')).test(escape(files[i].type)) == true ) {
                
            //     let blob : Blob | null = null;

            //     // 리사이즈
            //     await this.fileReaderPromise(files[i])
            //         .then(async(pe_fr : any) => {
            //             if( pe_fr.target == null || pe_fr.target.result == null ) {
            //                 return;
            //             }

            //             let base64url : string = "";

            //             if( pe_fr.target.result instanceof ArrayBuffer ){
            //                 const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
            //                 const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
            //                 base64url = decodeURIComponent(url);
            //             } else {
            //                 base64url = pe_fr.target.result;
            //             }

            //             // 이미지 리사이즈
            //             blob = await vue.hodu_image_resize(base64url);

            //         })
                
            //     url = URL.createObjectURL(blob ? blob : files[i]);
            // }

            const url : string = URL.createObjectURL(files[i]);
            
            event_files.push({
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: url,                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            })

            // 업로드용 파일 객체 담기
            this.image_or_files.push({
                index : ( this.appointment_detail_info.event.event_data.attachment.files.length + i ), 
                file : files[i]
            });
        }

        // 배열 합치기
        this.appointment_detail_info.event.event_data.attachment.files = this.appointment_detail_info.event.event_data.attachment.files.concat(event_files);

        // 파일 input 비우기
        $('#file4Patient').val("");

        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 promise
     */
    // fileReaderPromise(file : File | Blob) : Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         const fileReader : FileReader = new FileReader();
    //         fileReader.onload = (fr) => resolve(fr);
    //         fileReader.onerror = () => reject();
    //         fileReader.readAsDataURL(file);
    //     });
    // }

    /**
     * 파일 삭제
     */
    deleteImageAndFile(file : t_event_file) : void {
        if( this.appointment_detail_info.event == null || this.appointment_detail_info.event.event_data.attachment == null || 
            this.appointment_detail_info.event.event_data.attachment.files == null ) {
            return;
        }

        const image_or_file_index : number = this.appointment_detail_info.event.event_data.attachment.files.indexOf(file);

        if( image_or_file_index == -1 ){
            return;
        }

        this.appointment_detail_info.event.event_data.attachment.files.splice(image_or_file_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const image_or_files_count : number = this.image_or_files.length;
        for( let i = (image_or_files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.image_or_files[i].index > image_or_file_index ) {
                this.image_or_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.image_or_files[i].index == image_or_file_index ) {
                this.image_or_files.splice(i, 1);
                break;
            }
        }
    }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        // this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        // DO NOTHING, 나중에 효과 생길때 사용
        // this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        // this.file_drag = false;
        this.addImageAndFile(event.dataTransfer.files);
    }

    /**
     * 이미지 타입일때 background-image : url(); 설정
     */
    getBackgroundImage(file : t_event_file) : string {
        // console.log(file.mimeType);

        if( new RegExp(escape('image')).test(escape(file.mimeType)) == true ) {
            return `url( ${ file.url.indexOf('patient_file/') > -1 ? 'app_images/' : '' }${file.url})`;
        }

        return 'none';
    }

    /**
     * 파일 클릭
     */
    fileClick(file : t_event_file) : void {

        // 이미지는 미리보기
        if( new RegExp(escape('image')).test(escape(file.mimeType)) == true ) {
            this.imagePreviewOpen(file);
            return;
        }   

        this.fileDownload(file);
    }
    
    /**
     * 이미지 미리보기
     */
    imagePreviewOpen(image : t_event_file) : void {

        if( this.appointment_detail_info.event == null || 
            this.appointment_detail_info.event.event_data.attachment == null ||
            this.appointment_detail_info.event.event_data.attachment.files == null ) { return; }

        const image_files : t_event_file[] = this.appointment_detail_info.event.event_data.attachment.files.filter(item => new RegExp(escape('image')).test(escape(item.mimeType)) == true);
        const image_index : number         = image_files.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        this.doSetEventImagePreviewInfo({
            "selected_index" : image_index,
            "files" : image_files
        });
        this.doSetShowEventImagePreview(true);
    }

    // 파일 다운로드 (조회)
    fileDownload(file : t_event_file) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 환자 상세로 이동
     */
    movePatientDetail() : void {
        // if( this.doSetPatientTab ) {
        //     this.doSetPatientTab("PATIENT_FILEBOX")
        //     this.hodu_router_push(`/hospital/${new Date().getTime()}/patient`);
        // }

        if( this.appointment_detail_info.event == null ) { return; }

        this.hodu_router_push(`/hospital/${new Date().getTime()}/patient/${this.appointment_detail_info.event.event_data.event_owner_id}`);
    }

    /**
     * 다른 예약으로 이동
     */
    moveAnotherEvent(event : t_event) : void {
        
        if( this.doSetAppointmentDetailInfo == null || this.appointment_detail_info.event == null || this.appointment_detail_info.event.event_id == event.event_id ) { return; }

        this.doSetAppointmentDetailInfo({
            "event" : event,
            "is_patient" : this.appointment_detail_info.is_patient
        });

        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment/${event.event_id}`);
    }

    /**
     * CKEditor 세팅
     */
    async setCkeditor() : Promise<void> {

        // if( !CKEDITOR.instances.noteForP ) { return; }

        let ckeditorOption : any = {
            uploadUrl: "api/v1/upload/noticeUpload?type=image",  // 이게 드래그 드롭을 위한 URL
            filebrowserUploadUrl: "api/v1/upload/noticeUpload?type=file",  // 파일업로드를 위한 URL
            filebrowserImageUploadUrl : "api/v1/upload/noticeUpload?type=image",//"/hoduweb/upload/ckeditorImg.do",
            filebrowserUploadMethod : 'xhr',
            fileTools_requestHeaders: {
                'X-Requested-With': 'XMLHttpRequest',
                'device-uid': sessionStorage.getItem("device_uid"),
                'authorization': sessionStorage.getItem("session_token"),
            }
        };

        ckeditorOption.resize_maxWidth = 652;

        await CKEDITOR.replace('noteForP', ckeditorOption);
        if( this.appointment_detail_info.event == null || this.appointment_detail_info.event.event_data.appointment == null ) { return; }
        CKEDITOR.instances.noteForP.setData(this.appointment_detail_info.event.event_data.appointment.prescription);
    }

    async actionSave() : Promise<void> {
        const vue = this;

        let content_body = CKEDITOR.instances.modal_noti_contents.getData();

        if ( content_body.trim().length < 1 ) {
            alert('내용을 입력하세요');
            return;
        }

        // 등록된 이미지 찾기
        let HTML = content_body;
        //let re = /<img src="(.*?)"/gi;
        let re = /src="(.*?)"/gi;
        let result = HTML.match(re);
        let replace_images : string[] = [];

        if ( result && result.length > 0 ) {
            for ( let i = 0; i < result.length; i++ ) {
                let img = result[i].replace('src="', '').replace('"', '');

                if ( Number(img.indexOf("/app_images/temp_uploads/")) > -1 ) {
                    replace_images.push(img);
                }
            }
        }
        
        content_body; // => event.event_data.appointment.prescription 에 저장할 html 데이터

        // 예약 이벤트 수정 호출부
        // let req_data = {
        // }

        // await this.hodu_api_call(`api/v1/notification/notice/${this.team_id == 0 ? 'GROUP' : 'TEAM'}`, `${this.is_created ? 'post' : 'put'}`, req_data)
        //     .then(async(response) => {
        //         if ( this.is_created ) {
        //             if ( vue.group_team_option.noti_uid ) {
        //                 delete vue.group_team_option.noti_uid;
        //             }
        //         }
                
        //         vue.movePrevPage();
        //         return;
        //     })
        //     .catch(async(e) => {
        //         this.hodu_error_process(e, true, false);
        //     });
    }

    inputPrescription(event) {
        const value = event.target.value;
        if( this.appointment_detail_info.event != null && this.appointment_detail_info.event.event_data.appointment != null ) {
            this.appointment_detail_info.event.event_data.appointment.prescription = value;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        // @ts-ignore
        $('.forDocDiv').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('.thisPatientDiv').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
