
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, OWNER_TYPE, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class HospitalFileList extends Mixins(VueHoduCommon) {

    /**
     * 정렬된 파일 리스트 반환
     */
    get computedSortFileList() : any[] {

        if( this.selected_filebox == null ) { return []; }

        let target_list = JSON.parse(JSON.stringify(this.selected_filebox.file_list));

        target_list = target_list.sort((item1, item2) : number => {
            
            // 파일 이름 기준 정렬
            if( this.sort_type == 'NAME' ) {

                if( item1.file_name == item2.file_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_name < item2.file_name ? -1 : 1;
                }

                return item1.file_name > item2.file_name ? -1 : 1;

            }

            // 파일 용량 기준 정렬
            if( this.sort_type == 'SIZE' ) {

                if( item1.file_size == item2.file_size ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.file_size < item2.file_size ? -1 : 1;
                }

                return item1.file_size > item2.file_size ? -1 : 1;

            }

            // 파일 올린 날짜 기준 정렬
            if( this.sort_type == 'DATE' ) {

                if( new Date(item1.audit_created).getTime() == new Date(item2.audit_created).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(item1.audit_created).getTime() < new Date(item2.audit_created).getTime() ? -1 : 1;
                }

                return new Date(item1.audit_created).getTime() > new Date(item2.audit_created).getTime() ? -1 : 1;

            }

            return 0;
        });

        return target_list;
    }

    biz_id : string = "";
    hodu_d_list : any[] = [];
    selected_filebox : any = null;

    is_open_dir_list : boolean = false;

    // 정렬
    sort_type : 'NAME' | 'SIZE' | 'DATE' = 'DATE';
    sort_dir  : SORT_TYPE = SORT_TYPE.DESC;

    async mounted() : Promise<void> {
        
        this.biz_id = this.$route.params.biz_id;
        this.setScroll();
        await this.getFileBoxDirAndFile();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();
        const getHeight        : number = (titleHeight ? titleHeight : 0) + (settingBgHeight ? settingBgHeight : 0) + (sortHeaderHeight ? sortHeaderHeight : 0);
        const finalHeight      : number | undefined = window.innerHeight - getHeight;

        // @ts-ignore
        $('#hodu_d_file_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : finalHeight,
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
        });

        // @ts-ignore
        $('#dir_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0),
        });
    }

    /**
     * 유저와 관련된 파일함 전체 조회
     */
    async getFileBoxDirAndFile() : Promise<void> {

        await this.hodu_api_call(`api/v1/user/me/file`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.hodu_d_list.splice(0, this.hodu_d_list.length);
                this.hodu_d_list = this.hodu_d_list.concat(response.data.data.hodu_d_list);
                
                // 파일함이 하나도 존재하지 않는다면
                if( this.hodu_d_list.length < 1 ) {
                    this.hodu_show_dialog('cancel', "호두닥 파일함이 존재하지 않습니다", ['확인'], [() => { this.movePrevPage(); }]);
                    return;
                }

                const selected_list : any[] = this.hodu_d_list.filter(item => item.biz_id == this.biz_id);
                
                // 해당 병원의 파일함이 존재한다면 선택, 없다면 첫번째꺼 자동선택
                if( selected_list.length > 0 ) {
                    this.selected_filebox = selected_list[0];
                    return;
                }

                this.selected_filebox = this.hodu_d_list[0];

            })
            .catch((e) => {
                this.hodu_error_process(e, true, true);
            });

    }

    /**
     * 확장자 뽑기
     */
    getExtensionOfFilename(filename) : string {
        var _fileLen = filename.length;
        var _lastDot = filename.lastIndexOf('.');
    
        // 확장자 명만 추출한 후 소문자로 변경
        var _fileExt = filename.substring(_lastDot, _fileLen).toLowerCase();
 
        if ( _fileExt == ".png" || _fileExt == ".jpg" || _fileExt == ".jpeg" || _fileExt == ".bmp" || _fileExt == ".gif" ) {
            return "img";
        }
        else {
            return _fileExt.replace(".", "");
        }
    }

    /**
     * BYTE TO SIZE
     */
    byteToSize(byte, decimals = 2) : string {
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];        
    }

    /**
     * 파일 다운로드
     */
    fileDownLoad(ps_path, ps_file_name) : void { 
        this.hodu_download(`/app_images/${ps_path}`, ps_file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 호두닥 파일박스 변경
     */
    fileBoxChange(group : any) : void {
        this.biz_id = group.biz_id;

        this.selected_filebox = JSON.parse(JSON.stringify(this.hodu_d_list.filter(item => item.biz_id == this.biz_id)[0]));
    }

    /**
     * 디렉토리 리스트 ON / OFF
     */
    dirListOpenOnOff() : void {
        this.is_open_dir_list = !this.is_open_dir_list;
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'NAME' | 'SIZE' | 'DATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#hodu_d_file_scroll").mCustomScrollbar('destroy');

        // @ts-ignore
        $('#dir_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }
    
}
