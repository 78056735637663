const cookie = require('cookie');
import moment from 'moment';

export class HoduCookie {

    /**
     * 쿠키 가져오기
     * @param key - 쿠키 키값
     */
    get(key : string) : string | null {
        const cookies = cookie.parse(document.cookie);
        return cookies[key];
    }

    /**
     * 쿠키 세팅
     * @param key - 쿠키 키값
     * @param value - 쿠키 밸류값
     */
    set(key : string, value : string) : void {
        document.cookie = cookie.serialize(key, value, { expires : moment().add('month', 1).toDate() });
    }

    /**
     * 쿠키 삭제
     * @param key - 쿠키 키값
     */
    delete(key : string) : void {
        document.cookie = cookie.serialize(key, "", { expires : moment().toDate() });
    }

}

export const hodu_cookie : HoduCookie = new HoduCookie();