
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupSurveyChoiceListModalInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class GroupSurvey extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupSurveyChoiceListModalInfo ?: (params : GroupSurveyChoiceListModalInfo) => void;

    mounted() : void {
        //tab page
        $('#TopTab h2').click (function(){
            var contentIndex = $(this).index();
            $('.tab-content').hide();
            $('.tab-content').eq(contentIndex).show();
            $(this).addClass('active').siblings().removeClass();

            return false;
        });

        $('.sendTabUl > li').click (function(){
            var contentIndex = $(this).index();
            $('.sn-tab-con').hide();
            $('.sn-tab-con').eq(contentIndex).show();
            $(this).addClass('on').siblings().removeClass();

            return false;
        });

        $('.resultTabUl > li').click (function(){
            var contentIndex = $(this).index();
            $('.srContWrap').hide();
            $('.srContWrap').eq(contentIndex).show();
            $(this).addClass('on').siblings().removeClass();

            return false;
        });

        //toggleClass
        $("#typeSelBox").click(function(){
            $(".selectUl").toggleClass("on");
        });

        $("#moveSelBox").click(function () {
			$(this).siblings('ul').toggle();
		});

        //hover
        $('.tooltipSpan').hover(function(){
            $('.requiredToolBox').css("display", "block");
        }, function(){
            $('.requiredToolBox').css("display", "none");
        });

        //원형 그래프
        $(document).ready(function(){
            pieAct();
        })

        function pieAct(){
            var color = ["#477fff","#ff198b","#ffa70e","#4dbaff","#13d08b"]; //그래프 색상
            var angel = -90; //그래프 시작 지점
            var pieWidth = $('.pie').width(); 

            $('.pie circle').each(function(i){
                var percentData = $(this).data('percent'); //그래프 비율
                var perimeter = ((pieWidth??0)/2) * 3.14; //원의 둘레
                var percent =  percentData*(perimeter/100); //그래프 비율만큼 원의 둘레 계산
                
                //그래프 비율, 색상 설정
                $(this).css({
                    'stroke-dasharray':percent+' '+perimeter, 
                    'stroke':color[i],
                    'transform':'rotate('+angel+'deg)'
                });
                $('.pie_info > li').eq(i).find('.colorDot').css({'background':color[i]});
                
                //그래프 시작 지점 갱신
                angel += (percentData * 3.6); 
            })
        }
    }

    showChoiceListModal() {
        this.doSetGroupSurveyChoiceListModalInfo?.({
            show_modal : true,
            data : {}
        });
    }

    /**
     * 리사이즈 감지
     */
     handleResize() : void {
        // // @ts-ignore
        // $('#group_member_scroll').mCustomScrollbar('destroy');
        
    	// this.setScroll();
    }

}
