import VueHoduCommon, { API_METHOD, getBrowserInfo, getOSInfo } from '@/mixin/VueHoduCommon';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { Store } from 'vuex';
import { chat_notification_process } from './type/channel';
import { group_notice_notification_process } from './type/group_notice';
import { message_notification_process } from './type/message';
import { push_notification_process } from './type/push';

export async function initFirebase() {

	// 알림을 지원하지 않는다면 아무것도 하지않는다
	if (!('Notification' in window) || !firebase.messaging.isSupported()) {
		return;
	}

	// Your web app's Firebase configuration
	const firebaseConfig = {
		apiKey: "AIzaSyDKoiri-ATwfZJlwSK6wLi-kAp23oQdAWw",
		authDomain: "acquired-script-118310.firebaseapp.com",
		databaseURL: "https://acquired-script-118310.firebaseio.com",
		projectId: "acquired-script-118310",
		storageBucket: "acquired-script-118310.appspot.com",
		messagingSenderId: "48808503160",
		appId: "1:48808503160:web:9991ba3abfecb69fc64a8d"
	};

	// Initialize Firebase
	firebase.initializeApp(firebaseConfig);
	
	const messaging = firebase.messaging();
	
	messaging.usePublicVapidKey('BA6IeoP8Ww9DpDUVj9jGLuIVMrleu34vXGAtg7VNAIy3AUYrk5qZlMOAGkMaLK-XnWScKI5s8n6q9fyKlnGkApU')
	
	// 알림 수신을 위한 사용자 권한 요청
	Notification.requestPermission()
	.then(async(permission) => {
		console.log('permission ', permission)
		if (permission === 'granted') {

			// Send token to server for send notification
			const token = await messaging.getToken();

			if( token != null && token.length > 0 ) {
				console.log(token);

				// 로그인이 된 경우
				if( (window["HoduApp"].$store as Store<any>).state.LoginInfo.isLogin == true ) {
					const app_vue : VueHoduCommon = (window["HoduApp"].$root.$children[0] as VueHoduCommon);
					try {
						await updatePushToken(app_vue, token);
					} catch(e) {
						app_vue.hodu_error_process(e, false, false, true);
					}
				}

			}

			// Handle received push notification at foreground
			messaging.onMessage(payload => {
				console.log(payload);
				
				// 로그인이 안 된 경우
				if( (window["HoduApp"].$store as Store<any>).state.LoginInfo.isLogin == false ) {
					return;
				}

				const group_id = payload.data.group_id ? Number(payload.data.group_id) : 0;
				const team_id  = payload.data.team_id ? Number(payload.data.team_id) : 0;

				// 그룹, 팀 알림 거부 체크
				if( ( group_id > 0 || team_id > 0 ) && (window["HoduApp"].$store as Store<any>).state.LoginInfo.user_preference != null ) {
					const user_preference : any[] = (window["HoduApp"].$store as Store<any>).state.LoginInfo.user_preference;

					for( const preference of user_preference ) {
						if( preference.preference == null ) {
							continue;
						}

						if( preference.cate != 'group_alarm' && preference.cate != "team_alarm" ) {
							continue;
						}

						if( group_id > 0 && team_id < 1 && preference.cate == 'group_alarm' && preference.preference[`${group_id}`] == 'false' ) {
							console.log(preference.preference[`${group_id}`]);
							return;
						}

						if( team_id > 0 && preference.cate == 'team_alarm' && preference.preference[`${team_id}`] == 'false' ) {
							console.log(preference.preference[`${team_id}`]);
							return;
						}
					}
				}

				const notification_option : NotificationOptions = {
					body : payload.data.body,
					icon : require('@/assets/images/contents/ic_hoduShare.png')
				}

				if( payload.data.channel_uid ) {
					notification_option.tag = payload.data.channel_uid;
					notification_option.renotify = true;
				}

				const push_yn = payload.data.push_yn;
				const noti_type = payload.data.noti_type;
				const noti_sub_type = payload.data.noti_sub_type;
				
				if( push_yn != null && Boolean(push_yn) == false ) {
					window['calendar_reload']?.();
					return;
				}

				if( noti_type == "PUSH" && noti_sub_type == "SILENT_EVENT_SYNC" ) {
					window['calendar_reload']?.();
				}

				else if( noti_type == "PUSH" && noti_sub_type == "SILENT_USER_SYNC" ) {
					const app_vue : VueHoduCommon = (window["HoduApp"].$root.$children[0] as VueHoduCommon);

					app_vue.hodu_api_call('api/v1/user/me', API_METHOD.GET).then(async(response) => {

						try {

							console.log(response);

							if( response == null || !app_vue.isHttpStatusSuccess(response.status) || response.data == null || response.data.data == null ) {
								throw new Error("자동 로그인 실패");
							}

							await app_vue.doLogin({
								isLogin           : true,
								user_id           : response.data.data.user.user_id,
								user_type         : response.data.data.last_login_type,
								user_email        : response.data.data.user.user_email,
								user_name         : response.data.data.user.user_name,
								user_phone_number : response.data.data.user.user_phone_number,
								is_temp_password  : response.data.data.is_temp_password,
								user_preference   : response.data.data.user_preference,
								user_group_role   : [],
								user_team_role    : [],
								template_map      : response.data.data.template_map,
								auth_info         : response.data.data.user.auth_info
							});
							
							await app_vue.get_group_role_service();

							await window['calendar_reload']?.();
							await window['user_area_refresh']?.();
							
						} catch(e) {
							console.log(e);
						}
						
					});
					
				}

				else if( noti_type == "PUSH" && noti_sub_type == "SILENT_TODO_SYNC" ) {
					window['getTodoList']?.();
				}

				else {
					window['calendar_reload']?.();
					const notification : Notification = new Notification(payload.data.title, notification_option);

					// 알림 클릭 이벤트
					notification.onclick = (event) => {
						console.log(event);
						notification_process(payload.data);
					};
				}
				
			});

			// 토큰이 업데이트 된 경우
			messaging.onTokenRefresh(async() => {

				// 로그인이 안 된 경우
				if( (window["HoduApp"].$store as Store<any>).state.LoginInfo.isLogin == false ) {
					return;
				}
				
				const app_vue : VueHoduCommon = (window["HoduApp"].$root.$children[0] as VueHoduCommon);
				const token = await messaging.getToken();

				try {
					await updatePushToken(app_vue, token);
				} catch(e) {
					app_vue.hodu_error_process(e, false, false, true);
				}

			});
		}
	})
	
	
}

/**
 * notification 처리
 * @param  {any} data
 * @returns void
 */
function notification_process(data : any) : void {

	const app_vue : VueHoduCommon = (window["HoduApp"].$root.$children[0] as VueHoduCommon);

	const noti_type = data.noti_type;

	if( noti_type == 'PUSH' ) {
		push_notification_process(app_vue, data);
	}

	if( noti_type == 'MESSAGE' ) {
		message_notification_process(app_vue, data);
	}

	if( noti_type == 'GROUP_NOTICE' ) {
		group_notice_notification_process(app_vue, data);
	}

	if( noti_type == 'CHANNEL' ) {
		chat_notification_process(app_vue, data);
	}

}

/**
 * 푸시토큰 업데이트
 */
export async function updatePushToken(vue : VueHoduCommon, token ?: string) : Promise<void> {

	if( token == null ) {
		token = await firebase.messaging().getToken();
	}

	try {
		const response = await vue.hodu_api_call('api/v1/user/me/token', API_METHOD.PUT, { 
			"push_token"    : token,
			"device_os"     : "WEB",
            "device_os_ver" : await getBrowserInfo(),
            "device_model"  : await getOSInfo(), 
		}, false);

		if( !response || !vue.isHttpStatusSuccess(response.status) ) {
			throw new Error("푸시토큰 업데이트 실패");
		}

	} catch(e) {
		vue.hodu_error_process(e, false, false, true);
	}
}