
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
const GroupInfo  = namespace('GroupInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class GroupMemberSmall extends Mixins(VueHoduCommon) {
    /**
     * 변수 선언부
     */
    @Prop() group_id        !: number;
    @Prop() team_id         !: number;
    @Prop() is_member_read  !: boolean;

    member_data       : any     = [];
    manager_image     : string  = "";
    manager_count     : number  = 0;
    manager_name      : string  = "";
    sub_manager_image : string  = "";
    sub_manager_count : number  = 0;
    sub_manager_name  : string  = "";
    member_count      : number  = 0;

    async mounted() {
        this.getGroupMember();
    }

    /**
     * 그룹멤버조회
     */
    async getGroupMember() : Promise<void> {
        const vue = this;
        this.member_data = [];

        await this.hodu_api_call(`api/v1/groupsweb/getGroupTeamMember/${this.group_id}/${this.team_id}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                this.member_data       = response.data.data.list;
                this.manager_count     = 0;
                this.manager_name      = "";
                this.sub_manager_count = 0;
                this.sub_manager_name  = "";
                this.member_count      = 0;
                this.manager_image     = require("@/assets/images/contents/im_photoB.gif");
                this.sub_manager_image = require("@/assets/images/contents/im_photoB.gif");

                $.each(this.member_data, function(key, data) {
                    if ( vue.group_id > 0 && vue.team_id == 0 && data.is_apply ) {
                        if ( (vue.GroupAuth.GROUP_MANAGER == data.role) || (vue.GroupAuth.ADMIN == data.role) ) {
                            vue.manager_count ++;

                            if ( !vue.manager_name ) {
                                vue.manager_name  = data.user_info.user_name;
                                vue.manager_image = (data.user_info.user_pic ? "/app_images/" + data.user_info.user_pic : require("@/assets/images/contents/im_photoB.gif"));
                            }
                        }
                        else if ( vue.GroupAuth.GROUP_SUB_MANAGER == data.role ) {
                            vue.sub_manager_count ++;

                            if ( !vue.sub_manager_name ) {
                                vue.sub_manager_name  = data.user_info.user_name;
                                vue.sub_manager_image = (data.user_info.user_pic ? "/app_images/" + data.user_info.user_pic : require("@/assets/images/contents/im_photoB.gif"));
                            }
                        }
                        else {
                            vue.member_count ++;
                        }
                    }
                    else if ( vue.group_id > 0 && vue.team_id > 0 && data.is_apply ) {
                        if ( (vue.GroupAuth.TEAM_MANAGER == data.role) || (vue.GroupAuth.ADMIN == data.role) ) {
                            vue.manager_count ++;

                            if ( !vue.manager_name ) {
                                vue.manager_name  = data.user_info.user_name;
                                vue.manager_image = (data.user_info.user_pic ? "/app_images/" + data.user_info.user_pic : require("@/assets/images/contents/im_photoB.gif"));
                            }
                        }
                        else {
                            vue.member_count ++;
                        }
                    }
                });
            })
            .catch(async(e) => {
                //this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 멤버관리 이동
     */
    goMemberList() : void {
        this.$emit('increment');
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('@/assets/images/contents/im_photoB.gif');
    }
    
}
