import {GetterTree, MutationTree , ActionTree} from "vuex";

export const namespaced = true;

export interface MenuInfoState {
    /**
     * Left
     */
    left_control_box_flag  : boolean;
    right_control_box_flag : boolean;
    group_menu_close       : boolean;
    message_menu_close     : boolean;
    work_menu_close        : boolean;
    community_menu_close   : boolean;
    appointment_menu_close : boolean;
    doctor_menu_close      : boolean;
    patient_menu_close     : boolean;
    hospital_setting_close : boolean;  
    resident_menu_close    : boolean;  
    
    /**
     * Right
     */
    user_profile_open           : boolean;
    notification_list_menu_open : boolean;
    preferences_menu_open       : boolean;
    todo_menu_open              : boolean;
    todo_create_or_update       : boolean;
    etc_menu_open               : boolean;
    filter_menu_open            : boolean;
}

export const state: MenuInfoState = {
    /**
     * Left
     */
    left_control_box_flag  : false,
    right_control_box_flag : false,
    group_menu_close       : false,
    message_menu_close     : false,
    work_menu_close        : false,
    community_menu_close   : false,
    appointment_menu_close : true,
    doctor_menu_close      : true,
    patient_menu_close     : true,
    hospital_setting_close : true,
    resident_menu_close    : true,

    /**
     * Right
     */
    user_profile_open           : false,
    notification_list_menu_open : false,
    preferences_menu_open       : false,
    todo_menu_open              : false,
    todo_create_or_update       : false,
    etc_menu_open               : false,
    filter_menu_open            : false,
}

// getters
export const getters: GetterTree<MenuInfoState, any> = {

    isLeftControlBoxFlag: (state) => {
        return state.left_control_box_flag;
    },

    isRightControlBoxFlag: (state) => {
        return state.right_control_box_flag;
    },

    isGroupMenuClose: (state) => {
        return state.group_menu_close;
    },

    isMessageMenuClose: (state) => {
        return state.message_menu_close;
    },

    isWorkMenuClose: (state) => {
        return state.work_menu_close;
    },
    
    isCommunityMenuClose: (state) => {
        return state.community_menu_close;
    },

    isAppointmentMenuClose : (state) => {
        return state.appointment_menu_close;
    },

    isDoctorMenuClose : (state) => {
        return state.doctor_menu_close;
    },   

    isPatientMenuClose : (state) => {
        return state.patient_menu_close;
    },   

    isHospitalSettingClose : (state) => {
        return state.hospital_setting_close;
    },   

    isResidentMenuClose : (state) => {
        return state.resident_menu_close;
    },   

    isUserProfileOpen: (state) => {
        return state.user_profile_open;
    },

    isNotificationListMenuOpen: (state) => {
        return state.notification_list_menu_open;
    },

    isPreferencesMenuOpen: (state) => {
        return state.preferences_menu_open;
    },

    isTodoMenuOpen: (state) => {
        return state.todo_menu_open;
    },

    isTodoCreateOrUpdate: (state) => {
        return state.todo_create_or_update;
    },

    isEtcMenuOpen: (state) => {
        return state.etc_menu_open;
    },

    isFilterMenuOpen: (state) => {
        return state.filter_menu_open;
    },

}

// action
export const actions: ActionTree<MenuInfoState, MenuInfoState> = {

    doSetLeftControlBoxFlag: ({commit, state}, left_control_box_flag:boolean) => {
        console.log("actions.doSetLeftControlBoxFlag");
        commit("setLeftControlBoxFlag", left_control_box_flag);
    },

    doSetRightControlBoxFlag: ({commit, state}, right_control_box_flag:boolean) => {
        console.log("actions.doSetRightControlBoxFlag");
        commit("setRightControlBoxFlag", right_control_box_flag);
    },

    doSetGroupMenuClose: ({commit, state}, group_menu_close:boolean) => {
        console.log("actions.doSetGroupMenuClose");
        commit("setGroupMenuClose", group_menu_close);
    },

    doSetMessageMenuClose: ({commit, state}, message_menu_close:boolean) => {
        console.log("actions.doSetMessageMenuClose");
        commit("setMessageMenuClose", message_menu_close);
    },

    doSetWorkMenuClose: ({commit, state}, work_menu_close:boolean) => {
        console.log("actions.doSetWorkMenuClose");
        commit("setWorkMenuClose", work_menu_close);
    },

    doSetCommunityMenuClose: ({commit, state}, community_menu_close:boolean) => {
        console.log("actions.doSetCommunityMenuClose");
        commit("setCommunityMenuClose", community_menu_close);
    },

    doSetResidentMenuClose: ({commit, state}, resident_menu_close:boolean) => {
        console.log("actions.doSetResidentMenuClose");
        state.resident_menu_close = resident_menu_close;
    },

    doSetUserProfileOpen: ({commit, state}, user_profile_open:boolean) => {
        console.log("actions.doSetUserProfileOpen");
        commit("setUserProfileOpen", user_profile_open);
    },

    doSetNotificationListMenuOpen: ({commit, state}, notification_list_menu_open:boolean) => {
        console.log("actions.doSetNotificationListMenuOpen");
        commit("setNotificationListMenuOpen", notification_list_menu_open);
    },

    doSetPreferencesMenuOpen: ({commit, state}, preferences_menu_open:boolean) => {
        console.log("actions.doSetPreferencesMenuOpen");
        commit("setPreferencesMenuOpen", preferences_menu_open);
    },

    doSetTodoMenuOpen: ({commit, state}, todo_menu_open:boolean) => {
        console.log("actions.doSetTodoMenuOpen");
        commit("setTodoMenuOpen", todo_menu_open);
    },

    doTodoCreateOrUpdate: ({commit, state}, todo_create_or_update:boolean) => {
        console.log("actions.doTodoCreateOrUpdate");
        commit("setTodoCreateOrUpdate", todo_create_or_update);
    },

    doSetEtcMenuOpen: ({commit, state}, etc_menu_open:boolean) => {
        console.log("actions.doSetEtcMenuOpen");
        commit("setEtcMenuOpen", etc_menu_open);
    },

    doSetFilterMenuOpen: ({commit, state}, filter_menu_open : boolean) => {
        console.log("actions.doSetFilterMenuOpen");
        state.filter_menu_open = filter_menu_open;
    }
}

// mutation
export const mutations: MutationTree<MenuInfoState> = {
    
    setLeftControlBoxFlag: (state, payload:boolean) => {
        console.log(`mutations.setLeftControlBoxFlag ${JSON.stringify(payload)}`);
        state.left_control_box_flag = payload;
    },

    setRightControlBoxFlag: (state, payload:boolean) => {
        console.log(`mutations.setRightControlBoxFlag ${JSON.stringify(payload)}`);
        state.right_control_box_flag = payload;
    },

    setGroupMenuClose: (state, payload:boolean) => {
        console.log(`mutations.setGroupMenuClose ${JSON.stringify(payload)}`);
        state.group_menu_close = payload;
    },

    setMessageMenuClose: (state, payload:boolean) => {
        console.log(`mutations.setMessageMenuClose ${JSON.stringify(payload)}`);
        state.message_menu_close = payload;
    },

    setWorkMenuClose: (state, payload:boolean) => {
        console.log(`mutations.setWorkMenuClose ${JSON.stringify(payload)}`);
        state.work_menu_close = payload;
    },

    setCommunityMenuClose: (state, payload:boolean) => {
        console.log(`mutations.setCommunityMenuClose ${JSON.stringify(payload)}`);
        state.community_menu_close = payload;
    },

    setUserProfileOpen: (state, payload:boolean) => {
        console.log(`mutations.setUserProfileOpen ${JSON.stringify(payload)}`);
        state.user_profile_open = payload;
    },

    setNotificationListMenuOpen: (state, payload:boolean) => {
        console.log(`mutations.setNotificationListMenuOpen ${JSON.stringify(payload)}`);
        state.notification_list_menu_open = payload;
    },

    setPreferencesMenuOpen: (state, payload:boolean) => {
        console.log(`mutations.setPreferencesMenuOpen ${JSON.stringify(payload)}`);
        state.preferences_menu_open = payload;
    },

    setTodoMenuOpen: (state, payload:boolean) => {
        console.log(`mutations.setTodoMenuOpen ${JSON.stringify(payload)}`);
        state.todo_menu_open = payload;
    },

    setTodoCreateOrUpdate: (state, payload:boolean) => {
        console.log(`mutations.setTodoCreateOrUpdate ${JSON.stringify(payload)}`);
        state.todo_create_or_update = payload;
    },

    setEtcMenuOpen: (state, payload:boolean) => {
        console.log(`mutations.setEtcMenuOpen ${JSON.stringify(payload)}`);
        state.etc_menu_open = payload;
    },

}