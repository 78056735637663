
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class GroupFileListNew extends Mixins(VueHoduCommon) {

    mounted() : void {
        //리스트형으로 보기
        $(".btns .dpTwo").click(function(){
            $(".section_ce_fix").addClass("dp2");
            $(".section_ce_fix").removeClass("dp1");
            $(".btns .dpOne").show();
            $(this).hide();

        });

        //박스형으로 보기
        $(".btns .dpOne").click(function(){
            $(".section_ce_fix").removeClass("dp2");
            $(".section_ce_fix").addClass("dp1");
            $(".btns .dpTwo").show();
            $(this).hide();

        });

        // 즐겨찾기 정렬
        $(".faveStar").click(function(){
            $(this).toggleClass("fave_on");
        });

        //메뉴 열기
        $(".fileMenu").click(function(){
            $(this).parent().toggleClass("on");
        });

        // 왼쪽 menu 열기
        $(".closeLeft").click(function(){
            $(".section_ce_fix").toggleClass("leftDivClosed");
            $(this).toggleClass("on");

        });

        // 왼쪽 메뉴 체크
        $(".fdTreeBox .fd_tree a").click(function(){
            $(this).parent().parent().addClass('active').siblings().removeClass();
        })

        // 왼쪽 메뉴 dropdown
        $(".tree_has_child > .mainMore").click(function(){
            $(this).parent().siblings().toggleClass("open");
            $(this).toggleClass("open");
        })

        // 왼쪽 메뉴 dropdown2
        $(".tree_has_child > .subMore").click(function(){
            $(this).parent().siblings().toggleClass("open");
            $(this).toggleClass("open");
        })

        var file_drag = false;
        // 파일을 드래그하고 해당 영역위에 올렸을때 발생하는 이벤트
        $('body').on('dragover', function(e){
            e.stopPropagation();
            e.preventDefault();
            $(".bg4file").css('display','block');
            
            file_drag = true;
        });

        // 파일을 드래그하고 해당 영역을 떠났을때 발생하는 이벤트
        $('.bg4file').on('dragleave', function(e){
            if(file_drag){
                $(".bg4file").css('display','none');
            }

            file_drag = false;
        });
    }

    moveFileBox() : void {
        this.hodu_router_push(`/filebox?${new Date().getTime()}`);
    }

    moveGroupFileBox() : void {
        this.hodu_router_push(`/group/filebox?${new Date().getTime()}`);
    }

    moveGroupFileList() : void {
        this.hodu_router_push(`/group/filebox/dir_id?${new Date().getTime()}`);
    }

}
