
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import SearchBar from '@/components/ui/SearchBar.vue';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');
const dateFormat = require('dateformat');

import { GroupNoticeSearchInfo } from '@/store/modules/GroupInfo';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        SearchBar, ResizeObserver
    },
})
export default class GroupNoticeList extends Mixins(VueHoduCommon) {
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupNoticeReadInfo ?: any;

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;
    @GroupInfo.State group_notice_search_info !: GroupNoticeSearchInfo;

    /**
     * GroupInfo.Action
     */
    @GroupInfo.Action doSetGroupNoticeSearchInfo ?: (params : GroupNoticeSearchInfo) => void;

    /**
     * 변수 선언부
     */
    search_type          : string  = "GROUP";  // 공백 : 호두공지 사항, GROUP : 그룹&팀 공지사항
    notice_type          : string  = "";
    notice_data          : any     = [];
    original_notice_data : any     = [];
    b_isNoticeCreateFlag : boolean = false;

    notice_sort_target : string | null = null;
    notice_sort_direction : string = "ASC";

    is_search_open : boolean = false;
    search_query : string = "";
    search_query_temp : string = "";

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }

        this.search_query = this.group_notice_search_info.search_query ? this.group_notice_search_info.search_query : "";
        if ( this.search_query.length > 0 ) {
            this.is_search_open = true;
            this.search_query_temp = this.search_query;
        }

        this.notice_sort_target = this.group_notice_search_info.notice_sort_target;
        this.notice_sort_direction = this.group_notice_search_info.notice_sort_direction;
    }

    mounted() : void {
        const vue = this;
        
        this.setScroll();
        
        $(".selectTitle").click(function(){
			$(this).toggleClass("on");
			$(this).siblings().toggleClass("on");
		});
		
		$('.seeNotice').on('click', function(e){
            e.preventDefault();
            vue.hodu_router_push(`/group/${new Date().getTime()}/notice_create`);
			// $.notificationTemp3.initViewPage('#section_ce');
		});
        
        /*
		$('#addNotice').on('click', function(e){
            e.preventDefault();
            vue.hodu_router_push('group_notice_create');
			// $.notificationTemp4.initViewPage('#section_ce');
        });
        */

       // 공지사항 uid 삭제
       if ( this.group_team_option.noti_uid ) {
            delete vue.group_team_option.noti_uid;
        }
        
        // 공지사항 조회
        this.getNotice();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        const scrollHeight      : number | undefined = window.innerHeight - ( titleHeight ? titleHeight : 0 ) - ( settingBgHeight ? settingBgHeight : 0 ) - ( sortHeaderHeight ? sortHeaderHeight : 0 );

        // @ts-ignore
        $('#group_notice_scroll').mCustomScrollbar({
            axis : 'y',
            setHeight : scrollHeight,
            scrollbarPosition : 'outside',
        });
    }

    /**
     * 공지사항 작성
     */
    addNotice() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);

        if ( this.group_team_option.noti_uid ) {
            delete this.group_team_option.noti_uid;
        }

        this.doGroupTeamOption(this.group_team_option);
        this.hodu_router_push(`/group/${new Date().getTime()}/notice_create`);
    }

    ckeditor4() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);

        if ( this.group_team_option.noti_uid ) {
            delete this.group_team_option.noti_uid;
        }

        this.doGroupTeamOption(this.group_team_option);
        this.hodu_router_push(`/group/${new Date().getTime()}/notice_create_editor`);
    }

    /**
     * 공지사항 상세보기
     */
    async detailNotice(po_data) : Promise<void> {
        const vue = this;

        // 공지사항 읽음여부
        if ( !po_data.read_flag ) {
            await this.hodu_api_call(`api/v1/notification/insertRead/${po_data.noti_uid}`, API_METHOD.PUT, {}, false)
                .then(async(response) => {
                    vue.goNoticeDetail(po_data);
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }
        else {
            vue.goNoticeDetail(po_data);
        }
    }

    goNoticeDetail(po_data) : void { 
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);
        this.group_team_option.noti_uid = po_data.noti_uid;

        this.doGroupTeamOption(this.group_team_option);
        this.hodu_router_push(`/group/${new Date().getTime()}/notice_detail`);
    }

    /**
     * 그룹 읽음 현황 모달 보이기
     */
    showGroupNoticeStatus(noti_uid : string) : void {
        this.doSetGroupNoticeReadInfo({
            show_modal : true,
            noti_uid : noti_uid
        });
    }

    /**
     * 그룹 공지사항, 호두 공지사항 변경
     */
    // tabChange(type) : void {
    //     alert('준비중입니다');
    //     this.search_type = type;
    // }

    /**
     * 그룹 공지사항 조회
     */
    async getNotice() : Promise<void> {
        
        this.notice_type = (this.team_id == 0 ? "GROUP" : "TEAM");

        await this.hodu_api_call(`api/v1/notification/notice/${this.group_id}/${this.team_id}/${this.notice_type}?page=0&size=0&current_date=${moment(new Date()).utc().format()}`, API_METHOD.GET, null)
            .then(async(response) => {

                this.notice_data.splice(0, this.notice_data.length)
                this.notice_data = this.notice_data.concat(response.data.data.list);

                if( this.search_query != null && this.search_query.length > 0 ) {
                    this.notice_data = this.notice_data.filter(notice => this.hodu_string_includes(notice.noti_info.title, this.search_query) == true || 
                                                                         this.hodu_string_includes(notice.noti_data.body, this.search_query) == true);
                }

                // 공지사항 작성
                if ( this.notice_type == "TEAM" ) {
                    this.b_isNoticeCreateFlag = this.is_team_permmision(this.team_id, "notice", "create");
                }
                else {
                    this.b_isNoticeCreateFlag = this.is_group_permmision(this.group_id, "notice", "create");
                }

                $.each(this.notice_data, function(key, data) {
                    data.noti_info.start_date = dateFormat(data.noti_info.start_date, 'yyyy.mm.dd');
                });

                this.original_notice_data = JSON.parse(JSON.stringify(this.notice_data));

                if( this.notice_sort_target != null ) {
                    this.noticeListSort();
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 그룹, 팀 이름 기준 정렬
     */
    groupTeamSort() : void {
        // 이미 GROUP_TEAM Sort 중이라면
        if( this.notice_sort_target == "GROUP_TEAM" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // GROUP_TEAM Sort가 아니였다면
        else {
            this.notice_sort_target = "GROUP_TEAM";
            this.notice_sort_direction = "ASC";
        }

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.notice_sort_target = this.notice_sort_target;
        search_info.notice_sort_direction = this.notice_sort_direction;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * 제목 기준 정렬
     */
    subjectSort() : void {
        // 이미 SUBJECT Sort 중이라면
        if( this.notice_sort_target == "SUBJECT" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // SUBJECT Sort가 아니였다면
        else {
            this.notice_sort_target = "SUBJECT";
            this.notice_sort_direction = "ASC";
        }

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.notice_sort_target = this.notice_sort_target;
        search_info.notice_sort_direction = this.notice_sort_direction;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * 작성자 이름 기준 정렬
     */
    userNameSort() : void {
        // 이미 USER_NAME Sort 중이라면
        if( this.notice_sort_target == "USER_NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // USER_NAME Sort가 아니였다면
        else {
            this.notice_sort_target = "USER_NAME";
            this.notice_sort_direction = "ASC";
        }

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.notice_sort_target = this.notice_sort_target;
        search_info.notice_sort_direction = this.notice_sort_direction;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * 작성일 기준 정렬
     */
    timeSort() : void {
        // 이미 TIME Sort 중이라면
        if( this.notice_sort_target == "TIME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.notice_sort_direction ) {
                case "ASC":
                    this.notice_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.notice_sort_target = null;
                    break; 
            }
        }
        
        // TIME Sort가 아니였다면
        else {
            this.notice_sort_target = "TIME";
            this.notice_sort_direction = "ASC";
        }

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.notice_sort_target = this.notice_sort_target;
        search_info.notice_sort_direction = this.notice_sort_direction;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        if( this.notice_sort_target == null ) { this.setListToOriginalList(); }
        else { this.noticeListSort(); }
    }

    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.notice_data = JSON.parse(JSON.stringify(this.original_notice_data));
    }

    /**
     * 공지사항 정렬
     */
    noticeListSort() : void {
        this.notice_data.sort((o1 : any, o2 : any) : number => {
            // 그룹&팀 이름 오름차순
            if( this.notice_sort_target == "GROUP_TEAM" && this.notice_sort_direction == "ASC" ) {
                if( o1.group_nm  > o2.group_nm ) { return  1; } 
                if( o1.group_nm  < o2.group_nm ) { return -1; } 
                if( o1.group_nm == o2.group_nm ) { return  0; }
            }

            // 그룹&팀 이름 내림차순
            if( this.notice_sort_target == "GROUP_TEAM" && this.notice_sort_direction == "DESC" ) {
                if( o1.group_nm  > o2.group_nm ) { return -1; } 
                if( o1.group_nm  < o2.group_nm ) { return  1; } 
                if( o1.group_nm == o2.group_nm ) { return  0; }
            }

            // 제목 오름차순
            if( this.notice_sort_target == "SUBJECT" && this.notice_sort_direction == "ASC" ) {
                if( o1.noti_info.title  > o2.noti_info.title ) { return  1; } 
                if( o1.noti_info.title  < o2.noti_info.title ) { return -1; } 
                if( o1.noti_info.title == o2.noti_info.title ) { return  0; }
            }

            // 제목 내림차순
            if( this.notice_sort_target == "SUBJECT" && this.notice_sort_direction == "DESC" ) {
                if( o1.noti_info.title  > o2.noti_info.title ) { return -1; } 
                if( o1.noti_info.title  < o2.noti_info.title ) { return  1; } 
                if( o1.noti_info.title == o2.noti_info.title ) { return  0; }
            }

            // 작성자 이름 오름차순
            if( this.notice_sort_target == "USER_NAME" && this.notice_sort_direction == "ASC" ) {
                if( o1.audit_user_name  > o2.audit_user_name ) { return  1; } 
                if( o1.audit_user_name  < o2.audit_user_name ) { return -1; } 
                if( o1.audit_user_name == o2.audit_user_name ) { return  0; }
            }

            // 작성자 이름 내림차순
            if( this.notice_sort_target == "USER_NAME" && this.notice_sort_direction == "DESC" ) {
                if( o1.audit_user_name  > o2.audit_user_name ) { return -1; } 
                if( o1.audit_user_name  < o2.audit_user_name ) { return  1; } 
                if( o1.audit_user_name == o2.audit_user_name ) { return  0; }
            }

            // 작성일 오름차순
            if( this.notice_sort_target == "TIME" && this.notice_sort_direction == "ASC" ) {
                if( new Date(o1.noti_info.start_date).getTime()  > new Date(o2.noti_info.start_date).getTime() ) { return  1; } 
                if( new Date(o1.noti_info.start_date).getTime()  < new Date(o2.noti_info.start_date).getTime() ) { return -1; } 
                if( new Date(o1.noti_info.start_date).getTime() == new Date(o2.noti_info.start_date).getTime() ) { return  0; }
            }

            // 작성일 내림차순
            if( this.notice_sort_target == "TIME" && this.notice_sort_direction == "DESC" ) {
                if( new Date(o1.noti_info.start_date).getTime()  > new Date(o2.noti_info.start_date).getTime() ) { return -1; } 
                if( new Date(o1.noti_info.start_date).getTime()  < new Date(o2.noti_info.start_date).getTime() ) { return  1; } 
                if( new Date(o1.noti_info.start_date).getTime() == new Date(o2.noti_info.start_date).getTime() ) { return  0; }
            }

            return 0;
        })
    }

    /**
     * 검색 ON / OFF
     */
    searchOnOff(flag : boolean) : void {

        this.is_search_open = flag;

        if( flag == false ) {
            this.search_query = "";
            this.search_query_temp = "";

            const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
            search_info.search_query = "";
            this.doSetGroupNoticeSearchInfo?.(search_info);

            this.getNotice();
        }
    }

    /**
     * 검색 keydown
     */
    searchKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }
        
        this.search();
    }

    /**
     * 검색
     */
    search() : void {
        this.search_query = this.search_query_temp.trim();

        const search_info : GroupNoticeSearchInfo = JSON.parse(JSON.stringify(this.group_notice_search_info));
        search_info.search_query = this.search_query;
        this.doSetGroupNoticeSearchInfo?.(search_info);

        this.getNotice();
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#group_notice_scroll').mCustomScrollbar('destroy');

    	this.setScroll();
    }    
}
