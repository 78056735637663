var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section_ce_fix"},[_c('resize-observer',{on:{"notify":_vm.handleResize}}),_c('div',{staticClass:"bt_scheduleBox",attrs:{"id":"bt_scheduleBoxNoti"}},[(_vm.b_isNoticeCreateFlag)?_c('input',{staticClass:"bt_schedule",attrs:{"type":"button","id":"btnAddNotification"},on:{"click":_vm.ckeditor4}}):_vm._e()]),_c('div',{staticClass:"title_box"},[_c('a',{staticClass:"bt_back",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.movePrevPage.apply(null, arguments)}}},[_c('span',{staticClass:"blind"},[_vm._v("이전화면 가기")])]),_c('h3',{staticClass:"title_doc",attrs:{"id":"viewTitle"}},[_vm._v("공지사항")]),_c('a',{staticClass:"schBtn",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.searchOnOff(true)}}},[_vm._v("검색하기")])]),_c('SearchBar',{attrs:{"is_search_open":_vm.is_search_open,"placeholder":'공지사항을 검색하세요',"search_query":_vm.search_query},on:{"searchOff":_vm.searchOnOff,"query":(value) => _vm.search_query_temp = value,"search":_vm.search,"searchKeyDown":_vm.searchKeyDown}}),_c('div',{staticClass:"section_scroll",attrs:{"id":"noticeScroll"}},[_c('div',{staticClass:"viewGroup"},[_c('div',{staticClass:"schedule_box"}),_c('div',{staticClass:"fixedDiv"},[_c('ul',{staticClass:"sortHeader groupNoticeHeader",attrs:{"id":""}},[_vm._m(0),_c('li',{staticClass:"num"},[_vm._v("No")]),_c('li',{staticClass:"group headerSort",class:{
                            sortOff  : _vm.notice_sort_target != 'GROUP_TEAM',
                            sortUp   : _vm.notice_sort_target == 'GROUP_TEAM' && _vm.notice_sort_direction == 'ASC', 
                            sortDown : _vm.notice_sort_target == 'GROUP_TEAM' && _vm.notice_sort_direction == 'DESC', 
                        },on:{"click":_vm.groupTeamSort}},[_c('span',[_vm._v(" "+_vm._s(_vm.isHoduH(_vm.scope_group_team_option.biz_type ? _vm.scope_group_team_option.biz_type : '') ? '아파트 & 동' : '그룹 & 팀')+" ")])]),_c('li',{staticClass:"title headerSort",class:{
                            sortOff  : _vm.notice_sort_target != 'SUBJECT',
                            sortUp   : _vm.notice_sort_target == 'SUBJECT' && _vm.notice_sort_direction == 'ASC', 
                            sortDown : _vm.notice_sort_target == 'SUBJECT' && _vm.notice_sort_direction == 'DESC', 
                        },on:{"click":_vm.subjectSort}},[_c('span',[_vm._v("제목")])]),_c('li',{staticClass:"writtenBy headerSort",class:{
                            sortOff  : _vm.notice_sort_target != 'USER_NAME',
                            sortUp   : _vm.notice_sort_target == 'USER_NAME' && _vm.notice_sort_direction == 'ASC', 
                            sortDown : _vm.notice_sort_target == 'USER_NAME' && _vm.notice_sort_direction == 'DESC', 
                        },on:{"click":_vm.userNameSort}},[_c('span',[_vm._v("작성자")])]),_c('li',{staticClass:"time headerSort",class:{
                            sortOff  : _vm.notice_sort_target != 'TIME',
                            sortUp   : _vm.notice_sort_target == 'TIME' && _vm.notice_sort_direction == 'ASC', 
                            sortDown : _vm.notice_sort_target == 'TIME' && _vm.notice_sort_direction == 'DESC', 
                        },on:{"click":_vm.timeSort}},[_c('span',[_vm._v("작성일")])]),_vm._m(1)])]),_c('div',{staticClass:"content",attrs:{"id":"group_notice_scroll"}},[_c('ul',{staticClass:"notiListUl"},_vm._l((_vm.notice_data),function(lo_data,index){return _c('li',{key:index,class:{
                        new: !lo_data.read_flag
                        }},[_vm._m(2,true),_c('p',{staticClass:"num"},[_c('span',[_vm._v(_vm._s(index+1))])]),_c('p',{staticClass:"group"},[_c('span',[_vm._v(_vm._s(lo_data.group_nm))])]),_c('p',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(lo_data.noti_info.title))]),(lo_data.noti_info.files != null && lo_data.noti_info.files.length > 0)?_c('i',{staticClass:"noticeFile_icon"},[_vm._v("파일첨부여부 icon")]):_vm._e()]),_c('p',{staticClass:"writtenBy"},[_c('span',[_vm._v(_vm._s(lo_data.audit_user_name && lo_data.audit_user_name.length > 0 ? lo_data.audit_user_name : '-'))])]),_c('p',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(lo_data.noti_info.start_date))])]),_c('p',{staticClass:"status"},[_c('a',{on:{"click":function($event){return _vm.showGroupNoticeStatus(lo_data.noti_uid)}}},[_c('span',[_vm._v(_vm._s(lo_data.read_cnt))]),_vm._v(" 명")])]),_c('a',{staticClass:"seeNotice",attrs:{"title":"상세보기"},on:{"click":function($event){$event.preventDefault();return _vm.detailNotice(lo_data)}}},[_vm._v("상세보기")])])}),0)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"notiCheck"},[_c('p',{staticClass:"notiCheck on",attrs:{"id":"notiCheckAll"}},[_c('input',{attrs:{"type":"checkbox","id":""}}),_c('label',{attrs:{"for":"notiCheckAll"}},[_vm._v("전체선택")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"status"},[_c('span',[_vm._v("읽은사람")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"notiCheck"},[_c('input',{staticClass:"input_check",attrs:{"type":"checkbox","id":"notiCheck01","value":""}}),_c('label',{attrs:{"for":"notiCheck01"}})])
}]

export { render, staticRenderFns }