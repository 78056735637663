
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { Hooper, Slide } from 'hooper';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        Hooper, Slide
    },
})
export default class HomeResidentCarRequestModal extends Mixins(VueHoduCommon) {

    // this.$refs.hooperSlide 사용
    $refs !: { hooperSlide : HTMLFormElement; }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_resident_car_request_modal_info !: hodu_home_modal_info.HomeResidentCarRequestModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeResidentCarRequestModalInfo ?: (params : hodu_home_modal_info.HomeResidentCarRequestModalInfo) => void;

    request_car : any = null;
    comment : string = "";

    view_index : number = 0;
    image_none = require('@/assets/images/contents/im_photoB.gif');

    mounted() : void {
        this.request_car = this.home_resident_car_request_modal_info.car;
    }

    getTitle() : string {
        if( this.request_car == null ) return '';

        if( this.request_car != null && this.request_car.car_info.state == 'REQUEST' ) {
            return `등록요청${ this.request_car.car_info.is_contract == true ? ' (추가차량)' : ''}`;
        }

        if( this.request_car != null && this.request_car.car_info.state == 'APPROVE' && this.request_car.car_info.is_contract == false ) {
            return '등록완료';
        }

        if( this.request_car != null && this.request_car.car_info.state == 'APPROVE' && this.request_car.car_info.is_contract == true ) {
            return '등록완료';
        }
        
        if( this.request_car != null && this.request_car.car_info.state == 'REJECT' ) {
            return '등록거절';
        }

        return '';
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        this.doSetHomeResidentCarRequestModalInfo?.({ show_modal : false });
    }

    /**
     * 거절
     */
    async reject() : Promise<void> {

        if( this.request_car == null ) return;

        const copy_comment = this.comment.trim();

        // 거절사유 필수
        if( copy_comment.length < 1 ) {
            alert("거절사유를 작성해주세요");
            $('#reject_text').focus();
            return;
        }

        try {
            const request_car = JSON.parse(JSON.stringify(this.request_car));
            request_car.images = request_car.car_info.images;
            request_car.car_info.state = 'REJECT'; 
            request_car.car_info.is_apply = false;
            request_car.car_info.comment = copy_comment;

            const response = await this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${this.request_car.car_id}`, API_METHOD.PUT, request_car);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("입주자 차량 거절 처리 중 오류 발생");
            }

            this.home_resident_car_request_modal_info.callback?.();
            this.closeModal();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 승인
     */
    async approve() : Promise<void> {

        if( this.request_car == null ) return;

        try {

            const request_car = JSON.parse(JSON.stringify(this.request_car));
            request_car.images = request_car.car_info.images;
            request_car.car_info.state = 'APPROVE';
            request_car.car_info.is_apply = true;
            
            const response = await this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${this.request_car.car_id}`, API_METHOD.PUT, request_car);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("입주자 차량 승인 처리 중 오류 발생");
            }

            this.home_resident_car_request_modal_info.callback?.();
            this.closeModal();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 이미지 크게 보기
     */
    showImageBigger(image) : void {
        if( this.$refs.hooperSlide.isSliding == true ) return;
        window.open(`app_images/${image.url}`, '_blank');
    }

    /**
     * 현재 보고있는 인덱스로 세팅
     */
    updateViewIndex(payload : any) : void {
        this.view_index = payload.currentSlide;
    }

    /**
     * 해당 인덱스로 이동
     */
    slideTo(index : number) : void {
        this.view_index = index;
        this.$refs.hooperSlide.slideTo(index);
    }

    /**
     * 거절
     */
    // rejectReuqest() : void {
    //     if( this.request_car == null ) { return; }

    //     this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${this.request_car.car_id}`, API_METHOD.DELETE)
    //         .then((response) => {
    //             console.log(response);
    //             window['residentSearch']();
    //             this.closeModal();
    //         })
    //         .catch((e) => {
    //             this.hodu_error_process(e, false, false);
    //         });
    // }

    /**
     * 승인
     */
    // applyRequest() : void {
    //     if( this.request_car == null ) { return; }

    //     this.request_car.car_info.is_apply = true;

    //     this.hodu_api_call(`api/v1/home/car/${this.scope_group_team_option.biz_id}/${this.request_car.car_id}`, API_METHOD.PUT, this.request_car)
    //         .then((response) => {
    //             console.log(response);
    //             window['residentSearch']();
    //             this.closeModal();
    //         })
    //         .catch((e) => {
    //             this.hodu_error_process(e, false, false);
    //         });
    // }

}
