
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupSurveyTemplateModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class GroupSurveyTemplateModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_survey_template_modal_info !: GroupSurveyTemplateModalInfo;

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetGroupSurveyTemplateModalInfo ?: (params : GroupSurveyTemplateModalInfo) => void;

    mounted() : void {
        $("#exampleText").click(function(){
            $("#group_survey_template_modal .selectUl").toggleClass("on");
        });
    }

    close() : void {
        this.doSetGroupSurveyTemplateModalInfo?.({ show_modal : false });
    }

}
