
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HomeBuildingCreateModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_building_create_modal_info !: hodu_home_modal_info.HomeBuildingCreateModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeBuildingCreateModalInfo ?: (params : hodu_home_modal_info.HomeBuildingCreateModalInfo) => void;

    name : string = "";
    count : number = 2;

    mounted() : void {
        
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetHomeBuildingCreateModalInfo == null ) { return; } 

        this.doSetHomeBuildingCreateModalInfo({
            show_modal : false
        });
    }

    /**
     * 동 추가 
     */
    createBuilding() : void {
        window['createBuilding']({
            key : require('uuid/v4')(),
            name : this.name,
            count : this.count
        });
        this.closeModal();
    }

}
