
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class HomeResidentInfoModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State home_resident_info_modal_info !: hodu_home_modal_info.HomeResidentInfoModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeResidentInfoModalInfo ?: (params : hodu_home_modal_info.HomeResidentInfoModalInfo) => void;

    mounted() : void {
        
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        if( this.doSetHomeResidentInfoModalInfo == null ) { return; }

        this.doSetHomeResidentInfoModalInfo({
            show_modal : false,
            resident : null
        });
    }

}
