
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({

})
export default class FindPassword extends Mixins(VueHoduCommon) {
    
    ware_user_name  : string = "";
    ware_user_email : string = "";

    mounted() : void {

    }

    /**
     * 이름, 이메일 형식 확인
     */
    checkValid() : boolean {
        if( this.ware_user_name == null || this.ware_user_name.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "이름을 입력해 주세요", ["확인"], [() => { $('#ware_username').focus(); }]);
            return false;
        }

        if( this.ware_user_email == null || this.ware_user_email.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "이메일을 입력해 주세요", ["확인"], [() => { $('#ware_email').focus(); }]);
            return false;
        }

        // 개발 아이디가 아니라면 이메일 형식 체크
        if( !( this.ware_user_email.trim() == "3" || this.ware_user_email.trim() == "4" || this.ware_user_email.trim() == "5" || 
               this.ware_user_email.trim() == "6" || this.ware_user_email.trim() == "7" ) ) {
            
            // 이메일 형식 체크
            if(! /\S+@\S+\.\S+/.test(this.ware_user_email.trim()) ){
                this.hodu_show_dialog("alert", "이메일 형식을 확인해주세요", ["확인"], [() => { $('#ware_email').focus(); }]);
                return false;
            }
        }

        return true;
    }

    /**
     * 비밀번호 찾기
     */
    findPassword() : void {
        const vue = this;

        if( this.checkValid() == false ) {
            return;
        }

        this.hodu_api_call(`api/v1/auth/find`, API_METHOD.POST, {
            user_name : this.ware_user_name.trim(),
            user_email : this.ware_user_email.trim()
        }).then((response) => {
            console.log(response);
            vue.hodu_show_dialog('success', response.data.msg, ['확인'], [() => { vue.movePrevPage(); }]);
        })
        .catch((e) => {
            this.hodu_error_process(e, true, false);
        });
    }

}
