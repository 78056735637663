import {GetterTree, MutationTree , ActionTree} from "vuex";
import { t_event, t_event_file, t_event_location, t_event_vote, t_event_reply } from "@/model/event";

import { CRUD_TYPE, SHARE_OPTION } from '@/mixin/VueHoduCommon';

export const namespaced = true;

export interface EventInfoState {
    event                     : t_event;
    event_crud_type           : CRUD_TYPE;
    is_first_repeat_event     : boolean;               // 반복 일정에서 가장 첫 번째 일정 인지의 여부
    event_original_date       : EventOriginalDate;     // 반복 일정의 원본 Date
    repeat_modify_action      : string;                // 반복 수정, 삭제인지 구분 [수정 or 삭제]
    location_search_query     : string;                // 장소검색 쿼리
    selected_location         : t_event_location;      // 선택된 장소
    event_image_preview_info ?: EventImagePreviewInfo; // 이미지 미리보기 정보
    event_share_info          : EventShareInfo;        // 일정 공유 정보
    vote                      : t_event_vote;
    vote_end_date_limit       : Date;                  // 투표 종료일 한계치
    vote_modify_index         : number;                // 확인 / 수정 중인 투표 index
    is_moved_by_short_create  : boolean;               // 일정 간편 작성에서 들어온것인지 여부 
}

export interface EventOriginalDate {
    original_start : Date; // 반복 일정의 원본 start
    original_end   : Date; // 반복 일정의 원본 end
}

export interface EventImagePreviewInfo {
    selected_index        ?: number;
    files                 ?: t_event_file[];
    type                  ?: "EVENT" | "CHAT"; 
    reply_delete_function ?: Function;
    js_event              ?: any;
    reply                 ?: t_event_reply;
    dayIndex              ?: number;
    reply_index           ?: number;    
}

export interface EventShareInfo {
    share_option : SHARE_OPTION;
    user_ids : number[];
    group_ids : number[];
    team_ids : number[];
    group_user_ids : number[][];
    team_user_ids : number[][];
}

export const state: EventInfoState = {
    event : {
        event_id : "",
        calendar_id : "",
        event_type : "",
        event_sub_type : "",
        user_id : 0,
        group_id : 0,
        team_id : 0,
        audit_created : new Date(),
        audit_modified : new Date(),
        audit_deleted : null,
        audit_delete_flag : false,
        audit_user_id : 0,
        subscribe_users : [],
        subscribe_groups: [],
        subscribe_teams : [],
        event_data : {
            version : "1",
            title : "",
            schedule_date : {
                start : new Date(),
                end : new Date(),
                recurrence_end : new Date(),
                isAllDay : false,
                isContinuos : false,
                lunar_yn : false,
                isIgnore : false
            },
            attend : false,
            contacts : [{
                name : "",
                tel : ""
            }],
            vote : [],
            is_private : false,
        },
        duration : null,
        last_update_tag : ""
    },
    event_crud_type : CRUD_TYPE.NONE,
    is_first_repeat_event : false,
    event_original_date : {
        original_start : new Date(),
        original_end : new Date()
    },
    repeat_modify_action : "",
    location_search_query : "",
    selected_location : {
        place : "",
        tel : "",
        post_code : "",
        address : "",
        address_old : "",
        geo : {
            longitude : "",
            latitude : ""
        }
    },
    event_share_info : {
        share_option : SHARE_OPTION.SHARE,
        user_ids : [],
        group_ids : [],
        team_ids : [],
        group_user_ids : [],
        team_user_ids : []
    },
    vote : {
        "title": "",
        "end_date": new Date(),
        "items_type": "T",
        "items" : [],
        "secret": false,
        "multiple": false,
        "ing_report": true,
        "is_end": false
    },
    vote_end_date_limit : new Date(),
    vote_modify_index : -1,
    is_moved_by_short_create : false,
};

// getters
export const getters: GetterTree<EventInfoState, any> = {

    getEvent: (state) => {
        return state.event;
    },

    getEventCrudType: (state) => {
        return state.event_crud_type;
    },

    isFirstRepeatEvent: (state) => {
        return state.is_first_repeat_event;
    },

    getEventOriginalDate: (state) => {
        return state.event_original_date;
    },

    getRepeatModifyAction: (state) => {
        return state.repeat_modify_action;
    },

    getLocationSearchQuery: (state) => {
        return state.location_search_query;
    },

    getSelectedLocation: (state) => {
        return state.selected_location;
    },

    getEventImagePreviewInfo: (state) => {
        return state.event_image_preview_info;
    },

    getEventShareInfo: (state) => {
        return state.event_share_info;
    },

    getVote: (state) => {
        return state.vote;
    },

    getVoteEndDateLimit: (state) => {
        return state.vote_end_date_limit;
    },

    getVoteModifyIndex: (state) => {
        return state.vote_modify_index;
    },

    isMovedByShortCreate: (state) => {
        return state.is_moved_by_short_create;
    },

};

// action
export const actions: ActionTree<EventInfoState, EventInfoState> = {

    doSetEvent: ({commit, state}, event : t_event) => {
        console.log("actions.doSetEvent");
        commit("setEvent", event);
    },

    doSetIsFirstRepeatEvent: ({commit, state}, is_first_repeat_event : string) => {
        console.log("actions.doSetIsFirstRepeatEvent");
        commit("setIsFirstRepeatEvent", is_first_repeat_event);
    },

    doSetEventOriginalDate: ({commit, state}, event_original_date : EventOriginalDate) => {
        console.log("actions.doSetEventOriginalDate");
        commit("setEventOriginalDate", event_original_date);
    },

    doSetRepeatModifyAction: ({commit, state}, repeat_modify_action : string) => {
        console.log("actions.doSetRepeatModifyAction");
        commit("setRepeatModifyAction", repeat_modify_action);
    },

    doSetLocationSearchQuery: ({commit, state}, location_search_query : string) => {
        console.log("actions.doSetLocationSearchQuery");
        commit("setLocationSearchQuery", location_search_query);
    },

    doSetSelectedLocation: ({commit, state}, seleted_location : t_event_location) => {
        console.log("actions.doSetSelectedLocation");
        commit("setSelectedLocation", seleted_location);
    },

    doSetEventImagePreviewInfo: ({commit, state}, event_image_preview_info : EventImagePreviewInfo) => {
        console.log("actions.doSetEventImagePreviewInfo");
        commit("setEventImagePreviewInfo", event_image_preview_info);
    },

    doSetEventShareInfo: ({commit, state}, event_share_info : EventShareInfo) => {
        console.log("actions.doSetEventShareInfo");
        commit("setEventShareInfo", event_share_info);
    },

    doSetVote: ({commit, state}, vote : t_event_vote) => {
        console.log("actions.doSetVote");
        commit("setVote", vote);
    },

    doSetVoteEndDateLimit: ({commit, state}, vote_end_date_limit : Date ) => {
        console.log("actions.doSetVoteEndDateLimit");
        commit("setVoteEndDateLimit", vote_end_date_limit);
    },

    doSetVoteModifyIndex: ({commit, state}, vote_modify_index : number ) => {
        console.log("actions.doSetVoteModifyIndex");
        commit("setVoteModifyIndex", vote_modify_index);
    },

    doSetEventCrudType: ({commit, state}, event_crud_type : CRUD_TYPE) => {
        console.log("actions.doSetEventCrudType");
        commit("setEventCrudType", event_crud_type);
    },

    doSetIsMovedByShortCreate: ({commit, state}, is_moved_by_short_create : boolean) => {
        console.log("actions.doSetIsMovedByShortCreate");
        commit("setIsMovedByShortCreate", is_moved_by_short_create);
    },

};

// mutation
export const mutations: MutationTree<EventInfoState> = {

    setEventInfo: (state, payload : t_event) => {
        console.log(`mutations.setEventInfo ${JSON.stringify(payload)}`);
        state.event = payload;
    },

    setEvent: (state, payload : t_event) => {
        console.log(`mutations.setEvent ${JSON.stringify(payload)}`);
        state.event = payload;

        if( sessionStorage != null ) {
            sessionStorage.setItem("event_info", JSON.stringify(payload));
        }
    },

    setIsFirstRepeatEvent: (state, payload : boolean) => {
        console.log(`mutations.setIsFirstRepeatEvent ${JSON.stringify(payload)}`);
        state.is_first_repeat_event = payload;

        if( sessionStorage != null ) {
            sessionStorage.setItem("is_first_repeat_event", JSON.stringify(payload));
        }
    },

    setEventOriginalDate: (state, payload : EventOriginalDate) => {
        console.log(`mutations.setEventOriginalDate ${JSON.stringify(payload)}`);
        state.event_original_date.original_start       = payload.original_start;
        state.event_original_date.original_end         = payload.original_end;

        if( sessionStorage != null ) {
            sessionStorage.setItem("event_original_date", JSON.stringify(payload));
        }
    },

    setRepeatModifyAction: (state, payload : string) => {
        console.log(`mutations.setRepeatModifyAction ${JSON.stringify(payload)}`);
        state.repeat_modify_action = payload;
    },

    setLocationSearchQuery: (state, payload : string) => {
        console.log(`mutations.setLocationSearchQuery ${JSON.stringify(payload)}`);
        state.location_search_query = payload;
    },

    setSelectedLocation: (state, payload : t_event_location) => {
        console.log(`mutations.setSelectedLocation ${JSON.stringify(payload)}`);
        state.selected_location.place       = payload.place;
        state.selected_location.tel         = payload.tel;
        state.selected_location.address     = payload.address;
        state.selected_location.address_old = payload.address_old;

        if( state.selected_location.geo != null ) {
            state.selected_location.geo.longitude = payload.geo == null ? "": payload.geo.longitude;
            state.selected_location.geo.latitude  = payload.geo == null ? "": payload.geo.latitude;
        }
    },

    setEventImagePreviewInfo: (state, payload : EventImagePreviewInfo) => {
        console.log(`mutations.setEventImagePreviewInfo ${JSON.stringify(payload)}`);
        state.event_image_preview_info = payload;
    },

    setEventShareInfo: (state, payload : EventShareInfo) => {
        console.log(`mutations.setEventShareInfo ${JSON.stringify(payload)}`);
        state.event_share_info = payload;
    },

    setVote: (state, payload : t_event_vote) => {
        console.log(`mutations.setVote ${JSON.stringify(payload)}`);

        state.vote.title = payload.title;
        state.vote.end_date = payload.end_date;
        state.vote.items_type = payload.items_type;
        state.vote.secret = payload.secret;
        state.vote.multiple = payload.multiple;
        state.vote.ing_report = payload.ing_report;

        state.vote.items.splice(0, state.vote.items.length);

        const current_vote_count : number = payload.items.length;
        for( let i = 0; i < current_vote_count; i++ ) {
            state.vote.items.push(payload.items[i] as string & Date);
        }
    },

    setVoteEndDateLimit: (state, payload : Date) => {
        console.log(`mutations.setVoteEndDateLimit ${JSON.stringify(payload)}`);
        state.vote_end_date_limit = payload;
    },

    setVoteModifyIndex: (state, payload : number) => {
        console.log(`mutations.setVoteModifyIndex ${JSON.stringify(payload)}`);
        state.vote_modify_index = payload;
    },

    setEventCrudType: (state, payload : CRUD_TYPE) => {
        console.log(`mutations.setEventCrudType ${JSON.stringify(payload)}`);
        state.event_crud_type = payload;

        if( sessionStorage != null ) {
            sessionStorage.setItem("event_crud_type", JSON.stringify(payload));
        }
    },

    setIsMovedByShortCreate: (state, payload : boolean) => {
        console.log(`mutations.setIsMovedByShortCreate ${JSON.stringify(payload)}`);
        state.is_moved_by_short_create = payload;

        if( sessionStorage != null ) {
            sessionStorage.setItem("is_moved_by_short_create", JSON.stringify(payload));
        }
    },
};