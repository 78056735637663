
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { ginus_exception_log } from '@/model/osm';

@Component({
    components: {

    }
}) export default class Exception extends Mixins(VueHoduCommon) {

    os_tab : string = 'ALL';
    version_tab : string = 'ALL'; 
    exception_version_list : string[] = [];
    exception_list : ginus_exception_log[] = [];
    search_type : string = 'ALL';
    search_query : string = "";
    user_search_query : string = "";

    mounted() : void {
        this.getException();

        var lo_thread    = null;
        var exceptionId  = 0;

        // 스크롤시 thead가 따라다니도록
        const exception_wrap : HTMLElement | null = document.getElementById("exception_wrap");
        if( exception_wrap != null ) {
            exception_wrap.addEventListener("scroll", function(){
                const translate = "translate(0,"+this.scrollTop+"px)";
                const map : HTMLTableSectionElement | null = this.querySelector("thead");
                if( map != null ) {
                    map.style.transform = translate;
                }
            });
        }
        
    }

    /**
     * Exception 조회
     */
    getException(scroll_to_top : boolean = true) : void {
        if( scroll_to_top == true ) { $('#exception_wrap').scrollTop(0); }
        this.hodu_api_call(`api/v1/exception?os=${this.os_tab}&search_type=${this.search_type}&search_query=${this.search_query}&app_version=${this.version_tab}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);

                this.exception_version_list.splice(0, this.exception_version_list.length);
                this.exception_version_list = this.exception_version_list.concat(response.data.data.exception_version_list); 

                this.exception_list.splice(0, this.exception_list.length);
                this.exception_list = this.exception_list.concat(response.data.data.exception_list); 
            })
            .catch((e) => {});
    }

    /**
     * 엔터키 반응해서 검색하도록 설정
     */
    keyUpSearch(event : KeyboardEvent) : void {
        if( event.keyCode != 13 ) { return; }
        this.getException();
    }

    /**
     * 검색 탭 변경
     */
    searchTabChange(event : any) : void {
        this.search_type = event.target.value;
    }

    /**
     * OS 탭 변경
     */
    osTabchange(event : any) : void {
        this.os_tab = event.target.value;
        this.getException();
    }

    /**
     * 버전 탭 변경
     */
    versionChange(event : any) : void {
        this.version_tab = event.target.value;
        this.getException();
    } 

    /**
     * user_id 검색
     */
    // searchUserByUserId(event : KeyboardEvent) : void {
    //     const code : number = event.keyCode;

    //     if( code == 13 ) {
    //         if( this.user_search_query == null || this.user_search_query.length < 1 ) {
    //             alert("user_id를 입력해주세요");
    //             return;
    //         }

    //         let user_id : number 
    //         try {
    //             user_id = Number(this.user_search_query);
    //             if( isNaN(user_id) ) { throw new Exception(); }
    //         } catch(e) {
    //             return;
    //         }

    //         this.hodu_api_call(`api/v1/hodu/user/${user_id}`, API_METHOD.GET)
    //             .then((response) => {
    //                 const textArea : HTMLTextAreaElement = document.createElement("textarea");
    //                 document.body.appendChild(textArea);
    //                 textArea.value = JSON.stringify(response.data.data.user_info);
    //                 textArea.select();
    //                 document.execCommand('copy');
    //                 document.body.removeChild(textArea);
    //                 alert("클립보드에 유저 정보가 복사 되었습니다.");
    //             })
    //             .catch((e) => { if( e.response ) { alert(e.response.data); } });
    //     }
    // }

    /**
     * exception 해결
     */
    solveExceptionReport(exception_id : number) : void {
        this.hodu_api_call(`api/v1/exception/${exception_id}`, API_METHOD.DELETE)
            .then((response) => {
                console.log(response); 
                this.getException(false); 
            })
            .catch((e) => { console.log(e); });
    }

}

