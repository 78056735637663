import VueHoduCommon from '@/mixin/VueHoduCommon';

/**
 * 메시지 알림 noti_type == 'MESSAGE' 처리
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 */
export async function message_notification_process(vue : VueHoduCommon, data : any) {

    if( data.noti_type != 'MESSAGE' ) return;

    // 알림창 열고 끝
    vue.$store.dispatch("MenuInfo/doSetUserProfileOpen", false);
    vue.$store.dispatch("MenuInfo/doSetNotificationListMenuOpen", true);
    vue.$store.dispatch("MenuInfo/doSetPreferencesMenuOpen", false);
    vue.$store.dispatch("MenuInfo/doSetTodoMenuOpen", false);
    vue.$store.dispatch("MenuInfo/doTodoCreateOrUpdate", false);
    vue.$store.dispatch("MenuInfo/doSetEtcMenuOpen", false);

    for( const child of vue.$children ) {
        if ( (child as VueHoduCommon).COMPONENT_NAME == "RightArea" ) {

            child.$nextTick(() => {
                for( const right_child of child.$children ) {

                    if( (right_child as VueHoduCommon).COMPONENT_NAME == "RightNotificationList" ) {
                        right_child.$nextTick(() => {

                            let group_name = "";
                            let group_color = "#477FFF";
                            if( data.group_id > 0 ) {
                                const groups = vue.all_group_data.filter(item => item.group_id == data.group_id);

                                if( groups.length > 0 ) {
                                    group_name = groups[0].group_name;
                                    group_color = groups[0].color;

                                    if( data.team_id > 0 ) {
                                        const teams = groups[0].teams.filter(item => item.team_id == data.team_id);

                                        if( teams.length > 0 ) {
                                            group_name = teams[0].team_name;
                                            group_color = teams[0].color;
                                        }
                                    }
                                }
                            }

                            right_child['read_message_flag'] = true;
                            right_child['message_list'].splice(0, right_child['message_list'].length);
                            right_child['render_message_list'].splice(0, right_child['render_message_list'].length);
                            right_child['message_group_id'] = data.group_id ? Number(data.group_id) : 0;  
                            right_child['message_team_id'] = data.team_id ? Number(data.team_id) : 0;   
                            right_child['message_group_name'] = group_name; // 그룹 or 팀 이름
                            right_child['message_group_color'] = group_color; // 그룹 or 팀 컬러
                            right_child['last_message_uid'] = "";   
                            
                            right_child['is_api_call'] = false;
                            right_child['getMessage']();
                            
                            setTimeout(() => { right_child['setScroll'](); }, 1);
                        });
                        return;
                    }
                }
            });
            break;
        }
    }
}