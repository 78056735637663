
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import { hodu_doc_object } from '../../model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {

    },
})
export default class PatientFileBoxDirList extends Mixins(VueHoduCommon) {

    get computedPatientList() : hodu_doc_object.patient_info[] {

        let patient_infos : hodu_doc_object.patient_info[] = [];

        if( this.patient_info != null ) {
            patient_infos = this.patient_info.filter(item => this.hodu_string_includes(item.user_name, this.search_word) == true);
        }

        return patient_infos;
    }

    @Prop() event_bus !: Vue;

    @Prop() selected_patient !: hodu_doc_object.patient_info | null;

    search_word : string = "";

    mounted() : void {
        
        if( this.event_bus != null ) { this.event_bus.$on('dirListResize', this.handleResize); }

        this.setScroll();

        // 왼쪽 환자 리스트 클릭시 파일함 변경
        // $(".patientUl li").click(function(){

        //     var getIndex = $(this).index();
        //     $(this).siblings().removeClass("on");
        //     $(this).addClass("on");

        //     // 예제, 아무것도 없을 시
        //     if( getIndex == 1 ) {
        //         $(".rightAreaDiv").addClass("nothing");
        //     } else {
        //         $(".rightAreaDiv").removeClass("nothing");
        //     }

        // });

    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        this.$nextTick(() => {
            const title_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const sch_div_2_height : number | undefined = $('.schDiv2').outerHeight();
            // const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

            // @ts-ignore
            $('#patient_filebox_dir_list_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sch_div_2_height ? sch_div_2_height : 0)
            });
        });

    }

    /**
     * 환자 파일함 선택
     */
    selectPatientFileBox(patient : hodu_doc_object.patient_info) : void {
        this.$emit('selectPatientFileBox', patient);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#patient_filebox_dir_list_scroll').mCustomScrollbar('destroy');
        this.setScroll();
    }

    /**
     * 환자 선택 변경
     */
    @Watch('selected_patient')
    changedSelectedPatient() : void {
        this.handleResize();
    }

}

