
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import moment from 'moment';
const AttendanceInfo = namespace('AttendanceInfo');

@Component({
    components: {
        
    },
})
export default class KidsAttendanceMyList extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 달력 틀 computed
     */
    get computedCalendar() : any[] {
        let calendar : any[] = [];

        let month_start = moment(`${this.date_string}.01`).toDate();
        let month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

        const month_start_day = month_start.getDay();
        const month_end_day   = month_end.getDay();

        const default_i = month_start.getDate();
        const end_i     = month_end.getDate();

        for( let i = default_i - month_start_day; i < end_i + (7 - month_end_day); i++ ) {
            const moment_start = moment(month_start);
            const date = moment_start.set('date', moment_start.get('date') + i - 1).toDate();
            const event_key = moment(date).format('YYYYMMDD');
            const map_key = moment(date).format('YYYY-MM-DD');
            
            calendar.push({
                "date" : date,
                "events" : this.event && this.event[event_key] ? this.event[event_key] : [],
                "lunar" : this.lunar_date.get(map_key),
                "holiday" : this.holidays.get(map_key)
            });
        }

        return calendar;
    }

    /**
     * @CalendarInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @CalendarInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트
    
    date_string : string = "";

    kids : any[] = [];
    selected_member_key = null;

    event : any = null;
    lunar_date : Map<string, string> = new Map(); // 음력 Map
    holidays   : Map<string, Object> = new Map(); // 공휴일 Map

    mounted() : void {
        this.event_bus.$on('myListResize', this.handleResize);
        
        this.date_string = moment(this.selected_date).format('YYYY.MM');
        this.datepickerInit();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const schedule_box_height = $('.schedule_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                 - (schedule_box_height ? schedule_box_height : 0);

        // @ts-ignore
        $('#attendance_my_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * attend data 가져오기
     */
    @Watch('date_string')
    async getEmpAttendDataMonthInfo() : Promise<void> {
        await this.$nextTick();

        try {
            let month_start = moment(`${this.date_string}.01`).toDate();
            let month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

            await Promise.all([this.getLunar(month_start, month_end), this.getHolidays(month_start, month_end)]);
            await this.getKids();
            await this.getEvents();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
        }
    }

    /**
     * 해당 부모의 원아 정보 가져오기
     */
    async getKids() : Promise<void> {

        try {
            const partner_key : string = this.getGroupPartnerKey(this.scope_group_id);

            const response = await this.hodu_api_call(`api/v1/kids?partner_key=${partner_key}&search_mode=PARENT`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.kids ) {
                throw new Error("원아 정보 조회 중 오류 발생");
            }

            this.kids.splice(0, this.kids.length);
            this.kids = this.kids.concat(response.data.data.kids);

            // 선택되어질 멤버키 
            if( this.kids.length > 0 && (this.selected_member_key == null || this.kids.filter(kid => kid.member_key == this.selected_member_key).length < 1) ) {
                this.selected_member_key = this.kids[0].member_key;
            }
            else if( this.kids.length < 1 ) {
                this.selected_member_key = null;
                throw new Error("원아 정보가 존재하지 않습니다");
            }

        } catch(e) {
            throw e;
        }

    }

    /**
     * 이벤트(월 달력 일별 근태현황) 조회
     */
    async getEvents() : Promise<void> {
        try {
            const request_date_string = this.date_string.replaceAll(/\./ig, "");

            const today_date_string = moment().format('YYYYMMDD');
            
            const response = await this.hodu_api_call(`api/v1/kids/attend/data/month?group_id=${this.scope_group_id}&month=${request_date_string}&search_mode=USER&today_date_string=${today_date_string}&member_key=${this.selected_member_key}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_month_info ) {
                throw new Error("월달력 일별 근태현황 조회 실패");
            }
            
            for( const event of response.data.data.attend_emp_month_info ) {
                this.event = event.jsonb == null ? [] : JSON.parse(JSON.stringify(event.jsonb));
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 음력 구하기
     */
    async getLunar(start : Date, end : Date) : Promise<void> {
        start = start instanceof Date ? start : new Date(moment(start).format());
        end   = end   instanceof Date ? end   : new Date(moment(end).format());

        const lunar_objs : any[] = await this.hodu_solars_to_lunars(start, end);
        
        for( let lunar_obj of lunar_objs ) {
            this.lunar_date.set(lunar_obj.solar_ymd, lunar_obj.lunar_ymd);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {
        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            this.holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

    }

    /**
     * 날짜 선택
     */
    datepickerInit() : void {

        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const date_moment = moment(dateText);

                vue.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
                vue.doSetSelectedDate(date_moment.toDate());
                vue.date_string = moment(vue.selected_date).format('YYYY.MM');
                
                inst.input.val(vue.date_string);

                // @ts-ignore
                $('#datepicker').datepicker('setDate', vue.selected_date);
            },
        };

        // @ts-ignore
        $('#attendance-my-list-current-date').datepicker(option);
    }

    /**
     * 이전 달로 이동
     */
    async handlePrev() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.add('month', -1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 다음 달로 이동
     */
    async handleNext() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.add('month', 1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 오늘 날짜로 이동
     */
    async handleToday() : Promise<void> {
        
        const date_moment = moment();

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date());
    }

    /**
     * 달력 이동 해야 되는 날짜가 바뀐 것을 감지
     */
    @Watch('go_to_date')
    onDateChange() : void {
        const date_moment = moment(this.go_to_date);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');
    }

    /**
     * 멤버키 변경
     */
    changeMemberKey() : void {
        this.getEmpAttendDataMonthInfo();
    }

    /**
     * 출근 시간
     */
    getStartTimeText(event) : string {
        if( event == null ) return '';
        let start_time = event.start_time;
        return start_time ? this.processTimeText(start_time) : '';
    }

    /**
     * 퇴근 시간
     */
    getEndTimeText(event, date : Date) : string {
        if( event == null ) return '';
        let end_time = event.end_time;
        return end_time ? this.processTimeText(end_time) : '--:--';
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    /**
     * count 총
     */
    getTotalCount() : string {
        if( this.event == null ) return '0';
        return Object.keys(this.event).length.toString();
    }

    /**
     * count 정상
     */
    getNormalCount() : string {

        if( this.event == null ) return '0';

        let count = 0;

        for( const key of Object.keys(this.event) ) {
            if( this.event[key].attend_type == 'NORMAL' ) count++;
        }

        return `${count}`;
    }

    /**
     * count 지각
     */
    getAbsentCount() : string {

        if( this.event == null ) return '0';

        let count = 0;

        for( const key of Object.keys(this.event) ) {
            if( this.event[key].attend_type == 'ABSENT' ) count++;
        }

        return `${count}`;
    }

    /**
     * 근무 안하는날 텍스트 반환 
     */
    getOffText(attend) : string {

        if( attend == null && attend.events == null ) return "휴일";

        const vacation = attend.events.filter(item => item.attend_type == '20');
        const businesstrip = attend.events.filter(item => item.attend_type == '30');

        if( vacation.length > 0 && businesstrip.length > 0 ) {
            return '휴가·출장';
        }

        if( vacation.length > 0 ) {
            return '휴가';
        }

        if( businesstrip.length > 0 ) {
            return '출장';
        }

        return '휴일';
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#attendance_my_list_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

}
