
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { CALENDAR_TYPE, EVENT_SUB_TYPE, OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import TitleBox from '@/components/ui/TitleBox.vue';

import moment from 'moment';

// import { directive as onClickaway } from 'vue-clickaway';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    // directives: {
    //     onClickaway: onClickaway,
    // },
    components: {
        TitleBox
    },
})
export default class WorkPdfModal extends Mixins(VueHoduCommon) {

    /**
     * ModalInfo.State
     */
    @ModalInfo.State show_work_pdf_modal !: boolean;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetShowWorkPdfModal ?: (params : boolean) => void;

    work_type : EVENT_SUB_TYPE = EVENT_SUB_TYPE.REPORT;
    document_type : string = "daily_report_1";

    report_documents : any[] = [
        { "type" : "daily_report_1", "text" : "일일업무일지1", "preview" : "ic_preview_report_1.png" }, 
        { "type" : "daily_report_2", "text" : "일일업무일지2", "preview" : "ic_preview_report_2.png" }, 
        { "type" : "weekly_report_1", "text" : "주간업무일지1", "preview" : "ic_preview_report_3.png" }, 
        { "type" : "weekly_report_2", "text" : "주간업무일지2", "preview" : "ic_preview_report_4.png" }
    ];
    meeting_log_documents : any[] = [
        { "type" : "meeting_log_1", "text" : "회의록1", "preview" : "ic_preview_meeting_log_1.png" }, 
        { "type" : "meeting_log_2", "text" : "회의록2", "preview" : "ic_preview_meeting_log_2.png" }
    ];

    start : Date | null = null;
    end : Date | null = null;

    start_text = "";
    end_text = "";

    title : string = "";
    content : string = "";
    writer : string = "";

    selected_color : string[] = [];
    is_color_all_check : boolean = false;

    first_event_complete : boolean = false;

    beforeMount() : void {
        this.start = moment().set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
        this.end = moment(this.start).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
        this.makeDateText();
    }

    mounted() : void {
        this.datepickerInit();

        if( this.getReportReadPermission() == false ) {
            this.work_type = EVENT_SUB_TYPE.MEETINGLOG;
            this.changeWorkType(this.work_type);
        }

        $('#left_area, #right_area_controller, #header').on('click', (event) => {
            event.preventDefault();
            if( !this.first_event_complete ) {
                this.first_event_complete = true;
                return;
            }
            this.closeModal();
        });        
    }

    beforeDestroy() : void {
        $('#left_area, #right_area_controller, #header').off('click');
    }

    /**
     * datepicker 세팅
     */
    datepickerInit() : void {

        let datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange : '1900:2050',
            onSelect : this.dateChange
        };

        // @ts-ignore
        $('#work_search_sch_from').datepicker(datepicker_option);

        // @ts-ignore
        $('#work_search_sch_to').datepicker(datepicker_option);

    }
    
    /**
     * 날짜 변경
     */
    dateChange(dateText, inst) : void {
        const selected_date = moment(dateText).toDate();

        if( this.document_type.includes('weekly') == false ) {
            inst.input.val(`${this.hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(selected_date, '요일')}`);
        }
        
        if( inst.id == 'work_search_sch_from' ) {
            
            // 일반
            if( this.document_type.includes('weekly') == false ) {
                this.start = moment(selected_date).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                if( this.end == null || this.end.getTime() < this.start.getTime() ) {
                    this.end = moment(selected_date).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                }
            } 
            
            // 주간
            if( this.document_type.includes('weekly') == true ) {
                this.start = moment(selected_date).set('day', 1).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                this.end = moment(this.start).set('date', moment(this.start).get('date') + 4).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                inst.input.val(`${this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.start, '요일')}`);
            }
        }

        if( inst.id == 'work_search_sch_to' ) {

            // 일반 
            if( this.document_type.includes('weekly') == false ) {
                this.end = moment(selected_date).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                if( this.start == null || this.end.getTime() < this.start.getTime() ) {
                    this.start = moment(selected_date).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                }
            }

            // 주간
            if( this.document_type.includes('weekly') == true ) {
                this.start = moment(selected_date).set('day', 1).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
                this.end = moment(this.start).set('date', moment(this.start).get('date') + 4).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
                inst.input.val(`${this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.end, '요일')}`);
            }
        }

        this.makeDateText();
    }
    
    /**
     * 날짜 텍스트 생성
     */
    makeDateText() : void {

        if( this.start != null ) {
            this.start_text = `${this.hodu_date_to_format_string(this.start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.start, '요일')}`;
        }

        if( this.end != null ) {
            this.end_text = `${this.hodu_date_to_format_string(this.end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.end, '요일')}`;
        }

    }

    /**
     * 업무 종류 선택
     */
    changeWorkType(event : any) : void {
        try {
            if( this.work_type == EVENT_SUB_TYPE.REPORT ) {
                this.document_type = this.report_documents[0].type;
            }

            if( this.work_type == EVENT_SUB_TYPE.MEETINGLOG ) {
                this.document_type = this.meeting_log_documents[0].type;
            }
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 문서 종류 선택
     */
    selectDocument(event : any, document_type : string) : void {
        this.document_type = document_type;

        // 날짜 변경
        if( document_type.includes('weekly') == true && this.start && this.end ) {
            this.start = moment(this.start).set('day', 1).set('hour', 0).set("minute", 0).set('second', 0).set('millisecond', 0).toDate();
            this.end = moment(this.start).set('date', moment(this.start).get('date') + 4).set('hour', 23).set("minute", 59).set('second', 59).set('millisecond', 999).toDate();
            this.makeDateText();
        }
    }

    /**
     * 이미지 미리보기
     */
    preview(image_name : string) : void {
        window.open(`/img/preview/${image_name}`, "_blank");
    } 

    /**
     * 해당 컬러가 검색 할 컬러에 포함되어있는지 여부 반환
     */
    isContainColor(color : string) : boolean {
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();
        return (this.selected_color.indexOf(upper_color) > -1);
    }

    /**
     * 검색 컬러 변경
     */
    changeSearchColor(event : any, color : string) : void {
        const checked : boolean = event.target.checked;
        const upper_color : string = this.hodu_hex_color_process(color).toUpperCase();

        const color_index : number = this.selected_color.indexOf(upper_color);

        // 체크 하는 경우
        if( checked == true ) {
            if( color_index < 0 ) { this.selected_color.push(upper_color); }
        }

        // 체크를 해제 하는 경우
        else {
            if( color_index > -1 ) { this.selected_color.splice(color_index, 1); }
        }

        this.is_color_all_check = this.selected_color.length == 20;
    }

    /**
     * 색상 전체 선택 / 선택 해제
     */
    changeColorAllCheck(event : any) : void {
        const checked : boolean = event.target.checked;

        this.selected_color.splice(0, this.selected_color.length);
        if( checked ) {
            for( const color of this.dc_color ) this.selected_color.push(color);
            for( const color of this.lc_color ) this.selected_color.push(color);
        }
    }
    
    /**
     * 검색 타입 초기화
     */
    reset() : void {
        this.work_type = EVENT_SUB_TYPE.REPORT;
        this.document_type = this.report_documents[0].type;

        this.start = null;
        this.end = null;
        this.start_text = "";
        this.end_text = "";

        this.title = "";
        this.content = "";
        this.writer = ""; 

        this.selected_color.splice(0, this.selected_color.length);
        this.is_color_all_check = false;
    }

    /**
     * 검색
     */
    search() : void {
        if( !this.check() ) return;

        if( this.start == null || this.end == null) return;

        // popup open
        let query = "";
        query += `calendar_id=${this.calendar_id}`;
        query += `&event_sub_type=${this.work_type}`;
        query += `&document_type=${this.document_type}`;
        query += `&start=${moment(this.start).utc().format()}`;
        query += `&end=${moment(this.end).utc().format()}`;
        
        if( this.title.length > 0 ) query += `&title=${encodeURIComponent(this.title)}`;
        if( this.content.length > 0 ) query += `&content=${encodeURIComponent(this.content)}`;
        if( this.writer.length > 0 ) query += `&writer=${encodeURIComponent(this.writer)}`; 

        query += `&request_user_id=${this.user_id}`;
        query += `&scope=${this.scope}`;
        query += `&scope_id=${this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id}`;
        if( this.selected_color != null && this.selected_color.length < 20 ) {
            const color_size : number = this.selected_color.length;
             for( let i = 0; i < color_size; i++ ) {
                 if( this.selected_color[i] == null || this.selected_color[i].length < 7 ) {
                     continue;
                 }

                 query += `&color=${encodeURIComponent(this.selected_color[i])}`;
                 if( this.selected_color[i].length == 7 ) { query += `&color=${encodeURIComponent(this.selected_color[i].replace(/#/, "#FF"))}`; }
                 if( this.selected_color[i].length == 9 ) { query += `&color=${encodeURIComponent(this.selected_color[i].replace(/#FF/, "#"))}`; }
             }
        }

        const timezone = -(new Date().getTimezoneOffset());
        query += `&timezone=${timezone ? timezone : 540}`;

        try {
            let type = navigator.appName;
            let country_code = 'ko-KR';
            if (type == 'Netscape') {
                country_code = navigator.language;
            } else {
                country_code = navigator['userLanguage'];
            }
            query += `&country_code=${country_code}`;
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            query += `&country_code=ko-KR`;
        }

        window.open(`/work_pdf.html?${query}`, "work_pdf", "toolbar=0, scrollbars=0, resizable=0, width=" + 1024 + ", height=" + 768);
        this.closeModal();
    }

    /**
     * 옵션 체크
     */
    check() : boolean {

        if( this.start == null ) {
            this.hodu_show_dialog('alert', "날짜를 입력해주세요", ['확인'], [() => document.getElementById('work_search_sch_from')?.focus()]);
            return false;
        }

        if( this.end == null ) {
            this.hodu_show_dialog('alert', "날짜를 입력해주세요", ['확인'], [() => document.getElementById('work_search_sch_to')?.focus()]);
            return false;
        }

        this.title = this.title.trim();
        this.content = this.content.trim();
        this.writer = this.writer.trim();

        return true;
    }

    /**
     * 업무 일지 읽기 권한 체크
     */
    getReportReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "report", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "report", "read");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 회의록 읽기 권한 체크
     */
    getMeetingLogReadPermission() : boolean {
        
        // 그룹 달력
        if( this.scope == OWNER_TYPE.GROUP ) {
            return this.is_group_permmision(this.scope_group_id, "meetinglog", "read");
        }

        // 팀 달력
        else if( this.scope == OWNER_TYPE.TEAM ) {
            return this.is_team_permmision(this.scope_team_id, "meetinglog", "read");
        }

        // 개인 달력
        else {
            return false;
        } 
    }

    /**
     * 모달 닫기
     */
    closeModal() : void {
        this.doSetShowWorkPdfModal?.(false);
    }

}
