var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section_ce_fix"},[_c('div',{staticClass:"title_box"},[_c('a',{staticClass:"bt_back",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.movePrevPage.apply(null, arguments)}}},[_c('span',{staticClass:"blind"},[_vm._v("이전화면 가기")])]),_c('h3',{staticClass:"title_doc",attrs:{"id":"viewTitle"}},[_vm._v("가입승인 리스트")])]),_c('div',{attrs:{"id":"grpApprove"}},[_c('div',{staticClass:"viewGroup"},[_c('div',{staticClass:"schedule_box"},[_c('div',{staticClass:"grp settingBg"},[_c('h3',{staticClass:"selectTitle",attrs:{"id":"grpSelectTitle"}},[_c('span',{staticClass:"txt"},[_vm._v("승인 대기자 리스트")]),_vm._v(" "),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.list.length))]),_c('span',{staticClass:"rest"},[_vm._v(" 명")]),_c('a',{},[_vm._v("그룹 선택하기")])]),_vm._m(0),_c('p',{staticClass:"btn_group"},[_c('a',{staticClass:"rejectBtn input_btn",attrs:{"href":"#","id":""},on:{"click":function($event){$event.preventDefault();return _vm.groupApprovSave('E')}}},[_vm._v("거절")]),_c('a',{staticClass:"input_btn acceptBtn",attrs:{"href":"#","id":""},on:{"click":function($event){$event.preventDefault();return _vm.groupApprovSave('A')}}},[_vm._v("승인")])])]),_c('div',{staticClass:"content",attrs:{"id":""}},[_vm._m(1),_c('div',{},[_c('div',{staticClass:"over_hidden"},[_c('div',{staticClass:"sortHeader approveHeader"},[_c('p',{staticClass:"checkBox",attrs:{"id":""}},[_c('input',{attrs:{"type":"checkbox","id":"selectAll"},domProps:{"checked":_vm.is_allChack},on:{"change":function($event){return _vm.checkAll($event)}}}),_c('label',{attrs:{"for":"selectAll"}},[_vm._v("전체선택")])]),_c('p',{staticClass:"num"},[_vm._v("No")]),_c('p',{staticClass:"userName headerSort",class:{
                                        sortOff  : _vm.approve_sort_target != 'NAME',
                                        sortUp   : _vm.approve_sort_target == 'NAME' && _vm.approve_sort_direction == 'ASC', 
                                        sortDown : _vm.approve_sort_target == 'NAME' && _vm.approve_sort_direction == 'DESC', 
                                    },on:{"click":_vm.sortName}},[_vm._v("이름")]),_c('p',{staticClass:"tel headerSort",class:{
                                        sortOff  : _vm.approve_sort_target != 'TEL',
                                        sortUp   : _vm.approve_sort_target == 'TEL' && _vm.approve_sort_direction == 'ASC', 
                                        sortDown : _vm.approve_sort_target == 'TEL' && _vm.approve_sort_direction == 'DESC', 
                                    },on:{"click":_vm.sortTel}},[_vm._v("전화번호")]),_c('p',{staticClass:"email headerSort",class:{
                                        sortOff  : _vm.approve_sort_target != 'EMAIL',
                                        sortUp   : _vm.approve_sort_target == 'EMAIL' && _vm.approve_sort_direction == 'ASC', 
                                        sortDown : _vm.approve_sort_target == 'EMAIL' && _vm.approve_sort_direction == 'DESC', 
                                    },on:{"click":_vm.sortEmail}},[_vm._v("이메일")]),_c('p',{staticClass:"time headerSort",class:{
                                        sortOff  : _vm.approve_sort_target != 'REQUEST',
                                        sortUp   : _vm.approve_sort_target == 'REQUEST' && _vm.approve_sort_direction == 'ASC', 
                                        sortDown : _vm.approve_sort_target == 'REQUEST' && _vm.approve_sort_direction == 'DESC', 
                                    },on:{"click":_vm.sortRequestDate}},[_vm._v("요청일")])]),_c('div',{staticClass:"grpApproveList"},[_c('ul',{staticClass:"grpApproveUl",attrs:{"id":""}},_vm._l((_vm.list),function(lo_data,index){return _c('li',{key:index},[_c('div',{staticClass:"grpAppListDiv",attrs:{"id":"group_approv_list"}},[_c('p',{staticClass:"grpAppCheck"},[_c('input',{staticClass:"input_check",attrs:{"type":"checkbox","id":`grpAppCheck_${index}`,"value":""},domProps:{"checked":lo_data.checked},on:{"change":function($event){return _vm.checkRow($event, index)}}}),_c('label',{attrs:{"for":`grpAppCheck_${index}`}})]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(index+1))]),_c('span',{staticClass:"userName"},[_vm._v(_vm._s(lo_data.user_name))]),_c('span',{staticClass:"tel"},[_vm._v(_vm._s(lo_data.user_phone_number ? lo_data.user_phone_number : '-'))]),_c('span',{staticClass:"email"},[_vm._v(_vm._s(lo_data.user_email ? lo_data.user_email : '-'))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(lo_data.audit_created))])])])}),0)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dropdown",attrs:{"id":"selectTitleDropdown"}},[_c('ul',[_c('li',[_c('a',{staticClass:"on"},[_vm._v("승인 대기자 리스트")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"inviteMateDiv"},[_c('div',{staticClass:"grp1"},[_c('h2',[_vm._v("멤버초대")]),_c('p',{staticClass:"descript"},[_vm._v("초대하고싶은 멤버에 이름과 전화번호를 입력하신 후 문자보내기 버튼을 클릭하세요. "),_c('span',{staticClass:"descript2"},[_vm._v("(초대받은 멤버는 호두 가입 후 가입승인리스트 > 초대현황리스트에 보여집니다.)")])])]),_c('form',{attrs:{"action":""}},[_c('fieldset',[_c('legend',{staticClass:"over_hidden"},[_vm._v("멤버 관리")]),_c('label',{attrs:{"for":""}},[_vm._v("이름")]),_c('input',{staticClass:"input_txt wrong",attrs:{"type":"text","id":"modalMemRegNm","placeholder":"예) 홍길동"}}),_c('label',{attrs:{"for":""}},[_vm._v("전화번호")]),_c('input',{staticClass:"input_txt",attrs:{"type":"text","id":"modalMemRegPh","placeholder":"010xxxxxxxx"}}),_c('a',{attrs:{"href":"#","id":"modalMemInvateBtn"}},[_vm._v("가입 권유 문자 보내기")])])]),_c('div',{staticClass:"grp2"},[_c('h3',[_vm._v("성공적으로 전송되었습니다!")]),_c('a',{staticClass:"check"},[_vm._v("현황 확인")]),_c('div',{staticClass:"btns"},[_c('a',{staticClass:"close"},[_vm._v("닫기")]),_c('a',{staticClass:"inviteMore"},[_vm._v("다른 멤버 초대")])])])])
}]

export { render, staticRenderFns }