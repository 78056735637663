
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import moment from 'moment';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ScheduleBoxDatepicker extends Mixins(VueHoduCommon) {

    @Prop() id !: string;
    @Prop() start !: Date;
    @Prop() end !: Date;
    @Prop() onSelect !: Function;

    start_value : string = "";
    end_value : string = "";

    mounted() : void {
        this.make_value();

        let datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange : '1900:2050',
            onSelect : (dateText, inst) => {
                inst.input.val(this.createDateFormat(dateText));
                this.onSelect(dateText, inst);
            }
        };

        // @ts-ignore
        $(`#${this.id}_fr_ymd`).datepicker(datepicker_option);

        // @ts-ignore
        $(`#${this.id}_to_ymd`).datepicker(datepicker_option);
    }

    prev() : void {
        this.$emit('prev');
    }

    next() : void {
        this.$emit('next');
    }

    today() : void {
        this.$emit('today');
    }

    createDateFormat(request_date : Date) : string {
        if( request_date == null ) return "";

        if( !(request_date instanceof Date) ) {
            request_date = new Date(moment(request_date).format());
        }

        const year  : string  = `${request_date.getFullYear()}`;
        const month : string = `0${request_date.getMonth() + 1}`.slice(-2);
        const date  : string  = `0${request_date.getDate()}`.slice(-2);

        return `${year}.${month}.${date} ${this.getDayOfWeekByDate(request_date)}`;
    }

    make_value() : void {
        this.start_value = this.createDateFormat(this.start);
        this.end_value = this.createDateFormat(this.end);
    }

    @Watch('start')
    @Watch('end')
    watchStartEnd() {
        this.make_value();
    }

}
