
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import AttendanceTotalListYear from '@/components/attendance/AttendanceTotalListYear.vue';
import AttendanceTotalListMonth from '@/components/attendance/AttendanceTotalListMonth.vue';
import AttendanceDayGridMonth from '@/components/attendance/AttendanceDayGridMonth.vue';
import AttendanceListMonth    from '@/components/attendance/AttendanceListMonth.vue';
import AttendanceListWeek     from '@/components/attendance/AttendanceListWeek.vue';
import AttendanceListDay      from '@/components/attendance/AttendanceListDay.vue';
// import AttendanceMyCalendar   from '@/components/attendance/AttendanceMyCalendar.vue';
import AttendanceMyList       from '@/components/attendance/AttendanceMyList.vue';

import { attendance_enum } from '@/model/attendance';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        AttendanceTotalListYear, AttendanceTotalListMonth,
        AttendanceDayGridMonth, AttendanceListMonth, AttendanceListWeek, AttendanceListDay, /* AttendanceMyCalendar, */ AttendanceMyList,
        ResizeObserver
    },
})
export default class Attendance extends Mixins(VueHoduCommon) {

    /**
     * @AttendanceInfo.State
     */
    @AttendanceInfo.State calendar_view_type !: attendance_enum.ATTENDANCE_CALENDAR_TYPE;

    /**
     * @AttendanceInfo.Action
     */
    @AttendanceInfo.Action doSetCalendarViewType ?: (params : attendance_enum.ATTENDANCE_CALENDAR_TYPE) => void;

    event_bus : Vue = new Vue();

    mounted() : void {
        
    }

    /**
     * 연별 통합 리스트
     */
    isTotalListYear() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.TOTAL_LIST_YEAR;
    }

    /**
     * 월별 통합 리스트
     */
    isTotalListMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.TOTAL_LIST_MONTH;
    }

    /**
     * 월 달력
     */
    isDayGridMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.DAYGRID_MONTH;
    }

    /**
     * 월별 리스트
     */
    isListMonth() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_MONTH;
    }

    /**
     * 주별 리스트
     */
    isListWeek() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_WEEK;
    }

    /**
     * 일별 리스트
     */
    isListDay() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_DAY;
    }

    /**
     * 내 현황 달력
     */
    isMyCalendar() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.CALENDAR;
    }

    /**
     * 내 현황 리스트
     */
    isMyList() : boolean {
        return this.calendar_view_type == attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST;
    }

    /**
     * 달력 변경
     */
    changeCalendar(calendar_type : attendance_enum.ATTENDANCE_CALENDAR_TYPE) : void {
        this.doSetCalendarViewType?.(calendar_type);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        if( this.isDayGridMonth() == true ) this.event_bus.$emit('daygridMonthResize');
        else if( this.isListDay() == true ) this.event_bus.$emit('listDayResize');
        else if( this.isListWeek() == true ) this.event_bus.$emit('listWeekResize');
        else if( this.isListMonth() == true ) this.event_bus.$emit('listMonthResize');
        else if( this.isMyCalendar() == true ) this.event_bus.$emit('myCalendarResize');
        else if( this.isMyList() == true ) this.event_bus.$emit('myListResize');
        else if( this.isTotalListYear() == true ) this.event_bus.$emit('totalListYearResize');
        else if( this.isTotalListMonth() == true ) this.event_bus.$emit('totalListMonthResize');
    }

}
