
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { ImageCropModalInfo } from '@/store/modules/ModalInfo';

import { t_event_file, t_event_location } from '@/model/event';
import { t_company_info } from '@/model/osm';

declare var daum: any;

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalBasicSetting extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;
    
    @Prop() event_bus !: Vue;

    images : t_event_file[] = [];
    image_files : any[] = [];
    descript : string = "";
    phone : string = "";
    web_url : string = "";
    auto_apply_appointment : boolean = false;

    location : t_event_location | null = null;

    async mounted() : Promise<void> {
        if( this.event_bus != null ) { this.event_bus.$on('basicSettingResize', this.handleResize); }
        
        await this.get_hodu_d_info(this.scope_group_team_option.biz_id);

        this.setDataByHospitalInfo();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();

        // @ts-ignore
        $('#hospital_basic_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0)
        });
    }

    /**
     * 병원 기본 정보 저장
     */
    async updateHospitalInfo() : Promise<void> {
        const vue = this;
        
        if( this.hospital_info == null ) { return; }

        /**
         * 이미지 파일이 있을때 => 이미지 임시파일 생성
         */
        console.log(this.image_files);
        if( this.image_files.length > 0 ) {
            
            const form_data : FormData = new FormData();
            
            // FormData 내용 생성
            const image_count : number = this.image_files.length;
            for( let i = 0; i < image_count; i++ ) {
                form_data.append('file', this.image_files[i].file);
            }

            await this.hodu_temp_upload(form_data)
                .then(async(response) => {
                    console.log(response);
                    const temp_files : t_event_file[] = response.data.data.temp_files;

                    // 임시파일 데이터로 객체 대체하기
                    const image_count : number = this.image_files.length;
                    for( let i = 0; i < image_count; i++ ) {
                        vue.images.splice(this.image_files[i].index, 1, temp_files[i]);    
                    }

                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        const body : t_company_info = JSON.parse(JSON.stringify(this.hospital_info.company_info));

        body.images = this.images;
        body.descript = this.descript;
        body.contacts = {
            name : "본원",
            tel : this.phone
        }
        body.web_url = this.web_url;
        body.location = this.location ? this.location : body.location;
        body.auto_apply_appointment = this.auto_apply_appointment;

        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}`, API_METHOD.PUT, body)
            .then((response) => {
                this.hodu_show_dialog('success', '병원 기본 설정 저장 완료', ['확인'], [async() => {
                    await this.get_hodu_d_info(this.scope_group_team_option.biz_id);
                    this.setDataByHospitalInfo();
                }]);
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })

    }

    /**
     * hospital_info 에서 데이터를 꺼내서 사용
     */
    setDataByHospitalInfo() : void {
        if( this.hospital_info == null ) { return; }
        
        this.images.splice(0, this.image_files.length);

        // 이미지 정보가 존재한다면
        if( this.hospital_info.company_info.images != null ) {
            this.images.splice(0, this.images.length);
            for( const image of this.hospital_info.company_info.images ) {
                this.images.push(JSON.parse(JSON.stringify(image)));
            }
        }

        // 병원 기본 정보들이 존재한다면 적용
        this.descript = this.hospital_info.company_info.descript ? this.hospital_info.company_info.descript : '';
        this.phone    = this.hospital_info.company_info.contacts 
                            ? this.hospital_info.company_info.contacts.tel 
                                ? this.hospital_info.company_info.contacts.tel
                                :  ''
                            : '';
        this.web_url  = this.hospital_info.company_info.web_url ? this.hospital_info.company_info.web_url : ''; 
        this.location = this.hospital_info.company_info.location ? this.hospital_info.company_info.location : {
            place : "",
            tel : "",
            post_code : "",
            address : "",
            address_old : "",
            geo : {
                longitude : "",
                latitude : "",
            },
        };
        this.auto_apply_appointment = this.hospital_info.company_info.auto_apply_appointment ? this.hospital_info.company_info.auto_apply_appointment : false;
        
    }

    /**
     * 기본 설정 초기화
     */
    resetBasicSetting() : void {
        this.images.splice(0, this.image_files.length);
        this.images.splice(0, this.images.length);
        this.descript = "";
        this.phone = "";
        this.web_url = "";
        this.location = {
            place : "",
            tel : "",
            post_code : "",
            address : "",
            address_old : "",
            geo : {
                longitude : "",
                latitude : "",
            },
        }
        this.auto_apply_appointment = false;
    }

    /**
     * 이미지 파일 추가
     */
    async addImage(event : any, index : number) : Promise<void> {
        const vue = this;
        const files : File[] = event.target.files;

        // 이미지 파일 추가 (파일 1개만 들어옴)
        await this.hodu_show_indicator();
        await this.fileReaderPromise(files[0])
            .then(async(pe_fr : any) => {

                // onloadend로 들어온 경우
                if( pe_fr == null || this.doSetImageCropModalInfo == null ) {
                    $(event.target).val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (4/3), // 4:3
                    image_url : url,
                    cancel_function : async() => {
                        $(event.target).val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        
                        await this.hodu_show_indicator();

                        const resize_blob : Blob = await vue.hodu_image_resize(cropped_url);
                        
                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = files[0];
                        try{
                            resize_file = await this.hodu_blob_to_file(resize_blob, files[0].name);
                        }catch(e){
                            try {
                                (resize_blob as any).lastModifiedDate = new Date();
                                (resize_blob as any).name = files[0].name;
                                resize_file = (resize_blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }
                        
                        await this.hodu_hide_indicator();

                        // 이미 존재하는 이미지 교체
                        if( vue.images[index] != null ) {
                            
                            vue.images.splice(index, 1, {
                                name: resize_file.name,                // 원본 파일명
                                mimeType: resize_file.type,            // MIME TYPE
                                url: URL.createObjectURL(resize_blob), // 파일 경로
                                size: resize_file.size,                // 파일 크기
                                date: new Date()                       // 저장 날짜
                            });

                            // 이번에 새로추가하고 있었던 파일이라면
                            const exist_file = vue.image_files.filter(item => item.index == index);
                            if( exist_file.length > 0 ) {
                                vue.image_files.splice(vue.image_files.indexOf(exist_file[0]), 1, {
                                    index : index, 
                                    file : resize_file
                                });
                            }

                            // 해당 번호에 새로운 파일이 없었다면 업로드용 파일 객체 담기
                            else {
                                
                                vue.image_files.push({
                                    index : index, 
                                    file : resize_file
                                });
                            }
                            
                        }

                        // 새로운 이미지 추가
                        else {
                            vue.images.push({
                                name: resize_file.name,                // 원본 파일명
                                mimeType: resize_file.type,            // MIME TYPE
                                url: URL.createObjectURL(resize_blob), // 파일 경로
                                size: resize_file.size,                // 파일 크기
                                date: new Date()                       // 저장 날짜
                            });

                            // 업로드용 파일 객체 담기
                            vue.image_files.push({
                                index : ( vue.images.length - 1 ), 
                                file : resize_file
                            });
                        }

                        $(event.target).val("");

                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

    /**
     * 파일 
     */
    removeImageFile(index : number) : void {
        if( this.images[index] == null ) {
            return;
        }

        this.images.splice(index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const image_count : number = this.image_files.length;
        for( let i = (image_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.image_files[i].index > index ) {
                this.image_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.image_files[i].index == index ) {
                this.image_files.splice(i, 1);
                break;
            }
        }
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지가 존재하는 경우 background-image : url(app_images/${this.images[index].url}) 를 반환한다
     */
    getBackgroundImageStyle(index : number) : string {
        if( this.images[index] == null ) { return 'background-image : none;' }
        return `background-image : url(${ this.images[index].url.indexOf('profile') > -1 ? 'app_images/' : '' }${this.images[index].url})`;
    }

    /**
     * 장소 주소 가져오기
     */
    getLocationAddress() : string {
        if( this.location == null ) {
            return "";
        }

        let address = ""; 

        // 도로명 주소가 유효한 경우
        if( this.location.address != null && this.location.address.length > 0 ) {
            address = this.location.address;
        }

        // 지번 주소가 유효한 경우
        else if( this.location.address_old != null && this.location.address_old.length > 0 ) {
            address = this.location.address_old;
        }

        return address;
    }

    /**
     * 장소 주소 입력
     */
    setLocationAddress(event : any) : void {
        if( this.location == null ) { return; }
        
        // 도로명 주소가 유효한 경우
        if( this.location.address != null && this.location.address.length > 0 ) {
             this.location.address = event.target.value.trim();
        }

        // 지번 주소가 유효한 경우
        else if( this.location.address_old != null && this.location.address_old.length > 0 ) {
            this.location.address_old = event.target.value.trim();
        }

        // 둘다 유효하지 않은 경우
        else {
            this.location.address = event.target.value.trim();
            this.location.address_old = event.target.value.trim();
        }

    }

    /**
     * 장소 나머지 주소 가져오기
     */
    // getLocationRemainAddress() : string {
    //     if( this.location == null ) {
    //         return "";
    //     }

    //     return this.location.remain_address ? this.location.remain_address : '';
    // }

    /**
     * 장소 나머지 주소 입력
     */
    // setLocationRemainAddress(event : any) : void {
    //     if( this.location == null ) { return; }
        
    //     this.location.remain_address = event.target.value;
    // }

    /**
     * 주소 검색
     */
    searchAddress() : void {
        
        const width : number = 500;
        const height : number = 600;

        new daum.Postcode({
            width : width,
            height : height,
            oncomplete: async(data) => {
                // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
                // 예제를 참고하여 다양한 활용법을 확인해 보세요.
                // https://spi.maps.daum.net/postcode/guidessl
                console.log(data);

                // location이 null인 경우
                if( this.location == null ) {
                    this.location = {
                        place : "",
                        tel : "",
                        post_code : "",
                        address : "",
                        address_old : "",
                        geo : {
                            longitude : "",
                            latitude : "",
                        },
                    };
                }

                // 지번주소, 도로명주소
                // const building_name      = data.buildingName;
                const user_selected_type = data.userSelectedType;
                const address            = data.jibunAddress;
                const road_address       = data.roadAddress;

                // 건물명
                // if( building_name != null && building_name.length > 0 ) {
                //     this.location.place = building_name;
                // }

                // 지번 주소를 클릭한 경우
                if( user_selected_type == 'J' && address != null && address.length > 0 ) {
                    this.location.address_old = address;
                    this.location.address = address;  
                }

                // 도로명 주소를 클릭한 경우
                if( user_selected_type == 'R' && road_address != null && road_address.length > 0 ) {
                    this.location.address_old = road_address;
                    this.location.address = road_address;  
                }
                
                // 나온 정보로 검색
                await this.hodu_api_call(`api/v1/location?query=${this.location.address + ' ' + this.scope_group_team_option.group_team_name}&page=${1}&size=15&category_group_code=HP8`, API_METHOD.GET)
                    .then(async(response) => {
                        
                        console.log(response);

                        // 검색결과가 없는 경우
                        if( response.data.data.documents.length < 1 ) {
                            this.hodu_show_dialog('cancel', '장소 정보를 찾는데 문제가 발생 했습니다\n관리자에게 문의 해주세요', ['확인']);
                            return;
                        }

                        // 정보 세팅
                        if( this.location != null && this.location.geo != null ) {
                            this.location.geo.latitude  = response.data.data.documents[0].y;
                            this.location.geo.longitude = response.data.data.documents[0].x;
                        }

                        $('.addr2').focus();

                    })
                    .catch(async(e) => {
                        this.hodu_error_process(e, false, false, true);
                    });

            }
        }).open({ 
            q : this.getLocationAddress(),
            left: (window.screen.width / 2) - (width / 2),
            top: (window.screen.height / 2) - (height / 2)
        });

    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#hospital_basic_setting_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
