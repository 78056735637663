
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { t_notification } from '@/model/osm';

const EtcInfo    = namespace('EtcInfo');
const dateFormat = require('dateformat');

declare var CKEDITOR: any;
@Component({
    components : {}
})
export default class HoduNoticeCreate extends Mixins(VueHoduCommon) {
    @EtcInfo.State hodu_noti_uid !: string;
    is_created : boolean = true;

    o_notice : t_notification = {
        noti_uid : "",
        noti_type : "",
        noti_sub_type: "",
        group_id: 0,
        team_id: 0,
        team_ids: [],
        group_ids: [],
        user_ids: [],
        noti_info: {
            title: "",
            start_date: null,
            end_date: null,
            calendar_id: "",
            event_id: "",
            event_sub_type: "",
            shared: "",
            scope: "",
            scope_id: 0
        },
        noti_data: {
            body: "",
            body_type: null
        },
        meta: "",
        audit_created: null,
        audit_modified: null,
        audit_deleted: null,
        audit_delete_flag: false,
        audit_user_id: 0
    };    

    async mounted() : Promise<void> {
        const vue = this;

        // 리사이즈시 322를 제외한 section_ce하이트로 하단에 하이트 변경
        let height_ori = $("#section_ce").outerHeight();
        let height = (height_ori == null ? 0 : height_ori) - 332;

        // 공지사항 수정일때 사용함..
        if ( this.hodu_noti_uid ) {
            this.is_created = false;
        }
        else {
            this.is_created = true;
            this.o_notice.noti_info.start_date = new Date();
            this.setNoticeStartYmdDisplay();
        }

        // date_picker
        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                vue.o_notice.noti_info.start_date = new Date(dateText)
                vue.setNoticeStartYmdDisplay();
            },
        };

        // @ts-ignore
        $('#noti_datepicker').datepicker(option);

        // CKEDITOR
        setTimeout(() => {
            $("#cke_modal_noti_contents .cke_contents").attr('style', 'height : ' + height + 'px !important');
        }, 100);
        
        await CKEDITOR.on( 'instanceReady', function( e ) {
            let editor = e.editor,body = CKEDITOR.document.getBody();
            editor.on( 'focus', function() {
                // texArea에 포커스가 있을때 datePicker none 처리
                $('#ui-datepicker-div').css("display", "none");
            });
        });

        // 공지 상세
        this.getNoticeDetatil();
    }

    /**
     * 공지 시작일 표시
     */
    setNoticeStartYmdDisplay() : void {
        $('#noti_datepicker').val(dateFormat(this.o_notice.noti_info.start_date, "yyyy.mm.dd"));
    }    

    /**
     * 공지사항 상세화면 조회
     */
    async getNoticeDetatil() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/groupsweb/getNoticeDetail/0/0?uid=${this.hodu_noti_uid}`, API_METHOD.GET, {}, false)
            .then(async(response) => {
                if ( this.is_created == false ) {
                    // 데이터가 없을경우
                    if ( !response.data.data.noti_data ) {
                        vue.movePrevPage();
                        return;
                    }
                    else {
                        this.o_notice = response.data.data.noti_data;
                        this.setCkeditor();
                        this.setNoticeStartYmdDisplay();
                    }
                }
                else {
                    this.setCkeditor();
                }

                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, true);
            });        
    }    

    /**
     * CKEditor 세팅
     */
    async setCkeditor() : Promise<void> {
        let ckeditorOption : any = {
            uploadUrl: "api/v1/upload/noticeUpload?type=image",  // 이게 드래그 드롭을 위한 URL
            filebrowserUploadUrl: "api/v1/upload/noticeUpload?type=file",  // 파일업로드를 위한 URL
            filebrowserImageUploadUrl : "api/v1/upload/noticeUpload?type=image",//"/hoduweb/upload/ckeditorImg.do",
            filebrowserUploadMethod : 'xhr',
            fileTools_requestHeaders: {
                'X-Requested-With': 'XMLHttpRequest',
                'device-uid': sessionStorage.getItem("device_uid"),
                'authorization': sessionStorage.getItem("session_token"),
            }
        };

        ckeditorOption.resize_maxWidth = 652;

        await CKEDITOR.replace('modal_noti_contents', ckeditorOption);
        CKEDITOR.instances.modal_noti_contents.setData(this.o_notice.noti_data.body);
    }    

    /**
     * 저장
     */
    async actionSave() : Promise<void> {
        const vue = this;

        let content_body = CKEDITOR.instances.modal_noti_contents.getData();

        if ( this.o_notice.noti_info.title.trim().length < 1 ) {
            alert('제목을 입력하세요');
            return;
        }

        if ( content_body.trim().length < 1 ) {
            alert('내용을 입력하세요');
            return;
        }

        // 등록된 이미지 찾기
        let HTML = content_body;
        //let re = /<img src="(.*?)"/gi;
        let re = /src="(.*?)"/gi;
        let result = HTML.match(re);
        let replace_images : string[] = [];

        if ( result && result.length > 0 ) {
            for ( let i = 0; i < result.length; i++ ) {
                let img = result[i].replace('src="', '').replace('"', '');

                if ( Number(img.indexOf("/app_images/temp_uploads/")) > -1 ) {
                    replace_images.push(img);
                }
            }
        }

        this.o_notice.noti_data.body = content_body;

        if ( this.is_created ) {
            this.o_notice.noti_type = "HODU_NOTICE";
            this.o_notice.noti_data.body_type = "HTML";
            this.o_notice.noti_info.end_date = new Date("2050.12.31");
        }

        let req_data = {
            notification: this.o_notice,
            web_notice: "Y",
            replace_images: replace_images
        }

        await this.hodu_api_call(`api/v1/notification/notice/HODU`, this.is_created ? API_METHOD.POST : API_METHOD.PUT, req_data)
            .then(async(response) => {
                if ( this.is_created ) {
                    
                }

                vue.movePrevPage();
                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }    
}
