
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const GroupInfo = namespace('GroupInfo');

import { GroupAddFileModalNewInfo, GroupFileboxMoveModalInfo } from '@/store/modules/ModalInfo';
import { hodu_cookie } from '@/lib/HoduCookie';

const dateFormat = require('dateformat');

import { t_event_file } from '@/model/event';

import GroupFileBoxRecursion from '@/components/GroupFileBoxRecursion.vue';

import { ResizeObserver } from 'vue-resize';

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

@Component({
    components: {
        GroupFileBoxRecursion, ResizeObserver
    },
})
export default class GroupFileBoxNew extends Mixins(VueHoduCommon) {

    get computedDirsAndFiles() {
        let target_list : any[] = [];

        let target_dirs = Array.from(this.dirs, (item => Object.assign(item, { "list_type" : "DIR" })));
        let target_files = Array.from(this.files, (item => Object.assign(item, { "list_type" : "FILE" })));

        target_list = target_list.concat(target_dirs);
        target_list = target_list.concat(target_files);

        if( this.is_card_view == false && this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list.sort((o1, o2) => {

            const o1_list_type = o1.list_type;
            const o2_list_type = o2.list_type;

            if( o1_list_type != o2_list_type ) {
                if( o1_list_type == 'DIR' && o2_list_type == 'FILE' ) return -1;
                else if( o1_list_type == 'FILE' && o2_list_type == 'DIR' ) return 1;
            }

            const is_fave = o1.is_favorite;
            const is_fave2 = o2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            // 디렉토리, 파일 이름 기준 정렬
            if( this.sort_type == 'DIR_NAME' ) {

                let name = o1_list_type == 'DIR' ? o1.dir_name : o1.file_name;
                let name2 = o2_list_type == 'DIR' ? o2.dir_name : o2.file_name;
                
                // 디렉토리 이름이 같다면
                if( name == name2 ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return name > name2 ? 1 : -1;
                }

                return name < name2 ? 1 : -1;
            }

            // 유저 이름 기준 정렬
            if( this.sort_type == 'USER_NAME' ) {
                
                // 유저 이름이 같다면
                if( o1.user_name == o2.user_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return o1.user_name > o2.user_name ? 1 : -1;
                }

                return o1.user_name < o2.user_name ? 1 : -1;
            }

            if( this.sort_type == 'FILE_COUNT' ) {
                
                const o1_file_size = o1_list_type == 'DIR' ? this.dirFileSizeNumber(o1) : o1.file_size;
                const o2_file_size = o2_list_type == 'DIR' ? this.dirFileSizeNumber(o2) : o2.file_size;

                if( o1_file_size == o2_file_size ) return 0;

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return o1_file_size > o2_file_size ? 1 : -1;
                }

                return o1_file_size < o2_file_size ? 1 : -1;
                
            }

            // 업데이트 날짜 기준 정렬
            if( this.sort_type == 'UPDATE' ) {
                
                // 날짜가 같다면
                if( new Date(o1.audit_modified).getTime() == new Date(o2.audit_modified).getTime() ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return new Date(o1.audit_modified).getTime() > new Date(o2.audit_modified).getTime() ? 1 : -1;
                }

                return new Date(o1.audit_modified).getTime() < new Date(o2.audit_modified).getTime() ? 1 : -1;
            }

            return 0;
        });

        return target_list;
    }

    /**
     * 파일함 디렉토리 트리
     */
    get computedDirTree() : any {

        let copy_dirs = JSON.parse(JSON.stringify(this.all_dirs));

        copy_dirs.sort((o1, o2) => {

            const is_fave = o1.is_favorite;
            const is_fave2 = o2.is_favorite;

            if( is_fave == true && is_fave2 == false ) return -1;
            else if( is_fave == false && is_fave2 == true ) return 1;

            const o1_dir_name = o1.dir_name;
            const o2_dir_name = o2.dir_name;

            if( o1_dir_name < o2_dir_name ) return -1;
            else if( o1_dir_name > o2_dir_name ) return 1;

            return 0;
        });

        let max_level = 0;
        for( const dir of copy_dirs ) {
            if( dir.dir_path.length > max_level ) max_level = dir.dir_path.length;
            dir['dirs'] = [];
            dir['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.dir_closed_map.get(dir.dir_uuid);
            if( is_closed != null ) {
                dir['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_dirs = copy_dirs.filter(item => item.dir_path.length == search_level);
            const next_search_dirs = copy_dirs.filter(item => item.dir_path.length == search_next_level); 

            for( const next_search_dir of next_search_dirs ) {
                const next_search_dir_uuid = next_search_dir.dir_uuid;
                next_search_dir.level = search_next_level;

                for( const current_search_dir of current_search_dirs ) {
                    const current_search_dir_uuid = current_search_dir.dir_uuid;
                    current_search_dir.level = search_level;

                    if( current_search_dir.dir_path.indexOf(next_search_dir_uuid) > -1 ) {
                        next_search_dir.dirs.push(current_search_dir);
                    }

                }
            }
            
            search_level--;
        }

        const top_dirs = copy_dirs.filter(item => item.dir_path.length == 1);
        if( top_dirs == null || top_dirs.length < 1 ) return null;

        return top_dirs;
    }

    get computedSearchFiles() : any[] {

        let target_list : any[] = JSON.parse(JSON.stringify(this.search_files));

        if( this.show_fave_only == true ) target_list = target_list.filter(item => item.is_favorite == true);

        target_list.sort((o1, o2) => {

            const o1_group_type = o1.group_type;
            const o2_group_type = o2.group_type;

            if( o1_group_type != o2_group_type ) {
                if( o1_group_type == 'BIZC' && o2_group_type == 'BIZD' ) return -1;
                else if( o1_group_type == 'BIZD' && o2_group_type == 'BIZC' ) return 1;
            }

            const o1_path_dirs = o1.path_dirs;
            const o2_path_dirs = o2.path_dirs;

            let o1_path_names = ""; 
            let o2_path_names = "";
            
            const o1_path_dirs_length = o1_path_dirs.length;
            for( let i = 0; i < o1_path_dirs_length; i++ ) {
                const o1_path_dir = o1_path_dirs[i];
                if( i > 0 ) o1_path_names += "_";
                o1_path_names += o1_path_dir.dir_name;
            }
            
            const o2_path_dirs_length = o2_path_dirs.length;
            for( let i = 0; i < o2_path_dirs_length; i++ ) {
                const o2_path_dir = o2_path_dirs[i];
                if( i > 0 ) o2_path_names += "_";
                o2_path_names += o2_path_dir.dir_name;
            }

            // 노드, 트리 형식 정렬에 사용하기 좋음
            return o1_path_names.localeCompare(o2_path_names);
        });

        for( const obj of target_list ) {
            obj.list_type = 'DIR';
        }

        // 비밀번호 중간에 걸려있으면 제외시켜야함 && 패스워드 걸린 디렉토리는 디렉토리 이름이 검색된게 아니면 검색 리스트에서 제외되어야함
        target_list = target_list.filter(item => this.isMiddleDirHasPassword(item) == false && this.isPassHasPasswordDirRule(item) == true);

        // 비밀번호 걸린 디렉토리는 파일이 노출되어서는 안됨
        for( const item of target_list ) {
            if( item.has_password == true ) {
                item.files.splice(0, item.files.length);
            }
        }

        return target_list;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupAddFileModalNewInfo ?: (params : GroupAddFileModalNewInfo) => void;
    @ModalInfo.Action doSetGroupFileboxMoveModalInfo ?: (params : GroupFileboxMoveModalInfo) => void;

    /**
     * @GroupInfo.State
     */
    @GroupInfo.State selected_dir_uuid !: string

    /**
     * @GroupInfo.Action
     */
    @GroupInfo.Action doSelectedDirUuid ?: (params : string) => void;
    
    all_dirs : any[] = [];

    dir_uuid : string = "";
    selected_dir : any = null;

    dirs : any[] = [];
    files : any[] = [];

    sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE' = 'DIR_NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    is_card_view : boolean = true;
    is_right_div_open : boolean = true;
    show_fave_only : boolean = false;

    file_search_query : string = "";
    search_files : any[] = [];
    close_search_dir_uuids : string[] = [];

    is_file_drag : boolean = false;

    dir_closed_map : Map<string, boolean> = new Map();

    // beforeMount() {
    //     this.dir_uuid = String(this.$route.query.dir_uuid ?? "");
    // }

    async mounted() {

        this.dir_uuid = this.selected_dir_uuid;

        // //리스트형으로 보기
        // $(".btns .dpTwo").click(function(){
        //     $(".section_ce_fix").addClass("dp2");
        //     $(".section_ce_fix").removeClass("dp1");
        //     $(".btns .dpOne").show();
        //     $(this).hide();
        // });

        // //박스형으로 보기
        // $(".btns .dpOne").click(function(){
        //     $(".section_ce_fix").removeClass("dp2");
        //     $(".section_ce_fix").addClass("dp1");
        //     $(".btns .dpTwo").show();
        //     $(this).hide();
        // });

        // // 즐겨찾기 정렬
        // $(".faveStar").click(function(){
        //     $(this).toggleClass("fave_on");
        // });

        // //메뉴 열기
        // $(".fileMenu").click(function(){
        //     $(this).parent().toggleClass("on");
        // });

        // // 왼쪽 menu 열기
        // $(".closeLeft").click(function(){
        //     $(".section_ce_fix").toggleClass("leftDivClosed");
        //     $(this).toggleClass("on");

        // });

        // // 왼쪽 메뉴 체크
        // $(".fdTreeBox .fd_tree a").click(function(){
        //     $(this).parent().parent().addClass('active').siblings().removeClass();
        // })


        // // 왼쪽 메뉴 dropdown
        // $(".tree_has_child > .mainMore").click(function(){
        //     $(this).parent().siblings().toggleClass("open");
        //     $(this).toggleClass("open");
        // })

        // // 왼쪽 메뉴 dropdown2
        // $(".tree_has_child > .subMore").click(function(){
        //     $(this).parent().siblings().toggleClass("open");
        //     $(this).toggleClass("open");
        // });

        const group_file_box_is_card_view = hodu_cookie.get('group_file_box_is_card_view');
        if( group_file_box_is_card_view != null ) {
            this.is_card_view = group_file_box_is_card_view === 'true';
        }

        const group_file_box_is_right_div_open = hodu_cookie.get('group_file_box_is_right_div_open');
        if( group_file_box_is_right_div_open != null ) {
            this.is_right_div_open = group_file_box_is_right_div_open === 'true';
        }

        await this.getGroupFiles();
        if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
            await this.getFileDir();
        }
        else {
            this.files.splice(0, this.files.length);
        }
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        this.$nextTick(() => {

            const title_box_height : number | undefined = $('.title_box').outerHeight();
            const schedule_box_height : number | undefined = $('.schedule_box').outerHeight();
            const route_bar_height : number | undefined = $('#route_bar').outerHeight();
            const sort_header_height : number | undefined = this.is_card_view == true ? 0 : $('.sortHeader').outerHeight();
            const left_sort_header_height : number | undefined = $('.leftContent .sortHeader').outerHeight();
            const search_sort_header_height : number | undefined = $('.grp_file_search .sortHeader').outerHeight();

            // @ts-ignore
            $('#filebox_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (route_bar_height ? route_bar_height : 0)
                                            - (sort_header_height ? sort_header_height : 0),
            });

            // @ts-ignore
            $('#filebox_tree').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (route_bar_height ? route_bar_height : 0)
                                            - (left_sort_header_height ? left_sort_header_height : 0),
            });

            // @ts-ignore
            $('#search_filebox_scroll').mCustomScrollbar({
                axis : 'y',
                scrollbarPosition : 'outside',
                mouseWheelPixels : 100,
                scrollInertia : 60,
                autoDraggerLength : false,
                setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                            - (schedule_box_height ? schedule_box_height : 0)
                                            - (search_sort_header_height ? search_sort_header_height : 0),
            });
        
            // const left_area_width : number | undefined = !this.left_control_box_flag ? $('#left_area').outerWidth() : 60;
            // const right_area_controller_width : number | undefined = $('#right_area_controller').outerWidth();

            // @ts-ignore
            // $('#route_bar').mCustomScrollbar({
            //     axis : 'x',
            //     scrollbarPosition : 'outside',
            //     mouseWheelPixels : 100,
            //     scrollInertia : 60,
            //     autoDraggerLength : false,
            //     setWidth : window.innerWidth - (left_area_width ? left_area_width : 0) 
            //                                  - (right_area_controller_width ? right_area_controller_width : 0),
            // });
        });
        
    }

    /**
     * 그룹 파일함 전체 조회
     */
    async getGroupFiles() : Promise<void> {
        await this.hodu_api_call(`api/v1/groupsweb/getGroupFiles/${this.scope_group_id}/${this.scope_group_team_option.biz_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                
                this.all_dirs.splice(0, this.all_dirs.length);
                this.all_dirs = this.all_dirs.concat(response.data.data.all_dirs);

                if( this.dir_uuid == null || this.dir_uuid.length < 1 ) {
                    this.dirs.splice(0, this.dirs.length);
                    this.dirs = this.dirs.concat(response.data.data.files);
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    async getFileDir() : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/file/${this.dir_uuid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dir ) {
                throw Error("파일함 조회 중 오류 발생");
            }

            this.selected_dir = JSON.parse(JSON.stringify(response.data.data.dir));

            this.dirs.splice(0, this.dirs.length);
            this.dirs = this.dirs.concat(this.selected_dir.dirs);

            this.files.splice(0, this.files.length);
            this.files = this.files.concat(this.selected_dir.files);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 파일함 추가 모달
     */
    showGroupAddFileBox(event, dir ?: any) : void {

        this.doSetGroupAddFileModalNewInfo?.({
            show_modal : true,
            is_create: dir != null ? false : true,
            dir_uuid: dir != null ? dir.dir_uuid : "",
            group_id: this.scope_group_id,
            up_dir_uuid: this.selected_dir != null ? this.selected_dir.dir_uuid : undefined,
            create_function: async() => { 
                await this.getGroupFiles();
                if( this.dir_uuid != null && this.dir_uuid.length > 0 ) { 
                    await this.getFileDir();
                }
                else {
                    this.files.splice(0, this.files.length);
                }
            },
            update_function: async(is_delete : boolean, dir_uuid : string) => {
                await this.getGroupFiles();
                if( this.dir_uuid != null && this.dir_uuid.length > 0 ) { 
                    await this.getFileDir();
                }
                else {
                    this.files.splice(0, this.files.length);
                }
            }
        });
    }

    isEditable(obj) {
        if( obj == null ) return false;
        const is_group_manager = (this.get_group_role(this.scope_group_id) == 'GROUP_MANAGER' || this.get_group_role(this.scope_group_id) == 'ADMIN');
        return is_group_manager || obj.audit_user_id == this.user_id;
    }

    dirFileSize(dir) {

        let byte = 0;

        if( dir.path_files == null ) return `0 Bytes`;

        for( const path_file of dir.path_files ) byte += path_file.file_size;
          
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]; 
    }

    dirFileSizeNumber(dir) {
        let byte = 0;
        for( const path_file of dir.path_files ) byte += path_file.file_size;
        return byte; 
    }

    /**
     * BYTE TO SIZE
     */
    byteToSize(byte, decimals = 2) : string {
        if (byte === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(byte) / Math.log(k));

        return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];        
    }

    /**
     * 날짜 시간 변환
     */
    dateToString(date, format) : string {
        let returnVal = dateFormat(date, format);
        if ( format == "HH:MM" ) {
            const hour : number = Number(returnVal.substring(0,2));
            const min  : string = returnVal.substring(3,5);
            if( hour > 12 ) {
                returnVal = `오후 ${ `0${hour - 12}`.slice(-2) }:${min}`;
            }

            else if( hour == 12 ) {
                returnVal = `오후 12:${min}`;
            }

            else if( hour == 0 ) {
                returnVal = `오전 12:${min}`;
            }

            else {
                returnVal = `오전 ${ `0${hour}`.slice(-2) }:${min}`;
            }
        }

        return returnVal;
    }

    /**
     * 확장자 뽑기
     */
    getExtensionOfFilename(filename) : string {
        var _fileLen = filename.length;
        var _lastDot = filename.lastIndexOf('.');
    
        // 확장자 명만 추출한 후 소문자로 변경
        var _fileExt = filename.substring(_lastDot, _fileLen).toLowerCase();
 
        if ( _fileExt == ".png" || _fileExt == ".jpg" || _fileExt == ".jpeg" || _fileExt == ".bmp" || _fileExt == ".gif" ) {
            return "img";
        }
        else {
            return _fileExt.replace(".", "");
        }
    }

    /**
     * 카드 뷰 모드 ON / OFF
     */
    cardViewModeOnOff() : void {
        this.is_card_view = !this.is_card_view;

        hodu_cookie.set('group_file_box_is_card_view', String(this.is_card_view));
        
        this.$nextTick(() => this.handleResize());
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'DIR_NAME' | 'USER_NAME' | 'FILE_COUNT' | 'UPDATE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    async selectFileBox(dir_uuid : string, is_route : boolean, complete_password_input : boolean = false) {

        // console.log(`selectFileBox : ${dir_uuid}, ${is_route}, ${complete_password_input}`);
        
        // 비밀번호 걸린 디렉토리 찾기용
        if( is_route == false && dir_uuid != null && dir_uuid.length > 0 ) {
            
            let dir = null;
            let has_password : boolean = false;

            for( const dir_or_file of this.all_dirs ) {
                if( dir_or_file.list_type == 'FILE' ) continue;
                if( dir_or_file.dir_uuid == dir_uuid ) {
                    has_password = dir_or_file.has_password;
                    dir = dir_or_file;
                    break;
                }
            }

            if( has_password == true && complete_password_input == false ) {
                this.password_input(dir, 'select');
                return;
            }

        }

        this.file_search_query = "";

        this.dir_uuid = dir_uuid;
        this.doSelectedDirUuid?.(this.dir_uuid);

        await this.getGroupFiles();
        if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
            await this.getFileDir();
        }
        else {
            this.selected_dir = null;
            this.files.splice(0, this.files.length);
        }

    }

    /**
     * 파일함 즐겨찾기 ON / OFF
     */
    async insertFileOrDirFavorite(group_id : number, obj : any, is_root : boolean) {

        try {
            
            let response : any = null;

            if( obj.list_type == 'DIR' ) {
                response = await this.hodu_api_call(`/api/v1/fileDirFavorite/${group_id}/${obj.dir_uuid}?is_root=${is_root}`, API_METHOD.POST);
            }
            else {
                response = await this.hodu_api_call(`/api/v1/fileFavorite/${obj.file_uuid}/${obj.dir_uuid}?is_root=${is_root}`, API_METHOD.POST);
            }

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("파일함 즐겨찾기 ON / OFF 중 오류 발생");
            }

            // 재조회
            await this.getGroupFiles();
            if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                await this.getFileDir();
            }
            else {
                this.files.splice(0, this.files.length);
            }

            if( this.file_search_query != null && this.file_search_query.length > 0 ) {
                this.watchFileSearchQuery();
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일함 즐겨찾기 설정 중 오류 발생", ['확인']);
        }
    }

    menuOpen(obj) {

        let before_menu_open : boolean = obj.menu_open;

        if( this.dirs.length > 0 ) {
            for( const dir of this.dirs ) {
                dir.menu_open = false;
                if( obj.list_type == 'DIR' && obj.dir_uuid == dir.dir_uuid ) {
                    dir.menu_open = (before_menu_open == true ? false : true);
                }
            }
        }
        

        if( this.files.length > 0 ) {
            for( const file of this.files ) {
                file.menu_open = false;
                if( obj.list_type == 'FILE' && obj.file_uuid == file.file_uuid ) {
                    file.menu_open = (before_menu_open == true ? false : true);
                }
            }
        }        

        this.$forceUpdate();
    }

    async delete_password(dir) {

        try {

            const delete_response = await this.hodu_api_call(`api/v1/groups/${dir.group_id}/file/${dir.dir_uuid}/password`, API_METHOD.DELETE);

            console.log(delete_response);

            if( !delete_response || !this.isHttpStatusSuccess(delete_response.status) ) {
                throw new Error("파일함 비밀번호 삭제 중 오류 발생");
            }
            
            await this.getGroupFiles();
            if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                await this.getFileDir();
            }
            else {
                this.files.splice(0, this.files.length);
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "파일함 비밀번호 삭제 중 오류 발생", ['확인']);
        }

    }

    fileDelete(file_uuid) {
        this.hodu_show_dialog('cancel', "정말 파일을 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.hodu_api_call(`api/v1/groupsweb/deleteGroupFile/${file_uuid}/${this.scope_group_team_option.biz_id}`, API_METHOD.DELETE, {})
                    .then(async(response) => {
                        await this.getGroupFiles();
                        if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                            await this.getFileDir();
                        }
                        else {
                            this.files.splice(0, this.files.length);
                        }
                    })
                    .catch(async(e) => {
                        this.hodu_error_process(e, true, false);
                    });
            }
        ])
    }

    fileDownLoad(ps_path, ps_file_name) : void { 
        this.hodu_download(`/app_images/group_file/${ps_path}`, ps_file_name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 배경화면 반환
     */
    getBackgroundImage(obj : any) : string {
        if( new RegExp(escape('image')).test(escape(obj.file_type)) == false ) {
            return 'none';
        }

        return obj.file_path.indexOf('patient_file') > -1 ? `url(app_images/${obj.file_path})` : `url(app_images/group_file/${obj.file_path})`;
    }

    /**
     * 파일 들고 들어 온 경우
     */
    fileDragOver(event : any) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.is_file_drag = true;
    }
    
    /**
     * 파일 나간 경우
     */
    fileDragLeave(event : any) : void {
        this.is_file_drag = false;
    }
    
    /**
     * 파일 드랍
     */
    fileDrop(event : any) : void {
        this.is_file_drag = false;
        event.target.files = event.dataTransfer.files;
        this.addFile(event);
    }

    /**
     * 파일 추가
     */
    async addFile(event) : Promise<void> {
        
        if ( this.dir_uuid == "" ) {
            $('#addFile').val('');
            this.hodu_show_dialog("alert", "파일함을 생성해주세요", ['확인']);
            return;
        }

        const files : File[] = event.target.files;

        if ( files.length < 1 ) {
            $('#addFile').val('');
            return;
        }

        const files_length : number   = files.length;
        const form_data    : FormData = new FormData();

        for ( let i = 0; i < files_length; i++ ) {
            if( files[i].size > this.DEFAULT_FILE_MAX_SIZE ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", `${this.DEFAULT_FILE_MAX_SIZE_TEXT} 이하의 파일만 업로드 가능 합니다`, ['확인']);
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", "확장자가 있는 파일만 업로드 가능 합니다", ['확인']);
                return;
            } 

            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                $('#addFile').val('');
                this.hodu_show_dialog("alert", `${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`, ['확인']);
                return;
            }

            let file_data = {
                name: files[i].name,
                mimeType: files[i].type,
                url: '',
                size: files[i].size,
                date: new Date(),
            }

            form_data.append('file', files[i]);
        }

        await this.hodu_temp_upload(form_data)
            .then(async(response) => {
                const temp_files : t_event_file[] = response.data.data.temp_files;
                await this.groupFileSave(temp_files, false);
            })
            .catch(async(e) => {
                $('#addFile').val('');
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 파일 저장
     */
    async groupFileSave(po_files : t_event_file[], pb_overLap : boolean) : Promise<void> {
        const vue = this;
        let param_data = {file: po_files}
        
        await this.hodu_api_call(`api/v1/groupsweb/setGroupFile/${this.scope_group_id}/${this.scope_group_team_option.biz_id}/${this.dir_uuid}/${pb_overLap}`, API_METHOD.POST, param_data)
            .then(async(response) => {
                if ( response.data.data.overlap_files.length < 1 ) {
                    await this.getGroupFiles();
                    if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                        await this.getFileDir();
                    }
                    else {
                        this.files.splice(0, this.files.length);
                    }
                }
                else {
                    this.overlapFileSave(response.data.data.overlap_files);
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });

        $('#addFile').val('');
    }

    /**
     * 중복된 파일에 한해서 파일 저장
     */
    async overlapFileSave(po_files : t_event_file[]) : Promise<void> {
        if ( po_files.length < 1 ) {
            await this.getGroupFiles();
            if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                await this.getFileDir();
            }
            else {
                this.files.splice(0, this.files.length);
            }
            return;
        }

        let upload_files : t_event_file[] = [];

        for ( let i = 0; i < po_files.length; i++ ) {
            /*
            this.hodu_show_dialog("alert", po_files[i].name + " 중복된 파일입니다. \n\n파일을 덮어씌우시겠습니까?", ['아니오', '예'], [
                () => {},
                () => {
                    upload_files.push(po_files[i]);
                },
            ]);
            */

            if ( confirm(po_files[i].name + "\n동일한 파일명이 존재합니다.\n파일을 덮어씌우시겠습니까?") ) {
                upload_files.push(po_files[i]);
            }
        }

        if ( upload_files.length > 0 ) {
            this.groupFileSave(upload_files, true);
        }
        else {
            await this.getGroupFiles();
            if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                await this.getFileDir();
            }
            else {
                this.files.splice(0, this.files.length);
            }
        }
    }

    /**
     * 폴더 토글
     */
    toggleLeftDir(dir : any, complete_password_input : boolean = false) : void {

        // console.log(`toggleLeftDir : ${dir}, ${complete_password_input}`);

        if( dir.is_closed == true && dir.has_password == true && complete_password_input == false ) {
            this.password_input(dir, 'toggle');
            return;
        }

        dir.is_closed = !dir.is_closed;
        this.$forceUpdate();
        this.dir_closed_map.set(dir.dir_uuid, dir.is_closed);
    }

    /**
     * 디렉토리 비밀번호 입력
     */
    password_input(dir : any, action : string) {

        let uuid : string = dir.dir_uuid;

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "비밀번호 입력",
            subtitle : "비밀번호",
            placeholder : "비밀번호를 입력해주세요",
            is_password : true,
            save_text : "확인",
            cancel : () => {

            },
            save : async(password) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/groupsweb/file/dir/${uuid}/password`, API_METHOD.POST, {
                        "password" : password 
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("파일함 비밀번호 체크 중 오류 발생");
                    }
                    
                    switch( action ) {
                        case 'select':
                            this.selectFileBox(dir.dir_uuid, false, true);
                            break;

                        case 'toggle':
                            this.toggleLeftDir(dir, true);
                            break;

                        case 'delete':
                            this.delete_password(dir);
                            break;
                    }

                } catch(e) {
                    this.hodu_show_dialog("cancel", "비밀번호를 확인해주세요", ['확인']);
                }

            },
        });
    }

    /**
     * 오른쪽 div 닫기 / 열기
     */
    dirTreeOnOff() : void {
        this.is_right_div_open = !this.is_right_div_open; 
        hodu_cookie.set('group_file_box_is_right_div_open', String(this.is_right_div_open));
    }

    /**
     * 파일 검색 쿼리 변경 감지
     */
    @Debounce(250)
    @Watch('file_search_query')
    async watchFileSearchQuery() : Promise<void> {

        this.close_search_dir_uuids.splice(0, length);

        if( this.file_search_query.length < 1 ) {
            this.search_files.splice(0, this.search_files.length);
            return;
        }

        try {
            const response = await this.hodu_api_call(`api/v1/file?search_query=${this.file_search_query}&group_id=${this.scope_group_id}`, API_METHOD.GET, null, false);

            console.log(response);
            
            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.groups ) {
                throw new Error("검색 중 오류 발생");
            }

            this.search_files.splice(0, this.search_files.length);
            this.search_files = this.search_files.concat(response.data.data.groups);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        this.$nextTick(() => this.handleResize()); 
    }

    /**
     * 그룹 파일함 검색 리스트 폴더 열기
     */
    searchFileBoxOnOff(dir) : void {
        
        if( dir == null ) return;

        let dir_uuid = dir.group_type == 'BIZC' ? dir.dir_uuid : dir.biz_id;

        let index = this.close_search_dir_uuids.indexOf(dir_uuid);
        if( index < 0 ) {
            this.close_search_dir_uuids.push(dir_uuid);
        }
        else {
            this.close_search_dir_uuids.splice(index, 1);
        }

    }

    getGrpHtml(dir) {

        // <i class="clr dc6"></i>
        // 비밀번호 파일 pswFolder 클래스 추가
        // <span>{{ dir.group_name + `${ dir.group_type == 'BIZC' ? " / " : "" }` }} </span> 
        // <span :class="{ pswFolder : dir.has_password == true }" v-if="dir.group_type == 'BIZC'">
        //     <i class="ic-password">비밀번호 여부</i>
        //     {{ dir.dir_name }}
        //     <mark>호두C</mark>
        // </span>

        if( dir == null ) return '';

        let html = `<i class="clr ${this.hodu_color_dc_lc(dir.group_color)}"></i>`;
        html += `<span>${ dir.group_name }</span>`;

        if( dir.group_type != 'BIZC' || dir.path_dirs == null || dir.path_dirs.length < 1 ) {
            return html;
        }

        const SHOW_DIR_TREE_MAX_COUNT = 4;

        const dir_count = dir.path_dirs.length;
        for( let i = 0; i < dir_count; i++ ) {
            const path_dir = dir.path_dirs[i];

            if( dir_count > SHOW_DIR_TREE_MAX_COUNT ) {
                if( (SHOW_DIR_TREE_MAX_COUNT / 2) - 1 < i && i < dir_count - (SHOW_DIR_TREE_MAX_COUNT / 2) ) {
                    if( i == dir_count - (SHOW_DIR_TREE_MAX_COUNT / 2) - 1 ) html += ` / ··· `;
                    continue;
                }
            }

            const mark_text = path_dir.dir_name.replaceAll(this.file_search_query, `<mark>${this.file_search_query}</mark>`);

            html += ` / <span class="${ path_dir.has_password == true ? "pswFolder" : "" }"><i class="ic-password">비밀번호 여부</i>${mark_text}</span>`;
        }

        return html;
    }

    getFileGrpHtml(file) {

        if( file == null ) return '';

        return file.file_name.replaceAll(this.file_search_query, `<mark>${this.file_search_query}</mark>`)

    }

    isMiddleDirHasPassword(dir) {

        if( dir == null || dir.path_dirs == null || dir.path_dirs.length <= 1 ) return false; 

        let is_middle_dir_has_password : boolean = false;

        let count = dir.path_dirs.length;

        for( let i = 0; i < (count - 1); i++ ) {
            const path_dir = dir.path_dirs[i];

            if( path_dir.has_password == true ) {
                is_middle_dir_has_password = true;
                break;
            }
        }

        return is_middle_dir_has_password;
    }

    isPassHasPasswordDirRule(dir) {

        if( dir == null ) return false;
        if( dir.has_password == false ) return true;

        let is_pass_has_password_dir_rule : boolean = new RegExp(this.file_search_query).test(dir.dir_name);

        return is_pass_has_password_dir_rule;
    }

    /**
     * 파일 이동
     */
    fileMove(file) {
        this.doSetGroupFileboxMoveModalInfo?.({
            show_modal : true,
            type : "FILE",
            file_uuid : file.file_uuid,
            callback : async() => {
                await this.getGroupFiles();
                if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                    await this.getFileDir();
                }
                else {
                    this.files.splice(0, this.files.length);
                }
            },
        })
    }

    /**
     * 디렉토리 이동
     */
     dirMove(dir) {
        this.doSetGroupFileboxMoveModalInfo?.({
            show_modal : true,
            type : "DIR",
            dir_uuid : dir.dir_uuid,
            callback : async() => {
                await this.getGroupFiles();
                if( this.dir_uuid != null && this.dir_uuid.length > 0 ) {
                    await this.getFileDir();
                }
                else {
                    this.files.splice(0, this.files.length);
                }
            },
        })
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        // @ts-ignore
        $('#filebox_scroll').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#filebox_tree').mCustomScrollbar('destroy');
        
        // @ts-ignore
        $('#search_filebox_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

    // moveFileBox() : void {
    //     this.hodu_router_push(`/filebox?${new Date().getTime()}`);
    // }

    // moveGroupFileBox() : void {
    //     this.hodu_router_push(`/group/filebox?${new Date().getTime()}`);
    // }

    // moveGroupFileList() : void {
    //     this.hodu_router_push(`/group/filebox/dir_id?${new Date().getTime()}`);
    // }

}
