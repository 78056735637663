
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { GroupNoticeReadInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {

    }
}) export default class GroupNoticeRead extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_notice_read_info !: GroupNoticeReadInfo

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupNoticeReadInfo ?: any;

    user_list : any[] = [];
    unread_user_list : any[] = [];

    tab : string = "READ";

    mounted() : void {
        this.getNotificationRead();
    }

    /**
     * 공지사항 읽은 사람 조회
     */
    getNotificationRead() : void {
        const vue = this;

        this.hodu_api_call(`api/v1/notification/read/${this.group_notice_read_info.noti_uid}`, API_METHOD.GET)
            .then((response) => {
                console.log(response);
                
                // 읽은 사람
                vue.user_list.splice(0, vue.user_list.length);
                vue.user_list = vue.user_list.concat(response.data.data.notice_read_user_list);

                // 안 읽은 사람
                vue.unread_user_list.splice(0, vue.unread_user_list.length);
                vue.unread_user_list = vue.unread_user_list.concat(response.data.data.notice_unread_user_list);
            })
            .catch((e) => {
                this.doSetGroupNoticeReadInfo({
                    show_modal : false,
                    noti_uid : ''
                });

                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 유저 이미지를 불러오는 중 에러가 발생한 경우
     */
    userImageError(event) : void {
        $(event.target).parent().find('.fPic').addClass("none");
    }

    /**
     * 역할 받기
     */
    getRole(user : any) : string {
        if( user.team_role && user.team_role.length > 0 ) {
            switch( user.team_role[0] ) {
                case 'ADMIN':
                    return '최종관리자';

                case 'TEAM_MANAGER':
                    return '팀장';

                case 'TEAM_USER':
                    return '팀원';
            }
        }

        if( user.group_role && user.group_role.length > 0 ) {
            switch( user.group_role[0] ) {
                case 'ADMIN':
                    return '최종관리자';

                case 'GROUP_MANAGER':
                    return '그룹 관리자';

                case 'GROUP_SUB_MANAGER':
                    return '그룹 부관리자';
                    
                case 'GROUP_USER':
                    return '그룹원'
            }
        }

        return '';
    }

    /**
     * 확인 버튼 클릭
     */
    clickCloseButton() : void {
        this.doSetGroupNoticeReadInfo({
            show_modal : false,
            noti_uid : ""
        });
    }

}

