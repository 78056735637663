import {GetterTree, MutationTree , ActionTree} from "vuex";
import { t_todo } from '@/model/osm';

export const namespaced = true;

export interface TodoInfoState {
    todo : t_todo;
    todoSelectFlag : boolean;
}

export const state: TodoInfoState = {
    todo : {
        todo_id : "",
        user_id : 0,
        last_update_tag : "",
        audit_modified : new Date(),
        audit_delete_flag : false,
        todo_info : {
            summary : "",
            start : null,
            end : null,
            is_end : false
        }
    },
    todoSelectFlag : false
};

// getters
export const getters: GetterTree<TodoInfoState, any> = {
    getTodo: (state) => {
        return state.todo;
    },

    isTodoSelectFlag: (state) => {
        return state.todoSelectFlag;
    }
};

// action
export const actions: ActionTree<TodoInfoState, TodoInfoState> = {
    doSetTodo: ({commit, state}, todo : t_todo) => {
        console.log("actions.doSetTodo");
        commit("setTodo", todo);
    },

    doSetTodoSelectFlag: ({commit, state}, todoSelectFlag : boolean) => {
        console.log("actions.doSetTodoSelectFlag");
        commit("setTodoSelectFlag", todoSelectFlag);
    },
};

// mutation
export const mutations: MutationTree<TodoInfoState> = {
    setTodo: (state, payload : t_todo) => {
        console.log(`mutations.setTodo ${JSON.stringify(payload)}`);
        state.todo = payload;
    },

    setTodoSelectFlag: (state, payload : boolean) => {
        console.log(`mutations.setTodoSelectFlag ${JSON.stringify(payload)}`);
        state.todoSelectFlag = payload;
    },
};