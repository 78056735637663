
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo    = namespace('ModalInfo');

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';

import { hodu_doc_modal_info } from '@/model/hodudoc';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HoduInlineDatePicker
    },
}) 
export default class DoctorOffSettingModal extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State doctor_off_setting_modal_info !: hodu_doc_modal_info.DoctorOffSettingModalInfo;

    event_bus : Vue = new Vue();

    mounted() : void {
        // 오른쪽 시간선택시 
        $(".timeDiv div input").click(function(){
            $(this).parent().siblings().removeClass("on");
            $(this).parent().addClass("on");
            $(this).parent().siblings().find("input").removeClass("on");
            $(this).addClass("on");

        });
    }

    /**
     * 탭 변경으로 왼쪽 데이트피커 날짜 변경
     */
    changeDate(date : Date | string) : void {
        date = date instanceof Date ? date : new Date(date);
        this.event_bus.$emit('changeDate', date);
    }

}
