
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { ResizeObserver } from 'vue-resize';
import {hodu_doc_object, hodu_doc_modal_info} from "@/model/hodudoc";

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        ResizeObserver
    },
}) 
export default class HospitalDepartmentList extends Mixins(VueHoduCommon) {

    /**
     * 진료과 정렬 리스트
     */
    get computedDepartmentSortList() : hodu_doc_object.department_info[] {

        let target_list : hodu_doc_object.department_info[] = JSON.parse(JSON.stringify(this.departments));

        target_list = target_list.sort((item1, item2) : number => {
            
            // 진료과 이름 기준 정렬
            if ( this.sort_type == 'NAME' ) {
                
                if( item1.department_name == item2.department_name ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.department_name < item2.department_name ? -1 : 1;
                }

                return item1.department_name > item2.department_name ? -1 : 1;

            }

            // 진료과 코드 기준 정렬
            if ( this.sort_type == 'CODE' ) {
                
                if( item1.department_code == item2.department_code ) { return 0; }

                if( this.sort_dir == SORT_TYPE.ASC ) {
                    return item1.department_code < item2.department_code ? -1 : 1;
                }

                return item1.department_code > item2.department_code ? -1 : 1;

            }

            return 0;
        })

        return target_list;
    }
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDepartmentSettingModalInfo ?: (params : hodu_doc_modal_info.DepartmentSettingModalInfo) => void;

    @Prop() event_bus !: Vue;

    biz_id      : string = "";
    departments : hodu_doc_object.department_info[]  = [];
    selected_indexs : number[] = [];

    // 정렬
    sort_type : 'NAME' | 'CODE' = 'NAME';
    sort_dir  : SORT_TYPE = SORT_TYPE.ASC;

    created() : void {
        this.biz_id = this.scope_group_team_option.biz_id;
        this.getDepartments();
    }

    mounted() : void {
        if( this.event_bus != null ) { this.event_bus.$on('deleteDepartments', this.deleteDepartments); }
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#department_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    selectDept(index: number){
        if (this.selected_indexs.includes(index) == false) {
            this.selected_indexs.push(index)
        }else{
            this.selected_indexs.splice(this.selected_indexs.indexOf(index), 1)
        }
    }

    selectAll(){
        const numOfDept = this.departments.length

        if (this.selected_indexs.length < numOfDept) {
            this.selected_indexs.splice(0, this.selected_indexs.length)
            for (let i=0; i<numOfDept; i++) {
                this.selected_indexs.push(i)
            }
        }else{
            this.selected_indexs.splice(0, this.selected_indexs.length)
        }
    }

    /**
     * 진료과 조회
     */
    getDepartments(scrollTo : string | null = null) : void {
        this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/department`, API_METHOD.GET)
            .then((response) => {
                // let jsonData = JSON.stringify(response.data.data);
                this.departments.splice(0, this.departments.length);
                this.departments = this.departments.concat(response.data.data);
                this.selected_indexs.splice(0, this.selected_indexs.length)
                
                if ( scrollTo == null) { return }
                switch( scrollTo ) {
                    case "TOP" :

                        this.$nextTick(() => {
                            // @ts-ignore
                            $('#department_list_scroll').mCustomScrollbar('scrollTo', 'top', { scrollInertia : 0 });
                        });

                        break;

                    case "BOTTOM" :
                        
                        this.$nextTick(() => {
                            // @ts-ignore
                            $('#department_list_scroll').mCustomScrollbar('scrollTo', 'bottom', { scrollInertia : 0 });
                        });

                        break;

                    default:
                        break;
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 진료과 추가
     */
    addDepartment() : void {
        window['getDepartments'] = this.getDepartments;
        if( this.doSetDepartmentSettingModalInfo ) { 
            this.doSetDepartmentSettingModalInfo({
                show_modal  : true,
                is_create   : true,
                department  : {
                    department_code : "",
                    department_name : ""
                }
            });
        }
    }

    /**
     * 진료과 조회 및 수정
     */
    detailDepartment(department: hodu_doc_object.department_info) : void {
        window['getDepartments'] = this.getDepartments;
        if( this.doSetDepartmentSettingModalInfo ) { 
            this.doSetDepartmentSettingModalInfo({
                show_modal  : true,
                is_create   : false,
                department  : {
                    department_code : department.department_code,
                    department_name : department.department_name
                }
            });
        }
    }

    /**
     * 진료과 단일 삭제
     */
    deleteDepartment(depart: hodu_doc_object.department_info) {
        let body = {
            department_codes : [depart.department_code]
        }
        this.hodu_show_dialog("cancel", `정말로 [${depart.department_name}]를 삭제하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => {
                this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/department`, API_METHOD.DELETE, body)
                .then((response) => {
                    // let jsonData = JSON.stringify(response.data.data);
                    this.getDepartments()
                    
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
            ]);        
    }

    /**
     * 진료과 멀티 삭제
     */
    deleteDepartments(){
        if ( this.selected_indexs.length < 1 ) { return; }

        this.hodu_show_dialog("cancel", `정말로 ${this.selected_indexs.length}개의 진료과를 삭제하시겠습니까?`, ["아니오", "예"], [
            () => {},
            () => {
                let arrO_depart_codes : string[] = []
                for (const index of this.selected_indexs) {
                    arrO_depart_codes.push(this.computedDepartmentSortList[index].department_code)
                }
                let body = {
                    department_codes : arrO_depart_codes
                }
                this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/department`, API_METHOD.DELETE, body)
                .then((response) => {
                    // let jsonData = JSON.stringify(response.data.data);
                    this.getDepartments()
                    
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });
            }
            ]);   
    }

    /**
     * 정렬 타입 변경
     */
    sortTypeChange(sort_type : 'NAME' | 'CODE') : void {
        
        // 기존 타입과 똑같은 경우 (방향만 바꿈)
        if( this.sort_type == sort_type ) {
            this.sort_dir = this.sort_dir == SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
        }
        
        // 기존 타입과 일치하지 않는 경우
        else {
            this.sort_type = sort_type;
            this.sort_dir  = SORT_TYPE.ASC;
        }
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#department_list_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
