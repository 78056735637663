
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';

@Component({

})
export default class LeftAdminPage extends Mixins(VueHoduCommon) {
    admin_menu_close : boolean = true;

    mounted() : void {

    }

    admin_menu_click(ps_value) : void {
        if ( !ps_value ) {
            this.admin_menu_close = !this.admin_menu_close;
        }
        else if ( ps_value == "biz_create" ) {
            this.hodu_router_push(`/biz/${new Date().getTime()}/create`);
        }
        else if ( ps_value == "biz_apply" ) {
            this.hodu_router_push(`/biz/${new Date().getTime()}/apply`);
        }
        else if( ps_value == 'home_resident_data' ) {
            this.hodu_router_push("/home/resident_data");
        }

        // else if( ps_value == 'external_project' ) {
        //     this.hodu_router_push(`/external/project?${new Date().getTime()}`);
        // }
    }
}
