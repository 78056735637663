
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD, SORT_TYPE } from '@/mixin/VueHoduCommon';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class KidsTeacher extends Mixins(VueHoduCommon) {

    get computedTeachers() : any[] {

        let computed_teachers : any[] = JSON.parse(JSON.stringify(this.teachers));

        if( this.search_query.trim().length > 0 ) {
           computed_teachers = computed_teachers.filter(teacher => this.hodu_string_includes(teacher.user_name, this.search_query) || 
                                                        teacher.class_info != null && this.hodu_string_includes(teacher.class_info.team_info.team_name, this.search_query) ||
                                                        teacher.user_phone_number != null && this.hodu_string_includes(teacher.user_phone_number, this.search_query) || 
                                                        teacher.user_email != null && this.hodu_string_includes(teacher.user_email, this.search_query))
        }

        return computed_teachers.sort((o1, o2) : number => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_class_name = o1.class_info ? o1.class_info.team_info.team_name : null;
            const o2_class_name = o2.class_info ? o2.class_info.team_info.team_name : null;
            
            if( this.sort_type == 'NAME' ) {
                if( o1_name > o2_name ) return this.sort_direction == "ASC" ? 1 : -1;
                else if( o1_name < o2_name ) return this.sort_direction == "ASC" ? -1 : 1;
                return 0;
            }

            else if( this.sort_type == 'CLASS' ) {
                
                if( o1_class_name == o2_class_name ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0;
                }
                else if( o1_class_name == null && o2_class_name != null ) return 1;
                else if( o1_class_name != null && o2_class_name == null ) return -1;
                else if( o1_class_name > o2_class_name ) return this.sort_direction == "ASC" ? 1 : -1;
                else if( o1_class_name < o2_class_name ) return this.sort_direction == "ASC" ? -1 : 1;
                
            }

            return 0;
        });
    }

    // 정렬
    sort_type : string = "NAME";
    sort_direction : SORT_TYPE = SORT_TYPE.ASC;

    is_search_open : boolean = false;
    search_query : string = "";

    teams : any[] = [];
    teachers : any[] = [];

    mounted() : void {
        this.getTotalData();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        let title_height = $('.title_box').outerHeight();
        let sort_header_height = $('.sortHeader').outerHeight();

        let scroll_height = window.innerHeight - (title_height ? title_height : 0) 
                                               - (sort_header_height ? sort_header_height : 0);
        
        // @ts-ignore
        $('#teacher_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });

    }

    /**
     * 전체 데이터 조회
     */
    async getTotalData() : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getTeamInfo();
            await this.getTeachersInfo();
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "선생님 정보 조회 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 팀 정보 조회
     */
    async getTeamInfo() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/teams/${this.scope_group_id}/0`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.teams ) {
                throw new Error("팀 정보 조회 중 오류 발생");
            }

            this.teams.splice(0, this.teams.length);
            this.teams = this.teams.concat(response.data.data.teams);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 선생님 정보 조회
     */
    async getTeachersInfo() : Promise<void> {

        const partner_key : string = this.getGroupPartnerKey(this.scope_group_id);

        try {
            const response = await this.hodu_api_call(`api/v1/kids/teacher?partner_key=${partner_key}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.teachers ) {
                throw new Error("선생님 정보 조회 중 오류 발생");
            }

            this.teachers.splice(0, this.teachers.length);
            this.teachers = this.teachers.concat(response.data.data.teachers);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 선생님 직급 변경
     */
    async changeGroupRole(event, teacher) : Promise<void> {

        let before_api_call = true;

        try {

            const role = event.target.value;
            const user_id = teacher.user_id;

            const response = await this.hodu_api_call(`api/v1/groups/updateGroupMemberPermmision/${this.scope_group_id}/${role}/${user_id}`, API_METHOD.PUT);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("선생님 직급 변경 중 오류 발생");
            }

            before_api_call = false;
            this.getTotalData();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "선생님 직급 변경 중 오류 발생", ['확인']);

            // API 실행이 완료되기전에 오류 발생시 원본값으로 변경하기 위해 재조회
            if( before_api_call ) {
                this.getTotalData();
            }
        }
    }

    /**
     * 선생님 반 변경
     */
    async changeTeacherClass(event, teacher) : Promise<void> {

        let before_api_call = true;

        try {
            const partner_key = this.getGroupPartnerKey(this.scope_group_id);
            const string_team_id = event.target.value;
            const team_id = Number(string_team_id);

            const response = await this.hodu_api_call(`api/v1/kids/class`, API_METHOD.PUT, {
                "type" : "teacher",
                "group_id" : this.scope_group_id,
                "team_id" : team_id,
                "partner_key" : partner_key,
                "user_id" : teacher.user_id
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("선생님 반 변경 중 오류 발생");
            }

            before_api_call = false;
            this.getTotalData();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "선생님 반 변경 중 오류 발생", ['확인']);

            // API 실행이 완료되기전에 오류 발생시 원본값으로 변경하기 위해 재조회
            if( before_api_call ) {
                this.getTotalData();
            }
        }

    }

    /**
     * 검색창 토글
     */
    toggleSearch() : void {
        this.is_search_open = !this.is_search_open;

        if( this.is_search_open == false ) {
            this.search_query = "";
        }
        else {
            this.$nextTick(() => $('#kids_teacher_search').focus());
        }
    }

    /**
     * 정렬
     */
    sort(sort_type : string) {
        if(this.sort_type != sort_type) {
            this.sort_type = sort_type;
            this.sort_direction = SORT_TYPE.ASC;
            return;
        }

        this.sort_direction = (this.sort_direction == SORT_TYPE.ASC) ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        
        // @ts-ignore
        $('#teacher_list_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
