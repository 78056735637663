
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { approval_modal_interface, approval_enum } from '@/model/approval';

@Component({
    components: {
        
    },
})
export default class ApprovalApproverFavorite extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;
    @Prop() tab !: approval_enum.APPROVAL_BOX_TAB;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalApproverFavoriteModalInfo ?: (params : approval_modal_interface.ApprovalApproverFavoriteModalInfo) => void;

    favorites : any[] = [];
    
    scroll_height : number = 0;
    resize_function_submit : boolean = false;

    favorite_on_off_map : Map<string, boolean> = new Map();

    mounted() : void {

        //리스트 on/off
        // $('.eqinfor03_btn').off("click").on("click", function(){
        // 	$(this).toggleClass('off');
        //     if ($(this).hasClass("off")) {
        //         $(this).parent().parent().addClass('off')
        //     } else if (!$(this).hasClass("off")) {
        //         $(this).parent().parent().removeClass('off')
        //     }
        // });

        this.setScroll();
        this.getApprovalApproverFavorites();

        this.event_bus?.$on('getApprovalApproverFavorites', this.getApprovalApproverFavorites);
    }

    /**
     * 스크롤 설정
     */
     setScroll() : void {
        this.$nextTick(() => {
            const titleHeight  : number | undefined = $('.title_box').outerHeight();
            const filterHeight : number | undefined = $('.filter_tap').outerHeight();

            this.scroll_height = window.innerHeight - (titleHeight ? titleHeight : 0)
                                                - (filterHeight ? filterHeight : 0);

            if( this.resize_function_submit == false ) {
                this.event_bus?.$on('approvalApproverFavoriteResize', this.handleResize);
                this.resize_function_submit = true;
            }
        });
    }

    async getApprovalApproverFavorites() {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite`, API_METHOD.GET);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.favorites ) {
                throw new Error("결재자 즐겨찾기 조회 오류");
            }

            for( let favorite of response.data.data.favorites ) {
                const on_off = this.favorite_on_off_map.get(favorite.favorite_id);
                favorite.on = (on_off == true);
            }

            this.favorites.splice(0, this.favorites.length);
            this.favorites = this.favorites.concat(response.data.data.favorites);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "결재자 즐겨찾기 조회 실패", ['확인']);
        }

    }

    createApprovalApproverFavoriteModalInfo() {

        this.doSetApprovalApproverFavoriteModalInfo?.({
            show_modal : true,
            approver : [],
            receive_reference : [],
            callback : async(favorite_name, approver, receive_reference) => {
                try {

                    const body = {
                        "favorite_name" : favorite_name,
                        "approver" : approver,
                        "receive_reference" : receive_reference
                    }

                    console.log(body);

                    // 추가
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite`, API_METHOD.POST, body);

                    if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("결재자 즐겨찾기 추가 오류");
                    }
                    
                    this.getApprovalApproverFavorites();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "결재자 즐겨찾기 추가 실패", ['확인']);
                }
            }
        });

    }

    updateApprovalApproverFavoriteModalInfo(approver_favorite) {

        this.doSetApprovalApproverFavoriteModalInfo?.({
            show_modal : true,
            favorite_id : approver_favorite.favorite_id,
            approver : approver_favorite != null && approver_favorite.approver != null ? JSON.parse(JSON.stringify(approver_favorite.approver)) : [],
            receive_reference : approver_favorite != null && approver_favorite.receive_reference != null ? JSON.parse(JSON.stringify(approver_favorite.receive_reference)) : [],
            callback : async(favorite_name, approver, receive_reference) => {
                try {

                    const body = {
                        "favorite_name" : favorite_name,
                        "approver" : approver,
                        "receive_reference" : receive_reference
                    }

                    console.log(body);

                    // 추가
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${approver_favorite.favorite_id}`, API_METHOD.PUT, body);

                    if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("결재자 즐겨찾기 수정 오류");
                    }
                    
                    this.getApprovalApproverFavorites();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "결재자 즐겨찾기 수정 실패", ['확인']);
                }
            }
        });

    }

    deleteApprovalApproverFavoriteModalInfo(favorite) {

        this.hodu_show_dialog('cancel', '정말 즐겨찾기를 삭제하시겠습니까?', ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${favorite.favorite_id}`, API_METHOD.DELETE);

                    if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("결재자 즐겨찾기 삭제 오류");
                    }
                    
                    this.getApprovalApproverFavorites();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "결재자 즐겨찾기 삭제 실패", ['확인']);
                }
            }
        ]);
        
    }

    async deleteSingleApprover(favorite, approver, single_approver) {
        try {

            const index = approver.indexOf(single_approver);
            const temp_approver = JSON.parse(JSON.stringify(approver));
            temp_approver.splice(index, 1);

            const body = {
                "favorite_name" : favorite.favorite_name,
                "approver" : temp_approver,
                "receive_reference" : favorite.receive_reference
            }

            console.log(body);

            // 추가
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${favorite.favorite_id}`, API_METHOD.PUT, body);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("결재자 즐겨찾기 수정 오류");
            }
            
            this.getApprovalApproverFavorites();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "결재자 즐겨찾기 수정 실패", ['확인']);
        }
    }

    async deleteSingleReceiveReference(favorite, receive_reference, single_receive_reference) {
        try {

            const index = receive_reference.indexOf(single_receive_reference);
            const temp_receive_reference = JSON.parse(JSON.stringify(receive_reference));
            temp_receive_reference.splice(index, 1);

            const body = {
                "favorite_name" : favorite.favorite_name,
                "approver" : favorite.approver,
                "receive_reference" : temp_receive_reference
            }

            console.log(body);

            // 추가
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite/${favorite.favorite_id}`, API_METHOD.PUT, body);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("결재자 즐겨찾기 수정 오류");
            }

            this.getApprovalApproverFavorites();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "결재자 즐겨찾기 수정 실패", ['확인']);
        }
    }

    favoriteOnOff(favorite) {

        if( favorite.on == null || favorite.on == false ) {
            favorite.on = true;
            this.favorite_on_off_map.set(favorite.favorite_id, true);
        }
        else {
            favorite.on = false;
            this.favorite_on_off_map.set(favorite.favorite_id, false);
        }
        this.$forceUpdate();
    }

    getProfileImage(emp) {
        if( emp.user_pic != null ) {
            return `/app_images/${emp.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((emp.user_id ? emp.user_id : 0) / 10000)}/${emp.user_id}.jpg`;
    }

    /**
     * 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user_on.png');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.setScroll();
    }

}
