
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo    = namespace('ModalInfo');

import { EventReadModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class EventReadModal extends Mixins(VueHoduCommon) {

    /**
     * 읽은 사람
     */
    get getRead() : any {

        let read : any[] = JSON.parse(JSON.stringify(this.read));
        let count : number = 0;

        const read_count = read.length;
        for( let i = (read_count - 1);  i >= 0; i-- ) {
            const obj = read[i];
            const user_count = obj.read_users.length;
            for( let j = (user_count - 1);  j >= 0; j-- ) {
                const user = obj.read_users[j];
                if( user.read_flag == true ) continue;
                obj.read_users.splice(j, 1);
            }

            if( obj.read_users.length < 1 ) {
                read.splice(i, 1);
            }
        }

        for( const obj of read ) {
            count += obj.read_users.length;
        }

        return {
            "list" : read,
            "count" : count
        };
    }

    /**
     * 안 읽은 사람
     */
    get getNotRead() : any {

        let read : any[] = JSON.parse(JSON.stringify(this.read));
        let count : number = 0;

        const read_count = read.length;
        for( let i = (read_count - 1);  i >= 0; i-- ) {
            const obj = read[i];
            const user_count = obj.read_users.length;
            for( let j = (user_count - 1);  j >= 0; j-- ) {
                const user = obj.read_users[j];
                if( user.read_flag == false ) continue;
                obj.read_users.splice(j, 1);
            }

            if( obj.read_users.length < 1 ) {
                read.splice(i, 1);
            }
        }

        for( const obj of read ) {
            count += obj.read_users.length;
        }

        return {
            "list" : read,
            "count" : count
        };
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State event_read_modal_info !: EventReadModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventReadModalInfo ?: (params : EventReadModalInfo) => void;

    read : any[] = [];
    tab : string = "READ"; // READ, NOT_READ

    mounted() : void {
        // // 탭 메뉴
        // $('.tabs a').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.modal_content > div').hide();
        // 	$('.modal_content > div').eq(borderIndex).show();
        // 	$(this).addClass('on').siblings().removeClass();

        // 	return false;
        // })

        // // 리스트 열기
        // $(".userUlList > li").click(function(){
        //     $(this).toggleClass("on");
        // });

        this.getEventRead();
    }

    async getEventRead() : Promise<void> {
        try {

            const calendar_id = this.event_read_modal_info.calendar_id;
            const owner_type = this.event_read_modal_info.owner_type;
            const owner_id = this.event_read_modal_info.owner_id;
            const event_id = this.event_read_modal_info.event_id;
            const repeat_seq = this.event_read_modal_info.repeat_seq;

            const response = await this.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${owner_type}/${owner_id}/read/${event_id}/${repeat_seq}`, API_METHOD.GET, null, false);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.read ) {
                throw new Error("읽기 정보 조회 중 오류 발생")
            }

            this.read.splice(0, this.read.length);
            this.read = this.read.concat(response.data.data.read);
            
        } catch(e) {
            alert("읽기 정보 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    tabChange(tab : string) {
        this.tab = tab;
        this.read.splice(0, this.read.length);
        this.getEventRead();
    } 

    shareOnOff(read) {
        let flag = true;
        let index = -1;
        let target_obj : any = null;

        for( const obj of this.read ) {
            if( obj.share_tag == read.share_tag ) {
                index = this.read.indexOf(obj);
                target_obj = obj;
                flag = (obj.open == true) ? false : true;
                break;
            }
        }

        if( index > -1 && target_obj != null ) {
            target_obj = JSON.parse(JSON.stringify(target_obj));
            target_obj.open = flag;
            this.read.splice(index, 1, target_obj);
        }

    }

    userImageError(event) : void {
        const default_url = require('@/assets/images/contents/ic_approval_user.png');
        $(event.target).attr('src', default_url);
    }

    close() : void {
        this.doSetEventReadModalInfo?.({
            show_modal : false,
            event_id : "",
            repeat_seq : 0,
            calendar_id : "",
            owner_type : "",
            owner_id : 0
        })
    }

}
