const messages = {
    ko: {
        message: {
            test : '테스트',
        },
    },
    en: {
        message: {
            test : 'test',
        },
    }
};

export default messages;