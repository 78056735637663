
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { EventImportModalInfo } from '@/store/modules/ModalInfo';

@Component({

})
export default class CustomerSetting extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventImportModalInfo ?: (params : EventImportModalInfo) => void;

    mounted() : void {

    }

    importEvent() : void {
        if( !this.doSetEventImportModalInfo ) { return; }

        this.doSetEventImportModalInfo({ show_modal : true });
    }

}
