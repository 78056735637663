
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import moment from 'moment';
const AttendanceInfo = namespace('AttendanceInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class AttendanceMyList extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    /**
     * 달력 틀 computed
     */
    get computedCalendar() : any[] {
        let calendar : any[] = [];

        let month_start = moment(`${this.date_string}.01`).toDate();
        let month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

        const month_start_day = month_start.getDay();
        const month_end_day   = month_end.getDay();

        const default_i = month_start.getDate();
        const end_i     = month_end.getDate();

        for( let i = default_i - month_start_day; i < end_i + (7 - month_end_day); i++ ) {
            const moment_start = moment(month_start);
            const date = moment_start.set('date', moment_start.get('date') + i - 1).toDate();
            const event_key = moment(date).format('YYYYMMDD');
            const map_key = moment(date).format('YYYY-MM-DD');
            
            calendar.push({
                "date" : date,
                "events" : this.event && this.event[event_key] ? this.event[event_key] : [],
                "lunar" : this.lunar_date.get(map_key),
                "holiday" : this.holidays.get(map_key)
            });
        }

        return calendar;
    }

    /**
     * @CalendarInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @CalendarInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트
    
    date_string : string = "";

    event : any = null;
    lunar_date : Map<string, string> = new Map(); // 음력 Map
    holidays   : Map<string, Object> = new Map(); // 공휴일 Map

    mounted() : void {
        this.event_bus.$on('myListResize', this.handleResize);
        
        this.date_string = moment(this.selected_date).format('YYYY.MM');
        this.datepickerInit();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const schedule_box_height = $('.schedule_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                 - (schedule_box_height ? schedule_box_height : 0);

        // @ts-ignore
        $('#attendance_my_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * attend data 가져오기
     */
    @Watch('date_string')
    async getEmpAttendDataMonthInfo() : Promise<void> {
        await this.$nextTick();

        try {
            let month_start = moment(`${this.date_string}.01`).toDate();
            let month_end   = moment(month_start).add('month', 1).set('date', 0).toDate();

            await Promise.all([this.getLunar(month_start, month_end), this.getHolidays(month_start, month_end)]);
            await this.getEvents();

            // TODO 회사에서 지정한 휴일도 따로 불러와서 세팅 고려!
            // TODO 회사에서 안쉬기로 정한 법정공휴일은 안 쉬어야하는지 고려!

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
        }
    }

    /**
     * 이벤트(월 달력 일별 근태현황) 조회
     */
    async getEvents() : Promise<void> {
        try {
            const request_date_string = this.date_string.replaceAll(/\./ig, "");

            // 오늘 날짜를 넣어서 오늘 이후의 데이터를 안나오게 하려다가 오늘 이후의 연차 까지 나와야해서 이 달력의 마지막 날까지 조회 하도록 변경
            const today_date_string = moment().format('YYYYMMDD');
            // const today_date_string = moment(`${this.date_string}.01`).add('month', 1).set('date', 0).format('YYYYMMDD');

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/attend/data/month?month=${request_date_string}&search_mode=USER&today_date_string=${today_date_string}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_month_info ) {
                throw new Error("월달력 일별 근태현황 조회 실패");
            }
            
            for( const event of response.data.data.attend_emp_month_info ) {
                for( const key in event.jsonb ) {
                    // 근태현황 시작일이 적용 안됐다면 전부 제외
                    if( this.getAttendanceStart() == '' ) {
                        delete(event.jsonb[key]);
                        continue;
                    }

                    // 근태현황 시작일 보다 예전 날짜 데이터는 날려버림
                    if( Number(this.getAttendanceStart()) > Number(key) ) {
                        delete(event.jsonb[key]);
                        continue;
                    }
                }
                this.event = JSON.parse(JSON.stringify(event.jsonb));
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 음력 구하기
     */
    async getLunar(start : Date, end : Date) : Promise<void> {
        start = start instanceof Date ? start : new Date(moment(start).format());
        end   = end   instanceof Date ? end   : new Date(moment(end).format());

        const lunar_objs : any[] = await this.hodu_solars_to_lunars(start, end);
        
        for( let lunar_obj of lunar_objs ) {
            this.lunar_date.set(lunar_obj.solar_ymd, lunar_obj.lunar_ymd);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {
        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            this.holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

    }

    /**
     * 날짜 선택
     */
    datepickerInit() : void {

        const vue = this;

        let option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1900:2050',
            onSelect: function (dateText, inst) {
                const date_moment = moment(dateText);

                vue.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
                vue.doSetSelectedDate(date_moment.toDate());
                vue.date_string = moment(vue.selected_date).format('YYYY.MM');
                
                inst.input.val(vue.date_string);

                // @ts-ignore
                $('#datepicker').datepicker('setDate', vue.selected_date);
            },
        };

        // @ts-ignore
        $('#attendance-my-list-current-date').datepicker(option);
    }

    /**
     * 이전 달로 이동
     */
    async handlePrev() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.add('month', -1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 다음 달로 이동
     */
    async handleNext() : Promise<void> {

        const date_moment = moment(this.selected_date);
        date_moment.add('month', 1);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', this.selected_date);
    }

    /**
     * 오늘 날짜로 이동
     */
    async handleToday() : Promise<void> {
        
        const date_moment = moment();

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');

        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date());
    }

    /**
     * 달력 이동 해야 되는 날짜가 바뀐 것을 감지
     */
    @Watch('go_to_date')
    onDateChange() : void {
        const date_moment = moment(this.go_to_date);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());

        this.date_string = moment(this.selected_date).format('YYYY.MM');
    }

    /**
     * 출근 시간
     */
    getStartTimeText(events : any[]) : string {
        let start_time = null;

        for( const attend_info of events ) {
            // 출 퇴근 정보
            if( attend_info.attend_type == 10 ) {
                start_time = attend_info.start_time;
            }

            // 휴가 정보
            else if( attend_info.attend_type == 20 ) {
                
            }
        }

        return start_time != null ? this.processTimeText(start_time) : ''
    }

    /**
     * 퇴근 시간
     */
    getEndTimeText(events : any[], date : Date) : string {
        let end_time = null;
        const today_string = moment().format('YYYYMMDD');

        for( const attend_info of events ) {
            // 출 퇴근 정보
            if( attend_info.attend_type == 10 ) {
                end_time = attend_info.end_time;
            }
            // 휴가 정보
            else if( attend_info.attend_type == 20 ) {
                
            }
        }

        return end_time != null ? this.processTimeText(end_time) : (
            moment(date).format('YYYYMMDD') == today_string ? (this.getStartTimeText(events) == '' ? '' : '근무중') : ''
        );
    }

    /**
     * 4글자 시간 텍스트를 오전, 오후에 맞게 가공해서 뿌려줌
     */
    processTimeText(time_text : string) : string {
        
        let text = "";

        let AmPm : string = "AM";
        
        let hour = Number(time_text.substring(0, 2));
        const min = Number(time_text.substring(2, 4));

        if ( hour >= 12 ) AmPm = "PM";
        if ( hour >= 13 ) hour -= 12;
        if ( hour == 0  ) hour = 12;

        text = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)} ${AmPm}`;

        return this.amPmStringToLocaleAmPmString(text);
    }

    /**
     * 휴가가 존재하는 경우
     */
    isOff(day) : boolean {
        let vacation_type_name = null;

        for( const attend_info of day.events ) {
            if( attend_info.attend_type == 20 ) {
                vacation_type_name = attend_info.vacation_type_name;
            }
        }

        return vacation_type_name != '';
    }

    /**
     * 출·퇴근 데이터가 필요 없는 경우 (휴가로 인해)
     */
    // isRestByVacation(day) : boolean {

    //     let attend_daycount = 0;
    //     let vacation_daycount = 0;

    //     for( const attend_info of day.events ) {
    //         // 출 퇴근 정보
    //         if( attend_info.attend_type == 10 ) {
    //             attend_daycount = attend_info.daycount;
    //         }
    //         // 휴가 정보
    //         else if( attend_info.attend_type == 20 ) {
    //             vacation_daycount += attend_info.daycount;
    //         }
    //     }

    //     return !this.isRestByAttendDaycount(day) || (attend_daycount - vacation_daycount <= 0);
    // }

    /**
     * 출·퇴근 데이터가 필요 없는 경우 (업무일이 아님)
     */
    // isRestByAttendDaycount(day) : boolean {

    //     let attend_daycount = 0;

    //     for( const attend_info of day.events ) {
    //         // 출 퇴근 정보
    //         if( attend_info.attend_type == 10 ) {
    //             attend_daycount = attend_info.daycount;
    //         }
    //     }

    //     return attend_daycount <= 0;
    // }
    
    /**
     * 이벤트 개수
     */
    getEventCount(day) : number {

        if( day.events == null || day.events.length < 1 ) return 0;
        
        const current_yyyymmdd = moment().format('YYYYMMDD');
        const day_yyyymmdd = moment(day.date).format('YYYYMMDD');
        if( day.events.length == 1 && day.events[0].attend_type == null && Number(current_yyyymmdd) < Number(day_yyyymmdd) ) {
            return 0;
        }

        let count = 0;
        let vacation_daycount = 0;
        let businesstrip_daycount = 0;
        let is_exist_state_10 = false;

        for( const event of day.events ) {
            if( event.attend_type == 20 ) {
                count++;
                vacation_daycount += event.daycount;
            }
            else if( event.attend_type == 30 ) {
                count++;
                businesstrip_daycount += event.daycount;
            }
            else if( event.attend_type == null || event.attend_type == 10 ) {
                count += 2;
                is_exist_state_10 = true;
            }
        }

        // 휴가 합이 1일이 안되는데 출근 정보가 존재하지 않는다면(미체크) count + 2 TODO work_type 조회해서 해당 타입에 맞게 바뀌어야함
        if( is_exist_state_10 == false && (vacation_daycount + businesstrip_daycount) < 1 ) {
            return count + 2;
        }
        
        // 휴가가 1일 이상으로 신청됐다면 무조건 휴가 TODO work_type 조회해서 해당 타입에 맞게 바뀌어야함
        if( (vacation_daycount + businesstrip_daycount) >= 1 ) {
            return 1;
        }

        return count;
    }

    /**
     * 결근 여부
     */
    isAwol(day) : boolean {
        if( day.events == null || day.events.length < 1 ) return false;

        for( const event of day.events ) {
            if( event.attend_type == 10 ) {
                return event.attend_state == '40';
            }
        }

        return false;
    }

    /**
     * 휴가 제목 리스트 반환
     */
    getVacationTitle(events) : string {
        let vacation_name_list = "";
        for( const event of events ) {
            if( vacation_name_list.length > 0 ) vacation_name_list += ", "; 
            vacation_name_list += event.vacation_type_name;
        }
        return vacation_name_list;
    }

    /**
     * count 총
     */
    getTotalCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == '10' ) {
                    count += event.daycount;
                }
                if( event.attend_type == '20' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * count 정상
     */
    getNormalCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == 10 && event.attend_state == '10' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * count 지각
     */
    getLateCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == 10 && event.attend_state == '30' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * count 미체크
     */
    getNoneCheckCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == 10 && event.attend_state == '20' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * count 결근
     */
    getAwolCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == 10 && event.attend_state == '40' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    } 

    /**
     * count 휴가
     */
    getVacationCount() : string {

        let count = 0;

        for( const day of this.computedCalendar ) {

            if( day.events == null ) continue;

            for( const event of day.events ) {
                if( event.attend_type == '20' ) {
                    count += event.daycount;
                }
            }
        }

        return `${count}`;
    }

    /**
     * 근태현황 시작일자 가져오기
     */
    getAttendanceStart() : string {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null ? feature['attendance']['start'] ? feature['attendance']['start'] : '' : '';
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return '';
    }

    /**
     * 근무 안하는날 텍스트 반환 
     */
    getOffText(attend) : string {

        if( attend == null && attend.events == null ) return "휴일";

        const vacation = attend.events.filter(item => item.attend_type == '20');
        const businesstrip = attend.events.filter(item => item.attend_type == '30');

        if( vacation.length > 0 && businesstrip.length > 0 ) {
            return '휴가·출장';
        }

        if( vacation.length > 0 ) {
            return '휴가';
        }

        if( businesstrip.length > 0 ) {
            return '출장';
        }

        return '휴일';
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#attendance_my_list_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

}
