
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { approval_enum, approval_modal_interface } from '@/model/approval';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
})
export default class ApprovalProcessModal extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State approval_process_modal_info !: approval_modal_interface.ApprovalProcessModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalProcessModalInfo ?: (params : approval_modal_interface.ApprovalProcessModalInfo) => void;

    reject_message : string = ""; 

    mounted() : void {
        
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetApprovalProcessModalInfo?.({ show_modal : false });
    }

    /**
     * 처리
     */
    process(state : approval_enum.APPROVAL_APPROVER_STATE) : void {

        if( state == approval_enum.APPROVAL_APPROVER_STATE.REJECT && this.reject_message.trim().length < 1 ) {
            alert("반려 사유를 작성해주세요");
            $('#approval_process_modal #note').focus();
            return;
        }

        this.approval_process_modal_info.callback?.(state, this.reject_message);
        this.close();
    }   

}
