
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class SupplyList extends Mixins(VueHoduCommon) {

    /**
     * computed 비품 리스트
     */
    get computedSupplies() : any[] {
        let list = JSON.parse(JSON.stringify(this.supplies));

        const search_query = this.search_query.toUpperCase().trim();

        if( search_query.length > 0 ) {
            list = list.filter(supply => this.hodu_string_includes(supply.supply_data.name, search_query) ||
                                         this.hodu_string_includes(supply.supply_data.category, search_query) ||
                                         this.hodu_string_includes(supply.supply_data.manager, search_query) || 
                                         this.hodu_string_includes(supply.supply_data.manager_department, search_query) || 
                                         this.hodu_string_includes(supply.supply_data.desc, search_query) || 
                                         this.hodu_string_includes(String(supply.unit_price), search_query) || 
                                         this.hodu_string_includes(String(supply.inbound_count - supply.outbound_count), search_query) || 
                                         this.hodu_string_includes(supply.user_name, search_query));
        }

        return list;
    }

    /**
     * computed 장비 관리 이력 리스트
     */
    get computedManagements() : any[] {
        let list = JSON.parse(JSON.stringify(this.managements));

        const search_query = this.search_query.toUpperCase().trim();

        if( search_query.length > 0 ) {
            list = list.filter(management => this.hodu_string_includes(management.supply.supply_data.name, search_query) ||
                                             this.hodu_string_includes(management.supply.supply_data.category, search_query) ||
                                             this.hodu_string_includes(management.management_data.desc, search_query) ||
                                             this.hodu_string_includes(management.user_name, search_query));
        }

        if( this.filter_open == true ) {
            
            // 날짜 필터
            list = list.filter(management => this.hodu_date_to_format_string(this.filter_start, "YYYYMMDD") <= this.hodu_date_to_format_string(management.audit_created, 'YYYYMMDD') && 
                                             this.hodu_date_to_format_string(management.audit_created, 'YYYYMMDD') <= this.hodu_date_to_format_string(this.filter_end, "YYYYMMDD"))

            if( this.filter_type != null && this.filter_type.length > 0 ) {
                list = list.filter(management => management.management_data.type == this.filter_type);
            }

            if( this.filter_category != null && this.filter_category.length > 0 ) {
                list = list.filter(management => management.supply.supply_data.category == this.filter_category);
            }

            if( this.filter_vendor != null && this.filter_vendor.trim().length > 0 ) {
                list = list.filter(management => management.management_data.vendor != null && this.hodu_string_includes(management.management_data.vendor, this.filter_vendor));
            }

        }

        return list;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetSupplyManagementDetailModalInfo ?: (params : hodu_home_modal_info.SupplyManagementDetailModalInfo) => void;

    tab : string = "SUPPLY";

    supplies : any[] = [];
    managements : any[] = [];
    categories : any[] = [];

    scroll_height : number = 0;

    search_query : string = "";

    filter_open : boolean = false;
    filter_start : Date = new Date();
    filter_end : Date = new Date();
    filter_type : string = "";
    filter_category : string = "";
    filter_vendor : string = "";

    beforeMount() : void {
        this.filter_start = new Date();
        this.filter_end   = new Date(this.filter_start);
        this.filter_start.setDate(this.filter_start.getDate() - 7);
    }

    mounted() : void {

        if( this.tab == 'SUPPLY' ) {
            this.getHomeSupplies();
        }
        else {
            this.getHomeSupplyManagements();
        }

        this.setScroll();

        // //메뉴 탭
        // $('.titleDiv h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.fixedDiv_con > ul').hide();
        // 	$('.fixedDiv_con > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // });

        // //필터 on/off
        // $('.bt_filter').off("click").on("click", function(){
        // 	if($(".bt_filter, .nav_filter").hasClass("on")){
        //         $(".bt_filter, .nav_filter").removeClass("on");
        //     } else {
        //         $(".bt_filter, .nav_filter").addClass("on");
        //     }
        // });
        
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const title_div_height = $('.titleDiv').outerHeight();
        const sort_header_height = $('.sortHeader').outerHeight();
        const filter_tap_height = $('.filter_tap').outerHeight();

        this.scroll_height = window.innerHeight - (title_height ? title_height : 0)
                                                - (title_div_height ? title_div_height : 0)
                                                - (sort_header_height ? sort_header_height : 0)
                                                - (filter_tap_height ? filter_tap_height : 0);
    }

    /**
     * 비품 리스트 조회
     */
    async getHomeSupplies() : Promise<void> {
        
        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply`, API_METHOD.GET);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.supplies ) {
                throw new Error("비품 리스트 조회 중 오류 발생");
            }

            this.supplies.splice(0, this.supplies.length);
            this.supplies = this.supplies.concat(response.data.data.supplies);

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 리스트 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 비품 관리 이력 리스트 조회
     */
    async getHomeSupplyManagements() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/supply/management`, API_METHOD.GET);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.managements ) {
                throw new Error("비품 관리 이력 리스트 조회 중 오류 발생");
            }

            this.managements.splice(0, this.managements.length);
            this.managements = this.managements.concat(response.data.data.managements);

            if( response.data.data.managements ) {
                this.categories.splice(0, this.categories.length);
                this.categories = this.categories.concat(response.data.data.categories);
            }

        } catch(e) {
            this.hodu_show_dialog('cancel', "비품 관리 이력 리스트 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 탭 변경
     */
    tabChange(tab) : void {
        this.search_query = "";
        this.filter_open = false;
        this.tab = tab;

        if( this.tab == 'SUPPLY' ) {
            this.getHomeSupplies();
        } 
        else {
            this.getHomeSupplyManagements();
        }

        this.$nextTick(() => this.setScroll());
    }

    /**
     * 재고수 반환
     */
    getStock(current_management) : number {

        let filter = this.managements.filter(management => management.uid == current_management.uid);

        let index = -1;
        for( const management of filter ) {
            if( management.uid == current_management.uid && management.seq == current_management.seq ) index = filter.indexOf(management);
        }

        if( index < 0 ) {
            return 0; 
        }

        let stock : number = 0;
        let array_count : number = filter.length;

        for( let i = (array_count - 1); i >= index; i-- ) {
            const management = filter[i]
            if ( management.management_data.type == 'inbound' ) stock += management.management_data.count;
            if ( management.management_data.type == 'outbound' ) stock -= management.management_data.count;
        }

        return stock;
    }

    /**
     * 필터 열기 / 닫기
     */
    filterOnOff() : void {
        this.filter_open = !this.filter_open;

        if( this.filter_open == true ) this.datepickerInit();
        this.$nextTick(() => this.setScroll());
    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {

            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(selected_date)}`);

                    switch ( inst.id ) {
                        case "supplyFil_calendar_start":
                            this.filter_start = new Date(selected_date);
                            if( this.hodu_date_to_format_string(this.filter_start, 'YYYYMMDD') > this.hodu_date_to_format_string(this.filter_end, 'YYYYMMDD') ) {
                                this.filter_end = new Date(this.filter_start);
                            }
                            break;

                        case "supplyFil_calendar_end":
                            this.filter_end = new Date(selected_date);
                            if( this.hodu_date_to_format_string(this.filter_start, 'YYYYMMDD') > this.hodu_date_to_format_string(this.filter_end, 'YYYYMMDD') ) {
                                this.filter_start = new Date(this.filter_end);
                            }
                            break;
                    }

                },
            }

            // @ts-ignore
            $('#supplyFil_calendar_start, #supplyFil_calendar_end').datepicker(datepicker_option);
        });
    }

    /**
     * 비품 입·출고 내역 상세
     */
    showSupplyManagementDetail(uid, seq) : void {
        this.doSetSupplyManagementDetailModalInfo?.({
            show_modal : true,
            uid : uid,
            seq : seq,
            callback : this.getHomeSupplyManagements
        });
    }

    /**
     * 비품 상세
     */
    moveSupplyDetail(uid) : void {
        this.hodu_router_push(`/home/supply/${uid}`);
    }

    /**
     * 비품 생성
     */
    moveSupplyCreate() : void {
        this.hodu_router_push(`/home/supply/create`);
    }

    /**
     * 비품 입·출고 내역 등록
     */
    moveSupplyManagementCreate(type) : void {
        this.hodu_router_push(`/home/supply/all/management/${type}/create`);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        this.$nextTick(() => this.setScroll());
    }

}
