
import { Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonColorPickerModalInfo } from '../../../store/modules/ModalInfo';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class CommonColorPickerModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State common_color_picker_modal_info !: CommonColorPickerModalInfo
    
    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetCommonColorPickerModalInfo ?: (parms : CommonColorPickerModalInfo) => void;

    created() : void {
    }

    mounted() : void {
        // $(".clrUl li a").click(function(){
        //     $(this).parent().parent().parent().siblings().find("a").removeClass("on");
        //     $(this).parent().siblings().find("a").removeClass("on");
        //     $(this).addClass("on");
        // });
    }

    selectColor(ps_color: string) : void {
        this.common_color_picker_modal_info.selected_color = ps_color;
    }

    saveColor() : void {
        window['applyColor']?.(this.common_color_picker_modal_info.selected_color);
        this.cancel();
    }

    cancel() : void {
        delete(window['applyColor']);
        this.doSetCommonColorPickerModalInfo?.({ show_modal : false, selected_color : "", is_dc_only : false });
    }

}
