
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { hodu_home_modal_info } from '@/model/hoduhome';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class VisitantListAmano extends Mixins(VueHoduCommon) {
    
    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetHomeVisitantAmanoInfoModalInfo ?: (params : hodu_home_modal_info.HomeVisitantAmanoInfoModalInfo) => void;

    start_date : Date = new Date();
    end_date : Date = new Date();

    car_list : any[] = [];

    beforeMount() : void {
        this.end_date = moment().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
        this.start_date = moment(this.end_date).set('date', this.end_date.getDate() - 7).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
    }

    mounted() : void {
        this.datepickerInit();
        this.getPreDiscountListInfo();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight      : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight  : number | undefined = $('.settingBg').outerHeight();
        const sortHeaderHeight : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hoduhome_list_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (titleHeight ? titleHeight : 0)
                                           - (settingBgHeight ? settingBgHeight : 0)
                                           - (sortHeaderHeight ? sortHeaderHeight : 0),
        });
    }

    /**
     * 차량 조회
     */
    async getParkingTcktCarList(carNo4Digit : string, startDtm : string, endDtm : string) : Promise<any> {

        try {

            const body = {
                "url" : "interop/getParkingTcktCarList.do",
                "method" : API_METHOD.POST,
                "request_body" : {
                    "lotAreaNo": 20,
                    "carNo4Digit" : carNo4Digit,
                    "customerSearchType" : 0,
                    "fromInDtm": startDtm,
                    "toInDtm": endDtm,
                    // "passType" : "visitor"
                }
            }

            return this.hodu_api_call(`api/v1/amano`, API_METHOD.POST, body, false);

            // console.log(response);

            // if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.response ) {
            //     throw new Error("방문차량 조회 중 오류 발생");
            // }

            // const amano_response = response.data.data.response;

            // console.log(amano_response);

        } catch(e) {
            this.hodu_show_dialog('error', "방문 차량 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 방문 차량 조회
     */
    async getPreDiscountListInfo() : Promise<void> {
        try {

            const body = {
                "url" : "interop/getPreDiscountListInfo.do",
                "method" : API_METHOD.POST,
                "request_body" : {
                    "lotAreaNo": 20,
                    "fromRegiDtm": this.hodu_date_to_format_string(moment(this.start_date).set('month', this.start_date.getMonth() - 1).toDate(), 'YYYYMMDDHHmmss'),
                    "toRegiDtm": this.hodu_date_to_format_string(moment(this.end_date).set('month', this.start_date.getMonth() + 1).toDate(), 'YYYYMMDDHHmmss')
                }
            }

            const response = await this.hodu_api_call(`api/v1/amano`, API_METHOD.POST, body);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.response ) {
                throw new Error("방문차량 조회 중 오류 발생");
            }

            const amano_response = response.data.data.response;

            console.log(amano_response);

            if( !amano_response.data || !amano_response.data.list ) {
                throw new Error("방문 차량 조회 중 오류 발생");
            }

            this.car_list.splice(0, this.car_list.length);
            this.car_list = this.car_list.concat(amano_response.data.list.sort((o1, o2) : number => {

                const o1_startDtm = o1.startDtm;
                const o2_startDtm = o2.startDtm;

                if( o1_startDtm > o2_startDtm ) return -1;
                if( o1_startDtm < o2_startDtm ) return 1;

                return 0;
            }).filter(item => item.startDtm >= this.hodu_date_to_format_string(this.start_date, 'YYYYMMDDHHmmss') && item.startDtm <= this.hodu_date_to_format_string(this.end_date, 'YYYYMMDDHHmmss')));

            const promise_array : Promise<any>[] = [];

            for( const car of this.car_list ) {
                const car_number = car.carNo;
                const car_start_dtm = car.startDtm;
                const car_end_dtm = car.endDtm;

                if( !car_number && car_number.length < 1 ) {
                    car.state = 'EXPECTED';
                    this.$forceUpdate();
                    continue
                }

                const promise : Promise<any> = this.getParkingTcktCarList(car_number.substring(car_number.length - 4), car_start_dtm, car_end_dtm).then((response) => {
                    try {
                        console.log(response);

                        if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.response ) {
                            throw new Error("주차 차량 조회 중 오류 발생");
                        }

                        const amano_response = response.data.data.response;

                        console.log(amano_response);

                        if( !amano_response.data || !amano_response.data.list ) {
                            throw new Error("주차 차량 조회 중 오류 발생");
                        }

                        const parking_car_list = amano_response.data.list.filter(item => item.carNo == car_number);

                        if( parking_car_list.length < 1 ) {
                            car.state = "EXPECTED";
                            this.$forceUpdate();
                            return;
                        }

                        const parking_car = parking_car_list[0];

                        if( parking_car.outDtm && parking_car.outDtm.length > 0 ) {
                            car.state = 'OUT';
                            car.inDtm = parking_car.inDtm;
                            car.outDtm = parking_car.outDtm;
                        } 

                        else if( parking_car.inDtm && parking_car.inDtm.length > 0 ) {
                            car.state = 'IN';
                            car.inDtm = parking_car.inDtm;
                        }

                        this.$forceUpdate();

                    } catch(e) {

                    }
                });

                promise_array.push(promise);
            }

            Promise.all(promise_array);

        } catch(e) {
            this.hodu_show_dialog('error', "방문 차량 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * datepikcer 설정
     */
    datepickerInit() : void {
        const vue = this;

        const datepicker_option = {
            inline: false,
            showOtherMonths: true,
            selectOtherMonths: true,
            dateFormat: 'yy-mm-dd',
            monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
            yearSuffix : '.',
            blankSpace : '',
            changeYear : true,
            yearRange  : '1970:2050',
            onSelect: function (dateText, inst) {
                const id : string | undefined = $(this).attr('id');
                const mnt : moment.Moment = moment(dateText).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
                inst.input.val(mnt.format('YYYY.MM.DD') + ' ' + vue.getDayOfWeekByDate(mnt.toDate()));
                
                // 시작일 변경
                if( id == 'visitant_fr_ymd' ) {
                    vue.start_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.end_date = moment(vue.start_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();
                    }
                } 

                // 종료일 변경
                else if( id == 'visitant_to_ymd' ) {
                    vue.end_date = mnt.toDate();

                    if( vue.start_date.getTime() > vue.end_date.getTime() ) {
                        vue.start_date = moment(vue.end_date).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate();
                    }
                }

                vue.end_date = moment(vue.end_date).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate();

                vue.getPreDiscountListInfo();
            },
        }

        // @ts-ignore
        $('#visitant_fr_ymd').datepicker(datepicker_option);

        // @ts-ignore
        $('#visitant_to_ymd').datepicker(datepicker_option);
    }

    /**
     * 방문 날짜
     */
    getVisitDate(car) : string {
        const start = car.startDtm;
        const date = new Date([start.substring(0,4), start.substring(4,6), start.substring(6,8)].join('-'));
        return `${this.hodu_date_to_format_string(date, 'YY.MM.DD')} ${this.getDayOfWeekByDate(date)}`;
    }

    /**
     * 방문 예정 날짜 오전/오후
     */
    getVisitAmPm(car) : string {
        const start = car.startDtm;

        const hour = Number(start.substring(8,10));

        return hour >= 0 && hour <= 11 ? "오전" : "오후";
    }

    /**
     * 방문 예정 날짜 시간
     */
    getVisitTime(car) : string {
        const start = car.startDtm;

        const hour = Number(start.substring(8,10));
        const min = Number(start.substring(10,12));

        const is_am = hour >= 0 && hour <= 11;

        let hour_min = "";
        if( is_am && hour == 0 ) {
            hour_min = `12:${`0${min}`.slice(-2)}`;
        }
        else if( is_am || (!is_am && hour == 12) ) {
            hour_min = `${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)}`;
        }
        else {
            hour_min = `${`0${hour - 12}`.slice(-2)}:${`0${min}`.slice(-2)}`;
        }

        return hour_min;
    }

    /**
     * 방문기간
     */
    getVisitPeriod(car) : string {
        const start = car.startDtm;
        const end = car.endDtm;

        const start_yyyy_mm_dd = [start.substring(0,4), start.substring(4,6), start.substring(6,8)].join('-');
        const start_hh_mm_ss = [start.substring(8,10), start.substring(10,12), start.substring(12,14)].join(':');

        const end_yyyy_mm_dd = [end.substring(0,4), end.substring(4,6), end.substring(6,8)].join('-');
        const end_hh_mm_ss = [end.substring(8,10), end.substring(10,12), end.substring(12,14)].join(':');

        const start_date = moment(`${start_yyyy_mm_dd} ${start_hh_mm_ss}`).toDate();
        const end_date = moment(`${end_yyyy_mm_dd} ${end_hh_mm_ss}`).toDate();

        const period_ms = end_date.getTime() - start_date.getTime();

        // 시간 차이 60분(1시간) 미만
        if( period_ms < 60 * 60 * 1000 ) {
            return `${Math.floor(period_ms / 60 / 1000)}분`;
        }

        // 시간 차이 24시간(1일) 미만
        if( period_ms < 24 * 60 * 60 * 1000 ) {
            return `${Math.floor(period_ms / 60 / 60 / 1000)}시간`;
        }

        // 시간 차이 24시간(1일) 이상
        return `${Math.floor(period_ms / 24 / 60 / 60 / 1000)}일`;
    }

    /**
     * 차량 상태값 텍스트 반환
     */
    getCarStateText(state) : string {

        switch(state) {
            case "EXPECTED": return "예정";
            case "IN": return "입차";
            case "OUT": return "출차";
        }

        return '';
    }

    /**
     * 방문차량 정보 모달
     */
    showVisitantAmanoInfoModalInfo(car) : void {

        if( !car.state ) return;

        this.doSetHomeVisitantAmanoInfoModalInfo?.({
            show_modal : true,
            amano_info : {
                "car" : car
            }
        })
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $("#hoduhome_list_scroll").mCustomScrollbar('destroy');

    	this.setScroll();
    }

}
