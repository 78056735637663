
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE, GROUP_TYPE, API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const AttendanceInfo = namespace('AttendanceInfo');
const ApprovalInfo = namespace('ApprovalInfo');
const OrganizationInfo = namespace('OrganizationInfo');
const HoduDocInfo = namespace('HoduDocInfo');

import LeftDatePicker   from "@/components/left/LeftDatePicker.vue";
import LeftGroupFilter  from "@/components/left/LeftGroupFilter.vue";
import LeftGroupList    from "@/components/left/LeftGroupList.vue";
import LeftGroupMember  from "@/components/left/LeftGroupMember.vue";
import LeftWork         from "@/components/left/LeftWork.vue";
import LeftMessage      from "@/components/left/LeftMessage.vue";
import LeftGroupNotice  from "@/components/left/LeftGroupNotice.vue";
// import LeftGroupFile    from "@/components/left/LeftGroupFile.vue";
import LeftGroupFileNew from "@/components/left/LeftGroupFileNew.vue";
import LeftAdminPage    from "@/components/left/LeftAdminPage.vue";

import { ResizeObserver } from 'vue-resize';
import { hodu_local_storage } from '@/lib/HoduLocalStorage';
import { hodu_cookie } from '@/lib/HoduCookie';

declare let Kakao : any;
import '@/assets/js/kakao'

import { attendance_enum } from '@/model/attendance';
import { approval_enum } from '@/model/approval';
import { organization_enum } from '@/model/organization';

@Component({
    components : {
        LeftDatePicker, LeftGroupFilter, LeftGroupList, LeftGroupMember, LeftWork, LeftMessage, LeftGroupNotice, 
        LeftGroupFileNew, LeftAdminPage,
        ResizeObserver
    }
})
export default class Left extends Mixins(VueHoduCommon) {
    
    /** 
     * @CalendarInfo.Action 
     */ 
    @CalendarInfo.Action doSetStartDate ?: any; // start_date 업데이트

    /** 
     * @AttendanceInfo.Action 
     */ 
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarViewType    ?: (params : attendance_enum.ATTENDANCE_CALENDAR_TYPE) => void;

    /** 
     * @ApprovalInfo.Action 
     */ 
    @ApprovalInfo.Action doSetSelectedTab        ?: (params : approval_enum.APPROVAL_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedDraftTab   ?: (params : approval_enum.APPROVAL_DRAFT_BOX_TAB) => void;
    @ApprovalInfo.Action doSetSelectedReceiveTab ?: (params : approval_enum.APPROVAL_RECEIVE_BOX_TAB) => void;

    /**
     * @OrganizationInfo.Action
     */
    @OrganizationInfo.Action doSetOrganizationSelectedTab ?: (params : organization_enum.ORGANIZATION_SETTING_TAB) => void;
    @OrganizationInfo.Action doSetOrganizationSelectedVacationTab ?: (params : organization_enum.ORGANIZATION_VACATION_TAB) => void;

    /**
     * @HoduDocInfo.Action
     */
    @HoduDocInfo.Action doSetDoctorAndDepartmentTab ?: (parms : "DOCTOR" | "DEPARTMENT") => void;
    @HoduDocInfo.Action doSetPatientTab             ?: (parms : "LIST" | "SEND_MESSAGE" | "MESSAGE_TEMPLATE" | "PATIENT_FILEBOX") => void;
    @HoduDocInfo.Action doSetHospitalSettingTab     ?: (parms : "BASIC" | "TIME" | "HOLIDAY" | "OFF") => void;

    admin_flag : boolean = false;
    chat_unread_count : number = 0;

    my_new_meesage_interval : number | undefined;

    async mounted() {
        this.left_inbox02_mCustomScrollbar();

        this.admin_flag = await this.getAdminCheck();

        window['left_inbox02_mCustomScrollbar'] = this.left_inbox02_mCustomScrollbar;
        window['myNewMessage'] = this.myNewMessage;

        this.myNewMessage();
        
        // 20초마다 채팅 조회
        this.my_new_meesage_interval = window.setInterval(async() => {
            this.myNewMessage();
        }, 20000);

        if( this.scope != null && this.scope.length > 0 && ( this.scope == OWNER_TYPE.GROUP || this.scope == OWNER_TYPE.TEAM ) ) { 
            if( $('#bt_calContr').hasClass('on') == false ) {
                $('#container').find('#left_area').find('.left_inbox').addClass('on');
                $('.ui-datepicker-calendar-wrap').hide();
                $('#bt_calContr').addClass('on');

                // const left_area_height : number | undefined = $('#left_area').outerHeight();
                // const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

                // const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
                // const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

                // let heightSize : number = left_area_height_final - left_inbox_height_final;
                // $('.left_inbox02').height(heightSize+"px");

                setTimeout(() => {
                    this.$nextTick(() => {
                        // @ts-ignore
                        $('.left_inbox02').mCustomScrollbar('destroy');
                        this.left_inbox02_mCustomScrollbar();
                    });
                }, 150);
            }
        }
        
        // this.$nextTick(() => {
        //     /*
        //     호두웍스 클릭 slideToggle
        //     */
        //     $(".works_box").click(function(e){
        //         e.preventDefault();
        //         $(".left_menu").slideToggle(300);
        //         $(".bt_worksContr").toggleClass("on");
        //     });
        //     return false;
        // });
    }

    /**
     * 종료 전 interval 제거
     */
    beforeDestroy() : void {
        if( this.my_new_meesage_interval ) { clearInterval(this.my_new_meesage_interval); }
    }

    /**
     * 스크롤
     */
    left_inbox02_mCustomScrollbar() : void {
        const left_area_height  : number | undefined = $('#left_area').outerHeight();
        const header_height     : number | undefined = $('.headerTitleDiv').outerHeight();
        const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

        const left_area_height_final  : number = left_area_height  == null ? 0 : left_area_height;
        const header_height_final     : number = header_height     == null ? 0 : header_height;
        const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

        let heightSize : number = left_area_height_final - left_inbox_height_final - header_height_final;
        let scrollerOption = {
            axis : 'y',
            setHeight : this.left_control_box_flag == false ? heightSize : left_area_height_final,
            scrollbarPosition : 'outside',
        };

        // @ts-ignore
        $('.left_inbox02').mCustomScrollbar(scrollerOption);
    }

    /**
     * 개인 달력으로 돌아가기
     */
    async goPersonalCalendar() : Promise<void> {

        // HODU D 에서는 바로 로그아웃한다
        if( this.scope_group_team_option != null && this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD ) {
            this.logout();
            return;
        }

        // 관리자모드 HODU H 에서는 바로 로그아웃한다
        if( this.scope_group_team_option.login_mode == 'HOME' && this.scope_group_team_option != null && this.scope_group_team_option.biz_type == GROUP_TYPE.BIZH ) {
            this.logout();
            return;
        }

        // kids.houdware.com 에서는 바로 로그아웃
        if( this.scope_group_team_option.login_mode == 'KIDS' ) {
            this.logout();
            return;
        }

        // 호두 프리미엄 그룹 도메인 서비스에서는 바로 로그아웃한다
        if( this.scope_group_team_option != null && this.scope_group_team_option.is_domain_service == true ) {
            this.logout();
            return;
        }

        // scope 등을 쓰는 computed 데이터가 있을때 먼저 이동 하지 않으면 오류 발생함
        this.hodu_router_push(`/?${new Date().getTime()}`, async() => {

            if( $('#bt_calContr').hasClass('on') == true ) {
                $('#container').find('#left_area').find('.left_inbox').removeClass('on');
                $('.ui-datepicker-calendar-wrap').show();
                $('#bt_calContr').removeClass('on');

                const left_area_height : number | undefined = $('#left_area').outerHeight();
                const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

                const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
                const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

                let heightSize : number = left_area_height_final - left_inbox_height_final;
                $('.left_inbox02').height(heightSize+"px");
            }
            
            this.doSetGroupMenuClose(false);

            this.doSetStartDate(new Date());
            this.doSetCalendarId(`personal-${this.user_id}`);
            this.doSetScope(OWNER_TYPE.PERSONAL);  
            this.doSetScopeGroupId(0);  
            this.doSetScopeTeamId(0);  
            this.doSetScopeGroupTeamOption({
                group_team_name : '',
                group_team_color : '#FFFFFF',
                group_team_image : '',
                group_team_descript : '',
                biz_id : '',
                biz_type : ''
            });

            await this.hodu_loading_timer_exit();
            await this.hodu_loading();

            setTimeout(() => {
                // @ts-ignore
                $('.left_inbox02').mCustomScrollbar('destroy');
                this.left_inbox02_mCustomScrollbar();
            }, 100);

            if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
            
        });
    }   

    /**
     * 로그아웃
     */
    async logout() : Promise<void> {
    
        try {
            if( this.user_type == "KAKAOTALK" ) { 
                if(!Kakao.isInitialized()){ await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
                Kakao.Auth.logout((data) => {}); 
            }
            
            // 자동로그인 관련 설정
            hodu_local_storage.setItem('auto_login_flag', String(false));
            hodu_cookie.delete("auto_login_flag");
            await this.hodu_api_call(`/api/v1/auth/logout`, API_METHOD.GET)
                .then(async(response) => {

                    this.hodu_router_push('/login', () => {
                        
                        // 세션 없애기
                        if(sessionStorage != null && localStorage != null){
                            sessionStorage.setItem("session_token", "");
                            localStorage.setItem("session_token"  , "");
                            hodu_cookie.delete("session_token");
                        }
                        
                        // 로그인 정보 없애기
                        this.doLogin({
                            isLogin           : false,
                            user_id           : 0,
                            user_type         : "",
                            user_email        : "",
                            user_name         : "",
                            user_phone_number : "",
                            country_code      : "",
                            is_temp_password  : false,
                            user_preference   : null,
                            user_group_role   : null,
                            user_team_role    : null,
                            template_map      : null,
                            auth_info         : {
                                auth_check: false, 
                                auth_check_date: null, 
                                auth_delay_date: null, 
                                auth_check_later: false
                            },
                            schedule_search_config : {
                                past_schedule     : true,
                                personal_schedule : true,
                                my_schedule       : true,
                                shared_schedule   : true,
                                group_schedule    : true,
                                hodu_c_schedule   : true,
                                group_filter      : [],
                                team_filter       : [],    
                            }
                        });

                        // 달력 정보 없애기
                        this.doSetStartDate(new Date());
                        this.doSetCalendarId('');
                        this.doSetScope('');  
                        this.doSetScopeGroupId(0);  
                        this.doSetScopeTeamId(0);  
                        this.doSetScopeGroupTeamOption({
                            group_team_name : '',
                            group_team_color : '#FFFFFF',
                            group_team_image : '',
                            group_team_descript : '',
                            biz_id : '',
                            biz_type : ''
                        }); 
                        
                        // datepicker 남아 있는거 제거
                        $('#ui-datepicker-div').remove();
                    });
                    
                })
                .catch(async(e)=>{
                    this.hodu_error_process(e, false, false);
                });

        } catch(e){
            this.hodu_error_process(e, false, false);
        }
    }

    /**
     * 파일함 보일지 여부
     */
    getFileVisibleFlag() : boolean {

        // 개인 달력에서는 무조건 보임
        if( this.scope == OWNER_TYPE.PERSONAL ) {
            return true;
        }

        // 호두 D에서는 무조건 안 보임
        if( this.scope_group_team_option != null && this.scope_group_team_option.biz_type == GROUP_TYPE.BIZD ) {
            return false;
        }

        // 그룹달력에서는 그룹 기능이 켜져있고 읽기 권한이 있다면
        if( this.scope != OWNER_TYPE.PERSONAL && this.isEnableGroupFeature(this.scope_group_id, 'filebox') && this.is_group_permmision(this.scope_group_id, 'file', 'read') ) {
            return true;
        }
        
        return false;
    }

    /**
     * 업무 권한 체크 (업무일지, 회의록, 프로젝트의 읽기, 쓰기 권한 중 하나라도 허용인 경우에 보임) => READ로만 체크하도록 변함 나중에 필요시 create 사용
     */
    getWorkPermission() : boolean {
        let report_permission : boolean = false;
        let meetinglog_permission : boolean = false;
        let work_permission : boolean = false;
        
        // 그룹
        if( this.scope == OWNER_TYPE.GROUP ) {
            // work_permission = this.is_group_permmision(this.scope_group_id, 'work', 'create') || this.is_group_permmision(this.scope_group_id, 'work', 'read');
            report_permission = this.is_group_permmision(this.scope_group_id, 'report', 'read');
            meetinglog_permission = this.is_group_permmision(this.scope_group_id, 'meetinglog', 'read');
            work_permission = this.is_group_permmision(this.scope_group_id, 'work', 'read');
        } 
        
        // 팀
        else if( this.scope == OWNER_TYPE.TEAM ) {
            // work_permission = this.is_team_permmision(this.scope_team_id, 'work', 'create') || this.is_team_permmision(this.scope_team_id, 'work', 'read');
            report_permission = this.is_team_permmision(this.scope_team_id, 'report', 'read');
            meetinglog_permission = this.is_team_permmision(this.scope_team_id, 'meetinglog', 'read');
            work_permission = this.is_team_permmision(this.scope_team_id, 'work', 'read');
        }

        return report_permission || meetinglog_permission || work_permission;
    }

    /**
     * 대시보드로 이동
     */
    moveGroupDashboard() : void {
        this.doGroupId(this.scope_group_id);
        this.doTeamId(this.scope_team_id);
        this.hodu_router_push(`/group/${new Date().getTime()}/detail`);
    }

    /**
     * 달력
     */
    goCalendar() : void {
        this.hodu_router_push(`/?${new Date().getTime()}`);
    }

    /**
     * 환경설정
     */
    goCustomerSetting() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        this.hodu_router_push("/setting");
    }

    /**
     * 예약 리스트
     */
    goAppointmentList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        this.hodu_router_push(`/hospital/${new Date().getTime()}/appointment`);
    }

    /**
     * 의사 및 진료과
     */
    goDoctorList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        if( this.doSetDoctorAndDepartmentTab ) { this.doSetDoctorAndDepartmentTab('DOCTOR'); }
        this.hodu_router_push(`/hospital/${new Date().getTime()}/doctor`);
    }

    /**
     * 환자 리스트
     */
    goPatientList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        if( this.doSetPatientTab ) { this.doSetPatientTab("LIST"); }                
        this.hodu_router_push(`/hospital/${new Date().getTime()}/patient`);
    }

    /**
     * 병원 설정
     */
    goHospitalSetting() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        if( this.doSetHospitalSettingTab ) { this.doSetHospitalSettingTab("BASIC"); }
        this.hodu_router_push(`/hospital/${new Date().getTime()}/setting`);
    }

    goSurvey() {
        this.hodu_router_push(`/group/${new Date().getTime()}/survey`);
    }

    /**
     * 채팅 안 읽은 개수 가져오기
     */
    async myNewMessage() {
        try {
            const response = await this.hodu_api_call('/api/v1/chat/channel_info/my/unread', API_METHOD.GET, null, false);

            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || 
                response.data.data.unread_item == null || response.data.data.unread_item.unread_count == null ) {
                throw new Error("채팅 안 읽은 개수 조회 실패");
            }

            this.chat_unread_count = response.data.data.unread_item.unread_count;

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구
     */
    goFriend() : void {
        this.hodu_router_push(`/friend?${new Date().getTime()}`);
    }

    /**
     * 채팅
     */
    goChatting() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        this.hodu_router_push(`/chatting/${new Date().getTime()}`);
    }

    /**
     * 임시 보관함
     */
    goTemp() : void {
        this.hodu_router_push(`/temp?${new Date().getTime()}`);
    }

    /**
     * 입주자 리스트
     */
    goResidentList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }

        this.doGroupId(this.scope_group_id);
        this.doTeamId(this.scope_team_id);
        this.doGroupTeamOption({
            group_team_name : this.scope_group_team_option.group_team_name,
            group_team_color : this.scope_group_team_option.group_team_color,
            group_team_image : this.scope_group_team_option.group_team_image,
            group_team_descript : this.scope_group_team_option.group_team_descript,
            biz_id : this.scope_group_team_option.biz_id
        });

        this.hodu_router_push(`/group/${new Date().getTime()}/member`);
    }

    residentMenuOnOff() : void {
        this.doSetResidentMenuClose?.(!this.resident_menu_close);
    }

    /**
     * 입주자 차량 리스트
     */
    goResidentCarList() : void {
        this.hodu_router_push(`/home/resident/car?${new Date().getTime()}`);
    }

    /**
     * 입주 예약 현황
     */
    goResidentMoveIntoRequest() : void {
        this.hodu_router_push(`/home/resident/request?${new Date().getTime()}`);
    }

    /**
     * 입주 예약 설정
     */
    goResidentMoveIntoRequestSetting() : void {
        this.hodu_router_push(`/home/resident/request/setting?${new Date().getTime()}`);
    }

    /**
     * 입주 예약 설정
     */
    goResidentJoin() : void {
        this.hodu_router_push(`/home/resident/join?${new Date().getTime()}`);
    }

    /**
     * 방문차량 리스트
     */
    goVisitantList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }

        // if( this.isHoduAmano(this.scope_group_id) ) {
        //    this.hodu_router_push(`/home/visitant/${new Date().getTime()}/amano`);
        // }
        // else {
            this.hodu_router_push(`/home/visitant/${new Date().getTime()}`);
        // }
    }

    /**
     * 호두홈 안내방송
     */
    goAnnouncement() : void {
        this.hodu_router_push(`/home/announcement?${new Date().getTime()}`);
    }

    /**
     * 호두홈 장비이력
     */
    goEquipment() : void {
        this.hodu_router_push(`/home/equipment?${new Date().getTime()}`);
    }

    /**
     * 호두홈 비품관리
     */
    goSupply() : void {
        this.hodu_router_push(`/home/supply?${new Date().getTime()}`);
    }

    /**
     * 아파트 설정
     */
    goApartmentSetting() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }
        this.hodu_router_push(`/home/apartment/setting/${new Date().getTime()}`);
    }

    /**
     * 전자결재
     */
    moveApproval() : void {
        this.doSetSelectedTab?.(approval_enum.APPROVAL_BOX_TAB.DRAFT);
        this.doSetSelectedDraftTab?.(approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
        this.doSetSelectedReceiveTab?.(approval_enum.APPROVAL_RECEIVE_BOX_TAB.WAIT);
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval?${new Date().getTime()}`);
    }

    /**
     * 전자결재 설정
     */
    moveOrganizationSetting() : void {
        this.doSetOrganizationSelectedTab?.(organization_enum.ORGANIZATION_SETTING_TAB.RANK);
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/organization/setting?${new Date().getTime()}`);
    }

    /**
     * 근태 현황
     */
    moveAttendance() : void {
        this.doSetAttendanceStartDate(new Date());
        this.doSetCalendarViewType?.((this.get_group_role(this.scope_group_id) == 'GROUP_MANAGER' || this.get_group_role(this.scope_group_id) == 'ADMIN') ? attendance_enum.ATTENDANCE_CALENDAR_TYPE.DAYGRID_MONTH : attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST);
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/attendance?${new Date().getTime()}`);
    }

    /**
     * 휴가 현황
     */
    // moveOrganizationVacation() : void {

    //     if( (this.get_group_role(this.scope_group_id) == 'GROUP_MANAGER' || this.get_group_role(this.scope_group_id) == 'ADMIN') ) {
    //         this.doSetOrganizationSelectedVacationTab?.(organization_enum.ORGANIZATION_VACATION_TAB.ADMIN);
    //     }
    //     else {
    //         this.doSetOrganizationSelectedVacationTab?.(organization_enum.ORGANIZATION_VACATION_TAB.USER);
    //     }

    //     this.hodu_router_push(`/GROUP/${this.scope_group_id}/organization/vacation`);
    // }

    /**
     * 호두키즈 선생님
     */
    moveKidsTeacher() : void {
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/kids/teacher?${new Date().getTime()}`);
    }

    /**
     * 호두키즈 원아
     */
    moveKidsList() : void {
        if( this.get_group_role(this.scope_group_id) == 'GROUP_USER' ) {
            this.hodu_router_push(`/GROUP/${this.scope_group_id}/kids/kid/parent?${new Date().getTime()}`);
            return;
        }
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/kids?${new Date().getTime()}`);
    }

    /**
     * 호두키즈 출석부
     */
    moveKidsAttendance() : void {
        this.doSetAttendanceStartDate(new Date());
        this.doSetCalendarViewType?.(this.get_group_role(this.scope_group_id) == 'GROUP_USER' ? attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST : attendance_enum.ATTENDANCE_CALENDAR_TYPE.DAYGRID_MONTH);
        this.hodu_router_push(`/GROUP/${this.scope_group_id}/kids/attendance?${new Date().getTime()}`);
    }

    /**
     * datepicker example로 이동
     */
    goExample() : void {
        this.hodu_router_push('/example/datepicker');
    }

    /**
     * 리사이즈
     */
    handleResize() : void {
        // @ts-ignore
        $('.left_inbox02').mCustomScrollbar('destroy');
        this.left_inbox02_mCustomScrollbar();
    }

    /**
     * left_control_box_flag 조정시 애니메이션 완료 후 리사이즈
     */
    @Watch('left_control_box_flag')
    watchLeftControlBoxFlag() : void {
        setTimeout(() => { this.handleResize(); }, 100);
    }

    
    
}

