
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');

import { GroupNoticeReplyModalInfo } from '@/store/modules/ModalInfo';

import { t_event_file } from '@/model/event';

import JSZip from 'jszip';

const dateFormat = require('dateformat');

declare var CKEDITOR: any;

@Component({
    components : {
    }
})
export default class GroupNoticeDetail extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupNoticeReplyModalInfo ?: (params : GroupNoticeReplyModalInfo) => void;

    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id  !: number;

    /**
     * 변수 선언부
     */
    noti_uid        : string  = "";
    o_notice        : any     = null;
    start_date      : string  = "";
    title           : string  = "";
    body            : string  = "";
    is_modify       : boolean = false;
    is_delete       : boolean = false;
    body_type       : string  = "";
    audit_user_name : string  = "";
    audit_user_pic  : string  = "";

    file_popup_open : boolean = false;

    interval : number | undefined;

    files : any[] = [];

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() : Promise<void> {
        if ( !this.group_team_option.noti_uid ) {
            alert('uid 가 잘못되었습니다');
            this.movePrevPage();
            return;
        }

        this.noti_uid = this.group_team_option.noti_uid;

        // 공지 상세
        this.getNoticeDetatil();

        this.interval = window.setInterval(() => { $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 390)); }, 1000);

        //파일첨부 팝업
        // $('.attachedBtn').click(function(){
        //     $('.filePopup').fadeIn(200);
        //     return false;
        // })
        // $('.filePopup button.filePopup_close').click(function(){
        //     $('.filePopup').fadeOut()
        // })
    }

    beforeDestroy() {
        if( this.interval ) window.clearInterval(this.interval);
    }

    /**
     * 공지사항 상세화면 조회
     */
    async getNoticeDetatil() : Promise<void> {
        const vue = this;

        this.is_modify = false;
        this.is_delete = false;

        $('#modal_noti_contents').css('height', ((window.innerHeight ? window.innerHeight : 0) - 390));

        await this.hodu_api_call(`api/v1/groupsweb/getNoticeDetail/${this.group_id}/${this.team_id}?uid=${this.noti_uid}`, API_METHOD.GET, {}, false)
            .then(async(response) => {
                // 데이터가 없을경우
                if ( !response.data.data.noti_data ) {
                    if ( vue.group_team_option.noti_uid ) {
                        delete vue.group_team_option.noti_uid;
                    }

                    vue.movePrevPage();
                    return;
                }
                else {
                    this.o_notice        = response.data.data.noti_data;
                    this.title           = this.o_notice.noti_info.title;
                    this.body            = this.o_notice.noti_data.body.replace(/\n/g, "<br>");
                    this.start_date      = dateFormat(this.o_notice.noti_info.start_date, 'yyyy.mm.dd');
                    this.body_type       = this.o_notice.noti_data.body_type;
                    this.audit_user_name = this.o_notice.audit_user_name;

                    if( this.o_notice.noti_info.files ) {
                        this.files = this.files.concat(this.o_notice.noti_info.files);
                    }

                    if ( this.o_notice.audit_user_pic ) {
                        this.audit_user_pic = '/app_images/' + this.o_notice.audit_user_pic;
                    }
                    else {
                        this.audit_user_pic = require("@/assets/images/contents/im_photoB.gif");
                    }

                    if ( this.group_id > 0 && this.team_id > 0 ) {
                        if ( this.o_notice.team_id == this.team_id ) {
                            this.is_modify = this.is_team_permmision(this.team_id, "notice", "modify");
                            this.is_delete = this.is_team_permmision(this.team_id, "notice", "delete");
                        }
                    }
                    else if ( this.group_id > 0 && this.team_id == 0 ) {
                        if ( this.o_notice.team_id == 0 && this.o_notice.group_id == this.group_id ) { 
                            this.is_modify = this.is_group_permmision(this.group_id, "notice", "modify");
                            this.is_delete = this.is_group_permmision(this.group_id, "notice", "delete");
                        }
                    }
                }
            })
            .catch(async(e) => {

                if ( vue.group_team_option.noti_uid ) {
                    delete vue.group_team_option.noti_uid;
                }

                this.hodu_error_process(e, true, true);
            });        
    }

    /**
     * 공지사항 수정
     */
    actionModify() : void { 
        if ( this.body_type == "HTML" || this.body_type == "TEXT" ) {
            this.doGroupId(this.group_id);
            this.doTeamId(this.team_id);
            this.group_team_option.noti_uid = this.noti_uid;

            this.doGroupTeamOption(this.group_team_option);
            this.body_type == "HTML" ? this.hodu_router_push(`/group/${new Date().getTime()}/notice_create_editor`) : 
                                       this.hodu_router_push(`/group/${new Date().getTime()}/notice_create`)
        }
    }
    
    /**
     * 공지사항 삭제
     */
    async actionDelete() : Promise<void> { 
        const vue = this;

        this.hodu_show_dialog("alert", "공지사항을 삭제 하시겠습니까?", ['아니오', '예'], [
            () => {},
            async () => {
                let notiType = (this.team_id > 0 ? "TEAM" : "GROUP");

                await this.hodu_api_call(`api/v1/notification/notice/${this.noti_uid}/${notiType}/${this.group_id}/${this.team_id}`, API_METHOD.DELETE, {})
                    .then(async(response) => {
                        if ( vue.group_team_option.noti_uid ) {
                            delete vue.group_team_option.noti_uid;
                        }

                        vue.movePrevPage();
                    })
                    .catch(async(e) => {
                        if ( vue.group_team_option.noti_uid ) {
                            delete vue.group_team_option.noti_uid;
                        }

                        this.hodu_error_process(e, true, true);
                    });    
            },
        ]);
    }

    /**
     * 공지사항 댓글
     */
    showNoticeReply() : void {
        this.doSetGroupNoticeReplyModalInfo?.({
            show_modal : true,
            noti_uid : this.noti_uid,
            callback : this.getNoticeDetatil
        });
    }

    /**
     * 공지사항 댓글 기능
     */
    noticeReplyChecked() : boolean {        
        const feature = this.get_group_feature(this.group_id);

        if( feature == null || feature.notice == null || feature.notice.enable_group_comment == null ) return false;

        return feature.notice.enable_group_comment;
    }

    /**
     * 파일 다운로드 (조회)
     */
    fileDownload(file : t_event_file) : void {
        this.hodu_download(`/app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 파일 전체 다운로드
     */
    async allDownload() : Promise<void> {
        
        const selected_files : t_event_file[] = this.files;

        if( selected_files.length < 1 ) {
            this.hodu_show_dialog('alert', '다운로드 할 파일을 선택해주세요', ["확인"], [() => {}]);
            return;
        }

        // 파일 1개라면
        if( selected_files.length == 1 ) {
            this.fileDownload(selected_files[0]);
            return;
        }

        // 파일 여러개는 zip 파일로 받는다

        await this.hodu_show_indicator();

        const zip = new JSZip();

        const file_promise : Promise<void>[] = [];

        for( const file of selected_files ) {

            // 파일 다운로드 Promise
            const promise = this.hodu_download(`/app_images/${file.url}`, "", true)
                .then((response) => {
                    console.log(response);

                    // 다운로드한 파일은 zip 파일 구성에 추가
                    zip.file(file.name, new Blob([response.data]));
                })
                .catch((e) => {
                    this.hodu_error_process(e, true, false);
                });

            // 병렬처리용 Promise 배열에 넣기
            file_promise.push(promise);
        }

        Promise.all(file_promise)
            .then(async() => {

                // zip 파일 압축
                await zip.generateAsync({type:"blob"}).then((content) => {
                    
                    // 압축 완료시 나오는 blob으로 다운로드
                    try {
                        this.hodu_download_blob(content, `hodu_download_${new Date().getTime()}.zip`);
                    } catch(e) {
                        this.hodu_error_process(e, true, false);
                    }

                });
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            }).finally(async() => {
                await this.hodu_hide_indicator();
            });

    }

}
