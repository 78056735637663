
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { t_event_file } from '@/model/event';

const lodash = require('lodash');

function Throttle(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.throttle(target[prop], delay)
    };
  }
}

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class EquipmentManagementCreate extends Mixins(VueHoduCommon) {

    uid : string = "";
    seq : string = "";
    is_create : boolean = true;

    equipment : any = null;

    repair_items : string = "";
    repairer : string = "";
    manager : string = "";
    repair_tel : string = "";
    repair_date : Date = new Date();
    repair_complete_date : Date = new Date();
    repair_price : string = "";
    memo : string = "";
    images : t_event_file[] = [];

    image_max_cnt : number = 5;
    image_files : any[] = [];

    image_view_seq : number = 0;

    default_image = require("@/assets/images/contents/hoduhome_car_camera.svg");

    beforeMount() : void {
        this.uid = this.$route.params.uid;
        this.seq = this.$route.params.seq;
        this.is_create = this.seq == null || this.seq.length < 1;

        if( this.uid == null || this.uid.trim().length < 1 ) {
            this.hodu_show_dialog('cancel', '장비 관리 이력 추가에 필요한 데이터가 부족합니다', ['확인'], [() => { this.movePrevPage(); }])
        }
    }

    mounted() : void {
        this.datepickerInit();
        this.setScroll();

        if( this.is_create ) {
            this.getHomeEquipment();
        }
        else {
            Promise.all([this.getHomeEquipment(), this.getHomeEquipmentManagement()]);
        }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();

        const scroll_height = window.innerHeight - (title_height ? title_height : 0);

        // @ts-ignore
        $('#equipment_detail').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : scroll_height,
        });
    }

    /**
     * 장비 조회
     */
    async getHomeEquipment() : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${this.uid}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.equipment ) {
                throw new Error("장비 조회 중 오류 발생");
            }

            this.equipment = JSON.parse(JSON.stringify(response.data.data.equipment));

        } catch(e) {
            this.hodu_show_dialog('cancel', "장비 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 장비 관리 이력 상세 조회
     */
    async getHomeEquipmentManagement() : Promise<void> {

        try {

            const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${this.uid}/management/${this.seq}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.management ) {
                throw new Error("장비 관리 이력 조회 중 오류 발생");
            }

            const management = JSON.parse(JSON.stringify(response.data.data.management));

            this.repair_items = management.management_data.repair_items;
            this.repairer = management.management_data.repairer;
            this.manager = management.management_data.manager;
            this.repair_tel = management.management_data.repair_tel;
            this.repair_date = new Date(this.yyyymmddToDate(management.management_data.repair_date));
            this.repair_complete_date = new Date(this.yyyymmddToDate(management.management_data.repair_complete_date));
            this.repair_price = management.management_data.repair_price;
            this.memo = management.management_data.memo;

            this.images.splice(0, this.images.length);
            this.images = this.images.concat(management.management_data.images);

        } catch(e) {
            this.hodu_show_dialog('cancel', "장비 관리 이력 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }

    }


    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        this.$nextTick(() => {
            const datepicker_option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: (dateText, inst) => {
                    const selected_date = new Date(dateText);
                    inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")} (${this.getDayOfWeekByDate(selected_date)})`);
 
                    switch ( inst.id ) {
                        case "eqTime_datepicker":
                            this.repair_date = new Date(selected_date);
                            break;
                        case "eqComplete_time":
                            this.repair_complete_date = new Date(selected_date);
                            break;
                    }

                },
            }

            // @ts-ignore
            $('#eqTime_datepicker').datepicker(datepicker_option);

            // @ts-ignore
            $('#eqComplete_time').datepicker(datepicker_option);
        });
    }

    /**
     * 일정 이미지 추가
     */
    addImageNormal(event) : void {
        const files : File[] = event.target.files;
        if( files.length == 0 ) { return; }
        this.addImage(files);
    }

    /**
     * 이미지 객체 생성
     */
    async addImage(files : File[]) : Promise<void> {
        const vue = this;
        
        let file_count : number = files.length;
        let end_count  : number = 0;

        // 이미지가 최대치 만큼 이미 차 있는 경우
        if( this.images.length >= this.image_max_cnt ) {
            alert(`이미지 최대 개수는 ${this.image_max_cnt}개 입니다`);
            $('#cdImg').val("");
            return;
        }

        // 이미지 여유 공간이 있지만 선택한 이미지 개수 + 기존에 있던 이미지 개수가 최대치를 넘은 경우
        if( this.images.length + file_count > this.image_max_cnt ) {
            if( files instanceof FileList ) {
                files = Array.prototype.slice.call(files, 0, (this.image_max_cnt - this.images.length));
            }

            else {
                alert(`이미지 최대 개수는 ${this.image_max_cnt}개 입니다`);
                $('#cdImg').val("");
                return;
            }

            file_count = files.length;
        }

        await this.hodu_show_indicator();

        for( let i = 0; i < file_count; i++ ) {
            await this.fileReaderPromise(files[i])
                .then(async(pe_fr : any) => {

                    if( pe_fr.target == null || pe_fr.target.result == null ){
                        return;
                    }
                    
                    let base64url : string = "";

                    if( pe_fr.target.result instanceof ArrayBuffer ){
                        const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                        const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                        base64url = decodeURIComponent(url);
                    } else {
                        base64url = pe_fr.target.result;
                    }

                    // 이미지 리사이즈
                    const blob : Blob = await vue.hodu_image_resize(base64url);
                    
                    // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                    let resize_file : File = files[i];
                    try{
                        resize_file = await this.hodu_blob_to_file(blob, files[i].name);
                    }catch(e){
                        try {
                            (blob as any).lastModifiedDate = new Date();
                            (blob as any).name = files[i].name;
                            resize_file = (blob as any);
                        } catch(e) {
                            this.hodu_error_process(e, false, false, true);
                        }
                    }

                    vue.images.push({
                        name: resize_file.name,         // 원본 파일명
                        mimeType: resize_file.type,     // MIME TYPE
                        url: URL.createObjectURL(blob), // 파일 경로
                        size: resize_file.size,         // 파일 크기
                        date: new Date()                // 저장 날짜
                    })

                    // 업로드용 파일 객체 담기
                    vue.image_files.push({
                        index : ( vue.images.length - 1 ), 
                        file : resize_file
                    });

                    // 마지막 파일 로드 후 input 값 비우기 [안 비우면 똑같은 사진 multiple 아니면 안들어감]
                    if( file_count == ++end_count ) { $('#cdImg').val(""); }

                })
                .catch((e) => {
                    console.log('filereader promise error',e);
                });
        }

        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File | Blob) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * 이미지 슬라이드
     */
    @Throttle(100)
    imageSeqMove(num : number) {
        if( this.image_view_seq + num < 0 || this.image_view_seq + num >= this.images.length ) {
            return;
        }
        this.image_view_seq = this.image_view_seq + num;
    }

    /**
     * 이미지 삭제
     */
    deleteImage(image : t_event_file) : void {

        const image_index : number = this.images.indexOf(image);

        if( image_index == -1 ){
            return;
        }

        if( image_index < this.image_view_seq || (image_index == this.image_view_seq && this.image_view_seq == (this.images.length - 1))) {
            this.image_view_seq--;
        }

        this.images.splice(image_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const image_files_count : number = this.image_files.length;
        for( let i = (image_files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.image_files[i].index > image_index ) {
                this.image_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.image_files[i].index == image_index ) {
                this.image_files.splice(i, 1);
                break;
            }
        }

    } 

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
    yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 저장
     */
    async save() : Promise<void> {

        this.repair_price = String(this.repair_price);

        if( this.repair_items.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "수리 항목을 입력해주세요", ['확인'], [() => { $('#eqFix').focus(); }]);
            return;
        }

        if( this.repair_price.trim().length > 0 && isNaN(Number(this.repair_price.replaceAll(",", "").trim())) == true ) {
            this.hodu_show_dialog("alert", "수리 금액에는 숫자만 입력해주세요", ['확인'], [() => { $('#eqPrice').focus(); }]);
            return;
        }

        if( this.repair_price.trim().length > 0 && Number(this.repair_price.replaceAll(",", "").trim()) < 0 ) {
            this.hodu_show_dialog("alert", "수리 금액에는 양수만 입력해주세요", ['확인'], [() => { $('#eqPrice').focus(); }]);
            return;
        }

        if( this.repair_price.trim().length > 0 && this.repair_price.replaceAll(",", "").trim().includes('.') == true ) {
            this.hodu_show_dialog("alert", "수리 금액에는 정수만 입력해주세요", ['확인'], [() => { $('#eqPrice').focus(); }]);
            return;
        }

        try {

            const image_files_count : number = this.image_files.length;
            if( image_files_count > 0 ) {

                const form_data : FormData = new FormData();
                
                // FormData 내용 생성
                for( let i = 0; i < image_files_count; i++ ) {
                    form_data.append('file', this.image_files[i].file);
                }
        
                try {
                    const response = await this.hodu_temp_upload(form_data);
                    console.log(response);
        
                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("이미지 임시 업로드 실패");
                    }
        
                    const temp_files : t_event_file[] = response.data.data.temp_files;
        
                    // 임시파일 데이터로 객체 대체하기
                    const image_files_count : number = this.image_files.length;
                    for( let i = 0; i < image_files_count; i++ ) {
                        this.images.splice(this.image_files[i].index, 1, temp_files[i]);    
                    }
        
                } catch(e) {
                    throw e;
                }
            }

            let body = {
                repair_items : this.repair_items.trim(),
                repairer : this.repairer.trim(),
                manager : this.manager.trim(),
                repair_tel : this.repair_tel.trim(),
                repair_date : this.hodu_date_to_format_string(this.repair_date, 'YYYYMMDD'),
                repair_complete_date : this.hodu_date_to_format_string(this.repair_complete_date, 'YYYYMMDD'),
                repair_price : Number(this.repair_price.replaceAll(",", "").trim()),
                memo : this.memo.trim(),
                images : this.images
            };

            if( this.is_create ) {    
    
                const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${this.uid}/management`, API_METHOD.POST, body);
    
                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("장비 관리 이력 등록 중 오류 발생");
                } 
    
                this.movePrevPage();
    
            }
            else {
                
                const response = await this.hodu_api_call(`api/v1/home/${this.scope_group_id}/equipment/${this.uid}/management/${this.seq}`, API_METHOD.PUT, body);
    
                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("장비 관리 이력 수정 중 오류 발생");
                } 
    
                this.movePrevPage();

            }
            
        } catch(e) {
            this.hodu_show_dialog("cancel", "장비 관리 이력 저장 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#equipment_detail').mCustomScrollbar("destroy");

        this.setScroll();
    }

}
