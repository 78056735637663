

import { Component, Vue, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import moment from 'moment';
import { namespace } from 'vuex-class';
import { hodu_doc_modal_info, hodu_doc_table } from '@/model/hodudoc';
const ModalInfo = namespace('ModalInfo');

@Component
export default  class DoctorOffDays extends Mixins(VueHoduCommon) {

    /**
     * 선택한 년도에 따른 오름차순 휴가 리스트
     */
    get computedOffListByYear() : hodu_doc_table.t_hospital_doctor_day_offs[] {
        if ( this.dayoff_info == null ) { return [] }
        let filtered_list : hodu_doc_table.t_hospital_doctor_day_offs[] = []

        filtered_list = this.dayoff_info.filter(item => new Date(item.start_date).getFullYear() == this.selected_year)

        filtered_list.sort((off1 : hodu_doc_table.t_hospital_doctor_day_offs, off2 : hodu_doc_table.t_hospital_doctor_day_offs) : number => {
                //오름차순
                if( off1.start_date  > off2.start_date ) { return  1; }
                if( off1.start_date  < off2.start_date ) { return -1; }
                if( off1.start_date == off2.start_date ) { return  0; }
                return 0
            })
        return filtered_list;
    }

    /**
     * 전체 휴가 리스트의 해당 년도 리스트
     */
    get computedAvailbleYearList() : number[] {
        if ( this.dayoff_info == null ) { return [] }
        let exist_years : number[] = []
        
        //먼저 전체 휴가 리스트의 작성 연도 리스트를 구한다
        const list = this.dayoff_info.reverse();
        for ( const info of list ) {
            const start_year = new Date(info.start_date).getFullYear()
            if ( exist_years.includes(start_year) == false ) {
                exist_years.push(start_year)
            }
        }

        exist_years.sort((year1 : number, year2 : number) : number => {
                //오름차순
                if( year1  > year2 ) { return  1; }
                if( year1  < year2 ) { return -1; }
                if( year1 == year2 ) { return  0; }
                return 0
            })
        return exist_years;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDayOffSettingModalInfo ?: (day_off_setting_modal_info : hodu_doc_modal_info.DayOffSettingModalInfo) => void;

    @Prop() dayoff_info    !: hodu_doc_table.t_hospital_doctor_day_offs[];
    @Prop() biz_id         !: string;
    @Prop() department_code!: string;
    @Prop() doctor_code    !: string;

    selected_year : number = new Date().getFullYear()

    async mounted() {
        this.setScroll()
    }

    /**
     * 휴가 생성 및 상세 모달 띄우기
     */
    showOffSettingModal(dayoff: hodu_doc_table.t_hospital_doctor_day_offs | null) : void {
        if( this.doSetDayOffSettingModalInfo ) {
            if ( dayoff != null ) {
                window['deleteDayOff'] = this.deleteDayOff
                window['updateDayOff'] = this.updateDayOff
                this.doSetDayOffSettingModalInfo({
                    "show_modal" : true,
                    "is_create"  : false,
                    "is_doctor"  : true,
                    "day_off"    : dayoff
                }); 
            }else{
                window['createDayOff'] = this.createDayOff
                this.doSetDayOffSettingModalInfo({
                    "show_modal" : true,
                    "is_create"  : true,
                    "is_doctor"  : true,
                    "day_off"    : null
                }); 
            }
        }
    }

    /**
     * 휴가 날짜 표기
     */
    getDateString(date: Date | string, compare_date: Date | string | null, key: string) : string {
        if (compare_date != null && key == "end"){
            const start_date = moment(new Date(date)).format("YYYY-MM-DD")
            const end_date   = moment(new Date(compare_date)).format("YYYY-MM-DD")

            if ( start_date == end_date ) {
                return ""
            }
        }
        let dowNum = Number(moment(date).format("E"));
        let dowName = this.getDayName(dowNum)

        return (key == "end" ? "~ " : "" ) + moment(date).format("MM.DD ") + dowName
    }

    /**
     * 휴가 시간 표기
     */
    getTimeString(date: Date) : string {
        return ""
        // return moment(date).format("HH:mm")
    }

    /**
     * 휴가 기간 표기
     */
    getDuringDaysString(dayoff : hodu_doc_table.t_hospital_doctor_day_offs) : string {
        const term = moment(dayoff.end_date).diff(dayoff.start_date, "days")
        
        // let fixed_term = Number(term.toFixed(1)) //! 8.8
        // let a = Math.floor(fixed_term)           //! 8
        // let b = Math.round((fixed_term % 1) * 10) / 10 //! 0.799999 -> 0.8
        
        // if(b == 0) { 
        //     b = 0
        // }else if(b <= 0.5) {
        //     b = 0.5
        // }else{
        //     b = 1
        // }
        
        // if( (a+b) == 0 ) {
        //     return ""
        // }
        // return `${a + b} 일`
        if( term < 1 ) { return "1일" }
        return `${term+1}일`;
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const windowOuterHeight   : number | undefined = $(window).innerHeight();
        const titleBoxHeight      : number | undefined = $('.title_box').innerHeight();
        const offTitleBoxHeight   : number | undefined = $('.titleDiv').innerHeight();

        // @ts-ignore
        $('#offListScroll').mCustomScrollbar({
            axis : 'y',
            mouseWheelPixels : 80,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : (windowOuterHeight == null ? 0 : windowOuterHeight) - (titleBoxHeight == null ? 0 : titleBoxHeight) - (offTitleBoxHeight == null ? 0 : offTitleBoxHeight),
            scrollbarPosition : 'outside'
        });
    }
    
    /**
     * 의사 휴무 삭제
     */
    async deleteDayOff(dayoff: hodu_doc_table.t_hospital_doctor_day_offs) : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/doctor/${this.department_code}/${this.doctor_code}/day_off/${dayoff.doctor_off_id}`, API_METHOD.DELETE)
            .then((response) => {
                let response_msg = response.data.msg as string
                if (response_msg == "success"){
                    this.getDoctorDayOffs()
                }
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 의사 휴무 수정
     */
    async updateDayOff(dayoff: hodu_doc_table.t_hospital_doctor_day_offs) : Promise<void> {
        let req_data = {
            off_name   : dayoff.off_name,
            off_reason : dayoff.off_reason,
            start_date : new Date(dayoff.start_date),
            end_date   : new Date(dayoff.end_date)
        }
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/doctor/${this.department_code}/${this.doctor_code}/day_off/${dayoff.doctor_off_id}`, API_METHOD.PUT, req_data)
            .then((response) => {
                let jsonData = JSON.parse(JSON.stringify(response.data.data));
                let day_off = jsonData as hodu_doc_table.t_hospital_doctor_day_offs

                if ( day_off.off_name != null && day_off.off_name.length > 0 ) {
                    this.getDoctorDayOffs();
                }
                
            })
            .catch((e) => {
                let error = JSON.stringify(e);
                console.log(error);
            });
    }

    /**
     * 의사 휴무 생성
     */
    async createDayOff(dayoff: hodu_doc_table.t_hospital_doctor_day_offs) : Promise<void> {
        let req_data = {
            biz_id : this.biz_id,
            department_code : this.department_code,
            doctor_code : this.doctor_code,
            off_name : dayoff.off_name,
            off_reason : dayoff.off_reason,
            start_date : new Date(dayoff.start_date),
            end_date : new Date(dayoff.end_date)
        }
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/doctor/${this.department_code}/${this.doctor_code}/day_off`, API_METHOD.POST, req_data)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                if (jsonData.length > 0) {
                    this.getDoctorDayOffs();
                }
                
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 의사 휴무 정보 조회
     */
    async getDoctorDayOffs() : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.biz_id}/doctor/${this.department_code}/${this.doctor_code}/day_off`, API_METHOD.GET)
            .then((response) => {
                let jsonData : any[] = JSON.parse(JSON.stringify(response.data.data))
                this.$emit('reloadDayOffs', jsonData)
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

}


