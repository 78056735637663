
import { Component, Vue, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { EventExportModalInfo } from '@/store/modules/ModalInfo';

@Component({

})
export default class RightPreferences extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEventExportModalInfo ?: (params : EventExportModalInfo) => void;

    async mounted() : Promise<void> { 

        // 개인 달력 환경설정 정보 갱신
        if( this.scope_group_id < 1 ) {
            const calendar_preference = await this.get_user_preference("calendar", false, true);

            if( calendar_preference == null || calendar_preference.preference == null || calendar_preference.preference.schedule_time_type == null ) {
                this.doSetScheduleTimeType?.("NONE");
            }
            else {
                this.doSetScheduleTimeType?.(calendar_preference.preference.schedule_time_type);
            }
        }

    }

    /**
     * 일정 시간 표기 변경
     */
    async changeScheduleTimeType(value : string) {

        try {
            const calendar_preference = await this.get_user_preference("calendar", false, true);

            let body : any = {};

            if( calendar_preference == null || calendar_preference.preference == null ) {
                body = { "schedule_time_type" : value };
            }
            else {
                body = calendar_preference.preference;
                body.schedule_time_type = value;
            }
            
            await this.set_user_preference("calendar", body, true);

            // 캘린더 환경설정 리셋
            await this.calendar_preference_sync();

        } catch(e) {
            alert("일정 시간 표기 변경 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 프리미엄 기능 ON / OFF
     */
    premiumFeaturesOnOff(event : any, target : string) : void {
        let featureOnOff : boolean = event.target.checked;

        // 그룹 기능 변경하는 API 호출 [(groups.ts : groupFeatureChange) - ( '/groups' : '/:group_id/features/:feature' )]
        this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/${target}?feature_flag=${featureOnOff}`, API_METHOD.PUT)
            .then((response) => {
                this.get_group_role_service();
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })
    }

    /**
     * 메세지 ON / OFF 여부
     */
    messageChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'message');
    }

    /**
     * 업무 ON / OFF 여부
     */
    workChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'work');
    }

    /**
     * 파일함 ON / OFF 여부
     */
    fileboxChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'filebox');
    }

    /**
     * 전자결재 ON / OFF 여부
     */
    approvalChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'approval');
    }

    /**
     * 근태관리 ON / OFF 여부
     */
    attendanceChecked() : boolean {
        return this.isEnableGroupFeature(this.scope_group_id, 'attendance');
    }

    /**
     * 공지사항 댓글 ON / OFF
     */
    async noticeReplyOnOff(value) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/notice?key=enable_group_comment&feature_flag=${value}`, API_METHOD.PUT);

            console.log(response);

            if( !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("그룹 기능 변경 중 오류 발생");
            }
            
            this.get_group_role_service();

        } catch(e) {
            alert("그룹 기능 변경 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 공지사항 댓글 기능
     */
    noticeReplyChecked() : boolean {

        const feature = this.get_group_feature(this.scope_group_id);

        if( feature == null || feature.notice == null || feature.notice.enable_group_comment == null ) return false;

        return feature.notice.enable_group_comment;
    }

    /**
     * 일정 내보내기
     */
    eventExport() {
        this.doSetEventExportModalInfo?.({
            show_modal : true
        });
    }

    groupModify() {
        this.doGroupId(this.scope_group_id);
        this.hodu_router_push(`/group/${new Date().getTime()}/create`);
    }

}

