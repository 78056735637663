
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const AttendanceInfo = namespace('AttendanceInfo');

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';

import moment from 'moment';

import { attendance_enum } from '@/model/attendance';

@Component({
    components: {
        FullCalendar
    },
})
export default class KidsAttendanceDayGridMonth extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    // this.$refs.fullCalendar.getApi() 사용
    $refs!: { fullCalendar : HTMLFormElement; };

    /**
     * @CalendarInfo.State
     */
    @AttendanceInfo.State start_date      !: string; // 마지막으로 보고있던 월의 시작일
    @AttendanceInfo.State calendar_height !: number; // 캘린더 높이
    @AttendanceInfo.State event_limit     !: number; // 이벤트 보이는 제한 개수
    @AttendanceInfo.State selected_date   !: Date;   // 선택된 날짜
    @AttendanceInfo.State go_to_date      !: Date;   // LeftDatePicker Watch 콜백용

    /** 
     * @CalendarInfo.Action
     */
    @AttendanceInfo.Action doSetAttendanceStartDate ?: any; // start_date 업데이트
    @AttendanceInfo.Action doSetCalendarHeight      ?: any; // calendar_height 업데이트
    @AttendanceInfo.Action doSetEventLimit          ?: any; // event_limit 업데이트
    @AttendanceInfo.Action doSetSelectedDate        ?: any; // selected_date 업데이트


    is_calendar_mounted : boolean = false; // 달력 mount가 완료 됐는지 여부
    waited_render_arg : any = null;        // 최초 달력이 mount 되기까지 대기 중일때 들어온 handleDatesRender에서 사용되는 arg
    
    activeStart ?: Date | null = null; // (조회 기준) 달력 시작일
    activeEnd   ?: Date | null = null; // (조회 기준) 달력 종료일
    lunar_ymd   ?: string;             // 시작일 기준 음력

    is_create_menu_open : boolean = false;
    is_first_init : boolean = false;

    events : any[] = [];
    lunar_date : Map<string, string> = new Map(); // 음력 Map
    holidays   : Map<string, Object> = new Map(); // 공휴일 Map

    calendarPlugins : any = [ dayGridPlugin, rrulePlugin, interactionPlugin, momentPlugin ]; // 플러그인

    views : any = {
        dayGrid: {
            titleFormat : 'YYYY.MM',
            columnHeaderText: (date : Date) => {
                switch( date.getDay() ){
                    case 0:
                        return "일";

                    case 1:
                        return "월";

                    case 2:
                        return "화";

                    case 3:
                        return "수";

                    case 4:
                        return "목";

                    case 5:
                        return "금";

                    case 6:
                        return "토";

                    default:
                        return "?";
                }    
            },
        }        
    }

    mounted() : void {
        this.event_bus?.$on('daygridMonthResize', this.calendarResize);

        // @mousewheel 안먹히는 브라우저가 있어서 onWheel 사용
        $('#kids_attendance.section_ce_fix').off().on('wheel', (e : any) => {
            this.handleMouseWheel(e.originalEvent);
        }); 

        this.doSetSelectedDate(moment(this.start_date).year() == moment().year() && moment(this.start_date).month() == moment().month() ? new Date() : moment(this.start_date).toDate());
        this.calendarGotoDateCss(moment(this.start_date).year() == moment().year() && moment(this.start_date).month() == moment().month() ? moment().format('YYYY-MM-DD') : moment(this.start_date).format('YYYY-MM-DD'));
        this.calendarResize();
        
        this.is_calendar_mounted = true;
        if( this.waited_render_arg != null ) { this.handleDatesRender(this.waited_render_arg); }
    }

    beforeDestroy() {
        $('#kids_attendance.section_ce_fix').off('wheel');
    }

    /**
     * 새로고침
     */
    refresh() : void {
        this.handlePrev();
        this.handleNext();
    }

    /**
     * default date 반환
     */
    getDefaultDate() : Date {
        let default_date : Date | null = null;
        
        // 조건에 맞지 않으면 항상 start_date로 설정된 날짜 반환
        default_date = new Date(this.start_date);
        
        // 월달력은 같은 년월이라면 오늘날짜 반환
        default_date = new Date(moment(this.start_date).format()).getFullYear() == new Date().getFullYear() && 
                       new Date(moment(this.start_date).format()).getMonth() == new Date().getMonth() ? new Date() : new Date(moment(this.start_date).format());

        return default_date;
    }

    /**
     * 이벤트 렌더링
     */
    handleEventRender(arg) : boolean | HTMLElement | void {
        
        const attend = arg.event.extendedProps.attend;

        // console.log(attend);
        
        // 오늘 날짜에는 최상단 div class에 today를 추가
        let el = $(`
            <div class="contDiv">
                <div class="grpDiv">
                    <div>
                        <span class="fst">전체</span>
                        <span class="lst">${Number(attend.total_cnt)}</span>
                    </div>
                    <div>
                        <span class="fst">정상</span>
                        <span class="lst">${Number(attend.normal_cnt)}</span>
                    </div>
                    <div>
                        <span class="fst">결석</span>
                        <span class="lst">${Number(attend.absent_cnt)}</span>
                    </div>
                    <div>
                        <span class="fst">미체크</span>
                        <span class="lst">${Number(attend.unchecked_cnt)}</span>
                    </div>
                </div>
            <div>
        `);

        // 오늘을 제외하고 통계값이 전부 0인날은 숨긴다
        if( attend.attend_date != this.hodu_date_to_format_string(new Date(), 'YYYYMMDD') && Number(attend.total_cnt ? attend.total_cnt : 0) == Number(attend.unchecked_cnt ? attend.unchecked_cnt : 0) ) {
            el = $('');
        }

        return el[0];
    }

    /**
     * 날짜 렌더링
     */
    async handleDatesRender(arg) : Promise<void> {
        
        this.waited_render_arg = arg; 
        
        // mount가 되기 전까지는 arg를 받고 대기한다
        if( this.is_calendar_mounted == false ) {
            return; 
        }
        
        const vue = this;

        // handleDatesRender에 현재 보고 있는 날짜와 같은 날짜가 들어올때 리턴
        const arg_active_start     : Date        = arg.view.activeStart instanceof Date ? arg.view.activeStart : new Date(arg.view.activeStart);
        const current_active_start : Date | null = this.activeStart     instanceof Date ? this.activeStart     : null;
        if( current_active_start != null && current_active_start.getTime() == arg_active_start.getTime() ) { return; }

        this.activeStart = arg.view.activeStart;
        this.doSetAttendanceStartDate(moment(arg.view.currentStart).format('YYYY-MM-DD').toString());
        this.activeEnd   = arg.view.activeEnd;

        this.calendarResize();            

        // 해당 달의 1일로 세팅
        const current_month : Date = new Date(arg.view.activeStart);
                
        if( current_month.getDate() != 1 ) {
            current_month.setDate(1);
            current_month.setMonth(current_month.getMonth() + 1);
        }
        
        // 날짜 텍스트
        $('#hMonthPrt').html(`<input type="text" id="attendance_current_month" class="forDatePick" value="${moment(current_month).format("YYYY-MM-DD")}" readonly />${arg.view.title}`);
        $('#fc-print-title').text(moment(current_month).format("YYYY.MM"));
        this.datepickerInit();
        
        // week에 해당 월이 5주인지 6주인지 써준다
        this.$nextTick(() => {
            $('.fc-body .fc-day-grid-container .fc-week').removeClass('row-5');
            $('.fc-body .fc-day-grid-container .fc-week').removeClass('row-6');
            $('.fc-body .fc-day-grid-container .fc-week').addClass(`row-${$('.fc-body .fc-day-grid-container .fc-week').length}`);
        });

        // 일정 비우기
        this.events.splice(0,this.events.length);

        this.$nextTick(async() => {            
            try {
                await Promise.all([this.getEvents(), this.getLunar(arg.view.activeStart, arg.view.activeEnd), this.getHolidays(arg.view.activeStart, arg.view.activeEnd)]);

                // TODO 회사에서 지정한 휴일도 따로 불러와서 세팅 고려!
                // TODO 회사에서 안쉬기로 정한 법정공휴일은 안 쉬어야하는지 고려!

                await this.dayGridLunarAndHolidays();
            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "데이터 조회 중 오류 발생", ['확인']);
            }
            
        });
    }

    /**
     * 이벤트(월 달력 일별 근태현황) 조회
     */
    async getEvents() : Promise<void> {
        try {
            const el = document.getElementById('attendance_current_month');
        
            if( el == null ) return;

            const request_date_string = (el as HTMLInputElement).value.replaceAll(/-/ig, "").substring(0, 6);
            const today_date_string = moment().format('YYYYMMDD');

            const response = await this.hodu_api_call(`api/v1/kids/attend/count?group_id=${this.scope_group_id}&month=${request_date_string}&search_mode=DAY&today_date_string=${today_date_string}`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.attend_emp_month_info ) {
                throw new Error("월달력 일별 근태현황 조회 실패");
            }

            this.events.splice(0, this.events.length);

            for( const attend of response.data.data.attend_emp_month_info ) {
                console.log(attend);

                const date_string = ([attend.attend_date.substring(0, 4), attend.attend_date.substring(4, 6), attend.attend_date.substring(6, 8)] as string[]).join('-');
                
                this.events.push({
                    allDay : false,
                    backgroundColor:"#FFFFFFFF",
                    borderColor:"transparent",
                    duration:null,
                    start: moment(date_string).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate(),
                    end: moment(date_string).set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999).toDate(),
                    hasBackground:false,
                    id: attend.yyyymmdd,
                    rrule:null,
                    textColor:"#202A39",
                    title:"",
                    attend: attend
                });
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 음력 구하기
     */
    async getLunar(start : Date, end : Date) : Promise<void> {
        start = start instanceof Date ? start : new Date(moment(start).format());
        end   = end   instanceof Date ? end   : new Date(moment(end).format());

        const lunar_objs : any[] = await this.hodu_solars_to_lunars(start, end);
        
        for( let lunar_obj of lunar_objs ) {
            this.lunar_date.set(lunar_obj.solar_ymd, lunar_obj.lunar_ymd);
        }
    }

    /**
     * 공휴일 구하기
     */
    async getHolidays(start : Date, end : Date) : Promise<void> {
        const solar_start : Date = start instanceof Date ? start : new Date(moment(start).format());
        const solar_end   : Date = end   instanceof Date ? end   : new Date(moment(end).format());

        const holi_days : any[] = await this.hodu_get_holidays(solar_start, solar_end);

        // 공휴일 Map 생성
        for( let holi_day of holi_days ) {
            this.holidays.set(holi_day.solar_ymd, holi_day);

            // add_before 처리
            for( let before_day = 1; before_day <= holi_day.add_before; before_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_before : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // before 만큼 날짜 이전으로
                target_date.setDate(target_date.getDate() - before_day);

                // object 및 map에 세팅
                holi_day_before.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_before.solar_ymd, holi_day_before);
            }

            // add_after 처리
            for( let after_day = 1; after_day <= holi_day.add_after; after_day++ ) {
                // 세팅용 데이터 생성
                const holi_day_after : any = JSON.parse(JSON.stringify(holi_day));
                const target_date : Date = new Date(moment(holi_day.solar_ymd).format());

                // after 만큼 날짜 이후로
                target_date.setDate(target_date.getDate() + after_day);

                // object 및 map에 세팅
                holi_day_after.solar_ymd = moment(target_date).format('YYYY-MM-DD');
                this.holidays.set(holi_day_after.solar_ymd, holi_day_after);
            }
        }

    }

    /**
     * daygrid 음력, 공휴일 렌더링
     */
    async dayGridLunarAndHolidays() : Promise<void> {
        const vue = this;

        const day_count : number = $('.fc-day-grid').find('.fc-day-number').length;

        for( let i = 0; i < day_count; i++ ) {
            const html_element : HTMLElement = $('.fc-day-grid').find('.fc-day-number')[i];
            
            // fc-special-name가 이미 있다면 넘어감
            if( $(html_element).parent().find('.fc-special-name').length > 0 ) {
                continue;
            }

            const this_date : Date = new Date(moment($(html_element).parent().data('date')).format());
            const this_date_string : string = moment(this_date).format('YYYY-MM-DD');
            
            const lunar : string | undefined = this.lunar_date.get(moment(this_date).format('YYYY-MM-DD'));
            const lunar_string : string = lunar ? lunar : moment(this_date).lunar().format();

            const holidays : any | undefined = this.holidays.get(moment(this_date).format('YYYY-MM-DD'));
            
            // 해당하는 날짜가 아닌경우 continue
            if( this_date_string != $(html_element).parent().data('date')) {
                continue;
            }
            
            // 공휴일인 경우 parent에 fc-special 클래스 추가
            if( holidays ) { $(html_element).parent().addClass('fc-special'); }

            // moment.format으로 하면 2월 30일이 Invalid Date로 나오기 때문에 텍스트 그대로 잘라서 사용한다
            const lunar_ymd : string[] = lunar ? lunar.split('-') : [];
            const lunar_text : string = lunar_ymd.length > 1 ? `${lunar_ymd[1]}.${lunar_ymd[2]}` : ''; 
            $(html_element).after(`<span class="fc-special-name">${ holidays ? holidays.holi_name : '' }</span><span class="day-num-other">${ this_date.getDay() == 0 ? lunar_text : '' } </span> `);
        }

    }

    /**
     * 마우스 휠 이벤트
     */
    async handleMouseWheel(e) : Promise<void> {
        if(e.deltaY >= 0){
            $('#btnNext').trigger('click');
        } else if(e.deltaY < 0){
            $('#btnPrev').trigger('click');
        }
    }

    /**
     * 이전 달로 이동
     */
    async handlePrev() : Promise<void> {
        this.$refs.fullCalendar.getApi().prev();
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date(moment(this.$refs.fullCalendar.getApi().view.currentStart).format()));
        this.calendarGotoDateCss(moment(this.selected_date).format('YYYY-MM-DD'));
    }

    /**
     * 다음 달로 이동
     */
    async handleNext() : Promise<void> {
        this.$refs.fullCalendar.getApi().next();
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date(moment(this.$refs.fullCalendar.getApi().view.currentStart).format()));
        this.calendarGotoDateCss(moment(this.selected_date).format('YYYY-MM-DD'));
    }

    /**
     * 오늘 날짜로 이동
     */
    async handleToday() : Promise<void> {
        this.$refs.fullCalendar.getApi().gotoDate(new Date());
        
        // @ts-ignore
        $('#datepicker').datepicker('setDate', new Date());
        this.calendarGotoDateCss(moment().format('YYYY-MM-DD'));
    }

    /**
     * 달력에서 일 숫자 클릭
     */
    handleNavLinkDayClick(date : Date, jsEvent : Event) : void {
        this.$refs.fullCalendar.getApi().gotoDate(date);
        this.doSetSelectedDate(date);
        this.calendarGotoDateCss(moment(date).format('YYYY-MM-DD'));
    }

    /**
     * 날짜 클릭
     */
    handleDateClick(arg) : void {
        console.log(arg);
        // console.log(arg.date);

        const date_moment = moment(arg.date);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());
        this.$emit('changeCalendar', attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_DAY);
    }

    /**
     * 현황 클릭
     */
    handleEventClick(arg) : void {
        console.log(arg);
        // console.log(arg.event.start);

        const date_moment = moment(arg.event.start);

        this.doSetAttendanceStartDate(date_moment.format('YYYY-MM-DD'));
        this.doSetSelectedDate(date_moment.toDate());
        this.$emit('changeCalendar', attendance_enum.ATTENDANCE_CALENDAR_TYPE.LIST_DAY);
    }

    /**
     * 이벤트 리사이즈 - 아무것도 안하도록 원래대로 돌림
     */
    async handleEventResize(info) : Promise<void> {
        info.revert();
    }

    /**
     * 이벤트 드랍 - 아무것도 안하도록 원래대로 돌림
     */
    async handleEventDrop(info) : Promise<void> {
        info.revert();
    }

    /**
     * datepicker 설정
     */
    datepickerInit() : void {
        const vue = this;

        this.$nextTick(() => {
            // datepicker option
            let option = {
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: function (dateText, inst) {

                    vue.doSetSelectedDate(new Date(dateText));
                    vue.$refs.fullCalendar.getApi().gotoDate(new Date(dateText));

                    // @ts-ignore
                    $('#datepicker').datepicker('setDate', new Date(moment(vue.selected_date).format()));
                    vue.calendarGotoDateCss(moment(vue.selected_date).format('YYYY-MM-DD'));
                },
            };

            // @ts-ignore
            $('.forDatePick').datepicker(option);
        });
    }

    /**
     * 달력 이동 해야 되는 날짜가 바뀐 것을 감지
     */
    @Watch('go_to_date')
    onDateChange() : void {
        this.$refs.fullCalendar.getApi().gotoDate(this.go_to_date);
    }

    /**
     * 캘린더 리사이즈
     */
    calendarResize() : void {
        // resize 시 height 변경 처리
        let scrollProc: any = 0;
        let minWidths : any = 0;
        try {
            minWidths = $('.section_ce_fix').css('min-width');

            if(minWidths) {
                minWidths = minWidths.replace('px', '');
            } else {
                minWidths = 0;
            }
        } catch(e) {
            minWidths = 0;
        }

        let windowWidth      : number | undefined = $(window).outerWidth();
        let windwoWidthFinal : number             = windowWidth == null ? 0 : windowWidth;

        let leftWidth      : number | undefined = $('#left_area').outerWidth();
        let leftWidthFinal : number             = leftWidth == null ? 0 : leftWidth;

        if(Number(minWidths)>=(windwoWidthFinal - leftWidthFinal)) {
            scrollProc = 15;
        }

        let containerHeight      : number | undefined = $('#container').outerHeight();
        let containerHeightFinal : number             = containerHeight == null ? 0 : containerHeight;

        let userToolboxHeight      : number | undefined = $('.title_box').outerHeight();
        let userToolboxHeightFinal : number             = userToolboxHeight == null ? 0 : userToolboxHeight;

        let settingBgHeight      : number | undefined = $('.schedule_box .settingBg').outerHeight();
        let settingBgHeightFinal : number             = settingBgHeight == null ? 0 : settingBgHeight;

        const calendar_height : number = containerHeightFinal - userToolboxHeightFinal - settingBgHeightFinal - scrollProc;
        this.doSetCalendarHeight(calendar_height);

        // event_limit 설정
        const week_count : number = $('.fc-widget-content').find('.fc-week').length;
        const event_height : number = 114;

        let event_limit : number = (calendar_height == 0 || week_count == 0 || event_height == 0) ? 0 : Math.floor(calendar_height / week_count / event_height);
        if( event_limit < 1 ){
            event_limit = 1;
        }
    
        this.doSetEventLimit(event_limit);
    }

    /**
     * 캘린더 변경 CSS 
     */
    calendarGotoDateCss(date : string) : void {

        this.$nextTick(() => {
            $('#calendar').find('.fc-day-number').removeClass('on_orange');
            $('#calendar').find('.fc-day-number').removeClass('on_select');
            
            if( moment().format('YYYY-MM-DD') == date ) {
                $('#calendar').find(`td.fc-day-top[data-date="${date}"]`).find('.fc-day-number').addClass('on_orange');
            } else {
                $('#calendar').find(`td.fc-day-top[data-date="${date}"]`).find('.fc-day-number').addClass('on_select');
            }
        });

    }

}
