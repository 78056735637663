
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, {API_METHOD} from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonInputModalInfo } from '@/store/modules/ModalInfo';

import { approval_modal_interface, approval_interface, approval_enum } from '@/model/approval';

import { directive as onClickaway } from 'vue-clickaway';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        
    },
})
export default class ApprovalFormSetting extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    @Prop() event_bus !: Vue;
    @Prop() all_templates !: approval_interface.ApprovalTemplateBundle[];
    @Prop() templates !: approval_interface.ApprovalTemplateBundle[];
    @Prop() none_class_template !: any;
    @Prop() default_template !: any;
    @Prop() vacation_template !: any;
    @Prop() business_trip_template !: any;
    @Prop() holiday_work_template !: any;
    @Prop() cash_disbursement_voucher_template !: any;
    @Prop() free_template !: any;
    @Prop() receive_template !: any;

    get computedTemplate() {

        let obj = {
            "all_templates" : JSON.parse(JSON.stringify(this.all_templates)),
            "templates" : JSON.parse(JSON.stringify(this.templates)),
            "none_class_template" : JSON.parse(JSON.stringify(this.none_class_template)),
            "default_template" : JSON.parse(JSON.stringify(this.default_template)),
            "vacation_template" : JSON.parse(JSON.stringify(this.vacation_template)),
            "business_trip_template" : JSON.parse(JSON.stringify(this.business_trip_template)),
            "holiday_work_template" : JSON.parse(JSON.stringify(this.holiday_work_template)),
            "cash_disbursement_voucher_template" : JSON.parse(JSON.stringify(this.cash_disbursement_voucher_template)),
            "free_template" : JSON.parse(JSON.stringify(this.free_template)),
            "receive_template" : JSON.parse(JSON.stringify(this.receive_template)),
        }

        if( this.employees != null && this.employees.length > 0 ) {
            
            for( const all_template of obj.all_templates ) {
                for( const temp of all_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }

            for( const template of obj.templates ) {
                for( const temp of template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }

            if( obj.none_class_template != null ) {
                for( const temp of obj.none_class_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.default_template != null ) {
                for( const temp of obj.default_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }

            if( obj.vacation_template != null ) {
                for( const temp of obj.vacation_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.business_trip_template != null ) {
                for( const temp of obj.business_trip_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.holiday_work_template != null ) {
                for( const temp of obj.holiday_work_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.cash_disbursement_voucher_template != null ) {
                for( const temp of obj.cash_disbursement_voucher_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.free_template != null ) {
                for( const temp of obj.free_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }
            
            if( obj.receive_template != null ) {
                for( const temp of obj.receive_template.files ) {
                    for( const approver of temp.approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                    }
                    for( const self_approver of temp.self_approver ) {
                        const employee = this.employees.filter(employee => employee.user_id == self_approver.user_id);
                        if( employee.length > 0 ) {
                            self_approver.user_name = employee[0].user_name;
                            self_approver.user_pic = employee[0].user_pic;
                        }
                    }
                }
            }

        }

        return obj;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetApprovalFileModalInfo       ?: (params : approval_modal_interface.ApprovalFileModalInfo) => void;
    @ModalInfo.Action doSetApproverSelectModalInfo     ?: (params : approval_modal_interface.ApproverSelectModalInfo) => void;
    @ModalInfo.Action doSetApprovalFormModifyModalInfo ?: (params : approval_modal_interface.ApprovalFormModifyModalInfo) => void;
    // @ModalInfo.Action doSetApprovalFormDetailModalInfo ?: (params : approval_modal_interface.ApprovalFormDetailModalInfo) => void;

    position : any[] = [];
    employees : any[] = [];

    default_tab : approval_enum.APPROVAL_DEFAULT_TAB = approval_enum.APPROVAL_DEFAULT_TAB.APPROVER;
 
    is_default_approver_open : boolean = true;
    is_vacation_open : boolean = true;
    is_none_class_open : boolean = true;

    scroll_height : number = 0;
    resize_function_submit : boolean = false;

    vacation_color_open : boolean = false;
    business_trip_color_open : boolean = false;

    async mounted() : Promise<void> {
        // $(".more_temp").click(function(){
        //     $(this).toggleClass("change2");
		// 	$(".more_temp").siblings("ul.text_more").toggleClass("open");
        // });


        //21.03.03 결재자/수신참조 tab
        // $('.content_btm_tab h2').click (function(){
        // 	var borderIndex = $(this).index();
        // 	$('.setDefaultUl_wrap > ul').hide();
        // 	$('.setDefaultUl_wrap > ul').eq(borderIndex).show();
        // 	$(this).addClass('active').siblings().removeClass();

        // 	return false;
        // })

        await this.getOrganizationEmp();
        await this.getOrganizationPosition();
        await this.getApprovalTemplateInfo();
        this.setScroll();

// 추가 jquery
        // $(document).ready(function(){
        //     $(".setDefaultDiv .more_temp").click(function(){
        //         $(".setDefaultDiv").toggleClass("on");
        //         return false;
        //     });
        // });

    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            const titleHeight  : number | undefined = $('.title_box').outerHeight();
            const default_div_height = $('.setDefaultDiv').outerHeight();
            const filterHeight : number | undefined = $('.filter_tap').outerHeight();

            this.scroll_height = window.innerHeight - (titleHeight ? titleHeight : 0)
                                                - (default_div_height ? default_div_height : 0)
                                                - (filterHeight ? filterHeight : 0);

            if( this.resize_function_submit == false ) {
                this.event_bus?.$on('formSettingResize', this.handleResize);
                this.resize_function_submit = true;
            }
        });
    }

    /**
     * 양식 설정 조회
     */
    async getApprovalTemplateInfo() : Promise<void> {
        this.$emit('getApprovalTemplateInfo');
    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
     async getOrganizationEmp() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 전자결재 파일 모달
     */
    async showApprovalFileModal(templateClassId : number) : Promise<void> {

        if( this.isAttendanceOnly() == true ) return;
        
        // 분류 없음 class가 없다면 추가
        if( this.computedTemplate.none_class_template == null ) {
            try {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { approval_class_name : '분류 없음', template_state : '0' });
            
                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("양식 추가에 필요한 데이터를 불러오는 중 오류 발생");
                }

                await this.getOrganizationPosition();
                await this.getApprovalTemplateInfo();

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", '양식 추가에 필요한 데이터를 불러오는 중 오류 발생', ['확인']);
            }
        }

        // 양식 추가 모달 (template_class_id가 0으로 들어온 경우 분류 없음)
        this.doSetApprovalFileModalInfo?.({ 
            show_modal : true, 
            is_create: true, 
            template_class_id: templateClassId == 0 ? this.computedTemplate.none_class_template.template_class_id : templateClassId, 
            callback: () =>{ 
                this.getApprovalTemplateInfo()
            } 
        });
    }

    toggleClass(template : any, index: number) {

        if( this.isAttendanceOnly() == true ) return;

        template.is_fold = !template.is_fold;
        this.computedTemplate.templates.splice(index, 1, template);
        this.$forceUpdate();
    }

    modalAddForm() : void {

        if( this.isAttendanceOnly() == true ) return;

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "분류 추가",
            subtitle : "분류명",
            placeholder : "분류명을 입력해주세요",
            save : async(name) => {
                try {
                    this.insertApprovalTemplateClass(name);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "분류항목 추가 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 선택한 양식파일을 삭제
     */
    async deleteFile(file: any) : Promise<void> {

        if( this.isAttendanceOnly() == true ) return;
        
        const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${file.template_class_id}/${file.template_id}`, API_METHOD.DELETE, null);
        console.log(response)

        if( !response || !this.isHttpStatusSuccess(response.status) ) {
            throw new Error("양식 분류항목 추가 중 오류 발생");
        }

        this.getApprovalTemplateInfo()
    }
    
    /**
     * 선택한 분류 항목들을 모두 삭제
     */
    deleteClass() : void {

        if( this.isAttendanceOnly() == true ) return;

        let selectedTemplateClassIds : number[] = []
        
        for ( const template of this.computedTemplate.templates ) {
            if ( template.is_selected ) {
                selectedTemplateClassIds.push(template.template_class_id as number)
            }
        }
        
        if ( selectedTemplateClassIds.length < 1 ) {
            return
        }

        this.hodu_show_dialog("cancel", selectedTemplateClassIds.length > 1 ? "선택하신 분류들을 삭제하시겠습니까?" : "선택하신 분류를 삭제하시겠습니까?", ['아니오', '예'], [
            () => {},
            () => {
                this.deleteApprovalTemplateClasses(selectedTemplateClassIds);
            }
        ]);
    }
    
    /**
     * 선택된 분류항목 삭제 서비스 호출
     */
    async deleteApprovalTemplateClasses(class_ids: number[]) : Promise<void> {
        
        let body = {
            class_ids : class_ids
        }

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.DELETE, body);
            console.log(response);
            
            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("분류항목 삭제 중 오류 발생");
            }
    
            await this.getApprovalTemplateInfo();

        }catch(e){
            this.hodu_error_process(e, false, false, true);
            alert("분류항목 삭제 중 오류 발생");
        }
    }

    /**
     * 분류항목 추가 서비스 호출
     */
    async insertApprovalTemplateClass(class_name: String) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, {approval_class_name : class_name});
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("양식 분류항목 추가 중 오류 발생");
            }

            await this.getApprovalTemplateInfo();

        } catch(e) {
            this.hodu_show_dialog("cancel", "양식 분류항목 추가 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 상세 모달
     */
    showApprovalFormDetailModal() : void {
        // this.doSetApprovalFormDetailModalInfo?.({
        //     show_modal : true,
        //     template_class_id : 0,
        //     template_id : 0
        // });
    }

    /**
     * 결재자 선택 모달
     */
    async showApproverSelectModal(template_state : string, template) : Promise<void> {

        if( template_state != '20' && template_state != '30' && this.isAttendanceOnly() == true ) {
            return;
        }

        await this.hodu_show_indicator();
        await this.getOrganizationPosition();
        await this.getApprovalTemplateInfo();
        await this.hodu_hide_indicator();
        
        let target_approver;

        if( template == null ) {
            target_approver = [];
        }
        else {
            const target_template_class_array = this.computedTemplate.all_templates.filter(temp_class => temp_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id ).length > 0);
            
            if( target_template_class_array.length < 1 ) {
                target_approver = [];
            }
            else {
                const target_template_class = target_template_class_array[0];
                const target_template = target_template_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id )[0];
                target_approver = (target_template != null && target_template.approver != null && target_template.approver.length > 0) ? JSON.parse(JSON.stringify(target_template.approver)) : []
            }
            
        }

        // 해당 양식에 기본 결재자가 없다면 && 전체 기본 결재자가 존재한다면
        if( template_state != '50' && target_approver.length < 1 && (this.computedTemplate.default_template != null && this.computedTemplate.default_template.files != null && this.computedTemplate.default_template.files.length > 0 && this.computedTemplate.default_template.files[0].approver != null && this.computedTemplate.default_template.files[0].approver.length > 0) ) {
            target_approver = JSON.parse(JSON.stringify(this.computedTemplate.default_template.files[0].approver));
        }

        for( const approver of target_approver ) {
            if( approver.approver_type != 'POSITION' ) continue;
            approver.position_seq = this.getPositionSeq(approver.pos_id) - 1;
        }

        this.doSetApproverSelectModalInfo?.({
            show_modal : true,
            is_approver : template_state != '50',
            is_default_setting : true,
            is_self_approve : false,
            selected : target_approver,
            callback : async(data : any) => {
                try {

                    console.log(data);
                    
                    // 일반 양식 결재자
                    if( template_state == '10' ) {
                        template.approver = data;
                        const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                        
                        console.log(response);

                        if( !response || !this.isHttpStatusSuccess(response.status) ) {
                            throw new Error("결재자 등록 중 오류 발생");
                        }
                    }

                    // 기본 결재자
                    else if( template_state == '20' ) {
                        if( this.computedTemplate.default_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '기본결재자',
                                template_state : '20',
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                            
                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 휴가원
                    else if( template_state == '30' ) {
                        if( this.computedTemplate.vacation_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '휴가원',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 출장신청서
                    else if( template_state == '40' ) {
                        if( this.computedTemplate.business_trip_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '출장신청서',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 지출결의서
                    else if( template_state == '60' ) {
                        if( this.computedTemplate.cash_disbursement_voucher_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '지출결의서',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 양식없음
                    else if( template_state == '70' ) {
                        if( this.computedTemplate.free_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '양식없음',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 휴일근무
                    else if( template_state == '80' ) {
                        if( this.computedTemplate.holiday_work_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '휴일근무',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 기본 수신참조
                    else if( template_state == '50' ) {
                        if( this.computedTemplate.receive_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '기본수신참조',
                                template_state : template_state,
                                approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("수신참조 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("수신참조 등록 중 오류 발생");
                            }
                        }
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);

                    if( template_state != '50' ) {
                        this.hodu_show_dialog("cancel", "결재자 등록 중 오류 발생", ['확인']);
                    } 
                    else {
                        this.hodu_show_dialog("cancel", "수신참조 등록 중 오류 발생", ['확인']);
                    }
                    
                } finally {
                    await this.hodu_show_indicator();
                    await this.getOrganizationPosition();
                    await this.getApprovalTemplateInfo();
                    await this.hodu_hide_indicator();
                }
            }
        });
    }

    /**
     * 자가 결재 설정
     */
    async showSelfApproverSelectModal(template_state : string, template) : Promise<void> {

        if( template_state != '20' && template_state != '30' && this.isAttendanceOnly() == true ) {
            return;
        }

        await this.hodu_show_indicator();
        await this.getOrganizationPosition();
        await this.getApprovalTemplateInfo();
        await this.hodu_hide_indicator();
        
        let target_self_approver;

        if( template == null ) {
            target_self_approver = [];
        }
        else {
            const target_template_class_array = this.computedTemplate.all_templates.filter(temp_class => temp_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id ).length > 0);
            
            if( target_template_class_array.length < 1 ) {
                target_self_approver = [];
            }
            else {
                const target_template_class = target_template_class_array[0];
                const target_template = target_template_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id )[0];
                target_self_approver = (target_template != null && target_template.self_approver != null && target_template.self_approver.length > 0) ? JSON.parse(JSON.stringify(target_template.self_approver)) : []
            }
        }

        this.doSetApproverSelectModalInfo?.({
            show_modal : true,
            is_approver : true,
            is_default_setting : true,
            is_self_approve : true,
            selected : target_self_approver,
            callback : async(data : any) => {

                console.log(data);

                try {

                    await this.hodu_show_indicator();
                    await this.getOrganizationPosition();
                    await this.getApprovalTemplateInfo();
                    await this.hodu_hide_indicator();

                    let target_approver;

                    if( template == null ) {
                        target_approver = [];
                    }
                    else {
                        const target_template_class_array = this.computedTemplate.all_templates.filter(temp_class => temp_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id ).length > 0);
                        
                        if( target_template_class_array.length < 1 ) {
                            target_approver = [];
                        }
                        else {
                            const target_template_class = target_template_class_array[0];
                            const target_template = target_template_class.files.filter(temp => temp.template_class_id == template.template_class_id && temp.template_id == template.template_id )[0];
                            target_approver = (target_template != null && target_template.approver != null && target_template.approver.length > 0) ? JSON.parse(JSON.stringify(target_template.approver)) : []
                        }
                    }

                    // 일반 양식 결재자
                    if( template_state == '10' ) {
                        template.approver = target_approver;
                        template.self_approver = data;  
                        const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                        
                        console.log(response);

                        if( !response || !this.isHttpStatusSuccess(response.status) ) {
                            throw new Error("자가 결재자 등록 중 오류 발생");
                        }
                    }

                    // 시스템 양식 - 휴가원
                    else if( template_state == '30' ) {
                        if( this.computedTemplate.vacation_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '휴가원',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 출장신청서
                    else if( template_state == '40' ) {
                        if( this.computedTemplate.business_trip_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '출장신청서',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 지출결의서
                    else if( template_state == '60' ) {
                        if( this.computedTemplate.cash_disbursement_voucher_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '지출결의서',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 양식없음
                    else if( template_state == '70' ) {
                        if( this.computedTemplate.free_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '양식없음',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                    // 시스템 양식 - 휴일근무
                    else if( template_state == '80' ) {
                        if( this.computedTemplate.holiday_work_template == null ) {
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                                approval_class_name : '휴일근무',
                                template_state : template_state,
                                approver : target_approver,
                                self_approver : data 
                            });

                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }

                        }
                        else {
                            template.approver = target_approver;
                            template.self_approver = data;
                            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                            
                            console.log(response);

                            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                                throw new Error("자가 결재자 등록 중 오류 발생");
                            }
                        }
                    }

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "자가 결재자 등록 중 오류 발생", ['확인']);
                } finally {
                    await this.hodu_show_indicator();
                    await this.getOrganizationPosition();
                    await this.getApprovalTemplateInfo();
                    await this.hodu_hide_indicator();
                }

            }
        });
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        const jquery_target = $(event.target);
        const defaultImage = require('@/assets/images/contents/ic_approval_user.png');
        jquery_target.parent().find('p.img').css("background-image", `url(${defaultImage})`);
    }

    /**
     * 양식 수정모달
     */
    modify(file) : void {

        if( this.isAttendanceOnly() == true ) return;

        this.doSetApprovalFormModifyModalInfo?.({
            show_modal : true,
            template_class_id : file.template_class_id,
            template_id : file.template_id,
            callback : async() => {
                await this.getOrganizationPosition();
                await this.getApprovalTemplateInfo();
            }
        });
    }

    /**
     * 근태관리 기능만 ON 인 상태
     */
    isAttendanceOnly() : boolean {
        return (this.isEnableGroupFeature(this.scope_group_id, 'approval') == false && this.isEnableGroupFeature(this.scope_group_id, 'attendance') == true);
    }

    /**
     * 휴가원 색상
     */
    showSelectVacationColor() : void {
        this.vacation_color_open = !this.vacation_color_open;
    }

    /**
     * 출장신청서 색상
     */
    showSelectBusinessTripColor() : void {
        this.business_trip_color_open = !this.business_trip_color_open;
    }

    /**
     * 선택된 색상 없음
     */
    isColorNone(template) {
        return (template == null || template.files == null || template.files.length < 1 || template.files[0].contents == null ||
            template.files[0].contents.color == null || template.files[0].contents.color.length < 1);
    }

    /**
     * 선택된 색상인가?
     */
    isColorChecked(template, color) {

        if( this.isColorNone(template) == true ) {
            return false;    
        }

        return this.hodu_hex_color_process(template.files[0].contents.color).toUpperCase() == this.hodu_hex_color_process(color).toUpperCase();
    }

    /**
     * 휴가원 색상 선택
     */
    async setVacationTemplateColor(color, bar_color) {

        try {
            if( this.computedTemplate.vacation_template == null ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                    approval_class_name : '휴가원',
                    template_state : '30',
                    approver : [],
                    contents : { color : color, bar_color : bar_color } 
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("색상 등록 중 오류 발생");
                }

            }
            else {

                const template = this.computedTemplate.vacation_template.files[0];
                template.contents.color = color;
                template.contents.bar_color = bar_color;
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("색상 등록 중 오류 발생");
                }
            }

            const login_response = await this.hodu_api_call('api/v1/user/me', API_METHOD.GET);

            console.log(login_response);

            if( login_response == null || !this.isHttpStatusSuccess(login_response.status) || login_response.data == null || login_response.data.data == null ) {
                throw new Error("유저 정보 갱신 실패");
            }

            await this.doLogin({
                isLogin           : true,
                user_id           : login_response.data.data.user.user_id,
                user_type         : login_response.data.data.last_login_type,
                user_email        : login_response.data.data.user.user_email,
                user_name         : login_response.data.data.user.user_name,
                user_phone_number : login_response.data.data.user.user_phone_number,
                is_temp_password  : login_response.data.data.is_temp_password,
                user_preference   : login_response.data.data.user_preference,
                user_group_role   : [],
                user_team_role    : [],
                template_map      : login_response.data.data.template_map,
                auth_info         : login_response.data.data.user.auth_info
            });

            await this.get_group_role_service();

            // 달력 환경설정 정보 갱신
            await this.calendar_preference_sync(false);

            // 친구 정보 갱신
            await this.getFriends();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "색상 등록 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_show_indicator();
            await this.getOrganizationPosition();
            await this.getApprovalTemplateInfo();
            await this.hodu_hide_indicator();
        }

    }

    /**
     * 출장신청서 색상 선택
     */
    async setBusinessTripTemplateColor(color, bar_color) {

        try {

            if( this.computedTemplate.business_trip_template == null ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                    approval_class_name : '출장신청서',
                    template_state : '40',
                    approver : [],
                    contents : { color : color, bar_color : bar_color } 
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("색상 등록 중 오류 발생");
                }

            }
            else {

                const template = this.computedTemplate.business_trip_template.files[0];
                template.contents.color = color;
                template.contents.bar_color = bar_color;
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("색상 등록 중 오류 발생");
                }
            }

            const login_response = await this.hodu_api_call('api/v1/user/me', API_METHOD.GET);

            console.log(login_response);

            if( login_response == null || !this.isHttpStatusSuccess(login_response.status) || login_response.data == null || login_response.data.data == null ) {
                throw new Error("유저 정보 갱신 실패");
            }

            await this.doLogin({
                isLogin           : true,
                user_id           : login_response.data.data.user.user_id,
                user_type         : login_response.data.data.last_login_type,
                user_email        : login_response.data.data.user.user_email,
                user_name         : login_response.data.data.user.user_name,
                user_phone_number : login_response.data.data.user.user_phone_number,
                is_temp_password  : login_response.data.data.is_temp_password,
                user_preference   : login_response.data.data.user_preference,
                user_group_role   : [],
                user_team_role    : [],
                template_map      : login_response.data.data.template_map,
                auth_info         : login_response.data.data.user.auth_info
            });

            await this.get_group_role_service();

            // 달력 환경설정 정보 갱신
            await this.calendar_preference_sync(false);

            // 친구 정보 갱신
            await this.getFriends();
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "색상 등록 중 오류 발생", ['확인']);
        } finally {
            await this.hodu_show_indicator();
            await this.getOrganizationPosition();
            await this.getApprovalTemplateInfo();
            await this.hodu_hide_indicator();
        }

    }

    vacationColorOutsideClick() : void {
        this.vacation_color_open = false;
    }

    businessTripColorOutsideClick() : void {
        this.business_trip_color_open = false;
    }

    /**
     * 순차열람 여부
     */
    isInOrderApproval() : boolean {
        const feature = this.get_group_feature(this.scope_group_id);

        try {
            return feature != null && feature['approval'] != null ? (feature['approval']['is_in_order_approval'] ? feature['approval']['is_in_order_approval'] : false) : false;
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

        return false;
    }

    /**
     * 순차열람 옵션 변경
     */
    async changeIsInOrderApproval(event) {
        try {
            const feature = this.get_group_feature(this.scope_group_id);

            if( feature == null || feature['approval'] == null ) {
                throw new Error("순차열람 옵션 변경 중 오류 발생");
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/features/approval?key=is_in_order_approval&feature_flag=${event.target.checked}`, API_METHOD.PUT);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("순차열람 옵션 변경 중 오류 발생");
            }

        } catch(e) {
            this.hodu_show_dialog("cancel", "순차열람 옵션 변경 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        } finally {
            this.get_group_role_service();
        }
    }

    async changeHolidayWorkVisible(event) : Promise<void> {

        try {
            const checked = event.target.checked;
            
            if( this.computedTemplate.holiday_work_template != null && this.computedTemplate.holiday_work_template.files != null && this.computedTemplate.holiday_work_template.files[0] != null ) {
                
                const template = this.computedTemplate.holiday_work_template.files[0];
                
                if( template.contents == null ) {
                    template.contents = { "visible" : checked };
                }
                else {
                    template.contents.visible = checked;
                }
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/${template.template_class_id}/${template.template_id}`, API_METHOD.PUT, template);
                
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴일근무 사용여부 변경 중 오류 발생");
                }

                
            }
            else {
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class`, API_METHOD.POST, { 
                    approval_class_name : '휴일근무',
                    template_state : '80',
                    approver : [],
                    contents : { "visible" : checked } 
                });

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("휴일근무 사용여부 변경 중 오류 발생");
                }
            }

            await this.getApprovalTemplateInfo();
            
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "휴일근무 사용여부 변경 중 오류 발생", ['확인']);
        }
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 리사이즈 감지
     */
    @Watch('is_default_approver_open')
    handleResize() : void {
        // @ts-ignore
        // $('#form_list_scroll').mCustomScrollbar('destroy');
        this.setScroll();
    }

    //21.03.03 결재자/수신참조 tab
    // ('.content_btm_tab h2').click (function(){
	// 	var borderIndex = $(this).index();
	// 	$('.setDefaultUl_wrap > ul').hide();
	// 	$('.setDefaultUl_wrap > ul').eq(borderIndex).show();
	// 	$(this).addClass('active').siblings().removeClass();

	// 	return false;
    // })
    
    


}

    
