
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { EquipmentImportModalInfo } from '@/store/modules/ModalInfo';
import { t_event_file } from '@/model/event';

@Component({
    components: {
        
    },
})
export default class EquipmentImportModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State equipment_import_modal_info !: EquipmentImportModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetEquipmentImportModalInfo ?: (params : EquipmentImportModalInfo) => void;

    file : File | null = null;
    file_obj : t_event_file | null = null;    

    mounted() : void {
        
    }

    /**
     * 양식 파일 다운로드
     */
    async exampleDownload() : Promise<void> {
        try {
            const response = await this.$http({
                url : 'app_images/hodu_equipment_material_import_excel_example.xlsx',
                method : 'GET',
                responseType: 'blob'
            });

            console.log(response);

            const blob = new Blob([response.data]);

            // @ts-ignore
            if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                // @ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, "호두_장비_자재_가져오기_양식.xlsx"); // for IE
                return;
            }

            await this.hodu_download_blob(blob, "호두_장비_자재_가져오기_양식.xlsx");

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert('장비/자재 가져오기 양식 다운로드 중 오류 발생');
        }
    }

    /**
     * 파일 추가 - +버튼 눌러서 추가
     */
    addFile(event) : void {

        try {
            const files : File[] = event.target.files;

            if( files.length < 1 ) {
                this.file = null;
                this.file_obj = null;
                $('#uploadfile').val("");
                return;
            }

            const temp_file = files[0];

            if( temp_file.name.substring(temp_file.name.lastIndexOf('.')) != '.xlsx' ) {
                alert("xlsx 파일만 업로드 가능합니다");
                $('#uploadfile').val("");
                this.file = null;
                this.file_obj = null;
                return;
            }

            this.file = temp_file;

            this.file_obj = JSON.parse(JSON.stringify({
                name: temp_file.name,     // 원본 파일명
                mimeType: temp_file.type, // MIME TYPE
                url: "",                  // 파일 경로
                size: temp_file.size,     // 파일 크기
                date: new Date()          // 저장 날짜
            }));

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert('파일 업로드 중 오류 발생');
        }
    }

    /**
     * 장비/자재 가져오기
     */
    async importEquipment() : Promise<void> {
        try {

            if( this.file == null || this.file_obj == null ) {
                alert("파일을 추가해주세요");
                return;
            }

            await this.hodu_show_indicator();

            const form_data : FormData = new FormData();

            form_data.append('file', this.file);

            const temp_response = await this.hodu_temp_upload(form_data);
            console.log(temp_response);

            if( !temp_response || !this.isHttpStatusSuccess(temp_response.status) || !temp_response.data || !temp_response.data.data ) {
                throw new Error("파일 임시 업로드 실패");
            }

            const temp_files : t_event_file[] = temp_response.data.data.temp_files;

            for( const temp_file of temp_files ) {
                this.file_obj = JSON.parse(JSON.stringify(temp_file));
            }

            // 엑셀 장비/자재 가져오기
            const excel_response = await this.hodu_api_call(`api/v1/excel/equipment`, API_METHOD.POST, {
                group_id : this.equipment_import_modal_info.group_id,
                file : this.file_obj
            });

            console.log(excel_response);

            if( !excel_response || !this.isHttpStatusSuccess(excel_response.status) ) {
                throw new Error("장비/자재 가져오기 실패");
            }

            window['equipment_reload']?.();
            this.close();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert('장비/자재 가져오기 중 오류 발생');
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetEquipmentImportModalInfo?.({
            show_modal : false,
            group_id : 0
        });
    }

}
