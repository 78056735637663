
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupModalInfo, GroupListConfirmModalData } from '@/store/modules/ModalInfo';
const ModalInfo = namespace('ModalInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class GroupListConfirmModal extends Mixins(VueHoduCommon) {
    @ModalInfo.State group_modal_info !: GroupModalInfo;

    data            !: GroupListConfirmModalData;
    type             : string  = "";
    name             : string  = "";
    image            : string  = "";
    description      : string  = "";
    color            : string  = "";
    content          : string  = "";
    sub_content      : string  = "";

    async mounted() {
        if ( null != this.group_modal_info.data ) {
            this.data        = this.group_modal_info.data;
            this.type        = this.data.type;
            this.name        = this.data.name;
            this.image       = this.data.image;
            this.description = this.data.description;
            this.color       = this.data.color;

            //! ===============================================================
            //! 가입요청 , 가입취소
            //! ===============================================================
            if ( this.data.modal_type == "join_cancel" ) {
                if ( this.data.user_id == 0 ) {
                    $('#border_color').css('border-color', '#477fff');
                    this.content = (this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ? this.name+"에" : (this.type == "GROUP" ? "그룹에" : "팀에")) + " 가입하시겠습니까?";
                }
                else {
                    // if ( this.data.appr_state == "R" ) {
                    $('#border_color').css('border-color', '#ffa70e');
                    this.content = "가입요청을 취소하시겠습니까?";
                    this.sub_content = "가입요청일 : " + this.data.member_created;
                    // }
                    // else if ( this.data.appr_state == "E" || this.data.appr_state == "T" ) {
                    //     $('#border_color').css('border-color', '#ffa70e');
                    //     this.content = "가입요청이 거절되었습니다. 가입요청을 취소하시겠습니까?";
                    //     this.sub_content = "가입요청을 취소하시겠습니까?";
                    // }
                }
            }
            //! ===============================================================
            //! 그룹 팀 탈퇴 (내자신)
            //! ===============================================================
            else if ( this.data.modal_type == "my_out" ) {

                $('#border_color').css('border-color', '#ff6060');

                if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                    this.content = (this.type == "GROUP" ? "아파트" : "동") + "에서 탈퇴하시겠습니까?";
                    this.sub_content = (this.type == "GROUP" ? "아파트에 관련된 모든 데이터가 삭제되며 가입된 동에서도 자동 탈퇴됩니다." : "동에 관련된 모든 데이터가 삭제됩니다.");
                }

                else {
                    this.content = (this.type == "GROUP" ? "그룹" : "팀") + "에서 탈퇴하시겠습니까?";
                    this.sub_content = (this.type == "GROUP" ? "그룹에 관련된 모든 데이터가 삭제되며 가입된 팀에서도 자동 탈퇴됩니다." : "팀에 관련된 모든 데이터가 삭제됩니다.");
                }
            }
        }
    }

    groupListConfirmCancel() : void {
        window["groupListConfirmCancel"]();
    }

    groupListConfirm() : void {
        window["groupListConfirm"](this.data);
    }
}
