
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const EventInfo = namespace('EventInfo');
const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');

import { t_event, t_event_reply, t_event_file } from '@/model/event';

import moment from 'moment';

import { ResizeObserver } from 'vue-resize';
import { GroupNoticeReplyModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        ResizeObserver
    },
}) export default class GroupNoticeReplyModal extends Mixins(VueHoduCommon) {
    
    /**
     * @GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;

    /**
     * @EventInfo.Action
     */
    @EventInfo.Action doSetEventImagePreviewInfo ?: any;

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State group_notice_reply_modal_info !: GroupNoticeReplyModalInfo;
    @ModalInfo.State show_event_image_preview !: boolean;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupNoticeReplyModalInfo ?: (params : GroupNoticeReplyModalInfo) => void;
    @ModalInfo.Action doSetShowEventImagePreview ?: any;

    reply_list : t_event_reply[] = [];
    read_count : number[] = [];
    display_reply_list : any[] = [];

    reply_string : string = "";

    isShift : boolean = false; // 쉬프트가 눌렸는지 여부
    
    // 이미지 1장 최대 너비, 높이
    image_max_width  : number = 300;
    image_max_height : number = 0;
    image_min_width  : number = 100;
    image_min_height : number = 100;

    get_reply_interval : number | undefined;

    async mounted() : Promise<void> {
        
        // 이미지뷰 1장 일때 max-height 설정
        const cmmntModal : HTMLElement | null = document.getElementById("cmmntModal");
        if( cmmntModal ) { this.image_max_height = Math.round(screen.height * 0.5); }

        // 최초 댓글 조회
        await Promise.all([this.getAllReply(), this.getAllReplyRead()]);
        
        // 2초마다 댓글 조회
        this.get_reply_interval = window.setInterval(async() => {
            Promise.all([this.getAllReply(), this.getAllReplyRead()]);
        }, 2000);

        this.setScroll();

        // @ts-ignore
        setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom', { scrollInertia : 0 }); }, 1);
    }

    /**
     * 종료 전 interval 제거
     */
    beforeDestroy() : void {
        if( this.get_reply_interval ) { clearInterval(this.get_reply_interval); }
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        // @ts-ignore
        setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom', { scrollInertia : 0 }); }, 1);

        const title_height : number | undefined = $('#reply_title').outerHeight();
        const write_height : number | undefined = $('#reply_write_area').outerHeight();
        // @ts-ignore
        $('.modal-scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 120,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - ( title_height == null ? 0 : title_height ) - ( write_height == null ? 0 : write_height )
        });
    }

    /**
     * 모든 댓글 불러오기
     */
    async getAllReply() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?isGroupNotiMode=true`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.event_reply ) {
                throw new Error("댓글 조회 실패");
            }

            this.reply_list.splice(0, this.reply_list.length);
            this.display_reply_list.splice(0, this.display_reply_list.length);
            this.reply_list = this.reply_list.concat(JSON.parse(JSON.stringify(response.data.data.event_reply)));

            // 댓글 날짜별 분류
            const reply_length : number = this.reply_list.length;
            for( let i = 0; i < reply_length; i++ ) {
                const reply : t_event_reply = this.reply_list[i];

                if( reply == null || reply.reply_data == null || reply.reply_data.contents == null || reply.audit_modified == null ) {
                    continue;
                }
                
                reply.reply_data.contents = reply.reply_data.contents.split('\n').join("<br>");
                
                // 비교용 데이터 생성 (display_reply_list에 없다면 새롭게 생성 해야함)
                const reply_time_for_sort : number = new Date(moment(this.reply_list[i].audit_modified).format('YYYY-MM-DD')).getTime();
                const reply_date          : string = `${ moment(this.reply_list[i].audit_modified).format('YYYY.MM.DD') } ${ this.getDayOfWeek(new Date(reply.audit_modified)) }`;

                // display_reply_list에 존재하는지 체크
                let index_of_reply_date : number = -1;
                const display_count : number = this.display_reply_list.length;
                for( let j = 0; j < display_count; j++ ) {
                    if( reply_time_for_sort == this.display_reply_list[j].reply_time_for_sort ) {
                        index_of_reply_date = j;
                        break;
                    }
                }

                // 해당 날짜의 정보가 없었다면 추가한다
                if( index_of_reply_date == -1 ) {
                    index_of_reply_date = this.display_reply_list.push({
                        "reply_time_for_sort" : reply_time_for_sort,
                        "reply_date"          : reply_date,
                        "reply"               : []
                    }) - 1;
                }

                // 추가
                this.display_reply_list[index_of_reply_date].reply.push(JSON.parse(JSON.stringify(reply)));
            }

        } catch(e) {
            alert("조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
            this.close();
        }
    }

    /**
     * 댓글 읽음 여부 조회
     */
    async getAllReplyRead() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}/read?isGroupNotiMode=true`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.user_read ) {
                throw new Error("댓글 읽음 여부 조회 실패");
            }

            this.read_count.splice(0, this.read_count.length);

            for (const [key, value] of Object.entries(response.data.data.user_read)) {
                this.read_count.push(Number(value));
            }            

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 한 글자 요일 텍스트 가져오기 
     */
    getDayOfWeek(date : Date) : string {

        if( date == null ){
            return "";
        }

        switch( date.getDay() ){
            case 0:
                return "일";

            case 1:
                return "월";

            case 2:
                return "화";

            case 3:
                return "수";

            case 4:
                return "목";

            case 5:
                return "금";

            case 6:
                return "토";

            default:
                return "?";
        }
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * Date로 시간 text 만들기
     */
    getTimeTextByDate(reply_date : Date) : string {
        const current_date : Date = new Date();

        const time_millis : number = current_date.getTime() - reply_date.getTime();

        // 같은 날짜일때
        if( this.getDateDiff(reply_date, current_date) < 1 ) {
            
            // 시간 차이 1분 미만
            if( time_millis < 1 * 60 * 1000 ) {
                return "방금 전"
            }

            // 시간 차이 10분 전 미만
            if( time_millis < 10 * 60 * 1000 ) {
                return `${Math.floor(time_millis / 60 / 1000)}분 전`;
            }

            // 시간 차이 1시간 전 미만
            if( time_millis < 60 * 60 * 1000 ) {
                return `${Math.floor(time_millis / 60 / 10 / 1000)}0분 전`;
            }

            // 시간 차이 6시간 전 미만
            if( time_millis < 6 * 60 * 60 * 1000 ) {
                return `${Math.floor(time_millis / 60 / 60 / 1000)}시간 전`;
            }

        }

        // 같은 날짜의 6시간 전 보다 차이가 큰 날짜 && 다른 날의 날짜는 AM, PM 시간을 그대로 리턴
        const hour : string = `0${reply_date.getHours() == 0 ? '12' : reply_date.getHours() > 12 ? reply_date.getHours() - 12 : reply_date.getHours() }`.slice(-2);
        const min  : string = `0${reply_date.getMinutes()}`.slice(-2);
        const amPm : string = `${reply_date.getHours() < 12 ? '오전' : '오후' }`;
        return `${amPm} ${hour}:${min}`;
    }

    /**
     * 삭제 버튼에 mouseover
     */
    hoverDelete(event) : void {
        if( $(event.target).parent().parent().parent().hasClass('xHover') == true ) {
            return;
        }

        $(event.target).parent().parent().parent().addClass('xHover');
    }

    /**
     * 삭제 버튼에 mouseover
     */
    leaveDelete(event) : void {
        if( $(event.target).parent().parent().parent().hasClass('xHover') == false ) {
            return;
        }

        $(event.target).parent().parent().parent().removeClass('xHover');
    }

    /**
     * 댓글 삭제
     */
    async deleteReply(event, reply : t_event_reply, dayIndex : number, reply_index : number) : Promise<void> {

        const reply_uuid : string = reply.event_reply_id == null ? "" : reply.event_reply_id

        // 삭제 효과
        if( event ) { $(event.target).parent().parent().parent().addClass('delChat'); }
        setTimeout(() => this.display_reply_list[dayIndex].reply.splice(reply_index, 1), 200);

        // API 호출
        try {
            const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?isGroupNotiMode=true`, API_METHOD.DELETE, { "reply_uuid" : reply_uuid }, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("댓글 삭제 실패");
            }

            this.group_notice_reply_modal_info.callback?.();
            await setTimeout(async() => { await Promise.all([this.getAllReply(), this.getAllReplyRead()]); }, 100);

        } catch(e) {
            alert("댓글 삭제 중 오류 발생");
            this.hodu_error_process(e, false, false, true);

            // 삭제 실패시 다시 원래대로
            await setTimeout(async() => {
                if( event ) { $(event.target).parent().parent().parent().removeClass('delChat'); }
                this.display_reply_list[dayIndex].reply.splice(reply_index, 0, reply); 
            } , 100);
        }
    }

    /**
     * shift + event 개행
     * enter         댓글 등록
     */
    keyUpTextArea(event) : void {
        if( event.keyCode == 16 ) { this.isShift = false; } 
    }

    /**
     * shift + event 개행
     * enter         댓글 등록
     */
    keyDownTextArea(event) : boolean {
        if( event.keyCode == 16 ) { this.isShift = true; } 
        if( event.keyCode == 13 && this.isShift == false ) { 
            this.saveReply(event);
            return false; 
        } 

        return true;
    }

    /**
     * 댓글 저장
     */
    async saveReply(event : any = null) : Promise<void> {

        if( event != null ) {
            event.stopPropagation();
        }
        
        // 아무것도 입력 안했다면 종료
        if( this.reply_string.trim().length < 1 ) {
            return;
        }

        try {
            const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?isGroupNotiMode=true`, API_METHOD.POST, { 
                user_name : this.user_name,  
                contents : this.reply_string.trim()
            }, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("댓글 저장 실패");
            }

            this.group_notice_reply_modal_info.callback?.();
            await Promise.all([this.getAllReply(), this.getAllReplyRead()]);

            this.reply_string = "";

            // @ts-ignore
            this.$nextTick(() => { setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom'); }, 1); });

        } catch(e) {
            alert("댓글 등록 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 댓글 모달 닫기
     */
    close() : void {
        this.doSetGroupNoticeReplyModalInfo?.({
            show_modal : false,
            noti_uid : ""
        });
    }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
    fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        // DO NOTHING, 나중에 효과 생길때 사용
        // this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        this.addFileEvent(event.dataTransfer.files);
    }

    /**
     * 파일 추가
     */
    addImages(event :any) {
        this.addFileEvent(event.target.files);
    }

    /**
     * 댓글 파일 추가
     */
    async addFileEvent(files : File[]) : Promise<void> {
        const vue = this;

        const event_files : t_event_file[] = [];
        const resize_files : File[] = [];
        if( files.length == 0 ) { return; }

        // 이미지는 15장까지만 올릴 수 있다 
        if( files.length > 15 ) {
            if( files instanceof FileList ) {
                files = Array.prototype.slice.call(files, 0, 15);
            }

            else {
                alert(`파일은 한번에 15개까지 업로드 가능합니다`);
                $('#replyImage').val("");
                return;
            }
        }

        await this.hodu_show_indicator();
        const file_count : number = files.length;
        let end_count  : number = 0;
        for( let i = 0; i < file_count; i++ ) {

            const file = files[i];

            let max_size : number = this.is_premium_group('GROUP', this.group_id) ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
            let max_size_text : string = this.is_premium_group('GROUP', this.group_id) ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

            // 파일 용량 체크
            if( file.size > max_size ) {
                await this.hodu_hide_indicator();
                this.hodu_show_dialog("alert", `${max_size_text} 이하의 파일만 업로드 가능 합니다`, ['확인']);
                $('#replyImage').val("");
                return;
            }

            // 확장자가 없는 파일
            if( file.name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#replyImage').val("");
                await this.hodu_hide_indicator();
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(file.name.substring(file.name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ file.name.substring(file.name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#replyImage').val("");
                await this.hodu_hide_indicator();
                return;
            }

            // 이미지인지 파일인지 구분해서 이미지라면 리사이즈 한다
            if( new RegExp('image').test(file.type) == true ) {

                await this.fileReaderPromise(files[i])
                    .then(async(pe_fr : any) => {

                        if( pe_fr.target == null || pe_fr.target.result == null ){
                            return;
                        }

                        let base64url : string = "";

                        if( pe_fr.target.result instanceof ArrayBuffer ){
                            const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                            const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                            base64url = decodeURIComponent(url);
                        } else {
                            base64url = pe_fr.target.result;
                        }

                        const resize_data = await vue.hodu_image_resize_return_with_image_size(base64url);
                        const blob : Blob = resize_data.return_blob;

                        // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                        let resize_file : File = files[i];
                        try{
                            resize_file = await this.hodu_blob_to_file(blob, files[i].name);
                        }catch(e){
                            try {
                                (blob as any).lastModifiedDate = new Date();
                                (blob as any).name = files[i].name;
                                resize_file = (blob as any);
                            } catch(e) {
                                this.hodu_error_process(e, false, false, true);
                            }
                        }

                        event_files.push({
                            name: resize_file.name,            // 원본 파일명
                            mimeType: resize_file.type,        // MIME TYPE
                            url: base64url,                    // 파일 경로
                            size: resize_file.size,            // 파일 크기
                            date: new Date(),                  // 저장 날짜
                            image_info: resize_data.image_info // 이미지 정보
                        });

                        resize_files.push(resize_file);

                    })
                    .catch((e) => {
                        console.log('filereader promise error',e);
                    });
            }
            else {

                event_files.push({
                    name: file.name,     // 원본 파일명
                    mimeType: file.type, // MIME TYPE
                    url : "",            // 파일 경로
                    size: file.size,     // 파일 크기
                    date: new Date(),    // 저장 날짜
                });

                resize_files.push(file);
            }
        }

        // 임시 파일 업로드
        const form_data : FormData = new FormData();
            
        // FormData 내용 생성
        const image_files_count : number = resize_files.length;
        for( let i = 0; i < image_files_count; i++ ) {
            form_data.append('file', resize_files[i]);
        }

        await this.hodu_temp_upload(form_data)
            .then(async(response) => {
                console.log(response);
                const temp_files : t_event_file[] = response.data.data.temp_files;

                // 임시파일 데이터로 객체 대체하기
                const image_files_count : number = event_files.length;
                for( let i = 0; i < image_files_count; i++ ) {
                    const temp = temp_files[i];

                    if( new RegExp('image').test(event_files[i].mimeType) ) {
                        temp.image_info = event_files[i].image_info; 
                    }

                    event_files.splice(i, 1, temp);
                }

            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            })
            .finally(() => {
                $('#replyImage').val("");
            });

        // 일반 파일과 이미지가 겹치지 않도록 한다
        const img : t_event_file[] = event_files.filter(item => new RegExp('image').test(item.mimeType) == true);
        const file : t_event_file[] = event_files.filter(item => new RegExp('image').test(item.mimeType) == false);

        const calendar_id = this.team_id > 0 ? `team-${this.team_id}` : `group-${this.group_id}`;

        if( img.length > 0 ) {

            // 내용이 없는 댓글 등록
            try {

                const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?calendar_id=${calendar_id}&isGroupNotiMode=true`, API_METHOD.POST, {
                    user_name : this.user_name, 
                    contents : "",
                    images : img
                }, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("댓글 이미지 등록 실패");
                }

                this.group_notice_reply_modal_info.callback?.();
                await Promise.all([this.getAllReply(), this.getAllReplyRead()]);

                // @ts-ignore
                this.$nextTick(() => { setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom'); }, 1); });

            } catch(e) {
                alert("댓글 이미지 등록 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        }

        if( file.length > 0 ) {
            
            try {
                // 내용이 없는 댓글 등록
                const response = await this.hodu_api_call(`api/v1/reply/${this.group_notice_reply_modal_info.noti_uid}?calendar_id=${calendar_id}&isGroupNotiMode=true`, API_METHOD.POST, {
                    user_name : this.user_name, 
                    contents : "",
                    files : file
                }, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("댓글 파일 등록 실패");
                }

                this.group_notice_reply_modal_info.callback?.();
                await Promise.all([this.getAllReply(), this.getAllReplyRead()]);

                // @ts-ignore
                this.$nextTick(() => { setTimeout(() => { $('.modal-scroll').mCustomScrollbar('scrollTo', 'bottom'); }, 1); });

            } catch(e) {
                alert("댓글 파일 등록 중 오류 발생");
                this.hodu_error_process(e, false, false, true);
            }
        }
        await this.hodu_hide_indicator();
    }

    /**
     * 파일리더 Promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }
    
    /**
     * 이미지 다이얼로그 보이기
     */
    showImageModal(image_files : t_event_file[], image_index : number, reply, dayIndex, reply_index, is_me : boolean) : void {
        if( image_index == -1 || image_files.length <= image_index ){
            return;
        }

        if( is_me ) {
            this.doSetEventImagePreviewInfo({
                "selected_index" : image_index,
                "files" : image_files,
                "reply_delete_function": this.deleteReply,
                "js_event" : null,   
                "reply" : reply,
                "dayIndex" : dayIndex, 
                "reply_index" : reply_index
            });
        }

        else {
            this.doSetEventImagePreviewInfo({
                "selected_index" : image_index,
                "files" : image_files
            });
        }

        this.doSetShowEventImagePreview(true);
    }

    /**
     * 이미지 인덱스와 개수를 통해 무슨 클래스가 들어갈지 계산해서 보내준다
     */
    getImageClassByIndex(files : t_event_file[], index : number) : string {
        // 2랑 3은 고정
        if( files.length == 2 ) { return "two_in_row"; }
        if( files.length == 3 ) { return "three_in_row"; }

        // 3으로 나눈 나머지가 1이라면 끝에서 4개의 index는 two_in_row, 나머지는 three_in_row
        if( files.length % 3 == 1 ) {
            return index <= ( files.length - 1 ) - 4 ? "three_in_row" : "two_in_row";
        }
        
        // 3으로 나눈 나머지가 2인 경우 마지막 row 2개만 two_in_row, 나머지는 three_in_row
        if( files.length % 3 == 2 ) {
            return index <= ( files.length - 1 ) - 2 ? "three_in_row" : "two_in_row";
        }

        // 3으로 나눠 떨어지면 무조건 three_in_row
        return "three_in_row";
    }
    
    /**
     * width 구하기
     */
    getSingleImageWidth(image : t_event_file) : string {
        if( image == null || image.image_info == null ) { return "0"; }

        let width : number = 0;
        let height : number = 0;

        const original_width : number = image.image_info.width;
        const original_height : number = image.image_info.height;

        width = original_width;
        height = original_height;

        const image_raito : number = width / height;

        // 최소 너비보다 짧은 경우
        if( width < this.image_min_width ) {
            width = this.image_min_width;
            height = Math.round(this.image_min_width / image_raito);
        }

        // 최소 높이보다 낮은 경우
        if( height < this.image_min_height ) {
            width = Math.round(this.image_min_height * image_raito);
            height = this.image_min_height;
        }

        // 최대 너비보다 긴 경우
        if( width > this.image_max_width ) {
            width = this.image_max_width;
            height = Math.round(this.image_max_width / image_raito);
        }

        // 최대 높이보다 큰 경우
        if( height > this.image_max_height ) {
            width = Math.round(this.image_max_height * image_raito);
            height = this.image_max_height;
        }
        
        return `${width}px`;
    }

    /**
     * height 구하기
     */
    getSingleImageHeight(image : t_event_file) : string {
        if( image == null || image.image_info == null ) { return "0"; }

        let width : number = 0;
        let height : number = 0;

        const original_width : number = image.image_info.width;
        const original_height : number = image.image_info.height;

        width = original_width;
        height = original_height;

        const image_raito : number = width / height;

        // 최소 너비보다 짧은 경우
        if( width < this.image_min_width ) {
            width = this.image_min_width;
            height = Math.round(this.image_min_width / image_raito);
        }

        // 최소 높이보다 낮은 경우
        if( height < this.image_min_height ) {
            width = Math.round(this.image_min_height * image_raito);
            height = this.image_min_height;
        }

        // 최대 너비보다 긴 경우
        if( width > this.image_max_width ) {
            width = this.image_max_width;
            height = Math.round(this.image_max_width / image_raito);
        }

        // 최대 높이보다 큰 경우
        if( height > this.image_max_height ) {
            width = Math.round(this.image_max_height * image_raito);
            height = this.image_max_height;
        }

        return `${height}px`;
    }

    /**
     * 안 읽은 수 반환
     */
    getUnreadCount(index : number) : string {

        let count = 0;

        for( const num of this.read_count ) {
            if( num < index ) { count++; }
        }

        return `${count}`;
    }

    /**
     * 파일 다운로드
     */
    download(file : t_event_file) : void {
        this.hodu_download(`app_images/${file.url}`, file.name)
            .catch((e) => {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "파일 다운로드 실패", ['확인']);
            });
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('.modal-scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }
}
