
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonSelectFriendAndGroupTeamModalInfo } from '@/store/modules/ModalInfo';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        ResizeObserver
    },
})
export default class Friend extends Mixins(VueHoduCommon) {

    get computedFriends() : any[] {

        let computed_friends : any[]  = JSON.parse(JSON.stringify(this.friends));
        
        if( this.search_query.trim().length > 0 ) {
            computed_friends = computed_friends.filter(friend => this.hodu_string_includes(this.getFriendName(friend), this.search_query) ||
                                                                 (friend.user_phone_number != null && this.hodu_string_includes(friend.user_phone_number, this.search_query)) ||
                                                                 (friend.user_email != null && this.hodu_string_includes(friend.user_email, this.search_query)) );
        }

        computed_friends = computed_friends.filter(friend => friend.is_hide == false && friend.is_block == false);

        return computed_friends.sort((o1, o2) : number => {

            // 3일이내 신규등록된 친구
            const o1_dday = this.getDateDiff(new Date(), new Date(o1.audit_created));
            const o2_dday = this.getDateDiff(new Date(), new Date(o2.audit_created));

            /// 둘다 3일 이내
            if( o1_dday < 3 && o2_dday < 3 ) {
                if( o1_dday < o2_dday ) return -1;
                else if( o1_dday > o2_dday ) return 1;
                return 0;
            }

            // 한쪽만 3일이내
            if( o1_dday >= 3 && o2_dday < 3 ) {
                return 1;
            }

            // 한쪽만 3일이내
            if( o1_dday < 3 && o2_dday >= 3 ) {
                return -1;
            }

            let o1_name = this.getFriendName(o1);
            let o2_name = this.getFriendName(o2);

            if( o1_name < o2_name ) return -1;
            else if( o1_name > o2_name ) return 1;

            return 0;
        });
    }

    get computedHideFriends() : any[] {

        let computed_friends : any[]  = JSON.parse(JSON.stringify(this.friends));

        computed_friends = computed_friends.filter(friend => friend.is_hide == true);

        return computed_friends.sort((o1, o2) : number => {

            let o1_name = this.getFriendName(o1);
            let o2_name = this.getFriendName(o2);

            if( o1_name < o2_name ) return -1;
            else if( o1_name > o2_name ) return 1;

            return 0;
        });
    }

    get computedBlockFriends() : any[] {

        let computed_friends : any[]  = JSON.parse(JSON.stringify(this.friends));

        computed_friends = computed_friends.filter(friend => friend.is_block == true);

        return computed_friends.sort((o1, o2) : number => {

            let o1_name = this.getFriendName(o1);
            let o2_name = this.getFriendName(o2);

            if( o1_name < o2_name ) return -1;
            else if( o1_name > o2_name ) return 1;

            return 0;
        });
    }

    /**
     * @ModalInfo.state
     */
    @ModalInfo.State common_select_friend_and_group_team_modal_info !: CommonSelectFriendAndGroupTeamModalInfo;

    is_search_open : boolean = false;
    search_query : string = "";
    is_close_hide_list : boolean = false;

    mounted() : void {
        //옵션 클릭 친구 리스트랑 같은 설정부분입니다
        // $('.frHidMore').click(function(){
        //     $(this).toggleClass('on');   
        //     $(this).siblings('ul').toggle();         
        // })

        // //메뉴 on/off
        // $('.config_btn').off("click").on("click", function(){
        // 	if($(".config_btn, .friendRightCon, .leftScroll").hasClass("on")){
        //         $(".config_btn, .friendRightCon, .leftScroll").removeClass("on");
        //     } else {
        //         $(".config_btn, .friendRightCon, .leftScroll").addClass("on");
        //     }
        // });

        // 서브폴더 열기
        $(".RightDivShare .foldBtn").click(function(){
            $(this).parent().parent().toggleClass("on");
        });

        this.getFriends(true);
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();
        const setting_height = $('.grp.settingBg').outerHeight();
        const sort_header_height = $('.content .sortHeader').outerHeight();

        const friend_list_height = window.innerHeight - (title_height ? title_height : 0)
                                                - (setting_height ? setting_height : 0)
                                                - (sort_header_height ? sort_header_height : 0);

        const right_friend_list_height = window.innerHeight - (title_height ? title_height : 0)
                                                            - (setting_height ? setting_height : 0);

        // @ts-ignore
        $('#friendList').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : friend_list_height,
        });

        // @ts-ignore
        $('#friend_right_list').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : right_friend_list_height,
        });
        
    }

    /**
     * 설정 버튼 클릭
     */
    clickMoreOption(friends : any[], target_friend : any) : void {
        try {

            let current_flag = friends[friends.indexOf(target_friend)].moreOption;
            if( current_flag == null ) current_flag = false;

            if( current_flag == true ) {
                friends[friends.indexOf(target_friend)].moreOption = false;
                return;
            }
            
            for( const friend of friends ) {
                friend.moreOption = false;
            }

            friends[friends.indexOf(target_friend)].moreOption = true;

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        } finally {
            this.$forceUpdate();
        }
    }

    /**
     * 이름 변경
     */
    changeName(friend : any) : void {

        const friend_name = this.getFriendName(friend);

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "친구 이름 변경",
            subtitle : "친구 이름",
            placeholder : "친구 이름을 입력해주세요",
            content : friend_name,
            save : async(friend_custom_nickname) => {

                try {

                    const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                        "friend_custom_nickname" : friend_custom_nickname
                    });

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("친구 이름 변경 중 오류 발생");
                    }
                    
                    this.getFriends(true);

                } catch(e) {
                    this.hodu_show_dialog("cancel", "친구 이름 변경 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
                
            }
        });

    } 

    /**
     * 친구 숨기기
     */
    async hideFriend(friend) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                "is_hide" : true
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("친구 숨기는 중 오류 발생");
            }
            
            this.getFriends(true);

        } catch(e) {
            this.hodu_show_dialog("cancel", "친구 숨기는 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구 숨기기 해제
     */
    async showFriend(friend) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                "is_hide" : false
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("친구 숨김 해제 중 오류 발생");
            }
            
            this.getFriends(true);

        } catch(e) {
            this.hodu_show_dialog("cancel", "친구 숨김 해제 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구 차단
     */
     async blockFriend(friend) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                "is_block" : true
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("친구 숨기는 중 오류 발생");
            }
            
            this.getFriends(true);

        } catch(e) {
            this.hodu_show_dialog("cancel", "친구 숨기는 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구 차단해제
     */
     async unblockFriend(friend) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/friend/${friend.friend_user_id}`, API_METHOD.PUT, {
                "is_block" : false
            });

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("친구 숨기는 중 오류 발생");
            }
            
            this.getFriends(true);

        } catch(e) {
            this.hodu_show_dialog("cancel", "친구 숨기는 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 친구 추가 모달
     */
    showFriendAddModal() : void {

        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            "show_modal" : true,
            "title" : "친구추가",
            "use_share_option" : false,
            "use_user_select_only" : true,
            "use_group_select_only" : true,
            "use_need_user_email" : false,
            "callback" : async(selected_info) => {
                console.log(selected_info);

                try {

                    const body = { "friend_user_ids" : selected_info.selected_user_ids };

                    const response = await this.hodu_api_call(`/api/v1/friend`, API_METHOD.POST, body);

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("친구 추가 중 오류 발생");
                    }

                    this.getFriends();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "친구 추가 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('@/assets/images/contents/im_photoB.gif');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#friendList, #friend_right_list').mCustomScrollbar('destroy');
    	this.setScroll();
    }

}
