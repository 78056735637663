
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class OrganizationPositionSetting extends Mixins(VueHoduCommon) {

    @Prop() event_bus !: Vue;

    position : any[] = [];

    mounted() : void {
        this.event_bus.$on('OrganizationPositionSettingResize', this.handleResize);
        this.getOrganizationPosition();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        
        const title_box_height = $('#organization_setting .title_box').outerHeight();
        const title_box2_height = $('#organization_setting .title_box2').outerHeight();
        const sort_header_height = $('#organization_setting .section_scroll .sortHeader').outerHeight();

        // @ts-ignore
        $('#organization_position_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_box_height ? title_box_height : 0)
                                           - (title_box2_height ? title_box2_height : 0)
                                           - (sort_header_height ? sort_header_height : 0)
        });
    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직급 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 직급 추가 모달
     */
    addPosition() : void {
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "직급 추가",
            subtitle : "직급명",
            placeholder : "직급명을 입력해주세요",
            save : async(name) => {
                try {

                    // 정보 가공
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.POST, { "pos_name" : name });
                    
                    console.log(response);
                    
                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 직급 추가 중 오류 발생");
                    }

                    this.getOrganizationPosition();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직급 추가 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 직급 수정 모달
     */
    modifyPosition(position : any) : void {
        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : "직급 수정",
            subtitle : "직급명",
            placeholder : "직급명을 입력해주세요",
            content : position.pos_name,
            save : async(name) => {
                try {
                    await this.modifyPositionAPI(position.pos_id, { "pos_name" : name });
                    this.getOrganizationPosition();
                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직급 수정 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 직급 수정 API
     */
    async modifyPositionAPI(pos_id : number, data : any) : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position/${pos_id}`, API_METHOD.PUT, data, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("조직도 직급 수정 중 오류 발생");
            }
        } catch(e) {
            throw e;
        }
    }

    /**
     * 직급 순서 변경
     */
    async updatePositionSeq(index : number, dir : number) : Promise<void> {
        try {
            // 제일 직급이 높은데 위로 가는걸 누른경우, 제일 직급이 낮은데 아래로 가는걸 누른 경우
            if( (dir == -1 && index == 0) || (dir == 1 && index == (this.position.length - 1)) ) return;
            
            const target_position = this.position[index];

            await this.getOrganizationPosition();
            
            const new_target_position = this.position.filter(item => item.pos_id == target_position.pos_id)[0];
            const affected_position = this.position[this.position.indexOf(new_target_position) + dir];

            if( new_target_position == null || affected_position == null ) {
                throw new Error("조직도 직급 순서 수정 중 오류 발생");
            }

            const modify_promise : Promise<any>[] = [];
            
            modify_promise.push(this.modifyPositionAPI(new_target_position.pos_id, { pos_seq : new_target_position.pos_seq + dir }));
            modify_promise.push(this.modifyPositionAPI(affected_position.pos_id, { pos_seq : affected_position.pos_seq - dir }));

            this.hodu_show_indicator();
            await Promise.all(modify_promise);
            await this.getOrganizationPosition();
            this.hodu_hide_indicator();
            
        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 직급 순서 수정 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 직급 삭제
     */
    deletePosition(event, position : any) : void {
        event.stopPropagation();

        this.hodu_show_dialog('cancel', `정말로 직급을 삭제하시겠습니까?\n'${position.pos_name}'`, ['아니오', '예'], [
            () => {},
            async() => {
                try {

                    // 정보 가공
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position/${position.pos_id}`, API_METHOD.DELETE);
                    
                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("조직도 직급 삭제 중 오류 발생");
                    }

                    this.getOrganizationPosition();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "조직도 직급 삭제 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        ]);
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#organization_position_setting_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
