
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const ApprovalInfo = namespace('ApprovalInfo');

import { approval_enum, approval_interface, approval_modal_interface } from '@/model/approval';

import OrganizationRecursion from '@/components/organization/OrganizationRecursion.vue';
import { hodu_color } from '@/common/color';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion
    },
})
export default class ApproverSelectModal extends Mixins(VueHoduCommon) {

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }

    /**
     * 직원 정보
     */
    get computedEmployee() : any[] {
        let employees = JSON.parse(JSON.stringify(this.employees));

        for( const employee of employees ) employee.approver_type = 'EMPLOYEE';

        employees.sort((o1, o2) => {

            const o1_name = o1.user_name;
            const o2_name = o2.user_name;

            const o1_pos_seq = this.getPositionSeq(o1.pos_id);
            const o2_pos_seq = this.getPositionSeq(o2.pos_id);

            // 같은 직급은 이름순
            if( o1_pos_seq == o2_pos_seq ) {
                if( o1_name > o2_name ) return 1;
                else if( o1_name < o2_name ) return -1;
                return 0
            }
            else if( o1_pos_seq > o2_pos_seq ) return  1;
            else if( o1_pos_seq < o2_pos_seq ) return -1;

            return 0;
        });

        return employees.filter(item => item.pos_id != null && item.dept_id != null && (this.approver_select_modal_info.is_default_setting == true ? true : (item.user_id != this.user_id)));
    }

    /**
     * 선택된 것
     */
    get computedSelected() : any[]  {

        let selected : any[] = [];

        if( this.approver_select_modal_info.selected == null ) {
            return [];
        }

        if( this.approver_select_modal_info.is_self_approve == false ) {
            selected = this.approver_select_modal_info.selected;
        }
        else {
            // 직급, 가나다순
            selected = this.approver_select_modal_info.selected.sort((o1, o2) : number => {

                const o1_name = o1.user_name;
                const o2_name = o2.user_name;

                const o1_pos_seq = this.getPositionSeq(o1.pos_id);
                const o2_pos_seq = this.getPositionSeq(o2.pos_id);

                // 같은 직급은 이름순
                if( o1_pos_seq == o2_pos_seq ) {
                    if( o1_name > o2_name ) return 1;
                    else if( o1_name < o2_name ) return -1;
                    return 0
                }
                else if( o1_pos_seq > o2_pos_seq ) return  1;
                else if( o1_pos_seq < o2_pos_seq ) return -1;
                
                return 0;
            });
        }

        return selected;
    }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State approver_select_modal_info !: approval_modal_interface.ApproverSelectModalInfo;

    /**
     * @ModalInfo.Action 
     */
    @ModalInfo.Action doSetApproverSelectModalInfo ?: (params : approval_modal_interface.ApproverSelectModalInfo) => void;

    /**
     * @ApprovalInfo.State
     */
    @ApprovalInfo.State approval !: approval_interface.t_approval;

    departments : any[] = [];
    selected_department : any = { dept_id : -1 }; 

    department_closed_map : Map<number, boolean> = new Map();
    department_height_map : Map<number, number> = new Map();

    position : any[] = [];
    employees : any[] = [];

    tab : string = "EMPLOYEE"; // EMPLOYEE, POSITION
    
    mounted() : void {
        // 버튼 클릭시 라인과 팀 보이기
        $(".arw").click(function(){
            // 리스트 인덱스 가져오기
            var getIndex = $(this).parent().index();
            
            // 버튼 클릭시 라인과 팀 보이기
            $(".fList").eq(getIndex).toggleClass("showTeam");

        });
        
        this.getTotalOrganizationData();
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationEmp();
        } catch(e) {
            alert("조직도 조회 중 오류 발생");
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            // map에 담겨있는 vertical_height 정보로 업데이트
            for( const department of response.data.data.dept_info ) {
                const vertical_height = this.department_height_map.get(department.dept_id);
                if( vertical_height != null ) {
                    department.vertical_height = vertical_height;
                }
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            if( this.selected_department == null || this.selected_department.dept_id == -1 ) {
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                    throw new Error("조직도 전체 직원 조회 중 오류 발생");
                }

                this.employees.splice(0, this.employees.length);
                this.employees = this.employees.concat(response.data.data.emp_info);
                return;
            }

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp/${this.selected_department.dept_id}`, API_METHOD.GET);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 부서 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 부서 선택
     */
    async select(department : any) : Promise<void> {
        this.selected_department = department;
        this.$forceUpdate();
        this.hodu_show_indicator();
        await this.getTotalOrganizationData();
        this.hodu_hide_indicator();
    }

    /**
     * 직급 순서 반환
     */
    getPositionSeq(pos_id : number) : number {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return Number.MAX_SAFE_INTEGER;
            return target[0].pos_seq;
        } catch(e) {
            return Number.MAX_SAFE_INTEGER;
        }
    }

    /**
     * 부서 이름 반환
     * level 1 : 최상위 부서 이름 반환
     * level 2 : 최상위 부서 바로 아래의 부서 이름 하나만 반환
     * level n (n > 2) : level 2 / level 3 / level 4 ... 
     */
    getDepartmentName(dept_id : number) : string {
        try {
            let dept_name = "";
            const target = this.departments.filter(item => item.dept_id == dept_id)[0];

            let target_departments : any[] = [];
            for( const department of this.departments ) {
                if( target.dept_id_array.indexOf(department.dept_id) > -1 ) target_departments.push(department);
            }

            target_departments.sort((o1, o2) : number => {
                const o1_length = o1.dept_id_array.length;
                const o2_length = o2.dept_id_array.length;

                if( o1_length > o2_length ) return 1;
                else if( o1_length < o2_length ) return -1;
                return 0;
            });

            for( const department of target_departments ) {
                if( target_departments.length >= 2 && target_departments.indexOf(department) == 0 ) continue;

                if( dept_name.length > 0 ) dept_name += " / ";
                dept_name += department.dept_name;
            }
        
            return dept_name;
        } catch(e) {
            console.log(e);
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 선택된 부서에서 선택된 사람의 수 또는 선택된 직급 수
     */
    getSelectedCheck() : number {
        if( this.approver_select_modal_info.selected == null ) return 0;

        const ids : number[] = [];

        if( this.tab == 'EMPLOYEE' ) {
            for( const selected_emp of this.approver_select_modal_info.selected ) {
                for( const emp of this.computedEmployee ) {
                    if( selected_emp.user_id == emp.user_id ) ids.push(selected_emp.user_id);
                }
            }
        }

        else if( this.tab == 'POSITION' ) {
            for( const selected_emp of this.approver_select_modal_info.selected ) {
                for( const pos of this.position ) {
                    if( selected_emp.approver_type == 'POSITION' && selected_emp.pos_id == pos.pos_id ) ids.push(selected_emp.user_id);
                }
            }
        }

        return ids.length;
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user.png');
    }

    /**
     * 사람 선택
     */
    changeSelected(event, employee) : void {
        if( this.approver_select_modal_info.selected == null ) return;
        
        try {
            const checked : boolean = event.target.checked;
            if( checked == true && this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id).length == 0 ) {
                this.approver_select_modal_info.selected.push(employee);
            }
            else if( checked == false ) {
                while( this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id).length > 0 ) {
                    const target = this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id)[0];
                    this.approver_select_modal_info.selected.splice(this.approver_select_modal_info.selected.indexOf(target as any), 1);
                }
            }
        } catch(e) {
            console.log(e);
        }

        this.$forceUpdate();
    }

    /**
     * 직급 선택
     */
    changeSelectedPosition(event, position) : void {
        if( this.approver_select_modal_info.selected == null ) return;

        try {
            const checked : boolean = event.target.checked;
            if( checked == true && this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id).length == 0 ) {
                this.approver_select_modal_info.selected.push({
                    "user_id" : 0,
                    "seq" : this.approver_select_modal_info.selected.length + 1,
                    "approver_type" : "POSITION",
                    "position_seq" : this.position.indexOf(position),
                    "user_name" : "",
                    "dept_name" : "",
                    "pos_name" : this.getPositionName(position.pos_id),
                    "pos_id" : position.pos_id,
                    "dept_id" : 0,
                    "color" : hodu_color[`hodu_dc_${this.position.indexOf(position) % 10}`],
                    "include_parent" : false
                });
            }
            else if( checked == false && this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id).length > 0 ) {
                const target = this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id)[0];
                this.approver_select_modal_info.selected.splice(this.approver_select_modal_info.selected.indexOf(target as any), 1);
            }
        } catch(e) {
            console.log(e);
        }

        this.$forceUpdate();
    }

    /**
     * 상위부서 포함 여부 변경
     */
    changeSelectedPositionIncludeParent(event, position) : void {

        if( this.approver_select_modal_info.selected == null ) return;

        try {
            const checked : boolean = event.target.checked;

            if( !(this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id).length > 0) ) {
                return;
            }

            const target = this.approver_select_modal_info.selected.filter(item => item.approver_type == 'POSITION' && item.pos_id == position.pos_id)[0];
            target.include_parent = checked;

        } catch(e) {
            console.log(e);
        }

        this.$forceUpdate();
    }

    /**
     * 모두삭제
     */
    deleteAllSelected() : void {
        this.approver_select_modal_info?.selected?.splice(0, this.approver_select_modal_info.selected.length);
        this.$forceUpdate();
    }

    /**
     * 순서 앞으로 당기기
     */
    up(select) : void {
        if( this.approver_select_modal_info.selected == null ) return;
        const index = this.approver_select_modal_info.selected.indexOf(select);
        const target_index = index - 1;
        const target_seq   = select.seq;
        
        const target = this.approver_select_modal_info.selected[target_index];
        target.seq = target_seq;
        select.seq = select.seq - 1;

        this.approver_select_modal_info.selected[target_index] = select;
        this.approver_select_modal_info.selected[index] = target;

        this.$forceUpdate();
    } 

    /**
     * 순서 뒤로 밀기
     */
    down(select) : void {
        if( this.approver_select_modal_info.selected == null ) return;
        const index = this.approver_select_modal_info.selected.indexOf(select);  
        const target_index = index + 1;
        const target_seq   = select.seq;

        const target = this.approver_select_modal_info.selected[target_index];
        target.seq = target_seq;
        select.seq = select.seq + 1;

        this.approver_select_modal_info.selected[target_index] = select;
        this.approver_select_modal_info.selected[index] = target;

        this.$forceUpdate();
    } 

    /**
     * 삭제
     */
    remove(employee) : void {

        if( this.approver_select_modal_info.selected == null ) return;

        if( employee.user_id > 0 ) {
            while( this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id).length > 0 ) {
                const target = this.approver_select_modal_info.selected.filter(item => item.user_id == employee.user_id)[0];
                this.approver_select_modal_info.selected.splice(this.approver_select_modal_info.selected.indexOf(target as any), 1);                
            }
        }
        
        else if( employee.pos_id >= 0 ) {
            while( this.approver_select_modal_info.selected.filter(item => item.pos_id == employee.pos_id).length > 0 ) {
                const target = this.approver_select_modal_info.selected.filter(item => item.pos_id == employee.pos_id)[0];
                this.approver_select_modal_info.selected.splice(this.approver_select_modal_info.selected.indexOf(target as any), 1);
            }
        }

        this.$forceUpdate();
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetApproverSelectModalInfo?.({
            show_modal : false,
            is_approver : false,
            is_default_setting : false,
            is_self_approve : false,
        });
    }

    /**
     * 저장
     */
    save() : void {

        if( this.approver_select_modal_info.selected == null || this.approver_select_modal_info.selected.length < 1 ) {
            this.approver_select_modal_info.callback?.([]);
        }
        
        else {

            if( this.approver_select_modal_info.is_default_setting == true ) {
                const data : any[] = [];

                for( const approver of this.approver_select_modal_info.selected ) {
                    
                    const obj : any = {
                        "user_id" : approver.user_id,
                        "seq" : this.approver_select_modal_info.selected.indexOf(approver) + 1,
                        "user_name" : approver.user_name,
                        "dept_name" : this.getDepartmentName(approver.dept_id),
                        "pos_name" : this.getPositionName(approver.pos_id),
                        "pos_id" : approver.pos_id,
                        "dept_id" : approver.dept_id,
                        "approver_type" : approver.approver_type,
                    }

                    if( approver.approver_type == 'POSITION' ) obj.include_parent = approver.include_parent;
                    
                    data.push(obj);
                }

                this.approver_select_modal_info.callback?.(data);
                this.close();
                return;
            }

            const data : approval_interface.t_approval_receive_reference[] | approval_interface.t_approval_approver[] = [];
        
            if( this.approver_select_modal_info.is_approver == true ) {

                for( const approver of this.approver_select_modal_info.selected ) {
                    console.log(approver);
                    (data as approval_interface.t_approval_approver[]).push({
                        "rev" : this.approval.approval_rev,
                        "seq" : this.approver_select_modal_info.selected.indexOf(approver) + 1,
                        "state" : this.approver_select_modal_info.selected.indexOf(approver) == 0 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                        "comment" : "",
                        "user_id" : approver.user_id,
                        "pos_name" : this.getPositionName(approver.pos_id),
                        "dept_name" : this.getDepartmentName(approver.dept_id),
                        "user_name" : approver.user_name,
                        "pos_id" : approver.pos_id,
                        "dept_id" : approver.dept_id,
                        "user_pic" : approver.user_pic,
                    });
                }
            }
            else {
                for( const ref of this.approver_select_modal_info.selected ) {
                    console.log(ref);
                    (data as approval_interface.t_approval_receive_reference[]).push({
                        "user_id" : ref.user_id,
                        "pos_name" : this.getPositionName(ref.pos_id),
                        "dept_name" : this.getDepartmentName(ref.dept_id),
                        "user_name" : ref.user_name,
                        "pos_id" : ref.pos_id,
                        "dept_id" : ref.dept_id,
                        "user_pic" : ref.user_pic,
                    });
                }
            }

            this.approver_select_modal_info.callback?.(data);
        }

        this.close();
    }

}
