
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { organization_modal_interface } from '@/model/organization';

@Component({
    components: {
        
    },
})
export default class OrganizationDefaultEmpOffModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_default_emp_off_modal_info !: organization_modal_interface.OrganizationDefaultEmpOffModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationDefaultEmpOffModalInfo ?: (params : organization_modal_interface.OrganizationDefaultEmpOffModalInfo) => void;

    annual_type : string = "FiscalYear"; // FiscalYear, JoinDate

    mounted() : void {
        this.getGroup();
    }

    async getGroup() {

        try {
            const group_id = this.organization_default_emp_off_modal_info.group_id;

            const response = await this.hodu_api_call(`api/v1/groupsweb/getDetail/${group_id}/0`, API_METHOD.GET, null);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.data ) {
                throw new Error("그룹 정보 조회중 오류발생");
            }

            const data = response.data.data.data;

            this.annual_type = data.group_info.annual_type ?? 'FiscalYear';

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("그룹 정보 조회 중 오류 발생");
            this.close();
        }
    }

    async confirm() {

        try {
            
            const response = await this.hodu_api_call(`api/v1/groups/${this.organization_default_emp_off_modal_info.group_id}/organization/default_off?annual_type=${this.annual_type}`, API_METHOD.POST);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("연차 수 표시 여부 변경 중 오류 발생");
            }

            this.organization_default_emp_off_modal_info.callback?.();

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            alert("연차 기준 설정 중 오류 발생");
        } finally {
            this.close();
        }
        
    }

    close() {
        this.doSetOrganizationDefaultEmpOffModalInfo?.({
            show_modal : false,
            group_id : 0
        });
    }

}
