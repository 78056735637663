
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { CommonDatepickerModalInfo } from '@/store/modules/ModalInfo';

import HoduInlineDatePicker from '@/components/common/HoduInlineDatePicker.vue';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HoduInlineDatePicker
    },
})
export default class CommonDatepickerModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State common_datepicker_modal_info !: CommonDatepickerModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetCommonDatepickerModalInfo ?: (params : CommonDatepickerModalInfo) => void;

    event_bus : Vue = new Vue();
    
    selected_date : Date = new Date();

    mounted() : void {
        this.selected_date = this.common_datepicker_modal_info.default_date ? this.common_datepicker_modal_info.default_date : new Date();
    }

    /**
     * 날짜 선택
     */
    select(date_string : string , data : any) : void {
        this.selected_date = new Date(date_string);
    }

    /**
     * 모달 닫기
     */
    close() : void {
        this.doSetCommonDatepickerModalInfo?.({ show_modal : false });
    }

    /**
     * 저장
     */
    save() : void {
        this.common_datepicker_modal_info.callback?.(this.selected_date);
        this.close();
    }

}
