
import { Component, Vue, Mixins } from 'vue-property-decorator';
import VueHoduCommon from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { ImageCropModalInfo } from '@/store/modules/ModalInfo';

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

@Component({
    components: {
        VueCropper
    }
}) export default class ImageCropModal extends Mixins(VueHoduCommon) {

    // this.$refs.cropper 사용
    $refs !: { cropper : HTMLFormElement; }

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State image_crop_modal_info !: ImageCropModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;

    image_url : string = "";
    image_crop_ratio : number = 1;

    async beforeMount() : Promise<void> {
        if( this.image_crop_modal_info.crop_image_ratio ) { this.image_crop_ratio = this.image_crop_modal_info.crop_image_ratio; }
        if( this.image_crop_modal_info.image_url ) { this.image_url = this.image_crop_modal_info.image_url; }
    }

    mounted() : void {

    }
    
    /**
     * 취소 버튼 클릭
     */
    clickCancel() : void {
        if( this.image_crop_modal_info.cancel_function == null ) { return; }

        Promise.all([this.image_crop_modal_info.cancel_function()]).then(() => {
            if( this.doSetImageCropModalInfo == null ) { return; }
            this.doSetImageCropModalInfo({ "show_modal" : false });
        });
    }

    /**
     * 확인 버튼 클릭
     */
    clickConfirm() : void {
        if( this.image_crop_modal_info.confirm_function == null ) { return; }
        
        // console.log(this.$refs.cropper.initCrop().getCroppedCanvas());

        // crop
        const cropped_url : string = this.$refs.cropper.initCrop().getCroppedCanvas().toDataURL('image/jpeg')

        // crop 해서 나온 Blob 파라미터로 넘기기
        Promise.all([this.image_crop_modal_info.confirm_function(cropped_url)]).then(() => {
            if( this.doSetImageCropModalInfo == null ) { return; }
            this.doSetImageCropModalInfo({ "show_modal" : false });
        });
    }

    /**
     * 확대
     */
    zoomIn() : void {
        this.$refs.cropper.relativeZoom(0.1);
    }

    /**
     * 축소
     */
    zoomOut() : void {
        this.$refs.cropper.relativeZoom(-0.1);
    }

    /**
     * 왼쪽으로 회전
     */
    rotateLeft() : void {
        this.$refs.cropper.rotate(-90);
    }

    /**
     * 오른쪽으로 회전
     */
    rotateRight() : void {
        this.$refs.cropper.rotate(90);
    }

    /**
     * 좌우반전
     */
    flipHorizontal() : void {
        this.$refs.cropper.scale(-(this.$refs.cropper.getData().scaleX), this.$refs.cropper.getData().scaleY);
    }

    /**
     * 상하반전
     */
    flipVertical() : void {
        this.$refs.cropper.scale(this.$refs.cropper.getData().scaleX, -(this.$refs.cropper.getData().scaleY));
    }

}

