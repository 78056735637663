
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon, { CRUD_TYPE, API_METHOD } from "@/mixin/VueHoduCommon";

import { namespace } from 'vuex-class';
import { t_favorite_addr } from '@/model/osm';
import { t_event_location } from '@/model/event';
const EventInfo = namespace('EventInfo');
const ModalInfo = namespace('ModalInfo');

declare var daum: any;

const lodash = require('lodash');

function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
        configurable: true,
        enumerable: false,
        value: lodash.debounce(target[prop], delay)
    };
  }
}

@Component({

}) export default class EventDaumMap extends Mixins(VueHoduCommon) {

    @EventInfo.State  event_crud_type       !: string;
    @EventInfo.State  location_search_query !: string;
    @EventInfo.State  selected_location     !: t_event_location;

    @EventInfo.Action doSetSelectedLocation ?: any;
    
    @ModalInfo.Action doSetShowEventDaumMap ?: any;

    map : any;
    
    map_overlay : any = new daum.maps.CustomOverlay({xAnchor:1}); // 검색 결과 목록이나 마커를 클릭했을 때 장소명을 표출할 인포윈도우를 생성합니다

    search_query : string = "";
    searched_query : string = ""; // 최종적으로 검색한 값
    total_page : number = 0;
    current_page : number = 1;
    favorite_open : boolean = false;

    location_list : any[] = [];
    selected_any_location : any = null;
    marker_list : any[] = [];
    total_count : number = 0;

    favorite_list : t_favorite_addr[] = [];
    favorite_modify_index : number = -1;

    favorite_search_list : t_favorite_addr[] = [];
    favorite_search_query : string = "";
    
    favorite_add : boolean = false; // favroite이 추가되서 재조회하는경우 list를 맨 밑으로 가게한다

    show_delete_alert : boolean[] = [];

    async mounted() {
        $('#modalMapkeyword').focus();
        
        let container = document.getElementById('modalMap');
        let mapOption = {
            center: new daum.maps.LatLng(
                this.event_crud_type == CRUD_TYPE.READ && this.selected_location != null && this.selected_location.geo != null ? this.selected_location.geo.latitude  : 37.566826,
                this.event_crud_type == CRUD_TYPE.READ && this.selected_location != null && this.selected_location.geo != null ? this.selected_location.geo.longitude : 126.9786567
            ), // 지도의 중심좌표
            level: 3 // 지도의 확대 레벨
        }; 

        this.map = new daum.maps.Map(container, mapOption); //지도 생성 및 객체 리턴

        // @ts-ignore
        $('.placesListDivScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
        });
        
        // @ts-ignore
        $('.faveScroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
        });

        await this.getFavoriteAddress();

        // 검색어 들어있는채로 들어왔다면 검색도 한다
        if( this.location_search_query != null && this.location_search_query.length > 0 ) {
            this.search_query = this.location_search_query;
            await this.locationSearch();
        }

        // 일정 조회에서 들어왔다면 마커 생성
        if( this.event_crud_type == CRUD_TYPE.READ && this.selected_location != null && this.selected_location.geo != null ) {
            const bounds = new daum.maps.LatLngBounds();

            // 마커 추가 
            const marker : any = new daum.maps.Marker({
                position: new daum.maps.LatLng(this.selected_location.geo.latitude, this.selected_location.geo.longitude),
                image: new daum.maps.MarkerImage(
                    require('../../../assets/images/contents/ic_pin_chosen.png'), 
                    new daum.maps.Size(40, 40),
                    {
                        spriteSize : new daum.maps.Size(40, 40),  // 스프라이트 이미지의 크기
                        spriteOrigin : new daum.maps.Point(0, 0), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
                        offset: new daum.maps.Point(20, 40)       // 마커 좌표에 일치시킬 이미지 내에서의 좌표
                    }
                ) 
            });
            marker.setMap(this.map);
            this.marker_list.push(marker);

            bounds.extend(new daum.maps.LatLng(this.selected_location.geo.latitude, this.selected_location.geo.longitude));

            this.map.setBounds(bounds);

            // overlay설정
            const content : string = `
            <div class="modal_mapwrap">
                <div class="info">
                    <div class="title">${ this.selected_location.place }</div>
                    <div class="body">
                        <div class="desc">
                            <div class="ellipsis"> ${ this.selected_location.address_old } </div>
                        </div>
                        <div class="modalBtns">
                            <a href="javascript: window.closeDaumMapModal()" id="locationConfirm" class="confirm" style="width:100%;">확인</a>
                        </div>
                    </div>
                </div>
            </div>`;
            
            this.map_overlay.setContent(content);
            this.map_overlay.setPosition(new daum.maps.LatLng(this.selected_location.geo.latitude, this.selected_location.geo.longitude));
            this.map_overlay.setMap(this.map);

            daum.maps.event.addListener(this.map, 'click', (mouseEvent) => { this.map_overlay.setContent(''); });
            daum.maps.event.addListener(this.map, 'dragstart', (mouseEvent) => { this.map_overlay.setContent(''); });
        }

        // 카카오맵에 만들어 넣는 html에서 사용 할 함수를 window에 함수 등록
        window["addFavorite"]    = this.addFavorite;
        window["selectLocation"] = this.selectLocation;
        window["closeDaumMapModal"] = () => this.doSetShowEventDaumMap(false);
    }

    locationModalClose() : void {
        this.doSetShowEventDaumMap(false);
    }

    /**
     * 즐겨찾기 메뉴 toggle
     */
    favoriteOpen() : void {
        this.favorite_open = !this.favorite_open;
    }

    /**
     * 키다운 이벤트 감지 : 엔터키 누르면 검색
     */
    locationKeyDown(event) : void {
        if( event.keyCode != 13 ) {
            return;
        }

        this.locationSearch();
    }

    /**
     * 장소 검색, 페이징은 별도의 함수
     */
    async locationSearch() : Promise<void> {
        const vue = this;

        // 검색어 입력이 없는 경우
        if( this.search_query.trim().length < 1 ){
            return;
        } 

        await this.hodu_api_call(`api/v1/location?query=${this.search_query}&page=${this.current_page}&size=15`, API_METHOD.GET)
            .then(async(response) => {
                // console.log(JSON.stringify(response));  

                // 최종 검색 결과 저장
                vue.searched_query = vue.search_query;

                // 검색결과가 없는 경우
                if( response.data.data.documents.length < 1 ) {
                    alert("검색 결과가 존재하지 않습니다");
                    return;
                }

                // @ts-ignore
                $('.placesListDivScroll').mCustomScrollbar("scrollTo", "top", { scrollInertia : 0 });
                
                vue.location_list = response.data.data.documents;
                vue.total_count   = response.data.data.total_count;

                vue.setMapInformation();
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * bound, 마커, favorite 정보 등을 가공 
     */
    setMapInformation() : void {

        const location_count : number = this.location_list.length;

        if( location_count > 0 ) {
            this.total_page = ( 30 < this.total_count )                           ? 3 
                            : ( 15 < this.total_count && 30 >= this.total_count ) ? 2
                            : 1; 
        } else {
            this.total_page = 0;
        }

        // bounds 설정
        const bounds = new daum.maps.LatLngBounds();

        // 마커 제거 하고 진행
        this.removeMarker();

        for( let i = 0; i < location_count; i++ ) { 
            
            // 즐겨찾기인지 검사 (위도, 경도로 체크)
            const favorite_count : number = this.favorite_list.length;
            for( let j = 0; j < favorite_count; j++ ) {
                if( this.favorite_list[j].addr.latitude == this.location_list[i].y && this.favorite_list[j].addr.longitude == this.location_list[i].x ) {
                    this.location_list[i].isFavorite = true;
                }
            }

            // 마커 추가 
            const marker : any = new daum.maps.Marker({
                position: new daum.maps.LatLng(this.location_list[i].y, this.location_list[i].x),
                image: new daum.maps.MarkerImage(
                    require('../../../assets/images/contents/marker_number_red.png'), 
                    new daum.maps.Size(36, 37),
                    {
                        spriteSize : new daum.maps.Size(36, 691),             // 스프라이트 이미지의 크기
                        spriteOrigin : new daum.maps.Point(0, (i * 46) + 10), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
                        offset: new daum.maps.Point(13, 37)                   // 마커 좌표에 일치시킬 이미지 내에서의 좌표
                    }
                ) 
            });
            marker.setMap(this.map);
            this.marker_list.push(marker);

            bounds.extend(new daum.maps.LatLng(this.location_list[i].y, this.location_list[i].x));
        }
        
        // bounds 적용
        this.map.setBounds(bounds);

        // overlay 없애기
        this.map_overlay.setMap(null);
    }

    /**
     * 지도 위에 표시 된 모든 마커 제거
     */
    removeMarker() : void {
        const marker_count : number = this.marker_list.length;
		for ( let i = 0; i < marker_count; i++ ) {
            const marker : any = this.marker_list[i];
            marker.setMap(null);
        }
        this.marker_list = [];
    }
    /**
     * 페이지네이션 클릭 이벤트 ( current_page 바꿔서 재조회 )
     */
    selectOtherPage(seleted_page : number) : void {
        if( this.current_page == seleted_page ) {
            return;
        }
        
        const vue = this;
        this.current_page = seleted_page;

        this.hodu_api_call(`api/v1/location?query=${this.searched_query}&page=${this.current_page}&size=15`, API_METHOD.GET)
            .then((response) => {
                // console.log(JSON.stringify(response));  

                // 페이지 변경 후 기존 검색 결과로 되돌린다
                vue.search_query = vue.searched_query;

                // 검색결과가 없는 경우 ()
                if( response.data.data.documents.length < 1 ) {
                    alert("데이터 조회 오류, 잠시 후 다시 시도해주세요");
                    return;
                }

                // @ts-ignore
                $('.placesListDivScroll').mCustomScrollbar("scrollTo", "top", { scrollInertia : 0 });
                
                vue.location_list = response.data.data.documents;
                vue.total_count   = response.data.data.total_count;

                vue.setMapInformation();
            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }
    
    /**
     * 리스트 클릭 이벤트 ( 맵 이동 + 맵에 정보 및 확인창 띄우기 )
     */
    locationClick(location : any) : void {
        this.selected_any_location = location;

        this.map_overlay.setMap(null);
        this.map.setLevel(2);
        this.map.setCenter(new daum.maps.LatLng(location.y, location.x));

        const content : string = `
            <div class="modal_mapwrap">
                <div class="info">
                    <div class="title">${ location.place_name }</div>
                    <div class="body">
                        <div class="desc">
                            <div class="ellipsis"> ${ location.address_name } </div>
                        </div>
                        <div class="modalBtns">
                            <a href="javascript: window.addFavorite()   " id="addFavorite"     class="confirm">즐겨찾기 ${ this.selected_any_location.isFavorite == true ? '해제' : '등록'}</a>
                            <a href="javascript: window.selectLocation()" id="locationConfirm" class="confirm">선택</a>
                        </div>
                    </div>
                </div>
            </div>`;
            
        this.map_overlay.setContent(content);
        this.map_overlay.setPosition(new daum.maps.LatLng(location.y, location.x));
        this.map_overlay.setMap(this.map);
    }

    /**
     * 즐겨찾기 조회
     */
    async getFavoriteAddress() : Promise<void> {
        const vue = this;

        await this.hodu_api_call(`api/v1/user/favorite`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);
                vue.favorite_list = response.data.data.favorite;

                vue.show_delete_alert.splice(0, vue.show_delete_alert.length);
                const favorite_count : number = vue.favorite_list.length;
                for( let i = 0; i < favorite_count; i++ ) {
                    vue.show_delete_alert.push(false);
                }

                this.map_overlay.setMap(null);

                if( this.selected_any_location != null ) {
                    this.locationClick(this.selected_any_location);
                }

                if( vue.favorite_add == true ) {
                    setTimeout(() => { 
                        // @ts-ignore
                        $('.faveScroll').mCustomScrollbar("scrollTo", "bottom", { scrollInertia : 0 }); 
                        vue.favorite_add = false;
                    }, 75);
                }
                
            })
            .catch(async(e) => {
                this.hodu_error_process(e, false, false);
            });
    }

    /**
     * 즐겨찾기 등록
     */
    @Debounce(150)
    addFavorite() : void {
        const vue = this;

        // 이미 존재하는 즐겨찾기라면 
        if( this.selected_any_location.isFavorite == true ) {
            const favorite_legnth : number = this.favorite_list.length;
            let addr : t_favorite_addr | undefined;
            for( let i = 0; i < favorite_legnth; i++ ) {
                if( ( this.selected_any_location.x == this.favorite_list[i].addr.longitude ) &&
                    ( this.selected_any_location.y == this.favorite_list[i].addr.latitude  ) ) {
                    addr = this.favorite_list[i];
                }
            }
            
            if( addr != null ) {
                this.deleteFavorite(addr);
            }
        }

        // 즐겨찾기 추가
        else {
            this.hodu_api_call(`api/v1/user/favorite`, API_METHOD.POST, {
                "id" : this.selected_any_location.id,
                "title" : this.selected_any_location.place_name,
                "addr_tel" : this.selected_any_location.phone,
                "old_addr" : this.selected_any_location.address_name,
                "new_addr" : this.selected_any_location.road_address_name,
                "longitude" : this.selected_any_location.x,
                "latitude" : this.selected_any_location.y
            }).then((reponse) => {
                vue.favorite_add = true;
                
                // 선택된 장소 즐겨찾기 플래그 업데이트
                vue.selected_any_location.isFavorite = true;
                
                // 즐겨찾기 재조회
                vue.getFavoriteAddress();

            }).catch((e) => {
                this.hodu_error_process(e, false, false);
            });
        }
    }

    /**
     * 포커스 들어가면 수정모드로 변경
     */
    favoriteFocusEdit(favorite : t_favorite_addr) : void {
        this.favorite_modify_index = this.favorite_list.indexOf(favorite);
    }

    /**
     * 즐겨찾기 수정 취소
     */
    cancelModifyFavorite(favorite : t_favorite_addr) : void {
        $(`#favPlaceNm${this.favorite_list.indexOf(favorite)}`).val(this.favorite_list[this.favorite_list.indexOf(favorite)].addr.title);
        
        // setTimeout이 없으면 favorite_modify_index가 -1이 되면서 뒤쪽 li 클릭이벤트 조건을 만족해서 동시에 발생해버린다
        setTimeout(() => { this.favorite_modify_index = -1; }, 1);
    }

    /**
     * 즐겨찾기 이름 수정
     */
    modifyFavorite(favorite : t_favorite_addr) : void {
        const vue = this;

        const modifyName : string = String($(`#favPlaceNm${this.favorite_list.indexOf(favorite)}`).val()).trim();

        // 이름 검사
        if( modifyName.length < 1 ) {
            alert("즐겨찾기 이름을 입력해주세요");
            return;
        }
        
        // 즐겨찾기 이름 수정
        this.hodu_api_call(`api/v1/user/favorite/${favorite.addr_uuid}`, API_METHOD.PUT, {
            "title" : modifyName
        }).then((response) => {
            
            // 즐겨찾기 재조회
            vue.getFavoriteAddress();
            this.favorite_modify_index = -1;

        }).catch((e) => {
            this.hodu_error_process(e, false, false);
        });
    }

    /**
     * 즐겨찾기 리스트에 삭제 alert 띄우기
     */
    deleteAlertOpen(index : number) : void {
        this.show_delete_alert.splice(index, 1, true);
    }

    /**
     * 즐겨찾기 삭제 취소
     */
    deleteAlertCancel(index : number) : void {
        this.show_delete_alert.splice(index, 1, false);
    }

    /**
     * 즐겨찾기 삭제
     */
    @Debounce(150)
    deleteFavorite(favorite : t_favorite_addr) : void {
        const vue = this;

        this.hodu_api_call(`api/v1/user/favorite/${favorite.addr_uuid}`, API_METHOD.DELETE)
            .then((response) => {
                
                // 조회된 장소 리스트에서 즐겨찾기 플래그 업데이트
                const location_count : number = vue.location_list.length;
                for( let i = 0; i < location_count; i++ ) {
                    if( favorite.addr.latitude == this.location_list[i].y && favorite.addr.longitude == this.location_list[i].x ) {
                        vue.location_list[i].isFavorite = false;
                        break;
                    }
                }
                
                // 즐겨찾기 재조회
                vue.getFavoriteAddress();

            })
            .catch((e) => {
                this.hodu_error_process(e, false, false);
            });
    }
    
    /**
     * 장소 선택
     */
    selectLocation() : void {
        this.doSetSelectedLocation({
            "place" : this.selected_any_location.place_name,
            "tel" : this.selected_any_location.phone,
            "post_code" : "",
            "address" : this.selected_any_location.road_address_name,
            "address_old" : this.selected_any_location.address_name,
            "geo" : {
                "longitude" : this.selected_any_location.x,
                "latitude" : this.selected_any_location.y,
            }
        });
        window["changeSeletedLocation"]();
        this.doSetShowEventDaumMap(false);
    }

    /**
     * 즐겨찾기 선택
     */
    selectFavorite(favorite : t_favorite_addr) : void {
        if( this.favorite_modify_index > -1 ) {
            return;
        } 

        this.doSetSelectedLocation({
            "place" : favorite.addr.title,
            "tel" : favorite.addr.addr_tel,
            "post_code" : "",
            "address" : favorite.addr.new_addr,
            "address_old" : favorite.addr.old_addr,
            "geo" : {
                "longitude" : favorite.addr.longitude,
                "latitude" : favorite.addr.latitude,
            }
        });
        window["changeSeletedLocation"]();
        this.doSetShowEventDaumMap(false);
    }

    /**
     * 즐겨찾기 장소 검색
     */
    @Watch('favorite_search_query')
    favoriteSearch() : void {
        this.favorite_search_list.splice(0, this.favorite_search_list.length);
        this.favorite_search_list = this.favorite_search_list.concat(this.favorite_list.filter(favorite => favorite.addr.title.indexOf(this.favorite_search_query.trim()) > -1));

        console.log(this.favorite_search_query.trim());
        console.log(JSON.stringify(this.favorite_search_list))
    }
}

