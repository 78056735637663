
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo    = namespace('ModalInfo');

import { CommonPremiumExpireModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class CommonPremiumExpireModal extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.State
     */
    @ModalInfo.State common_premium_expire_modal_info !: CommonPremiumExpireModalInfo;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetCommonPremiumExpireModalInfo ?: (params : CommonPremiumExpireModalInfo) => void;

    timer_offset : number = 7;
    interval : number | undefined = undefined;

    mounted() : void {

        window.setTimeout(() => {

            this.interval = window.setInterval(() => {
                this.timer_offset -= 1;

                if( this.timer_offset < 0 && this.interval != null ) {
                    window.clearInterval(this.interval);
                }

            }, 1000);
            
        }, 1000);
        
    }

    beforeDestroy() {
        if( this.interval != null ) {
            window.clearInterval(this.interval);
        }
    }

    @Watch("timer_offset")
    watchTimerOffset() : void {
        if( this.timer_offset < 0 ) this.close();
    }

    close() : void {
        this.doSetCommonPremiumExpireModalInfo?.({ show_modal : false, data : {} });
    }

}
