var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section_scroll",attrs:{"id":"organization_off_setting"}},[((_vm.get_group_role(_vm.scope_group_id) == 'GROUP_MANAGER' || _vm.get_group_role(_vm.scope_group_id) == 'ADMIN'))?_c('div',{staticClass:"bt_scheduleBox"},[_c('input',{staticClass:"bt_schedule",attrs:{"type":"button","id":"btnAddVacationType"},on:{"click":function($event){$event.preventDefault();return _vm.add.apply(null, arguments)}}})]):_vm._e(),_c('div',{staticClass:"content"},[_c('div',{staticClass:"pubHolidayDiv"},[_c('ul',{staticClass:"sortHeader",attrs:{"id":""}},[_c('li',{staticClass:"num"},[_vm._v("No")]),_c('li',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.dropdownOutsideClick),expression:"dropdownOutsideClick"}],staticClass:"yyyy"},[_c('a',{staticClass:"sort",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (() => { _vm.is_year_open = !_vm.is_year_open }).apply(null, arguments)}}},[_vm._v(_vm._s(_vm.selected_year)+"년")]),_c('div',{staticClass:"dropdown",class:{ on : _vm.is_year_open == true },attrs:{"id":"year_dropdown"}},[_c('ul',_vm._l((_vm.computedYearAndOffs),function(year_and_offs){return _c('li',{key:year_and_offs.year},[_c('a',{class:{ on : _vm.selected_year == year_and_offs.year },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (() => { 
                                    _vm.selected_year = year_and_offs.year;
                                    _vm.is_year_open = false;
                                }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(year_and_offs.year)+"년 ")])])}),0)])]),_c('li',{staticClass:"time headerSort",class:{
                    sortOff  : _vm.sort_type != 'DATE',
                    sortUp   : _vm.sort_type == 'DATE' && _vm.sort_dir == 'ASC', 
                    sortDown : _vm.sort_type == 'DATE' && _vm.sort_dir == 'DESC', 
                },on:{"click":function($event){$event.preventDefault();return _vm.sort(_vm.organization_enum.ORGANIZATION_OFF_SORT_TYPE.DATE)}}},[_c('span',[_vm._v("휴무 기간")])]),_c('li',{staticClass:"total headerSort",class:{
                    sortOff  : _vm.sort_type != 'COUNT',
                    sortUp   : _vm.sort_type == 'COUNT' && _vm.sort_dir == 'ASC', 
                    sortDown : _vm.sort_type == 'COUNT' && _vm.sort_dir == 'DESC', 
                },on:{"click":function($event){$event.preventDefault();return _vm.sort(_vm.organization_enum.ORGANIZATION_OFF_SORT_TYPE.COUNT)}}},[_c('span',[_vm._v("휴무 수")])]),_vm._m(0)]),_c('div',{attrs:{"id":"organization_off_setting_scroll"}},[_c('ul',{staticClass:"holidayUl"},_vm._l((_vm.computedSelectedYearOffs),function(off,index){return _c('li',{key:off.off_id},[_c('p',{staticClass:"num"},[_vm._v(_vm._s(index + 1))]),_c('p',{staticClass:"yyyy"},[_vm._v(_vm._s(_vm.selected_year)+"년")]),_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.getDateText(off)))]),_c('p',{staticClass:"total"},[_vm._v(_vm._s(_vm.getDateDiff(_vm.yyyymmddToDate(off.start_date), _vm.yyyymmddToDate(off.end_date)) + 1)+" 일")]),_c('p',{staticClass:"reason"},[_vm._v(_vm._s(off.off_reason))]),_c('a',{staticClass:"seeDets",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.detail(off)}}},[_vm._v("자세히 보기")]),((_vm.get_group_role(_vm.scope_group_id) == 'GROUP_MANAGER' || _vm.get_group_role(_vm.scope_group_id) == 'ADMIN'))?_c('a',{staticClass:"del",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteOff(off)}}},[_vm._v("삭제")]):_vm._e()])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"reason"},[_c('span',[_vm._v("사유")])])
}]

export { render, staticRenderFns }