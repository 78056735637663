
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함

/**
 * Component 선언 및 extends Mixins(Vue)
 */
@Component({
    components: {
        
    },
})
export default class SearchBar extends Mixins(Vue) {

    @Prop() is_search_open !: boolean;
    @Prop() placeholder !: string;
    @Prop() search_query !: string;

    mounted() : void {
        
    }

    query(value : string) : void {
        this.$emit('query', value);
    }

    search() : void {
        this.$emit('search');
    }

    searchKeyDown(event) : void {
        this.$emit('searchKeyDown', event);
    }

    searchOff() : void {
        this.$emit('searchOff', false);
    }

    @Watch('is_search_open')
    searchOnOff() : void {
        if( this.is_search_open == true ) this.$nextTick(() => $('#search_text').focus());
    }

}
