
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

import OrganizationVacationList from '@/components/organization/OrganizationVacationList.vue';
import OrganizationMyVacationList from '@/components/organization/OrganizationMyVacationList.vue';

import { namespace } from 'vuex-class';
const OrganizationInfo = namespace('OrganizationInfo');

import { organization_enum } from '@/model/organization';

import { ResizeObserver } from 'vue-resize';

@Component({
    components: {
        OrganizationVacationList, OrganizationMyVacationList, ResizeObserver
    },
})
export default class OrganizationVacation extends Mixins(VueHoduCommon) {

    /**
     * @OrganizationInfo.State
     */
    @OrganizationInfo.State selected_vacation_tab !: organization_enum.ORGANIZATION_VACATION_TAB;

    /**
     * @OrganizationInfo.Action
     */
    @OrganizationInfo.Action doSetOrganizationSelectedVacationTab ?: (params : organization_enum.ORGANIZATION_VACATION_TAB) => void;

    event_bus : Vue = new Vue();

    approval_user_id : number = 0;
    annual_start : string = "";
    annual_end : string = "";
    
    beforeMount() : void {

        if( this.$route.query.user_id == null || this.$route.query.annual_start == null || this.$route.query.annual_end == null ) {
            this.hodu_show_dialog('cancel', "데이터 오류 발생", ["확인"], [() => { this.movePrevPage(); }]);
            return;
        }

        this.approval_user_id = Number(this.$route.query.user_id);

        if( isNaN(this.approval_user_id) ) {
            this.hodu_show_dialog('cancel', "데이터 오류 발생", ["확인"], [() => { this.movePrevPage(); }]);
            return;
        }

        this.annual_start = String(this.$route.query.annual_start);
        this.annual_end = String(this.$route.query.annual_end);
    }

    mounted() : void {
        
    }

    /**
     * 달력 변경
     */
    changeVacationListType(tab : organization_enum.ORGANIZATION_VACATION_TAB) : void {
        this.doSetOrganizationSelectedVacationTab?.(tab);
    }

    /**
     * 직원 휴가 현황 여부
     */
    isAdmin() : boolean {
        return this.selected_vacation_tab == organization_enum.ORGANIZATION_VACATION_TAB.ADMIN;
    }

    /**
     * 내 휴가 현황 여부
     */
    isUser() : boolean {
        return this.selected_vacation_tab == organization_enum.ORGANIZATION_VACATION_TAB.USER;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        if( this.isAdmin() == true ) this.event_bus.$emit('adminResize');
        else if( this.isUser() == true ) this.event_bus.$emit('userResize');
    }

}
