
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon from '@/mixin/VueHoduCommon';

@Component({
    components: {
        
    },
})
export default class ExampleDatepicker extends Mixins(VueHoduCommon) {

    mounted() : void {
        // @ts-ignore
        $('#example-text').datepick({ dateFormat: 'yyyy.mm.dd', multiSelect : 999, showOtherMonths : true, selectOtherMonths : true, fixedWeeks : true })
        
        // @ts-ignore
        $('#example-button').datepick({ dateFormat: 'yyyy.mm.dd', multiSelect : 999, showOtherMonths : true, selectOtherMonths : true, fixedWeeks : true })
    }

}
