
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
import { organization_modal_interface } from '@/model/organization';
const ModalInfo = namespace('ModalInfo');

import OrganizationRecursion2 from '@/components/organization/OrganizationRecursion2.vue';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        OrganizationRecursion2
    },
})
export default class OrganizationModal2 extends Mixins(VueHoduCommon) {

    /**
     * 부서 정보
     */
    get computedOrganization() : any {

        const copy_departments = JSON.parse(JSON.stringify(this.departments));

        let max_level = 0;
        for( const department of copy_departments ) {
            if( department.dept_id_array.length > max_level ) max_level = department.dept_id_array.length;
            department['departments'] = [];
            department['is_closed'] = true;

            // 이전에 정보가 있다면 그 정보로 업데이트
            const is_closed = this.department_closed_map.get(department.dept_id);
            if( is_closed != null ) {
                department['is_closed'] = is_closed;
            }
        }

        let search_level = max_level;
        while(search_level > 1) {
            let search_next_level = search_level - 1;

            const current_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_level);
            const next_search_departments = copy_departments.filter(item => item.dept_id_array.length == search_next_level); 

            for( const next_search_department of next_search_departments ) {
                const next_search_department_id = next_search_department.dept_id;
                next_search_department.level = search_next_level;

                for( const current_search_department of current_search_departments ) {
                    const current_search_department_id = current_search_department.dept_id;
                    current_search_department.level = search_level;

                    if( current_search_department.dept_id_array.indexOf(next_search_department_id) > -1 ) {
                        next_search_department.departments.push(current_search_department);
                    }

                }
            }
            
            search_level--;
        }

        const top_organizations = copy_departments.filter(item => item.dept_id_array.length == 1);
        if( top_organizations == null || top_organizations.length < 1 ) return null;
        const top_organization = top_organizations[0];

        return top_organization;
    }
    
    /**
     * @ModalInfo.State
     */
    @ModalInfo.State organization_modal2_info !: organization_modal_interface.OrganizationModal2Info;

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetOrganizationModal2Info ?: (params : organization_modal_interface.OrganizationModal2Info) => void;

    departments : any[] = [];
    selected_department : any = null;

    department_closed_map : Map<number, boolean> = new Map();

    async mounted() : Promise<void> {
        await this.getOrganization();
        this.setScroll();
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        this.$nextTick(() => {
            // @ts-ignore
            // $('#organization_modal2 .section_scroll').mCustomScrollbar({
            //     axis : 'y',
            //     scrollbarPosition : 'outside',
            //     mouseWheelPixels : 100,
            //     scrollInertia : 60,
            //     autoDraggerLength : false
            // });
        })
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }

            const data = response.data.data.dept_info;

            // 기존에 선택된 부서
            for(const department of data) {
                if( this.organization_modal2_info.dept_id == department.dept_id ) {
                    this.selected_department = department;
                    break;
                }
            }

            // 선택된 부서가 없다면 최상위 부서가 선택됨
            if( this.selected_department == null ) this.selected_department = data.filter(item => item.dept_id == 0)[0];

            const dept_ids_count = this.selected_department.dept_id_array.length;
            for( let i = (dept_ids_count - 1) - 1; i > 0; i-- ) {
                const target_dept_id = this.selected_department.dept_id_array[i];
                const target = data.filter(item => item.dept_id == target_dept_id);

                if( target.length > 0 ) {
                    this.department_closed_map.set(target[0].dept_id, false);
                }
            }

            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(data);

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 부서 조회 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

    }

    /**
     * 선택
     */
    select(department) : void {
        console.log(department);
        this.selected_department = JSON.parse(JSON.stringify(department));
    }

    /**
     * 닫기
     */
    close() : void {
        this.doSetOrganizationModal2Info?.({ show_modal : false, dept_id : 0 });
    }

    /**
     * 저장
     */
    save() : void {
        this.organization_modal2_info.callback?.(this.selected_department);
        this.close();
    }

}
