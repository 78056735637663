
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';

const EtcInfo = namespace('EtcInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class MessageSendStatus extends Mixins(VueHoduCommon) {
    /**
     * EtcInfo.State
     */
    @EtcInfo.State message_info !: any;
    message_body : string = "";

    async mounted() {
        this.message_body = await this.message_info.noti_data.body.replace(/#{그룹팀명}/g, this.message_info.group_team_name)
                                                                  .replace(/#{이름}/g, this.message_info.user_name)
                                                                  .replace(/#{이메일}/g, (this.message_info.user_email ? this.message_info.user_email : '-'))
                                                                  .replace(/#{발신일자}/g, this.message_info.message_start_date + ' '  + this.message_info.message_start_time)
                                                                  .replace(/\n/g, '<BR>');

        setTimeout(function() {
            const msgBoxHeight : number | undefined = $('.modal.detailMsg').outerHeight();
            $('#modalDetail').css('margin-top', '-'+(msgBoxHeight!/2)+'px');
        }, 10);
    }

    messageSendStatusPopClose() : void {
        window["messageSendStatusPopClose"]();
    }
}
