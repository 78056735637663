
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import moment from 'moment';
import { namespace } from 'vuex-class';
import { hodu_doc_table, hodu_doc_modal_info } from '../../model/hodudoc';
const ModalInfo = namespace('ModalInfo');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        
    },
}) 
export default class HospitalOffSetting extends Mixins(VueHoduCommon) {
    
    /**
     * 선택한 년도에 따른 오름차순 휴가 리스트
     */
    get computedDayOffsByYear() : hodu_doc_table.t_hospital_day_offs[] {
        if ( this.hospital_day_offs == null ) { return [] }
        let filtered_list : hodu_doc_table.t_hospital_day_offs[] = []

        filtered_list = this.hospital_day_offs.filter(item => new Date(item.start_date).getFullYear() == this.selected_year)

        filtered_list.sort((off1 : hodu_doc_table.t_hospital_day_offs, off2 : hodu_doc_table.t_hospital_day_offs) : number => {
                //오름차순
                if( off1.start_date  > off2.start_date ) { return  1; }
                if( off1.start_date  < off2.start_date ) { return -1; }
                if( off1.start_date == off2.start_date ) { return  0; }
                return 0
            })
        return this.sortDayOffs(filtered_list);
    }

    /**
     * 전체 휴가 리스트의 해당 년도 리스트
     */
    get computedAvailbleYearList() : number[] {
        if ( this.hospital_day_offs == null ) { return [] }
        let exist_years : number[] = []
        
        //먼저 전체 휴가 리스트의 작성 연도 리스트를 구한다
        const list = this.hospital_day_offs.reverse();
        for ( const info of list ) {
            const start_year = new Date(info.start_date).getFullYear()
            if ( exist_years.includes(start_year) == false ) {
                exist_years.push(start_year)
            }
        }

        exist_years.sort((year1 : number, year2 : number) : number => {
                //오름차순
                if( year1  > year2 ) { return  1; }
                if( year1  < year2 ) { return -1; }
                if( year1 == year2 ) { return  0; }
                return 0
            })
        
        return exist_years;
    }

    /**
     * @ModalInfo.Action
     */
    @ModalInfo.Action doSetDayOffSettingModalInfo ?: (day_off_setting_modal_info : hodu_doc_modal_info.DayOffSettingModalInfo) => void;

    @Prop() event_bus  !: Vue;
    
    selected_year           : number = new Date().getFullYear()
    hospital_day_offs       : hodu_doc_table.t_hospital_day_offs[] = [];
    selected_off_ids        : string[] = [];
    is_year_list_open       : boolean = false;
    day_off_sort_target     : string = "DATE";
    day_off_sort_direction  : string = "ASC";

    mounted() : void {
        if( this.event_bus != null ) { 
            this.event_bus.$on('offSettingResize', this.handleResize);
            this.event_bus.$on('deleteDayOffs', this.deleteDayOffs);
        }
        this.setScroll();
        this.getHospitalDayOffs();
    }

    selectedYear(year : number) {
        this.selected_year = year
        this.is_year_list_open = !this.is_year_list_open;
        this.selected_off_ids.splice(0, this.selected_off_ids.length)
    }

    selectDayOff(off_id: string){
        if (this.selected_off_ids.includes(off_id) == false) {
            this.selected_off_ids.push(off_id)
        }else{
            this.selected_off_ids.splice(this.selected_off_ids.indexOf(off_id), 1)
        }
    }

    selectAllDayOffs(){
        const numOfDayOffs = this.computedDayOffsByYear.length

        if (this.selected_off_ids.length != numOfDayOffs) {
            this.selected_off_ids.splice(0, this.selected_off_ids.length)
            for (const dayoff of this.computedDayOffsByYear) {
                this.selected_off_ids.push(dayoff.hospital_off_id)
            }
        }else{
            this.selected_off_ids.splice(0, this.selected_off_ids.length)
        }
    }

    toggleYearSelectMenu(event) : void {
        if (this.computedAvailbleYearList.length < 1) { return; }
        event.stopPropagation();
        this.is_year_list_open = !this.is_year_list_open;
    }

    sortBy(target: string) {
        // 이미 DATE Sort 중이라면
        if( this.day_off_sort_target == target ) {
            // ASC -> DESC  순으로 변경 되어야 한다
            switch( this.day_off_sort_direction ) {
                case "ASC":
                    this.day_off_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.day_off_sort_direction = "ASC";
                    break; 
            }
        }
        
        // DATE Sort가 아니였다면
        else {
            this.day_off_sort_target = target;
            this.day_off_sort_direction = "ASC";
        }
    }

    sortDayOffs(list : hodu_doc_table.t_hospital_day_offs[]) : hodu_doc_table.t_hospital_day_offs[] {
        return list.sort((dayoff1, dayoff2) : number => {
            // 날짜 오름차순
            if( this.day_off_sort_target == "DATE" && this.day_off_sort_direction == "ASC" ) {
                if( dayoff1.start_date  > dayoff2.start_date ) { return  1; } 
                if( dayoff1.start_date  < dayoff2.start_date ) { return -1; } 
                if( dayoff1.start_date == dayoff2.start_date ) { return  0; }
            }

            // 날짜 내림차순
            if( this.day_off_sort_target == "DATE" && this.day_off_sort_direction == "DESC" ) {
                if( dayoff1.start_date  > dayoff2.start_date ) { return -1; }
                if( dayoff1.start_date  < dayoff2.start_date ) { return  1; }
                if( dayoff1.start_date == dayoff2.start_date ) { return  0; }
            }

            // 휴일명 오름차순
            if( this.day_off_sort_target == "NAME" && this.day_off_sort_direction == "ASC" ) {
                if( dayoff1.off_name  > dayoff2.off_name ) { return  1; }
                if( dayoff1.off_name  < dayoff2.off_name ) { return -1; }
                if( dayoff1.off_name == dayoff2.off_name ) { return  0; }
            }

            // 휴일명 내림차순
            if( this.day_off_sort_target == "NAME" && this.day_off_sort_direction == "DESC" ) {
                if( dayoff1.off_name  > dayoff2.off_name ) { return -1; }
                if( dayoff1.off_name  < dayoff2.off_name ) { return  1; }
                if( dayoff1.off_name == dayoff2.off_name ) { return  0; }
            }

            // 휴일수 오름차순
            if( this.day_off_sort_target == "TERM" && this.day_off_sort_direction == "ASC" ) {
                if( this.getDayOffDuring(dayoff1)  > this.getDayOffDuring(dayoff2) ) { return  1; }
                if( this.getDayOffDuring(dayoff1)  < this.getDayOffDuring(dayoff2) ) { return -1; }
                if( this.getDayOffDuring(dayoff1) == this.getDayOffDuring(dayoff2) ) { return  0; }
            }

            // 휴일수 내림차순
            if( this.day_off_sort_target == "TERM" && this.day_off_sort_direction == "DESC" ) {
                if( this.getDayOffDuring(dayoff1)  > this.getDayOffDuring(dayoff2) ) { return -1; }
                if( this.getDayOffDuring(dayoff1)  < this.getDayOffDuring(dayoff2) ) { return  1; }
                if( this.getDayOffDuring(dayoff1) == this.getDayOffDuring(dayoff2) ) { return  0; }
            }

            return 0;
        });
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const title_height : number | undefined = $('.title_box').outerHeight();
        const schedule_box_height : number | undefined = $('.schedule_box .bg').outerHeight();
        const sort_header_height : number | undefined = $('.sortHeader').outerHeight();

        // @ts-ignore
        $('#hospital_off_setting_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0) - (schedule_box_height ? schedule_box_height : 0) - (sort_header_height ? sort_header_height : 0)
        });
    }

    /**
     * 휴일 생성 및 상세 모달 띄우기
     */
    showHospitalOffSettingModal(is_create : boolean, dayoff: hodu_doc_table.t_hospital_day_offs | null = null) : void {
        if( this.doSetDayOffSettingModalInfo == null ) { return; }
        this.doSetDayOffSettingModalInfo({
            show_modal : true,
            is_create  : is_create,
            is_doctor  : false,
            day_off    : dayoff == null ?  null : JSON.parse(JSON.stringify(dayoff))
        });
        window['deleteDayOff'] = this.deleteDayOff
        window['updateDayOff'] = this.updateDayOff
        window['createDayOff'] = this.createDayOff
    }

    getDayOffYearString(dayoff : hodu_doc_table.t_hospital_day_offs) : string {
        const start_date = new Date(dayoff.start_date).getFullYear()

        return `${start_date}년`
    }

    getDayOffDateString(dayoff : hodu_doc_table.t_hospital_day_offs) : string {
        const start_date = new Date(dayoff.start_date)
        const end_date   = new Date(dayoff.end_date)

        return `${start_date.getMonth()+1}월 ${start_date.getDate()}일 ~ ${end_date.getMonth()+1}월 ${end_date.getDate()}일`
    }

    getDayOffDuring(dayoff : hodu_doc_table.t_hospital_day_offs) : number {
        const start_date = new Date(dayoff.start_date)
        const end_date   = new Date(dayoff.end_date)
        const during_days = moment(end_date).diff(start_date, "days") + 1

        return during_days
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#hospital_off_setting_scroll').mCustomScrollbar('destroy');
    	this.setScroll();
    }

    /**
     * 병원 지정 휴일 생성
     */
    async createDayOff(dayoff: hodu_doc_table.t_hospital_day_offs) : Promise<void> {
        let body = {
            biz_id : this.scope_group_team_option.biz_id,
            off_name : dayoff.off_name,
            off_reason : dayoff.off_reason,
            start_date : new Date(dayoff.start_date),
            end_date : new Date(dayoff.end_date),
            is_public_holiday : false
        }
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.POST, body)
            .then((response) => {
                let jsonData = JSON.stringify(response.data.data);
                // console.log(jsonData);
                
                if (jsonData.length > 0) {
                    this.getHospitalDayOffs(new Date(dayoff.start_date).getFullYear());
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 조회
     */
    async getHospitalDayOffs(moveYearTo: number | null = null) : Promise<void> {
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off?year=0`, API_METHOD.GET)
            .then((response) => {
                let jsonData : hodu_doc_table.t_hospital_day_offs[] = JSON.parse(JSON.stringify(response.data.data))
                this.hospital_day_offs.splice(0, this.hospital_day_offs.length)
                this.hospital_day_offs = this.hospital_day_offs.concat(jsonData)
                this.selected_off_ids.splice(0, this.selected_off_ids.length)
                
                //! 조회 후 이동하고자 하는 년도로 이동
                if ( this.computedDayOffsByYear.length < 1 && this.computedAvailbleYearList.length < 1) {
                    this.selected_year = new Date().getFullYear()
                    return;
                }
                if ( moveYearTo != null ) {
                    this.selected_year = moveYearTo
                }else{
                    if ( this.computedDayOffsByYear.length < 1 && this.computedAvailbleYearList.length > 0 ) {
                        this.selected_year = this.computedAvailbleYearList[this.computedAvailbleYearList.length-1]
                    }
                }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 단일 삭제
     */
    deleteDayOff(dayoff : hodu_doc_table.t_hospital_day_offs, show_alert : boolean | null = null) {
        
        if (show_alert) {
            this.hodu_show_dialog("cancel", `정말로 [${dayoff.off_name}]휴일을 삭제하시겠습니까?`, ["아니오", "예"], [
                () => {},
                () => {
                    this.deleteHospitalDayOff([dayoff.hospital_off_id])
                }
            ]);
        }else{
            this.deleteHospitalDayOff([dayoff.hospital_off_id])
        }
    }

    /**
     * 병원 지정 휴일 멀티 삭제
     */
    async deleteDayOffs() : Promise<void> {
        if ( this.hospital_day_offs.length < 1 || this.selected_off_ids.length < 1 ) { return; }
        const numOfSelectedDayOffs = this.selected_off_ids.length
        
        this.hodu_show_dialog("cancel", `정말로 ${numOfSelectedDayOffs}개의 휴일을 삭제하시겠습니까?`, ["아니오", "예"], [
            () => {},
            async () => {
                this.deleteHospitalDayOff(this.selected_off_ids)
            }
        ]);
    }

    /**
     * 병원 지정 휴일 삭제 요청
     */
    async deleteHospitalDayOff(off_ids : string[]) : Promise<void> {
        let body = {
            hospital_off_ids : off_ids
        }
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.DELETE, body)
            .then((response) => {
                this.getHospitalDayOffs()
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 병원 지정 휴일 수정
     */
    async updateDayOff(dayoff: hodu_doc_table.t_hospital_day_offs) : Promise<void> {
        let body = {
            off_name        : dayoff.off_name,
            off_reason      : dayoff.off_reason,
            start_date      : new Date(dayoff.start_date),
            end_date        : new Date(dayoff.end_date),
            hospital_off_id : dayoff.hospital_off_id
        }
        
        await this.hodu_api_call(`api/v1/hodudoc/hospital/${this.scope_group_team_option.biz_id}/day_off`, API_METHOD.PUT, body)
            .then((response) => {
                let jsonData = JSON.parse(JSON.stringify(response.data.data));
                // let day_off = jsonData as hodu_doc_table.t_hospital_doctor_day_offs

                // if ( day_off.off_name != null && day_off.off_name.length > 0 ) {
                    this.getHospitalDayOffs();
                // }
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            });
    }

}
